// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'
import { Loader } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './button.styles'
import { themeConsumer } from '../../../styles/ThemeContext'

// utils
import { getLabelFromChildren } from '../../../utils/dataLocatorUtils'

const Spinner = styled(Loader)(styles.spinner)

type ButtonProps = {
  // Contents of button.
  children: Node,
  // Set button type.
  type?: 'primary' | 'secondary' | 'link' | 'tertiary' | 'action' | 'icon' | 'whiteWithBorder',
  // Set button to error state.
  error?: boolean,
  // Disable use of button.
  disabled?: boolean,
  // data-locator
  dataLocator?: string,
  // transparent backgroud
  isTransparent?: boolean,
  // show spinner and disabled the button
  isLoading?: boolean,
  // Loader props
  loaderProps?: Object,
  // To handle form submit
  btnActionType?: 'button' | 'submit',
}

const Button = (props: ButtonProps) => {
  const { type, buttonRef, btnActionType, ...reducedProps } = props
  const { error, disabled, children, dataLocator, isTransparent, isLoading, loaderProps } =
    reducedProps
  const dataLocatorValue = {
    'data-locator': dataLocator || `button-${type}-${getLabelFromChildren(children)}`,
  }
  const isBtnDisabled = disabled || isLoading
  const Btn = styled('button')(
    styles.base,
    ({ theme }) => ({
      ...(type === 'action' ? styles[type](theme).action : styles[type](theme)),
      ...(isBtnDisabled ? { ...styles.disabled, ...styles[type](theme).disabled } : null),
      ...(isTransparent ? { ...styles.transparent, ...styles[type](theme).transparent } : null),
    }),
    error ? styles.error : null
  )
  return (
    <Btn type={btnActionType} ref={buttonRef} {...dataLocatorValue} {...reducedProps}>
      {isLoading ? <Spinner {...loaderProps} /> : children}
    </Btn>
  )
}

Button.defaultProps = {
  btnActionType: 'button',
  type: 'primary',
  error: false,
  disabled: false,
  dataLocator: '',
  isTransparent: false,
  isLoading: false,
  loaderProps: {
    spinnerSize: 20,
    borderSize: 2,
  },
}
export default themeConsumer(Button, 'button')
