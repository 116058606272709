export const SAVE_ANSWERS_URE = 'SAVE_ANSWERS_URE'
export const INITIALIZE_DATA_URE = 'INITIALIZE_DATA_URE'
export const UPDATE_DATA_WITH_NEW_DETAILS_URE = 'UPDATE_DATA_WITH_NEW_DETAILS_URE'
export const UPDATE_URE_WITH_ALL_ACTIVE_ENQUIRY_ID = 'UPDATE_URE_WITH_ALL_ACTIVE_ENQUIRY_ID'
export const SET_ACTIVE_ENQUIRY_ID_OF_URE = 'SET_ACTIVE_ENQUIRY_ID_OF_URE'
export const GET_PAYLOAD_OF_FULL_URE = 'GET_PAYLOAD_OF_FULL_URE'
export const GET_PAYLOAD_OF_FULL_URE_ERROR = 'GET_PAYLOAD_OF_FULL_URE_ERROR'
export const GET_PAYLOAD_OF_FULL_URE_FAILURE = 'GET_PAYLOAD_OF_FULL_URE_FAILURE'
export const GET_PAYLOAD_OF_FULL_URE_SUCCESS = 'GET_PAYLOAD_OF_FULL_URE_SUCCESS'
export const GET_RESULTS_OF_URE = 'GET_RESULTS_OF_URE'
export const UPDATE_ANSWERS_OF_FULL_URE = 'UPDATE_ANSWERS_OF_FULL_URE'
export const UPDATE_ANSWERS_OF_FULL_URE_ERROR = 'UPDATE_ANSWERS_OF_FULL_URE_ERROR'
export const UPDATE_ANSWERS_OF_FULL_URE_FAILURE = 'UPDATE_ANSWERS_OF_FULL_URE_FAILURE'
export const UPDATE_ANSWERS_OF_FULL_URE_SUCCESS = 'UPDATE_ANSWERS_OF_FULL_URE_SUCCESS'
export const UPDATE_UNDER_WRITING_METHOD = 'UPDATE_UNDER_WRITING_METHOD'
export const INITIALIZE_DATA_URE_SUCCESS = 'INITIALIZE_DATA_URE_SUCCESS'
export const INITIALIZE_DATA_URE_ERROR = 'INITIALIZE_DATA_URE_ERROR'
export const INITIALIZE_DATA_URE_INIT = 'INITIALIZE_DATA_URE_INIT'
export const INITIALIZE_DATA_URE_FAILURE = 'INITIALIZE_DATA_URE_FAILURE'
export const GET_RESULTS_OF_URE_SUCCESS = 'GET_RESULTS_OF_URE_SUCCESS'
export const GET_RESULTS_OF_URE_INIT = 'GET_RESULTS_OF_URE_INIT'
export const GET_RESULTS_OF_URE_ERROR = 'GET_RESULTS_OF_URE_ERROR'
export const GET_RESULTS_OF_URE_FAILURE = 'GET_RESULTS_OF_URE_FAILURE'
export const SAVE_URL_FOR_URE_RESULT = 'SAVE_URL_FOR_URE_RESULT'
export const UPDATE_STATUS_OF_ENQUIRY = 'UPDATE_STATUS_OF_ENQUIRY'
export const RESET_URE_DATA = 'RESET_URE_DATA'
export const FETCH_FULL_URE_DATA = 'URE/FETCH_FULL_URE_DATA'
export const FETCH_FULL_URE_DATA_INIT = 'URE/FETCH_FULL_URE_DATA_INIT'
export const FETCH_FULL_URE_DATA_SUCCESS = 'URE/FETCH_FULL_URE_DATA_SUCCESS'
export const FETCH_FULL_URE_DATA_FAILURE = 'URE/FETCH_FULL_URE_DATA_FAILURE'
export const FETCH_FULL_URE_DATA_ERROR = 'URE/FETCH_FULL_URE_DATA_ERROR'
export const RESET_FULL_URE_RESULT_SUCCESS_DATA = 'URE/RESET_FULL_URE_RESULT_SUCCESS_DATA'
export const OPEN_URE_ERROR_MODAL_POPUP = 'URE/OPEN_URE_ERROR_MODAL_POPUP'
export const CLOSE_URE_ERROR_MODAL_POPUP = 'URE/CLOSE_URE_ERROR_MODAL_POPUP'
export const CONFIRM_SMOKER_TERMS = 'URE/CONFIRM_SMOKER_TERMS'
export const RESET_CONFIRM_SMOKER_TERMS = 'URE/RESET_CONFIRM_SMOKER_TERMS'
export const GET_DETAILS_FOR_ENQUIRY_ID = 'GET_DETAILS_FOR_ENQUIRY_ID'
export const GET_DETAILS_FOR_ENQUIRY_ID_INIT = 'GET_DETAILS_FOR_ENQUIRY_ID_INIT'
export const GET_DETAILS_FOR_ENQUIRY_ID_SUCCESS = 'GET_DETAILS_FOR_ENQUIRY_ID_SUCCESS'
export const GET_DETAILS_FOR_ENQUIRY_ID_ERROR = 'GET_DETAILS_FOR_ENQUIRY_ID_ERROR'
export const GET_DETAILS_FOR_ENQUIRY_ID_FAILURE = 'GET_DETAILS_FOR_ENQUIRY_ID_FAILURE'
