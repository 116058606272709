// @flow
import { GENDER } from '../../../constants/forms'
import {
  date,
  required,
  datePast,
  isNotFormattedDate,
  ageRangeCondition,
  firstNameLastName,
} from '../../../utils/formUtils'
import { type ChildCoverFields } from './childCover.fields'

export const FORM_ID = 'childCover'

// Type for the serialised CCI form data that gets used to create quote/policy
export type ChildData = {|
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  childGender: 'Male' | 'Female',
  childSmokerStatus: 'Yes' | 'No',
|} | null

// this form schema requires fields be combined with it before it can be retrieved.
const getChildCoverSchema = (fields: ChildCoverFields) => ({
  firstName: {
    condition: [
      required,
      {
        condition: firstNameLastName,
        errorMsg: fields && fields.validFirstNameError,
      },
    ],
    errorMsg: fields.firstNameError,
    tooltip: '',
  },
  lastName: {
    condition: [
      required,
      {
        condition: firstNameLastName,
        errorMsg: fields && fields.validLastNameError,
      },
    ],
    errorMsg: fields.lastNameError,
    tooltip: '',
  },
  dateOfBirth: {
    condition: [
      required,
      date,
      {
        condition: isNotFormattedDate,
        errorMsg: fields && fields.dateOfBirthError.value,
      },
      {
        condition: date,
        errorMsg: fields && fields.dateOfBirthError.value,
      },
      { condition: datePast, errorMsg: fields.dateOfBirthFutureError },
      {
        condition: ageRangeCondition(3, 18),
        errorMsg: fields.dateOfBirthRangeError,
      },
    ],
    errorMsg: fields.dateOfBirthError,
    tooltip: '',
  },
  // These need to be prefixed with 'child' due to naming collisions with the
  // radio button sets in <CaptureClient>
  childGender: {
    condition: required,
    errorMsg: fields.genderError,
    tooltip: '',
    options: GENDER,
  },
  childSmokerStatus: {
    condition: required,
    errorMsg: fields.smokerStatusError,
    tooltip: '',
    options: [
      {
        label: 'Smoker',
        value: 'Yes',
      },
      {
        label: 'Non Smoker',
        value: 'No',
      },
    ],
  },
})

export default getChildCoverSchema
