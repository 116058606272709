import { colours, fontSize, space } from '../../../styles'

const styles = {
  editButton: {
    color: colours.white,
    fontSize: fontSize.xs,
    display: 'block',
    width: '100%',
    '&:hover': {
      color: colours.white,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  quoteToolContainer: {
    minHeight: `calc(100vh - ${space(12, true)})`,
    position: 'relative',
    paddingBottom: space(18, true),
  },
  modalButton: {
    marginTop: space(2, true),
  },
}

export default styles
