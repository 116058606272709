/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconUmbrella32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.787 4.001c.486-.331 1.068-.501 1.713-.501s1.227.17 1.713.501c.474.324.768.741.947 1.108l.004.008c2.398.232 4.742.873 6.56 2.451C27.875 9.433 29 12.345 29 16.5V18H18v5c0 1.414-.277 2.883-1.08 4.041C16.064 28.281 14.716 29 13 29c-1.715 0-3.063-.72-3.92-1.959C8.276 25.883 8 24.414 8 23h3c0 1.086.223 1.867.546 2.334.267.386.669.666 1.454.666.785 0 1.187-.28 1.454-.666.323-.467.546-1.248.546-2.334v-5H4v-1.5c0-4.155 1.126-7.067 3.275-8.932 1.819-1.578 4.163-2.219 6.561-2.451l.004-.008c.179-.367.473-.784.947-1.108zM16.5 8c-.487 0-.962.01-1.424.034-2.545.128-4.505.646-5.834 1.8-1.085.941-1.949 2.486-2.18 5.166h18.877c-.232-2.68-1.096-4.225-2.18-5.166-1.33-1.154-3.29-1.672-5.835-1.8A28.247 28.247 0 0 0 16.5 8z"
      fill="#000"
    />
  </Icon>
)

export default IconUmbrella32
