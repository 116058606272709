import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, inputPadding, mediaQueries } = Variables

const styles = {
  wrapper: isManual => ({
    display: isManual ? 'block' : 'none',
  }),
  toggle: {
    color: colours.functionalAcceptDark,
    cursor: 'pointer',
  },
  select: {
    marginBottom: 0,
  },
  addressToggle: {
    marginBottom: space(2),
  },
  addressToggleRight: {
    marginBottom: space(2),
    textAlign: 'right',
  },
  inputThirds: {
    width: '100%',
    marginBottom: 0,

    [mediaQueries.md]: {
      width: '33.33%',
    },
  },
  actionItem: {
    color: colours.mediumGreen,
    cursor: 'pointer',
    paddingLeft: inputPadding.sm.horizontal,
    paddingRight: inputPadding.sm.horizontal,
    paddingTop: space(1),
    paddingBottom: space(1),
  },
  inputGroup: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: 0,
    '& > div': {
      width: '100%',
    },
    input: {
      width: '100%',
    },
    [mediaQueries.md]: {
      flexDirection: 'row',
      gap: space(3),
    },
  },
}

export default styles
