// @flow
import get from 'lodash/get'
import moment from 'moment'

/**
 * Returns true if all the policies is invalid for a particular rule
 * @param {array} rulesForPolicies - policies from alterations rule API
 * @param {string} flagName - key name for rule
 * @param {string} invalidFlagValue - value when rule is not valid
 */
export const isAllPoliciesInValidForRule = (
  rulesForPolicies: Array<Object>,
  flagName: string,
  invalidFlagValue: string = 'N'
) =>
  rulesForPolicies.every(
    policy => get(policy, `assesment[${flagName}]`, invalidFlagValue) === invalidFlagValue
  )

/**
 * Returns message for invalid policy, null if policy is valid
 * @param {array} policiesRules - policies from alterations rule API
 * @param {string} bancsPolicyNo - bancs policy number
 * @param {array} alterationsModalMessages - masterlist data for messages
 * @param {object} isAdviserPortal - if adviser portal
 */
export const policyInvalidMessage = (
  policiesRules: Array<Object>,
  bancsPolicyNo: string,
  alterationsModalMessages: Array<Object> = [],
  isAdviserPortal: boolean
) => {
  // default value for invalidValue is Y
  const criterias = [
    {
      name: 'rejectCPI_NeverApplies',
    },
    {
      name: 'rejectCPI_BancsCalculationCompleted',
      invalidValue: 'N',
    },
    {
      name: 'rejectCPI_InEscalationRejectionPeriod',
      invalidValue: 'N',
    },
    {
      name: 'rejectCPI_EscalationAlreadyRejected',
    },
    {
      name: 'policyInfo_HasPendingAlteration',
    },
    {
      name: 'policyInfo_HasPendingClaim',
    },
    {
      name: 'policyInfo_IsSuspended',
    },
    {
      name: 'linkedPolicyInfo_HasPendingClaim',
    },
    {
      name: 'linkedPolicyInfo_IsSuspended',
    },
    {
      name: 'linkedPolicyInfo_HasPendingAlteration',
    },
    {
      name: 'linkedPolicyInfo_IdenticalCommencementDate',
      invalidValue: 'N',
    },
  ]
  let messageData
  const currentPolicyRules = policiesRules.find(
    policyRules => policyRules.bancsPolicyNo === bancsPolicyNo
  )
  const eligibilityFlag = isAdviserPortal
    ? 'rejectCPI_PolicyEligibleForAdviser'
    : 'rejectCPI_PolicyEligibleForCustomer'
  if (get(currentPolicyRules, `assesment.${eligibilityFlag}`) === 'N') {
    const flagFound = criterias.some(criteria => {
      if (
        get(currentPolicyRules, `assesment.[${criteria.name}]`) === (criteria.invalidValue || 'Y')
      ) {
        if (
          !isAdviserPortal &&
          [
            'policyInfo_IsSuspended',
            'linkedPolicyInfo_IsSuspended',
            'linkedPolicyInfo_IdenticalCommencementDate',
          ].includes(criteria.name)
        )
          criteria.name += '_CP'
        messageData = alterationsModalMessages.find(item => item.code === criteria.name) || {}
        return true
      }
      return false
    })
    if (!flagFound) {
      messageData = alterationsModalMessages.find(item => item.code === eligibilityFlag) || {}
    }
    return messageData.message
  }
  return null
}

const isProductCodeEligible = (isAdviserPortal, policiesInRules) => {
  const policiesNotEligibleForDecrease = ['LC_IP_PF_BE_NS_MLC']
  return !policiesInRules.some(
    policyRule =>
      policiesNotEligibleForDecrease.includes(get(policyRule, 'assesment.policyInfo_ProductID')) &&
      (isAdviserPortal
        ? get(policyRule, 'assesment.rejectCPI_PolicyEligibleForAdviser') === 'N' ||
          get(policyRule, 'assesment.decrease_PolicyEligibleForAdviser') === 'N'
        : get(policyRule, 'assesment.rejectCPI_PolicyEligibleForCustomer') === 'N')
  )
}

/**
 * Returns alteration unavailable heading and description based on different criteria
 * This is called by other functions
 * @param {object} param0 - required data for the function
 */
export const getAlterationNotEligibleMsg = ({
  alterationsModalMessages = [],
  portfolioRules,
  isAdviserPortal,
  rulesList,
  eligibilityFlagAdviser = 'rejectCPI_EligibleForAdviser',
  eligibilityFlagCustomer = 'rejectCPI_EligibleForCustomer',
  policiesInRules = [],
}: Object) => {
  let messageData
  const flagFound = rulesList.some(rule => {
    if (
      (get(rule, 'portal', 'ALL') === 'ALL' ||
        (isAdviserPortal && rule.portal === 'AP') ||
        (!isAdviserPortal && rule.portal === 'CP')) &&
      portfolioRules[rule.name] === (rule.invalidValue || 'Y') &&
      isProductCodeEligible(isAdviserPortal, policiesInRules)
    ) {
      messageData = alterationsModalMessages.find(item => item.code === rule.name) || {}
      return true
    }
    return false
  })
  if (!flagFound) {
    const eligibilityFlag = isAdviserPortal ? eligibilityFlagAdviser : eligibilityFlagCustomer
    messageData = alterationsModalMessages.find(item => item.code === eligibilityFlag) || {}
  }
  return {
    heading: messageData.title,
    description: messageData.message,
  }
}

/**
 * Returns reject CPI unavailable heading and description based on different criteria
 * @param {object} data - required data for the function
 */
export const getCPINotEligibleDesc = (data: Object) => {
  // possible portal values - AP, CP, ALL(default value)
  // default value for invalidValue is Y
  const rulesList = [
    {
      name: 'rejectCPI_BenefitLinkageIssue',
      portal: 'CP',
    },
    {
      name: 'customerInfo_HasInForcePolicy',
      invalidValue: 'N',
    },
    {
      name: 'customerInfo_HasPendingClaim',
    },
    {
      name: 'customerInfo_HasPendingAlteration',
    },
    {
      name: 'customerInfo_IsSuspended',
    },
    {
      name: 'rejectCPI_AnniversaryDateConflictForAdviser',
      portal: 'AP',
    },
    {
      name: 'rejectCPI_AnniversaryDateConflictForCustomer',
      portal: 'CP',
    },
    {
      name: 'rejectCPI_PortfolioWithPPPWithMultipleLifeInsured',
      portal: 'AP',
    },
    {
      name: 'customerInfo_MultipleAdvisers',
      portal: 'CP',
    },
    {
      name: 'customerInfo_SomePolicyWithMultiplePeople',
      portal: 'CP',
    },
  ]
  return getAlterationNotEligibleMsg({
    ...data,
    rulesList,
  })
}

/**
 * Returns alterations unavailable heading and description
 * based on different criteria for generic reasons
 * @param {object} data - required data for the function
 */
export const getAltsNotEligibleGenericMsg = (data: Object) => {
  // default value for portal is ALL
  // default value for invalidValue is Y
  const rulesList = [
    {
      name: 'customerInfo_HasInForcePolicy',
      invalidValue: 'N',
    },
    {
      name: 'customerInfo_HasPendingClaim',
    },
    {
      name: 'customerInfo_HasPendingAlteration',
    },
    {
      name: 'customerInfo_hasSMBBenefit',
    },
    {
      name: 'customerInfo_IsSuspended',
    },
    {
      name: 'decreaseIncrease_AnniversaryDateConflictForAdviser',
    },
    {
      name: 'customerInfo_ConflictingMonthlySchedules',
    },
    {
      name: 'decreaseIncrease_portfolioAnniversaryPeriod',
      invalidValue: 'Multiple',
    },
    {
      name: 'customerInfo_hasHybridBenefitPeriod',
    },
    {
      name: 'decreaseIncrease_BenefitLinkageIssue',
      portal: 'AP',
    },
  ]
  return getAlterationNotEligibleMsg({
    ...data,
    rulesList,
  })
}

/**
 * Returns message for invalid policy, null if policy is valid
 * @param {array} policiesRules - policies from alterations rule API
 * @param {string} bancsPolicyNo - bancs policy number
 * @param {array} alterationsModalMessages - masterlist data for messages
 */
export const decreasePolicyInvalidMessage = (
  policiesRules: Array<Object>,
  bancsPolicyNo: string,
  alterationsModalMessages: Array<Object> = []
) => {
  // default value for invalidValue is Y
  const rulesList = [
    {
      name: 'policyInfo_HasPendingAlteration',
    },
    {
      name: 'linkedPolicyInfo_HasPendingAlteration',
    },
    {
      name: 'policyInfo_HasPendingClaim',
    },
    {
      name: 'linkedPolicyInfo_HasPendingClaim',
    },
    {
      name: 'policyInfo_IsSuspended',
    },
    {
      name: 'linkedPolicyInfo_IsSuspended',
    },
    {
      name: 'linkedPolicyInfo_IdenticalCommencementDate',
      invalidValue: 'N',
    },
    {
      name: 'policyBenefitLinkageIssue',
    },
    {
      name: 'policyInfo_HybridBenefitPeriod',
    },
    {
      name: 'policyInfo_HasSMBBenefit',
    },
  ]
  const currentPolicyRules = policiesRules.find(
    policyRules => policyRules.bancsPolicyNo === bancsPolicyNo
  )
  const messageData = getAlterationNotEligibleMsg({
    policiesInRules: [currentPolicyRules],
    portfolioRules: currentPolicyRules?.assesment ?? [],
    rulesList,
    alterationsModalMessages,
    isAdviserPortal: true,
    eligibilityFlagAdviser: 'decrease_PolicyEligibleForAdviser',
  })
  return messageData?.description || ''
}

export const getLatterEscalationDate = (policiesRules: Array<Object>) => {
  if (!policiesRules.length) {
    return ''
  }
  let date = moment(policiesRules[0].assesment?.rejectCPI_EscalationDate, 'DD/MM/YYYY')
  for (let index = 1; index < policiesRules.length; index += 1) {
    const policyDate = moment(
      policiesRules[index].assesment?.rejectCPI_EscalationDate,
      'DD/MM/YYYY'
    )
    if (policyDate.isAfter(date)) {
      date = policyDate
    }
  }
  return date.format('YYYY-MM-DD')
}
