// @flow
import React, { Node } from 'react'
import styled from '@emotion/styled'
import { themeConsumer } from '../../../styles/ThemeContext'

// components.
import { IconExclaimation16, IconTooltip16 } from '../Icons'

// styles.
import styles from './caption.styles'

type CaptionProps = {
  // Caption content
  children: Node,
  // Sets caption to error state
  error?: boolean,
  // Move caption message from left
  captionTabIndex?: Number,
  // Sets if caption displays icon
  withIcon?: boolean,
}

const El = styled('span')(({ theme, error, captionTabIndex }) =>
  styles.base(theme, error, captionTabIndex)
)

const Caption = (props: CaptionProps) => {
  const { children, error, withIcon } = props
  const Icon = styled(error ? IconExclaimation16 : IconTooltip16)(styles.icon)
  return (
    <El {...props}>
      {withIcon && <Icon />}
      {children}
    </El>
  )
}
Caption.defaultProps = {
  error: false,
  captionTabIndex: 0,
  withIcon: true,
}

export default themeConsumer(Caption, 'form')
