export const RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF = 'RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF'
export const RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_INIT =
  'RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_INIT'
export const RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_SUCCESS =
  'RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_SUCCESS'
export const RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_FAILURE =
  'RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF_FAILURE'

export const SAVE_SUPPORT_STAFF_CONSENT = 'SAVE_SUPPORT_STAFF_CONSENT'
export const SAVE_SUPPORT_STAFF_CONSENT_INIT = 'SAVE_SUPPORT_STAFF_CONSENT_INIT'
export const SAVE_SUPPORT_STAFF_CONSENT_SUCCESS = 'SAVE_SUPPORT_STAFF_CONSENT_SUCCESS'
export const SAVE_SUPPORT_STAFF_CONSENT_FAILURE = 'SAVE_SUPPORT_STAFF_CONSENT_FAILURE'
export const SAVE_SUPPORT_STAFF_CONSENT_ERROR = 'SAVE_SUPPORT_STAFF_CONSENT_ERROR'

export const SAVE_ACTIVE_SUPPORT_STAFF_DATA = 'SAVE_ACTIVE_SUPPORT_STAFF_DATA'
export const SAVE_ACTIVE_ADVISER_DATA = 'SAVE_ACTIVE_ADVISER_DATA'
export const RESET_ADVISOR_SUPPORT_STAFF = 'RESET_ADVISOR_SUPPORT_STAFF'

export const FETCH_SUPPORT_STAFF_DETAILS = 'FETCH_SUPPORT_STAFF_DETAILS'
export const FETCH_SUPPORT_STAFF_DETAILS_INIT = 'FETCH_SUPPORT_STAFF_DETAILS_INIT'
export const FETCH_SUPPORT_STAFF_DETAILS_SUCCESS = 'FETCH_SUPPORT_STAFF_DETAILS_SUCCESS'
export const FETCH_SUPPORT_STAFF_DETAILS_FAILURE = 'FETCH_SUPPORT_STAFF_DETAILS_FAILURE'
export const FETCH_SUPPORT_STAFF_DETAILS_ERRROR = 'FETCH_SUPPORT_STAFF_DETAILS_ERRROR'
export const UPDATE_SUPPORT_STAFF_DETAILS = 'UPDATE_SUPPORT_STAFF_DETAILS'
