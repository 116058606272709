import { space, colours, fontSize, fontWeights } from '../../../../styles'

const styles = {
  base: {
    padding: space(3),
    width: space(50),
    position: 'relative',
  },
  twoColumnLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flexBasis: `calc(50% - ${space(1, true)})`,
    },
  },
  footer: {
    flex: 1,
    width: '100%',
    marginBottom: space(1),
    '& > *': {
      marginTop: space(3),
      width: '100%',
    },
  },
  rating: {
    flex: '0 1 100%',
    width: '100%',
    color: colours.mediumGrey,
  },
  noResults: {
    padding: space(2),
  },

  wrap: {
    position: 'relative',
    padding: `${space(2, true)} ${space(3, true)} ${space(2, true)} ${space(4, true)}`,
    borderBottom: `1px solid ${colours.darkestGrey} `,
    '&:before': {
      content: '""',
      width: space(1),
      height: '100%',
      position: 'absolute',
      background: colours.lightGreen,
      left: 0,
      top: 0,
    },
  },

  settings: {
    marginLeft: space(-1),
  },

  infoIcon: {
    color: colours.white,
    margin: space(1),
    width: space(2),
    height: space(2),
  },
  switch: {
    color: colours.white,
    fontSize: fontSize.lg,
    margin: `0 ${space(1, true)}`,
    fontWeight: fontWeights.semiBold,
  },
  label: {
    color: colours.white,
    fontWeight: fontWeights.semiBold,
    textAlign: 'right',
    fontSize: fontSize.xxs,
  },
  button: {
    marginTop: space(5),
    display: 'block',
  },
  inputElement: {
    maxWidth: space(32),
  },
  quoteCloseIcon: {
    color: colours.black,
    margin: 0,
    width: space(2),
    height: space(2),
  },
  soleDirectorCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  sidebar: {
    marginBottom: space(3),
  },
  error: {
    color: colours.red,
  },
  wrapper: {
    position: 'relative',
  },
  wraptext: {
    marginTop: space(3),
  },
}
export default styles
