import {
  email,
  optional,
  numeric,
  phoneInputs,
  alphaNumericWithSpace,
} from '../../../../../../utils/formUtils'

export const FORM_ID = 'quoteUpdateAdviserDetails'

const schema = (fields, isManual) => ({
  adviserName: {
    condition: [optional],
  },
  businessName: {
    condition: [optional],
  },
  abn: {
    condition: [optional],
  },
  fullAddress: {
    condition: [optional],
  },
  contactNumber: {
    condition: [
      {
        condition: numeric,
        errorMsg: fields.quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg.value,
      },
    ],
    onChangeCondition: phoneInputs,
  },
  email: {
    condition: [
      optional,
      {
        condition: email,
        errorMsg: fields.quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg.value,
      },
    ],
  },
  street: {
    condition: [
      optional,
      isManual
        ? {
            condition: alphaNumericWithSpace,
            errorMsg: fields.downloadQuoteInvalidStreetErrorMsg.value,
          }
        : optional,
    ],
  },
  houseNo: {
    condition: optional,
    minimum: 0,
  },
  locality: {
    condition: [
      optional,
      isManual
        ? {
            condition: alphaNumericWithSpace,
            errorMsg: fields.downloadQuoteInvalidLocalityErrorMsg.value,
          }
        : optional,
    ],
  },
  state: {
    condition: [
      optional,
      isManual
        ? {
            condition: alphaNumericWithSpace,
            errorMsg: fields.downloadQuoteInvalidStateErrorMsg.value,
          }
        : optional,
    ],
  },
  country: {
    condition: [optional],
  },
  postCode: {
    condition: [
      optional,
      isManual
        ? {
            condition: alphaNumericWithSpace,
            errorMsg: fields.downloadQuoteInvalidPostCodeErrorMsg.value,
          }
        : optional,
    ],
    minimum: 0,
  },
})

export default schema
