// @flow

// checks if the two covers are same
export const isDisplayPolicyStructureDuplicateCover = (cover1: Object, cover2: Object) =>
  cover1.type === cover2.type &&
  cover1.benefitInstanceNo.toString() === cover2.benefitInstanceNo.toString() &&
  cover1.policyInstanceNo.toString() === cover2.policyInstanceNo.toString()

// returns cover from displayPolicyStructure data
export const getCoverInDisplayPolicyStructure = (
  coverToSearch: Object,
  coversList: Array<Object>
) => {
  let resultCover = coversList.find(cover =>
    isDisplayPolicyStructureDuplicateCover(cover, coverToSearch)
  )
  if (resultCover) {
    return resultCover
  }
  coversList.some(cover => {
    resultCover = getCoverInDisplayPolicyStructure(coverToSearch, cover.covers)
    return resultCover
  })
  return resultCover
}

// returns cover from policy structure in quote entity
export const getCoverInPolicyStructure = (
  coverToSearch: Object,
  policyStructure: Array<Object>,
  policyInstanceNo: string
) => {
  let resultCover = null
  policyStructure.some(policy => {
    resultCover = policy.covers.find(
      cover =>
        cover.type === coverToSearch.type &&
        cover.benefitInstanceNo.toString() === coverToSearch.benefitInstanceNo.toString() &&
        policyInstanceNo.toString() === policy.policyInstanceNo.toString()
    )
    return resultCover
  })
  return resultCover
}

// adds cover to displayPolicyStructure argument

export const addCoverToDisplayPolicyStructure = (
  coverToBeAdded,
  displayPolicyStructure,
  policyInstanceNo,
  policyStructure
  // @FIXME: Need to determine return type if isCoverAlreadyAdded is truthy
  // eslint-disable-next-line consistent-return
) => {
  const isCoverAlreadyAdded = getCoverInDisplayPolicyStructure(
    coverToBeAdded,
    displayPolicyStructure
  )
  if (!isCoverAlreadyAdded) {
    // Use optimiserParentBenefitReference, if present, in place of parentBenefitReference
    // for optimiser benefits
    const coverToBeAddedParentBenefitReference =
      coverToBeAdded.optimiserParentBenefitReference || coverToBeAdded.parentBenefitReference
    if (coverToBeAddedParentBenefitReference) {
      const parentCover = {
        policyInstanceNo: coverToBeAddedParentBenefitReference.parentPolicyReferenceNo,
        benefitInstanceNo: coverToBeAddedParentBenefitReference.parentBenefitInstanceNo,
        type: coverToBeAddedParentBenefitReference.parentType,
        covers: [],
      }

      const existingParentCover = getCoverInDisplayPolicyStructure(
        parentCover,
        displayPolicyStructure
      )
      if (existingParentCover) {
        existingParentCover.covers.push(coverToBeAdded)
      } else {
        const parentCoverInPolicyStructure = getCoverInPolicyStructure(
          parentCover,
          policyStructure,
          parentCover.policyInstanceNo
        )
        parentCover.parentBenefitReference = parentCoverInPolicyStructure.parentBenefitReference
        parentCover.optimiserParentBenefitReference =
          parentCoverInPolicyStructure.optimiserParentBenefitReference
        parentCover.name = parentCoverInPolicyStructure.name
        parentCover.covers.push(coverToBeAdded)
        return addCoverToDisplayPolicyStructure(
          parentCover,
          displayPolicyStructure,
          policyInstanceNo
        )
      }
    } else {
      displayPolicyStructure.push(coverToBeAdded)
    }
  }
}

// returns displayPolicyStructure data
export const getDisplayPolicyStructure = (policyStructure: Array<Object>) => {
  const displayPolicyStructure = []
  policyStructure.forEach(policy => {
    policy.covers.forEach(cover => {
      const coverToBeAdded = {
        policyInstanceNo: policy.policyInstanceNo,
        benefitInstanceNo: cover.benefitInstanceNo,
        type: cover.type,
        covers: [],
        parentBenefitReference: cover.parentBenefitReference,
        optimiserParentBenefitReference: cover.optimiserParentBenefitReference,
        name: cover.name,
      }
      addCoverToDisplayPolicyStructure(
        coverToBeAdded,
        displayPolicyStructure,
        policy.policyInstanceNo,
        policyStructure
      )
    })
  })
  return displayPolicyStructure
}

// checks if cover is of given policy or it is child of any cover of given policy
export const isPolicyCoverChild = (coverToSearch: Object, policyInstanceNo: string) => {
  if (coverToSearch.policyInstanceNo.toString() === policyInstanceNo.toString()) {
    return true
  }
  return coverToSearch.covers.some(cover => isPolicyCoverChild(cover, policyInstanceNo))
}

// returns parent cover of a given cover
export const getParentCoverInDisplayPolicyStructure = (() => {
  // created a closure so that parentCover is accessible to inner function and its value can be set
  let parentCover
  const getParentCover = (coverToSearchData, displayPolicyStructureData) => {
    if (!coverToSearchData.parentBenefitReference) {
      return parentCover
    }
    displayPolicyStructureData.some(cover => {
      if (
        cover.policyInstanceNo.toString() ===
          coverToSearchData.parentBenefitReference.parentPolicyReferenceNo.toString() &&
        cover.benefitInstanceNo.toString() ===
          coverToSearchData.parentBenefitReference.parentBenefitInstanceNo.toString() &&
        cover.type === coverToSearchData.parentBenefitReference.parentType
      ) {
        parentCover = cover
        return true
      }
      // function called recursively
      return getParentCover(coverToSearchData, cover.covers)
    })
    return parentCover
  }
  return (coverToSearch: Object, displayPolicyStructure: Array<Object>) => {
    parentCover = null
    return getParentCover(coverToSearch, displayPolicyStructure)
  }
})()

// checks if any parent cover of cover is of a given policy
export const isPolicyCoverParent = (
  coverToSearch: Object,
  policyInstanceNo: string,
  displayPolicyStructure: Array<Object>
) => {
  const parentCover = getParentCoverInDisplayPolicyStructure(coverToSearch, displayPolicyStructure)
  if (!parentCover) {
    return false
  }
  if (parentCover.policyInstanceNo.toString() === policyInstanceNo.toString()) {
    return true
  }
  // recursively calling the function
  return isPolicyCoverParent(parentCover, policyInstanceNo, displayPolicyStructure)
}

// checks if cover is of given policy or it is child or parent of any cover of given policy
// also checks if optimiser cover is of same policy
export const isPolicyCoverChildOrParent = (
  coverToSearch: Object,
  policyInstanceNo: string,
  displayPolicyStructure: Array<Object>
) => {
  let isOptimiserCoverOfSamePolicy = true
  if (coverToSearch.optimiserParentBenefitReference) {
    isOptimiserCoverOfSamePolicy =
      coverToSearch.policyInstanceNo.toString() === policyInstanceNo.toString()
  }
  return (
    (isPolicyCoverChild(coverToSearch, policyInstanceNo) ||
      isPolicyCoverParent(coverToSearch, policyInstanceNo, displayPolicyStructure)) &&
    isOptimiserCoverOfSamePolicy
  )
}
