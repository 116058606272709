import styled from '@emotion/styled'
import { Button, Checkbox, Heading, Icons, Input, Select } from '@mlcl-digital/mlcl-design'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import React, { useEffect } from 'react'

// redux.
import { useDispatch, useSelector } from 'react-redux'
import { createEvent } from '../../../../../../utils/telemetry'

// components.
import InputPhone from '../../../../../molecules/InputPhone'

// schema.
import SCHEMA, { FORM_ID, SchemaNameType } from './supportStaffRegisterForm.schema'

// utils
// @ts-expect-error file not in typescript
import { errorCheck } from '../../../../../../utils/formUtils'
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../../actions'

// constants
import { DEFAULT_PHONE_CODE } from '../../../../../../constants/contactDetails'

// types
import { fieldsType } from '../../../../../../types/components/SupportStaffRegisterForm'
import { Store } from '../../../../../../types/store'

// styles
import styles from './supportStaffRegisterForm.styles'

type SupportStaffRegisterFormProps = {
  // To render all labels from sitecore content editor
  fields: fieldsType
  handleBack: (setShowRegisterForm: boolean) => void
}

const SupporStaffFormWrapper = styled('section')(styles.supporStaffFormWrapper)
const SupportStaffContainer = styled('section')(styles.supportStaffContainer)
const SupportStaffRegisterWrapper = styled('section')(styles.supportStaffRegisterWrapper)
const Footer = styled('footer')(styles.footer)
// @ts-expect-error file not in typescript
export const Fieldset = styled('div')(({ withoutFlex }) => styles.fieldset(withoutFlex)) // div because flex doesnot work with fieldset in Chorme
export const InputXsm = styled(Input)(({ withoutMargin, transparent }) =>
  styles.sectionXsm(withoutMargin, transparent)
)
export const SelectXsm = styled(Select)(({ withoutMargin }) => styles.sectionXsm(withoutMargin))
export const InputXl = styled(Input)(({ withoutMargin }) => styles.sectionXl(withoutMargin))
const InputFullWidth = styled(Input)(styles.fullWidth)
const RegistrationDescription = styled('p')(styles.registrationDescription)
const DeclarationCheckbox = styled(Checkbox)(styles.declarationCheckbox)
const SupportStaffRegisterDeclarationGroupWrapper = styled('section')(
  styles.supportStaffRegisterDeclarationGroupWrapper
)
const NavButton = styled(Button)(styles.nav)
const SubmitBtn = styled(Button)(styles.submitBtn)
const { IconChevronLeft16 } = Icons as { [key: string]: React.ReactNode }

export const emptyList = () => ''
export const emptyIndicator = () => null

const supportStaffRegisterForm = (props: SupportStaffRegisterFormProps) => {
  const { fields, handleBack } = props
  const { formInit, formUpdateField, formSubmit, formValidate } = actionCreators
  const dispatch = useDispatch()
  const form = useSelector((state: Store) => state.forms.supportStaffRegisterForm)
  const schema = SCHEMA(fields)
  const {
    contactNumberPhoneCode,
    contactNumber,
    dateOfBirth,
    email,
    title,
    firstName,
    lastName,
    isSupportStaffCheckboxSelected,
    loginId,
  } = form?.fields || {}

  useEffect(() => {
    const data = {
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      contactNumber: '',
      contactNumberPhoneCode: DEFAULT_PHONE_CODE,
      isSupportStaffCheckboxSelected: false,
      loginId: '',
    }
    if (!form) {
      dispatch(formInit(FORM_ID, SCHEMA(fields), data))
    }
  }, [])

  // handle changes on form elements.
  const handleChange = ({ value, name }: { value: string; name: SchemaNameType }) => {
    const data = {
      error: errorCheck(
        value,
        schema[name].condition,
        null,
        ['contactNumber', 'secondaryNumber'].includes(name) ? form.fields : {}
      ) as boolean,
      value,
    }
    dispatch(formUpdateField(FORM_ID, name, data))
    dispatch(formValidate(FORM_ID, schema))
  }

  const onSubmit = (): void => {
    const formValid = true

    if (formValid) {
      formSubmit(FORM_ID, schema)
    }
  }

  const handleSubmit = () => {
    // GA tags splunk observability
    const event = createEvent({
      GA: {
        category: 'Support staff',
        action: 'AP - Add a support staff submit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'AP - Add a support staff submit',
        },
      },
    })
    event.end()
    onSubmit()
  }

  const selectPrimaryPhoneCode = {
    label: fields.CountryCodeLabel,
    value: contactNumberPhoneCode?.value,
    name: 'contactNumberPhoneCode',
    id: 'contactNumberPhoneCode',
    changeHandler: handleChange,
  }
  const inputPrimaryPhoneNumber = {
    htmlFor: 'contactNumber',
    name: 'contactNumber',
    label: fields.MobilenumberLabel,
    changeHandler: handleChange,
    value: contactNumber?.value,
    error: contactNumber?.error?.error,
    caption: contactNumber?.error?.error && contactNumber?.error?.errorMsg,
    placeholder: fields.MobilenumberPlaceholder,
  }

  return (
    <SupportStaffRegisterWrapper>
      <NavButton data-testid="backButton" onClick={() => handleBack(false)} variant="tertiary">
        {/* @ts-expect-error non-TS-code  */}
        <IconChevronLeft16 />
        {fields.backButtonLabel}
      </NavButton>
      <Heading variant="h2" size="large" aria-label={fields.supportStaffRegisterFieldSetAreaLabel}>
        {fields.RegistrationHeading}
      </Heading>
      <RegistrationDescription>
        <RichText field={{ value: fields.RegistrationDescription }} />
      </RegistrationDescription>
      <SupportStaffContainer>
        <Heading variant="h2" size="medium">
          {fields.RegistrationSideHeading}
        </Heading>
        <SupporStaffFormWrapper role="form">
          <Fieldset role="group">
            <InputFullWidth
              htmlFor="loginId"
              name="loginId"
              label={fields.LoginIdLabel}
              changeHandler={handleChange}
              value={loginId?.value}
              error={loginId?.error?.error}
              caption={loginId?.error?.error && loginId?.error?.errorMsg}
              placeholder={fields.LoginIdPlaceholder}
            />
          </Fieldset>
          <Fieldset role="group">
            <SelectXsm
              withoutMargin
              label={fields.TitleLabel}
              name="title"
              id="Title"
              placeholder={fields.TitlePlaceholder}
              changeHandler={handleChange}
              options={schema.title?.options}
              value={title?.value}
              error={title?.error?.error}
              caption={title?.error?.error && fields.supportStaffRegisterTitleError}
            />
            <InputXl
              htmlFor="firstName"
              name="firstName"
              placeholder={fields.FirstnamePlaceholder}
              label={fields.FirstnameLabel}
              changeHandler={handleChange}
              value={firstName?.value}
              error={firstName?.error?.error}
              caption={firstName?.error?.error && firstName?.error?.errorMsg}
            />
          </Fieldset>
          <Fieldset role="group">
            <InputFullWidth
              htmlFor="lastName"
              name="lastName"
              label={fields.LastnameLabel}
              placeholder={fields.LastnamePlaceholder}
              changeHandler={handleChange}
              value={lastName?.value}
              error={lastName?.error?.error}
              caption={lastName?.error?.error && lastName?.error?.errorMsg}
            />
          </Fieldset>
          <Fieldset role="group">
            <InputFullWidth
              withoutMargin
              htmlFor="dateOfBirth"
              name="dateOfBirth"
              options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
              label={fields.DOBLabel}
              changeHandler={handleChange}
              placeholder={fields.supportStaffRegisterDateOfBirthPlaceholder}
              value={dateOfBirth?.value}
              error={dateOfBirth?.error?.error}
              caption={dateOfBirth?.error?.error && dateOfBirth?.error?.errorMsg}
            />
          </Fieldset>

          <Fieldset role="group">
            <InputPhone
              // @ts-expect-error suppress ts prop error
              selectPhoneCode={selectPrimaryPhoneCode}
              // @ts-expect-error suppress ts prop error
              inputPhoneNumber={inputPrimaryPhoneNumber}
            />
          </Fieldset>
          <Fieldset role="group">
            <InputFullWidth
              htmlFor="email"
              name="email"
              label={fields.EmailaddressLabel}
              changeHandler={handleChange}
              value={email?.value}
              error={email?.error?.error}
              caption={email?.error?.error && email?.error?.errorMsg}
              placeholder={fields.EmailaddressPlaceholder}
            />
          </Fieldset>
        </SupporStaffFormWrapper>
        <SupportStaffRegisterDeclarationGroupWrapper>
          <Heading variant="h3" size="medium">
            {fields.DeclarationsLabel}
          </Heading>
          <DeclarationCheckbox
            text={<RichText field={{ value: fields.DeclarationsContent }} />}
            name="isSupportStaffCheckboxSelected"
            htmlFor="isSupportStaffCheckboxSelected"
            onChangeHandler={handleChange}
            checked={isSupportStaffCheckboxSelected?.value}
          />
        </SupportStaffRegisterDeclarationGroupWrapper>
        <Footer>
          <SubmitBtn type="secondary" disabled={!form?.isValid} onClick={handleSubmit}>
            {fields.SubmitButtonLabel}
          </SubmitBtn>
        </Footer>
      </SupportStaffContainer>
    </SupportStaffRegisterWrapper>
  )
}

export default supportStaffRegisterForm
