// @flow
import React from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './card.styles'

const Element = styled('div')(styles.base)
const Card = props => <Element {...props} />

export default Card
