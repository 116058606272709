// @FIXME: need to determine how this is createBrowserHistory gets created
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-unresolved */
// @flow
/* eslint-disable import/no-extraneous-dependencies */
import { createBrowserHistory } from 'history'

let history = { location: { search: '', pathname: '' } }
if (typeof document !== 'undefined') {
  history = createBrowserHistory()
}
export default history
