// @flow
/* eslint-disable no-param-reassign */
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import throttle from 'lodash/throttle'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { Checkbox, Loader, PageWrap, SectionHeader, Button, Modal } from '@mlcl-digital/mlcl-design'
import ModalWithEmbed from '@mlcl-digital/mlcl-design/lib/base/ModalWithEmbed'

// constant
import isEmpty from 'lodash/isEmpty'
import { createEvent } from '../../../utils/telemetry'
import { NAVIGATION_APPLICATION_OVERVIEW } from '../../../constants/navigation'
import {
  APPLICATION_PATH,
  APPLICATION_URE_PAGE,
  APPLICATION_DETAILS_PATH,
  COMPLETE_APPLICATION,
  ADV_F2F,
  SAVE_DRAFT_APPLICATION,
  ADV_PHN,
  CHILD_CI,
  COMPLETE_MYLINK,
  COMPLETE_TELE,
  APPLICATION_STAGE_STEP,
} from '../../../constants/application'

// actions
import { actionCreators } from '../../../actions'

// components
import Header from '../../molecules/PageHeader'
import DownloadRecords from './components/DownloadRecords'
import ToggleGroup from '../../molecules/ToggleGroup'

// style
import styles from './fullUREResults.styles'

// util
import { isIPCover, isNonSuperProduct, createBenefitIdentifier } from '../../../utils/quoteUtils'

import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { generateCorrelationID, getIsUREHealthyLivingDiscount } from '../../../utils/commonUtils'
import {
  updateQuoteForTISO,
  updateQuoteForSGBO,
  removeFeatureInQuote,
  getSGBOCoverData,
} from '../../../utils/extendedQuoteUtils'
import { getUREStatus, getActiveBenefitList } from '../../../utils/ureUtils'
import { isFeatureEnabled } from '../../../utils/featureToggling'

import Tag from '../../atoms/Tag'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'
import {
  UNDERWRITING_CTA_TYPE,
  TERM_DECISON_MAP,
  CHILD_CI_DIGITAL_TYPE,
  TYPE_HIGH_LEVEL_REFERRED,
  TYPE_HIGH_LEVEL_ACCEPTED,
  CALCULATED_URE_TERM,
  CALCULATED_URE_REFERRED,
  CALCULATED_URE_ACCEPTED,
  HIGH_LEVEL_DECISON_BUCKET,
  FULL_URE_TOGGLE_CTA_OPTIONS,
  DECISON_TYPE_NON_STANDARD,
  DECISON_TYPE_EVIDENCE_REQUIRED,
  DECISON_TYPE_POSTPONE,
  DECISON_TYPE_UNABLE_TO_OFFER,
  DECISON_TYPE_DECLINE,
  DECISON_TYPE_REFER,
  UNDERWRITING_REASON_SEPARATOR,
} from '../../../constants/ure'
import { COVERS_NOT_FOR_UI } from '../../../constants/benefit'

import { getCollectionFrequency } from '../../../utils/paymentUtils'

// schema.
import { FORM_ID } from './fullUREResult.schema'
import {
  addOptimiserSuffixToBenefitName,
  isTdpOptimised,
  convertBenefitCodeToFullName,
} from '../../../utils/policyUtils'
import { getHealthyEligibleCovers } from '../../../selectors/common.selectors'
import { hasAdviserBeenPresentedLicopFlyer } from '../../../selectors/createQuote'
import WithLoader from '../../molecules/WithLoader'

// data locators

const MainWrapper = styled('div')(styles.wrapper)
const PolicyContainer = styled('div')(styles.policyContainer)
const PolicyHeader = styled('div')(styles.policyHeader)
const PolicyBody = styled('div')(styles.policyBody)
const PolicyNameDetail = styled('h3')(styles.policyNameDetail)
const PolicyPremiumDetail = styled('p')(styles.policyPremiumDetail)
const PolicyType = styled(Tag)(({ isPaddingRequired }) => styles.policyType(isPaddingRequired))

const CoverHeader = styled('div')(styles.coverHeader)
const CoverNameDetail = styled('div')(styles.coverNameDetail)
const CoverHighLevelResult = styled('p')(styles.coverHighLevelResult)

const DecisionList = styled('ul')(styles.decisionList)
const DecisionItem = styled('li')(styles.decisionItem)
const DecisionItemWithCheckbox = styled('div')(
  styles.decisionItemWithCheckbox,
  ({ isVivoIncentiveDiscountApplied }) =>
    isVivoIncentiveDiscountApplied && styles.decisionItemWithCheckboxChecked
)
const CheckBox = styled(Checkbox)(styles.customCheckBox)

const StickyWrapper = styled('div')(styles.stickyWrapper)
const StickyCTAs = styled('div')(({ isSticky }) => styles.stickyCTAs(isSticky))
const CTAList = styled('ul')(styles.CTAList)
const IndividualCTA = styled('li')(styles.individualCTA)
const ModalContent = styled('div')(styles.modalContent)
const SectionHeaderWrap = styled('div')(styles.sectionHeaderWrap)
const ChildName = styled('span')(styles.childName)
const OkButton = styled(Button)(styles.okButton)
const Wrap = styled(PageWrap)(styles.wrap)
const ContinueButton = styled(Button)(styles.continueButton)
const LicopContinueButton = styled(Button)(styles.licopContinueButton)

const HalfWidthToggleGroup = styled(ToggleGroup)(styles.halfWidth)
const DecisionListContainer = styled('div')(styles.decisionContainer)
const TagWrap = styled(Tag)(styles.tag)
const FlexRow = styled('div')(styles.flewRow)
const FeatureName = styled('div')(styles.featureName)

type FullUREResultsProps = {
  fields: Object,
  actions: {
    changeNavigationType: Function,
    fetchFullUREdata: Function,
    ureInitalData: Function,
    updateLoading: Function,
    updateApplicationStage: Function,
    createQuoteSetIsSatisfied: Function,
    retriggerFullUredata: Function,
    openUreErrorModal: Function,
  },
  createQuote: Object,
  ure: Object,
  fromApplicationReview: Boolean,
  // redux env constant config
  config: Object,
  // master list entity
  masterList: Object,
  // is true when saveQuote API is being called
  isQuoteLoading: Boolean,
  // is true when we have to show loader
  showLoader: Boolean,
  eligibleHealthyCovers: Array<string>,
  isUREHealthyLivingDiscount: Boolean,
  isAdviserLicopModalOpen: Boolean,
  // feature toggle for Download ROA text
  isDownloadROATextEnabled: Boolean,
}

export const ReferedDecison = (
  <PolicyType type="warning" isPaddingRequired>
    {TYPE_HIGH_LEVEL_REFERRED}
  </PolicyType>
)

export const AcceptedDecison = (
  <PolicyType type="successlimeshade" isPaddingRequired>
    {TYPE_HIGH_LEVEL_ACCEPTED}
  </PolicyType>
)

export class FullUREResults extends Component<FullUREResultsProps> {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleLicopModalContinueButtonClick = this.handleLicopModalContinueButtonClick.bind(this)
    this.state = {
      stickyCTAs: false,
      showUnderwriterModal: false,
      openSmokerStatusChangedModal: false,
      hasClickedContinueCTA: false,
      hasAdviserCompletedPS: false,
      isVivoIncentiveDiscountApplied: false,
    }
  }

  componentDidMount() {
    const {
      actions: { changeNavigationType, fetchFullUREdata, updateLoading },
      ure: {
        answers,
        activeEnquiryIds,
        initialData: { umeBenefits, umeExpiryPeriod },
      },
      fromApplicationReview,
      createQuote: { quotes, activeIndex, action },
    } = this.props

    const underwritingMethod = get(
      quotes,
      `[${activeIndex}]underwritingDetails.underwritingMethod`,
      ''
    )

    changeNavigationType(NAVIGATION_APPLICATION_OVERVIEW)

    const unifiedEnquiryIds = activeEnquiryIds.map(data => data.enquiryId)
    const enquiries = Object.keys(answers)
      .filter(answerId => unifiedEnquiryIds.includes(answerId))
      .map(answerId => ({
        ...answers[answerId],
        expiry: {
          noOfDays: umeExpiryPeriod,
          createdOn: get(
            activeEnquiryIds.find(enquiry => enquiry.enquiryId === answerId),
            'createdOn'
          ),
        },
      }))
    const isSatisfied = get(quotes, `[${activeIndex}]underwritingDetails.isSatisfied`, '')
    if (
      !fromApplicationReview &&
      action !== COMPLETE_MYLINK &&
      action !== COMPLETE_TELE &&
      action !== COMPLETE_APPLICATION &&
      !isSatisfied
    ) {
      updateLoading([])
      fetchFullUREdata({
        enquiries,
        umeBenefits,
      })
    }

    this.footerElement = document.querySelector('footer')
    window.addEventListener('scroll', throttle(this.handleScroll, 100))
    if (
      (underwritingMethod === ADV_F2F || underwritingMethod === ADV_PHN) &&
      action === SAVE_DRAFT_APPLICATION
    ) {
      this.setState(() => ({
        hasAdviserCompletedPS: true,
      }))
    }
    const tagEvent = createEvent({
      GA: {
        category: 'URE decisions on adviser portal',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'URE decisions on adviser portal - View',
        },
      },
    })
    tagEvent.end()
  }

  componentDidUpdate(prevProps) {
    const {
      ure: { isLoading: prevIsloading },
    } = prevProps
    const {
      ure: { isLoading, fullUREresult },
      createQuote,
      actions: { openUreErrorModal },
    } = this.props

    if (prevIsloading && !isLoading) {
      this.saveLoadingsNExclusions()
    }
    this.handleScroll()

    // if calculate ure status not present in refetching full ure, show error
    if (
      prevIsloading &&
      isLoading &&
      get(fullUREresult, 'metaData.isRefetchURE') &&
      !fullUREresult.calculatedUREstatus
    ) {
      openUreErrorModal()
    }
    // if refetch ure was done, on success of save quote, redirect user
    if (
      prevProps.createQuote.isFetchingData &&
      !createQuote.isFetchingData &&
      get(fullUREresult, 'metaData.isRefetchURE')
    ) {
      if (createQuote.isSaveQuoteErrorModal) {
        openUreErrorModal()
      } else {
        history.push(this.redirectToUrl)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getConsolidatedFullUreResults() {
    const {
      ure: { fullUREresult },
    } = this.props

    if (fullUREresult && fullUREresult.results && fullUREresult.results.length) {
      const { results } = fullUREresult
      return cloneDeep(results).reduce((acc, result) => {
        const { umeBenefits, enquiryId } = result
        const transformedUmeBenefit = [...umeBenefits]
        transformedUmeBenefit.forEach(umeBenefit => {
          umeBenefit.enquiryId = enquiryId
          umeBenefit.termDecision = umeBenefit.cta
            ? ''
            : TERM_DECISON_MAP[umeBenefit.highLevelResults.decisionValue]
          let { umeCode } = umeBenefit
          const { waitingPeriod } = umeBenefit
          // eslint-disable-next-line prefer-destructuring
          if (umeCode.toLowerCase() !== CHILD_CI_DIGITAL_TYPE) umeCode = umeCode.split('_')[0]

          if (umeBenefit.loadings && umeBenefit.loadings.length) {
            // eslint-disable-next-line no-return-assign
            umeBenefit.loadings.map(loading => (loading.benefit = umeCode))
          } else {
            umeBenefit.loadings = []
          }

          if (umeBenefit.exclusion && umeBenefit.exclusion.length) {
            // eslint-disable-next-line no-return-assign
            umeBenefit.exclusion.map(exc => {
              exc.benefit = umeCode
              if (waitingPeriod) exc.waitingPeriod = waitingPeriod
              return exc
            })
          } else {
            umeBenefit.exclusion = []
          }
        })
        return [...acc, ...transformedUmeBenefit]
      }, [])
    }
    return []
  }

  // eslint-disable-next-line react/sort-comp
  backClickHandler = () => {
    // change personal statement application status to partially complete
    // and quote as unsatisfied to avoid incomplete application submit

    const {
      actions: {
        updateApplicationStage,
        createQuoteSetIsSatisfied,
        createQuoteIsTermsAcceptedCover,
      },
      createQuote: { activeIndex, quotes },
    } = this.props

    const activeQuote = quotes[activeIndex]

    activeQuote.policyStructure.forEach((policy, policyIndex) => {
      policy.covers.forEach((cover, coverIndex) => {
        createQuoteIsTermsAcceptedCover(undefined, policyIndex, coverIndex)
      })
    })

    createQuoteSetIsSatisfied(false)

    updateApplicationStage(2, {
      stage2: APPLICATION_STAGE_STEP.partiallyComplete,
    })

    const { hasAdviserCompletedPS } = this.state
    if (hasAdviserCompletedPS) {
      history.push(APPLICATION_URE_PAGE)
    } else {
      history.push(APPLICATION_DETAILS_PATH)
    }
  }

  ctaClickHandler = type => {
    const path = APPLICATION_PATH
    const { showUnderwriterModal } = this.state
    if (type && type === UNDERWRITING_CTA_TYPE) {
      this.setState({ showUnderwriterModal: true })
      return
    }
    if (showUnderwriterModal) this.setState({ showUnderwriterModal: false })
    history.push(path)
  }

  closeUnderwriterModal = () => {
    this.setState({ showUnderwriterModal: false })
  }

  getUnderwriterEntityData() {
    const {
      ure: {
        fullUREresult,
        activeEnquiryIds,
        initialData: { umeBenefits },
      },
      createQuote: { quotes, activeIndex },
    } = this.props
    const { results, calculatedUREstatus } = fullUREresult
    return [...results].map(result => {
      const { enquiryId, isOpen, isSatisfied, isVoid } = result
      const isChildCI =
        activeEnquiryIds[0] &&
        activeEnquiryIds.find(enquiry => enquiry.enquiryId === enquiryId).isChildCI

      return {
        isChildCI,
        enquiryId,
        underwritingData: {
          calculatedUREStatus: calculatedUREstatus,
          isOpen,
          isSatisfied,
          isVoid,
          status: getUREStatus(
            enquiryId,
            getActiveBenefitList(quotes[activeIndex]),
            isChildCI,
            fullUREresult,
            umeBenefits
          ),
          isGeneticTest: get(fullUREresult, 'geneticTest', false),
        },
      }
    })
  }

  saveLoadingsNExclusions() {
    const {
      actions: {
        updateLoading,
        createQuoteCalculate,
        removeAllExclusionsInQuoteNApplyFromURE,
        createQuoteApplyGeneticTestResult,
        updateUREDetailToUnderwriterEntity,
        createQuoteUpdateIsNonStandard,
        createQuoteUpdateBMI,
        createQuoteUpdateOption,
        createQuoteUpdateCoversCampaign,
      },
      ure,
      eligibleHealthyCovers,
    } = this.props
    const consolidatedFullUreResults = this.getConsolidatedFullUreResults()

    const geneticTest = get(ure, 'fullUREresult.geneticTest', false)

    const calculatedUREstatus = get(ure, 'fullUREresult.calculatedUREstatus', '')

    this.setState({
      openSmokerStatusChangedModal: get(ure, 'fullUREresult.changeInSmokerStatus', false),
    })
    updateUREDetailToUnderwriterEntity(this.getUnderwriterEntityData())
    const loadingsList =
      consolidatedFullUreResults.length &&
      consolidatedFullUreResults.reduce((acc, ureItem) => [...acc, ...ureItem.loadings], [])
    const exlcusionList =
      consolidatedFullUreResults.length &&
      consolidatedFullUreResults.reduce((acc, ureItem) => [...acc, ...ureItem.exclusion], [])
    createQuoteApplyGeneticTestResult(geneticTest)
    if (loadingsList && loadingsList.length) updateLoading(loadingsList)
    if (exlcusionList) removeAllExclusionsInQuoteNApplyFromURE(exlcusionList)
    if (get(ure, 'fullUREresult.calculatedUREstatus', '') === CALCULATED_URE_TERM) {
      createQuoteUpdateIsNonStandard(true)
    } else {
      createQuoteUpdateIsNonStandard(false)
    }
    if (get(ure, 'fullUREresult.bmi', '')) {
      createQuoteUpdateBMI(ure.fullUREresult.bmi)
      // updating option in store based on BMI value
      createQuoteUpdateOption({
        healthyLivingDiscount: getIsUREHealthyLivingDiscount(ure),
      })

      // adding/removing campaign in covers based on BMI value
      createQuoteUpdateCoversCampaign({
        healthyLivingDiscount: getIsUREHealthyLivingDiscount(ure),
        eligibleHealthyCovers,
      })
    }

    // calculate URE outcome
    const tagEvent = createEvent({
      GA: {
        category: 'URE decisions on adviser portal',
        action: 'Submit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'URE decisions on adviser portal - Submit',
        },
      },
    })
    tagEvent.end()
    if (calculatedUREstatus) {
      createQuoteCalculate({
        initiateSaveQuote: true,
      })
    }
  }

  handleScroll() {
    const { stickyCTAs } = this.state
    if (
      this.footerElement &&
      this.footerElement.offsetTop < window.pageYOffset + window.innerHeight
    ) {
      // eslint-disable-next-line no-unused-expressions
      stickyCTAs &&
        this.setState({
          stickyCTAs: false,
        })
    } else {
      // eslint-disable-next-line no-unused-expressions
      !stickyCTAs &&
        this.setState({
          stickyCTAs: true,
        })
    }
  }

  // eslint-disable-next-line react/sort-comp
  renderPolicyHeader(policy) {
    const {
      fields: {
        underwritingResultsInsideSuperTypeText,
        underwritingResultsOutsideSuperTypeText,
        underwritingResultsMonthlyPremiumLabel,
      },
    } = this.props
    const { productId, productName, totalPremiumAmount, paymentFrequency } = policy
    const isInsideSuper = !isNonSuperProduct(productId)
    return (
      <PolicyHeader>
        <PolicyNameDetail>
          {productName}
          {isInsideSuper ? (
            <PolicyType type="primary">
              {renderTextField(underwritingResultsInsideSuperTypeText)}
            </PolicyType>
          ) : (
            <PolicyType type="danger">
              {renderTextField(underwritingResultsOutsideSuperTypeText)}
            </PolicyType>
          )}
        </PolicyNameDetail>
        <PolicyPremiumDetail>
          {underwritingResultsMonthlyPremiumLabel.value.replace(
            '$0',
            getCollectionFrequency(paymentFrequency).toLowerCase()
          )}
          <span>{` $${Number(totalPremiumAmount).toFixed(2).toLocaleString()}`}</span>
        </PolicyPremiumDetail>
      </PolicyHeader>
    )
  }

  renderUmeBenefitsTag(highLevelResults, isTermsAccepted) {
    if (isTermsAccepted === undefined) {
      if (highLevelResults === TYPE_HIGH_LEVEL_REFERRED) {
        return ReferedDecison
      }
      if (highLevelResults === TYPE_HIGH_LEVEL_ACCEPTED) {
        return AcceptedDecison
      }
      return ReferedDecison
    }

    if (this.getBoolean(isTermsAccepted) === true) {
      return AcceptedDecison
    }
    return ReferedDecison
  }

  handleTermsSelection = (target, policyIndex, coverIndex) => {
    const {
      actions: { createQuoteIsTermsAcceptedCover },
    } = this.props
    createQuoteIsTermsAcceptedCover(target.value, policyIndex, coverIndex)
  }

  getBoolean = value => {
    if (value === 'true' || value === true) {
      return true
    }
    if (value === 'false' || value === false) {
      return false
    }
    return value
  }

  renderTermsCTAs(policyIndex, cover, coverIndex, ctaOptions) {
    const { hasClickedContinueCTA } = this.state
    const currentTermsCTA = `termsCTA-policy-${policyIndex}-cover-${coverIndex}`
    let currentValue = get(cover, 'isTermsAccepted', '')

    if (currentValue) {
      currentValue = FULL_URE_TOGGLE_CTA_OPTIONS.find(
        option => this.getBoolean(option.value) === this.getBoolean(currentValue)
      ).value
    }

    const hasError = hasClickedContinueCTA && !currentValue
    const {
      fields: { underwritingResultsTermSelectErrorMessage },
    } = this.props

    return (
      <HalfWidthToggleGroup
        htmlFor={currentTermsCTA}
        name={currentTermsCTA}
        handleChange={e => this.handleTermsSelection(e, policyIndex, coverIndex)}
        value={currentValue}
        options={ctaOptions}
        labelSize="sm"
        variant="tab"
        error={hasError}
        caption={hasError && renderTextField(underwritingResultsTermSelectErrorMessage)}
        className="termsCTA"
      />
    )
  }

  getHighLevelResults = decision => {
    let highLevelResult = TYPE_HIGH_LEVEL_REFERRED
    if (decision) {
      highLevelResult = HIGH_LEVEL_DECISON_BUCKET[decision]
    }
    return highLevelResult
  }

  getUnderWritingResults = cover => {
    const { type } = cover
    const {
      createQuote: { activeIndex, quotes },
    } = this.props
    let savedResults = []
    if (type.toLowerCase() === CHILD_CI) {
      savedResults = get(cover, 'childCIUnderwritingInfo.status', [])
    } else {
      savedResults = get(quotes, `[${activeIndex}].underwritingDetails.status`, [])
    }
    return savedResults
  }

  getUnderWritingReasons = (ureResults, id, isChildCI) => {
    const resultData = isChildCI
      ? [ureResults[0]]
      : [ureResults.find(result => result.benefitIdentifier === id)]
    const explanationsReturn = []
    resultData.forEach(result => {
      if (
        result &&
        result.underwritingDecisionExplanation &&
        result.underwritingDecisionExplanation !== 'Non Standard' &&
        result.underwritingDecisionExplanation.length > 20
      ) {
        result.underwritingDecisionExplanation
          .split(UNDERWRITING_REASON_SEPARATOR)
          .forEach(word => explanationsReturn.push(word))
      }
    })

    return explanationsReturn
  }

  getHighLevelDecision = (ureResults, id, isChildCI) => {
    const resultData = isChildCI
      ? ureResults[0]
      : ureResults.find(result => result.benefitIdentifier === id)
    if (resultData && resultData.underwritingDecision) {
      return resultData.underwritingDecision
    }
    return ''
  }

  makeLinkedBenefitLabel = (benefitReference: Object) => {
    const {
      masterList: { data: masterData },
    } = this.props
    const { parentType, parentBenefitInstanceNo, parentPolicyReferenceNo } = benefitReference
    return `${convertBenefitCodeToFullName(
      masterData,
      parentType
    )} ${parentBenefitInstanceNo} Policy ${parentPolicyReferenceNo}`
  }

  getLinkedBenefitLabel = (cover: Object) => {
    const optimiserParentBenefitReference = get(cover, 'optimiserParentBenefitReference', false)
    if (optimiserParentBenefitReference)
      return this.makeLinkedBenefitLabel(optimiserParentBenefitReference)

    const parentBenefitReference = get(cover, 'parentBenefitReference', false)
    if (parentBenefitReference) {
      return this.makeLinkedBenefitLabel(parentBenefitReference)
    }
    return null
  }

  handleVivoIncentiveDiscountResults = () => {
    const { isVivoIncentiveDiscountApplied } = this.state
    this.setState({ isVivoIncentiveDiscountApplied: !isVivoIncentiveDiscountApplied })
    // GA event fire only for true condition
    if (!isVivoIncentiveDiscountApplied) {
      const tagEvent = createEvent({
        GA: {
          category: 'Healthy Lives Discount unavailable',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Healthy Lives Discount unavailable',
          },
        },
      })
      tagEvent.end()
    }
  }

  renderPolicyCovers(policy, policyKey, enquiryId, quoteIndex, policyIndex) {
    const {
      createQuote: { activeIndex, quotes },
      fromApplicationReview,
      masterList,
    } = this.props

    const calculatedUREStatus = get(
      quotes,
      `[${activeIndex}].underwritingDetails.calculatedUREStatus`
    )

    if (policy.covers.length) {
      return (
        <PolicyBody>
          {policy.covers.map((cover, coverIndex) => {
            const {
              name,
              premiumStyle,
              type,
              isTermsAccepted,
              tpdDefinition,
              coverStyle,
              benefitInstanceNo,
            } = cover
            const benefitIdentifier = createBenefitIdentifier(
              policy.productName,
              type,
              benefitInstanceNo
            )
            const isChildCI = type.toLowerCase() === CHILD_CI

            const ureResults = this.getUnderWritingResults(cover)
            const highLevelDecision = this.getHighLevelDecision(
              ureResults,
              benefitIdentifier,
              isChildCI
            )
            const underWritingReasons = this.getUnderWritingReasons(
              ureResults,
              benefitIdentifier,
              isChildCI
            )
            const highLevelResults = this.getHighLevelResults(highLevelDecision)
            const childFirstName = get(cover, 'benefitChildCI.firstName')

            const ctaOptions =
              highLevelDecision === DECISON_TYPE_NON_STANDARD && FULL_URE_TOGGLE_CTA_OPTIONS

            const renderTdpOptimiserLabel = () => (
              <FlexRow>
                <span>{addOptimiserSuffixToBenefitName(cover)}</span>
                <Fragment>
                  <TagWrap type="primary">Linked to</TagWrap>
                  <div>
                    <div>{isTdpOptimised(cover, masterList.data)}</div>
                  </div>
                </Fragment>
              </FlexRow>
            )
            const benefitNameWithTpdDefinition =
              tpdDefinition || coverStyle ? `${name} (${tpdDefinition || coverStyle})` : name
            const relatedSGBOBenefit = isIPCover(cover)
              ? getSGBOCoverData(quotes[activeIndex], policyIndex, cover, false)
              : {}

            const linkedBenefitLabel = this.getLinkedBenefitLabel(cover)
            const renderBenefitName = () => (
              <FlexRow>
                {<span>{benefitNameWithTpdDefinition}</span>}
                {!isEmpty(relatedSGBOBenefit) && (
                  <FeatureName>{relatedSGBOBenefit.name}</FeatureName>
                )}
                {linkedBenefitLabel && (
                  <Fragment>
                    <TagWrap type="primary">Linked to</TagWrap>
                    <div>
                      <div>{this.getLinkedBenefitLabel(cover)}</div>
                    </div>
                  </Fragment>
                )}
              </FlexRow>
            )
            if (!COVERS_NOT_FOR_UI.includes(type)) {
              return (
                <Fragment key={kebabCase(`${name} ${premiumStyle}${coverIndex}`)}>
                  <CoverHeader>
                    <CoverNameDetail>
                      {cover.optimiserParentBenefitReference
                        ? renderTdpOptimiserLabel()
                        : renderBenefitName()}
                      {type.toLowerCase() === CHILD_CI && childFirstName && (
                        <ChildName>
                          {childFirstName} {get(cover, 'benefitChildCI.lastName')}
                        </ChildName>
                      )}
                    </CoverNameDetail>
                    <CoverHighLevelResult>
                      {this.renderUmeBenefitsTag(highLevelResults, isTermsAccepted)}
                    </CoverHighLevelResult>
                  </CoverHeader>

                  <DecisionListContainer>
                    <DecisionList>
                      {underWritingReasons &&
                        underWritingReasons.map(decision => (
                          <DecisionItem key={kebabCase(decision)}>{decision}</DecisionItem>
                        ))}
                    </DecisionList>
                    {!fromApplicationReview &&
                    calculatedUREStatus === CALCULATED_URE_TERM &&
                    underWritingReasons.length > 0 &&
                    ctaOptions
                      ? this.renderTermsCTAs(
                          policyIndex,
                          cover,
                          coverIndex,
                          ctaOptions,
                          isTermsAccepted
                        )
                      : null}
                  </DecisionListContainer>
                </Fragment>
              )
            }
            return null
          })}
        </PolicyBody>
      )
    }
    return null
  }

  renderResults() {
    const {
      createQuote: { quotes, activeIndex },
      fromApplicationReview,
      fields: {
        HealthyLivingDiscountResultsHeading,
        HealthyLivingDiscountResultsName,
        HealthyLivingDiscountResultsAcceptedStatus,
        HealthyLivingDiscountResultsAcceptedDescription,
        HealthyLivingDiscountResultsDeclinedStatus,
        HealthyLivingDiscountResultsDeclinedDescription,
        HealthyLivingVivoIncentiveDiscountResultsDescription,
        HealthyLivingVivoIncentiveDiscountResultsName,
      },
      isUREHealthyLivingDiscount,
    } = this.props
    const { isVivoIncentiveDiscountApplied } = this.state
    const isHealthyLivingDiscountApplied = get(
      quotes,
      `[${activeIndex}].option.healthyLivingDiscount`,
      false
    )
    return (
      <MainWrapper>
        <form id={FORM_ID} aria-labelledby="full-ure-result">
          {quotes.map((quote, quoteIndex) =>
            quote.policyStructure.map((policy, policyIndex) => {
              const enquiryId = get(quote, 'underwritingDetails.enquiryId', '')
              if (activeIndex === quoteIndex) {
                return (
                  <PolicyContainer key={kebabCase(`${policy.productName}${policyIndex}`)}>
                    {this.renderPolicyHeader(policy)}

                    {this.renderPolicyCovers(
                      policy,
                      kebabCase(`${policy.productName}${policyIndex}`),
                      enquiryId,
                      quoteIndex,
                      policyIndex
                    )}
                  </PolicyContainer>
                )
              }
              return null
            })
          )}
        </form>
        {fromApplicationReview && (
          <PolicyContainer>
            <PolicyHeader>
              <PolicyNameDetail>
                {renderTextField(HealthyLivingDiscountResultsHeading)}
              </PolicyNameDetail>
            </PolicyHeader>
            <PolicyBody>
              <CoverHeader>
                <CoverNameDetail>
                  <FlexRow>{renderTextField(HealthyLivingDiscountResultsName)}</FlexRow>
                  <FlexRow />
                </CoverNameDetail>
                <CoverHighLevelResult>
                  <PolicyType
                    type={isHealthyLivingDiscountApplied ? 'successlimeshade' : 'removed'}
                    isPaddingRequired
                  >
                    {renderTextField(
                      isHealthyLivingDiscountApplied
                        ? HealthyLivingDiscountResultsAcceptedStatus
                        : HealthyLivingDiscountResultsDeclinedStatus
                    )}
                  </PolicyType>
                </CoverHighLevelResult>
              </CoverHeader>
              <DecisionListContainer>
                <DecisionList>
                  <DecisionItem>
                    {renderTextField(
                      isHealthyLivingDiscountApplied
                        ? HealthyLivingDiscountResultsAcceptedDescription
                        : HealthyLivingDiscountResultsDeclinedDescription
                    )}
                  </DecisionItem>
                </DecisionList>
              </DecisionListContainer>
            </PolicyBody>
          </PolicyContainer>
        )}
        {!fromApplicationReview && (
          <PolicyContainer>
            <PolicyHeader>
              <PolicyNameDetail>
                {renderTextField(HealthyLivingDiscountResultsHeading)}
              </PolicyNameDetail>
            </PolicyHeader>
            <PolicyBody>
              <CoverHeader>
                <CoverNameDetail>
                  <FlexRow>
                    {renderTextField(HealthyLivingVivoIncentiveDiscountResultsName)}
                  </FlexRow>
                  <FlexRow />
                </CoverNameDetail>
                <CoverHighLevelResult>
                  <PolicyType
                    type={isUREHealthyLivingDiscount ? 'successlimeshade' : 'removed'}
                    isPaddingRequired
                  >
                    {renderTextField(
                      isUREHealthyLivingDiscount
                        ? HealthyLivingDiscountResultsAcceptedStatus
                        : HealthyLivingDiscountResultsDeclinedStatus
                    )}
                  </PolicyType>
                </CoverHighLevelResult>
              </CoverHeader>
              <DecisionListContainer>
                <DecisionList>
                  <DecisionItem>
                    {renderTextField(
                      isUREHealthyLivingDiscount
                        ? HealthyLivingDiscountResultsAcceptedDescription
                        : HealthyLivingDiscountResultsDeclinedDescription
                    )}
                  </DecisionItem>
                </DecisionList>
              </DecisionListContainer>
              {!isUREHealthyLivingDiscount && (
                <DecisionItemWithCheckbox
                  isVivoIncentiveDiscountApplied={isVivoIncentiveDiscountApplied}
                >
                  <CheckBox
                    variant="normal"
                    htmlFor="customerRequiresTele"
                    text={renderTextField(HealthyLivingVivoIncentiveDiscountResultsDescription)}
                    name="customerRequiresTele"
                    onChangeHandler={() => this.handleVivoIncentiveDiscountResults()}
                    checked={isVivoIncentiveDiscountApplied}
                  />
                </DecisionItemWithCheckbox>
              )}
            </PolicyBody>
          </PolicyContainer>
        )}
      </MainWrapper>
    )
  }

  getToggleCTANumber = () => document.querySelectorAll('.termsCTA').length

  getSelectedToogleCTA = () => {
    const selectedDecison = []
    const {
      createQuote: { activeIndex, quotes },
    } = this.props

    quotes[activeIndex].policyStructure.forEach(policy => {
      policy.covers.forEach(cover => {
        if (cover.isTermsAccepted !== undefined) {
          selectedDecison.push(this.getBoolean(cover.isTermsAccepted))
        }
      })
    })

    return selectedDecison
  }

  bottomCtaClickHandler = () => {
    const {
      createQuote: { activeIndex, quotes },
      actions: {
        saveQuote,
        updateCalculateUreStatus,
        createQuoteUpdateIsNonStandard,
        retriggerFullUredata,
        setHealthyLivingDiscountConsent,
      },
    } = this.props
    const { hasAdviserCompletedPS } = this.state
    const underwritingDetails = get(quotes, `[${activeIndex}].underwritingDetails`, {})
    const { calculatedUREStatus, status } = underwritingDetails

    // adding healthy living discount consent in quotes based on BMI value
    setHealthyLivingDiscountConsent(true)

    let path = APPLICATION_DETAILS_PATH
    if (hasAdviserCompletedPS) {
      path = APPLICATION_PATH
    }

    if (calculatedUREStatus === CALCULATED_URE_TERM) {
      let updatedCalculatedUREstatus = CALCULATED_URE_REFERRED
      this.setState(() => ({
        hasClickedContinueCTA: true,
      }))

      const selectedCTAdecison = this.getSelectedToogleCTA()

      if (this.getToggleCTANumber() === selectedCTAdecison.length) {
        const doesHighLevelDecisionHasReferred = status.some(
          ({ underwritingDecision }) =>
            underwritingDecision === DECISON_TYPE_EVIDENCE_REQUIRED ||
            underwritingDecision === DECISON_TYPE_POSTPONE ||
            underwritingDecision === DECISON_TYPE_UNABLE_TO_OFFER ||
            underwritingDecision === DECISON_TYPE_DECLINE ||
            underwritingDecision === DECISON_TYPE_REFER
        )

        const doesTermshasRefer = selectedCTAdecison.includes(false)

        if (!doesHighLevelDecisionHasReferred && !doesTermshasRefer) {
          updatedCalculatedUREstatus = CALCULATED_URE_ACCEPTED
        }

        const tagEvent = createEvent({
          GA: {
            category: 'URE decisions on adviser portal',
            action: updatedCalculatedUREstatus,
          },
          Splunk: {
            attributes: {
              'workflow.name': 'URE decisions on adviser portal',
              status: updatedCalculatedUREstatus,
            },
          },
        })
        tagEvent.end()
        createQuoteUpdateIsNonStandard(false)
        updateCalculateUreStatus(updatedCalculatedUREstatus)

        saveQuote(() => {
          history.push(path)
        })
      }
    } else if (!calculatedUREStatus) {
      retriggerFullUredata()
    } else {
      saveQuote(() => {
        history.push(path)
      })
    }
  }

  renderBottomCTA() {
    const { stickyCTAs, hasAdviserCompletedPS } = this.state
    const {
      ure: { fullUREresult },
      fields: { underwritingResultsContinueButtonText, underwritingResultsSubmitButtonText },
      isUREHealthyLivingDiscount,
    } = this.props

    const { isVivoIncentiveDiscountApplied } = this.state

    let label = renderTextField(underwritingResultsSubmitButtonText)

    if (hasAdviserCompletedPS) {
      label = renderTextField(underwritingResultsContinueButtonText)
    }

    if (fullUREresult) {
      return (
        <StickyWrapper>
          <StickyCTAs id="stickyCTAs" isSticky={stickyCTAs}>
            <PageWrap>
              <CTAList>
                <IndividualCTA>
                  <ContinueButton
                    disabled={!isVivoIncentiveDiscountApplied && !isUREHealthyLivingDiscount}
                    onClick={() => this.bottomCtaClickHandler()}
                    type="primary"
                  >
                    {label}
                  </ContinueButton>
                </IndividualCTA>
              </CTAList>
            </PageWrap>
          </StickyCTAs>
        </StickyWrapper>
      )
    }
    return null
  }

  renderUnderwriterModal() {
    const { showUnderwriterModal } = this.state
    const {
      fields: {
        UnderwriterReviewModalHeading,
        UnderwriterReviewModalContent,
        UnderwriterReviewModalButtonText,
      },
    } = this.props
    return (
      <Modal
        isOpen={showUnderwriterModal}
        onClose={this.closeUnderwriterModal}
        title={renderTextField(UnderwriterReviewModalHeading)}
      >
        <ModalContent>{renderTextField(UnderwriterReviewModalContent)}</ModalContent>
        <Button type="secondary" onClick={this.ctaClickHandler}>
          {renderTextField(UnderwriterReviewModalButtonText)}
        </Button>
      </Modal>
    )
  }

  handleSmokerChangeModalClose() {
    this.setState(
      {
        openSmokerStatusChangedModal: false,
      },
      () => {
        this.backClickHandler()
      }
    )
  }

  handleSmokerChangeModalAccept() {
    const {
      ure,
      actions: { createQuoteChangeSmokerStatus, createQuoteCalculate, confirmSmokerTerms },
    } = this.props
    const changeInSmokerStatus = get(ure, 'fullUREresult.changeInSmokerStatus', false)
    const smokerStatusInURE = get(ure, 'fullUREresult.smokerStatusInURE', false)
    this.setState(
      {
        openSmokerStatusChangedModal: false,
      },
      () => {
        if (changeInSmokerStatus) {
          confirmSmokerTerms()
          createQuoteChangeSmokerStatus(smokerStatusInURE)
          createQuoteCalculate()
        }
      }
    )
  }

  handleLicopModalContinueButtonClick() {
    const {
      actions: { setAdviserPresentedLicopFlyerConsent, saveQuote },
    } = this.props
    setAdviserPresentedLicopFlyerConsent()
    saveQuote()
  }

  onCloseErrorModal = () => {
    const {
      actions: { closeUreErrorModal, resetFullUreResultData },
    } = this.props
    resetFullUreResultData()
    closeUreErrorModal()
    this.backClickHandler()
  }

  handleDownloadClick = () => {
    const { createQuote, config } = this.props
    const { quotes, activeIndex } = createQuote
    const enquiryId =
      quotes[activeIndex] && quotes[activeIndex].underwritingDetails
        ? get(quotes[activeIndex], 'underwritingDetails.enquiryId', {})
        : {}

    const updatedQuoteCollection = {
      ...createQuote,
      quotes: quotes.map(quote => {
        let updatedQuote = updateQuoteForTISO(quote)
        updatedQuote = updateQuoteForSGBO(updatedQuote)
        updatedQuote = removeFeatureInQuote(updatedQuote)
        return updatedQuote
      }),
    }
    const requestPayload = {
      docType: 'RECORD_ANSWERS',
      quoteCollection: { ...updatedQuoteCollection },
      correlationID: generateCorrelationID(),
      enquiryId,
      returnJson: 'N',
    }
    downloadDocument(requestPayload, null, null, config)
  }

  render() {
    const {
      fields,
      ure: { isLoading, errorModal, agreedOnSmokerTerms },
      fromApplicationReview,
      isQuoteLoading,
      showLoader,
      isAdviserLicopModalOpen,
      isDownloadROATextEnabled,
    } = this.props

    const { hasAdviserCompletedPS, openSmokerStatusChangedModal } = this.state

    const {
      underwritingResultsBackButton,
      underwritingResultsPageTitle,
      underwritingResultsPageSubHeading,
      fullUREResultSectionHeaderText,
      underwriterReviewErrorModalTitle,
      underwriterLicopModalInformation,
      underwriterLicopModalHeading,
      underwriterLicopModalDescription,
      underwriterLicopModalDownloadPdfButtonText,
      underwriterLicopModalContinueButtonText,
      underwriterReviewErrorModalDescription,
      underwriterReviewChangeSmokerStatusModalTitle,
      underwriterReviewChangeSmokerStatusModalMessage,
      underwriterReviewChangeSmokerStatusModalConfirmButtonText,
      underwriterLicopModalPdfPath,
    } = fields

    return (
      <WithLoader
        isLoading={isLoading || showLoader || isQuoteLoading}
        overlay
        isOverlayComponent
        loaderProps={{
          spinnerSize: 25,
        }}
      >
        <Fragment>
          {fromApplicationReview ? (
            <SectionHeaderWrap>
              <SectionHeader heading={renderTextField(fullUREResultSectionHeaderText)} />
            </SectionHeaderWrap>
          ) : (
            <Header
              backClickHandler={this.backClickHandler}
              heading={underwritingResultsPageTitle}
              subHeading={underwritingResultsPageSubHeading}
              showBackIcon
              iconText={underwritingResultsBackButton}
              isBackButtonDisabled={isQuoteLoading}
            >
              {hasAdviserCompletedPS && (
                <DownloadRecords
                  className="display-none lg-display-block"
                  fields={fields}
                  onClick={this.handleDownloadClick}
                  isDownloadROATextEnabled={isDownloadROATextEnabled}
                />
              )}
            </Header>
          )}
          <Fragment>
            <Wrap className={fromApplicationReview ? 'applicationView' : ''}>
              {this.renderResults()}
            </Wrap>
            {this.renderUnderwriterModal()}
            {fromApplicationReview ? '' : this.renderBottomCTA()}
          </Fragment>
          <Modal
            onClose={this.onCloseErrorModal}
            showCloseButton
            title={underwriterReviewErrorModalTitle.value}
            shouldFocusCloseButton
            enableScroll={false}
            isOpen={errorModal.isOpen}
          >
            <p>{underwriterReviewErrorModalDescription.value}</p>
          </Modal>
          <Modal
            onClose={() => this.handleSmokerChangeModalClose()}
            showCloseButton
            title={renderTextField(underwriterReviewChangeSmokerStatusModalTitle)}
            shouldFocusCloseButton
            enableScroll={false}
            isOpen={!agreedOnSmokerTerms && openSmokerStatusChangedModal}
          >
            <p>{renderTextField(underwriterReviewChangeSmokerStatusModalMessage)}</p>
            <OkButton type="secondary" onClick={() => this.handleSmokerChangeModalAccept()}>
              {renderTextField(underwriterReviewChangeSmokerStatusModalConfirmButtonText)}
            </OkButton>
          </Modal>
          <ModalWithEmbed
            enableScroll={false}
            showCloseButton={false}
            isOpen={isAdviserLicopModalOpen}
            footer={
              <>
                <Button
                  as="a"
                  variant="secondaryWithTheme"
                  href={underwriterLicopModalPdfPath?.value}
                  download
                >
                  {renderTextField(underwriterLicopModalDownloadPdfButtonText)}
                </Button>
                <LicopContinueButton onClick={this.handleLicopModalContinueButtonClick}>
                  {isQuoteLoading ? (
                    <Loader spinnerSize={25} type="noHeight" />
                  ) : (
                    renderTextField(underwriterLicopModalContinueButtonText)
                  )}
                </LicopContinueButton>
              </>
            }
            information={renderTextField(underwriterLicopModalInformation)}
            description={renderTextField(underwriterLicopModalDescription)}
            heading={renderTextField(underwriterLicopModalHeading)}
            embed={{
              src: underwriterLicopModalPdfPath?.value,
              width: '606',
              height: '340',
              type: 'application/pdf',
            }}
          />
        </Fragment>
      </WithLoader>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => {
  const { createQuote, ure, forms, config, masterList } = state
  const { isFetchingData, isRecalculating, quotes, activeIndex } = createQuote
  const healthyLivingDiscount = get(quotes[activeIndex], 'option.healthyLivingDiscount')
  return {
    createQuote,
    ure,
    form: forms[FORM_ID],
    config,
    masterList,
    isQuoteLoading: isFetchingData,
    showLoader: isRecalculating,
    eligibleHealthyCovers: getHealthyEligibleCovers(state.productRules),
    isUREHealthyLivingDiscount: healthyLivingDiscount,
    isAdviserLicopModalOpen: hasAdviserBeenPresentedLicopFlyer(state),
    isDownloadROATextEnabled: isFeatureEnabled('DownloadROAText', config.FEATURES),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullUREResults)
