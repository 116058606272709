// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { A11yLabel } from '@mlcl-digital/mlcl-design'

import Input from '../../../../../atoms/Input'
import Select from '../../../../../atoms/Select'
import MemberList from '../../../../MemberList'
import Checkbox from '../../../../../atoms/Checkbox'
import { actionCreators } from '../../../../../../actions'
import { errorCheck } from '../../../../../../utils/formUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import schema, { FORM_ID } from './creditCard.schema'
import styles from './creditCard.styles'
import { creditCardData } from './creditCardInItUtils'
import { getPaymentFrequency } from '../../../../../../utils/paymentUtils'

const Paragraph = styled('p')(styles.label)
const PaymentFrequency = styled('p')(styles.paymentFrequency)
const TermsAndConditions = styled(Checkbox)(styles.label)
const MemberListContainer = styled(MemberList)(styles.memberListContainer)

type Props = {
  actions: Object,
  fields: Object,
  form: Object,
  createQuote: Object,
  policyInstanceNo: string,
  captureCreditCard: Object,
}
type State = {
  paymentDrawDayOptions: Array<Object>,
  SCHEMA: Object,
}

export class CreditCard extends Component<Props, State> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit, displayCreditCardListing },
      fields,
      form,
    } = props

    // Payment draw date options for dropdown
    const paymentDrawDayOptions = []
    let index
    for (index = 1; index <= 28; index += 1) {
      paymentDrawDayOptions.push({
        label: index,
        value: index,
      })
    }
    const SCHEMA = schema({ fields })
    const data = creditCardData(props)
    this.state = {
      SCHEMA,
    }
    // initializing form
    if (!form) {
      formInit(FORM_ID, SCHEMA, data)
      displayCreditCardListing(false)
    }
  }

  componentDidMount() {
    const {
      createQuote,
      policyInstanceNo,
      actions: { displayCreditCardListing },
    } = this.props
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''

    const panId = activePolicy
      ? get(activePolicy, 'paymentInstruction.creditCardDetails.panID', '')
      : ''
    const cardEndingNumber = panId.substr(panId.length - 3)
    const isTokenExist = activePolicy
      ? get(activePolicy, 'paymentInstruction.creditCardDetails.tokenNumber', false)
      : false

    if (isTokenExist && cardEndingNumber) {
      displayCreditCardListing(true)
    }
  }

  // Handles changes in form fields
  handleFieldChange = ({ name, value }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state

    const data = {
      value,
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  editCardDetails = () => () => {
    const {
      actions: { displayCreditCardListing },
    } = this.props
    displayCreditCardListing(false)
  }

  render() {
    const { form, fields, createQuote, policyInstanceNo, captureCreditCard } = this.props
    const { SCHEMA } = this.state
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    const {
      addPayerCreditCardAccountNameLabel,
      addPayerCreditCardAccountNumberLabel,
      addPayerCreditCardExpiryDateLabel,
      addPayerCreditCardExpiryDatePlaceholder,
      addPayerCreditCardPaymentDrawDayLabel,
      addPayerCreditCardPaymentDrawDaySelectLabel,
      addPayerDirectDebitTerms,
    } = fields
    if (!form) {
      return null
    }
    const { cardNumber, cardHolderName, expiryDate, paymentDrawDay, termsAndConditions } =
      form.fields

    const { addPayerCreditCardExpiryDatePlaceholder: reduceAddPayerCCExpiryDatePlaceholder } =
      reduceAuthorableFields({
        addPayerCreditCardExpiryDatePlaceholder,
      })

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''

    let frequency = ''
    if (activePolicy) frequency = getPaymentFrequency(activePolicy, fields)

    const panId = activePolicy
      ? get(activePolicy, 'paymentInstruction.creditCardDetails.panID', '')
      : ''
    const cardEndingNumber = panId.substr(panId.length - 3)
    const isTokenExist = activePolicy
      ? get(activePolicy, 'paymentInstruction.creditCardDetails.tokenNumber', false)
      : false

    return (
      <div>
        <A11yLabel id="creditCardForm">
          <Text field={get(fields, 'addPayerCreditCardFormLabel', {})} />
        </A11yLabel>
        {isTokenExist && cardEndingNumber && captureCreditCard.displayCreditCardListing ? (
          <MemberListContainer
            caption="Payment Type"
            members={[{ name: `Credit card ending in ${cardEndingNumber}` }]}
            editHandler={this.editCardDetails()}
            removeHandler={null}
          />
        ) : (
          <form aria-labelledby="creditCardForm">
            <Input
              htmlFor="cardNumber"
              name="cardNumber"
              label={renderTextField(addPayerCreditCardAccountNumberLabel)}
              changeHandler={this.handleFieldChange}
              error={cardNumber.error.error}
              caption={
                cardNumber.error.error ? cardNumber.error.errorMsg : SCHEMA.cardNumber.tooltip
              }
              value={cardNumber.value}
            />
            <Input
              htmlFor="cardHolderName"
              label={renderTextField(addPayerCreditCardAccountNameLabel)}
              name="cardHolderName"
              changeHandler={this.handleFieldChange}
              error={cardHolderName.error.error}
              caption={
                cardHolderName.error.error
                  ? cardHolderName.error.errorMsg
                  : SCHEMA.cardHolderName.tooltip
              }
              value={cardHolderName.value}
            />
            <Input
              htmlFor="expiryDate"
              name="expiryDate"
              options={{ date: true, pattern: 'MM/YY' }}
              label={renderTextField(addPayerCreditCardExpiryDateLabel)}
              changeHandler={this.handleFieldChange}
              placeholder={reduceAddPayerCCExpiryDatePlaceholder}
              error={expiryDate.error.error}
              caption={
                expiryDate.error.error ? expiryDate.error.errorMsg : SCHEMA.expiryDate.tooltip
              }
              value={expiryDate.value}
            />
            <Paragraph>
              <Text field={get(fields, 'addPayerCreditCardPaymentDateNote', {})} />
            </Paragraph>
            <Select
              label={renderTextField(addPayerCreditCardPaymentDrawDayLabel)}
              placeholder={renderTextField(addPayerCreditCardPaymentDrawDaySelectLabel)}
              value={paymentDrawDay.value}
              name="paymentDrawDay"
              error={paymentDrawDay.error.error}
              caption={
                paymentDrawDay.error.error
                  ? paymentDrawDay.error.errorMsg
                  : SCHEMA.paymentDrawDay.tooltip
              }
              changeHandler={this.handleFieldChange}
              options={SCHEMA.paymentDrawDay.options}
            />
            <PaymentFrequency>{frequency}</PaymentFrequency>
            <TermsAndConditions
              text={renderTextField(addPayerDirectDebitTerms, true)}
              name="termsAndConditions"
              htmlFor="termsAndConditions"
              onChangeHandler={this.handleFieldChange}
              checked={termsAndConditions.value}
              error={termsAndConditions.error.error}
              caption={
                termsAndConditions.error.error
                  ? termsAndConditions.error.errorMsg
                  : SCHEMA.termsAndConditions.tooltip
              }
            />
          </form>
        )}
      </div>
    )
  }
}

export const mapStateToProps = ({ forms, captureCreditCard }) => ({
  form: forms[FORM_ID],
  captureCreditCard,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)
