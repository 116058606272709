import { mediaQueries, space } from '../../../styles'

// for filter cateories tab
export const responsiveCategoryTabStyle = {
  gridTemplateColumns: 'repeat(1, 1fr)',
  [mediaQueries.lg]: {
    gridTemplateColumns: 'repeat(6, 0fr)',
  },
  [mediaQueries.xl]: {
    gridTemplateColumns: 'repeat(7, 0fr)',
  },
}

// for filter category tabs size
export const responsiveButtonStyle = {
  [mediaQueries.lg]: {
    width: space(24),
  },
}

// for article tiles
export const responsiveTileStyle = {
  gridTemplateColumns: 'repeat(1, 1fr)',
  [mediaQueries.sm]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [mediaQueries.md]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}
