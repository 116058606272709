import { colours, space } from '../../../../../styles'

const styles = {
  wrapper: {
    width: space(50),
    position: 'relative',
    backgroundColor: colours.white,
    padding: space(3),
    label: {
      marginTop: space(2),
    },
  },
  section: {
    clear: 'both',
    marginBottom: space(3),
    color: colours.black,
  },
  headerWrapper: {
    marginBottom: space(4),
  },
  buttonWrapper: {
    clear: 'both',
    marginTop: space(6),
    '&>button': {
      width: '100%',
    },
  },
}
export default styles
