export const APPLICATION_STAGE_STEP = {
  incomplete: 'applicationStepIncompleteText',
  partiallyComplete: 'applicationStepPartiallyCompleteText',
  completed: 'applicationStepCompletedText',
  lifeAssuredCompleted: 'applicationLifeAssuredStepCompletedText',
}
// destructuring doesn't work with dotenv https://github.com/mrsteele/dotenv-webpack/issues/142
/* eslint-disable prefer-destructuring */
/* eslint-enable prefer-destructuring */
export const APPLICATION_REVIEW_PATH = '/application/review'
export const APPLICATION_DETAILS_PATH = '/application/details'
export const APPLICATION_DECLARATION_PATH = '/application/declaration'
export const APPLICATION_PATH = '/application'
export const PERSONAL_STATEMENT = '/application/personal-statement'
export const CUSTOMER_COMPLETES = '/application/customer-completes'
export const CUSTOMER_PERSONAL_STATEMENT = '/personalstatement/underwriting'
export const CUSTOMER_URE = '/personalstatement/underwriting/engine'
export const RESULTS_PAGE = '/application/underwriting/results'
export const SAVE_QUOTE_COLLECTION = 'SAVE_QUOTE_COLLECTION'
export const CONVERT_TO_DRAFT_APPLICATION = 'CONVERT_TO_DRAFT_APPLICATION'
export const SAVE_DRAFT_APPLICATION = 'SAVE_DRAFT_APPLICATION'
export const REGISTER_MY_LINK = 'REGISTER_MYLINK'
export const WIP_MYLINK = 'WIP_MYLINK'
export const COMPLETE_MYLINK = 'COMPLETE_MYLINK'
export const ADV_F2F = 'ADV_F2F'
export const ADV_PHN = 'ADV_PHN'
export const MYLINK = 'MYLINK'
export const TELE = 'TELE'
export const METHOD_NONE = 'NONE'
export const COMPLETE_TELE = 'COMPLETE_TELE'
export const CHILD_CI = 'child_ci'
export const CI_EBO = 'CI_EBO'
export const EBO_FEATURE = 'EBO'
export const ALTERNATIVE_INCOME_PROTECTION = 'AIP'
export const DETERMINE_UNDERWRITING_OUTCOME = 'DETERMINE_UNDERWRITING_OUTCOME'
export const DETERMINE_APPLICATION_OUTCOME = 'DETERMINE_APPLICATION_OUTCOME'
export const SUBMIT_TO_BANCS = 'SUBMIT_TO_BANCS'
export const COMPLETE_APPLICATION = 'COMPLETE_APPLICATION'
export const OUTCOME = 'OUTCOME'
export const ADVISER_VERIFICATION = 'ADVISER_VERIFICATION'
export const ADVISER_PRESENTED_LICOP_FLYER = 'ADVISER_PRESENTED_LICOP_FLYER'
export const YES = 'Y'
export const NO = 'N'
export const STEP_ONE = '1'
export const STEP_TWO = '2'
export const STEP_ZERO = '0'
export const TOAST_ID_EMAIL = 'Email'
export const TOAST_ID_PHONE = 'Phone'
export const APPLICATION_OVERVIEW_GENDER_STATUS_LONG = 'Male'
export const APPLICATION_OVERVIEW_GENDER_STATUS_SHORT = 'M'
export const APPLICATION_OVERVIEW_SMOKER_STATUS_LONG = 'Yes'
export const APPLICATION_OVERVIEW_SMOKER_STATUS_SHORT = 'Y'
export const NOT_AVAILABLE = 'NA'
export const STATUS_QUOTE_APPLICATION = 'Quote_Application'
export const STATUS_DRAFT_APPLICATION = 'Draft_Application'
export const STATUS_SUBMITTED_APPLICATION = 'Submitted_Application'
export const STATUS_COMPLETED_APPLICATION = 'Completed_Application'
export const PENDING_APPLICATION = 'PENDING_APPLICATION'
export const SUBMITTED_APPLICATION = 'SUBMITTED_APPLICATION'
export const PENDING_APPLICATION_ALTS = 'PENDING_ALTERATION'
export const SUBMITTED_APPLICATION_ALTS = 'SUBMITTED_ALTERATION'

export const UPDATED_DATE = 'updatedDate'
export const ORDER_BY_DESC = 'desc'
export const ORDER_BY_ASC = 'asc'
export const FILTER_CATEGORY_ALL = 'ALL'
export const QUOTE_NAME = 'quoteName'
export const POLICY_OWNER = 'policyOwner'
export const POLICY_OWNER_KEY = 'POW'
export const LIFE_INSURED_KEY = 'LA'
export const APPLICATION_URE_PAGE = '/application/underwriting/engine'
export const STAGE0 = 'stage0'
export const STAGE1 = 'stage1'
export const STAGE2 = 'stage2'
export const PENDING = 'pending'
export const PROCESSING = 'processing'
export const COMPLETE = 'complete'
export const REFERRED = 'REFERRED'
export const ACCEPTED = 'ACCEPTED'
export const BUSINESSEXPENSE = 'BE_PLATINUM'
export const INCOMEPROTECTIONPLT = 'PHI_PLAT_2020'
export const INCOMEPROTECTIONSTD = 'PHI_STD_2020'
export const POLICYOWNER = 'OWR'
export const IET = 'IET'
export const PRIMEOWNER = 'PRIMEOWNER'
export const ADVISER = 'adviser'
export const AUTH = 'auth'
export const POLICYOWN = 'policyOwn'
export const CONSENT = 'consent'
export const DIGITAL_QUOTE = 'DIGITAL_QUOTE'
export const DIGITAL_ALTERATION = 'DIGITAL_ALTERATION'
export const POLICY_RELATIONSHIPS_LIFEASSURED = 'LA'
export const TERM = 'TERM'
export const SHARE_PERS_STMT_WITH_ADVISOR = 'SHARE_PERS_STMT_WITH_ADVISOR'
export const SHARE_AUTH1_WITH_GP = 'SHARE_AUTH1_WITH_GP'
export const SHARE_AUTH2_WITH_GP = 'SHARE_AUTH2_WITH_GP'
export const OUTSTANDING_REQUIREMENT_CATEGORY_MLC = 'MLC'
export const OUTSTANDING_REQUIREMENT_CATEGORY_ADVISER = 'Adviser'
export const NOTIFICATION_UW = 'Notification (UW)'
export const NOTIFICATION_NB = 'Notification (NB)'
export const REQUIREMENTS_UW = 'Requirements (UW)'
export const REQUIREMENTS_NB = 'Requirements (NB)'
export const APPLICATION_TYPE_REPLACE = 'SBR'

export const APPLICATION_STAGE_NTU = 'Not Taken Up (NTU)'
export const APPLICATION_STAGE_DECLINED = 'Application Declined'
export const APPLICATION_STAGE_STRAIGHT_THROUGH_ACCEPT = 'Straight Through Accept'
export const APPLICATION_STAGE_POLICY_ISSUED = 'Policy Issued'
export const APPLICATION_EDIT_PAGE = '/quote-tool'
export const GENERATE_REPORTING_PAGE_PATH = '/reporting'

export const URE_BY_ADVISER = 'URE_BY_ADVISER'
export const URE_BY_CUSTOMER = 'URE_BY_CUSTOMER'
export const URE_BY_TELE = 'URE_BY_TELE'

export const applicationsfilterMap = {
  ALL: [PENDING_APPLICATION, SUBMITTED_APPLICATION],
  PENDING: [PENDING_APPLICATION],
  SUBMITTED: [SUBMITTED_APPLICATION],
}

export const alterationApplicationsFilterMap = {
  ALL: [PENDING_APPLICATION_ALTS, SUBMITTED_APPLICATION_ALTS],
  PENDING: [PENDING_APPLICATION_ALTS],
  SUBMITTED: [SUBMITTED_APPLICATION_ALTS],
}

export const ALTERATION_TYPE_REJECT_CPI = 'rejectCPI'

export const APPLICATION_TYPES = {
  NEWBUS: 'NEWBUS',
  ALT: 'ALT',
  SBR: 'SBR',
  BuyBack: 'BuyBack',
  Reinstatement: 'Reinstatement',
  Continuation_Option: 'Continuation Option',
  DigiAlt: 'DigiAlt',
}

export const ACTION_TYPES = {
  SAVE_DIGITAL_ALTERATION: 'SAVE_DIGITAL_ALTERATION',
  SAVE_QUOTE_COLLECTION: 'SAVE_QUOTE_COLLECTION',
  SAVE_DRAFT_APPLICATION: 'SAVE_DRAFT_APPLICATION',
  CONVERT_TO_DRAFT_APPLICATION: 'CONVERT_TO_DRAFT_APPLICATION',
  COMPLETE_APPLICATION: 'COMPLETE_APPLICATION',
}

export const APPLICATION_STAGES = {
  NOT_TAKEN_UP: 'Not Taken Up (NTU)',
  DECLINED: 'Application Declined',
  POLICY_ISSUED: 'Policy Issued',
  STRAIGHT_THROUGH_ACCEPT: 'Straight Through Accept',
}

export const PREMIUM_STYLES = {
  LEVEL: 'Level',
  STEPPED: 'Stepped',
  NONE: 'None',
  ECONOMISER: 'Economiser',
}

export const HEALTHY_LIVING_DISCOUNT_CONSENT = 'HEALTHY_LIVING_DISCOUNT_CONSENT'
export const MINIMUM_BMI_THRESHOLD = 18.5
export const MAXIMUM_BMI_THRESHOLD = 28.5
