/* eslint-disable no-unused-vars */
// redux.
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
import React from 'react'
import get from 'lodash/get'
import { closeSidebar, previousPanel } from '../../../../actions/creators/sidebar'
import { addNulis } from '../../../../actions/creators/createQuote'

// utils
import { formUpdateValidations } from '../../../../actions/creators/forms'

import { validateAll } from '../../../../utils/formUtils'

// components.
import AddNulis from '../../AddNulis'
import AddTFN from '../../AddNulis/AddTFN'

import SCHEMA, { FORM_ID } from '../../AddNulis/AddNulis.schema'

const SIDEBAR_SCHEMA = (fields = null, sidebar = {}) => [
  {
    sitecoreFieldHeadingKey: 'nulisHeader',
    component: AddNulis,
    visible: false,
    callbackKey: 'handleNewClient',
    props: {
      handleNewClient: next => next(),
      secondaryHeader: true,
      alwaysReset: true,
    },
    nextText: get(fields, 'saveBeneficiariesButtonText.value', 'Save'),
    handleNext: (next, dispatch, state) => {
      const {
        addressLookup,
        nulis: { showNulisExtraFields },
        sidebar: {
          panelProps: { policyInstanceNo },
        },
        abnLookup: { isAbnCancelled },
      } = state

      const isManualResidential =
        addressLookup.policyOwnerResidentialAddress &&
        addressLookup.policyOwnerResidentialAddress.isManual

      const schema = SCHEMA()(
        {
          isManualResidential,
          showNulisExtraFields,
        },
        fields
      )
      if (isAbnCancelled) return

      dispatch(formUpdateValidations(FORM_ID, schema))
      const valid = validateAll(schema, state.forms.addNulis.fields)
      if (valid) {
        dispatch(addNulis(state))
        dispatch(closeSidebar())
      }
    },
  },
  {
    sitecoreFieldHeadingKey: 'nulisHeader',
    component: AddTFN,
    visible: false,
    callbackKey: 'handleNewClient',
    props: {
      secondaryHeader: true,
      alwaysReset: true,
    },
    nextText: get(fields, 'nulisAddTFNButton.value', 'Add'),
    handleNext: (next, dispatch, state) => {
      const {
        addressLookup,
        forms: { addNulis: addNulisState },
      } = state

      const isManualResidential =
        addressLookup.policyOwnerResidentialAddress &&
        addressLookup.policyOwnerResidentialAddress.isManual

      const schema = SCHEMA()(
        {
          isManualResidential,
        },
        fields
      )

      if (!addNulisState.fields.tfn.error.error) {
        dispatch(previousPanel())
      }
    },
  },
]

export default SIDEBAR_SCHEMA
