/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconExclaimation16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M6 1h4L9 11H7L6 1zM8 12c-1.2 0-2 1.125-2 1.5S6.8 15 8 15s2-1.125 2-1.5S9.2 12 8 12z"
      fill="#000"
    />
  </Icon>
)

export default IconExclaimation16
