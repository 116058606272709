// @flow
import moment from 'moment'
import {
  CUSTOMER_PORTAL,
  ADVISOR_PORTAL,
  PAYMENTS_PORTAL,
  CONSUMER_PORTAL_SOURCE,
  ADVISER_PORTAL_SOURCE,
  RETAIL_PORTAL_SOURCE,
} from '../constants/site'

import { getBancsCustomerNumber, getAdviserNo } from './cookieUtils'
import { uuidv4 } from './commonUtils'
import { EXPERIENCE_API_HEADER_X_INITIAL_USER } from '../constants/apis'
import { ADVISER_NO_KEY } from '../constants/adviser'

export const TYPE = {
  success: 'SUCCESS',
  failure: 'FAILURE',
  error: 'ERROR',
}

// effectively determines that we haven't received a cached, error or failure response.
export const isValidResponse = (status: number) => status >= 200 && status < 300

// logs network status to the console.
export const networkLog = (name: string, status: number) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(
      `%c${status}${status ? ' : ' : ''}${name}`,
      `background: ${
        isValidResponse(status) ? 'green' : 'red'
      }; color: white; padding: 5px; display: block; width:100%;`
    )
  }
}

export const makeActions = (name: string): { success: string, failure: string, error: string } => ({
  success: `${name}_SUCCESS`,
  failure: `${name}_FAILURE`,
  error: `${name}_ERROR`,
  init: `${name}_INIT`,
})

export const makeRequestObj = (
  {
    data,
    verb = 'GET',
    contentType = 'application/json',
  }: { data: Object, verb: string, contentType: string },
  headers: Object = {},
  stringify: boolean = false
) => {
  const body = data && verb !== 'GET' ? { body: stringify ? JSON.stringify(data) : data } : {}
  return {
    method: verb,
    headers: {
      'Content-Type': contentType,
      ...headers,
    },
    mode: 'cors',
    ...body,
  }
}

export const makeQueryString = (params: Object): string => {
  const arr = []
  Object.entries(params).forEach(obj => {
    arr.push(`${obj[0]}=${encodeURIComponent(obj[1])}`)
  })
  return `?${arr.join('&')}`
}

export const getSource = () => {
  switch (process.env.SITE) {
    case CUSTOMER_PORTAL:
      return CONSUMER_PORTAL_SOURCE
    case ADVISOR_PORTAL:
      return ADVISER_PORTAL_SOURCE
    case PAYMENTS_PORTAL: {
      return RETAIL_PORTAL_SOURCE
    }
    default: {
      return ''
    }
  }
}

export const getRole = () => {
  switch (process.env.SITE) {
    case CUSTOMER_PORTAL: {
      return 'Customer'
    }
    case ADVISOR_PORTAL: {
      return 'Adviser'
    }
    case PAYMENTS_PORTAL: {
      return 'Payment'
    }
    default: {
      return ''
    }
  }
}

export const makeRequestHeaders = (
  contentType,
  token,
  additionalHeaders = {},
  isUnauthenticatedRequest,
  removeHeaders
) => {
  const creationTime = moment().toISOString()
  const adviserNo = getAdviserNo()
  const requestHeaders = {
    'Content-Type': contentType,
    'X-InitialUser': EXPERIENCE_API_HEADER_X_INITIAL_USER,
    'X-InitialRole': getRole(),
    bancsCustomerNo: getBancsCustomerNumber(),
    token,
    timestamp: creationTime,
    creationTime,
    source: getSource(),
    transactionId: uuidv4(),
    trackingId: `trackingid-${creationTime}`,
    ...(adviserNo && {
      [ADVISER_NO_KEY]: adviserNo,
    }),
    ...additionalHeaders,
  }
  if (removeHeaders) {
    removeHeaders.forEach(header => delete requestHeaders[header])
  }
  isUnauthenticatedRequest && delete requestHeaders.token
  return requestHeaders
}

export const getScope = () => {
  switch (process.env.SITE) {
    case CUSTOMER_PORTAL:
      return 'customer'
    case ADVISOR_PORTAL:
    case PAYMENTS_PORTAL: {
      return 'advisor'
    }
    default: {
      return 'openid'
    }
  }
}
