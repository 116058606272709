// @flow

// components.
import Visualiser from '../../Visualiser'
import VisualiserDisclaimer from '../../VisualiserDisclaimer'

// actions
import { previousPanel } from '../../../../actions/creators/sidebar'

const SIDEBAR_SCHEMA = () => [
  {
    sitecoreFieldHeadingKey: 'visualiserTitle',
    component: Visualiser,
    width: 915,
  },
  {
    sitecoreFieldHeadingKey: 'visualiserTitle',
    component: VisualiserDisclaimer,
    width: 915,
    nextText: 'Confirm',
    props: {
      secondaryHeader: true,
    },
    handleNext: (next: Function, dispatch: Function) => {
      dispatch(previousPanel())
    },
  },
]

export default SIDEBAR_SCHEMA
