/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowRight16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M14 8l.707.707.707-.707-.707-.707L14 8zM8.293 3.707l5 5 1.414-1.414-5-5-1.414 1.414zm5 3.586l-5 5 1.414 1.414 5-5-1.414-1.414zM1 9h13V7H1v2z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowRight16
