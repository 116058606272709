/* eslint-disable no-unused-vars */
// redux.
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
import React from 'react'
import { pathOr } from 'lodash/fp'
import get from 'lodash/get'

// creators
import { closeSidebar, previousPanel } from '../../../../actions/creators/sidebar'
import { submitMemberDetails, addBusinessAsPayer } from '../../../../actions/creators/createQuote'
import { resetAbnLookup } from '../../../../actions/creators/abnLookup'

// schema.
import policyOwnerDetailsSchema from '../../AddPayers/components/PolicyOwnerDetails/policyOwnerDetails.schema'
import businessOwnerDetailsSchema, {
  FORM_ID as businessOwnerDetailsId,
} from '../../AddPayers/components/BusinessOwnerDetails/businessOwnerDetails.schema'
import addDirectorSchema, {
  FORM_ID as addDirectorId,
} from '../../AddPayers/components/AddDirector/addDirector.schema'

// utils
import {
  formReset,
  formSubmit,
  formSubmitComplete,
  formUpdateField,
  formValidate,
} from '../../../../actions/creators/forms'

// constants.
import {
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_PARTY_TYPE_INDIVIDUAL,
  POLICY_PARTY_TYPE_BUSINESS,
} from '../../../../constants/policies'

// components.
import PolicyOwnerAdd from '../../PolicyOwnerAdd'
import PolicyOwnerDetails from '../../AddPayers/components/PolicyOwnerDetails'
import BusinessOwnerDetails from '../../AddPayers/components/BusinessOwnerDetails'
import AddDirector from '../../AddPayers/components/AddDirector'
import { initForms } from '../../AddPayers/components/PolicyOwnerDetails/memberInitUtils'
import { initForms as initBussinessForms } from '../../AddPayers/components/BusinessOwnerDetails/businessOwnerInitUtils'
import { initForms as initDirectorForms } from '../../AddPayers/components/AddDirector/addDirectorInitUtils'

// utils
import { formToMemberEntityDirector } from '../../../../utils/addBusinessUtils'

const SIDEBAR_SCHEMA = (fields = null, sidebar = {}) => {
  const policyOwnerDetailsId = `policyOwner${
    sidebar.panelProps ? sidebar.panelProps.policyInstanceNo : ''
  }`
  return [
    {
      sitecoreFieldHeadingKey: 'addPolicyOwnerHeaderLabel',
      component: PolicyOwnerAdd,
      visible: false,
      callbackKey: 'handleNewClient',
      callBackWithValue: true,
      props: {
        handleNewClient: (next, dispatch, subpanelIndex) =>
          next(undefined, undefined, subpanelIndex),
        secondaryHeader: true,
        deleteFormIds: [businessOwnerDetailsId, policyOwnerDetailsId, addDirectorId],
        alwaysReset: true,
      },
    },
    [
      {
        sitecoreFieldHeadingKey: 'addIndividualHeaderLabel',
        component: PolicyOwnerDetails,
        visible: false,
        callbackKey: 'handleNewClient',
        secondaryActionText: fields && fields.policyOwnerDetailsClearButtonText.value,
        props: {
          handleNewClient: next => next(),
          secondaryHeader: true,
          formId: policyOwnerDetailsId,
          partyType: POLICY_PARTY_TYPE_INDIVIDUAL,
          alwaysReset: true,
        },
        nextText: fields && fields.addIndividualNextLabel.value,
        handleSecondaryAction: (dispatch, state, authorableFields) => {
          const { sidebar: sidebarState, forms, createQuote } = state
          const isAddPayer = !!(sidebarState.schemaName === 'addPayer')
          const hasSecondaryContact =
            state.forms[policyOwnerDetailsId].fields.secondaryContactNoCheck
          const schema = policyOwnerDetailsSchema(authorableFields)({
            hasSecondaryContact,
            isAddPayer,
          })

          const data = initForms({
            createQuote,
            sidebarState,
            partyType: get(sidebarState, 'panelProps.partyType'),
          })

          dispatch(formReset(policyOwnerDetailsId, schema, data))
        },
        handleNext: (next, dispatch, state, authorableFields) => {
          const {
            secondaryContactNoCheck,
            isPostalResidentialAddressSame,
            contactNumberPhoneCode,
            secondaryNumberPhoneCode,
          } = state.forms[policyOwnerDetailsId].fields
          const {
            addressLookup,
            sidebar: {
              panelProps: { policyInstanceNo },
            },
          } = state

          const isAddPayer = !!(sidebar.schemaName === 'addPayer')
          const schema = policyOwnerDetailsSchema(authorableFields)({
            hasSecondaryContact: secondaryContactNoCheck.value,
            isManualPostal:
              addressLookup.policyOwnerPostalAddress &&
              addressLookup.policyOwnerPostalAddress.isManual,
            isManualResidential:
              addressLookup.policyOwnerResidentialAddress &&
              addressLookup.policyOwnerResidentialAddress.isManual,
            postalHidden: isPostalResidentialAddressSame.value,
            isAddPayer,
          })

          const addIndividualPolicyOwner = data => {
            data.partyType = POLICY_PARTY_TYPE_INDIVIDUAL
            dispatch(
              submitMemberDetails(
                {
                  ...data,
                  contactNumberCountryCode: get(contactNumberPhoneCode, 'value.countryCode'),
                  secondaryNumberCountryCode: get(secondaryNumberPhoneCode, 'value.countryCode'),
                },
                undefined,
                [POLICY_RELATIONSHIPS_OWNER],
                policyInstanceNo
              )
            )
            dispatch(closeSidebar())
            dispatch(
              formReset(policyOwnerDetailsId, schema, {
                useAsPostalAddress: true,
              })
            )
          }
          dispatch(formSubmit(policyOwnerDetailsId, schema, addIndividualPolicyOwner))
          dispatch(formSubmitComplete(policyOwnerDetailsId))
        },
      },
      {
        sitecoreFieldHeadingKey: 'addBusinessHeaderLabel',
        component: BusinessOwnerDetails,
        visible: false,
        callbackKey: 'handleNewClient',
        props: {
          handleNewClient: next => next(),
          secondaryHeader: true,
          partyType: POLICY_PARTY_TYPE_BUSINESS,
          alwaysReset: true,
        },
        secondaryActionText: pathOr('Clear', 'policyOwnerDetailsClearButtonText.value', fields),
        nextText: pathOr('Add Business', 'addBusinessNextButtonLabel.value', fields),

        handleSecondaryAction: (dispatch, state) => {
          const { createQuote } = state
          const schema = businessOwnerDetailsSchema({ fields })(
            state.forms[businessOwnerDetailsId].fields
          )

          const data = initBussinessForms({
            createQuote,
            sidebar,
          })
          dispatch(formReset(businessOwnerDetailsId, schema, data))
          dispatch(resetAbnLookup())
        },

        handleNext: (next, dispatch, state) => {
          const { addressLookup, forms } = state
          const businessOwnerSchema = businessOwnerDetailsSchema()({
            isManualResidential:
              addressLookup.policyOwnerResidentialAddress &&
              addressLookup.policyOwnerResidentialAddress.isManual,
            ...forms[businessOwnerDetailsId].fields,
            fields,
          })

          const addBusinessPolicyOwner = policyBusinessdata => {
            dispatch(
              addBusinessAsPayer({
                ...policyBusinessdata,
                contactNumberCountryCode: get(
                  forms,
                  `${businessOwnerDetailsId}.fields.contactNumberCode.value.countryCode`
                ),
              })
            )
            dispatch(closeSidebar())
          }
          dispatch(formSubmit(businessOwnerDetailsId, businessOwnerSchema, addBusinessPolicyOwner))
          dispatch(formSubmitComplete(businessOwnerDetailsId))
        },
      },
    ],
    {
      sitecoreFieldHeadingKey: 'addDirectorLabel',
      component: AddDirector,
      visible: false,
      isDarkPanel: true,
      callbackKey: 'handleNewClient',
      props: {
        handleNewClient: next => next(),
        secondaryHeader: true,
        alwaysReset: true,
      },

      secondaryActionText: pathOr('Clear', 'policyOwnerDetailsClearButtonText.value', fields),
      nextText: pathOr('Add Director', 'addDirectorLabel.value', fields),

      handleSecondaryAction: (dispatch, state) => {
        const {
          sidebar: { panelProps },
          addressLookup: {
            address: { isManual: isManualPostal },
          },
        } = state
        const data = initDirectorForms({
          businessForm: state.forms[businessOwnerDetailsId],
          sidebarPanelProps: panelProps,
        })
        const schema = addDirectorSchema(fields)({ isManualPostal })
        dispatch(formReset(addDirectorId, schema, data))
      },

      handleNext: (next, dispatch, state) => {
        const { addressLookup, forms } = state
        const schemaValue = {
          isManualPostal: addressLookup.address && addressLookup.address.isManual,
          sameAsCompanyAddress: forms.director.fields.sameAsCompanyAddress.value,
          address: forms.director.fields.address.value,
        }

        const schema = addDirectorSchema(fields)(schemaValue)

        const addBusinessDirectorFn = formData => {
          const isManualPostal =
            addressLookup.policyOwnerPostalAddress &&
            addressLookup.policyOwnerPostalAddress.isManual
          const isManualResidential =
            addressLookup.policyOwnerResidentialAddress &&
            addressLookup.policyOwnerResidentialAddress.isManual
          // TODO: action logic to be done
          const schemaOfBussiness = businessOwnerDetailsSchema({
            isManualPostal,
            isManualResidential,
            fields,
          })

          const alreadyAddedDirectors =
            get(forms[businessOwnerDetailsId], 'fields.directors.value') || []
          const memberEntity = formToMemberEntityDirector(formData, true)
          let directorFound = false

          if (formData.identifiers) {
            alreadyAddedDirectors.forEach((director, directorIndex) => {
              if (
                director.relatedParty.identifiers.some(
                  identifier => identifier.value === formData.identifiers
                )
              ) {
                directorFound = true
                alreadyAddedDirectors[directorIndex] = memberEntity
              }
            })
          }

          if (!directorFound) {
            alreadyAddedDirectors.push(memberEntity)
          }

          dispatch(
            formUpdateField(businessOwnerDetailsId, 'directors', {
              error: { error: false },
              value: [...alreadyAddedDirectors],
            })
          )
          dispatch(formValidate(businessOwnerDetailsId, schemaOfBussiness))
          dispatch(previousPanel())
        }
        dispatch(formSubmit(addDirectorId, schema, addBusinessDirectorFn))
      },
    },
  ]
}

export default SIDEBAR_SCHEMA
