// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { pathOr } from 'lodash/fp'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../../actions'

// components.
import TagComponent from '../../../../../../atoms/Tag'

// constants.
import {
  BENEFIT_NATURE_TYPE_STANDALONE,
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
} from '../../../../../../../constants/benefit'

// styles.
import styles from './policyBenefitsInfo.styles'

import {
  getLinks,
  isCoverForUI,
  isOwnTPDDisabled,
  isIPCover,
} from '../../../../../../../utils/quoteUtils'
import { getSGBOCoverData } from '../../../../../../../utils/extendedQuoteUtils'
import { convertBenefitCodeToFullName } from '../../../../../../../utils/policyUtils'
import { numberWithCommas } from '../../../../../../../utils/formUtils'
import { isFeatureEnabledForAP } from '../../../../../../../utils/commonUtils'
import {
  POLICY_BENEFIT_TISO,
  TPD_OPTIMISER,
  POLICY_BENEFIT_PLAT_SGBO,
  POLICY_BENEFIT_STD_SGBO,
} from '../../../../../../../constants/policies'

type QuoteSummaryPolicyBenefitsInfoProps = {
  // Fields object containing authorable fields.
  fields: Object,
  // AppliedPolicy object containing particular policy information.
  appliedPolicy: {
    covers: Array<Object>,
    productId: string,
  },
  // ProductRules object containing product rules information.
  productRules: Object,
  // all policies in quote.
  policyStructure: Array<Object>,

  quote: Object,
  masterData: Object,
}

const QuoteSummaryPolicyTableRow = styled('div')(styles.quoteSummaryPolicyTableRow)
const SGBORow = styled('div')(styles.quoteSummaryPolicyTableRow, styles.sgboRow)
const QuoteSummaryPolicyBenefitNameWrapper = styled('div')(
  styles.quoteSummaryPolicyBenefitNameWrapper
)
const QuoteSummaryPolicyBenefitName = styled('span')(styles.quoteSummaryPolicyBenefitName)
const QuoteSummaryPolicyBenefitLinkedName = styled('span')(
  styles.quoteSummaryPolicyBenefitLinkedName
)
const Tag = styled(TagComponent)(styles.quoteSummaryLinkedTag)
const QuoteSummaryPolicyPaymentStruct = styled('div')(styles.quoteSummaryPolicyPaymentStruct)
const QuoteSummaryPolicySumInsured = styled('div')(styles.quoteSummaryPolicySumInsured)
const QuoteSummaryPolicyPremium = styled('div')(styles.quoteSummaryPolicyPremium)
const BenefitWrapper = styled('div')(styles.benefitWrapper)

/**
 * QuoteSummaryPolicyBenefitsInfo
 * This Component will render a row containing benefit name, premium structure,
 * policy sum insured, and premium amount
 */
export class QuoteSummaryPolicyBenefitsInfo extends Component<QuoteSummaryPolicyBenefitsInfoProps> {
  /**
   * getCoverDataFromProductRules
   * method will return the value of the first element in the productData.benefits
   * that satisfies the provided condition.
   *
   * @param {string} productId - premium product id
   * @param {string} coverType - premium cover type
   *
   * @returns {Object|false} coverData
   */
  getCoverDataFromProductRules = (productId: string, coverType: string) => {
    const productData = this.getProductDataFromProductRules(productId)
    const coverData = productData.benefits.find(cover => cover.benefitCode === coverType)
    return coverData && Object.keys(coverData).length ? coverData : false
  }

  /**
   * getProductDataFromProductRules
   * method will return the value of the first element in the productRules
   * that satisfies the provided condition.
   *
   * @param {string} productId - premium product id
   *
   * @returns {Object} productRules
   */
  getProductDataFromProductRules = (productId: string) => {
    const { productRules } = this.props
    return productRules.find(product => product.productId === productId)
  }

  /**
   * getBenefitLink
   * get linked benefit object
   *
   * @param {Array<Object>} benefitLinks - benefit links
   * @param {Object} parentBenefitReference - parent benefit reference
   *
   * @returns {Object} benefitLinks
   */
  getBenefitLink = (benefitLinks: Array<Object>, parentBenefitReference: Object, cover: Object) => {
    const optimiserParentBenefitReference = get(cover, 'optimiserParentBenefitReference', false)
    if (optimiserParentBenefitReference)
      return this.getBenefitLabel(optimiserParentBenefitReference)

    const isBenefitLinked = benefitLinks.find(link => isEqual(link.value, parentBenefitReference))
    if (isBenefitLinked) {
      return this.getBenefitLabel(parentBenefitReference)
    }
    return false
  }

  getBenefitLabel = (benefitReference: Object) => {
    const { masterData } = this.props
    const { parentType, parentBenefitInstanceNo, parentPolicyReferenceNo } = benefitReference
    return `${convertBenefitCodeToFullName(
      masterData,
      parentType
    )} ${parentBenefitInstanceNo} Policy ${parentPolicyReferenceNo}`
  }

  /**
   * checkIsBenefitNotLinked
   * check if benefitnature is standalone
   *
   * @param {Array<Object>} benefitLinks - benefit links
   *
   * @returns {bool} true if benefit is standalone
   */
  checkIsBenefitNotLinked = (benefitLinks: Array<Object>) => {
    if (benefitLinks && benefitLinks.length === 1) {
      const isBenefitStandalone = benefitLinks.find(
        link => link.value.benefitNature === BENEFIT_NATURE_TYPE_STANDALONE
      )
      return isBenefitStandalone
    }
    return false
  }

  /**
   * renderPaymentStructValue
   * get payment structure value of passed benefit
   *
   * @param {Object} cover - current benfit information
   *
   * @returns {string} benefit value of passed cover or empty string if not found
   */
  renderPaymentStructValue = (cover: Object) => pathOr('', 'premiumStyle', cover)

  // Get Benefit links for covers
  getCoversLinks = covers => {
    const { policyStructure, productRules, appliedPolicy, quote, masterData } = this.props
    const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode, featureControlSwitch } =
      masterData
    const {
      memberMandatories: { occupationClassCode, occupationCode },
    } = quote
    const enableTPDOptimser = isFeatureEnabledForAP(featureControlSwitch, 'enableTPDOptimser')
    return covers.map(cover => {
      const { parentBenefitReference } = cover
      const linkageofCover = {
        ...cover,
        benefitLinks: getLinks(
          cover,
          productRules,
          policyStructure,
          appliedPolicy,
          enableTPDOptimser,
          isOwnTPDDisabled(
            cover.type,
            occupationClassCode,
            allowedTPDOccupationTypeGroup,
            excludedTPDOccupationTypeCode,
            occupationCode
          )
        ),
      }
      if (
        parentBenefitReference &&
        parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
      ) {
        linkageofCover.benefitLinks = [
          ...linkageofCover.benefitLinks,
          {
            label: `Optimised to ${parentBenefitReference.parentType} ${parentBenefitReference.parentBenefitInstanceNo} (policy ${parentBenefitReference.parentPolicyReferenceNo})`,
            value: parentBenefitReference,
          },
        ]
      }
      return linkageofCover
    })
  }

  /**
   * render
   * To render the row containing benefit info
   */
  render() {
    const { appliedPolicy, fields, masterData } = this.props
    const { covers, productId } = appliedPolicy
    const { quoteSummaryBenefitLinkedLabel } = fields
    let updatedCovers = covers.filter(
      cover => cover.type !== POLICY_BENEFIT_PLAT_SGBO && cover.type !== POLICY_BENEFIT_STD_SGBO
    )
    updatedCovers = this.getCoversLinks(updatedCovers)
    return (
      <Fragment>
        {updatedCovers.map((cover, index) => {
          const {
            type,
            coverAmount,
            premiumAmount,
            benefitLinks,
            parentBenefitReference,
            tpdDefinition,
            coverStyle,
          } = cover
          const coverIndex = index
          const coverProductData = this.getCoverDataFromProductRules(productId, type)
          const { benefitDisplayName, benefitCode } = coverProductData
          const premiumStructureValue = this.renderPaymentStructValue(cover)

          const isBenefitNotLinked = this.checkIsBenefitNotLinked(benefitLinks)
          const linkedBenefitName =
            isBenefitNotLinked && !parentBenefitReference
              ? null
              : this.getBenefitLink(benefitLinks, parentBenefitReference, cover)

          let benefitWithOptimiserName = ''
          if (coverProductData) {
            const isTpdOptimised = get(cover, 'optimiserParentBenefitReference', false)
              ? TPD_OPTIMISER
              : ''
            const isTpdDefinition = tpdDefinition ? `(${tpdDefinition})` : ''
            const tpdLabel = `${convertBenefitCodeToFullName(
              masterData,
              benefitCode
            )} ${isTpdDefinition} ${isTpdOptimised}`

            benefitWithOptimiserName = cover.tpdDefinition
              ? tpdLabel
              : convertBenefitCodeToFullName(masterData, benefitCode)
          }

          let coverNameWhenNotLinked = `${(coverProductData && benefitDisplayName) || type}`
          coverNameWhenNotLinked =
            tpdDefinition || coverStyle
              ? `${coverNameWhenNotLinked} (${tpdDefinition || coverStyle})`
              : coverNameWhenNotLinked

          const sgboBenefit =
            isIPCover(cover) &&
            getSGBOCoverData({ policyStructure: [appliedPolicy] }, 0, cover, false)

          return (
            <BenefitWrapper key={`${linkedBenefitName}-${premiumStructureValue}-${coverIndex}`}>
              <QuoteSummaryPolicyTableRow>
                <QuoteSummaryPolicyBenefitNameWrapper>
                  <QuoteSummaryPolicyBenefitName>
                    {linkedBenefitName ? benefitWithOptimiserName : coverNameWhenNotLinked}
                  </QuoteSummaryPolicyBenefitName>
                  {linkedBenefitName && (
                    <Fragment>
                      <Tag type="primary">{quoteSummaryBenefitLinkedLabel}</Tag>
                      <div>
                        <QuoteSummaryPolicyBenefitLinkedName>
                          {linkedBenefitName}
                        </QuoteSummaryPolicyBenefitLinkedName>
                      </div>
                    </Fragment>
                  )}
                </QuoteSummaryPolicyBenefitNameWrapper>
                <QuoteSummaryPolicyPaymentStruct>
                  {premiumStructureValue}
                </QuoteSummaryPolicyPaymentStruct>
                <QuoteSummaryPolicySumInsured>
                  {cover.type === POLICY_BENEFIT_TISO
                    ? `$${numberWithCommas(coverAmount)}`
                    : isCoverForUI(cover) && `$${numberWithCommas(coverAmount)}`}
                </QuoteSummaryPolicySumInsured>
                <QuoteSummaryPolicyPremium>
                  ${Number(premiumAmount || 0).toFixed(2)}
                </QuoteSummaryPolicyPremium>
              </QuoteSummaryPolicyTableRow>

              {sgboBenefit && (
                <SGBORow>
                  <QuoteSummaryPolicyBenefitNameWrapper sgboBenefit={sgboBenefit}>
                    <QuoteSummaryPolicyBenefitName>
                      {sgboBenefit.name}
                    </QuoteSummaryPolicyBenefitName>
                  </QuoteSummaryPolicyBenefitNameWrapper>
                  <QuoteSummaryPolicyPaymentStruct>
                    {this.renderPaymentStructValue(sgboBenefit)}
                  </QuoteSummaryPolicyPaymentStruct>
                  <QuoteSummaryPolicySumInsured>
                    {`$${numberWithCommas(sgboBenefit.coverAmount)}`}
                  </QuoteSummaryPolicySumInsured>
                  <QuoteSummaryPolicyPremium>
                    ${Number(sgboBenefit.premiumAmount || 0).toFixed(2)}
                  </QuoteSummaryPolicyPremium>
                </SGBORow>
              )}
            </BenefitWrapper>
          )
        })}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ createQuote, masterList }) => ({
  quote: createQuote.quotes[createQuote.activeIndex],
  masterData: masterList.data,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSummaryPolicyBenefitsInfo)
