// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// components.
import Heading from '../../../../../atoms/Heading'

// constants.
import { GENDER, YES_NO, STATES } from '../../../../../../constants/forms'

// styles.
import styles from './customerInfo.styles'

// utils
import { getRelativeTimeDifference, formatCurrency } from '../../../../../../utils/quoteUtils'
import { isEmployee } from '../../../../../../utils/extendedQuoteUtils'

type CustomerInfoProps = {
  // Sitecore fields
  fields: Object,
  // Quote collection storage.
  quoteData: Object,
  withHeading?: boolean,
  // Master data from Sitecore
  masterData: Object,
}

const CustomerHeading = styled(Heading)(styles.customerHeading)
const CustomerInfoRoot = styled('div')(styles.customerInfoRoot)
const CustomerInfoItem = styled('div')(styles.customerInfoItem)
const CollectionLabel = styled('span')({ ...styles.collectionLabel, ...styles.customerInfoLabel })
const CustomerInfoValue = styled('span')(styles.customerInfoValue)
const OccupationInfoRoot = styled('div')(({ withHeading }) => ({
  ...styles.occupationInfoRoot,
  ...(!withHeading ? styles.textCenter : null),
}))
const OccupationInfoHeading = styled('div')(styles.occupationInfoHeading)
const OccupationInfoSummary = styled('div')(styles.occupationInfoSummary)

/**
 * CustomerInfo
 * This component will render the customer information
 *
 * @param {object} fields - containing sitecore labels
 * @param {object} quoteData - current active quote information
 */

export const getFieldValue = (fieldObject, data, field) => {
  const fieldValue =
    fieldObject && data.find(translationObject => translationObject.value === fieldObject)

  return fieldValue ? fieldValue[field] : null
}

const CustomerInfo = ({ fields, quoteData, withHeading, masterData }: CustomerInfoProps) => {
  const {
    quoteSummaryAgeLabel,
    quoteSummaryGender,
    quoteSummarySmoker,
    quoteSummaryState,
    quoteSummaryCustomerInfoHeading,
    quoteSummaryOccupationInfoHeading,
    quoteSummaryOccupationInfoEarning,
    quoteSummaryOccupationInfoEarningPA,
    EarningsIncludingSuper,
    EarningsExcludingSuper,
  } = fields

  const dateOfBirth = get(quoteData, 'memberMandatories.dateOfBirth')
  const gender = get(quoteData, 'memberMandatories.gender')
  const isSmoker = get(quoteData, 'memberMandatories.isSmoker')
  const stateOfResidence = get(quoteData, 'memberMandatories.stateOfResidence')
  const occupation = get(quoteData, 'memberMandatories.occupation')
  const earning = get(quoteData, 'memberMandatories.earning')
  const earningExcludingSuper = get(quoteData, 'memberMandatories.earningExcludingSuper')
  const employmentStatusCode = get(quoteData, 'memberMandatories.employmentStatus')
  const employementStatus = get(masterData, 'employmentStatus', []).find(
    empStatus => empStatus.code === employmentStatusCode
  )
  const smokerStatusObj = YES_NO.find(translationObject => translationObject.label === isSmoker)
  const genderObj = GENDER.find(translationObject => translationObject.value === gender)
  const isClientEmployee = isEmployee(employmentStatusCode)
  return (
    <Fragment>
      {withHeading && <CustomerHeading size="2">{quoteSummaryCustomerInfoHeading}</CustomerHeading>}
      <CustomerInfoRoot>
        <CustomerInfoItem>
          <CustomerInfoValue>{getRelativeTimeDifference(dateOfBirth)}</CustomerInfoValue>
          <CollectionLabel>{quoteSummaryAgeLabel}</CollectionLabel>
        </CustomerInfoItem>
        <CustomerInfoItem>
          <CustomerInfoValue>{genderObj && genderObj.shortLabel}</CustomerInfoValue>
          <CollectionLabel>{quoteSummaryGender}</CollectionLabel>
        </CustomerInfoItem>
      </CustomerInfoRoot>
      <CustomerInfoRoot>
        <CustomerInfoItem>
          <CustomerInfoValue>{getFieldValue(stateOfResidence, STATES, 'label')}</CustomerInfoValue>
          <CollectionLabel>{quoteSummaryState}</CollectionLabel>
        </CustomerInfoItem>
        <CustomerInfoItem>
          <CustomerInfoValue>{smokerStatusObj && smokerStatusObj.label}</CustomerInfoValue>
          <CollectionLabel>{quoteSummarySmoker}</CollectionLabel>
        </CustomerInfoItem>
      </CustomerInfoRoot>
      <OccupationInfoRoot>
        <OccupationInfoHeading>{quoteSummaryOccupationInfoHeading}</OccupationInfoHeading>
        <OccupationInfoSummary>
          {employementStatus && employementStatus.value}
        </OccupationInfoSummary>
        <OccupationInfoSummary>{occupation}</OccupationInfoSummary>
        <OccupationInfoSummary>
          {quoteSummaryOccupationInfoEarning}:{` ${formatCurrency(earning, '$', 0)} `}
          {quoteSummaryOccupationInfoEarningPA} {isClientEmployee && EarningsIncludingSuper}
        </OccupationInfoSummary>
        {isClientEmployee && (
          <OccupationInfoSummary>
            {quoteSummaryOccupationInfoEarning}:
            {` ${formatCurrency(earningExcludingSuper, '$', 0)} `}
            {quoteSummaryOccupationInfoEarningPA} {EarningsExcludingSuper}
          </OccupationInfoSummary>
        )}
      </OccupationInfoRoot>
    </Fragment>
  )
}

CustomerInfo.defaultProps = {
  withHeading: true,
}

export default CustomerInfo
