// @flow

import { PAYMENT_DRAW_DATE } from '../../../../../../constants/forms'
import { optional } from '../../../../../../utils/formUtils'

export const FORM_ID: string = 'linkedMasterkeyAccount'

const schema = (): Function => (): Object => ({
  accountNumber: {
    condition: optional,
  },
  paymentDrawDate: {
    condition: optional,
    options: PAYMENT_DRAW_DATE,
  },
})

export default schema
