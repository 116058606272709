// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// redux.
import { actionCreators } from '../../../actions'
// components.
import FileUpload from './components/FileUpload'

export const mapStateToProps = ({ fileUploadInfo }) => ({
  fileUploadInfo,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)
