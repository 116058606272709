// @flow
import get from 'lodash/get'
import { STATES, CONTRIBUTION_TYPES } from '../../../constants/forms'
import {
  optional,
  required,
  postcode,
  numeric,
  tfn,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../utils/formUtils'

export const FORM_ID: string = 'addNulis'

const schema =
  (): Function =>
  (
    state?: Object,
    fields?: {
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError: Object,
      policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage: Object,
      policyOwnerDetailsResidentialAddressCountryFieldError: Object,
      policyOwnerDetailsResidentialAddressStreetFieldError: Object,
      policyOwnerDetailsResidentialAddressLocalityFieldError: Object,
      policyOwnerDetailsResidentialAddressStateFieldError: Object,
      policyOwnerDetailsResidentialAddressPostCodeFieldError: Object,
      policyOwnerDetailsPostalAddressStreetFieldError: Object,
      policyOwnerDetailsPostalAddressLocalityFieldError: Object,
      policyOwnerDetailsPostalAddressStateFieldError: Object,
      policyOwnerDetailsPostalAddressPostCodeFieldError: Object,
      policyOwnerDetailsResidentialAddressValidStreetFieldError: Object,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError: Object,
      policyOwnerDetailsResidentialAddressValidStateFieldError: Object,
      policyOwnerDetailsResidentialAddressValidPostalFieldError: Object,
      nulisContributionTypeError: Object,
      nulisABNError: Object,
      nulisEmployerName: Object,
      nulisTFNRequiredError: Object,
    } = {}
  ): Object => ({
    contributionType: {
      condition: required,
      errorMsg: get(fields, 'nulisContributionTypeError.value'),
      options: CONTRIBUTION_TYPES,
    },
    employerName: {
      condition: state.showNulisExtraFields ? required : optional,
      errorMsg: get(fields, 'nulisEmployerName.value'),
    },
    abn: {
      condition: state.showNulisExtraFields ? required : optional,
      errorMsg: get(fields, 'nulisABNError.value'),
    },
    abnConfirmation: {
      condition: state.showNulisExtraFields ? required : optional,
    },
    nulisDeclarationMLCSuperFund: {
      condition: [required, false],
    },
    nulisDeclarationTFN: {
      condition: [required, false],
    },
    residentialAddress: {
      condition: state.showNulisExtraFields && !state.isManualResidential ? required : optional,
    },
    residentialAddressHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            fields,
            'policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value'
          ),
        },
      ],
    },
    residentialAddressStreet: {
      condition: [
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressStreetFieldError.value'),
            }
          : optional,
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg: get(
                fields,
                'policyOwnerDetailsResidentialAddressValidStreetFieldError.value'
              ),
            }
          : {},
      ],
    },
    residentialAddressLocality: {
      condition: [
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressLocalityFieldError.value'),
            }
          : optional,
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg: get(
                fields,
                'policyOwnerDetailsResidentialAddressValidLocalityFieldError.value'
              ),
            }
          : {},
      ],
    },
    residentialAddressState: {
      condition: [
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressStateFieldError.value'),
            }
          : optional,
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg: get(
                fields,
                'policyOwnerDetailsResidentialAddressValidStateFieldError.value'
              ),
            }
          : {},
      ],
      options: STATES,
    },
    residentialAddressCountry: {
      condition: [
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressCountryFieldError.value'),
            }
          : optional,
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg: get(
                fields,
                'policyOwnerDetailsResidentialAddressCountryFieldInvalidError.value'
              ),
            }
          : {},
      ],
    },
    residentialAddressPostCode: {
      condition: [
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressPostCodeFieldError.value'),
            }
          : optional,
        state.showNulisExtraFields && state.isManualResidential
          ? {
              condition: manualAddressPostalCode,
              errorMsg: get(
                fields,
                'policyOwnerDetailsResidentialAddressValidPostalFieldError.value'
              ),
            }
          : {},
        postcode,
      ],
    },
    tfn: {
      condition: [
        {
          condition: required,
          errorMsg: get(fields, 'nulisTFNRequiredError.value'),
        },
        {
          condition: numeric,
          errorMsg: get(fields, 'nulisTFNInvalidError.value'),
        },
        {
          condition: tfn,
          errorMsg: get(fields, 'nulisTFNInvalidError.value'),
        },
      ],
    },
  })
export default schema
