import { space, fontSize, colours, coloursWithOpacity } from '../../../../../styles'

const styles = {
  base: {
    padding: space(3),
    position: 'relative',
    minHeight: `calc(100vh - ${space(24, true)})`,
    paddingBottom: space(15),
  },
  soleDirectorCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  disabledInput: isDisabled => ({
    ...(isDisabled
      ? {
          input: {
            borderWidth: 0,
            paddingLeft: 0,
            opacity: 1,
            color: colours.darkGrey,
          },
        }
      : {}),
  }),
  readOnlyLabel: {
    paddingBottom: space(3),
    display: 'block',
  },
  businessLabel: {
    fontSize: fontSize.xxs,
    display: 'block',
    marginBottom: space(2),
  },
  addDirectorBtn: {
    borderBottom: `1px solid ${colours.lightestGrey}`,
    borderTop: `1px solid ${colours.lightestGrey}`,
    display: 'block',
    width: '100%',
    textAlign: 'left',
    height: 'auto',
    padding: `${space(3, true)} ${space(1, true)} ${space(2, true)}`,
    span: {
      fontSize: fontSize.xs,
      paddingLeft: space(1),
    },
  },
  errorAddDirector: {
    color: colours.red,
    border: `2px solid ${colours.red}`,
    padding: space(2),
    borderRadius: space(1),
    background: coloursWithOpacity(colours.red, 0.3),
    fontSize: fontSize.xxs,
    width: space(44),
    marginTop: space(2, true),
  },
  addPresonSection: {
    borderTop: `1px solid ${colours.lightestGrey}`,
  },
  fieldset: {
    padding: 0,
    display: 'flex',
    width: '100%',
    border: 'none',
    position: 'relative',
    margin: 0,
    justifyContent: 'space-between',
  },
  fullWidth: {
    flex: '0 0 100%',
  },
}
export default styles
