// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { any, func, bool, oneOfType, objectOf, arrayOf, string, number, object } from 'prop-types'

// components.
import { Pagination } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import TaskDashboardAccordion from './components/Accordion'
import TaskRow from './components/TaskRow'

// utils
import { idMaker } from '../../../utils/formUtils'
import { getBancsCustomerNumber, getAdviserNo } from '../../../utils/cookieUtils'
import { browserOrigin } from '../../../utils/browserUtils'
import {
  handleDocumentToDownloadFilter,
  getNoOfTasks,
  isRequirementsAvailable,
} from '../../../utils/dashboardUtils'
import { generateCorrelationID } from '../../../utils/commonUtils'

// molecules
import FileUpload from '../FileUpload'
import WithLoader from '../WithLoader'

// styles.
import styles from './TasksList.styles'

// constant
import { REQUIREMENTS_UW, REQUIREMENTS_NB } from '../../../constants/application'
import { TASKS_STARTING_INDEX, NUMBER_OF_TASKS_TO_DISPLAY } from '../../../constants/taskDashboard'
import { FREE_TEXT } from '../../../constants/dashboard'

const Wrap = styled('div')(styles.base)
const PaginationContainer = styled('div')(styles.paginationContainer)

export class TasksList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFileUploadModel: false,
      activeAccordion: null,
      isSameAccordionOpen: false,
      showOnlyDownloadOption: false,
      documentDownloadLink: '',
      docSubTypeCode: null,
      documentTypeName: '',
      quoteCollectionId: '',
      agreementNumber: '',
      agreementReqId: '',
      pageSize: 20,
      pageIndex: 1,
      totalPage: 0,
      totalRecords: 0,
    }
  }

  componentWillMount() {
    const {
      actions: { getOutstandingRequirement, resetNavigationType, resetFooterType },
    } = this.props
    getOutstandingRequirement(
      {
        bancsAdviserCustomerNo: [getAdviserNo() || getBancsCustomerNumber()],
      },
      (err, data) => {
        if (!err) {
          const { pageSize } = this.state
          const totalRecords = get(data, 'businessData.totalRecords', 0)
          const totalPage = Math.ceil(totalRecords / pageSize)
          this.setState({ totalPage, totalRecords })
        }
      }
    )
    resetNavigationType()
    resetFooterType()
  }

  handleFreeTextSubmit = (freeTextValue, callback) => {
    const {
      quoteCollectionId,
      agreementReqId,
      docSubTypeCode,
      agreementNumber,
      question,
      lifeInsuredName,
    } = this.state
    const { advisorDetails } = this.props

    const {
      actions: { generateFreeText, toggleFileUploadModal },
    } = this.props
    const advisorName = get(advisorDetails[0], 'agencyName', '')
    const agencyCode = get(advisorDetails[0], 'agencyCode', '')
    const data = {
      workType: FREE_TEXT,
      agreementNumber,
      agreementReqId,
      docSubTypeCode,
      correlationID: generateCorrelationID(),
      freeformAnswerDetails: {
        advisorName,
        agencyCode,
        time: new Date(),
        applicationNumber: quoteCollectionId,
        lifeInsuredName,
        question,
        response: freeTextValue,
      },
    }
    generateFreeText(data, type => {
      if (type === 'success') {
        callback()
        toggleFileUploadModal(false)
      }
    })
  }

  renderFileUpload = () => {
    const { fields, isFreeTextFetching } = this.props
    const {
      modalHeading,
      modalConfirmButton,
      modalSubHeading,
      maxFileSizeError,
      maxFileSize,
      maxFileNumber,
      wrongFileType,
      fileUploading,
      fileUploadSuccess,
      fileUploadFailure,
      fileRemoveButtonLabel,
      createWorkItemError,
      createWorkItemSuccess,
      noFileUploadedError,
      wrongFileNameError,
      freeTextPlaceholder,
    } = fields
    const {
      docSubTypeCode,
      showOnlyDownloadOption,
      documentTypeName,
      agreementNumber,
      documentDownloadLink,
      agreementReqId,
    } = this.state
    const href = `<a href=${browserOrigin() + documentDownloadLink} target=_blank>Download</a>`
    return (
      <FileUpload
        isTaskUpload
        isCreateWorkItemDisabled
        isFreeTextBox
        isFreeTextFetching={isFreeTextFetching}
        handleFreeTextSubmit={this.handleFreeTextSubmit}
        agreementReqId={agreementReqId}
        showOnlyDownloadOption={showOnlyDownloadOption}
        freeTextRequirementPlaceholder={freeTextPlaceholder}
        agreementNumber={agreementNumber}
        formDownloadMeta={{
          documentName: documentTypeName,
          documentPath: {
            value: documentDownloadLink ? href : '',
          },
          docSubTypeCode,
        }}
        modalMeta={{
          modalHeading,
          modalSubHeading,
          modalConfirmButton,
        }}
        dropzoneMeta={{
          maxFileNumber,
          maxFileSize,
        }}
        fileUploadMeta={{
          maxFileSizeError,
          wrongFileType,
          fileUploading,
          fileUploadSuccess,
          fileUploadFailure,
          createWorkItemError,
          createWorkItemSuccess,
          fileRemoveButtonLabel,
          noFileUploadedError,
          wrongFileNameError,
        }}
        uploadDocumentRequest={{
          docSubTypeCode,
          agreementNumber,
          agreementReqId,
        }}
      />
    )
  }

  handleToggle = index => {
    const { activeAccordion, isSameAccordionOpen } = this.state
    if (activeAccordion === index) {
      this.setState({ isSameAccordionOpen: !isSameAccordionOpen })
    } else {
      this.setState({
        activeAccordion: index,
        isSameAccordionOpen: false,
      })
    }
  }

  handleActionButton = (quoteCollectionId, lifeInsuredName, requirement) => {
    const { masterList } = this.props
    const { ID, docSubTypeCode, description } = requirement
    this.setState(
      {
        documentTypeName: '',
        showOnlyDownloadOption: true,
        isFileUploadModel: true,
        agreementNumber: quoteCollectionId,
        agreementReqId: ID,
        docSubTypeCode,
        quoteCollectionId,
        question: description,
        lifeInsuredName,
      },
      () => handleDocumentToDownloadFilter(docSubTypeCode, this.handleSetDocumentTypes, masterList)
    )
  }

  handleSetDocumentTypes = (documentDownloadLink, documentTypeName) => {
    const {
      actions: { toggleFileUploadModal },
    } = this.props
    this.setState(
      {
        documentDownloadLink,
        documentTypeName,
      },
      () => toggleFileUploadModal(true)
    )
  }

  handleFilterOutstandingRequirements = (filterByPageNo?: Number) => {
    const { taskDashboard, createQuote, limited, isSingleApplication } = this.props
    const { pageSize } = this.state
    let outstandingRequirements = isSingleApplication
      ? get(createQuote, 'outstandingRequirements', [])
      : get(taskDashboard, 'records', []).filter(task => task.outstandingRequirements)
    if (
      outstandingRequirements &&
      !outstandingRequirements.some(requirement => requirement.quoteCollectionId)
    ) {
      const qcid = get(createQuote, 'quoteCollectionId', '')
      outstandingRequirements = outstandingRequirements.map(requirement => ({
        ...requirement,
        quoteCollectionId: qcid,
      }))
    }
    if (limited) {
      return outstandingRequirements.slice(TASKS_STARTING_INDEX, NUMBER_OF_TASKS_TO_DISPLAY)
    }
    return filterByPageNo
      ? outstandingRequirements.slice((filterByPageNo - 1) * pageSize, filterByPageNo * pageSize)
      : outstandingRequirements
  }

  nextPageClick = () => {
    this.setState(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }))
  }

  prevPageClick = () => {
    this.setState(prevState => ({ ...prevState, pageIndex: prevState.pageIndex - 1 }))
  }

  renderMultiApplicationTasks = () => {
    const { fields, taskDashboard, limited } = this.props
    const { actionButtonText } = fields
    const { activeAccordion, isSameAccordionOpen, totalPage, pageIndex } = this.state
    const isLoading = get(taskDashboard, 'isLoading', false)
    const applications = this.handleFilterOutstandingRequirements(pageIndex)
    return (
      <WithLoader isLoading={isLoading} loaderProps={{ type: 'tab' }}>
        {applications.map(
          (app, idx) =>
            app.outstandingRequirements.length > 0 &&
            isRequirementsAvailable(app) && (
              <TaskDashboardAccordion
                key={idMaker(app.lifeInsuredName + app.quoteCollectionId)}
                lifeAssured={app.lifeInsuredName}
                applicationId={app.quoteCollectionId}
                isOpen={isSameAccordionOpen ? false : activeAccordion === idx}
                toggleListener={() => this.handleToggle(idx)}
                noOfTask={getNoOfTasks(app.outstandingRequirements)}
              >
                {app.outstandingRequirements &&
                  app.outstandingRequirements.map(requirement => {
                    const { ID, dateRaised, status, type, description, comment, category } =
                      requirement
                    if (category === REQUIREMENTS_UW || category === REQUIREMENTS_NB) {
                      const { quoteCollectionId } = app
                      const { lifeInsuredName } = requirement
                      return (
                        <TaskRow
                          key={idMaker(ID)}
                          date={dateRaised}
                          status={status}
                          shouldShowLifeInsured={app.lifeInsuredName.includes(',')}
                          lifeInsuredName={lifeInsuredName}
                          title={type}
                          actionButtonText={actionButtonText}
                          handleActionButton={() =>
                            this.handleActionButton(quoteCollectionId, lifeInsuredName, requirement)
                          }
                          description={description}
                          comment={comment}
                          isPaddingBottom
                        />
                      )
                    }
                    return null
                  })}
              </TaskDashboardAccordion>
            )
        )}
        {!limited && applications.length > 0 ? (
          <PaginationContainer>
            <Pagination
              currentPage={pageIndex}
              totalPages={totalPage}
              nextPageClickHandler={pageIndex !== totalPage ? this.nextPageClick : undefined}
              previousPageClickHandler={pageIndex > 1 ? this.prevPageClick : undefined}
            />
          </PaginationContainer>
        ) : null}
      </WithLoader>
    )
  }

  renderSingleApplicationTasks = () => {
    const { fields, createQuote } = this.props
    const { actionButtonText } = fields
    const { quoteCollectionName } = createQuote
    return this.handleFilterOutstandingRequirements().map(requirement => {
      const {
        ID,
        dateRaised,
        status,
        type,
        description,
        comment,
        category,
        quoteCollectionId,
        lifeInsuredName,
      } = requirement
      if (category === REQUIREMENTS_UW || category === REQUIREMENTS_NB) {
        return (
          <TaskRow
            key={idMaker(ID)}
            date={dateRaised}
            status={status}
            title={type}
            actionButtonText={actionButtonText}
            handleActionButton={() =>
              this.handleActionButton(quoteCollectionId, lifeInsuredName, requirement)
            }
            description={description}
            comment={comment}
            hideActionButton={false}
            hideStatus
            isInvertColorTheme
            isPaddingBottom
            lifeInsuredName={lifeInsuredName}
            shouldShowLifeInsured={quoteCollectionName.includes(',')}
          />
        )
      }
      return null
    })
  }

  render() {
    const { isSingleApplication } = this.props
    const { isFileUploadModel } = this.state
    return (
      <Wrap>
        {isSingleApplication
          ? this.renderSingleApplicationTasks()
          : this.renderMultiApplicationTasks()}
        {isFileUploadModel && this.renderFileUpload()}
      </Wrap>
    )
  }
}

TasksList.propTypes = {
  // Sitecore authorable fields.
  fields: objectOf(oneOfType([string, number])).isRequired,
  // An object containing action creator functions.
  actions: objectOf(func).isRequired,
  // MasterList
  masterList: objectOf(any).isRequired,
  // Array of outstanding requirements
  taskDashboard: objectOf(any).isRequired,
  // adviser entity
  advisorDetails: arrayOf(object).isRequired,
  // fetching generate free text API call
  isFreeTextFetching: bool,
  createQuote: objectOf(any).isRequired,
  limited: bool,
  isSingleApplication: bool,
}

TasksList.defaultProps = {
  isFreeTextFetching: false,
  limited: false,
  isSingleApplication: false,
}

const mapStateToProps = ({
  taskDashboard: { taskDashboard },
  advisor: { advisorDetails },
  taskDashboard: { isFreeTextFetching },
  masterList,
  createQuote,
}) => ({
  taskDashboard,
  isFreeTextFetching,
  advisorDetails,
  masterList,
  createQuote,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksList)
