import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { colours, fontSize, fontWeights, pxToRem, space } = Variables as VariablesType

const styles = {
  wrapper: {
    '> div': {
      borderBottom: `1px solid ${colours.lightestGrey}`,
      '&:nth-last-of-type(1)': {
        borderBottom: 0,
      },
    },
  },
  editButton: {
    padding: 0,
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
  },
  sectionHeading: {
    fontSize: pxToRem(20),
  },
  policy: {
    marginBottom: space(3),
    fontWeight: fontWeights.semiBold,
    color: colours.tertiaryOne,
    fontSize: fontSize.sm,
  },
  emailAddress: {
    wordBreak: 'break-all' as const,
  },
  labelValuePair: {
    marginBottom: 0,
  },
}

export default styles
