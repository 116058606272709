/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconEdit16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.375L9.375 4l2.5 2.5L4.5 13.875H2v-2.5zM13.75 4.719l-1.25 1.25-2.5-2.5 1.25-1.25c.292-.292.594-.292.906 0l1.594 1.594c.292.312.292.614 0 .906z"
      fill="#000"
    />
  </Icon>
)

export default IconEdit16
