import { OktaAuth } from '@okta/okta-auth-js'
import { ADVISOR_PORTAL } from '../constants/site'
import { isFeatureEnabled } from './featureToggling'

export const authClient = (authConfig = {}, envConfig) => {
  const config = {
    url: envConfig.MLCL_OKTA_API,
    issuer: `${envConfig.MLCL_OKTA_API}/oauth2/${envConfig.MLCL_OKTA_AUTH_SERVER_ID}`,
    clientId: envConfig.MLCL_OKTA_CLIENT_ID,
    redirectUri: envConfig.MLCL_OKTA_REDIRECT_URI,
    pkce: false,
    // Override the default authorize URL
    authorizeUrl: `${envConfig.MLCL_OKTA_API}/oauth2/${envConfig.MLCL_OKTA_AUTH_SERVER_ID}/v1/authorize`,
    tokenManager: {
      storage: 'cookie',
    },
    ...authConfig,
  }

  return new OktaAuth(config)
}
/**
 *
 * @param {*} tokenExpiry: token expiration time in seconds
 * @param {*} tokenRefreshTime: refresh token before expiration secondss
 */

export const validateTokenExpiry = (tokenExpiry, tokenRefreshTime) =>
  Boolean(tokenExpiry) && tokenExpiry - Math.round(Date.now() / 1000) <= 60 * tokenRefreshTime

export const checkIfActiveSession = config => {
  const isActive = authClient({}, config).session.exists()
  return isActive
}

export const configurePrimaryAuthClient = async (authConfig = {}, envConfig) => {
  const config = {
    issuer: `${envConfig.MLCL_OKTA_API}/oauth2/${envConfig.MLCL_OKTA_AUTH_SERVER_ID}`,
    clientId: envConfig.MLCL_OKTA_CLIENT_ID,
    redirectUri: envConfig.MLCL_OKTA_REDIRECT_URI,
    responseTypes: ['token'],
    pkce: isFeatureEnabled('SignInRedirect', envConfig.FEATURES),
    scopes: [envConfig.SITE === ADVISOR_PORTAL ? 'advisor' : 'customer'],
    ...authConfig,
    tokenManager: {
      storage: 'cookie',
    },
  }
  window.authClient = new OktaAuth(config)
  await window.authClient.start()
}
