// @flow
import queryString from 'query-string'
import { isBrowser } from './browserUtils'

/**
 * First check if a querystring of ?feature[FEATURE_NAME] is present and
 * will override whats defined in the feature list
 * otherwise if no querystring is present it will take the value from the feature list.
 * Will return false if no feature is present
 * @param {*} feature name of the feature to check if enabled
 * @param {*} features feature list from config state
 * @returns true if feature is enabled otherwise false
 */
export const isFeatureEnabled = (feature: String, features: Object): Boolean => {
  const params = isBrowser() ? queryString.parse(window.location.search) : {}
  if (params[`feature${feature}`]) {
    return params[`feature${feature}`] === 'true'
  }
  return !!features && !!features[feature]
}
