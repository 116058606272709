/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconInfo32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1783 22.4609C12.9348 22.4609 12.6913 22.3696 12.5087 22.2174C12.2043 21.9739 12.0522 21.5783 12.1435 21.1826L13.6348 13.5739L12.813 14.0304C12.2957 14.3043 11.6565 14.1522 11.3522 13.6348C11.0783 13.1174 11.2304 12.4783 11.7478 12.1739L14.5783 10.5609C14.9435 10.3478 15.4 10.3783 15.7348 10.6217C16.0696 10.8652 16.2217 11.2913 16.1609 11.687L14.6087 19.6L15.887 19.0522C16.4348 18.8087 17.0435 19.0522 17.287 19.6C17.5304 20.1478 17.287 20.7565 16.7391 21L13.6043 22.3696C13.4826 22.4304 13.3304 22.4609 13.1783 22.4609ZM16.1913 7.91304C16.4652 7.63913 16.6478 7.24348 16.6478 6.84783C16.6478 6.45217 16.4957 6.05652 16.1913 5.78261C15.9174 5.5087 15.5217 5.32609 15.1261 5.32609C14.7304 5.32609 14.3348 5.47826 14.0609 5.78261C13.787 6.05652 13.6043 6.45217 13.6043 6.84783C13.6043 7.24348 13.7565 7.63913 14.0609 7.91304C14.3348 8.18696 14.7304 8.36957 15.1261 8.36957C15.5217 8.36957 15.9174 8.21739 16.1913 7.91304ZM28 14C28 6.26957 21.7304 0 14 0C6.26957 0 0 6.26957 0 14C0 21.7304 6.26957 28 14 28C21.7304 28 28 21.7304 28 14ZM25.5652 14C25.5652 20.3913 20.3913 25.5652 14 25.5652C7.6087 25.5652 2.43478 20.3913 2.43478 14C2.43478 7.6087 7.6087 2.43478 14 2.43478C20.3913 2.43478 25.5652 7.6087 25.5652 14Z"
      fill="#000"
    />
  </Icon>
)

export default IconInfo32
