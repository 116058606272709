// @flow
// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  ADDRESS_LOOKUP_FETCH,
  ADDRESS_LOOKUP_INIT,
  ADDRESS_LOOKUP_CLEAR,
  ADDRESS_SET_MANUAL,
} from '../types/addressLookup'

// constants.
import { EXPERIENCE_API_ADDRESS_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const addressLookupInit = (fieldName: string, isManual: boolean) => ({
  type: ADDRESS_LOOKUP_INIT,
  payload: {
    fieldName,
    isManual,
  },
})

export const addressLookupInProgress = (fieldName: string) => ({
  type: ADDRESS_LOOKUP_FETCH,
  payload: {
    fieldName,
  },
})

export const addressLookupClear = (fieldName: string) => ({
  type: ADDRESS_LOOKUP_CLEAR,
  payload: {
    fieldName,
  },
})

export const setAdddressToManual = (fieldName: string, isManual: boolean) => ({
  type: ADDRESS_SET_MANUAL,
  payload: {
    fieldName,
    isManual,
  },
})

export const getAddresses = (fullAddress: string, fieldName: string) => dispatch => {
  if (fullAddress === '') {
    dispatch(addressLookupClear(fieldName))
    return
  }
  dispatch(addressLookupInProgress(fieldName))
  dispatch({
    type: EXPERIENCE_API,
    name: ADDRESS_LOOKUP_FETCH,
    verb: 'POST',
    data: {
      payload: [
        {
          fullAddress,
        },
      ],
    },
    errorPayload: {
      fieldName,
    },
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_ADDRESS_BASE}`,
    callback: (err, dataReceived) => {
      const {
        _embedded: { addresses },
      } = dataReceived
      return { fieldName, addresses: addresses || [] }
    },
  })
}
