/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChat32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.822 19H25V8H7v11h3v3.249L16.822 19zM7 27v-5a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3h-7.5L7 27z"
      fill="#000"
    />
  </Icon>
)

export default IconChat32
