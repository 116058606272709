// @FIXME: need to use better key values
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { SidebarActionItem } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import MemberList from '../MemberList'

// constants
import {
  POLICY_RELATIONSHIPS_PAYER,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_RELATIONSHIPS_MEMBER,
  POLICY_PAYER_BUSINESS_PARTY,
  SUPER_OWNER_INTERNAL,
  SUPER_OWNER_IOOF,
  WRAPPER_NAVIGATOR_ID,
  POLICY_MEMBER_INDIVIDUAL,
  POLICY_MEMBER_BUSINESS,
  POLICY_RELATIONSHIPS_SMSF,
} from '../../../constants/policies'

import { SMSF_SUPER_FUND_NAME, MASTERKEY_PAYMENT_METHOD } from './constants'

// styles.
import styles from './addPayers.styles'
import { getActivePolicy, getPaymentMode, getIdentifier } from '../../../utils/quoteUtils'
import { REF_NO } from '../../../constants/adviser'

type addPayersProps = {
  handleNewClient: Function,
  // To render all labels from sitecore content editor
  fields: {},
  // create Quote from store
  createQuote: Object,
  // Redux actions available to the component.
  actions: {
    deleteMemberDetails: (relatedMember: Object) => void,
    closeSidebar: () => void,
  },
  // properties of sidebar
  sidebar: Object,
  abnLookup: Object,
  // form ID to delete
  deleteFormIds: Array<string>,
}

const SidebarItem = styled(SidebarActionItem)(styles.sidebarItem)
const Wapper = styled('section')(styles.wrapper)
const MemberListContainer = styled(MemberList)(styles.memberListContainer)

export class AddPayers extends Component<addPayersProps> {
  componentDidMount() {
    const {
      actions: { deleteForms, setNextTabPanel, resetAbnLookup },
      deleteFormIds,
      sidebar: { currentPanel, nextTabSideBarPanel },
      abnLookup: { error, isLoading },
    } = this.props
    if (currentPanel === 0 && deleteFormIds) {
      deleteForms(deleteFormIds)
      nextTabSideBarPanel !== 0 && setNextTabPanel(0)
    }
    if (error || isLoading) {
      resetAbnLookup()
    }
  }

  getMemberDetails = policy =>
    policy &&
    policy.relationships &&
    policy.relationships.find(member => member.role.includes(POLICY_RELATIONSHIPS_PAYER))

  removeHandler =
    ({ deleteIdentifier: identifier, company }) =>
    () => {
      const {
        actions: { deleteMemberDetails, closeSidebar, displayCreditCardListing },
        sidebar,
      } = this.props
      const policyInstanceNo = getActivePolicy(sidebar)

      deleteMemberDetails(identifier, policyInstanceNo, POLICY_RELATIONSHIPS_PAYER, company)
      displayCreditCardListing(false)
      closeSidebar()
    }

  addRoleToExistingMember = (goToSubPanel, identifier, roles?) => () => {
    const {
      handleNewClient,
      actions: { setPanelProps },
    } = this.props
    setPanelProps({ payerType: identifier, activeAbnNumber: identifier.activeAbnNumber })
    handleNewClient(goToSubPanel)()
    this.handlePolicyOwner(roles, true)
  }

  editPayer =
    ({ role, roles }) =>
    () => {
      const {
        handleNewClient,
        createQuote,
        actions: { setPanelProps },
        sidebar,
      } = this.props
      const { quotes, activeIndex } = createQuote
      const { policyStructure } = quotes[activeIndex]
      const policyInstanceNo = getActivePolicy(sidebar)
      const currentPolicy = policyStructure.find(
        policy => policy.policyInstanceNo === policyInstanceNo
      )
      const relatedMember = this.getMemberDetails(currentPolicy)
      const company = role === POLICY_MEMBER_BUSINESS
      const relatedParty = relatedMember && relatedMember.relatedParty

      const sidebarIdentifier = get(sidebar, 'panelProps.payerType.identifier.value')

      if (
        relatedParty &&
        !relatedParty.identifiers.some(identifier => identifier.value === sidebarIdentifier)
      ) {
        setPanelProps({
          payerType: {
            identifier: relatedParty.identifiers[0],
          },
        })
      }

      if (
        relatedMember.role &&
        (relatedMember.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) ||
          relatedMember.role.includes(POLICY_RELATIONSHIPS_SMSF))
      ) {
        handleNewClient(2)()
      } else {
        handleNewClient(company ? 1 : 0)()
      }
      this.handlePolicyOwner(roles)
    }

  handlePolicyOwner(roles, addNew) {
    const {
      actions: { setPanelProps },
    } = this.props
    if (addNew && roles && roles.includes(POLICY_RELATIONSHIPS_OWNER)) {
      setPanelProps({ moveDefaultToPayment: true })
    }
  }

  render() {
    const { fields, createQuote, sidebar } = this.props

    const { quotes, activeIndex } = createQuote
    const { policyStructure } = quotes[activeIndex]
    const policyInstanceNo = getActivePolicy(sidebar)
    const currentPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === policyInstanceNo
    )
    const relatedMember = this.getMemberDetails(currentPolicy)
    const companyBusinessName = relatedMember && relatedMember.relatedParty.businessName
    const relatedParty = relatedMember && relatedMember.relatedParty
    const activePolicyType = get(sidebar, 'panelProps.productId', null)
    const { superFundName, fundPaymentMethod } = getPaymentMode(createQuote)
    const isSuperPolicy = activePolicyType === POLICY_PRODUCT_CODE_SUPER
    const isWrapSuperSMSF = isSuperPolicy && superFundName === SMSF_SUPER_FUND_NAME
    const isAssociatedWithSuperFund =
      isSuperPolicy &&
      ((superFundName === SUPER_OWNER_INTERNAL && fundPaymentMethod === WRAPPER_NAVIGATOR_ID) ||
        superFundName === SUPER_OWNER_IOOF)

    const isEMASuperInternal =
      isSuperPolicy &&
      superFundName === SUPER_OWNER_INTERNAL &&
      fundPaymentMethod === MASTERKEY_PAYMENT_METHOD

    return (
      <Wapper>
        {relatedMember && (
          <MemberListContainer
            caption="Individual"
            members={[
              {
                name: companyBusinessName || `${relatedParty.firstName} ${relatedParty.lastName}`,
                editIdentifier: relatedMember.role,
                deleteIdentifier: getIdentifier(relatedMember),
                role: companyBusinessName ? POLICY_MEMBER_BUSINESS : POLICY_MEMBER_INDIVIDUAL,
                ...relatedMember.relatedParty,
              },
            ]}
            editHandler={this.editPayer}
            removeHandler={this.removeHandler}
            referMember
          />
        )}
        {!relatedMember &&
          currentPolicy &&
          currentPolicy.relationships &&
          currentPolicy.relationships
            .filter(relationship => relationship.relatedParty)
            .map((relationship, index) => {
              const companyBusinessNameIn = get(relationship, 'relatedParty.businessName')
              const firstName = get(relationship, 'relatedParty.firstName')
              const lastName = get(relationship, 'relatedParty.lastName')
              const isSMSFEntity = relationship.role.includes(POLICY_RELATIONSHIPS_SMSF)
              if (
                !companyBusinessNameIn &&
                relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) &&
                !isWrapSuperSMSF
              ) {
                return (
                  <SidebarItem
                    key={index}
                    title={
                      companyBusinessNameIn
                        ? `Add ${companyBusinessNameIn}`
                        : `Add ${firstName} ${lastName} ${fields.addLifeInsuredLabelSuffix.value}`
                    }
                    clickHandler={this.addRoleToExistingMember(companyBusinessNameIn ? 1 : 2, {
                      identifier: getIdentifier(relationship),
                      activeAbnNumber: companyBusinessNameIn ? companyBusinessNameIn.abn : '',
                    })}
                  />
                )
              }
              if (isWrapSuperSMSF && isSMSFEntity) {
                return (
                  <SidebarItem
                    key={index}
                    title={
                      companyBusinessNameIn
                        ? `Add ${companyBusinessNameIn}`
                        : `Add ${firstName} ${lastName} ${fields.addLifeInsuredLabelSuffix.value}`
                    }
                    clickHandler={this.addRoleToExistingMember(2, {
                      identifier: getIdentifier(relationship),
                      activeAbnNumber: companyBusinessNameIn ? companyBusinessNameIn.abn : '',
                    })}
                  />
                )
              }
              if (
                !isWrapSuperSMSF &&
                !isAssociatedWithSuperFund &&
                relationship.role.includes(POLICY_RELATIONSHIPS_OWNER)
              ) {
                return (
                  <SidebarItem
                    key={index}
                    title={
                      companyBusinessNameIn
                        ? `Add ${companyBusinessNameIn}`
                        : `Add ${firstName} ${lastName}`
                    }
                    clickHandler={this.addRoleToExistingMember(
                      companyBusinessNameIn ? 1 : 0,
                      {
                        identifier: getIdentifier(relationship),
                        activeAbnNumber: companyBusinessNameIn ? companyBusinessNameIn.abn : '',
                      },
                      [POLICY_RELATIONSHIPS_OWNER]
                    )}
                  />
                )
              }
              if (
                !isWrapSuperSMSF &&
                !isAssociatedWithSuperFund &&
                relationship.role.includes(POLICY_RELATIONSHIPS_MEMBER)
              ) {
                return (
                  <SidebarItem
                    key={index}
                    title={
                      companyBusinessNameIn
                        ? `Add ${companyBusinessNameIn}`
                        : `Add ${firstName} ${lastName}`
                    }
                    clickHandler={this.addRoleToExistingMember(companyBusinessNameIn ? 1 : 0, {
                      identifier: getIdentifier(relationship),
                      activeAbnNumber: companyBusinessNameIn ? companyBusinessNameIn.abn : '',
                    })}
                  />
                )
              }
              return ''
            })}

        {!relatedMember && (
          <Fragment>
            {!isEMASuperInternal && !isWrapSuperSMSF && !isAssociatedWithSuperFund && (
              <Fragment>
                <SidebarItem
                  title={fields.addIndividualActionLabel.value}
                  clickHandler={this.addRoleToExistingMember(0, {
                    identifier: {
                      role: REF_NO,
                      value: '',
                    },
                    activeAbnNumber: companyBusinessName ? companyBusinessName.abn : '',
                  })}
                />
                <SidebarItem
                  title={fields.addBusinessActionLabel.value}
                  clickHandler={this.addRoleToExistingMember(1, {
                    identifier: {
                      role: POLICY_PAYER_BUSINESS_PARTY,
                      value: '',
                    },
                    activeAbnNumber: companyBusinessName ? companyBusinessName.abn : '',
                  })}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Wapper>
    )
  }
}

export const mapStateToProps = ({ createQuote, sidebar, abnLookup }) => ({
  createQuote,
  sidebar,
  abnLookup,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPayers)
