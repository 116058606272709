export const POLICY_ANNIVERSARY_ID = 'policy-anniversary'
export const RISK_OF_LAPSE_ID = 'missed-payment'
export const RECENTLY_LAPSE_ID = 'lapsed'
export const RECENTLY_CANCELLED_ID = 'cancelled'

export const RETENTION_DASHBOARD_URL = '/retention-dashboard'
export const TASK_DASHBOARD_URL = '/task-dashboard'
export const MIN_ROW = 0
export const MAXIMUM_PAGE_SIZE = 20
export const RESET_PAGINATION_PAGE_INDEX = 0

export const POLICY_OWNER = 'Policy Owner'
export const POLICY_NO = 'Policy Number'
export const PREMIUM = 'Premium'
export const ANNIVERSARY_DATE = 'Anniversary date'
export const PAYMENT_OUTSTANDING = 'Payment outstanding'
export const PRODUCT_TYPE = 'Policy type'
export const PAYMENT_FREQUENCY = 'Payment frequency'
export const PAYMENT_METHOD = 'Payment Method'
export const SCHEDULED_LAPSE_DATE = 'Scheduled lapse date'
export const LAPSED_DATE = 'Lapsed date'
export const CANCELLED_DATE = 'Cancelled date'

export const FREE_TEXT = 'FREE_TEXT'
export const RETENTION_DASHBOARD_ANNIVERSARY = 'Anniversary'
export const RETENTION_DASHBOARD_RISK_OF_LAPSE = 'RiskOfLapse'
export const RETENTION_DASHBOARD_RECENTLY_LAPSE = 'Lapsed'
export const RETENTION_DASHBOARD_RECENTLY_CANCELLED = 'Cancelled'
