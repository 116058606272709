// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'
import { createEvent } from '../../../../../../utils/telemetry'

// components.
import TemplateItem from '../../TemplateItem'
import ManageTemplates from '../ManageTemplates'
import Heading from '../../../../../atoms/Heading'
import EditTemplateItem from './EditItem'

// utils.
import { convertStringToKey, deepEqual } from '../../../../../../utils/commonUtils'
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import {
  convertCoverToEntityStructure,
  getProduct,
  getBenefit,
  filterPolicyStructure,
  postProcessInitialPolicyStructure,
} from '../../../../../../utils/quoteUtils'

// constants
import {
  POLICY_PRODUCT_CODE_SUPER,
  UPDATE_FROM_LIFE_INSURED,
} from '../../../../../../constants/policies'

// styles.
import styles from './templateList.styles'
import { checkQuoteToolPolicyContainHealthyLiving } from '../../../../../../utils/extendedQuoteUtils'

const Container = styled('div')(styles.container)
const Wrap = styled('div')(styles.wrap)
export const SubHeading = styled(Heading)(styles.subHeading)

type TemplateListProps = {
  // advisor managed templates list
  advisorManagedTemplates: Array<Object>,
  // redux actions
  actions: {
    // rename advisor template
    renameAdviserTemplate: Function,
    // delete advisor template
    deleteAdviserTemplate: Function,
    // update Healthy living toggle option
    createQuoteUpdateOption: Function,
  },
  // sitecore fields
  fields: Object<Object>,
  // history
  history: Object,
  // sidebar callback
  handleNext: Function,
  // product rules
  productRules: Array<Object>,
  // mandatory details filled by user.
  memberMandatories: Object,
  // advisor details
  advisorDetails: Object,
  // master data
  masterData: Object,
  calculateSaveQuote: Object,
  // value of Health Living toggle button
  option: Object,
}

type TemplateListState = {
  // enable/disable template list edit mode
  isTemplateListEditModeActive: boolean,
}
export class TemplateList extends Component<TemplateListProps, TemplateListState> {
  constructor(props) {
    super(props)
    this.state = {
      isTemplateListEditModeActive: false,
    }
  }

  // check if template item is duplicate
  duplicateCheck = value => {
    const { advisorManagedTemplates } = this.props
    const duplicateTemplate = advisorManagedTemplates.findIndex(
      template => template.templateName === value && template.templateName.trim() === value.trim()
    )

    return !!((duplicateTemplate || duplicateTemplate === 0) && duplicateTemplate !== -1)
  }

  handleUpdate = (oldTemplateName, newTemplateName) => {
    const {
      actions: { renameAdviserTemplate },
    } = this.props

    if (oldTemplateName !== newTemplateName) {
      renameAdviserTemplate({ oldTemplateName, newTemplateName })
    }
  }

  handleDeleteItem = templateName => {
    const {
      actions: { deleteAdviserTemplate },
    } = this.props

    deleteAdviserTemplate({
      templateName,
    })
  }

  renderEditTemplateItemList = () => {
    const { advisorManagedTemplates, fields } = this.props

    return advisorManagedTemplates.map(template => {
      const { templateName } = template
      const templateKey = convertStringToKey(templateName)
      return (
        <li key={templateKey}>
          <EditTemplateItem
            key={templateKey}
            templateId={templateKey}
            title={templateName}
            itemData={template}
            fields={fields}
            onDeleteHandler={this.handleDeleteItem}
            onUpdateHandler={this.handleUpdate}
            duplicateCheck={this.duplicateCheck}
          />
        </li>
      )
    })
  }

  renderEmptyMessage = ({ yourTemplatesNoTempLabel, yourTemplatesNoTempDesc }) => (
    <Wrap>
      <Heading size="6">
        <Text field={yourTemplatesNoTempLabel} />
      </Heading>
      <SubHeading size="6">
        <Text field={yourTemplatesNoTempDesc} />
      </SubHeading>
    </Wrap>
  )

  clickHandler = ({ policyStructure }) => {
    const {
      actions: {
        closeSidebar,
        createQuoteSetPolicyStructure,
        setIPSumInsured,
        createQuoteUpdateOption,
      },
      history,
      handleNext,
      productRules,
      advisorDetails,
      memberMandatories,
      option: quoteOption,
      masterData,
      calculateSaveQuote: { isIPCalculated },
    } = this.props
    const isContainHealthyLiving = checkQuoteToolPolicyContainHealthyLiving(policyStructure)
    const formattedPolicyStructure = policyStructure.map(policy => {
      const { productId, covers } = policy
      const formattedProduct = getProduct(productRules, productId)
      const formattedCovers =
        covers.length &&
        covers.map(cover => {
          const getCoverDetails = getBenefit(formattedProduct.benefits, cover.type)
          // return cover in headlessQuoteEntity form
          const getCoverHeadlessEntityStructure = convertCoverToEntityStructure(
            getCoverDetails,
            undefined,
            advisorDetails,
            undefined,
            undefined,
            memberMandatories,
            quoteOption,
            policy
          )
          return {
            ...getCoverHeadlessEntityStructure,
            ...cover,
          }
        })

      return {
        ...policy,
        covers: formattedCovers,
      }
    })
    const { updatedPolicyStructure, calculatedsumInsured } = postProcessInitialPolicyStructure(
      productRules,
      memberMandatories,
      formattedPolicyStructure,
      masterData,
      isIPCalculated
    )
    setIPSumInsured(calculatedsumInsured)
    createQuoteSetPolicyStructure(updatedPolicyStructure)
    // updating option in store based on policy containing healthy living campaign
    createQuoteUpdateOption({
      healthyLivingDiscount: isContainHealthyLiving,
    })

    const tagEvent = createEvent({
      GA: {
        category: 'Adviser managed templates',
        action: 'Select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Adviser managed templates',
        },
      },
    })
    tagEvent.end()

    if (policyStructure.filter(e => e.productId === POLICY_PRODUCT_CODE_SUPER).length) {
      handleNext()
    } else {
      history.push('/quote-tool')
      closeSidebar()
    }
  }

  renderTemplateItemList = () => {
    const { advisorManagedTemplates, fields, productRules, memberMandatories, masterData } =
      this.props
    const { yourTemplatesViewDetails, yourTemplatesCloseDetails } = reduceAuthorableFields(fields)

    return (
      !!advisorManagedTemplates.length &&
      advisorManagedTemplates.map(template => {
        const { templateName, policyStructure } = template
        const templateKey = convertStringToKey(templateName)

        const updatedPolicyStructure = filterPolicyStructure(
          policyStructure,
          memberMandatories,
          productRules,
          UPDATE_FROM_LIFE_INSURED,
          masterData
        )
        const isTemplatedDisabled = !deepEqual(policyStructure, updatedPolicyStructure)

        return (
          <li key={templateKey}>
            <TemplateItem
              id={templateKey}
              templateName={templateName}
              templateDisabled={isTemplatedDisabled}
              viewDetailsLabel={yourTemplatesViewDetails}
              closeDetailsLabel={yourTemplatesCloseDetails}
              handleTemplateClick={this.clickHandler}
              policyStructure={policyStructure}
              productRules={productRules}
            />
          </li>
        )
      })
    )
  }

  toggleTemplateListEditMode = () => {
    const { isTemplateListEditModeActive } = this.state
    this.setState({ isTemplateListEditModeActive: !isTemplateListEditModeActive })
  }

  render() {
    const { isTemplateListEditModeActive } = this.state
    const { advisorManagedTemplates, fields } = this.props
    const { yourTemplatesNoTempLabel, yourTemplatesNoTempDesc } = fields
    if (!advisorManagedTemplates.length)
      return this.renderEmptyMessage({ yourTemplatesNoTempLabel, yourTemplatesNoTempDesc })

    return (
      <Container>
        <ManageTemplates
          isTemplateListEditModeActive={isTemplateListEditModeActive}
          toggleTemplateListEditMode={this.toggleTemplateListEditMode}
          fields={fields}
        />
        <ul>
          {!isTemplateListEditModeActive
            ? this.renderTemplateItemList()
            : this.renderEditTemplateItemList()}
        </ul>
      </Container>
    )
  }
}

export default withRouter(TemplateList)
