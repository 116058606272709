/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconHeart16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M8 5l-.949.316H8.95L8 5zm.949-.316a7.33 7.33 0 0 0-1.198-2.19C7.164 1.772 6.24 1 5 1v2c.359 0 .77.228 1.2.756.415.512.714 1.148.851 1.56l1.898-.632zM5 1c-1.24 0-2.465.46-3.39 1.232C.68 3.007 0 4.146 0 5.5h2c0-.646.32-1.257.89-1.732A3.369 3.369 0 0 1 5 3V1zM0 5.5c0 .496.145 1 .335 1.464.195.473.464.965.773 1.453.617.977 1.437 2.006 2.272 2.938a27.223 27.223 0 0 0 2.454 2.428c.37.316.722.59 1.033.793.154.1.317.195.479.268.14.063.377.156.654.156v-2c.152 0 .226.046.167.02a1.725 1.725 0 0 1-.21-.121 8.39 8.39 0 0 1-.823-.635A25.267 25.267 0 0 1 4.87 10.02c-.79-.88-1.533-1.82-2.072-2.672a7.698 7.698 0 0 1-.614-1.146C2.043 5.86 2 5.63 2 5.5H0zm8.949-.184a5.339 5.339 0 0 1 .852-1.56C10.23 3.228 10.64 3 11 3V1c-1.241 0-2.164.772-2.75 1.494a7.33 7.33 0 0 0-1.199 2.19l1.898.632zM11 3c.76 0 1.535.29 2.11.768.57.475.89 1.086.89 1.732h2c0-1.354-.68-2.493-1.61-3.268A5.368 5.368 0 0 0 11 1v2zm3 2.5c0 .129-.043.359-.184.702-.138.334-.345.72-.614 1.146-.54.852-1.282 1.791-2.072 2.672a25.26 25.26 0 0 1-2.264 2.244 8.39 8.39 0 0 1-.823.635 1.725 1.725 0 0 1-.21.121c-.059.027.015-.02.167-.02v2c.277 0 .515-.093.654-.156a3.64 3.64 0 0 0 .48-.268c.31-.202.663-.477 1.032-.793a27.22 27.22 0 0 0 2.454-2.428c.835-.932 1.655-1.961 2.272-2.938a9.67 9.67 0 0 0 .773-1.453C15.855 6.5 16 5.996 16 5.5h-2z"
      fill="#000"
    />
  </Icon>
)

export default IconHeart16
