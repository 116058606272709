export const MEDICAL_EVIDENCE = 'Medical Evidence'
export const CERTIFIED_ID = 'Certified ID'
export const FINANCIAL_EVIDENCE = 'Financial evidence'
export const TASKS_STARTING_INDEX = 0
export const NUMBER_OF_TASKS_TO_DISPLAY = 5

export const PREFIX_KEY = 'Outstanding Requirements'
export const KEY_SEPARATOR = '_'

export const POLICY_OWNER = 'Policy Owner'
export const POLICY_OWNER_KEY = 'policyOwner'
export const LIFE_INSURED = 'Life Insured Name'
export const LIFE_INSURED_KEY = 'lifeInsuredName'
export const QUOTE_COLLECTION_ID = 'Quote Collection Id'
export const QUOTE_COLLECTION_ID_KEY = 'quoteCollectionId'
export const APPLICATION_TYPE = 'Application Type'
export const APPLICATION_TYPE_KEY = 'applicationType'

export const OUTSTANDING_REQ_ID = 'ID'
export const OUTSTANDING_REQ_POLICY_NO = 'policyNo'
export const OUTSTANDING_REQ_TYPE = 'type'
export const OUTSTANDING_REQ_COMMENT = 'comment'
export const OUTSTANDING_REQ_CATEGORY = 'category'
export const OUTSTANDING_REQ_DOC_NAME = 'docName'
export const OUTSTANDING_REQ_DOC_SUB_TYPE_CODE = 'docSubTypeCode'
export const OUTSTANDING_REQ_STATUS = 'status'
export const OUTSTANDING_REQ_DATE_RAISED = 'dateRaised'
export const OUTSTANDING_REQ_BANCS_POLICY_NO = 'bancsPolicyNo'
export const OUTSTANDING_REQ_DESCRIPTION = 'description'

const KEYS_TO_MAP = [
  OUTSTANDING_REQ_ID,
  OUTSTANDING_REQ_POLICY_NO,
  OUTSTANDING_REQ_TYPE,
  OUTSTANDING_REQ_COMMENT,
  OUTSTANDING_REQ_CATEGORY,
  OUTSTANDING_REQ_DOC_NAME,
  OUTSTANDING_REQ_DOC_SUB_TYPE_CODE,
  OUTSTANDING_REQ_STATUS,
  OUTSTANDING_REQ_DATE_RAISED,
  OUTSTANDING_REQ_BANCS_POLICY_NO,
  OUTSTANDING_REQ_DESCRIPTION,
]

export const HEADER_ENTITY = {
  prefix: PREFIX_KEY,
  keys: KEYS_TO_MAP,
}
