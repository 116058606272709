// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'

// redux.
import { connect } from 'react-redux'

// styles.
import styles from './tabBar.styles'

// components.
import Tab from './components/Tab'
import Dropdown from './components/Dropdown'

// util to get the index of object taht matches a given hash
const getTabByIdHash = (id, schema) => {
  const index = schema.findIndex(tab => tab.id === id)
  return index > -1 ? index : 0
}

type TabBarProps = {
  // Schema for the tabs and each of their related components.
  schema: Array<{
    title: string,
    id: string,
    component: Node,
    // hide tab bar on condition
    isVisible: boolean,
    // component properties
    props: Object,
  }>,
  // A given function to be fired on tab change.
  onTabChange?: Function,
  // Set the size of the tabs for the tab bar.
  tabSize?: 'sm' | 'lg',
  // Set if sabs fit full width of tab bar
  spaceEvenly?: Boolean,
  // Access to url parameters via react router.
  location: {
    hash: string,
  },
  // current active tab
  currentTab: number,
  renderFoot: Function,
}

const Tabs = styled('ul')(({ spaceEvenly, size }) => styles.tabs(spaceEvenly, size))

export class TabBar extends Component<TabBarProps> {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.changeHandler = this.changeHandler.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.setFormTouched = this.setFormTouched.bind(this)
  }

  componentWillMount() {
    const { schema, onTabsLoad, location } = this.props
    const { hash } = location
    const currentTab = getTabByIdHash(hash, schema)
    if (onTabsLoad) onTabsLoad(currentTab)
  }

  setFormTouched(isTouched) {
    this.setState({ isTouched })
  }

  changeHandler(index, id) {
    const { onTabChange, currentTab, schema } = this.props
    const { isTouched } = this.state
    let isOk = false
    // @FIXME: need to determine if confirm should be used here
    // eslint-disable-next-line no-alert
    if (isTouched) isOk = window.confirm(schema[currentTab].props.fields.unsavedDataPopupLabel)
    if (isOk) {
      this.setState({ isOpen: false, isTouched: false })
      // if click OK for the native browser pop up, then change tab
      if (onTabChange) onTabChange(index, id)
    }
    if (index !== currentTab && !isTouched) {
      this.setState({ isOpen: false })
      // if form is not touched, then change tab
      if (onTabChange) onTabChange(index, id)
    }
  }

  handleToggle() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const { isOpen } = this.state
    const { schema, tabSize, spaceEvenly, disableHash, currentTab, renderFoot } = this.props
    const { title: dropdownText, component: Panel, props } = schema[currentTab] || schema[0]

    return (
      <div>
        <Dropdown text={dropdownText} isOpen={isOpen} changeHandler={this.handleToggle}>
          <Tabs spaceEvenly={spaceEvenly} size={tabSize}>
            {schema.map(
              ({ title, id, isVisible, dataLocator, disabled }, i) =>
                isVisible && (
                  <li key={`tab-${id}`}>
                    <Tab
                      index={i}
                      hash={`${disableHash ? '' : id}`}
                      selected={i === currentTab}
                      clickHandler={disabled ? () => {} : this.changeHandler}
                      size={tabSize}
                      dataLocator={dataLocator}
                      disabled={disabled}
                      id={id}
                    >
                      {title}
                    </Tab>
                  </li>
                )
            )}
          </Tabs>
        </Dropdown>
        {Panel && <Panel {...props} setFormTouched={this.setFormTouched} renderFoot={renderFoot} />}
      </div>
    )
  }
}

TabBar.defaultProps = {
  onTabChange: null,
  onTabsLoad: null,
  tabSize: 'lg',
  spaceEvenly: false,
}

export default connect()(withRouter(TabBar))
