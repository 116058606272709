// @flow
import {
  ADD_LOADING,
  DELETE_LOADING,
  EDIT_LOADING,
  UPDATE_LOADING,
  SET_PRE_ASSESSMENT_OPEN_MODE,
  RESET_PRE_ASSESSMENT_OPEN_MODE,
  SAVE_LOADINGS,
} from '../actions/types/preAssessment'
import { GET_RESULTS_OF_URE_SUCCESS } from '../actions/types/ure'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

import { benefitList } from '../components/organisms/CreateQuote/components/PreAssessment/PreAssessmentTab'
import { applyLoadings } from '../utils/preAssessmentBenefitUtils'
import { PREASSESSMENT_AUTOMATED_UNDERWRITER_NAME } from '../constants/benefit'

export const initialFieldState = {
  loadings: [],
  mode: 'default',
  underwriterName: '',
  activeEditIndex: null,
}

const preAssessment = (state = initialFieldState, action) => {
  switch (action.type) {
    case ADD_LOADING: {
      return { ...state, loadings: [...state.loadings, action.payload] }
    }
    case EDIT_LOADING: {
      const { payload } = action
      return {
        ...state,
        activeEditIndex: payload,
      }
    }
    case UPDATE_LOADING:
    case DELETE_LOADING: {
      return { ...state, loadings: [...action.payload] }
    }
    case GET_RESULTS_OF_URE_SUCCESS: {
      const { data } = action.payload
      if (data && data.umeBenefits) {
        let loadings = []
        data.umeBenefits.forEach(benefit => {
          if (benefit.umeCode && benefit.loadings && benefit.loadings.length) {
            benefit.loadings.forEach(loading => {
              loadings = [
                ...loadings,
                {
                  benefit: benefit.umeCode,
                  ...loading,
                },
              ]
            })
          }

          if (loadings.length) {
            loadings.forEach(lg => {
              benefitList.map(bt => {
                if (
                  lg.benefit === bt.umeCode &&
                  bt.possibleCombination &&
                  bt.possibleCombination.length
                ) {
                  bt.possibleCombination.map(combination => {
                    if (
                      combination.reason.toLowerCase() === lg.reason[0].reasonCode.toLowerCase() &&
                      combination.type === lg.loadingType
                    ) {
                      combination.selected = true
                      combination.status = 'saved'
                    }
                    return combination
                  })
                }
                return bt
              })
            })

            applyLoadings(loadings)
          }
        })
        return {
          ...state,
          loadings: [...loadings],
          underwriterName: loadings.length ? PREASSESSMENT_AUTOMATED_UNDERWRITER_NAME : '',
        }
      }

      return state
    }
    case SET_PRE_ASSESSMENT_OPEN_MODE: {
      const { payload: mode } = action
      return { ...state, mode }
    }
    case RESET_PRE_ASSESSMENT_OPEN_MODE: {
      const { mode } = initialFieldState
      return { ...state, mode }
    }
    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialFieldState
    }
    case SAVE_LOADINGS: {
      const { payload } = action
      return {
        ...state,
        underwriterName: payload,
      }
    }

    default: {
      return state
    }
  }
}

export default preAssessment
