/* eslint-disable no-unused-vars */
// redux.
import React from 'react'
import { pathOr } from 'lodash/fp'

// schema.
import get from 'lodash/get'
import policyOwnerDetailsSchema from '../../AddPayers/components/PolicyOwnerDetails/policyOwnerDetails.schema'
import paymentDetailsSchema, {
  FORM_ID as paymentDetailsSchemaId,
} from '../../AddPayers/components/PaymentDetails/paymentDetails.schema'
import { FORM_ID as directDebitSchemaId } from '../../AddPayers/components/PaymentDetails/DirectDebit/directDebit.schema'

import { FORM_ID as creditCardSchemaId } from '../../AddPayers/components/PaymentDetails/CreditCard/creditCard.schema'

import businessOwnerDetailsSchema, {
  FORM_ID as businessOwnerDetailsId,
} from '../../AddPayers/components/BusinessOwnerDetails/businessOwnerDetails.schema'

import wrapSuperSmfSchema, {
  FORM_ID as wrapSuperSmfId,
  getAccountType,
} from '../../AddPayers/components/PaymentDetails/WrapSuperSmf/wrapSuperSmf.schema'

import linkedMasterkeySchema, {
  FORM_ID as linkedMasterkeyId,
} from '../../AddPayers/components/PaymentDetails/LinkedMasterKey/linkedMasterKey.schema'

import addDirectorSchema, {
  FORM_ID as addDirectorId,
} from '../../AddPayers/components/AddDirector/addDirector.schema'

import { FORM_ID as rolloverPaymentId } from '../../AddPayers/components/PaymentDetails/RolloverPaymentMethod/rolloverPaymentMethod.schema'

// utils
import {
  formReset,
  formSubmit,
  formSubmitComplete,
  formUpdateField,
  formValidate,
} from '../../../../actions/creators/forms'
import { getActivePolicy } from '../../../../utils/quoteUtils'
import { getActivePaymentDetailsForm } from '../../../../utils/paymentUtils'

// actions
import {
  submitMemberDetails,
  submitPaymentDetails,
  updateRoleOfMember,
  addBusinessAsPayer,
} from '../../../../actions/creators/createQuote'
import { setNextTabPanel, closeSidebar, previousPanel } from '../../../../actions/creators/sidebar'

import { fetchCreditCardInfo } from '../../../../actions/creators/captureCreditCard'

// components.
import AddDirector from '../../AddPayers/components/AddDirector'
import AddPayers from '../../AddPayers'
import PayerTab from '../../AddPayers/components/PayerTab'
import PaymentDetails from '../../AddPayers/components/PaymentDetails'

// constants
import { POLICY_RELATIONSHIPS_PAYER } from '../../../../constants/policies'
import { ADD_BUTTON_TEXT } from '../../../../constants/sidebar'
import { PAYER_TYPE_INDIVIDUAL, PAYER_TYPE_BUSINESS } from '../../AddPayers/constants'
import { initForms } from '../../AddPayers/components/PolicyOwnerDetails/memberInitUtils'
import { initForms as initBussinessForms } from '../../AddPayers/components/BusinessOwnerDetails/businessOwnerInitUtils'
import { initForms as initDirectorForms } from '../../AddPayers/components/AddDirector/addDirectorInitUtils'
import { paymentDetailsInitData } from '../../AddPayers/components/PaymentDetails/paymentIntUtils'
import { directDebitData } from '../../AddPayers/components/PaymentDetails/DirectDebit/directDebitInItUtils'
import { creditCardData } from '../../AddPayers/components/PaymentDetails/CreditCard/creditCardInItUtils'
import { rolloverPaymentData } from '../../AddPayers/components/PaymentDetails/RolloverPaymentMethod/rolloverPaymentMethodUtil'
import { wrapSupeSmfInitData } from '../../AddPayers/components/PaymentDetails/WrapSuperSmf/wrapSuperSmfUtil'
import { formToMemberEntityDirector } from '../../../../utils/addBusinessUtils'
import { resetAbnLookup } from '../../../../actions/creators/abnLookup'

const SIDEBAR_SCHEMA = (fields = null, sidebar = {}) => {
  const policyOwnerDetailsId = `policyPayer${
    sidebar.panelProps ? sidebar.panelProps.policyInstanceNo : ''
  }`
  return [
    {
      sitecoreFieldHeadingKey: 'addPayerHeaderLabel',
      component: AddPayers,
      visible: false,
      callbackKey: 'handleNewClient',
      callBackWithValue: true,
      props: {
        handleNewClient: (next, dispatch, subpanelIndex) =>
          next(undefined, undefined, subpanelIndex),
        secondaryHeader: true,
        deleteFormIds: [
          policyOwnerDetailsId,
          paymentDetailsSchemaId,
          directDebitSchemaId,
          businessOwnerDetailsId,
          creditCardSchemaId,
          addDirectorId,
          wrapSuperSmfId,
          rolloverPaymentId,
        ],
        alwaysReset: true,
      },
    },
    [
      {
        sitecoreFieldHeadingKey: 'addIndividualHeaderLabel',
        component: PayerTab,
        visible: false,
        callbackKey: 'handleNewClient',
        props: {
          handleNewClient: next => next(),
          secondaryHeader: true,
          formId: policyOwnerDetailsId,
          payerCategory: PAYER_TYPE_INDIVIDUAL,
          alwaysReset: true,
        },
        isDarkPanel: true,

        secondaryActionText: pathOr('Clear', 'policyOwnerDetailsClearButtonText.value', fields),
        nextText:
          sidebar.nextTabSideBarPanel === 1
            ? pathOr(ADD_BUTTON_TEXT, 'paymentDetailsAddButton.value', fields)
            : pathOr(ADD_BUTTON_TEXT, 'addIndividualNextButtonLabel.value', fields),

        handleSecondaryAction: (dispatch, state, authorableFields) => {
          const {
            sidebar: { nextTabSideBarPanel },
            forms,
            createQuote,
          } = state
          const isAddPayer = !!(sidebar.schemaName === 'addPayer')
          const hasSecondaryContact =
            state.forms[policyOwnerDetailsId].fields.secondaryContactNoCheck

          const schema = policyOwnerDetailsSchema(authorableFields)({
            hasSecondaryContact,
            isAddPayer,
          })
          if (nextTabSideBarPanel === 0) {
            const data = initForms({
              createQuote,
              sidebar,
              payerType: get(sidebar, 'panelProps.payerType'),
            })
            dispatch(formReset(policyOwnerDetailsId, schema, data))
          }
          if (nextTabSideBarPanel === 1) {
            const { paymentDetails } = forms[paymentDetailsSchemaId].fields
            const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
              paymentDetails.value.value || paymentDetails.value || linkedMasterkeyId,
              { fields }
            )
            if (activeFormId !== wrapSuperSmfId && activeFormId !== rolloverPaymentId) {
              dispatch(
                formReset(
                  paymentDetailsSchemaId,
                  paymentDetailsSchema,
                  paymentDetailsInitData({
                    createQuote,
                    sidebar,
                    form: {},
                  })
                )
              )
            }
            if (activeFormId === wrapSuperSmfId && activeSchema) {
              dispatch(
                formReset(
                  wrapSuperSmfId,
                  activeSchema,
                  wrapSupeSmfInitData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === creditCardSchemaId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  creditCardData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === directDebitSchemaId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  directDebitData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === rolloverPaymentId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  rolloverPaymentData({
                    createQuote: { quotes: {} },
                    sidebar,
                    form: {},
                  })
                )
              )
            }
          }
        },
        handleNext: (next, dispatch, state, authorableFields) => {
          const {
            secondaryContactNoCheck,
            isPostalResidentialAddressSame,
            contactNumberPhoneCode,
            secondaryNumberPhoneCode,
          } = state.forms[policyOwnerDetailsId].fields
          const { addressLookup, forms, captureCreditCard } = state
          const isAddPayer = !!(sidebar.schemaName === 'addPayer')
          const schema = policyOwnerDetailsSchema(authorableFields)({
            hasSecondaryContact: secondaryContactNoCheck.value,
            isManualPostal:
              addressLookup.policyOwnerPostalAddress &&
              addressLookup.policyOwnerPostalAddress.isManual,
            isManualResidential:
              addressLookup.policyOwnerResidentialAddress &&
              addressLookup.policyOwnerResidentialAddress.isManual,
            postalHidden: isPostalResidentialAddressSame.value,
            identifier: get(sidebar, 'panelProps.payerType.identifier'),
            isAddPayer,
          })
          const { paymentDetails } = forms[paymentDetailsSchemaId].fields
          const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
            paymentDetails.value.value || paymentDetails.value,
            { fields },
            captureCreditCard.displayCreditCardListing
          )
          const addMasterkeypayment = (data, form, individualData) => {
            const masterkeySchema = linkedMasterkeySchema()({})
            dispatch(submitPaymentDetails({ ...data, paymentDetails: paymentDetails.value }))
            dispatch(
              submitMemberDetails(
                {
                  ...individualData,
                  contactNumberCountryCode: get(contactNumberPhoneCode, 'value.countryCode'),
                  secondaryNumberCountryCode: get(secondaryNumberPhoneCode, 'value.countryCode'),
                },
                undefined,
                [POLICY_RELATIONSHIPS_PAYER],
                getActivePolicy(state.sidebar)
              )
            )
            dispatch(closeSidebar())
            dispatch(
              formReset(policyOwnerDetailsId, schema, {
                useAsPostalAddress: true,
              })
            )
            dispatch(formReset(linkedMasterkeyId, masterkeySchema, {}))
          }
          const addPayment = (data, form, { individualData, paymentData }) => {
            if (
              paymentData.paymentDetails === 'CC' &&
              !captureCreditCard.displayCreditCardListing
            ) {
              dispatch(
                fetchCreditCardInfo(response => {
                  if (response && response.captureCardInfoIsSuccess) {
                    dispatch(
                      submitMemberDetails(
                        {
                          ...individualData,
                          contactNumberCountryCode: get(
                            contactNumberPhoneCode,
                            'value.countryCode'
                          ),
                          secondaryNumberCountryCode: get(
                            secondaryNumberPhoneCode,
                            'value.countryCode'
                          ),
                        },
                        undefined,
                        [POLICY_RELATIONSHIPS_PAYER],
                        getActivePolicy(state.sidebar)
                      )
                    )
                    dispatch(submitPaymentDetails({ ...data, ...paymentData }))
                    dispatch(closeSidebar())
                  }
                })
              )
            } else if (
              paymentData.paymentDetails === 'CC' &&
              captureCreditCard.displayCreditCardListing
            ) {
              dispatch(
                submitMemberDetails(
                  {
                    ...individualData,
                    contactNumberCountryCode: get(contactNumberPhoneCode, 'value.countryCode'),
                    secondaryNumberCountryCode: get(secondaryNumberPhoneCode, 'value.countryCode'),
                  },
                  undefined,
                  [POLICY_RELATIONSHIPS_PAYER],
                  getActivePolicy(state.sidebar)
                )
              )
              dispatch(closeSidebar())
            } else {
              dispatch(
                submitMemberDetails(
                  {
                    ...individualData,
                    contactNumberCountryCode: get(contactNumberPhoneCode, 'value.countryCode'),
                    secondaryNumberCountryCode: get(secondaryNumberPhoneCode, 'value.countryCode'),
                  },
                  undefined,
                  [POLICY_RELATIONSHIPS_PAYER],
                  getActivePolicy(state.sidebar)
                )
              )
              dispatch(submitPaymentDetails({ ...data, ...paymentData }))
              dispatch(closeSidebar())
            }
          }
          const addPaymentMethodType = (data, form, individualData) => {
            if (activeFormId && activeSchema) {
              dispatch(
                formSubmit(activeFormId, activeSchema, addPayment, {
                  individualData,
                  paymentData: data,
                })
              )
            } else {
              dispatch(
                submitMemberDetails(
                  {
                    ...individualData,
                    contactNumberCountryCode: get(contactNumberPhoneCode, 'value.countryCode'),
                    secondaryNumberCountryCode: get(secondaryNumberPhoneCode, 'value.countryCode'),
                  },
                  undefined,
                  [POLICY_RELATIONSHIPS_PAYER],
                  getActivePolicy(state.sidebar)
                )
              )
              dispatch(submitPaymentDetails({ ...data }))
              dispatch(closeSidebar())
            }
          }
          const addIndividualPolicyOwner = individualData => {
            if (activeFormId === linkedMasterkeyId) {
              const masterkeySchema = linkedMasterkeySchema()({})
              dispatch(
                formSubmit(linkedMasterkeyId, masterkeySchema, addMasterkeypayment, individualData)
              )
              dispatch(formSubmitComplete(linkedMasterkeyId))
            } else {
              dispatch(
                formSubmit(
                  paymentDetailsSchemaId,
                  paymentDetailsSchema,
                  addPaymentMethodType,
                  individualData
                )
              )
              dispatch(setNextTabPanel(1))
            }

            // next()
          }
          dispatch(setNextTabPanel(0))
          dispatch(formSubmit(policyOwnerDetailsId, schema, addIndividualPolicyOwner))
        },
      },
      {
        sitecoreFieldHeadingKey: 'addBusinessHeaderLabel',
        component: PayerTab,
        visible: false,
        callbackKey: 'handleNewClient',
        props: {
          handleNewClient: next => next(),
          secondaryHeader: true,
          payerCategory: PAYER_TYPE_BUSINESS,
          alwaysReset: true,
        },

        isDarkPanel: true,

        secondaryActionText: get(fields, 'policyOwnerDetailsClearButtonText.value', 'Clear'),
        nextText:
          sidebar.nextTabSideBarPanel === 1
            ? pathOr(ADD_BUTTON_TEXT, 'paymentDetailsAddButton.value', fields)
            : get(fields, 'addBusinessNextButtonLabel.value', ADD_BUTTON_TEXT),

        handleSecondaryAction: (dispatch, state) => {
          const {
            sidebar: { nextTabSideBarPanel },
            forms,
            createQuote,
          } = state
          const schema = businessOwnerDetailsSchema()(state.forms[businessOwnerDetailsId].fields)
          if (nextTabSideBarPanel === 0) {
            const data = initBussinessForms({
              createQuote,
              sidebar,
            })
            dispatch(formReset(businessOwnerDetailsId, schema, data))
            dispatch(resetAbnLookup())
          }
          if (nextTabSideBarPanel === 1) {
            const { paymentDetails } = forms[paymentDetailsSchemaId].fields
            const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
              paymentDetails.value.value || paymentDetails.value || linkedMasterkeyId,
              { fields }
            )
            if (activeFormId !== wrapSuperSmfId && activeFormId !== rolloverPaymentId) {
              dispatch(
                formReset(
                  paymentDetailsSchemaId,
                  paymentDetailsSchema,
                  paymentDetailsInitData({
                    createQuote,
                    sidebar,
                    form: {},
                  })
                )
              )
            }
            if (activeFormId === creditCardSchemaId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  creditCardData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === wrapSuperSmfId && activeSchema) {
              dispatch(
                formReset(
                  wrapSuperSmfId,
                  wrapSuperSmfSchema,
                  wrapSupeSmfInitData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === directDebitSchemaId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  directDebitData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === rolloverPaymentId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  rolloverPaymentData({
                    createQuote: { quotes: {} },
                    sidebar,
                    form: {},
                  })
                )
              )
            }
          }
        },

        handleNext: (next, dispatch, state) => {
          const {
            addressLookup,
            forms,
            captureCreditCard,
            abnLookup: { isAbnCancelled },
          } = state
          const { paymentDetails } = forms[paymentDetailsSchemaId].fields
          const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
            paymentDetails.value.value || paymentDetails.value,
            { fields },
            captureCreditCard.displayCreditCardListing
          )
          if (isAbnCancelled) return

          const addMasterkeypayment = (data, form, policyBusinessdata) => {
            const masterkeySchema = linkedMasterkeySchema()({})
            dispatch(submitPaymentDetails({ ...data, ...paymentDetails }))
            dispatch(
              addBusinessAsPayer({
                ...policyBusinessdata,
                contactNumberCountryCode: get(
                  forms,
                  `${businessOwnerDetailsId}.fields.contactNumberCode.value.countryCode`
                ),
              })
            )
            dispatch(formReset(linkedMasterkeyId, masterkeySchema, {}))
            dispatch(closeSidebar())
          }
          const addPayment = (data, form, { policyBusinessdata, paymentData }) => {
            if (
              paymentData.paymentDetails === 'CC' &&
              !captureCreditCard.displayCreditCardListing
            ) {
              dispatch(
                fetchCreditCardInfo(response => {
                  if (response && response.captureCardInfoIsSuccess) {
                    dispatch(
                      addBusinessAsPayer({
                        ...policyBusinessdata,
                        contactNumberCountryCode: get(
                          forms,
                          `${businessOwnerDetailsId}.fields.contactNumberCode.value.countryCode`
                        ),
                      })
                    )
                    dispatch(submitPaymentDetails({ ...data, ...paymentData }))
                    dispatch(closeSidebar())
                  }
                })
              )
            } else if (
              paymentData.paymentDetails === 'CC' &&
              captureCreditCard.displayCreditCardListing
            ) {
              dispatch(
                addBusinessAsPayer({
                  ...policyBusinessdata,
                  contactNumberCountryCode: get(
                    forms,
                    `${businessOwnerDetailsId}.fields.contactNumberCode.value.countryCode`
                  ),
                })
              )
              dispatch(closeSidebar())
            } else {
              dispatch(
                addBusinessAsPayer({
                  ...policyBusinessdata,
                  contactNumberCountryCode: get(
                    forms,
                    `${businessOwnerDetailsId}.fields.contactNumberCode.value.countryCode`
                  ),
                })
              )
              dispatch(submitPaymentDetails({ ...data, ...paymentData }))
              dispatch(closeSidebar())
            }
          }
          const addPaymentMethodType = (data, form, policyBusinessdata) => {
            if (activeFormId && activeSchema) {
              dispatch(
                formSubmit(activeFormId, activeSchema, addPayment, {
                  policyBusinessdata,
                  paymentData: data,
                })
              )
            } else {
              dispatch(
                addBusinessAsPayer({
                  ...policyBusinessdata,
                  contactNumberCountryCode: get(
                    forms,
                    `${businessOwnerDetailsId}.fields.contactNumberCode.value.countryCode`
                  ),
                })
              )
              dispatch(submitPaymentDetails({ ...data }))
              dispatch(closeSidebar())
            }
          }

          const businessOwnerSchema = businessOwnerDetailsSchema()({
            isManualResidential:
              addressLookup.policyOwnerResidentialAddress &&
              addressLookup.policyOwnerResidentialAddress.isManual,
            ...forms[businessOwnerDetailsId].fields,
            fields,
          })
          const addBusinessPolicyOwner = policyBusinessdata => {
            if (activeFormId === linkedMasterkeyId) {
              const masterkeySchema = linkedMasterkeySchema()({})
              dispatch(
                formSubmit(
                  linkedMasterkeyId,
                  masterkeySchema,
                  addMasterkeypayment,
                  policyBusinessdata
                )
              )
              dispatch(formSubmitComplete(linkedMasterkeyId))
            } else {
              dispatch(
                formSubmit(
                  paymentDetailsSchemaId,
                  paymentDetailsSchema,
                  addPaymentMethodType,
                  policyBusinessdata
                )
              )
              dispatch(setNextTabPanel(1))
            }
          }
          dispatch(setNextTabPanel(0))
          dispatch(formSubmit(businessOwnerDetailsId, businessOwnerSchema, addBusinessPolicyOwner))

          // TODO: this code will move after business tab data save function gets implemented

          dispatch(formSubmitComplete(businessOwnerDetailsId))
        },
      },
      {
        sitecoreFieldHeadingKey: 'paymentDetailsLabel',
        component: PaymentDetails,
        visible: false,
        callbackKey: 'handleNewClient',
        props: {
          handleNewClient: next => next(),
          secondaryHeader: true,
          formId: 'paymentForLifeAssured',
          alwaysReset: true,
        },
        isDarkPanel: true,

        secondaryActionText: pathOr('Clear', 'policyOwnerDetailsClearButtonText.value', fields),
        nextText: pathOr('Done', 'lifeInsuredPaymentDoneButton.value', fields),

        handleSecondaryAction: (dispatch, state) => {
          const { forms, createQuote } = state
          if (state.forms[wrapSuperSmfId]) {
            dispatch(
              formReset(
                wrapSuperSmfId,
                wrapSuperSmfSchema,
                wrapSupeSmfInitData({
                  form: {},
                  createQuote,
                  sidebar,
                })
              )
            )
          } else if (state.forms[linkedMasterkeyId]) {
            const masterkeySchema = linkedMasterkeySchema()({})
            dispatch(formReset(linkedMasterkeyId, masterkeySchema, {}))
          } else {
            const { paymentDetails } = forms[paymentDetailsSchemaId].fields
            const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
              paymentDetails.value.value || paymentDetails.value,
              { fields }
            )
            const paymentDetailsData = paymentDetailsInitData({
              createQuote,
              sidebar,
              form: {},
            })
            if (activeFormId !== wrapSuperSmfId && activeFormId !== rolloverPaymentId) {
              dispatch(formReset(paymentDetailsSchemaId, paymentDetailsSchema, paymentDetailsData))
            }
            if (activeFormId === creditCardSchemaId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  creditCardData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === directDebitSchemaId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  directDebitData({
                    form: {},
                    createQuote,
                    sidebar,
                  })
                )
              )
            } else if (activeFormId === rolloverPaymentId && activeSchema) {
              dispatch(
                formReset(
                  activeFormId,
                  activeSchema,
                  rolloverPaymentData({
                    createQuote: { quotes: {} },
                    sidebar,
                    form: {},
                  })
                )
              )
            }
          }
        },
        handleNext: (next, dispatch, state) => {
          const { forms, captureCreditCard } = state
          const { paymentDetails } = forms[paymentDetailsSchemaId].fields
          const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
            paymentDetails.value.value || paymentDetails.value,
            { fields },
            captureCreditCard.displayCreditCardListing
          )

          const addPayment = (data, form, { paymentData }) => {
            if (
              paymentData.paymentDetails === 'CC' &&
              !captureCreditCard.displayCreditCardListing
            ) {
              dispatch(
                fetchCreditCardInfo(response => {
                  if (response && response.captureCardInfoIsSuccess) {
                    dispatch(updateRoleOfMember(POLICY_RELATIONSHIPS_PAYER))
                    dispatch(submitPaymentDetails({ ...data, ...paymentData }))
                    dispatch(closeSidebar())
                  }
                })
              )
            } else if (
              paymentData.paymentDetails === 'CC' &&
              captureCreditCard.displayCreditCardListing
            ) {
              dispatch(updateRoleOfMember(POLICY_RELATIONSHIPS_PAYER))
              dispatch(closeSidebar())
            } else {
              dispatch(updateRoleOfMember(POLICY_RELATIONSHIPS_PAYER))
              dispatch(submitPaymentDetails({ ...data, ...paymentData }))
              dispatch(closeSidebar())
            }
          }
          const addPaymentMethodType = (data, form, individualData) => {
            if (activeFormId && activeSchema) {
              dispatch(
                formSubmit(activeFormId, activeSchema, addPayment, {
                  individualData,
                  paymentData: data,
                })
              )
            } else {
              addPayment(data, form, { paymentData: data })
            }
          }
          dispatch(formSubmit(paymentDetailsSchemaId, paymentDetailsSchema, addPaymentMethodType))
        },
      },
    ],
    {
      sitecoreFieldHeadingKey: 'addDirectorLabel',
      component: AddDirector,
      visible: false,
      isDarkPanel: true,
      props: {
        handleNewClient: next => next(),
        secondaryHeader: true,
        alwaysReset: true,
      },

      secondaryActionText: pathOr('Clear', 'policyOwnerDetailsClearButtonText.value', fields),
      nextText: pathOr('Add Director', 'addDirectorLabel.value', fields),

      handleSecondaryAction: (dispatch, state) => {
        const {
          sidebar: { panelProps },
          addressLookup: {
            address: { isManual: isManualPostal },
          },
        } = state
        const data = initDirectorForms({
          businessForm: state.forms[businessOwnerDetailsId],
          sidebarPanelProps: panelProps,
        })
        const schema = addDirectorSchema(fields)({ isManualPostal })
        dispatch(formReset(addDirectorId, schema, data))
      },

      handleNext: (next, dispatch, state) => {
        const { addressLookup, forms } = state

        const schemaValue = {
          isManualPostal: addressLookup.address && addressLookup.address.isManual,
          sameAsCompanyAddress: forms.director.fields.sameAsCompanyAddress.value,
          address: forms.director.fields.address.value,
        }

        const schema = addDirectorSchema(fields)(schemaValue)

        const addBusinessDirectorFn = formData => {
          const isManualPostal =
            addressLookup.policyOwnerPostalAddress &&
            addressLookup.policyOwnerPostalAddress.isManual
          const isManualResidential =
            addressLookup.policyOwnerResidentialAddress &&
            addressLookup.policyOwnerResidentialAddress.isManual
          // TODO: action logic to be done
          const schemaOfBussiness = businessOwnerDetailsSchema({
            isManualPostal,
            isManualResidential,
          })

          const alreadyAddedDirectors =
            get(forms[businessOwnerDetailsId], 'fields.directors.value') || []
          const memberEntity = formToMemberEntityDirector(formData)
          let directorFound = false

          if (formData.identifiers) {
            alreadyAddedDirectors.forEach((director, directorIndex) => {
              if (
                director.identifiers.some(identifier => identifier.value === formData.identifiers)
              ) {
                directorFound = true
                alreadyAddedDirectors[directorIndex] = memberEntity
              }
            })
          }

          if (!directorFound) {
            alreadyAddedDirectors.push(memberEntity)
          }

          dispatch(
            formUpdateField(businessOwnerDetailsId, 'directors', {
              error: { error: false },
              value: [...alreadyAddedDirectors],
            })
          )
          dispatch(formValidate(businessOwnerDetailsId, schemaOfBussiness))
          dispatch(previousPanel())
        }
        dispatch(formSubmit(addDirectorId, schema, addBusinessDirectorFn))
      },
    },
    {
      sitecoreFieldHeadingKey: 'paymentDetailsLabel',
      component: PaymentDetails,
      visible: false,
      callbackKey: 'handleNewClient',
      props: {
        handleNewClient: next => next(),
        secondaryHeader: true,
        alwaysReset: true,
      },
      secondaryActionText: pathOr('Clear', 'policyOwnerDetailsClearButtonText.value', fields),
      isDarkPanel: true,
      nextText: pathOr('Add Individual', 'addIndividualHeaderLabel.value', fields),
      handleNext: (next, dispatch) => {
        const addPayment = data => {
          dispatch(submitPaymentDetails(data))
          dispatch(closeSidebar())
          dispatch(formReset(paymentDetailsSchemaId, paymentDetailsSchema))
        }
        formSubmit(paymentDetailsSchemaId, paymentDetailsSchema, addPayment)
        dispatch(formSubmitComplete(paymentDetailsSchemaId))
      },
      handleSecondaryAction: next => next(),
    },
  ]
}

export default SIDEBAR_SCHEMA
