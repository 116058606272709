// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loader, Notification, Input as InputField, Icons, Button } from '@mlcl-digital/mlcl-design'
import { actionCreators } from '../../../actions'
import { OKTA_CHANGE_PASSWORD_MODAL } from '../../../actions/types/okta'

// components.
import Modal from '../../molecules/Modal'

// helpers.
import { errorCheck } from '../../../utils/formUtils'
import { getPasswordInputType } from '../../../utils/commonUtils'
// schema.
import {
  FORM_ID,
  CURRENT_PASSWORD_FIELD_KEY,
  NEW_PASSWORD_FIELD_KEY,
  CONFIRM_NEW_PASSWORD_FIELD_KEY,
  currentPasswordSchema,
  newPasswordSchema,
} from './changePassword.schema'

// styles.
import styles from './changePassword.styles'

const { IconEye16 } = Icons

const Description = styled('p')(styles.desc)
const IconEye = styled(IconEye16)(styles.iconEye)
const Footer = styled('div')(styles.footer)
const InputElement = styled(InputField)(styles.inputElement)
const NewPasswordInput = styled(InputField)(styles.newPasswordInput)
const NotificationElement = styled(Notification)(styles.notificationElement)
const NewPasswordGroup = styled('div')(styles.inputGroupContainer)
const Spinner = styled(Loader)(styles.spinner)

type ChangePasswordProps = {
  // sitecore fields
  fields: Object<Object>,
  // show/hide modal
  isOpen: Boolean,
  // redux actions
  actions: Object<Object>,
  // redux authentication
  authentication: Object<Object>,
  // redux form
  form: Object<Object>,
}

export class ChangePassword extends React.Component<ChangePasswordProps> {
  constructor(props) {
    super(props)
    const {
      fields,
      actions: { formInit },
    } = props

    this.state = {
      formSchema: currentPasswordSchema(fields),
      showBackButton: false,
      componentRender: () => this.renderCurrentPasswordForm,
      activeFormKey: CURRENT_PASSWORD_FIELD_KEY,
      showPasswordText: {
        [CURRENT_PASSWORD_FIELD_KEY]: { isText: false },
        [CONFIRM_NEW_PASSWORD_FIELD_KEY]: { isText: false },
        [NEW_PASSWORD_FIELD_KEY]: { isText: false },
      },
    }

    formInit(FORM_ID, currentPasswordSchema(fields))
  }

  togglePasswordText = fieldKey => {
    const { showPasswordText } = this.state
    this.setState({
      showPasswordText: {
        ...showPasswordText,
        [fieldKey]: { isText: !showPasswordText[fieldKey].isText },
      },
    })
  }

  handleFieldChange = ({ value, name }) => {
    const {
      actions: { formUpdateField },
      form,
    } = this.props

    const { formSchema, activeFormKey } = this.state

    const field = {
      error: errorCheck(
        value,
        formSchema[activeFormKey].condition,
        formSchema[activeFormKey].errorMsg,
        form
      ),
      value,
    }

    formUpdateField(FORM_ID, name, field)
  }

  validateNewPasswordForm = () => {
    const {
      actions: { formValidate },
    } = this.props
    const { formSchema } = this.state

    formValidate(FORM_ID, formSchema)
  }

  loadNewPasswordSchema = () => {
    const {
      fields,
      actions: { formUpdateField },
    } = this.props

    formUpdateField(FORM_ID, NEW_PASSWORD_FIELD_KEY, { value: '', error: false })
    formUpdateField(FORM_ID, CONFIRM_NEW_PASSWORD_FIELD_KEY, { value: '', error: false })

    this.setState(
      {
        formSchema: newPasswordSchema(fields),
        showBackButton: true,
        componentRender: () => this.renderNewPassworsForm,
        activeFormKey: NEW_PASSWORD_FIELD_KEY,
      },
      this.validateNewPasswordForm
    )
  }

  handleCurrentPasswordSubmit = event => {
    event.preventDefault()
    const {
      actions: { formSubmit },
    } = this.props

    const { formSchema } = this.state
    formSubmit(FORM_ID, formSchema, this.loadNewPasswordSchema)
  }

  loadCurrentPasswordSchema = () => {
    const { fields } = this.props

    this.setState({
      formSchema: currentPasswordSchema(fields),
      showBackButton: false,
      componentRender: () => this.renderCurrentPasswordForm,
      activeFormKey: CURRENT_PASSWORD_FIELD_KEY,
    })
  }

  handleBackButtonClick = () => {
    const { fields } = this.props
    this.loadCurrentPasswordSchema(fields)
  }

  handleNewPasswordSubmit = event => {
    event.preventDefault()
    const {
      actions: { formSubmit, oktaChangePassword },
    } = this.props
    const { formSchema } = this.state
    formSubmit(FORM_ID, formSchema, fields => {
      // call change password api
      oktaChangePassword({
        oldPassword: fields[CURRENT_PASSWORD_FIELD_KEY],
        newPassword: fields[NEW_PASSWORD_FIELD_KEY],
      })
    })
  }

  closeChangePasswordModal = () => {
    const {
      actions: { hideModal },
    } = this.props
    hideModal(OKTA_CHANGE_PASSWORD_MODAL)
  }

  renderErrorMessage = errorObj => (
    <Fragment>
      <NotificationElement variant="error">{errorObj.errorMsg}</NotificationElement>
    </Fragment>
  )

  renderCurrentPasswordForm = () => {
    const {
      form,
      fields: { currentPasswordLabel, currentPasswordPlaceholder, currentPasswordButton },
    } = this.props

    const { showPasswordText } = this.state

    const hasFormError =
      form &&
      form.fields[CURRENT_PASSWORD_FIELD_KEY] &&
      form.fields[CURRENT_PASSWORD_FIELD_KEY].error
    return (
      <Fragment>
        <Description>{currentPasswordLabel}</Description>
        <InputElement
          name={CURRENT_PASSWORD_FIELD_KEY}
          type={getPasswordInputType(showPasswordText[CURRENT_PASSWORD_FIELD_KEY])}
          changeHandler={this.handleFieldChange}
          placeholder={currentPasswordPlaceholder}
          suffix={
            <IconEye
              data-testid="makeVisible"
              onClick={() => this.togglePasswordText(CURRENT_PASSWORD_FIELD_KEY)}
            />
          }
          value={(form && form.fields[CURRENT_PASSWORD_FIELD_KEY].value) || ''}
        />
        <Footer>
          {hasFormError && hasFormError.error && this.renderErrorMessage(hasFormError)}
          <Button
            btnActionType="submit"
            variant="secondaryWithTheme"
            onClick={this.handleCurrentPasswordSubmit}
          >
            {currentPasswordButton}
          </Button>
        </Footer>
      </Fragment>
    )
  }

  renderNewPassworsForm = () => {
    const {
      authentication: { isLoading, errorCode },
      form,
      fields: {
        newPasswordLabel,
        newPasswordPlaceholder,
        newPasswordButtonLabel,
        confirmNewPasswordPlaceholder,
        passwordNotChanged,
      },
    } = this.props
    const { showPasswordText } = this.state

    const hasFormError =
      (form && form.fields[NEW_PASSWORD_FIELD_KEY] && form.fields[NEW_PASSWORD_FIELD_KEY].error) ||
      (form.fields[CONFIRM_NEW_PASSWORD_FIELD_KEY] &&
        form.fields[CONFIRM_NEW_PASSWORD_FIELD_KEY].error)

    const hasApiError = errorCode ? { error: true, errorMsg: passwordNotChanged } : false

    const hasError = hasFormError || hasApiError
    return (
      <Fragment>
        <Description>{newPasswordLabel}</Description>
        <NewPasswordGroup>
          <NewPasswordInput
            name={NEW_PASSWORD_FIELD_KEY}
            type={getPasswordInputType(showPasswordText[NEW_PASSWORD_FIELD_KEY])}
            changeHandler={this.handleFieldChange}
            placeholder={newPasswordPlaceholder}
            suffix={<IconEye onClick={() => this.togglePasswordText(NEW_PASSWORD_FIELD_KEY)} />}
            value={
              (form &&
                form.fields[NEW_PASSWORD_FIELD_KEY] &&
                form.fields[NEW_PASSWORD_FIELD_KEY].value) ||
              ''
            }
          />
          <NewPasswordInput
            name={CONFIRM_NEW_PASSWORD_FIELD_KEY}
            type={getPasswordInputType(showPasswordText[CONFIRM_NEW_PASSWORD_FIELD_KEY])}
            changeHandler={this.handleFieldChange}
            placeholder={confirmNewPasswordPlaceholder}
            suffix={
              <IconEye onClick={() => this.togglePasswordText(CONFIRM_NEW_PASSWORD_FIELD_KEY)} />
            }
            value={
              (form &&
                form.fields[CONFIRM_NEW_PASSWORD_FIELD_KEY] &&
                form.fields[CONFIRM_NEW_PASSWORD_FIELD_KEY].value) ||
              ''
            }
          />
        </NewPasswordGroup>

        <Footer>
          {hasError && hasError.error && this.renderErrorMessage(hasError)}
          <Button
            btnActionType="submit"
            disabled={isLoading}
            variant="secondaryWithTheme"
            onClick={this.handleNewPasswordSubmit}
          >
            {isLoading ? (
              <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
            ) : (
              newPasswordButtonLabel
            )}
          </Button>
        </Footer>
      </Fragment>
    )
  }

  render() {
    const {
      isOpen,
      fields: { changePasswordHeader },
    } = this.props
    const { showBackButton, componentRender } = this.state
    const RenderComp = componentRender()
    return (
      <Modal
        showBackButton={showBackButton}
        isOpen={isOpen}
        onClose={this.closeChangePasswordModal}
        title={changePasswordHeader}
        handleBackButton={this.handleBackButtonClick}
      >
        <form onSubmit={this.submit} id={FORM_ID} aria-labelledby={FORM_ID}>
          <RenderComp />
        </form>
      </Modal>
    )
  }
}

export const mapStateToProps = ({ authentication, forms }) => ({
  authentication,
  form: forms[FORM_ID],
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
