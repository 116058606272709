// import React, { Component, Fragment } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Card as ComponentLibraryCard } from '@mlcl-digital/mlcl-design'
import styles from './CardChildren.styles'

export const CardTable = styled('div')(styles.table)
export const CardCell = styled('div')(({ isHeader, bold, danger }) =>
  styles.cell({ isHeader, bold, danger })
)
export const CardDivide = styled('div')(({ large }) => styles.cardDivide({ large }))
export const CardGrid = styled('div')(({ large }) => styles.cardGrid({ large }))
export const CardGridItem = styled('div')(styles.gridItem)
export const CardContainer = styled('div')(styles.cardContainer)

export const Card = props => (
  <ComponentLibraryCard
    {...props}
    styleOverrides={{
      base: styles.cardBase(props),
      header: styles.cardHeader,
      body: styles.cardBody(props),
      footer: styles.cardFooter(props),
    }}
  />
)
