/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconBell32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"
      fill="#000"
    />
    <path d="M13.73 21a2 2 0 0 1-3.46 0" />
  </Icon>
)

export default IconBell32
