// @flow

import get from 'lodash/get'
import {
  STATES,
  PREFERRED_METHOD_OF_COMMS,
  PREFERRED_METHOD_OF_COMMS_FIELDS,
} from '../../../../constants/forms'
import {
  email,
  optional,
  required,
  phone,
  phoneInputs,
  postcode,
  manualAddressRegex,
  manualAddressPostalCode,
  mobilePhone,
  regexTest,
} from '../../../../utils/formUtils'
import { phoneCodeAustralia } from '../../../../constants/customerPersonalDetails'

export const FORM_ID: string = 'contactDetailsForm'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema =
  (): Function =>
  (state?: Object, fields): Object => ({
    phones: {
      condition: [
        {
          condition: required,
          errorMsg: fields && fields.ContactDetailsFormPhonesRequiredError,
        },
        {
          condition: (value, formFields) => {
            const phoneCodeValue =
              (formFields &&
                formFields.phoneCode &&
                formFields.phoneCode.value &&
                formFields.phoneCode.value.value) ||
              (formFields && formFields.phoneCode && formFields.phoneCode.value)
            const phoneRegex = phoneCodeValue === phoneCodeAustralia ? mobilePhone : phone
            return !regexTest(phoneRegex, value)
          },
          errorMsg: fields && fields.ContactDetailsFormPhonesError,
        },
      ],
      onChangeCondition: phoneInputs,
      errorMsg: fields && fields.ContactDetailsFormPhonesRequiredError,
    },
    phoneCode: {
      condition: required,
    },
    emails: {
      condition: [
        {
          condition: required,
          errorMsg: fields && fields.ContactDetailsFormEmailsRequiredError,
        },
        { condition: email, errorMsg: fields && fields.ContactDetailsFormEmailsError },
      ],
    },
    [PREFERRED_METHOD_OF_COMMS]: {
      options: [
        {
          ...PREFERRED_METHOD_OF_COMMS_FIELDS.digital,
          tooltip: get(fields, 'digitalTooltip.value', ''),
        },
        {
          ...PREFERRED_METHOD_OF_COMMS_FIELDS.paper,
          tooltip: get(fields, 'paperTooltip.value', ''),
        },
      ],
      condition: [
        {
          condition: required,
          errorMsg: fields && fields.ContactDetailsPreferredMethodOfCommsError,
        },
      ],
    },
    residentialAddress: {
      condition: state.isManualResidential || !state.hidePostal ? optional : required,
      errorMsg: fields && fields.ContactDetailsFormResidentialAddressFieldError,
    },
    hasAddressChanged: {
      condition: optional,
    },
    residentialAddressHouseNo: {
      condition: optional,
      minimum: 0,
    },
    residentialAddressStreet: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressStreetFieldError,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidStreetFieldError,
            }
          : {},
      ],
    },
    residentialAddressLocality: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressLocalityFieldError,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidLocalityFieldError,
            }
          : {},
      ],
    },
    residentialAddressState: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressStateFieldError,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressInvalidStateFieldError,
            }
          : {},
      ],
      options: STATES,
    },
    residentialAddressCountry: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressCountryFieldError,
            }
          : optional,
      ],
    },
    residentialAddressPostCode: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressPostCodeFieldError,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressPostalCode,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidPostCodeFieldError,
            }
          : {},
        postcode,
      ],
    },
    showPostalAddressCheckbox: {
      condition: optional,
    },
    hasSecondaryAddressChanged: {
      condition: optional,
    },
    postalAddress: {
      condition: state.isManualPostal || state.hidePostal ? optional : required,
      errorMsg: fields && fields.ContactDetailsPostalAddressRequiredErrorMessage,
    },
    postalAddressHouseNo: {
      condition: optional,
      minimum: 0,
    },
    postalAddressPONo: {
      condition: optional,
      minimum: 0,
    },
    postalAddressStreet: {
      condition: [
        !state.isPOManual && state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressStreetFieldError,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: state.isPOManual && state.isManualPostal ? /^$/ : manualAddressRegex,
              errorMsg:
                state.isPOManual && state.isManualPostal
                  ? fields.ContactDetailsFormResidentialAddressInvalidStreetFieldErrorIfPOBox
                  : fields.ContactDetailsFormResidentialAddressInvalidStreetFieldError,
            }
          : {},
      ],
    },
    postalAddressLocality: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressLocalityFieldError,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg: fields.ContactDetailsFormResidentialAddressInvalidLocalityFieldError,
            }
          : {},
      ],
    },
    postalAddressState: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressStateFieldError,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg: fields.ContactDetailsFormResidentialAddressInvalidStateFieldError,
            }
          : {},
      ],
      options: STATES,
    },
    postalAddressCountry: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressCountryFieldError,
            }
          : optional,
      ],
    },
    postalAddressPostCode: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressPostCodeFieldError,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressPostalCode,
              errorMsg: fields.ContactDetailsFormResidentialAddressInvalidPostCodeFieldError,
            }
          : {},
        postcode,
      ],
    },
  })
export default schema
