import { Variables } from '@mlcl-digital/mlcl-design'

const { fontSize, space, colours, fontWeights } = Variables

const styles = {
  base: { padding: `${space(3, true)}`, paddingBottom: space(5) },
  disclaimer: {
    paddingRight: space(1),
    paddingLeft: space(1),
    paddingTop: space(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: space(1),
  },
  total: {
    flex: 1,
    justifySelf: 'left',
    fontSize: fontSize.md,
    color: colours.black,
    fontWeight: fontWeights.bold,
    '& > *': {
      paddingLeft: space(1),
      fontWeight: fontWeights.normal,
      fontSize: fontSize.xs,
      color: colours.darkGrey,
    },
  },
  button: {
    justifySelf: 'right',
    display: 'block',
    textAlign: 'right',
    flex: 1,
    padding: 0,
    fontSize: fontSize.xs,
  },
  error: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingLeft: space(3),
    paddingRight: space(3),
    background: colours.white,
  },
  beneficiaryType: {
    padding: `${space(2, true)} 0`,
    position: 'relative',
  },
  beneficiaryTypeLabel: {
    padding: `${space(1, true)} 0`,
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xxs,
  },
  beneficiaryTypeText: {
    padding: `${space(2, true)} 0`,
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
  },
  nonBindingDisclaimer: {
    fontSize: fontSize.xxs,
    '& a': {
      color: colours.mlcAquaAccessible,
      textDecoration: 'none',
      '&:hover, &:visited, &:focus': {
        color: colours.mlcAquaCustomer,
      },
    },
  },
}

export default styles
