import { space, colours, fontSize } from '../../../../../../styles'

const styles = {
  tabsWrapper: {
    display: 'flex',
    border: `1px solid ${colours.lightestGrey}`,
    margin: '0',
    padding: '0',
  },
  tab: {
    listStyle: 'none',
  },
  button(isActive) {
    return {
      borderWidth: `0 0 ${isActive ? space(0.5, true) : 0} 0`,
      borderStyle: 'solid',
      color: isActive ? colours.darkGreen : colours.mediumGrey,
      fontSize: fontSize.xxs,
    }
  },
}

export default styles
