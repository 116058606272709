import { space, colours } from '../../../styles'

const styles = {
  wrapper: {
    padding: `${space(2, true)} ${space(3, true)}} 0`,
    table: {
      width: '100%',
    },
    'table, th, td': {
      border: `1px solid ${colours.darkestGrey}`,
      borderCollapse: 'collapse',
    },
    'th, td': {
      padding: `${space(1, true)} ${space(2, true)}}`,
    },
  },
}

export default styles
