export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST'
export const FILE_UPLOAD_REQUEST_PROGRESS = 'FILE_UPLOAD_REQUEST_PROGRESS'
export const FILE_UPLOAD_REQUEST_FAILURE = 'FILE_UPLOAD_REQUEST_FAILURE'
export const FILE_UPLOAD_REQUEST_SUCCESS = 'FILE_UPLOAD_REQUEST_SUCCESS'
export const FILE_UPLOAD_REQUEST_ERROR = 'FILE_UPLOAD_REQUEST_ERROR'

export const FILE_REMOVE_REQUEST = 'FILE_REMOVE_REQUEST'

export const FILE_UPLOAD_CREATE_WORK_ITEM = 'FILE_UPLOAD_CREATE_WORK_ITEM'
export const FILE_UPLOAD_CREATE_WORK_ITEM_INIT = 'FILE_UPLOAD_CREATE_WORK_ITEM_INIT'
export const FILE_UPLOAD_CREATE_WORK_ITEM_SUCCESS = 'FILE_UPLOAD_CREATE_WORK_ITEM_SUCCESS'
export const FILE_UPLOAD_CREATE_WORK_ITEM_FAILURE = 'FILE_UPLOAD_CREATE_WORK_ITEM_FAILURE'
export const FILE_UPLOAD_CREATE_WORK_ITEM_ERROR = 'FILE_UPLOAD_CREATE_WORK_ITEM_ERROR'

export const FILE_BROWSE_INIT = 'FILE_BROWSE_INIT'
export const FILE_BROWSE_INIT_SUCCESS = 'FILE_BROWSE_INIT_SUCCESS'

export const FILE_UPLOAD_MODAL_TOGGLE = 'FILE_UPLOAD_MODAL_TOGGLE'
export const FILE_UPLOAD_MODAL_RESET_DATA = 'FILE_UPLOAD_MODAL_RESET_DATA'
export const UPLOADED_DOCUMENT_LIST = 'UPLOADED_DOCUMENT_LIST'
export const UPLOADED_DOCUMENT_LIST_SUCCESS = 'UPLOADED_DOCUMENT_LIST_SUCCESS'
export const UPLOADED_DOCUMENT_LIST_FAILURE = 'UPLOADED_DOCUMENT_LIST_FAILURE'
export const UPLOADED_DOCUMENT_LIST_ERROR = 'UPLOADED_DOCUMENT_LIST_ERROR'

export const SAVE_UPLOADED_DOCUMENTS = 'SAVE_UPLOADED_DOCUMENTS'

export const SHOW_TASK_UPLOAD_CONFIRMATION_MODAL = 'SHOW_TASK_UPLOAD_CONFIRMATION_MODAL'
