// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { Heading, Button, Checkbox, Loader, Input, Select } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../../../utils/telemetry'

// Components
import AddressLookUp from '../../../../../molecules/AddressLookUp'
import InputPhone from '../../../../../molecules/InputPhone'

// molecules.
import Modal from '../../../../../molecules/Modal'

// schema.
import SCHEMA, { FORM_ID } from './contactDetailsForm.schema'

// styles
import styles from './contactDetailsForm.styles'
import {
  TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
  TOAST_ID_UPDATE_CONTACT_ERROR,
  TOAST_ID_UPDATE_CONTACT_SUCCESS,
} from '../../../../../../constants/toast'

import { DASHBOARD_ROUTE } from '../../../../../../constants/routes'

// utils
import {
  errorCheck,
  generateFieldsFromData,
  getValue,
  validateAll,
} from '../../../../../../utils/formUtils'
import {
  getFullAddressWithState,
  getEmail,
  normalizeContactDetails,
  getMobile,
  getPreferredValueForMobile,
  getCountryCode,
  constructAddress,
  getPreferredAddress,
  isPartyTypeOrg,
  getLatestAddressBasedOnType,
} from '../../../../../../utils/contactUtils'
import history from '../../../../../../utils/browserHistory'
import { reduceAuthorableFields, renderTextField } from '../../../../../../utils/sitecoreUtils'

// Constants
import { MARKETING_YES, MARKETING_NO } from '../../../../../../constants/customerPersonalDetails'
import {
  PREFERRED_YES,
  COUNTRY_CODE,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
} from '../../../../../../constants/policies'
import {
  DEFAULT_PHONE_CODE,
  DEFAULT_PHONE_ENTITY,
} from '../../../../../../constants/contactDetails'
import { generateFormId, getMemberUpdateRequestPayload } from './utils'
import { STATES } from '../../../../../../constants/forms'
import { ALTERATION_TYPES, SAVE_QUOTE_ACTION_TYPE } from '../../../../../../constants/alterations'
import { ACTION_TYPES } from '../../../../../../constants/application'

const REDIRECTION_DELAY_MSECS = 1000

const Form = styled('form')(styles)
const FormInput = styled(Input)(styles.input)
const FormInputPhone = styled(InputPhone)(styles.inputPhone)
const SelectComponent = styled(Select)(styles.title)
const FormAddressLookup = styled(AddressLookUp)(styles.input)
const Spinner = styled(Loader)(styles.spinner)
const StyledFormHeading = styled(Heading)(styles.formHeading)
const AddLineSeperator = styled('div')(styles.input, styles.firstFormElementStyle)
const PostalAddressCheckbox = styled(Checkbox)(styles.postalAddressCheckbox)
const StyledSubmitButton = styled(Button)(styles.styledSubmitButton)

export const RESIDENTIAL_ADDRESS_FIELDS = [
  'residentialAddress',
  'residentialAddressStreet',
  'residentialAddressHouseNo',
  'residentialAddressLocality',
  'residentialAddressState',
  'residentialAddressCountry',
  'residentialAddressPostCode',
]

export const POSTAL_ADDRESS_FIELDS = [
  'postalAddress',
  'postalAddressHouseNo',
  'postalAddressStreet',
  'postalAddressLocality',
  'postalAddressState',
  'postalAddressCountry',
  'postalAddressPostCode',
  'postalAddressPONo',
]

// TODO: Change to use React PropTypes
// TODO: Since this is now used only in ALts flow a major part of
// existing form will have to be removed in MVP2 of ALts
// TODO: tech debt: isAlterations flag is now redundant and needs removal
type ContactDetailsFormProps = {
  // An object containing action creator functions.
  actions: Object,
  // Sitecore authorable fields.
  fields: Object,
  // Sitecore params
  params: Object,
  // Form object in store
  // Save Quote args for Decrease risk
  quoteActionType?: string,
  firstNameLA?: string,
  lastNameLA?: string,
  quoteCollectionName?: string,
  productRules?: Array,
  form: Object,
  // Personal details object of the customer
  customerPersonalDetails: {
    details: { contactMethods: Object },
    partyType: String,
  },
  // Should Residential address be setup manually
  isManualResidential: boolean,
  // Should Postal address be setup manually (Mandatory for Alterations flow)
  isManualPostal?: boolean,
  // method to close the edit contact details form
  closeContactDetailsForm: Function,
  // master data entity
  masterList: Object,
  // welcome journey flag
  hasCompletedWelcomeJourney: Boolean,
  // Form item number. Starts with 0. Thsi will be appended to FORM_ID
  formIdIndex?: Number,
  // An Optional Form heading to be displayed on the form if passed
  formHeading?: String,
  // An optional Form sub heading to displayed on the form if passed
  formSubHeading?: String,
  // Optional flag to hide Submit and Cancel buttons
  hideCTAButtons?: boolean,
  // Form data array when more than one form record is present.
  membersContactDetails?: [
    {
      contactMethods: Object,
      bancsCustomerNo: string,
      partyType: string,
    }
  ],
  // Form Submission CTA (only for Alterations flow)
  ContinueCTA?: String,
  // Alterations flow flag
  isAlterations?: boolean,
  // All Contact details forms
  contactDetailsForms?: Object,
  /*
  TODO: Remove the below once PropTypes are used
  {
    [string]: { isValid: boolean, isSending: boolean, isDirty: boolean, fields: Object },
  }
  */
  // Click handler for alterations flow
  handleClick?: Function,
  // Handler function to swap Loader state
  setIsSaveQuoteAPILoading?: Function,
  // The Alterations Redux store object
  alterations?: {
    bancsCustomerNo: string,
    clientId: string,
    hasFormSubmitted: boolean,
    hasNetworkError: boolean,
    hasUpdateError: boolean,
    isFetchingPolicies: boolean,
    isFetchingRejectCPI: boolean,
    isPoliciesFetchError: boolean,
    memberContactDetailsForm: { hasNetworkError: boolean, hasFormSubmitted: boolean },
    members: [{ hasUpdateError: boolean }],
    membersContactDetails: [
      {
        hasUpdateError: boolean,
        isUpdateLoading: boolean,
      }
    ],
    policies: Array<Object>,
    rejectCPIData: {
      isNotInDateRange: boolean,
      rejectCPIAlreadyApplied: boolean,
      isMultipleLifeInsured: boolean,
    },
    rejectCPIError: null,
  },
  // Alteration Type, used for GA events tracking
  alterationType: String,
  // Advisor email address to avoid
  advisorEmailAddress: String,
  // Current Date needed for one scenario in update of addresses
  currentDate: String,
}

type ContactDetailsFormState = {
  // Does the API invocation have any errors
  hasUpdateErrors: boolean,
  // Is the form submitted
  hasFormSubmitted: boolean,
  // Has any member update API invoked for this form
  isMemberUpdateCalled: boolean,
}

export class ContactDetailsForm extends Component<
  ContactDetailsFormProps,
  ContactDetailsFormState
> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)
    this.state = {
      hasUpdateErrors: false,
      hasFormSubmitted: false,
      isMemberUpdateCalled: false,
    }
    this.apiResponse = []
    this.formId = FORM_ID
    const {
      customerPersonalDetails: { partyType },
    } = this.props
    this.isPartyTypeOrg = isPartyTypeOrg(partyType)
    this.redirectComplete = false
  }

  componentWillMount() {
    const {
      actions: { formInit, resetMemberContactDetails },
      isManualResidential,
      isManualPostal,
      customerPersonalDetails: { details, partyType },
      fields,
      isAlterations,
      formIdIndex,
      alterationType,
      advisorEmailAddress,
    } = this.props
    const formFieldInitialValues = this.initContactDetailsForm(details, partyType, isAlterations)
    const hidePostal = isAlterations
      ? !this.isPartyTypeOrg && get(formFieldInitialValues, 'showPostalAddressCheckbox', true)
      : true
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
      fields
    )
    if (isAlterations) {
      resetMemberContactDetails()
      this.formId = generateFormId(FORM_ID, formIdIndex)
    }
    formInit(this.formId, schema, formFieldInitialValues)
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Contact details confirmation page load',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Contact details confirmation page load`,
        },
      },
    })
    tagEvent.end()
  }

  componentDidUpdate() {
    const { isMemberUpdateCalled } = this.state
    const {
      alterations: { membersContactDetails: membersContactDetailsState },
    } = this.props
    // If there has been an API invocation,
    // then Show Toast messages based on API response
    // and reset the hasFormSubmitted flag
    if (isMemberUpdateCalled) this.postAPIInvocationActions(membersContactDetailsState)
  }

  initContactDetailsForm = (customerContactDetails, partyType, isAlterations) => {
    const phonesArray = get(customerContactDetails, 'contactMethods.phones', [])
    const mobileObj = getMobile(phonesArray)
    const phones = mobileObj.number || ''
    const phoneCode = mobileObj.idc || DEFAULT_PHONE_CODE
    const addressesArray = get(customerContactDetails, 'contactMethods.addresses', [])
    // If partyType=ORG, then home address is not needed and hence will not be shown in form.
    const homeAddress = this.isPartyTypeOrg
      ? {}
      : getLatestAddressBasedOnType(addressesArray, ADDRESS_TYPE_HOME)
    const residentialAddress = isAlterations
      ? constructAddress(homeAddress)
      : getFullAddressWithState(addressesArray)
    const emails = getEmail(get(customerContactDetails, 'contactMethods.emails', []))
    const {
      houseNo: residentialAddressHouseNo = '',
      street: residentialAddressStreet = '',
      locality: residentialAddressLocality = '',
      state: residentialAddressState = '',
      country: residentialAddressCountry = COUNTRY_CODE,
      postCode: residentialAddressPostCode = '',
    } = homeAddress

    // If partyType=ORG, use preferred address as statement address
    const statementAddress = this.isPartyTypeOrg
      ? getPreferredAddress(addressesArray)
      : getLatestAddressBasedOnType(addressesArray, ADDRESS_TYPE_STATEMENT)
    const {
      houseNo: postalAddressHouseNo = '',
      street: postalAddressStreet = '',
      locality: postalAddressLocality = '',
      state: postalAddressState = '',
      country: postalAddressCountry = COUNTRY_CODE,
      postCode: postalAddressPostCode = '',
    } = statementAddress
    // If partyType=ORG, then get the preferred address and use that instead for secondary address.
    const postalAddress = constructAddress(statementAddress)
    // If address is present, check box is Unchecked to show postal address in form
    // For Non Alteration flow, mark this as Checked by default.
    const showPostalAddressCheckbox = isAlterations ? !Object.keys(statementAddress).length : true

    const marketing = get(customerContactDetails, 'marketingPreference', '')
    let marketingPreference = MARKETING_YES.toUpperCase()
    if (marketing.suppressAll === 'Y') {
      marketingPreference = MARKETING_NO.toUpperCase()
    }
    return {
      phones,
      phoneCode,
      emails,
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      marketingPreference,
      showPostalAddressCheckbox,
      postalAddress,
      postalAddressHouseNo,
      postalAddressStreet,
      postalAddressLocality,
      postalAddressState,
      postalAddressCountry,
      postalAddressPostCode,
      hasAddressChanged: '',
      hasSecondaryAddressChanged: '',
    }
  }

  formatContactDetailsForm = (form, updatedCustomerContactDetails) => {
    const { masterList, currentDate } = this.props
    const { phones, emails, marketingPreference, phoneCode } = form.fields
    const contactMethods = get(updatedCustomerContactDetails, 'contactMethods.phones', [])
    const phoneEntityMobile = getMobile(contactMethods)
    const mobileIdc = getValue(phoneCode.value)
    const phonesPayload = [
      {
        ...DEFAULT_PHONE_ENTITY,
        preferred: getPreferredValueForMobile(contactMethods),
        ...phoneEntityMobile,
        number: phones.value,
        idc: mobileIdc,
        countryCode: getCountryCode(mobileIdc, masterList),
      },
    ]

    const emailEntity = get(updatedCustomerContactDetails, 'contactMethods.emails', [])

    if (emailEntity.length === 0) {
      emailEntity.push({ type: ADDRESS_TYPE_HOME, email: '', preferred: PREFERRED_YES })
    }

    emailEntity.map(email => {
      if (email.preferred === PREFERRED_YES) {
        email.email = emails.value
      }
      return email
    })

    if (
      updatedCustomerContactDetails &&
      updatedCustomerContactDetails.contactMethods !== undefined
    ) {
      updatedCustomerContactDetails.contactMethods.phones = phonesPayload
      updatedCustomerContactDetails.contactMethods.emails = emailEntity
    }

    const addresses = get(updatedCustomerContactDetails, 'contactMethods.addresses', [])
    updatedCustomerContactDetails.contactMethods.addresses = getMemberUpdateRequestPayload(
      form,
      addresses,
      currentDate
    )

    const marketing =
      typeof marketingPreference.value === 'object'
        ? marketingPreference.value.value
        : marketingPreference.value

    if (typeof updatedCustomerContactDetails.marketingPreference === 'object') {
      get(updatedCustomerContactDetails, 'marketingPreference', '').suppressAll = 'Y'
    } else {
      updatedCustomerContactDetails.marketingPreference = { suppressAll: 'Y' }
    }

    if (marketing === MARKETING_NO.toUpperCase()) {
      Object.keys(updatedCustomerContactDetails.marketingPreference).forEach(key => {
        updatedCustomerContactDetails.marketingPreference[key] = 'Y'
      })
    } else {
      Object.keys(updatedCustomerContactDetails.marketingPreference).forEach(key => {
        updatedCustomerContactDetails.marketingPreference[key] = 'N'
      })
    }

    return updatedCustomerContactDetails
  }

  addressChange = ({ name, data, value }) => {
    const {
      actions: { formUpdateField, formUpdate, formValidate },
      isAlterations,
      isManualResidential,
      isManualPostal,
      form,
      fields,
      advisorEmailAddress,
    } = this.props
    const hidePostal = isAlterations
      ? !this.isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))
      : true
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
      fields
    )
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(this.formId, name, field)
    formUpdate(this.formId, generateFieldsFromData(data))
    formValidate(this.formId, schema)
    if (name === 'residentialAddress') {
      formUpdateField(this.formId, 'hasAddressChanged', {
        error: false,
        value: 'YES',
      })
    } else {
      formUpdateField(this.formId, 'hasSecondaryAddressChanged', {
        error: false,
        value: 'YES',
      })
    }
  }

  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      isAlterations,
      isManualResidential,
      isManualPostal,
      form,
      fields,
      advisorEmailAddress,
    } = this.props
    const hidePostal = isAlterations
      ? !this.isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))
      : true
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
      fields
    )
    const data = {
      error: errorCheck(
        value,
        schema[name].condition,
        schema[name].errorMsg,
        name === 'phones' ? form.fields : {}
      ),
      value,
    }

    formUpdateField(this.formId, name, data)
    formValidate(this.formId, schema)
    if (RESIDENTIAL_ADDRESS_FIELDS.includes(name)) {
      formUpdateField(this.formId, 'hasAddressChanged', {
        error: false,
        value: 'YES',
      })
    } else if (POSTAL_ADDRESS_FIELDS.includes(name)) {
      formUpdateField(this.formId, 'hasSecondaryAddressChanged', {
        error: false,
        value: 'YES',
      })
    }
  }

  addressToggleHandler = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    if (isManual) {
      formResetField(this.formId, RESIDENTIAL_ADDRESS_FIELDS)
    }
  }

  postalAddressToggleHandler = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    if (isManual) {
      formResetField(this.formId, POSTAL_ADDRESS_FIELDS)
    }
  }

  onSubmit = async (fromContinue: boolean = true) => {
    const {
      isManualResidential,
      isManualPostal,
      fields,
      form,
      customerPersonalDetails: { details },
      membersContactDetails,
      contactDetailsForms,
      isAlterations,
      alterationType,
      advisorEmailAddress,
    } = this.props
    // Mark flag for submit button and Modal being open as true
    this.setState({ hasFormSubmitted: true })

    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: fromContinue
          ? 'Continue through application - Contact Details'
          : 'Try Again on modal - Contact Details ',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - ${
            fromContinue
              ? 'Continue through application - Contact Details'
              : 'Try Again on modal - Contact Details '
          }`,
        },
      },
    })
    tagEvent.end()

    // Use all member details to submit each form sequentially
    if (membersContactDetails && membersContactDetails.length >= 1) {
      membersContactDetails.forEach((eachMemberContactDetail, index) => {
        const formIndex = generateFormId(FORM_ID, index)
        const eachForm = contactDetailsForms[formIndex]
        if (!eachForm) return
        const hidePostal = isAlterations
          ? !isPartyTypeOrg(eachMemberContactDetail.partyType) &&
            getValue(get(eachForm.fields, 'showPostalAddressCheckbox', true))
          : true
        const schema = this.schemaWithAuthorableFields(
          { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
          fields
        )
        // Invoke form submission for each form.
        this.formSubmissionActions(formIndex, schema, eachForm, eachMemberContactDetail)
      })
    } else {
      const hidePostal = isAlterations
        ? !this.isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))
        : true
      const schema = this.schemaWithAuthorableFields(
        { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
        fields
      )
      this.formSubmissionActions(FORM_ID, schema, form, details)
    }
  }

  /** Save Quote action handler
   * If Action is CONVERT_TO_DRAFT_APPLICATION, then set it to SAVE_DRAFT_APPLICATION,
   * else do nothing
   * Perform a Save Quote and handle state side effects to handle subsequent Save actions
   */
  executeSaveQuote = () => {
    const {
      actions: { updateQuoteStatus, saveQuote },
      quoteActionType,
      firstNameLA,
      lastNameLA,
      quoteCollectionName,
      productRules,
      handleClick,
      setIsSaveQuoteAPILoading,
    } = this.props

    // Set the Loader state to enable loader in parent
    setIsSaveQuoteAPILoading(true)
    // Action type changes
    quoteActionType === ACTION_TYPES.CONVERT_TO_DRAFT_APPLICATION &&
      updateQuoteStatus({
        actionName: ACTION_TYPES.SAVE_DRAFT_APPLICATION,
      })
    // Save Quote action
    saveQuote(
      err => {
        // Reset the loader state
        setIsSaveQuoteAPILoading(false)
        if (err) {
          this.redirectComplete = false
          this.resetHasFormSubmitted()
        } else {
          setTimeout(handleClick, REDIRECTION_DELAY_MSECS)
        }
      },
      SAVE_QUOTE_ACTION_TYPE.CONTINUE_CLICK,
      quoteCollectionName,
      firstNameLA,
      lastNameLA,
      productRules
    )
  }

  onCancel = () => {
    const { closeContactDetailsForm } = this.props
    closeContactDetailsForm()
  }

  // Close the Modal
  onModalClose = () => {
    const {
      actions: { resetMemberContactDetails },
      alterationType,
    } = this.props
    this.setState({ hasUpdateErrors: false })

    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Modal Close - Contact Details',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Modal Close - Contact Details`,
        },
      },
    })
    tagEvent.end()
    resetMemberContactDetails()
  }

  onTryAgain = () => {
    this.onModalClose()
    this.onSubmit(false)
  }

  formSubmissionActions = (formIndex, schema, eachForm, memberContactDetail) => {
    const {
      actions: {
        formSubmit,
        formSetDirty,
        formSetSending,
        formUpdateValidations,
        formValidate,
        updateContactDetails,
        updateMemberContactDetails,
      },
      closeContactDetailsForm,
      hasCompletedWelcomeJourney,
      isAlterations,
    } = this.props

    // Validate all field level validations and set field level state
    formUpdateValidations(formIndex, schema)
    if (validateAll(schema, eachForm.fields)) {
      // Set form.isValid flag based on validation result
      formValidate(formIndex, schema)
      // If form field values have been changed, then perform form submit actions
      if (eachForm.isDirty) {
        const dataToSend = this.formatContactDetailsForm(eachForm, memberContactDetail)
        const { bancsCustomerNo } = memberContactDetail
        // Sets form.isDirty: False, form.isSending: True
        formSubmit(formIndex, schema, () => {
          const data = normalizeContactDetails(dataToSend)
          if (isAlterations) {
            delete data.bancsCustomerNo
            delete data.partyType
          }
          // If Alterations flow, call memeber update API with bancsCustomerNo
          isAlterations
            ? updateMemberContactDetails(data, bancsCustomerNo, (_, err) => {
                // Set form sending as true when API call has returned
                formSetSending(formIndex, false)
                if (err) {
                  // Mark or retain the form as dirty for submitting the form again.
                  formSetDirty(formIndex, true)
                  this.setState({ hasFormSubmitted: true })
                }
                // Register the flag as true to run post api invocation actions later
                this.setState({ isMemberUpdateCalled: true })
              })
            : updateContactDetails(data, (dataObj, err) => {
                // Set form sending as true when API call has returned
                formSetSending(formIndex, false)
                if (err) {
                  // Mark or retain the form as dirty for submitting the form again.
                  formSetDirty(formIndex, true)
                  // Show toast message as error
                  toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
                    toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
                    type: toast.TYPE.ERROR,
                  })
                } else {
                  toast(TOAST_ID_UPDATE_CONTACT_SUCCESS, {
                    toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
                    type: toast.TYPE.SUCCESS,
                  })
                  closeContactDetailsForm()
                  if (!hasCompletedWelcomeJourney) {
                    history.push(DASHBOARD_ROUTE)
                  }
                }
              })
        })
      } else if (!isAlterations && !hasCompletedWelcomeJourney) {
        history.push(DASHBOARD_ROUTE)
      }
    } else {
      // If the form validation has failed, then reset the form submission switch
      // This will need the form to be submitted again to recheck the redirection of page.
      this.resetHasFormSubmitted()
    }
  }

  resetHasFormSubmitted = () => {
    this.setState({ hasFormSubmitted: false })
  }

  /** Helper function for alterations flow to check
   * if Submit button is clicked,
   * all APIs have executed,
   * then show relevant toast message
   * and reset the form submission flag
   * */
  postAPIInvocationActions = (membersContactDetailsState: Object) => {
    const bancsCustomerNos = Object.keys(membersContactDetailsState)
    if (!bancsCustomerNos.length) return

    const isAPIExecutionComplete = bancsCustomerNos.every(
      bancsCustomerNo => !membersContactDetailsState[bancsCustomerNo].isUpdateLoading
    )

    const { hasFormSubmitted } = this.state
    if (hasFormSubmitted && isAPIExecutionComplete) {
      const hasErrors = bancsCustomerNos.some(
        bancsCustomerNo => membersContactDetailsState[bancsCustomerNo].hasUpdateError
      )
      if (hasErrors) {
        toast(TOAST_ID_UPDATE_CONTACT_ERROR, {
          toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
          type: toast.TYPE.ERROR,
        })
        this.resetHasFormSubmitted()
        // Show the Try again modal
        this.setState({ hasUpdateErrors: true })
      } else {
        toast(TOAST_ID_UPDATE_CONTACT_SUCCESS, {
          toastId: TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO,
          type: toast.TYPE.SUCCESS,
        })
        this.resetHasFormSubmitted()
      }
    }
  }

  togglePostalAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      fields,
      form,
      isManualResidential,
      isManualPostal,
      isAlterations,
      advisorEmailAddress,
    } = this.props
    const hidePostal = isAlterations
      ? !this.isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))
      : true
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
      fields
    )
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition),
      value,
    }

    formUpdateField(this.formId, name, data)
    formValidate(this.formId, schema)
  }

  /** Handler for rendering a Retry Modal */
  renderRetryModal() {
    const {
      alterations: {
        memberContactDetailsForm: { hasNetworkError },
      },
      fields,
    } = this.props
    const { ModalCTA, ModalDesc, ModalTitle } = reduceAuthorableFields(fields)
    const { hasUpdateErrors } = this.state
    return (
      <Modal
        isOpen={hasNetworkError || hasUpdateErrors}
        onClose={this.onModalClose}
        title={ModalTitle}
      >
        <p>{renderTextField(ModalDesc)}</p>
        <Button type="secondary" onClick={this.onTryAgain}>
          {ModalCTA}
        </Button>
      </Modal>
    )
  }

  renderAddressLookup(isPostal = false) {
    const { fields, form } = this.props

    const {
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      postalAddress,
      postalAddressHouseNo,
      postalAddressStreet,
      postalAddressLocality,
      postalAddressState,
      postalAddressCountry,
      postalAddressPostCode,
    } = form.fields

    const address = isPostal ? postalAddress : residentialAddress
    const houseNo = isPostal ? postalAddressHouseNo : residentialAddressHouseNo
    const street = isPostal ? postalAddressStreet : residentialAddressStreet
    const locality = isPostal ? postalAddressLocality : residentialAddressLocality
    const state = isPostal ? postalAddressState : residentialAddressState
    const country = isPostal ? postalAddressCountry : residentialAddressCountry
    const postCode = isPostal ? postalAddressPostCode : residentialAddressPostCode
    const postalType = isPostal ? 'postalAddressPostalType' : 'residentialAddressPostalType'
    const postalNumber = isPostal ? 'postalAddressPostalNumber' : 'residentialAddressPostalNumber'

    const offset = isPostal ? 'postalAddress' : 'residentialAddress'

    const {
      ContactDetailsCantFindAddressLabel,
      ContactDetailsFormResidentialAddressFieldLabel,
      ContactDetailsFormResidentialAddressFieldPlaceholder,
      ContactDetailsFormResidentialAddressStreetFieldLabel,
      ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
      ContactDetailsFormResidentialAddressHouseNoFieldLabel,
      ContactDetailsFormResidentialAddressHouseNoFieldPlaceholder,
      ContactDetailsFormResidentialAddressLocalityFieldLabel,
      ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
      ContactDetailsFormResidentialAddressStateFieldLabel,
      ContactDetailsFormResidentialAddressStateFieldPlaceholder,
      ContactDetailsFormResidentialAddressCountryFieldLabel,
      ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
      ContactDetailsFormResidentialAddressPostCodeFieldLabel,
      ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
      ContactDetailsFormResidentialAddressToggleToAutoText,
      PostalAddressFieldTitle,
      PostalAddressFieldPlaceholder,
    } = reduceAuthorableFields(fields)

    return (
      <FormAddressLookup
        toggleHandler={isPostal ? this.postalAddressToggleHandler : this.addressToggleHandler}
        name={offset}
        auto={{
          label: isPostal
            ? PostalAddressFieldTitle
            : ContactDetailsFormResidentialAddressFieldLabel,
          placeholder: isPostal
            ? PostalAddressFieldPlaceholder
            : ContactDetailsFormResidentialAddressFieldPlaceholder,
          toggleLabel: ContactDetailsCantFindAddressLabel,
          value: address.value,
          selectChangeHandler: this.addressChange,
          addressName: `${offset}`,
          addressError: address.error.error,
          caption: address.error.error && address.error.errorMsg.value,
          addressErrorMessage: address.error.error && address.error.errorMsg.value,
        }}
        manual={{
          streetLabel: ContactDetailsFormResidentialAddressStreetFieldLabel,
          streetPlaceholder: ContactDetailsFormResidentialAddressStreetFieldPlaceholder,
          streetValue: street.value,
          streetRequiredErrorMessage: get(street, 'error.errorMsg.value', ''),
          streetError: street.error.error,
          caption: street.error.error && street.error.errorMsg.value,
          streetName: `${offset}Street`,
          houseNoLabel: ContactDetailsFormResidentialAddressHouseNoFieldLabel,
          houseNoPlaceholder: ContactDetailsFormResidentialAddressHouseNoFieldPlaceholder,
          houseNoValue: houseNo.value,
          houseNoError: houseNo.error.error,
          houseNoRequiredErrorMessage: get(houseNo, 'error.errorMsg.value', ''),
          houseNoName: `${offset}HouseNo`,
          localityLabel: ContactDetailsFormResidentialAddressLocalityFieldLabel,
          localityPlaceholder: ContactDetailsFormResidentialAddressLocalityFieldPlaceholder,
          localityLabelValue: locality.value,
          localityError: locality.error.error,
          localityRequiredErrorMessage: get(locality, 'error.errorMsg.value', ''),
          localityName: `${offset}Locality`,
          stateLabel: ContactDetailsFormResidentialAddressStateFieldLabel,
          statePlaceholder: ContactDetailsFormResidentialAddressStateFieldPlaceholder,
          stateOptions: STATES,
          stateValue: state.value,
          stateError: state.error.error,
          stateRequiredErrorMessage: get(state, 'error.errorMsg.value', ''),
          stateName: `${offset}State`,
          countryLabel: ContactDetailsFormResidentialAddressCountryFieldLabel,
          countryPlaceholder: ContactDetailsFormResidentialAddressCountryFieldPlaceholder,
          countryValue: country.value,
          countryError: country.error.error,
          countryRequiredErrorMessage: get(country, 'error.errorMsg.value', ''),
          countryName: `${offset}Country`,
          postCodeLabel: ContactDetailsFormResidentialAddressPostCodeFieldLabel,
          postCodePlaceholder: ContactDetailsFormResidentialAddressPostCodeFieldPlaceholder,
          postCodeValue: postCode.value,
          postCodeError: postCode.error.error,
          postCodeRequiredErrorMessage: get(postCode, 'error.errorMsg.value', ''),
          postCodeName: `${offset}PostCode`,
          manualToggleLabel: ContactDetailsFormResidentialAddressToggleToAutoText,
          inputEntryHandler: this.handleChange,
          postalType,
          postalNumber,
        }}
      />
    )
  }

  renderPostalAddressFields() {
    const {
      fields,
      form: {
        fields: {
          showPostalAddressCheckbox: { value: showPostalAddress },
        },
      },
    } = this.props
    const { PostalAddressLbl } = reduceAuthorableFields(fields)
    return (
      <div>
        {!this.isPartyTypeOrg && (
          <PostalAddressCheckbox
            text={PostalAddressLbl}
            name="showPostalAddressCheckbox"
            htmlFor="showPostalAddressCheckbox"
            onChangeHandler={this.togglePostalAddress}
            checked={showPostalAddress}
          />
        )}
        {!showPostalAddress && this.renderAddressLookup(true)}
      </div>
    )
  }

  render() {
    const {
      fields,
      params,
      form,
      formIdIndex,
      contactDetailsForms,
      membersContactDetails,
      isManualResidential,
      isManualPostal,
      hasCompletedWelcomeJourney,
      formHeading,
      formSubHeading,
      hideCTAButtons,
      ContinueCTA,
      isAlterations,
      alterations,
      handleClick,
      advisorEmailAddress,
    } = this.props
    if (!form) return null

    const { isSending } = form
    const {
      customerContactDetailsMarketing,
      ContactDetailsEmailAddress,
      ContactDetailsPhone,
      ContactDetailsPhoneCode,
    } = reduceAuthorableFields(fields)
    const { phones, phoneCode, emails, marketingPreference } = form.fields
    // If partyType is ORG, show postal address and hide home address.
    const hidePostal = isAlterations
      ? !this.isPartyTypeOrg && getValue(get(form.fields, 'showPostalAddressCheckbox', true))
      : true
    // If partyType is ORG, send hidePostal as false to make home address optional.
    const schema = this.schemaWithAuthorableFields(
      { isManualResidential, isManualPostal, hidePostal, advisorEmailAddress },
      fields
    )
    const selectPhoneCode = {
      label: ContactDetailsPhoneCode,
      value: phoneCode.value,
      name: 'phoneCode',
      id: 'phoneCode',
      changeHandler: this.handleChange,
    }
    const inputPhoneNumber = {
      htmlFor: 'phones',
      name: 'phones',
      label: ContactDetailsPhone,
      placeholder: ContactDetailsPhone,
      value: phones.value,
      changeHandler: this.handleChange,
      type: 'text',
      error: phones.error.error,
      caption: phones.error.error && phones.error.errorMsg.value,
    }
    // Local component state
    const { hasFormSubmitted } = this.state

    const showCancelCTA = !isAlterations && hasCompletedWelcomeJourney && (
      <Button type="tertiary" onClick={this.onCancel}>
        Cancel
      </Button>
    )

    const {
      membersContactDetails: membersContactDetailsState,
      memberContactDetailsForm: { hasNetworkError },
    } = alterations

    // TODO: Use membersContactDetails or membersContactDetailsState ?
    // Set isUpdateLoading as true if any of the API calls are still running
    let isUpdateLoading = false
    if (membersContactDetails && membersContactDetails.length >= 1) {
      isUpdateLoading = membersContactDetails.some((eachFormData, index) => {
        const formIndex = generateFormId(FORM_ID, index)
        const eachForm = contactDetailsForms[formIndex]
        return !!eachForm.isSending
      })
    }

    // Redirect the Form to next Screen only if following conditions are met in order:
    // If Form has been submitted,
    // There are no forms validation issues,
    // If any n/w calls need to be made they are initiated,
    // In this case check the calls are complete and no errors,
    // then redirect to next screen
    // Else if no n/w calls need to be done then redirect to next screen.
    const formKeys = Object.keys(contactDetailsForms)
    const bancsCustomerNos = Object.keys(membersContactDetailsState)
    if (hasFormSubmitted && formKeys.length > 0 && !this.redirectComplete) {
      if (formKeys.every(eachKey => contactDetailsForms[eachKey].isValid === true)) {
        if (formKeys.every(eachKey => contactDetailsForms[eachKey].isSending === false)) {
          if (
            bancsCustomerNos.length === 0 ||
            (bancsCustomerNos.length > 0 &&
              bancsCustomerNos.every(
                bancsCustomerNo =>
                  membersContactDetailsState[bancsCustomerNo].isUpdateLoading === false
              ) &&
              bancsCustomerNos.every(
                bancsCustomerNo =>
                  membersContactDetailsState[bancsCustomerNo].hasUpdateError === false
              ))
          ) {
            // Flag to avoid multiple redirects
            this.redirectComplete = true

            // If alterationType is Decrease Risk, Run a Save Quote API call
            // ( & / else) Redirect with a timeout configured via REDIRECTION_DELAY_MSECS
            if (params.AlterationType === ALTERATION_TYPES.DECREASE_RISK) {
              this.executeSaveQuote()
            } else {
              setTimeout(handleClick, REDIRECTION_DELAY_MSECS)
            }
          }
        }
      }
    }

    return (
      <Fragment>
        {isAlterations && !hasNetworkError && isUpdateLoading ? (
          // Show loader only for first formIndex
          !formIdIndex && <Loader />
        ) : (
          <Form id={this.formId} aria-labelledby="application-overview">
            {formHeading && (
              <StyledFormHeading size="small" variant="h4" styleOverrides={styles.styleOverrides}>
                {formHeading}
              </StyledFormHeading>
            )}
            {formSubHeading && (
              <Heading size="xsmall" variant="h4">
                {formSubHeading}
              </Heading>
            )}
            {isAlterations && <AddLineSeperator />}
            <FormInput
              htmlFor="emails"
              name="emails"
              label={ContactDetailsEmailAddress}
              changeHandler={this.handleChange}
              placeholder={ContactDetailsEmailAddress}
              value={emails && emails.value}
              error={emails.error.error}
              caption={emails.error.error && emails.error.errorMsg.value}
            />
            <FormInputPhone selectPhoneCode={selectPhoneCode} inputPhoneNumber={inputPhoneNumber} />
            {!this.isPartyTypeOrg && this.renderAddressLookup()}
            {isAlterations && this.renderPostalAddressFields()}
            {!isAlterations && hasCompletedWelcomeJourney && (
              <SelectComponent
                label={customerContactDetailsMarketing}
                placeholder={customerContactDetailsMarketing}
                value={marketingPreference.value}
                name="marketingPreference"
                id="marketingPreference"
                changeHandler={this.handleChange}
                options={schema.marketingPreference.options}
                error={marketingPreference.error.error}
                caption={
                  marketingPreference.error.error && marketingPreference.error.errorMsg.value
                }
              />
            )}
            {hideCTAButtons && (
              <StyledSubmitButton onClick={this.onSubmit}>
                {isSending ? (
                  <Spinner spinnerSize={Number(20)} borderSize={Number(2)} />
                ) : (
                  ContinueCTA || 'Confirm'
                )}
              </StyledSubmitButton>
            )}
            {showCancelCTA}
          </Form>
        )}
        {isAlterations && this.renderRetryModal()}
      </Fragment>
    )
  }
}

export default ContactDetailsForm

ContactDetailsForm.defaultProps = {
  membersContactDetails: [],
  formIdIndex: 0,
  formHeading: '',
  formSubHeading: '',
  hideCTAButtons: true,
  ContinueCTA: 'Continue',
  isAlterations: false,
  contactDetailsForms: {},
  isManualPostal: false,
  alterations: {
    bancsCustomerNo: '',
    clientId: '',
    hasFormSubmitted: false,
    hasNetworkError: false,
    hasUpdateError: false,
    isFetchingPolicies: false,
    isFetchingRejectCPI: false,
    isPoliciesFetchError: false,
    memberContactDetailsForm: { hasNetworkError: false, hasFormSubmitted: false },
    members: [{ hasUpdateError: false }],
    membersContactDetails: {
      hasNetworkError: false,
      hasFormSubmitted: false,
    },
    policies: [],
    rejectCPIData: {
      isNotInDateRange: false,
      rejectCPIAlreadyApplied: false,
      isMultipleLifeInsured: false,
    },
    rejectCPIError: null,
    currentDate: '01/01/1900',
  },
  quoteActionType: '',
  firstNameLA: '',
  lastNameLA: '',
  quoteCollectionName: '',
  productRules: [],
  handleClick: () => {},
  setIsSaveQuoteAPILoading: () => {},
}
