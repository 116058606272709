export const BENEFIT_NATURE_TYPE_STANDALONE = 'Standalone'
export const BENEFIT_NATURE_TYPE_NA = 'NA'
export const BENEFIT_NATURE_TYPE_RIDER = 'Rider'
export const BENEFIT_NATURE_TYPE_RIDER_CONNECTED = 'Rider Connected'
export const BENEFIT_NATURE_TYPE_SATELLITE = 'Satellite'
export const BENEFIT_NATURE_TYPE_RIDER_OPTIMISER = 'Rider Optimiser'

export const LOADING_TYPES = [
  { label: 'Percent (%) ', value: 'PER' },
  { label: '$ Per 1000', value: 'ABS' },
]
export const LOADING_REASONS_MEDICAL = 'Medical'
export const LOADING_REASONS = [
  {
    label: 'Medical',
    value: 'medical',
  },
  {
    label: 'Pastime',
    value: 'pastime',
  },
]
export const CPI = 'CPI'
export const PREASSESSMENT_MODE_FROM_SIDEBAR = 'sidebar'
export const PREASSESSMENT_HIGH_LEVEL_TYPES = [
  { type: 'STANDARD', tagType: 'success' },
  { type: 'NON_STANDARD', tagType: 'warning' },
  { type: 'EVIDENCE_REQUIRED', tagType: 'warning' },
  { type: 'POSTPONE', tagType: 'warning' },
  { type: 'REFER', tagType: 'warning' },
  { type: 'DECLINE', tagType: 'danger' },
  { type: 'UNABLE_TO_OFFER', tagType: 'danger' },
]
export const PREASSESSMENT_AUTOMATED_UNDERWRITER_NAME = 'Pre-assess'
export const LOADING_TYPE_CODE = 'EMR'
export const LOADING_TYPE = 'Extra mortality rate'
export const WAIVER_BENEFIT_CODE = 'WOP'
export const COVERS_NOT_FOR_UI = ['WOP', 'TISO', 'SG_PLAT_2020', 'SG_STD_2020']
// For premium calculator
export const BENEFITS_SHOWN_AS_OPTIONS = ['TISO', 'WOP']
export const COMBINED_COVER = 'combined'
export const ESCALATION_ANNUAL_FREQUENCY = 'ANN'
export const CANCELLED_BENEFIT = 'CNC'
export const LOADING_TYPE_EMR = {
  code: 'EMR',
  symbol: '%',
  type: 'Extra mortality rate',
}
export const LOADING_TYPE_RPM = {
  code: 'RPM',
  symbol: '$',
  unit: 'per $1000',
  type: 'Rate Per Mille',
}

export const LOADING_TYPE_MAPPING = {
  EMR: {
    type: 'Extra mortality rate',
    value: 'PER',
  },
  RPM: {
    type: 'Rate Per Mille',
    value: 'ABS',
  },
  AGE: { type: 'Years to Age', value: 'AGE' },
}

export const CORE_DISCOUNTS = 'core discounts'
export const BENEFIT_STATE_INFORCE = 'Inforce'
export const INDEXED_CLAIMS_BENEFIT = 'IIA'
export const PREMIUM_STYLE_LEVEL = 'Level'
export const PREMIUM_STYLE_STEPPED = 'Stepped'
export const PERIOD_UNIT_AGE = 'Age'

// Waiting period is in Year and benefit period in Years
export const PERIOD_UNITS = {
  Day: 1,
  Month: 30,
  Year: 365,
  Years: 365,
}

export const SHORT_WAITING_PERIOD_FEATURE = 'SWP'
export const HEP_MAX_SUM_INSURED = 500000
export const ADB_MAX_SUM_INSURED = 1000000
