/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconEye16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28585 2.11556C10.9893 2.11556 12.3734 3.49963 12.3734 5.14987C12.3734 6.85334 10.9893 8.23741 9.28585 8.23741C7.63561 8.23741 6.25154 6.85334 6.25154 5.14987C6.25154 3.49963 7.63561 2.11556 9.28585 2.11556ZM9.28585 3.02053C8.11471 3.02053 7.15651 3.97873 7.15651 5.14987C7.15651 6.37424 8.11471 7.33244 9.28585 7.33244C10.5102 7.33244 11.4684 6.37424 11.4684 5.14987C11.4684 3.97873 10.5102 3.02053 9.28585 3.02053Z"
      fill="#000"
    />

    <path
      className="path2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.75 5.14984C17.1112 5.84188 16.5789 6.48068 15.8336 7.11948C13.8107 8.82295 11.5749 9.72792 9.28588 9.72792C7.05008 9.72792 4.81427 8.82295 2.73817 7.11948C2.04614 6.48068 1.46057 5.84188 0.875 5.14984C1.46057 4.45781 2.04614 3.81901 2.73817 3.23344C4.81427 1.47673 7.05008 0.625 9.28588 0.625C11.5749 0.625 13.8107 1.47673 15.8336 3.23344C16.5789 3.81901 17.1112 4.45781 17.75 5.14984ZM2.09937 5.14984C2.52524 5.62894 2.9511 6.00158 3.37697 6.37421C5.24014 7.97121 7.26301 8.76972 9.28588 8.76972C11.362 8.76972 13.3849 7.97121 15.248 6.37421C15.6739 6.00158 16.0998 5.62894 16.5256 5.14984C16.0998 4.72397 15.6739 4.29811 15.248 3.92547C13.3849 2.32847 11.362 1.52997 9.28588 1.52997C7.26301 1.52997 5.24014 2.32847 3.37697 3.92547C2.9511 4.29811 2.52524 4.72397 2.09937 5.14984Z"
      fill="#000"
    />
  </Icon>
)

export default IconEye16
