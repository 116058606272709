/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconCircle32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
      fill="#000"
    />
  </Icon>
)

export default IconCircle32
