// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { pathOr } from 'lodash/fp'
import { A11yLabel } from '@mlcl-digital/mlcl-design'

import Input from '../../../../../atoms/Input'
import Select from '../../../../../atoms/Select'
import Checkbox from '../../../../../atoms/Checkbox'
import { actionCreators } from '../../../../../../actions'
import { errorCheck } from '../../../../../../utils/formUtils'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'
import schema, { FORM_ID } from './directDebit.schema'
import styles from './directDebit.styles'
import { directDebitData } from './directDebitInItUtils'
import { getPaymentFrequency } from '../../../../../../utils/paymentUtils'

const Paragraph = styled('p')(styles.label)
const TermsAndConditions = styled(Checkbox)(styles.label)
const PaymentFrequency = styled('p')(styles.paymentFrequency)

type Props = {
  actions: Object,
  fields: Object,
  form: Object,
  bsbLookup: Object,
  createQuote: Object,
  policyInstanceNo: string,
}
type State = {
  paymentDrawDateOptions: Array<Object>,
  SCHEMA: Object,
  bsbField: string,
}

export class DirectDebit extends Component<Props, State> {
  constructor(props: Object) {
    super(props)
    const {
      actions: { formInit, initBsbLookup },
      fields,
      form,
      bsbLookup,
    } = props

    // Payment draw date options for dropdown
    const paymentDrawDateOptions = []
    let index
    for (index = 1; index <= 28; index += 1) {
      paymentDrawDateOptions.push({
        label: index,
        value: index,
      })
    }
    const SCHEMA = schema({ fields })
    const data = directDebitData(props)
    this.state = {
      SCHEMA,
      bsbField: !form ? data.directDebitBsb : form.fields.directDebitBsb.value,
    }
    // initializing form
    if (!form) {
      formInit(FORM_ID, SCHEMA, data)
    }

    // initializing bsbLookup
    if (!bsbLookup) {
      initBsbLookup('directDebitBsb')
    }
  }

  componentWillReceiveProps(nextProps: Object) {
    const { bsbLookup } = this.props
    // error in bsb lookup
    if (
      bsbLookup &&
      nextProps.bsbLookup.isError !== bsbLookup.isError &&
      nextProps.bsbLookup.isError
    ) {
      const {
        fields: { addPayerDirectDebitBsbApiError, addPayerDirectDebitBSBError },
      } = this.props
      const errorMsg =
        pathOr(false, 'bsbLookup.data.errorCode', nextProps) === 404
          ? addPayerDirectDebitBSBError.value
          : addPayerDirectDebitBsbApiError.value
      this.updateBsbField('', true, errorMsg)
    }
    if (
      bsbLookup &&
      !nextProps.bsbLookup.isFetching &&
      bsbLookup.isFetching &&
      !nextProps.bsbLookup.isError
    ) {
      const { bsbField } = this.state
      this.updateBsbField(bsbField, false, '')
    }
  }

  updateBsbField = (value, error, errorMsg) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state
    const data = {
      value,
      error: {
        error,
        errorMsg,
      },
    }
    formUpdateField(FORM_ID, 'directDebitBsb', data)
    formValidate(FORM_ID, SCHEMA)
  }

  // Handles changes in form fields
  handleFieldChange = ({ name, value }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { SCHEMA } = this.state
    const data = {
      value,
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  handleBsbChange = ({ value }) => {
    // @FIXME: need to remove param assignment safely
    // eslint-disable-next-line no-param-reassign
    value = value.split('-').join('')
    const formattedVal = value.length ? value.match(/.{1,3}/g).join('-') : ''
    this.setState({
      bsbField: formattedVal,
    })
  }

  /**
   * Handles BSB field blur event
   * @param event - event object
   */
  handleBsbFieldBlur = (event: Object, name: string) => {
    const {
      actions: { validateBsb },
      fields: { addPayerDirectDebitBSBError },
    } = this.props
    const {
      target: { value },
    } = event
    if (value) {
      let bsbValue = value
      if (value[3] === ' ') {
        bsbValue = `${value.substring(0, 3)}-${value.substring(4)}`
      } else if (value[3] && value[3] !== '-') {
        bsbValue = `${value.substring(0, 3)}-${value.substring(3)}`
      }

      // action for api call to validate BSB code
      validateBsb({ name, value: bsbValue })
    }
    this.updateBsbField('', true, addPayerDirectDebitBSBError.value)
  }

  render() {
    const { form, fields, createQuote, policyInstanceNo, bsbLookup } = this.props
    const { SCHEMA, bsbField } = this.state
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    const {
      addPayerDirectDebitAccountName,
      addPayerDirectDebitFormLabel,
      addPayerDirectDebitAccountNumber,
      addPayerDirectDebitBSB,
      addPayerDirectDebitPaymentDateNote,
      addPayerDirectDebitPaymentDrawDay,
      addPayerDirectDebitPaymentDrawDaySelectLabel,
      addPayerDirectDebitTerms,
      addPayerDirectDebitBSBValidating,
    } = fields
    if (!form) {
      return null
    }
    const { accountName, accountNumber, directDebitBsb, paymentDrawDate, termsAndConditions } =
      form.fields

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''

    let frequency = ''
    if (activePolicy) frequency = getPaymentFrequency(activePolicy, fields)

    return (
      <div>
        <A11yLabel id="directDebitForm">
          <Text field={addPayerDirectDebitFormLabel} />
        </A11yLabel>
        <form aria-labelledby="directDebitForm">
          <Input
            htmlFor="accountName"
            label={<Text field={addPayerDirectDebitAccountName} />}
            name="accountName"
            changeHandler={this.handleFieldChange}
            error={accountName.error.error}
            caption={
              accountName.error.error ? accountName.error.errorMsg : SCHEMA.accountName.tooltip
            }
            value={accountName.value}
          />
          <Input
            htmlFor="accountNumber"
            label={<Text field={addPayerDirectDebitAccountNumber} />}
            name="accountNumber"
            changeHandler={this.handleFieldChange}
            error={accountNumber.error.error}
            caption={
              accountNumber.error.error
                ? accountNumber.error.errorMsg
                : SCHEMA.accountNumber.tooltip
            }
            value={accountNumber.value}
          />
          <Input
            htmlFor="directDebitBsb"
            label={<Text field={addPayerDirectDebitBSB} />}
            name="directDebitBsb"
            changeHandler={this.handleBsbChange}
            error={directDebitBsb.error.error}
            caption={
              directDebitBsb.error.error &&
              (bsbLookup.isFetching
                ? addPayerDirectDebitBSBValidating.value
                : directDebitBsb.error.errorMsg)
            }
            value={bsbField}
            blurHandler={this.handleBsbFieldBlur}
          />
          <Paragraph>
            <Text field={addPayerDirectDebitPaymentDateNote} />
          </Paragraph>
          <Select
            label={<Text field={addPayerDirectDebitPaymentDrawDay} />}
            placeholder={<Text field={addPayerDirectDebitPaymentDrawDaySelectLabel} />}
            value={paymentDrawDate.value}
            name="paymentDrawDate"
            changeHandler={this.handleFieldChange}
            options={SCHEMA.paymentDrawDate.options}
          />
          <PaymentFrequency>{frequency}</PaymentFrequency>
          <TermsAndConditions
            text={renderTextField(addPayerDirectDebitTerms, true)}
            name="termsAndConditions"
            htmlFor="termsAndConditions"
            onChangeHandler={this.handleFieldChange}
            checked={termsAndConditions.value}
            error={termsAndConditions.error.error}
            caption={
              termsAndConditions.error.error
                ? termsAndConditions.error.errorMsg
                : SCHEMA.termsAndConditions.tooltip
            }
          />
        </form>
      </div>
    )
  }
}

export const mapStateToProps = ({ forms, bsbLookup }) => ({
  form: forms[FORM_ID],
  bsbLookup: bsbLookup.directDebitBsb,
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DirectDebit)
