import { space, colours, fontSize } from '../../../../../../styles'

const styles = {
  container: {
    textAlign: 'center',
    borderBottom: `1px solid ${colours.lightGrey}`,
  },

  manageTemplatesAction: {
    fontSize: fontSize.xs,
  },

  editIcon: {
    marginLeft: space(2, true),
  },
}

export default styles
