// eslint-disable-next-line max-len
// @flow
import { benefitList } from '../components/organisms/CreateQuote/components/PreAssessment/PreAssessmentTab'

const generateCombination = (reasons, types) => {
  const combinations = []

  if (reasons && reasons.length && types && types.length) {
    const reasonsToPlainArray = reasons.reduce((acc, ele) => {
      acc.push(ele.value)
      return acc
    }, [])

    const typesToPlainArray = types.reduce((acc, ele) => {
      acc.push(ele.value)
      return acc
    }, [])

    for (let i = 0; i < reasonsToPlainArray.length; i += 1) {
      for (let j = 0; j < reasonsToPlainArray.length; j += 1) {
        combinations.push({
          reason: reasonsToPlainArray[i],
          type: typesToPlainArray[j],
          selected: false,
          status: 'new', // ? new | draft | saved
        })
      }
    }
  }

  return combinations
}

export const applyLoadings = (loadings, benefits) => {
  const targetedBenfit = benefits || benefitList
  if (loadings.length) {
    loadings.forEach(lds => {
      targetedBenfit.map(item => {
        if (item.umeCode === lds.benefit) {
          item.possibleCombination.map(combination => {
            const { reason, type } = combination
            if (
              lds.reason &&
              reason.toLowerCase() === lds.reason[0].reasonCode.toLowerCase() &&
              type === lds.loadingValueType
            ) {
              // eslint-disable-next-line no-param-reassign
              combination.selected = true
              // eslint-disable-next-line no-param-reassign
              combination.status = 'saved'
            }
            return combination
          })
        }
        return item
      })
    })
  }
}

export const getBenefitList = (data = [], loadings = []) => {
  const list = data.map(benefit => {
    const { loadingReasons, loadingTypes } = benefit
    // eslint-disable-next-line no-param-reassign
    benefit.possibleCombination = generateCombination(loadingReasons, loadingTypes)
    return benefit
  })

  applyLoadings(loadings, list)

  return list
}

export const resetBenefitList = () => {
  benefitList.map(item => {
    item.possibleCombination.map(combination => {
      // eslint-disable-next-line no-param-reassign
      combination.selected = false
      // eslint-disable-next-line no-param-reassign
      combination.status = 'new'

      return combination
    })
    return item
  })
}

export const manualLoadingBenefitsType = (fullData = false, currentBenefit) => {
  if (fullData === true) {
    return benefitList
      .filter(benefit => benefit.types.includes('pre-sale'))
      .map(benefit => ({ label: benefit.loadingDisplayText, value: benefit.umeCode }))
  }

  return benefitList
    .filter(benefit => benefit.types.includes('pre-sale'))
    .filter(option => {
      if (currentBenefit && option.umeCode === currentBenefit) {
        return true
      }

      return option.possibleCombination.some(
        com => com.selected === false && com.status !== 'saved'
      )
    })
    .map(opt => ({ label: opt.loadingDisplayText, value: opt.umeCode }))
}

export const manualLoadingReasons = (selectedBenefit, currentReason) => {
  if (benefitList[0] && selectedBenefit) {
    const { possibleCombination } = benefitList.find(item => item.umeCode === selectedBenefit)
    return benefitList
      .find(benefit => benefit.umeCode === selectedBenefit)
      .loadingReasons.filter(option => {
        if (currentReason && option.value === currentReason) {
          return true
        }
        const notSelected = possibleCombination.some(
          com => com.reason === option.value && (com.selected === false || com.status !== 'saved')
        )
        return notSelected
      })
  }
  return []
}

export const manualLoadingType = (selectedBenefit, selectedReason, currentType) => {
  if (benefitList[0] && selectedBenefit && selectedReason) {
    const { possibleCombination } = benefitList.find(item => item.umeCode === selectedBenefit)
    return benefitList
      .find(benefit => benefit.umeCode === selectedBenefit)
      .loadingTypes.filter(option => {
        if (currentType && option.value === currentType) {
          return true
        }
        const notSelected = possibleCombination.some(
          com =>
            com.type === option.value &&
            com.reason === selectedReason &&
            (com.selected === false || com.status !== 'saved')
        )
        return notSelected
      })
  }
  return []
}

export const manualLoadingAmount = (type = 'PER') => {
  const options = []
  if (type === 'PER') {
    let i = 1
    while (25 * i <= 400) {
      const opt = 25 * i
      options.push({ label: `${opt}%`, value: `${opt}` })
      i += 1
    }
  } else {
    let i = 0
    while (i < 20.0) {
      i = 0.5 + i
      options.push({ label: `$${i}`, value: `${i}` })
    }
  }
  return options
}

/**
 *  Extracts loadings from a quote collection
 *  to be stored in preAssessment state
 */
export const extractLoadingsFromQuote = (umeBenefits: Array = [], policyStructure: Array = []) => {
  const loadings = []
  umeBenefits.forEach(umeBenefit => {
    if (umeBenefit.covers && umeBenefit.types.includes('pre-sale')) {
      umeBenefit.covers.forEach(umeCover => {
        let policyCover = null
        policyStructure.some(policy =>
          policy.covers.some(cover => {
            if (cover.type === umeCover) {
              policyCover = cover
              return true
            }
            return false
          })
        )
        if (policyCover && policyCover.loading) {
          loadings.push(
            ...policyCover.loading.map(coverLoading => ({
              benefit: umeBenefit.umeCode,
              ...coverLoading,
            }))
          )
        }
      })
    }
  })
  return loadings
}
