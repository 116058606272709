// @flow
import {
  APPLICATION_STAGE_UPDATE,
  APPLICATION_STAGE_INCREMENT,
  APPLICATION_STAGE_RESET,
} from '../types/application'

export const updateApplicationStage = (stage: number, status: Object<Object>) => ({
  type: APPLICATION_STAGE_UPDATE,
  payload: {
    stage,
    status,
  },
})

export const incrementApplicationStage = () => ({
  type: APPLICATION_STAGE_INCREMENT,
})

export const resetApplicationStage = () => ({
  type: APPLICATION_STAGE_RESET,
})
