import React from 'react'
import { Button, Modal } from '@mlcl-digital/mlcl-design'

type EmailClientErrorModalProps = {
  fields: { [key: string]: string }
  isOpen: boolean
  onClose: () => void
  handleCheckButtonClick: () => void
}

export const EmailClientErrorModal = ({
  fields,
  isOpen,
  onClose,
  handleCheckButtonClick,
}: EmailClientErrorModalProps) => {
  const {
    EmailClientErrorModalHeading,
    EmailClientErrorModalContent,
    EmailClientErrorModalButtonText,
  } = fields

  return (
    <Modal
      isOpen={isOpen}
      title={EmailClientErrorModalHeading}
      onClose={onClose}
      showCloseButton
      footer={
        <Button variant="secondaryWithTheme" onClick={handleCheckButtonClick}>
          {EmailClientErrorModalButtonText}
        </Button>
      }
    >
      <div>{EmailClientErrorModalContent}</div>
    </Modal>
  )
}
export default EmailClientErrorModal
