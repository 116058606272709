import { Variables } from '@mlcl-digital/mlcl-design'
import { VariablesType } from '../../../types/ComponentLibrary'

const { space, mediaQueries } = Variables as VariablesType
const styles = {
  wrapper: {
    [mediaQueries.md]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  selectPhoneCode: {
    [mediaQueries.md]: {
      width: '33%',
    },
  },
  inputPhoneNumber: {
    [mediaQueries.md]: {
      width: `calc(67% - ${space(3, true)})`,
    },
  },
}

export default styles
