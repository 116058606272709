import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// styles.
import { Link as RRLink } from 'react-router-dom'
import styles from './link.styles'
import { themeConsumer } from '../../../styles/ThemeContext'

// utils
import { getClassnameProp } from '../../../utils/stylesUtils'

const Link = props => {
  const { element = RRLink, ...rest } = props
  const { disabled, testid } = rest

  const El = styled(element)(
    styledProps => styles.base(styledProps),
    styledProps => ({
      ...(styledProps.disabled
        ? { ...styles.disabled, ...styles.base(styledProps).disabled }
        : null),
      ...(styledProps.transparent
        ? { ...styles.transparent, ...styles.base(styledProps).transparent }
        : null),
    })
  )
  let className = getClassnameProp(rest)?.className
  if (disabled) {
    className = className ? `${className} disabled` : 'disabled'
  }
  return <El {...rest} data-testid={testid} className={className} />
}

Link.defaultProps = {
  disabled: false,
  variant: 'link',
  size: 'medium',
  element: RRLink,
}

Link.propTypes = {
  /** Set link type. */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link', 'secondaryWithOutline']),
  /** Set link size. */
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  /** Disabled state of Link. */
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /** Path that Link should go to. */
  to: PropTypes.string.isRequired,
  element: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.elementType]),
  testid: PropTypes.string.isRequired,
}

export default themeConsumer(Link, 'button')
export { Link }
