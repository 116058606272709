export const FORM_INIT = 'FORM_INIT'
export const FORM_SUBMIT = 'FORM_SUBMIT'
export const FORM_SUBMIT_COMPLETE = 'FORM_SUBMIT_COMPLETE'
export const FORM_UPDATE = 'FORM_UPDATE'
export const FORM_UPDATE_FIELD = 'FORM_UPDATE_FIELD'
export const FORM_RESET = 'FORM_RESET'
export const FORM_VALIDATE = 'FORM_VALIDATE'
export const FORM_VALIDATE_ONEOF = 'FORM_VALIDATE_ONEOF'
export const FORM_UPDATE_VALIDATIONS = 'FORM_UPDATE_VALIDATIONS'
export const FORM_RESET_FIELD = 'FORM_RESET_FIELD'
export const FORM_ADD_FIELD = 'FORM_ADD_FIELD'
export const FORM_REMOVE_FIELDS = 'FORM_REMOVE_FIELDS'
export const FORM_SET_VALID = 'FORM_SET_VALID'
export const FORM_DELETE_FROM_STORE = 'FORM_DELETE_FROM_STORE'
export const FORM_SUBMIT_PARTIALLY = 'FORM_SUBMIT_PARTIALLY'
export const FORM_SET_DIRTY = 'FORM_SET_DIRTY'
export const FORM_SET_SENDING = 'FORM_SET_SENDING'
