// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { actionCreators } from '../../../../../actions'

// components.
import TabBar from '../../../../molecules/TabBar'

// schema.
import SCHEMA from './selectCoverTabs.schema'

type SelectCoverProps = {
  actions: {
    getAllProducts: Function,
    getPopularProtection: Function,
    getTemplates: Function,
  },
  fields: Object<Object>,
  productData: Array<Object>,
  renderFoot: Function,
}

// TODO: set up redux and always clear it on load of this view

class SelectCover extends Component<SelectCoverProps> {
  constructor(props) {
    super(props)
    this.state = {
      currentTab: 0,
    }
    this.updateSidebarFooter = this.updateSidebarFooter.bind(this)
    this.setActiveTab = this.setActiveTab.bind(this)
  }

  componentWillMount() {
    const {
      actions: { getAllProducts, getPopularProtection, getTemplates },
      productData,
    } = this.props
    if (!get(productData, 'length')) {
      getAllProducts()
    }
    getTemplates()
    getPopularProtection()
  }

  setActiveTab(index) {
    this.setState({ currentTab: index })
  }

  updateSidebarFooter(tabId) {
    const {
      actions: { showSidebarFooter, hideSidebarFooter },
    } = this.props

    if (tabId === 0) {
      hideSidebarFooter()
    } else {
      showSidebarFooter()
    }
  }

  render() {
    const { fields, renderFoot } = this.props
    const { currentTab } = this.state
    return (
      <TabBar
        spaceEvenly
        onTabsLoad={this.updateSidebarFooter}
        currentTab={currentTab}
        onTabChange={this.setActiveTab}
        tabSize="sm"
        schema={SCHEMA(this.props)}
        fields={fields}
        disableHash
        renderFoot={renderFoot}
      />
    )
  }
}

const mapStateToProps = state => ({
  mlclManagedTemplates: state.quoteTemplates.mlclManaged,
  productData: state.productRules.data,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectCover)
