// @flow
// constants.
import {
  NAVIGATION_CHANGE_FOOTER_TYPE,
  NAVIGATION_CHANGE_NAVIGATION_TYPE,
  NAVIGATION_RESET_NAVIGATION_TYPE,
  NAVIGATION_RESET_FOOTER_TYPE,
  INIT_STATE,
  NAVIGATION_BURGER_MENU_TOGGLE,
  NAVIGATION_NOTIFICATION_BANNER_VISIBLE,
} from '../types/navigation'

export const changeNavigationType = navigationType => ({
  type: NAVIGATION_CHANGE_NAVIGATION_TYPE,
  payload: {
    navigationType,
  },
})

export const resetNavigationType = () => ({
  type: NAVIGATION_RESET_NAVIGATION_TYPE,
})

export const changeFooterType = footerType => ({
  type: NAVIGATION_CHANGE_FOOTER_TYPE,
  payload: {
    footerType,
  },
})

export const resetFooterType = () => ({
  type: NAVIGATION_RESET_FOOTER_TYPE,
})

export const resetAllSavedState = () => ({
  type: INIT_STATE,
})

export const toggleBurgerMenu = isOpen => ({
  type: NAVIGATION_BURGER_MENU_TOGGLE,
  payload: {
    isOpen,
  },
})

export const notificationBannerIsVisible = showNotificationBanner => ({
  type: NAVIGATION_NOTIFICATION_BANNER_VISIBLE,
  payload: {
    showNotificationBanner,
  },
})
