/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconYoutube32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.005 16.197l-6.482-3.388v6.735l6.482-3.348zm4.393-7.955c.468.052 1.5.052 2.41 1.017 0 0 .736.723.95 2.384.255 1.941.242 3.884.242 3.884v1.822s.013 1.941-.242 3.883c-.214 1.647-.95 2.384-.95 2.384-.91.951-1.942.951-2.41 1.005 0 0-3.35.254-8.398.254-6.242-.053-8.156-.24-8.156-.24-.536-.095-1.742-.068-2.653-1.02 0 0-.735-.736-.95-2.383-.254-1.942-.24-3.883-.24-3.883v-1.822s-.014-1.943.24-3.884c.215-1.661.95-2.384.95-2.384.911-.965 1.943-.965 2.411-1.017 0 0 3.35-.242 8.398-.242 5.049 0 8.398.242 8.398.242z"
      fill="#000"
    />
  </Icon>
)

export default IconYoutube32
