import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'

// constants
// @ts-expect-error non-ts code
import { CREATION_DATE_FORMAT } from './quoteUtils'
import {
  PREFERRED_YES,
  POLICY_PARTY_TYPE_BUSINESS,
  CONTACT_TYPES_TEL,
  CONTACT_SUB_TYPE_WORK,
  CONTACT_TEL_SUB_TYPE_LIST,
  CONTACT_SUB_TYPE_MOBILE,
  PREFERRED_NO,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
  ADDRESS_TYPE_WORK,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../constants/policies'
import { CONTACT_TYPES, TITLES } from '../constants/forms'
import {
  API_DATE_OF_BIRTH_FORMAT,
  DATE_OF_BIRTH_FORMAT,
  DEFAULT_PHONE_CODE,
  COMMUNICATION_CHANNEL_MAP,
  DEFAULT_EMAIL_ENTITY,
  DEFAULT_PHONE_ENTITY,
} from '../constants/contactDetails'
// utils
// @ts-expect-error non-ts code
import { getDropdownValue } from './commonUtils'

// Types
import { Phone, Email, Address, RelatedParty } from '../types/ClientPolicies'
import { MasterList } from '../types/masterData'
import { FormField } from '../types/forms'

export const getPreferredItemBreakdown = (
  data: Array<Phone | Email | Address>,
  type: string | null = null
) => {
  if (data) {
    let preferredItem = []
    if (type === CONTACT_TYPES_TEL) {
      preferredItem = [...data]
        .filter(
          item =>
            item.preferred === PREFERRED_YES &&
            // @ts-expect-error need to rework this part as type is not on all contact entities
            item.type === CONTACT_TYPES_TEL &&
            // @ts-expect-error need to rework this part as subType is not on all contact entities
            CONTACT_TEL_SUB_TYPE_LIST.includes(item.subType as string)
        )
        // @ts-expect-error need to rework this part as type is not on all contact entities
        .sort(a => (a.type === CONTACT_SUB_TYPE_MOBILE ? -1 : 0))
    } else {
      preferredItem = data.filter(item => item.preferred === PREFERRED_YES)
    }

    if (preferredItem.length) {
      // @ts-expect-error need to rework this part as type is not on all contact entities
      return type ? preferredItem.find(item => item.type === type) : preferredItem[0]
    }
    return {}
  }
  return null
}

export const formatPhoneNumberWithSpaces = (phoneNumber: string) =>
  `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4, 7)} ${phoneNumber.substring(7, 10)}`

export const sortBasedonDate = (items: Array<Address | Phone | Email>, dateKey: string) =>
  items.sort((a, b) => {
    // @ts-expect-error need to improve type here
    if (!a[dateKey]) {
      return 1
    }
    // @ts-expect-error need to improve type here
    if (!b[dateKey]) {
      return -1
    }
    // @ts-expect-error need to improve type here
    const dateA = moment(a[dateKey] as string, CREATION_DATE_FORMAT)
    // @ts-expect-error need to improve type here
    const dateB = moment(b[dateKey] as string, CREATION_DATE_FORMAT)

    return dateA.isBefore(dateB) ? 1 : -1
  })

export const getNonPreferredLatestItemBreakdown = (
  data: Array<Email | Address | Phone>,
  type: string | null = null
): Address | Email | Phone | undefined | object => {
  if (data) {
    const nonPreferredItems = data.filter(item => item.preferred === 'N')
    const sortedNonPreferredItems = sortBasedonDate(nonPreferredItems, 'creationTime')

    if (sortedNonPreferredItems.length) {
      return type
        ? // @ts-expect-error item type doesn't exist on all contact entities
          sortedNonPreferredItems.find(item => item.type === type)
        : sortedNonPreferredItems[0]
    }
    return {}
  }
  return undefined
}

export const getFirstName = (payload: RelatedParty) => {
  const { firstName = '' } = payload || {}
  return firstName.trim()
}

export const getFullName = (
  payload: { title?: string; firstName: string; middleName?: string; lastName: string },
  showMiddleName = true
) => {
  const { title = '', firstName = '', middleName, lastName = '' } = payload || {}
  const midName = middleName && showMiddleName ? `${middleName} ` : ''
  const titleCode = TITLES.find(titles => titles.value.toUpperCase() === title.toUpperCase())
  const titleToUse = titleCode ? titleCode.label : title
  return `${titleToUse} ${firstName} ${midName}${lastName}`.trim()
}

export const getPhoneNumber = (data: Array<Phone>, type: string) => {
  const phoneObject = getPreferredItemBreakdown(data, type) as Phone

  if (!phoneObject) return ''
  const { number } = phoneObject
  return number || ''
}

export const getPhoneNumberCode = (data: Array<Phone>, type: string) => {
  const phoneObject = getPreferredItemBreakdown(data, type) as Phone

  if (!phoneObject) return ''
  const { idc } = phoneObject
  return idc || ''
}

export const getPhoneNumberWithCode = (data: Array<Phone>, type?: string | null) => {
  const phoneObject = getPreferredItemBreakdown(data, type) as Phone
  if (!phoneObject) return ''
  return `${get(phoneObject, 'idc', '')} ${
    // @ts-expect-error is areaCode on Phone entity?
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    phoneObject.areaCode ? `(${phoneObject.areaCode}) ` : ''
  }${get(phoneObject, 'number', '')}`.trim()
}

// get the preferred mobile if there are multiple, otherwise just use any
export const getMobile = (data: Array<Phone> = []) =>
  [...data]
    .filter(phone => phone.type === CONTACT_TYPES_TEL && phone.subType === CONTACT_TYPES.MOBILE)
    .sort(a => (a.preferred === 'Y' ? -1 : 0))[0] || {}

export const getIsMemberHasRole = (roles: Array<string>, role: string) =>
  !!(roles && roles.length && roles.includes(role))

export const makeName = ({ firstName, lastName }: RelatedParty, suffix = '') =>
  `${firstName} ${lastName} ${suffix}`

export const getPreferredAddress = (addresses: Array<Address>) =>
  getPreferredItemBreakdown(addresses) as Address

export const getEmail = (data: Array<Email> = []) => {
  const preferredEmail = getPreferredItemBreakdown(data) as Email
  return preferredEmail && preferredEmail.email ? preferredEmail.email : ''
}

export const getFullAddress = (data: Array<Address>, type: string | null = null) => {
  if (data) {
    const address = data.find(
      item => item.preferred === PREFERRED_YES && (type ? item.addressType === type : true)
    )
    if (!address) return ''
    const { street, locality, country } = address
    const houseNumber = isEmpty(address.houseNo) ? '' : `${address.houseNo} `
    const houseLocality = locality ? `${locality}` : ''
    const streetAddress = street ? `${street}, ` : ''
    return `${houseNumber}${streetAddress}${houseLocality} ${country}`
  }
  return ''
}

export const getAddressByAddressType = (data: Array<Address>, type: string | null = null) => {
  let address
  if (data) {
    address = data.find(item => item.addressType === type)
  }
  return address
}

/**
 * Helper function to construct the address based on the objects in the address.
 * Pattern: `${poBox}${houseNumber}${streetAddress}
 * ${houseLocality} ${state} ${country} ${postCodeAddress}`
 * @param {*} address
 * @returns the address string
 */
export const constructAddress = (address: Address | undefined) => {
  if (!address || !Object.keys(address).length) return ''
  const { street, locality, country, state, postCode, district } = address
  const houseNumber = isEmpty(address.houseNo) ? '' : `${address.houseNo} `
  const houseLocality = locality ? `${locality}` : ''
  const streetAddress = street ? `${street}, ` : ''
  const postCodeAddress = postCode ? `${postCode}` : ''
  const poBox = district ? `PO Box ${district}, ` : ''
  // eslint-disable-next-line max-len
  return `${poBox}${houseNumber}${streetAddress}${houseLocality} ${state} ${country} ${postCodeAddress}`
}

/**
 * get the full address from a list of addresses.
 * @param {Array<Object>} data - a list of addresses
 * @param {string} type - optional, the type of the address wants to find in the list of addresses
 * @returns {string} represent the full address including:
 *                   houseNo, street, locality, state, country, postcode
 */
export const getFullAddressWithState = (data: Array<Address>, type: string | null = null) => {
  if (data) {
    const address = data.find(
      item => item.preferred === PREFERRED_YES && (type ? item.addressType === type : true)
    )
    return constructAddress(address)
  }
  return ''
}

export const getFullAddressWithStateNonPrefered = (
  data: Array<Address>,
  type: string | null = null
) => {
  if (data) {
    const address = data.find(
      item => item.preferred === 'N' && (type ? item.addressType === type : true)
    )
    if (!address) return ''
    return constructAddress(address)
  }
  return ''
}

/**
 * Remove gender key & add postCode
 * @returns transformed addresses in contactMethods
 */
export const normalizeContactDetails = (contact: RelatedParty) => {
  // const contact = contactDetails
  if (contact) {
    delete contact.gender
    const updatedAddress =
      contact.contactMethods &&
      contact.contactMethods.addresses &&
      contact.contactMethods.addresses.map(address => ({
        ...address,
        postCode: address.postCode.toString(),
      }))
    contact.contactMethods.addresses = updatedAddress
  }
  return contact
}

export const isAddressesSame = (address1: Address, address2: Address) =>
  (address1.houseNo || '') === (address2.houseNo || '') &&
  (address1.district || '') === (address2.district || '') &&
  address1.street === address2.street &&
  address1.locality === address2.locality &&
  address1.state === address2.state &&
  address1.country === address2.country &&
  address1.postCode === address2.postCode

export const isPartyTypeOrg = (partyType: string) => partyType === POLICY_PARTY_TYPE_BUSINESS

// FIXME: Potentially deprecate
export const getPartyName = (party: RelatedParty) => {
  if (isPartyTypeOrg(party.partyType)) {
    return party.businessName
  }

  return `${party.firstName} ${party.middleName ? party.middleName : ''} ${party.lastName}`
}

export const getCountryCode = (idc: string | FormField, masterList: MasterList) => {
  const countryCodeList = masterList?.data?.countryCodeList ?? []
  const countryEntity = getDropdownValue(idc, countryCodeList) as { countryCode: string }
  if (countryEntity) return get(countryEntity, 'countryCode', '')
  return undefined
}

export const getWorkPhone = (data: Array<Phone>) => {
  const workPhone = data && data.filter(phone => phone.subType === CONTACT_SUB_TYPE_WORK)
  return workPhone.length ? workPhone[0] : {}
}

// Returns 'N' if any TEL element in data array contains preferred 'Y',
// returns 'Y' in other case
export const getPreferredValueForMobile = (data: Array<Phone>) =>
  data.some(entity => entity.type === CONTACT_TYPES_TEL && entity.preferred === PREFERRED_YES)
    ? PREFERRED_NO
    : PREFERRED_YES

// check if residentialAddress address is preferred or NOT
export const getAddressType = (address: Address, data: Array<Address>) =>
  address.preferred === PREFERRED_YES
    ? getFullAddressWithState(data)
    : getFullAddressWithStateNonPrefered(data)

//
export const getFormattedDOB = (dob: string) =>
  moment(dob, API_DATE_OF_BIRTH_FORMAT).format(DATE_OF_BIRTH_FORMAT)

/**
 * Determines what the value of the distric field should be.
 * We want to store PO box number in case it's a PO box or empty if not
 * @param {object} type - field object of postalType determines if address is a PO box
 * @param {object} number - PO box number
 * @param {string} existingValue - current district field value
 * @returns {string} updated district field value
 */
export const determineDistrict = (
  type: { value: string } | null,
  number: { value: string },
  existingValue: string | null
) => {
  if (type && type.value === 'PO BOX') {
    return number.value
  }
  if (type && type.value === '') {
    return ''
  }
  return existingValue || ''
}

/** Get the latest matching address type sorted based on date. */
export const getLatestAddressBasedOnType = (
  addresses: Array<Address>,
  addressType: string
): Address => {
  const allAddresses = sortBasedonDate(
    (addresses || []).filter(address => address.addressType === addressType),
    'creationTime'
  ) as Array<Address>
  // @FIXME: Shouldn't be returning empty objects as nullish values
  return (allAddresses && allAddresses[0]) || {}
}

export const getContactDetailsData = (personalDetails: RelatedParty) => {
  const { contactMethods, preferredCommChannel } = personalDetails
  if (contactMethods) {
    const emailAddress = getEmail(contactMethods?.emails ?? [])
    const mobileDetails = getMobile(contactMethods?.phones ?? [])
    const contactNumber = `${mobileDetails?.idc ?? DEFAULT_PHONE_CODE} ${
      mobileDetails?.number ?? ''
    }`
    const telePhoneNumber = getPhoneNumberWithCode(
      get(contactMethods, 'phones', []),
      CONTACT_TYPES.TEL
    )
    const addresses = get(contactMethods, 'addresses', [])

    const partyType = get(personalDetails, 'partyType', '')
    const isOrgPartyType = partyType === POLICY_PARTY_TYPE_BUSINESS

    const homeAddress = getLatestAddressBasedOnType(
      addresses,
      isOrgPartyType ? ADDRESS_TYPE_WORK : ADDRESS_TYPE_HOME
    )
    const residentialAddress = constructAddress(homeAddress)

    // If partyType=ORG, use preferred address as statement address
    const statementAddress = isOrgPartyType
      ? getPreferredAddress(addresses)
      : getLatestAddressBasedOnType(addresses, ADDRESS_TYPE_STATEMENT)

    // If partyType=ORG, then get the preferred address
    // And use that instead for secondary address.
    const postalAddress = constructAddress(statementAddress)

    const preferredMethodOfComms = COMMUNICATION_CHANNEL_MAP[preferredCommChannel] || 'None'
    return {
      emailAddress,
      contactNumber,
      telePhoneNumber,
      residentialAddress,
      preferredMethodOfComms,
      postalAddress,
    }
  }
  return null
}

export const contactDetailsFormToRequestPayload = (
  formData: { [key: string]: string },
  relatedParty: RelatedParty,
  primaryAddressType: 'W' | 'H',
  masterList: MasterList
) => {
  const {
    contactMethods: { addresses, emails, phones },
  } = relatedParty

  const previousDay = moment(moment(), 'DD/MM/YYYY').subtract(1, 'days').format('YYYY-MM-DD')

  const primaryAddress = getLatestAddressBasedOnType(addresses, primaryAddressType)
  const statementAddress = getLatestAddressBasedOnType(addresses, ADDRESS_TYPE_STATEMENT)
  // The API behaviour to update addresses in BANCS is quirky.
  // the request object changes between the three following scenarios
  //  1. Updating a postal OR residential/work address
  //  2. Adding a postal address
  //  3. Removing a postal address
  //  4. Modifying a manually inputed postal address if the primary address has not changed
  const addingPostalAddress = Object.keys(statementAddress).length === 0 && formData.postalStreet

  const hasPrimaryAddressChanged = !isAddressesSame(primaryAddress, {
    ...primaryAddress,
    country: formData.country,
    houseNo: formData.houseNo,
    locality: formData.locality,
    postCode: formData.postCode,
    district: formData.postalNumber,
    state: formData.state,
    street: formData.street,
  })

  const updatedAddresses = [
    // If we're adding a postal address, we want to add the existing residential address
    // and "invalidate" it by modifying the effectiveStartDate and effectiveEndDate to be yesterday
    ...((addingPostalAddress && [
      {
        ...primaryAddress,
        effectiveEndDate: previousDay,
        effectiveStartDate: previousDay,
      },
      {
        country: formData.country,
        houseNo: formData.houseNo,
        locality: formData.locality,
        postCode: formData.postCode,
        district: formData.postalNumber,
        state: formData.state,
        street: formData.street,
        addressType: primaryAddressType,
        preferred: PREFERRED_NO,
      },
    ]) ||
      []),
    // For some reason if we want to modify the postal and not the primary,
    // the primary cannot exist in the payload.
    // This doesn't matter if it's the other way around though
    ...(hasPrimaryAddressChanged || !formData.postalStreet
      ? [
          {
            // If we're not adding a postal address we want to
            // keep the existing address and modify it based on form input
            ...(!addingPostalAddress && {
              ...primaryAddress,
            }),
            country: formData.country,
            houseNo: formData.houseNo,
            locality: formData.locality,
            postCode: formData.postCode,
            district: formData.postalNumber,
            state: formData.state,
            street: formData.street,
            addressType: primaryAddressType,
            preferred: formData.postalStreet ? PREFERRED_NO : PREFERRED_YES,
          },
        ]
      : []),
    ...(formData.postalStreet
      ? [
          {
            ...statementAddress,
            country: formData.postalCountry,
            houseNo: formData.postalHouseNo,
            locality: formData.postalLocality,
            postCode: formData.postalPostCode,
            district: formData.postalPostalNumber,
            state: formData.postalState,
            street: formData.postalStreet,
            addressType: ADDRESS_TYPE_STATEMENT,
            preferred: PREFERRED_YES,
          },
        ]
      : []),
  ]

  const phoneEntityMobile = getMobile(phones)
  const phonesPayload = [
    {
      ...DEFAULT_PHONE_ENTITY,
      ...phoneEntityMobile,
      preferred: PREFERRED_YES,

      number: formData.mobilePhone,
      idc: formData.phoneCode,
      countryCode: getCountryCode(formData.phoneCode, masterList),
    },
  ]

  return {
    isTFNProvided: relatedParty.isTFNProvided,
    preferredCommChannel: formData.preferredMethodOfComms,
    contactMethods: {
      phones: phonesPayload,
      addresses: updatedAddresses,
      emails: [
        ...(emails
          ? emails.map(email => ({
              ...email,
              ...(email.preferred === PREFERRED_YES && {
                email: formData.email,
              }),
            }))
          : [
              {
                ...DEFAULT_EMAIL_ENTITY,
                email: formData.email,
              },
            ]),
      ],
    },
  }
}

export const createLOARequestPayload = (
  data: { [key: string]: string },
  masterList: MasterList
) => {
  const {
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    houseNo,
    houseName,
    street,
    locality,
    state,
    country,
    mobilePhone,
    email,
    partyType,
    postalType,
    postalNumber,
    companyName,
    postCode,
    abn,
    postalPostalNumber,
    postalPostalType,
    postalCountry,
    postalHouseNo,
    postalLocality,
    postalPostCode,
    postalState,
    postalStreet,
    duration,
  } = data
  return {
    partyType,
    loaEndDate: duration,
    companyIdentifier: 'NGRP', // Hardcoded
    ...(partyType === POLICY_PARTY_TYPE_BUSINESS && {
      name: {
        businessName: companyName,
      },
      abnNumber: abn,
    }),
    ...(partyType === POLICY_PARTY_TYPE_INDIVIDUAL && {
      name: {
        title,
        firstName,
        middleName,
        lastName,
      },
      dob: moment(dateOfBirth, DATE_OF_BIRTH_FORMAT).format(API_DATE_OF_BIRTH_FORMAT),
      smokerStatus: 'UNK', // Hardcoded
      gender: 'U', // Hardcoded
      lifeStatus: 'ALV', // Hardcoded
    }),
    contacts: {
      postals: [
        {
          houseNo,
          houseName,
          street,
          locality,
          district: determineDistrict(
            postalType !== ''
              ? {
                  value: postalType,
                }
              : null,
            { value: postalNumber },
            null
          ),
          postCode,
          state,
          country,
          addressType:
            partyType === POLICY_PARTY_TYPE_BUSINESS ? ADDRESS_TYPE_WORK : ADDRESS_TYPE_HOME,
          preferred: postalStreet ? PREFERRED_NO : PREFERRED_YES,
        },
        ...(postalStreet
          ? [
              {
                houseNo: postalHouseNo,
                street: postalStreet,
                locality: postalLocality,
                district: determineDistrict(
                  postalPostalType !== ''
                    ? {
                        value: postalPostalType,
                      }
                    : null,
                  { value: postalPostalNumber },
                  null
                ),
                postCode: postalPostCode,
                state: postalState,
                country: postalCountry,
                addressType: ADDRESS_TYPE_STATEMENT,
                preferred: PREFERRED_YES,
              },
            ]
          : []),
      ],
      phones: [
        ...(partyType === POLICY_PARTY_TYPE_INDIVIDUAL
          ? [
              {
                ...DEFAULT_PHONE_ENTITY,
                countryCode: getCountryCode(data.phoneCode, masterList),
                idc: data.phoneCode,
                number: mobilePhone,
              },
            ]
          : []),
      ],
      emails: [
        ...(partyType === POLICY_PARTY_TYPE_INDIVIDUAL
          ? [
              {
                type: ADDRESS_TYPE_HOME,
                email,
                preferred: PREFERRED_YES,
              },
            ]
          : []),
      ],
    },
  }
}
