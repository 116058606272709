import get from 'lodash/get'

import { getFullAddressWithState } from '../../../../../utils/contactUtils'
import {
  POLICY_RELATIONSHIPS_SMSF,
  PREFERRED_YES,
  COUNTRY_CODE,
} from '../../../../../constants/policies'

export const initForms = props => {
  const {
    createQuote,
    sidebar: { panelProps: sidebarPanelProps },
  } = props

  const { policyInstanceNo } = sidebarPanelProps

  const { quotes, activeIndex } = createQuote
  const activeQuote = quotes[activeIndex]
  const policy = activeQuote.policyStructure.find(p => p.policyInstanceNo === policyInstanceNo)
  const relationship =
    policy.relationships &&
    policy.relationships.find(rel => rel.role.includes(POLICY_RELATIONSHIPS_SMSF))

  if (!relationship) return {}
  const isSmsfIsCompany = !!get(relationship, 'companies[0].directors[0]')

  const { relatedParty, companies, fundDetails } = relationship

  const residentialAddressIndex = relatedParty.contactMethods.addresses
    ? relatedParty.contactMethods.addresses.findIndex(
        address => address.preferred === PREFERRED_YES
      )
    : -1
  const primaryEmailIndex = relatedParty.contactMethods.emails
    ? relatedParty.contactMethods.emails.findIndex(email => email.preferred === PREFERRED_YES)
    : -1

  const email =
    primaryEmailIndex !== -1 ? relatedParty.contactMethods.emails[primaryEmailIndex].email : {}
  const address =
    residentialAddressIndex !== -1
      ? relatedParty.contactMethods.addresses[residentialAddressIndex]
      : {}

  const data = {
    superannuationFundName: relatedParty.businessName,
    abn: relatedParty.abnNumber,
    abnConfirmation: relatedParty.abnNumber,
    email,
    regulatory: fundDetails.fundRegulated,
    residentialAddress: getFullAddressWithState([address]),
    residentialHouseNo: address.houseNo,
    residentialStreet: address.street,
    residentialLocality: address.locality,
    residentialState: address.state,
    residentialCountry: address.country || COUNTRY_CODE,
    residentialPostCode: address.postCode,
    isSoleDirector: isSmsfIsCompany ? companies[0].directors.length === 1 : false,
    directors: isSmsfIsCompany ? companies[0].directors.map(director => director.relatedParty) : [],
  }

  return data
}
