/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconRadioOff16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10zm0 2A7 7 0 1 0 8 1a7 7 0 0 0 0 14z"
      fill="#000"
    />
  </Icon>
)

export default IconRadioOff16
