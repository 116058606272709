// helpers.
import {
  required,
  optional,
  email,
  postcode,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../../utils/formUtils'
import { STATES, REGULATORY } from '../../../../../constants/forms'

export const FORM_ID = 'smsfDetails'

const schema = (state = {}, fields) => ({
  superannuationFundName: {
    condition: required,
  },
  regulatory: {
    condition: { condition: required },
    options: REGULATORY,
  },
  residentialAddress: {
    condition: state.isManualResidential ? optional : required,
  },

  residentialStreet: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.policyOwnerDetailsResidentialAddressStreetFieldError.value,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.policyOwnerDetailsResidentialAddressValidStreetFieldError.value,
      },
    ],
  },
  residentialHouseNo: {
    condition: [
      optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value,
      },
    ],
  },
  residentialLocality: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.policyOwnerDetailsResidentialAddressLocalityFieldError.value,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.policyOwnerDetailsResidentialAddressValidLocalityFieldError.value,
      },
    ],
  },
  residentialState: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.policyOwnerDetailsResidentialAddressStateFieldError.value,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.policyOwnerDetailsResidentialAddressValidStateFieldError.value,
      },
    ],
    options: STATES,
  },
  residentialCountry: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.policyOwnerDetailsResidentialAddressCountryFieldError.value,
          }
        : optional,
      {
        condition: manualAddressRegex,
        errorMsg: fields.policyOwnerDetailsResidentialAddressCountryFieldInvalidError.value,
      },
    ],
  },
  residentialPostCode: {
    condition: [
      state.isManualResidential
        ? {
            condition: required,
            errorMsg: fields.policyOwnerDetailsResidentialAddressPostCodeFieldError.value,
          }
        : optional,
      {
        condition: manualAddressPostalCode,
        errorMsg: fields.policyOwnerDetailsResidentialAddressValidPostalFieldError.value,
      },
      postcode,
    ],
  },
  abn: {
    condition: required,
  },
  email: {
    condition: email,
  },
})

export default schema
