import { space, colours, fontWeights } from '../../../../../styles'

const styles = {
  descriptionLabel: {
    color: colours.lightGreen,
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
  },

  policyStructureContainer: {
    borderBottom: `1px solid ${colours.lightestGrey}`,
  },

  policyStructureLabel: {
    paddingLeft: space(4, true),
  },

  policyStructureTree: {
    paddingLeft: space(4, true),
  },
}

export default styles
