// @flow
import {
  FORM_INIT,
  FORM_SUBMIT,
  FORM_SUBMIT_COMPLETE,
  FORM_VALIDATE,
  FORM_VALIDATE_ONEOF,
  FORM_UPDATE_FIELD,
  FORM_UPDATE,
  FORM_RESET,
  FORM_UPDATE_VALIDATIONS,
  FORM_RESET_FIELD,
  FORM_ADD_FIELD,
  FORM_REMOVE_FIELDS,
  FORM_SET_VALID,
  FORM_SET_DIRTY,
  FORM_SET_SENDING,
  FORM_DELETE_FROM_STORE,
  FORM_SUBMIT_PARTIALLY,
} from '../types/form'
import { getSerializedFormState } from '../../utils/formUtils'
import { setErrorFocus } from '../../utils/commonUtils'

export const formInit = (id, schema, data = null, dynamicFields = null) => ({
  type: FORM_INIT,
  payload: {
    id,
    schema,
    data,
    dynamicFields,
  },
})

export const formUpdateField = (id, field, data) => ({
  type: FORM_UPDATE_FIELD,
  payload: {
    id,
    field,
    data,
  },
})

export const formUpdate = (id, data) => ({
  type: FORM_UPDATE,
  payload: {
    id,
    data,
  },
})

export const formAddField = (id, field, data) => ({
  type: FORM_ADD_FIELD,
  payload: {
    id,
    field,
    data,
  },
})

export const formRemoveFields = (id, field) => ({
  type: FORM_REMOVE_FIELDS,
  payload: {
    id,
    field,
  },
})

export const formResetField = (id: string, field: string | Array) => ({
  type: FORM_RESET_FIELD,
  payload: {
    id,
    field,
  },
})

export const formReset = (id, schema, data = null) => ({
  type: FORM_RESET,
  payload: {
    id,
    schema,
    data,
  },
})

export const formUpdateValidations = (id, schema) => ({
  type: FORM_UPDATE_VALIDATIONS,
  payload: {
    id,
    schema,
  },
})

export const formValidate = (id, schema) => ({
  type: FORM_VALIDATE,
  payload: {
    id,
    schema,
  },
})

export const formSetValid = (id, isValid) => ({
  type: FORM_SET_VALID,
  payload: {
    id,
    isValid,
  },
})

export const formSetDirty = (id: string, isDirty: boolean) => ({
  type: FORM_SET_DIRTY,
  payload: {
    id,
    isDirty,
  },
})

export const formSetSending = (id: string, isSending: boolean) => ({
  type: FORM_SET_SENDING,
  payload: {
    id,
    isSending,
  },
})

export const formValidateOneOf = id => ({
  type: FORM_VALIDATE_ONEOF,
  payload: {
    id,
  },
})

export const formSubmitComplete = id => ({
  type: FORM_SUBMIT_COMPLETE,
  payload: { id },
})

export const deleteForms = (ids: Array<string>) => ({
  type: FORM_DELETE_FROM_STORE,
  payload: ids,
})

export const submitPartialForm = (id: string) => ({
  type: FORM_SUBMIT_PARTIALLY,
  payload: { id },
})

export function formSubmit(id, schema, callback, meta) {
  return (dispatch, getState) => {
    const form = getState().forms[id]
    if (schema && form) {
      dispatch(formUpdateValidations(id, schema))
      dispatch(formValidate(id, schema))
    }
    const updatedForm = getState().forms[id]
    if (updatedForm && updatedForm.isValid) {
      const data = getSerializedFormState(form.fields)
      dispatch({
        type: FORM_SUBMIT,
        payload: { id },
      })
      callback(data, updatedForm, meta)
    } else if (updatedForm) {
      setErrorFocus()
    }
  }
}
