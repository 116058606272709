import { trace } from '@opentelemetry/api'
import ReactGA4 from 'react-ga4'

type configType = {
  GA?: {
    category: string
    action: string
  }
  Splunk: {
    attributes: {
      [key: string]: string | number | boolean
      'workflow.name': string
    }
  }
}

export const createEvent = (config: configType) => {
  const tracer = trace.getTracer('Retail Portal')
  const span = tracer.startSpan(config.Splunk.attributes['workflow.name'], {
    attributes: config.Splunk.attributes,
  })

  // return modified span to also trigger GA action
  return {
    ...span,
    end: () => {
      if (config.GA) {
        ReactGA4.event(config.GA)
      }
      span.end()
    },
  }
}
