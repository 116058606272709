export const TOAST_AUTOCLOSE_DELAY = 3000

// these group toast notifications so we can update their content, prevent duplicates and
//   programmatically close them from different areas of the application. these should not be
//   numbers to avoid collissions. most importantly, make sure the variable names are readable.
export const TOAST_ID_GENERIC_API_FAILURE = 'api'
export const TOAST_ID_ROUTE_ERROR = 'r'
export const TOAST_ID_CALCULATE_QUOTE = 'c'
export const TOAST_ID_QUICK_QUOTE = 'CREATE_QUOTE_QUICK_QUOTE'
export const TOAST_ID_REMOVE_SUPPORT_STAFF = 'TOAST_ID_REMOVE_SUPPORT_STAFF'
export const TOAST_ID_SAVE_QUOTE = 'saveQuote'
export const TOAST_ID_DOWNLOAD_DOCUMENT = 'downloadDocument'
export const TOAST_ID_ENQUIRY_ID_CREATE = 'EnquiryIDCreate'
export const TOAST_ID_CAPTURE_CREDITCARD = 'captureCreditCardToast'
export const TOAST_ID_SEARCH_QUOTE = 'search'
export const TOAST_ID_LOGOUT_PROGRESS = 'logoutProgress'
export const TOAST_ID_LOGOUT_SUCCESS = 'logoutSuccess'
export const TOAST_ID_LOGOUT_ERROR = 'logoutError'
export const TOAST_ID_CONTACT_MLC = 'contactMLCError'
export const TOAST_ID_FORGOT_PASSWORD_PROGRESS = 'forgotPasswordProgess'
export const TOAST_ID_FORGOT_PASSWORD_ERROR = 'forgotPasswordError'
export const TOAST_ID_RESEND_CODE_PROGRESS = 'resendCodeProgess'
export const TOAST_ID_RESEND_CODE_ERROR = 'resendCodeError'
export const TOAST_ID_RESEND_CODE_SUCCESS = 'resendCodeSuccess'
export const TOAST_ID_VERIFY_CODE_PROGRESS = 'verifyCodeProgress'
export const TOAST_ID_VERIFY_CODE_ERROR = 'verifyCodeError'
export const TOAST_ID_RESET_PASSWORD_PROGRESS = 'resetPasswordProgress'
export const TOAST_ID_RESET_PASSWORD_ERROR = 'resetPasswordError'
export const TOAST_ID_RESET_PASSWORD_SUCCESS = 'resetPasswordSuccess'
export const TOAST_ID_VISUALISER_ERROR = 'visualiserError'
export const TOAST_ID_RESTORE_PREFERENCES = 'restoreQuoteDefaults'
export const TOAST_ID_ADVISER_REGISTRATION = 'adviserRegistration'
export const TOAST_ID_RETENTION_POLICY_ERROR = 'retentionPolicyCallError'
export const TOAST_ID_DELETE_QUOTE_ERROR = 'deleteQuoteError'
export const TOAST_ID_DOWNLOAD_POLICIES_REPORT = 'downloadPoliciesReport'
// Toast Title
export const TOAST_TITLE_SAVE_QUOTE_INITIATE = 'Saving quote'
export const TOAST_TITLE_SAVE_QUOTE_ERROR = 'Error in Saving quote'
export const TOAST_TITLE_SAVE_QUOTE_SUCCESS = 'Quote saved successfully'
export const TOAST_TITLE_SAVE_QUOTE_CAN_NOT_PROCESS =
  'Can not process save due to error in quote calculation'
export const TOAST_TITLE_CALCULATE_QUOTE_INITIATE = 'Calculating quote'
export const TOAST_TITLE_CALCULATE_QUOTE_ERROR = 'Error in quote calculation'
export const TOAST_TITLE_QUICK_QUOTE_ERROR = 'Error in quick quote'
export const TOAST_TITLE_CALCULATE_QUOTE_SUCCESS = 'Quote has been updated successfully'
export const TOAST_TITLE_QUICK_QUOTE_SUCCESS = 'Quick quote processed successfully'
export const TOAST_TITLE_REMOVE_SUPPORT_STAFF_SUCCESS =
  '$0 will no longer have access to your profile.'
export const TOAST_TITLE_REMOVE_SUPPORT_STAFF_ERROR = '$0 removal failed. Please try again.'
export const TOAST_TITLE_CAPTURE_CREDITCARD_SUCCESS = 'Payment details added successfully'
export const TOAST_TITLE_CAPTURE_CREDITCARD_ERROR = 'Payment details could not be added'
export const TOAST_TITLE_FORGOT_PASSWORD_PROGRESS = 'Sending code to your mobile number'
export const TOAST_TITLE_FORGOT_PASSWORD_ERROR = 'Error in sending code to your mobile'
export const TOAST_TITLE_RESEND_CODE_PROGRESS = 'Resending code to your mobile number'
export const TOAST_TITLE_RESEND_CODE_ERROR = 'Error in sending code to your mobile'
export const TOAST_TITLE_RESEND_CODE_SUCCESS = 'Code is sent to your mobile number'
export const TOAST_TITLE_VERIFY_CODE_PROGRESS = 'Verifying the code'
export const TOAST_TITLE_VERIFY_CODE_ERROR = 'Code does not match'
export const TOAST_TITLE_RESET_PASSWORD_PROGRESS = 'Updating password'
export const TOAST_TITLE_RESET_PASSWORD_SUCCESS = 'Password updated successfully'
export const TOAST_TITLE_RESET_PASSWORD_ERROR = 'Error in updating password'
export const TOAST_TITLE_VISUALISER_ERROR = 'Something went wrong. Please try again after sometime.'
export const TOAST_ID_DOWNLOAD_DOCUMENT_SUCCESS = 'Document Downloaded'
export const TOAST_ID_DOWNLOAD_DOCUMENT_ERROR = 'Error in Downloading Document'
export const TOAST_ID_DOWNLOAD_PAYMENT_RECEIPT_ERROR =
  // eslint-disable-next-line max-len
  'Sorry, your receipt cannot be downloaded right now. Please call 13 65 25 to request a copy of your receipt'
export const TOAST_LOGOUT_PROGRESS = 'Logout in progress'
export const TOAST_LOGOUT_SUCCESS = 'Logout successful'
export const TOAST_LOGOUT_ERROR = 'Error in logging out'
export const TOAST_PASSWORD_CHANGED_SUCCESS = 'Password changed successfully'
export const TOAST_PASSWORD_CHANGE_ERROR = 'Error updating password'
export const TOAST_TITLE_CONTACT_MLC_ERROR = 'Error in contacting MLC'
export const TOAST_ID_UPDATE_CONTACT_INFO = 'updateAdviserContactDetails'
export const TOAST_ID_UPDATE_CUSTOMER_CONTACT_INFO = 'updateCustomerCOntactDetails'
export const TOAST_ID_UPDATE_CONTACT_ERROR = 'Failed to update contact info'
export const TOAST_ID_UPDATE_CONTACT_SUCCESS = 'Contact details updated successfully'
export const TOAST_TITLE_CREDITCARD_PAYMENT_SUCCESS = 'Payment done successfully'
export const TOAST_TITLE_CREDITCARD_PAYMENT_ERROR = 'Payment unsuccessful'
export const TOAST_TITLE_CREDITCARD_PAYMENT_TRIGGERED = 'Payment in progress'
export const TOAST_TITLE_SAVE_CARD_SUCCESS = 'Card saved successfully'
export const TOAST_TITLE_SAVE_CARD_ERROR = 'Save Card unsuccessful'
export const TOAST_TITLE_SAVE_CARD_TRIGGERED = 'Save Card in progress'
export const TOAST_ROA_NO_FILE_ERROR_MSG = 'File not found to download'
export const TOAST_ROA_NO_FILE_ERROR_ID = 'fileNotFoundToDownload'
export const TOAST_ROA_DOWNLOAD_ERROR_POLICY_MSG = 'Policies not found'
export const TOAST_ROA_DOWNLOAD_ERROR_POLICY_ID = 'policiesNotFound'
export const TOAST_TITLE_RESTORE_PREFERENCES_SUCCESS = 'Quote defaults restored successfully'
export const TOAST_TITLE_RESTORE_PREFERENCES_ERROR = 'Error in restoring quote defaults'
export const TOAST_UPDATE_BENEF_ERROR_MSG = 'Beneficiary update failed'
export const TOAST_UPDATE_BENEF_SUCCESS_MSG = 'Request submited succesfully'
export const TOAST_UPDATE_BENEF_ERROR_ID = 'beneficiaryUpdateFailed'
export const TOAST_UPDATE_BENEF_SUCCESS_ID = 'requestSubmitedSuccesfully'
export const TOAST_ADVISER_REGISTRATION_ERROR =
  'Failed to submit, please try again after 30 seconds'
export const TOAST_SAVE_QUOTE_EMPTY_POLICY_TITLE = 'Cannot save quote with empty policy'
export const TOAST_RETENTION_CALL_POLICY_FAIL = 'Sorry, the policy is not available'
export const TOAST_ID_DOCUMENT_CREATION_SUCCESS = 'Document creation successful'
export const TOAST_DELETE_QUOTE_ERROR = 'Failed to delete quote, please try again'
export const TOAST_DOWNLOAD_POLICIES_ERROR_REPORT = 'Download failed, please try again'
export const TOAST_DOWNLOAD_POLICIES_NO_RECORD = 'No record found'
