// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { components } from 'react-select'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { Input, Select, Label } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// styles.
import styles from './addressLookUp.styles'

// utilities.
import { idMaker, getValue } from '../../../utils/formUtils'
import { getClassnameProp } from '../../../utils/stylesUtils'
import { makeCountryOptions } from '../../../utils/commonUtils'

// constants
import { COUNTRY_CODE } from '../../../constants/policies'
import { STATES } from '../../../constants/forms'

type AddressLookupProps = {
  // Name of address lookup fields and initialised store state.
  name: string,
  // Contains Auto Entry properties
  auto: Object,
  // Contains Manual Entry properties
  manual: Object,
  // A function to be fired on toggleChange
  toggleHandler?: Function,
  // Check if Manual entry is enable
  isManual?: boolean,
  // Redux actions available to the component
  actions: {
    addressLookupInit: Function,
    setAdddressToManual: Function,
    getAddresses: Function,
    getMasterList: Function,
  },
  // master data
  masterData: Object,
}

type AddressLookupState = {
  countryOptions: Array<Object>,
  // selectAddress is get true when AddressSelect Field is focused
  selectAddress: Boolean,
}

export const Wrapper = styled('div')(({ isManual }) => styles.wrapper(isManual))
const Toggle = styled('small')(styles.toggle)
const InputGroup = styled('div')(styles.inputGroup)
const InputThirds = styled(Input)(styles.inputThirds)
export const AddressSelect = styled(Select)(styles.select)
export const AddressToggle = styled('div')(styles.addressToggle)
const AddressToggleRight = styled('div')(styles.addressToggleRight)

const makeAddressOptions = (addresses: []): [] =>
  addresses.map(address => ({
    ...address,
    label: address.fullAddress,
    value: address.fullAddress,
  }))

// Custom menu for adreess look up list
const ActionItem = styled('div')(styles.actionItem)
const MenuList = (toggleLabel, toggle) => props => {
  const { isLoading, children } = props
  return (
    <components.MenuList {...props}>
      {children}
      {!isLoading ? (
        <ActionItem
          onClick={() => {
            toggle(true)
          }}
        >
          <small>{toggleLabel}</small>
        </ActionItem>
      ) : null}
    </components.MenuList>
  )
}

export class AddressLookup extends Component<AddressLookupProps, AddressLookupState> {
  constructor(props: AddressLookupProps) {
    super(props)

    const {
      masterData,
      actions: { addressLookupInit, getMasterList },
      name,
      isManual,
    } = props

    // initialising state
    const countryCodeList = get(masterData, 'countryCodeList')
    this.state = {
      countryOptions: countryCodeList ? makeCountryOptions(countryCodeList) : [],
      selectAddress: false,
    }

    // init for the component
    getMasterList()
    addressLookupInit(name, isManual)

    this.updateAddresses = debounce(this.updateAddresses, 200)
  }

  static getDerivedStateFromProps(nextProps: Object, prevState: Object) {
    const nextCountryCodeList = get(nextProps, 'masterData.countryCodeList')
    if (!prevState.countryOptions.length && nextCountryCodeList) {
      return {
        countryOptions: makeCountryOptions(nextCountryCodeList),
      }
    }
    return null
  }

  toggle = (isManual: boolean): void => {
    const {
      name,
      toggleHandler,
      actions: { setAdddressToManual },
    } = this.props
    toggleHandler(isManual, name)
    setAdddressToManual(name, isManual)
  }

  mapAddressFields = data => {
    const {
      manual: {
        streetName,
        localityName,
        stateName,
        countryName,
        postCodeName,
        houseNoName,
        postalType,
        postalNumber,
      },
    } = this.props
    return {
      [streetName || 'street']:
        (data.street && `${data.streetNumber} ${data.street}`) || `${data.postal}`,
      [localityName || 'locality']: data.locality,
      [houseNoName || 'houseNo']:
        `${data.floorLevelType} ${data.floorLevelNumber} ${data.flatUnitType} ${data.flatUnitNumber} ${data.lotNumber}`
          .replace(/\s\s+/g, ' ')
          .trim(),
      houseName: data.buildingName,
      [stateName || 'state']: data.state,
      [countryName || 'country']: data.country || COUNTRY_CODE,
      [postCodeName || 'postCode']: data.postcode,
      [postalType || 'postalType']: data.postalType,
      [postalNumber || 'postalNumber']: data.postalNumber,
    }
  }

  selectHandler = ({ name, value: address }): void => {
    const { fullAddress, ...rest } = address
    const {
      auto: { selectChangeHandler },
    } = this.props
    const data = this.mapAddressFields({ ...rest })
    selectChangeHandler({ value: fullAddress, name, data })
  }

  updateAddresses = (inputValue: string, name: string) => {
    const {
      actions: { getAddresses },
    } = this.props
    getAddresses(inputValue, name)
  }

  inputHandler = (inputValue: string) => {
    const { name } = this.props
    this.updateAddresses(inputValue, name)
  }

  addressSelectFocus = (isFocused: Boolean) => {
    this.setState({ selectAddress: isFocused })
  }

  render() {
    const {
      addressLookup,
      name,
      auto: {
        label: addressLabel,
        placeholder,
        value,
        toggleLabel,
        addressError,
        addressErrorMessage,
        addressName,
        toggleLabelRight = false,
      },
      manual: {
        streetPlaceholder,
        streetValue,
        streetError,
        streetRequiredErrorMessage,
        streetName,
        houseNoPlaceholder,
        houseNoValue,
        postOfficeNoValue,
        houseNoMin,
        houseNoError,
        houseNoRequiredErrorMessage,
        houseNoName,
        postOfficeNoPlaceholder,
        localityPlaceholder,
        localityLabelValue,
        localityError,
        localityRequiredErrorMessage,
        localityName,
        statePlaceholder,
        stateValue,
        stateError,
        stateRequiredErrorMessage,
        stateName,
        countryPlaceholder,
        countryValue,
        countryError,
        countryRequiredErrorMessage,
        countryName,
        postCodePlaceholder,
        postCodeValue,
        postCodeError,
        postCodeMin,
        postCodeRequiredErrorMessage,
        postCodeName,
        manualToggleLabel,
        inputEntryHandler,
        postOfficeNoName,
        postOfficeNoError,
        postOfficeNoMin,
        postOfficeNoRequiredErrorMessage,
        showPOBoxField,
      },
    } = this.props
    const { countryOptions, selectAddress } = this.state
    // state dropdown handler
    // to set value of state dropdown as string rather object so
    // that it behaves same for state input field and dropdown
    const stateDropdownHander = ({ name: dropdownStateName, value: dropDownStateValue }) => {
      inputEntryHandler({ name: dropdownStateName, value: getValue(dropDownStateValue) })
    }

    // country dropdown handler
    const countryDropdownHandler = ({ name: dropdownCountryName, value: dropDownCountryValue }) => {
      inputEntryHandler({ name: dropdownCountryName, value: dropDownCountryValue })
      // Setting initial state value to empty when australia is selected
      const {
        manual: { countryValue: prevCountryValue },
      } = this.props
      if (
        getValue(dropDownCountryValue) !== getValue(prevCountryValue) &&
        getValue(dropDownCountryValue) === COUNTRY_CODE
      ) {
        inputEntryHandler({ name: stateName || 'state', value: '' })
      }
    }

    if (!addressLookup[name]) return null
    const { addresses, isLoading, isManual } = addressLookup[name]
    return (
      <div {...getClassnameProp(this.props)}>
        <Label variant="input" htmlFor={addressName}>
          {addressLabel}
        </Label>
        <Wrapper isManual={!isManual}>
          <AddressSelect
            isAsync
            isSearchable
            isLoading={isLoading}
            name={addressName || 'address'}
            id={addressName || 'address'}
            toggleLabel={toggleLabel}
            placeholder={placeholder}
            changeHandler={this.selectHandler}
            handleInputChange={this.inputHandler}
            onFocus={() => this.addressSelectFocus(true)}
            onBlur={() => this.addressSelectFocus(false)}
            options={addresses ? makeAddressOptions(addresses) : []}
            value={selectAddress ? null : value}
            error={addressError}
            customComponents={{
              MenuList: toggleLabel && MenuList(toggleLabel, this.toggle),
              NoOptionsMessage: () => null,
              LoadingMessage: () => null,
              DropdownIndicator: () => null,
            }}
            caption={addressError && addressErrorMessage}
            captionTabIndex={1}
          />
          {toggleLabel && !toggleLabelRight && (
            <AddressToggle>
              <Toggle
                onClick={() => {
                  this.toggle(true)
                }}
              >
                {toggleLabel}
              </Toggle>
            </AddressToggle>
          )}
          {toggleLabel && toggleLabelRight && (
            <AddressToggleRight>
              <Toggle
                onClick={() => {
                  this.toggle(true)
                }}
              >
                {toggleLabel}
              </Toggle>
            </AddressToggleRight>
          )}
        </Wrapper>
        <Wrapper isManual={isManual}>
          {['postalAddress', 'accountSettingsPostalAddress'].includes(name) || showPOBoxField ? (
            <InputGroup>
              <Input
                htmlFor={idMaker(houseNoName || 'houseNo')}
                name={houseNoName || 'houseNo'}
                placeholder={houseNoPlaceholder}
                changeHandler={inputEntryHandler}
                value={houseNoValue}
                error={houseNoError}
                min={houseNoMin || 0}
                caption={houseNoError && houseNoRequiredErrorMessage}
              />
              <Input
                htmlFor={idMaker(postOfficeNoName || 'postalAddressPONo')}
                name={postOfficeNoName || 'postalAddressPONo'}
                placeholder={postOfficeNoPlaceholder}
                changeHandler={inputEntryHandler}
                value={postOfficeNoValue}
                error={postOfficeNoError}
                min={postOfficeNoMin || 0}
                caption={postOfficeNoError && postOfficeNoRequiredErrorMessage}
              />
            </InputGroup>
          ) : (
            <Input
              htmlFor={idMaker(houseNoName || 'houseNo')}
              name={houseNoName || 'houseNo'}
              placeholder={houseNoPlaceholder}
              changeHandler={inputEntryHandler}
              value={houseNoValue}
              error={houseNoError}
              min={houseNoMin || 0}
              caption={houseNoError && houseNoRequiredErrorMessage}
            />
          )}
          <Input
            htmlFor={idMaker(streetName || 'street')}
            name={streetName || 'street'}
            placeholder={streetPlaceholder}
            changeHandler={inputEntryHandler}
            value={streetValue}
            error={streetError}
            caption={streetError && streetRequiredErrorMessage}
          />
          <Input
            htmlFor={idMaker(localityName || 'locality')}
            name={localityName || 'locality'}
            placeholder={localityPlaceholder}
            changeHandler={inputEntryHandler}
            value={localityLabelValue}
            error={localityError}
            caption={localityError && localityRequiredErrorMessage}
          />

          {getValue(countryValue) === COUNTRY_CODE ? (
            <Select
              placeholder={statePlaceholder}
              value={stateValue}
              name={stateName || 'state'}
              id={stateName || 'state'}
              error={stateError}
              caption={stateError && stateRequiredErrorMessage}
              changeHandler={stateDropdownHander}
              options={STATES}
            />
          ) : (
            <Input
              htmlFor={idMaker(stateName || 'state')}
              name={stateName || 'state'}
              placeholder={statePlaceholder}
              changeHandler={inputEntryHandler}
              value={stateValue}
              error={stateError}
              caption={stateError && stateRequiredErrorMessage}
            />
          )}

          <Select
            placeholder={countryPlaceholder}
            value={countryValue}
            name={countryName || 'country'}
            id={countryName || 'country'}
            changeHandler={countryDropdownHandler}
            options={countryOptions}
            error={countryError}
            caption={countryError && countryRequiredErrorMessage}
            captionTabIndex={1}
          />

          <InputThirds
            htmlFor={idMaker(postCodeName || 'postCode')}
            name={postCodeName || 'postCode'}
            placeholder={postCodePlaceholder}
            changeHandler={inputEntryHandler}
            value={postCodeValue}
            error={postCodeError}
            caption={postCodeError && postCodeRequiredErrorMessage}
            min={postCodeMin}
          />
          {manualToggleLabel && (
            <AddressToggle>
              <Toggle
                onClick={() => {
                  this.toggle(false)
                }}
              >
                {manualToggleLabel}
              </Toggle>
            </AddressToggle>
          )}
        </Wrapper>
      </div>
    )
  }
}

export const mapStateToProps = ({ addressLookup, masterList }) => ({
  addressLookup,
  masterData: masterList.data,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

AddressLookup.defaultProps = {
  isManual: false,
  toggleHandler: () => {},
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressLookup)
