import get from 'lodash/get'
import {
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_MEMBER_COMPANY,
  POLICY_MEMBER_INDIVIDUAL,
  PREFERRED_YES,
  COUNTRY_CODE,
} from '../../../../../constants/policies'
import { DEFAULT_PHONE_CODE } from '../../../../../constants/contactDetails'
import {
  getFullAddressWithState,
  getEmail,
  getPreferredAddress,
  getPhoneNumber,
  getPhoneNumberCode,
} from '../../../../../utils/contactUtils'
import { getDropdownValue } from '../../../../../utils/commonUtils'

export const initForms = props => {
  const {
    createQuote,
    sidebar: { panelProps: sidebarPanelProps },
    abnLookup,
    countriesPhoneCode,
  } = props

  if (!sidebarPanelProps)
    return {
      directors: [],
      individuals: [],
      companyName: abnLookup.companyName,
      companyEmail: '',
      companyAddress: '',
      companyHouseNo: '',
      companyStreet: '',
      companyLocality: '',
      companyState: '',
      companyCountry: COUNTRY_CODE,
      companyPostCode: '',
      trustee: '',
      contactNumber: '',
      contactNumberCode: getDropdownValue(DEFAULT_PHONE_CODE, countriesPhoneCode),
    }
  const { policyInstanceNo } = sidebarPanelProps

  const { quotes, activeIndex } = createQuote
  const activeQuote = quotes[activeIndex]
  const policy = activeQuote.policyStructure.find(p => p.policyInstanceNo === policyInstanceNo)
  const relationship = policy.relationships.find(rel =>
    rel.role.includes(POLICY_RELATIONSHIPS_SMSF)
  )
  const { relatedParty: { identifiers: lifeInsuredIdentifier = {} } = {} } =
    policy.relationships.find(rel => rel.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)) || {}

  const isCompany = !!get(relationship, 'companies[0].directors[0]')
  const residentialAddressIndex =
    isCompany && relationship.relatedParty.contactMethods.addresses
      ? relationship.relatedParty.contactMethods.addresses.findIndex(
          address => address.preferred === PREFERRED_YES
        )
      : -1
  const address =
    residentialAddressIndex !== -1
      ? relationship.relatedParty.contactMethods.addresses[residentialAddressIndex]
      : {}
  const companyPreferredAddress = getPreferredAddress(
    get(relationship, 'companies[0].relatedParty.contactMethods.addresses', [])
  )
  const data = {
    trustee: isCompany ? POLICY_MEMBER_COMPANY : POLICY_MEMBER_INDIVIDUAL,
    companyName: get(relationship, 'companies[0].relatedParty.businessName', ''),
    companyEmail: getEmail(
      get(relationship, 'companies[0].relatedParty.contactMethods.emails', [])
    ),
    companyAddress: getFullAddressWithState(
      get(relationship, 'companies[0].relatedParty.contactMethods.addresses', [])
    ),
    companyHouseNo: get(companyPreferredAddress, 'houseNo', ''),
    companyStreet: get(companyPreferredAddress, 'street', ''),
    companyLocality: get(companyPreferredAddress, 'locality', ''),
    companyState: get(companyPreferredAddress, 'state', ''),
    companyCountry: get(companyPreferredAddress, 'country', COUNTRY_CODE),
    companyPostCode: get(companyPreferredAddress, 'postCode', ''),
    isSoleDirector: isCompany
      ? get(relationship, 'companies[0].directors', []).length === 1
      : false,
    directors: isCompany ? get(relationship, 'companies[0].directors', []) : [],
    individuals: !isCompany
      ? (relationship &&
          relationship.companies.map(company => ({
            ...company,
            ...(company.relatedParty.identifiers[0].value === lifeInsuredIdentifier[0].value
              ? { isLifeAssured: true }
              : {}),
          }))) ||
        []
      : [],
    residentialAddress: getFullAddressWithState([address]),
    residentialAddressHouseNo: address.houseNo,
    residentialAddressStreet: address.street,
    residentialAddressLocality: address.locality,
    residentialAddressState: address.state,
    residentialAddressCountry: address.country,
    residentialAddressPostCode: address.postCode,
    contactNumber: getPhoneNumber(
      get(relationship, 'companies[0].relatedParty.contactMethods.phones', [])
    ),
    contactNumberCode: getDropdownValue(
      getPhoneNumberCode(
        get(relationship, 'companies[0].relatedParty.contactMethods.phones', [])
      ) || DEFAULT_PHONE_CODE,
      countriesPhoneCode
    ),
  }

  return data
}
