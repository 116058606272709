/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLocation32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.814 4.814C13.008 3.62 14.66 3 16.5 3c1.84 0 3.491.62 4.686 1.814C22.38 6.01 23 7.66 23 9.5c0 1.84-.62 3.492-1.814 4.686-.86.858-1.954 1.42-3.186 1.668V22.5h-3v-6.646c-1.232-.248-2.327-.81-3.186-1.668C10.62 12.992 10 11.34 10 9.5c0-1.84.62-3.491 1.814-4.686zm2.122 2.122C13.38 7.49 13 8.34 13 9.5c0 1.16.38 2.008.936 2.564S15.34 13 16.5 13c1.16 0 2.009-.38 2.564-.936.556-.556.936-1.405.936-2.564 0-1.16-.38-2.009-.936-2.564C18.51 6.38 17.66 6 16.5 6c-1.16 0-2.008.38-2.564.936zm-6.14 13.146C9.205 19.414 11.052 19 13 19v3c-1.553 0-2.956.336-3.92.793-.464.22-.772.442-.949.618.288.212.8.473 1.578.723 1.563.503 3.9.866 6.791.866 2.891 0 5.228-.363 6.791-.866.778-.25 1.29-.51 1.578-.723-.177-.177-.485-.398-.949-.618C22.955 22.336 21.553 22 20 22v-3c1.947 0 3.794.414 5.205 1.082.702.333 1.356.757 1.856 1.28.494.517.939 1.244.939 2.138 0 1.078-.706 1.842-1.319 2.302-.652.489-1.512.88-2.472 1.189-1.937.622-4.6 1.009-7.709 1.009s-5.772-.387-7.709-1.01c-.96-.308-1.82-.7-2.472-1.188C5.706 25.342 5 24.578 5 23.5c0-.894.445-1.62.939-2.138.5-.523 1.154-.947 1.856-1.28zm17.218 3.511l-.002-.004a.026.026 0 0 1 .002.004zm.041-.346a.054.054 0 0 1 .01-.012l-.01.012zm-17.12-.012c.001 0 .005.004.01.012a.048.048 0 0 1-.01-.012zm.053.354a.028.028 0 0 1-.002.004l.002-.004z"
      fill="#000"
    />
  </Icon>
)

export default IconLocation32
