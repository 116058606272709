// @flow
import moment from 'moment'
import { toast } from 'react-toastify'
import get from 'lodash/get'

// eslint-disable-next-line no-unused-vars
import { flatMap } from 'core-js/fn/array/flat-map'
import history from '../../utils/browserHistory'
import {
  CREATE_QUOTE_UPDATE_BENEFICIARIES,
  CREATE_QUOTE_ADD_BENEFIT_TO_POLICY,
  CREATE_QUOTE_ADD_POLICY,
  CREATE_QUOTE_ADD_POLICY_OWNER,
  CREATE_QUOTE_UPDATE_POLICY_OWNER,
  CREATE_QUOTE_CALCULATE,
  CREATE_QUOTE_CALCULATION_IN_PROGRESS,
  CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_TRACK,
  CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_POLICY_FEE,
  CREATE_QUOTE_SET_FEATURES,
  CREATE_QUOTE_SET_FIXED_PAYMENT_FREQUENCY,
  CREATE_QUOTE_SET_QUOTE_NAME,
  RESET_TOTAL_PREMIUM_AMOUNT,
  CREATE_QUOTE_UPDATE_BENEFIT_LINK,
  CREATE_QUOTE_UPDATE_CHILD_DATA,
  CREATE_QUOTE_UPDATE_COVER,
  CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES,
  CREATE_QUOTE_UPDATE_SECONDARY_MAIL,
  CREATE_QUOTE_UPDATE_PAYMENT_DETAILS,
  CREATE_QUOTE_UPDATE_PAYMENT_FREQUENCY,
  CREATE_QUOTE_UPDATE_LEGACY_CODE,
  CREATE_QUOTE_UPDATE_SUM_INSURED,
  CREATE_QUOTE_UPDATE_MEMBER_DETAILS,
  CREATE_QUOTE_UPDATE_COVERS,
  CREATE_QUOTE_GET_CALCULATION_IN_PROGRESS,
  CREATE_QUOTE_GET_CALCULATE,
  CREATE_QUOTE_REMOVE_BENEFIT,
  CREATE_QUOTE_DUPLICATE_TEMPLATE,
  CREATE_QUOTE_DELETE_FROM_COLLECTION,
  DELETE_QUOTE_COLLECTION,
  UPDATE_QUOTE_ACTIVE_INDEX,
  IS_UPDATED_QUOTE,
  CREATE_QUOTE_SAVE,
  UPDATE_BANCS_ADVISER_CUSTOMER_NO,
  CREATE_QUOTE_SHOW_SAVE_MODAL,
  CREATE_QUOTE_UPDATE_IS_SAVE,
  REMOVE_ACTION_AGREEMENT_CONSENTS,
  UPDATE_QUOTE_ENTITY_MY_LINK_DETAILS,
  RESET_UPDATE_QUOTE_COLLECTION_SUCCESS_FLAG,
  UPDATE_ADVISER_VERIFICATION_CONSENTS,
  CREATE_QUOTE_CALCULATE_QUOTE_ERROR_MODAL,
  CREATE_QUOTE_SAVE_QUOTE_ERROR_MODAL,
  CREATE_QUOTE_SAVE_AND_EXIT_MODAL,
  CREATE_QUOTE_CONVERT_COVERS_FEATURES,
  CREATE_QUOTE_UPDATE_DUTY_OF_DISCLOSURE_CONSENTS,
  CREATE_QUOTE_UPDATE_UNDERWRITING_OPTIONS_CONSENTS,
  CREATE_QUOTE_UPDATE_DECLARATION_AUTHORIZATION_CONSENTS,
  RESET_QUOTE_DECLARATION_CONSENTS,
  CREATE_QUOTE_UPDATE_DECLARATION_PO_CONSENTS,
  CREATE_QUOTE_UPDATE_DECLARATION_FOR_ADVISOR_CONSENTS,
  CREATE_QUOTE_UPDATE_MARKETING_CONSENTS,
  CREATE_QUOTE_UPDATE_UNDERWRITING_FOLLOW_UP_TIMING,
  CREATE_QUOTE_DELETE_POLICY,
  FETCH_QUOTE_COLLECTION,
  UPDATE_IS_TERM_ACCEPTED_FIELD,
  CREATE_QUOTE_DELETE_MEMBER_DETAILS,
  CREATE_QUOTE_ADD_PAYMENT_DETAILS,
  CREATE_QUOTE_UPDATE_ROLE_OF_MEMBER,
  CREATE_QUOTE_UPDATE_PRINTABLE_ADVISER_DETAILS,
  CREATE_QUOTE_UPDATE_EXISTING_COVER,
  CREATE_QUOTE_RESET_QUOTE_ID,
  CREATE_QUOTE_UPDATE_APPLICATION_TYPE,
  CREATE_QUOTE_UPDATE_QUOTE_DATA,
  CREATE_ENQUIRY_ID,
  CREATE_CHILD_ENQUIRY_ID,
  UPDATE_APPLICATION_STATUS,
  UPDATE_QUOTE_STATUS,
  UPDATE_ENQUIRY_ID_URE,
  REMOVE_ENQUIRY_ID_URE,
  SET_SAVE_QUOTE_INITIATOR,
  CREATE_QUOTE_UPDATE_POLICY_STRUCTURE,
  CREATE_QUOTE_UPDATE_FEATURE_STATUS,
  RESET_CREATE_QUOTE,
  CREATE_QUOTE_APPLY_LOADINGS,
  CREATE_QUOTE_REMOVE_APPLIED_LOADINGS,
  CREATE_QUOTE_UPDATE_MLC_ON_TRACK_CONSENTS,
  CREATE_QUOTE_UPDATE_TMD_CONSENTS,
  CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY,
  CREATE_QUOTE_APPLY_EXCLUSIONS,
  CREATE_QUOTE_REMOVE_APPLIED_EXCLUSION,
  CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS,
  CREATE_QUOTE_APPLY_GENETIC_TEST_RESULT,
  ADD_BUSINESS_AS_PAYER,
  UPDATE_QUOTE_ID_TO_ACTION,
  CREATE_QUOTE_UPDATE_QUOTE_COLLECTION,
  UPDATE_POLICY_COMMENCEMENT_DATE,
  CREATE_QUOTE_CHANGE_SMOKER_STATUS,
  UPDATE_URE_DETAIL_TO_UNDERWRITING_ENTITY,
  ADD_NULIS,
  ADD_OWNER_FOR_NULIS_WRAPPER,
  ADD_TFN,
  UPDATE_UNDER_WRITING_DETAILS,
  UPDATE_WORKTYPE_HISTORY,
  DELETE_WORKTYPE_HISTORY,
  UPDATE_IS_TELE_FROM_ADVISER,
  REMOVE_PARENT_BENEFIT_REFERENCE,
  CREATE_QUOTE_CALCULATE_ERROR,
  CREATE_QUOTE_UPDATE_POLICY_NAME,
  QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL,
  CREATE_QUOTE_UPDATE_SMSF_DETAILS,
  CREATE_QUOTE_UPDATE_CALCULATED_URE_STATUS,
  RESET_CREATE_AND_SAVE_QUOTE_ERROR_MODAL,
  CREATE_QUOTE_UPDATE_TELE_CUSTOMER_TERM_CONSENTS,
  CREATE_QUOTE_REDIRECT,
  CREATE_QUOTE_UPDATE_TPD_DEFINATION,
  CREATE_QUOTE_UPDATE_ADVISER_AUTHORITY_CONSENTS,
  UPDATE_POLICY_REPLACE_STATUS,
  CREATE_QUOTE_PUSH_EXISTING_COVER,
  CREATE_QUOTE_UPDATE_IS_NON_STANDARD,
  CREATE_QUOTE_UPDATE_BMI,
  CREATE_QUOTE_SET_IS_SATISFIED,
  UPDATE_IS_TERMS_ACCEPTED_ON_POLICY_COVER,
  CREATE_QUOTE_UPDATE_LA_CONTACT_DETAILS,
  CREATE_QUOTE_ADD_OPTIMISER_REFERENCE,
  CREATE_QUOTE_REMOVE_OPTIMISER_REFERENCE,
  CREATE_QUOTE_REMOVE_EMPTY_POLICY,
  CREATE_QUOTE_UPDATE_LINKED_OPTIMISER,
  CREATE_QUOTE_UPDATE_LINKED_BENEFITS,
  CHECK_QUOTE_ACTION_STATUS,
  CREATE_QUOTE_CLEAR_POLICY_SETUP_DATA,
  PRE_POPULATE_MANDATORIES,
  CLEAR_PRE_EXISTING_MANDATORIES,
  UPDATE_SUBMITTED_DATE,
  UPDATE_MEMBER_MANDATORIES,
  CREATE_QUOTE_SET_POLICY_STRUCTURE,
  CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME,
  CREATE_QUOTE_SET_REQUESTED_BY,
  CREATE_QUOTE_SET_ALTERATION_TYPE,
  CREATE_QUOTE_SET_QUOTE_COLLECTION_ID,
  CREATE_QUOTE_SET_ADVISER_NUMBER,
  CREATE_QUOTE_SET_APPLICATION_DETAILS,
  CREATE_QUOTE_SET_QUOTE_CUSTOMER_NO,
  CREATE_QUOTE_ADD_FEATURE_AS_SUB_BENEFIT,
  CREATE_QUOTE_DELETE_FEATURE_AS_SUB_BENEFIT,
  ALTER_SUM_INSURED,
  ALTS_CALCULATE_QUOTE,
  RESET_SUM_INSURED,
  DELETE_ALTERATIONS_IN_QUOTE,
  REMOVE_BENEFIT_OPTION,
  CANCEL_BENEFIT_OPTION,
  CHANGE_WAITING_BENEFIT_PERIOD,
  RESET_WAITING_BENEFIT_PERIOD,
  ALTS_REMOVE_BENEFIT,
  ALTER_PAYMENT_FREQUENCY,
  RESET_PAYMENT_FREQUENCY,
  CREATE_QUOTE_SET_ALTERATION_EFFECTIVE_DATE,
  CREATE_QUOTE_UPDATE_OPTION,
  CREATE_QUOTE_UPDATE_HL_CAMPAIGN,
  CREATE_QOUTE_SET_HEALTHY_LIVING_DISCOUNT_CONSENT,
  CREATE_QUOTE_ADD_HEALTHY_LIVING_DISCOUNT_IN_MISSING_REQUIREMENTS,
  UPDATE_ALTS_DOWNLOAD_QUOTE_PROGRESS,
  RESET_STATE_FOR_NEW_QUOTE,
  CREATE_QOUTE_SET_ADVISER_PRESENTED_LICOP_FLYER_CONSENT,
  UPDATE_ALTS_EFFECTIVE_DATES,
  UPDATE_MEDICAL_AUTHORITY_CONSENTS,
  UPDATE_DETAILS_FOR_CONTACT,
  CREATE_QUOTE_QUICK_QUOTE,
} from '../types/createQuote'
import { SET_IP_SUM_INSURED } from '../types/calculateSaveQuote'
import { EXPERIENCE_API } from '../types/experienceApi'

// constants.
import {
  EXPERIENCE_API_BASE,
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_URE_BASE,
  SC_BRANCH_TAG,
  URE_VERSION_OCCUPATIONS_RATING,
} from '../../constants/apis'
import {
  POLICY_NON_SUPER_BENEFIT_PREFIX,
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
  COUNTRY_CODE,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
  PREFERRED_YES,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_MEMBER_DIRECTOR,
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_RELATIONSHIPS_EMPLOYER,
  ADDRESS_TYPE_WORK,
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_TISO,
  POLICY_BENEFIT_PLAT_SGBO,
  POLICY_BENEFIT_STD_SGBO,
  POLICY_FEATURE_SGBO,
  POLICY_BENEFIT_PTD,
  POLICY_BENEFIT_CI_PLUS,
  COMPANY_IDENTIFIER_NGRP,
  SMSF_ORGANIZATION_TYPE,
  SMSF_BUSINESS_ORGANIZATION_TYPE,
  CONTACT_TYPES_TEL,
  CONTACT_SUB_TYPES,
  UPDATE_FROM_LIFE_INSURED,
  INCOME_PROTECTION_PLATINUM_ID,
  INCOME_PROTECTION_STANDARD_ID,
  QUOTE_STATUS_APPLICATION_STAGE,
  FUND_PAYMENT_METHODS_IOOF,
} from '../../constants/policies'
import {
  APPLICATION_STAGE_STEP,
  TELE,
  APPLICATION_TYPES,
  ACTION_TYPES,
  ACCEPTED,
} from '../../constants/application'
import { ALTERATION_TYPES, REQUESTED_BY } from '../../constants/alterations'
import {
  TOAST_ID_CALCULATE_QUOTE,
  TOAST_ID_SAVE_QUOTE,
  TOAST_TITLE_SAVE_QUOTE_ERROR,
  TOAST_TITLE_SAVE_QUOTE_CAN_NOT_PROCESS,
  TOAST_TITLE_CALCULATE_QUOTE_ERROR,
  TOAST_TITLE_QUICK_QUOTE_ERROR,
  TOAST_ID_QUICK_QUOTE,
  TOAST_TITLE_QUICK_QUOTE_SUCCESS,
  TOAST_TITLE_CALCULATE_QUOTE_SUCCESS,
  TOAST_SAVE_QUOTE_EMPTY_POLICY_TITLE,
} from '../../constants/toast'
import { DEFAULT_QUOTE_COLLECTION_NAME } from '../../constants/forms'
import { DISCOUNT_COMMISSION_DIAL_DOWN } from '../../constants/discounts'
import { EMPLOYER_SG, AWARD, SALARY_SACRIFICE, EMPLOYER_VOLUNTARY } from '../../constants/nulis'
import {
  WRAP,
  MASTER_KEY_ACCOUNT,
  ROLLOVER_FROM_EXTERNAL_FUND,
  ELIGIBLE_MLC_ACCOUNT,
  CC,
  DD,
} from '../../constants/customerPaymentDetails'
import { THIRD_PARTY_QUOTE_COOKIE_NAME, THIRD_PARTY_SOURCE } from '../../constants/bancs'
import { ADVISOR_PORTAL, CUSTOMER_PORTAL } from '../../constants/site'
import { COVERS_NOT_FOR_UI } from '../../constants/benefit'
// action
import { updateApplicationStage } from './application'
import { setLifeAssuredName } from './lifeAssured'
// helpers.
// these are here for now until the create quote flows are resolved and aren't pushing random data.
import {
  createPolicy,
  postProcessInitialPolicyStructure,
  sortProducts,
  isFixedPaymentFrequency,
  applyGroupConnection,
  getLegacyProductCode,
  updateFilteredBenefitsData,
  validateQuote,
  policiesWillUpdate,
  getActivePolicy,
  updateQuoteBasedOnDependentOn,
  filterQuoteEntityToSave,
  isValidBenefit,
  isOwnTPDDisabled,
  removeAllOptimizedCovers,
  isIPCover,
  getIdentifier,
  CLIENT_SIDE_DATE_FORMAT,
  constructPolicyStructure,
  getProduct,
  convertCoverToEntityStructure,
} from '../../utils/quoteUtils'
import { mergeAltsInPolicyStructure } from '../../utils/altsQuoteUtils'
import {
  getLifeInsuredMemberEntity,
  updateQuoteForTISO,
  updateQuoteForSGBO,
  removeFeatureInQuote,
  makeFeatures,
  isLoadinginQuote,
  updateLegacyProductCodeToExistingQuote,
  isBECover,
  totalIPSumInsured,
  totalBoosterOptionSumInsured,
  totalSGBOptionSumInsured,
  isSGBOBenefit,
  updateCommissionProfile,
} from '../../utils/extendedQuoteUtils'

import { isEnquiyIdExpired } from '../../utils/ureUtils'

import { buildQuickQuoteSumInsuredBody } from '../../utils/formUtils'

import { getCreditType } from '../../utils/creditTypeUtils'

import { showEnquiryIdExpiredModal } from './enquiryIdExpiredModal'
import { saveUrlForCallBack, ureInitalData } from './ure'
import { setPanelProps, setIsNextBtnLoader } from './sidebar'
import { formUpdateField, formInit } from './forms'
import { updateLoadingsFromQuote } from './preAssessment'
// eslint-disable-next-line max-len
import CAPTURE_CLIENT_SCHEMA, {
  FORM_ID as CAPTURE_CLIENT_FORM_ID,
} from '../../components/organisms/CreateQuote/components/CaptureClient/captureClient.schema'
import { REF_NO } from '../../constants/adviser'
import { universalCookie, getBancsCustomerNumber } from '../../utils/cookieUtils'
import { getPreferredAddress } from '../../utils/contactUtils'
import { getAgentFromPolicies } from '../../utils/policyUtils'
import { toggleIPCoverSumInsuredErrorModal } from './calculateSaveQuote'

// selectors
import {
  getEligiblePolicies,
  getBancsCustomerNoOfLAFromAlterations,
  getUpdatePrintableAdviserDetailsPayload,
} from '../../selectors/alterations'
import { makeAltsLifeInsuredNameAndPartyNo } from '../../selectors/common.selectors'
import {
  getRulesForAlterationPolicies,
  makeMemberMandatoriesFromAlterations,
  makePolicyAssessment,
  makeAdviserNo,
} from '../../selectors/altsDeclaration.selectors'

// actions
import { resetAllSavedState } from './modal'

export const isUpdatedQuote = () => ({
  type: IS_UPDATED_QUOTE,
})

export const redirectToQuoteTool = queuedDispatch => (dispatch, getState) => {
  const {
    createQuote: { quotes },
    ure: { isUreTemplateFetching },
  } = getState()
  universalCookie.remove(THIRD_PARTY_QUOTE_COOKIE_NAME)
  universalCookie.remove(THIRD_PARTY_SOURCE)
  history.push('/quote-tool')

  dispatch({
    type: CREATE_QUOTE_REDIRECT,
  })
  // 1. ure template api call for mapping, used in step 2
  // 2. update loadings in preassessment state
  // 3. calculate quote api call (queuedDispatch)
  // calculate quote action first removes loadings and then apply loadings as
  // per loadings in preassessment state
  if (isLoadinginQuote(quotes[0])) {
    dispatch(
      ureInitalData({
        queuedAction: prevAction => {
          if (!isUreTemplateFetching) {
            dispatch(updateLoadingsFromQuote(quotes[0])(prevAction))
          }
          return queuedDispatch
        },
      })
    )
  } else if (queuedDispatch) {
    dispatch(queuedDispatch)
  }
}

export const setQuoteCollectionName = quoteCollectionName => ({
  type: CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME,
  payload: {
    quoteCollectionName,
  },
})

export const updateCalculateQuoteError =
  (showToastNotification: boolean = true) =>
  (dispatch): Object => {
    dispatch({
      type: CREATE_QUOTE_CALCULATE_ERROR,
    })
    if (showToastNotification) {
      toast(TOAST_TITLE_CALCULATE_QUOTE_ERROR, {
        autoClose: 3000,
        toastId: TOAST_ID_CALCULATE_QUOTE,
        type: toast.TYPE.ERROR,
      })
    }
  }

export const deletePolicy = (policy: Object) => (dispatch: Function, getState: Function) => {
  const {
    productRules: { data: productRulesData },
    masterList: { data },
    calculateSaveQuote: { isIPCalculated },
    createQuote: { activeIndex, quotes },
  } = getState()
  const { memberMandatories } = quotes[activeIndex]
  dispatch({
    type: CREATE_QUOTE_DELETE_POLICY,
    payload: {
      policy,
      productRulesData,
      memberMandatories,
      data,
      isIPCalculated,
    },
  })
}

export const convertCoversToFeatures = () => (dispatch: Function, getState: Function) => {
  const {
    productRules: { data: productData },
    createQuote: { quotes },
  } = getState()
  dispatch({
    type: CREATE_QUOTE_CONVERT_COVERS_FEATURES,
    payload: {
      productData,
      quotes,
    },
  })
}

/**
 * Action creator for saving quote, makes api call
 * @return {function} - action creator
 */
export const saveQuote =
  (
    callback,
    identifier,
    quoteCollectionName,
    lifeInsuredFirstName,
    lifeInsuredLastName,
    productRulesData,
    createQuoteData
  ) =>
  (dispatch, getState) => {
    const { createQuote: createQuoteState, productRules, sidebar } = getState()
    const createQuote = createQuoteData || createQuoteState
    const {
      quoteCollectionId,
      hasCalculationError,
      isRecalculating,
      quotes,
      activeIndex,
      quoteIDToAction,
    } = createQuote

    const noOfQuotes = quotes.length
    const productData = productRulesData || productRules.data
    const { policyStructure } = quotes[activeIndex]
    const noCover =
      policyStructure.length && policyStructure.some(policy => policy.covers.length === 0)
    let quotePayload = createQuote
    if (!noCover && !hasCalculationError && !isRecalculating) {
      if (quoteCollectionId) {
        dispatch(isUpdatedQuote())
        // @FIXME: this is needed since previously we were using getState()
        // side effects that were removed. Ideally we shouldn't be using getState() like we are.
        quotePayload = {
          ...quotePayload,
          quotes: quotePayload.quotes.map((quote, index) => ({
            ...quote,
            isUpdated: index === activeIndex,
          })),
        }
      }

      const data = filterQuoteEntityToSave(quotePayload, sidebar)
      const coverData = {
        ...data,
        quoteCollectionName: quoteCollectionName || createQuote.quoteCollectionName,
        lifeInsuredFirstName: lifeInsuredFirstName || createQuote.lifeInsuredFirstName,
        lifeInsuredLastName: lifeInsuredLastName || createQuote.lifeInsuredLastName,
      }
      const activeQuote = createQuote.quotes.find(quote => quote.quoteId === quoteIDToAction)
      if (
        coverData.workTypeHistory &&
        activeQuote &&
        activeQuote.underwritingDetails &&
        activeQuote.underwritingDetails.underwritingMethod !== TELE
      ) {
        delete coverData.workTypeHistory
        coverData.isTeleFromAdviser = false
      }
      dispatch({
        type: EXPERIENCE_API,
        name: CREATE_QUOTE_SAVE,
        verb: 'POST',
        route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes`,
        data: {
          ...coverData,
          noOfQuotes,
        },
        callback: (err, dataReceived) => {
          toast.dismiss(TOAST_ID_SAVE_QUOTE)
          if (err) {
            toast(TOAST_TITLE_SAVE_QUOTE_ERROR, {
              toastId: TOAST_ID_SAVE_QUOTE,
              type: toast.TYPE.ERROR,
            })
          } else {
            dispatch(setQuoteCollectionName(quoteCollectionName || createQuote.quoteCollectionName))
          }
          if (typeof callback === 'function') {
            callback(err, dataReceived)
          }
          return {
            dataReceived,
            identifier,
            productData,
          }
        },
      })
    } else if (noCover) {
      toast(TOAST_SAVE_QUOTE_EMPTY_POLICY_TITLE, {
        autoClose: 3000,
        toastId: TOAST_ID_CALCULATE_QUOTE,
        type: toast.TYPE.ERROR,
      })

      if (callback) {
        callback({
          status: {
            type: 'error',
            description: TOAST_SAVE_QUOTE_EMPTY_POLICY_TITLE,
          },
        })
      } else if (noCover) {
        toast(TOAST_SAVE_QUOTE_EMPTY_POLICY_TITLE, {
          autoClose: 3000,
          toastId: TOAST_ID_CALCULATE_QUOTE,
          type: toast.TYPE.ERROR,
        })

        if (callback) {
          callback({
            status: {
              type: 'error',
              description: TOAST_SAVE_QUOTE_EMPTY_POLICY_TITLE,
            },
          })
        }
      } else {
        toast(TOAST_TITLE_SAVE_QUOTE_CAN_NOT_PROCESS, {
          autoClose: 3000,
          toastId: TOAST_ID_CALCULATE_QUOTE,
          type: toast.TYPE.ERROR,
        })

        if (callback) {
          callback({
            status: {
              type: 'error',
              description: TOAST_TITLE_SAVE_QUOTE_CAN_NOT_PROCESS,
            },
          })
        }
      }
    }
  }

export const saveSelectedPoliciesQuote =
  (
    callback?: Function,
    identifier?: string,
    quoteCollectionName?: string,
    lifeInsuredFirstName?: string,
    lifeInsuredLastName?: string,
    productRulesData?: Array<Object>
  ) =>
  (dispatch: Function, getState: Function) => {
    const { createQuote, alterations } = getState()
    const { activeIndex } = createQuote
    const { policySelectionMap = {} } = alterations
    // Creating quote entity based on selected policies and
    // using that in save quote
    const createQuoteData = {
      ...createQuote,
      quotes: createQuote.quotes.map((quote, index) => {
        if (index === activeIndex) {
          return {
            ...quote,
            policyStructure: quote.policyStructure.filter(
              policy => policySelectionMap[policy.bancsPolicyNo]
            ),
          }
        }
        return quote
      }),
    }
    dispatch(
      saveQuote(
        callback,
        identifier,
        quoteCollectionName,
        lifeInsuredFirstName,
        lifeInsuredLastName,
        productRulesData,
        createQuoteData
      )
    )
  }

type createQuoteCalculateConfigType = {
  // quote data to be sent as payload, if quote not provided active quote will be used
  quote?: Object,
  // index of quote that is to be used as payload if quote is not present in config,
  // index of quote that is to be updated by response,
  // if not provided activeIndex from quote entity used
  quoteIndex?: number,
  // if save quote needs to be triggered after calculate quote
  initiateSaveQuote?: boolean,
  // if save quote needs to be triggered after calculate quote
  initiateSGBOCalculation?: boolean,
  // if commission profile was updated
  commissionProfile?: string,
  // Needed if updating commission Profile
  policyIndex?: number,
}

export const createQuoteCalculate =
  (config: createQuoteCalculateConfigType = {}, callback): Object =>
  (dispatch, getState): Object => {
    // obtain the current state of the quote that needs to be recalculated.
    const {
      initiateSaveQuote,
      commissionProfile,
      policyIndex,
      quote: configQuote,
      quoteIndex,
      initiateSGBOCalculation,
      notUpdateSeriesCode,
      isFromCustomerPortal,
    } = config
    const {
      createQuote,
      preAssessment: { loadings },
      ure: ureData,
      productRules: { data },
      advisor: { details: advisorDetails },
    } = getState()

    const { quotes, activeIndex } = createQuote
    // Use quote sent in config
    // If quote not present in config, pick quote from quote entity based on quoteIndex
    // If quote and quoteIndex not present in config, pick
    // quote from quote entity based on activeIndex
    const quote = configQuote || quotes[quoteIndex || activeIndex]
    const { policyStructure } = quote
    const benefitMasterData = get(ureData, 'initialData.umeBenefits', [])
    const noCoverOrCoverHaveNoSumInsured =
      policyStructure.length &&
      policyStructure.some(
        policy =>
          policy.covers.length === 0 ||
          policy.covers.some(
            cover => !COVERS_NOT_FOR_UI.includes(cover.type) && cover.coverAmount <= 0
          )
      )
    const ipSumInsured = totalIPSumInsured(policyStructure)
    const boosterOptionSumInsured = totalBoosterOptionSumInsured(quote)
    const sgboSumInsured = totalSGBOptionSumInsured(policyStructure)
    const coversData = policyStructure.flatMap(({ covers = [] }) => covers)
    const isIPCoverAvailable = coversData.some(cover => isIPCover(cover))
    const totalIPSumInsuredValue = ipSumInsured + boosterOptionSumInsured + sgboSumInsured
    if (isIPCoverAvailable && totalIPSumInsuredValue > 30000) {
      dispatch(toggleIPCoverSumInsuredErrorModal(true))
    } else if (!noCoverOrCoverHaveNoSumInsured) {
      dispatch({
        type: CREATE_QUOTE_REMOVE_APPLIED_LOADINGS,
      })
      dispatch({
        type: CREATE_QUOTE_APPLY_LOADINGS,
        payload: {
          loadings,
          benefitMasterData,
        },
      })

      // set the collection `isRecalculating` value to true.
      dispatch({
        type: CREATE_QUOTE_CALCULATION_IN_PROGRESS,
      })

      // TISO check
      let updatedQuote = updateQuoteForTISO(quote)

      // SGBO check
      updatedQuote = updateQuoteForSGBO(updatedQuote, initiateSGBOCalculation, data)

      // Removing LSBO feature as it should be sub-benefit in quote entity not feature
      updatedQuote = removeFeatureInQuote(updatedQuote)

      // Update legacy code
      // within Mylink or Tele Flow when it is Series 5 application then we donot update the code
      if (!(notUpdateSeriesCode && isFromCustomerPortal))
        updatedQuote = updateLegacyProductCodeToExistingQuote(updatedQuote, data)

      // Update Commission Profile
      if (commissionProfile) {
        updatedQuote = updateCommissionProfile(
          updatedQuote,
          commissionProfile,
          advisorDetails,
          policyIndex
        )
      }

      // call the experience api to recalculate the quote.
      dispatch({
        type: EXPERIENCE_API,
        name: CREATE_QUOTE_CALCULATE,
        verb: 'POST',
        route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/calculate`,
        data: {
          quote: updatedQuote,
        },
        callback: (err, dataReceived) => {
          toast.dismiss(TOAST_ID_CALCULATE_QUOTE)
          if (get(err, 'status.type', '') === 'error') {
            toast(TOAST_TITLE_CALCULATE_QUOTE_ERROR, {
              autoClose: 3000,
              toastId: TOAST_ID_CALCULATE_QUOTE,
              type: toast.TYPE.ERROR,
            })
            return {
              dataReceived,
            }
          }
          toast(TOAST_TITLE_CALCULATE_QUOTE_SUCCESS, {
            autoClose: 3000,
            toastId: TOAST_ID_CALCULATE_QUOTE,
            type: toast.TYPE.SUCCESS,
          })
          if (callback) {
            callback()
          }
          const { businessData } = dataReceived
          const recalculatedQuote = businessData && businessData.quote ? businessData.quote : {}
          // TISO check
          const updatedReceivedQuote = {
            ...quote,
            policyStructure: recalculatedQuote.policyStructure.map(policy => ({
              ...policy,
              covers: policy.covers.map(cover => {
                if (
                  cover.parentBenefitReferance &&
                  cover.parentBenefitReferance.parentQuotePolicyNo
                )
                  delete cover.parentBenefitReferance.parentQuotePolicyNo

                return {
                  ...cover,
                  features: makeFeatures(cover, policy, data),
                }
              }),
            })),
          }

          return {
            quote: updatedReceivedQuote,
            // pass the quote index through so that the correct quote can be updated if successful.
            quoteIndex: quoteIndex || activeIndex,
          }
        },
        ...(initiateSaveQuote && {
          queuedAction: saveQuote,
        }),
      })
    } else if (initiateSaveQuote) {
      dispatch(updateCalculateQuoteError())
    }
  }

const getQuoteCalculate = (quote, callback) => dispatch => {
  let updatedQuote = updateQuoteForTISO(quote)
  updatedQuote = updateQuoteForSGBO(updatedQuote)
  updatedQuote = removeFeatureInQuote(updatedQuote)
  // set the collection `isRecalculating` value to true.
  dispatch({
    type: CREATE_QUOTE_GET_CALCULATION_IN_PROGRESS,
  })
  // call the experience api to recalculate the quote.
  dispatch({
    type: EXPERIENCE_API,
    name: CREATE_QUOTE_GET_CALCULATE,
    verb: 'POST',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/calculate`,
    data: {
      quote: updatedQuote,
    },
    callback: (err, dataReceived) => {
      const { businessData } = dataReceived
      const recalculatedQuote = businessData && businessData.quote ? businessData.quote : {}
      return callback(recalculatedQuote)
    },
  })
}

export const getRemunerationPremium =
  (policyIndex: number, quote: Object, coverIndex: Number = 0, callback: null) =>
  dispatch => {
    dispatch(
      getQuoteCalculate(quote, data => {
        const { policyStructure } = data
        const { covers } = policyStructure[policyIndex]
        const { discounts } = covers[coverIndex]
        const { discountValue } = discounts.find(
          discount => discount.discountName === DISCOUNT_COMMISSION_DIAL_DOWN
        )
        if (callback) callback(discountValue)
        return { discountValue }
      })
    )
  }

export const filterPolicyCovers =
  (updateLifeInsured: string = '', redirectQuoteTool: boolean = false) =>
  (dispatch, getState) => {
    const {
      createQuote,
      createQuote: { quotes, activeIndex },
      productRules: { data },
      masterList: { data: masterData },
    } = getState()
    const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode } = masterData
    const {
      memberMandatories: { occupationClassCode, occupationCode },
    } = quotes[activeIndex]
    if (data) {
      const filteredBenefits = updateFilteredBenefitsData(
        createQuote,
        data,
        updateLifeInsured,
        '',
        masterData
      )
      const policyStructure = updateQuoteBasedOnDependentOn(filteredBenefits, data)
      const isOwnDisabled = isOwnTPDDisabled(
        POLICY_BENEFIT_PTD,
        occupationClassCode,
        allowedTPDOccupationTypeGroup,
        excludedTPDOccupationTypeCode,
        occupationCode
      )
      const updatedPolicyStructure = isOwnDisabled
        ? removeAllOptimizedCovers(policyStructure)
        : policyStructure

      dispatch({
        type: CREATE_QUOTE_UPDATE_POLICY_STRUCTURE,
        payload: {
          policyStructure: updatedPolicyStructure,
        },
      })
      if (redirectQuoteTool) {
        const isCoverAvailable =
          updatedPolicyStructure.findIndex(
            policy => policy.covers && policy.covers.length === 0
          ) === -1
        if (isCoverAvailable) {
          dispatch(createQuoteCalculate())
        } else {
          const policy = updatedPolicyStructure.filter(policies => policies.covers.length === 0)
          policy.forEach(p => {
            dispatch(
              deletePolicy({
                quoteIndex: createQuote.activeIndex,
                policyInstanceNo: p.policyInstanceNo,
              })
            )
          })
        }
        history.push('/quote-tool')
      }
    }
  }

export const precheckMandatories = () => (dispatch, getState) => {
  const {
    createQuote,
    productRules: { data },
    masterList: { data: masterData },
  } = getState()

  const quoteWillBeModified = policiesWillUpdate(createQuote, data, masterData)
  return quoteWillBeModified
}

export const updateMandatoryDetails =
  ({ dateOfBirth, earning, earningExcludingSuper, ...otherDetails }) =>
  dispatch => {
    dispatch({
      type: CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES,
      payload: {
        ...(dateOfBirth && {
          dateOfBirth: dateOfBirth && moment(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }),
        ...(earning && { earning: earning && parseInt(earning, 10) }),
        ...(earningExcludingSuper && {
          earningExcludingSuper: earningExcludingSuper && parseInt(earningExcludingSuper, 10),
        }),
        ...otherDetails,
      },
    })
    dispatch(filterPolicyCovers(UPDATE_FROM_LIFE_INSURED))
  }

export const updateLifeInsuredDetails =
  ({ dateOfBirth, earning, earningExcludingSuper, ...otherDetails }) =>
  dispatch => {
    dispatch({
      type: CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES,
      payload: {
        dateOfBirth: dateOfBirth && moment(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        earning: earning && parseInt(earning, 10),
        earningExcludingSuper: earningExcludingSuper && parseInt(earningExcludingSuper, 10),
        ...otherDetails,
      },
    })
  }

export const updateSecondaryEmail = ({ email, firstName }) => ({
  type: CREATE_QUOTE_UPDATE_SECONDARY_MAIL,
  payload: {
    email,
    firstName,
  },
})

export const updateApplicationStatus = status => ({
  type: UPDATE_APPLICATION_STATUS,
  payload: {
    status,
  },
})

export const addCommissionSplitDetails = form => ({
  type: CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS,
  payload: {
    form,
  },
})

export const createQuoteChooseCover =
  ({ covers, childData }) =>
  (dispatch, getState) => {
    const {
      createQuote: { activeIndex, quotes },
      advisor: { details: advisorDetails },
      productRules: { data: productRulesData },
      masterList: { data },
      calculateSaveQuote: { isIPCalculated },
    } = getState()
    const { memberMandatories, option: quoteOption } = quotes[activeIndex]

    const policies = []

    // access the individual products.
    const { inSuperProduct, outsideSuperProduct } = sortProducts(productRulesData)
    // create an array of product rules data based on the selected super benefits.
    const updatedSuperCovers = applyGroupConnection(
      inSuperProduct.benefits
        .filter(benefit =>
          Object.keys(covers)
            .filter(key => covers[key] && !key.includes(POLICY_NON_SUPER_BENEFIT_PREFIX))
            .includes(benefit.benefitCode)
        )
        .map(cover => ({
          ...cover,
          benefitInstanceNo: 1,
        })),
      productRulesData,
      memberMandatories
    )

    // create an array of product rules data based on the selected non-super benefits.
    const updatedNonSuperCovers = applyGroupConnection(
      outsideSuperProduct.benefits
        .filter(benefit =>
          Object.keys(covers)
            .filter(key => covers[key] && key.includes(POLICY_NON_SUPER_BENEFIT_PREFIX))
            .map(key => key.replace(POLICY_NON_SUPER_BENEFIT_PREFIX, ''))
            .includes(benefit.benefitCode)
        )
        .map(cover => ({
          ...cover,
          benefitInstanceNo: 1,
        })),
      productRulesData,
      memberMandatories
    )
    // TISO case
    const superLCCover = updatedSuperCovers.find(
      cover => (cover.type || cover.benefitCode) === POLICY_BENEFIT_LC
    )
    const nonSuperLCCover = updatedNonSuperCovers.find(
      cover => (cover.type || cover.benefitCode) === POLICY_BENEFIT_LC
    )
    if (
      superLCCover &&
      (superLCCover.features || superLCCover.allowableFeatures).find(
        feature =>
          (feature.featureName || feature.code) === POLICY_BENEFIT_TISO &&
          (feature.featureApplicable === 'Y' || feature.selected)
      )
    ) {
      const superProductData = productRulesData.find(
        product => product.productId === POLICY_PRODUCT_CODE_SUPER
      )
      const tisoBenefit = (superProductData.covers || superProductData.benefits).find(
        cover => (cover.type || cover.benefitCode) === POLICY_BENEFIT_TISO
      )
      if (!isValidBenefit(tisoBenefit, memberMandatories)) {
        updatedSuperCovers.push({
          benefitInstanceNo: 1,
          ...tisoBenefit,
        })
      } else {
        superLCCover.features = (superLCCover.features || superLCCover.allowableFeatures).map(
          f => ({
            ...f,
            ...((f.featureName || f.code) === POLICY_BENEFIT_TISO
              ? { featureApplicable: 'N' }
              : {}),
          })
        )
      }
    }

    if (
      nonSuperLCCover &&
      (nonSuperLCCover.features || nonSuperLCCover.allowableFeatures).find(
        feature =>
          (feature.featureName || feature.code) === POLICY_BENEFIT_TISO &&
          (feature.featureApplicable === 'Y' || feature.selected)
      )
    ) {
      const NonSuperProductData = productRulesData.find(
        product => product.productId === POLICY_PRODUCT_CODE_NON_SUPER
      )
      const tisoBenefit = (NonSuperProductData.covers || NonSuperProductData.benefits).find(
        cover => (cover.type || cover.benefitCode) === POLICY_BENEFIT_TISO
      )
      if (!isValidBenefit(tisoBenefit, memberMandatories)) {
        updatedNonSuperCovers.push({
          benefitInstanceNo: 1,
          ...tisoBenefit,
        })
      } else {
        nonSuperLCCover.features = (
          nonSuperLCCover.features || nonSuperLCCover.allowableFeatures
        ).map(f => ({
          ...f,
          ...((f.featureName || f.code) === POLICY_BENEFIT_TISO ? { featureApplicable: 'N' } : {}),
        }))
      }
    }

    // SGBO Benefit

    const superIPCover = updatedSuperCovers.find(
      cover =>
        (cover.type || cover.benefitCode) === INCOME_PROTECTION_PLATINUM_ID ||
        (cover.type || cover.benefitCode) === INCOME_PROTECTION_STANDARD_ID
    )
    const nonSuperIPCover = updatedNonSuperCovers.find(
      cover =>
        (cover.type || cover.benefitCode) === INCOME_PROTECTION_PLATINUM_ID ||
        (cover.type || cover.benefitCode) === INCOME_PROTECTION_STANDARD_ID
    )
    const sgboSuperBenefitCode =
      superIPCover && superIPCover.benefitCode === INCOME_PROTECTION_PLATINUM_ID
        ? POLICY_BENEFIT_PLAT_SGBO
        : POLICY_BENEFIT_STD_SGBO

    const sgboNonSuperBenefitCode =
      nonSuperIPCover && nonSuperIPCover.benefitCode === INCOME_PROTECTION_PLATINUM_ID
        ? POLICY_BENEFIT_PLAT_SGBO
        : POLICY_BENEFIT_STD_SGBO

    const isSGBOApplicable =
      (superIPCover &&
        (superIPCover.features || superIPCover.allowableFeatures).find(
          feature =>
            (feature.featureName || feature.code) === POLICY_FEATURE_SGBO &&
            (feature.featureApplicable === 'Y' || feature.selected)
        )) ||
      (nonSuperIPCover &&
        (nonSuperIPCover.features || nonSuperIPCover.allowableFeatures).find(
          feature =>
            (feature.featureName || feature.code) === POLICY_FEATURE_SGBO &&
            (feature.featureApplicable === 'Y' || feature.selected)
        ))

    if (isSGBOApplicable && superIPCover) {
      const superProductData = productRulesData.find(
        product => product.productId === POLICY_PRODUCT_CODE_SUPER
      )
      const sgboBenefitSuper = (superProductData.covers || superProductData.benefits).find(
        cover => (cover.type || cover.benefitCode) === sgboSuperBenefitCode
      )
      updatedSuperCovers.push({
        benefitInstanceNo: 1,
        ...sgboBenefitSuper,
      })
    }

    if (isSGBOApplicable && nonSuperIPCover) {
      const NonSuperProductData = productRulesData.find(
        product => product.productId === POLICY_PRODUCT_CODE_NON_SUPER
      )
      const sgboBenefitNonSuper = (NonSuperProductData.covers || NonSuperProductData.benefits).find(
        cover => (cover.type || cover.benefitCode) === sgboNonSuperBenefitCode
      )
      updatedNonSuperCovers.push({
        benefitInstanceNo: 1,
        ...sgboBenefitNonSuper,
      })
    }

    const selectedPolicyStructure = [
      ...(updatedSuperCovers && updatedSuperCovers.length
        ? [
            {
              productId: POLICY_PRODUCT_CODE_SUPER,
              covers: updatedSuperCovers,
              policyInstanceNo: '1',
            },
          ]
        : []),
      ...(updatedNonSuperCovers && updatedNonSuperCovers.length
        ? [
            {
              productId: POLICY_PRODUCT_CODE_NON_SUPER,
              covers: updatedNonSuperCovers,
              policyInstanceNo: updatedSuperCovers && updatedSuperCovers.length ? '2' : '1',
            },
          ]
        : []),
    ]
    // assemble the policy structure for the quote entity.
    if (updatedSuperCovers.length) {
      policies.push(
        createPolicy(
          selectedPolicyStructure,
          inSuperProduct,
          1,
          updatedSuperCovers,
          advisorDetails,
          childData,
          memberMandatories,
          quoteOption
        )
      )
    }
    if (updatedNonSuperCovers.length) {
      policies.push(
        createPolicy(
          selectedPolicyStructure,
          outsideSuperProduct,
          updatedSuperCovers.length ? 2 : 1,
          updatedNonSuperCovers,
          advisorDetails,
          childData,
          memberMandatories,
          quoteOption
        )
      )
    }

    const updatedPolicy = policies.map(policy => ({
      ...policy,
      covers: policy.covers.map(cover => {
        const lcCover = policy.covers.find(c => c.type === POLICY_BENEFIT_LC)
        const ipCover = policy.covers.find(c => isIPCover(c))
        return {
          ...cover,
          ...(cover.type === POLICY_BENEFIT_TISO
            ? {
                commissionDetails: lcCover.commissionDetails,
                premiumStyle: lcCover.premiumStyle,
                parentBenefitReference: {
                  parentPolicyReferenceNo: policy.policyInstanceNo.toString(),
                  parentQuotePolicyNo: policy.policyInstanceNo.toString(),
                  parentBenefitCode: POLICY_BENEFIT_LC,
                  parentType: POLICY_BENEFIT_LC,
                  parentBenefitInstanceNo: lcCover.benefitInstanceNo.toString(),
                  benefitNature: 'Satellite',
                },
              }
            : {}),
          ...(isSGBOBenefit(cover.type)
            ? {
                commissionDetails: ipCover.commissionDetails,
                premiumStyle: ipCover.premiumStyle,
                waitingPeriod: ipCover.waitingPeriod,
                coverPeriod: ipCover.coverPeriod,
                parentBenefitReference: {
                  parentPolicyReferenceNo: policy.policyInstanceNo.toString(),
                  parentQuotePolicyNo: policy.policyInstanceNo.toString(),
                  parentBenefitCode: ipCover.type,
                  parentType: ipCover.type,
                  parentBenefitInstanceNo: ipCover.benefitInstanceNo.toString(),
                  benefitNature: 'Satellite',
                },
              }
            : {}),
        }
      }),
    }))

    const { updatedPolicyStructure, calculatedsumInsured } = postProcessInitialPolicyStructure(
      productRulesData,
      memberMandatories,
      updatedPolicy,
      data,
      isIPCalculated,
      true // SR occupation check, update default benefit and waiting period
    )

    dispatch({
      type: SET_IP_SUM_INSURED,
      payload: {
        sumInsured: calculatedsumInsured,
      },
    })

    dispatch({
      type: CREATE_QUOTE_SET_POLICY_STRUCTURE,
      payload: {
        policyStructure: updatedPolicyStructure,
      },
    })
  }

export const createQuoteSetPolicyStructure = policyStructure => ({
  type: CREATE_QUOTE_SET_POLICY_STRUCTURE,
  payload: {
    policyStructure,
  },
})

export const createQuoteSetAlterationEffectiveDate = alterationEffectiveDate => ({
  type: CREATE_QUOTE_SET_ALTERATION_EFFECTIVE_DATE,
  payload: {
    alterationEffectiveDate: moment(alterationEffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
  },
})

export const createQuoteSetPolicyStructureOnTrack = mlcOnTrack => ({
  type: CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_TRACK,
  payload: {
    mlcOnTrack,
  },
})

export const createQuoteSetPolicyStructureOnPolicyFee = policyFee => ({
  type: CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_POLICY_FEE,
  payload: {
    policyFee,
  },
})

// set a fixed payment frequency for the active inside super policy.
export const createQuoteSetFixedPaymentFrequency = () => ({
  type: CREATE_QUOTE_SET_FIXED_PAYMENT_FREQUENCY,
})

// update payment frequency for a policy.
export const createQuoteUpdatePaymentFrequency = ({ paymentFrequency, policyIndex }) => ({
  type: CREATE_QUOTE_UPDATE_PAYMENT_FREQUENCY,
  payload: {
    paymentFrequency,
    policyIndex,
  },
})

// update the legacy product code for an Inside Super policy based on
// paymentInstructions/superFundName.
export const createQuoteUpdateLegacyProductCode =
  (paymentInstructions: string, fundPaymentMethod: string) =>
  (dispatch: Function, getState: Function) => {
    const {
      productRules: { data },
    } = getState()

    dispatch({
      type: CREATE_QUOTE_UPDATE_LEGACY_CODE,
      payload: {
        legacySystemProductCode: getLegacyProductCode({
          paymentInstructions,
          productRules: data,
          fundPaymentMethod,
        }),
      },
    })
  }

export const updatePolicyName = () => ({
  type: CREATE_QUOTE_UPDATE_POLICY_NAME,
})

// update payment details for the active inside super policy.
export const createQuoteUpdatePaymentDetails =
  ({ fundDetails, paymentInstructions, product, premiumPaymentMethod, selectedPolicyFee }) =>
  dispatch => {
    // if payment details trigger a fixed payment frequency rule, update the
    // inside super policy's payment frequency.
    if (isFixedPaymentFrequency(fundDetails)) {
      dispatch(createQuoteSetFixedPaymentFrequency())
    }

    // update the Inside Super policy's legacy product code
    dispatch(createQuoteUpdateLegacyProductCode(paymentInstructions, fundDetails))

    // update payment details.
    dispatch({
      type: CREATE_QUOTE_UPDATE_PAYMENT_DETAILS,
      payload: {
        fundDetails,
        paymentInstructions,
        product,
        premiumPaymentMethod,
        selectedPolicyFee,
      },
    })

    // update policy name based on payment method
    dispatch(updatePolicyName())
  }

export const createQuoteUpdateSumInsured = ({ policyIndex, coverIndex, value }) => ({
  type: CREATE_QUOTE_UPDATE_SUM_INSURED,
  payload: {
    policyIndex,
    coverIndex,
    value: value.trim(),
    sumAssuredApplicable: parseInt(value, 10) > 0 ? 'ON' : 'OFF',
  },
})

export const createQuoteUpdateChildData = ({ childData, policyIndex, benefitInstanceNo }) => ({
  type: CREATE_QUOTE_UPDATE_CHILD_DATA,
  payload: {
    childData,
    policyIndex,
    benefitInstanceNo,
  },
})

export const createQuoteUpdateCovers = ({ covers, policyIndex }) => ({
  type: CREATE_QUOTE_UPDATE_COVERS,
  payload: {
    covers,
    policyIndex,
  },
})

export const createQuoteUpdateCover =
  (policyIndex, coverIndex, value, callback) => (dispatch, getState) => {
    const { productRules, masterList } = getState()
    dispatch({
      type: CREATE_QUOTE_UPDATE_COVER,
      payload: {
        policyIndex,
        coverIndex,
        value,
        productRules: productRules.data,
        masterData: masterList.data,
      },
    })
    if (callback) {
      const {
        createQuote: { quotes, activeIndex },
      } = getState()
      const activeQuote = quotes[activeIndex]
      callback(activeQuote)
    }
  }

export const createQuoteUpdateFeatures = ({
  policyIndex,
  benefitInstanceNo,
  features,
  type,
}: {
  policyIndex: number,
  benefitInstanceNo: number,
  features: Array<Object>,
}): Object => ({
  type: CREATE_QUOTE_SET_FEATURES,
  payload: {
    policyIndex,
    benefitInstanceNo,
    features,
    type,
  },
})

export const addPolicyToQuote = policy => ({
  type: CREATE_QUOTE_ADD_POLICY,
  payload: {
    policy,
  },
})

export const addBenefitToPolicy =
  (benefit, policyIndex, policyFee) => (dispatch: Function, getState: Function) => {
    dispatch({
      type: CREATE_QUOTE_ADD_BENEFIT_TO_POLICY,
      payload: {
        benefit,
        policyIndex,
        policyFee,
      },
    })
    const {
      createQuote: { activeIndex, quotes },
    } = getState()
    const activeQuote = quotes[activeIndex]
    if (activeQuote) {
      const { policyStructure } = activeQuote
      const activePolicy = policyStructure && policyStructure[policyIndex]
      if (isIPCover(benefit) || isBECover(benefit)) {
        if (activePolicy && activePolicy.productId === POLICY_PRODUCT_CODE_NON_SUPER) {
          const { relationships, policyInstanceNo } = activePolicy
          const policyOwners =
            relationships &&
            relationships.filter(relation =>
              relation.role.find(role => role.includes(POLICY_RELATIONSHIPS_OWNER))
            )

          policyOwners &&
            policyOwners.length &&
            policyOwners.forEach(policyOwner => {
              const identifier = getIdentifier(policyOwner)
              dispatch({
                type: CREATE_QUOTE_DELETE_MEMBER_DETAILS,
                payload: {
                  identifier,
                  policyInstanceNo,
                  roleToGetRemoved: POLICY_RELATIONSHIPS_OWNER,
                  deletePolicyOwner: true,
                },
              })
            })
        }
      }
    }
  }

export const setQuoteName = quoteName => ({
  type: CREATE_QUOTE_SET_QUOTE_NAME,
  payload: {
    quoteName,
  },
})

// Reset Total Premium Amount
export const resetTotalPremiumAmount = () => (dispatch: Function, getState: Function) => {
  const {
    createQuote: { quotes, activeIndex },
  } = getState()
  const quote = quotes[activeIndex]
  const { policyStructure } = quote
  const hasCover = policyStructure.length && policyStructure.some(policy => !!policy.covers.length)
  dispatch({
    type: RESET_TOTAL_PREMIUM_AMOUNT,
    payload: {
      hasCover,
    },
  })
}

export const removeParentBenefitReference = (policyIndex, coverIndex) => ({
  type: REMOVE_PARENT_BENEFIT_REFERENCE,
  payload: {
    policyIndex,
    coverIndex,
  },
})

export const updateFeatureStatus = (policyIndex, coverIndex) => ({
  type: CREATE_QUOTE_UPDATE_FEATURE_STATUS,
  payload: {
    policyIndex,
    coverIndex,
  },
})

export const createQuoteUpdateBenefitLink =
  ({ policyIndex, coverIndex, value }) =>
  (dispatch, getState) => {
    const {
      productRules: { data: productRules },
      masterList: { data: masterData },
    } = getState()
    dispatch({
      type: CREATE_QUOTE_UPDATE_BENEFIT_LINK,
      payload: {
        policyIndex,
        coverIndex,
        value,
        productRules,
        masterData,
      },
    })
  }

type memberDetailsDataObject = {
  contactNumber: String,
  contactNumberCountryCode: String,
  contactNumberPhoneCode: String,
  coverRulesPassed: Boolean,
  dateOfBirth: String,
  earningsPA: Number,
  earningsPAExcludingSuper: Number,
  email: String,
  employmentStatus: String,
  firstName: String,
  gender: String,
  houseName: String,
  identifier: String,
  isPostalResidentialAddressSame: Boolean,
  isSmoker: String,
  lastName: String,
  postalAddress: String,
  postalCountry: String,
  postalHouseNo: String,
  postalLocality: String,
  postalPostCode: String,
  postalState: String,
  postalStreet: String,
  primaryOccupation: String,
  primaryOccupationCode: String,
  residentialAddress: String,
  residentialCountry: String,
  residentialHouseNo: String,
  residentialLocality: String,
  residentialPostCode: String,
  residentialState: String,
  residentialStreet: String,
  secondaryEarningsPA: String,
  secondaryNumber: String,
  secondaryNumberCountryCode: String,
  secondaryNumberPhoneCode: String,
  secondaryOccupation: String,
  secondaryOccupationCode: String,
  title: String,
}

export const submitMemberDetails =
  (data: memberDetailsDataObject, form, role, policyInstanceNo, formId, callback) =>
  (dispatch, getState) => {
    const { occupation = {}, forms } = getState()
    let memberform = form
    if (!form && formId) {
      memberform = forms[formId]
    }
    const memberEntityStructure = getLifeInsuredMemberEntity(data, occupation)
    if (memberform) {
      if (data.coverRulesPassed === undefined || data.coverRulesPassed) {
        dispatch(
          updateApplicationStage(memberform.isValid ? 2 : 0, {
            stage1: memberform.isValid
              ? APPLICATION_STAGE_STEP.completed
              : APPLICATION_STAGE_STEP.partiallyComplete,
          })
        )
      } else {
        dispatch(
          updateApplicationStage(0, {
            stage0: APPLICATION_STAGE_STEP.incomplete,
            stage1: APPLICATION_STAGE_STEP.partiallyComplete,
          })
        )
      }
    }

    dispatch({
      type: CREATE_QUOTE_UPDATE_MEMBER_DETAILS,
      payload: {
        memberEntityStructure,
        role,
        policyInstanceNo,
      },
    })
    if (callback) {
      const {
        createQuote: { quotes, activeIndex },
      } = getState()
      const activeQuote = quotes[activeIndex]
      callback(activeQuote)
    }
  }

export const addFeatureAsSubBenefit = (benefitCode, sbenefitCode) => (dispatch, getState) => {
  const {
    productRules: { data: productRulesData },
  } = getState()

  dispatch({
    type: CREATE_QUOTE_ADD_FEATURE_AS_SUB_BENEFIT,
    payload: { productRulesData, benefitCode, sbenefitCode },
  })
}

export const deleteFeatureAsSubBenefit =
  (benefitCode, policyInstanceNo, benefitInstanceNo) => dispatch => {
    dispatch({
      type: CREATE_QUOTE_DELETE_FEATURE_AS_SUB_BENEFIT,
      payload: { benefitCode, policyInstanceNo, benefitInstanceNo },
    })
  }

export const deleteMemberDetails = (
  identifier,
  policyInstanceNo,
  roleToGetRemoved,
  deletePolicyOwner = false
) => ({
  type: CREATE_QUOTE_DELETE_MEMBER_DETAILS,
  payload: { identifier, policyInstanceNo, roleToGetRemoved, deletePolicyOwner },
})

export const addPolicyOwner = ({ policyInstanceNo, newRelationship }) => ({
  type: CREATE_QUOTE_ADD_POLICY_OWNER,
  payload: {
    policyInstanceNo,
    newRelationship,
  },
})

export const updatePolicyOwner = (policyInstanceNo, identifier, relationships, isPrimary) => ({
  type: CREATE_QUOTE_UPDATE_POLICY_OWNER,
  payload: {
    policyInstanceNo,
    identifier,
    relationships,
    isPrimary,
  },
})

export const updateBeneficiaries = (policyInstanceNo, beneficiaries) => ({
  type: CREATE_QUOTE_UPDATE_BENEFICIARIES,
  payload: {
    policyInstanceNo,
    beneficiaries,
  },
})

export const removeEmptyPolicy = () => ({
  type: CREATE_QUOTE_REMOVE_EMPTY_POLICY,
})

// Action creator for removing benefits
// @param {object} benefit Object containing type, benefitInstanceNo,
// policyInstanceNo and quoteIndex of benefit
// @return {function} - action dispatcher
export const removeBenefit = benefit => (dispatch, getState) => {
  const {
    productRules: { data: productRulesData },
    masterList: { data },
    calculateSaveQuote: { isIPCalculated },
  } = getState()
  dispatch({
    type: CREATE_QUOTE_REMOVE_BENEFIT,
    payload: {
      benefit,
      productRulesData,
      data,
      isIPCalculated,
    },
  })
  // Removing empty policy if any after deleting covers
  dispatch(removeEmptyPolicy())
}

export const duplicateQuoteTemplate = (quoteItem, activeIndex) => ({
  type: CREATE_QUOTE_DUPLICATE_TEMPLATE,
  payload: {
    quoteItem,
    activeIndex,
  },
})

export const deleteQuoteFromCollection = activeIndex => ({
  type: CREATE_QUOTE_DELETE_FROM_COLLECTION,
  payload: {
    activeIndex,
  },
})

export const updateQuoteActiveIndex = activeIndex => ({
  type: UPDATE_QUOTE_ACTIVE_INDEX,
  payload: {
    activeIndex,
  },
})

/**
 * Action creator for updating isSaveQuote state in createQuote
 * @return {function} - action creator
 */
export const updateIsSaveQuote = (isSaveQuote = true) => ({
  type: CREATE_QUOTE_UPDATE_IS_SAVE,
  payload: {
    isSaveQuote,
  },
})

/**
 * Action creator for updating show/hide state of save quote modal
 * @param {boolean} isSaveQuoteModal true/false to show/hide save quote modal
 * @param {boolean} exitToDashboard true/false to know if exit to dashboard button is clicked
 * @return {function} - action creator
 */
export const showSaveQuoteModal = (isSaveQuoteModal, exitToDashboard = false, identifier) => ({
  type: CREATE_QUOTE_SHOW_SAVE_MODAL,
  payload: {
    showSaveQuoteModal: isSaveQuoteModal,
    exitToDashboard,
    identifier,
  },
})

/**
 * Action creator for updating BancsCustomerNumber state in createQuote
 * @return {function} - action creator
 */
export const updateBancsCustomerNumber = bancsCustomerNo => ({
  type: UPDATE_BANCS_ADVISER_CUSTOMER_NO,
  payload: { ...(bancsCustomerNo && { bancsCustomerNo }) },
})

/**
 * Action creator for delete quote, makes api call
 * @return {function} - action creator
 */
export const deleteQuoteCollection = callback => (dispatch, getState) => {
  dispatch(isUpdatedQuote())
  const { createQuote } = getState()
  const { activeIndex, quotes } = createQuote
  const { quoteId } = quotes[activeIndex]
  const data = {
    ...createQuote,
    ...filterQuoteEntityToSave(createQuote),
    quoteIDToDelete: [quoteId],
  }

  dispatch({
    type: EXPERIENCE_API,
    name: DELETE_QUOTE_COLLECTION,
    verb: 'POST',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes`,
    data,
    callback: (err, dataReceived) => {
      if (callback) {
        callback(err, dataReceived)
      }
      return {
        dataReceived,
        deletedQuoteId: quoteId,
      }
    },
  })
}

/**
 * Action creator to show/hide calculate quote error modal
 * @param {boolean} isCalQuoteErrorModal true/false to show/hide calculate quote error modal
 * @return {function} - action creator
 */
export const showCalQuoteErrorModal = isCalQuoteErrorModal => ({
  type: CREATE_QUOTE_CALCULATE_QUOTE_ERROR_MODAL,
  payload: {
    isCalQuoteErrorModal,
  },
})

/**
 * Action creator to conditionally render save and exit modal
 * @param {boolean} isSaveAndExitModal true/false to show/hide save and exit modal
 * @return {function} - action creator
 */
export const showSaveAndExitModal = isSaveAndExitModal => ({
  type: CREATE_QUOTE_SAVE_AND_EXIT_MODAL,
  payload: {
    isSaveAndExitModal,
  },
})

/**
 * Action creator to show/hide save quote error modal
 * @param {boolean} isSaveQuoteErrorModal true/false to show/hide save quote error modal
 * @return {function} - action creator
 */
export const showSaveQuoteErrorModal = isSaveQuoteErrorModal => ({
  type: CREATE_QUOTE_SAVE_QUOTE_ERROR_MODAL,
  payload: {
    isSaveQuoteErrorModal,
  },
})

/**
 * Action creator to update consents data related to duty of disclosure
 * @param {string} isDutyOfDisclosureChecked 'Y'/'N' if duty of disclosure is checked
 * @param {string} isDutyOfDisclosurePrivacyChecked 'Y'/'N' if duty of disclosure privacy is checked
 * @return {function} - action creator
 */
export const updateDutyOfDisclosureConsents = ({
  isDutyOfDisclosureChecked,
  isDutyOfDisclosurePrivacyChecked,
  targetedIndex,
}) => ({
  type: CREATE_QUOTE_UPDATE_DUTY_OF_DISCLOSURE_CONSENTS,
  payload: {
    isDutyOfDisclosureChecked,
    isDutyOfDisclosurePrivacyChecked,
    targetedIndex,
  },
})

export const setAdviserPresentedLicopFlyerConsent = () => ({
  type: CREATE_QOUTE_SET_ADVISER_PRESENTED_LICOP_FLYER_CONSENT,
})

export const updateDeclarationAuthorisationConsents = ({
  isDeclarationAuthorizationChecked,
  targetedIndex,
  termNumber,
  description,
}) => ({
  type: CREATE_QUOTE_UPDATE_DECLARATION_AUTHORIZATION_CONSENTS,
  payload: {
    isDeclarationAuthorizationChecked,
    targetedIndex,
    identifier: termNumber,
    description,
  },
})

export const updateDeclarationPOConsents = ({
  isDeclarationPOChecked,
  targetedIndex,
  termNumber,
}) => ({
  type: CREATE_QUOTE_UPDATE_DECLARATION_PO_CONSENTS,
  payload: {
    isDeclarationPOChecked,
    targetedIndex,
    identifier: termNumber,
  },
})

export const updateTeleCustomerTerm = ({ isTeleTermConfirmed, targetedIndex, termNumber }) => ({
  type: CREATE_QUOTE_UPDATE_TELE_CUSTOMER_TERM_CONSENTS,
  payload: {
    isTeleTermConfirmed,
    targetedIndex,
    identifier: termNumber,
  },
})

export const updateAdviserAuthorityTerm = ({ isAdviserAuthorityConfirm, targetedIndex }) => ({
  type: CREATE_QUOTE_UPDATE_ADVISER_AUTHORITY_CONSENTS,
  payload: {
    isAdviserAuthorityConfirm,
    targetedIndex,
  },
})

export const updateAdviserDeclarationConsents = ({
  isAdviserDeclarationChecked,
  targetedIndex,
  termNumber,
  description,
}) => ({
  type: CREATE_QUOTE_UPDATE_DECLARATION_FOR_ADVISOR_CONSENTS,
  payload: {
    isAdviserDeclarationChecked,
    targetedIndex,
    identifier: termNumber,
    description,
  },
})

export const resetDeclarationConsents = ({ targetedIndex }) => ({
  type: RESET_QUOTE_DECLARATION_CONSENTS,
  payload: {
    targetedIndex,
  },
})

export const updateMarketingConsents = ({
  isMarketingConsentsChecked,
  targetedIndex,
  marketingContent,
}) => ({
  type: CREATE_QUOTE_UPDATE_MARKETING_CONSENTS,
  payload: {
    isMarketingConsentsChecked,
    targetedIndex,
    ...(marketingContent && { marketingContent }),
  },
})

/**
 * Action creator to update the Quote status
 * @param {object} quoteStatus it's contains action, type and UW Method
 * @return {function} - action creator
 */
export const updateQuoteStatus = quoteStatus => ({
  type: UPDATE_QUOTE_STATUS,
  payload: {
    quoteStatus,
  },
})

/**
 * Action creator to remove consents data related to Action Agreement in CARM
 * @return {function} - action creator
 */
export const removeActionAgreementConsents = () => ({
  type: REMOVE_ACTION_AGREEMENT_CONSENTS,
})

/**
 * Action creator to update quote entity - Email and Phone Number
 * @param {string} contact  it's Contains EmailAddress and phone number
 * @param {number} targeteIndex Quote index number
 * @return {function} - action creator
 */
export const updateQuoteEntityMyLinkDetails = (contact, adviserContact, targeteIndex) => ({
  type: UPDATE_QUOTE_ENTITY_MY_LINK_DETAILS,
  payload: {
    contact,
    adviserContact,
    targeteIndex,
  },
})

/**
 * Action creator to update consents data related to Quote Entity
 * @param {string} consentsData  "Y" if adviser agree
 * @param {number} activeIndex Quote index number
 * @return {function} - action creator
 */
export const updateAdviserVerificationConsents = (consentsData, activeIndex) => ({
  type: UPDATE_ADVISER_VERIFICATION_CONSENTS,
  payload: {
    consentsData,
    activeIndex,
  },
})

/**
 * Action creator to update quote entity policy benefits
 * @param updateTermValue updated ure result benefit state
 * @return {function} - action creator
 */
export const updateIsTermAcceptedField = updatedTermValue => ({
  type: UPDATE_IS_TERM_ACCEPTED_FIELD,
  payload: {
    updatedTermValue,
  },
})

/**
 * Action creator to reset update update quote collection flag
 * @return {function} - action creator
 */
export const resetUpdateQuoteSuccessFlag = () => ({
  type: RESET_UPDATE_QUOTE_COLLECTION_SUCCESS_FLAG,
})

/**
 * Action creator to update consents data related to underwriting options
 * @param {string} isTrackMedicalAgreed 'Y'/'N' if agree to track medical requirements
 * @param {string} isTrackFollowupAgreed 'Y'/'N' if agree to track follow up infors
 * @param {string} fastTrackingMedicalContent Medial content
 * @param {string} fastTrackingFollowupContent Followup content
 * @return {function} - action creator
 */
export const updateUnderwritingOptionsConsents = ({
  isTrackMedicalAgreed,
  isTrackFollowupAgreed,
  fastTrackingMedicalContent,
  fastTrackingFollowupContent,
}) => ({
  type: CREATE_QUOTE_UPDATE_UNDERWRITING_OPTIONS_CONSENTS,
  payload: {
    isTrackMedicalAgreed,
    isTrackFollowupAgreed,
    fastTrackingMedicalContent,
    fastTrackingFollowupContent,
  },
})

/**
 * Action creator to update consents data related to Mlc On Track options
 * @param {string} isMlcOnTrackTermsAndConditionsAgreed 'Y'/'N' to present if agree to MLC on track
 * @param {string} isFitnessTrackerFollowupAgreed 'Y'/'N' if select the Fitness Tracker options
 * @return {function} - action creator
 */
export const updateMlcOnTrackConsents = ({
  isMlcOnTrackTermsAndConditionsAgreed,
  isFitnessTrackerFollowupAgreed,
}) => ({
  type: CREATE_QUOTE_UPDATE_MLC_ON_TRACK_CONSENTS,
  payload: {
    isMlcOnTrackTermsAndConditionsAgreed,
    isFitnessTrackerFollowupAgreed,
  },
})

export const updateTMDConsents = ({
  meetsTMDRequirementRadio,
  notMeetsTMDRequirementReason,
  isPersonalOrGeneralAdviceForTMDRadio,
}) => ({
  type: CREATE_QUOTE_UPDATE_TMD_CONSENTS,
  payload: {
    meetsTMDRequirementRadio,
    notMeetsTMDRequirementReason,
    isPersonalOrGeneralAdviceForTMDRadio,
  },
})

/**
 * Action creator for saving quote, makes api call
 * @return {function} - action creator
 */
export const fetchQuoteCollection = (quoteCollectionId, callback) => dispatch => {
  dispatch({
    type: EXPERIENCE_API,
    name: FETCH_QUOTE_COLLECTION,
    verb: 'GET',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/${quoteCollectionId}`,
    queuedAction: convertCoversToFeatures,
    callback: (err, dataReceived) => {
      if (callback) {
        callback(err, dataReceived.businessData)
      }
      return dataReceived
    },
  })
}

export const checkQuoteActionStatus = (quoteCollectionId, callback) => dispatch => {
  dispatch({
    type: EXPERIENCE_API,
    name: CHECK_QUOTE_ACTION_STATUS,
    verb: 'GET',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/${quoteCollectionId}`,
    callback: (err, dataReceived) => {
      if (callback) {
        callback(err, dataReceived)
      }
      return dataReceived
    },
  })
}

/**
 * Action creator for submit PaymentDetails
 * @return {function} - action creator
 */
export const submitPaymentDetails = data => (dispatch, getState) => {
  const {
    sidebar: { panelProps },
    bsbLookup: { directDebitBsb },
    captureCreditCard: { nabData },
    createQuote: { quotes, activeIndex },
    productRules: { data: productRulesData },
  } = getState()

  const { policyStructure } = quotes[activeIndex]
  const activePolicyInstanceNo = getActivePolicy(getState().sidebar)
  const activePolicy = policyStructure.find(
    policy => policy.policyInstanceNo === activePolicyInstanceNo
  )

  let paymentMethod = {
    collectionFrequency: get(activePolicy, 'paymentFrequency', ''),
    collectionBasis: 'IN_ARREAR', // TODO: its a mandatory field so it needs to get hardcoded for now as we don't know from where this field will come
    collectionMethod: [...FUND_PAYMENT_METHODS_IOOF, WRAP, MASTER_KEY_ACCOUNT].includes(
      data.paymentDetails
    )
      ? ELIGIBLE_MLC_ACCOUNT
      : data.paymentDetails,
  }
  if (data.paymentDetails === DD) {
    paymentMethod = {
      ...paymentMethod,
      paymentDrawDay: data.paymentDrawDate,
      debitCardDetails: {
        bsb: get(directDebitBsb, 'data.branchCode', ''),
        accountNo: data.accountNumber,
        bankName: get(directDebitBsb, 'data.bsbName', ''),
        accountName: data.accountName,
        branchAddress: get(directDebitBsb, 'data.address', ''),
      },
    }
  } else if (data.paymentDetails === CC) {
    const cardType = getCreditType(data.cardNumber)

    paymentMethod = {
      ...paymentMethod,
      paymentDrawDay: data.paymentDrawDay,
      creditCardDetails: {
        tokenNumber: get(nabData, 'token', ''),
        panID: get(nabData, 'pan', ''),
        cardExpiryDate: data.expiryDate,
        ccAmendmentReason: '',
        cardOwnerName: data.cardHolderName,
        cardType: cardType && cardType.isVisa ? 'VI' : 'MC',
      },
    }
  } else if (
    [...FUND_PAYMENT_METHODS_IOOF, WRAP, MASTER_KEY_ACCOUNT].includes(data.paymentDetails)
  ) {
    paymentMethod = {
      ...paymentMethod,
      paymentDrawDay: data.paymentDrawDate,
      fundDetails: {
        externalMembershipNo: data.accountNumber,
      },
    }
  } else if (data.paymentDetails === ROLLOVER_FROM_EXTERNAL_FUND) {
    const productPolicyRules =
      activePolicy && activePolicy.productId
        ? productRulesData.find(rule => rule.productId === activePolicy.productId)
        : {}

    let internalUSI = ''

    if (productPolicyRules.allowableUsi) {
      const { allowableUsi, paymentMethods } = productPolicyRules
      const superFundMethod = paymentMethods.find(
        method => method.productClass.toLowerCase() === 'super'
      )
      if (superFundMethod && superFundMethod.latestSeriesCode) {
        const usiData = allowableUsi.find(
          allowable => allowable.seriesCode === superFundMethod.latestSeriesCode
        )
        if (usiData && usiData.usi) {
          internalUSI = usiData.usi
        }
      }
    }

    paymentMethod = {
      ...paymentMethod,
      paymentDrawDay: data.paymentDrawDate,
      fundDetails: {
        externalFundName: data.fundName,
        externalFundABN: data.abn,
        internalUSI,
        uniqueSuperannuationId: data.usi,
        externalMembershipNo: data.membershipNumber,
        externalFundProductName: data.productName,
      },
    }
  }

  dispatch({
    type: CREATE_QUOTE_ADD_PAYMENT_DETAILS,
    payload: {
      paymentMethod,
      policyInstanceNo: panelProps.policyInstanceNo,
    },
  })
}

/**
 * Action creator for submit PaymentDetails
 * @return {function} - action creator
 */
export const updateRoleOfMember = role => (dispatch, getState) => {
  const {
    sidebar: {
      panelProps: { policyInstanceNo, payerType },
    },
  } = getState()

  dispatch({
    type: CREATE_QUOTE_UPDATE_ROLE_OF_MEMBER,
    payload: {
      policyInstanceNo,
      payerType,
      role,
    },
  })
}

/**
 * Action creator to update printable adviser details in the quote
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} businessName
 * @param {string} abn
 * @param {string} address1
 * @param {string} address2
 * @param {string} state
 * @param {string} postCode
 * @param {string} mobile
 * @param {string} email
 * @return {function} - action creator
 */
export const updatePrintableAdviserDetails = payload => ({
  type: CREATE_QUOTE_UPDATE_PRINTABLE_ADVISER_DETAILS,
  payload,
})

/**
 * Action creator for updating existing cover in quote
 * @return {function} - action creator
 */
export const updateExisitingCover = existingCover => dispatch => {
  // TODO: It will uncomment once save quote api is stable
  // dispatch(saveQuote())
  dispatch({
    type: CREATE_QUOTE_UPDATE_EXISTING_COVER,
    payload: {
      existingCover,
    },
  })
}

export const updateApplicationType = applicationType => (dispatch, state) => {
  const {
    createQuote: { applicationType: stateApplicationType },
  } = state()

  if (stateApplicationType !== applicationType) {
    dispatch({
      type: CREATE_QUOTE_UPDATE_APPLICATION_TYPE,
      payload: {
        applicationType,
      },
    })
  }
}

export const resetQuoteIdfromQuoteEntity = () => ({
  type: CREATE_QUOTE_RESET_QUOTE_ID,
})

export const updateQuoteEntity = (quotes, queuedDispatch) => (dispatch, getState) => {
  const {
    productRules,
    advisor: { details: advisorDetails },
  } = getState()

  dispatch({
    type: CREATE_QUOTE_UPDATE_QUOTE_DATA,
    payload: {
      quotes,
      productRules,
      advisorDetails,
    },
  })

  if (queuedDispatch) {
    dispatch(queuedDispatch)
  }
}

export const createEnquiryId =
  (
    callback: Function,
    data: Object,
    isPreassessment: boolean = true,
    saveUpdatedEnquiryId: boolean = false
  ) =>
  (dispatch: Function, getState) => {
    const creationTime = moment().format('YYYY-MM-DD')
    const {
      ure: { underwritingMethod },
      masterList: {
        data: { ureapiVersion },
      },
    } = getState()
    const branch =
      (ureapiVersion && ureapiVersion.find(item => item.code === SC_BRANCH_TAG).value) || ''
    const tag =
      (ureapiVersion &&
        ureapiVersion.find(version => version.code === URE_VERSION_OCCUPATIONS_RATING).value) ||
      ''
    dispatch({
      type: EXPERIENCE_API,
      name: CREATE_ENQUIRY_ID,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/enquiries?enrich=true${
        branch ? `&branch=${branch}` : ''
      }${tag ? `&tag=${tag}` : ''}`,
      ...(!isPreassessment ? { queuedAction: saveUrlForCallBack } : {}),
      ...(saveUpdatedEnquiryId && { queuedAction: saveQuote }),
      data,
      callback: (err, dataReceived) => {
        if (isPreassessment) dispatch(saveUrlForCallBack())
        if (callback) callback()
        return {
          dataReceived: {
            ...dataReceived,
            ...(underwritingMethod && !isPreassessment
              ? {
                  underwritingMethod,
                }
              : {}),
            creationTime,
          },
          isPreassessment,
        }
      },
    })
  }

// CREATE_CHILD_ENQUIRY_ID
export const createChildEnquiryId =
  (callback: Function, data: Object, childCI: object) =>
  (dispatch: Function, getState: Function) => {
    const creationTime = moment().format('YYYY-MM-DD')
    const {
      masterList: {
        data: { ureapiVersion },
      },
    } = getState()
    const branch = ureapiVersion.find(item => item.code === SC_BRANCH_TAG).value
    const tag = ureapiVersion.find(version => version.code === URE_VERSION_OCCUPATIONS_RATING).value
    dispatch({
      type: EXPERIENCE_API,
      name: CREATE_CHILD_ENQUIRY_ID,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/enquiries?enrich=true${
        branch ? `&branch=${branch}` : ''
      }${tag ? `&tag=${tag}` : ''}`,
      additionalHeaders: {
        creationTime,
        transactionId: `createEnquiryTransaction-${creationTime}`,
        trackingId: `createEnquiryTracking-${creationTime}`,
      },
      queuedAction: saveUrlForCallBack,
      data,
      callback: (err, dataReceived) => {
        if (callback) {
          callback()
        }
        return {
          dataReceived: {
            ...dataReceived,
            creationTime,
          },
          childCI,
        }
      },
    })
  }
export const getOutcomeEnquiry = () => (dispatch: Function, getState) => {
  const {
    ure: { activeEnquiryId },
    createQuote,
  } = getState()

  const hasPolicy = get(createQuote, 'quotes[0].policyStructure', []).length

  dispatch({
    type: EXPERIENCE_API,
    name: CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY,
    verb: 'GET',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/enquiries/${activeEnquiryId}/outcome`,
    callback: (err, dataReceived) => ({
      dataReceived,
    }),
    ...(hasPolicy && { queuedAction: createQuoteCalculate }),
  })
}

export const updateCoversInEnquiryId =
  (callback: Function, data: Object, isPreassessment: boolean = true) =>
  (dispatch: Function, getState: Function) => {
    const creationTime = moment().format('YYYY-MM-DD')
    const {
      createQuote: { activeIndex, quotes },
    } = getState()
    const activeQuote = quotes[activeIndex]
    const { enquiryId } = get(
      activeQuote,
      'underwritingDetails',
      get(activeQuote, 'preAssessmentDetails', {})
    )
    dispatch({
      type: EXPERIENCE_API,
      name: CREATE_ENQUIRY_ID,
      verb: 'PUT',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/enquiries/${enquiryId}/answers`,
      data,
      callback: (err, dataReceived) => {
        if (isPreassessment) dispatch(saveUrlForCallBack())
        if (callback) callback()

        return {
          dataReceived: {
            ...dataReceived,
            creationTime,
          },
          isPreassessment,
        }
      },
    })
  }

export const updateUnderwritingDetails = (data: Object) => ({
  type: UPDATE_UNDER_WRITING_DETAILS,
  payload: {
    data,
  },
})

export const removeEnquiryId = isPreassessment => ({
  type: REMOVE_ENQUIRY_ID_URE,
  payload: { isPreassessment },
})

export const updateEnquiryIdURE = (data: Object) => ({
  type: UPDATE_ENQUIRY_ID_URE,
  payload: data,
})

export const updateEnquiryId =
  (data: { callback: Function, isPreassessment?: boolean, payload: Object }) =>
  (dispatch: Function, getState: Function) => {
    const {
      ure: {
        initialData: { umeExpiryPeriod: expiryDuration },
        underwritingMethod,
      },
      createQuote,
      createQuote: { activeIndex },
      preAssessment: { loadings },
    } = getState()

    const { callback, isPreassessment = true, payload } = data
    const activeQuote = get(createQuote, `quotes[${activeIndex}]`)
    let underWritingKey
    if (isPreassessment) {
      underWritingKey = 'preAssessmentDetails'
    } else {
      const preAssessmentDetails = get(activeQuote, 'preAssessmentDetails', {})
      const underwritingDetails = get(activeQuote, 'underwritingDetails', {})
      if (
        typeof preAssessmentDetails.enquiryId !== 'undefined' &&
        typeof underwritingDetails.enquiryId === 'undefined'
      ) {
        // update UnderwritingDetails with preAssessmentDetails
        dispatch(updateUnderwritingDetails(preAssessmentDetails))
        underWritingKey = 'preAssessmentDetails'
      } else {
        underWritingKey = 'underwritingDetails'
      }
    }
    const { enquiryId, createdOn } = get(activeQuote, `${underWritingKey}`, {})

    // Child CI Enquiry ID Update
    if (!isPreassessment) {
      activeQuote.policyStructure.forEach((policy, policyIndex) => {
        policy.covers.forEach((cover, coverIndex) => {
          if (cover.type === 'Child_CI') {
            const { enquiryId: childCIEnquiryId, createdOn: childCICreatedOn } = get(
              cover,
              'childCIUnderwritingInfo',
              {}
            )
            if (!childCIEnquiryId || isEnquiyIdExpired(childCICreatedOn, expiryDuration)) {
              const childCI = {}
              childCI.policyStructurePosition = policyIndex
              childCI.coversPosition = coverIndex
              dispatch(createChildEnquiryId(callback, payload, childCI))
            }
          }
        })
      })
    }

    if (isPreassessment && loadings && loadings.length) {
      dispatch(showEnquiryIdExpiredModal())
      return
    }
    // If enquiryId does not exist
    // If personal statement flow i.e. full URE (retail)
    if (!isPreassessment) {
      // Get enquiryId of preassessment
      const { enquiryId: preAssessmentEnquiryId, createdOn: preAssessmentCreatedOn } = get(
        activeQuote,
        'preAssessmentDetails',
        get(activeQuote, 'underwritingDetails', {})
      )
      if (
        underwritingMethod &&
        enquiryId &&
        !isEnquiyIdExpired(preAssessmentCreatedOn, expiryDuration)
      ) {
        const quoteStatus = {
          actionName: null,
          quoteType: null,
          underwritingMethod,
        }
        dispatch({
          type: UPDATE_QUOTE_STATUS,
          payload: {
            quoteStatus,
          },
        })
      }
      if (callback && typeof callback === 'function') callback()
      // If preassessment enquiryId expired or does not exist,
      // call api to create enquiry ID else use the enquiryId
      if (isEnquiyIdExpired(createdOn, expiryDuration)) {
        dispatch(showEnquiryIdExpiredModal())
      } else if (!preAssessmentEnquiryId && !enquiryId) {
        dispatch(createEnquiryId(callback, payload, isPreassessment))
      } else if (
        preAssessmentEnquiryId &&
        !enquiryId &&
        !isEnquiyIdExpired(preAssessmentCreatedOn, expiryDuration)
      ) {
        dispatch(
          updateEnquiryIdURE({
            enquiryId: preAssessmentEnquiryId,
            createdOn: preAssessmentCreatedOn,
            ...(underwritingMethod
              ? {
                  underwritingMethod,
                }
              : {}),
          })
        )
        dispatch(updateCoversInEnquiryId(callback, payload, isPreassessment))
      }
    } else if (!enquiryId) {
      dispatch(createEnquiryId(callback, payload, isPreassessment))
    } else if (isEnquiyIdExpired(createdOn, expiryDuration)) {
      // Show enquiry ID expired modal
      dispatch(showEnquiryIdExpiredModal())
    } else {
      dispatch(updateCoversInEnquiryId(callback, payload, isPreassessment))
    }
  }

export const updateQuoteIDToAction = (quoteId: string) => ({
  type: UPDATE_QUOTE_ID_TO_ACTION,
  payload: {
    quoteId,
  },
})

export const initiateSaveQuote =
  (callback, identifier, quoteCollectionName, lifeInsuredFirstName, lifeInsuredLastName) =>
  (dispatch, getState) => {
    const { createQuote } = getState()
    const policyStructure = get(
      createQuote,
      `quotes[${createQuote.activeIndex}].policyStructure`,
      []
    )
    const hasCover =
      policyStructure.length && policyStructure.some(policy => !!policy.covers.length)

    // do not trigger action to update isSaveQuote if isSaveQuote is already true
    if (!createQuote.isSaveQuote) {
      dispatch(updateIsSaveQuote())
    }

    if (hasCover) {
      const isValidQuote = validateQuote(createQuote.quotes)
      if (isValidQuote) {
        // in case unnamed quote collection, open save quote modal
        if (createQuote.quoteCollectionName === DEFAULT_QUOTE_COLLECTION_NAME) {
          dispatch(showSaveQuoteModal(true, false, identifier))
          if (quoteCollectionName) {
            dispatch(
              saveQuote(
                callback,
                identifier,
                quoteCollectionName,
                lifeInsuredFirstName,
                lifeInsuredLastName
              )
            )
          }
        } else {
          // trigger action to save quote
          dispatch(
            saveQuote(
              callback,
              identifier,
              quoteCollectionName,
              lifeInsuredFirstName,
              lifeInsuredLastName
            )
          )
        }
      }
    } else {
      dispatch(showSaveQuoteErrorModal(true))
    }
  }

export const setSaveQuoteInitiator = (saveQuoteInitiator: string) => ({
  type: SET_SAVE_QUOTE_INITIATOR,
  payload: {
    saveQuoteInitiator,
  },
})

export const resetCreateQuote = () => ({
  type: RESET_CREATE_QUOTE,
})

export const createQuoteRemoveAppliedLoading = loadings => ({
  type: CREATE_QUOTE_REMOVE_APPLIED_LOADINGS,
  payload: {
    loadings,
  },
})

/**
 * Action creator to update follow up time data related to underwriting options
 * @param {Array} prefered Array represents the day and timings of following up info
 * @param {string} alternateContact optional contact number if other that personal
 * @return {function} - action creator
 */
export const updateUnderwritingFollowUpTimings = ({ preferred, alternateContact }) => ({
  type: CREATE_QUOTE_UPDATE_UNDERWRITING_FOLLOW_UP_TIMING,
  payload: {
    preferred,
    alternateContact,
  },
})

export const removeAllExclusionsInQuoteNApplyFromURE = exclusions => (dispatch, getState) => {
  const { ure: ureData } = getState()
  const benefitMasterData = get(ureData, 'initialData.umeBenefits', [])
  dispatch({
    type: CREATE_QUOTE_REMOVE_APPLIED_EXCLUSION,
  })
  dispatch({
    type: CREATE_QUOTE_APPLY_EXCLUSIONS,
    payload: {
      exclusions,
      benefitMasterData,
    },
  })
}

export const createQuoteApplyGeneticTestResult = result => ({
  type: CREATE_QUOTE_APPLY_GENETIC_TEST_RESULT,
  payload: {
    result,
  },
})

export const addBusinessAsPayer = formData => (dispatch, getState) => {
  const {
    abn,
    companyName,
    email,
    directors,
    isSoleDirector,
    residentialAddressCountry,
    residentialAddressHouseNo,
    residentialAddressLocality,
    residentialAddressPostCode,
    residentialAddressState,
    residentialAddressStreet,
    identifier,
    contactNumber,
    contactNumberCode,
    contactNumberCountryCode,
  } = formData
  const {
    sidebar: { panelProps: sidebarPanelProps },
  } = getState()

  dispatch({
    type: ADD_BUSINESS_AS_PAYER,
    payload: {
      sidebarPanelProps,
      abn,
      directors,
      memberEntity: {
        identifiers: [
          {
            type: REF_NO,
            value: identifier ? identifier.toString() : moment().unix().toString(),
          },
        ],
        businessName: companyName,
        companyIdentifier: COMPANY_IDENTIFIER_NGRP,
        abnNumber: abn,
        organizationType: SMSF_BUSINESS_ORGANIZATION_TYPE,
        contactMethods: {
          addresses: [
            {
              houseNo: residentialAddressHouseNo,
              street: residentialAddressStreet,
              locality: residentialAddressLocality,
              state: residentialAddressState,
              country: residentialAddressCountry || COUNTRY_CODE,
              postCode: residentialAddressPostCode.toString(),
              preferred: PREFERRED_YES,
              addressType: ADDRESS_TYPE_STATEMENT,
            },
          ],
          emails: [{ email, preferred: PREFERRED_YES, type: ADDRESS_TYPE_WORK }],
          phones: [
            {
              number: contactNumber,
              preferred: PREFERRED_YES,
              type: CONTACT_TYPES_TEL,
              subType: CONTACT_SUB_TYPES,
              idc: contactNumberCode,
              countryCode: contactNumberCountryCode,
            },
          ],
        },
        partyType: 'ORG',
      },
    },
  })
  dispatch(setPanelProps({ isSoleDirector }))
}

export const addOwnerForSuperWrapper = policyInstanceNo => dispatch => {
  const reqPayload = {
    type: ADD_OWNER_FOR_NULIS_WRAPPER,
    payload: {
      policyInstanceNo,
      beneficiaryObj: [
        {
          role: [POLICY_RELATIONSHIPS_OWNER],
          isPrimary: true,
          isAssociatedToSuperFund: true,
        },
      ],
    },
  }
  dispatch(reqPayload)
}

export const addNulis = formData => (dispatch, getState) => {
  const {
    abn,
    contributionType,
    tfn,
    employerName,
    nulisDeclarationTFN,
    nulisDeclarationMLCSuperFund,
    // Address fields
    residentialAddressHouseNo,
    residentialAddressStreet,
    residentialAddressLocality,
    residentialAddressState,
    residentialAddressCountry,
    residentialAddressPostCode,
    identifier,
  } = formData.forms.addNulis.fields

  const {
    sidebar: { panelProps: sidebarPanelProps },
  } = getState()

  const tfnval = get(tfn, 'value', '')
  const country =
    typeof residentialAddressCountry.value === 'object'
      ? residentialAddressCountry.value.value
      : residentialAddressCountry.value

  const addMemberEntity =
    contributionType.value.value === EMPLOYER_SG ||
    contributionType.value.value === SALARY_SACRIFICE ||
    contributionType.value.value === AWARD ||
    contributionType.value.value === EMPLOYER_VOLUNTARY ||
    contributionType.value === EMPLOYER_SG ||
    contributionType.value === SALARY_SACRIFICE ||
    contributionType.value === AWARD ||
    contributionType.value === EMPLOYER_VOLUNTARY

  const consents = [
    {
      name: 'TFN_SUPERANNUATION',
      value: nulisDeclarationTFN.value,
      description: 'TFN_SUPERANNUATION',
    },
    {
      name: 'MEMBER_MLC_SUPERFUND',
      value: nulisDeclarationMLCSuperFund.value,
      description: 'MEMBER_MLC_SUPERFUND',
    },
  ]

  const reqPayload = {
    type: ADD_NULIS,
    payload: {
      sidebarPanelProps,
      contributionType,
      consents,
      beneficiaryObj: [
        {
          role: [POLICY_RELATIONSHIPS_OWNER],
          isPrimary: true,
          isAssociatedToSuperFund: true,
        },
      ],
    },
  }

  if (addMemberEntity) {
    reqPayload.payload.beneficiaryObj.push({
      role: [POLICY_RELATIONSHIPS_EMPLOYER],
      isAssociatedToSuperFund: false,
      relatedParty: {
        identifiers: [
          {
            type: REF_NO,
            value: identifier ? identifier.toString() : moment().unix().toString(),
          },
        ],
        companyIdentifier: COMPANY_IDENTIFIER_NGRP,
        businessName: employerName.value,
        abnNumber: abn.value,
        partyType: POLICY_PARTY_TYPE_BUSINESS,
        contactMethods: {
          addresses: [
            {
              houseNo: residentialAddressHouseNo.value,
              street: residentialAddressStreet.value,
              locality: residentialAddressLocality.value,
              state: residentialAddressState.value,
              country: country || COUNTRY_CODE,
              postCode: residentialAddressPostCode.value.toString(),
              preferred: PREFERRED_YES,
              addressType: ADDRESS_TYPE_HOME,
            },
          ],
        },
      },
      companies: [],
    })
  }

  const tfnRequestPayload = {
    type: ADD_TFN,
    payload: {
      tfnval,
      sidebarPanelProps,
    },
  }

  dispatch(reqPayload)
  dispatch(tfnRequestPayload)
}

// update policy commencement date.
export const updatePolicyCommencementDate =
  (policyCommencementDate, activePolIndex) => dispatch => {
    dispatch({
      type: UPDATE_POLICY_COMMENCEMENT_DATE,
      payload: {
        policyCommencementDate,
        activePolIndex,
      },
    })
  }
export const createQuoteChangeSmokerStatus = (currentSmokerStatus: boolean) => dispatch => {
  if (typeof currentSmokerStatus !== 'undefined') {
    const acceptableSmokerStatus = currentSmokerStatus ? 'Yes' : 'No'
    dispatch(
      formUpdateField(CAPTURE_CLIENT_FORM_ID, 'isSmoker', {
        error: false,
        value: acceptableSmokerStatus,
      })
    )
    dispatch({
      type: CREATE_QUOTE_CHANGE_SMOKER_STATUS,
      payload: {
        currentSmokerStatus: acceptableSmokerStatus,
      },
    })
  }
}

export const updateUREDetailToUnderwriterEntity = (underwriterEntityData: Object = {}) => ({
  type: UPDATE_URE_DETAIL_TO_UNDERWRITING_ENTITY,
  payload: {
    data: underwriterEntityData,
  },
})

export const updateWorkTypeHistory = data => ({
  type: UPDATE_WORKTYPE_HISTORY,
  payload: {
    data,
  },
})

export const deleteWorkTypeHistory = () => ({
  type: DELETE_WORKTYPE_HISTORY,
})

export const updateIsTeleFromAdviser = (isTeleFromAdviser: boolean = false) => ({
  type: UPDATE_IS_TELE_FROM_ADVISER,
  payload: {
    isTeleFromAdviser,
  },
})

export const getQuoteForManual = quoteId => ({
  type: EXPERIENCE_API,
  name: QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/${quoteId}`,
  queuedAction: convertCoversToFeatures,
})

export const updateSmsfDetails =
  ({ smsfTrusteeData: smsfTrusteeInfo, smsfDetailsData: smsfDetailsInfo }) =>
  (dispatch, getState) => {
    const { sidebar } = getState()
    const smsfDetailsAddress = isDirector => ({
      houseNo: get(smsfDetailsInfo, 'residentialHouseNo', ''),
      street: get(smsfDetailsInfo, 'residentialStreet', ''),
      locality: get(smsfDetailsInfo, 'residentialLocality', ''),
      state: get(smsfDetailsInfo, 'residentialState', ''),
      addressType: isDirector ? ADDRESS_TYPE_STATEMENT : ADDRESS_TYPE_HOME,
      country: get(smsfDetailsInfo, 'residentialCountry', '') || COUNTRY_CODE,
      postCode: get(smsfDetailsInfo, 'residentialPostCode', '').toString(),
      preferred: PREFERRED_YES,
    })

    const smsfCompanyAddress = isDirector => ({
      houseNo: get(smsfTrusteeInfo, 'companyHouseNo', ''),
      street: get(smsfTrusteeInfo, 'companyStreet', ''),
      locality: get(smsfTrusteeInfo, 'companyLocality', ''),
      state: get(smsfTrusteeInfo, 'companyState', ''),
      addressType: isDirector ? ADDRESS_TYPE_STATEMENT : ADDRESS_TYPE_HOME,
      country: get(smsfTrusteeInfo, 'companyCountry', '') || COUNTRY_CODE,
      postCode: get(smsfTrusteeInfo, 'companyPostCode', '').toString(),
      preferred: PREFERRED_YES,
    })

    dispatch({
      type: CREATE_QUOTE_UPDATE_SMSF_DETAILS,
      payload: {
        relationship: {
          fundDetails: {
            fundRegulated: get(smsfDetailsInfo, 'regulatory', ''),
          },
          relatedParty: {
            identifiers: [
              {
                type: REF_NO,
                value: smsfDetailsInfo.identifier
                  ? smsfDetailsInfo.identifier.toString()
                  : moment().unix().toString(),
              },
            ],
            companyIdentifier: COMPANY_IDENTIFIER_NGRP,
            partyType: POLICY_PARTY_TYPE_BUSINESS,
            businessName: get(smsfDetailsInfo, 'superannuationFundName', ''),
            abnNumber: get(smsfDetailsInfo, 'abn', ''),
            organizationType: SMSF_ORGANIZATION_TYPE,
            contactMethods: {
              emails: [
                {
                  email: get(smsfDetailsInfo, 'email', ''),
                  preferred: PREFERRED_YES,
                  type: ADDRESS_TYPE_HOME,
                },
              ],
              addresses: [smsfDetailsAddress(true)],
            },
          },
          companies:
            smsfTrusteeInfo && smsfTrusteeInfo.directors && smsfTrusteeInfo.directors.length
              ? [
                  {
                    isPrimary: true,
                    relatedParty: {
                      identifiers: [
                        {
                          type: REF_NO,
                          value: smsfTrusteeInfo.identifier
                            ? smsfTrusteeInfo.identifier.toString()
                            : (moment().unix() + 60).toString(),
                        },
                      ],
                      companyIdentifier: COMPANY_IDENTIFIER_NGRP,
                      businessName: get(smsfTrusteeInfo, 'companyName', ''),
                      abnNumber: get(smsfDetailsInfo, 'abn', ''),
                      partyType: POLICY_PARTY_TYPE_BUSINESS,
                      organizationType: SMSF_BUSINESS_ORGANIZATION_TYPE,
                      contactMethods: {
                        phones: [
                          {
                            number: get(smsfTrusteeInfo, 'contactNumber', ''),
                            preferred: PREFERRED_YES,
                            type: CONTACT_TYPES_TEL,
                            subType: CONTACT_SUB_TYPES,
                            idc: get(smsfTrusteeInfo, 'contactNumberCode', ''),
                            countryCode: get(smsfTrusteeInfo, 'contactNumberCountryCode', ''),
                          },
                        ],
                        emails: [
                          {
                            email: get(smsfTrusteeInfo, 'companyEmail', ''),
                            preferred: PREFERRED_YES,
                            type: ADDRESS_TYPE_WORK,
                          },
                        ],
                        addresses: [smsfCompanyAddress(true)],
                      },
                    },
                    directors: get(
                      smsfTrusteeInfo,
                      'directors',
                      get(smsfTrusteeInfo, 'individuals', [])
                    ).map(director => {
                      const finalDirectorData = {
                        isSecretary: director.relatedParty.companyRole !== POLICY_MEMBER_DIRECTOR,
                        ...director,
                      }

                      if (director.relatedParty.sameAsCompanyAddress) {
                        director.relatedParty.contactMethods.addresses = [smsfCompanyAddress(true)]
                      }
                      delete director.relatedParty.sameAsCompanyAddress
                      return finalDirectorData
                    }),
                  },
                ]
              : smsfTrusteeInfo.individuals.map(({ relatedParty, isPrimary }) => {
                  if (relatedParty.sameAsCompanyAddress) {
                    relatedParty.contactMethods.addresses = [smsfDetailsAddress(false)]
                  }
                  return {
                    relatedParty,
                    isPrimary,
                  }
                }),
        },
        sidebar,
      },
    })
  }
export const updateCalculateUreStatus = status => ({
  type: CREATE_QUOTE_UPDATE_CALCULATED_URE_STATUS,
  payload: {
    status,
  },
})

export const resetQuoteErrorModal = () => ({
  type: RESET_CREATE_AND_SAVE_QUOTE_ERROR_MODAL,
})

export const updatedPolicyReplaceStatus = (policyIdx, benefitIdx, value) => ({
  type: UPDATE_POLICY_REPLACE_STATUS,
  payload: {
    policyIdx,
    benefitIdx,
    value,
  },
})

export const updateByPushToExisitingCover = existingCoverArray => dispatch => {
  dispatch({
    type: CREATE_QUOTE_PUSH_EXISTING_COVER,
    payload: {
      existingCoverArray,
    },
  })
}

export const createQuoteUpdateTpdDefination = ({
  policyInstanceNo,
  benefitInstanceNo,
  type,
  value,
}) => ({
  type: CREATE_QUOTE_UPDATE_TPD_DEFINATION,
  payload: {
    data: {
      policyInstanceNo,
      benefitInstanceNo,
      type,
      value,
    },
  },
})

export const createQuoteUpdateIsNonStandard = isNonStandard => ({
  type: CREATE_QUOTE_UPDATE_IS_NON_STANDARD,
  payload: {
    isNonStandard,
  },
})

export const createQuoteUpdateBMI = BMIValue => ({
  type: CREATE_QUOTE_UPDATE_BMI,
  payload: {
    BMIValue,
  },
})

export const createQuoteSetIsSatisfied = isSatisfied => (dispatch, getState) => {
  const {
    createQuote: { quotes, activeIndex },
  } = getState()
  const hasPSInitiated = get(quotes, `[${activeIndex}].underwritingDetails.enquiryId`, false)
  if (hasPSInitiated) {
    dispatch({
      type: CREATE_QUOTE_SET_IS_SATISFIED,
      payload: {
        isSatisfied,
      },
    })
  }
}

export const createQuoteIsTermsAcceptedCover = (isTermsAccepted, policyIndex, coverIndex) => ({
  type: UPDATE_IS_TERMS_ACCEPTED_ON_POLICY_COVER,
  payload: {
    isTermsAccepted,
    policyIndex,
    coverIndex,
  },
})

// action to update contact details for life insured in relationships array in quote entity
export const updateLifeInsuredContactDetails = ({ phone, email }: Object) => ({
  type: CREATE_QUOTE_UPDATE_LA_CONTACT_DETAILS,
  payload: {
    phone,
    email,
  },
})

export const addOptimerParentBenefitReference = (
  cover: Object,
  policyInstanceNo: string,
  optimiserParentBenefitReference: Object
) => ({
  type: CREATE_QUOTE_ADD_OPTIMISER_REFERENCE,
  payload: {
    cover,
    policyInstanceNo,
    optimiserParentBenefitReference,
  },
})

export const removeOptimiserParentBenefitReference = (cover: Object, policyInstanceNo: string) => ({
  type: CREATE_QUOTE_REMOVE_OPTIMISER_REFERENCE,
  payload: {
    cover,
    policyInstanceNo,
  },
})

export const updateLinkedOptimiser = (cover: Object) => ({
  type: CREATE_QUOTE_UPDATE_LINKED_OPTIMISER,
  payload: {
    cover,
  },
})

// Action to update items in linked benefits(parentBenefitReference and
// optimiserParentBenefitReference)
// e.g. when CI_PLUS changed to CI_STD
export const updateLinkedBenefits = (
  cover: Object,
  policyInstanceNo: string,
  oldCover: Object
) => ({
  type: CREATE_QUOTE_UPDATE_LINKED_BENEFITS,
  payload: {
    cover,
    policyInstanceNo,
    oldCover,
  },
})

export const updateQuoteCollection = (quoteCollection = Object, isSaveQuote: boolean = false) => ({
  type: CREATE_QUOTE_UPDATE_QUOTE_COLLECTION,
  payload: {
    quoteCollection,
  },
  ...(isSaveQuote && { queuedAction: saveQuote }),
})

export const clearPolicySetupData = policyInstanceNo => ({
  type: CREATE_QUOTE_CLEAR_POLICY_SETUP_DATA,
  payload: { policyInstanceNo },
})

export const prePopulateMandatoriesWithExistingClient =
  (bancsCustomerNumber?: String) =>
  (dispatch: Function): Object => {
    dispatch({
      type: EXPERIENCE_API,
      name: PRE_POPULATE_MANDATORIES,
      verb: 'GET',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${
        bancsCustomerNumber || getBancsCustomerNumber()
      }`,
      callback: (err, dataReceived) => {
        const member = get(dataReceived, 'businessData.member', {})
        if (member) {
          const gender = get(member, 'gender', '')
          const isSmoker = get(member, 'isSmoker', '')
          const dateOfBirth = moment(get(member, 'dateOfBirth', '')).format(CLIENT_SIDE_DATE_FORMAT)
          const stateOfResidence = getPreferredAddress(
            get(member, 'contactMethods.addresses', [])
          ).state
          const firstName = get(member, 'firstName', '')
          const lastName = get(member, 'lastName', '')
          const employmentStatus = get(member, 'employment.employmentStatus', '')
          const dataToInit = {
            gender,
            isSmoker,
            dateOfBirth,
            stateOfResidence,
            employmentStatus,
          }
          dispatch(formInit(CAPTURE_CLIENT_FORM_ID, CAPTURE_CLIENT_SCHEMA, dataToInit))
          dispatch(setLifeAssuredName(firstName, lastName))
        }
        // pass subset of response, or during error pass whole payload down.
        return dataReceived.businessData ? dataReceived.businessData.member : dataReceived
      },
    })
  }
export const resetExistingClient = () => ({
  type: CLEAR_PRE_EXISTING_MANDATORIES,
})

export const updateSubmittedDate = submittedDate => ({
  type: UPDATE_SUBMITTED_DATE,
  payload: { submittedDate },
})

export const updateMemberMandatories = (memberMandatories, role, policyInstanceNo = '0') => ({
  type: CREATE_QUOTE_UPDATE_MEMBER_DETAILS,
  payload: {
    memberEntityStructure: memberMandatories,
    role,
    policyInstanceNo,
  },
})

export const updateMemberMandatoriesV1 = memberMandatories => ({
  type: UPDATE_MEMBER_MANDATORIES,
  payload: {
    memberMandatories,
  },
})

export const setRequestedBy = requestedBy => ({
  type: CREATE_QUOTE_SET_REQUESTED_BY,
  payload: {
    requestedBy,
  },
})

export const setAlterationType = alterationType => ({
  type: CREATE_QUOTE_SET_ALTERATION_TYPE,
  payload: {
    alterationType,
  },
})

export const setQuoteCollectionId = quoteCollectionId => ({
  type: CREATE_QUOTE_SET_QUOTE_COLLECTION_ID,
  payload: {
    quoteCollectionId,
  },
})

export const setAdviserNumber = adviserNumber => ({
  type: CREATE_QUOTE_SET_ADVISER_NUMBER,
  payload: {
    adviserNumber,
  },
})

export const setApplicationdetails = applicationDetails => ({
  type: CREATE_QUOTE_SET_APPLICATION_DETAILS,
  payload: {
    applicationDetails,
  },
})

export const setQuoteCustomerNo = quoteCustomerNo => ({
  type: CREATE_QUOTE_SET_QUOTE_CUSTOMER_NO,
  payload: {
    quoteCustomerNo,
  },
})

// this looks a little weird but essentially when re-entering an alteration from
// the quotes dashboard we need to flip the calculatePolicyPrice flag to true for
// each policy that has an alteration in order to re-calculate the alteration
export const calculateAlteredPoliciesInQuote = () => (dispatch, getState) => {
  const { createQuote } = getState()
  const { quotes } = createQuote
  const alteredBancsPolicyNos = quotes[0].policyStructure
    .map(policy => {
      if (policy?.alteration?.isPolicyAltered) {
        return policy.bancsPolicyNo
      }
      return false
    })
    .filter(Boolean)

  const dispatchAction = responseData => {
    // we either use what we have in state or what is returned from the previous
    // call in order to not blow away state
    const modifiedQuote = responseData ? responseData?.businessData : createQuote
    const bancsPolicyNo = alteredBancsPolicyNos[0]
    if (bancsPolicyNo) {
      const data = {
        ...modifiedQuote,
        quotes: [
          {
            ...modifiedQuote.quotes[0],
            policyStructure: modifiedQuote.quotes[0].policyStructure.map(policy => {
              if (policy.bancsPolicyNo === bancsPolicyNo) {
                return {
                  ...policy,
                  alteration: {
                    ...policy.alteration,
                    calculatePolicyPrice: true,
                  },
                }
              }
              return policy
            }),
          },
        ],
      }
      dispatch({
        verb: 'POST',
        type: EXPERIENCE_API,
        name: ALTS_CALCULATE_QUOTE,
        route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/alterations`,
        data,
        callback: (err, dataReceived) => {
          // Shift out the bancsPolicyNo we just used and recursivelly call dispatchAction
          alteredBancsPolicyNos.shift()
          dispatchAction(dataReceived)
          if (!err) {
            return {
              ...dataReceived,
              activeIndex: createQuote.activeIndex,
            }
          }

          return dataReceived
        },
      })
    }
  }
  dispatchAction()
}

export const altsCalculateQuote = callback => (dispatch, getState) => {
  const { createQuote, alterations } = getState()
  const { sumInsuredErrors } = alterations
  const { quotes } = createQuote
  const hasAnyPolicyAltered = quotes[0].policyStructure.some(
    policy => policy.alteration?.calculatePolicyPrice
  )
  const isError = Object.values(sumInsuredErrors).some(item => item)
  if (hasAnyPolicyAltered && !isError) {
    dispatch({
      verb: 'POST',
      type: EXPERIENCE_API,
      name: ALTS_CALCULATE_QUOTE,
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/alterations`,
      data: createQuote,
      callback: (err, dataReceived) => {
        if (callback) {
          callback(err, dataReceived)
        }
        if (!err) {
          return {
            ...dataReceived,
            activeIndex: createQuote.activeIndex,
          }
        }
        return dataReceived
      },
    })
  }
}

export const resetRemoveSumInsured = (
  cover,
  bancsPolicyNo,
  policyInstanceNo,
  isPremiumCalculator
) => ({
  type: RESET_SUM_INSURED,
  payload: {
    cover,
    bancsPolicyNo,
    policyInstanceNo,
    isPremiumCalculator,
  },
})

export const altsRemoveBenefit = (cover, bancsPolicyNo, policyInstanceNo) => ({
  type: ALTS_REMOVE_BENEFIT,
  payload: {
    cover,
    bancsPolicyNo,
    policyInstanceNo,
  },
})

export const alterSumInsured =
  (cover, sumInsured, bancsPolicyNo, policyInstanceNo, isPremiumCalculator) => dispatch => {
    if (sumInsured === '0') {
      // In premium calculator, we are not allowing customer to remove benefit,
      // will update 0 in quote entity and show error below the field
      if (isPremiumCalculator) {
        dispatch({
          type: ALTER_SUM_INSURED,
          payload: {
            cover,
            sumInsured,
            bancsPolicyNo,
            policyInstanceNo,
            isPremiumCalculator,
          },
        })
      } else {
        dispatch(altsRemoveBenefit(cover, bancsPolicyNo, policyInstanceNo))
      }
    } else if (parseInt(cover.coverAmount, 10).toString() === sumInsured) {
      dispatch(resetRemoveSumInsured(cover, bancsPolicyNo, policyInstanceNo, isPremiumCalculator))
    } else {
      dispatch({
        type: ALTER_SUM_INSURED,
        payload: {
          cover,
          sumInsured,
          bancsPolicyNo,
          policyInstanceNo,
          isPremiumCalculator,
        },
      })
    }
  }

export const deleteAlterationsInQuote = () => ({
  type: DELETE_ALTERATIONS_IN_QUOTE,
})

export const removeBenefitOption = (cover, bancsPolicyNo, featureName, policyInstanceNo) => ({
  type: REMOVE_BENEFIT_OPTION,
  payload: {
    cover,
    bancsPolicyNo,
    featureName,
    policyInstanceNo,
  },
})

export const cancelBenefitOption = (cover, bancsPolicyNo, featureName, policyInstanceNo) => ({
  type: CANCEL_BENEFIT_OPTION,
  payload: {
    cover,
    bancsPolicyNo,
    featureName,
    policyInstanceNo,
  },
})

export const alterPaymentFrequency = (newPremiumPayingFrequency, bancsPolicyNo) => ({
  type: ALTER_PAYMENT_FREQUENCY,
  payload: {
    newPremiumPayingFrequency,
    bancsPolicyNo,
  },
})

export const resetPaymentFrequency = bancsPolicyNo => ({
  type: RESET_PAYMENT_FREQUENCY,
  payload: {
    bancsPolicyNo,
  },
})

export const createQuoteUpdateOption = option => ({
  type: CREATE_QUOTE_UPDATE_OPTION,
  payload: {
    option,
  },
})

export const createQuoteUpdateCoversCampaign = healthyLivingDiscountObject => ({
  type: CREATE_QUOTE_UPDATE_HL_CAMPAIGN,
  payload: healthyLivingDiscountObject,
})

export const setHealthyLivingDiscountConsent = isVivoIncentiveDiscountApplied => ({
  type: CREATE_QOUTE_SET_HEALTHY_LIVING_DISCOUNT_CONSENT,
  payload: {
    isVivoIncentiveDiscountApplied,
  },
})

export const createQuoteAddHealthyLivingDiscountInMissingRequirements = () => ({
  type: CREATE_QUOTE_ADD_HEALTHY_LIVING_DISCOUNT_IN_MISSING_REQUIREMENTS,
})

export const changeWaitingBenefitPeriod = (
  cover,
  bancsPolicyNo,
  value,
  isBenefitPeriod = false
) => ({
  type: CHANGE_WAITING_BENEFIT_PERIOD,
  payload: {
    cover,
    bancsPolicyNo,
    value,
    isBenefitPeriod,
  },
})

export const resetWaitingBenefitPeriodData = (cover, bancsPolicyNo, isBenefitPeriod) => ({
  type: RESET_WAITING_BENEFIT_PERIOD,
  payload: {
    cover,
    bancsPolicyNo,
    isBenefitPeriod,
  },
})

export const resetStateForNewQuote = (adviserNumber: String, bancsAdviserCustomerNo: String) => ({
  type: RESET_STATE_FOR_NEW_QUOTE,
  payload: {
    adviserNumber,
    bancsAdviserCustomerNo,
  },
})

export const updateAltsEffectiveDates = () => ({
  type: UPDATE_ALTS_EFFECTIVE_DATES,
})

export const refreshAltsQuote = () => (dispatch, getState) => {
  const state = getState()
  const { masterList, config, createQuote } = state
  const eligiblePolicies = getEligiblePolicies(ALTERATION_TYPES.DECREASE_RISK)(state)
  const rulesForAlterationPolicies = getRulesForAlterationPolicies(state)
  const benefitStatus = get(masterList, 'data.benefitStatus', [])
  const site = config && config.SITE
  const memberMandatories = makeMemberMandatoriesFromAlterations(state)
  // Create the policyStructure
  let policyStructure = constructPolicyStructure(
    eligiblePolicies,
    benefitStatus,
    rulesForAlterationPolicies,
    ALTERATION_TYPES.DECREASE_RISK,
    site
  )
  policyStructure = mergeAltsInPolicyStructure(
    policyStructure,
    createQuote.quotes[createQuote.activeIndex].policyStructure
  )
  dispatch(createQuoteSetPolicyStructure(policyStructure))
  // Set the memberMandatories within createQuote
  dispatch(updateMemberMandatoriesV1(memberMandatories))
  // Updating policyAlterationEffectiveDate for altered policies
  dispatch(updateAltsEffectiveDates())
}

export const createAltsQuote =
  ({ alterationType }) =>
  (dispatch, getState) => {
    const state = getState()
    const { advisor, config, masterList } = state
    const { firstName, lastName } = makeAltsLifeInsuredNameAndPartyNo(state)
    const bancsCustomerNoOfLA = getBancsCustomerNoOfLAFromAlterations(state)
    const rulesForAlterationPolicies = getRulesForAlterationPolicies(state)
    const eligiblePolicies = getEligiblePolicies(alterationType)(state)
    const memberMandatories = makeMemberMandatoriesFromAlterations(state)
    const adviserNumber = makeAdviserNo(state)
    const site = config && config.SITE
    const benefitStatus = get(masterList, 'data.benefitStatus', [])
    const updatePrintableAdviserDetailsPayload = getUpdatePrintableAdviserDetailsPayload(state)

    // resetting create quote state before updating it for alterations
    const adviserNumberFromAdvisorState = get(
      advisor,
      'details.customerDetails.customerNo',
      undefined
    )
    dispatch(resetAllSavedState(adviserNumberFromAdvisorState, getBancsCustomerNumber()))

    dispatch(
      setQuoteCustomerNo(site === ADVISOR_PORTAL ? bancsCustomerNoOfLA : getBancsCustomerNumber())
    )
    dispatch(setQuoteName('Quote#1'))

    const quoteCollectionName = `${firstName} ${lastName}`
    dispatch(setQuoteCollectionName(quoteCollectionName))

    // Set applicationType = 'DigiAlt'
    dispatch(updateApplicationType(APPLICATION_TYPES.DigiAlt))

    // Set quoteId, quoteCollectionId = '' (by default '')
    dispatch(setQuoteCollectionId(''))
    // Set quoteIDToAction as ''
    dispatch(updateQuoteIDToAction(''))
    // reset quoteID and internalQuoteCollectionId to ''
    dispatch(resetQuoteIdfromQuoteEntity())

    // Set action = 'SAVE_DIGITAL_ALTERATION' & quoteType = 'DRAFT_APPLN' for rejectCPI
    // Set actionName based on type for decrease cover
    if (alterationType === ALTERATION_TYPES.DECREASE_RISK) {
      dispatch(
        updateQuoteStatus({
          actionName: ACTION_TYPES.SAVE_QUOTE_COLLECTION,
          quoteType: QUOTE_STATUS_APPLICATION_STAGE,
          underwritingMethod: null,
        })
      )
    } else {
      dispatch(
        updateQuoteStatus({
          actionName: ACTION_TYPES.SAVE_DIGITAL_ALTERATION,
          quoteType: QUOTE_STATUS_APPLICATION_STAGE,
          underwritingMethod: null,
        })
      )

      const matchingRuleForPolicy =
        get(rulesForAlterationPolicies, 'businessData.policies', []).find(
          el => el.bancsPolicyNo === get(eligiblePolicies[0], 'bancsPolicyNo')
        ) || {}

      const escalationDate = get(matchingRuleForPolicy, 'assesment.rejectCPI_EscalationDate', '')

      dispatch(createQuoteSetAlterationEffectiveDate(escalationDate))
    }

    const requestedBy = site === ADVISOR_PORTAL ? REQUESTED_BY.AGNT : REQUESTED_BY.OWR
    dispatch(setRequestedBy(requestedBy))

    // Set the AlterationType using sitecore param
    dispatch(setAlterationType(alterationType))

    // TODO: read this value from get Rules API: policyInfo_todaysDate
    const { systemInfo_todaysDate: policyInfoTodaysDate } = makePolicyAssessment
    dispatch(updateSubmittedDate(policyInfoTodaysDate))

    // Create the policyStructure
    const policyStructure = constructPolicyStructure(
      eligiblePolicies,
      benefitStatus,
      rulesForAlterationPolicies,
      alterationType,
      site
    )
    dispatch(createQuoteSetPolicyStructure(policyStructure))
    // Set the memberMandatories within createQuote
    dispatch(updateMemberMandatoriesV1(memberMandatories))

    // Set Advier Number at quote collection entity level
    dispatch(setAdviserNumber(adviserNumber))

    let advisorBancsCustomerNo
    if (site === CUSTOMER_PORTAL) {
      advisorBancsCustomerNo = get(getAgentFromPolicies(eligiblePolicies), 'bancsCustomerNo', '')
    }
    dispatch(updateBancsCustomerNumber(advisorBancsCustomerNo))

    // Set only overallApplicationOutcome under applicationDetails
    const applicationDetails = { overallApplicationOutcome: ACCEPTED }
    dispatch(setApplicationdetails(applicationDetails))
    dispatch(updatePrintableAdviserDetails(updatePrintableAdviserDetailsPayload))
  }

/**
 * Action creator to update the flag to mark the progress of download quote api
 * @param {boolean} isDownloadQuoteInProgress true/false to show/hide save and exit modal
 * @return {function} - action creator
 */
export const updateDownloadQuoteProgress = isDownloadQuoteInProgress => ({
  type: UPDATE_ALTS_DOWNLOAD_QUOTE_PROGRESS,
  payload: {
    isDownloadQuoteInProgress,
  },
})

/**
 * Action creator to update the medical authority consents in the consents array
 * @param {boolean} MAOne
 * @param {string} MATwo
 * @returns
 */
export const updateMedicalAuthorityConsents = (MAOne, MATwo) => ({
  type: UPDATE_MEDICAL_AUTHORITY_CONSENTS,
  payload: {
    MAOne,
    MATwo,
  },
})

/**
 * Action creator to update contact details for alterations in customer portal
 * @param {Object} data consisting of preferredContactNumber and preferredContactTime
 * @returns {function} - action creator
 */
export const updateDetailsForContact = data => ({
  type: UPDATE_DETAILS_FOR_CONTACT,
  payload: data,
})

/**
 * Action to request quick quote api
 * @param {function} successCallback callback for success case
 * @returns {function} - action creator
 */
export const createQuoteQuickQuote =
  successCallback =>
  (dispatch, getState): Object => {
    const {
      forms,
      createQuote: { activeIndex, quotes },
      advisor: { details: advisorDetails },
      productRules: { data: productRulesData },
    } = getState()

    const quote = quotes[activeIndex]
    const { memberMandatories, policyStructure, option } = quote
    // set the next button isLoading value to true.
    dispatch(setIsNextBtnLoader(true))

    // call the experience api to request quick quote.
    dispatch({
      type: EXPERIENCE_API,
      name: CREATE_QUOTE_QUICK_QUOTE,
      verb: 'POST',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quickQuote`,
      data: buildQuickQuoteSumInsuredBody(forms.quickQuoteSumInsured.fields),
      additionalHeaders: { version: 'v2' },
      queuedAction: createQuoteCalculate,
      callback: (err, dataReceived) => {
        // set the next button isLoading value to false.
        dispatch(setIsNextBtnLoader(false))
        toast.dismiss(TOAST_ID_QUICK_QUOTE)

        if (err) {
          toast(TOAST_TITLE_QUICK_QUOTE_ERROR, {
            autoClose: 3000,
            toastId: TOAST_ID_QUICK_QUOTE,
            type: toast.TYPE.ERROR,
          })
          return {
            dataReceived,
          }
        }

        toast(TOAST_TITLE_QUICK_QUOTE_SUCCESS, {
          autoClose: 3000,
          toastId: TOAST_ID_QUICK_QUOTE,
          type: toast.TYPE.SUCCESS,
        })

        const quickQuotePoliciesRes = dataReceived?.businessData?.policies ?? []
        const updatedReceivedQuote = {
          ...quote,
          policyStructure: policyStructure.reduce((allStructure, policy) => {
            const { productId, policyInstanceNo, covers } = policy
            // get product by productId
            const productData = getProduct(productRulesData, productId)

            const policyToUpdate = quickQuotePoliciesRes.find(
              p => p.productId === productId && p.policyInstanceNo === policyInstanceNo
            )

            // generate covers based on response from quick quote
            const updatedCoverFromQuickQuote = policyToUpdate.covers.reduce((acc, cur) => {
              const { type, benefitInstanceNo, name, coverAmount, parentBenefitReference } = cur
              const coverToUpdate = covers.find(
                c => c.type === type && c.benefitInstanceNo === benefitInstanceNo && c.name === name
              )
              const isPTDorCI = [POLICY_BENEFIT_CI_PLUS, POLICY_BENEFIT_PTD].includes(type)

              return [
                ...acc,
                {
                  ...coverToUpdate,
                  ...(coverToUpdate
                    ? { coverAmount }
                    : {
                        ...convertCoverToEntityStructure(
                          productData.benefits.find(benefit => benefit.benefitCode === type),
                          covers.length,
                          advisorDetails,
                          {},
                          policyStructure,
                          memberMandatories,
                          option,
                          policy
                        ),
                        coverAmount,
                        benefitInstanceNo,
                      }),
                  sumAssuredApplicable: 'ON',
                  ...(!parentBenefitReference && isPTDorCI
                    ? { parentBenefitReference: undefined }
                    : parentBenefitReference && {
                        parentBenefitReference: {
                          ...coverToUpdate?.parentBenefitReference,
                          ...parentBenefitReference,
                          parentPolicyReferenceNo:
                            parentBenefitReference.parentPolicyReferenceNo.toString(),
                          parentBenefitInstanceNo:
                            parentBenefitReference.parentBenefitInstanceNo.toString(),
                        },
                      }),
                },
              ]
            }, [])

            if (policyToUpdate) {
              return [
                ...allStructure,
                {
                  ...policy,
                  covers: [
                    ...updatedCoverFromQuickQuote,
                    ...covers.filter(
                      c =>
                        !policyToUpdate.covers.find(
                          qqCover =>
                            qqCover.type === c.type &&
                            qqCover.benefitInstanceNo === c.benefitInstanceNo
                        )
                    ),
                  ],
                },
              ]
            }

            return allStructure
          }, []),
        }

        successCallback(dispatch)

        return {
          quote: updatedReceivedQuote,
          quoteIndex: activeIndex,
        }
      },
    })
  }
