/* eslint-disable no-unused-vars */
// @flow
import React from 'react'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// utils.
import {
  getAllDiscountCodes,
  isDiscountQuoteWide,
  getBenefitsFromDiscount,
  getDiscountAmount,
  getDiscountNameFromProductData,
} from '../../../../../../utils/quoteUtils'

// constants.
import { DISCOUNT_TYPES_TO_DISPLAY } from '../../../../../../constants/discounts'

// styles.
import styles from './quoteDiscounts.styles'

// styled components.
const Wrapper = styled('div')(styles.wrapper)
const Discount = styled('div')(styles.discount)

type QuoteDiscountsProps = {
  // sitecore fields to display.
  fields: {
    // discount message part 1.
    quoteDiscountMessagePrefix: Object,
    // discount message part 2a (quote-wide).
    quoteDiscountMessageAppliedToQuote: Object,
    // discount message part 2b (benefit-specific).
    quoteDiscountMessageAppliedToBenefit: Object,
    // discount message part 3 (benefit-specific).
    quoteDiscountMessageSuffix: Object,
  },
  // policies array from quote object.
  policyStructure: Array<Object>,
  // product data from product rules.
  productData: Array<Object>,
  // custom styles to apply.
  styles?: {
    wrapper?: Object,
    discount?: Object,
  },
}

const QuoteDiscounts = (props: QuoteDiscountsProps) => {
  const {
    policyStructure,
    productData,
    fields: {
      quoteDiscountMessagePrefix,
      quoteDiscountMessageAppliedToQuote,
      quoteDiscountMessageAppliedToBenefit,
      quoteDiscountMessageSuffix,
    },
    styles: { wrapper, discount },
  } = props

  // generate array of discount types to display.
  const discountTypes = getAllDiscountCodes(policyStructure).filter(discountType =>
    DISCOUNT_TYPES_TO_DISPLAY.includes(discountType)
  )

  return (
    <Wrapper css={wrapper}>
      {discountTypes.map(discountType => {
        // seems a pointless check, but it shuts Flow up
        if (!discountType) {
          return null
        }

        // render a quote-wide discount message
        if (isDiscountQuoteWide(discountType, policyStructure)) {
          return (
            <Discount key={discountType} css={discount}>
              <span>{getDiscountNameFromProductData(discountType, productData)} </span>
              <Text field={quoteDiscountMessagePrefix} />
              <span> {getDiscountAmount(discountType, policyStructure)} </span>
              <Text field={quoteDiscountMessageAppliedToQuote} />
            </Discount>
          )
        }

        // render a benefit(s)-specific discount message
        return (
          <Discount key={discountType} css={discount}>
            <span>{getDiscountNameFromProductData(discountType, productData)} </span>
            <Text field={quoteDiscountMessagePrefix} />
            <span> {getDiscountAmount(discountType, policyStructure)} </span>
            <Text field={quoteDiscountMessageAppliedToBenefit} />
            <span> {getBenefitsFromDiscount(discountType, policyStructure).join(', ')} </span>
            <Text field={quoteDiscountMessageSuffix} />
          </Discount>
        )
      })}
    </Wrapper>
  )
}

QuoteDiscounts.defaultProps = {
  styles: {
    wrapper: {},
    discount: {},
  },
}

export default QuoteDiscounts
