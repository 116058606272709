// @flow
// shared action creators.
import * as addressLookupActions from './creators/addressLookup'
import * as formActions from './creators/forms'
import * as navigationActions from './creators/navigation'
import * as oktaActions from './creators/okta'
import * as modalActions from './creators/modal'
import * as ure from './creators/ure'
import * as authentication from './creators/authentication'
import * as masterList from './creators/masterList'
import * as timelineWithComponent from './creators/timelineWithComponents'
import * as alteration from './creators/alterations'

// this is a dummy import and the module will be replaced to spread a site
//  specific set of action creators. this is done in the webpack.common config
//  using NormalModuleReplacementPlugin
import siteActions from './siteActions'

export const actionCreators = {
  ...addressLookupActions,
  ...formActions,
  ...navigationActions,
  ...oktaActions,
  ...siteActions,
  ...modalActions,
  ...ure,
  ...authentication,
  ...masterList,
  ...timelineWithComponent,
  ...alteration,
}
