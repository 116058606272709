export const TERMS_AND_CONDITION = 'termsAndCondition'
export const NAB_ACCEPTANCE = 'nabAcceptance'
export const PRIVACY_NOTIFICATION = 'privacyNotification'
export const DECLARATION_YES = 'yes'
export const DECLARATION_NO = 'no'

export const PARTY_TYPE = 'partyType'
export const TITLE = 'title'
export const PARTY_TYPE_PERSON = 'Individual'
export const PARTY_TYPE_BUSINESS = 'Business'
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const DATE_OF_BIRTH = 'dateOfBirth'
export const EMAIL = 'email'
export const ABN_NUMBER = 'abnNumber'
export const BUSINESS_NAME = 'businessName'
export const PREFERRED = 'y'
export const ADDRESS_HOME_TYPE = 'h'
export const ADDRESS_STATUS = 'dfl'
export const PHONE_TYPE = 'tel'
export const PHONE_SUB_TYPE = 'm'
export const ASIC_REP_NO = 'asicRepNo'
export const AFSL_NAME = 'afslName'
export const AFSL_NUMBER = 'afslNumber'
export const ADDITIONAL_CODE = 'additionalCode'
export const ADVISER_REGISTRATION_DEFAULT_COUNTRY_CODE = 'AUS'
export const ADVISER_REGISTRATION_GENDER = 'Unknown'
export const ADVISER_REGISTRATION_SMOKER_STATUS = 'Unknown'
export const ADVISER_REGISTRATION_LIFE_STATUS = 'ALV'
export const ADVISER_REGISTRATION_CONTACT_NUMBER = 'contactNumber'
export const ADVISER_REGISTRATION_CONTACT_NUMBER_CODE = 'contactNumberCode'
export const ADVISER_REGISTRATION_CONTACT_NUMBER_COUNTRY_CODE = 'contactNumberCountryCode'
export const ADVISER_REGISTRATION_RESIDENTIAL_ADDRESS = 'residentialAddress'
export const ADVISER_REGISTRATION_RESIDENTIAL_STREET = 'residentialStreet'
export const ADVISER_REGISTRATION_RESIDENTIAL_HOUSENO = 'residentialHouseNo'
export const ADVISER_REGISTRATION_RESIDENTIAL_LOCALITY = 'residentialLocality'
export const ADVISER_REGISTRATION_RESIDENTIAL_STATE = 'residentialState'
export const ADVISER_REGISTRATION_RESIDENTIAL_COUNTRY = 'residentialCountry'
export const ADVISER_REGISTRATION_RESIDENTIAL_POSTCODE = 'residentialPostCode'

export const AUTHORIZED_REPRESENTATIVES_PROP_ID = 'authorizedRepId'
export const AUTHORIZED_REPRESENTATIVES_FIELD_NAME = 'authorizedReps'
export const AUTHORIZED_REPRESENTATIVE_KEY = 'representativeKey'
export const AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_TITLE = 'representativeTitle'
export const AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_FIRST_NAME = 'representativeFirstName'
export const AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_LAST_NAME = 'representativeLastName'
export const AUTHORIZED_REPRESENTATIVE_AUTHORIZED_REP_ASIC_REP_NO = 'representativeAsicRepNo'

export const AUTHORIZED_REPRESENTATIVE_MAXIMUM_NUMBER = 10
