/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTheBox16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path className="path1" d="M5 10h6V8H5v2z" fill="#000" />
    <path
      className="path2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7h1v6a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7h1V4a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v3zm12-3H3v1h10V4zm-9 9V7h8v6H4z"
      fill="#000"
    />
  </Icon>
)

export default IconTheBox16
