// @flow
import { Variables } from '@mlcl-digital/mlcl-design'
import { pathOr } from 'lodash/fp'
import get from 'lodash/get'
import { createEvent } from '../../../../utils/telemetry'

// redux.
import {
  updateMandatoryDetails,
  createQuoteChooseCover,
  createQuoteUpdatePaymentDetails,
  setQuoteName,
  addFeatureAsSubBenefit,
  submitMemberDetails,
  createQuoteUpdateOption,
  createQuoteQuickQuote,
} from '../../../../actions/creators/createQuote'
import {
  closeSidebar,
  openSidebar,
  setIsNextBtnLoader,
  nextPanel,
  updateQuickQuoteCheck,
  setQuoteSource,
} from '../../../../actions/creators/sidebar'

// components.
import CreateQuote from '../../CreateQuote'
import SelectCover from '../../CreateQuote/components/SelectCover'
import CaptureClient from '../../CreateQuote/components/CaptureClient'
import SelectSuper from '../../CreateQuote/components/SelectSuper'
import QuickQuoteSumInsured from '../../CreateQuote/components/QuickQuoteSumInsured'

// helpers.
import { isSuperSelected, sortProducts } from '../../../../utils/quoteUtils'
import { getLifeInsuredData } from '../../../../utils/extendedQuoteUtils'
import history from '../../../../utils/browserHistory'
import { submitClientDetails } from '../../../../utils/captureClientUtils'

// schemas.
// eslint-disable-next-line max-len
import { FORM_ID as chooseCoverId } from '../../CreateQuote/components/ChooseCover/chooseCover.schema'
import { FORM_ID as quickQuoteSumInsuredId } from '../../CreateQuote/components/QuickQuoteSumInsured/quickQuoteSumInsured.schema'
import {
  FORM_ID as childCoverId,
  type ChildData,
} from '../../../molecules/ChildCover/childCover.schema'

import selectSuperSchema, {
  FORM_ID as selectSuperId,
} from '../../CreateQuote/components/SelectSuper/selectSuper.schema'

// utils.
import { formSubmit, formSubmitComplete } from '../../../../actions/creators/forms'
import { getSerializedFormState, buildQuickQuoteBody } from '../../../../utils/formUtils'
import { checkExistingBusiness } from '../../../../utils/clientDetailsUtils'
import {
  POLICY_RELATIONSHIPS_LIFEASSURED,
  INCOME_PROTECTION_PLATINUM_ID,
  POLICY_FEATURE_BO,
  POLICY_FEATURE_EBO,
  POLICY_BENEFIT_CI_PLUS,
  POLICY_NON_SUPER_BENEFIT_PREFIX_NEW,
} from '../../../../constants/policies'
import {
  DIGITAL_QUICK_QUOTE,
  DIGITAL_MANUAL_OVERRIDE,
  DIGITAL_NOT_QUICK_QUOTE,
} from '../../../../constants/quotes'
import {
  callExperienceAPIv2,
  PORTAL_API_PATH,
} from '../../../../utils/customerPortalExperienceAPIUtils'

const { colours } = Variables

export const goToQuoteTool = dispatch => {
  history.push('/quote-tool')
  dispatch(closeSidebar())
}

const SIDEBAR_SCHEMA = (_, sidebar, reduxState) => [
  {
    sitecoreFieldHeadingKey: 'quoteHeaderLabel',
    component: CreateQuote,
    visible: false,
    callbackKey: 'handleNewClient',
    props: {
      handleNewClient: next => next(),
      secondaryHeader: true,
    },
    isDark: true,
  },
  {
    sitecoreFieldHeadingKey: 'quoteHeaderLabel',
    component: CaptureClient,
    visible: false,
    nextText: 'Confirm',
    handleNext: (next, dispatch, state) => {
      const { productRules } = state
      if (get(productRules, 'data.length')) {
        submitClientDetails(state, {
          updateMandatoryDetails: payload => dispatch(updateMandatoryDetails(payload)),
          setQuoteName: payload => dispatch(setQuoteName(payload)),
          formSubmit: (captureClientId, captureClientSchema, updateMemberMandatories) =>
            dispatch(formSubmit(captureClientId, captureClientSchema, updateMemberMandatories)),
          formSubmitComplete: payload => dispatch(formSubmitComplete(payload)),
          nextPanel: payload => dispatch(nextPanel(payload)),
        })
      } else {
        dispatch(setIsNextBtnLoader())
        const tagEvent = createEvent({
          GA: {
            category: 'Save client mandatories',
            action: 'Save',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Save client mandatories - Save',
            },
          },
        })
        tagEvent.end()
      }
    },
    props: {
      secondaryHeader: true,
      panelStyleOverrides: {
        verticalAlign: 'top',
      },
      forcedBackNav: (dispatch, state) => {
        const isFromClientListingPage = get(
          state,
          'sidebar.panelProps.isFromClientListingPage',
          false
        )
        if (isFromClientListingPage) {
          dispatch(
            openSidebar(
              'requestManualQuote',
              2,
              undefined,
              undefined,
              undefined,
              'ChangeCoverSidebar'
            )
          )
        } else {
          dispatch(openSidebar('createQuote'))
        }
      },
    },
  },
  {
    sitecoreFieldHeadingKey: 'quoteHeaderLabel',
    component: SelectCover,
    visible: false,
    nextText: 'Next',
    callbackKey: 'handleNext',
    nextDisabled: state => state.forms[chooseCoverId] && !state.forms[chooseCoverId].isValid,
    handleNext: (next, dispatch, state, fields) => {
      const { createQuote, existingClient, forms } = state

      const updateChooseCoverDetails = async formFields => {
        const hasChildData = typeof state.forms[childCoverId] !== 'undefined'
        const childData: ChildData = hasChildData
          ? getSerializedFormState(state.forms[childCoverId].fields)
          : null
        let healthyLivingDiscount = get(
          state.forms[chooseCoverId],
          'fields.healthyLivingProgram.value',
          false
        )
        // we are coverting string to boolean
        healthyLivingDiscount = healthyLivingDiscount === 'true'
        // when user is clicking next button of choose cover then we are updating
        // healthy living value to create quote
        dispatch(
          createQuoteUpdateOption({
            healthyLivingDiscount,
          })
        )
        dispatch(createQuoteChooseCover({ covers: formFields, childData }))
        dispatch(addFeatureAsSubBenefit(INCOME_PROTECTION_PLATINUM_ID, POLICY_FEATURE_BO))
        dispatch(addFeatureAsSubBenefit(POLICY_BENEFIT_CI_PLUS, POLICY_FEATURE_EBO))
        // If this is for an existing Life Assured, we can go
        // ahead and update the quote with LA details.
        if (checkExistingBusiness(existingClient)) {
          const lifeAssured = getLifeInsuredData({
            createQuote,
            existingClient,
            isExistingBusiness: true,
          })

          dispatch(
            submitMemberDetails(
              {
                ...lifeAssured,
                contactNumberCountryCode: '',
                secondaryNumberCountryCode: '',
              },
              null,
              [POLICY_RELATIONSHIPS_LIFEASSURED]
            )
          )
        }

        const isQuickQuoteEnabled = fields.QuickQuoteEnable?.value
        if (isQuickQuoteEnabled) {
          dispatch(setIsNextBtnLoader())
          const response = await callExperienceAPIv2({
            path: `/${PORTAL_API_PATH}/quickQuote/check`,
            method: 'POST',
            requestBody: buildQuickQuoteBody(forms.chooseCover.fields),
          })
          const qqEligible = response?.businessData?.quickQuote?.eligible
          dispatch(setIsNextBtnLoader(false))
          dispatch(updateQuickQuoteCheck(response && qqEligible))
          !qqEligible && dispatch(setQuoteSource(DIGITAL_NOT_QUICK_QUOTE))

          if (isSuperSelected(formFields, ['healthyLivingProgram'])) {
            next()
          } else if (qqEligible) {
            next(4)
          } else {
            goToQuoteTool(dispatch)
          }
        } else if (isSuperSelected(formFields, ['healthyLivingProgram'])) {
          next()
        } else {
          goToQuoteTool(dispatch)
        }
      }
      dispatch(formSubmit(chooseCoverId, undefined, updateChooseCoverDetails))
      dispatch(formSubmitComplete(chooseCoverId))
    },
    props: {
      handleNext: next => next(),
      secondaryHeader: true,
      topFoot: true,
      footStyleOverrides: {
        position: 'static',
        padding: 0,
        height: 'auto',
      },
      panelStyleOverrides: {
        height: 'calc(100vh - 96px)',
      },
    },
  },
  {
    sitecoreFieldHeadingKey: 'quoteHeaderLabel',
    component: SelectSuper,
    nextText: sidebar?.quickQuoteCheck ? 'Next' : 'Create Quote',
    callbackKey: 'handleNext',
    handleNext: (next, dispatch, state) => {
      const { inSuperProduct } = sortProducts(state.productRules.data)
      // DISPATCH THE THINGS. REDIRECT THE THINGS
      const updateSelectSuper = formFields => {
        const premiumPaymentMethods = pathOr([], 'premiumPaymentMethod', state.masterList.data)
        const updatedFormFields = {
          ...formFields,
          product: inSuperProduct,
          // TODO: needs to change when masterData api is stable
          premiumPaymentMethod: (
            premiumPaymentMethods.find(
              paymentMethod => paymentMethod.value === formFields.fundDetails
            ) || {}
          ).code,
        }
        dispatch(createQuoteUpdatePaymentDetails(updatedFormFields))
        if (sidebar?.quickQuoteCheck) {
          next()
        } else {
          history.push('/quote-tool')
          dispatch(closeSidebar())
        }
      }

      dispatch(formSubmit(selectSuperId, selectSuperSchema, updateSelectSuper))
      dispatch(formSubmitComplete(selectSuperId))
    },
    props: {
      secondaryHeader: true,
      panelStyleOverrides: {
        verticalAlign: 'top',
      },
      topFoot: true,
      footStyleOverrides: {
        position: 'static',
        padding: 0,
      },
    },
  },
  {
    sitecoreFieldHeadingKey: 'quoteHeaderLabel',
    component: QuickQuoteSumInsured,
    visible: false,
    nextText: 'Create quote',
    callbackKey: 'handleNext',
    ...(reduxState?.forms?.quickQuoteSumInsured?.fields &&
      Object.keys(reduxState?.forms?.quickQuoteSumInsured?.fields)
        .filter(i =>
          JSON.stringify(reduxState?.forms?.quickQuoteSumInsured?.fields[i]).includes('value')
        )
        .every(field => field.includes(POLICY_NON_SUPER_BENEFIT_PREFIX_NEW)) && { backToPanel: 2 }),
    nextDisabled: state =>
      state.forms[quickQuoteSumInsuredId] && !state.forms[quickQuoteSumInsuredId].isValid,
    handleNext: (next, dispatch) => {
      // quick quote create quote observability
      const event = createEvent({
        GA: {
          category: 'Create quote',
          action: 'AP - Create New Quote via Quick Quote',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'AP - Create New Quote via Quick Quote',
          },
        },
      })
      event.end()
      const updateQuickQuoteSumInsuredDetails = () => {
        dispatch(createQuoteQuickQuote(goToQuoteTool))
      }

      dispatch(setQuoteSource(DIGITAL_QUICK_QUOTE))
      dispatch(formSubmit(quickQuoteSumInsuredId, undefined, updateQuickQuoteSumInsuredDetails))
      dispatch(formSubmitComplete(quickQuoteSumInsuredId))
    },
    secondaryActionText: 'I prefer to do it manually',
    handleSecondaryAction: dispatch => {
      // quick quote do it mannually observability
      const event = createEvent({
        GA: {
          category: 'Create quote',
          action: 'AP - Create New Quote via Manual',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'AP - Create New Quote via Manual',
          },
        },
      })
      event.end()
      dispatch(setQuoteSource(DIGITAL_MANUAL_OVERRIDE))
      goToQuoteTool(dispatch)
    },
    props: {
      secondaryHeader: true,
      topFoot: true,
      secondaryActionStyleOverrides: {
        color: colours.mlcAquaAccessible,
        textDecoration: 'underline',
      },
      footStyleOverrides: {
        position: 'static',
        padding: 0,
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: '24px',
        height: 'auto',
      },
      panelStyleOverrides: {
        verticalAlign: 'top',
      },
    },
  },
]

export default SIDEBAR_SCHEMA
