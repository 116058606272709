// @flow
// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import { ABN_LOOK_UP, ABN_LOOK_UP_RESET } from '../types/abnLookup'
import { EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

export const lookupOfABN = (lookupString: string) => ({
  type: EXPERIENCE_API,
  name: ABN_LOOK_UP,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}/digital/retailproxy/reference/abn/${lookupString}`,
})

export const resetAbnLookup = () => ({
  type: ABN_LOOK_UP_RESET,
})
