// @flow
import get from 'lodash/get'

import { createSelector } from 'reselect'
import { getAlterations, getAlterationPolicies } from './common.selectors'

// Utils
// @ts-expect-error file not in typescript
import { empStatusTransform } from '../utils/quoteUtils'
// @ts-expect-error file not in typescript
import { getPrimaryLifeInsured } from '../utils/policyUtils'

// constants
import { POLICY_RELATIONSHIPS_AGENT } from '../constants/policies'

// types
import { Relationship, identifier as Identifier } from '../types/ClientPolicies'
import { Alterations, Rules } from '../types/alterations'

export const getRulesForAlterationPolicies = createSelector(
  [getAlterations],
  (alterations: Alterations) => alterations?.rules
)

/**
 * Returns the mandatory attributes of memberMandatories object of a Quote Entity
 * Uses policies from alterations Redux store
 * Maps the values from relationships and benefits of Polciy Entity
 */
export const makeMemberMandatoriesFromAlterations = createSelector(
  [getAlterationPolicies],
  alterationPolicies => {
    // Find the relationship in any of the policy.
    // Assumption: LA record in all policies are the same.
    const relationships = get(
      alterationPolicies,
      ['0', 'policy', 'relationships'],
      []
    ) as Array<Relationship>
    // Derive the below from the LA relationship in policy
    const lifeInsured = get(getPrimaryLifeInsured(relationships), ['0'], {}) as Relationship
    const dateOfBirth = lifeInsured?.relatedParty?.dateOfBirth ?? 'NA'
    const gender = lifeInsured?.relatedParty?.gender ?? 'Unknown'
    const isSmoker = lifeInsured?.relatedParty?.isSmoker ?? 'UNK'
    const employmentStatus = lifeInsured?.relatedParty?.employment?.employmentStatus ?? 'EMP'
    const earning = lifeInsured?.relatedParty?.employment?.totalGrossYrlyIncome
    const occupation = get(
      alterationPolicies,
      '[0].policy.benefits[0].benefitAssured[0].occupation.occupationDescription',
      ''
    ) as string

    // Derive the below from any benefit in policy
    const stateOfResidence = get(
      alterationPolicies,
      '[0].policy.benefits[0].benefitAssured[0].stampDutyState',
      ''
    ) as string

    return {
      isSmoker,
      dateOfBirth,
      gender,
      stateOfResidence,
      employmentStatus: empStatusTransform[employmentStatus.toUpperCase()] as string,
      earning,
      occupation,
    }
  }
)

export const makeAdviserNo = createSelector([getAlterationPolicies], alterationPolicies => {
  // Find the relationship in any of the policy.
  const relationships = get(
    alterationPolicies,
    ['0', 'policy', 'relationships'],
    []
  ) as Array<Relationship>
  // Derive the below from the SA relationship in policy
  const servicingAdviser =
    relationships &&
    relationships.find(
      (relationship: Relationship) => relationship.roleCode === POLICY_RELATIONSHIPS_AGENT
    )
  const identifiers = servicingAdviser?.relatedParty?.identifiers ?? []
  return get(
    identifiers.find((identifier: Identifier) => identifier.type === 'PARTY_NO'),
    'value',
    ''
  )
})

export const makePolicyAssessment = createSelector(
  [getRulesForAlterationPolicies],
  (rules: Rules) => {
    const { systemInfo_TodaysDate } = rules?.businessData?.assesment ?? ''
    return {
      systemInfo_TodaysDate,
    }
  }
)
