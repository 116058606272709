// @flow
import React from 'react'
import styled from '@emotion/styled'

import styles from './tag.styles'

type TagProps = {
  children: string,
  type?: 'primary | success | successlimeshade | danger | removed | warning',
}

const Tag = (props: TagProps) => {
  const { children, type } = props

  const TagRoot = styled('span')(styles.base(type))
  return <TagRoot {...props}>{children}</TagRoot>
}

Tag.defaultProps = {
  type: 'primary',
}

export default Tag
