import { toast } from 'react-toastify'
import { makeRequestObj, getSource } from './middlewareUtils'
import { getBancsCustomerNumber, getToken, getAdviserNo } from './cookieUtils'
import { uuidv4 } from './commonUtils'
import {
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_BASE,
  EXPERIENCE_API_HEADER_X_INITIAL_USER,
  EXPERIENCE_API_DOCUMENT_GENERATE,
} from '../constants/apis'
import {
  TOAST_ID_DOCUMENT_CREATION_SUCCESS,
  TOAST_ID_DOWNLOAD_DOCUMENT_ERROR,
} from '../constants/toast'
import {
  FEATURES_DISPLAY_SEPARATE_OPTIONS_DEFAULT,
  FEATURE_DISPLAY_MAP,
} from '../constants/policies'
import { ADVISER_NO_KEY } from '../constants/adviser'

/**
 * create a document from api response content and trigger auto-download of document
 * @param {Object} data : data to get document from api
 */

export const removeFeatureFromRequestObj = quoteData => ({
  ...quoteData,
  ...(quoteData && quoteData.quote
    ? {
        quote: {
          ...quoteData.quote,
          ...(quoteData.quote && quoteData.quote.policyStructure
            ? {
                policyStructure: quoteData.quote.policyStructure.map(policy => ({
                  ...policy,
                  covers: policy.covers.map(cover => ({
                    ...cover,
                    features:
                      (cover.features &&
                        cover.features.filter(
                          feature =>
                            !(
                              FEATURE_DISPLAY_MAP[quoteData.docType] ||
                              FEATURES_DISPLAY_SEPARATE_OPTIONS_DEFAULT
                            ).includes(feature.featureName)
                        )) ||
                      [],
                  })),
                })),
              }
            : {}),
        },
      }
    : {}),
})

export const downloadDocument = (data, method, url, config, callback = () => {}, options = {}) => {
  const { toastMessage, isOnlyGetDocument } = options
  const adviserNo = getAdviserNo()
  const headers = {
    bancsCustomerNo: getBancsCustomerNumber(),
    token: getToken(),
    'X-InitialUser': EXPERIENCE_API_HEADER_X_INITIAL_USER,
    'X-InitialRole': (config && config.MLCL_UPLOAD_FILE_API_INITIAL_ROLE) || '',
    source: getSource(),
    transactionId: uuidv4(),
    ...(adviserNo && {
      [ADVISER_NO_KEY]: adviserNo,
    }),
  }

  const requestObj = makeRequestObj(
    { data: removeFeatureFromRequestObj(data), verb: method || 'POST' },
    headers,
    true
  )
  const type = (config && config.MLCL_EXPERIENCE_API) || ''

  let responseHeaders = null
  fetch(
    `${type}${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}${
      url || EXPERIENCE_API_DOCUMENT_GENERATE
    }`,
    requestObj
  )
    .then(response => {
      const getContentDisposition = response.headers.get('content-disposition')
      if (response.ok) {
        if (getContentDisposition.includes('attachment')) {
          responseHeaders = response.headers
        }
        return response.blob().then(blob => {
          const contentDisposition = responseHeaders.get('content-disposition').split(';')
          if (contentDisposition) {
            if (!isOnlyGetDocument) {
              const fileName = contentDisposition[1].split('=')
              // eslint-disable-next-line
              const trimFileName = fileName.length > 1 ? fileName[1].replace(/\"/g, '') : 'doc'
              if (window && window.navigator && window.navigator.msSaveBlob) {
                // For IE
                window.navigator.msSaveBlob(blob, trimFileName)
              } else {
                // create a temporary link in document to trigger auto-download of document
                const fileBlobURL = URL.createObjectURL(blob)
                const link = document.createElement('a')
                // set document url to a tag
                link.href = fileBlobURL
                // document name with type
                link.download = trimFileName
                // append link to body
                document.body.appendChild(link)
                // trigger document auto-download
                link.click()

                // revoke blob url and remove a tag from dom
                URL.revokeObjectURL(fileBlobURL)
                document.body.removeChild(link)
              }
              toast(TOAST_ID_DOCUMENT_CREATION_SUCCESS, {
                toastId: data.docType,
                type: toast.TYPE.SUCCESS,
                autoClose: 8000,
              })
            }
            callback()
          } else {
            toast((toastMessage && toastMessage.error) || TOAST_ID_DOWNLOAD_DOCUMENT_ERROR, {
              toastId: data.docType,
              type: toast.TYPE.ERROR,
              autoClose: 8000,
            })
            callback({ name: 'EmptyDataError' })
          }
        })
      }

      return response.json().then(errResponse => {
        toast((toastMessage && toastMessage.error) || TOAST_ID_DOWNLOAD_DOCUMENT_ERROR, {
          toastId: data.docType,
          type: toast.TYPE.ERROR,
          autoClose: 8000,
        })
        // eslint-disable-next-line no-console
        console.error('downloadDocument API error', errResponse.toString())
        callback(errResponse)
      })
    })
    .catch(err => {
      toast((toastMessage && toastMessage.error) || TOAST_ID_DOWNLOAD_DOCUMENT_ERROR, {
        toastId: data.docType,
        type: toast.TYPE.ERROR,
        autoClose: 8000,
      })
      // eslint-disable-next-line no-console
      console.error('downloadDocument API error', err.toString())
      callback(err)
    })
}
