// @flow
import { colours, space, fontSize } from '../../../../../styles'

const styles = {
  header: {
    paddingTop: space(8, true),
    paddingBottom: space(20, true),
    backgroundColor: colours.darkGreen,
    color: colours.white,
  },

  title: {
    color: colours.white,
  },

  subtitle: {
    color: colours.white,
  },

  backButton: {
    display: 'block',
    marginBottom: space(2),
    paddingLeft: 0,
    height: 'auto',
    fontSize: fontSize.xs,
    color: colours.white,
    svg: {
      marginRight: space(1),
      marginTop: space(-0.25, true),
      color: colours.white,
    },
    '&:hover': {
      color: colours.lightGreen,
      svg: {
        color: colours.lightGreen,
      },
    },
  },

  content: {
    marginTop: space(-10, true),
    marginBottom: space(5),
  },

  policies: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}

export default styles
