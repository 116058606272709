// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Checkbox } from '@mlcl-digital/mlcl-design'

// icons
import { IconArrowLeft16 } from '../../../../../atoms/Icons'

// components
import Button from '../../../../../atoms/Button'

// styles
import styles from './quoteDownloadOptions.styles'
import adviserDetailsStyles from '../QuoteUpdateAdviserDetails/quoteUpdateAdviserDetails.styles'

// utils
import { renderTextField } from '../../../../../../utils/sitecoreUtils'
import { downloadDocument } from '../../../../../../utils/downloadDocumentUtils'

import { generateCorrelationID } from '../../../../../../utils/commonUtils'

// constants
import SCHEMA from './quoteDownloadOptions.schema'
import { renamePolicy } from '../../../../../../utils/quoteUtils'

const BackButton = styled('button')(styles.backButton)
const SelectButton = styled(Button)(styles.selectButton)
const FormButton = styled(Button)(adviserDetailsStyles.formButton)
const ErrorMessage = styled('p')(styles.errorMessage)
const ErrorWrapper = styled.div(styles.errorWrapper)
const HeaderWrapper = styled.div(styles.headerWrapper)
const CheckboxWrapper = styled.div(styles.checkboxWrapper)

type QuoteDownloadOptionsProps = {
  // sitecore fields
  fields: Object,
  // action to update modal content
  submitAdvisorDetails: Function,
  // action to close modal
  closeModal: Function,
  // quote data from redux state
  quote: Object,
  // redux env constants config
  config: Object,
  // life insured first name
  lifeInsuredFirstName: string,
  // life insured last name
  lifeInsuredLastName: string,
}

function createDownloadOptionsMap(value) {
  return SCHEMA.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item.name] = value
    return obj
  }, {})
}

export class QuoteDownloadOptions extends Component<QuoteDownloadOptionsProps> {
  constructor(props) {
    super(props)
    this.state = {
      ...createDownloadOptionsMap(false),
      noSelectionError: false,
    }
  }

  handleCheckboxChange = ({ value, name }) => {
    this.setState({ [name]: value, noSelectionError: false })
  }

  backClickHandler = () => {
    const { submitAdvisorDetails } = this.props
    submitAdvisorDetails()
  }

  handleSelectAll = () => {
    const isAllSelectionsTrue = SCHEMA.reduce((selections, item) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (!this.state[item.name] || !selections) {
        return false
      }
      return true
    }, true)
    if (isAllSelectionsTrue) {
      this.setState({
        ...createDownloadOptionsMap(false),
        noSelectionError: false,
      })
    } else {
      this.setState({
        ...createDownloadOptionsMap(true),
        noSelectionError: false,
      })
    }
  }

  handleSubmit = () => {
    const selectedOptions = SCHEMA.reduce((selections, item) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state[item.name]) {
        selections.push(item)
      }
      return selections
    }, [])

    if (selectedOptions.length) {
      selectedOptions.forEach((item, index) => {
        setTimeout(() => {
          this.triggerDownloadDocument(item.key)
        }, 50 * index)
      })
      const { closeModal } = this.props
      // close adviser detail modal after download
      closeModal()
    } else {
      this.setState({ noSelectionError: true })
    }
  }

  triggerDownloadDocument = docType => {
    const { quote: tempQuote, config, lifeInsuredFirstName, lifeInsuredLastName } = this.props
    const quote = this.triggerPolicyRename(tempQuote)
    const dataToPost = {
      docType,
      quote,
      correlationID: generateCorrelationID(),
      lifeInsuredFirstName,
      lifeInsuredLastName,
    }
    downloadDocument(dataToPost, null, null, config)
  }

  triggerPolicyRename = quote => {
    const {
      fields: {
        quoteToolInsidePolicyNamePrefix,
        quoteToolOutsidePolicyNamePrefix,
        quoteToolWrapPolicySuffix,
        quoteToolSuperPolicySuffix,
        quoteToolOutsidePolicySuffix,
      },
    } = this.props
    const updatedPolicyStructure = quote.policyStructure.map(policy => {
      const newName = renamePolicy(
        policy,
        quoteToolInsidePolicyNamePrefix.value,
        quoteToolOutsidePolicyNamePrefix.value,
        quoteToolWrapPolicySuffix.value,
        quoteToolSuperPolicySuffix.value,
        quoteToolOutsidePolicySuffix.value
      )
      return {
        ...policy,
        productName: newName,
      }
    })
    return {
      ...quote,
      policyStructure: updatedPolicyStructure,
    }
  }

  renderCheckBoxItems() {
    return SCHEMA.map(item => (
      <CheckboxWrapper key={item.name}>
        <Checkbox
          key={item.name}
          htmlFor={item.name}
          text={item.value}
          name={item.name}
          disabled={false}
          value={item.name}
          onChangeHandler={this.handleCheckboxChange}
          // eslint-disable-next-line react/destructuring-assignment
          checked={this.state[item.name]}
        />
      </CheckboxWrapper>
    ))
  }

  render() {
    const { fields } = this.props
    const {
      downloadQuoteSelectOptionsHeader,
      downloadQuoteBackButton,
      downloadQuoteSelectOptionError,
    } = fields
    const { noSelectionError } = this.state
    return (
      <div>
        <BackButton onClick={this.backClickHandler}>
          <IconArrowLeft16 /> {renderTextField(downloadQuoteBackButton)}
        </BackButton>
        <HeaderWrapper>{renderTextField(downloadQuoteSelectOptionsHeader)}</HeaderWrapper>
        {this.renderCheckBoxItems()}
        <div>
          <SelectButton type="tertiary" onClick={this.handleSelectAll}>
            <Text field={fields.quoteDownloadSelectAllButton} />
          </SelectButton>
        </div>
        <ErrorWrapper>
          {noSelectionError && (
            <ErrorMessage>{renderTextField(downloadQuoteSelectOptionError)}</ErrorMessage>
          )}
        </ErrorWrapper>
        <FormButton type="secondary" onClick={this.handleSubmit}>
          <Text field={fields.quoteDownloadModalSubmitButton} />
        </FormButton>
      </div>
    )
  }
}

const mapStateToProps = ({ createQuote }) => ({
  lifeInsuredFirstName: createQuote.lifeInsuredFirstName,
  lifeInsuredLastName: createQuote.lifeInsuredLastName,
  quote: createQuote.quotes[createQuote.activeIndex],
})
export default connect(mapStateToProps)(QuoteDownloadOptions)
