// @flow
import get from 'lodash/get'
import {
  ADV_F2F,
  ADV_PHN,
  MYLINK,
  TELE,
  INCOMEPROTECTIONPLT,
  INCOMEPROTECTIONSTD,
  BUSINESSEXPENSE,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICYOWNER,
  IET,
} from '../constants/application'

// Get the term data from policyStructure
export const getTermData = (policyStructure: Array<Object>): Object => {
  const termData = {
    isIPInApplication: false,
    isBEInApplication: false,
    isAcceptedWithTerms: false,
    isLIisThePolicyOwner: false,
    isLIisThePrimaryPolicyOwner: false,
    isPrimaryOwnerOtherThenLi: false,
    isPolicyOwnerOtherThenLi: false,
    isOnlyOnePolicyOwnerThatIsLi: false,
    isMoreThenOnePolicyOwner: false,
    isbusinessAsThePolicyOwner: false,
    businessName: '',
    policyOwnerName: [],
  }
  let loadingInPolicy = false
  let exclusionInPolicy = false
  policyStructure.forEach(policy => {
    const covers = get(policy, 'covers', [])
    const relationships = get(policy, 'relationships', [])
    // check if covers exist
    covers.length &&
      covers.forEach(cover => {
        const coverType = get(cover, 'type', '')
        const loading = get(cover, 'loading', '')
        const exclusion = get(cover, 'exclusion', '')
        // return - true - IP benefit available in application
        termData.isIPInApplication =
          coverType === INCOMEPROTECTIONPLT || coverType === INCOMEPROTECTIONSTD
            ? true
            : termData.isIPInApplication

        // return - true - BE benefit available in application
        termData.isBEInApplication =
          coverType === BUSINESSEXPENSE ? true : termData.isBEInApplication

        // return - true - Accepted with terms if loading and exclusions
        loadingInPolicy = !!loading.length
        exclusionInPolicy = !!exclusion.length
      })

    // return - true : if only one policy owner that is LI available in application
    const roleIsPolicyOwnerAndLI =
      relationships.length &&
      relationships.filter(
        relationship =>
          (relationship.role.includes(POLICYOWNER) &&
            relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)) ||
          (relationship.role.includes(POLICYOWNER) && relationship.isAssociatedToSuperFund)
      )
    termData.isOnlyOnePolicyOwnerThatIsLi = !!(
      roleIsPolicyOwnerAndLI && roleIsPolicyOwnerAndLI.length === 1
    )

    // return - true : if more then one policy owner available in application
    const roleIsPolicyOwner =
      relationships.length &&
      relationships.filter(relationship => relationship.role.includes(POLICYOWNER))

    termData.isMoreThenOnePolicyOwner =
      roleIsPolicyOwner && roleIsPolicyOwner.length > 1 ? true : termData.isMoreThenOnePolicyOwner

    // check if relationships exist
    policy.relationships.length &&
      policy.relationships.forEach(relationship => {
        const relationshipRole = get(relationship, 'role', [])
        const isPrimaryOwnerChecked = get(relationship, 'isPrimary', false)
        const isLifeinsured = relationshipRole.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
        const isPolicyOwner =
          relationshipRole.includes(POLICYOWNER) || relationshipRole.includes(IET)
        const companies = isPolicyOwner ? get(relationship, 'companies', []) : []
        const ownerFirstName = get(relationship, 'relatedParty.firstName', '')
        const ownerLastName = get(relationship, 'relatedParty.lastName', '')
        const isAssociatedToSuperFund = get(relationship, 'isAssociatedToSuperFund', false)
        const nameSuffix = ''

        // return - true -  business as the policy owner
        termData.isbusinessAsThePolicyOwner = companies.length
          ? true
          : termData.isbusinessAsThePolicyOwner

        // return - true - LI is the policy owner
        termData.isLIisThePolicyOwner =
          (isLifeinsured && isPolicyOwner) || isAssociatedToSuperFund
            ? true
            : termData.isLIisThePolicyOwner

        // return - true - LI is the primary policy owner
        termData.isLIisThePrimaryPolicyOwner =
          (isLifeinsured && isPolicyOwner && isPrimaryOwnerChecked) || isAssociatedToSuperFund
            ? true
            : termData.isLIisThePrimaryPolicyOwner

        // return - true : if primary owner is available in application
        termData.isPrimaryOwnerOtherThenLi =
          isPolicyOwner && !isLifeinsured && isPrimaryOwnerChecked
            ? true
            : termData.isPrimaryOwnerOtherThenLi

        // return - true : if primary owner is available in application
        termData.isPolicyOwnerOtherThenLi =
          isPolicyOwner && !isLifeinsured && !isAssociatedToSuperFund
            ? true
            : termData.isPolicyOwnerOtherThenLi

        // return the business name
        if (companies.length) {
          const businessName = get(relationship, 'relatedParty.businessName', '')
          termData.businessName =
            !isLifeinsured && isPolicyOwner && businessName ? businessName : termData.businessName
        }

        // get Policy Owner Name excluded the LI name
        if (isPolicyOwner && !isLifeinsured && !companies.length) {
          const name = `${ownerFirstName} ${ownerLastName} ${nameSuffix}`
          // if Policy Owner and No business, life insured
          if (isPrimaryOwnerChecked) {
            termData.policyOwnerName.unshift(name)
          } else {
            termData.policyOwnerName.push(name)
          }
        }
      })
  })
  termData.isAcceptedWithTerms = !!(loadingInPolicy && exclusionInPolicy)
  return termData
}

// Face to Face flow
export const getDataForF2fMyLinkAndPhoneFlow = (policyStructure: Array<Object>): Object => {
  const termContent = {
    termIdentifier: '',
    applicationPolicyOwner: '',
    redirect: false,
    isLIisThePolicyOwner: true,
  }
  if (Array.isArray(policyStructure) && policyStructure.length) {
    const termData = getTermData(policyStructure)

    const {
      isIPInApplication,
      isBEInApplication,
      isAcceptedWithTerms,
      isLIisThePolicyOwner,
      isLIisThePrimaryPolicyOwner,
      isPrimaryOwnerOtherThenLi,
      isPolicyOwnerOtherThenLi,
      isMoreThenOnePolicyOwner,
      isOnlyOnePolicyOwnerThatIsLi,
      isbusinessAsThePolicyOwner,
      businessName,
      policyOwnerName,
    } = termData
    termContent.isLIisThePolicyOwner = isLIisThePolicyOwner
    //  Validate condition
    if (
      !isbusinessAsThePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      !isPolicyOwnerOtherThenLi &&
      !isMoreThenOnePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 1-  Only PO AND LI is the PO AND  No BE or IP in application  - Accepted with No Term
      termContent.termIdentifier = '1'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      !isbusinessAsThePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      !isMoreThenOnePolicyOwner &&
      !isPolicyOwnerOtherThenLi &&
      !isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 2-  Only PO AND LI is the PO AND  No BE or IP in application - Accepted with TERMS
      termContent.termIdentifier = '2'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isPolicyOwnerOtherThenLi &&
      !isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 3- LI is the primary PO AND one or more additional
      // PO AND NO BE or IP in application - Accepted with No Term
      termContent.termIdentifier = '3'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isPolicyOwnerOtherThenLi &&
      !isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 4-  LI is the primary PO AND one or more additional
      // PO AND NO BE or IP in application - Accepted with TERMS
      termContent.termIdentifier = '4'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isLIisThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 5-  LI is NOT the primary PO AND A primary PO (Individual) LI Is
      // another Owner AND NO BE or IP in application - Accepted with No Term
      // 7-  LI is NOT the primary PO AND A primary PO AND one or more
      // Owners AND LI is an Owner AND NO BE or IP in application - Accepted with No Term
      termContent.termIdentifier = '5'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isLIisThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 6-  LI is NOT the primary PO AND A primary PO (Individual)
      // LI Is another Owner AND NO BE or IP in application - Accepted with TERMS
      // 8-  LI is NOT the primary PO AND A primary PO AND
      // one or more Owners AND LI is an Owner AND NO BE or IP in application - Accepted with TERMS
      termContent.termIdentifier = '6'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      !isbusinessAsThePolicyOwner &&
      !isLIisThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 9-  LI is NOT the primary PO AND A primary PO AND one or more
      // Owners AND LI is an NOT an Owner AND NO BE or IP in application  - Accepted with No Term
      termContent.termIdentifier = '9'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      !isbusinessAsThePolicyOwner &&
      !isLIisThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 10- LI is NOT the primary PO AND A primary PO AND one or more
      // Owners AND LI is an NOT an Owner AND NO BE or IP in application  - Accepted with TERMS
      termContent.termIdentifier = '10'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      isbusinessAsThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 11-  as LI is NOT the primary PO AND A BUSINESSOwner
      // AND NO BE or IP in application - Accepted with No Term
      termContent.termIdentifier = '11'
      termContent.applicationPolicyOwner = businessName
    } else if (
      isbusinessAsThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 12-  as LI is NOT the primary PO AND A BUSINESSOwner
      // AND NO BE or IP in application -  Accepted with TERMS
      termContent.termIdentifier = '12'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isBEInApplication &&
      !isIPInApplication &&
      !isAcceptedWithTerms
    ) {
      // 13- Only PO AND LI is the PO AND BE in application  - Accepted with No Term
      termContent.termIdentifier = '13'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isBEInApplication &&
      !isIPInApplication &&
      isAcceptedWithTerms
    ) {
      // 14- Only PO AND LI is the PO AND BE in application  - Accepted with TERMS
      termContent.termIdentifier = '14'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isbusinessAsThePolicyOwner &&
      isBEInApplication &&
      !isIPInApplication &&
      isAcceptedWithTerms
    ) {
      // 15- LI is the primary PO AND COMPANY as PO AND BE in application -  - Accepted with No Term
      termContent.termIdentifier = '15'
      termContent.applicationPolicyOwner = businessName
    } else if (
      isbusinessAsThePolicyOwner &&
      (isBEInApplication || (isIPInApplication && isBEInApplication)) &&
      !isAcceptedWithTerms
    ) {
      // 16- LI is the primary PO AND COMPANY as PO AND BE in application -  - Accepted with TERMS
      termContent.termIdentifier = '16'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isBEInApplication &&
      !isIPInApplication &&
      !isAcceptedWithTerms
    ) {
      // 17- LI is NOT the primary PO AND A primary PO AND BE in application - Accepted with No Term
      // 18- LI is NOT the primary PO AND A primary PO
      // AND one or more Pos AND BE in application - Accepted with No Term
      termContent.termIdentifier = '17'
      termContent.applicationPolicyOwner = 'NA'
      termContent.redirect = true
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      !isPolicyOwnerOtherThenLi &&
      isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 19- Only PO AND LI is the PO AND  IP in application - Accepted with No Term
      termContent.termIdentifier = '19'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      !isPolicyOwnerOtherThenLi &&
      isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 20- Only PO AND LI is the PO AND  IP in application - Accepted with TERMS
      termContent.termIdentifier = '20'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      (isbusinessAsThePolicyOwner || isPolicyOwnerOtherThenLi) &&
      isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 21- LI is the primary PO AND COMPANY as PO AND IP in application - Accepted with No Term
      termContent.termIdentifier = '21'
      termContent.applicationPolicyOwner = businessName
    } else if (
      (isbusinessAsThePolicyOwner || isPolicyOwnerOtherThenLi) &&
      isIPInApplication &&
      !isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 22- LI is the primary PO AND COMPANY as PO AND IP in application - Accepted with TERMS
      termContent.termIdentifier = '22'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isIPInApplication &&
      !isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 23- LI is NOT the primary PO AND A primary PO AND IP in application - Accepted with No Term
      // 24- LI is NOT the primary PO AND A primary PO AND
      // one or more Pos AND IP in application - Accepted with No Term
      termContent.termIdentifier = '23'
      termContent.applicationPolicyOwner = 'NA'
      termContent.redirect = true
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isIPInApplication &&
      isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 25- Only PO AND LI is the PO AND Both BE and IP in application - Accepted with No Term
      termContent.termIdentifier = '25'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isIPInApplication &&
      isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 26- Only PO AND LI is the PO AND Both BE and IP in application - Accepted with TERMS
      termContent.termIdentifier = '26'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isMoreThenOnePolicyOwner &&
      isIPInApplication &&
      isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 27- LI is the primary PO AND one or more additional
      // PO AND Both BE and IP in application - Accepted with No Term
      termContent.termIdentifier = '27'
      termContent.applicationPolicyOwner = businessName
    } else if (
      isbusinessAsThePolicyOwner &&
      isIPInApplication &&
      isBEInApplication &&
      isAcceptedWithTerms
    ) {
      // 28- LI is the primary PO AND BUSINESS as PO
      // AND Both BE and IP in application - Accepted with TERMS
      termContent.termIdentifier = '28'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isIPInApplication &&
      isBEInApplication &&
      !isAcceptedWithTerms
    ) {
      // 29- LI is NOT the primary PO AND A primary PO AND
      // Both BE and IP in application - Accepted with No Term
      // 30- LI is NOT the primary PO AND A primary PO AND
      // one or more Pos AND Both BE and IP in application - Accepted with No Term
      termContent.termIdentifier = '29'
      termContent.redirect = true
      termContent.applicationPolicyOwner = 'NA'
    } else {
      termContent.redirect = true
    }
  }
  return termContent
}

// Tele flow
export const getDataForTele = (policyStructure: Array<Object>): Object => {
  const termContent = {
    termIdentifier: '',
    applicationPolicyOwner: '',
    redirect: false,
    isLIisThePolicyOwner: true,
  }
  if (Array.isArray(policyStructure) && policyStructure.length) {
    const termData = getTermData(policyStructure)

    const {
      isIPInApplication,
      isBEInApplication,
      isLIisThePolicyOwner,
      isLIisThePrimaryPolicyOwner,
      isPrimaryOwnerOtherThenLi,
      isPolicyOwnerOtherThenLi,
      isMoreThenOnePolicyOwner,
      isOnlyOnePolicyOwnerThatIsLi,
      isbusinessAsThePolicyOwner,
      businessName,
      policyOwnerName,
    } = termData
    termContent.isLIisThePolicyOwner = isLIisThePolicyOwner
    //  Validate condition
    if (
      isOnlyOnePolicyOwnerThatIsLi &&
      !isMoreThenOnePolicyOwner &&
      isLIisThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication
    ) {
      // 1. Only PO AND LI is the PO AND  No BE or IP in application
      termContent.termIdentifier = '1'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isPolicyOwnerOtherThenLi &&
      !isIPInApplication &&
      !isBEInApplication
    ) {
      // 2. LI is the primary PO AND one or more additional PO AND NO BE or IP in application
      termContent.termIdentifier = '2'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      !isIPInApplication &&
      !isBEInApplication
    ) {
      // 3. LI is NOT the primary PO AND. A primary PO (Individual) AND  NO BE or IP in application
      // 4. LI is NOT the primary PO AND. A primary PO AND
      // one or more Pos AND NO BE or IP in application
      termContent.termIdentifier = '3'
      termContent.applicationPolicyOwner = policyOwnerName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isbusinessAsThePolicyOwner &&
      !isIPInApplication &&
      !isBEInApplication
    ) {
      // 5. LI is NOT the primary PO AND. A BUSINESS as Owner AND NO BE or IP in application
      termContent.termIdentifier = '5'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isBEInApplication &&
      !isIPInApplication
    ) {
      // 6. Only PO AND LI is the PO AND  BE in application
      termContent.termIdentifier = '6'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isPolicyOwnerOtherThenLi &&
      isbusinessAsThePolicyOwner &&
      isBEInApplication &&
      !isIPInApplication
    ) {
      // 7. LI is the primary PO AND COMPANY as PO AND   BE in application
      termContent.termIdentifier = '7'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isBEInApplication &&
      !isIPInApplication
    ) {
      // 8. LI is NOT the primary PO AND. A primary PO AND  BE in application
      // 9. LI is NOT the primary PO AND. A primary PO AND  one or more Pos AND  BE in application
      // REDIRECT
      termContent.termIdentifier = '8'
      termContent.applicationPolicyOwner = 'NA'
      termContent.redirect = true
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isIPInApplication &&
      !isBEInApplication
    ) {
      // 10. Only PO AND LI is the PO AND  IP in application
      termContent.termIdentifier = '10'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isPolicyOwnerOtherThenLi &&
      isbusinessAsThePolicyOwner &&
      isIPInApplication &&
      !isBEInApplication
    ) {
      // 11. LI is the primary PO AND COMPANY as PO AND  IP in application
      termContent.termIdentifier = '11'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isIPInApplication &&
      !isBEInApplication
    ) {
      // 12. LI is NOT the primary PO AND. A primary PO AND IP in application
      // 13. LI is NOT the primary PO AND. A primary PO AND one or more Pos AND IP in application
      // REDIRECT
      termContent.termIdentifier = '12'
      termContent.applicationPolicyOwner = 'NA'
      termContent.redirect = true
    } else if (
      !isbusinessAsThePolicyOwner &&
      !isMoreThenOnePolicyOwner &&
      isOnlyOnePolicyOwnerThatIsLi &&
      isIPInApplication &&
      isBEInApplication
    ) {
      // 14. Only PO AND LI is the PO AND Both BE and IP in application
      termContent.termIdentifier = '14'
      termContent.applicationPolicyOwner = 'NA'
    } else if (
      isLIisThePrimaryPolicyOwner &&
      isPolicyOwnerOtherThenLi &&
      isbusinessAsThePolicyOwner &&
      isIPInApplication &&
      isBEInApplication
    ) {
      // 15. LI is the primary PO AND BUSINESS as PO AND Both BE and IP in application
      termContent.termIdentifier = '15'
      termContent.applicationPolicyOwner = businessName
    } else if (
      !isLIisThePrimaryPolicyOwner &&
      isPrimaryOwnerOtherThenLi &&
      isIPInApplication &&
      isBEInApplication
    ) {
      //  16. LI is NOT the primary PO AND. A primary PO AND  Both BE and IP in application
      // 17. LI is NOT the primary PO AND. A primary PO AND
      // one or more Pos AND  Both BE and IP in application
      // REDIRECT
      termContent.termIdentifier = '16'
      termContent.applicationPolicyOwner = 'NA'
      termContent.redirect = true
    } else {
      termContent.redirect = true
    }
  }
  // update the owner name here
  return termContent
}

// return - getTermAndCondition with underwriting method
export const getTermAndCondition = (activeQuote: Object): Object => {
  const underwritingMethod = get(activeQuote, 'underwritingDetails.underwritingMethod', '')
  const policyStructure = get(activeQuote, 'policyStructure', [])
  const termData = {
    termIdentifier: '',
    applicationPolicyOwner: '',
    redirect: false,
    termNumber: '',
    isLIisThePolicyOwner: true,
  }
  if (underwritingMethod === ADV_F2F) {
    const { termIdentifier, applicationPolicyOwner, redirect, isLIisThePolicyOwner } =
      getDataForF2fMyLinkAndPhoneFlow(policyStructure)
    termData.termIdentifier = 'termAndConditionF2F'
    termData.applicationPolicyOwner = applicationPolicyOwner
    termData.redirect = redirect
    termData.termNumber = termIdentifier
    termData.isLIisThePolicyOwner = isLIisThePolicyOwner
  } else if (underwritingMethod === ADV_PHN) {
    const { termIdentifier, applicationPolicyOwner, redirect, isLIisThePolicyOwner } =
      getDataForF2fMyLinkAndPhoneFlow(policyStructure)
    termData.termIdentifier = 'termAndConditionPhone'
    termData.applicationPolicyOwner = applicationPolicyOwner
    termData.redirect = redirect
    termData.termNumber = termIdentifier
    termData.isLIisThePolicyOwner = isLIisThePolicyOwner
  } else if (underwritingMethod === MYLINK || underwritingMethod === TELE) {
    const { termIdentifier, applicationPolicyOwner, redirect, isLIisThePolicyOwner } =
      getDataForF2fMyLinkAndPhoneFlow(policyStructure)
    termData.termIdentifier = 'termAndConditionOnline'
    termData.applicationPolicyOwner = applicationPolicyOwner
    termData.redirect = redirect
    termData.termNumber = termIdentifier
    termData.isLIisThePolicyOwner = isLIisThePolicyOwner
  }
  return termData
}

// getTermAndConditionLabel - return term and condition label
export const getTermAndConditionLabel = (
  data: Array<Object>,
  termIdentifier: string,
  termNumber: string
) => {
  const itemIdentifier = `${termIdentifier}${termNumber}`
  if (itemIdentifier) {
    return data.find(element => element.itemIndex.toLowerCase() === itemIdentifier.toLowerCase())
  }
  return null
}
