/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTooltip16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm2 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7 9.374a8 8 0 0 0 .042.626h1.507a6.776 6.776 0 0 1-.041-.59c0-.276.078-.51.235-.704.157-.193.465-.443.926-.75.535-.346.904-.661 1.107-.945a1.7 1.7 0 0 0 .304-1.016c0-.693-.417-1.53-.93-1.916C9.64 3.693 8.923 3.5 8 3.5c-.535 0-1.06.065-1.577.195-.516.13-.982.317-1.397.561l.65 1.146c.793-.394 1.522-.59 2.186-.59.894 0 1.503.608 1.503 1.23 0 .213-.064.4-.193.562-.13.161-.383.368-.761.62-.535.362-.904.695-1.107.998C7.101 8.525 7 8.909 7 9.374zm1.051 3.363c-.332 0-.59-.077-.774-.23-.185-.154-.277-.365-.277-.633 0-.275.092-.49.277-.644.184-.153.442-.23.774-.23s.59.077.775.23c.184.154.276.365.276.632 0 .276-.092.49-.276.644-.185.154-.443.23-.775.23z"
      fill="#000"
    />
  </Icon>
)

export default IconTooltip16
