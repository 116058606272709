/* eslint-disable prefer-destructuring */
// @flow
import queryString from 'query-string'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import axios from 'axios'
import qs from 'querystring'
import { OktaAuth } from '@okta/okta-auth-js'
import { createEvent } from '../../utils/telemetry'
// constants.
import { LAYOUT_SERVICE_LOGOUT_ITEM } from '../../constants/sitecore'
import {
  OKTA_COOKIE_NAME,
  OKTA_IDENTITY_TYPE_SUPPORT_STUFF,
  OKTA_SESSION_INACTIVE,
} from '../../constants/okta'
import {
  TOAST_PASSWORD_CHANGED_SUCCESS,
  TOAST_PASSWORD_CHANGE_ERROR,
  TOAST_LOGOUT_ERROR,
  TOAST_ID_LOGOUT_PROGRESS,
  TOAST_ID_LOGOUT_ERROR,
} from '../../constants/toast'
import { ADVISOR_PORTAL, CUSTOMER_PORTAL } from '../../constants/site'
import {
  BANCS_CUSTOMER_NUM_COOKIE_NAME,
  THIRD_PARTY_QUOTE_COOKIE_NAME,
  THIRD_PARTY_SOURCE,
} from '../../constants/bancs'
import { CUSTOMER_PERSONAL_STATEMENT_DETAIL_PAGE } from '../../constants/personalStatement'
import {
  OKTA_SIGNOUT,
  OKTA_API,
  OKTA_AUTHORIZATION,
  OKTA_REDIRECT,
  MYLINK_OTP_VERIFY_REQUEST,
  MYLINK_PRIMARY_AUTH,
  MYLINK_OTP_SUBMIT_REQUEST,
  MYLINK_OTP_RESEND_REQUEST,
  OKTA_CHANGE_PASSWORD,
  OKTA_CHANGE_PASSWORD_INIT,
  OKTA_CHANGE_PASSWORD_MODAL,
  MYLINK_OTP_GRANT_ACCESS,
  OKTA_AUTHORIZATION_FAILURE,
  OKTA_AUTHORIZATION_SUCCESS,
  OKTA_REVOKE_ACCESS_TOKEN,
  OKTA_CLOSE_CURRENT_SESSION,
  OKTA_SIGNOUT_FAILURE,
  OKTA_SIGNOUT_SUCCESS,
  OKTA_REVOKE_ACCESS_TOKEN_FAILURE,
  OKTA_REVOKE_ACCESS_TOKEN_SUCCESS,
  OKTA_REAUTHORIZATION,
  OKTA_REAUTHORIZATION_SUCCESS,
  MYLINK_PRIMARY_AUTH_FAILURE,
  OKTA_REVOKE_ERROR_CODE,
  OKTA_SET_BANCS_CUSTOMER_NO,
  OKTA_CLOSE_CURRENT_SESSION_FAILURE,
  OKTA_SET_USER_PROFILE_FROM_REDIRECT,
} from '../types/okta'

import history from '../../utils/browserHistory'
import { getRouteData } from '../../utils/routeUtils'
// action
import { hideModal } from './modal'
import { transformThirdPartyQuote } from './thirdParty'
import { getAdvisorDetails } from './advisor'
import { resetProductsData } from './productRules'
import { getRetentionDashboard } from './retentionDashboardAction'
// utils.
import {
  universalCookie,
  removeAllCookies,
  decodeCookie,
  getBancsCustomerNumber,
} from '../../utils/cookieUtils'
import { nonce, uuidv4 } from '../../utils/commonUtils'
import { getScope } from '../../utils/middlewareUtils'
import { isBrowser, getQueryParameterValueFromURL, isLocalStorage } from '../../utils/browserUtils'
import { isFeatureEnabled } from '../../utils/featureToggling'
import {
  SUPPORT_STAFF_SELECT_ADVISER_ROUTE,
  CUSTOMER_PERSONAL_DETAILS,
  CUSTOMER_MAKE_A_PAYMENT,
  UPDATE_MOBILE,
} from '../../constants/routes'

const LIMIT_OF_RETENTION_RECORDS = 1
const WELCOME_JOURNEY_ANCHOR = '#welcome'

const makeMyLinkOktaClient = config => {
  const headerConfig = {
    url: config.MLCL_OKTA_API,
    issuer: `${config.MLCL_OKTA_API}/oauth2/${config.MLCL_MYLINK_OKTA_AUTH_SERVER_ID}`,
    authorizeUrl: `${config.MLCL_OKTA_API}/oauth2/${config.MLCL_MYLINK_OKTA_AUTH_SERVER_ID}/v1/authorize`,
    clientId: config.MLCL_MYLINK_OKTA_CLIENT_ID,
    redirectUri: config.MLCL_MYLINK_OKTA_REDIRECT_URI,
    tokenManager: {
      storage: 'cookie',
    },
    pkce: false,
    scopes: ['prospect'],
  }

  const myLinkAuthClient = new OktaAuth(headerConfig)
  return myLinkAuthClient
}

export const clearSitecoreSession =
  (redirectUrl: string = '/') =>
  async (dispatch, getState) => {
    const { config } = getState()
    const routeData = await getRouteData(LAYOUT_SERVICE_LOGOUT_ITEM, null, {
      sitecoreApiHost: config.MLCL_LAYOUT_SERVICE_API_HOST,
    })

    toast.dismiss(TOAST_ID_LOGOUT_PROGRESS, { autoClose: 3000 })
    if (routeData !== null) {
      dispatch({ type: OKTA_SIGNOUT_SUCCESS })
      // redirection
      if (redirectUrl !== false) {
        history.push(redirectUrl || '/')
      }
    } else {
      dispatch({ type: OKTA_SIGNOUT_FAILURE })
      // notification for error in logout
      toast(TOAST_LOGOUT_ERROR, {
        toastId: TOAST_ID_LOGOUT_ERROR,
        autoClose: 3000,
        type: toast.TYPE.ERROR,
      })
    }
  }

export const revokeOktaToken =
  (hasSitecoreSession = true, callback, perserveThirdPartyCookies, redirectUrl) =>
  async (dispatch: Function, getState): void => {
    const {
      config,
      okta: { token },
    } = getState()

    if (token) {
      const OKTA_REVOKE_ACCESS_TOKEN_URL = `${config.MLCL_OKTA_API}/oauth2/${config.MLCL_OKTA_AUTH_SERVER_ID}/v1/revoke`

      const requestBody = {
        token,
        client_id: config.MLCL_OKTA_CLIENT_ID,
      }

      const headerConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
      axios
        .post(OKTA_REVOKE_ACCESS_TOKEN_URL, qs.stringify(requestBody), headerConfig)
        .then(() => {
          removeAllCookies(perserveThirdPartyCookies)
          // clear sitecore session
          dispatch({ type: OKTA_REVOKE_ACCESS_TOKEN_SUCCESS })
          if (hasSitecoreSession) {
            dispatch({ type: OKTA_SIGNOUT })
            dispatch(clearSitecoreSession(redirectUrl))
            if (callback) {
              callback()
            }
          }
        })
        .catch(revokeTokenFailure => {
          removeAllCookies(perserveThirdPartyCookies)
          dispatch({
            type: OKTA_REVOKE_ACCESS_TOKEN_FAILURE,
            payload: { data: revokeTokenFailure },
          })
          // clear sitecore session
          if (hasSitecoreSession) {
            dispatch(clearSitecoreSession(redirectUrl))
          }
        })
    } else {
      dispatch({
        type: OKTA_REVOKE_ACCESS_TOKEN_FAILURE,
        payload: { data: 'Access token is missing' },
      })
    }
  }

export const signOut =
  (callback?: Fucntion, perserveThirdPartyCookies, redirectUrl): Function =>
  async (dispatch: Function): void => {
    // close current session
    dispatch({ type: OKTA_CLOSE_CURRENT_SESSION })
    await window.authClient.signOut({
      postLogoutRedirectUri: redirectUrl ? `${window.location.origin}${redirectUrl}` : null,
    })
    removeAllCookies(perserveThirdPartyCookies)
  }

// @TODO: this can be removed after MFA login golive
export const oktaSignOut =
  (callback?: Fucntion, perserveThirdPartyCookies, redirectUrl): Function =>
  async (dispatch: Function, getState: Function): void => {
    const { config } = getState()
    if (isFeatureEnabled('SignInRedirect', config.FEATURES)) {
      return dispatch(signOut(callback, perserveThirdPartyCookies, redirectUrl))
    }
    // close current session
    dispatch({ type: OKTA_CLOSE_CURRENT_SESSION })

    await window.authClient
      .signOut()
      .then(() => {
        // now that redux persist is complete, nuke all the cookies.
        // revoke access token
        dispatch({ type: OKTA_REVOKE_ACCESS_TOKEN })
        dispatch(revokeOktaToken(true, callback, perserveThirdPartyCookies, redirectUrl))
      })
      .catch(() => {
        dispatch(revokeOktaToken(true, callback, perserveThirdPartyCookies, redirectUrl))
        dispatch({ type: OKTA_CLOSE_CURRENT_SESSION_FAILURE })
        // clear sitecore session
        dispatch(clearSitecoreSession(redirectUrl))
      })
    return null
  }

const setOktaCookie = ({ accessToken, customerID }) => {
  universalCookie.set(OKTA_COOKIE_NAME, accessToken, {
    // allow us to maintain control from javascript.
    httpOnly: false,
    // don't expose this token data to other domains.
    sameSite: 'Strict',
    // secure cookies if https is enabled.
    secure: /^https/.test(window.location.protocol.toLowerCase()),
  })
  // Setting cookie for devlopent process
  if (
    process.env.NODE_ENV === 'development' &&
    isBrowser() &&
    !universalCookie.get(BANCS_CUSTOMER_NUM_COOKIE_NAME)
  ) {
    universalCookie.set(BANCS_CUSTOMER_NUM_COOKIE_NAME, customerID)
  }
}

export const redirectToLocation = (tokenData, state) => (dispatch, getState) => {
  const { accessToken } = tokenData
  const {
    authentication: { isSupportStaff, hasCompletedWelcomeJourney, isMobileNumberExists, userId },
    config,
  } = getState()

  if (accessToken) {
    // set the session cookie, which will be sent along with requests to sitecore in headers.
    setOktaCookie(tokenData)

    // set userId in local storage to prevent multiple users login
    if (isLocalStorage()) {
      window.localStorage.setItem('userId', userId)
    }

    const thirdPartyQuoteKey = decodeCookie(THIRD_PARTY_QUOTE_COOKIE_NAME)
    const thirdPartySource = decodeCookie(THIRD_PARTY_SOURCE)
    if (thirdPartyQuoteKey) {
      dispatch(transformThirdPartyQuote(thirdPartyQuoteKey, thirdPartySource))
      const event = createEvent({
        GA: {
          category: 'List of quotes-third party',
          action: 'Navigate to quote summary',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'List of quotes-third party - Navigate to quote summary',
          },
        },
      })
      event.end()
    } else {
      // redirect to dashboard
      dispatch({
        type: OKTA_REDIRECT,
      })

      // check if deeplink url exists, redirect user to that url other than dashboard
      const deeplinkUrl = getQueryParameterValueFromURL(state, 'deeplink')
      if (deeplinkUrl) {
        if (deeplinkUrl === CUSTOMER_MAKE_A_PAYMENT) {
          const event = createEvent({
            GA: {
              category: 'Make a payment - Deeplink',
              action: 'CP - make a payment',
            },
            Splunk: {
              attributes: {
                'workflow.name': 'Make a payment - Deeplink',
              },
            },
          })
          event.end()
        }
        if (isSupportStaff) {
          history.push(SUPPORT_STAFF_SELECT_ADVISER_ROUTE, { deeplinkUrl })
        } else {
          history.push(deeplinkUrl)
        }
      } else if (isSupportStaff) {
        history.push(SUPPORT_STAFF_SELECT_ADVISER_ROUTE)
      } else if (config && config.SITE === CUSTOMER_PORTAL && !hasCompletedWelcomeJourney) {
        // redirect to customer contact details page
        history.push(CUSTOMER_PERSONAL_DETAILS + WELCOME_JOURNEY_ANCHOR)
      } else if (!isMobileNumberExists && !hasCompletedWelcomeJourney) {
        // first time adviser login if mobile number does not exist
        history.push(UPDATE_MOBILE)
      } else {
        history.push('/dashboard')
      }
    }
  }
}

export const reAuthorizeUser = () => (dispatch, getState) => {
  const {
    okta: { token, tokenExpiresIn },
  } = getState()

  dispatch({ type: OKTA_REAUTHORIZATION })

  dispatch({
    type: OKTA_AUTHORIZATION,
  })

  const oktaAuthClient = window.authClient
  oktaAuthClient.token
    .renew({
      accessToken: token,
      expiresAt: tokenExpiresIn,
      scopes: [getScope()],
    })
    .then(updatedToken => {
      const decodeToken = oktaAuthClient.token.decode(updatedToken.accessToken)
      const {
        payload: { customerID, exp },
      } = decodeToken

      dispatch({
        type: OKTA_AUTHORIZATION_SUCCESS,
        payload: {
          tokenData: { accessToken: updatedToken.accessToken, expiresAt: exp, customerID },
        },
      })
      dispatch({ type: OKTA_REAUTHORIZATION_SUCCESS })
      // update cookie
      setOktaCookie({ accessToken: updatedToken.accessToken, customerID })
    })
    .catch(error => {
      // logout on error
      dispatch(signOut())
      dispatch({ type: OKTA_AUTHORIZATION_FAILURE, payload: { data: error } })
    })
}

export const authorizeUser = () => (dispatch, getState) => {
  const {
    authentication: { sessionToken, isSupportStaff },
    config,
  } = getState()

  if (sessionToken) {
    dispatch({
      type: OKTA_AUTHORIZATION,
    })

    window.authClient.token
      .getWithoutPrompt({
        sessionToken,
        scopes: [getScope()],
        nonce: nonce()(),
        responseType: 'token',
        prompt: 'none',
        state: uuidv4(),
      })
      .then(tokenData => {
        const decodeToken = window.authClient.token.decode(tokenData.tokens.accessToken.accessToken)
        const {
          payload: { customerID, sub, scp },
        } = decodeToken

        dispatch({
          type: OKTA_AUTHORIZATION_SUCCESS,
          payload: { tokenData: { ...tokenData.tokens.accessToken, customerID } },
        })

        if (isBrowser()) {
          import('@splunk/otel-web').then(({ SplunkRum }) => {
            SplunkRum.setGlobalAttributes({
              'enduser.id': sub,
              'enduser.role': scp[0],
            })
          })
        }

        const event = createEvent({
          GA: {
            category: 'Adviser/customer successfully logs in',
            action: 'AP or CP - Login',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Log in',
            },
          },
        })
        event.end()

        // fetch advisor details
        if (config && config.SITE === ADVISOR_PORTAL) {
          if (isSupportStaff) {
            dispatch(redirectToLocation(tokenData.tokens.accessToken))
          } else {
            dispatch(
              getAdvisorDetails(data => {
                const { bancsAgencyCodes } = data
                // This call is dependend on the adviser details API
                const thirdPartyQuoteKey = decodeCookie(THIRD_PARTY_QUOTE_COOKIE_NAME)
                if (!thirdPartyQuoteKey) {
                  dispatch(getRetentionDashboard({ bancsAgencyCodes }, LIMIT_OF_RETENTION_RECORDS))
                }
                // redirect user to dashboard
                dispatch(redirectToLocation(tokenData.tokens.accessToken))
              })
            )
          }
          dispatch(resetProductsData())
        } else {
          dispatch(redirectToLocation(tokenData.tokens.accessToken))
        }
      })
      .catch(error => dispatch({ type: OKTA_AUTHORIZATION_FAILURE, payload: { data: error } }))
  }
}

export const myLinkSendOtp = (prevAction, config) => {
  const { data } = prevAction.payload
  const { stateToken, _embedded } = data
  const factorId = _embedded.factors.find(factor => factor.factorType.toLowerCase() === 'sms')

  if (factorId && factorId.id) {
    return {
      type: OKTA_API,
      name: MYLINK_OTP_VERIFY_REQUEST,
      verb: 'POST',
      route: `${config.MLCL_OKTA_API}/api/v1/authn/factors/${factorId.id}/verify`,
      data: {
        stateToken,
      },
    }
  }
  return {
    type: MYLINK_PRIMARY_AUTH_FAILURE,
    payload: { data: { hasFactorMissing: true } },
  }
}

export const myLinkSubmitOtp =
  ({ factorId, stateToken, passCode }, callbackFn) =>
  (dispatch: Function, getState: Function) => {
    const { config } = getState()
    return dispatch({
      type: OKTA_API,
      name: MYLINK_OTP_SUBMIT_REQUEST,
      verb: 'POST',
      route: `${config.MLCL_OKTA_API}/api/v1/authn/factors/${factorId}/verify`,
      data: {
        stateToken,
        passCode,
      },
      callback: (errorResponse, { status, data }, dispatchAction) => {
        if (status === 200) {
          if (callbackFn) callbackFn()

          const client = makeMyLinkOktaClient(config)

          client.token
            .getWithoutPrompt({
              sessionToken: data.sessionToken,
              scopes: ['prospect'],
              nonce: 'nonce',
              responseType: 'token',
              prompt: 'none',
            })
            .then(tokenOrTokens => {
              const query = queryString.parse(get(history, 'location.search', ''))

              universalCookie.set(OKTA_COOKIE_NAME, tokenOrTokens.tokens.accessToken.accessToken, {
                httpOnly: false,
                sameSite: 'Strict',
                secure: /^https/.test(window.location.protocol.toLowerCase()),
              })
              universalCookie.set(BANCS_CUSTOMER_NUM_COOKIE_NAME, query.quoteCollectionId)

              dispatchAction({
                type: MYLINK_OTP_GRANT_ACCESS,
                payload: {
                  tokenData: tokenOrTokens.tokens.accessToken,
                  quoteCollectionId: query.quoteCollectionId,
                },
              })
              history.push(CUSTOMER_PERSONAL_STATEMENT_DETAIL_PAGE)
            })
        }
      },
    })
  }

export const myLinkPrimaryAuth =
  ({ quoteCollectionId }) =>
  (dispatch, getState) => {
    const { config } = getState()
    dispatch({
      type: OKTA_API,
      name: MYLINK_PRIMARY_AUTH,
      verb: 'POST',
      data: { username: quoteCollectionId.trim() },
      route: `${config.MLCL_OKTA_API}/api/v1/authn`,
      queuedAction: prevAction => myLinkSendOtp(prevAction, config),
    })
  }

export const resendOTP =
  ({ factorId, stateToken }) =>
  (dispatch, getState) => {
    const { config } = getState()
    toast('OTP requested', {
      autoClose: false,
      toastId: 'TOAST_RESEND_OTP_INIT',
      type: toast.TYPE.INFO,
    })
    dispatch({
      type: OKTA_API,
      name: MYLINK_OTP_RESEND_REQUEST,
      verb: 'POST',
      route: `${config.MLCL_OKTA_API}/api/v1/authn/factors/${factorId}/verify`,
      data: {
        stateToken,
      },
      callback: err => {
        toast.dismiss('TOAST_RESEND_OTP_INIT')
        if (!err) {
          toast('Code has been resent', {
            autoClose: false,
            toastId: 'TOAST_RESEND_OTP_INIT',
            type: toast.TYPE.INFO,
          })
        }
      },
    })
  }
export const showSuccessNotification = () => {
  // display a toast notification which will auto-close.
  toast(TOAST_PASSWORD_CHANGED_SUCCESS, {
    type: toast.TYPE.INFO,
  })

  return hideModal(OKTA_CHANGE_PASSWORD_MODAL)
}

export const showErrorNotification = () => {
  // display a toast notification which will auto-close.
  toast(TOAST_PASSWORD_CHANGE_ERROR, {
    type: toast.TYPE.ERROR,
  })

  return hideModal(OKTA_CHANGE_PASSWORD_MODAL)
}

export const oktaChangePassword =
  ({ oldPassword, newPassword }) =>
  (dispatch, getState) => {
    const {
      config,
      authentication,
      okta: { token },
    } = getState()

    if (authentication.authenticated && authentication.userId) {
      dispatch({
        type: OKTA_API,
        name: OKTA_CHANGE_PASSWORD,
        verb: 'POST',
        data: { oldPassword, newPassword },
        route: `${config.MLCL_OKTA_PROXY_API}/api/v1/users/${authentication.userId}/credentials/change_password`,
        additionalHeaders: { token, bancsCustomerNo: getBancsCustomerNumber() },
        queuedAction: showSuccessNotification,
      })
    } else {
      showErrorNotification()
      dispatch({ type: OKTA_API })
    }
  }

export const oktaResetChangePassword = () => dispatch =>
  dispatch({ type: OKTA_CHANGE_PASSWORD_INIT })

// FIXME: This is only used by Teleunderwriter so should be
// renamed to be distinguished with normal OKTA access grant
export const oktaGrantAccess = (accessToken, expiresAt) => ({
  type: MYLINK_OTP_GRANT_ACCESS,
  payload: {
    tokenData: {
      accessToken,
      expiresAt,
    },
  },
})

export const clearInactiveAdviser = () => dispatch => {
  window.authClient
    .signOut()
    .then(() => {
      // now that redux persist is complete, nuke all the cookies.
      // revoke access token
      dispatch({ type: OKTA_REVOKE_ACCESS_TOKEN })
      dispatch(revokeOktaToken(false))
    })
    .catch(() => dispatch(revokeOktaToken(false)))
}

export const clearMyLinkOktaSession = () => (dispatch, getState) => {
  const { config } = getState()
  dispatch({ type: OKTA_REVOKE_ACCESS_TOKEN })
  dispatch(revokeOktaToken(false))

  const client = makeMyLinkOktaClient(config)

  return client.session.get().then(data => {
    if (data && data.status !== OKTA_SESSION_INACTIVE)
      axios.delete(`${config.MLCL_OKTA_API}/api/v1/sessions/me`, { withCredentials: true })
  })
}
export const resetOktaAuthorizationError = () => ({
  type: OKTA_REVOKE_ERROR_CODE,
})

// this is added for telewriter only because for telewriter we are getting expiry time
// in second but we want in timestamp
export const decodeToken = token => dispatch => {
  if (token && window.authClient) {
    const decodedToken = window.authClient.token.decode(token)
    const {
      payload: { exp },
    } = decodedToken
    dispatch(oktaGrantAccess(token, exp))
  }
}

export const setOktaBancsCustomerNo = bancsCustomerNo => ({
  type: OKTA_SET_BANCS_CUSTOMER_NO,
  payload: bancsCustomerNo,
})

export const authoriseSuccess = (tokenData, state) => (dispatch, getState) => {
  const { config } = getState()
  const decodedToken = window.authClient.token.decode(tokenData.accessToken)
  const {
    payload: {
      customerID,
      sub,
      scp,
      identityType,
      hasCompletedWelcomeJourney,
      HasSeenDigiCommsNotification,
    },
  } = decodedToken

  const isSupportStaff = identityType === OKTA_IDENTITY_TYPE_SUPPORT_STUFF

  dispatch({
    type: OKTA_AUTHORIZATION_SUCCESS,
    payload: { tokenData: { ...tokenData, customerID } },
  })

  dispatch({
    type: OKTA_SET_USER_PROFILE_FROM_REDIRECT,
    payload: {
      hasCompletedWelcomeJourney,
      hasSeenDigitalComms: HasSeenDigiCommsNotification,
      userId: sub,
      isSupportStaff,
    },
  })

  if (isBrowser()) {
    import('@splunk/otel-web').then(({ SplunkRum }) => {
      SplunkRum.setGlobalAttributes({
        'enduser.id': sub,
        'enduser.role': scp[0],
      })
    })
  }

  const event = createEvent({
    GA: {
      category: 'Adviser/customer successfully logs in',
      action: 'Log in',
    },
    Splunk: {
      attributes: {
        'workflow.name': 'Log in',
      },
    },
  })
  event.end()

  // fetch advisor details
  if (config && config.SITE === ADVISOR_PORTAL) {
    if (isSupportStaff) {
      dispatch(redirectToLocation(tokenData, state))
    } else {
      dispatch(
        getAdvisorDetails(data => {
          const { bancsAgencyCodes } = data
          // This call is dependend on the adviser details API
          const thirdPartyQuoteKey = decodeCookie(THIRD_PARTY_QUOTE_COOKIE_NAME)
          if (!thirdPartyQuoteKey) {
            dispatch(getRetentionDashboard({ bancsAgencyCodes }, LIMIT_OF_RETENTION_RECORDS))
          }
          // redirect user to dashboard
          dispatch(redirectToLocation(tokenData, state))
        })
      )
    }
    dispatch(resetProductsData())
  } else {
    dispatch(redirectToLocation(tokenData, state))
  }
}
