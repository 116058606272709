import { space, colours, fontSize, fontWeights, fontFamily } from '../../../styles'

const styles = {
  wrapper: {
    padding: `${space(3, true)} ${space(6, true)} ${space(6, true)}`,
    h3: {
      color: colours.darkGrey,
      fontSize: fontSize.xs,
      lineHeight: space(3, true),
      marginBottom: space(1, true),
      fontWeight: fontWeights.semiBold,
      fontFamily: fontFamily.sourceSans,
    },
  },
  dropdownsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdown: {
    width: 'calc(50% - 16px)',
  },
  chartsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
  chartWrapper: {
    flex: '0 0 auto',
    marginRight: space(0.5, true),
    '&:last-child': {
      marginRight: 0,
    },
  },
  alignment: align => ({
    textAlign: align || 'left',
  }),
  note: {
    fontWeight: fontWeights.semiBold,
    marginBottom: space(10, true),
    minHeight: '24px',
  },
  loaderOuterDiv: {
    width: '100%',
    height: '500px',
  },
  loaderInnerDiv: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  disclaimerBtnWrapper: {
    textAlign: 'right',
  },
}

export default styles
