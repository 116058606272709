/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconFacebook16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.654h-1.453c-1.14 0-1.353.567-1.353 1.384v1.817h2.714l-.361 2.847H9.194V16H6.36V8.702H4V5.855h2.36V3.76C6.36 1.327 7.796 0 9.889 0c1 0 1.861.076 2.111.115v2.54z"
      fill="#000"
    />
  </Icon>
)

export default IconFacebook16
