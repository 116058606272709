// components.
import PopularProtection from '../PopularProtection'
import YourTemplates from '../YourTemplates'
import ChooseCover from '../ChooseCover'

// utils.
import { idMaker } from '../../../../../utils/formUtils'

const schema = props => [
  {
    title: props.fields.choseCoverTabLabel.value,
    id: idMaker(props.fields.choseCoverTabLabel.value),
    component: ChooseCover,
    isVisible: true,
    props,
  },
  {
    title: props.fields.yourTemplatesTabLabel.value,
    id: idMaker(props.fields.yourTemplatesTabLabel.value),
    component: YourTemplates,
    isVisible: true,
    props,
  },
  {
    title: props.fields.popularProtectionTabLabel.value,
    id: idMaker(props.fields.popularProtectionTabLabel.value),
    component: PopularProtection,
    isVisible: props.mlclManagedTemplates.length > 0 && true,
    props,
  },
]

export default schema
