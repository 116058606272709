// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import TemplateItem from '../TemplateItem'

// constants.
import {
  POLICY_PRODUCT_CODE_SUPER,
  UPDATE_FROM_LIFE_INSURED,
  POLICY_BENEFIT_PTD,
  TPD_DEFINITION_OWN,
} from '../../../../../constants/policies'

// utils
import {
  getBenefit,
  getProduct,
  createPolicy,
  convertCoverToEntityStructure,
  getMLCLPolicyStructureDetails,
  generateProductName,
  filterPolicyStructure,
  postProcessInitialPolicyStructure,
  isNonSuperProduct,
} from '../../../../../utils/quoteUtils'
import { convertPolicyStrutureForOptimiser } from '../../../../../utils/extendedQuoteUtils'
import { convertStringToKey, deepEqual } from '../../../../../utils/commonUtils'

// styles.
import styles from './PopularProtection.styles'

const Container = styled('div')(styles.container)

type PopularProtectionProps = {
  data: Object,
  // mandatory details filled by user.
  memberMandatories: Object,
  // To render all labels from sitecore content editor
  fields: {},
  // redux action object
  actions: {
    // close sidebar action
    closeSidebar: Function,
    // update policy structure in redux
    createQuoteUpdatePolicyStructure: Function,
  },
  // React router object to handle routes
  history: {
    // Method to change routes.
    push: Function,
  },
  // next callback
  handleNext: Function,
  // raw productRules
  productRules: Array,
  // advisor details
  advisorDetails: Object,
  // master data
  masterData: Object,
  calculateSaveQuote: Object,
  // value of Health Living toggle button
  option: Object,
}

type PopularProtectionState = {
  // filtered list of template
  templateItems: Object<Object>,
}

const createTemplatesState = (data, productRules) =>
  data.reduce((prev, { templateDisplayName, policyStructure }) => {
    const templateId = convertStringToKey(templateDisplayName)

    const policyStructureWithDetails = getMLCLPolicyStructureDetails(policyStructure, productRules)

    const obj = prev
    obj[templateId] = {
      templateId,
      templateDisplayName,
      templatePolicyStructureDetail: policyStructureWithDetails.length
        ? policyStructureWithDetails
        : [],
    }
    return obj
  }, {})

export class PopularProtection extends Component<PopularProtectionProps, PopularProtectionState> {
  constructor() {
    super()
    this.state = {
      templateItems: {},
    }
  }

  componentDidMount() {
    const { data, productRules, memberMandatories } = this.props
    this.setTemplateState(data, productRules, memberMandatories)
  }

  componentDidUpdate(prevProps) {
    const { data, productRules, memberMandatories } = this.props
    if (data !== prevProps.data) {
      this.setTemplateState(data, productRules, memberMandatories)
    }
  }

  setTemplateState = (data, productRules) => {
    const templateItems = data ? createTemplatesState(data, productRules) : {}
    this.setState({ templateItems })
  }

  clickHandler = ({ policyStructure }) => {
    const {
      actions: { closeSidebar, createQuoteSetPolicyStructure, setIPSumInsured },
      history,
      handleNext,
      productRules,
      advisorDetails,
      memberMandatories,
      masterData,
      option: quoteOption,
      calculateSaveQuote: { isIPCalculated },
    } = this.props

    const formattedPolicyStructure = policyStructure.map(policy => {
      const { policyInstanceNo, productId, covers } = policy
      const formattedProduct = getProduct(productRules, productId)
      const formattedCovers =
        covers.length &&
        covers.map(cover => {
          const getCoverDetails = getBenefit(formattedProduct.benefits, cover.type)
          // return cover in headlessQuoteEntity form
          const getCoverHeadlessEntityStructure = convertCoverToEntityStructure(
            getCoverDetails,
            undefined,
            advisorDetails,
            undefined,
            undefined,
            memberMandatories,
            quoteOption,
            policy
          )
          return {
            ...getCoverHeadlessEntityStructure,
            ...cover,
            ...(cover.type === POLICY_BENEFIT_PTD &&
            cover.optimiserParentBenefitReference &&
            isNonSuperProduct(policy.productId)
              ? {
                  tpdDefinition: TPD_DEFINITION_OWN,
                }
              : null),
          }
        })

      // return policy in headlessQuoteEntity form
      const getPolicyHeadlessEntityStructure = createPolicy(
        productRules,
        formattedProduct,
        undefined,
        undefined,
        advisorDetails,
        undefined,
        memberMandatories,
        quoteOption
      )
      return {
        ...getPolicyHeadlessEntityStructure,
        policyInstanceNo,
        productId,
        productName: generateProductName(policyInstanceNo),
        covers: formattedCovers,
      }
    })
    const { updatedPolicyStructure, calculatedsumInsured } = postProcessInitialPolicyStructure(
      productRules,
      memberMandatories,
      formattedPolicyStructure,
      masterData,
      isIPCalculated
    )
    setIPSumInsured(calculatedsumInsured)
    createQuoteSetPolicyStructure(updatedPolicyStructure)

    const tagEvent = createEvent({
      GA: {
        category: 'MLCL managed templates',
        action: 'Select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'MLCL managed templates',
        },
      },
    })
    tagEvent.end()

    if (policyStructure.filter(e => e.productId === POLICY_PRODUCT_CODE_SUPER).length) {
      handleNext()
    } else {
      history.push('/quote-tool')
      closeSidebar()
    }
  }

  renderTemplateList = () => {
    const { templateItems } = this.state
    const {
      fields: { popularProtectionViewDetails, popularProtectionCloseDetails },
      productRules,
      memberMandatories,
      masterData,
    } = this.props

    return Object.keys(templateItems).map(templateKey => {
      const { templateId, templateDisplayName, templatePolicyStructureDetail } =
        templateItems[templateKey]
      // Deriving and updating parentBenefitReference and
      // optimiserParentBenefitReference for optimiser benefits
      const policyStructureWithOptimiser = convertPolicyStrutureForOptimiser(
        templatePolicyStructureDetail
      )
      const updatedPolicyStructure = filterPolicyStructure(
        policyStructureWithOptimiser,
        memberMandatories,
        productRules,
        UPDATE_FROM_LIFE_INSURED,
        masterData
      )
      const isTemplatedDisabled = !deepEqual(policyStructureWithOptimiser, updatedPolicyStructure)

      return (
        <li key={`${templateId}_${templateDisplayName}`}>
          <TemplateItem
            id={templateId}
            templateName={templateDisplayName}
            templateDisabled={isTemplatedDisabled}
            viewDetailsLabel={popularProtectionViewDetails.value}
            closeDetailsLabel={popularProtectionCloseDetails.value}
            handleTemplateClick={this.clickHandler}
            policyStructure={policyStructureWithOptimiser}
            productRules={productRules}
          />
        </li>
      )
    })
  }

  render() {
    return (
      <Container>
        <ul>{this.renderTemplateList()}</ul>
      </Container>
    )
  }
}

const mapStateToProps = ({
  quoteTemplates,
  productRules,
  advisor,
  createQuote,
  masterList,
  calculateSaveQuote,
}) => ({
  data: quoteTemplates.mlclManaged,
  productRules: productRules.data,
  advisorDetails: advisor.details,
  memberMandatories: createQuote.quotes[0].memberMandatories || null,
  masterData: masterList.data,
  calculateSaveQuote,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PopularProtection))
