export const RETENTION_DASHBOARD_FETCH = 'RETENTION_DASHBOARD_FETCH'
export const RETENTION_DASHBOARD_FETCH_INIT = 'RETENTION_DASHBOARD_FETCH_INIT'
export const RETENTION_DASHBOARD_FETCH_SUCCESS = 'RETENTION_DASHBOARD_FETCH_SUCCESS'
export const RETENTION_DASHBOARD_FETCH_ERROR = 'RETENTION_DASHBOARD_FETCH_ERROR'
export const RETENTION_DASHBOARD_FETCH_FAILURE = 'RETENTION_DASHBOARD_FETCH_FAILURE'
export const RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH = 'RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH'
export const RETENTION_DASHBOARD_NEW_PREMIUMS_FETCH_COMPLETE =
  'RETENTION_DASHBOARD_NEW_PREMIUMS_FETCH_COMPLETE'
export const RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH_INIT =
  'RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH_INIT'
export const RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH_SUCCESS =
  'RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH_SUCCESS'
export const RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH_ERROR = 'RETENTION_DASHBOARD_FETCH_ERROR'
export const RETENTION_DASHBOARD_POLICIES_DETAIL_FETCH_FAILURE = 'RETENTION_DASHBOARD_FETCH_FAILURE'
export const RETENTION_DASHBOARD_NEWPREMIUM_FETCH_LOADING =
  'RETENTION_DASHBOARD_NEWPREMIUM_FETCH_LOADING'

export const TOAST_AUTOCLOSE_DELAY = 3000
export const TOAST_RETENTION_DASHBOARD_ID = 'retentionDashboard '
export const TOAST_RETENTION_DASHBOARD_FETCH = 'Triggering Retention Dashboard '
export const TOAST_RETENTION_DASHBOARD_FETCH_SUCCESS = 'Retention Dashboard successfully'
export const TOAST_RETENTION_DASHBOARD_FETCH_ERROR = 'Error in Retention Dashboard'
