// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import get from 'lodash/get'

// redux.
import { useSelector, useDispatch } from 'react-redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'
import { getRetentionTableData } from './utils'

// components.
import WithLoader from '../../molecules/WithLoader'
import RetentionDashboardNew from './components/RetentionNew'

// utils.
import { reduceAuthorableFields, renderTextField } from '../../../utils/sitecoreUtils'
import { getPolicyDetailsByMemberId, getPolicyIDBySearchInput } from '../../../utils/policyUtils'
import history from '../../../utils/browserHistory'
import { getConfig } from '../../../selectors/common.selectors'

import retentionDashboardTabs from './components/Tab'

// constants
import {
  POLICY_ANNIVERSARY_ID,
  RISK_OF_LAPSE_ID,
  RECENTLY_LAPSE_ID,
  RECENTLY_CANCELLED_ID,
} from '../../../constants/dashboard'
import {
  TOAST_ID_RETENTION_POLICY_ERROR,
  TOAST_RETENTION_CALL_POLICY_FAIL,
} from '../../../constants/toast'

// utils
import { isBrowser } from '../../../utils/browserUtils'
import { isFeatureEnabled } from '../../../utils/featureToggling'

// Tile Component
import { RetentionTabContainer } from './components/TabContainer'

type RetentionDashboardProps = {
  // Sitecore authorable fields.
  fields: Object<Object>,
  tileSchema: Array<{
    label: String,
    value: String | Number,
    id: String,
  }>,
  downloadTitle: string,
  // An object containing action creator functions.
  actions: {
    getRetentionDashboard: Function,
    getPoliciesNewpremium: Function,
  },
  tableData: {
    [POLICY_ANNIVERSARY_ID]: Object,
    [RISK_OF_LAPSE_ID]: Object,
    [RECENTLY_LAPSE_ID]: Object,
    [RECENTLY_CANCELLED_ID]: Object,
  }, // Array of search results mapped to props from state from member search
  dashboardDetail: Array<{
    anniversary: Object,
    riskOfLapse: Object,
    lapsed: Object,
    cancelled: Object,
    isLoading: Boolean,
  }>,
  policyStatus: Array<{
    status: String,
  }>,
  isPoliciesFetchingError: Boolean,
}

const showPolicyCallFailureToast = () =>
  toast(TOAST_RETENTION_CALL_POLICY_FAIL, {
    autoClose: 3000,
    toastId: TOAST_ID_RETENTION_POLICY_ERROR,
    type: toast.TYPE.ERROR,
  })
const RetentionDashboard = (props: RetentionDashboardProps) => {
  const {
    fields,
    tileSchema,
    downloadTitle,
    tableData,
    actions,
    policyStatus,
    dashboardDetail,
    isPoliciesFetchingError,
  } = props

  const {
    policyAnniversaryNoDataMessage,
    recentlyLapseNoDataMessage,
    recentlyCancelledNoDataMessage,
    riskOfLapseNoDataMessage,
  } = fields

  const hashTabIndexMapping = {
    [RECENTLY_LAPSE_ID]: 0,
    [RISK_OF_LAPSE_ID]: 1,
    [POLICY_ANNIVERSARY_ID]: 2,
    [RECENTLY_CANCELLED_ID]: 3,
  }

  const getHash = () => {
    const hash = get(window, 'location.hash')
    if (hash === '') return POLICY_ANNIVERSARY_ID
    return hashTabIndexMapping[hash.substr(1)]
  }

  const gaEvent = {
    [RECENTLY_LAPSE_ID]: {
      category: 'Lapsed list',
      action: 'View',
    },
    [RISK_OF_LAPSE_ID]: {
      category: 'Missed payment list',
      action: 'View',
    },
    [POLICY_ANNIVERSARY_ID]: {
      category: 'Policy anniversary list',
      action: 'View',
    },
    [RECENTLY_CANCELLED_ID]: {
      category: 'Cancelled list',
      action: 'View',
    },
  }
  const [activeTab, setActiveTab] = useState(isBrowser() ? getHash() : POLICY_ANNIVERSARY_ID)
  const [canPolicyNewPremiumFetching, setCanPolicyNewPremiumFetching] = useState(false)

  const fireGAEvent = id => {
    const tagEvent = createEvent({
      GA: typeof id === 'number' ? gaEvent[Object.keys(gaEvent)[id]] : gaEvent[id],
      Splunk: {
        attributes: {
          'workflow.name': 'retention dashboard tab click',
        },
      },
    })
    tagEvent.end()
  }

  useEffect(() => {
    actions.getRetentionDashboard()
    setCanPolicyNewPremiumFetching(true)
    fireGAEvent(activeTab)
  }, [])

  const [policyDataFetching, setPolicyDataFetching] = useState(false)

  const getAdviserPolicy = useCallback(
    (bancsPartyNo, isSMSF, policyNo) => {
      actions.getPolicies(bancsPartyNo, isSMSF, data => {
        setPolicyDataFetching(false)
        const { businessData } = data
        if (!businessData) {
          showPolicyCallFailureToast()
        } else {
          const { policies } = businessData
          const policyId = getPolicyIDBySearchInput(policies, policyStatus, policyNo)
          if (!policyId) {
            showPolicyCallFailureToast()
          } else {
            const path = `/clients/details?clientId=${bancsPartyNo}&policyId=${policyId}&isSMSF=${isSMSF}&fromSearch=true`
            history.push(path)
          }
        }
      })
    },
    [setPolicyDataFetching, actions.getPolicies]
  )

  const getPolicyDetailsNRedirect = useCallback(
    policyNo => {
      if (policyNo) {
        setPolicyDataFetching(true)
        actions.searchPolicies(policyNo, (err, data) => {
          if (err) {
            setPolicyDataFetching(false)
            showPolicyCallFailureToast()
          } else {
            getPolicyDetailsByMemberId(data, getAdviserPolicy, policyNo)
          }
        })
      }
    },
    [setPolicyDataFetching, actions.searchPolicies]
  )

  const getAllPoliciesNewPremium = async (policies, startIndex, endIndex) => {
    const { getPoliciesNewpremium } = actions
    const policiesNewpremiums = await getPoliciesNewpremium(policies, startIndex, endIndex)
    return policiesNewpremiums
  }

  const tableDataKeys = Object.keys(tableData)
  tableDataKeys[0] = tableDataKeys.splice(2, 1, tableDataKeys[0])[0]
  const { columns, data, csvFileName } =
    tableData[typeof activeTab === 'number' ? tableDataKeys[activeTab] : activeTab]

  const ErrorStateMsg = {
    [RECENTLY_LAPSE_ID]: recentlyLapseNoDataMessage,
    [RISK_OF_LAPSE_ID]: riskOfLapseNoDataMessage,
    [POLICY_ANNIVERSARY_ID]: policyAnniversaryNoDataMessage,
    [RECENTLY_CANCELLED_ID]: recentlyCancelledNoDataMessage,
  }

  const onTabClickHandler = useCallback(id => {
    setActiveTab(id)
    fireGAEvent(id)
  })

  const isRentionNewPremiumEnabled = isFeatureEnabled(
    'retentionDashboardNewPremium',
    useSelector(getConfig).FEATURES
  )

  return (
    <WithLoader isLoading={policyDataFetching && !isPoliciesFetchingError} overlay>
      <RetentionDashboardNew
        data={data}
        tabSchema={tileSchema}
        onClick={onTabClickHandler}
        activeTab={activeTab}
        data-testid={`retention-table-${activeTab}`}
        downloadTitle={downloadTitle}
        fields={reduceAuthorableFields(fields)}
        columns={columns}
        csvFileName={csvFileName}
        policies={data.policies}
        rowClickHandler={getPolicyDetailsNRedirect}
        getAllPoliciesNewPremium={getAllPoliciesNewPremium}
        canPolicyNewPremiumFetching={canPolicyNewPremiumFetching}
        isNewPremiumLoading={dashboardDetail.isNewPremiumLoading}
        isDownloadListLoading={dashboardDetail.isDownloadListLoading}
        getClientDetails={actions.getClientDetails}
        message={renderTextField(
          ErrorStateMsg[
            typeof activeTab === 'number' ? Object.keys(ErrorStateMsg)[activeTab] : activeTab
          ]
        )}
        showBackground={false}
        emptyState
        pagesNewPremiumLoadedList={dashboardDetail.anniversary.pagesNewPremiumLoaded}
        isRentionNewPremiumEnabled={isRentionNewPremiumEnabled}
      />
    </WithLoader>
  )
}

type RetentionDashboardContainerProps = {
  fields: Object,
}

const RetentionDashboardContainer = ({ fields }: RetentionDashboardContainerProps) => {
  const dispatch = useDispatch()

  const sitecoreFields = reduceAuthorableFields(fields)

  const { advisorDetails } = useSelector(state => state.advisor)
  const { dashboardDetail } = useSelector(state => state.retentionDashboard)
  const masterList = useSelector(state => state.masterList)

  const { hasFetchError: isSearchPolicyFetchError } = useSelector(state => state.searchPolicy)
  const { hasFetchError: isPoliciesFetchError } = useSelector(state => state.policies)

  const isSearchPoliciesAndPoliciesFetchError = Boolean(
    isSearchPolicyFetchError || isPoliciesFetchError
  )

  /** redux actions */
  const getRetentionDashboard = useCallback(() => {
    const bancsAgencyCodes = advisorDetails.map(({ bancsAgencyCode }) => bancsAgencyCode)
    dispatch(actionCreators.getRetentionDashboard({ bancsAgencyCodes }, dashboardDetail.limit))
  }, [advisorDetails, dashboardDetail.limit])
  const getPolicies = (bancsPartyNo, isSMSF, cb) =>
    dispatch(actionCreators.getPolicies(bancsPartyNo, isSMSF, cb))
  const searchPolicies = (policyNo, cb) => dispatch(actionCreators.searchPolicies(policyNo, cb))
  const getPoliciesNewpremium = (policies, startIndex, endIndex) =>
    dispatch(actionCreators.getPoliciesNewpremium(policies, startIndex, endIndex))
  const getClientDetails = (bancsCustomerNumber, callback) =>
    dispatch(actionCreators.getClientDetails(bancsCustomerNumber, callback))
  const tileSchema = retentionDashboardTabs({ fields: sitecoreFields }, dashboardDetail)
  // swap element 0 and 2 to match redesign requirement
  tileSchema[0] = tileSchema.splice(2, 1, tileSchema[0])[0]

  return (
    <RetentionDashboard
      tileSchema={tileSchema}
      dashboardDetail={dashboardDetail}
      policyStatus={get(masterList, 'data.policyStatus', [])}
      downloadTitle={sitecoreFields.retentionDashboardDownloadTitle}
      retentionDataLoading={dashboardDetail.isLoading}
      isPoliciesFetchingError={isSearchPoliciesAndPoliciesFetchError}
      actions={{
        getRetentionDashboard,
        getPolicies,
        searchPolicies,
        getPoliciesNewpremium,
        getClientDetails,
      }}
      tableData={getRetentionTableData(dashboardDetail, sitecoreFields)}
      fields={fields}
    />
  )
}

export default RetentionDashboardContainer
export { RetentionTabContainer as RetentionDashboard }
