// @flow
import { convertStringToKey } from './commonUtils'
// constantsC3196305
import {
  MIME_TPYE_PDF,
  MIME_TPYE_PNG,
  MIME_TPYE_JPG_OR_JPEG,
  MIME_TPYE_TIFF,
} from '../constants/fileUpload'

export const getFileName = fileName => {
  const n = fileName.lastIndexOf('.')
  return n > -1 ? fileName.substr(0, n) : fileName
}
/**
 *
 * @param {Array} files
 * @param {String} fileId
 * @param {Object} payload
 *
 * returns array for update files list
 */
export const updateFileList = (files, fileId, payload) =>
  files.map(fileData => {
    if (fileData.fileId === fileId) {
      return {
        ...fileData,
        ...payload,
      }
    }
    return fileData
  })

export const checkMimeType = (file: Object) => {
  // list allow mime type
  const types = [MIME_TPYE_PDF, MIME_TPYE_PNG, MIME_TPYE_JPG_OR_JPEG, MIME_TPYE_TIFF]
  return types.every(type => file.type !== type)
}

/**
 *
 * @param {Number} fileSize // in bytes
 *
 * return file size in kb
 */
export const convertByteFileSizeToKB = fileSize => fileSize / 1024

/**
 *
 * @param {Array} files
 *
 * returns array of files which are uploaded succesfully
 */
export const checkUploadedFilesError = files =>
  files.filter(fileData => fileData && (fileData.hasValidationError || fileData.hasNetworkError))

export const checkFileValidationError = files => files.filter(file => file.hasValidationError)

/**
 * Returns file key by appending timestamp
 *
 * @param {Object} file object
 */

export const generateFileKey = file => {
  const randomStr = Math.random().toString(36).substring(7)
  return `${convertStringToKey(file.name)}__${randomStr}`
}

export const checkIsUploadProgress = files =>
  files.filter(
    file => file.loading && file.loading > 0 && file.loading < 100 && !file.hasNetworkError
  )

/** Returns workItem object
 *
 * @param {Array} workItemInfo
 * @param {String} workTypeCode
 */

export const fetchWorkItemNo = (workItemInfo, workTypeCode, bancsPolicyNo, bancsCustomerNo) =>
  workItemInfo.filter(
    workItem =>
      workItem.workTypeCode === workTypeCode &&
      (!bancsCustomerNo || bancsCustomerNo === workItem.bancsCustomerNo) &&
      (!bancsPolicyNo || bancsPolicyNo === workItem.bancsPolicyNo)
  )

export const trimFileNameByChar = (fileName, length) => {
  const trimExtension = fileName.replace(/\.[^/.]+$/, '')
  return trimExtension.length > length ? `${trimExtension.substring(0, length)}...` : fileName
}

export const getDocumentTypeDetails = (documentName, docTypesMasterData) => {
  if (documentName && docTypesMasterData) {
    return docTypesMasterData.filter(document => document.documentTypeName === documentName)
  }
  return {}
}
