/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconVK32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.092 13.769c-2.425 3.296-2.686 2.99-.688 4.893 1.925 1.826 2.324 2.708 2.387 2.824 0 0 .8 1.43-.887 1.442l-3.199.052c-.687.14-1.6-.498-1.6-.498-1.2-.843-2.323-3.029-3.198-2.748 0 0-.899.295-.874 2.263.011.42-.189.65-.189.65s-.224.244-.662.281h-1.436c-3.16.205-5.948-2.772-5.948-2.772s-3.049-3.219-5.724-9.645c-.175-.422.014-.627.014-.627s.188-.242.713-.242l3.422-.025c.325.051.55.23.55.23s.2.14.3.408a25.929 25.929 0 0 0 1.288 2.735c1.248 2.63 2.098 3.079 2.585 2.81 0 0 .637-.396.5-3.577-.049-1.15-.361-1.675-.361-1.675-.289-.395-.825-.51-1.063-.548-.187-.025.125-.486.538-.69.612-.307 1.7-.319 2.986-.307 1.012.013 1.3.077 1.687.167 1.186.294.786 1.417.786 4.126 0 .87-.16 2.083.45 2.478.263.179.914.026 2.512-2.76 0 0 .75-1.328 1.337-2.873.1-.281.312-.397.312-.397s.2-.114.476-.076l3.597-.026c1.088-.14 1.263.37 1.263.37.188.525-.4 1.752-1.874 3.757z"
      fill="#000"
    />
  </Icon>
)

export default IconVK32
