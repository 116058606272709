export const TASK_DASHBOARD_FETCH = 'TASK_DASHBOARD_FETCH'
export const TASK_DASHBOARD_FETCH_INIT = 'TASK_DASHBOARD_FETCH_INIT'
export const TASK_DASHBOARD_FETCH_SUCCESS = 'TASK_DASHBOARD_FETCH_SUCCESS'
export const TASK_DASHBOARD_FETCH_ERROR = 'TASK_DASHBOARD_FETCH_ERROR'
export const TASK_DASHBOARD_FETCH_FAILURE = 'TASK_DASHBOARD_FETCH_FAILURE'

export const TASK_DASHBOARD_FILTER = 'TASK_DASHBOARD_FILTER'

export const TOAST_AUTOCLOSE_DELAY = 3000
export const TASK_DASHBOARD_ID = 'retentionDashboard '
export const TOAST_TASK_DASHBOARD_FETCH = 'Triggering Retention Dashboard '
export const TOAST_TASK_DASHBOARD_FETCH_SUCCESS = 'Retention Dashboard successfully'
export const TOAST_TASK_DASHBOARD_FETCH_ERROR = 'Error in Retention Dashboard'

export const GENERATE_FREE_TEXT = 'GENERATE_FREE_TEXT'
export const GENERATE_FREE_TEXT_INIT = 'GENERATE_FREE_TEXT_INIT'
export const GENERATE_FREE_TEXT_SUCCESS = 'GENERATE_FREE_TEXT_SUCCESS'
export const GENERATE_FREE_TEXT_ERROR = 'GENERATE_FREE_TEXT_ERROR'
export const GENERATE_FREE_TEXT_FAILURE = 'GENERATE_FREE_TEXT_FAILURE'
