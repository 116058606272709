// @flow

// Map some field identifiers to Sitecore field names.
const QUOTE_COLLECTION_NAME_FIELDS_MAPPING = {
  title: 'quoteCollectionNameTitle',
  description: 'quoteCollectionNameDescription',
  firstName: 'quoteCollectionFirstNameLabel',
  firstNameError: 'quoteCollectionFirstNameError',
  invalidFirstNameError: 'quoteCollectionInvalidFirstNameError',
  lastName: 'quoteCollectionLastNameLabel',
  lastNameError: 'quoteCollectionLastNameError',
  invalidLastNameError: 'quoteCollectionInvalidLastNameError',
  submitButton: 'quoteCollectionNameSubmitButtonLabel',
}

const EXIT_COLLECTION_NAME_FIELDS_MAPPING = {
  title: 'dashboardConfirmQuoteModalHeading',
  description: 'dashboardConfirmQuoteModalDescription',
  firstName: 'quoteCollectionFirstNameLabel',
  firstNameError: 'quoteCollectionFirstNameError',
  invalidFirstNameError: 'quoteCollectionInvalidFirstNameError',
  lastName: 'quoteCollectionLastNameLabel',
  lastNameError: 'quoteCollectionLastNameError',
  invalidLastNameError: 'quoteCollectionInvalidLastNameError',
  submitButton: 'dashboardConfirmQuoteModalSaveBtn',
  exitBtn: 'exitToDashboardBtn',
}

export type QuoteCollectionNameFields = {
  title: string,
  description: string,
  firstName: string,
  firstNameError: string,
  invalidFirstNameError: string,
  lastName: string,
  lastNameError: string,
  invalidLastNameError: string,
  submitButton: string,
}

// Generate a `QuotecollectionNameFields` object containing all the Sitecore fields used
// in quote collection name. It uses the values in `QUOTE_COLLECTION_NAME_FIELDS_MAPPING` to lookup
// the data in a Sitecore fields collection.
export const getQuotecollectionNameFields = (
  fields: {
    [string]: string,
  },
  exitToDashboard: boolean
): QuoteCollectionNameFields => {
  const collection = exitToDashboard
    ? EXIT_COLLECTION_NAME_FIELDS_MAPPING
    : QUOTE_COLLECTION_NAME_FIELDS_MAPPING
  return Object.keys(collection).reduce(
    (quoteCollectionNameFields, name) => ({
      ...quoteCollectionNameFields,
      [name]: fields[collection[name]],
    }),
    {}
  )
}
