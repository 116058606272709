import { fontSize, colours, fontWeights, space } from '../../../styles'

const styles = {
  root: {
    marginTop: space(-2, true),
    marginBottom: space(2, true),
  },

  policyName: {
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    background: colours.offWhite,
    padding: `${space(1, true)} ${space(2, true)}`,
    marginBottom: 0,
    color: colours.black,
  },

  tree: {
    listStyle: 'none',
    padding: `0 ${space(2, true)} ${space(2, true)} ${space(2, true)}`,
  },

  treeChildren: {
    lineHeight: space(3, true),
    fontSize: fontSize.xs,
    paddingTop: space(2, true),
  },

  treeChildrenItemContainer: {
    listStyle: 'none',
    paddingLeft: space(3, true),
    paddingTop: space(1, true),
  },

  treeChildrenLeafItem: {
    margin: 0,
    paddingTop: space(1, true),
    position: 'relative',
    lineHeight: space(3, true),
    fontSize: fontSize.xs,
    color: colours.black,
    paddingLeft: space(1, true),
    '&::before': {
      position: 'absolute',
      top: '-10px',
      left: `-${space(2, true)}`,
      height: space(4, true),
      width: space(2, true),
      borderBottom: `0.5px dashed ${colours.lightestGrey}`,
      borderLeft: `0.5px dashed ${colours.lightestGrey}`,
      content: '""',
      display: 'inline-block',
    },
  },

  treeChildrenLeafLabel: isLinkedToSamePolicy => ({
    marginBottom: space(1, true),
    color: !isLinkedToSamePolicy ? colours.lightGrey : colours.black,
  }),
}

export default styles
