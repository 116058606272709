/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconClose32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 18.121l6.94 6.94 2.12-2.122L18.122 16l6.94-6.94-2.122-2.12L16 13.878l-6.94-6.94-2.12 2.122L13.878 16l-6.94 6.94 2.122 2.12L16 18.122z"
      fill="#000"
    />
  </Icon>
)

export default IconClose32
