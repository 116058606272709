import { space, setColumns } from '../../../../../styles'

const styles = {
  base: {
    padding: space(3),
    width: 400,
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
  },
  title: {
    marginRight: `calc(50% + ${space(1, true)})`,
    width: `calc(50% - ${space(1, true)})`,
  },
  postalAddressCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  halfColumn: {
    width: `calc(50% - ${space(1, true)})`,
  },
}
export default styles
