/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconUser32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 29c3 0 3-1 3-5 0-1.457-1.591-2.913-5.934-3.597C20.446 20.148 18.445 20 16 20s-4.447.148-6.066.403C5.592 21.087 4 22.543 4 24c0 4 0 5 3 5h18zM10.791 17.261C11.821 18.27 13.445 19 16 19c2.555 0 4.178-.731 5.209-1.739C22.944 15.566 23 13.088 23 12c0-1.733-.143-8-7-8s-7 6.266-7 8c0 1.087.056 3.566 1.791 5.261zM25 29v-2.445V29zm-.024-3c.02-.413.023-.923.024-1.598a4.065 4.065 0 0 0-.74-.367C22.905 23.493 20.348 23 16 23c-4.347 0-6.904.493-8.26 1.035a4.067 4.067 0 0 0-.74.367c0 .675.005 1.185.024 1.598h17.952zM6.84 24.54c-.004 0 .008-.019.046-.052-.024.037-.043.053-.046.052zm18.32 0c-.003.001-.022-.015-.046-.052.038.033.05.052.046.052zM18.39 7.546C17.965 7.28 17.244 7 16 7c-1.244 0-1.965.279-2.39.546-.43.27-.75.65-.999 1.147C12.06 9.796 12 11.17 12 12c0 .836.067 1.86.521 2.64.193.332.463.628.903.862.457.244 1.245.498 2.576.498 1.33 0 2.12-.254 2.576-.498.44-.234.71-.53.903-.862.454-.78.521-1.804.521-2.64 0-.83-.059-2.204-.611-3.307-.25-.497-.57-.877-.999-1.147z"
      fill="#000"
    />
  </Icon>
)

export default IconUser32
