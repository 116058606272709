// @flow
import { SITECORE_API } from '../types/sitecoreApi'
import { SITECORE_API_BASE } from '../../constants/apis'
import { PRODUCT_RULES_FETCH, PRODUCT_RULES_RESET } from '../types/productRules'

export const getAllProducts = queuedActionCallback => ({
  type: SITECORE_API,
  name: PRODUCT_RULES_FETCH,
  verb: 'GET',
  route: `${SITECORE_API_BASE}/products`,
  callback: (err, dataReceived) => dataReceived,
  queuedAction: queuedActionCallback || null,
})

export const resetProductsData = () => ({
  type: PRODUCT_RULES_RESET,
})
