export const QUOTE_TEMPLATE_GET_ADVISOR_MANAGED = 'QUOTE_TEMPLATE_GET_ADVISOR_MANAGED'
export const QUOTE_TEMPLATE_GET_ADVISOR_MANAGED_SUCCESS =
  'QUOTE_TEMPLATE_GET_ADVISOR_MANAGED_SUCCESS'

export const QUOTE_TEMPLATE_GET_POPULAR_PROTECTION = 'QUOTE_TEMPLATE_GET_POPULAR_PROTECTION'
export const QUOTE_TEMPLATE_GET_POPULAR_PROTECTION_SUCCESS =
  'QUOTE_TEMPLATE_GET_POPULAR_PROTECTION_SUCCESS'

export const QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE =
  'QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE'
export const QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE_SUCCESS =
  'QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE_SUCCESS'

export const QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE =
  'QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE'
export const QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE_SUCCESS =
  'QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE_SUCCESS'

export const QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE =
  'QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE'
export const QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_SUCCESS =
  'QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_SUCCESS'

export const QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_ERROR =
  'QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_ERROR'
export const QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_FAILURE =
  'QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_FAILURE'

export const QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL = 'QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL'
