export const ABN_LOOK_UP = 'ABN_LOOK_UP'
export const ABN_LOOK_UP_RESET = 'ABN_LOOK_UP_RESET'
export const ABN_LOOK_UP_INIT = 'ABN_LOOK_UP_INIT'
export const ABN_LOOK_UP_SUCCESS = 'ABN_LOOK_UP_SUCCESS'
export const ABN_LOOK_UP_FAILURE = 'ABN_LOOK_UP_FAILURE'
export const ABN_LOOK_UP_ERROR = 'ABN_LOOK_UP_FAILURE'
export const FUND_LOOKUP = 'FUND_LOOKUP'
export const FUND_LOOKUP_INIT = 'FUND_LOOKUP_INIT'
export const FUND_LOOKUP_SUCCESS = 'FUND_LOOKUP_SUCCESS'
export const FUND_LOOKUP_FAILURE = 'FUND_LOOKUP_FAILURE'
export const FUND_LOOKUP_ERROR = 'FUND_LOOKUP_ERROR'
export const FUND_LOOKUP_RESET = 'FUND_LOOKUP_RESET'
