// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Heading, Variables, Chip } from '@mlcl-digital/mlcl-design'
// components.
import { IconChevronRight16, IconEdit16, IconClose16 } from '../../atoms/Icons'

// locators.
import {
  LOCATOR_SIDEBAR_NAV_ITEM_LABEL,
  LOCATOR_SIDEBAR_NAV_ITEM_DESC,
} from './sidebarNavItem.locators'

// styles.
import styles from './sidebarNavItem.styles'
import { getThemeProp, themeConsumer } from '../../../styles/ThemeContext'

const { colours } = Variables
type SidebarNavItemProps = {
  // A function to be fired when nav item is clicked
  clickHandler: Function,
  // title of nav item
  title: boolean,
  // Description for nav item
  description?: boolean,
  // Disable use of nav item
  disabled?: boolean,
  // Define if item is first
  first?: boolean,
  // to style description link
  descriptionActive?: boolean,
  // A function to be fired when It has link in description clicked
  descriptionClickHandler?: Function,
  // Use themed version of componnet
  themed?: boolean,
  // item label data-locator
  labelDataLocator?: string,
  // item desc data-locator
  descDataLocator?: string,
  // Determine if item belongs to first sidebar panel for styling purposes
  darkPanel?: boolean,
  // status of item
  status?: string,
}

const Arrow = styled(IconChevronRight16)(styles.arrow)
const Item = styled('button')(
  ({ theme, first, darkPanel }) => styles.base(theme, Arrow, first, darkPanel),
  ({ disabled }) => (disabled ? styles.disabled : null)
)
const Title = styled(Heading)(styles.title, ({ disabled }) => (disabled ? styles.disabled : null))
const EditIcon = styled(IconEdit16)(styles.check)
const CloseIcon = styled(IconClose16)(styles.cross)
const Status = styled(Chip)(styles.status)
const El = (props: SidebarNavItemProps) => {
  const {
    description,
    title,
    clickHandler,
    disabled,
    first,
    darkPanel,
    descriptionActive,
    descriptionClickHandler,
    showEditingActions,
    onEditClickHandler,
    onCancelClickHandler,
    descDataLocator,
    status,
  } = props
  const Description = styled('p')(
    styles.description,
    descriptionActive ? styles.activeDescription : null
  )
  const handleClick = e => {
    e.preventDefault()
    if (!disabled) clickHandler()
  }
  return (
    <Item
      onClick={handleClick}
      disabled={disabled}
      first={first}
      darkPanel={darkPanel}
      {...getThemeProp(props)}
      aria-label={title}
    >
      {status && <Status variant="danger">{status}</Status>}
      <Title
        variant="h3"
        size="small"
        styleOverrides={{
          color: darkPanel ? colours.tertiarySix : 'inherit',
          marginBottom: 0,
          borderBottomColor: '1px solid rgba(0, 0, 0, 0.3)',
        }}
        disabled={disabled}
      >
        {title}
      </Title>
      {description && (
        <Description
          data-testid="description"
          data-locator={descDataLocator}
          onClick={descriptionClickHandler}
        >
          {description}
        </Description>
      )}
      {showEditingActions ? (
        <Fragment>
          <EditIcon data-testid="editIcon" onClick={onEditClickHandler} />
          <CloseIcon onClick={onCancelClickHandler} />
        </Fragment>
      ) : (
        <Arrow />
      )}
    </Item>
  )
}

El.displayName = 'SidebarNavItem'

El.defaultProps = {
  description: '',
  disabled: false,
  first: false,
  themed: false,
  darkPanel: false,
  descriptionClickHandler: () => {},
  descriptionActive: false,
  labelDataLocator: LOCATOR_SIDEBAR_NAV_ITEM_LABEL,
  descDataLocator: LOCATOR_SIDEBAR_NAV_ITEM_DESC,
  status: '',
}

const SidebarNavItem = (props: SidebarNavItemProps) => {
  const { themed, ...rest } = props
  return themed ? themeConsumer({ ...rest })(El, 'sidebar') : El(rest)
}

export default SidebarNavItem
