export const ADVISOR_PREFERENCES_GET = 'ADVISOR_PREFERENCES_GET'
export const ADVISOR_PREFERENCES_GET_SUCCESS = 'ADVISOR_PREFERENCES_GET_SUCCESS'
export const ADVISOR_PREFERENCES_GET_ERROR = 'ADVISOR_PREFERENCES_GET_ERROR'
export const ADVISOR_PREFERENCES_GET_FAILURE = 'ADVISOR_PREFERENCES_GET_FAILURE'
export const ADVISOR_PREFERENCES_POST = 'ADVISOR_PREFERENCES_POST'
export const ADVISOR_PREFERENCES_POST_SUCCESS = 'ADVISOR_PREFERENCES_POST_SUCCESS'
export const ADVISOR_PREFERENCES_POST_ERROR = 'ADVISOR_PREFERENCES_POST_ERROR'
export const ADVISOR_PREFERENCES_POST_FAILURE = 'ADVISOR_PREFERENCES_POST_FAILURE'
export const ADVISOR_PREFERENCES_RESTORE = 'ADVISOR_PREFERENCES_RESTORE'
export const ADVISOR_PREFERENCES_RESTORE_SUCCESS = 'ADVISOR_PREFERENCES_RESTORE_SUCCESS'
export const ADVISOR_PREFERENCES_RESTORE_ERROR = 'ADVISOR_PREFERENCES_RESTORE_ERROR'
export const ADVISOR_PREFERENCES_RESTORE_FAILURE = 'ADVISOR_PREFERENCES_RESTORE_FAILURE'
export const ADVISOR_PREFERENCES_UPDATE = 'ADVISOR_PREFERENCES_UPDATE'
