/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowUp32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M16.5 6.5l1.06-1.06-1.06-1.061-1.06 1.06L16.5 6.5zM8.56 16.56l9-9-2.12-2.12-9 9 2.12 2.12zm6.88-9l9 9 2.12-2.12-9-9-2.12 2.12zM18 28V6.5h-3V28h3z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowUp32
