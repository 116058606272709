// @flow
import {
  NAVIGATION_CHANGE_NAVIGATION_TYPE,
  NAVIGATION_CHANGE_FOOTER_TYPE,
  NAVIGATION_RESET_NAVIGATION_TYPE,
  NAVIGATION_RESET_FOOTER_TYPE,
  NAVIGATION_BURGER_MENU_TOGGLE,
  NAVIGATION_NOTIFICATION_BANNER_VISIBLE,
} from '../actions/types/navigation'

// constants.
import { NAVIGATION_MAIN, FOOTER_MAIN } from '../constants/navigation'

export const initialState = {
  navigationType: NAVIGATION_MAIN,
  footerType: FOOTER_MAIN,
  isOpen: false,
  showNotificationBanner: false,
}

const navigation = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case NAVIGATION_RESET_NAVIGATION_TYPE: {
      return {
        ...state,
        navigationType: NAVIGATION_MAIN,
      }
    }

    case NAVIGATION_RESET_FOOTER_TYPE: {
      return {
        ...state,
        footerType: FOOTER_MAIN,
      }
    }

    case NAVIGATION_CHANGE_NAVIGATION_TYPE: {
      const { navigationType } = action.payload
      return {
        ...state,
        navigationType,
      }
    }

    case NAVIGATION_CHANGE_FOOTER_TYPE: {
      const { footerType } = action.payload
      return {
        ...state,
        footerType,
      }
    }

    case NAVIGATION_BURGER_MENU_TOGGLE: {
      const { isOpen } = action.payload
      return {
        ...state,
        isOpen,
      }
    }

    case NAVIGATION_NOTIFICATION_BANNER_VISIBLE: {
      const { showNotificationBanner } = action.payload
      return {
        ...state,
        showNotificationBanner,
      }
    }

    default: {
      return state
    }
  }
}

export default navigation
