// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mlcl-digital/mlcl-design'
// components.
import { IconEdit16 } from '../../../../../atoms/Icons'

// utils.
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'

// styles.
import styles from './manageTemplates.styles'

// data-locators
import { LOCATOR_MANAGE_TEMPLATE, LOCATOR_MANAGE_TEMPLATE_CANCEL } from '../yourTemplates.locators'

const Container = styled('div')(styles.container)
export const ManageTemplatesAction = styled(Button)(styles.manageTemplatesAction)
const EditIcon = styled(IconEdit16)(styles.editIcon)

type ManageTemplatesProps = {
  // enable/disable template list edit mode
  isTemplateListEditModeActive: boolean,
  // toogle template list edit mode
  toggleTemplateListEditMode: Function,
  // sitecore fields
  fields?: {
    yourTemplatesCancelBtnLabel: { value: string },
    yourTemplatesManageTempLabel: { value: string },
  },
}

export default function ManageTemplates({
  isTemplateListEditModeActive,
  toggleTemplateListEditMode,
  fields,
}: ManageTemplatesProps) {
  const { yourTemplatesCancelBtnLabel, yourTemplatesManageTempLabel } =
    reduceAuthorableFields(fields)
  return (
    <Container>
      <ManageTemplatesAction
        data-locator={
          isTemplateListEditModeActive ? LOCATOR_MANAGE_TEMPLATE_CANCEL : LOCATOR_MANAGE_TEMPLATE
        }
        variant="tertiary"
        onClick={toggleTemplateListEditMode}
      >
        {isTemplateListEditModeActive ? (
          yourTemplatesCancelBtnLabel
        ) : (
          <Fragment>
            {yourTemplatesManageTempLabel}
            <EditIcon />
          </Fragment>
        )}
      </ManageTemplatesAction>
    </Container>
  )
}

ManageTemplates.defaultProps = {
  fields: {
    yourTemplatesCancelBtnLabel: { value: 'Cancel' },
    yourTemplatesManageTempLabel: { value: 'Manages Templates' },
  },
}
