/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTick16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.818 10.404l4.334-6.934 1.696 1.06-5.666 9.066-4.89-4.889 1.415-1.414 3.111 3.111z"
      fill="#000"
    />
  </Icon>
)

export default IconTick16
