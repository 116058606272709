/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const Icon2Users32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.908 20.148C20.626 20.8 22 22.1 22 23.4c0 3.68 0 4.6-2.75 4.6H2.75C0 28 0 27.08 0 23.4c0-1.3 1.374-2.6 5.092-3.252 1.542-.27 3.489-.428 5.908-.428 2.42 0 4.366.158 5.908.428zM19 23.9c-.001.44-.004.798-.013 1.1H3.013A40.18 40.18 0 0 1 3 23.9a4.25 4.25 0 0 1 .524-.246c1.2-.481 3.505-.934 7.476-.934 3.972 0 6.277.453 7.476.934.242.097.41.18.524.246zm-16.21.152c-.003 0 .009-.015.044-.043-.024.03-.042.044-.044.043zm16.42 0c-.002.001-.02-.013-.044-.043.035.027.047.043.044.043zM11 18.8c-2.522 0-4.053-.78-4.983-1.817-1.387-1.548-1.434-3.668-1.434-4.623C4.583 10.765 4.715 5 11 5c6.286 0 6.417 5.765 6.417 7.36 0 .955-.047 3.075-1.434 4.623C15.053 18.02 13.522 18.8 11 18.8zM11 8c1.099 0 1.71.246 2.054.463.348.22.614.531.827.958.48.962.536 2.179.536 2.939 0 .767-.064 1.654-.447 2.313a1.768 1.768 0 0 1-.73.7c-.375.2-1.054.427-2.24.427-1.186 0-1.865-.227-2.24-.428a1.768 1.768 0 0 1-.73-.7c-.383-.658-.447-1.545-.447-2.312 0-.76.056-1.977.536-2.939.213-.427.48-.739.827-.958C9.29 8.246 9.901 8 11 8z"
      fill="#000"
    />
    <path
      className="path2"
      d="M17.616 18.153c.138.064.283.125.434.18.475.103.919.218 1.331.345A10.1 10.1 0 0 0 21 18.8c2.522 0 4.053-.78 4.983-1.817 1.387-1.548 1.434-3.668 1.434-4.623C27.417 10.765 27.286 5 21 5c-1.484 0-2.625.321-3.502.833.345.437.628.896.857 1.356.218.436.39.88.528 1.315l.063-.041C19.29 8.246 19.901 8 21 8c1.099 0 1.71.246 2.054.463.348.22.614.531.827.958.48.962.536 2.179.536 2.939 0 .767-.064 1.654-.447 2.313a1.768 1.768 0 0 1-.73.7c-.375.2-1.054.427-2.24.427-.955 0-1.581-.147-1.985-.31a7.733 7.733 0 0 1-.72 1.692c-.197.34-.423.665-.68.97z"
      fill="#000"
    />
    <path
      className="path3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.952 25.813c-.048.669-.153 1.452-.506 2.159l-.014.028h5.818C32 28 32 27.08 32 23.4c0-1.3-1.374-2.6-5.092-3.252-1.382-.242-3.089-.394-5.17-.423 1.165.744 2.024 1.768 2.22 3.092 2.218.159 3.655.49 4.518.837.242.097.41.18.524.246-.001.44-.004.798-.013 1.1h-4.999c-.007.287-.018.56-.036.813zm5.258-1.76c-.002 0-.02-.014-.044-.044.035.027.047.043.044.043z"
      fill="#000"
    />
  </Icon>
)

export default Icon2Users32
