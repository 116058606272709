/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconUser16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 16c2 0 2-1 2-3 0-.719-1.205-1.437-3.616-1.785C10.441 11.08 9.313 11 8 11c-1.313 0-2.44.08-3.384.215C2.206 11.563 1 12.281 1 13c0 2 0 3 2 3h10zM5.118 9.138C5.71 9.645 6.615 10 8 10s2.29-.355 2.882-.862C12 8.181 12 6.68 12 6c0-1.04 0-5-4-5S4 4.96 4 6c0 .68 0 2.18 1.118 3.138zM13 14.288zM12.986 14a9.39 9.39 0 0 0 .01-.34 4.326 4.326 0 0 0-.358-.13C11.741 13.24 10.218 13 8 13s-3.741.24-4.638.53a4.324 4.324 0 0 0-.359.13c.003.127.006.238.011.34h9.972zM9.178 3.282C8.996 3.157 8.655 3 8 3c-.656 0-.996.157-1.178.282-.193.133-.358.336-.496.643C6.02 4.613 6 5.465 6 6c0 .553.032 1.041.234 1.393a.874.874 0 0 0 .38.355C6.826 7.86 7.243 8 8 8c.756 0 1.172-.14 1.387-.252a.874.874 0 0 0 .379-.355C9.968 7.041 10 6.553 10 6c0-.535-.02-1.387-.326-2.075-.138-.307-.303-.51-.496-.643z"
      fill="#000"
    />
  </Icon>
)

export default IconUser16
