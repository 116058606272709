/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconBox32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9v2h18V9H7zm-3 5h2v11a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V14h2V8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v6zm5 0h14v11H9V14zm2 6h10v-3H11v3z"
      fill="#000"
    />
  </Icon>
)

export default IconBox32
