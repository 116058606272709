// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Input, Select, ToggleGroup, Tooltip, A11yLabel, Loader } from '@mlcl-digital/mlcl-design'
import { get, debounce } from 'lodash'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pathOr } from 'lodash/fp'
import { createEvent } from '../../../../../utils/telemetry'
import CaptureClientErrorModalComponent from './CaptureClientErrorModal'
import { actionCreators } from '../../../../../actions'

// components.
import MenuList from '../../../../molecules/MenuList'
import Occupation from '../../../Occupation'
import SidebarNavItem from '../../../../molecules/SidebarNavItem'
import { IconInfo16 } from '../../../../atoms/Icons'

// schema.
import SCHEMA, { FORM_ID } from './captureClient.schema'

// helpers.
import {
  errorCheck,
  getSerializedFormState,
  optional,
  required,
  removeComma,
} from '../../../../../utils/formUtils'
import { submitClientDetails } from '../../../../../utils/captureClientUtils'

// styles.
import styles from './captureClient.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

// types
import { occupationsStateType } from '../../../../../reducers/occupation'
import { memberMandatoriesType } from '../../../../../reducers/createQuote'

// utils.
import { reduceAuthorableFields, renderTextField } from '../../../../../utils/sitecoreUtils'
import { isContainsNumber, transformToSelectOptions } from '../../../../../utils/commonUtils'
import { isEmployee, getSuperGuaranteeMaxRate } from '../../../../../utils/extendedQuoteUtils'

// constants
import { PREASSESSMENT_MODE_FROM_SIDEBAR } from '../../../../../constants/benefit'
import {
  INCOME_PROTECTION_PLATINUM_ID,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../../../../../constants/policies'
import { OCCUPATION_LIST } from '../../../../../constants/occupation'

// set up components for ignoring column layouts.
const FullWidthToggleGroup = ignoreColumns(ToggleGroup)
const FullWidthSelectAsync = ignoreColumns(Select)

const Form = styled('form')(styles.base)
const Wrapper = styled('div')({
  position: 'relative',
})
const Footer = styled('div')(styles.footer)
const TwoColumnLayout = styled('div')(styles.twoColumnLayout)
const OneColumnLayout = styled('div')(styles.oneColumnLayout)
const Rating = styled('div')(styles.rating)
const TooltipParentDiv = styled('div')(styles.tooltipParentDiv)
const InfoIcon = styled(IconInfo16)(styles.infoIconEarnings)
const NoResults = styled('div')(styles.noResults)
const MandatoriesUpdate = styled('div')(styles.mandatoriesUpdate)
// const Button = styled(ButtonComponent)(styles.button)

type CaptureClientProps = {
  // Redux actions available to the component.
  actions: Object<Function>,
  // advisor details sub-store.
  advisor: {
    isLoading: boolean,
  },
  // To render all child labels from sitecore content editor.
  fields: {},
  // memberMandatories which user has selected
  memberMandatories: memberMandatoriesType,
  // Occupation which user has selected
  occupation: occupationsStateType,
  // product rules payload.
  productRules: Object,
  quoteWillBeModified: boolean,
  // form fields object
  form: Object<Object>,
  oldMemberMandatories: memberMandatoriesType,
  setPreAssessmentVisibility: boolean,
  activeQuote: Object,
  // clientDetails memberEntity data
  sidebar: Object,
  createQuote: Object,
  isNewClient: boolean,
  isClientLoading: boolean,
  // Master data from Sitecore
  masterData: Object,
}

type CaptureClientState = {
  showOccupation: boolean,
  showOccupationDropDown: boolean,
  disableOccupationDropDown: boolean,
  isModalOpen: boolean,
  displayEarningsField: boolean,
}

class CaptureClient extends Component<CaptureClientProps, CaptureClientState> {
  constructor(props) {
    super(props)
    const {
      quoteWillBeModified,
      memberMandatories: { employmentStatus },
    } = props
    this.userTypedOccupationValue = ''
    this.callBackOccupation = null

    this.state = {
      showOccupation: false,
      showOccupationDropDown: false,
      disableOccupationDropDown: false,
      isModalOpen: quoteWillBeModified,
      displayEarningsField: isEmployee(employmentStatus),
    }

    this.setModalOpen = this.setModalOpen.bind(this)
  }

  componentWillMount() {
    const { form, actions } = this.props
    const { formInit, getListOfOccupations } = actions
    if (!form) {
      formInit(FORM_ID, SCHEMA)
    }

    this.debounceListOfOccupations = debounce(getListOfOccupations, 200)
  }

  // eslint-disable-next-line react/sort-comp
  componentDidUpdate(preProps) {
    const { productRules, sidebar, occupation, createQuote, actions, isNewClient, form } =
      this.props
    const { isNewClient: prevIsNewClient } = preProps
    const { formInit } = actions
    // FIXME: reset form if switching from existing client to a new client
    // this can be handled way better given more time.
    const formNotFilledYet = Object.values(get(form, 'fields', {})).every(
      field => field.value === ''
    )
    if (!prevIsNewClient && isNewClient && formNotFilledYet) {
      formInit(FORM_ID, SCHEMA)
    }

    if (preProps.occupation.occupationRating !== occupation.occupationRating) {
      this.updateOccupationRating()
    }
    if (!productRules.isFetching && preProps.productRules.isFetching && sidebar.isNextBtnLoader) {
      // If products API call is in progress, clicking on Next btn on client mandatories will not
      // submit client details. Hence, doing it here.
      actions.setIsNextBtnLoader(false)
      submitClientDetails(
        {
          occupation,
          createQuote,
        },
        actions
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      memberMandatories: { occupationRating, occupationCode, employmentStatus },
      form,
    } = nextProps
    const { actions, memberMandatories, masterData } = this.props
    const { formValidate, formUpdateField } = actions
    const formEmploymentStatus =
      get(form, 'fields.employmentStatus.value.value', '') ||
      get(form, 'fields.employmentStatus.value', '')
    if (occupationRating !== memberMandatories.occupationRating && occupationRating) {
      formUpdateField(FORM_ID, 'occupationRating', {
        error: { error: false },
        value: occupationRating,
      })
      formValidate(FORM_ID, SCHEMA)
    }

    if (occupationCode !== memberMandatories.occupationCode && occupationCode) {
      formUpdateField(FORM_ID, 'occupationCode', {
        error: { error: false },
        value: occupationCode,
      })
      formValidate(FORM_ID, SCHEMA)
    }

    if (
      employmentStatus !== get(memberMandatories, 'employmentStatus', '') &&
      isEmployee(employmentStatus)
    ) {
      this.setState({ displayEarningsField: true })
    }
    if (
      employmentStatus &&
      employmentStatus === formEmploymentStatus &&
      get(masterData, 'employmentStatus', []).findIndex(
        status => status.code === employmentStatus && status.relatedOccupation
      ) > 0
    ) {
      this.setState({ disableOccupationDropDown: true })
    }
  }

  setModalOpen(isModalOpen) {
    this.setState({ isModalOpen })
  }

  handlePreAssessment = (): void => {
    const { form, actions } = this.props
    const { formUpdateValidations, formValidate, openSidebar, setPreAssessmentOpenMode } = actions
    formUpdateValidations(FORM_ID, SCHEMA)
    formValidate(FORM_ID, SCHEMA)
    if (form.isValid) {
      getSerializedFormState(form.fields)
      setPreAssessmentOpenMode(PREASSESSMENT_MODE_FROM_SIDEBAR)
      openSidebar('preAssessment')
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Initiate preassessment from client mandatories',
        action: 'Initiate preassessment',
      },
      Splunk: {
        attributes: {
          'workflow.name':
            'Initiate preassessment from client mandatories - Initiate preassessment',
        },
      },
    })
    tagEvent.end()
  }

  updateOccupationRating = () => {
    const {
      actions: { formUpdateField, formValidate },
      occupation: { occupationRating },
    } = this.props
    const occupationRatingValue = pathOr('', 'attributes.Code', occupationRating)
    const occupationCode = pathOr('', 'attributes.OCCP_CODE', occupationRating)
    if (occupationRatingValue) {
      formUpdateField(FORM_ID, 'occupationRating', {
        error: { error: false },
        value: occupationRatingValue,
      })
      formValidate(FORM_ID, SCHEMA)
    }
    if (occupationCode) {
      formUpdateField(FORM_ID, 'occupationCode', {
        error: { error: false },
        value: occupationCode,
      })
      formValidate(FORM_ID, SCHEMA)
    }
  }

  // handle changes on form elements.
  handleChange = ({ value, name }): void => {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions
    const data = {
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  // handle change for employment status
  handleEmploymentStatusChange = ({ value, name }) => {
    const relatedOccupation = get(value, 'relatedOccupation', '')
    const { form } = this.props
    if (isEmployee(value.code)) {
      if (get(form, 'fields.earningExcludingSuper.value', '') === 0) {
        this.handleChange({ value: '', name: 'earningExcludingSuper' })
      }
      SCHEMA.earningExcludingSuper.condition = required
      this.setState({ displayEarningsField: true })
    } else {
      SCHEMA.earningExcludingSuper.condition = optional
      this.handleChange({ value: 0, name: 'earningExcludingSuper' })
      this.setState({ displayEarningsField: false })
    }
    if (relatedOccupation) {
      this.onSelectOfOccupation({
        name: 'occupation',
        value: {
          value: relatedOccupation,
          label: relatedOccupation,
        },
      })
      this.setState({ disableOccupationDropDown: true })
    } else {
      this.setState({ disableOccupationDropDown: false })
    }
    this.handleChange({ value, name })
  }

  // validate and update on blur for input elements.
  handleBlur = (event, name): void => {
    const { value } = event.target
    const { actions, productRules } = this.props
    const { formUpdateField, formValidate } = actions
    const data = {
      error: errorCheck(value, SCHEMA[name].condition, SCHEMA[name].errorMsg),
      value: removeComma(value),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
    if (name === 'earningExcludingSuper') {
      this.handleChange({
        value: Math.floor(
          removeComma(value) *
            (1 +
              getSuperGuaranteeMaxRate(POLICY_PRODUCT_CODE_NON_SUPER, productRules.data, {
                type: INCOME_PROTECTION_PLATINUM_ID,
              }) /
                100)
        ),
        name: 'earning',
      })
    }
  }

  onSelectOfOccupation = ({ value, name }): void => {
    const { actions } = this.props
    const { getRatingOfOccupation, formUpdateField, resetOccupationInStore } = actions
    formUpdateField(FORM_ID, 'occupationRating', { error: { error: true }, value: '' })
    // resetting occupation state before making API call to populate it,
    // so that old values are not used if API fails
    resetOccupationInStore()
    getRatingOfOccupation(encodeURIComponent(value.value))
    this.handleChange({ value, name })
  }

  // open modal of occupation
  toggleOccupation = state => () => {
    this.setState({ showOccupation: state, showOccupationDropDown: false })
  }

  createOptions = values => values.map(value => ({ label: value, value }))

  onSelectInputChange = (value): void => {
    const { showOccupationDropDown } = this.state
    if (isContainsNumber(value)) {
      this.setState({ showOccupationDropDown: false })
    } else if (value && value.length >= 3) {
      this.userTypedOccupationValue = value
      if (!showOccupationDropDown) this.setState({ showOccupationDropDown: true })
      this.debounceListOfOccupations(encodeURIComponent(this.userTypedOccupationValue))
    } else {
      this.userTypedOccupationValue = ''
      if (showOccupationDropDown) this.setState({ showOccupationDropDown: false })
    }
  }

  renderRelations = (memberMandatories, captureClientGeneralOccupationRatings) => {
    const {
      occupation: { occupationRating },
    } = this.props
    let updatedMemberMandatories = memberMandatories
    if (occupationRating.attributes) {
      const occupations = OCCUPATION_LIST.filter(
        occupation => occupationRating.attributes[occupation]
      )
      updatedMemberMandatories = {
        occupation: occupationRating.text,
        occupationalRating: occupationRating.attributes,
        occupationClassCode: [
          {
            benefit: occupations,
            code: occupations.map(occupation => occupationRating.attributes[occupation]),
          },
        ],
        // As per confirmation code will always be on 0th index of array
        occupationCode: occupationRating.attributes.OCCP_CODE,
      }
    } else if (memberMandatories.occupationalRating) {
      const occupations = OCCUPATION_LIST.filter(
        occupation => memberMandatories.occupationalRating[occupation]
      )
      updatedMemberMandatories = {
        occupationClassCode: [
          {
            benefit: occupations,
            code: occupations.map(occupation => memberMandatories.occupationalRating[occupation]),
          },
        ],
      }
    }

    if (
      updatedMemberMandatories &&
      updatedMemberMandatories.occupationClassCode &&
      // TODO: this should be string value, for now it is an array
      Array.isArray(updatedMemberMandatories.occupationClassCode) &&
      updatedMemberMandatories.occupationClassCode.length
    ) {
      return (
        <Rating>
          {`${captureClientGeneralOccupationRatings}`}
          {updatedMemberMandatories.occupationClassCode[0].benefit.map(
            (benefit, index) =>
              `${benefit}: ${updatedMemberMandatories.occupationClassCode[0].code[index]} `
          )}
        </Rating>
      )
    }
    return null
  }

  shouldDobOrGenderOrStateBeDisabled = field => {
    const {
      isNewClient,
      form: { fields },
    } = this.props
    return Boolean(!isNewClient && fields[field])
  }

  render() {
    const {
      form,
      fields,
      actions,
      memberMandatories,
      occupation: { occupationNames, isLoading },
      quoteWillBeModified,
      oldMemberMandatories,
      setPreAssessmentVisibility,
      activeQuote,
      isClientLoading,
      masterData,
      productRules,
    } = this.props
    const { updateGeneralOccupations } = actions

    if (!form) return null
    const { showOccupation, showOccupationDropDown, displayEarningsField } = this.state
    const {
      gender,
      isSmoker,
      dateOfBirth,
      earning,
      earningExcludingSuper,
      occupation,
      stateOfResidence,
      employmentStatus,
    } = form.fields
    const {
      captureClientGenderLabel,
      captureClientSmokingLabel,
      captureClientDobLabel,
      captureClientStateResLabel,
      captureClientEmpLabel,
      captureClientEarnLabel,
      CaptureClientEarnInfoToolTip,
      CaptureClientEarnLabelInclSuperLabel,
      CaptureClientEarnInfoInclSuperTooltip,
      CaptureClientEarnLabelExclSuperLabel,
      CaptureClientEarnInfoExclSuperTootTip,
      captureClientOccuLabel,
      captureClientEmpPlaceholder,
      captureClientStateResPlaceholder,
      captureClientOccuPlaceholder,
      captureClientGeneralOccupationRatings,
      captureClientOccupationNoResults,
      preAssessmentTabLabel,
      captureClientMandatoriesUpdateMessage,
    } = fields

    const { isModalOpen, disableOccupationDropDown } = this.state

    const loadingMessage = pathOr(
      'Loading...',
      'captureClientOccupationLoadingMessage.value',
      fields
    )

    const hasPreAssessmentEnquiryId = get(activeQuote, 'preAssessmentDetails', false)

    if (this.callBackOccupation) this.callBackOccupation(this.createOptions(occupationNames))

    const isDobDisabled = this.shouldDobOrGenderOrStateBeDisabled('dateOfBirth')
    const isGenderDisabled = this.shouldDobOrGenderOrStateBeDisabled('gender')
    const isStateDisabled = this.shouldDobOrGenderOrStateBeDisabled('stateOfResidence')

    return (
      <Fragment>
        {isClientLoading ? (
          <Wrapper>
            <Loader spinnerSize={40} />
          </Wrapper>
        ) : (
          <Wrapper>
            <A11yLabel>Client</A11yLabel>
            <Form id="client" aria-labelledby="client-label">
              <FullWidthToggleGroup
                htmlFor="gender"
                label={captureClientGenderLabel.value}
                name="gender"
                labelledby="client-label"
                handleChange={this.handleChange}
                value={gender.value}
                checked={!gender.value === ''}
                error={gender.error.error}
                caption={gender.error.error ? gender.error.errorMsg : SCHEMA.gender.tooltip}
                options={SCHEMA.gender.options.map(gen => ({
                  ...gen,
                  disabled: isGenderDisabled,
                }))}
              />
              <FullWidthToggleGroup
                htmlFor="isSmoker"
                label={captureClientSmokingLabel.value}
                name="isSmoker"
                labelledby="client-label"
                handleChange={this.handleChange}
                value={isSmoker.value}
                checked={!isSmoker.value === ''}
                error={isSmoker.error.error}
                caption={isSmoker.error.error ? isSmoker.error.errorMsg : SCHEMA.isSmoker.tooltip}
                options={SCHEMA.isSmoker.options}
              />
              <TwoColumnLayout>
                <Input
                  htmlFor="dateOfBirth"
                  name="dateOfBirth"
                  label={captureClientDobLabel.value}
                  blurHandler={this.handleBlur}
                  placeholder="DD / MM / YYYY"
                  disabled={isDobDisabled}
                  error={dateOfBirth.error.error}
                  caption={
                    dateOfBirth.error.error
                      ? dateOfBirth.error.errorMsg
                      : SCHEMA.dateOfBirth.tooltip
                  }
                  options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                  value={dateOfBirth.value}
                />
                <Select
                  label={captureClientStateResLabel.value}
                  placeholder={captureClientStateResPlaceholder.value}
                  value={stateOfResidence.value}
                  name="stateOfResidence"
                  id="stateOfResidence"
                  disabled={isStateDisabled}
                  error={stateOfResidence.error.error}
                  caption={
                    stateOfResidence.error.error
                      ? stateOfResidence.error.errorMsg
                      : SCHEMA.stateOfResidence.tooltip
                  }
                  changeHandler={this.handleChange}
                  options={SCHEMA.stateOfResidence.options}
                />
              </TwoColumnLayout>

              <Select
                label={captureClientEmpLabel.value}
                placeholder={captureClientEmpPlaceholder.value}
                value={employmentStatus.value}
                name="employmentStatus"
                id="employmentStatus"
                error={employmentStatus.error.error}
                caption={
                  employmentStatus.error.error
                    ? employmentStatus.error.errorMsg
                    : SCHEMA.employmentStatus.tooltip
                }
                changeHandler={this.handleEmploymentStatusChange}
                options={transformToSelectOptions(get(masterData, 'employmentStatus', []))}
              />

              {displayEarningsField && employmentStatus.value && (
                <OneColumnLayout>
                  <Input
                    htmlFor="earningExcludingSuper"
                    label={CaptureClientEarnLabelExclSuperLabel.value}
                    name="earningExcludingSuper"
                    changeHandler={this.handleChange}
                    blurHandler={this.handleBlur}
                    disabled={productRules.isFetching}
                    error={earningExcludingSuper.error.error}
                    caption={
                      earningExcludingSuper.error.error
                        ? earningExcludingSuper.error.errorMsg
                        : SCHEMA.earningExcludingSuper.tooltip
                    }
                    value={earningExcludingSuper.value}
                    prefix="$"
                    options={{
                      numeralThousandsGroupStyle: 'thousand',
                      numeralPositiveOnly: true,
                      disableDecimal: true,
                    }}
                  />
                  <TooltipParentDiv>
                    <div data-tip data-for="earningsPAExclSuper">
                      <InfoIcon />
                    </div>
                    <Tooltip
                      id="earningsPAExclSuper"
                      place="left"
                      offset={{ top: -15, left: 25 }}
                      multiline
                    >
                      {renderTextField(CaptureClientEarnInfoExclSuperTootTip, true)}
                    </Tooltip>
                  </TooltipParentDiv>
                </OneColumnLayout>
              )}

              <OneColumnLayout>
                <Input
                  htmlFor="earning"
                  label={
                    displayEarningsField && employmentStatus.value
                      ? CaptureClientEarnLabelInclSuperLabel.value
                      : captureClientEarnLabel.value
                  }
                  name="earning"
                  changeHandler={this.handleChange}
                  disabled={displayEarningsField}
                  error={earning.error.error}
                  caption={earning.error.error ? earning.error.errorMsg : SCHEMA.earning.tooltip}
                  value={earning.value}
                  prefix="$"
                  options={{
                    numeralThousandsGroupStyle: 'thousand',
                    numeralPositiveOnly: true,
                    disableDecimal: true,
                  }}
                />
                {displayEarningsField && employmentStatus.value ? (
                  <TooltipParentDiv>
                    <div data-tip data-for="earningsPAInclSuper">
                      <InfoIcon />
                    </div>
                    <Tooltip
                      id="earningsPAInclSuper"
                      place="left"
                      offset={{ top: -15, left: 25 }}
                      multiline
                    >
                      {renderTextField(CaptureClientEarnInfoInclSuperTooltip, true)}
                    </Tooltip>
                  </TooltipParentDiv>
                ) : (
                  <TooltipParentDiv>
                    <div data-tip data-for="earningsPA">
                      <InfoIcon />
                    </div>
                    <Tooltip id="earningsPA" place="left" offset={{ top: -15, left: 25 }} multiline>
                      {renderTextField(CaptureClientEarnInfoToolTip, true)}
                    </Tooltip>
                  </TooltipParentDiv>
                )}
              </OneColumnLayout>

              <FullWidthSelectAsync
                label={captureClientOccuLabel.value}
                placeholder={captureClientOccuPlaceholder.value}
                value={occupation.value}
                name="occupation"
                id="occupation"
                error={occupation.error.error}
                disabled={disableOccupationDropDown}
                caption={
                  occupation.error.error ? occupation.error.errorMsg : SCHEMA.occupation.tooltip
                }
                handleInputChange={this.onSelectInputChange}
                options={this.createOptions(occupationNames)}
                changeHandler={this.onSelectOfOccupation}
                isSearchable
                customComponents={{
                  MenuList: showOccupationDropDown ? MenuList : () => '',
                  IndicatorsContainer: () => '',
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  NoOptionsMessage: () =>
                    isLoading ? (
                      <NoResults>{loadingMessage}</NoResults>
                    ) : (
                      <NoResults>{captureClientOccupationNoResults.value} </NoResults>
                    ),
                }}
                onClickOfCustomMenuOption={this.toggleOccupation(true)}
              />
              {occupation.value &&
                this.renderRelations(
                  memberMandatories,
                  captureClientGeneralOccupationRatings.value
                )}
              {/* show message if member Mandatories is modified */}
              {hasPreAssessmentEnquiryId && (
                <MandatoriesUpdate>
                  {renderTextField(captureClientMandatoriesUpdateMessage)}
                </MandatoriesUpdate>
              )}
              {setPreAssessmentVisibility ? (
                <Footer>
                  <SidebarNavItem
                    first
                    clickHandler={this.handlePreAssessment}
                    title={preAssessmentTabLabel.value}
                    disabled={!form.isValid}
                  />
                </Footer>
              ) : null}
              <Occupation
                fields={reduceAuthorableFields(fields)}
                onClose={this.toggleOccupation(false)}
                openOccupation={showOccupation}
                updateMandatoryDetails={updateGeneralOccupations}
                handleChange={this.handleChange}
              />
            </Form>
          </Wrapper>
        )}

        {quoteWillBeModified && (
          <CaptureClientErrorModalComponent
            setModalOpen={this.setModalOpen}
            isModalOpen={isModalOpen}
            fields={fields}
            quoteWillUpdate={quoteWillBeModified}
            formId={FORM_ID}
            schema={SCHEMA}
            actions={actions}
            oldMemberMandatories={oldMemberMandatories}
            title="Error"
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  advisor,
  forms,
  createQuote,
  occupation,
  productRules,
  masterList,
  sidebar,
  client,
  policies,
  existingClient,
}) => ({
  advisor,
  form: forms[FORM_ID],
  memberMandatories: createQuote.quotes[createQuote.activeIndex].memberMandatories,
  occupation,
  productRules,
  sidebar,
  masterData: get(masterList, 'data', []),
  quoteWillBeModified: sidebar.panelProps ? sidebar.panelProps.quoteWillBeModified : false,
  oldMemberMandatories: sidebar.panelProps ? sidebar.panelProps.oldMemberMandatories : {},
  setPreAssessmentVisibility: sidebar.panelProps
    ? sidebar.panelProps.setPreAssessmentVisibility
    : false,
  activeQuote: createQuote.quotes[createQuote.activeIndex],
  client,
  createQuote,
  policies,
  isClientLoading: existingClient.isLoading,
  isNewClient: !existingClient.details,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CaptureClient)
