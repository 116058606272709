/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowDown16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M8 14l-.707.707.707.707.707-.707L8 14zm4.293-5.707l-5 5 1.414 1.414 5-5-1.414-1.414zm-3.586 5l-5-5-1.414 1.414 5 5 1.414-1.414zM7 1v13h2V1H7z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowDown16
