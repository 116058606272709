import get from 'lodash/get'
import moment from 'moment'

import { ALLOWED_DATE_FORMAT, CLIENT_SIDE_DATE_FORMAT } from '../../../../../utils/quoteUtils'
import { getFullAddressWithState } from '../../../../../utils/contactUtils'
import { getDropdownValue } from '../../../../../utils/commonUtils'
import { getValue } from '../../../../../utils/formUtils'
import { PREFERRED_YES, COUNTRY_CODE } from '../../../../../constants/policies'
import { DEFAULT_PHONE_CODE } from '../../../../../constants/contactDetails'

export const getDataFromForm = (field, defaultValue, form) =>
  get(form, `fields[${field}].value`, defaultValue)

export const initForms = props => {
  if (!props.smsfTrusteeDetailsForm)
    return {
      contactNumberPhoneCode: props.countriesPhoneCode.find(
        phoneCode => phoneCode.value === DEFAULT_PHONE_CODE
      ),
      secondaryNumberPhoneCode: props.countriesPhoneCode.find(
        phoneCode => phoneCode.value === DEFAULT_PHONE_CODE
      ),
    }
  const {
    smsfTrusteeDetailsForm: {
      fields: {
        individuals,
        addressCountry,
        addressHouseNo,
        addressLocality,
        addressPostCode,
        addressState,
        addressStreet,
      },
    },
    sidebarPanelProps: { individualIdentifier },
    countriesPhoneCode,
  } = props

  const selectedIndividual =
    individuals.value &&
    individualIdentifier &&
    individuals.value.find(
      ({ relatedParty = {} }) =>
        relatedParty.identifiers &&
        relatedParty.identifiers.some(identifier => identifier.value === individualIdentifier.value)
    )
  if (!selectedIndividual)
    return {
      contactNumberPhoneCode: props.countriesPhoneCode.find(
        phoneCode => phoneCode.value === DEFAULT_PHONE_CODE
      ),
      secondaryNumberPhoneCode: props.countriesPhoneCode.find(
        phoneCode => phoneCode.value === DEFAULT_PHONE_CODE
      ),
    }
  const { relatedParty, isPrimary } = selectedIndividual

  const addressIndex = relatedParty.contactMethods.addresses.findIndex(
    address => address.preferred === PREFERRED_YES
  )
  const primaryEmailIndex = relatedParty.contactMethods.emails.findIndex(
    email => email.preferred === PREFERRED_YES
  )

  const email =
    primaryEmailIndex !== -1 ? relatedParty.contactMethods.emails[primaryEmailIndex].email : {}
  const address = addressIndex !== -1 ? relatedParty.contactMethods.addresses[addressIndex] : {}
  const fullAddress = getFullAddressWithState(relatedParty.contactMethods.addresses).trim()

  const primaryPhoneIndex = relatedParty.contactMethods.phones.findIndex(
    phone => phone.preferred === PREFERRED_YES
  )
  const contactNumberPhoneCodeValue = get(
    relatedParty,
    `contactMethods.phones[${primaryPhoneIndex}].idc`,
    DEFAULT_PHONE_CODE
  )
  const contactNumberPhoneCode = getDropdownValue(contactNumberPhoneCodeValue, countriesPhoneCode)
  const secondaryNumberPhoneCodeValue =
    get(relatedParty, 'contactMethods.phones.length') === 1
      ? DEFAULT_PHONE_CODE
      : get(
          relatedParty,
          `contactMethods.phones[${primaryPhoneIndex === 0 ? 1 : 0}].idc`,
          DEFAULT_PHONE_CODE
        )
  const secondaryNumberPhoneCode = getDropdownValue(
    secondaryNumberPhoneCodeValue,
    countriesPhoneCode
  )

  const data = {
    title: relatedParty.title,
    firstName: relatedParty.firstName,
    middleName: relatedParty.middleName,
    lastName: relatedParty.lastName,
    dateOfBirth: moment(relatedParty.dateOfBirth, ALLOWED_DATE_FORMAT, true).format(
      CLIENT_SIDE_DATE_FORMAT
    ),
    email,
    preferredCommChannel: relatedParty.preferredCommChannel,
    addressStreet: address.street,
    addressUnit: address.houseNo,
    addressLocality: address.locality,
    addressState: address.state,
    addressCountry: address.country || COUNTRY_CODE,
    addressPostCode: address.postCode,
    address: fullAddress,
    identifiers: relatedParty.identifiers[0],
    sameAsCompanyAddress:
      (address.value === fullAddress &&
        getValue(addressCountry) === address.country &&
        addressHouseNo.value === address.houseNo &&
        addressLocality.value === address.locality &&
        addressPostCode.value === address.postCode &&
        addressState.value === address.state &&
        addressStreet.value === address.street) ||
      relatedParty.sameAsCompanyAddress,
    isPrimary,
    contactNumber: get(relatedParty, `contactMethods.phones[${primaryPhoneIndex}].number`),
    contactNumberPhoneCode,
    secondaryNumber:
      get(relatedParty, 'contactMethods.phones.length') === 1
        ? ''
        : get(relatedParty, `contactMethods.phones[${primaryPhoneIndex === 0 ? 1 : 0}].number`),
    secondaryNumberPhoneCode,
  }

  return data
}
