import {
  LOGOUT,
  ADVISER_REGISTRATION,
  TELEUNDERWRITER,
  PAYMENTS,
  APPLICATION_POLICY_SETUP,
  PERSONAL_STATEMENT,
  URE_TOOL,
  FORGOT_USERNAME,
} from './routes'

import { INACTIVE_ADVISER_URL } from './adviser'

export const LAST_ACTIVITY_TIME = 'lastActivityTime'
// Session timeout duration in milliseconds (when user is idle for the specified time)
export const SESSION_TIMEOUT_DURATION = 15 * 60 * 1000

export const WHITELISTED_URL = [
  LOGOUT,
  ADVISER_REGISTRATION,
  TELEUNDERWRITER,
  PAYMENTS,
  APPLICATION_POLICY_SETUP,
  '/third-party-test',
  PERSONAL_STATEMENT,
  INACTIVE_ADVISER_URL,
  FORGOT_USERNAME,
]

export const WHITELISTED_SESSION_TIMEOUT_URL = [
  URE_TOOL,
  PAYMENTS,
  ADVISER_REGISTRATION,
  '/third-party-test',
  INACTIVE_ADVISER_URL,
  FORGOT_USERNAME,
]

export const MFA_REQUIRED = 'MFA_REQUIRED'
export const MFA_CHALLENGE = 'MFA_CHALLENGE'

export const LOGO_HORIZONTAL = '/images/logo-desktop-black.svg'
export const LOGO_VERTICAL = '/images/logo-mobile-black.svg'

export const ACCESS_DENIED = 'access_denied'
