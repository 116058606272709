/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconStarON16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path className="path1" d="M6 6l2-6 2 6h6l-5 4 2 6-5-4.5L3 16l2-6-5-4h6z" fill="#000" />
  </Icon>
)

export default IconStarON16
