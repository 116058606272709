/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconExternalLink16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M14 14v1h1v-1h-1zM2 14H1v1h1v-1zM2 2V1H1v1h1zm12.071 2.536l.707.707.707-.707-.707-.708-.707.708zM14 13H2v2h12v-2zM3 14V2H1v12h2zm10-4v4h2v-4h-2zM2 3h4V1H2v2zm7.828-1.293l3.536 3.536 1.414-1.415L11.243.293 9.828 1.707zm3.536 2.121L9.828 7.364l1.415 1.414 3.535-3.535-1.414-1.415zM13 3.5c-1.984 0-3.96.112-5.447.856-.777.388-1.437.955-1.893 1.762C5.21 6.916 5 7.878 5 9h2c0-.878.165-1.478.402-1.9.232-.41.572-.719 1.045-.956C9.46 5.638 10.984 5.5 13 5.5v-2z"
      fill="#000"
    />
  </Icon>
)

export default IconExternalLink16
