import get from 'lodash/get'
import { createEvent } from '../../../../utils/telemetry'

// schema.
import addLoadingSchema, {
  FORM_ID as addLoadingId,
} from '../../CreateQuote/components/PreAssessment/AddLoading/addLoading.schema'

import saveLoadingsSchema, {
  FORM_ID as saveLoadingsId,
} from '../../CreateQuote/components/PreAssessment/Loadings/loadings.schema'

// utils
import { formSubmit, formSubmitComplete } from '../../../../actions/creators/forms'

// components.
import PreAssessment, {
  benefitList,
} from '../../CreateQuote/components/PreAssessment/PreAssessmentTab'
import Loadings from '../../CreateQuote/components/PreAssessment/Loadings'
import AddLoading from '../../CreateQuote/components/PreAssessment/AddLoading'
import { preAssessmentSchema } from '../../CreateQuote/components/PreAssessment/PreAssessmentTab/preAssessment.schema'
import { getPreAssessmentEnquiryPayload } from '../../../../utils/ureUtils'

import history from '../../../../utils/browserHistory'
import { optional, truthy } from '../../../../utils/formUtils'
import EditLoading from '../../CreateQuote/components/PreAssessment/EditLoading'

// actions
import { addLoading, saveLoadings, updateLoading } from '../../../../actions/creators/preAssessment'
import {
  gotoPanel,
  openSidebar,
  closeSidebar,
  setPanelProps,
} from '../../../../actions/creators/sidebar'
import { updateEnquiryId, createQuoteCalculate } from '../../../../actions/creators/createQuote'

// constants
import {
  PREASSESSMENT_MODE_FROM_SIDEBAR,
  LOADING_TYPE_EMR,
  LOADING_TYPE_RPM,
} from '../../../../constants/benefit'

const SIDEBAR_SCHEMA = (fields = {}) => [
  {
    sitecoreFieldHeadingKey: 'preAssessmentSidebarTitle',
    component: PreAssessment,
    visible: false,
    nextText: get(fields, 'preAssessmentSidebarStartButton.value', 'Start pre-assessment'),
    handleNext: (next, dispatch, state) => {
      const schema = {}
      const {
        forms: { selectPreAssessment: form },
        ure: {
          initialData: { umeBenefits },
          answers,
          activeEnquiryId,
        },
        createQuote: { quotes, activeIndex },
        advisor: { details },
      } = state
      const tagEvent = createEvent({
        GA: {
          category: 'Start preassessment',
          action: 'Start preassessment',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Start preassessment',
          },
        },
      })
      tagEvent.end()
      const fieldsRequired = !Object.keys(form.fields).some(field => form.fields[field].value)
      umeBenefits.forEach(o => {
        if (o.types.includes('pre-sale') && !o.excludeInPA) {
          schema[o.umeText] = {
            ...o,
            condition: fieldsRequired ? truthy : optional,
          }
        }
      })

      const callback = () => {
        dispatch(closeSidebar())
        history.push('/ure-tool')
      }

      const updatePreAssessment = () => {
        const {
          forms: { captureClient },
        } = state
        const payload = getPreAssessmentEnquiryPayload(
          state.forms[preAssessmentSchema],
          captureClient,
          activeEnquiryId ? answers[activeEnquiryId].allAnswers : '',
          quotes[activeIndex].memberMandatories,
          details
        )
        dispatch(updateEnquiryId({ callback, payload }))
      }
      dispatch(formSubmit(preAssessmentSchema, schema, updatePreAssessment))
    },
    props: {
      secondaryHeader: true,
      animation: false,
      forcedBackNav: (dispatch, state) => {
        const mode = get(state, 'preAssessment.mode')
        if (mode === PREASSESSMENT_MODE_FROM_SIDEBAR) {
          dispatch(openSidebar('createQuote', 1))
          dispatch(
            setPanelProps({
              setPreAssessmentVisibility: true,
            })
          )
        } else {
          dispatch(closeSidebar())
        }
      },
    },
  },
  {
    sitecoreFieldHeadingKey: 'preAssessmentSidebarTitle',
    component: Loadings,
    visible: false,
    nextText: get(fields, 'preAssessmentSidebarSaveLoadingsButton.value', 'Save Loadings'),
    handleNext: (next, dispatch, state) => {
      const schema = saveLoadingsSchema()()
      const saveLoadingsHandler = data => {
        const { underwriterName } = data
        const {
          createQuote: { activeIndex, quotes },
        } = state
        const hasPolicy = get(quotes, `${activeIndex}.policyStructure`, []).length
        dispatch(saveLoadings(underwriterName))
        const mode = get(state, 'preAssessment.mode')
        if (mode === PREASSESSMENT_MODE_FROM_SIDEBAR) {
          dispatch(openSidebar('createQuote', 1))
        } else {
          dispatch(closeSidebar())
        }
        if (hasPolicy) {
          dispatch(createQuoteCalculate())
        }
        dispatch(
          setPanelProps({
            setPreAssessmentVisibility: true,
          })
        )
      }
      dispatch(formSubmit(saveLoadingsId, schema, saveLoadingsHandler))
      dispatch(formSubmitComplete(saveLoadingsId))
    },
    props: {
      secondaryHeader: true,
      animation: false,
      forcedBackNav: (dispatch, state) => {
        const mode = get(state, 'preAssessment.mode')
        if (mode === PREASSESSMENT_MODE_FROM_SIDEBAR) {
          dispatch(openSidebar('createQuote', 1))
        } else {
          dispatch(closeSidebar())
        }
      },
    },
  },
  {
    sitecoreFieldHeadingKey: 'preAssessmentSidebarTitle',
    component: AddLoading,
    visible: false,
    nextText: get(fields, 'preAssessmentSidebarAddLoadingButton.value', 'Add Loading'),
    handleNext: (next, dispatch) => {
      const schema = addLoadingSchema()()
      const loadingHandler = data => {
        const { benefit, loadingReason, loadingType, loadingAmount } = data
        const isAbsoluteLoadingType = loadingType.toLowerCase() === 'abs'
        const payload = {
          benefit,
          reason: [
            {
              reasonCode: loadingReason,
            },
          ],
          loadingValueType: loadingType,
          loadingValue: loadingAmount,
          loadingTypeCode: isAbsoluteLoadingType ? LOADING_TYPE_RPM.code : LOADING_TYPE_EMR.code,
          loadingType: isAbsoluteLoadingType ? LOADING_TYPE_RPM.type : LOADING_TYPE_EMR.type,
        }
        benefitList
          .find(item => item.umeCode === benefit)
          .possibleCombination.map(combination => {
            const { reason, type } = combination
            if (reason === loadingReason && type === loadingType) {
              // eslint-disable-next-line no-param-reassign
              combination.selected = true
              // eslint-disable-next-line no-param-reassign
              combination.status = 'saved'
            }
            return combination
          })
        dispatch(gotoPanel(1))
        dispatch(addLoading(payload))
      }
      dispatch(formSubmit(addLoadingId, schema, loadingHandler))
      dispatch(formSubmitComplete(addLoadingId))
    },
    props: {
      secondaryHeader: true,
      animation: false,
    },
  },
  {
    sitecoreFieldHeadingKey: 'preAssessmentSidebarTitle',
    component: EditLoading,
    visible: false,
    nextText: get(fields, 'preAssessmentSidebarUpdateLoadingButton.value', 'Update Loading'),
    handleNext: (next, dispatch, state) => {
      const { activeEditIndex, loadings } = state.preAssessment
      const schema = addLoadingSchema()()
      const editHandler = data => {
        const {
          benefit: lastBenefit,
          loadingReason: lastLoadingReason,
          loadingType: lastLoadingType,
        } = loadings[activeEditIndex]
        const { benefit, loadingReason, loadingType, loadingAmount } = data
        const isAbsoluteLoadingType = loadingType.toLowerCase() === 'abs'

        loadings[activeEditIndex] = {
          benefit,
          reason: [
            {
              reasonCode: loadingReason,
            },
          ],
          loadingValueType: loadingType,
          loadingValue: loadingAmount,
          loadingTypeCode: isAbsoluteLoadingType ? LOADING_TYPE_RPM.code : LOADING_TYPE_EMR.code,
          loadingType: isAbsoluteLoadingType ? LOADING_TYPE_RPM.type : LOADING_TYPE_EMR.type,
        }
        benefitList
          .find(item => item.umeCode === lastBenefit)
          .possibleCombination.map(combination => {
            const { reason, type } = combination
            if (reason === lastLoadingReason && type === lastLoadingType)
              // eslint-disable-next-line no-param-reassign
              combination.selected = false
            return combination
          })

        benefitList
          .find(item => item.umeCode === benefit)
          .possibleCombination.map(combination => {
            const { reason, type } = combination
            if (reason === loadingReason && type === loadingType) {
              // eslint-disable-next-line no-param-reassign
              combination.selected = true
              // eslint-disable-next-line no-param-reassign
              combination.status = 'saved'
            }
            return combination
          })
        dispatch(gotoPanel(1))
        dispatch(updateLoading(loadings))
      }
      dispatch(formSubmit(addLoadingId, schema, editHandler))
      dispatch(formSubmitComplete(addLoadingId))
    },
    props: {
      secondaryHeader: true,
      forcedBackNav: (dispatch, state) => {
        const mode = get(state, 'preAssessment.mode')
        if (mode === PREASSESSMENT_MODE_FROM_SIDEBAR) {
          dispatch(openSidebar('createQuote', 1))
        } else {
          dispatch(closeSidebar())
        }
      },
      animation: false,
    },
  },
]

export default SIDEBAR_SCHEMA
