import { space, mediaQueries, setColumns, colours, fontSize } from '../../../styles'

const styles = {
  fullWidth: {
    width: '100%',
  },
  fullWidthSelect: {
    width: space(5),
  },
  halfColumn: {
    width: `calc(50% - ${space(1, true)})`,
    maxWidth: space(51),
  },
  line: {
    [mediaQueries.md]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, auto)',
      gridRowGap: space(1, true),
      borderStyle: 'solid',
      borderTopWidth: 1,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: 1,
      borderColor: colours.lightestGrey,
      lineHeight: space(0.6),
    },
  },
  lineBox: {
    justifySelf: 'stretch',
  },
  lineBoxHidden: {
    display: 'none',
    [mediaQueries.md]: {
      justifySelf: 'stretch',
      display: 'block',
    },
  },
  activeBox: {
    marginLeft: space(2),
    marginTop: space(2),
    fontSize: space(2),
    justifySelf: 'stretch',
    height: space(3),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingTop: 2,
    paddingBottom: 2,
    display: 'inline',
  },
  textBox: {
    color: colours.darkestGrey,
    whiteSpace: 'nowrap',
    width: space(17),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [mediaQueries.md]: {
      overflow: 'visible',
      width: 'auto',
    },
  },
  textBoxUserId: {
    color: colours.darkestGrey,
  },
  smallButton: {
    width: 'auto',
  },
  button: {
    height: 'auto',
    textAlign: 'center',
    width: '100%',
    fontSize: fontSize.xs,
    background: 'none',
    padding: 0,
  },
  offset: {
    marginTop: -space(7),
  },
  base: {
    paddingBottom: space(8),
    paddingLeft: space(2),
    paddingRight: space(2),
    paddingTop: space(3),

    '& label': {
      marginBottom: 0,
    },

    [mediaQueries.md]: {
      ...setColumns(1.25),
      display: 'flex',
      paddingTop: space(7),
      paddingLeft: space(13),
      paddingRight: space(13),
    },
  },
  body: {
    maxWidth: '450px',
    [mediaQueries.lg]: {
      ...setColumns(1.25),
    },
  },
  header: {
    marginBottom: space(4),

    [mediaQueries.md]: {
      marginBottom: space(5),
    },
  },
  section: {
    marginBottom: space(8),
  },
  title: {
    marginBottom: space(1),
  },
  modalBody: {
    padding: '0 0 2rem 0',
  },
  modalButton: {
    marginTop: space(6),
  },
  postalAddressCheckbox: {
    marginBottom: space(3),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: space(3),
  },
  label: {
    opacity: '0.8',
    flexBasis: '25%',
    fontSize: fontSize.xxs,
    marginBottom: space(1.5),
  },
  values: {
    fontSize: fontSize.xs,
    marginBottom: space(1.5),
  },
  cancelButton: {
    marginLeft: space(4),
  },
}
export default styles
