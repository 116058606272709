// @flow
import React, { Component, type Node } from 'react'

import AccordionItem from './components/AccordionItem'
import { IconChevronDown16, IconChevronUp16 } from '../../atoms/Icons'

type AccordionProps = {
  // label for accordion
  label: Node,
  // accordion state open|close
  isOpen: boolean,
  // child elements to render inside
  children: Node,
  // open Icon
  openIcon?: Node,
  // close Icon
  closeIcon?: Node,
  // to differntiate item in accordion list
  labelKey?: string,
  // to disable toggle feature if its not an active accordion item
  disableToogle?: boolean,
  // close current accordion item if its just been updated and value is true
  isUpdated: boolean,
  // sends toggle state to parent component
  toggleListener: Function,
  // is padding needed or not
  isPaddingNeeded: boolean,
  // no of task
  noOfTask: string,
  // set chosen Accordion key for parent container
  chosenAccordion?: string,
  setChosenAccordion: Function,
}

class Accordion extends Component<AccordionProps> {
  constructor(props) {
    super(props)
    this.state = {
      openSections: {},
    }
    this.handleToggle = this.handleToggle.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { isOpen, chosenAccordion, labelKey } = this.props
    // close current accordion if the user clicks on others
    if (chosenAccordion !== prevProps.chosenAccordion && chosenAccordion !== labelKey) {
      this.updateOpenSections(false)
    } else if (isOpen !== prevProps.isOpen) {
      this.updateOpenSections(isOpen)
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { labelKey, isOpen, toggleListener } = nextProps
    const { openSections } = state
    if (openSections && openSections[labelKey]) {
      // check if toggleListener is added then parent component will manage the Accrdian state
      if (openSections[labelKey] === isOpen || !toggleListener) {
        return null
      }
    }

    return {
      openSections: {
        [nextProps.labelKey]: isOpen,
      },
    }
  }

  updateOpenSections(isOpen) {
    const { labelKey } = this.props
    this.setState({
      openSections: {
        [labelKey]: isOpen,
      },
    })
  }

  handleToggle(labelKey) {
    const { disableToogle, toggleListener, setChosenAccordion } = this.props
    const { openSections } = this.state

    const isOpen = !!openSections[labelKey]

    this.setState({
      openSections: {
        [labelKey]: disableToogle ? isOpen : !isOpen,
      },
    })
    if (toggleListener) {
      toggleListener(disableToogle ? isOpen : !isOpen)
    }
    // set the chosen accordion for parent container as the current one
    if (!disableToogle && setChosenAccordion) {
      setChosenAccordion(labelKey)
    }
  }

  render() {
    const {
      label,
      children,
      openIcon,
      closeIcon,
      labelKey,
      isPaddingNeeded,
      noOfTask,
      removeIcon,
      toggleLabel,
    } = this.props
    const { openSections } = this.state
    const updatedLabel = toggleLabel || label
    return (
      <AccordionItem
        {...this.props}
        labelKey={labelKey}
        label={!!openSections[labelKey] === false ? label : updatedLabel}
        onToggle={this.handleToggle}
        openIcon={openIcon}
        closeIcon={closeIcon}
        isOpen={!!openSections[labelKey]}
        isPaddingNeeded={isPaddingNeeded}
        noOfTask={noOfTask}
        removeIcon={removeIcon || false}
      >
        {children}
      </AccordionItem>
    )
  }
}

Accordion.defaultProps = {
  openIcon: <IconChevronDown16 data-testid="open-btn" />,
  closeIcon: <IconChevronUp16 data-testid="close-btn" />,
  disableToogle: false,
  labelKey: '',
  chosenAccordion: '',
}

export default Accordion
