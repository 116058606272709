// @flow
import axios from 'axios'
import get from 'lodash/get'

// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  TASK_DASHBOARD_FETCH,
  GENERATE_FREE_TEXT_SUCCESS,
  GENERATE_FREE_TEXT_ERROR,
  GENERATE_FREE_TEXT_FAILURE,
  GENERATE_FREE_TEXT_INIT,
  TASK_DASHBOARD_FILTER,
} from '../types/taskDashboard'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'

// utils
import { makeRequestHeaders } from '../../utils/middlewareUtils'
import { getToken } from '../../utils/cookieUtils'

export const getOutstandingRequirement =
  (data?: Object, callback?: Function = () => {}) =>
  (dispatch: Function) => {
    const outstadingRequirement = {
      type: EXPERIENCE_API,
      name: TASK_DASHBOARD_FETCH,
      verb: 'POST',
      data,
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/requirements/search`,
      callback: (err, dataReceived) => {
        if (callback) {
          callback(err, dataReceived)
        }
        return dataReceived
      },
    }
    dispatch(outstadingRequirement)
  }

export const filterListPostDocumentUploadSuccess =
  (id: string) => (dispatch: Function, getState: Function) => {
    const records = get(getState().taskDashboard, 'taskDashboard.records', [])
    records.forEach(task => {
      task.outstandingRequirements = task.outstandingRequirements.filter(ostReq => ostReq.ID !== id)
    })

    dispatch({
      type: TASK_DASHBOARD_FILTER,
      payload: { records },
    })
  }

const makeFreeTextRequestPayload = data => {
  const request = new FormData()
  const freeformAnswerDetails = JSON.stringify(data.freeformAnswerDetails)

  request.append('agreementReqId', data.agreementReqId)
  request.append('agreementNumber', data.agreementNumber)
  request.append('docSubTypeCode', data.docSubTypeCode)
  request.append('workType', data.workType)
  request.append('correlationID', data.correlationID)
  request.append('freeformAnswerDetails', freeformAnswerDetails)
  return request
}

export const generateFreeText =
  (data: Ojbect, callback?: Function = () => {}) =>
  (dispatch: Function, getState: Function) => {
    const { config } = getState()
    const API_DOMAIN = config.MLCL_EXPERIENCE_API
    dispatch({
      type: GENERATE_FREE_TEXT_INIT,
    })
    const headers = makeRequestHeaders(
      'multipart/form-data',
      '',
      {
        'X-InitialRole': config.MLCL_UPLOAD_FILE_API_INITIAL_ROLE,
      },
      true
    )
    const url = `${API_DOMAIN}${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/documents`
    axios({
      url,
      method: 'POST',
      headers: {
        ...headers,
        token: getToken(),
      },
      data: makeFreeTextRequestPayload(data),
    })
      .then(res => {
        const result = res.data
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: GENERATE_FREE_TEXT_SUCCESS,
            payload: result,
          })
          const status = get(result, 'status.type', '')
          if (status === 'success') {
            const taskIdToFilterOut = get(data, 'agreementReqId', '')
            dispatch(filterListPostDocumentUploadSuccess(taskIdToFilterOut))
          }
          callback(status)
        } else {
          dispatch({ type: GENERATE_FREE_TEXT_ERROR, payload: { error: res.error } })
        }
      })
      .catch(error => {
        const err = error && error.response && error.response.data
        dispatch({ type: GENERATE_FREE_TEXT_FAILURE, payload: { error: err } })
      })
  }
