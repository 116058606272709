import get from 'lodash/get'
import {
  MLC_ACC,
  NAVIGATOR,
  CAPSIL,
  PLUM,
  WRAP,
  SOURCE_FUNDING_SYSTEM_COMPASS,
  MASTER_KEY_ACCOUNT_ID,
  PLUM_SUPER_ID,
  WRAPPER_NAVIGATOR_ID,
  FUND_PAYMENT_METHODS_IOOF,
} from '../constants/policies'

const cashReceiptSubtypes = {
  WO: 'Write Off',
  ET: 'Electronic Transfer',
  ME: 'MLCI Eligible Account',
  ER: 'E-Rollover',
  CQ: 'Cheque',
  IN: 'Internal Transfer',
  BP: 'B-Pay',
  CC: 'Credit Card',
}

export const getPaymentMethod = (code, type) => {
  switch (code) {
    case 'CC':
      return 'Credit Card'
    case 'DD':
      return 'Direct Debit'
    case 'CHEQUE':
    case 'CQ':
      return 'Cheque'
    case 'BPAY':
    case 'BP':
      return 'BPAY'
    case 'EC':
      return 'E-Contribution'
    case 'ER':
      return 'E-Rollover'
    case 'EMA':
    case 'ME':
      return 'Masterkey Account'
    case 'WRAP':
      return 'Wrap/Navigator Account'
    case 'IFEX':
      return 'Expand Extra Super'
    case 'IFES':
      return 'Expand Essential Super'
    case 'IFPE':
      return 'IOOF Personal Super'
    case 'IFSS':
      return 'Shadforth Portfolio Service - Super'
    case 'IFXI':
      return 'Expand Extra Investment'
    case 'IFEI':
      return 'Expand Essential Investment'
    case 'PLUM':
      return 'Plum Super'
    case 'CS':
    case 'RC':
      return cashReceiptSubtypes[type] || 'Other'
    default:
      return '-'
  }
}

const creditCardType2Display = {
  MC: 'Mastercard',
  VI: 'Visa',
}
export const sourceFundSystem2paymentMethod = {
  [SOURCE_FUNDING_SYSTEM_COMPASS]: MASTER_KEY_ACCOUNT_ID,
  [CAPSIL]: MASTER_KEY_ACCOUNT_ID,
  [PLUM]: PLUM_SUPER_ID,
  [NAVIGATOR]: WRAPPER_NAVIGATOR_ID,
  [WRAP]: WRAPPER_NAVIGATOR_ID,
  [FUND_PAYMENT_METHODS_IOOF[0]]: FUND_PAYMENT_METHODS_IOOF[0],
  [FUND_PAYMENT_METHODS_IOOF[1]]: FUND_PAYMENT_METHODS_IOOF[1],
  [FUND_PAYMENT_METHODS_IOOF[2]]: FUND_PAYMENT_METHODS_IOOF[2],
  [FUND_PAYMENT_METHODS_IOOF[3]]: FUND_PAYMENT_METHODS_IOOF[3],
  [FUND_PAYMENT_METHODS_IOOF[4]]: FUND_PAYMENT_METHODS_IOOF[4],
  [FUND_PAYMENT_METHODS_IOOF[5]]: FUND_PAYMENT_METHODS_IOOF[5],
}
export const getPaymentCollectionMethod = (premiumPaymentMethod, paymentDetails = {}) => {
  const paymentMethod = paymentDetails.collectionMethod || premiumPaymentMethod
  // This is for an edge case where we get premiumPaymentMethod incorrectly
  // as all payment types are suspended
  if (premiumPaymentMethod && Object.keys(paymentDetails).length === 0) {
    if (!['CHEQUE', 'CQ', 'BP', 'BPAY', 'EC'].includes(premiumPaymentMethod)) {
      return '-'
    }
  }
  if (paymentMethod === MLC_ACC) {
    const sourceFundSystem = get(paymentDetails, 'fundDetails.sourceFundingSystem', '')
    return getPaymentMethod(sourceFundSystem2paymentMethod[sourceFundSystem])
  }
  if (paymentMethod === 'CC') {
    const creditCardType =
      creditCardType2Display[get(paymentDetails, 'creditCardDetails.cardType', '')]
    return `${getPaymentMethod(paymentMethod)}${creditCardType ? ` (${creditCardType})` : ''}`
  }
  return getPaymentMethod(paymentMethod)
}

export const getPaymentCollectionMethodOnPaymentHistory = (_, historyItem) => {
  let { paymentMethod } = historyItem
  const { paymentType, sourceFundingSystem } = historyItem
  // sourceFundingSystem of Navigator can never have a payment history of non Navigator payments
  if (paymentMethod === MLC_ACC) {
    paymentMethod = sourceFundSystem2paymentMethod[sourceFundingSystem]
  }
  return getPaymentMethod(paymentMethod, paymentType)
}
