// @flow
import { space, colours } from '../../../styles'
import { PROJECTIONS_CHART_LINES } from './helpers/projections.chart'

const styles = {
  wrapper: {
    padding: space(3, true),
  },

  chartFormContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    '& > div:last-of-type': {
      alignContent: 'start',
      marginTop: space(6, true),
    },
  },

  headingContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: space(2, true),
  },

  discounts: {
    paddingTop: space(1, true),
    paddingLeft: 0,
    color: colours.mediumGrey,
  },

  tableContainer: {
    margin: `${space(2, true)} 0`,
  },

  disclaimer: {
    marginTop: space(2, true),
    a: {
      textDecoration: 'none',
      color: colours.lightGreen,
    },
  },

  downloadProjectionContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  styledCheckbox: (structure: string) => ({
    paddingLeft: space(2, true),
    label: {
      '&:before': {
        background: PROJECTIONS_CHART_LINES.find(
          chart => chart.key.toLowerCase() === structure.toLowerCase()
        ).color,
        boxShadow: 'none',
      },
      svg: {
        color: colours.overlayWhite,
      },
    },
  }),
}

export default styles
