// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './icon.styles'
import { themeConsumer } from '../../../../styles/ThemeContext'

type IconProps = {
  // Contents of Icon.
  children?: Node,
  // Set the size of Icon
  size?: 'sm' | 'md' | 'lg',
  // Styles to be added on top of base styles of Icon.
  style?: Object,
  width: string,
  height: string,
}

const Icon = (props: IconProps) => {
  const { style, children, size, width, height } = props
  const svgProps = {
    width: width || size,
    height: height || size,
    viewBox: `0 0 ${width || size} ${height || size}`,
  }

  const SVG = styled('svg')(style, ({ theme }) => styles.base(theme))

  return (
    <SVG {...props} {...svgProps} preserveAspectRatio="xMidYMid meet">
      {children}
    </SVG>
  )
}

Icon.defaultProps = {
  children: undefined,
  size: 'sm',
  style: {},
}

export default themeConsumer(Icon, 'icon')
