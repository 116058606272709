/* eslint-disable no-unused-vars */
// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { BarChart, Loader } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../utils/telemetry'

// Actions
import { actionCreators } from '../../../actions'

// Components
import Select from '../../atoms/Select'
import MultiSectionSelect from '../../atoms/MultiSectionSelect'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'

// Constants
import { TOAST_ID_VISUALISER_ERROR, TOAST_TITLE_VISUALISER_ERROR } from '../../../constants/toast'

// Utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { getActiveQuote } from '../../../utils/quoteUtils'
import { transformToSelectOptions } from '../../../utils/commonUtils'

// Styles
import styles from './visualiser.styles'
import { colours, fontSize as commonFontSize, fontWeights } from '../../../styles'

// Styles components
const Wrapper = styled('div')(styles.wrapper)
const DropdownsWrapper = styled('div')(styles.dropdownsWrapper)
const ClaimScenarioDropdown = styled(MultiSectionSelect)(styles.dropdown)
const TimelineDropdown = styled(Select)(styles.dropdown)
const ChartsContainer = styled('div')(styles.chartsContainer)
const ChartWrapper = styled('div')(styles.chartWrapper)
const Note = styled('p')(styles.note)
const LoaderOuterDiv = styled('div')(styles.loaderOuterDiv)
const LoaderInnerDiv = styled('div')(styles.loaderInnerDiv)
const DisclaimerBtnWrapper = styled('div')(styles.disclaimerBtnWrapper)

type VisualiserLabelPropTypes = {
  x: Number,
  value: String,
}

const renderLeftLabel = ({ x, value }: VisualiserLabelPropTypes) => (
  <text
    x={x}
    y={355}
    fill={colours.darkGrey}
    textAnchor="start"
    dy={-5}
    dx={-98}
    transform={`rotate(-90 ${x} 355)`}
    fontSize={commonFontSize.xxs}
  >
    {value}
  </text>
)

type VisualiserPropsType = {
  data: Object,
  preClaimOptions: Array<Object>,
  actions: Object,
  quote: Object,
  incidentBasedOptions: Array<Object>,
  benefitBasedOptions: Array<Object>,
  fields: Object,
  isFetching: boolean,
}

type VisualiserStateType = {
  claimScenarioOptions: Array<Object>,
  claimScenario: string,
  timelineOptions: Array<Object>,
  timeline: string,
  graphsData: Array<Object>,
  graphsNote: string,
}

class Visualiser extends PureComponent<VisualiserPropsType, VisualiserStateType> {
  constructor(props) {
    super(props)

    this.state = {
      claimScenarioOptions: this.getClaimScenarioOptions(),
      claimScenario: props.preClaimOptions[0].code,
      timelineOptions: [],
      timeline: '',
      graphsData: [],
      graphsNote: '',
    }

    this.claimScenarioHandler = this.claimScenarioHandler.bind(this)
    this.timelineHandler = this.timelineHandler.bind(this)
    this.disclaimerHandler = this.disclaimerHandler.bind(this)
    this.handleVisualiserStateUpdate = this.handleVisualiserStateUpdate.bind(this)

    const tagEvent = createEvent({
      GA: {
        category: 'Visualiser',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Visualiser - View',
        },
      },
    })
    tagEvent.end()
  }

  componentDidMount() {
    this.updateVisualiserData()
  }

  componentDidUpdate(prevProps) {
    const { data, isFetching } = this.props
    if (isFetching !== prevProps.isFetching && !isFetching) {
      this.handleVisualiserStateUpdate()
    }
  }

  updateVisualiserData = () => {
    const {
      actions: { getVisualiserData },
      quote,
    } = this.props
    const { claimScenario } = this.state
    getVisualiserData({
      visualizerCode: claimScenario,
      quote,
    })
  }

  getTimelineOptions = updatedProps => {
    const { data } = updatedProps
    return get(data, 'visualizerTimeline', []).map((timeline, index) => ({
      label: timeline.label,
      value: index,
    }))
  }

  getFilteredOptions = options => {
    const { quote } = this.props
    return options.filter(option => {
      if (!option.relatedBenefits) {
        return true
      }
      const eligibleBenefitsList = option.relatedBenefits.split(',')
      return quote.policyStructure.some(policy =>
        policy.covers.some(cover => eligibleBenefitsList.indexOf(cover.type) > -1)
      )
    })
  }

  getClaimScenarioOptions = () => {
    const { fields } = this.props
    const { incidentBasedOptions, benefitBasedOptions, preClaimOptions } = this.props
    return [
      {
        heading: renderTextField(fields.benefitBasedOptionsHeading),
        options: this.getFilteredOptions(transformToSelectOptions(benefitBasedOptions)),
        width: '200px',
        rightBorder: true,
      },
      {
        heading: renderTextField(fields.incidentBasedOptionsHeading),
        options: this.getFilteredOptions(transformToSelectOptions(incidentBasedOptions)),
        width: '310px',
      },
      {
        options: transformToSelectOptions(preClaimOptions),
      },
    ]
  }

  claimScenarioHandler = field => {
    this.setState(
      {
        claimScenario: field.value.value,
      },
      () => {
        this.updateVisualiserData()
      }
    )
  }

  timelineHandler = field => {
    this.setState({
      timeline: field.value,
      graphsData: this.getGraphsData(field.value.value),
      graphsNote: this.getGraphsNote(field.value.value),
    })
  }

  transformToGraphData = bars =>
    bars.map(bar => ({
      value: bar.outstandingAmount,
      leftLabel: bar.barName,
      topLabel: bar.outstandingAmountLabel,
      bottomLabel: bar.paidOutAmount ? bar.paidOutAmountLabel : '',
      insideLabel: bar.waitPeriodOn ? bar.waitPeriod : '',
    }))

  getGraphsData = index => {
    const { data } = this.props
    const selectedTimeline = get(data, 'visualizerTimeline', [])[index]
    const graphs = get(selectedTimeline, 'graphs', [])
    if (graphs.length) {
      return graphs.map((graph, graphIndex) => ({
        heading: {
          label: graph.heading,
          align: graphIndex === graphs.length - 1 ? 'left' : 'center',
        },
        data: this.transformToGraphData(graph.bars),
      }))
    }
    return []
  }

  getGraphsNote = index => {
    const { data } = this.props
    return get(data, `visualizerTimeline[${index}].imageText`, '')
  }

  getChartWidth = (graphsData, index) => {
    const totalBars = graphsData.reduce((count, graphData) => count + graphData.data.length, 0)
    // all horizontal space can be divided between the graphs
    if (totalBars <= 9) {
      // if only one graph
      if (graphsData.length === 1) {
        return 795
      }
      return (785 * graphsData[index].data.length) / totalBars
    }
    // if horizontal scroll needs to be shown as number of bars are more
    return graphsData[index].data.length * 90
  }

  disclaimerHandler = () => {
    const {
      actions: { nextPanel },
    } = this.props
    nextPanel()
  }

  handleVisualiserStateUpdate() {
    const { data } = this.props
    if (get(data, 'visualizerTimeline', []).length) {
      const timelineOptions = this.getTimelineOptions(this.props)
      this.setState({
        timelineOptions,
        timeline: timelineOptions[0].value,
        graphsData: this.getGraphsData(0),
        graphsNote: this.getGraphsNote(0),
      })
    } else {
      // error scenario
      toast(TOAST_TITLE_VISUALISER_ERROR, {
        toastId: TOAST_ID_VISUALISER_ERROR,
        type: toast.TYPE.ERROR,
      })
      this.setState({
        timelineOptions: [],
        timeline: '',
        graphsData: [],
        graphsNote: '',
      })
    }
  }

  renderGraphs() {
    const { isFetching } = this.props
    const { graphsData, graphsNote } = this.state
    const labelList = [
      {
        dataKey: 'topLabel',
        position: 'top',
        fontWeight: fontWeights.semiBold,
        fontSize: commonFontSize.xxs,
      },
      {
        dataKey: 'bottomLabel',
        position: 'bottom',
        fontWeight: fontWeights.semiBold,
        fontSize: commonFontSize.xxs,
      },
      {
        dataKey: 'insideLabel',
        position: 'insideBottom',
        color: colours.white,
        dy: -10,
        fontWeight: fontWeights.semiBold,
        fontSize: commonFontSize.xxs,
      },
      {
        dataKey: 'leftLabel',
        position: 'insideBottomLeft',
        angle: -90,
        content: renderLeftLabel,
      },
    ]
    // show loader at center during api call
    if (isFetching) {
      return (
        <LoaderOuterDiv>
          <LoaderInnerDiv>
            <Loader type="panel" />
          </LoaderInnerDiv>
        </LoaderOuterDiv>
      )
    }
    return (
      <div>
        <Note>{graphsNote}</Note>
        <ChartsContainer>
          {graphsData.map((graphData, index) => (
            <ChartWrapper>
              <Heading element={3} size={4} css={styles.alignment(graphData.heading.align)}>
                {graphData.heading.label}
              </Heading>
              <BarChart
                data={graphData.data}
                width={this.getChartWidth(graphsData, index)}
                labelList={labelList}
              />
            </ChartWrapper>
          ))}
        </ChartsContainer>
      </div>
    )
  }

  render() {
    const { fields, isFetching } = this.props
    const { claimScenarioOptions, claimScenario, timeline, timelineOptions } = this.state
    return (
      <Wrapper>
        <DropdownsWrapper>
          <ClaimScenarioDropdown
            label={renderTextField(fields.claimScenarioField)}
            value={claimScenario}
            name="claimScenario"
            dataLocator="claimScenario"
            changeHandler={this.claimScenarioHandler}
            data={claimScenarioOptions}
          />
          <TimelineDropdown
            label="Timeline"
            value={timeline}
            name="timeline"
            dataLocator="timeline"
            changeHandler={this.timelineHandler}
            options={timelineOptions}
            disabled={isFetching}
          />
        </DropdownsWrapper>
        {this.renderGraphs()}
        <DisclaimerBtnWrapper>
          <Button type="tertiary" onClick={this.disclaimerHandler}>
            {renderTextField(fields.visualiserDisclaimerBtn)}
          </Button>
        </DisclaimerBtnWrapper>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({ masterList, createQuote, visualiser }) => ({
  incidentBasedOptions: get(masterList, 'data.incidentTypes', []),
  benefitBasedOptions: get(masterList, 'data.benefitBased', []),
  preClaimOptions: get(masterList, 'data.preClaimBased', []),
  quote: getActiveQuote(createQuote),
  ...visualiser,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Visualiser)
