import { space, colours } from '../../../styles'

const styles = {
  base: {
    backgroundColor: colours.white,
    padding: `0 ${space(3, true)}`,
    width: 400,
    height: '100%',
    position: 'relative',
    button: {
      textAlign: 'left',
    },
    span: {
      maxWidth: space(37),
    },
  },
}
export default styles
