import { colours, space } from '../../../../../styles'

const styles = {
  wrapper: {
    position: 'relative',
    width: space(50),
    backgroundColor: colours.white,
    padding: space(3),
    paddingRight: space(1),
  },
  section: {
    clear: 'both',
    marginBottom: space(3),
    svg: {
      float: 'right',
      color: colours.black,
    },
  },
  labelHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  labelSubheading: {
    display: 'flex',
    color: colours.mediumGrey,
  },
  LabelContainer: {
    display: 'flex',
  },
  infoIconHealthyLiving: {
    position: 'absolute',
    color: colours.lightBlack,
    margin: space(1, true),
    width: space(2),
    height: space(2),
    top: 0,
    right: '2px',
  },
  tooltipContent: {
    width: space(39, true),
    textAlign: 'left',
  },
  ToggleWrapper: {
    marginRight: space(2, true),
  },
}

export default styles
