// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
// components.
import { IconFileUpload64 } from '../../../../atoms/Icons'
import Button from '../../../../atoms/Button'
// styles.
import styles from './dropzone.styles'

const Container = styled('div')(styles.container)
const FileInput = styled('input')(styles.fileInput)
const BrowseLink = styled(Button)(styles.browseLink)
const DescriptionBox = styled('div')(styles.descriptionBox)
const FileUploadIcon = styled(IconFileUpload64)(({ isFileUploading }) =>
  styles.fileUploadIcon(isFileUploading)
)

type DropzoneProps = {
  // to enable html5 multi input
  hasMultipleFiles: boolean,
  // handle on drag and drop
  handleFileDrop: Function,
  // handle on file change
  validateFiles: Function,
  // return true if any file upload is going on
  isFileUploading: boolean,
  // disable uploading of multiple files
  isDisableMultipleFiles: boolean,
}

class Dropzone extends Component<DropzoneProps> {
  constructor(props) {
    super(props)
    this.fileInputRef = React.createRef()
  }

  handleChange = ({ target }) => {
    const { files } = target

    const { handleFileDrop, validateFiles, isFileUploading } = this.props
    if (isFileUploading) {
      return
    }

    // eslint-disable-next-line no-unused-expressions
    Object.keys(files).length && handleFileDrop(validateFiles(files))
    // set target to blank to handle same file browsing browser limitation
    target.value = ''
  }

  openFileDialog = () => {
    const { isFileUploading } = this.props
    if (isFileUploading) return false

    return this.fileInputRef.current.click()
  }

  render() {
    const { hasMultipleFiles, isFileUploading, isDisableMultipleFiles } = this.props
    return (
      <Container>
        <FileInput
          ref={this.fileInputRef}
          type="file"
          multiple={hasMultipleFiles}
          onChange={this.handleChange}
          data-testid="drop-zone-input"
        />
        <FileUploadIcon isFileUploading={isFileUploading} data-testid="drop-zone-icon" />

        <DescriptionBox onClick={this.openFileDialog}>
          Drop form here or
          <BrowseLink disabled={isFileUploading || isDisableMultipleFiles} type="tertiary">
            Browse
          </BrowseLink>
          to upload..
        </DescriptionBox>
      </Container>
    )
  }
}
export default Dropzone
