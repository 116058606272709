// @flow
/* eslint-disable react/require-default-props */
import React, { Fragment, Node } from 'react'
import styled from '@emotion/styled'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'

// components
import Header from '../PageHeader'

// style
import styles from './personalStatementLayout.styles'

// constants
import { THEME_LIGHT, THEME_DARK } from '../../../constants/themes'
import { CUSTOMER_PORTAL } from '../../../constants/site'

const MainWrapper = styled('div')(({ fillBg }) => styles.wrapper(fillBg))

type PersonalStatementProps = {
  children: Node,
  fields: Object,
  fillChildBackground?: boolean,
  pageTitle?: string,
  pageSubTitle?: string,
  config: Object,
  showBackIcon?: Boolean,
  backClickHandler?: Function,
}

export const PersonalStatementLayout = ({
  children,
  fillChildBackground,
  pageTitle,
  pageSubTitle,
  fields: { personalStatementOTPPageTitle, personalStatementOTPPageSubTitle },
  config,
  showBackIcon,
  backClickHandler,
}: PersonalStatementProps) => {
  const theme = config.SITE === CUSTOMER_PORTAL ? THEME_LIGHT : THEME_DARK
  return (
    <Fragment>
      <Header
        theme={theme}
        heading={pageTitle || personalStatementOTPPageTitle}
        subHeading={pageSubTitle || personalStatementOTPPageSubTitle}
        showBackIcon={showBackIcon}
        backClickHandler={backClickHandler}
      />
      <PageWrap>
        <MainWrapper fillBg={fillChildBackground}>{children}</MainWrapper>
      </PageWrap>
    </Fragment>
  )
}

PersonalStatementLayout.defaultProps = {
  fillChildBackground: true,
  pageTitle: '',
  pageSubTitle: '',
  showBackIcon: false,
  backClickHandler() {},
}

export const mapStateToProps = ({ config }) => ({
  config,
})

export default connect(mapStateToProps)(PersonalStatementLayout)
