// @flow
import get from 'lodash/get'
import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  SIDEBAR_HIDE_FOOTER,
  SIDEBAR_SHOW_FOOTER,
  SIDEBAR_NEXT_PANEL,
  SIDEBAR_PREVIOUS_PANEL,
  SIDEBAR_GOTO_PANEL,
  SIDEBAR_SET_SUB_PANEL,
  SIDEBAR_SET_TAB,
  SIDEBAR_DIRECTION,
  SIDEBAR_SET_PANEL_PROPS,
  SIDEBAR_SET_IS_NEXT_BTN_LOADER,
  DISABLE_SIDEBAR_CLOSING,
  UPDATE_QUICK_QUOTE_CHECK,
  SIDE_BAR_SET_SOURCE,
} from '../actions/types/sidebar'

export const initialState = {
  open: false,
  hideFooter: false,
  schemaName: 'default',
  panelProps: null,
  currentPanel: 0,
  nextTabSideBarPanel: 0,
  nextTabSideBarLabelPanel: null,
  activeSubPanel: undefined,
  maxLength: 0,
  leftAligned: false,
  changingAlignment: false,
  componentName: 'Sidebar',
  isSidebarClosingAllowed: true,
  quickQuoteCheck: false,
  source: '',
}

const sidebar = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case SIDEBAR_DIRECTION: {
      const { leftAligned } = action.payload
      return {
        ...state,
        changingAlignment: true,
        leftAligned,
      }
    }
    case SIDEBAR_OPEN: {
      const {
        schemaName,
        currentPanel,
        panelProps,
        maxLength,
        activeSubPanel,
        nextTabSideBarPanel,
        nextTabSideBarLabelPanel,
        componentName,
      } = action.payload
      return {
        ...state,
        open: true,
        schemaName,
        currentPanel: currentPanel <= maxLength && currentPanel >= 0 ? currentPanel : 0,
        panelProps: panelProps || state.panelProps,
        maxLength,
        activeSubPanel,
        nextTabSideBarPanel: nextTabSideBarPanel || 0,
        nextTabSideBarLabelPanel: nextTabSideBarLabelPanel || null,
        changingAlignment: false,
        componentName,
      }
    }

    case SIDEBAR_CLOSE: {
      return {
        ...state,
        open: false,
        currentPanel: 0,
        panelProps: undefined,
        maxLength: 0,
        leftAligned: false,
        schemaName: 'default',
        componentName: 'Sidebar',
        isSidebarClosingAllowed: true,
      }
    }

    case SIDEBAR_HIDE_FOOTER: {
      return {
        ...state,
        hideFooter: true,
      }
    }

    case SIDEBAR_NEXT_PANEL: {
      const activeSubPanel = get(action, 'payload.activeSubPanel', state.activeSubPanel)
      if (state.currentPanel === state.maxLength) return state
      return {
        ...state,
        currentPanel: state.currentPanel + 1,
        activeSubPanel,
      }
    }

    case SIDEBAR_SET_PANEL_PROPS: {
      const panelProps = get(action, 'payload.panelProps', state.nextProps)
      if (!panelProps) return state
      return {
        ...state,
        panelProps: {
          ...state.panelProps,
          ...panelProps,
        },
      }
    }

    case SIDEBAR_PREVIOUS_PANEL: {
      const activeSubPanel = get(action, 'payload.activeSubPanel', state.activeSubPanel)
      if (state.currentPanel === 0) return state
      return {
        ...state,
        currentPanel: state.currentPanel - 1,
        activeSubPanel,
      }
    }

    case SIDEBAR_SET_SUB_PANEL: {
      const activeSubPanel = get(action, 'payload.activeSubPanel', state.activeSubPanel)
      return {
        ...state,
        activeSubPanel,
      }
    }

    case SIDEBAR_SET_TAB: {
      const nextTabSideBarPanel = get(
        action,
        'payload.nextTabSideBarPanel',
        state.nextTabSideBarPanel
      )
      const nextTabSideBarLabelPanel = get(
        action,
        'payload.nextTabSideBarLabelPanel',
        state.nextTabSideBarLabelPanel
      )
      return {
        ...state,
        nextTabSideBarPanel,
        nextTabSideBarLabelPanel,
      }
    }

    case SIDEBAR_GOTO_PANEL: {
      const activeSubPanel = get(action, 'payload.activeSubPanel', state.activeSubPanel)
      const { currentPanel, panelProps } = action.payload
      if (currentPanel > state.maxLength) return state
      return {
        ...state,
        currentPanel,
        panelProps,
        activeSubPanel,
      }
    }

    case SIDEBAR_SHOW_FOOTER: {
      return {
        ...state,
        hideFooter: false,
      }
    }

    case SIDEBAR_SET_IS_NEXT_BTN_LOADER: {
      const { isNextBtnLoader } = action.payload
      return {
        ...state,
        isNextBtnLoader,
      }
    }

    case DISABLE_SIDEBAR_CLOSING: {
      return {
        ...state,
        isSidebarClosingAllowed: false,
      }
    }

    case UPDATE_QUICK_QUOTE_CHECK: {
      const { payload } = action
      return {
        ...state,
        quickQuoteCheck: payload,
      }
    }

    case SIDE_BAR_SET_SOURCE: {
      const { source } = action.payload

      return {
        ...state,
        source,
      }
    }

    default: {
      return state
    }
  }
}

export default sidebar
