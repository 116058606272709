// @flow
// types.
import get from 'lodash/get'
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  ADVISOR_DETAILS_FETCH,
  ADVISOR_DETAILS_UPDATE,
  ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST,
  ADVISOR_REMOVE_SUPPORT_STAFF,
  CUSTOMER_FETCH_ADVISER,
  CUSTOMER_REMOVE_ADVISER,
  ADVISOR_DETAILS_FETCH_FAILURE,
  RESET_ADVISOR_DATA,
  ADVISOR_BETA_TESTING_FEATURES_FETCH,
} from '../types/advisor'

// actions
import { clearInactiveAdviser } from './okta'
// utils.
import history from '../../utils/browserHistory'
import { getBancsCustomerNumber } from '../../utils/cookieUtils'

// constants.
import {
  EXPERIENCE_API_BASE,
  EXPERIENCE_API_VERSION_1,
  SITECORE_API_BASE,
} from '../../constants/apis'
import { STATUS_ACTIVE, INACTIVE_ADVISER_URL } from '../../constants/adviser'
import { ADVISOR_PORTAL } from '../../constants/site'
import { SITECORE_API } from '../types/sitecoreApi'

/* eslint-disable max-len */
export const retrieveAdviserSupportStaff =
  (payload: Object, callback: Function = () => {}) =>
  dispatch => {
    const { agencyCode } = payload
    dispatch({
      type: EXPERIENCE_API,
      name: ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST,
      verb: 'GET',
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/advisers/relationship/search/${agencyCode}`,
      callback: (err, dataReceived) => {
        callback({ ...dataReceived })
        return { ...dataReceived.businessData }
      },
    })
  }

export const removeAdviserSupportStaff =
  (payload: Object, callback: Function = () => {}) =>
  dispatch => {
    dispatch({
      type: EXPERIENCE_API,
      name: ADVISOR_REMOVE_SUPPORT_STAFF,
      verb: 'POST',
      data: {
        ...payload,
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/advisers/relationship/remove`,
      callback: (err, dataReceived) => {
        callback(err, dataReceived)
        return { memberRemovalResponse: dataReceived }
      },
    })
  }

export const getAdvisorDetails =
  (callback: Function = () => {}, defaultBancsCustomerNo) =>
  (dispatch: Function, getState: Function): Object => {
    // set the isLoading key in the store.
    dispatch({
      type: ADVISOR_DETAILS_FETCH,
    })
    const { config } = getState()
    const bancsCustomerNo = defaultBancsCustomerNo || getBancsCustomerNumber()

    if (bancsCustomerNo) {
      // dispatch the action to call the api.
      dispatch({
        type: EXPERIENCE_API,
        name: ADVISOR_DETAILS_FETCH,
        verb: 'GET',
        route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${bancsCustomerNo}?adviser=YES`,
        callback: (err, dataReceived) => {
          // if advisor api fails revoke token
          if (err) dispatch({ type: ADVISOR_DETAILS_FETCH_FAILURE, payload: err })
          const {
            adviserDetails,
            member = {},
            isPortalLoginAllowed,
          } = get(dataReceived, 'businessData', {})
          const isAdviserPortal = config && config.SITE === ADVISOR_PORTAL
          // check if user not have suspended agency
          if (isAdviserPortal && (!isPortalLoginAllowed || err)) {
            dispatch(clearInactiveAdviser())
            return history.push(INACTIVE_ADVISER_URL)
          }

          const activeAdviserDetails = adviserDetails
            ? adviserDetails.reduce((adviserDetail, item) => {
                if (item.status === STATUS_ACTIVE) adviserDetail.push(item)
                return adviserDetail
              }, [])
            : []

          const activeBancsAgencyCodes = activeAdviserDetails.reduce((agencyCodes, item) => {
            if (item.status === STATUS_ACTIVE) agencyCodes.push(item.bancsAgencyCode)
            return agencyCodes
          }, [])

          const filteredAdviserDetails = {
            ...member,
            ...get(activeAdviserDetails, '[0]', {}),
            adviserDetails: activeAdviserDetails,
            bancsAgencyCodes: activeBancsAgencyCodes,
          }

          if (callback) callback(filteredAdviserDetails)
          // pass subset of response, or during error pass whole payload down.
          return filteredAdviserDetails
        },
      })
    } else {
      dispatch({ type: ADVISOR_DETAILS_FETCH_FAILURE })
    }
  }

export const updateAdvisorDetails =
  (payload: Object, callback: Function) =>
  (dispatch: Function): void => {
    // reset loading and error state.
    dispatch({
      type: ADVISOR_DETAILS_UPDATE,
    })

    dispatch({
      type: EXPERIENCE_API,
      name: ADVISOR_DETAILS_UPDATE,
      verb: 'PUT',
      data: {
        member: {
          ...payload,
        },
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${getBancsCustomerNumber()}?adviser=YES`,
      callback: (err, dataReceived) => {
        callback(dataReceived, err)
        const { adviserDetails } = get(dataReceived, 'businessData', {})

        // pass subset of response, or during error pass whole payload down.
        return dataReceived.businessData
          ? {
              ...dataReceived.businessData.member,
              ...get(adviserDetails, '[0]', {}),
            }
          : dataReceived
      },
    })
  }

export const cpGetAdviserDetails = (payload: Object, callback: Function) => ({
  type: EXPERIENCE_API,
  name: CUSTOMER_FETCH_ADVISER,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${payload.adviserId}?adviser=YES`,
  callback: (err, dataReceived) => {
    const adviserDetails = get(dataReceived, 'businessData.adviserDetails[0]', {})
    callback(err, adviserDetails)
    return dataReceived
  },
})

export const cpRemoveAdvisor = (payload: Object, callback: Function) => ({
  type: EXPERIENCE_API,
  name: CUSTOMER_REMOVE_ADVISER,
  verb: 'POST',
  data: { ...payload },
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/general/serviceRequest`,
  callback: (err, dataReceived) => {
    if (callback) {
      callback(err, dataReceived)
    }
    return dataReceived
  },
})

export const restAdvisorData = () => ({ type: RESET_ADVISOR_DATA })

/** Fetch beta testing features enabled for the logged in advisor */
export const getBetaTestingFeaturesForAdviser =
  (payload: Object, callback: Function = () => {}) =>
  dispatch => {
    const bancsCustomerNo = getBancsCustomerNumber()
    dispatch({
      type: SITECORE_API,
      name: ADVISOR_BETA_TESTING_FEATURES_FETCH,
      verb: 'GET',
      route: `${SITECORE_API_BASE}/betaTestingFeatures?testerCode=${bancsCustomerNo}`,
      callback: (err, dataReceived) => {
        if (callback) {
          callback(err, dataReceived)
        }
        return dataReceived
      },
    })
  }
