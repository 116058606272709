// @flow
import get from 'lodash/get'

import { getRole } from './middlewareUtils'
import { POLICY_PARTY_TYPE_INDIVIDUAL } from '../constants/policies'

/**
 * Get Customer's First Name
 * @private
 *
 * @param {Object} customerDetails
 * @param {String} defaultProfileName
 *
 * @returns {String} name
 */
const getCustomerProfileName = (customerDetails: Object, defaultProfileName: String) => {
  const profileFirstName = get(customerDetails, 'details.firstName', defaultProfileName)

  return profileFirstName
}

/**
 * Get Advisor's First Name if partyType=PER
 * Get Advisor's Business Name if partyType=ORG
 * @private
 *
 * @param {Object} advisorDetails
 * @param {String} defaultProfileName
 *
 * @returns {String} name
 */
const getAdvisorProfileName = (advisorDetails: Object, defaultProfileName: String) => {
  let profileFirstName = ''
  const getPartyType = get(advisorDetails, 'details.partyType', '')
  if (getPartyType.toLowerCase() === POLICY_PARTY_TYPE_INDIVIDUAL.toLowerCase()) {
    profileFirstName = get(advisorDetails, 'details.firstName', defaultProfileName)
  } else {
    profileFirstName = defaultProfileName
  }

  return profileFirstName
}

/**
 * Get User Profile Name based on the portal
 * @public
 *
 * @param {Object} advisorDetails
 * @param {Object} customerDetails
 * @param {String} defaultProfileName
 *
 * @returns {String} profile name
 */
export const getProfileName = (
  advisorDetails: Object = {},
  customerDetails: Object = {},
  defaultProfileName: String = 'Account'
) =>
  getRole().toLowerCase() === 'customer'
    ? getCustomerProfileName(customerDetails, defaultProfileName)
    : getAdvisorProfileName(advisorDetails, defaultProfileName)
