// contants
import { REQUIREMENTS_UW, REQUIREMENTS_NB } from '../constants/application'

// Types
import { DocumentType, MasterList } from '../types/masterData'
import { RETENTION_CATEGORIES } from '../types/retention'
import { RetentionDashboardFields } from '../types/components/AdvisorDashboard'
import { OutstandingRequirements, TaskRecords } from '../types/tasks'
import { RetentionDashboardState } from '../types/retentionDashboard'

export const handleDocumentToDownloadFilter = (
  reqDocSubTypeCode: string,
  handleSetDocumentTypes: (link: string | undefined, name: string) => void,
  masterList: MasterList
) => {
  const documentType = masterList?.data?.documentTypes ?? []

  const filteredDocument = documentType.filter(
    (doc: DocumentType) => doc.documentCode === reqDocSubTypeCode
  )

  if (filteredDocument.length > 0) {
    const { link, documentTypeName } = filteredDocument[0]
    if (link !== '') {
      handleSetDocumentTypes(link, documentTypeName)
    }
  } else {
    handleSetDocumentTypes('', '')
  }
}

export const getNoOfTasks = (outstandingRequirements: OutstandingRequirements[]) => {
  if (outstandingRequirements.length) {
    const noOfTask = outstandingRequirements.filter(
      ({ category }) => category === REQUIREMENTS_UW || category === REQUIREMENTS_NB
    )
    return noOfTask.length
  }
  return false
}

export const isRequirementsAvailable = (task: TaskRecords) => {
  const isRequirements = (task?.outstandingRequirements ?? []).find(
    ({ category }: OutstandingRequirements) =>
      category === REQUIREMENTS_UW || category === REQUIREMENTS_NB
  )
  if (isRequirements) return true
  return false
}

export const retentionCategories = (
  { fields }: RetentionDashboardFields,
  dashboardDetail: RetentionDashboardState
) => [
  {
    label: fields.dashboardPolicyAnniversaryTabTitle,
    value: dashboardDetail?.anniversary?.totalNoOfPolicies ?? 0,
    id: RETENTION_CATEGORIES.POLICY_ANNIVERSARY,
    message: '',
  },
  {
    label: fields.dashboardRiskOfLapseTabTitle,
    value: dashboardDetail?.riskOfLapse?.totalNoOfPolicies ?? 0,
    id: RETENTION_CATEGORIES.MISSED_PAYMENTS,
    message: fields.missedPaymentWarningMessage,
  },
  {
    label: fields.recentlyLapseTabTitle,
    value: dashboardDetail?.lapsed?.totalNoOfPolicies ?? 0,
    id: RETENTION_CATEGORIES.LAPSED,
    message: fields.lapsedWarningMessage,
  },
  {
    label: fields.recentlyCancelledTabTitle,
    value: dashboardDetail?.cancelled?.totalNoOfPolicies ?? 0,
    id: RETENTION_CATEGORIES.CANCELLED,
    message: '',
  },
]
