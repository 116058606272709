// @flow
// Map some field identifiers to Sitecore field names.
const CHILD_COVER_FIELDS_MAPPING = {
  title: 'childCoverTitle',
  desc: 'childCoverDescription',
  firstName: 'childCoverFirstNameLabel',
  firstNameError: 'childCoverFirstNameError',
  validFirstNameError: 'ChildCoverValidFirstNameError',
  lastName: 'childCoverLastNameLabel',
  lastNameError: 'childCoverLastNameError',
  validLastNameError: 'ChildCoverValidLastNameError',
  dateOfBirth: 'childCoverDateOfBirthLabel',
  dateOfBirthError: 'childCoverDateOfBirthError',
  dateOfBirthFutureError: 'childCoverDateOfBirthFutureError',
  dateOfBirthRangeError: 'childCoverDateOfBirthRangeError',
  gender: 'childCoverGenderLabel',
  genderError: 'childCoverGenderError',
  isSmoker: 'childCoverSmokerStatusLabel',
  smokerStatusError: 'childCoverSmokerStatusError',
}

export type ChildCoverFields = {|
  title: string,
  desc: string,
  firstName: string,
  firstNameError: string,
  lastName: string,
  lastNameError: string,
  dateOfBirth: string,
  dateOfBirthError: string,
  dateOfBirthFutureError: string,
  dateOfBirthRangeError: string,
  gender: string,
  genderError: string,
  isSmoker: string,
  smokerStatusError: string,
  subtmitButton: string,
|}

// Generate a `ChildCoverFields` object containing all the Sitecore fields used
// in Child CI. It uses the values in `CHILD_COVER_FIELDS_MAPPING` to lookup
// the data in a Sitecore fields collection.
export const getChildCoverFields = (fields: { [string]: string }): ChildCoverFields =>
  Object.keys(CHILD_COVER_FIELDS_MAPPING).reduce(
    (childCoverFields, name) => ({
      ...childCoverFields,
      [name]: fields[CHILD_COVER_FIELDS_MAPPING[name]],
    }),
    {}
  )
