/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLinkedIn32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 24h-4.284v-6.86c0-1.72-.625-2.898-2.174-2.898-1.186 0-1.889.789-2.2 1.553-.104.284-.144.66-.144 1.048V24h-4.284c.051-11.623 0-12.826 0-12.826h4.284v1.863h-.026c.56-.879 1.575-2.16 3.893-2.16 2.826 0 4.935 1.837 4.935 5.772V24zM6.247 24h4.297V11.174H6.247V24zm2.15-14.573H8.37C6.925 9.427 6 8.444 6 7.214 6 5.958 6.964 5 8.421 5c1.472 0 2.384.958 2.397 2.214.013 1.23-.925 2.213-2.421 2.213z"
      fill="#000"
    />
  </Icon>
)

export default IconLinkedIn32
