import { space, fontSize, colours } from '../../../../../styles'

const styles = {
  base: {
    padding: space(3),
    position: 'relative',
  },
  soleDirectorCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  addDirectorBtn: {
    borderBottom: `1px solid ${colours.lightestGrey}`,
    display: 'block',
    width: '100%',
    textAlign: 'left',
    height: 'auto',
    padding: `${space(3, true)} ${space(1, true)} ${space(2, true)}`,
    span: {
      fontSize: fontSize.xs,
      paddingLeft: space(1),
    },
  },
  errorAddDirector: {
    color: colours.red,
    fontSize: fontSize.xxs,
  },
}
export default styles
