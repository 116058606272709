// @flow
import { keyframes } from '@emotion/core'
import { colours, fontSize, mediaQueries, space } from '../../../../../styles'

const animations = {
  modalOpen: keyframes({
    from: {
      opacity: 0.7,
      transform: 'translate(-50%, -50%) scale(0.8)',
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
  }),
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100%',
    height: '100%',
    padding: space(3),
    background: 'rgba(0,0,0,.3)',
    pointerEvent: 'none',
  },

  base: ({ enableScroll, smallMode, isDynamic = false }) => {
    const fixedSize = smallMode ? 300 : 656
    return {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(0.8)',
      zIndex: 10001,
      width: 'inherit',
      minWidth: fixedSize,
      maxWidth: isDynamic ? '100%' : fixedSize,
      maxHeight: '100%',
      minHeight: '100%',
      marginBottom: 0,
      padding: 0,
      animationName: `${animations.modalOpen}`,
      animationDuration: '0.5s',
      animationFillMode: 'forwards',
      animationTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
      opacity: 0.7,
      overflow: enableScroll ? 'auto' : 'initial',
      [mediaQueries.sm]: {
        maxHeight: '90%',
        minHeight: '90%',
      },
      [mediaQueries.md]: {
        maxHeight: '90%',
        minHeight: '40%',
      },
    }
  },

  closeButton: ({ smallMode }) => ({
    position: 'absolute',
    top: space(0.5),
    right: space(0.5),
    cursor: 'pointer',
    [mediaQueries.xs]: smallMode
      ? {}
      : {
          top: space(2),
          right: space(2),
        },
  }),

  backButton: ({ smallMode }) => {
    const responsiveBack = smallMode
      ? {}
      : {
          [mediaQueries.xs]: {
            top: space(2),
            left: space(5),
          },
          [mediaQueries.sm]: {
            top: space(2),
            left: space(7),
          },
          [mediaQueries.md]: {
            top: space(2),
            left: space(8),
          },
        }
    return {
      position: 'absolute',
      cursor: 'pointer',
      display: 'block',
      fontSize: fontSize.xs,
      height: 'auto',
      svg: {
        marginRight: space(0.5),
        color: 'inherit',
      },
      ...responsiveBack,
    }
  },

  icon: isCloseDisabled => ({
    color: isCloseDisabled ? colours.mediumGrey : colours.black,
  }),

  title: ({ smallMode }) => {
    const responsiveTitle = smallMode
      ? {}
      : {
          [mediaQueries.xs]: {
            padding: space(5),
            paddingBottom: 0,
            fontSize: fontSize.md,
            borderBottom: 0,
          },
          [mediaQueries.sm]: {
            padding: space(7),
            paddingBottom: 0,
            fontSize: fontSize.lg,
          },
          [mediaQueries.md]: {
            padding: space(9),
            paddingBottom: 0,
          },
        }
    return {
      padding: `${space(2, true)} ${space(4, true)} ${space(2, true)} ${space(2, true)}}`,
      fontSize: fontSize.sm,
      borderBottom: `1px solid ${colours.lightGrey}`,
      ...responsiveTitle,
    }
  },

  content: ({ smallMode, disablePadding }) => {
    const applyPadding = spacing => ({
      padding: space(disablePadding ? 0 : spacing),
      paddingTop: 0,
    })
    const responsiveContent = smallMode
      ? {}
      : {
          [mediaQueries.xs]: applyPadding(5),
          [mediaQueries.sm]: applyPadding(7),
          [mediaQueries.md]: applyPadding(9),
        }
    return {
      padding: disablePadding ? 0 : space(2),
      paddingTop: 0,
      ...responsiveContent,
    }
  },
}

export default styles
