import React, { useEffect } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

// components.
import { Heading, Input } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './quickQuoteSumInsured.styles'

// hooks
import usePrevious from '../../../../../hooks/usePrevious'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

// selectors
import { getPolicyStructure } from '../../../../../selectors/common.selectors'

// schema.
import SCHEMA, { FORM_ID } from './quickQuoteSumInsured.schema'

// helpers.
// @ts-expect-error file not in typescript
import { errorCheck } from '../../../../../utils/formUtils'
// @ts-expect-error file not in typescript
import { convertStringToKey } from '../../../../../utils/commonUtils'
// @ts-expect-error file not in typescript
import { isNonSuperProduct } from '../../../../../utils/quoteUtils'

// constants.
import { POLICY_NON_SUPER_BENEFIT_PREFIX_NEW } from '../../../../../constants/policies'

// types
import { Store } from '../../../../../types/store'
import { fieldsType } from '../../../../../types/components/Sidebar'
import { Cover } from '../../../../../types/createQuote'

const Wrapper = styled('div')(styles.wrapper)
const Section = styled('section')(styles.section)
const HeaderWrapper = styled('div')(styles.headerWrapper)

type quickQuoteSumInsuredProps = { fields: fieldsType; renderFoot: () => React.ReactElement }

const quickQuoteSumInsured = ({ fields, renderFoot }: quickQuoteSumInsuredProps) => {
  const dispatch = useDispatch()
  const form = useSelector((state: Store) => state.forms.quickQuoteSumInsured)
  const formFields = useSelector((state: Store) => state.forms.quickQuoteSumInsured?.fields)
  const createQuotePolicyStructure = useSelector(getPolicyStructure)
  const covers = createQuotePolicyStructure.reduce<Cover[]>(
    (acc, cur) => [
      ...acc,
      ...cur.covers.map(cover => {
        const convertedName = convertStringToKey(cover.name)
        const isNonSuper = isNonSuperProduct(cur.productId)
        return {
          ...cover,
          name: isNonSuper
            ? `${POLICY_NON_SUPER_BENEFIT_PREFIX_NEW}${convertedName}`
            : convertedName,
        }
      }),
    ],
    []
  )

  const coverNameMapping: { [key: string]: string } = {
    life_cover: 'Life Cover',
    total_and_permanent_disability: 'Total and Permanent Disability',
    non_life_cover: 'Life Cover',
    non_total_and_permanent_disability: 'Total and Permanent Disability',
    non_critical_illness_plus: 'Critical Illness',
  }

  const coversNameArr = covers
    .map(cover => cover.name)
    .filter(cName => Object.keys(coverNameMapping).includes(cName))
  const prevCoversNameArr = usePrevious<string[]>(coversNameArr)
  const prevFormFields = usePrevious<{ [key: string]: { error: object; value: string } }>(
    formFields
  )
  const isSelectedCoverEqual = coversNameArr.toString() === prevCoversNameArr?.toString()
  const hasLCFieldChanged =
    prevFormFields &&
    (prevFormFields?.life_cover?.value !== formFields?.life_cover?.value ||
      prevFormFields?.non_life_cover?.value !== formFields?.non_life_cover?.value)

  useEffect(() => {
    if (!form || !isSelectedCoverEqual) {
      dispatch(actionCreators.formInit(FORM_ID, SCHEMA(coversNameArr, fields)))
    }
  }, [isSelectedCoverEqual])

  useEffect(() => {
    const { formUpdateField } = actionCreators
    if (form && formFields && hasLCFieldChanged)
      dispatch(
        formUpdateField(FORM_ID, 'total_and_permanent_disability', {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          error: errorCheck(
            form.fields?.total_and_permanent_disability?.value,
            SCHEMA(coversNameArr, fields)?.total_and_permanent_disability?.condition?.[1],
            SCHEMA(coversNameArr, fields)?.total_and_permanent_disability?.errorMsg,
            formFields
          ),
        })
      )
  }, [hasLCFieldChanged])

  const { quickQuoteSumInsuredHeading, quickQuoteSumInsuredLabel } = fields

  // handle change on cover suminsured input.
  const handleChange = ({ value, name }: { value: string; name: string }): void => {
    const { formUpdateField, formValidate } = actionCreators
    const data = {
      error: errorCheck(
        value,
        SCHEMA(coversNameArr, fields)[name].condition,
        SCHEMA(coversNameArr, fields)[name].errorMsg,
        form.fields
      ) as
        | {
            error: boolean
            errorMsg: string
          }
        | boolean,
      value,
    }
    dispatch(formUpdateField(FORM_ID, name, data))
    dispatch(formValidate(FORM_ID, SCHEMA(coversNameArr, fields)))
  }

  const prepareCoversForRender = (curCovers: Cover[]) => {
    // sort the covers list to place life cover always 1st
    if (curCovers.some(i => i.name.includes('life_cover'))) {
      curCovers.unshift(
        curCovers.splice(
          curCovers.findIndex(c => c.name.includes('life_cover')),
          1
        )[0]
      )
    }

    return curCovers.filter(c => Object.keys(coverNameMapping).includes(c.name))
  }
  const coversToRender = prepareCoversForRender(covers)

  const renderCoversInput = () =>
    coversToRender.map(cover => (
      <Input
        key={cover.name}
        htmlFor={cover.name}
        label={coverNameMapping[cover.name]}
        name={cover.name}
        changeHandler={handleChange}
        error={form?.fields[cover.name]?.error?.error}
        caption={
          form?.fields[cover.name]?.error?.error ? form?.fields[cover.name]?.error?.errorMsg : ''
        }
        value={form?.fields[cover.name]?.value}
        prefix="$"
        options={{
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
          disableDecimal: true,
        }}
      />
    ))

  return (
    <Wrapper>
      <Section>
        <p>
          <Text field={quickQuoteSumInsuredHeading} />
        </p>
      </Section>
      <HeaderWrapper>
        <Heading size="small" variant="h5">
          <Text field={quickQuoteSumInsuredLabel} />
        </Heading>
      </HeaderWrapper>
      {renderCoversInput()}
      {renderFoot && renderFoot()}
    </Wrapper>
  )
}

export default quickQuoteSumInsured
