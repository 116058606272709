// @flow
import React from 'react'
import styled from '@emotion/styled'
import { createEvent } from '../../../../../utils/telemetry'

// components.
import { IconDocument16 } from '../../../../atoms/Icons'
import TagComponent from '../../../../atoms/Tag'

// styles.
import styles from './fileDownload.styles'

import { renderTextField } from '../../../../../utils/sitecoreUtils'

const Container = styled('div')(styles.container)
export const IconDocument = styled(IconDocument16)(styles.documentIcon)
const DownloadFile = styled('span')(styles.downloadFileButton)
const DocumentNameWrapper = styled('span')(styles.documentNameWrapper)
const Tag = styled(TagComponent)(styles.tag)

type FileDownloadProps = {
  formDownloadMeta: {
    // file name to download
    documentName: string,
    // sitecore file path to download
    documentPath: {
      value: string,
    },
  },
  // flag to show download icon or not
  showDownload: boolean,
  // tag represents document type
  documentTypeTag: string,
  // handle the change of file type
  handleChangeFileType: Function | null,
  analytics: Object,
}
// eslint-disable jsx-a11y/no-static-element-interactions
// eslint-disable jsx-a11y/click-events-have-key-events
const FileDownload = ({
  formDownloadMeta: { documentName, documentPath },
  showDownload,
  documentTypeTag,
  handleChangeFileType,
  analytics,
}: FileDownloadProps) => {
  const downloadFile = () => {
    if (analytics) {
      const event = createEvent({
        GA: analytics,
        Splunk: {
          attributes: {
            'workflow.name': analytics.category,
          },
        },
      })
      event.end()
    }
  }
  return (
    <Container>
      <DocumentNameWrapper onClick={handleChangeFileType}>
        <IconDocument />
        {documentName}
        {documentTypeTag && <Tag>{documentTypeTag}</Tag>}
      </DocumentNameWrapper>
      {showDownload && (
        <DownloadFile onClick={downloadFile}>{renderTextField(documentPath, true)}</DownloadFile>
      )}
    </Container>
  )
}

export default FileDownload
