import { setColumns, fullColumn, mediaQueries } from '../../../styles'

const styles = {
  wrapper: (count, variant, gutter) => {
    if (variant === 'mobileFullWidth') {
      return {
        ...fullColumn,
        justifyContent: variant === 'tab' ? 'normal' : 'space-between',

        [mediaQueries.md]: {
          ...setColumns(count, variant === 'tab' ? 'none' : gutter),
        },
      }
    }

    return {
      ...setColumns(count, variant === 'tab' ? 'none' : gutter),
      justifyContent: variant === 'tab' ? 'normal' : 'space-between',
    }
  },
}

export default styles
