// @flow
import React, { Component, type Node } from 'react'
import ReactDOM from 'react-dom'

// components.
import ModalCard from './components/ModalCard'

// utils.
import { canUseDOM } from '../../../utils/domUtils'

// id of <div> to act as Portal target
const MODAL_ROOT_ID = 'modal-root'

type ModalProps = {
  /** Contents of modal */
  children: Node,

  /** When true, do not apply padding to content area */
  disablePadding?: boolean,

  /** Indicates modal visibility */
  isOpen?: boolean,

  /** A function to be called when modal is closed */
  onClose: Function,

  /** A function to be called when modal is opened */
  onAfterOpen?: ?Function,

  /** Indicates whether clicking the overlay should close the modal */
  shouldOverlayClose?: boolean,

  /** Enable close button */
  showCloseButton?: boolean,

  /** Enable alternative small modal mode */
  smallMode?: boolean,

  /** Title to display */
  title: string | { value: string },

  /** Initially focus on close button  */
  shouldFocusCloseButton: boolean,

  /** Enable scroll */
  enableScroll?: boolean,

  /** enable/disable close button */
  isCloseDisabled?: boolean,

  /** Show/Hide back button */
  showBackButton: boolean,

  /** Back button click handler */
  handleBackButton: Function,

  /** Sets width to 100% if dynamic */
  isDynamic: boolean,
}

class Modal extends Component<ModalProps> {
  static defaultProps = {
    disablePadding: false,
    isOpen: false,
    onAfterOpen: null,
    shouldOverlayClose: false,
    showCloseButton: true,
    smallMode: false,
    enableScroll: true,
    isCloseDisabled: false,
  }

  // Create Portal target node and append to document.
  // Handy for test environments.
  static createModalRoot(): Element | null {
    if (!canUseDOM()) {
      return null
    }
    const root: Element = document.createElement('div')
    root.setAttribute('id', MODAL_ROOT_ID)
    if (document.body) {
      document.body.appendChild(root)
    }
    return root
  }

  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) {
      this.open()
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { isOpen } = this.props
    const justOpened = isOpen && !prevProps.isOpen
    if (justOpened) {
      this.open()
    }
  }

  // Thread on discussion of using property initialisers for class methods:
  // https://github.com/facebook/flow/issues/5874
  // They come pre-bound and skirt around the Flow assignment error when binding
  // methods in constructor. Also avoids a test env issue where `bind` is undefined.
  open = (): void => {
    const { onAfterOpen } = this.props
    if (onAfterOpen) {
      onAfterOpen()
    }
  }

  // Passes through props and returns presentational modal component
  createModal = (props: ModalProps): Node => {
    const {
      children,
      disablePadding,
      onClose,
      showCloseButton,
      shouldOverlayClose,
      smallMode,
      title,
      shouldFocusCloseButton,
      enableScroll,
      isCloseDisabled,
      showBackButton,
      handleBackButton,
      isDynamic,
    } = props
    return (
      <ModalCard
        disablePadding={disablePadding}
        onClose={onClose}
        showCloseButton={showCloseButton}
        shouldOverlayClose={shouldOverlayClose}
        smallMode={smallMode}
        title={title}
        shouldFocusCloseButton={shouldFocusCloseButton}
        enableScroll={enableScroll}
        isCloseDisabled={isCloseDisabled}
        showBackButton={showBackButton}
        handleBackButton={handleBackButton}
        isDynamic={isDynamic}
      >
        {children}
      </ModalCard>
    )
  }

  render() {
    const { isOpen } = this.props

    // Don't render when DOM unavailable
    if (!canUseDOM()) {
      return null
    }

    // Query and assign Portal target node, create one if necessary
    const modalRoot: Element | null = document.getElementById(MODAL_ROOT_ID)
      ? document.getElementById(MODAL_ROOT_ID)
      : Modal.createModalRoot()

    return isOpen && modalRoot !== null
      ? ReactDOM.createPortal(this.createModal(this.props), modalRoot)
      : null
  }
}

export default Modal
