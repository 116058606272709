// @flow
import { fontFamily, fontSize, fontWeights, space, colours, mediaQueries } from '.'

const headings = {
  h1: {
    fontFamily: fontFamily.charlie,
    margin: 0,
    fontSize: fontSize.lg,
    lineHeight: 1.11,
    fontWeight: fontWeights.normal,
    color: colours.darkestGrey,
    marginBottom: space(2),

    [mediaQueries.md]: {
      fontSize: fontSize.xl,
    },
  },
  h2: {
    fontFamily: fontFamily.charlie,
    fontSize: fontSize.md,
    lineHeight: 1.16,
    margin: 0,
    fontWeight: fontWeights.normal,
    color: colours.darkestGrey,
    marginBottom: space(2),

    [mediaQueries.md]: {
      fontSize: fontSize.lg,
    },
  },
  h3: {
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.md,
    lineHeight: 1.11,
    margin: 0,
    color: colours.darkestGrey,
    marginBottom: space(2),

    [mediaQueries.md]: {
      fontSize: fontSize.lg,
    },
  },
  h4: {
    fontFamily: fontFamily.charlie,
    fontSize: fontSize.sm,
    lineHeight: 1.25,
    margin: 0,
    fontWeight: fontWeights.normal,
    color: colours.darkestGrey,
    marginBottom: space(2),

    [mediaQueries.md]: {
      fontSize: fontSize.md,
    },
  },
  h5: {
    fontFamily: fontFamily.sourceSans,
    fontSize: fontSize.sm,
    lineHeight: 1.25,
    margin: 0,
    color: colours.darkestGrey,
    marginBottom: space(2),

    [mediaQueries.md]: {
      fontSize: fontSize.md,
      lineHeight: 1.33,
    },
  },
  h6: {
    fontFamily: fontFamily.sourceSans,
    fontSize: fontSize.sm,
    lineHeight: 1.2,
    margin: 0,
    color: colours.darkestGrey,
    marginBottom: space(2),
  },
  h7: {
    fontFamily: fontFamily.charlie,
    lineHeight: 1.12,
    margin: 0,
    fontWeight: fontWeights.normal,
    color: colours.darkestGrey,
    marginBottom: space(2),
  },
  small: {
    fontFamily: fontFamily.sourceSans,
    fontSize: fontSize.xxs,
    lineHeight: 1.33,
  },
}

export default headings
