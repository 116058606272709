// @flow
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { actionCreators } from '../../../actions'

// utils
import { isBrowser } from '../../../utils/browserUtils'

type ReCaptchaProps = {
  config: Object,
  children: Function,
}

const GOOGLE_RECAPTCHA_SDK_QUERY_PARAM = '?onload=captchaOnLoad&render=explicit'
export class ReCaptcha extends React.Component<ReCaptchaProps> {
  state = {
    isReady: false,
  }

  componentDidMount() {
    this.loadCaptcha()
  }

  componentWillUnmount() {
    this.unloadCaptcha()
  }

  loadCaptcha = () => {
    const { config } = this.props
    window.captchaOnLoad = () => {
      this.renderCaptcha()
      !!isBrowser() &&
        window.grecaptcha.ready(() => {
          this.setState({ isReady: true })
        })
    }

    const script = document.createElement('script')
    script.src = config.GOOGLE_RECAPTCHA_SDK_URL + GOOGLE_RECAPTCHA_SDK_QUERY_PARAM
    script.async = true
    script.defer = true
    this.script = document.body.appendChild(script)
  }

  renderCaptcha = () => {
    const { config } = this.props
    const div = document.createElement('div')
    div.id = 'g-recaptcha'
    this.div = document.body.appendChild(div)

    !!isBrowser() &&
      window.grecaptcha.render('g-recaptcha', {
        sitekey: config.MLCL_GOOGLE_RECAPTCHA_SITE_KEY,
        size: 'invisible',
      })
  }

  executeCaptcha = () => {
    const { isReady } = this.state
    if (!isReady) {
      throw new Error('Captcha must be ready before it can be executed.')
    }
    const execute = window && window.grecaptcha && window.grecaptcha.execute
    return execute && execute()
  }

  unloadCaptcha = () => {
    document.body.removeChild(this.div)
    document.body.removeChild(this.script)
  }

  render() {
    const { children } = this.props
    const { isReady } = this.state
    return children({
      isReady,
      execute: this.executeCaptcha,
    })
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ config }: Object) => ({
  config,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReCaptcha)
