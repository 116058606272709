// @flow
import get from 'lodash/get'
import { STATES } from '../../../../../constants/forms'
import {
  email,
  optional,
  required,
  minLength,
  phone,
  phoneInputs,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../../utils/formUtils'

export const FORM_ID: string = 'policyOwnerTypeBusiness'

const schema =
  (): Function =>
  (state?: Object): Object => ({
    companyName: {
      condition: required,
    },
    abn: {
      condition: required,
    },
    abnConfirmation: {
      condition: required,
    },
    email: {
      condition: email,
    },
    residentialAddress: {
      condition: state.isManualResidential ? optional : required,
    },
    residentialAddressHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state.fields,
            'policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value'
          ),
        },
      ],
    },
    residentialAddressStreet: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(
                state.fields,
                'policyOwnerDetailsResidentialAddressStreetFieldError.value'
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state.fields,
            'policyOwnerDetailsResidentialAddressValidStreetFieldError.value'
          ),
        },
      ],
    },
    residentialAddressLocality: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(
                state.fields,
                'policyOwnerDetailsResidentialAddressLocalityFieldError.value'
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state.fields,
            'policyOwnerDetailsResidentialAddressValidLocalityFieldError.value'
          ),
        },
      ],
    },
    residentialAddressState: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(
                state.fields,
                'policyOwnerDetailsResidentialAddressStateFieldError.value'
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state.fields,
            'policyOwnerDetailsResidentialAddressValidStateFieldError.value'
          ),
        },
      ],
      options: STATES,
    },
    residentialAddressCountry: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(
                state.fields,
                'policyOwnerDetailsResidentialAddressCountryFieldError.value'
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state.fields,
            'policyOwnerDetailsResidentialAddressCountryFieldInvalidError.value'
          ),
        },
      ],
    },
    residentialAddressPostCode: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: get(
                state.fields,
                'policyOwnerDetailsResidentialAddressPostCodeFieldError.value'
              ),
            }
          : optional,
        {
          condition: manualAddressPostalCode,
          errorMsg: get(
            state.fields,
            'policyOwnerDetailsResidentialAddressValidPostalFieldError.value'
          ),
        },
      ],
    },
    contactNumber: {
      condition: [{ condition: required }, { condition: phone }],
      onChangeCondition: phoneInputs,
    },
    contactNumberCode: {
      condition: required,
    },
    isSoleDirector: {
      condition: optional,
    },
    directors: {
      condition: [
        required,
        state.isSoleDirector && state.isSoleDirector.value ? minLength(1) : minLength(2),
      ],
    },
  })

export default schema
