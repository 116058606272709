// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import {
  A11yLabel,
  SidebarActionItem as SidebarActionItemComponent,
  Select,
  Input,
  Checkbox,
} from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// styles.
import styles from './nulis.styles'
import { ignoreColumns } from '../../../utils/stylesUtils'

// constants
import {
  EMPLOYER_SG,
  AWARD,
  SALARY_SACRIFICE,
  EMPLOYER_VOLUNTARY,
  CONTRIBUTIONTYPEVAL,
  ABN,
} from '../../../constants/nulis'
import {
  POLICY_RELATIONSHIPS_EMPLOYER,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  COUNTRY_CODE,
} from '../../../constants/policies'

// components.
import AbnLookup from '../../molecules/AbnLookup'
import AddressLookUp from '../../molecules/AddressLookUp'
import Button from '../../atoms/Button'
import Caption from '../../atoms/Caption'

// icons
import { IconClose16 } from '../../atoms/Icons'

// helpers
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { getPreferredAddress, getFullAddress } from '../../../utils/contactUtils'

import { errorCheck, getValue, generateFieldsFromData } from '../../../utils/formUtils'

// schema.
import SCHEMA, { FORM_ID } from './AddNulis.schema'

const FullWidthAbnLookup = ignoreColumns(AbnLookup)
const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const FullWidthInput = ignoreColumns(Input)
const FullWidthCheckbox = ignoreColumns(Checkbox)
const Form = styled('form')(styles.base)
const SidebarActionItem = styled(SidebarActionItemComponent)(styles.sidebar)

const Wrapper = styled('div')(styles.wrapper)
const Wrap = styled('div')(styles.wrapText)
const MemberListCaption = styled('p')(styles.memberListCaption)
const MemberListItem = styled('li')(styles.memberListItem)
const RemoveMember = styled(Button)(styles.removeMember)
const SoleDirectorCheckbox = styled(FullWidthCheckbox)(styles.soleDirectorCheckbox)
const ErrorAddDirector = styled('div')(styles.errorAddDirector)

type AddNulisProps = {
  handleNewClient: Function,
  // To render all labels from sitecore content editor
  fields: Object,
  // list of actions
  actions: Object<Function>,
  // form sub-store
  form: Object,
  isManualResidential: Boolean,
  abnLookup: Object,
  nulis: Object,
  policyInstanceNo: Number,
  createQuote: Object,
}

export const RESIDENTIAL_ADDRESS = [
  'residentialAddress',
  'residentialAddressStreet',
  'residentialAddressHouseNo',
  'residentialAddressLocality',
  'residentialAddressState',
  'residentialAddressPostCode',
]

export class AddNulis extends Component<AddNulisProps> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)
    this.state = {
      showExtraFields: false,
    }
  }

  componentWillMount() {
    const { actions } = this.props
    const { showExtraField } = actions
    showExtraField(false)
    this.initNulisForm()
  }

  componentDidUpdate(prevProps) {
    const {
      isManualResidential,
      form,
      fields,
      actions: { formUpdateField, formValidate },
      abnLookup: { identifierValue: abnNumber, companyName },
      nulis: { showNulisExtraFields },
    } = this.props
    const {
      abnLookup: { identifierValue: prevAbnNumber },
    } = prevProps

    if (!form) return

    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        showNulisExtraFields,
      },
      fields
    )

    if (abnNumber !== prevAbnNumber) {
      companyName &&
        formUpdateField(FORM_ID, 'employerName', {
          error: { error: false },
          value: companyName,
        })
      formUpdateField(FORM_ID, 'abnConfirmation', {
        error: { error: !abnNumber },
        value: abnNumber,
      })
      formValidate(FORM_ID, schema)
    }
  }

  initNulisForm = () => {
    const {
      actions,
      form,
      fields,
      isManualResidential,
      nulis: { showNulisExtraFields },
      policyInstanceNo,
      createQuote,
    } = this.props
    const { formInit, hideSidebarFooter, showExtraField } = actions
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''

    if (activePolicy) {
      const employerIndex = activePolicy.relationships.findIndex(member =>
        member.role.find(role => role.includes(POLICY_RELATIONSHIPS_EMPLOYER))
      )

      const lifeInsuredIndex = activePolicy.relationships.findIndex(member =>
        member.role.find(role => role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
      )

      const companyNameVal =
        employerIndex !== -1
          ? get(activePolicy.relationships[employerIndex].relatedParty, 'businessName', '')
          : ''
      const abnVal =
        employerIndex !== -1
          ? get(activePolicy.relationships[employerIndex].relatedParty, 'abnNumber', '')
          : ''

      const tfnVal = get(activePolicy.relationships[lifeInsuredIndex], 'tfn', '')
      const contributionTypeVal = get(activePolicy, 'contributionType', '')

      const addressEntity =
        employerIndex !== -1
          ? get(
              activePolicy.relationships[employerIndex],
              'relatedParty.contactMethods.addresses',
              []
            )
          : []
      const address = getPreferredAddress(addressEntity)
      const houseNo = get(address, 'houseNo', '')
      const street = get(address, 'street', '')
      const locality = get(address, 'locality', '')
      const state = get(address, 'state', '')
      const country = get(address, 'country', COUNTRY_CODE)
      const postCode = get(address, 'postCode', '')

      if (
        contributionTypeVal !== '' &&
        !form &&
        (contributionTypeVal === EMPLOYER_SG ||
          contributionTypeVal === AWARD ||
          contributionTypeVal === SALARY_SACRIFICE ||
          contributionTypeVal === EMPLOYER_VOLUNTARY)
      ) {
        this.setState({
          showExtraFields: true,
        })
        showExtraField(true)
      } else if (
        form &&
        (form.fields.contributionType.value.value === EMPLOYER_SG ||
          form.fields.contributionType.value.value === SALARY_SACRIFICE ||
          form.fields.contributionType.value.value === AWARD ||
          form.fields.contributionType.value.value === EMPLOYER_VOLUNTARY ||
          form.fields.contributionType.value === EMPLOYER_SG ||
          form.fields.contributionType.value === SALARY_SACRIFICE ||
          form.fields.contributionType.value === AWARD ||
          form.fields.contributionType.value === EMPLOYER_VOLUNTARY)
      ) {
        this.setState({
          showExtraFields: true,
        })
        showExtraField(true)
      }
      const tfnDeclaration = get(activePolicy, 'consents[0].value', '')
      const superDeclaration = get(activePolicy, 'consents[1].value', '')
      const data = {
        abn: abnVal,
        tfn: tfnVal,
        abnConfirmation: abnVal,
        contributionType: contributionTypeVal,
        nulisDeclarationMLCSuperFund: superDeclaration,
        nulisDeclarationTFN: tfnDeclaration,
        employerName: companyNameVal,
        // Address fields
        residentialAddress: getFullAddress(addressEntity),
        residentialAddressHouseNo: houseNo,
        residentialAddressStreet: street,
        residentialAddressLocality: locality,
        residentialAddressState: state,
        residentialAddressCountry: country,
        residentialAddressPostCode: postCode,
      }

      const schema = this.schemaWithAuthorableFields(
        {
          isManualResidential,
          showNulisExtraFields,
        },
        fields
      )
      hideSidebarFooter()

      if (!form) formInit(FORM_ID, schema, data)
    }
  }

  handleChange = ({ value, name }) => {
    const {
      actions,

      isManualResidential,
      nulis: { showNulisExtraFields },
      fields,
      abnLookup: { isLoading, error },
    } = this.props
    const { formUpdateField, formValidate, resetAbnLookup, showExtraField } = actions

    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        showNulisExtraFields,
      },
      fields
    )
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    if (name === CONTRIBUTIONTYPEVAL) {
      if (
        value.value === EMPLOYER_SG ||
        value.value === AWARD ||
        value.value === SALARY_SACRIFICE ||
        value.value === EMPLOYER_VOLUNTARY
      ) {
        this.setState({
          showExtraFields: true,
        })
        showExtraField(true)
      } else {
        this.setState({
          showExtraFields: false,
        })
        showExtraField(false)
      }
    }

    if (name === ABN) {
      formUpdateField(FORM_ID, 'employerName', { error: { error: true }, value: '' })
      formUpdateField(FORM_ID, 'abnConfirmation', { error: { error: true }, value: '' })
      if (isLoading || error) resetAbnLookup()
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressToggleHandler =
    () =>
    (isManual: boolean): void => {
      const {
        actions: { formResetField },
      } = this.props
      // We clear address fields when entering manual mode
      if (isManual) {
        formResetField(FORM_ID, RESIDENTIAL_ADDRESS)
      }
    }

  removeTaxFileNo = () => {
    const {
      fields,
      actions: { formUpdateField },
    } = this.props
    const { nulisTFNRequiredError } = reduceAuthorableFields(fields)
    formUpdateField(FORM_ID, 'tfn', {
      error: { error: true, errorMsg: nulisTFNRequiredError },
      value: '',
    })
  }

  addTFN = () => {
    const { handleNewClient } = this.props
    handleNewClient()
  }

  addressChange = address => {
    const { name, data, value } = address
    const {
      actions,
      isManualResidential,
      nulis: { showNulisExtraFields },
      fields,
    } = this.props

    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        showNulisExtraFields,
      },
      fields
    )
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  render() {
    const {
      fields,
      form,
      nulis: { showNulisExtraFields },
      isManualResidential,
      abnLookup: { error, isLoading, isAbnCancelled },
    } = this.props

    const { showExtraFields } = this.state
    if (!form) return null
    const tfnVal = get(form.fields, 'tfn.value', '')
    const {
      abn,
      contributionType,
      employerName,
      nulisDeclarationTFN,
      nulisDeclarationMLCSuperFund,
      // Address fields
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      tfn,
    } = form.fields

    const {
      policyOwnerDetailsResidentialAddressToggleToManualText,
      policyOwnerDetailsResidentialAddressFieldLabel,
      policyOwnerDetailsResidentialAddressFieldPlaceholder,
      policyOwnerDetailsResidentialAddressFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldLabel,
      policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
      policyOwnerDetailsResidentialAddressHouseNoFieldLabel,
      policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
      policyOwnerDetailsResidentialAddressLocalityFieldLabel,
      policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
      policyOwnerDetailsResidentialAddressStateFieldLabel,
      policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
      policyOwnerDetailsResidentialAddressCountryFieldLabel,
      policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
      policyOwnerDetailsResidentialAddressPostCodeFieldLabel,
      policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
      policyOwnerDetailsResidentialAddressToggleToAutoText,
      beneficiaryDetailRealationshipPlaceholder,
      policyOwnerValidatedBusinessCancelledAbnError,
      policyOwnerValidatingBusinessAbnNumberWarning,
      policyOwnerValidatedBusinessAbnNumberError,
      nulisContributionTypeLabel,
      nulisEmployerNameLabel,
      nulisEmployerNamePlaceHolder,
      nulisEABNLabel,
      nulisEABNPlaceHolder,
      nulisDeclarationMLCSuperFundDescription,
      nulisDeclarationTFNDescription,
      nulisTitelValue,
      nulisAddTFN,
      nulisTaxFileNumber,
      nulisTFNRequiredError,
      nulisSuppliedValue,
      nulisDeclarationMandatoryMsg,
    } = reduceAuthorableFields(fields)

    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        showNulisExtraFields,
      },
      fields
    )
    const handleAbnCaption = () => {
      if (isAbnCancelled) {
        return policyOwnerValidatedBusinessCancelledAbnError
      }
      if (abn.error.error) {
        return abn.error.errorMsg
      }
      if (isLoading) {
        return policyOwnerValidatingBusinessAbnNumberWarning
      }
      if (error) {
        return policyOwnerValidatedBusinessAbnNumberError
      }
      return null
    }

    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{nulisTitelValue}</A11yLabel>
          <Form id={FORM_ID} aria-labelledby="nulis-label">
            <Select
              label={nulisContributionTypeLabel}
              placeholder={beneficiaryDetailRealationshipPlaceholder}
              value={contributionType.value}
              name="contributionType"
              id="contributionType"
              error={contributionType.error.error}
              caption={contributionType.error.error && contributionType.error.errorMsg}
              changeHandler={this.handleChange}
              options={schema.contributionType.options}
              size="lg"
            />
            {tfnVal === '' && (
              <Fragment>
                <SidebarActionItem type="button" title={nulisAddTFN} clickHandler={this.addTFN} />

                {tfn.error && tfn.error.error && (
                  <ErrorAddDirector>
                    <Caption withIcon error>
                      {nulisTFNRequiredError}
                    </Caption>
                  </ErrorAddDirector>
                )}
              </Fragment>
            )}
            {tfnVal !== '' && (
              <Fragment>
                <MemberListCaption>{nulisTaxFileNumber}</MemberListCaption>
                <MemberListItem>
                  {nulisSuppliedValue}
                  <RemoveMember>
                    <IconClose16
                      onClick={this.removeTaxFileNo}
                      ariaLabel="remove TFN"
                      data-testid="removeTFN"
                    />
                  </RemoveMember>
                </MemberListItem>
              </Fragment>
            )}

            <Wrap>
              {showExtraFields && (
                <Fragment>
                  <FullWidthInput
                    htmlFor="employerName"
                    name="employerName"
                    label={nulisEmployerNameLabel}
                    changeHandler={this.handleChange}
                    placeholder={nulisEmployerNamePlaceHolder}
                    error={employerName.error.error}
                    caption={employerName.error.error && employerName.error.errorMsg}
                    value={employerName.value}
                  />
                  <FullWidthAbnLookup
                    htmlFor="abn"
                    name="abn"
                    label={nulisEABNLabel}
                    changeHandler={this.handleChange}
                    placeholder={nulisEABNPlaceHolder}
                    error={isAbnCancelled || abn.error.error || isLoading || error}
                    caption={handleAbnCaption()}
                    value={abn.value}
                  />
                  <FullWidthAddressLookup
                    toggleHandler={this.addressToggleHandler('isManualResidential')}
                    name="policyOwnerResidentialAddress"
                    auto={{
                      label: policyOwnerDetailsResidentialAddressFieldLabel,
                      placeholder: policyOwnerDetailsResidentialAddressFieldPlaceholder,
                      toggleLabel: policyOwnerDetailsResidentialAddressToggleToManualText,
                      value: residentialAddress.value,
                      selectChangeHandler: this.addressChange,
                      addressError: residentialAddress.error.error,
                      addressName: 'residentialAddress',
                      addressErrorMessage:
                        residentialAddress.error.error &&
                        policyOwnerDetailsResidentialAddressFieldError,
                    }}
                    manual={{
                      streetLabel: policyOwnerDetailsResidentialAddressStreetFieldLabel,
                      streetPlaceholder: policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
                      streetValue: residentialAddressStreet.value,
                      streetRequiredErrorMessage: residentialAddressStreet.error.errorMsg,
                      streetError: residentialAddressStreet.error.error,
                      streetName: 'residentialAddressStreet',
                      houseNoLabel: policyOwnerDetailsResidentialAddressHouseNoFieldLabel,
                      // eslint-disable-next-line max-len
                      houseNoPlaceholder:
                        policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
                      houseNoValue: residentialAddressHouseNo.value,
                      houseNoName: 'residentialAddressHouseNo',
                      houseNoError: residentialAddressHouseNo.error.error,
                      houseNoRequiredErrorMessage: residentialAddressHouseNo.error.errorMsg,
                      localityLabel: policyOwnerDetailsResidentialAddressLocalityFieldLabel,
                      // eslint-disable-next-line max-len
                      localityPlaceholder:
                        policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
                      localityLabelValue: residentialAddressLocality.value,
                      localityError: residentialAddressLocality.error.error,
                      localityRequiredErrorMessage: residentialAddressLocality.error.errorMsg,
                      localityName: 'residentialAddressLocality',
                      stateLabel: policyOwnerDetailsResidentialAddressStateFieldLabel,
                      statePlaceholder: policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
                      stateOptions: schema.residentialAddressState.options,
                      stateValue: residentialAddressState.value,
                      stateError: residentialAddressState.error.error,
                      stateRequiredErrorMessage: residentialAddressState.error.errorMsg,
                      stateName: 'residentialAddressState',
                      countryLabel: policyOwnerDetailsResidentialAddressCountryFieldLabel,
                      // eslint-disable-next-line max-len
                      countryPlaceholder:
                        policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
                      countryValue: residentialAddressCountry.value,
                      countryError: residentialAddressCountry.error.error,
                      countryRequiredErrorMessage: residentialAddressCountry.error.errorMsg,
                      countryName: 'residentialAddressCountry',
                      postCodeLabel: policyOwnerDetailsResidentialAddressPostCodeFieldLabel,
                      // eslint-disable-next-line max-len
                      postCodePlaceholder:
                        policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
                      postCodeValue: residentialAddressPostCode.value,
                      postCodeError: residentialAddressPostCode.error.error,
                      postCodeRequiredErrorMessage: residentialAddressPostCode.error.errorMsg,
                      postCodeName: 'residentialAddressPostCode',
                      manualToggleLabel: policyOwnerDetailsResidentialAddressToggleToAutoText,
                      inputEntryHandler: this.handleChange,
                    }}
                  />
                </Fragment>
              )}
            </Wrap>
            <SoleDirectorCheckbox
              text={nulisDeclarationMLCSuperFundDescription}
              name="nulisDeclarationMLCSuperFund"
              htmlFor="nulisDeclarationMLCSuperFund"
              onChangeHandler={this.handleChange}
              checked={nulisDeclarationMLCSuperFund.value}
              error={nulisDeclarationMLCSuperFund.error.error}
              caption={nulisDeclarationMLCSuperFund.error.error && nulisDeclarationMandatoryMsg}
            />

            <SoleDirectorCheckbox
              text={nulisDeclarationTFNDescription}
              name="nulisDeclarationTFN"
              htmlFor="nulisDeclarationTFN"
              onChangeHandler={this.handleChange}
              checked={nulisDeclarationTFN.value}
              error={nulisDeclarationTFN.error.error}
              caption={nulisDeclarationTFN.error.error && nulisDeclarationMandatoryMsg}
            />
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  nulis,
  createQuote,
  forms,
  addressLookup,
  abnLookup,
  sidebar,
}) => ({
  nulis,
  createQuote,
  form: forms[FORM_ID],

  isManualResidential:
    addressLookup.policyOwnerResidentialAddress &&
    addressLookup.policyOwnerResidentialAddress.isManual,
  abnLookup,
  sidebar,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNulis)
