import { createSelector } from 'reselect'
import get from 'lodash/get'
import moment from 'moment'
import 'core-js/es7/array'
import queryString from 'query-string'
import history from '../utils/browserHistory'
// Constants
import {
  POLICY_CHILD_COVER_BENEFIT_CODE,
  CPI_STATUS_ON,
  CPI_STATUS_OFF,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  POLICY_FEATURE_BO,
  MLC_ACC,
  NAVIGATOR,
  FUND_PAYMENT_METHODS_IOOF,
} from '../constants/policies'

import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_FAILED,
} from '../constants/apis'

import { BANCS_CUSTOMER_NUMBER_IOOF, BANCS_CUSTOMER_NUMBER_NULIS } from '../constants/bancs'

// Utils
import { getFullName, getFormattedDOB, getFullAddress } from '../utils/contactUtils'
import { dollarAmountWithCommasAndTwoDecimal } from '../utils/formUtils'
import { healthyLivingDiscountVal } from '../utils/extendedQuoteUtils'
import { getCollectionFrequency } from '../utils/paymentUtils'
import {
  DATE_FORMAT_WITH_MONTH_NAME,
  DATE_FORMAT,
  MMM_D_YYYY,
  CLIENT_SIDE_DATE_FORMAT,
} from '../utils/quoteUtils'
import { getPaymentCollectionMethod } from '../utils/paymentMethods'
import {
  getAllInForceBenefits,
  isBenefitAssuredForLifeInsured,
  isTdpOptimised,
  addOptimiserSuffixToBenefitName,
  // sortLifeAssuredByPolicyOwner,
  isCpiStatus,
} from '../utils/policyUtils'
import { getMasterData, getSite } from './common.selectors'
import { ADVISOR_PORTAL } from '../constants/site'

// @TODO: refactor selector to accomodate object type
const getCustomerPolicies = state => Object.values(state.customerPolicies)
export const getCustomerRelationships = state => Object.values(state.customerRelationships)
const getMasterList = state => ({
  policyStatus: get(state.masterList, 'data.policyStatus', []),
  benefitStatus: get(state.masterList, 'data.benefitStatus', []),
  masterList: state.masterList.data,
})

// @TODO: This root selector most likely breaks memoization,
// ideally this info should be stored as redux state. CF
const getPolicyNo = () => queryString.parse(history.location.search).policyId

const getLoadingStatus = state => {
  const policyId = getPolicyNo()
  return {
    summary: state.customerPolicySummaryStatus,
    policy: state.customerPolicyStatus[policyId],
  }
}

const findPolicy = (policies, policyId) => policies.find(p => p.policyNo === policyId)

const getLAForPolicy = state => {
  const policyId = getPolicyNo()
  const customerPolicies = getCustomerPolicies(state)
  const policy = findPolicy(customerPolicies, policyId) || {}
  return policy.lifeAssured || []
}

const getPolicyIdMappings = state =>
  Object.values(state.customerPolicies).map(policy => [
    {
      type: 'POLICY_ID',
      value: policy.policyId,
    },
    {
      type: 'BANCS_POLICY_NO',
      value: policy.policyNo,
    },
  ])

const getPolicyBenefits = state => {
  const policyId = getPolicyNo()
  const customerPolicies = getCustomerPolicies(state)
  const policy = findPolicy(customerPolicies, policyId) || {}
  return policy.benefits || []
}
export const getCustomerPolicyDetails = createSelector(
  [getCustomerPolicies, getPolicyNo],
  (policies, activePolicyNo) => {
    const policy = policies.find(p => p.policyNo === activePolicyNo)
    if (!policy) {
      return false
    }
    const {
      policyId,
      startDate,
      premiumPaymentMethod,
      policyPremium,
      paymentInstrument,
      bPayPayInReference,
      policyPremiumFrequency,
      benefits,
      policyName,
      workItems,
      policyFee,
      stampDuty,
      taxRebate,
      legacySystemProductCode,
    } = policy

    const collectionFrequency = get(
      paymentInstrument,
      'collectionFrequency',
      policyPremiumFrequency
    )
    const nextCollectionDateFromBenefit = get(
      paymentInstrument,
      'nextCollectionDate',
      get(benefits, '[0].nextPremiumDueDate', '')
    )
    const nextCollectionDate = nextCollectionDateFromBenefit
      ? moment(nextCollectionDateFromBenefit, DATE_FORMAT).format(MMM_D_YYYY)
      : '-'
    const fundDetails =
      premiumPaymentMethod === MLC_ACC && get(paymentInstrument, 'fundDetails', {})

    const shouldShowBpay = Boolean(
      !(
        premiumPaymentMethod === MLC_ACC &&
        fundDetails &&
        [...FUND_PAYMENT_METHODS_IOOF, NAVIGATOR].includes(fundDetails.sourceFundingSystem)
      ) && bPayPayInReference
    )
    return {
      policyName,
      policyId,
      benefits,
      startDate: moment(startDate).format(DATE_FORMAT_WITH_MONTH_NAME),
      premiumPaymentMethod,
      policyPremium: dollarAmountWithCommasAndTwoDecimal(policyPremium),
      paymentInstrument,
      bPayPayInReference,
      collectionFrequency: getCollectionFrequency(collectionFrequency),
      // paymentInstrument is undefined while loading, if it's an empty object we
      // assume there is no payment paymentCollectionMethod
      paymentCollectionMethod:
        typeof paymentInstrument === 'object' &&
        getPaymentCollectionMethod(premiumPaymentMethod, paymentInstrument),
      workItems,
      policyFee,
      stampDuty,
      taxRebate,
      nextCollectionDate,
      shouldShowBpay,
      policyPremiumFrequency,
      legacySystemProductCode,
    }
  }
)

export const getPrimaryPolicyOwner = createSelector(
  [getCustomerRelationships, getPolicyNo],
  (relationships, policyNo) => {
    let policyOwner = relationships.filter(
      relationship =>
        relationship.policyRolesMap[policyNo] &&
        relationship.policyRolesMap[policyNo].roles.some(role => ['OWR', 'NULIS'].includes(role))
    )
    if (policyOwner.length > 1) {
      policyOwner = policyOwner.filter(
        relationship =>
          relationship.policyRolesMap[policyNo].policyMeta.isPrimaryPolicyOwner === 'Y'
      )
    }
    policyOwner = policyOwner[0]
    if (!policyOwner) {
      return null
    }
    return [BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(
      policyOwner.bancsCustomerNo
    ) || policyOwner.partyType !== 'PER'
      ? policyOwner.businessName
      : `${policyOwner.firstName} ${policyOwner.lastName}`
  }
)

// @TODO: sort by primary policy owner
export const getLifeAssured = createSelector(
  [getLAForPolicy, getCustomerRelationships, getMasterList, getPolicyBenefits],
  (lifeAssuredList, relationships, { policyStatus }, benefits) => {
    const lifeAssured = lifeAssuredList.map(LA => {
      const relationship = relationships.find(r => r.bancsCustomerNo === LA) || {}
      const benefitCodeListForLA = get(
        relationship,
        `policyRolesMap.${getPolicyNo()}.policyMeta.benefits`,
        []
      )
      const {
        title,
        firstName,
        lastName,
        dateOfBirth,
        gender,
        smokerStatus,
        isTFNProvided,
        bancsCustomerNo,
        hasRelatedParty,
        contactMethods,
      } = relationship
      const formattedDateOfBirth = getFormattedDOB(dateOfBirth)
      return {
        fullName: getFullName({ title, firstName, lastName }, false),
        label: `${firstName} ${lastName}`,
        value: bancsCustomerNo,
        dob:
          formattedDateOfBirth && formattedDateOfBirth !== 'Invalid date'
            ? formattedDateOfBirth
            : null,
        gender,
        smokerStatus,
        isAnyBenefitsInForce: getAllInForceBenefits(benefits, policyStatus).some(benefit =>
          isBenefitAssuredForLifeInsured(benefit, bancsCustomerNo)
        ),
        tfn: isTFNProvided === 'Yes' ? 'Provided' : 'Not provided',
        bancsCustomerNo,
        hasRelatedParty,
        isChildLA: benefitCodeListForLA.includes(POLICY_CHILD_COVER_BENEFIT_CODE),
        fullAddress:
          contactMethods && contactMethods.addresses
            ? getFullAddress(contactMethods.addresses)
            : null,
      }
    })
    return lifeAssured
  }
)
// Gets all LA for a policy except Child LA's
export const getLifeAssuredForPolicy = createSelector([getLifeAssured], lifeAssured =>
  lifeAssured.filter(LA => !LA.isChildLA)
)

// Gets all LA for a policy with inforce benefits except Child LA's
export const getLifeAssuredWithBenefits = createSelector([getLifeAssured], lifeAssured =>
  lifeAssured.filter(LA => LA.isAnyBenefitsInForce && !LA.isChildLA)
)

const isCPI = benefit => (isCpiStatus(benefit.escalations || []) ? CPI_STATUS_ON : CPI_STATUS_OFF)

export const getPolicyCovers = createSelector(
  [getPolicyBenefits, getMasterList, getLifeAssured, getPolicyIdMappings],
  (benefits, { masterList }, lifeAssured, policyIdentifierMappings) => {
    const extractedBenefits = getAllInForceBenefits(benefits, masterList.policyStatus)
    return extractedBenefits.map(benefit => {
      let waitingPeriod = benefit.waitingPeriod
        ? `${benefit.waitingPeriod.value} ${benefit.waitingPeriod.unit}`
        : 'N/A'
      const coverPeriod = benefit.coverPeriod
        ? `${benefit.coverPeriod.unit} ${benefit.coverPeriod.value}`
        : 'N/A'
      if (waitingPeriod !== 'N/A' || coverPeriod !== 'N/A') {
        waitingPeriod = `${waitingPeriod} / ${coverPeriod}`
      }

      const isBoosterAvailable =
        benefit.subBenefits &&
        benefit.subBenefits.find(
          subBenefit => get(subBenefit, 'subBenefitCode', '') === POLICY_FEATURE_BO
        )
      const benefitObj = {
        benefit: addOptimiserSuffixToBenefitName(benefit) || '',
        type: benefit.type,
        subBenefitsCodes:
          (benefit.subBenefits &&
            benefit.subBenefits.flatMap(subBenefit => subBenefit.subBenefitCode)) ||
          [],
        benefitAssured: (benefit.benefitAssured || []).map(benefitAssured => ({
          bancsCustomerNo: benefitAssured,
          loadings: benefit.benefitAssuredLoadings[benefitAssured].loadings || [],
        })),
        associatedBenefits: [],
        isChildBenefit: benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE,
        paymentStructure: benefit.premiumStyle || 'N/A',
        waitingBenefitPeriod: waitingPeriod,
        cpiStatus: isCPI(benefit),
        campaignDetail: benefit.campaignDetail || [],
        parentBenefitCode: get(benefit.parentBenefitReference, 'parentBenefitCode', ''),
        parentBenefitReferenceNo: get(
          benefit.parentBenefitReference,
          'parentBenefitInstanceNo',
          ''
        ),
        sumInsured: `${dollarAmountWithCommasAndTwoDecimal(
          benefit.sumAssured || benefit.coverAmount
        )}${INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(benefit.type) ? ' p/m' : ''}`,
        benefitInstanceNo: benefit.benefitInstanceNo,
        premium: dollarAmountWithCommasAndTwoDecimal(benefit.premiumAmount),
        parentCover: isTdpOptimised(benefit, masterList, policyIdentifierMappings),
        childRelatedPartyList:
          benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE
            ? lifeAssured
                .filter(({ bancsCustomerNo }) =>
                  benefit.benefitAssured.flatMap(assured => assured).includes(bancsCustomerNo)
                )
                .map(({ fullName }) => fullName)
            : [],
        discounts: benefit.discounts || [],
      }
      let boosterObj = {}
      if (isBoosterAvailable) {
        boosterObj = {
          benefit: isBoosterAvailable.subBenefitName,
          benefitAssured: (benefit.benefitAssured || []).map(benefitAssured => ({
            bancsCustomerNo: benefitAssured,
            loadings: benefit.benefitAssuredLoadings[benefitAssured].loadings || [],
          })),
          benefitInstanceNo: benefit.benefitInstanceNo,
          isChildBenefit: benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE,
          childRelatedPartyList: [],
          parentType: benefit.type,
          cpiStatus: 'N/A',
          parentCover: isTdpOptimised(
            {
              parentBenefitReference: {
                parentBenefitCode: benefit.type,
                parentBenefitInstanceNo: benefit.benefitInstanceNo,
              },
            },
            masterList,
            policyIdentifierMappings
          ),
          paymentStructure: benefit.premiumStyle,
          premium: dollarAmountWithCommasAndTwoDecimal(isBoosterAvailable.premium),
          subBenefits: [],
          sumInsured: `${dollarAmountWithCommasAndTwoDecimal(isBoosterAvailable.sumAssured)} p/m`,
          type: isBoosterAvailable.subBenefitCode,
          waitingBenefitPeriod: 'N/A',
          discounts: benefit.discounts || [],
        }
      }
      return isBoosterAvailable ? [benefitObj, boosterObj] : [benefitObj]
    })
  }
)

export const getExclusions = createSelector(
  [getCustomerRelationships, getPolicyNo, getMasterList],
  (relationships, policyId, { masterList }) => {
    const lifeAssuredForPolicy = relationships.filter(LA =>
      get(LA.policyRolesMap[policyId], 'roles', []).includes('LA')
    )

    const exclusionsMeta = lifeAssuredForPolicy.flatMap(LA =>
      get(LA.policyRolesMap[policyId], 'policyMeta.exclusions', []).map(exclusionList => {
        const exclusions = exclusionList.map(exclusion => ({
          ...exclusion,
          isForChild: LA.policyRolesMap[policyId].policyMeta.benefits.includes(
            POLICY_CHILD_COVER_BENEFIT_CODE
          ),
          lifeAssured: LA.bancsCustomerNo,
          benefitHeading: masterList.benefitDescriptions.find(
            benefitDesc => benefitDesc.id === exclusion.benefitType
          ).benefitHeading,
        }))
        return getAllInForceBenefits(exclusions, masterList.policyStatus)
      })
    )
    return exclusionsMeta
  }
)

export const getPolicyFees = createSelector([getCustomerPolicyDetails], policy => {
  const { policyPremium, policyFee, stampDuty, taxRebate, policyPremiumFrequency, benefits } =
    policy
  return {
    policyFee: dollarAmountWithCommasAndTwoDecimal(typeof policy !== 'number' ? policyFee : 0),
    policyStampDuty: dollarAmountWithCommasAndTwoDecimal(stampDuty),
    policyTaxRebate: `- ${dollarAmountWithCommasAndTwoDecimal(taxRebate)}`,
    policyPaymentfrequency: `Policy ${getCollectionFrequency(policyPremiumFrequency)} Payment`,
    policyHealthyLivingDiscount: healthyLivingDiscountVal(benefits),
    policyPremium,
  }
})

/**
 * List of policy activity filtered based on defined items in masterdata
 * Transform description of each item as defined in masterdata
 */
export const getPolicyActivity = createSelector(
  [getCustomerPolicyDetails, getMasterData, getSite],
  (policy, masterdata, site) => {
    const masterDataKey = site === ADVISOR_PORTAL ? 'applyAP' : 'applyCP'
    const workItemsDisplayList = get(masterdata, 'policyActivities', []).filter(i =>
      Boolean(i[masterDataKey])
    )
    const workItemsInclusionList = workItemsDisplayList.map(({ code }) => code)
    const transactions = get(policy.workItems, 'transactions', [])
    const statusMap = {
      OPEN: 'open',
      CLOS: 'closed',
    }

    return transactions
      .filter(({ workTypeCode }) => workItemsInclusionList.includes(workTypeCode))
      .map(({ status, workTypeCode, creationDate }) => ({
        status: statusMap[status],
        requestType: get(
          workItemsDisplayList.find(
            ({ code }) => code.toUpperCase() === workTypeCode.toUpperCase()
          ),
          'value',
          ''
        ),
        dateCreated: moment(creationDate).format(CLIENT_SIDE_DATE_FORMAT),
      }))
  }
)

export const isPolicyDetailsPageLoading = createSelector(
  [getLoadingStatus],
  ({ summary, policy }) => {
    if (summary.isLoading === EXP_CUSTOMER_STATUS_FAILED) {
      return false
    }
    if (!policy) {
      return true
    }
    const {
      statuses: {
        self: { status },
      },
    } = policy
    return Boolean(
      summary.isLoading === EXP_CUSTOMER_STATUS_UNLOADED ||
        summary.isLoading === EXP_CUSTOMER_STATUS_LOADING ||
        status === EXP_CUSTOMER_STATUS_LOADING ||
        status === EXP_CUSTOMER_STATUS_UNLOADED
    )
  }
)

export const shouldCallSummary = createSelector(
  [getLoadingStatus],
  ({ summary }) => summary.isLoading === EXP_CUSTOMER_STATUS_UNLOADED
)

export const shouldCallPolicyDetails = createSelector(
  [getLoadingStatus],
  ({ policy }) => get(policy, 'statuses.self.status', '') === EXP_CUSTOMER_STATUS_UNLOADED
)

export const isPolicyActivityLoading = createSelector([getLoadingStatus], ({ policy }) => {
  if (!policy) {
    return true
  }
  const {
    statuses: {
      workItems: { status },
    },
  } = policy
  return status === EXP_CUSTOMER_STATUS_LOADING || status === EXP_CUSTOMER_STATUS_UNLOADED
})

export const hasPolicyDetailsPageFailed = createSelector(
  [getLoadingStatus],
  status =>
    status.policy &&
    Object.values({
      self: status.policy.statuses.self,
      paymentInstrument: status.policy.statuses.paymentInstrument,
    }).some(policyItem => policyItem.status === EXP_CUSTOMER_STATUS_FAILED)
)
// @TODO: not being used currently, will be used if we load supporting data at will
export const getSupportingDataToLoad = createSelector([getLoadingStatus], ({ policy }) => {
  const { self, ...supportingData } = get(policy, 'statuses', {})
  Object.keys(supportingData).filter(
    item => supportingData[item].status === EXP_CUSTOMER_STATUS_UNLOADED
  )
})
