/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLock32 = ({ ...rest }) => (
  <Icon {...rest} size={91}>
    <path
      d="M64.8747 84L66.8406 82.0177V34.4429L64.8747 32.4607H55.0452V22.5492C55.0452 16.7662 52.7669 11.22 48.7114 7.13071C44.656 3.04146 39.1556 0.744141 33.4203 0.744141C27.685 0.744141 22.1846 3.04146 18.1292 7.13071C14.0737 11.22 11.7954 16.7662 11.7954 22.5492V32.4607H1.9659L0 34.4429V82.0177L1.9659 84H64.8747ZM15.7272 22.5492C15.7272 17.8176 17.5913 13.2798 20.9094 9.93408C24.2275 6.58833 28.7278 4.70871 33.4203 4.70871C38.1128 4.70871 42.6131 6.58833 45.9312 9.93408C49.2493 13.2798 51.1134 17.8176 51.1134 22.5492V32.4607H15.7272V22.5492ZM3.9318 36.4252H62.9088V80.0354H3.9318V36.4252Z"
      fill="#01838C"
    />
    <path d="M35.3864 50.3008H31.4546V66.159H35.3864V50.3008Z" fill="#01838C" />
  </Icon>
)

export default IconLock32
