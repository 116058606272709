// @flow
import { Component, Fragment } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import styled from '@emotion/styled'

/** @jsx jsx */
import { jsx } from '@emotion/core'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Chart,
  Heading,
  Button,
  Loader,
  Disclaimer as DisclaimerAtom,
} from '@mlcl-digital/mlcl-design'
import uniqBy from 'lodash/uniqBy'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components.
import ProjectionsForm from './ProjectionsForm'
import ProjectionsTable from './ProjectionsTable'
import QuoteDiscounts from '../QuoteTool/components/QuoteToolPanel/QuoteDiscounts'
import Checkbox from '../../atoms/Checkbox'

// helpers.
import {
  makeTableData,
  makeChartData,
  getProjectionYears,
  getMaxDuration,
  makeProjectionsPDFData,
  combinedProjections,
} from './helpers/projections.data'
import {
  PROJECTIONS_CHART_LINES,
  PROJECTIONS_CHART_XAXIS,
  PROJECTIONS_CHART_YAXIS,
} from './helpers/projections.chart'
import { renderTextField, reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { getProduct, getBenefit, getEscalations } from '../../../utils/quoteUtils'
import {
  checkCoversApplicableProjectionPolicy,
  checkCoverForApplyingProject,
  checkQuoteToDisableCPIProjection,
} from '../../../utils/extendedQuoteUtils'
import { getValue } from '../../../utils/formUtils'
import { downloadDocument } from '../../../utils/downloadDocumentUtils'
import { generateCorrelationID } from '../../../utils/commonUtils'
import { isPartyTypeOrg } from '../../../utils/contactUtils'

// constants
import {
  PREMIUM_STYLE_STEPPED,
  COVERS_NOT_FOR_UI,
  COMBINED_COVER,
} from '../../../constants/benefit'
import { DOC_TYPE_PROJECTION_TOOL } from '../../../constants/documentTypes'

// types.
import { type SelectOptionType } from '../../atoms/Select'
import { ProjectionDataStructure } from '../../../types/projections'

// styles.
import styles from './projections.styles'
import { space } from '../../../styles'

const DEFAULT_DURATION = 5
const TEST_ID = 'projections-container'

// styled components.
const Wrapper = styled('div')(styles.wrapper)
const TableContainer = styled('div')(styles.tableContainer)
const ChartFormContainer = styled('div')(styles.chartFormContainer)
const Disclaimer = styled(DisclaimerAtom)(styles.disclaimer)
const HeadingContainer = styled('div')(styles.headingContainer)
const StyledCheckbox = styled(Checkbox)(({ structure }) => styles.styledCheckbox(structure))
const DownloadProjectionContainer = styled('div')(styles.downloadProjectionContainer)

type ProjectionsProps = {
  // all the quotes!
  quotes: Array<Object>,
  // the active quote's index.
  activeQuoteIndex: number,
  // indicates whether there is a pending calculation.
  // TODO: will eventually need this when we need to re-calculate projections.
  isRecalculating: boolean,
  // redux env constants config
  config: Object,
  // advisor details
  advisorDetails: Object,
  // life insured first name
  lifeInsuredFirstName: string,
  // life insured last name
  lifeInsuredLastName: string,
  // product data from product rules.
  productData: Array<Object>,
  // action creators.
  // TODO: will eventually need this when we need to re-calculate projections with[out] CPI.
  actions: Object,
  // sitecore fields from Sidebar route.
  fields: {
    projectionsTitle: Object,
    projectionsQuoteLabel: Object,
    projectionsPolicyLabel: Object,
    ProjectionsCoverLabel: Object,
    projectionsApplyCPILabel: Object,
    projectionsCumulativeLabel: Object,
    projectionsProjectDurationLabel: Object,
    ProjectionsSelectedLabel: Object,
    projectionsChartTitle: Object,
    projectionsTableFrequencyMonthly: Object,
    projectionsTableFrequencyAnnual: Object,
    projectionsTableFrequencyBiannual: Object,
    ProjectionsTableHeaderAnnualized: Object,
    projectionsTableHeaderPremium: Object,
    projectionsEmptyMessageNoPolicy: Object,
    projectionsEmptyMessageNoProjections: Object,
    quoteDiscountMessagePrefix: Object,
    quoteDiscountMessageAppliedToQuote: Object,
    quoteDiscountMessageAppliedToBenefit: Object,
    quoteDiscountMessageSuffix: Object,
    projectionsMLCOnTrackDisclaimer: Object,
    quoteProjectionsDisclaimer: Object,
    combinedPoliciesLabelForProjections: Object,
    CombinedCoversLabelForProjections: Object,
  },
}

type ProjectionsState = {
  // quote index of selected quote.
  selectedQuoteIndex: number,
  // policy index of selected policy.
  selectedPolicyIndex: number,
  // cover type fpr selected cover
  selectedCover: string,
  // indicates checked status of "Apply CPI" checkbox.
  applyCPI: boolean,
  // indicates checked status of "Cumulative" checkbox.
  cumulative: boolean,
  // indicates checked status of "Stepped" checkbox.
  stepped: boolean,
  // indicates checked status of "Level" checkbox.
  level: boolean,
  // indicates checked status of "Selected" checkbox.
  selected: boolean,
  // project duration.
  duration: number,
  // flag for combied policies
  combined: boolean,
}

export class Projections extends Component<ProjectionsProps, ProjectionsState> {
  constructor(props: ProjectionsProps) {
    super(props)
    const { activeQuoteIndex } = props
    this.state = {
      selectedQuoteIndex: activeQuoteIndex,
      selectedPolicyIndex: 0,
      selectedCover: COMBINED_COVER,
      applyCPI: true,
      stepped: false,
      // level and selected don't update
      level: false,
      selected: false,
      combined: false,
      cumulative: false,
      duration: DEFAULT_DURATION,
    }

    // Trigger calculate quote API to get projections with default apply CPI
    const selectedPolicy = this.selectedPolicy()
    if (selectedPolicy) {
      this.state = {
        ...this.state,
        ...this.getPremiumStyleCheckboxValues(selectedPolicy),
      }
      this.updateCpiAndTriggerCalculate()
    }

    const tagEvent = createEvent({
      GA: {
        category: 'Projections',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Projections - View',
        },
      },
    })
    tagEvent.end()
  }

  handleChange =
    (handler: Function): Function =>
    ({ value }) => {
      if (typeof handler === 'function') {
        handler(getValue(value))
      }
    }

  changeQuote = (quoteIndex: number): void => {
    const { quotes } = this.props
    this.setState(({ selectedQuoteIndex, selectedPolicyIndex }) => {
      const quoteHasChanged = quoteIndex !== selectedQuoteIndex
      const policyIndex = quoteHasChanged ? 0 : selectedPolicyIndex
      return {
        selectedQuoteIndex: quoteIndex,
        selectedPolicyIndex: policyIndex,
        combined: false,
        selectedCover: get(quotes[quoteIndex].policyStructure[policyIndex], 'covers[0].type', ''),
        duration: DEFAULT_DURATION,
      }
    }, this.updateCpiAndTriggerCalculate)
  }

  getPremiumStyleCheckboxValues = (policy: Object) => {
    const { covers = [] } = policy
    const stepped = covers.some(cover => {
      const { premiumStyle = '' } = cover
      return premiumStyle.toLowerCase() === PREMIUM_STYLE_STEPPED.toLowerCase()
    })
    // Don't show level in projections. See https://jira.mlctech.io/browse/RET-23562
    const level = false
    return {
      stepped,
      level,
    }
  }

  changePolicy = (policyIndex: number): void => {
    const { selectedCover } = this.state
    const policies = this.selectedQuote().policyStructure
    const selectedPolicy = policies && policies.length ? policies[policyIndex] : null

    if (policies.length === policyIndex) {
      const combinedCovers =
        selectedCover === COMBINED_COVER
          ? flatten(policies.flatMap(policy => policy.covers))
          : flatten(
              policies.flatMap(policy =>
                policy.covers.filter(cover => cover.type === selectedCover)
              )
            )
      this.setState({
        combined: true,
        selectedCover: COMBINED_COVER,
        ...this.getPremiumStyleCheckboxValues({ covers: combinedCovers }),
      })
    } else {
      const covers = get(selectedPolicy, 'covers', [])
      const combinedCovers =
        selectedCover === COMBINED_COVER
          ? covers
          : covers.filter(cover => cover.type === selectedCover)
      this.setState({
        combined: false,
        selectedPolicyIndex: policyIndex,
        selectedCover: get(selectedPolicy, 'covers[0].type', ''),
        duration: DEFAULT_DURATION,
        ...this.getPremiumStyleCheckboxValues({ covers: combinedCovers }),
      })
    }
  }

  changeCover = (coverType: string): void => {
    const { selectedPolicyIndex, combined } = this.state
    const policies = this.selectedQuote().policyStructure
    const selectedPolicy = policies && policies.length ? policies[selectedPolicyIndex] : null
    const covers = get(selectedPolicy, 'covers', [])
    if (coverType === COMBINED_COVER) {
      const combinedCovers = combined ? flatten(policies.flatMap(policy => policy.covers)) : covers
      this.setState({
        selectedCover: COMBINED_COVER,
        ...this.getPremiumStyleCheckboxValues({ covers: combinedCovers }),
      })
    } else {
      const combinedCovers = combined
        ? flatten(
            policies.flatMap(policy => policy.covers.filter(cover => cover.type === coverType))
          )
        : covers.filter(cover => cover.type === coverType)
      this.setState({
        selectedCover: coverType,
        duration: DEFAULT_DURATION,
        ...this.getPremiumStyleCheckboxValues({ covers: combinedCovers }),
      })
    }
  }

  getQuoteWithOrWithoutEscalation = (currentQuote, productData, withEscalation) => ({
    ...currentQuote,
    policyStructure: currentQuote.policyStructure.map(policy => ({
      ...policy,
      covers: policy.covers.map(cover => {
        if (!withEscalation) {
          const newCover = { ...cover }
          delete newCover.escalations
          return newCover
        }
        const formattedProduct = getProduct(productData, policy.productId)
        let escalations = []
        if (formattedProduct) {
          const getCoverDetails = getBenefit(formattedProduct.benefits, cover.type)
          escalations = getEscalations(getCoverDetails)
        }
        return {
          ...cover,
          ...(!checkCoverForApplyingProject(cover) && {
            ...escalations,
          }),
        }
      }),
    })),
  })

  updateCpiAndTriggerCalculate = () => {
    const { actions, quotes, productData } = this.props
    const { selectedQuoteIndex, applyCPI } = this.state
    const { createQuoteCalculate } = actions
    const currentQuote = quotes[selectedQuoteIndex]
    const quotesWithAppliedCPI = this.getQuoteWithOrWithoutEscalation(
      currentQuote,
      productData,
      applyCPI
    )
    createQuoteCalculate({ quote: quotesWithAppliedCPI, quoteIndex: selectedQuoteIndex })
  }

  /**
   * Recalculate quotes by passing CPI in body request
   * @param {boolean} hasCPI - value of the CPI checkbox
   */
  applyCPIChange = (hasCPI: boolean): void => {
    this.setState(
      {
        applyCPI: hasCPI,
      },
      this.updateCpiAndTriggerCalculate
    )
  }

  handleProjectionDownload = () => {
    const {
      config,
      lifeInsuredFirstName,
      lifeInsuredLastName,
      advisorDetails: { partyType, businessName, firstName, lastName },
    } = this.props
    const { duration, cumulative, stepped, level, applyCPI } = this.state

    // If all of the benefits in the quote aren't
    // applicable to CPI then we just want to disable this.
    const disableCPI = checkQuoteToDisableCPIProjection(this.selectedQuote())

    const quoteProjectionsPDF = {
      ...this.selectedQuote(),
      ...makeProjectionsPDFData({
        policies: this.selectedQuote().policyStructure,
        count: duration,
      }),
      isStepped: stepped,
      isLevel: level,
      isCumulative: cumulative,
      isCpi: disableCPI ? false : applyCPI,
      projectionDuration: duration,
      printableAdviserDetails: {
        ...(isPartyTypeOrg(partyType)
          ? { businessName }
          : {
              firstName,
              lastName,
            }),
      },
    }
    const projectionsData = {
      docType: DOC_TYPE_PROJECTION_TOOL,
      quote: quoteProjectionsPDF,
      correlationID: generateCorrelationID(),
      lifeInsuredFirstName,
      lifeInsuredLastName,
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Projections',
        action: 'Download PDF',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Projections - Download PDF',
        },
      },
    })
    tagEvent.end()
    downloadDocument(projectionsData, null, null, config)
  }

  updateStateHandler =
    (key: string): Function =>
    (value: mixed): void => {
      this.setState({ [key]: value })
    }

  selectedQuote = (): Object => {
    const { quotes } = this.props
    const { selectedQuoteIndex } = this.state
    return quotes[selectedQuoteIndex] || {}
  }

  selectedPolicy = (): ?Object => {
    const { selectedPolicyIndex } = this.state
    const policies = this.selectedQuote().policyStructure
    return policies && policies.length ? policies[selectedPolicyIndex] : null
  }

  quoteOptions = (): Array<SelectOptionType> => {
    const { quotes } = this.props
    return quotes.map(({ quoteName }, quoteIndex) => ({
      value: quoteIndex,
      label: quoteName,
    }))
  }

  policyOptions = (): Array<SelectOptionType> => {
    const policies: Array<SelectOptionType> = this.selectedQuote().policyStructure.map(
      ({ productName }, policyIndex) => ({
        value: policyIndex,
        label: productName,
      })
    )

    const { fields } = this.props
    const { combinedPoliciesLabelForProjections } = reduceAuthorableFields(fields)

    if (policies.length > 1) {
      policies.push({
        value: policies.length,
        label: combinedPoliciesLabelForProjections,
      })
    }
    return policies
  }

  coverOptions = (): Array<SelectOptionType> => {
    const { combined } = this.state
    const { fields } = this.props
    const { CombinedCoversLabelForProjections } = reduceAuthorableFields(fields)
    const coverList = covers =>
      covers
        .filter(({ type }) => !COVERS_NOT_FOR_UI.includes(type))
        .map(({ name, type }) => ({
          value: type,
          label: name,
        }))
    /* if projections for all policies is selected */
    if (combined) {
      const { policyStructure } = this.selectedQuote()
      const combinedCovers = coverList(
        uniqBy(flatten(policyStructure.flatMap(policy => policy.covers)), 'type')
      )
      if (combinedCovers.length > 0) {
        combinedCovers.push({
          value: COMBINED_COVER,
          label: CombinedCoversLabelForProjections,
        })
      }
      return combinedCovers
    }
    const covers: Array<SelectOptionType> = coverList(uniqBy(this.selectedPolicy().covers, 'type'))
    if (covers.length > 0) {
      covers.push({
        value: COMBINED_COVER,
        label: CombinedCoversLabelForProjections,
      })
    }
    return covers
  }

  // returns duration select options based on max duration of projections data.
  // returns default duration options if there is no projections data.
  durationOptions = (projections: Array<Object>): Array<SelectOptionType> => {
    const DEFAULT_DURATION_OPTIONS = [5, 10, 15, 20, 30, 40, 50, 60]
    const projectionLength = getProjectionYears(projections)
    const makeOption = duration => ({
      value: duration,
      label: duration.toString(),
    })

    // when no projections data, return all duration options.
    if (!projectionLength) {
      return DEFAULT_DURATION_OPTIONS.map(makeOption)
    }

    // with projections data, return filtered duration options.
    const maxDurationIndex = DEFAULT_DURATION_OPTIONS.indexOf(
      getMaxDuration(projectionLength, DEFAULT_DURATION_OPTIONS)
    )
    return DEFAULT_DURATION_OPTIONS.slice(0, maxDurationIndex + 1).map(makeOption)
  }

  constructHeading = () => {
    const { fields } = this.props
    const { projectionsTableHeaderPremium, projectionsTitle } = fields

    return `${projectionsTableHeaderPremium.value} ${projectionsTitle.value?.toLowerCase()}`
  }

  renderChartCheckboxes = () => {
    const { fields } = this.props

    const { ProjectionsSteppedLabel, ProjectionsLevelLabel, ProjectionsSelectedLabel } = fields
    const { stepped, level, selected } = this.state

    return (
      <div>
        {/* Stepped */}
        <StyledCheckbox
          structure="stepped"
          text={ProjectionsSteppedLabel.value}
          name="projections-premium-structure-stepped"
          htmlFor="projections-premium-structure-stepped"
          onChangeHandler={this.handleChange(this.updateStateHandler('stepped'))}
          checked={stepped}
          css={styles.checkbox}
        />

        {/* Level */}
        <StyledCheckbox
          structure="level"
          text={ProjectionsLevelLabel.value}
          name="projections-premium-structure-level"
          htmlFor="projections-premium-structure-level"
          onChangeHandler={this.handleChange(this.updateStateHandler('level'))}
          checked={level}
          css={styles.checkbox}
        />

        {/* Selected */}
        <StyledCheckbox
          structure="selected"
          text={ProjectionsSelectedLabel.value}
          name="projections-premium-structure-selected"
          htmlFor="projections-premium-structure-selected"
          onChangeHandler={this.handleChange(this.updateStateHandler('selected'))}
          checked={selected}
          css={styles.checkbox}
        />
      </div>
    )
  }

  componentWillUnmount = () => {
    const {
      quotes,
      productData,
      actions: { createQuoteCalculate },
    } = this.props
    const { selectedQuoteIndex } = this.state

    createQuoteCalculate({
      quote: this.getQuoteWithOrWithoutEscalation(quotes[selectedQuoteIndex], productData, true),
      quoteIndex: selectedQuoteIndex,
    })
  }

  renderProjectionsChart = () => {
    const { fields, isRecalculating } = this.props
    if (isRecalculating) {
      return <Loader type="panel" />
    }
    const { projectionsEmptyMessageNoProjections } = fields
    const { duration, cumulative, stepped, level, selected, combined, selectedCover } = this.state
    const selectedPolicy = this.selectedPolicy()
    const { projections, paymentFrequency } = selectedPolicy
    const hasProjections = projections && projections.length
    const policies = this.selectedQuote().policyStructure

    /* only render table and chart if projections data exists */
    return hasProjections ? (
      <div>
        <HeadingContainer>
          <Heading variant="h3" size="small">
            {this.constructHeading()}
          </Heading>
          {/* Not in used. See https://jira.mlctech.io/browse/RET-23562 */}
          {/* {this.getChartCheckboxes()} */}
        </HeadingContainer>
        {stepped ? (
          <Chart
            variant="line"
            data={makeChartData({
              projections,
              isCumulative: cumulative,
              isStepped: stepped,
              isLevel: level,
              isSelected: selected,
              isCombined: combined,
              count: duration,
              paymentFrequency,
              selectedCover,
              policies,
            })}
            width={580}
            height={390}
            showLegend={false}
            lines={PROJECTIONS_CHART_LINES}
            lineType="linear"
            xAxis={PROJECTIONS_CHART_XAXIS(Number(duration))}
            yAxis={PROJECTIONS_CHART_YAXIS}
            margin={{ bottom: space(5), right: space(1) }}
          />
        ) : (
          <Text field={projectionsEmptyMessageNoProjections} />
        )}
      </div>
    ) : (
      <Text field={projectionsEmptyMessageNoProjections} />
    )
  }

  renderSingleTable = (isShow: boolean, label: string, structure: ProjectionDataStructure) => {
    const { fields } = this.props
    const { ProjectionBreakdownLabel } = fields
    const selectedPolicy = this.selectedPolicy()
    const { projections, paymentFrequency } = selectedPolicy
    const { duration, cumulative, combined, selectedCover } = this.state
    const policies = this.selectedQuote().policyStructure
    return (
      isShow && (
        <TableContainer>
          <Heading variant="h4" size="small">
            {label} {ProjectionBreakdownLabel.value}
          </Heading>
          <ProjectionsTable
            data={makeTableData(
              {
                projections,
                paymentFrequency,
                structure,
                fields,
                isCumulative: cumulative,
                isCombined: combined,
                policies,
                selectedCover,
              },
              duration
            )}
          />
        </TableContainer>
      )
    )
  }

  renderProjectionsTable = () => {
    const { fields } = this.props
    const {
      projectionsMLCOnTrackDisclaimer,
      quoteProjectionsDisclaimer,
      projectionsTableHeaderPremium,
    } = fields
    const { stepped } = this.state
    const selectedPolicy = this.selectedPolicy()
    const { mlcOnTrack } = selectedPolicy

    return (
      <Fragment>
        {this.renderSingleTable(
          stepped,
          projectionsTableHeaderPremium.value,
          ProjectionDataStructure.Stepped
        )}
        {quoteProjectionsDisclaimer && (
          <Disclaimer>{renderTextField(quoteProjectionsDisclaimer, true)}</Disclaimer>
        )}
        {mlcOnTrack && (
          <Disclaimer>{renderTextField(projectionsMLCOnTrackDisclaimer, true)}</Disclaimer>
        )}
      </Fragment>
    )
  }

  render() {
    const { fields, productData } = this.props
    const { projectionsEmptyMessageNoPolicy, ProjectionsDownloadPDF } = fields
    const policies = this.selectedQuote().policyStructure

    const {
      selectedQuoteIndex,
      selectedPolicyIndex,
      duration,
      applyCPI,
      cumulative,
      selectedCover,
      combined,
    } = this.state
    const selectedPolicy = this.selectedPolicy()

    // render an empty state if there is no policy data.
    if (!selectedPolicy) {
      return (
        <Wrapper data-testid={TEST_ID}>
          <Text field={projectionsEmptyMessageNoPolicy} />
        </Wrapper>
      )
    }
    const disableCPI = checkCoversApplicableProjectionPolicy(selectedPolicy)
    // otherwise, render the UI.
    const projections = combined
      ? [{ premiumProjectionTwo: combinedProjections(policies) }]
      : get(selectedPolicy, 'projections', [])
    return (
      <Wrapper data-testid={TEST_ID}>
        <ChartFormContainer>
          {this.renderProjectionsChart()}
          <ProjectionsForm
            selectedQuoteIndex={selectedQuoteIndex}
            selectedPolicyIndex={combined ? policies.length : selectedPolicyIndex}
            selectedCover={selectedCover}
            quoteOptions={this.quoteOptions()}
            policyOptions={this.policyOptions()}
            coverOptions={this.coverOptions()}
            durationOptions={this.durationOptions(projections)}
            duration={duration}
            applyCPI={disableCPI ? false : applyCPI}
            disableCPI={disableCPI}
            cumulative={cumulative}
            changeQuote={this.changeQuote}
            changePolicy={this.changePolicy}
            changeCover={this.changeCover}
            changeDuration={this.updateStateHandler('duration')}
            changeCPI={this.applyCPIChange}
            changeCumulative={this.updateStateHandler('cumulative')}
            fields={fields}
          />
        </ChartFormContainer>
        {this.renderProjectionsTable()}
        <QuoteDiscounts
          fields={fields}
          policyStructure={this.selectedQuote().policyStructure}
          productData={productData}
          styles={{ wrapper: styles.discounts }}
        />
        <DownloadProjectionContainer>
          <Button onClick={this.handleProjectionDownload} type="primary">
            <Text field={ProjectionsDownloadPDF} />
          </Button>
        </DownloadProjectionContainer>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({
  createQuote,
  productRules,
  config,
  advisor: { details },
}: Object): Object => {
  const { quotes, activeIndex, isRecalculating, lifeInsuredFirstName, lifeInsuredLastName } =
    createQuote
  return {
    quotes,
    lifeInsuredFirstName,
    lifeInsuredLastName,
    activeQuoteIndex: activeIndex,
    isRecalculating,
    productData: productRules.data,
    config,
    advisorDetails: details,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projections)
