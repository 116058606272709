/* eslint-disable array-callback-return */
// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { withRouter } from 'react-router-dom'
import { pathOr } from 'lodash/fp'
import { PageWrap } from '@mlcl-digital/mlcl-design'

// components.
import PolicySetupPolicy from '../PolicySetupPolicy'
import Heading from '../../../../atoms/Heading'
import Button from '../../../../atoms/Button'

// utils.
import { policyHasOwner } from '../../../../../utils/quoteUtils'

// styles.
import styles from './policySetupView.styles'

// icons.
import { IconArrowLeft16 } from '../../../../atoms/Icons'

// styled components.
const Header = styled('header')(styles.header)
const Title = styled(Heading)(styles.title)
const Subtitle = styled(Heading)(styles.subtitle)
const Content = styled(PageWrap)(styles.content)
const Policies = styled('div')(styles.policies)
const BackButton = styled(Button)(styles.backButton)

// helpers.
const renderPolicy = (fields: Object) => (policy: Object) =>
  (
    <PolicySetupPolicy
      key={policy.productName}
      policy={policy}
      fields={fields}
      hasOwner={policyHasOwner(policy)}
    />
  )

type PolicySetupViewProps = {
  // sitecore fields
  fields: Object,
  // quote entity
  quote: Object,
  // action
  actions: Object,
  // history object
  history: Object,
}

const PolicySetupView = (props: PolicySetupViewProps) => {
  const { quote, fields, history, actions } = props

  const policies = pathOr([], 'policyStructure', quote)

  const saveQuoteCallBack = () => {
    history.push('/application')
  }

  // save policySetup
  const saveSetup = () => {
    const { saveQuote } = actions

    saveQuote(saveQuoteCallBack)
  }
  return (
    <Fragment>
      <Header>
        <PageWrap>
          <BackButton onClick={history.goBack} type="icon">
            <IconArrowLeft16 />
            <Text field={fields.backButtonLabel} />
          </BackButton>
          <Title size="1">
            <Text field={fields.pageTitle} />
          </Title>
          <Subtitle size="6">
            <Text field={fields.pageSubTitle} />
          </Subtitle>
        </PageWrap>
      </Header>
      <Content>
        {Array.isArray(policies) && policies[0] && (
          <Fragment>
            <Policies>{policies.map(renderPolicy(fields))}</Policies>
            <Button onClick={saveSetup}>
              <Text field={fields.saveButtonLabel} />
            </Button>
          </Fragment>
        )}
      </Content>
    </Fragment>
  )
}

export default withRouter(PolicySetupView)
