// @flow
// constants
import {
  PREFIX_KEY,
  POLICY_OWNER,
  POLICY_OWNER_KEY,
  LIFE_INSURED,
  LIFE_INSURED_KEY,
  QUOTE_COLLECTION_ID,
  QUOTE_COLLECTION_ID_KEY,
  APPLICATION_TYPE,
  APPLICATION_TYPE_KEY,
  KEY_SEPARATOR,
  HEADER_ENTITY,
} from '../constants/taskDashboard'

// create dynamic header with prefix and index as key
export const createCsvHeader = (maxSize: Number) => {
  const { keys, prefix } = HEADER_ENTITY
  let formattedArray = []
  // loop for the number of keys to map
  formattedArray = keys.reduce((newArr, key) => {
    const tmpArr = []
    // loop for the  maximum number of outstanding requirements in the records
    for (let index = 0; index <= maxSize; index += 1) {
      tmpArr.push({
        label: `${prefix}${KEY_SEPARATOR}${index}${KEY_SEPARATOR}${key}`,
        key: `${prefix}${KEY_SEPARATOR}${index}${KEY_SEPARATOR}${key}`,
      })
    }
    newArr.push(tmpArr)
    const flattenArr = newArr.concat.apply([], newArr)
    return flattenArr
  }, [])
  return formattedArray
}

// create the header to download CSV
export const csvTaskHeader = (maxSize: Number) => [
  { label: POLICY_OWNER, key: POLICY_OWNER_KEY },
  { label: LIFE_INSURED, key: LIFE_INSURED_KEY },
  { label: QUOTE_COLLECTION_ID, key: QUOTE_COLLECTION_ID_KEY },
  { label: APPLICATION_TYPE, key: APPLICATION_TYPE_KEY },
  // create dynamic header with label and key corresponding to the data
  ...createCsvHeader(maxSize),
]

// format the object to return a new single level object with combine key and index as key
export const convertObjWithKeyIndexPrefix = (obj: Object, index: number, res: Object = {}) => {
  Object.keys(obj).forEach(key => {
    const propName = index ? `${PREFIX_KEY}${KEY_SEPARATOR}${index}${KEY_SEPARATOR}${key}` : key
    if (typeof obj[key] === 'object') {
      convertObjWithKeyIndexPrefix(obj[key], propName, res)
    } else {
      res[propName] = obj[key]
    }
  })
  return res
}

export const downloadAllTasks = (tasks: Array) => {
  const formatTask = tasks
    .map(task => ({
      ...task,
      ...convertObjWithKeyIndexPrefix(task.outstandingRequirements),
    }))
    .map(outstandingRequirements => ({
      ...outstandingRequirements,
    }))
  // get the maximum count of oustanding requirement amongst the tasks
  const maxOutstandingRequirements = formatTask.map(task => task.outstandingRequirementsCount)
  // has data to download or not
  const isTaskAvailableToDownload = formatTask.length !== 0

  createCsvHeader(Math.max(...maxOutstandingRequirements))
  return {
    formatTask,
    maxOutstandingRequirements: Math.max(...maxOutstandingRequirements),
    isTaskAvailableToDownload,
  }
}

export const formatLifeAssuredArray = lifeAssuredString =>
  lifeAssuredString.split(',').map(LI => LI.trim())
