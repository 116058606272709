/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconHelp32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 14c0-.735.25-1.121.499-1.337.28-.243.706-.395 1.207-.38 1.049.03 1.794.685 1.794 1.658 0 .016-.001.018-.003.023l-.002.002a.777.777 0 0 1-.135.176 3.135 3.135 0 0 1-.156.154c-.106.099-.22.192-.363.309-.152.124-.335.274-.578.486-.497.434-1.082 1.014-1.532 1.836-.457.835-.731 1.84-.731 3.073h3c0-.767.164-1.27.363-1.633.206-.377.495-.686.874-1.017.055-.048.121-.104.196-.166l.11-.091c.314-.26.722-.598 1.003-.897.482-.512.954-1.24.954-2.255 0-3.027-2.505-4.593-4.706-4.657-1.124-.033-2.323.298-3.262 1.113-.97.842-1.532 2.087-1.532 3.603h3zm-.5 8.5c0-.75.667-1.5 2-1.5s2 .75 2 1.5-.667 1.5-2 1.5-2-.75-2-1.5z"
      fill="#000"
    />
  </Icon>
)

export default IconHelp32
