// @flow
import { OCCUPATION_LIST } from '../constants/occupation'

// schemas.
import captureClientSchema, {
  FORM_ID as captureClientId,
} from '../components/organisms/CreateQuote/components/CaptureClient/captureClient.schema'

export const submitClientDetails = (state: Object, actions: Object) => {
  const {
    occupation: { occupationRating },
  } = state
  let occupationData = {}
  if (occupationRating.attributes) {
    const occupations = OCCUPATION_LIST.filter(
      occupation => occupationRating.attributes[occupation]
    )
    const {
      attributes: { OCCP_CODE },
    } = occupationRating
    occupationData = {
      occupation: occupationRating.text ? occupationRating.text : occupationRating.attributes.text,
      occupationalRating: occupationRating.attributes,
      occupationClassCode: [
        {
          benefit: occupations,
          code: occupations.map(occupation => occupationRating.attributes[occupation]),
        },
      ],
      occupationRating: OCCP_CODE,
      occupationCode: OCCP_CODE,
    }
  }
  // callback to update createQuote reducer if the form validation was successful.
  const updateMemberMandatories = formFields => {
    actions.updateMandatoryDetails({ ...formFields, ...occupationData })
    actions.nextPanel()
  }
  if (
    state.createQuote &&
    state.createQuote.quotes &&
    !state.createQuote.quotes[state.createQuote.activeIndex].quoteName
  ) {
    actions.setQuoteName(`Quote# ${state.createQuote.activeIndex + 1}`)
  }
  actions.formSubmit(captureClientId, captureClientSchema, updateMemberMandatories)
  actions.formSubmitComplete(captureClientId)
}
