import { mediaQueries, colours } from '../../../styles'

const styles = {
  base: {
    position: 'relative',
  },
  tabs: (spaceEvenly, size) => ({
    width: '100%',
    listStyle: 'none',
    flexDirection: 'column',
    display: 'flex',
    '& > *': {
      display: 'block',
      width: '100%',
    },
    [mediaQueries.md]: {
      flexDirection: 'row',
      borderBottom: size === 'sm' ? `1px solid ${colours.lightGrey}` : 'none',
      justifyContent: spaceEvenly ? 'space-evenly' : 'inherit',
      '& > *': {
        width: spaceEvenly ? '100%' : 'auto',
      },
    },
  }),
}

export default styles
