// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { Button, Modal } from '@mlcl-digital/mlcl-design'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from '../../../../../actions'

// components
import WithLoader from '../../../../molecules/WithLoader'

// icons
import { IconChevronRight16 } from '../../../../atoms/Icons'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../../../utils/sitecoreUtils'
import {
  getLifeInsuredFromPolicies,
  getChildCIBenefitAssured,
} from '../../../../../utils/policyUtils'
import { CLIENT_SIDE_DATE_FORMAT } from '../../../../../utils/quoteUtils'
import history from '../../../../../utils/browserHistory'

import { DASHBOARD_ROUTE } from '../../../../../constants/routes'

// styles.
import styles from './requestManualQuote.styles'
import { processAfterFetchedPolicies } from './utils/processAfterFetchedPolicies'
import { processAfterEscalationsApiResponse } from './utils/processAfterEscalationsApiResponse'
import { processAfterApisSucceed } from './utils/processAfterApisSucceed'

export type RequestManualQuoteProps = {
  fields: Object<Object>,
  actions: Object,
  // the selectLifeAssued is using in the utils function processAfterApisSucceed
  // the linting error is likely a wrong detection
  // eslint-disable-next-line react/no-unused-prop-types
  selectLifeAssued: Function,
  lifeAssuredList: Array,
  benefitsAssured: Array,
  alterations: Object,
  isPoliciesLoading: boolean,
  isLoadingClient: boolean,
  existingClient: string,
}

const ResultsHeading = styled('div')(styles.resultsHeading)
export const LifeInsured = styled('div')(styles.lifeInsured)
const Arrow = styled(IconChevronRight16)(styles.arrow)
const DateOfBirth = styled('span')(styles.dateOfBirth)

export class RequestManualQuote extends Component<RequestManualQuoteProps> {
  componentDidUpdate(prevProps: Object) {
    // after policies are fetched
    processAfterFetchedPolicies(prevProps, this.props)

    // after escalations api response
    processAfterEscalationsApiResponse(prevProps, this.props)

    // alteration rules and product rules API are in parallel, we need to
    // make sure that both APIs are finished successfully before moving to next screen
    processAfterApisSucceed(prevProps, this.props)
  }

  setExistingClient = (bancsCustomerNo, bancsPolicyNo) => () => {
    const {
      actions: {
        setExistingClientDetails,
        prePopulateMandatoriesWithExistingClient,
        setLifeAssuredAndPolicy,
        getAlterationPolicies,
        setAlterationTypeSelectedByUser,
        setAlterationTypeForAlterationsRoute,
        resetAlterations,
        getBetaTestingFeaturesForAdviser,
      },
    } = this.props
    setExistingClientDetails(bancsCustomerNo)
    prePopulateMandatoriesWithExistingClient(bancsCustomerNo)
    setLifeAssuredAndPolicy(bancsCustomerNo, bancsPolicyNo)
    resetAlterations()
    getAlterationPolicies(bancsCustomerNo)
    // Reset flag in store empty to flush out any value from previous selection
    setAlterationTypeSelectedByUser('')
    setAlterationTypeForAlterationsRoute('')
    getBetaTestingFeaturesForAdviser()
  }

  renderLifeInsuredList = (lifeAssuredList, benefitsAssured) =>
    lifeAssuredList.map(lifeAsured => {
      const name = `${get(lifeAsured, 'relatedParty.firstName', '')} ${get(
        lifeAsured,
        'relatedParty.lastName',
        ''
      )}`
      const bancsCustomerNo = get(lifeAsured, 'bancsCustomerNo', '')
      const bancsPolicyNo = get(lifeAsured, 'bancsPolicyNo', '')
      const dob = get(lifeAsured, 'relatedParty.dateOfBirth', '')
      return (
        !benefitsAssured.includes(bancsCustomerNo) && (
          <LifeInsured
            onClick={this.setExistingClient(bancsCustomerNo, bancsPolicyNo, lifeAsured)}
            key={bancsPolicyNo}
            role="option"
          >
            <span>{name}</span>
            <DateOfBirth>{dob && moment(dob).format(CLIENT_SIDE_DATE_FORMAT)}</DateOfBirth>
            <Arrow />
          </LifeInsured>
        )
      )
    })

  onModalClose = () => {
    const {
      actions: { resetAlterations, closeSidebar },
    } = this.props
    resetAlterations()
    closeSidebar()
    history.push(DASHBOARD_ROUTE)
  }

  onTryAgain = () => {
    const {
      actions: { getAlterationPolicies, resetAlterations },
      existingClient,
    } = this.props
    resetAlterations()
    getAlterationPolicies(existingClient)
  }

  renderRetryModal() {
    const {
      alterations: { isPoliciesFetchError },
      fields,
    } = this.props
    const { ModalCTA, ModalDesc, ModalTitle } = reduceAuthorableFields(fields)
    return (
      <Modal isOpen={isPoliciesFetchError} onClose={this.onModalClose} title={ModalTitle}>
        <p>{renderTextField(ModalDesc)}</p>
        <Button type="secondary" onClick={this.onTryAgain}>
          {ModalCTA}
        </Button>
      </Modal>
    )
  }

  render() {
    const {
      fields,
      lifeAssuredList,
      benefitsAssured,
      alterations,
      isPoliciesLoading,
      isLoadingClient,
    } = this.props

    const { SelectLITitle } = reduceAuthorableFields(fields)
    const loaderProps = {
      type: 'panel',
    }
    return (
      <WithLoader
        loaderProps={loaderProps}
        isLoading={
          alterations.isFetchingPolicies ||
          alterations.isFetchingRules ||
          isPoliciesLoading ||
          isLoadingClient ||
          alterations.escalationAPICount > 0 ||
          alterations.productRulesAPICount > 0
        }
      >
        {lifeAssuredList && lifeAssuredList.length ? (
          <div>
            <ResultsHeading>{SelectLITitle}</ResultsHeading>
            <div>{this.renderLifeInsuredList(lifeAssuredList, benefitsAssured)}</div>
            {this.renderRetryModal()}
          </div>
        ) : null}
      </WithLoader>
    )
  }
}

export const mapStateToProps = ({ policies, alterations, client }) => ({
  lifeAssuredList: getLifeInsuredFromPolicies(policies.data),
  benefitsAssured: getChildCIBenefitAssured(policies.data),
  alterations,
  isPoliciesLoading: policies.isLoading,
  isLoadingClient: client.isLoading,
  existingClient: client.existingClient,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestManualQuote)
