// @flow
import Cookie from 'cookie'
import UniversalCookie from 'universal-cookie'

// constants.
import {
  BANCS_CUSTOMER_NUM_COOKIE_NAME,
  THIRD_PARTY_QUOTE_COOKIE_NAME,
  THIRD_PARTY_SOURCE,
} from '../constants/bancs'
import { OKTA_COOKIE_NAME, OKTA_SCOPE_NAME } from '../constants/okta'
import { isBrowser } from './browserUtils'
import { ADVISER_NO_KEY } from '../constants/adviser'

export const universalCookie = new UniversalCookie()

let bancsCustomerNo

export const setBancsCustomerNo = (bancsCustomerId: string) => {
  bancsCustomerNo = bancsCustomerId || null
}

export const getBancsCustomerNumber = (): string =>
  bancsCustomerNo || universalCookie.get(BANCS_CUSTOMER_NUM_COOKIE_NAME)

/**
 * returns adviser token after login
 */

export const getToken = (): string => universalCookie.get(OKTA_COOKIE_NAME)

export const getScope = (): string => universalCookie.get(OKTA_SCOPE_NAME)

export const removeAllCookies = (perserveThirdPartyCookies: Boolean): void => {
  const allCookies = Cookie.parse(document.cookie)
  Object.keys(allCookies)
    .filter(key => {
      // If the user has an invalid token and we're cleaning the slate we still want to keep
      // these cookies to preserve third party flow
      if (perserveThirdPartyCookies) {
        return ![THIRD_PARTY_QUOTE_COOKIE_NAME, THIRD_PARTY_SOURCE].includes(key)
      }
      return key
    })
    .forEach((key: string) => {
      universalCookie.remove(key)
    })
}

export const decodeCookie = key => {
  let decodedCookie = null
  const getCookie = universalCookie.get(key)
  if (isBrowser() && getCookie) {
    decodedCookie = window.atob(getCookie)
  }

  return decodedCookie
}

export const setAdviserNo = (value: String): void => {
  universalCookie.set(ADVISER_NO_KEY, value, {
    secure: true,
  })
}

export const getAdviserNo = (): string => universalCookie.get(ADVISER_NO_KEY)

export const removeAdviserNo = () => {
  universalCookie.remove(ADVISER_NO_KEY)
}
