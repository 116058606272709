// helpers.
import { required } from '../../../utils/formUtils'

export const FORM_ID = 'applicationBenificiaries'

const schema = {
  beneficiaryType: {
    condition: required,
  },
}

export default schema
