export const CC = 'CC'
export const DD = 'DD'
export const CHEQUE = 'CQ'
export const BPAY = 'BP'
export const WRAP = 'WRAP'
export const ELIGIBLE_MLC_ACCOUNT = 'ME'
export const ROLLOVER_FROM_EXTERNAL_FUND = 'ER'
export const MASTER_KEY_ACCOUNT = 'EMA'

export const ROLLOVER_DISCLAIEMER_NAME = 'rolloverAuthority'

export const BANCS_POLICY_NO = 'BANCS_POLICY_NO'

export const IS_POLICY_PAYER_YES_LABEL = 'Yes'
export const IS_POLICY_PAYER_NO_LABEL = 'No'

export const PAYMENT_INSTRUCTION_PURPOSE = 'PREMIUM'
export const PAYMENT_INSTRUCTION_COLLECTION_BASIS = 'IN_ARREAR'
export const PAYMENT_INSTRUCTION_INSTRUMENT_TYPE = 'REGULAR'

export const TOTAL_DUE = 'Total to pay'
export const MAKE_PARTIAL_PAYMENT = 'Make a partial payment'

export const CREDIT_CARD_INPUT_LABEL = 'Credit card details'
export const CHOOSE_PAYMENT_METHOD = 'Choose payment method'

export const TRANSACTION_REFERENCE = 'Transaction reference:'
export const AMOUNT_PAID = 'Amount paid'
export const DATE = 'Date'
export const PAYMENT_METHOD = 'Payment method'
export const DOWNLOAD_RECEIPT = 'Download receipt'
