// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { actionCreators } from '../../../../../../actions'

// components
import Button from '../../../../../atoms/Button'

// styles
import styles from './tabHeader.styles'

// util
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// styled component
const TabsWrapper = styled('ul')(styles.tabsWrapper)
const TabItem = styled('li')(styles.tab)

type tabHeaderProps = {
  // An object containing action creator functions.
  actions: Object<Function>,
  activeTab?: 1 | 2,
  fields: Object<Function>,
}

type tabProps = {
  title: string,
  isActive: boolean,
  clickHandler: Function<Function>,
}

export const Tab = ({ title, clickHandler, isActive }: tabProps) => {
  const TabButton = styled(Button)(styles.button(!!isActive))
  return (
    <TabItem>
      <TabButton type="secondary" onClick={clickHandler}>
        {renderTextField(title)}
      </TabButton>
    </TabItem>
  )
}

export class TabHeader extends Component<tabHeaderProps> {
  state = {}

  render() {
    const {
      actions: { gotoPanel },
      activeTab,
      fields: { preAssessmentTabLabel, preAssessmentLoadingsTabLabel },
    } = this.props

    return (
      <TabsWrapper>
        <Tab
          title={preAssessmentTabLabel}
          clickHandler={() => gotoPanel(0)}
          isActive={activeTab === 1}
        />
        <Tab
          title={preAssessmentLoadingsTabLabel}
          clickHandler={() => gotoPanel(1)}
          isActive={activeTab === 2}
        />
      </TabsWrapper>
    )
  }
}

TabHeader.defaultProps = {
  activeTab: 1,
}

export const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(TabHeader)
