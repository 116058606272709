import get from 'lodash/get'

import { getActivePolicy } from '../../../../../../utils/quoteUtils'

export const directDebitData = props => {
  const {
    form,
    createQuote: { quotes, activeIndex },
    sidebar,
  } = props
  const { policyStructure } = quotes[activeIndex]
  const activePolicyInstanceNo = getActivePolicy(sidebar)
  const activePolicy = policyStructure.find(
    policy => policy.policyInstanceNo === activePolicyInstanceNo
  )
  // values for fields in form to be passed during form initialization
  return {
    accountName: get(
      activePolicy,
      'paymentInstruction.debitCardDetails.accountName',
      get(form, 'fields.accountName.value', '')
    ),
    accountNumber: get(
      activePolicy,
      'paymentInstruction.debitCardDetails.accountNo',
      get(form, 'fields.accountNumber.value', '')
    ),
    directDebitBsb: get(
      activePolicy,
      'paymentInstruction.debitCardDetails.bsb',
      get(form, 'fields.directDebitBsb.value', '')
    ),
    paymentDrawDate: get(
      activePolicy,
      'paymentInstruction.paymentDrawDay',
      get(form, 'fields.paymentDrawDate.value', '')
    ),
    termsAndConditions: get(form, 'fields.termsAndConditions.value', ''),
  }
}
