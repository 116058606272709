// @flow
import React, { type Node } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { Loader } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './navItem.styles'
import { themeConsumer } from '../../../styles/ThemeContext'
import { getClassnameProp } from '../../../utils/stylesUtils'

type NavItemProps = {
  /** sets the routing path  */
  href?: string,
  /** Sets the content for the link  */
  children: Node,
  // Function to fire on click of link.
  onClick?: Function,
  // show spinner and disabled the button
  isLoading?: boolean,
  // Loader props
  loaderProps?: Object,
}

// styles

const Container = styled('li')(styles.container)
const Link = styled(NavLink)(styles.link)
const Spinner = styled(Loader)(styles.spinner)

export const NavItem = (props: NavItemProps): Node => {
  const { href, children, onClick, location, isLoading, loaderProps } = props
  return (
    <Container {...getClassnameProp(props)}>
      {isLoading ? (
        <Spinner {...loaderProps} />
      ) : (
        <Link onClick={onClick} to={href || location.pathname}>
          <span>{children}</span>
        </Link>
      )}
    </Container>
  )
}

NavItem.defaultProps = {
  href: '',
  onClick: null,
  isLoading: false,
  loaderProps: {
    spinnerSize: 20,
    borderSize: 2,
  },
}

export default withRouter(themeConsumer(NavItem, 'navigation'))
