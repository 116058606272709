// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import Heading from '../../../../../../atoms/Heading'
import ActionableInput from '../../../../../../molecules/ActionableInput'
import { IconEdit16, IconClose16, IconTick16 } from '../../../../../../atoms/Icons'
import DeleteItem from '../DeleteItem'

// data locators.
import {
  LOCATOR_EDIT_TEMPLATE_INPUT,
  LOCATOR_EDIT_TEMPLATE,
  LOCATOR_DELETE_TEMPLATE,
  LOCATOR_RENAME_TEMPLATE,
} from '../../yourTemplates.locators'

// utils.
import { reduceAuthorableFields } from '../../../../../../../utils/sitecoreUtils'

// styles.
import styles from './editItem.styles'
import { getThemeProp } from '../../../../../../../styles/ThemeContext'

export const Item = styled('a')(({ theme, first, isEditable }) =>
  styles.base(theme, first, isEditable)
)
const Title = styled(Heading)(styles.title)
export const EditIcon = styled(IconEdit16)(styles.edit)
export const CloseIcon = styled(IconClose16)(styles.close)
const TickIcon = styled(IconTick16)(styles.tick)

type TemplateItemProps = {
  // The templateId of the template
  templateId: Number,
  // title of nav item
  title: boolean,
  // A function to be fired when edit icon is clicked
  onUpdateHandler: Function,
  // A function to be fired when close icon is clicked
  onDeleteHandler: Function,
  // check template is duplicate
  duplicateCheck: Function,
  // template item data
  itemData: Object<Object>,
  // sitecore fields
  fields: Object<Object>,
}

type TemplateItemState = {
  // disable update button on error, empty
  disableUpdateAction: boolean,
  // check input is empty
  isInputEmpty: boolean,
  // show/hide delete template modal
  isDeleteModalOpen: boolean,
  // is input in edit mode
  isEditInputActive: boolean,
  // input value
  value: string,
  // template item is duplicate
  isItemDuplicate: boolean,
}

class EditTemplateItem extends PureComponent<TemplateItemProps, TemplateItemState> {
  state = {
    disableUpdateAction: false,
    isInputEmpty: false,
    isDeleteModalOpen: false,
    isEditInputActive: false,
    isItemDuplicate: false,
    value: '',
  }

  componentDidMount() {
    const { title } = this.props
    this.setState({ value: title })
  }

  showEditTemplateInput = () =>
    this.setState({
      isEditInputActive: true,
    })

  handleChange = ({ value }) => {
    const { duplicateCheck } = this.props
    // handle value change
    this.setState({
      value,
      isEditInputActive: true,
    })

    // check if item value if blank OR item is isItemDuplicate
    if (value.toLowerCase() === '') {
      this.setState({ isInputEmpty: true, disableUpdateAction: true })
    } else if (duplicateCheck(value)) {
      this.setState({ isItemDuplicate: true, disableUpdateAction: true, isInputEmpty: false })
    } else {
      this.setState({ disableUpdateAction: false })
    }
  }

  handleUpdate = () => {
    const { onUpdateHandler, title } = this.props
    const { value } = this.state

    onUpdateHandler(title, value)
  }

  showDeleteModal = () => this.setState({ isDeleteModalOpen: true })

  hideDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false })
  }

  handleDeleteItem = () => {
    const { onDeleteHandler } = this.props
    const { value } = this.state
    onDeleteHandler(value)
    this.hideDeleteModal()
  }

  render() {
    const { title, templateId, fields } = this.props
    const {
      disableUpdateAction,
      isDeleteModalOpen,
      isEditInputActive,
      value,
      isInputEmpty,
      isItemDuplicate,
    } = this.state
    const { yourTemplatesExistError, yourTemplatesEmptyError } = reduceAuthorableFields(fields)
    const isEmptyError = isInputEmpty && yourTemplatesEmptyError
    const isDuplicateError = isItemDuplicate && yourTemplatesExistError

    return (
      <Fragment>
        <Item isEditable={isEditInputActive} {...getThemeProp(this.props)}>
          {isEditInputActive ? (
            <ActionableInput
              data-locator={LOCATOR_EDIT_TEMPLATE_INPUT}
              name={templateId}
              changeHandler={this.handleChange}
              value={value}
              handleClick={this.handleUpdate}
              noMargin
              icon={<TickIcon data-locator={LOCATOR_RENAME_TEMPLATE} />}
              disableAction={disableUpdateAction}
              error={disableUpdateAction && (isEmptyError || isDuplicateError)}
              caption={disableUpdateAction && (isEmptyError || isDuplicateError)}
            />
          ) : (
            <Fragment>
              <Title size="3">{title}</Title>
              <EditIcon
                data-testid="templateEditIcon"
                data-locator={LOCATOR_EDIT_TEMPLATE}
                onClick={this.showEditTemplateInput}
              />
              <CloseIcon
                data-testid="showDeleteModalIcon"
                data-locator={LOCATOR_DELETE_TEMPLATE}
                onClick={this.showDeleteModal}
              />
            </Fragment>
          )}
        </Item>
        {isDeleteModalOpen && (
          <DeleteItem
            fields={fields}
            showModal={isDeleteModalOpen}
            handleModalClose={this.hideDeleteModal}
            handleDeleteItem={this.handleDeleteItem}
          />
        )}
      </Fragment>
    )
  }
}

export default EditTemplateItem
