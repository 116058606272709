// components.
import SmsfTrustee from './components/SmsfTrustee'
import SmsfDetails from './components/SmsfDetails'
// utils.
import { idMaker } from '../../../utils/formUtils'

const schema = props => [
  {
    title: 'SMSF details',
    id: idMaker('SMSF details'),
    component: SmsfDetails,
    isVisible: true,
    dataLocator: 'SMSF_details',
    props,
  },
  {
    title: 'Trustee details',
    id: idMaker('Trustee details'),
    component: SmsfTrustee,
    isVisible: true,
    dataLocator: 'Trustee_details',
    disabled: !(props.formSmsfDetails && props.formSmsfDetails.isValid),
    props,
  },
]

export default schema
