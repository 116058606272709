/* eslint-disable quote-props */
// @flow
import React from 'react'
import styled from '@emotion/styled'

// styles.
import headings from '../../../styles/headings'

type HeadingProps = {
  // Heading style size.
  size: '1' | '2' | '3' | '4' | '5' | '6',
  // Heading html element size.
  element?: string | null,
}

const Heading = (props: HeadingProps) => {
  const { element, size } = props
  const H = styled(`h${element || size}`)(element ? headings[`h${size}`] : null)
  return <H {...props} />
}

Heading.defaultProps = {
  element: null,
}

export default Heading
