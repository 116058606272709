export const CREATE_QUOTE_UPDATE_BENEFICIARIES = 'CREATE_QUOTE_UPDATE_BENEFICIARIES'
export const CREATE_QUOTE_ADD_BENEFIT_TO_POLICY = 'CREATE_QUOTE_ADD_BENEFIT_TO_POLICY'
export const CREATE_QUOTE_ADD_POLICY = 'CREATE_QUOTE_ADD_POLICY'
export const CREATE_QUOTE_ADD_POLICY_OWNER = 'CREATE_QUOTE_ADD_POLICY_OWNER'
export const CREATE_QUOTE_UPDATE_POLICY_OWNER = 'CREATE_QUOTE_UPDATE_POLICY_OWNER'
export const CREATE_QUOTE_REMOVE_POLICY_OWNER = 'CREATE_QUOTE_REMOVE_POLICY_OWNER'
export const CREATE_QUOTE_CALCULATE = 'CREATE_QUOTE_CALCULATE'
export const CREATE_QUOTE_QUICK_QUOTE = 'CREATE_QUOTE_QUICK_QUOTE'
export const CREATE_QUOTE_QUICK_QUOTE_SUCCESS = 'CREATE_QUOTE_QUICK_QUOTE_SUCCESS'
export const CREATE_QUOTE_QUICK_QUOTE_FAILURE = 'CREATE_QUOTE_QUICK_QUOTE_FAILURE'
export const CREATE_QUOTE_CALCULATE_ERROR = 'CREATE_QUOTE_CALCULATE_ERROR'
export const CREATE_QUOTE_CALCULATE_FAILURE = 'CREATE_QUOTE_CALCULATE_FAILURE'
export const CREATE_QUOTE_CALCULATE_SUCCESS = 'CREATE_QUOTE_CALCULATE_SUCCESS'
export const CREATE_QUOTE_CALCULATION_IN_PROGRESS = 'CREATE_QUOTE_CALCULATION_IN_PROGRESS'
export const CREATE_QUOTE_POLICY_WILL_UPDATE = 'CREATE_QUOTE_POLICY_WILL_UPDATE'
export const CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_TRACK =
  'CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_TRACK'
export const CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_POLICY_FEE =
  'CREATE_QUOTE_POLICY_STRUCTURE_MLC_ON_POLICY_FEE'
export const CREATE_QUOTE_SET_FEATURES = 'CREATE_QUOTE_SET_FEATURES'
export const CREATE_QUOTE_SET_FIXED_PAYMENT_FREQUENCY = 'CREATE_QUOTE_SET_FIXED_PAYMENT_FREQUENCY'
export const CREATE_QUOTE_SET_POLICY_STRUCTURE = 'CREATE_QUOTE_SET_POLICY_STRUCTURE'
export const CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME = 'CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME'
export const CREATE_QUOTE_SET_REQUESTED_BY = 'CREATE_QUOTE_SET_REQUESTED_BY'
export const CREATE_QUOTE_SET_ALTERATION_TYPE = 'CREATE_QUOTE_SET_ALTERATION_TYPE'
export const CREATE_QUOTE_SET_ALTERATION_EFFECTIVE_DATE =
  'CREATE_QUOTE_SET_ALTERATION_EFFECTIVE_DATE'

export const CREATE_QUOTE_SET_QUOTE_NAME = 'CREATE_QUOTE_SET_QUOTE_NAME'
export const RESET_TOTAL_PREMIUM_AMOUNT = 'RESET_TOTAL_PREMIUM_AMOUNT'
export const CREATE_QUOTE_UPDATE_BENEFIT_LINK = 'CREATE_QUOTE_UPDATE_BENEFIT_LINK'
export const CREATE_QUOTE_UPDATE_CHILD_DATA = 'CREATE_QUOTE_UPDATE_CHILD_DATA'
export const CREATE_QUOTE_UPDATE_COVER = 'CREATE_QUOTE_UPDATE_COVER'
export const UPDATE_IS_TERM_ACCEPTED_FIELD = 'UPDATE_IS_TERM_ACCEPTED_FIELD'
export const UPDATE_QUOTE_ID_TO_ACTION = 'UPDATE_QUOTE_ID_TO_ACTION'
export const UPDATE_UNDER_WRITING_DETAILS = 'UPDATE_UNDER_WRITING_DETAILS'
export const CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES = 'CREATE_QUOTE_UPDATE_MEMBER_MANDATORIES'
export const CREATE_QUOTE_UPDATE_SECONDARY_MAIL = 'CREATE_QUOTE_UPDATE_SECONDARY_MAIL'
export const CREATE_QUOTE_UPDATE_PAYMENT_DETAILS = 'CREATE_QUOTE_UPDATE_PAYMENT_DETAILS'
export const CREATE_QUOTE_UPDATE_PAYMENT_FREQUENCY = 'CREATE_QUOTE_UPDATE_PAYMENT_FREQUENCY'
export const CREATE_QUOTE_UPDATE_LEGACY_CODE = 'CREATE_QUOTE_UPDATE_LEGACY_CODE'
export const CREATE_QUOTE_UPDATE_SUM_INSURED = 'CREATE_QUOTE_UPDATE_SUM_INSURED'
export const CREATE_QUOTE_UPDATE_FEATURE_STATUS = 'CREATE_QUOTE_UPDATE_FEATURE_STATUS'
export const REMOVE_PARENT_BENEFIT_REFERENCE = 'REMOVE_PARENT_BENEFIT_REFERENCE'
export const CREATE_QUOTE_UPDATE_MEMBER_DETAILS = 'CREATE_QUOTE_UPDATE_MEMBER_DETAILS'
export const CREATE_QUOTE_DELETE_MEMBER_DETAILS = 'CREATE_QUOTE_DELETE_MEMBER_DETAILS'
export const CREATE_QUOTE_UPDATE_ROLE_OF_MEMBER = 'CREATE_QUOTE_UPDATE_ROLE_OF_MEMBER'
export const CREATE_QUOTE_UPDATE_COVERS = 'CREATE_QUOTE_UPDATE_COVERS'
export const CREATE_QUOTE_GET_CALCULATION_IN_PROGRESS = 'CREATE_QUOTE_GET_CALCULATION_IN_PROGRESS'
export const CREATE_QUOTE_GET_CALCULATE = 'CREATE_QUOTE_GET_CALCULATE'
export const CREATE_QUOTE_GET_CALCULATE_ERROR = 'CREATE_QUOTE_GET_CALCULATE_ERROR'
export const CREATE_QUOTE_GET_CALCULATE_FAILURE = 'CREATE_QUOTE_GET_CALCULATE_FAILURE'
export const CREATE_QUOTE_CALCULATE_QUOTE_ERROR_MODAL = 'CREATE_QUOTE_CALCULATE_QUOTE_ERROR_MODAL'
export const CREATE_QUOTE_GET_CALCULATE_SUCCESS = 'CREATE_QUOTE_GET_CALCULATE_SUCCESS'
export const CREATE_QUOTE_REMOVE_BENEFIT = 'CREATE_QUOTE_REMOVE_BENEFIT'
export const CREATE_QUOTE_DUPLICATE_TEMPLATE = 'CREATE_QUOTE_DUPLICATE_TEMPLATE'
export const CREATE_QUOTE_DELETE_FROM_COLLECTION = 'CREATE_QUOTE_DELETE_FROM_COLLECTION'
export const UPDATE_QUOTE_ACTIVE_INDEX = 'UPDATE_QUOTE_ACTIVE_INDEX'
export const REMOVE_ACTION_AGREEMENT_CONSENTS = 'REMOVE_ACTION_AGREEMENT_CONSENTS'
export const UPDATE_QUOTE_ENTITY_MY_LINK_DETAILS = 'UPDATE_QUOTE_ENTITY_MY_LINK_DETAILS'
export const RESET_UPDATE_QUOTE_COLLECTION_SUCCESS_FLAG =
  'RESET_UPDATE_QUOTE_COLLECTION_SUCCESS_FLAG'
export const UPDATE_QUOTE_COLLECTION = 'UPDATE_QUOTE_COLLECTION'
export const UPDATE_QUOTE_COLLECTION_SUCCESS = 'UPDATE_QUOTE_COLLECTION_SUCCESS'
export const UPDATE_QUOTE_COLLECTION_ERROR = 'UPDATE_QUOTE_COLLECTION_ERROR'
export const UPDATE_QUOTE_COLLECTION_FAILURE = 'UPDATE_QUOTE_COLLECTION_FAILURE'
export const DELETE_QUOTE_COLLECTION = 'DELETE_QUOTE_COLLECTION'
export const DELETE_QUOTE_COLLECTION_SUCCESS = 'DELETE_QUOTE_COLLECTION_SUCCESS'
export const DELETE_QUOTE_COLLECTION_ERROR = 'DELETE_QUOTE_COLLECTION_ERROR'
export const DELETE_QUOTE_COLLECTION_INIT = 'DELETE_QUOTE_COLLECTION_INIT'
export const UPDATE_APPLICATION_STATUS = 'UPDATE_APPLICATION_STATUS'
export const DELETE_QUOTE_COLLECTION_FAILURE = 'DELETE_QUOTE_COLLECTION_FAILURE'
export const UPDATE_ADVISER_VERIFICATION_CONSENTS = 'UPDATE_ADVISER_VERIFICATION_CONSENTS'
export const CREATE_QUOTE_SAVE = 'CREATE_QUOTE_SAVE'
export const CREATE_QUOTE_SAVE_SUCCESS = 'CREATE_QUOTE_SAVE_SUCCESS'
export const CREATE_QUOTE_SAVE_ERROR = 'CREATE_QUOTE_SAVE_ERROR'
export const CREATE_QUOTE_SAVE_FAILURE = 'CREATE_QUOTE_SAVE_FAILURE'
export const CREATE_QUOTE_SAVE_INIT = 'CREATE_QUOTE_SAVE_INIT'
export const CREATE_QUOTE_SHOW_SAVE_MODAL = 'CREATE_QUOTE_SHOW_SAVE_MODAL'
export const CREATE_QUOTE_UPDATE_IS_SAVE = 'CREATE_QUOTE_UPDATE_IS_SAVE'
export const CREATE_QUOTE_SAVE_QUOTE_ERROR_MODAL = 'CREATE_QUOTE_SAVE_QUOTE_ERROR_MODAL'
export const CREATE_QUOTE_SAVE_AND_EXIT_MODAL = 'CREATE_QUOTE_SAVE_AND_EXIT_MODAL'
export const CREATE_QUOTE_CONVERT_COVERS_FEATURES = 'CREATE_QUOTE_CONVERT_COVERS_FEATURES'
export const CREATE_QUOTE_UPDATE_DUTY_OF_DISCLOSURE_CONSENTS =
  'CREATE_QUOTE_UPDATE_DUTY_OF_DISCLOSURE_CONSENTS'
export const CREATE_QUOTE_UPDATE_DECLARATION_AUTHORIZATION_CONSENTS =
  'CREATE_QUOTE_UPDATE_DECLARATION_AUTHORIZATION_CONSENTS'
export const RESET_QUOTE_DECLARATION_CONSENTS = 'RESET_QUOTE_DECLARATION_CONSENTS'
export const CREATE_QUOTE_UPDATE_DECLARATION_PO_CONSENTS =
  'CREATE_QUOTE_UPDATE_DECLARATION_PO_CONSENTS'
export const CREATE_QUOTE_UPDATE_DECLARATION_FOR_ADVISOR_CONSENTS =
  'CREATE_QUOTE_UPDATE_DECLARATION_FOR_ADVISOR_CONSENTS'
export const CREATE_QUOTE_UPDATE_MARKETING_CONSENTS = 'CREATE_QUOTE_UPDATE_MARKETING_CONSENTS'
export const CREATE_QUOTE_UPDATE_UNDERWRITING_OPTIONS_CONSENTS =
  'CREATE_QUOTE_UPDATE_UNDERWRITING_OPTIONS_CONSENTS'
export const CREATE_QUOTE_UPDATE_UNDERWRITING_FOLLOW_UP_TIMING =
  'CREATE_QUOTE_UPDATE_UNDERWRITING_FOLLOW_UP_TIMING'
export const CREATE_QUOTE_DELETE_POLICY = 'CREATE_QUOTE_DELETE_POLICY'
export const FETCH_QUOTE_COLLECTION = 'CREATE_QUOTE/FETCH_QUOTE_COLLECTION'
export const FETCH_QUOTE_COLLECTION_INIT = 'CREATE_QUOTE/FETCH_QUOTE_COLLECTION_INIT'
export const FETCH_QUOTE_COLLECTION_SUCCESS = 'CREATE_QUOTE/FETCH_QUOTE_COLLECTION_SUCCESS'
export const FETCH_QUOTE_COLLECTION_ERROR = 'CREATE_QUOTE/FETCH_QUOTE_COLLECTION_ERROR'
export const FETCH_QUOTE_COLLECTION_FAILURE = 'CREATE_QUOTE/FETCH_QUOTE_COLLECTION_FAILURE'
export const CREATE_QUOTE_UPDATE_MLC_ON_TRACK_CONSENTS = 'CREATE_QUOTE_UPDATE_MLC_ON_TRACK_CONSENTS'
export const CREATE_QUOTE_UPDATE_TMD_CONSENTS = 'CREATE_QUOTE_UPDATE_TMD_CONSENTS'
export const CREATE_QUOTE_ADD_PAYMENT_DETAILS = 'CREATE_QUOTE_ADD_PAYMENT_DETAILS'
export const CREATE_QUOTE_UPDATE_PRINTABLE_ADVISER_DETAILS =
  'CREATE_QUOTE_UPDATE_PRINTABLE_ADVISER_DETAILS'
export const CREATE_QUOTE_UPDATE_EXISTING_COVER = 'CREATE_QUOTE/UPDATE_EXISTING_COVER'
export const CREATE_QUOTE_RESET_QUOTE_ID = 'CREATE_QUOTE_RESET_QUOTE_ID_FROM_QUOTE_ENTITY'
export const CREATE_QUOTE_UPDATE_APPLICATION_TYPE = 'CREATE_QUOTE/UPDATE_APPLICATION_TYPE'
export const UPDATE_BANCS_ADVISER_CUSTOMER_NO = 'UPDATE_BANCS_ADVISER_CUSTOMER_NO'
export const IS_UPDATED_QUOTE = 'IS_UPDATED_QUOTE'
export const CREATE_QUOTE_UPDATE_QUOTE_DATA = 'CREATE_QUOTE/CREATE_QUOTE_UPDATE_QUOTE_DATA'
export const CREATE_ENQUIRY_ID = 'CREATE_ENQUIRY_ID'
export const CREATE_CHILD_ENQUIRY_ID = 'CREATE_CHILD_ENQUIRY_ID'
export const CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY = 'CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY'
export const CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_SUCCESS =
  'CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_SUCCESS'
export const CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_FAILURE =
  'CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_FAILURE'
export const CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_ERROR = 'CREATE_QUOTE_GET_OUTCOME_OF_ENQUIRY_ERROR'
export const CREATE_ENQUIRY_ID_INIT = 'CREATE_ENQUIRY_ID_INIT'
export const CREATE_ENQUIRY_ID_SUCCESS = 'CREATE_ENQUIRY_ID_SUCCESS'
export const CREATE_CHILD_ENQUIRY_ID_INIT = 'CREATE_CHILD_ENQUIRY_ID_INIT'
export const CREATE_CHILD_ENQUIRY_ID_SUCCESS = 'CREATE_CHILD_ENQUIRY_ID_SUCCESS'
export const CREATE_CHILD_ENQUIRY_ID_ERROR = 'CREATE_CHILD_ENQUIRY_ID_ERROR'
export const CREATE_CHILD_ENQUIRY_ID_FAILURE = 'CREATE_CHILD_ENQUIRY_ID_FAILURE'
export const CREATE_ENQUIRY_ID_FAILURE = 'CREATE_ENQUIRY_ID_FAILURE'
export const CREATE_ENQUIRY_ID_ERROR = 'CREATE_ENQUIRY_ID_ERROR'
export const UPDATE_ENQUIRY_ID_URE = 'UPDATE_ENQUIRY_ID_URE'
export const REMOVE_ENQUIRY_ID_URE = 'REMOVE_ENQUIRY_ID_URE'
export const CREATE_QUOTE_UPDATE_ADVISER_AUTHORITY_CONSENTS =
  'CREATE_QUOTE_UPDATE_ADVISER_AUTHORITY_CONSENTS'
export const SET_SAVE_QUOTE_INITIATOR = 'SET_SAVE_QUOTE_INITIATOR'
export const CREATE_QUOTE_UPDATE_POLICY_STRUCTURE = 'CREATE_QUOTE_UPDATE_POLICY_STRUCTURE'
export const RESET_CREATE_QUOTE = 'RESET_CREATE_QUOTE'
export const ADD_BUSINESS_AS_PAYER = 'CREATE_QUOTE/ADD_BUSINESS_AS_PAYER'
export const UPDATE_QUOTE_STATUS = 'UPDATE_QUOTE_STATUS'
export const CREATE_QUOTE_APPLY_LOADINGS = 'CREATE_QUOTE/CREATE_QUOTE_APPLY_LOADINGS'
export const CREATE_QUOTE_REMOVE_APPLIED_LOADINGS =
  'CREATE_QUOTE/CREATE_QUOTE_REMOVE_APPLIED_LOADINGS'
export const CREATE_QUOTE_APPLY_EXCLUSIONS = 'CREATE_QUOTE/CREATE_QUOTE_APPLY_EXCLUSIONS'
export const CREATE_QUOTE_REMOVE_APPLIED_EXCLUSION =
  'CREATE_QUOTE/CREATE_QUOTE_REMOVE_APPLIED_EXCLUSION'
export const CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS = 'CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS'
export const CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_SUCCESS =
  'CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_SUCCESS'
export const CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_FAILURE =
  'CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_FAILURE'
export const CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_ERROR =
  'CREATE_QUOTE_ADD_COMMISSION_SPLIT_DETAILS_ERROR'
export const CREATE_QUOTE_APPLY_GENETIC_TEST_RESULT =
  'CREATE_QUOTE/CREATE_QUOTE_APPLY_GENETIC_TEST_RESULT'
export const CREATE_QUOTE_UPDATE_DIRECTOR_DETAILS = 'CREATE_QUOTE/CREATE_OR_UPDATE_DIRECTOR_DETAILS'
export const UPDATE_POLICY_COMMENCEMENT_DATE = 'UPDATE_POLICY_COMMENCEMENT_DATE'
export const CREATE_QUOTE_CHANGE_SMOKER_STATUS = 'CREATE_QUOTE/CREATE_QUOTE_CHANGE_SMOKER_STATUS'
export const UPDATE_URE_DETAIL_TO_UNDERWRITING_ENTITY =
  'CREATE_QUOTE/UPDATE_URE_DETAIL_To_UNDERWRITING_ENTITY'
export const ADD_NULIS = 'ADD_NULIS'
export const ADD_OWNER_FOR_NULIS_WRAPPER = 'ADD_OWNER_FOR_NULIS_WRAPPER'
export const ADD_TFN = 'ADD_TFN'
export const UPDATE_WORKTYPE_HISTORY = 'CREATE_QUOTE/UPDATE_WORKTYPE_HISTORY'
export const DELETE_WORKTYPE_HISTORY = 'CREATE_QUOTE/DELETE_WORKTYPE_HISTORY'
export const UPDATE_IS_TELE_FROM_ADVISER = 'UPDATE_IS_TELE_FROM_ADVISER'
export const CREATE_QUOTE_UPDATE_POLICY_NAME = 'CREATE_QUOTE/UPDATE_POLICY_NAME'
export const CREATE_QUOTE_UPDATE_SMSF_DETAILS = 'CREATE_QUOTE/UPDATE_SMSF_DETAILS'
export const QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL = 'QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL'
export const QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_INIT =
  'QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_INIT'
export const QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_SUCCESS =
  'QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_SUCCESS'
export const QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_FAILURE =
  'QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_FAILURE'
export const QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_ERROR =
  'QUOTE_TEMPLATE_FETCH_QUOTE_FOR_MANUAL_ERROR'
export const CREATE_QUOTE_UPDATE_CALCULATED_URE_STATUS = 'CREATE_QUOTE_UPDATE_CALCULATED_URE_STATUS'
export const RESET_CREATE_AND_SAVE_QUOTE_ERROR_MODAL = 'RESET_CREATE_AND_SAVE_QUOTE_ERROR_MODAL'
export const CREATE_QUOTE_UPDATE_TELE_CUSTOMER_TERM_CONSENTS =
  'CREATE_QUOTE_UPDATE_TELE_CUSTOMER_TERM_CONSENTS'
export const CREATE_QUOTE_REDIRECT = 'CREATE_QUOTE_REDIRECT'

export const UPDATE_POLICY_REPLACE_STATUS = 'UPDATE_POLICY_REPLACE_STATUS'
export const CREATE_QUOTE_PUSH_EXISTING_COVER = 'CREATE_QUOTE_PUSH_EXISTING_COVER'
export const CREATE_QUOTE_UPDATE_TPD_DEFINATION = 'CREATE_QUOTE_UPDATE_TPD_DEFINATION'
export const CREATE_QUOTE_UPDATE_IS_NON_STANDARD = 'CREATE_QUOTE_UPDATE_IS_NON_STANDARD'
export const CREATE_QUOTE_UPDATE_BMI = 'CREATE_QUOTE_UPDATE_BMI'
export const CREATE_QUOTE_SET_IS_SATISFIED = 'CREATE_QUOTE_SET_IS_SATISFIED'
export const UPDATE_IS_TERMS_ACCEPTED_ON_POLICY_COVER = 'UPDATE_IS_TERMS_ACCEPTED_ON_POLICY_COVER'
export const CREATE_QUOTE_UPDATE_LA_CONTACT_DETAILS = 'CREATE_QUOTE_UPDATE_LA_CONTACT_DETAILS'
export const CREATE_QUOTE_ADD_OPTIMISER_REFERENCE = 'CREATE_QUOTE_ADD_OPTIMISER_REFERENCE'
export const CREATE_QUOTE_REMOVE_OPTIMISER_REFERENCE = 'CREATE_QUOTE_REMOVE_OPTIMISER_REFERENCE'
export const CREATE_QUOTE_REMOVE_EMPTY_POLICY = 'CREATE_QUOTE_REMOVE_EMPTY_POLICY'
export const CREATE_QUOTE_UPDATE_LINKED_OPTIMISER = 'CREATE_QUOTE_UPDATE_LINKED_OPTIMISER'
export const CREATE_QUOTE_UPDATE_LINKED_BENEFITS = 'CREATE_QUOTE_UPDATE_LINKED_BENEFITS'

export const CHECK_QUOTE_ACTION_STATUS = 'CHECK_QUOTE_ACTION_STATUS'
export const CHECK_QUOTE_ACTION_STATUS_SUCCESS = 'CHECK_QUOTE_ACTION_STATUS_SUCCESS'
export const CHECK_QUOTE_ACTION_STATUS_ERROR = 'CHECK_QUOTE_ACTION_STATUS_ERROR'
export const CHECK_QUOTE_ACTION_STATUS_FAILURE = 'CHECK_QUOTE_ACTION_STATUS_FAILURE'
export const CHECK_QUOTE_ACTION_STATUS_INIT = 'CHECK_QUOTE_ACTION_STATUS_INIT'
export const CREATE_QUOTE_UPDATE_QUOTE_COLLECTION = 'CREATE_QUOTE_UPDATE_QUOTE_COLLECTION'
export const CREATE_QUOTE_CLEAR_POLICY_SETUP_DATA = 'CREATE_QUOTE_CLEAR_POLICY_SETUP_DATA'
export const PRE_POPULATE_MANDATORIES = 'PRE_POPULATE_MANDATORIES'
export const PRE_POPULATE_MANDATORIES_INIT = 'PRE_POPULATE_MANDATORIES_INIT'
export const PRE_POPULATE_MANDATORIES_FAILURE = 'PRE_POPULATE_MANDATORIES_FAILURE'
export const PRE_POPULATE_MANDATORIES_SUCCESS = 'PRE_POPULATE_MANDATORIES_SUCCESS'
export const CLEAR_PRE_EXISTING_MANDATORIES = 'CLEAR_PRE_EXISTING_MANDATORIES'

export const UPDATE_SUBMITTED_DATE = 'UPDATE_SUBMITTED_DATE'
export const UPDATE_MEMBER_MANDATORIES = 'UPDATE_MEMBER_MANDATORIES'
export const CREATE_QUOTE_SET_QUOTE_COLLECTION_ID = 'CREATE_QUOTE_SET_QUOTE_COLLECTION_ID'
export const CREATE_QUOTE_SET_ADVISER_NUMBER = 'CREATE_QUOTE_SET_ADVISER_NUMBER'
export const CREATE_QUOTE_SET_APPLICATION_DETAILS = 'CREATE_QUOTE_SET_APPLICATION_DETAILS'
export const CREATE_QUOTE_SET_QUOTE_CUSTOMER_NO = 'CREATE_QUOTE_SET_QUOTE_CUSTOMER_NO'
export const UPDATE_CONTACT_METHODS_OF_MEMBER = 'UPDATE_CONTACT_METHODS_OF_MEMBER'
export const CREATE_QUOTE_ADD_FEATURE_AS_SUB_BENEFIT = 'CREATE_QUOTE_ADD_FEATURE_AS_SUB_BENEFIT'
export const CREATE_QUOTE_DELETE_FEATURE_AS_SUB_BENEFIT =
  'CREATE_QUOTE_DELETE_FEATURE_AS_SUB_BENEFIT'
export const ALTER_SUM_INSURED = 'ALTER_SUM_INSURED'
export const ALTS_CALCULATE_QUOTE = 'ALTS_CALCULATE_QUOTE'
export const ALTS_CALCULATE_QUOTE_INIT = 'ALTS_CALCULATE_QUOTE_INIT'
export const ALTS_CALCULATE_QUOTE_SUCCESS = 'ALTS_CALCULATE_QUOTE_SUCCESS'
export const ALTS_CALCULATE_QUOTE_FAILURE = 'ALTS_CALCULATE_QUOTE_FAILURE'
export const ALTS_CALCULATE_QUOTE_ERROR = 'ALTS_CALCULATE_QUOTE_ERROR'
export const RESET_SUM_INSURED = 'RESET_SUM_INSURED'
export const DELETE_ALTERATIONS_IN_QUOTE = 'DELETE_ALTERATIONS_IN_QUOTE'
export const CHANGE_WAITING_BENEFIT_PERIOD = 'CHANGE_WAITING_BENEFIT_PERIOD'
export const RESET_WAITING_BENEFIT_PERIOD = 'RESET_WAITING_BENEFIT_PERIOD'
export const REMOVE_BENEFIT_OPTION = 'REMOVE_BENEFIT_OPTION'
export const CANCEL_BENEFIT_OPTION = 'CANCEL_BENEFIT_OPTION'
export const ALTS_REMOVE_BENEFIT = 'ALTS_REMOVE_BENEFIT'
export const ALTER_PAYMENT_FREQUENCY = 'ALTER_PAYMENT_FREQUENCY'
export const RESET_PAYMENT_FREQUENCY = 'RESET_PAYMENT_FREQUENCY'
export const CREATE_QUOTE_UPDATE_OPTION = 'CREATE_QUOTE_UPDATE_OPTION'
export const CREATE_QUOTE_UPDATE_HL_CAMPAIGN = 'CREATE_QUOTE_UPDATE_HL_CAMPAIGN'
export const CREATE_QOUTE_SET_HEALTHY_LIVING_DISCOUNT_CONSENT =
  'CREATE_QOUTE_SET_HEALTHY_LIVING_DISCOUNT_CONSENT'
export const CREATE_QOUTE_SET_ADVISER_PRESENTED_LICOP_FLYER_CONSENT =
  'CREATE_QOUTE_SET_ADVISER_PRESENTED_LICOP_FLYER_CONSENT'
export const CREATE_QUOTE_ADD_HEALTHY_LIVING_DISCOUNT_IN_MISSING_REQUIREMENTS =
  'CREATE_QUOTE_ADD_HEALTHY_LIVING_DISCOUNT_IN_MISSING_REQUIREMENTS'
export const UPDATE_ALTS_DOWNLOAD_QUOTE_PROGRESS = 'UPDATE_ALTS_DOWNLOAD_QUOTE_PROGRESS'
export const RESET_STATE_FOR_NEW_QUOTE = 'RESET_STATE_FOR_NEW_QUOTE'
export const UPDATE_ALTS_EFFECTIVE_DATES = 'UPDATE_ALTS_EFFECTIVE_DATES'
export const UPDATE_MEDICAL_AUTHORITY_CONSENTS = 'UPDATE_MEDICAL_AUTHORITY_CONSENTS'
export const UPDATE_DETAILS_FOR_CONTACT = 'UPDATE_DETAILS_FOR_CONTACT'
