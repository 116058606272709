// @flow
import React, { Fragment, useState } from 'react'
import styled from '@emotion/styled'
import { Heading, Button } from '@mlcl-digital/mlcl-design'
import { useDispatch, useSelector } from 'react-redux'
import { createEvent } from '../../../utils/telemetry'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'

// styles.
import styles from '../../organisms/CustomerPersonalDetails/PersonalDetailsForm/customerPersonalDetails.styles'

import ContactDetailsEdit from './ContactDetailsForm'
import { getContactDetailsHasUpdateError } from '../../../selectors/personalDetails'
import { resetCustomerContactDetailsHasUpdateError } from '../../../actions/creators/customerPersonalDetails'

const Header = styled('div')(styles.header)
const Details = styled('div')(styles.details)
const DetailsLabel = styled('div')(styles.label)
export const EditButton = styled(Button)(styles.button)
const ValueHeading = styled('div')(styles.values)

type ContactDetailsProps = {
  fields: Object,
  isManualResidential: Boolean,
  customerContactDetails: Object,
  // welcome journey completed flag
  hasCompletedWelcomeJourney: Boolean,
  isManualPostal: Boolean,
  isPartyTypeOrg: Boolean,
  isAdvisor: Boolean,
  clientId: String,
}

const ContactDetails = (props: ContactDetailsProps) => {
  const {
    fields,
    isManualResidential,
    hasCompletedWelcomeJourney,
    customerContactDetails,
    isManualPostal,
    isPartyTypeOrg,
    isAdvisor,
    clientId,
  } = props

  const [editContactDetails, setEditContactDetails] = useState(!hasCompletedWelcomeJourney)
  const dispatch = useDispatch()
  const customerHasUpdateError = useSelector(state => getContactDetailsHasUpdateError(state))

  const openEditContactDetails = () => {
    // Analytics
    const event = createEvent({
      GA: {
        category: 'Contact details',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Contact details edit',
        },
      },
    })
    event.end()

    customerHasUpdateError && dispatch(resetCustomerContactDetailsHasUpdateError())
    setEditContactDetails(true)
  }

  const closeContactDetailsForm = () => {
    setEditContactDetails(false)
  }

  if (customerContactDetails) {
    const {
      emailAddress,
      contactNumber,
      residentialAddress,
      postalAddress,
      preferredMethodOfComms,
    } = customerContactDetails

    return (
      <Fragment>
        <Header>
          <Heading size="large" variant="h2" styleOverrides={{ marginBottom: '6px' }}>
            {renderTextField(fields.ContactDetailsTitle)}
          </Heading>
          <p>{renderTextField(fields.ContactDetailsSubtitle)}</p>
        </Header>
        {!editContactDetails && (
          <Fragment>
            <Heading size="small" variant="h3" styleOverrides={styles.subHeading}>
              {renderTextField(fields.ContactDetailsSubHeading)}
            </Heading>
            <Details>
              <DetailsLabel>{renderTextField(fields.ContactDetailsPhone)}</DetailsLabel>
              <ValueHeading>{contactNumber}</ValueHeading>
            </Details>
            <Details>
              <DetailsLabel>{renderTextField(fields.ContactDetailsEmailAddress)}</DetailsLabel>
              <ValueHeading style={{ whiteSpace: 'nowrap' }}>{emailAddress}</ValueHeading>
            </Details>
            {!isPartyTypeOrg && (
              <Details>
                <DetailsLabel>
                  {renderTextField(fields.ContactDetailsResidentialAddress)}
                </DetailsLabel>
                <ValueHeading>{residentialAddress}</ValueHeading>
              </Details>
            )}
            <Details>
              <DetailsLabel>
                {renderTextField(fields.ContactDetailsPostalAddressLabel)}
              </DetailsLabel>
              <ValueHeading>{postalAddress || residentialAddress}</ValueHeading>
            </Details>
            <Details>
              <DetailsLabel style={{ whiteSpace: 'nowrap' }}>
                {renderTextField(fields.ContactDetailsPreferredMethodOfComms)}
              </DetailsLabel>
              <ValueHeading>{preferredMethodOfComms}</ValueHeading>
            </Details>
            <Details>
              <EditButton
                data-testid="edit-button-test"
                variant="secondary"
                size="small"
                onClick={() => openEditContactDetails()}
              >
                {renderTextField(fields.ContactDetailsEditButton)}
              </EditButton>
            </Details>
          </Fragment>
        )}
        {editContactDetails && (
          <ContactDetailsEdit
            fields={fields}
            isManualResidential={isManualResidential}
            closeContactDetailsForm={closeContactDetailsForm}
            hasCompletedWelcomeJourney={hasCompletedWelcomeJourney}
            isManualPostal={isManualPostal}
            isPartyTypeOrg={isPartyTypeOrg}
            isAdvisor={isAdvisor}
            clientId={clientId}
          />
        )}
      </Fragment>
    )
  }
  return null
}

export default ContactDetails
