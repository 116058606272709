// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { A11yLabel, Checkbox, Select, Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

import AddressLookUp from '../../../../molecules/AddressLookUp'

// styles.
import styles from './addDirector.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

// schema.
import SCHEMA, { FORM_ID } from './addDirector.schema'
import { FORM_ID as BUSINESS_FORM_ID } from '../BusinessOwnerDetails/businessOwnerDetails.schema'
import { FORM_ID as SMSF_TRUSTEE_FORM_ID } from '../../../Smsf/components/SmsfTrustee/smsfTrustee.schema'

// helpers.
import { errorCheck, generateFieldsFromData, getValue } from '../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { initForms } from './addDirectorInitUtils'
import { POLICY_MEMBER_DIRECTOR } from '../../../../../constants/policies'

type addDirectorProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  businessForm: Object,
  // Sitecore authorable fields
  fields: Object,
  // Address lookup manual entry redux state mapped to props.
  isManualPostal: boolean,
}

export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalAddressStreet',
  'postalAddressHouseNo',
  'postalAddressLocality',
  'postalAddressState',
  'postalAddressCountry',
  'postalAddressPostCode',
]

const FullWidthInput = ignoreColumns(Input)
const HalfWidthInput = styled(Input)(styles.halfColumn)
const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const FullWidthCheckbox = ignoreColumns(Checkbox)

const Form = styled('form')(styles.base)
const Wrapper = styled('div')()
const TitleSelect = styled(Select)(styles.title)
const RoleSelect = styled(Select)(styles.halfColumn)

const SameAsCompanyAddressCheckbox = styled(FullWidthCheckbox)(styles.sameAsCompanyAddressCheckbox)

export class AddDirector extends Component<addDirectorProps> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)

    const {
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldFutureDateError,
      policyOwnerDetailsDateOfBirthFieldFormatError,
      policyOwnerDetailsDateOfBirthFieldAgeRangeError,
      policyOwnerDetailsInvalidFirstNameError,
      policyOwnerDetailsFirstNameFieldError,
      policyOwnerDetailsInvalidLastNameError,
      policyOwnerDetailsLastNameFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldError,
      policyOwnerDetailsResidentialAddressValidStreetFieldError,
      policyOwnerDetailsResidentialAddressLocalityFieldError,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError,
      policyOwnerDetailsResidentialAddressStateFieldError,
      policyOwnerDetailsResidentialAddressValidStateFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError,
      policyOwnerDetailsResidentialAddressPostCodeFieldError,
      policyOwnerDetailsResidentialAddressValidPostalFieldError,
    } = props.fields

    this.schemaWithAuthorableFields = SCHEMA({
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldFutureDateError,
      policyOwnerDetailsDateOfBirthFieldFormatError,
      policyOwnerDetailsDateOfBirthFieldAgeRangeError,
      policyOwnerDetailsInvalidFirstNameError,
      policyOwnerDetailsFirstNameFieldError,
      policyOwnerDetailsInvalidLastNameError,
      policyOwnerDetailsLastNameFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldError,
      policyOwnerDetailsResidentialAddressValidStreetFieldError,
      policyOwnerDetailsResidentialAddressLocalityFieldError,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError,
      policyOwnerDetailsResidentialAddressStateFieldError,
      policyOwnerDetailsResidentialAddressValidStateFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError,
      policyOwnerDetailsResidentialAddressPostCodeFieldError,
      policyOwnerDetailsResidentialAddressValidPostalFieldError,
    })
  }

  componentWillMount() {
    const { actions, isManualPostal, fields } = this.props
    const { formInit } = actions
    const data = initForms(this.props)
    const schema = this.schemaWithAuthorableFields(
      {
        isManualPostal,
        sameAsCompanyAddress: data.sameAsCompanyAddress,
        address: data.address,
      },
      fields
    )
    formInit(FORM_ID, schema, data)
  }

  toggleAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate, setAdddressToManual },
      form: {
        fields: {
          sameAsCompanyAddress: { value: isSameAddress },
        },
      },
    } = this.props
    const { isManualPostal } = this.props
    // Set postal manual state to false if hiding postal details
    if (value && name === 'sameAsCompanyAddress') {
      setAdddressToManual('address', false)
    }
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isSameAddress,
    })
    const data = {
      error: errorCheck(value, schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { actions, isManualPostal } = this.props
    const { formUpdateField, formValidate } = actions

    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
    })
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressChange = address => {
    const { name, data, value } = address
    const { actions, isManualPostal } = this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = this.schemaWithAuthorableFields({ isManualPostal })
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  render() {
    const {
      fields,
      form,
      isManualPostal,
      businessForm,
      actions: { formUpdateField },
    } = this.props

    if (!form || !businessForm) return null
    const {
      fields: { directors, isSoleDirector },
    } = businessForm
    const {
      title,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      companyRole,
      email,
      // Address fields
      sameAsCompanyAddress,
      address,
      addressUnit,
      addressStreet,
      addressLocality,
      addressState,
      addressCountry,
      addressPostCode,
    } = form.fields

    const {
      addIndividualHeaderLabel,
      policyOwnerDetailsTitleFieldLabel,
      policyOwnerDetailsTitleFieldPlaceholder,
      policyOwnerDetailsTitleFieldError,
      policyOwnerDetailsFirstNameFieldLabel,
      policyOwnerDetailsFirstNameFieldPlaceholder,
      policyOwnerDetailsMiddleNameFieldLabel,
      policyOwnerDetailsMiddleNameFieldPlaceholder,
      policyOwnerDetailsLastNameFieldLabel,
      policyOwnerDetailsLastNameFieldPlaceholder,
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldLabel,
      policyOwnerDetailsDateOfBirthFieldPlaceholder,
      addDirectorCompanyRoleFieldLabel,
      addDirectorCompanyRoleFieldPlaceholder,
      addDirectorCompanyRoleFieldError,
      policyOwnerDetailsEmailFieldLabel,
      policyOwnerDetailsEmailFieldPlaceholder,
      policyOwnerDetailsEmailFieldError,
      // Address
      sameAsCompanyAddressCheckboxLabel,
      policyOwnerDetailsPostalAddressToggleToManualText,
      addDirectorAddressFieldLabel,
      addDirectorAddressFieldPlaceholder,
      policyOwnerDetailsPostalAddressFieldError,
      addDirectorStreetAddressFieldPlaceholder,
      addDirectorUnitAddressFieldPlaceholder,
      addDirectorCityFieldPlaceholder,
      addDirectorStateFieldPlaceholder,
      addDirectorPostCodeFieldPlaceholder,
      policyOwnerDetailsPostalAddressCountryFieldLabel,
      policyOwnerDetailsPostalAddressCountryFieldPlaceholder,
      policyOwnerDetailsPostalAddressToggleToAutoText,
    } = reduceAuthorableFields(fields)

    const isSecratoryAdded =
      directors.value &&
      directors.value.some(director => director.relatedParty.companyRole !== POLICY_MEMBER_DIRECTOR)

    const schema = this.schemaWithAuthorableFields({ isManualPostal })

    const directorOrSecratoryOption = schema.companyRole.options(
      isSecratoryAdded || isSoleDirector.value
    )
    if (directorOrSecratoryOption.length === 1 && !companyRole.value) {
      formUpdateField(FORM_ID, 'companyRole', directorOrSecratoryOption[0])
    }

    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{addIndividualHeaderLabel}</A11yLabel>
          <Form id={FORM_ID} aria-labelledby="application-overview">
            <TitleSelect
              label={policyOwnerDetailsTitleFieldLabel}
              placeholder={policyOwnerDetailsTitleFieldPlaceholder}
              value={title.value}
              name="title"
              id="title"
              error={title.error.error}
              caption={title.error.error && policyOwnerDetailsTitleFieldError}
              changeHandler={this.handleChange}
              options={schema.title.options}
            />

            <HalfWidthInput
              htmlFor="firstName"
              name="firstName"
              label={policyOwnerDetailsFirstNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsFirstNameFieldPlaceholder}
              error={firstName.error.error}
              caption={firstName.error.error ? firstName.error.errorMsg : schema.firstName.toolTip}
              value={firstName.value}
            />

            <HalfWidthInput
              htmlFor="middleName"
              name="middleName"
              label={policyOwnerDetailsMiddleNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsMiddleNameFieldPlaceholder}
              value={middleName.value}
            />

            <FullWidthInput
              htmlFor="lastName"
              name="lastName"
              label={policyOwnerDetailsLastNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsLastNameFieldPlaceholder}
              error={lastName.error.error}
              caption={lastName.error.error ? lastName.error.errorMsg : schema.lastName.toolTip}
              value={lastName.value}
            />

            <HalfWidthInput
              htmlFor="dateOfBirth"
              name="dateOfBirth"
              options={{ date: true }}
              label={policyOwnerDetailsDateOfBirthFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsDateOfBirthFieldPlaceholder}
              error={dateOfBirth.error.error}
              caption={dateOfBirth.error.error && policyOwnerDetailsDateOfBirthFieldError}
              value={dateOfBirth.value}
            />

            <RoleSelect
              label={addDirectorCompanyRoleFieldLabel}
              placeholder={addDirectorCompanyRoleFieldPlaceholder}
              value={companyRole.value}
              name="companyRole"
              id="companyRole"
              error={companyRole.error.error}
              caption={companyRole.error.error && addDirectorCompanyRoleFieldError}
              changeHandler={this.handleChange}
              disabled={directorOrSecratoryOption.length === 1}
              options={directorOrSecratoryOption}
            />

            <FullWidthInput
              htmlFor="email"
              name="email"
              label={policyOwnerDetailsEmailFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsEmailFieldPlaceholder}
              error={email.error.error}
              caption={email.error.error && policyOwnerDetailsEmailFieldError}
              value={email.value}
            />

            <SameAsCompanyAddressCheckbox
              text={sameAsCompanyAddressCheckboxLabel}
              name="sameAsCompanyAddress"
              htmlFor="sameAsCompanyAddress"
              onChangeHandler={this.toggleAddress}
              checked={sameAsCompanyAddress.value}
            />
            {!sameAsCompanyAddress.value && (
              <FullWidthAddressLookup
                name="address"
                auto={{
                  label: addDirectorAddressFieldLabel,
                  placeholder: addDirectorAddressFieldPlaceholder,
                  toggleLabel: policyOwnerDetailsPostalAddressToggleToManualText,
                  value: address.value,
                  selectChangeHandler: this.addressChange,
                  addressError: address.error.error,
                  addressName: 'address',
                  addressErrorMessage:
                    address.error.error && policyOwnerDetailsPostalAddressFieldError,
                }}
                manual={{
                  streetLabel: addDirectorStreetAddressFieldPlaceholder,
                  streetPlaceholder: addDirectorStreetAddressFieldPlaceholder,
                  streetValue: addressStreet.value,
                  streetRequiredErrorMessage: addressStreet.error.errorMsg,
                  streetError: addressStreet.error.error,
                  streetName: 'addressStreet',
                  houseNoLabel: addDirectorUnitAddressFieldPlaceholder,
                  houseNoPlaceholder: addDirectorUnitAddressFieldPlaceholder,
                  houseNoValue: addressUnit.value,
                  houseNoName: 'addressUnit',
                  houseNoError: addressUnit.error.error,
                  houseNoRequiredErrorMessage: addressUnit.error.errorMsg,
                  localityLabel: addDirectorCityFieldPlaceholder,
                  localityPlaceholder: addDirectorCityFieldPlaceholder,
                  localityLabelValue: addressLocality.value,
                  localityError: addressLocality.error.error,
                  localityRequiredErrorMessage: addressLocality.error.errorMsg,
                  localityName: 'addressLocality',
                  stateLabel: addDirectorStateFieldPlaceholder,
                  statePlaceholder: addDirectorStateFieldPlaceholder,
                  stateOptions: schema.addressState.options,
                  stateValue: addressState.value,
                  stateError: addressState.error.error,
                  stateRequiredErrorMessage: addressState.error.errorMsg,
                  stateName: 'addressState',
                  countryLabel: policyOwnerDetailsPostalAddressCountryFieldLabel,
                  countryPlaceholder: policyOwnerDetailsPostalAddressCountryFieldPlaceholder,
                  countryValue: addressCountry.value,
                  countryError: addressCountry.error.error,
                  countryRequiredErrorMessage: addressCountry.error.errorMsg,
                  countryName: 'addressCountry',
                  postCodeLabel: addDirectorPostCodeFieldPlaceholder,
                  postCodePlaceholder: addDirectorPostCodeFieldPlaceholder,
                  postCodeValue: addressPostCode.value,
                  postCodeError: addressPostCode.error.error,
                  postCodeRequiredErrorMessage: addressPostCode.error.errorMsg,
                  postCodeName: 'addressPostCode',
                  manualToggleLabel: policyOwnerDetailsPostalAddressToggleToAutoText,
                  inputEntryHandler: this.handleChange,
                }}
              />
            )}
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms, addressLookup, sidebar: { panelProps } }) => ({
  form: forms[FORM_ID],
  businessForm: forms[BUSINESS_FORM_ID] || forms[SMSF_TRUSTEE_FORM_ID],
  isManualPostal: addressLookup.address && addressLookup.address.isManual,
  sidebarPanelProps: panelProps,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDirector)
