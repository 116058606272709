/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconDot16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path className="path1" d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#000" />
  </Icon>
)

export default IconDot16
