// @flow
import React from 'react'
import styled from '@emotion/styled'

import Heading from '../../atoms/Heading'

import { renderTextField } from '../../../utils/sitecoreUtils'

import styles from './visualiserDisclaimer.styles'

const Wrapper = styled('div')(styles.wrapper)

type VisualiserDisclaimerPropTypes = {
  fields: Object,
}
const VisualiserDisclaimer = ({ fields }: VisualiserDisclaimerPropTypes) => (
  <Wrapper>
    <Heading element="3" size="2">
      {renderTextField(fields.visualiserDisclaimerHeading)}
    </Heading>
    {renderTextField(fields.visualiserDisclaimerContent, true)}
  </Wrapper>
)

export default VisualiserDisclaimer
