/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconBing32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4v20.442L13.573 27 25 20.373v-5.21l-10.14-3.582 1.99 4.983 3.156 1.489-6.436 3.736V5.616L9 4z"
      fill="#000"
    />
  </Icon>
)

export default IconBing32
