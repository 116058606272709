/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronLeft32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.621 16.5l8.94 8.94-2.122 2.12L10.38 16.5l11.06-11.06 2.122 2.12-8.94 8.94z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronLeft32
