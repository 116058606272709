import { space } from '../../../../../styles'

const styles = {
  names: {
    display: 'flex',
  },

  firstName: {
    flexGrow: 1,
    marginRight: space(2),
  },

  lastName: {
    flexGrow: 1,
  },

  dob: {
    width: space(21),
  },

  toggle: {
    width: space(44),
  },
}

export default styles
