/* eslint-disable no-unused-vars */
// redux.
import React from 'react'
import moment from 'moment'

// schema.
import applicationBeneficiariesSchema, {
  FORM_ID as applicationBeneficiariesId,
} from '../../ApplicationBeneficiaries/applicationBeneficiaries.schema'
import beneficiaryDetailsSchema, {
  FORM_ID as beneficiaryDetailsId,
} from '../../BeneficiaryDetails/beneficiaryDetails.schema'

// actions
import {
  formReset,
  formSubmit,
  formSubmitComplete,
  formAddField,
  formUpdateField,
  formSetValid,
} from '../../../../actions/creators/forms'
import { hideSidebarFooter, closeSidebar } from '../../../../actions/creators/sidebar'
import { updateBeneficiaries } from '../../../../actions/creators/createQuote'

import {
  POLICY_BENEFICIARY_ACTION_ADD,
  POLICY_BENEFICIARY_ACTION_REMOVE,
  POLICY_BENEFICIARY_ACTION_UPDATE,
  ADDRESS_TYPE_HOME,
} from '../../../../constants/policies'

import { createBeneficiaryEntity, getLifeAsssured } from '../../../../utils/quoteUtils'
import { isLifeInsuredSelectedAsBeneficiary } from '../../../../utils/policyUtils'

import { belowMinimumCondition, percentage, required } from '../../../../utils/formUtils'

import { getAddressByAddressType, getPreferredAddress } from '../../../../utils/contactUtils'

// components.
import BeneficiaryDetails from '../../BeneficiaryDetails'
import ApplicationBeneficiaries from '../../ApplicationBeneficiaries'

const SIDEBAR_SCHEMA = (fields = null) => [
  {
    sitecoreFieldHeadingKey: 'addBeneficiaryHeaderLabel',
    component: ApplicationBeneficiaries,
    visible: false,
    nextText: fields && fields.saveBeneficiariesButtonText.value,
    callbackKey: 'handleNext',
    props: {
      handleNext: () => {},
    },
    handleNext: (next, dispatch, state) => {
      const {
        sidebar: {
          panelProps: { policyInstanceNo },
        }, // Key passed via panel props. Use for editing state
        forms: {
          [applicationBeneficiariesId]: {
            fields: {
              beneficiaryType: { value: beneficiaryType },
              ...beneficiaryFields
            },
          },
        },
        createQuote,
      } = state

      const {
        addBeneficiaryBenefitPercentageMinimumConditionErrorMessage,
        addBeneficiaryBenefitInvalidPercentageValueErrorMessage,
      } = fields

      const policyLifeInsured = getLifeAsssured(createQuote)
      const beneficiaries = Object.keys(beneficiaryFields).map((name, index) => {
        const { dateOfBirth, relationship } = beneficiaryFields[name].data
        const beneficiaryFieldsData = [
          {
            relatedParty: {
              ...beneficiaryFields[name].data,
            },
          },
        ]
        const isLISelectedAsBeneficiary = isLifeInsuredSelectedAsBeneficiary(
          [policyLifeInsured],
          beneficiaryFieldsData
        )

        const dateofBirth =
          dateOfBirth && dateOfBirth.includes('/')
            ? moment(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : dateOfBirth
        let beneficiary = {
          gender: 'Unknown',
          addressType: ADDRESS_TYPE_HOME,
          // if Life insured select as beneficiary - add email, phone, gender and smoker status
          ...(isLISelectedAsBeneficiary
            ? {
                ...policyLifeInsured.relatedParty,
              }
            : {}),
          ...beneficiaryFields[name].data,
          percentageAllocation: beneficiaryFields[name].value,
          dateOfBirth: dateOfBirth ? dateofBirth : '',
          relationship: relationship === '' ? 'LGLRPSNT' : relationship,
        }
        const { addressSameAsLifeInsured } = beneficiary
        // Add life insured address to beneficiary is same as life insured selected.
        if (addressSameAsLifeInsured && !isLISelectedAsBeneficiary) {
          const {
            relatedParty: { contactMethods },
          } = policyLifeInsured
          beneficiary = {
            ...beneficiary,
            ...getAddressByAddressType(contactMethods.addresses, ADDRESS_TYPE_HOME),
          }
        }

        return createBeneficiaryEntity(
          beneficiary,
          beneficiaryType,
          index,
          isLISelectedAsBeneficiary
        )
      })

      // Create schema to check percentages
      const schema = {
        ...applicationBeneficiariesSchema,
        ...Object.keys(beneficiaryFields).reduce((acc, key) => {
          acc[key] = {
            condition: [
              {
                condition: belowMinimumCondition(1),
                errorMsg: addBeneficiaryBenefitPercentageMinimumConditionErrorMessage.value,
              },
              {
                condition: percentage,
                errorMsg: addBeneficiaryBenefitInvalidPercentageValueErrorMessage.value,
              },
            ],
          }
          return acc
        }, {}),
      }

      // Get total beneficiaries percentage
      const totalBeneficiaryPercentage = Object.keys(beneficiaryFields).reduce((acc, key) => {
        const beneficiaryField = beneficiaryFields[key]
        const { action } = beneficiaryField.data
        return action !== POLICY_BENEFICIARY_ACTION_REMOVE
          ? acc + Number(beneficiaryField.value)
          : acc
      }, 0)

      dispatch(
        // Allow submit if total of percentages are exactly 100 or is saving with no beneficiaries.
        formSubmit(applicationBeneficiariesId, schema, () => {
          if (totalBeneficiaryPercentage === 100 || beneficiaries.length === 0) {
            formSubmit(applicationBeneficiariesId, schema)
            dispatch(closeSidebar())
            dispatch(updateBeneficiaries(policyInstanceNo, beneficiaries))
            dispatch(formSubmitComplete(applicationBeneficiariesId))
            dispatch(formReset(applicationBeneficiariesId, applicationBeneficiariesSchema))
          } else {
            dispatch(formSetValid(applicationBeneficiariesId, false))
          }
        })
      )
    },
  },
  {
    sitecoreFieldHeadingKey: 'addBeneficiaryHeaderLabel',
    component: BeneficiaryDetails,
    nextText: fields && fields.addBeneficiariesButtonText.value,
    handleNext: (next, dispatch, state) => {
      const key = 'BeneficiaryPercentage'
      const {
        addressLookup,
        sidebar: {
          panelProps,
          panelProps: { policyInstanceNo },
        }, // Key passed via panel props. Use for editing state
        forms,
        forms: {
          [applicationBeneficiariesId]: {
            fields: {
              beneficiaryType: { value: beneficiaryType },
            },
          },
          [beneficiaryDetailsId]: {
            fields: { addressSameAsLifeInsured },
          },
        },
        createQuote: { activeIndex, quotes },
      } = state
      const { policyStructure } = quotes[activeIndex]
      // Get the active policies beneficiaries
      const { beneficiaries = [] } = policyStructure.find(
        policy => policy.policyInstanceNo === policyInstanceNo
      )
      const formKey = panelProps && panelProps.formKey
      const isManual = addressLookup.beneficiaryAddress && addressLookup.beneficiaryAddress.isManual
      const updateApplicationBeneficiaries = formFields => {
        if (formKey) {
          dispatch(
            formUpdateField(applicationBeneficiariesId, formKey, {
              data: {
                ...formFields,
                policyInstanceNo,
                type: beneficiaryType.value,
                beneficiaryIndex:
                  forms[applicationBeneficiariesId].fields[formKey].data.beneficiaryIndex,
                action:
                  forms[applicationBeneficiariesId].fields[formKey].data.action &&
                  forms[applicationBeneficiariesId].fields[formKey].data.action !==
                    POLICY_BENEFICIARY_ACTION_ADD
                    ? POLICY_BENEFICIARY_ACTION_UPDATE
                    : forms[applicationBeneficiariesId].fields[formKey].data.action,
              },
            })
          )
        } else {
          dispatch(
            formAddField(applicationBeneficiariesId, `${String(Date.now())}${key}`, {
              value: '',
              data: {
                ...formFields,
                policyInstanceNo,
                type: beneficiaryType.value,
                beneficiaryIndex: beneficiaries.length + 1,
                action: POLICY_BENEFICIARY_ACTION_ADD,
              },
            })
          )
        }
        dispatch(formSubmitComplete(beneficiaryDetailsId))
        dispatch(formReset(beneficiaryDetailsId, beneficiaryDetailsSchema(fields, false, false)))
        dispatch(hideSidebarFooter())
        next(0, { policyInstanceNo })
      }

      dispatch(
        formSubmit(
          beneficiaryDetailsId,
          beneficiaryDetailsSchema(fields, isManual, addressSameAsLifeInsured.value),
          updateApplicationBeneficiaries
        )
      )
    },
    secondaryActionText: 'Clear',
    handleSecondaryAction: (dispatch, state) => {
      const {
        sidebar: {
          panelProps: { data },
        },
      } = state

      dispatch(
        formReset(beneficiaryDetailsId, beneficiaryDetailsSchema(fields, false, false), data)
      )
    },
  },
]

export default SIDEBAR_SCHEMA
