/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconDownload16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M8 12l-.707.707.707.707.707-.707L8 12zm4.293-5.707l-5 5 1.414 1.414 5-5-1.414-1.414zm-3.586 5l-5-5-1.414 1.414 5 5 1.414-1.414zM7 1v11h2V1H7zM2 16h12v-2H2v2z"
      fill="#000"
    />
  </Icon>
)

export default IconDownload16
