// @flow
import { EXPERIENCE_API } from '../types/experienceApi'
import { EXPERIENCE_API_URE_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'
import { URE_VERSION_OCCUPATIONS_RATING, URE_VERSION_OCCUPATIONS_SEARCH } from '../../constants/ure'
import {
  OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS,
  OCCUPATIONS_SEARCH_OCCUPATION,
  OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING,
  OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING,
  OCCUPATIONS_UPDATE_GENERAL_OCCUPATIONS,
  OCCUPATIONS_NAMES_RESET,
  RESET_OCCUPATIONS_ON_STORE,
  RESET_SEC_OCCUPATION,
} from '../types/occupation'
import { headerOccupation, SC_BRANCH_TAG, DEFAULT_TAG } from '../../constants/occupationListApi'

export const getGeneralOccupations = () => (dispatch, getState) => {
  const {
    masterList: {
      data: { ureapiVersion },
    },
  } = getState()
  const branchTag = ureapiVersion.find(item => item.code === SC_BRANCH_TAG)
  const OCCUPATIONS_GET_OCCUPATION_BUCKETS_ENDPOINT = `/v1/digital/retailproxy/ure/lookupOptionBucketValues/${
    branchTag ? branchTag.value : DEFAULT_TAG
  }/${
    ureapiVersion.find(version => version.code === URE_VERSION_OCCUPATIONS_RATING).value
  }/Occupations/`
  dispatch({
    type: OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS,
  })
  dispatch({
    type: EXPERIENCE_API,
    name: OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS,
    verb: 'GET',
    // TODO: this url is a bypass until the ure proxy api's are available.
    route:
      OCCUPATIONS_GET_OCCUPATION_BUCKETS_ENDPOINT ||
      `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/occupations`,
    callback: (err, dataReceived) =>
      dataReceived.filter(data => /rated occupation|Special Risk occupations/i.test(data.text)),
    additionalHeaders: headerOccupation,
  })
}

export const updateGeneralOccupations = (occupation, isSecondaryOccupation) => dispatch => {
  dispatch({
    type: OCCUPATIONS_UPDATE_GENERAL_OCCUPATIONS,
    payload: {
      occupationData: occupation,
      isSecondaryOccupation,
    },
  })
}

export const resetOccupationNames = () => dispatch => {
  dispatch({
    type: OCCUPATIONS_NAMES_RESET,
  })
}

export const getListOfOccupations = searchedValue => (dispatch, getState) => {
  const {
    masterList: { data },
  } = getState()

  const { ureapiVersion, ureExcludedOccupations } = data
  const branchTag = ureapiVersion.find(item => item.code === SC_BRANCH_TAG)
  const OCCUPATIONS_GET_SEARCH_ENDPOINT = `/v1/digital/retailproxy/ure/lookupQuestionOption/${
    branchTag ? branchTag.value : DEFAULT_TAG
  }/${
    ureapiVersion.find(version => version.code === URE_VERSION_OCCUPATIONS_SEARCH).value
  }/OCCUPATION/`
  dispatch({
    type: OCCUPATIONS_SEARCH_OCCUPATION,
  })
  dispatch({
    type: EXPERIENCE_API,
    name: OCCUPATIONS_SEARCH_OCCUPATION,
    verb: 'GET',
    // TODO: this url is an override until the ure proxy api's are available.
    route: `${OCCUPATIONS_GET_SEARCH_ENDPOINT}${searchedValue}`,
    callback: (err, dataReceived) => {
      const updatedExclusionList = ureExcludedOccupations.reduce((acc, currentValue) => {
        const key = currentValue.code.replace(/\s/g, '').trim().toLowerCase()
        acc[key] = currentValue
        return acc
      }, [])

      const filteredRecievedData = dataReceived.reduce((acc, currentValue) => {
        const trimmedValue = currentValue.replace(/\s/g, '').trim().toLowerCase()

        if (!updatedExclusionList[trimmedValue]) {
          acc.push(currentValue)
        }

        return acc
      }, [])

      return filteredRecievedData
    },
    additionalHeaders: headerOccupation,
  })
}

export const getRatingOfOccupation =
  (selectedValue, isSecondaryOccupation) => (dispatch, getState) => {
    const {
      masterList: {
        data: { ureapiVersion },
      },
    } = getState()
    const branchTag = ureapiVersion.find(item => item.code === SC_BRANCH_TAG)
    const OCCUPATIONS_GET_RATING_ENDPOINT = `/v1/digital/retailproxy/ure/option/${
      branchTag ? branchTag.value : DEFAULT_TAG
    }/${
      ureapiVersion.find(version => version.code === URE_VERSION_OCCUPATIONS_RATING).value
    }/Occupations/`
    if (isSecondaryOccupation) {
      dispatch({
        type: EXPERIENCE_API,
        name: OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING,
        verb: 'GET',
        route: `${OCCUPATIONS_GET_RATING_ENDPOINT}${selectedValue}`,
        callback: (err, dataReceived) => dataReceived,
        additionalHeaders: headerOccupation,
      })
    } else {
      dispatch({
        type: EXPERIENCE_API,
        name: OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING,
        verb: 'GET',
        // TODO: this url is an override until the ure proxy api's are available.
        route: `${OCCUPATIONS_GET_RATING_ENDPOINT}${selectedValue}`,
        callback: (err, dataReceived) => dataReceived,
        additionalHeaders: headerOccupation,
      })
    }
  }

export const resetOccupationInStore = () => ({
  type: RESET_OCCUPATIONS_ON_STORE,
})

export const resetSecondaryOccupation = () => ({
  type: RESET_SEC_OCCUPATION,
})
