// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Component } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import get from 'lodash/get'
import has from 'lodash/has'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Button from '../../../../atoms/Button'
import { IconDownload16 } from '../../../../atoms/Icons'
import Modal from '../../../../molecules/Modal'
import Heading from '../../../../atoms/Heading'

// eslint-disable-next-line import/no-named-as-default
import QuoteUpdateAdviserDetails from './QuoteUpdateAdviserDetails'
// eslint-disable-next-line import/no-named-as-default
import QuoteDownloadOptions from './QuoteDownloadOptions'
import QuoteCollectionName from '../QuoteToolPanel/QuoteCollectionName'

// helpers.
import history from '../../../../../utils/browserHistory'

// styles.
import styles from './quoteDownload.styles'

// utils
import { validateQuote, checkCreateQuoteWithValidPolicies } from '../../../../../utils/quoteUtils'

import { DEFAULT_QUOTE_COLLECTION_NAME } from '../../../../../constants/forms'
import { POLICY_PRODUCT_CODE_SUPER, SAVE_QUOTE_COLLECTION } from '../../../../../constants/policies'

type QuoteDownloadProps = {
  // Align action root
  align: 'column' | 'row' | 'row-reverse' | 'column-reverse',
  // Download button style
  secondaryBtnTheme?: 'primary' | 'secondary' | 'tertiary',
  // Sitecore fields
  fields: {
    //  Quote Update Adviser Details Modal Title
    quoteUpdateAdviserDetailsTitle: { value: string },
    //  Quote Update Adviser Details Modal Company Form Header
    quoteUpdateAdviserDetailsCompanyFormHeader: { value: string },
    //  Quote Update Adviser Details Modal Name Label
    quoteUpdateAdviserDetailsNameLabel: { value: string },
    //  Quote Update Adviser Details Modal Business Name Label
    quoteUpdateAdviserDetailsBusinessNameLabel: { value: string },
    //  Quote Update Adviser Details Modal ABN Label
    quoteUpdateAdviserDetailsAbnLabel: { value: string },
    //  Quote Update Adviser Details Modal Contact Form Label
    quoteUpdateAdviserDetailsContactFormHeader: { value: string },
    //  Quote Update Adviser Details Modal Full Address Label
    quoteUpdateAdviserDetailsAddressLabel: { value: string },
    //  Quote Update Adviser Details Modal Cant Find Address Label
    quoteUpdateAdviserDetailsCantFindAddressLabel: { value: string },
    //  Quote Update Adviser Details Modal Street Input Placeholder
    quoteUpdateAdviserDetailsStreetPlaceholder: { value: string },
    //  Quote Update Adviser Details Modal House Number Input Placeholder
    quoteUpdateAdviserDetailsHouseNoPlaceholder: { value: string },
    //  Quote Update Adviser Details Modal Locality Input Placeholder
    quoteUpdateAdviserDetailsLocalityPlaceholder: { value: string },
    //  Quote Update Adviser Details Modal State Input Placeholder
    quoteUpdateAdviserDetailsStatePlaceholder: { value: string },
    //  Quote Update Adviser Details Modal Country Select Placeholder
    quoteUpdateAdviserDetailsCountryPlaceholder: { value: string },
    //  Quote Update Adviser Details Modal Zip Code Input Placeholder
    quoteUpdateAdviserDetailsPostCodePlaceholder: { value: string },
    //  Quote Update Adviser Details Modal Address Manual Toggle Label
    quoteUpdateAdviserDetailsManualToggleLabel: { value: string },
    //  Quote Update Adviser Details Modal Contact Number Label
    quoteUpdateAdviserDetailsContactLabel: { value: string },
    //  Quote Update Adviser Details Modal Email Address Label
    quoteUpdateAdviserDetailsEmailLabel: { value: string },
    //  Quote Update Adviser Details Modal Submit Button
    quoteUpdateAdviserDetailsSubmitButton: { value: string },
    //  Quote Update Adviser Details Phone Input Invalid Error Message
    quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg: { value: string },
    //  Quote Update Adviser Details Email Input Invalid Error Message
    quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg: { value: string },
    //  Quote Update Adviser Details Street Input Invalid Error Message
    downloadQuoteInvalidStreetErrorMsg: { value: string },
    //  Quote Update Adviser Details State Input Invalid Error Message
    downloadQuoteInvalidStateErrorMsg: { value: string },
    //  Quote Update Adviser Details PostCode Input Invalid Error Message
    downloadQuoteInvalidPostCodeErrorMsg: { value: string },
    //  Quote Update Adviser Details Locality Input Invalid Error Message
    downloadQuoteInvalidLocalityErrorMsg: { value: string },
    // Primary button label
    quoteEntityPrimaryActionBtn: { value: string },
    // Secondary button label
    quoteEntitySecondaryActionBtn: { value: string },
  },
  hasDownloadIcon?: boolean,
  hasPrimaryBtn?: boolean,
  // An object containing action creator functions.
  actions: Object<Function>,
  // active quote data from redux state
  quote: Object,
  // type of button
  buttonType: string,
  // styles for root component
  rootStyle: object,
  // styles for button
  buttonStyle: object,
  // quote object from redux state
  createQuote: object,
  // save quote initiator text
  saveQuoteInitiator: String,
  // exit to dashboard flag
  exitToDashboard: Boolean,
  // redux env constants config
  config: Object,
}

type QuoteDownloadPropsState = {
  // indicates if  modal is open
  downloadQuoteOpen: boolean,
  // error modal if super policy detail is not entered
  isSuperPolicyDetailsModal: boolean,
}

const QuoteButton = styled(Button)(styles.quoteButton)
const SubHeading = styled(Heading)(styles.subHeading)
const CloseButton = styled(Button)(styles.closeButton)

export class QuoteDownload extends Component<QuoteDownloadProps, QuoteDownloadPropsState> {
  state = {
    downloadQuoteOpen: false,
    isAdvisorDetailsUpdated: false,
    showSaveQuoteModal: false,
    isSuperPolicyDetailsModal: false,
  }

  static defaultProps = {
    secondaryBtnTheme: 'tertiary',
    hasDownloadIcon: false,
    secondaryActionClick: () => {},
    hasPrimaryBtn: true,
  }

  shouldComponentUpdate(nextProps, nextState) {
    /* to do: component keep re-render after its children component init its form,
     bypass by this now, will need to check the exactly reason later.. */
    const {
      downloadQuoteOpen,
      isAdvisorDetailsUpdated,
      showSaveQuoteModal,
      isSuperPolicyDetailsModal,
    } = this.state
    return (
      downloadQuoteOpen !== nextState.downloadQuoteOpen ||
      isAdvisorDetailsUpdated !== nextState.isAdvisorDetailsUpdated ||
      showSaveQuoteModal !== nextState.showSaveQuoteModal ||
      isSuperPolicyDetailsModal !== nextState.isSuperPolicyDetailsModal
    )
  }

  handleIsSuperPolicyDetailsFilled = () => {
    const { quote } = this.props
    const policyStructure = get(quote, 'policyStructure', [])

    const checkSuperPolicyDetails = (ps: Array) =>
      has(ps, 'superFundName') && has(ps, 'fundPaymentMethod')

    const isNotComplete = !policyStructure
      .filter(policy => policy.productId === POLICY_PRODUCT_CODE_SUPER)
      .every(checkSuperPolicyDetails)

    return isNotComplete
  }

  handleContinueToQuoteSummary = () => {
    const {
      quote,
      actions: { initiateSaveQuote, updateQuoteStatus, showSaveQuoteErrorModal },
    } = this.props

    // checks if super policy details is completely filled or not
    updateQuoteStatus({ actionName: SAVE_QUOTE_COLLECTION })
    if (this.handleIsSuperPolicyDetailsFilled()) {
      this.setState({ isSuperPolicyDetailsModal: true })
    } else if (checkCreateQuoteWithValidPolicies(quote)) {
      // logic to redirect
      const viewQuoteSummaryPath = '/quote-summary'
      initiateSaveQuote(err => {
        if (!err) {
          history.push(viewQuoteSummaryPath)
        }
      }, 'continueToApplication')
      this.setState({ isSuperPolicyDetailsModal: false })
    } else {
      showSaveQuoteErrorModal(true)
    }
  }

  handleDownloadQuote = () => {
    const {
      createQuote,
      quote,
      actions: { updateIsSaveQuote, saveQuote, showSaveQuoteErrorModal },
    } = this.props
    // do not trigger action to update isSaveQuote if isSaveQuote is already true
    if (!createQuote.isSaveQuote) {
      updateIsSaveQuote()
    }
    const isValidQuote = validateQuote(createQuote.quotes)

    if (isValidQuote) {
      // check policies validity with covers before proceeding downloading
      if (checkCreateQuoteWithValidPolicies(quote)) {
        // in case unnamed quote collection, open save quote modal
        if (createQuote.quoteCollectionName === DEFAULT_QUOTE_COLLECTION_NAME) {
          this.setState({ showSaveQuoteModal: true })
        } else {
          // trigger action to save quote
          saveQuote(() => {
            if (!createQuote.hasCalculationError) {
              this.setState({ downloadQuoteOpen: true })
            }
          })
        }
      } else {
        showSaveQuoteErrorModal(true)
      }
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Quote collection',
        action: 'Download',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Quote collection - Download',
        },
      },
    })
    tagEvent.end()
  }

  handleModalClose = () => this.setState({ isSuperPolicyDetailsModal: false })

  // updates the store with quote collection name
  updateQuoteCollectionName = data => {
    const {
      actions: { saveQuote },
      saveQuoteInitiator,
    } = this.props
    const quoteCollectionName = `${data.firstName} ${data.lastName}`
    const tagEvent = createEvent({
      GA: {
        category: 'Quote collection',
        action: 'Save',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Quote collection - Save',
        },
      },
    })
    tagEvent.end()
    saveQuote(
      err => {
        if (!err) {
          this.setState({ downloadQuoteOpen: true })
          switch (saveQuoteInitiator) {
            case 'continueToApplication': {
              history.replace('/quote-summary')
              break
            }
            default: {
              break
            }
          }
        }
      },
      saveQuoteInitiator ? `${saveQuoteInitiator}Modal` : '',
      quoteCollectionName,
      data.firstName,
      data.lastName
    )
    this.setState({ showSaveQuoteModal: false })
  }

  closeModal = () => {
    this.setState({
      downloadQuoteOpen: false,
      isAdvisorDetailsUpdated: false,
    })
  }

  submitAdvisorDetails = () => {
    const { isAdvisorDetailsUpdated } = this.state
    this.setState({ isAdvisorDetailsUpdated: !isAdvisorDetailsUpdated })
  }

  closeSaveQuoteModal() {
    this.setState({ showSaveQuoteModal: false })
  }

  render() {
    const {
      align,
      secondaryBtnTheme,
      fields,
      hasDownloadIcon,
      hasPrimaryBtn,
      quote,
      actions,
      buttonType,
      rootStyle,
      buttonStyle,
      exitToDashboard,
      config,
    } = this.props
    const {
      downloadQuoteOpen,
      isAdvisorDetailsUpdated,
      showSaveQuoteModal,
      isSuperPolicyDetailsModal,
    } = this.state

    const QuoteActionRoot = styled('div')(
      styles.quoteActionRoot(align, secondaryBtnTheme),
      rootStyle
    )

    const QuoteDownloadIcon = styled(IconDownload16)({
      ...styles.quoteIcon,
      ...styles.setColor(secondaryBtnTheme),
      ...styles.setFontSize(secondaryBtnTheme),
    })

    const QuoteDownloadLabel = styled('label')({
      ...styles.quoteLabel(secondaryBtnTheme),
      ...styles.setColor(secondaryBtnTheme),
      ...styles.setFontSize(secondaryBtnTheme),
    })

    const QuoteButtonSecondary = styled(Button)(buttonStyle)
    return (
      <QuoteActionRoot>
        {hasPrimaryBtn && (
          <QuoteButton onClick={this.handleContinueToQuoteSummary} type="primary">
            <Text field={fields.quoteEntityPrimaryActionBtn} />
          </QuoteButton>
        )}
        <QuoteButtonSecondary
          onClick={this.handleDownloadQuote}
          type={buttonType || (hasPrimaryBtn ? 'tertiary' : 'primary')}
        >
          <QuoteDownloadLabel>
            <Text field={fields.quoteEntitySecondaryActionBtn} />
          </QuoteDownloadLabel>
          {hasDownloadIcon && <QuoteDownloadIcon />}
        </QuoteButtonSecondary>
        <Modal
          title={
            !isAdvisorDetailsUpdated
              ? fields.quoteUpdateAdviserDetailsTitle
              : fields.quoteDownloadModalTitle
          }
          isOpen={downloadQuoteOpen}
          onClose={this.closeModal}
        >
          {!isAdvisorDetailsUpdated ? (
            <QuoteUpdateAdviserDetails
              fields={fields}
              closeModal={this.closeModal}
              submitAdvisorDetails={this.submitAdvisorDetails}
              quote={quote}
            />
          ) : (
            <QuoteDownloadOptions
              fields={fields}
              actions={actions}
              closeModal={this.closeModal}
              submitAdvisorDetails={this.submitAdvisorDetails}
              config={config}
            />
          )}
        </Modal>
        <Modal
          title={
            exitToDashboard
              ? fields.dashboardConfirmQuoteModalHeading.value
              : fields.quoteCollectionNameTitle.value
          }
          onClose={() => this.closeSaveQuoteModal()}
          isOpen={showSaveQuoteModal}
        >
          <QuoteCollectionName
            fields={fields}
            exitToDashboard={exitToDashboard}
            onSubmit={this.updateQuoteCollectionName}
          />
        </Modal>
        <Modal
          isOpen={isSuperPolicyDetailsModal}
          title={get(fields, 'incompleteSuperPolicyDetailsModalHeading', '')}
          onClose={this.handleModalClose}
        >
          <SubHeading size="6">
            <Text field={get(fields, 'incompleteSuperPolicyDetailsModalMessage', {})} />
          </SubHeading>
          <CloseButton type="secondary" onClick={this.handleModalClose}>
            <Text field={get(fields, 'incompleteSuperPolicyDetailsModalConfirm', {})} />
          </CloseButton>
        </Modal>
      </QuoteActionRoot>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

// selector function to retrieve the active quote.
const selectActiveQuote = ({ quotes, activeIndex }: Object): Object => quotes[activeIndex]

export const mapStateToProps = ({ createQuote, masterList, config }) => ({
  quote: selectActiveQuote(createQuote),
  createQuote,
  exitToDashboard: createQuote.exitToDashboard,
  saveQuoteInitiator: createQuote.saveQuoteInitiator,
  masterData: masterList.data,
  config,
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDownload)
