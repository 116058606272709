/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconRemoveCircle16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM3.57143 6C3.25584 6 3 6.44772 3 7C3 7.55228 3.25584 8 3.57143 8H10.4286C10.7442 8 11 7.55228 11 7C11 6.44772 10.7442 6 10.4286 6H3.57143Z"
      fill="#103339"
    />
  </Icon>
)

export default IconRemoveCircle16
