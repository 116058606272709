import { space, fontSize, fontWeights, colours, shadows } from '../../../../../styles'

const styles = {
  twoColumnLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flexBasis: `calc(50% - ${space(1, true)})`,
    },
  },
  icon: {
    margin: space(1, true),
    color: colours.black,
  },
  blueIcon: {
    margin: space(1, true),
    color: colours.blue,
  },
  selectNone: {
    display: 'none',
  },
  benefitContainer: {
    width: space(47.5, true),
    marginTop: space(2, true),
    marginBottom: space(2, true),
  },
  benefitAccordion: {
    fontWeight: 'bold',
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    color: colours.darkGrey,
  },
  button: {
    minWidth: space(17.5, true),
  },
  smallButton: {
    padding: 0,
    paddingBottom: space(2, true),
    fontSize: fontSize.xs,
  },
  primaryButton: {
    margin: `0 ${space(3, true)} 0 0`,
  },
  icons: {
    color: colours.black,
  },
  policyInput: {
    flex: 3,
  },
  policyContainer: {
    paddingTop: space(3, true),
  },
  policyWrapper: {
    width: space(47.5, true),
    display: 'grid',
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: 0,
    '&:first-child': {
      borderWidth: '1px 0 1px 0',
    },
    '&:not(:first-child)': {
      borderWidth: '0px 0 1px 0',
    },
  },
  dropDownContainer: {
    position: 'relative',
    display: 'inline-block',
    width: space(47.5, true),
    border: `2px dashed ${colours.lightestGrey}`,
    paddingBottom: space(1, true),
    fontSize: fontSize.xs,
    marginTop: space(2, true),
    '&:nth-child(2)': {
      marginTop: space(2.5, true),
    },
  },
  dropdown: {
    position: 'absolute',
    width: space(27.5, true),
    overflow: 'auto',
    boxShadow: shadows.high,
    zIndex: 1,
    marginLeft: space(1, true),
    marginBottom: space(2, true),
    '& > button': {
      color: colours.black,
      padding: `${space(1.5, true)} ${space(2, true)}`,
      '&:nth-child(odd)': {
        background: colours.offWhite,
      },
      '&:hover': {
        background: colours.lightGrey,
      },
      border: 'none',
      width: '100%',
    },
  },
  exitingCoverSection: {
    marginBottom: space(3, true),
    paddingBottom: space(3, true),
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
  },
  exitingCoverHeader: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    color: colours.darkestGrey,
    paddingBottom: space(2, true),
    paddingTop: space(3, true),
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: '1px 0 1px 0',

    '&:first-child': {
      borderWidth: '0 0 1px 0',
    },
  },
  exitingCoverDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    paddingTop: space(2, true),
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',

    ':last-child': {
      borderWidth: '0 0 0 0',
    },
  },
  detailsLeft: {
    width: '60%',
  },
  detailsRight: {
    width: '40%',
  },
  benefitTitle: {
    fontWeight: fontWeights.bold,
    color: colours.darkGrey,
  },
  detailsItem: {
    marginLeft: space(2, true),
    fontSize: fontSize.xs,
    color: colours.darkGrey,
  },
  exitingCoverDescription: {
    color: colours.darkGrey,
    marginBottom: space(3, true),
  },
  modalTextDescription: {
    marginBottom: space(3, true),
  },
  policyLayout: {
    width: space(47.5, true),
    display: 'flex',
    padding: `${space(3, true)} 0`,
    borderColor: colours.lightGrey,
    borderStyle: 'solid',
    borderWidth: 0,
    '&:first-child': {
      borderWidth: '1px 0 1px 0',
    },
    '&:not(:first-child)': {
      borderWidth: '0px 0 1px 0',
    },
  },
  policyDetailContainer: {
    width: '100%',
  },
  actionItemContainer: {
    display: 'flex',
    justifySelf: 'flex-end',
  },
  policyTitle: {
    marginBottom: space(4, true),
  },
  actionButton: {
    minWidth: space(1, true),
    padding: 0,
    margin: 0,
  },
  featuresContainer: {
    color: colours.darkGrey,
    marginBottom: space(3, true),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  featureTitle: {
    marginBottom: space(2.5, true),
  },
  benefitButton: {
    fontSize: fontSize.xs,
  },
  policyActionContainer: {
    width: space(47.5, true),
    display: 'flex',
    alignItems: 'center',
    marginTop: space(3, true),
    marginBottom: space(3, true),
  },
  insurerText: {
    fontSize: fontSize.xxs,
    color: colours.darkGrey,
  },
  policyText: {
    fontSize: fontSize.xs,
  },
  coverDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  startDateContainer: ({ isOtherFields }) => ({
    width: isOtherFields ? 'calc(25% - 11px)' : `calc(50% - ${space(1, true)})`,
  }),
  waitingPeriodContainer: {
    width: 'calc(36% - 11px)',
  },
  benefitPeriodContainer: {
    width: 'calc(39% - 11px)',
  },
  sumInsuredLabel: {
    color: colours.tertiaryOne,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(2),
  },
}

export default styles
