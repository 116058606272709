// @flow
import get from 'lodash/get'

// constants
import {
  POLICY_MEMBER_IDENTIFIER_CUSTOMER,
  POLICY_MEMBER_IDENTIFIER_PARTY,
} from '../constants/policies'
import { STATUS_ACTIVE } from '../constants/adviser'

// Group the policy IDs to Advisors
export const getGroupedAdvisers = (list: Array, keyGetter: Function): Array => {
  const map = new Map()
  list.forEach(item => {
    if (item) {
      const key = keyGetter(item)
      const details = map.get(key)
      if (!details) {
        const { policyId, ...ObjWithNoPolicy } = item
        map.set(key, { ...ObjWithNoPolicy, policyIds: [policyId] })
      } else {
        details.policyIds.push(item.policyId)
      }
    }
  })

  return Array.from(map.values())
}

export const extractDummyIds = (list: Array): Array => list.map(dummyIdObj => dummyIdObj.value)

export const getBancCustomerNo = (adviser: Object): string =>
  adviser.identifiers.find(id => id.type === POLICY_MEMBER_IDENTIFIER_PARTY).value

export const getDummyIDsFromMaster = masterList =>
  masterList && masterList.data && masterList.data.dummyAdviserIds
    ? extractDummyIds(masterList.data.dummyAdviserIds)
    : []

export const isAdviserActive = (status: string = '', adviserStatus: Object) => {
  const { STATUS_DEPROVISIONED, STATUS_SUSPENDED } = adviserStatus
  const adviserStatusArray = [STATUS_DEPROVISIONED, STATUS_SUSPENDED]

  return adviserStatusArray.includes(status.toUpperCase())
}

export const getAdviserNumber = (adviser: Object) => {
  if (adviser) {
    const identifiers = get(adviser, 'details.identifiers', [])
    return identifiers.find(id => id.type === POLICY_MEMBER_IDENTIFIER_CUSTOMER).value
  }
  return undefined
}

export const getBancsAgencyCodes = (adviserDetails: Array) => {
  const activeBancsAgencyCodes = adviserDetails.reduce(
    (agencyCodes, { status, bancsAgencyCode }) => {
      if (status === STATUS_ACTIVE) agencyCodes.push(bancsAgencyCode)
      return agencyCodes
    },
    []
  )
  return activeBancsAgencyCodes
}
