// @flow
/* eslint-disable import/no-extraneous-dependencies */
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import axios from 'axios'

// middlewares.
import experienceService from '../middleware/experienceApi'
import sitecoreService from '../middleware/sitecoreApi'
import oktaAuthenticationService from '../middleware/oktaAuthenticationApi'

// reducers.
import makeRootReducer from '../reducers'

let storeInstance = null
let persistorInstance = null

export const init = async (clientEnvConfigObj = null) => {
  const debug = process.env.NODE_ENV !== 'production'
  const middlewares = [thunk, experienceService, sitecoreService, oktaAuthenticationService]
  let updateEnvConfig = clientEnvConfigObj
  // client side persist configuration.
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
      'createQuote',
      'productRules',
      'ure',
      'client',
      'advisor',
      'preAssessment',
      'application',
      'myLink',
      'okta',
      'masterList',
      'customerPersonalDetails', // required because we need to display firstName on header
      'retentionDashboard',
      'authentication',
      'existingClient',
      'policies',
      'supportStaff', // requires to show change adviser nav
      'alterations',
    ],
    debug: process.env.NODE_ENV !== 'production',
  }

  if (debug) {
    if (!clientEnvConfigObj) {
      // fetch env constants and push to redux state
      const envConfig = await axios.get(`${process.env.NODE_API_HOST}/envconfig`)

      if (envConfig.status === 200) {
        updateEnvConfig = { ...updateEnvConfig, config: envConfig.data.data }
      }
    }

    // redux logger for non-production builds.
    if (!process.env.DISABLE_REDUX_LOGGING) {
      const logger = createLogger({ duration: true })
      middlewares.push(logger)
    }
  }

  // create enhanced reducer.
  const reducers = makeRootReducer(updateEnvConfig)
  const rootReducer = persistReducer(persistConfig, reducers())

  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const store = createStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
  )
  store.asyncReducers = {}
  storeInstance = store

  const persistPromise = new Promise(resolve => {
    // persist the store (enabling rehydration) and cache the persistor to control it in future.
    const persistor = persistStore(store, null, () => {
      store.getState()
      resolve(storeInstance)
    })

    persistorInstance = persistor
  })

  return persistPromise
}

export const getInstance = () => storeInstance
export const getPersistor = () => persistorInstance

export default { init, getInstance, getPersistor }
