export const ALTERATION_POLICIES_FETCH = 'ALTERATION_POLICIES_FETCH'
export const ALTERATION_POLICIES_FETCH_INIT = 'ALTERATION_POLICIES_FETCH_INIT'
export const ALTERATION_POLICIES_FETCH_SUCCESS = 'ALTERATION_POLICIES_FETCH_SUCCESS'
export const ALTERATION_POLICIES_FETCH_ERROR = 'ALTERATION_POLICIES_FETCH_ERROR'
export const ALTERATION_POLICIES_FETCH_FAILURE = 'ALTERATION_POLICIES_FETCH_FAILURE'
export const MEMBER_CONTACT_DETAILS_UPDATE = 'MEMBER_CONTACT_DETAILS_UPDATE'
export const MEMBER_CONTACT_DETAILS_UPDATE_INIT = 'MEMBER_CONTACT_DETAILS_UPDATE_INIT'
export const MEMBER_CONTACT_DETAILS_UPDATE_SUCCESS = 'MEMBER_CONTACT_DETAILS_UPDATE_SUCCESS'
export const MEMBER_CONTACT_DETAILS_UPDATE_ERROR = 'MEMBER_CONTACT_DETAILS_UPDATE_ERROR'
export const MEMBER_CONTACT_DETAILS_UPDATE_FAILURE = 'MEMBER_CONTACT_DETAILS_UPDATE_FAILURE'
export const MEMBER_CONTACT_DETAILS_FORM_RESET = 'MEMBER_CONTACT_DETAILS_FORM_RESET'
export const GET_ESCALATION = 'GET_ESCALATION'
export const GET_ESCALATION_INIT = 'GET_ESCALATION_INIT'
export const GET_ESCALATION_SUCCESS = 'GET_ESCALATION_SUCCESS'
export const GET_ESCALATION_ERROR = 'GET_ESCALATION_ERROR'
export const GET_ESCALATION_FAILURE = 'GET_ESCALATION_FAILURE'
export const RESET_ALTS_PRODUCT_RULES = 'RESET_ALTS_PRODUCT_RULES'
export const RESET_ALTS_PRODUCT_RULES_FAILED = 'RESET_ALTS_PRODUCT_RULES_FAILED'
export const GET_PRODUCT_RULES = 'GET_PRODUCT_RULES'
export const GET_PRODUCT_RULES_INIT = 'GET_PRODUCT_RULES_INIT'
export const GET_PRODUCT_RULES_SUCCESS = 'GET_PRODUCT_RULES_SUCCESS'
export const GET_PRODUCT_RULES_ERROR = 'GET_PRODUCT_RULES_ERROR'
export const GET_PRODUCT_RULES_FAILURE = 'GET_PRODUCT_RULES_FAILURE'
export const GET_ALTERATIONS_RULES = 'GET_ALTERATIONS_RULES'
export const GET_ALTERATIONS_RULES_INIT = 'GET_ALTERATIONS_RULES_INIT'
export const GET_ALTERATIONS_RULES_ERROR = 'GET_ALTERATIONS_RULES_ERROR'
export const GET_ALTERATIONS_RULES_FAILURE = 'GET_ALTERATIONS_RULES_FAILURE'
export const GET_ALTERATIONS_RULES_SUCCESS = 'GET_ALTERATIONS_RULES_SUCCESS'
export const SET_ALTERATIONS_POLICIES = 'SET_ALTERATIONS_POLICIES'
export const SET_IS_ESCALATION_API_DONE = 'SET_IS_ESCALATION_API_DONE'
export const SET_USER_ALTERATIONTYPE_SELECTION = 'SET_USER_ALTERATIONTYPE_SELECTION'
export const SET_USER_ALTERATIONTYPE_SELECTION_ALTERATIONSROUTE =
  'SET_USER_ALTERATIONTYPE_SELECTION_ALTERATIONSROUTE'
export const RESET_ALTERATIONS = 'RESET_ALTERATIONS'
export const SET_IS_POLICY_API_DONE_FOR_POLICY_WITHIN_ANIV_PERIOD =
  'SET_IS_POLICY_API_DONE_FOR_POLICY_WITHIN_ANIV_PERIOD'
export const SET_IS_ALTS_CALCULATE_ERROR_MODAL = 'SET_IS_ALTS_CALCULATE_ERROR_MODAL'
export const RESET_ALTS_CALCULATE_FAILURE_COUNT = 'RESET_ALTS_CALCULATE_FAILURE_COUNT'
export const VALIDATE_QUOTE = 'VALIDATE_QUOTE'
export const VALIDATE_QUOTE_INIT = 'VALIDATE_QUOTE_INIT'
export const VALIDATE_QUOTE_SUCCESS = 'VALIDATE_QUOTE_SUCCESS'
export const VALIDATE_QUOTE_ERROR = 'VALIDATE_QUOTE_ERROR'
export const VALIDATE_QUOTE_FAILURE = 'VALIDATE_QUOTE_FAILURE'
export const SET_IS_VALIDATION_ERROR = 'SET_IS_VALIDATION_ERROR'
export const SHOW_ALTS_INCREASED_PREMIUM_MODAL = 'SHOW_ALTS_INCREASED_PREMIUM_MODAL'
export const SET_OR_TOGGLE_POLICYSELECTION_KEY = 'SET_OR_TOGGLE_POLICYSELECTION_KEY'
export const SELECT_LIST_OF_POLICIES = 'SELECT_LIST_OF_POLICIES'
export const DESELECT_ALL_POLICIES = 'DESELECT_ALL_POLICIES'
export const RESET_ALTERATIONS_QUOTE_ERROR = 'RESET_ALTERATIONS_QUOTE_ERROR'
export const SET_SUM_INSURED_ERRORS = 'SET_SUM_INSURED_ERRORS'
export const RESET_SUM_INSURED_ERRORS = 'RESET_SUM_INSURED_ERRORS'
export const SET_IS_CONFIRM_LEAVE_MODAL = 'SET_IS_CONFIRM_LEAVE_MODAL'
export const SET_IS_ALTS_QUOTE_DOWNLOADED = 'SET_IS_ALTS_QUOTE_DOWNLOADED'
export const SET_IS_ALTS_QUOTE_URN = 'SET_IS_ALTS_QUOTE_URN'
export const SET_IS_ALTS_QUOTE_SUBMITTED = 'SET_IS_ALTS_QUOTE_SUBMITTED'
export const SET_IS_SUBMISSION_IN_PROGRESS = 'ALTERATIONS/SET_IS_SUBMISSION_IN_PROGRESS'
export const SET_IS_MAX_DOWNLOAD_LIMIT_MODAL = 'ALTERATIONS/SET_IS_MAX_DOWNLOAD_LIMIT_MODAL'
