// @flow
import { reduceAuthorableFields, renderTextField } from './sitecoreUtils'
import { ACCESS_DENIED } from '../constants/login'
import { QUOTE_TOOL } from '../constants/routes'

type ThirdParty = {
  isFetching: boolean,
  isDataAvailable: boolean,
  error: object,
}

export const getIsFetchingQuote = (thirdParty: ThirdParty) =>
  thirdParty && thirdParty.isFetching && !thirdParty.isDataAvailable && !thirdParty.error

type ProductRules = {
  isFetching: boolean,
  data: [],
  errors: [],
}

export const getIsFetchingProductRules = (productRules: ProductRules) =>
  productRules &&
  productRules.isFetching &&
  productRules.data &&
  !productRules.data.length &&
  !productRules.errors

type MasterList = {
  isFetching: boolean,
}

export const getIsFetchingMasterList = (masterList: MasterList) =>
  masterList && masterList.isFetching

type Fields = Record<string, { value: string } | string>

type GetIsLoginErrorParams = {
  form?: {
    fields: Record<
      string,
      {
        error: {
          error: string,
          errorMsg: string,
        },
      }
    >,
  },
  fields: Fields,
  okta: object,
  authentication: {
    errorCode: string,
    isLoading: boolean,
    hasNetworkFailure: boolean,
    isLocked: boolean,
    isValidIdentity: boolean,
    isError: boolean,
  },
  components: {
    formSecondaryFieldKey: string,
    formFieldKey: string,
  },
}

export const getIsLoginError = ({
  form,
  fields,
  okta,
  authentication,
  components,
}: GetIsLoginErrorParams) => {
  const { errorCode, isLoading, hasNetworkFailure, isLocked, isValidIdentity, isError } =
    authentication

  if (!form || !components) {
    return false
  }

  const {
    oktaErrorNetwork,
    oktaErrorLocked,
    usernameError,
    incorrectCombination,
    IncorrectUserName,
  }: Fields = reduceAuthorableFields(fields)

  const activeFormField =
    form.fields[components.formSecondaryFieldKey] || form.fields[components.formFieldKey]

  const formError = activeFormField && activeFormField.error.error && activeFormField.error.errorMsg

  const apiError =
    (!isLoading &&
      errorCode &&
      fields[errorCode] &&
      (fields[errorCode].value || oktaErrorNetwork)) ||
    (hasNetworkFailure && oktaErrorNetwork) ||
    (isLocked && oktaErrorLocked) ||
    (typeof isValidIdentity !== 'undefined' && !isValidIdentity && usernameError) ||
    (isError && IncorrectUserName) ||
    ''
  const oktaError = okta.errorCode === ACCESS_DENIED ? `${incorrectCombination}` : ''
  const renderError = apiError || formError || oktaError

  return renderError
}

type GetThirdPartyMsgParams = {
  thirdParty: ThirdParty,
  productRules: ProductRules,
  masterList: MasterList,
  fields: Fields,
}

export const getThirdPartyQuoteProgressMsg = ({
  thirdParty,
  productRules,
  masterList,
  fields,
}: GetThirdPartyMsgParams) => {
  const isFetchingQuote = getIsFetchingQuote(thirdParty)
  const isFetchingProductRules = getIsFetchingProductRules(productRules)
  const isFetchingMasterList = getIsFetchingMasterList(masterList)

  if (isFetchingQuote) {
    return renderTextField(fields.retrievingQuoteMsg)
  }
  if (isFetchingProductRules) {
    return renderTextField(fields.applyingProductRulesMsg)
  }
  if (isFetchingMasterList) {
    return renderTextField(fields.loadingQuoteMessage)
  }
  return ''
}

type GetShowThirdPartyLoadingParams = {
  location: URL,
  thirdPartyQuoteProgressMsg: Object | string,
  fields: Fields,
}

export const getShowThirdPartyLoading = ({
  location,
  thirdPartyQuoteProgressMsg,
}: GetShowThirdPartyLoadingParams) =>
  (thirdPartyQuoteProgressMsg && location.pathname !== QUOTE_TOOL) ||
  location.pathname === QUOTE_TOOL
