// @flow
import {
  APPLICATION_STAGE_UPDATE,
  APPLICATION_STAGE_INCREMENT,
  APPLICATION_STAGE_RESET,
} from '../actions/types/application'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

// constants.
import { APPLICATION_STAGE_STEP } from '../constants/application'
import { INIT_STATE } from '../actions/types/navigation'
import { RESET_STATE_FOR_NEW_QUOTE } from '../actions/types/createQuote'

export const initialState = {
  stage: 0,
  stageStatus: {
    stage0: APPLICATION_STAGE_STEP.completed,
    stage1: APPLICATION_STAGE_STEP.incomplete,
    stage2: APPLICATION_STAGE_STEP.incomplete,
    stage3: APPLICATION_STAGE_STEP.incomplete,
  },
}

const application = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case APPLICATION_STAGE_UPDATE: {
      const { stage, status } = action.payload
      return {
        ...state,
        stage,
        stageStatus: {
          ...state.stageStatus,
          ...status,
        },
      }
    }

    case APPLICATION_STAGE_INCREMENT: {
      const { stage } = state
      return {
        ...state,
        // TODO: should we worry about "max stage"?
        stage: stage + 1,
      }
    }

    case APPLICATION_STAGE_RESET: {
      return {
        ...state,
        stage: 0,
      }
    }

    // reset when user logs out.
    case RESET_STATE_FOR_NEW_QUOTE:
    case INIT_STATE:
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default application
