// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import { IconThumbsUp32 } from '../../../../../atoms/Icons'

// styles.
import styles from '../requestManualQuote.styles'

// utilities
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

type SubmittedRequestProps = {
  fields: Object<Object>,
  isAltsQuote: boolean,
}

const Wrapper = styled('div')(styles.wrapper)
const BlockWrap = styled('div')(styles.blockWrap)
const ContentWrap = styled('div')(styles.contentWrap)

export class SubmittedRequest extends PureComponent<SubmittedRequestProps> {
  render() {
    const { fields, isAltsQuote } = this.props
    const { QuoteSuccessMessage, AltsManualQuoteSuccess } = fields
    return (
      <Fragment>
        <Wrapper>
          <BlockWrap>
            <IconThumbsUp32 />
            <ContentWrap>
              {renderTextField(isAltsQuote ? AltsManualQuoteSuccess : QuoteSuccessMessage)}
            </ContentWrap>
          </BlockWrap>
        </Wrapper>
      </Fragment>
    )
  }
}

export default SubmittedRequest
