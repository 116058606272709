// @flow
import { get } from 'lodash'
import moment from 'moment'

export const dualSort =
  (primary, secondary) =>
  ({ values: a }, { values: b }, desc) => {
    const key1 = primary
    const key2 = secondary
    // force null and undefined to the bottom
    const aObj = a === null || a === undefined ? '' : a
    const bObj = b === null || b === undefined ? '' : b
    // force any string values to lowercase
    const a1 = typeof aObj[key1] === 'string' ? aObj[key1].toLowerCase() : aObj[key1]
    const b1 = typeof bObj[key1] === 'string' ? bObj[key1].toLowerCase() : bObj[key1]
    const a2 = typeof aObj[key2] === 'string' ? aObj[key2].toLowerCase() : aObj[key2]
    const b2 = typeof bObj[key2] === 'string' ? bObj[key2].toLowerCase() : bObj[key2]
    // Return either 1 or -1 to indicate a sort priority
    if (a1 === b1) {
      if (a2 > b2) return desc ? -1 : 1
      return desc ? 1 : -1
    }
    if (a1 > b1) return 1
    if (a1 < b1) return -1
    // returning 0, undefined or any falsey value will use subsequent sorts or
    // the index as a tiebreaker
    return 0
  }

export const sortCurrency =
  (primary, secondary) =>
  ({ values: a }, { values: b }, desc) => {
    const key1 = primary
    const key2 = secondary
    // force null and undefined to the bottom
    const aObj = a === null || a === undefined ? '' : a
    const bObj = b === null || b === undefined ? '' : b
    // force any string values to lowercase

    const a1 = parseFloat(aObj[key1].replace(/[$,]/g, ''))
    const b1 = parseFloat(bObj[key1].replace(/[$,]/g, ''))
    const a2 = typeof aObj[key2] === 'string' ? aObj[key2].toLowerCase() : aObj[key2]
    const b2 = typeof bObj[key2] === 'string' ? bObj[key2].toLowerCase() : bObj[key2]
    // Return either 1 or -1 to indicate a sort priority
    if (a1 === b1) {
      if (a2 > b2) return desc ? -1 : 1
      return desc ? 1 : -1
    }
    if (a1 > b1) return 1
    if (a1 < b1) return -1
    // returning 0, undefined or any falsey value will use subsequent sorts or
    // the index as a tiebreaker
    return 0
  }

export const sortDate =
  (primary, secondary, dateFormat = 'DD/MM/YYYY') =>
  ({ values: a }, { values: b }, desc) => {
    const key1 = primary
    const key2 = secondary
    // force null and undefined to the bottom
    const aObj = a === null || a === undefined ? '' : a
    const bObj = b === null || b === undefined ? '' : b
    // force any string values to lowercase

    const a1 = moment(aObj[key1], dateFormat)
    const b1 = moment(bObj[key1], dateFormat)
    const a2 = typeof aObj[key2] === 'string' ? aObj[key2].toLowerCase() : aObj[key2]
    const b2 = typeof bObj[key2] === 'string' ? bObj[key2].toLowerCase() : bObj[key2]

    // Return either 1 or -1 to indicate a sort priority
    if (a1.isSame(b1)) {
      if (a2 > b2) return desc ? -1 : 1
      return desc ? 1 : -1
    }
    if (a1.isAfter(b1)) return 1
    if (a1.isBefore(b1)) return -1
    // returning 0, undefined or any falsey value will use subsequent sorts or
    // the index as a tiebreaker
    return 0
  }

export const sortDateGeneric =
  (primary, secondary, desc, dateFormat = 'DD/MM/YYYY') =>
  (a, b) => {
    const key1 = primary
    const key2 = secondary
    // force null and undefined to the bottom
    const aObj = a === null || a === undefined ? '' : a
    const bObj = b === null || b === undefined ? '' : b
    // force any string values to lowercase

    const a1 = moment(aObj[key1], dateFormat)
    const b1 = moment(bObj[key1], dateFormat)
    const a2 = typeof aObj[key2] === 'string' ? aObj[key2].toLowerCase() : aObj[key2]
    const b2 = typeof bObj[key2] === 'string' ? bObj[key2].toLowerCase() : bObj[key2]

    // Return either 1 or -1 to indicate a sort priority
    if (a1.isSame(b1)) {
      if (a2 > b2) return desc ? -1 : 1
      return desc ? 1 : -1
    }
    if (a1.isAfter(b1)) return 1
    if (a1.isBefore(b1)) return -1
    // returning 0, undefined or any falsey value will use subsequent sorts or
    // the index as a tiebreaker
    return 0
  }

/**
 * Sort list of objects based on a key in object
 * @param {*} primary the object key to sort the records
 * @param {*} desc if true, sorting is done in desc order, esle asc by default
 * @returns array or sorted objects
 */
export const sortByKey =
  (primary, desc = false) =>
  (objA, objB) => {
    const compA = get(objA, 'values', objA)
    const compB = get(objB, 'values', objB)
    const a = compA[primary].toUpperCase()
    const b = compB[primary].toUpperCase()
    if (a < b) return desc ? 1 : -1
    if (a > b) return desc ? -1 : 1
    return 0
  }

export const sortByKeyOfFirst =
  primary =>
  ({ values: compA }, { values: compB }) => {
    const a = compA[primary][0].toUpperCase()
    const b = compB[primary][0].toUpperCase()
    if (a < b) return -1
    if (a > b) return 1
    return 0
  }

export const sortByNumerical =
  primary =>
  ({ values: compA }, { values: compB }) => {
    const a = parseFloat(compA[primary])
    const b = parseFloat(compB[primary])
    return a - b
  }

const checkObject = (obj: object, key: string, innerKey: string) => {
  if (innerKey)
    return typeof obj[key][innerKey] === 'string'
      ? obj[key][innerKey].toLowerCase()
      : obj[key][innerKey]
  return typeof obj[key] === 'string' ? obj[key].toLowerCase() : obj[key]
}

export const sortListResults = (result = [], keyName, innerKeyName): void =>
  [...result].sort((a, b) => {
    const aObj = checkObject(a, keyName, innerKeyName)
    const bObj = checkObject(b, keyName, innerKeyName)
    return aObj > bObj ? 1 : -1
  })

export const sortResultsWithOrderList = (
  result = [],
  keyName,
  orderList = [],
  innerKeyName = ''
): void =>
  [...result].sort((a, b) => {
    const aObj = checkObject(a, keyName, innerKeyName)
    const bObj = checkObject(b, keyName, innerKeyName)
    const lcOrderList = orderList.map(ele => ele.toLowerCase())
    return lcOrderList.indexOf(aObj) - lcOrderList.indexOf(bObj)
  })
