export const OKTA_FORGOT_PASSWORD = 'OKTA_FORGOT_PASSWORD'
export const OKTA_FORGOT_PASSWORD_INIT = 'OKTA_FORGOT_PASSWORD_INIT'
export const OKTA_FORGOT_PASSWORD_SUCCESS = 'OKTA_FORGOT_PASSWORD_SUCCESS'
export const OKTA_FORGOT_PASSWORD_FAILURE = 'OKTA_FORGOT_PASSWORD_FAILURE'
export const OKTA_FORGOT_PASSWORD_ERROR = 'OKTA_FORGOT_PASSWORD_ERROR'

export const OKTA_RESEND_CODE = 'OKTA_RESEND_CODE'
export const OKTA_RESEND_CODE_INIT = 'OKTA_RESEND_CODE_INIT'
export const OKTA_RESEND_CODE_SUCCESS = 'OKTA_RESEND_CODE_SUCCESS'
export const OKTA_RESEND_CODE_FAILURE = 'OKTA_RESEND_CODE_FAILURE'
export const OKTA_RESEND_CODE_ERROR = 'OKTA_RESEND_CODE_ERROR'

export const OKTA_VERIFY_CODE = 'OKTA_VERIFY_CODE'
export const OKTA_VERIFY_CODE_INIT = 'OKTA_VERIFY_CODE_INIT'
export const OKTA_VERIFY_CODE_SUCCESS = 'OKTA_VERIFY_CODE_SUCCESS'
export const OKTA_VERIFY_CODE_ERROR = 'OKTA_VERIFY_CODE_ERROR'
export const OKTA_VERIFY_CODE_FAILURE = 'OKTA_VERIFY_CODE_FAILURE'

export const OKTA_VERIFY_EMAIL_CODE = 'OKTA_VERIFY_EMAIL_CODE'
export const OKTA_VERIFY_EMAIL_CODE_INIT = 'OKTA_VERIFY_EMAIL_CODE_INIT'
export const OKTA_VERIFY_EMAIL_CODE_SUCCESS = 'OKTA_VERIFY_EMAIL_CODE_SUCCESS'
export const OKTA_VERIFY_EMAIL_CODE_ERROR = 'OKTA_VERIFY_EMAIL_CODE_ERROR'
export const OKTA_VERIFY_EMAIL_CODE_FAILURE = 'OKTA_VERIFY_EMAIL_CODE_FAILURE'

export const OKTA_RESET_PASSWORD = 'OKTA_RESET_PASSWORD'
export const OKTA_RESET_PASSWORD_INIT = 'OKTA_RESET_PASSWORD_INIT'
export const OKTA_RESET_PASSWORD_SUCCESS = 'OKTA_RESET_PASSWORD_SUCCESS'
export const OKTA_RESET_PASSWORD_ERROR = 'OKTA_RESET_PASSWORD_ERROR'
export const OKTA_RESET_PASSWORD_FAILURE = 'OKTA_RESET_PASSWORD_FAILURE'

export const OKTA_GET_USER_PROFILE = 'OKTA_GET_USER_PROFILE'
export const OKTA_GET_USER_PROFILE_INIT = 'OKTA_GET_USER_PROFILE_INIT'
export const OKTA_GET_USER_PROFILE_SUCCESS = 'OKTA_GET_USER_PROFILE_SUCCESS'
export const OKTA_GET_USER_PROFILE_FAILURE = 'OKTA_GET_USER_PROFILE_FAILURE'
export const OKTA_GET_USER_PROFILE_ERROR = 'OKTA_GET_USER_PROFILE_ERROR'

export const OKTA_RESET_ERROR_CODE = 'OKTA_RESET_ERROR_CODE'
export const OKTA_RESET_IS_ERROR = 'OKTA_RESET_IS_ERROR'

export const OKTA_UPDATE_USER_PROFILE = 'OKTA_UPDATE_USER_PROFILE'
export const OKTA_UPDATE_USER_PROFILE_INIT = 'OKTA_UPDATE_USER_PROFILE_INIT'
export const OKTA_UPDATE_USER_PROFILE_SUCCESS = 'OKTA_UPDATE_USER_PROFILE_SUCCESS'
export const OKTA_UPDATE_USER_PROFILE_FAILURE = 'OKTA_UPDATE_USER_PROFILE_FAILURE'
export const OKTA_UPDATE_USER_PROFILE_ERROR = 'OKTA_UPDATE_USER_PROFILE_ERROR'

export const OKTA_TRIGGER_QUEUED_CALLBACK = 'OKTA_TRIGGER_QUEUED_CALLBACK'
export const OKTA_RESET_USER_PROFILE_ACCOUNT_STATUS = 'OKTA_RESET_USER_PROFILE_ACCOUNT_STATUS'

export const OKTA_SET_USER_ID = 'OKTA_SET_USER_ID'

export const OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR = 'OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR'
export const OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_INIT =
  'OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_INIT'
export const OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_SUCCESS =
  'OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_SUCCESS'
export const OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_FAILURE =
  'OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_FAILURE'
export const OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_ERROR =
  'OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_ERROR'

export const OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR = 'OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR'
export const OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_INIT =
  'OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_INIT'
export const OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_SUCCESS =
  'OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_SUCCESS'
export const OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_FAILURE =
  'OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_FAILURE'
export const OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_ERROR =
  'OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_ERROR'

export const OKTA_AUTHENTICATION_REQUIRE_PASSWORD_RESET =
  'OKTA_AUTHENTICATION_REQUIRE_PASSWORD_RESET'
export const OKTA_RESET_AUTHENTICATION_STATE = 'OKTA_RESET_AUTHENTICATION_STATE'

export const SHOW_HIDE_IDEAL_TIMEOUT = 'SHOW_HIDE_IDEAL_TIMEOUT'

export const OKTA_FORGOT_USERNAME = 'OKTA_FORGOT_USERNAME'
export const OKTA_FORGOT_USERNAME_INIT = 'OKTA_FORGOT_USERNAME_INIT'
export const OKTA_FORGOT_USERNAME_SUCCESS = 'OKTA_FORGOT_USERNAME_SUCCESS'
export const OKTA_FORGOT_USERNAME_FAILURE = 'OKTA_FORGOT_USERNAME_FAILURE'
export const OKTA_FORGOT_USERNAME_ERROR = 'OKTA_FORGOT_USERNAME_ERROR'

export const OKTA_FORGOT_USERNAME_SET_RECAPTCHA = 'OKTA_FORGOT_USERNAME_SET_RECAPTCHA'
