// @flow
/* eslint-disable no-console, max-len, react/destructuring-assignment */
import React, { Component } from 'react'
import i18n from 'i18next'
import Helmet from 'react-helmet'
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// components
import Layout from './Layout'
import NotFound from './NotFound'
import SitecoreContextFactory from './lib/SitecoreContextFactory'

// configs.
import sitecoreTempConfig from './temp/config'

// utils
import { getRouteData } from './utils/routeUtils'
import { isWhiteListedURL } from './utils/commonUtils'
import { trackPageView } from './utils/inMoment'
// constants
import { LOGIN_ROUTE } from './constants/routes'

import { actionCreators } from './actions'

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

let ssrInitialState = null

type RouteHandlerProps = {
  // Redux actions available to the component.
  actions: Object<Function>,
  // Access to update route using react router.
  history: {
    push: Function,
  },
  // React router public methods and location details.
  route: Object<Object>,
  // Redux authentication
  authentication: Object<Object>,
  // Redux authentication
  navigation: Object,
  // okta
  okta: Object,
  // Redux env constants
  config: Object,
  // redux myLink
  myLink: Object,
}

class RouteHandler extends Component<RouteHandlerProps> {
  constructor(props) {
    super(props)

    this.state = {
      notFound: true,
      routeData: ssrInitialState, // null when client-side rendering
      defaultLanguage: sitecoreTempConfig.defaultLanguage,
    }

    if (ssrInitialState && ssrInitialState.sitecore && ssrInitialState.sitecore.route) {
      // set the initial sitecore context data if we got SSR initial state
      SitecoreContextFactory.setSitecoreContext({
        route: ssrInitialState.sitecore.route,
        itemId: ssrInitialState.sitecore.route.itemId,
        ...ssrInitialState.sitecore.context,
      })
    }

    // route data from react-router - if route was resolved, it's not a 404
    if (props.route !== null) {
      this.state.notFound = false
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (ssrInitialState && (!ssrInitialState.sitecore || !ssrInitialState.sitecore.route)) {
      this.state.notFound = true
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (ssrInitialState && ssrInitialState.context && ssrInitialState.context.language) {
      this.state.defaultLanguage = ssrInitialState.context.language
    }

    // once we initialize the route handler, we've "used up" the SSR data,
    // if it existed, so we want to clear it now that it's in react state.
    // future route changes that might destroy/remount this component should ignore any SSR data.
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
    // (once to find GraphQL queries that need to run, the second time to refresh the view with
    // GraphQL query results)
    // We test for SSR by checking for Node-specific process.env variable.
    if (typeof window !== 'undefined') {
      ssrInitialState = null
    }

    this.componentIsMounted = false
    this.languageIsChanging = false

    // tell i18next to sync its current language with the route language
    // currently disabled while i18n is not in use.
    // this.updateLanguage()
    // optional
  }

  async componentDidMount() {
    const { routeData } = this.state
    const { history, actions } = this.props
    // show/hide ideal session timeout popup on mount
    actions.toggleIdealTimeout(history.location.pathname)
    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData) {
      this.updateRouteData()
    }

    this.componentIsMounted = true
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url
    const { route, actions, history } = this.props
    const newRoute = route.match.url

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isExperienceEditorActive()) {
      window.location.assign(newRoute)
      return
    }

    // show/hide ideal session timeout popup
    actions.toggleIdealTimeout(history.location.pathname)
    // currently disabled while i18n is not in use.
    // this.updateLanguage()
    this.updateBurgerMenu()
    this.updateRouteData()
  }

  componentWillUnmount() {
    this.componentIsMounted = false
  }

  // updates the current app language to match the route data.
  updateLanguage() {
    const newLanguage = this.props.route.match.params.lang || this.state.defaultLanguage

    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true

      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate()
        }
      })
    }
  }

  // loads route data from Sitecore Layout Service into state.routeData
  updateRouteData() {
    const { route, config, actions, history } = this.props
    let sitecoreRoutePath = route.match.params.sitecoreRoute || '/'
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`
    }

    const language = route.match.params.lang || this.state.defaultLanguage

    // get the route data for the new route.
    getRouteData(sitecoreRoutePath, language, {
      sitecoreApiHost: config.MLCL_LAYOUT_SERVICE_API_HOST,
    }).then(routeData => {
      if (routeData !== null) {
        // check for errors from sitecore validation (either unauthorized access or expired access tokens).
        const { securityInfo } = routeData.sitecore.context

        // this isn't going to be provided in disconnected mode.
        if (securityInfo && securityInfo.statusCode) {
          const loginRoute = ''
          const loginRedirectUrl = `/${loginRoute}?redirect=${sitecoreRoutePath}`
          switch (securityInfo.statusCode) {
            // unauthorised.
            case 401:
            // forbidden (invalid access token).
            // eslint-disable-next-line no-fallthrough
            case 403: {
              // clear the token (in case an invalid one exists).
              if (
                sitecoreRoutePath !== LOGIN_ROUTE &&
                !isWhiteListedURL(sitecoreRoutePath).length
              ) {
                actions.signOut()

                // send to login screen.
                history.replace(loginRedirectUrl)
              }

              break
            }
            default: {
              const { displayName } = routeData.sitecore.route
              ReactGA.send({ hitType: 'pageview', page: sitecoreRoutePath, title: displayName })
              trackPageView(sitecoreRoutePath)
              break
            }
          }
        }
        // set the sitecore context data and push the new route.
        SitecoreContextFactory.setSitecoreContext({
          route: routeData.sitecore.route,
          itemId: routeData.sitecore.route.itemId,
          ...routeData.sitecore.context,
        })

        this.setState({ routeData, notFound: false })

        // reset session timeout timer
      } else {
        this.setState({ notFound: true })
      }
    })
  }

  updateBurgerMenu() {
    const { actions, navigation } = this.props
    if (navigation.isOpen) {
      actions.toggleBurgerMenu(false)
    }
  }

  render() {
    const { notFound, routeData } = this.state
    const { actions, authentication, config, okta, myLink } = this.props

    // client-side only 404 handling. no route data for the current route in sitecore.
    if (notFound) {
      return (
        <div>
          <Helmet>
            <title>{i18n.t('Page not found')}</title>
          </Helmet>
          <NotFound />
        </div>
      )
    }

    // don't render anything if the route data or dictionary data is not fully loaded yet.
    // this is a good place for a "Loading" component, if one is needed.
    if (!routeData || this.languageIsChanging) {
      return null
    }

    // render the app's root structural layout.
    return (
      <Layout
        route={routeData.sitecore.route}
        actions={actions}
        okta={okta}
        authentication={authentication}
        config={config}
        myLink={myLink}
      />
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actionCreators,
    },
    dispatch
  ),
})

export const mapStateToProps = state => ({
  config: state.config,
})

// sets the initial state provided by server-side rendering.
// setting this state will bypass initial route data fetch calls.
export function setServerSideRenderingState(ssrState) {
  ssrInitialState = ssrState
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteHandler))
