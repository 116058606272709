// @flow
import React from 'react'
import styled from '@emotion/styled'

// components.
import Heading from '../../../../atoms/Heading'
import IconEdit16 from '../../../../atoms/Icons/IconEdit16'
import IconClose16 from '../../../../atoms/Icons/IconClose16'
import Input from '../../../../atoms/Input'
import Button from '../../../../atoms/Button'

// styles
import styles from './beneficiary.styles'

type BeneficiariesProps = {
  // Name of input field
  name: string,
  // Value for input field
  value: string | number,
  // Does element have an error.
  error: boolean,
  // Error message.
  errorMessage: string,
  // Function for handling edit of beneficiary
  editHandler?: Function | null,
  // Function for handling delete of beneficiary
  deleteHandler: Function,
  // Function for handling input blur
  blurHandler: Function,
  // Function for handling input change
  changeHandler: Function,
  // title of beneficiary
  title: string,
  // Label for input
  label: string,
}

const EditIcon = styled(IconEdit16)(styles.icon)
const DeleteIcon = styled(IconClose16)(styles.icon)
const Wrap = styled('li')(styles.base)
const Header = styled('div')(styles.header)
const Title = styled(Heading)(styles.heading)
const ActionButton = styled(Button)(styles.actionButton)

const Beneficary = ({
  editHandler,
  name,
  value,
  error,
  deleteHandler,
  changeHandler,
  title,
  label,
  errorMessage,
  blurHandler,
}: BeneficiariesProps) => (
  <Wrap>
    <Header>
      <Title element="3" size="6">
        {title}
      </Title>
      {editHandler && (
        <ActionButton type="icon" onClick={editHandler} data-testid="edit">
          <EditIcon />
        </ActionButton>
      )}
      <ActionButton type="icon" onClick={deleteHandler} data-testid="remove">
        <DeleteIcon />
      </ActionButton>
    </Header>
    <Input
      htmlFor={name}
      name={name}
      label={label}
      placeholder="%"
      changeHandler={changeHandler}
      blurHandler={blurHandler}
      value={value}
      error={error}
      caption={error && errorMessage}
      type="decimal"
    />
  </Wrap>
)

Beneficary.defaultProps = {
  editHandler: null,
}

export default Beneficary
