// @flow
import {
  QUOTE_LISTING_FETCH_INIT,
  QUOTE_LISTING_FETCH_SUCCESS,
  QUOTE_DELETION_SUCCESS,
  QUOTE_LISTING_FETCH_ERROR,
  QUOTE_LISTING_FETCH_FAILURE,
  QUOTE_DELETION_INIT,
  QUOTE_DELETION_ERROR,
  QUOTE_DELETION_FAILURE,
  INIT_EMPTY_ROWS,
} from '../actions/types/quotes'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  details: {},
  isSubmitting: false,
  hasFetchError: false,
  hasUpdateError: false,
  search: {
    results: [],
    isLoading: false,
    hasFetchError: false,
  },
}

const quotes = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case INIT_EMPTY_ROWS: {
      return {
        ...state,
        search: {
          results: [
            {
              quoteCollectionId: '',
              quoteCollectionName: '',
              state: '',
              updatedDate: '',
            },
          ],
          isLoading: true,
        },
      }
    }

    case QUOTE_LISTING_FETCH_INIT: {
      return {
        ...state,
        search: {
          results: state.search.results,
          isLoading: true,
        },
      }
    }

    case QUOTE_LISTING_FETCH_SUCCESS: {
      const {
        businessData: { totalRecords, quotes: results },
      } = action.payload
      return {
        ...state,
        search: {
          results: results || [],
          totalRecords,
          isLoading: false,
        },
      }
    }

    case QUOTE_LISTING_FETCH_ERROR:
    case QUOTE_LISTING_FETCH_FAILURE: {
      return {
        ...state,
        search: {
          results: [],
          isLoading: false,
        },
      }
    }
    case QUOTE_DELETION_INIT: {
      return {
        search: {
          ...state.search,
          isLoading: true,
        },
      }
    }

    case QUOTE_DELETION_SUCCESS: {
      return {
        search: {
          ...state.search,
          isLoading: false,
        },
      }
    }

    case QUOTE_DELETION_ERROR:
    case QUOTE_DELETION_FAILURE: {
      return {
        search: {
          ...state.search,
          isLoading: false,
        },
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default quotes
