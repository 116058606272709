// @flow
import moment from 'moment'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'

// eslint-disable-next-line no-unused-vars
import { flatMap } from 'core-js/fn/array/flat-map'
// constants.
import {
  POLICY_PARTY_TYPE_INDIVIDUAL,
  PREFERRED_YES,
  COUNTRY_CODE,
  CONTACT_TYPES_TEL,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
  CONTACT_SUB_TYPES,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_TISO,
  POLICY_BENEFIT_SI,
  POLICY_FEATURE_SGBO,
  PREFERRED_NO,
  POLICY_FEATURE_LSBO,
  POLICY_FEATURE_BO,
  FEATURES_AS_SUB_BENEFITS,
  MANDATORY,
  OPTIONAL,
  INCOME_PROTECTION_PLATINUM_ID,
  POLICY_PRODUCT_CODE_SUPER,
  SUPER_OWNER_EXTERNAL,
  WRAPPER_NAVIGATOR_ID,
  FUND_PAYMENT_METHODS_IOOF,
  POLICY_RELATIONSHIPS_PAYER,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_BENEFIT_PTD,
  BUSINESS_EXPENSE,
  BUSINESS_EXPENSE_PLATINUM,
  POLICY_BENEFIT_PLAT_SGBO,
  POLICY_BENEFIT_STD_SGBO,
  INCOME_PROTECTION_STANDARD_ID,
  POLICY_OAI_BENEFIT_CODE,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  HEALTHY_LIVING_DISCOUNT,
  POLICY_MEMBER_IDENTIFIER_CUSTOMER,
  POLICY_PRODUCT_CODE_NON_SUPER,
  POLICY_NON_SUPER_BENEFIT_PREFIX,
  HEALTHY_LIVING_DISCOUNT_TEXT,
  POLICY_BENEFIT_CI_PLUS,
} from '../constants/policies'
import { REF_NO } from '../constants/adviser'
import { CONTACT_TYPES } from '../constants/forms'
import { DEFAULT_PHONE_CODE } from '../constants/contactDetails'
import { EMPLOYEE_EMP_STATUS_LIST } from '../constants/occupation'
import {
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
  BENEFIT_NATURE_TYPE_RIDER,
  BENEFIT_NATURE_TYPE_STANDALONE,
} from '../constants/benefit'

import { isKeyAvailable, isFeatureEnabledForAP } from './commonUtils'
import {
  DATE_FORMAT,
  ALLOWED_DATE_FORMAT,
  getIdentifier,
  getProduct,
  getBenefit,
  getCoverFeaturesFromPreferences,
  getCoverSubBenefitsFromPreferences,
  getWaitingPeriod,
  getCoverPeriod,
  getCoverStyle,
  getTpdDefinition,
  getDiscounts,
  getEscalations,
  getBenefitTypeVariation,
  isOwnTPDDisabled,
  isParentInstanceSame,
  getLinks,
  getLegacyProductCode,
  isIPCover,
  convertCoverToEntityStructure,
  setRemuneration,
} from './quoteUtils'
import {
  getEmail,
  getPhoneNumber,
  getPreferredItemBreakdown,
  getFullAddressWithState,
  getAddressType,
} from './contactUtils'

import { getBancsCustomerNumber } from './cookieUtils'

export const MM_YYYY = 'MM/YYYY'

export const isSGBOBenefit = (benefitCode: string) =>
  benefitCode === POLICY_BENEFIT_PLAT_SGBO || benefitCode === POLICY_BENEFIT_STD_SGBO

export const getLifeInsuredMemberEntity = (data, occupation) => {
  const { occupationRating = {}, secondaryOccupationRating = {} } = occupation
  let { secondaryOccupationCode } = data
  if (secondaryOccupationRating && secondaryOccupationRating.attributes) {
    secondaryOccupationCode = get(secondaryOccupationRating.attributes, 'Code', '')
  }
  const memberEntityStructure = {
    identifiers: [
      {
        type: REF_NO,
        value: data.identifier ? data.identifier.toString() : moment().unix().toString(),
      },
    ],
    title: get(data, 'title', ''),
    firstName: get(data, 'firstName', ''),
    middleName: get(data, 'middleName', ''),
    lastName: get(data, 'lastName', ''),
    dateOfBirth: moment(get(data, 'dateOfBirth', ''), ALLOWED_DATE_FORMAT, true).format(
      DATE_FORMAT
    ),
    gender: get(data, 'gender', 'Unknown'),
    isSmoker: get(data, 'isSmoker', 'Unknown'),
    employment: {},
    contactMethods: {
      addresses: [],
      phones: [
        {
          number: get(data, 'contactNumber', ''),
          preferred: PREFERRED_YES,
          type: CONTACT_TYPES_TEL,
          subType: CONTACT_SUB_TYPES,
          countryCode: get(data, 'contactNumberCountryCode', '') || COUNTRY_CODE,
          idc: get(data, 'contactNumberPhoneCode', ''),
        },
      ],
      emails: [
        { email: get(data, 'email', ''), preferred: PREFERRED_YES, type: ADDRESS_TYPE_HOME },
      ],
    },
    partyType: get(data, 'partyType', '') || POLICY_PARTY_TYPE_INDIVIDUAL,
    occupationalRating: {
      ...occupationRating,
      ...(secondaryOccupationCode &&
        data.secondaryOccupation && {
          secondaryOccupationCode,
        }),
    },
  }
  if (data.bancsCustomerNo) {
    memberEntityStructure.identifiers.push({
      type: 'BANCS_CUSTOMER_NO',
      value: data.bancsCustomerNo,
    })
  }

  const primaryEmployment = {
    occupationCode: get(
      occupationRating,
      'attributes.OCCP_CODE',
      get(data, 'primaryOccupationCode', '')
    ),
    occupation: get(data, 'primaryOccupation', ''),
    employmentStatus: get(data, 'employmentStatus', ''),
    totalGrossYrlyIncome: get(data, 'earningsPA', 0),
    yearlyIncomeExcludingSuper: get(data, 'earningsPAExcludingSuper'),
  }

  const secondaryEmployment = {
    occupation: get(data, 'secondaryOccupation', ''),
    employmentStatus: get(data, 'employmentStatus', ''),
    occupationCode:
      secondaryOccupationRating && secondaryOccupationRating.attributes
        ? secondaryOccupationCode.toString()
        : get(data, 'secondaryOccupationCode', ''),
    totalGrossYrlyIncome: get(data, 'secondaryEarningsPA', 0),
  }

  const residentialManualAddress = {
    houseNo: get(data, 'residentialHouseNo', ''),
    street: get(data, 'residentialStreet', ''),
    locality: get(data, 'residentialLocality', ''),
    state: get(data, 'residentialState', '').toUpperCase(),
    addressType: ADDRESS_TYPE_HOME,
    country: get(data, 'residentialCountry', '') || COUNTRY_CODE,
    postCode: get(data, 'residentialPostCode', '').toString(),
    preferred: data.isPostalResidentialAddressSame ? PREFERRED_YES : PREFERRED_NO,
  }
  const postalManualAddress = {
    houseNo: get(data, 'postalHouseNo', ''),
    street: get(data, 'postalStreet', ''),
    locality: get(data, 'postalLocality', ''),
    state: get(data, 'postalState', '').toUpperCase(),
    addressType: ADDRESS_TYPE_STATEMENT,
    country: get(data, 'postalCountry', '') || COUNTRY_CODE,
    postCode: get(data, 'postalPostCode', '').toString(),
    preferred: PREFERRED_YES,
  }

  if (data.primaryOccupation) {
    memberEntityStructure.employment = primaryEmployment
  }

  if (data.secondaryOccupation) {
    memberEntityStructure.secondaryEmployment = secondaryEmployment
  }

  if (data.isPostalResidentialAddressSame) {
    memberEntityStructure.contactMethods.addresses = [residentialManualAddress]
  } else {
    memberEntityStructure.contactMethods.addresses = [residentialManualAddress, postalManualAddress]
  }

  if (data.secondaryNumber) {
    memberEntityStructure.contactMethods.phones.push({
      number: get(data, 'secondaryNumber', ''),
      preferred: PREFERRED_NO,
      type: CONTACT_TYPES_TEL,
      subType: CONTACT_SUB_TYPES,
      countryCode: get(data, 'secondaryNumberCountryCode', '') || COUNTRY_CODE,
      idc: get(data, 'secondaryNumberPhoneCode', ''),
    })
  }
  return memberEntityStructure
}

export const getShortStartDateFormat = date =>
  date ? moment(date, 'YYYY-MM-DD').format('MM/YYYY') : ''

export const getLAFromRelationships = (policy: Object) =>
  policy &&
  policy.relationships &&
  policy.relationships.find(member =>
    member.role.find(role => role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
  )

export const getExistingClientDetails = (client: Object, data: Object) => {
  const updatedData = { ...data }
  const details = get(client, 'details', [])
  const contactMethodsClient = get(details, 'contactMethods', [])
  updatedData.title = get(details, 'title', ' ')
  updatedData.firstName = get(details, 'firstName', '')
  updatedData.lastName = get(details, 'lastName', '')
  updatedData.middleName = get(details, 'middleName', '')
  updatedData.email = contactMethodsClient ? getEmail(get(contactMethodsClient, 'emails', [])) : ''
  updatedData.contactNumber = contactMethodsClient
    ? getPhoneNumber(get(contactMethodsClient, 'phones', []), CONTACT_TYPES.TEL)
    : ''
  const residentialAddressClientIndex = contactMethodsClient.addresses.findIndex(
    address => address.preferred === PREFERRED_YES
  )

  const residentialAddressClient = getPreferredItemBreakdown(contactMethodsClient.addresses)
  updatedData.residentialAddress =
    residentialAddressClient && residentialAddressClient.postCode
      ? getFullAddressWithState(contactMethodsClient.addresses)
      : ''
  updatedData.residentialStreet = residentialAddressClient.street
  updatedData.residentialHouseNo = residentialAddressClient.houseNo
  updatedData.residentialLocality = residentialAddressClient.locality
  updatedData.residentialState = residentialAddressClient.state
  updatedData.residentialCountry = residentialAddressClient.country || COUNTRY_CODE
  updatedData.residentialPostCode = residentialAddressClient.postCode
  const postalAddressClient = get(
    details,
    `contactMethods.addresses[${residentialAddressClientIndex === 0 ? 1 : 0}]`
  )
  if (postalAddressClient) {
    updatedData.postalStreet = postalAddressClient.street ? postalAddressClient.street : ''
    updatedData.postalHouseNo = postalAddressClient.houseNo ? postalAddressClient.houseNo : ''
    updatedData.postalLocality = postalAddressClient.locality ? postalAddressClient.locality : ''
    updatedData.postalState = postalAddressClient.state ? postalAddressClient.state : ''
    updatedData.postalCountry = postalAddressClient.country
      ? postalAddressClient.country
      : COUNTRY_CODE
    updatedData.postalPostCode = postalAddressClient.postCode ? postalAddressClient.postCode : ''
    updatedData.postalAddress = `${updatedData.postalHouseNo} ${updatedData.postalStreet}, ${updatedData.postalLocality} ${updatedData.postalState} ${updatedData.postalCountry} ${updatedData.postalPostCode}`
  }
  if (details.identifiers) {
    updatedData.bancsCustomerNo = details.identifiers.find(
      ({ type }) => type === 'BANCS_CUSTOMER_NO'
    ).value
  }

  return updatedData
}

export const isEmployee = employmentStatus => EMPLOYEE_EMP_STATUS_LIST.includes(employmentStatus)

export const getLifeInsuredData = (props: Object) => {
  const {
    createQuote: { quotes, activeIndex, lifeInsuredFirstName, lifeInsuredLastName },
    existingClient,
    isExistingBusiness,
  } = props

  const { memberMandatories, policyStructure } = quotes[activeIndex]
  const relatedMember = getLAFromRelationships(policyStructure[0])
  const relatedParty = relatedMember && relatedMember.relatedParty

  let data = {
    title: '',
    firstName: lifeInsuredFirstName,
    lastName: lifeInsuredLastName,
    dateOfBirth: moment(memberMandatories.dateOfBirth).format('DD/MM/YYYY'),
    email: '',
    isPostalResidentialAddressSame: true,
    employmentStatus: memberMandatories.employmentStatus,
    primaryOccupation: memberMandatories.occupation,
    primaryOccupationCode: memberMandatories.occupationCode,
    earningsPA: memberMandatories.earning,
    ...(isEmployee(memberMandatories.employmentStatus)
      ? { earningsPAExcludingSuper: memberMandatories.earningExcludingSuper }
      : null),
    secondaryOccupation: '',
    secondaryOccupationCode: get(
      memberMandatories.occupationalRating,
      'secondaryOccupationCode',
      ''
    ),
    secondaryEarningsPA: '',
    gender: memberMandatories.gender,
    isSmoker: memberMandatories.isSmoker,
    residentialAddress: '',
    residentialStreet: '',
    residentialHouseNo: '',
    residentialLocality: '',
    residentialState: memberMandatories.stateOfResidence,
    residentialCountry: COUNTRY_CODE,
    residentialPostCode: '',
    postalStreet: '',
    postalHouseNo: '',
    postalLocality: '',
    postalState: '',
    postalCountry: COUNTRY_CODE,
    postalPostCode: '',
    postalAddress: '',
    contactNumberPhoneCode: DEFAULT_PHONE_CODE,
    secondaryNumberPhoneCode: DEFAULT_PHONE_CODE,
  }

  if (relatedParty) {
    const primaryPhoneIndex = get(relatedParty, 'contactMethods.phones', []).findIndex(
      phone => phone.preferred === PREFERRED_YES
    )

    const primaryEmailIndex = get(relatedParty, 'contactMethods.emails', []).findIndex(
      email => email.preferred === PREFERRED_YES
    )

    const postalAddress = get(relatedParty, 'contactMethods.addresses', []).find(
      address => address.addressType === ADDRESS_TYPE_STATEMENT
    )

    const residentialAddress = get(relatedParty, 'contactMethods.addresses', []).find(
      address => address.addressType === ADDRESS_TYPE_HOME
    )
    data.title = relatedParty.title
    data.firstName = relatedParty.firstName
    data.lastName = relatedParty.lastName
    data.middleName = relatedParty.middleName
    if (primaryEmailIndex !== -1) {
      data.email = get(relatedParty, `contactMethods.emails[${primaryEmailIndex}].email`)
    }
    data.isPostalResidentialAddressSame = get(relatedParty, 'contactMethods.addresses.length') === 1
    data.secondaryOccupation = get(relatedParty, 'secondaryEmployment.occupation')
    data.secondaryEarningsPA = get(relatedParty, 'secondaryEmployment.totalGrossYrlyIncome')
    if (primaryPhoneIndex !== -1) {
      data.contactNumber = get(relatedParty, `contactMethods.phones[${primaryPhoneIndex}].number`)
      data.contactNumberPhoneCode = get(
        relatedParty,
        `contactMethods.phones[${primaryPhoneIndex}].idc`,
        DEFAULT_PHONE_CODE
      )
      data.secondaryNumber =
        get(relatedParty, 'contactMethods.phones.length') === 1
          ? ''
          : get(relatedParty, `contactMethods.phones[${primaryPhoneIndex === 0 ? 1 : 0}].number`)
      data.secondaryNumberPhoneCode =
        get(relatedParty, 'contactMethods.phones.length') === 1
          ? data.secondaryNumberPhoneCode
          : get(
              relatedParty,
              `contactMethods.phones[${primaryPhoneIndex === 0 ? 1 : 0}].idc`,
              DEFAULT_PHONE_CODE
            )
    }
    data.residentialAddress =
      residentialAddress && residentialAddress.postCode
        ? getAddressType(residentialAddress, relatedParty.contactMethods.addresses)
        : ''
    data.residentialStreet = residentialAddress.street
    data.residentialHouseNo = residentialAddress.houseNo
    data.residentialLocality = residentialAddress.locality
    data.residentialCountry = residentialAddress.country || COUNTRY_CODE
    data.residentialPostCode = residentialAddress.postCode
    data.postalStreet = postalAddress ? postalAddress.street : ''
    data.postalHouseNo = postalAddress ? postalAddress.houseNo : ''
    data.postalLocality = postalAddress ? postalAddress.locality : ''
    data.postalState = postalAddress ? postalAddress.state : ''
    data.postalCountry = postalAddress ? postalAddress.country : COUNTRY_CODE
    data.postalPostCode = postalAddress ? postalAddress.postCode : ''
    data.postalAddress =
      postalAddress && postalAddress.postCode
        ? `${postalAddress.houseNo} ${postalAddress.street}, ${postalAddress.locality} ${postalAddress.state} ${postalAddress.country} ${postalAddress.postCode}`
        : ''
    data.partyType = relatedParty.partyType
    data.identifier = getIdentifier(relatedMember)
    data.bancsCustomerNo = get(
      relatedParty.identifiers
        ? relatedParty.identifiers.find(({ type }) => type === POLICY_MEMBER_IDENTIFIER_CUSTOMER)
        : {},
      'value',
      null
    )
  } else if (isExistingBusiness) {
    data = getExistingClientDetails(existingClient, data)
  }
  return data
}
export const checkifsumAssuredApplicable = value => (Number(value) ? 'ON' : 'OFF')

export const updateQuoteForTISO = (quote: Object) => ({
  ...quote,
  policyStructure: quote.policyStructure.map(policy => {
    const lcCover = policy.covers.find(c => c.type === POLICY_BENEFIT_LC)
    return {
      ...policy,
      covers: policy.covers.map(cover => ({
        ...cover,
        features:
          cover.type === POLICY_BENEFIT_LC && cover.features && cover.features.length
            ? cover.features.filter(feature => feature.featureName !== POLICY_BENEFIT_TISO)
            : cover.features,
        coverAmount:
          cover.type === POLICY_BENEFIT_TISO ? Number(cover.coverAmount) : cover.coverAmount,
        sumAssuredApplicable:
          cover.type === POLICY_BENEFIT_TISO
            ? checkifsumAssuredApplicable(lcCover.coverAmount)
            : cover.sumAssuredApplicable,
      })),
    }
  }),
})

export const updateSubBenefitApplicable = (
  subBenefitCode: string,
  cover: Object,
  coverProductData: Object,
  isApplicable: boolean = true,
  masterData,
  stateCoverSubBenefits: Object = {}
) => {
  const { validationsIPBenefit } = masterData
  const defaultIPCap = parseInt(
    isKeyAvailable(validationsIPBenefit, 'defaultIPCapValue', '30000'),
    10
  )
  const validLSBOCoverPeriod = validationsIPBenefit.find(
    validation => validation.code === 'validLSBOCoverPeriod'
  )
    ? validationsIPBenefit.find(validation => validation.code === 'validLSBOCoverPeriod')
    : { unit: 'Age', value: '65' }
  return {
    ...cover,
    subBenefits: [
      ...cover.subBenefits,
      ...coverProductData.subBenefits
        .filter(
          subBenefit =>
            get(subBenefit, 'subBenefitType.value', '') === subBenefitCode ||
            get(subBenefit, 'subBenefitCode', '') === subBenefitCode
        )
        .map(subBenefit => {
          const { subBenefitCode: benefitCode = '', subBenefitName = '' } = subBenefit
          const { premium = 0, sumAssured = 0 } = stateCoverSubBenefits
          return {
            subBenefitCode: benefitCode,
            subBenefitName,
            applicable: 'Y',
            mandatoryIndicator: FEATURES_AS_SUB_BENEFITS.includes(benefitCode)
              ? MANDATORY
              : OPTIONAL,
            ...(premium && {
              premium,
            }),
            ...(sumAssured && {
              sumAssured,
            }),
          }
        }),
    ],
    coverAmount:
      isApplicable && subBenefitCode === POLICY_FEATURE_LSBO && cover.coverAmount > defaultIPCap
        ? defaultIPCap
        : cover.coverAmount,
    coverPeriod:
      isApplicable && subBenefitCode === POLICY_FEATURE_LSBO
        ? { unit: validLSBOCoverPeriod.unit, value: validLSBOCoverPeriod.value }
        : cover.coverPeriod,
  }
}

export const removeFeatureInQuote = (quote: Object) => ({
  ...quote,
  policyStructure: quote.policyStructure.map(policy => ({
    ...policy,
    covers: policy.covers.map(cover => ({
      ...cover,
      features: get(cover, 'features', []).filter(
        feature => !FEATURES_AS_SUB_BENEFITS.includes(feature.featureName)
      ),
    })),
  })),
})

export const makeFeatureFromSubBenefits = (
  subBenefits: Array<Object> = [],
  allowableFeatures: Array<Object>
) => {
  const features = []
  const featureAdded = subBenefits.some(subBenefit => {
    const { subBenefitCode } = subBenefit
    if (FEATURES_AS_SUB_BENEFITS.includes(subBenefitCode)) {
      features.push({
        featureName: subBenefitCode,
        featureApplicable: subBenefit.applicable,
      })
      return true
    }
    return false
  })
  if (!featureAdded && allowableFeatures.length) {
    allowableFeatures.forEach(({ code }) => {
      if (FEATURES_AS_SUB_BENEFITS.includes(code)) {
        features.push({
          featureName: code,
          featureApplicable: 'N',
        })
      }
    })
  }
  return features
}

export const makeFeatures = (cover: Object, policy: Object, productData: Array) => {
  const selectedBenefit =
    getBenefit(getProduct(productData, policy.productId)?.benefits, cover.type) || {}

  const { allowableFeatures = {} } = selectedBenefit
  switch (cover.type) {
    case POLICY_BENEFIT_LC:
      return [
        ...(cover.features || []),
        {
          featureName: POLICY_BENEFIT_TISO,
          featureApplicable: policy.covers.some(
            c =>
              c.type === POLICY_BENEFIT_TISO &&
              get(c, 'parentBenefitReference.parentBenefitInstanceNo', '').toString() ===
                cover.benefitInstanceNo.toString()
          )
            ? 'Y'
            : 'N',
        },
      ]
    case POLICY_BENEFIT_CI_PLUS:
      return [
        ...(cover.features || []),
        ...makeFeatureFromSubBenefits(cover.subBenefits, allowableFeatures),
      ]
    case INCOME_PROTECTION_PLATINUM_ID:
      return [
        ...(cover.features || []),
        {
          featureName: POLICY_FEATURE_SGBO,
          featureApplicable: policy.covers.some(
            c =>
              c.type === POLICY_BENEFIT_PLAT_SGBO &&
              get(c, 'parentBenefitReference.parentBenefitInstanceNo', '').toString() ===
                cover.benefitInstanceNo.toString()
          )
            ? 'Y'
            : 'N',
        },
        ...makeFeatureFromSubBenefits(cover.subBenefits, allowableFeatures),
      ]
    case INCOME_PROTECTION_STANDARD_ID:
      return [
        ...(cover.features || []),
        {
          featureName: POLICY_FEATURE_SGBO,
          featureApplicable: policy.covers.some(
            c =>
              c.type === POLICY_BENEFIT_STD_SGBO &&
              get(c, 'parentBenefitReference.parentBenefitInstanceNo', '').toString() ===
                cover.benefitInstanceNo.toString()
          )
            ? 'Y'
            : 'N',
        },
      ]
    default:
      return cover.features
  }
}

// checks for commencement date is empty or not
const commencementDateCheck = policy => {
  if (!Object.prototype.hasOwnProperty.call(policy, 'policyCommencementDate')) {
    return false
  }
  return true
}

// checks if it has atleast one owner or payer
const payerOwnerCheck = (
  { relationships, productId, superFundName, fundPaymentMethod, contributionType },
  value
) => {
  const isInsideSuper = productId === POLICY_PRODUCT_CODE_SUPER
  const isOwnedBySMSF = superFundName === SUPER_OWNER_EXTERNAL
  const role =
    value === POLICY_RELATIONSHIPS_OWNER && isInsideSuper && isOwnedBySMSF
      ? POLICY_RELATIONSHIPS_SMSF
      : value

  // NULIS
  if (
    value === POLICY_RELATIONSHIPS_OWNER &&
    isInsideSuper &&
    !isOwnedBySMSF &&
    ![...FUND_PAYMENT_METHODS_IOOF, WRAPPER_NAVIGATOR_ID].includes(fundPaymentMethod) &&
    !contributionType
  ) {
    return false
  }

  if (relationships && relationships.length) {
    let isOwnerAdded = false

    relationships.forEach(relation => {
      const pyrIndex = indexOf(relation.role, role)
      if (pyrIndex !== -1) {
        isOwnerAdded = true
      }
    })
    if (!isOwnerAdded) {
      return false
    }
    return true
  }
  return false
}

// checks whether policySetup is complete
export const policyCompletionCheck = policy => {
  const policyCompletionStatus = []
  policyCompletionStatus.push(payerOwnerCheck(policy, POLICY_RELATIONSHIPS_OWNER))
  policyCompletionStatus.push(payerOwnerCheck(policy, POLICY_RELATIONSHIPS_PAYER))
  policyCompletionStatus.push(commencementDateCheck(policy))
  return policyCompletionStatus
}

export const isValidIPOccupation = (occupationCode, occupationList) =>
  occupationList.includes(occupationCode)

/**
 * return filtered policyStructure array filled with all
 * benefit and product details with parentBenefitReference
 * @param {Array} policyStructure : Policy Structure
 * @param {Array} productRules : Product Rules
 */
export const generateThirdPartyPolicyStructure: Array = (
  policyStructure: Array<Object>,
  productRules: Array<Object>,
  adviserDetails: Object = {}
) => {
  // create policyStructure array format
  const createPolicyStructure = policyStructure.map(selectedPolicy => {
    const getProductDetails = getProduct(productRules, selectedPolicy.productId)

    const formatCoverEntity = selectedPolicy.covers.map(cover => {
      const getCoverDetails = getBenefit(getProductDetails.benefits, cover.type)
      const { features: coverfeatures = [], subBenefits = [] } = cover
      const coverFeaturesFromPreferences = getCoverFeaturesFromPreferences(getCoverDetails)
      const subBenefitsFromPreferences = getCoverSubBenefitsFromPreferences(getCoverDetails)

      return {
        ...cover,
        name: getCoverDetails.benefitDisplayName,
        premiumAmount: '0',
        stampDuty: 0,
        // TODO: read premiumStructureChangeAge from product json once available from bancs
        premiumStructureChangeAge: get(getCoverDetails, 'premiumStructureChangeAge', ''),
        ...(coverfeatures.length && coverFeaturesFromPreferences.features
          ? {
              features: coverFeaturesFromPreferences.features.map(feature => {
                const selectedFeature =
                  cover.features.find(
                    appliedFeature => appliedFeature.featureName === feature.featureName
                  ) || {}
                return {
                  ...feature,
                  ...(selectedFeature.featureApplicable
                    ? {
                        featureApplicable: selectedFeature.featureApplicable,
                      }
                    : {}),
                }
              }),
            }
          : coverFeaturesFromPreferences),
        subBenefits: [
          ...subBenefits.map(subBenefit => ({
            ...subBenefit,
            mandatoryIndicator: 'M',
          })),
          ...subBenefitsFromPreferences.subBenefits,
        ],
        ...(cover.waitingPeriod || getWaitingPeriod(getCoverDetails)),
        ...(cover.coverPeriod || getCoverPeriod(getCoverDetails)),
        ...(cover.coverStyle ? { coverStyle: cover.coverStyle } : getCoverStyle(getCoverDetails)),

        ...(cover.tpdDefinition
          ? { tpdDefinition: cover.tpdDefinition }
          : getTpdDefinition(getCoverDetails)),
        ...getDiscounts(getCoverDetails),
        ...getEscalations(),
      }
    })

    return {
      ...selectedPolicy,
      agencyDetails: [
        {
          bancsCustomerNo: getBancsCustomerNumber(),
          agencyCode: adviserDetails.agencyCode,
          accountCode: get(adviserDetails, 'commissionProfiles[0].profileCode', 'NW001'),
        },
      ],
      covers: formatCoverEntity,
    }
  })

  return createPolicyStructure
}

export const getCoverDetails = (type: string, benefitList: Array<Object> = []) =>
  benefitList.find(benefit => benefit.code === type)

export const getBancsProfileNumber = (
  productId: string,
  commissionProfile: string,
  adviserDetails: Object = {}
) => {
  const adviserProductsIndex = adviserDetails.products
    ? adviserDetails.products.findIndex(product => product.productId.includes(productId))
    : -1
  if (adviserProductsIndex !== -1) {
    const adviserProduct = adviserDetails.products[adviserProductsIndex]
    const commissionProfileIndex = adviserProduct.commissionProfiles.findIndex(
      profile => profile.commissionStructure === commissionProfile
    )
    return commissionProfileIndex !== -1
      ? adviserProduct.commissionProfiles[commissionProfileIndex].bancsProfileNumber
      : 'CU1S01/MLCI_STEPPED_LIF'
  }
  return 'CU1S01/MLCI_STEPPED_LIF'
}

// benefit linkage utils - starts
export const isParentExists = (cover: Object, policyStructure: Array<Object>) =>
  policyStructure.some(policy =>
    policy.covers.some(
      policyCover =>
        policyCover.type === cover.parentBenefitReference.parentType &&
        policy.policyInstanceNo.toString() ===
          cover.parentBenefitReference.parentPolicyReferenceNo.toString() &&
        policyCover.benefitInstanceNo.toString() ===
          cover.parentBenefitReference.parentBenefitInstanceNo.toString()
    )
  )

export const getCoversLinks = ({
  covers,
  appliedPolicy,
  quote,
  productRules,
  masterData,
}: Object) => {
  const {
    memberMandatories: { occupationClassCode, occupationCode },
  } = quote
  const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode, featureControlSwitch } =
    masterData
  const enableTPDOptimser = isFeatureEnabledForAP(featureControlSwitch, 'enableTPDOptimser')
  const { policyStructure } = quote
  return covers.map(cover => {
    let tPDOptimisedCover
    const { type } = cover
    let isOwnDisable = false
    if (type === POLICY_BENEFIT_PTD) {
      isOwnDisable = isOwnTPDDisabled(
        type,
        occupationClassCode,
        allowedTPDOccupationTypeGroup,
        excludedTPDOccupationTypeCode,
        occupationCode
      )
      policyStructure.find((policy, policyIndex) =>
        policy.covers.find((c, coverIndex) => {
          if (
            c.parentBenefitReference &&
            c.parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER &&
            isParentInstanceSame(c, cover, appliedPolicy.policyInstanceNo)
          ) {
            tPDOptimisedCover = {
              ...c,
              policyIndex,
              policyInstanceNo: policy.policyInstanceNo,
              coverIndex,
            }
            return true
          }
          return false
        })
      )
    }
    return {
      ...cover,
      policyInstanceNo: appliedPolicy.policyInstanceNo,
      benefitLinks: getLinks(
        cover,
        productRules,
        policyStructure,
        appliedPolicy,
        enableTPDOptimser,
        isOwnDisable
      ),
      ...(tPDOptimisedCover ? { optimiserCover: tPDOptimisedCover } : {}),
    }
  })
}

export const getParentBenefitReferenceForCover = ({
  cover,
  policy,
  activeQuote,
  productRules,
  masterData,
}: Object) => {
  if (!cover.parentBenefitReference) {
    return null
  }
  if (isParentExists(cover, activeQuote.policyStructure)) {
    return {
      parentBenefitReference: cover.parentBenefitReference,
    }
  }
  // If parent benefit is updated, create options for benefit
  // linkages and select parentBenefitReference
  // of cover to first option
  const occupationalRating = activeQuote.memberMandatories
    ? activeQuote.memberMandatories.occupationalRating
    : null
  const appliedVariation = getBenefitTypeVariation(
    policy.covers,
    productRules,
    policy.productId,
    occupationalRating
  )
  // update covers with benefit linking where applicable.
  const updatedCovers = getCoversLinks({
    covers: appliedVariation,
    appliedPolicy: policy,
    productRules,
    quote: activeQuote,
    masterData,
  })
  const currentCover = updatedCovers.find(
    updatedCover =>
      updatedCover.type === cover.type &&
      updatedCover.benefitInstanceNo.toString() === cover.benefitInstanceNo.toString()
  )
  const { parentBenefitReference, benefitLinks } = currentCover
  const updatedLinkage =
    parentBenefitReference &&
    parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
      ? [
          ...benefitLinks,
          {
            label: `Optimised to ${parentBenefitReference.parentType} ${parentBenefitReference.parentBenefitInstanceNo} (policy ${parentBenefitReference.parentPolicyReferenceNo})`,
            value: parentBenefitReference,
          },
        ]
      : benefitLinks
  if (
    updatedLinkage &&
    updatedLinkage.length &&
    updatedLinkage[0].value.benefitNature !== BENEFIT_NATURE_TYPE_STANDALONE
  ) {
    return {
      parentBenefitReference: updatedLinkage[0].value,
    }
  }
  return null
}
// benefit linkage utils - ends

// To update policy structure for mlcl templates
// Derive and update parentBenefitReference and optimiserParentBenefitReference for
// optimiser benefits
export const convertPolicyStrutureForOptimiser = (policyStructure: Object) =>
  policyStructure.map(policy => ({
    ...policy,
    covers: policy.covers.map(cover => {
      const { parentBenefitReference, optimiserParentBenefitReference } = cover
      // If it is optimiser benefit (parent or child)
      if (
        parentBenefitReference &&
        parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER &&
        !optimiserParentBenefitReference
      ) {
        // Parent optimiser benefit
        if (
          parentBenefitReference.parentPolicyReferenceNo.toString() ===
          policy.policyInstanceNo.toString()
        ) {
          return {
            ...cover,
            parentBenefitReference: {
              ...parentBenefitReference,
              benefitNature: BENEFIT_NATURE_TYPE_RIDER,
            },
            optimiserParentBenefitReference: parentBenefitReference,
          }
        }

        // Child optimiser benefit
        let parentCover = null
        policyStructure.some(policyStructurePolicy =>
          policyStructurePolicy.covers.some(policyCover => {
            // If parent cover is updated for optimiser, current cover parentBenefitReference
            // will be equal to parent cover optimiserParentBenefitReference else will be equal to
            // parent cover parentBenefitReference
            if (
              (policyCover.parentBenefitReference &&
                policyCover.parentBenefitReference.benefitNature ===
                  parentBenefitReference.benefitNature &&
                policyCover.parentBenefitReference.parentType ===
                  parentBenefitReference.parentType &&
                policyCover.parentBenefitReference.parentBenefitInstanceNo.toString() ===
                  parentBenefitReference.parentBenefitInstanceNo.toString() &&
                policyCover.parentBenefitReference.parentPolicyReferenceNo.toString() ===
                  parentBenefitReference.parentPolicyReferenceNo.toString()) ||
              (policyCover.optimiserParentBenefitReference &&
                policyCover.optimiserParentBenefitReference.benefitNature ===
                  parentBenefitReference.benefitNature &&
                policyCover.optimiserParentBenefitReference.parentType ===
                  parentBenefitReference.parentType &&
                policyCover.optimiserParentBenefitReference.parentBenefitInstanceNo.toString() ===
                  parentBenefitReference.parentBenefitInstanceNo.toString() &&
                policyCover.optimiserParentBenefitReference.parentPolicyReferenceNo.toString() ===
                  parentBenefitReference.parentPolicyReferenceNo.toString())
            ) {
              parentCover = policyCover
              return true
            }
            return false
          })
        )
        return {
          ...cover,
          parentBenefitReference: {
            parentType: parentCover.type,
            parentBenefitInstanceNo: parentCover.benefitInstanceNo,
            parentPolicyReferenceNo: parentBenefitReference.parentPolicyReferenceNo,
            benefitNature: BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
          },
          optimiserParentBenefitReference: parentBenefitReference,
        }
      }
      return cover
    }),
  }))

// friendly error messages
export const getCalQuoteErrorMessage = (error, masterData) => {
  const formattedError = []

  const errorMessages = get(masterData, 'errorMessages', [])
  if (error.code === '105400') {
    const quotedError = get(error, 'description', [])
    let errorCodes = []
    quotedError.forEach((err, index) => {
      errorCodes.push({ code: err.split(',')[0], index })
    })
    errorCodes = uniqBy(errorCodes, 'code')
    errorCodes.forEach(err => {
      const errorMapping = errorMessages.length
        ? errorMessages.find(errMsg => errMsg.code === err.code)
        : {}
      const errorMessage = quotedError[err.index]
      formattedError.push({
        errorTitle: !isEmpty(errorMapping) ? errorMapping.value : '',
        errorContent: errorMessage.substring(errorMessage.indexOf(',') + 1),
      })
    })
  } else {
    const errorMapping = errorMessages.length
      ? errorMessages.find(errMsg => errMsg.code === error.code)
      : {}
    formattedError.push({
      errorTitle: '',
      errorContent: !isEmpty(errorMapping)
        ? errorMapping.value
        : (errorMessages.length && errorMessages.find(errMsg => errMsg.code === '100xxx').value) ||
          get(error, 'description', ''),
    })
  }
  return formattedError
}

export const isLoadinginQuote = (quote: Object) =>
  quote.policyStructure.some(policy => policy.covers.some(cover => cover.loading))

export const isLoadingInQuotes = (quotes: Array<Object>) =>
  quotes.some(quote => isLoadinginQuote(quote))

export const hasSeriesCodeExpired: Boolean = (quote: Object, productData: Array) => {
  const hasValidData =
    quote.policyStructure && quote.policyStructure[0] && productData && productData[0]

  if (!hasValidData) {
    return false
  }

  let { seriesCutOverDate } = productData[0].paymentMethods[0]
  const { latestSeriesCode } = productData[0].paymentMethods[0]

  const currentSeriesBucket = quote.policyStructure[0].legacySystemProductCode.split('/')[1]
  const configuredSeriesBucket = latestSeriesCode.split('/')[1]
  const today = moment()

  seriesCutOverDate = moment(seriesCutOverDate)

  return currentSeriesBucket !== configuredSeriesBucket && today.isAfter(seriesCutOverDate)
}

export const isNotSeriesSpecified: Boolean = (seriesCode: number, quote: Object) =>
  seriesCode.toString() !==
  quote?.policyStructure?.[0]?.legacySystemProductCode?.split('/')[1]?.slice(-1)

export const updateLegacyProductCodeToExistingQuote: Object = (
  quote: Object,
  productData: Array
) => {
  if (hasSeriesCodeExpired(quote, productData)) {
    const modifiedQuote = {
      ...quote,
      policyStructure: quote.policyStructure.map(policy => {
        const { superFundName, premiumPaymentMethod } = policy
        return {
          ...policy,
          legacySystemProductCode: getLegacyProductCode({
            productRules: productData,
            paymentInstructions: superFundName,
            fundPaymentMethod: premiumPaymentMethod,
          }),
        }
      }),
    }
    return modifiedQuote
  }

  return quote
}

export const updateCommissionProfile: Object = (
  quote: Object,
  commissionProfile: String,
  advisorDetails: Object,
  polIndex: Number
) => {
  const modifiedQuote = {
    ...quote,
    policyStructure: quote.policyStructure.map((policy, index) => {
      const { productId } = policy
      return {
        ...policy,
        covers:
          index === polIndex
            ? setRemuneration(policy.covers, { commissionProfile }, productId, advisorDetails)
            : policy.covers,
      }
    }),
  }
  return modifiedQuote
}

export const getPolicyOwnerTitle = (ownersNameList: Array<string>, fields: Object) => {
  const uniqueOwnersNameList = uniq(ownersNameList)
  switch (uniqueOwnersNameList.length) {
    case 0: {
      return ''
    }

    case 1: {
      return uniqueOwnersNameList[0]
    }

    default: {
      return `${uniqueOwnersNameList.slice(0, -1).join(', ')} ${get(
        fields,
        'declarationNameSeparator.value',
        ''
      )} ${uniqueOwnersNameList.slice(-1)[0]}`
    }
  }
}

export const getNextQuoteNumber = (quotes: Array<Object>, nextQuoteNumber?: number) => {
  const quoteNumber = nextQuoteNumber || quotes.length + 1
  const isValidNextQuoteNumber = !quotes.some(
    quote => quote.quoteName === `Quote# ${quoteNumber.toString()}`
  )
  if (isValidNextQuoteNumber) {
    return quoteNumber
  }
  return getNextQuoteNumber(quotes, quoteNumber + 1)
}

// check if it's an BE cover
export const isBECover = cover =>
  !!(cover.type === BUSINESS_EXPENSE || cover.type === BUSINESS_EXPENSE_PLATINUM)

export const getSGBOBenefitCode = (benefitCode: string) =>
  benefitCode === INCOME_PROTECTION_PLATINUM_ID ? POLICY_BENEFIT_PLAT_SGBO : POLICY_BENEFIT_STD_SGBO

export const getBenefitFromProducts = (
  productData: Array<Object>,
  productId: string,
  benefitCode: string
) =>
  productData.find(p => p.productId === productId).benefits.find(c => c.benefitCode === benefitCode)

export const getSubBenefitFromProducts = (
  productData: Array<Object>,
  productId: string,
  benefitCode: string,
  subBenefitCode: string
) => {
  const benefitData = getBenefitFromProducts(productData, productId, benefitCode)
  return benefitData.subBenefits.find(subBenefit => subBenefit.subBenefitCode === subBenefitCode)
}

// check if BO is selected for any of the Income assure cover
export const isBoosterOptionIPAvailable = (quote: Object) => {
  const { policyStructure = [] } = quote
  const coversData = policyStructure.flatMap(({ covers = [] }) => covers)
  return coversData.some(
    cover =>
      cover.type === INCOME_PROTECTION_PLATINUM_ID &&
      cover.features.find(
        feature => feature.featureName === POLICY_FEATURE_BO && feature.featureApplicable === 'Y'
      )
  )
}

// return healthy Living discount value for policy if present
export const healthyLivingDiscountVal = (covers: Array = []) => {
  const campaignData = covers
    .map(({ campaignDetail = [] }) =>
      campaignDetail.find(({ campaignCode }) => campaignCode === HEALTHY_LIVING_DISCOUNT)
    )
    .filter(campaign => !!campaign)
  return get(campaignData, '[0].discountValue', 0)
}

// return specified discount value for policy if present
export const getDiscountVal = (discountName: string = '', discounts: Array = []): number =>
  discounts.find(discount => discount?.discountName === discountName)?.discountValue ?? 0

// return boolean for policy if specified discount name is greater than 0
export const getPolicyHasDiscountAndAmount = (
  discountName: string = '',
  policy: object = {}
): { isPolicyHasDiscount: boolean, discountAmount: number } => {
  let discountAmount = 0
  const result: boolean = !!policy?.covers?.some(({ discounts } = {}) =>
    discounts?.some(discount => {
      if (discount?.discountName === discountName && discount?.discountValue > 0) {
        const { discountValue } = discount
        discountAmount = discountValue
        return true
      }
      return false
    })
  )
  return { isPolicyHasDiscount: result, discountAmount }
}

// check if SGBO is selected for any of the Income assure cover
export const isSGBOOptionIPAvailable = (quote: Object) => {
  const { policyStructure = [] } = quote
  const coversData = policyStructure.flatMap(({ covers = [] }) => covers)
  return coversData.some(
    cover =>
      (cover.type === INCOME_PROTECTION_PLATINUM_ID ||
        cover.type === INCOME_PROTECTION_STANDARD_ID) &&
      cover.features.find(
        feature => feature.featureName === POLICY_FEATURE_SGBO && feature.featureApplicable === 'Y'
      )
  )
}

export const isSGBOOptionSelectedCurrently = (cover: Object) =>
  isIPCover(cover) &&
  cover.features.find(
    feature => feature.featureName === POLICY_FEATURE_SGBO && feature.featureApplicable === 'Y'
  )

export const countSGBOOptions = (policyStructure = []) => {
  const coversData = policyStructure.flatMap(({ covers = [] }) => covers)
  return coversData.filter(cover => isSGBOBenefit(cover.type)).length
}

// Disable IP benefit, if it's already added in the quote. To be removed, IDII-1458
export const disableIPBenefitsIfPresent = (benefits: Array, policyStructure: Object): Array => {
  // total child CI count

  const coversData = policyStructure.flatMap(({ covers = [] }) => covers)
  const countIPcover =
    coversData.length &&
    coversData.reduce(
      (accumulator, currentValue) => (isIPCover(currentValue) ? accumulator + 1 : accumulator),
      0
    )

  return benefits.reduce((accumulator, benefit) => {
    if (isIPCover({ type: benefit.benefitCode }) && countIPcover > 0) {
      return [...accumulator, { ...benefit, disabled: true }]
    }
    return [
      ...accumulator,
      {
        ...benefit,
      },
    ]
  }, [])
}

// Fetch the sum of all Income Assure/Income Assure + benefits
export const totalIPSumInsured = policyStructure => {
  const filteredPolicies = policyStructure.flatMap(
    policy => policy.covers && policy.covers.filter(covers => isIPCover(covers))
  )
  let totalIPSumInsuredValue = 0
  filteredPolicies.forEach(policy => {
    totalIPSumInsuredValue += Number((policy && policy.coverAmount) || 0)
  })
  return totalIPSumInsuredValue
}

// Fetch the sum of all Booster option in Income Assure+ benefits
export const totalBoosterOptionSumInsured = ({ policyStructure, memberMandatories }) => {
  const filteredPolicies = policyStructure.flatMap(policy =>
    policy.covers.some(
      cover =>
        cover.type === INCOME_PROTECTION_PLATINUM_ID &&
        cover.subBenefits &&
        cover.subBenefits.some(
          subBenefit =>
            subBenefit.subBenefitCode === POLICY_FEATURE_BO && subBenefit.applicable === 'Y'
        )
    )
  )
  const { earning, employmentStatus, earningExcludingSuper } = memberMandatories
  const earnings = isEmployee(employmentStatus) ? earningExcludingSuper : earning
  let totalBOSumInsuredValue = 0
  if (filteredPolicies.includes(true)) totalBOSumInsuredValue = Math.floor((earnings * 0.2) / 12)
  return totalBOSumInsuredValue
}

// Fetch the sum of all SGBO option in Income Assure+/ Income Assure benefits
export const totalSGBOptionSumInsured = policyStructure => {
  const filteredPolicies = policyStructure.flatMap(
    policy => policy.covers && policy.covers.filter(cover => isSGBOBenefit(cover.type))
  )
  let totalSGBOSumInsuredValue = 0
  filteredPolicies.forEach(policy => {
    totalSGBOSumInsuredValue += Number((policy && policy.coverAmount) || 0)
  })
  return totalSGBOSumInsuredValue
}

// Update super guarantee rate, per  annum and per month if any of these values gets updated
export const getSGBOFields = ({ name, value, earnings, minSuperGuaranteePA }) => {
  const updatedCoverFields = {
    superGuaranteeRate: {
      superGuaranteeRate: Number(value),
      superGuaranteePA: parseInt(earnings * (value / 100), 10),
      superGuaranteePM: parseInt(Math.floor((earnings * (value / 100)) / 12), 10),
    },
    superGuaranteePA: {
      superGuaranteeRate: Number(((value / earnings) * 100).toFixed(1)),
      superGuaranteePA: Number(value),
      superGuaranteePM: parseInt(Math.floor(value / 12), 10),
    },
    superGuaranteePM: {
      superGuaranteeRate: Number((((value * 12) / earnings) * 100).toFixed(1)),
      superGuaranteePA:
        minSuperGuaranteePA && minSuperGuaranteePA > parseInt(value * 12, 10)
          ? minSuperGuaranteePA
          : parseInt(value * 12, 10),
      superGuaranteePM: Number(value),
    },
  }
  return updatedCoverFields[name]
}

// Fetch SGBO cover data from store based to be rendered in
// options section of parent Income Assure cover
export const getSGBOCoverData = (quote, policyIndex, cover, fromQuoteTool = true) => {
  if (isSGBOOptionSelectedCurrently(cover) && fromQuoteTool) {
    const { earning, employmentStatus, earningExcludingSuper } = quote.memberMandatories
    const earnings = isEmployee(employmentStatus) ? earningExcludingSuper : earning
    return {
      ...getSGBOFields({
        name: 'superGuaranteeRate',
        value: cover.superGuaranteeRate !== undefined ? cover.superGuaranteeRate : 10,
        earnings,
      }),
    }
  }
  const policy = quote.policyStructure[policyIndex]
  const sgboCover =
    policy &&
    policy.covers &&
    policy.covers.find(
      c =>
        c.parentBenefitReference &&
        c.parentBenefitReference.parentBenefitInstanceNo.toString() ===
          cover.benefitInstanceNo.toString() &&
        cover.type === c.parentBenefitReference.parentType
    )
  return sgboCover
}

export const getSuperGuaranteeMaxRate = (productId, productRules, cover) => {
  if (isIPCover(cover)) {
    const sgboBenefitCode = getSGBOBenefitCode(cover.type)
    const sgboBenefit = getBenefitFromProducts(productRules, productId, sgboBenefitCode)
    return get(sgboBenefit, 'superGuaranteePercent[0].value', 0)
  }
  return 0
}

// Filter SGBO feature before calculate quote
// Update Super guarantee rate, per month and per annum
// value if earnings or sum assured of parent cover is updated
export const updateQuoteForSGBO = (
  quote: Object,
  initiateSGBOCalculation: boolean = false,
  productRulesData
) => ({
  ...quote,
  policyStructure: quote.policyStructure.map((policy, policyIndex) => ({
    ...policy,
    covers: policy.covers.map(cover => {
      const { earning, employmentStatus, earningExcludingSuper } = quote.memberMandatories
      const earnings = isEmployee(employmentStatus) ? earningExcludingSuper : earning
      let fields = {}
      let parentCoverFields = {}
      const isSumAssuredRecalculationRequired =
        isSGBOOptionIPAvailable(quote) && isSGBOBenefit(cover.type)
      let updatedFeatures = cover.features
      if (isSumAssuredRecalculationRequired) {
        const parentCover = policy.covers.find(
          cov =>
            cov.benefitInstanceNo.toString() ===
              cover.parentBenefitReference.parentBenefitInstanceNo.toString() &&
            cov.type === cover.parentBenefitReference.parentType
        )
        parentCoverFields = {
          commissionDetails: parentCover.commissionDetails,
          premiumStyle: parentCover.premiumStyle,
          waitingPeriod: parentCover.waitingPeriod,
          coverPeriod: parentCover.coverPeriod,
          loading: parentCover.loading,
        }

        updatedFeatures =
          cover.features &&
          cover.features.map(
            f =>
              (parentCover.features &&
                parentCover.features.find(feature => feature.featureName === f.featureName)) ||
              f
          )

        if (initiateSGBOCalculation) {
          const factorContribution =
            Number(parentCover.coverAmount) / totalIPSumInsured(quote.policyStructure)
          fields = getSGBOFields({
            name: 'superGuaranteeRate',
            value: Number(
              (
                getSuperGuaranteeMaxRate(
                  quote.policyStructure[policyIndex].productId,
                  productRulesData,
                  parentCover
                ) * factorContribution
              ).toFixed(1)
            ),
            earnings,
          })
        }
      }

      if (isIPCover(cover)) {
        updatedFeatures = cover.features.filter(
          feature => feature.featureName !== POLICY_FEATURE_SGBO
        )
      }

      return {
        ...cover,
        ...(isSGBOBenefit(cover.type) && {
          ...parentCoverFields,
          sumAssuredApplicable: get(fields, 'superGuaranteePM', 0) ? 'ON' : 'OFF',
        }),
        ...(isSumAssuredRecalculationRequired &&
          initiateSGBOCalculation && {
            ...fields,
            coverAmount: get(fields, 'superGuaranteePM', 0),
          }),
        features: updatedFeatures,
      }
    }),
  })),
})

// Create SGBO cover entity data based on parent Income assure cover
export const createSGBOcover = (
  productRules: Array<Object>,
  quote: Object,
  activePolicy: Object,
  parentCover: Object,
  advisorDetails: Object,
  stateCover: Object
) => {
  const sgboBenefitCode = getSGBOBenefitCode(parentCover.type)
  const sgboBenefit = getBenefitFromProducts(productRules, activePolicy.productId, sgboBenefitCode)
  const { policyStructure, memberMandatories, option: quoteOption } = quote

  const coverEntity = convertCoverToEntityStructure(
    sgboBenefit,
    activePolicy.covers.filter(c => c.type === sgboBenefitCode).length + 1,
    advisorDetails,
    {},
    policyStructure,
    memberMandatories,
    quoteOption,
    activePolicy
  )
  const { earning, employmentStatus, earningExcludingSuper } = memberMandatories
  const earnings = isEmployee(employmentStatus) ? earningExcludingSuper : earning

  const fields = getSGBOFields({
    name: 'superGuaranteeRate',
    value: stateCover.superGuaranteeRate,
    earnings,
  })

  // Creating entity for SGBO cover
  const updatedCoverWithIP = {
    ...coverEntity,
    ...fields,
    coverAmount: get(fields, 'superGuaranteePM', 0),
    commissionDetails: parentCover.commissionDetails,
    premiumStyle: parentCover.premiumStyle,
    waitingPeriod: parentCover.waitingPeriod,
    coverPeriod: parentCover.coverPeriod,
    benefitInstanceNo: parentCover.benefitInstanceNo,
    policyInstanceNo: activePolicy.policyInstanceNo.toString(),
    loading: parentCover.loading,
    parentBenefitReference: {
      parentPolicyReferenceNo: activePolicy.policyInstanceNo.toString(),
      parentQuotePolicyNo: activePolicy.policyInstanceNo.toString(),
      parentBenefitCode: parentCover.type,
      parentType: parentCover.type,
      parentBenefitInstanceNo: parentCover.benefitInstanceNo,
      benefitNature: 'Satellite',
    },
  }
  return updatedCoverWithIP
}

// check cover type for applicablitity of projections
export const checkCoverForApplyingProject = (cover: Object) =>
  cover.type === POLICY_OAI_BENEFIT_CODE || cover.type === POLICY_CHILD_COVER_BENEFIT_CODE

// Disable CPI checkbox if only IP cover, CCI or OAI is present
export const checkCoversApplicableProjectionPolicy = (selectedPolicy: Object) =>
  selectedPolicy && selectedPolicy.covers.every(cover => checkCoverForApplyingProject(cover))

// Same as checkCoversApplicableProjectionPolicy but we want to do this at a quote level
export const checkQuoteToDisableCPIProjection = quote =>
  quote && quote.policyStructure.every(policy => checkCoversApplicableProjectionPolicy(policy))

// Create subset of memberMandatories
export const excludeKeysMemberMandatories = (obj, keysToOmit) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !keysToOmit.includes(key)))

// get benefit period and waiting period for risked occupation
export const updateRiskedOccCoverPeriod = (coverEntity, validationsIPBenefit, productId) => {
  let cover = coverEntity
  const { benefitPeriods = [], waitingPeriods = [] } =
    validationsIPBenefit.find(
      element =>
        element.benefitCode === INCOME_PROTECTION_STANDARD_ID && element.productCode === productId
    ) || {}
  const filteredWaitingPeriod = waitingPeriods.find(period => period.selected)
  const filteredBenefitPeriod = benefitPeriods.find(period => period.selected)
  cover = {
    ...cover,
    waitingPeriod: {
      unit: filteredWaitingPeriod.unit,
      value: filteredWaitingPeriod.value,
    },
    coverPeriod: {
      unit: filteredBenefitPeriod.unit,
      value: filteredBenefitPeriod.value,
    },
  }
  return cover
}

/** set order of policy benefits like LC followed by TISO, IA/+ followed by booster and SGBO */
export const orderIPBenefits = (
  isBoosterAvailable,
  benefits = [],
  benefitList: Array<Object> = []
) => {
  // eslint-disable-next-line no-param-reassign
  benefits = sortBy(benefits, ['benefitInstanceNo'])

  // Mapping benefit name from MasterData for SGBO benefits
  // eslint-disable-next-line array-callback-return
  benefits.map(benefit => {
    const benefitName = [POLICY_BENEFIT_PLAT_SGBO, POLICY_BENEFIT_STD_SGBO].some(
      val => val === benefit.type
    )
      ? benefitList.find(el => el.code === benefit.type)
      : benefit.benefit || benefit.name

    benefit.benefit = get(benefitName, 'value', benefit.benefit)
    benefit.name = get(benefitName, 'value', benefit.name)
  })

  const allowedAssociatedBenefits = [
    POLICY_BENEFIT_PLAT_SGBO,
    POLICY_BENEFIT_STD_SGBO,
    POLICY_BENEFIT_TISO,
    POLICY_BENEFIT_SI,
  ]
  if (isBoosterAvailable) allowedAssociatedBenefits.push(POLICY_FEATURE_BO)
  const parentBenefits = benefits.filter(
    benefit => !allowedAssociatedBenefits.includes(benefit.type)
  )
  const childBenefits = benefits.filter(benefit => allowedAssociatedBenefits.includes(benefit.type))

  /** categorize the benefits in parent and child category and then
   * loop through the child to find the corresponding parent */

  if (isBoosterAvailable) {
    parentBenefits.map(
      parentBenefit =>
        (parentBenefit.associatedBenefits = childBenefits.filter(
          ({ parentBenefitCode, parentBenefitReferenceNo, parentType }) =>
            (parentBenefit.type === parentBenefitCode &&
              parentBenefit.benefitInstanceNo === parentBenefitReferenceNo) ||
            parentBenefit.type === parentType
        ))
    )
    return parentBenefits
  }
  childBenefits.forEach(sgboBenefit => {
    parentBenefits.forEach((finalBenefit, j) => {
      if (
        finalBenefit.type === get(sgboBenefit, 'parentBenefitReference.parentType', '') &&
        finalBenefit.benefitInstanceNo.toString() ===
          get(sgboBenefit, 'parentBenefitReference.parentBenefitInstanceNo', '').toString()
      ) {
        parentBenefits.splice(j + 1, 0, sgboBenefit)
      }
    })
  })
  return parentBenefits
}

export const checkIfFeatureEnabled = (cover: Object, feature: String) => {
  const { features = [] } = cover
  const { featureApplicable } = features.find(({ featureName }) => featureName === feature) || {}
  return featureApplicable === 'Y'
}

export const isChildOptimiser = (
  coverToCompare: {
    parentBenefitReference: Object,
    optimiserParentBenefitReference: Object,
  },
  parentBenefit: {
    type?: string,
    benefitCode?: string,
    benefitInstanceNo: string,
  },
  policyNo: string
) =>
  !!(
    isParentInstanceSame(coverToCompare, parentBenefit, policyNo) &&
    coverToCompare.optimiserParentBenefitReference
  )

// to find if given benefit is child optimiser and not parent optimiser benefit
export const isOptimiserChildBenefit = (
  coverToCompare: {
    parentBenefitReference: Object,
    optimiserParentBenefitReference: Object,
  },
  parentBenefit: {
    type?: string,
    benefitCode?: string,
    benefitInstanceNo: string,
  },
  policyNo: string
) =>
  !!(
    coverToCompare.optimiserParentBenefitReference &&
    coverToCompare.optimiserParentBenefitReference.parentType === parentBenefit.type &&
    coverToCompare.optimiserParentBenefitReference.parentBenefitInstanceNo.toString() ===
      parentBenefit.benefitInstanceNo.toString() &&
    coverToCompare.optimiserParentBenefitReference.parentPolicyReferenceNo.toString() ===
      policyNo.toString() &&
    coverToCompare.parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
  )

// one of type or benefitCode is mandatory in parentBenefit param
export const getOptimisedChildBenefit = (
  parentBenefit: {
    type?: string,
    benefitCode?: string,
    benefitInstanceNo: string,
  },
  policy: {
    covers: Array<{
      parentBenefitReference: Object,
      optimiserParentBenefitReference: Object,
    }>,
  },
  policyNo: string
) => {
  let childBenefit
  policy.covers.some(cover => {
    if (isChildOptimiser(cover, parentBenefit, policyNo)) {
      childBenefit = cover
      return true
    }
    return false
  })
  return childBenefit
}

export const getOptimiserChildBenefitInPolicies = (
  parentBenefit: {
    type?: string,
    benefitCode?: string,
    benefitInstanceNo: string,
  },
  policyStructure: {
    covers: Array<{
      parentBenefitReference: Object,
      optimiserParentBenefitReference: Object,
    }>,
  }[],
  policyNo: string
) => {
  let childBenefit
  policyStructure.some(policy =>
    policy.covers.some(cover => {
      if (isOptimiserChildBenefit(cover, parentBenefit, policyNo)) {
        childBenefit = { ...cover, policyInstanceNo: policy.policyInstanceNo }
        return true
      }
      return false
    })
  )
  return childBenefit
}

export const hasLinkedChildBenefit = (policyStructure, parentCover, parentPolicyInstanceNo) =>
  policyStructure.find(policy =>
    policy.covers.find(
      cover =>
        parseInt(cover?.parentBenefitReference?.parentPolicyReferenceNo, 10) ===
          parentPolicyInstanceNo &&
        parseInt(cover?.parentBenefitReference?.parentBenefitInstanceNo, 10) ===
          parentCover.benefitInstanceNo &&
        cover?.parentBenefitReference?.parentType === parentCover.type
    )
  )

/** check whether the policy's having
 *  covers that is eligible for healthy living discount or not   */
export const checkQuoteToolPolicyContainHealthyLiving = (policyStructure = []) =>
  policyStructure.some(policy =>
    policy.covers.some(cover => {
      if (cover.campaignDetail) {
        const healthyLivingIndex = cover.campaignDetail.findIndex(
          object => object.campaignCode === HEALTHY_LIVING_DISCOUNT
        )
        if (healthyLivingIndex > -1) return true
      }
      return false
    })
  )

/** check whether the quoteTool containing covers eligible for healthy living discount or not   */
export const checkQuoteToolPolicyEligibleForHealthyLiving = (
  policyStructure = [],
  eligibleHealthyCovers = []
) =>
  policyStructure.some(policy =>
    policy.covers.some(cover =>
      eligibleHealthyCovers.includes(
        policy.productId === POLICY_PRODUCT_CODE_NON_SUPER
          ? `${POLICY_NON_SUPER_BENEFIT_PREFIX}${cover.type}`
          : cover.type
      )
    )
  )

export const getCampaignDetailObject = (isHealthyLivingApplicable, healthyLivingDiscount) => {
  if (isHealthyLivingApplicable && healthyLivingDiscount) {
    return {
      campaignDetail: [
        {
          campaignCode: HEALTHY_LIVING_DISCOUNT,
          campaignName: HEALTHY_LIVING_DISCOUNT_TEXT,
        },
      ],
    }
  }
  return {}
}

/**
 * helper function to map response from quote save/calcuate call back to state
 * @param {*} APIResponse
 * @param {*} state
 * @param {*} productData
 * @param {*} identifier
 * @returns
 */
export const buildQuoteAfterSave = (APIResponse, state, productData, identifier) => {
  const { businessData } = APIResponse
  return {
    ...state,
    ...businessData,
    // TODO: to be removed when CARMs fixes occupationalRating object in memberMandatories
    quotes: state.quotes.map(
      // insert the updated quote at the correct index.
      (quote, index) => ({
        ...businessData.quotes[index],
        // TISO
        policyStructure: businessData.quotes[index].policyStructure.map(policy => ({
          ...policy,
          covers: policy.covers.map(cover => {
            if (cover.parentBenefitReferance && cover.parentBenefitReferance.parentQuotePolicyNo)
              delete cover.parentBenefitReferance.parentQuotePolicyNo
            return {
              ...cover,
              features: makeFeatures(cover, policy, productData),
            }
          }),
        })),
        // spread mandatories over the existing values, in case keys are missing.
        memberMandatories: {
          ...quote.memberMandatories,
          ...businessData.quotes[index].memberMandatories,
        },
      })
    ),
    saveQuoteInitiator: identifier || state.saveQuoteInitiator,
    saveQuoteError: false,
    isFetchingData: false,
  }
}
