// @flow
import get from 'lodash/get'
// constants
import { STATES, TITLES } from '../../../../../constants/forms'

// utils
import {
  date,
  datePast,
  email,
  postcode,
  optional,
  required,
  isNotFormattedDate,
  phone,
  phoneInputs,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../../utils/formUtils'

export const FORM_ID: string = 'addTrustee'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema =
  (fields?: {
    policyOwnerDetailsDateOfBirthFieldError: Object,
    policyOwnerDetailsDateOfBirthFieldFutureDateError: Object,
    policyOwnerDetailsDateOfBirthFieldFormatError: Object,
    policyOwnerDetailsDateOfBirthFieldAgeRangeError: Object,
    policyOwnerDetailsPrimaryNumberError: Object,
    addDirectorStreetAddressFieldError: Object,
    addDirectorCityFieldError: Object,
    addDirectorStateFieldError: Object,
    policyOwnerDetailsPostalAddressCountryFieldError: Object,
    addDirectorPostCodeError: Object,
    policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage: Object,
    policyOwnerDetailsResidentialAddressValidStreetFieldError: Object,
    policyOwnerDetailsResidentialAddressValidLocalityFieldError: Object,
    policyOwnerDetailsResidentialAddressValidStateFieldError: Object,
    policyOwnerDetailsResidentialAddressCountryFieldInvalidError: Object,
    policyOwnerDetailsResidentialAddressValidPostalFieldError: Object,
  }): Function =>
  (state?: Object): Object => ({
    title: {
      condition: required,
      options: TITLES,
    },
    firstName: {
      condition: required,
    },
    middleName: {
      condition: optional,
    },
    lastName: {
      condition: required,
    },
    isPrimary: { condition: optional },
    isLifeAssured: { condition: optional },
    dateOfBirth: {
      condition: [
        required,
        {
          condition: isNotFormattedDate,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldFormatError.value,
        },
        {
          condition: date,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldError.value,
        },
        {
          condition: datePast,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldFutureDateError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldError.value,
    },
    email: {
      condition: email,
    },
    sameAsCompanyAddress: {
      condition: state.isManualPostal || state.address ? optional : required,
    },
    address: {
      condition: state.sameAsCompanyAddress || state.isManualPostal ? optional : required,
    },
    addressUnit: {
      condition: [
        optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value,
            }
          : {},
      ],
    },
    addressStreet: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.addDirectorStreetAddressFieldError.value,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidStreetFieldError.value,
            }
          : {},
      ],
    },
    addressLocality: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.addDirectorCityFieldError.value,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidLocalityFieldError.value,
            }
          : {},
      ],
    },
    addressState: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.addDirectorStateFieldError.value,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidStateFieldError.value,
            }
          : {},
      ],
      options: STATES,
    },
    addressCountry: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsPostalAddressCountryFieldError.value,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressCountryFieldInvalidError.value,
            }
          : {},
      ],
    },
    addressPostCode: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.addDirectorPostCodeError.value,
            }
          : optional,
        state.isManualPostal
          ? {
              condition: manualAddressPostalCode,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidPostalFieldError.value,
            }
          : {},
        postcode,
      ],
    },

    identifiers: {
      condition: optional,
    },
    contactNumber: {
      condition: [
        {
          condition: required,
          errorMsg: get(fields, 'policyOwnerDetailsPrimaryNumberError.value', ''),
        },
        {
          condition: phone,
          errorMsg: get(fields, 'policyOwnerDetailsPrimaryNumberError.value', ''),
        },
      ],
      onChangeCondition: phoneInputs,
      errorMsg: get(fields, 'policyOwnerDetailsPrimaryNumberError.value', ''),
    },
    contactNumberPhoneCode: {
      condition: optional,
    },
    secondaryNumber: {
      condition: [optional, { condition: phone }],
      onChangeCondition: phoneInputs,
      errorMsg: get(fields, 'policyOwnerDetailsPrimaryNumberError.value', ''),
    },
    secondaryNumberPhoneCode: {
      condition: optional,
    },
  })

export default schema
