import get from 'lodash/get'

// constants
import { ELIGIBLE_MLC_ACCOUNT, WRAP } from '../../../../../constants/customerPaymentDetails'

// utils
import { isNonSuperProduct, getActivePolicy } from '../../../../../utils/quoteUtils'

export const paymentDetailsInitData = props => {
  const {
    createQuote: { quotes, activeIndex },
    sidebar,
    form,
  } = props
  const { policyStructure } = quotes[activeIndex]
  const activePolicyInstanceNo = getActivePolicy(sidebar)
  const activePolicy = policyStructure.find(
    policy => policy.policyInstanceNo === activePolicyInstanceNo
  )
  const paymentMethod = get(
    activePolicy,
    'paymentInstruction.collectionMethod',
    get(form, 'fields.paymentDetails.value', '')
  )
  const fundPaymentMethod = get(
    activePolicy,
    'fundPaymentMethod',
    get(form, 'fields.paymentDetails.value', '')
  )
  let paymentDetails = paymentMethod
  if (paymentDetails === ELIGIBLE_MLC_ACCOUNT) {
    paymentDetails = isNonSuperProduct(activePolicy.productId) ? WRAP : fundPaymentMethod
  }
  return {
    paymentDetails,
  }
}
