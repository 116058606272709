/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronRight16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.586 8L5.293 3.707l1.414-1.414L12.414 8l-5.707 5.707-1.414-1.414L9.586 8z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronRight16
