// @flow
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

// constants
import {
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_PRODUCT_CODE_NON_SUPER,
  POLICY_PRODUCT_CODE_SUPER,
  BENEFICIARY_NOMINATION_DOC_CODE,
  AUTHORITY_TO_CANCEL_DOC_CODE,
  FINANCIAL_EVIDENCE_DOC_CODE,
  MEDICAL_EVIDENCE_DOC_CODE,
  QUOTE_DOC_CODE,
  OTHER_DOC_CODE,
  MASTER_KEY_ACCOUNT_ID,
  WRAPPER_NAVIGATOR_ID,
  ROLL_OVER_ID,
  FUND_PAYMENT_METHODS_IOOF,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_RELATIONSHIPS_AGENT,
  POLICY_PARTY_TYPE_INDIVIDUAL,
  TPD_OPTIMISER,
  POLICY_IDENTIFIER_POLICY_ID,
  POLICY_STATUS_NA,
  LIFE_RELATED_POLICY_BENEFITS,
  POLICY_STATE_INFORCE,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  INSIDE_SUPER,
  OUTSIDE_SUPER,
  NON_SUPER_PRODUCT_CLASS,
  ORDINARY_PRODUCT_CLASS,
  POLICY_FEATURE_BO,
  CPI_STATUS_ON,
  CPI_STATUS_OFF,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  BLACKLIST_BENEFITS_COC,
  POLICY_RELATIONSHIPS_LOA,
} from '../constants/policies'
import { CPI, PERIOD_UNIT_AGE } from '../constants/benefit'
import {
  BENF_DOC,
  AUTH_CANCEL_DOC,
  FINANCIAL_DOC,
  MEDICAL_DOC,
  QUOTE_DOC,
  OTHER_DOC,
  PAYMENT_BPAY,
  PAYMENT_CHEQUE,
  PAYMENT_ER,
  INFORCE_DT,
  ADVISOR_SPLIT,
  BENF,
  NAVIGATOR_ACCNT,
  MASTERKEY_ACCNT,
  MASTERKEY_ACCNT_PROVIDED,
  INSIGNIA_ACCOUNT,
  LA_BENF,
  POLICY_LAPSE_STATUS,
} from '../constants/payments'
import { BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'
import { BPAY, CHEQUE, ELIGIBLE_MLC_ACCOUNT } from '../constants/customerPaymentDetails'
import { GENETIC_TEST_KEY } from '../constants/ure'
import {
  BENEFICIARY_TYPE_BINDING_CODE,
  BENEFICIARY_TYPES_CODES,
  BENEFICIARY_TYPE_LAPSING_BINDING_CODE,
  BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE,
  BENEFICIARY_TYPE_NON_BINDING_CODE,
  PRODUCT_APPROVAL_REQUIRED,
} from '../constants/forms'

import { REF_NO } from '../constants/adviser'
import { DOC_TYPE_CERT_CURR } from '../constants/documentTypes'

// utils
import { getBancsCustomerNumber } from './cookieUtils'
import { isOwnerSuperFund, getBancsPartyNoByMemberRole } from './quoteUtils'
import { getFullName } from './contactUtils'
import { dollarAmountWithCommasAndTwoDecimal } from './formUtils'
import { generateCorrelationID } from './commonUtils'

export const getPrimaryPolicyOwner = policyOwners => {
  let policyOwner = {}
  if (policyOwners.length === 1) {
    policyOwner = { ...policyOwners[0] }
  } else if (policyOwners.length > 1) {
    policyOwner = policyOwners.find(
      owner => owner.isPrimaryPolicyOwner && owner.isPrimaryPolicyOwner === 'Y'
    )
  }
  return policyOwner
}

export const getPrimaryLifeInsured = (relationships = []) =>
  relationships.filter(
    relationship =>
      relationship.roleCode === POLICY_RELATIONSHIPS_LIFEASSURED &&
      relationship.isPrimaryLifeAssured === 'Y'
  )

export const getAllLifeInsured = (relationships = [], isQuoteEntity = false) =>
  relationships.filter(relationship =>
    isQuoteEntity
      ? relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
      : relationship.roleCode === POLICY_RELATIONSHIPS_LIFEASSURED
  )

export const formatPhoneNumber = phoneNumber =>
  `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4, 7)} ${phoneNumber.substring(7, 10)}`

export const isCpiStatus = escalations =>
  escalations.some(
    ({ escalationBasis, expiryDate }) =>
      escalationBasis === CPI && moment(expiryDate, 'YYYY-MM-DD').isSameOrAfter(moment())
  )
/**
 * Returns list of objects for all life insured
 * @param {array} policies - policies data
 * @param {boolean} isQuoteEntity - boolean to decide policies data is from
 * policies api or policyStructure in quotes used for alterations
 */
export const getLifeInsuredFromPolicies = (policies, isQuoteEntity = false) => {
  let allLifeInsured = []
  let uniqueBansCustomerNo = []
  policies.forEach(item => {
    const relationships = isQuoteEntity
      ? get(item, 'relationships')
      : get(item, 'policy.relationships')
    if (relationships) {
      const lifeAssured = getAllLifeInsured(relationships, isQuoteEntity)
      let filteredLifeAssured = lifeAssured.filter(
        LA => uniqueBansCustomerNo.indexOf(LA.bancsCustomerNo) === -1
      )
      uniqueBansCustomerNo = uniqueBansCustomerNo.concat(
        filteredLifeAssured.map(LA => LA.bancsCustomerNo)
      )
      filteredLifeAssured = filteredLifeAssured.map(LA => ({
        ...LA,
        bancsPolicyNo: item.bancsPolicyNo,
      }))
      allLifeInsured = allLifeInsured.concat(filteredLifeAssured)
    }
  })

  return allLifeInsured
}

export const getPoliciesForLifeInsured = policies => {
  const allPolicies = []
  policies.forEach(item => {
    if (item && item.policy && item.policy.relationships) {
      const lifeAssured = getPrimaryLifeInsured(item.policy.relationships)
      if (lifeAssured.length > 0) {
        allPolicies.push(item)
      }
    }
  })

  return allPolicies
}

/**
 * check isReferenceNoInBeneficiary
 * @param {Object} beneficiaries
 * @param {number} referenceNumber
 * @return {boolean} return true if Reference Number match in Beneficiary
 */
export const isReferenceNoInBeneficiary = (beneficiaries: Object, referenceNumber: number) => {
  let isValid = false
  if (beneficiaries && beneficiaries.length) {
    beneficiaries.forEach(beneficiarie => {
      const identifiers = get(beneficiarie, 'relatedParty.identifiers', [])
      if (identifiers && identifiers.length) {
        identifiers.forEach(identifier => {
          if (identifier.type === REF_NO && identifier.value === referenceNumber) {
            isValid = true
          }
        })
      }
    })
  }
  return isValid
}

/**
 * get getLifeInsuredReferenceNumber
 * @param {Object} relationships
 * @return {number} return Reference Number
 */
export const getLifeInsuredReferenceNumber = (relationships: Object) => {
  let referenceNumber = null
  if (relationships && relationships.length) {
    relationships.forEach(relationship => {
      if (relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)) {
        const identifiers = get(relationship, 'relatedParty.identifiers', [])
        if (identifiers && identifiers.length) {
          identifiers.forEach(identifier => {
            if (identifier.type === REF_NO) {
              referenceNumber = identifier.value
            }
          })
        }
      }
    })
  }
  return referenceNumber
}

/**
 * check isLifeInsuredSelectedAsBeneficiary
 * @param {Object} code
 * @param {Object} code
 * @return {boolean} return true if Life Insured Selected as a Beneficiary
 */
export const isLifeInsuredSelectedAsBeneficiary = (
  relationships: Object,
  beneficiaries: Object
) => {
  const referenceNumber = getLifeInsuredReferenceNumber(relationships)
  if (referenceNumber) {
    const isMatch = isReferenceNoInBeneficiary(beneficiaries, referenceNumber)
    return isMatch
  }
  return false
}

/**
 * getDocumentCode document identifier by document Code
 * @param {string} code
 * @return {string} document identifier
 */
export const getDocumentCode = (code: string) => {
  switch (code) {
    case BENEFICIARY_NOMINATION_DOC_CODE:
      return BENF_DOC
    case AUTHORITY_TO_CANCEL_DOC_CODE:
      return AUTH_CANCEL_DOC
    case MEDICAL_EVIDENCE_DOC_CODE:
      return MEDICAL_DOC
    case FINANCIAL_EVIDENCE_DOC_CODE:
      return FINANCIAL_DOC
    case QUOTE_DOC_CODE:
      return QUOTE_DOC
    case OTHER_DOC_CODE:
      return OTHER_DOC
    default:
      return null
  }
}

/**
 * getUploadDocuments document list
 * @param {array} documents - uploaded document list
 * @return {array} missing document list
 */
export const getUploadDocument = documents => {
  // upload Documents
  const documentList = []
  if (documents && documents.length) {
    documents.forEach(doc => {
      const documentCode = get(doc, 'documentCode', '')
      const uploadDocumentsCode = getDocumentCode(documentCode)
      if (uploadDocumentsCode) {
        documentList.push(uploadDocumentsCode)
      }
    })
  }
  return documentList
}

/**
 * getPayment payment identifier by Payment Code
 * @param {string} code
 * @return {string} payment identifier
 */
export const getPaymentCode = (code: string) => {
  switch (code) {
    case BPAY:
      return PAYMENT_BPAY
    case CHEQUE:
      return PAYMENT_CHEQUE
    default:
      return null
  }
}

/**
 * getMissingRequirement from Policy
 * @param {Array<Object>} policyStructure - policyStructure
 * @param {Object} ure - ure entity
 * @param {Object} fileUploadInfo - uploaded file details
 * @return {Array} Missing Requirement
 */
export const getMissingRequirement = (
  policyStructure: Array<Object>,
  ure: Object,
  fileUploadInfo: Object,
  quoteCollectionId: string,
  existingConsents: Object
) => {
  const missingRequirementList = []
  const geneticTest = get(ure, 'fullUREresult.geneticTest', false)
  const uploadDocuments = get(fileUploadInfo, `[${quoteCollectionId}]`, [])
  const uploadedDocument =
    uploadDocuments && uploadDocuments.length ? getUploadDocument(uploadDocuments) : []

  if (Array.isArray(policyStructure) && policyStructure.length) {
    policyStructure.forEach(policy => {
      const paymentInstruction = get(policy, 'paymentInstruction', {})
      const policyCommencementDate = get(policy, 'policyCommencementDate', '')
      const beneficiaries = get(policy, 'beneficiaries', [])
      const relationships = get(policy, 'relationships', [])
      const fundPaymentMethod = get(policy, 'fundPaymentMethod', '')
      const policyInstanceNo = get(policy, 'policyInstanceNo', '')
      const productId = get(policy, 'productId', '')
      const commissionSplitDetails = get(policy, 'commissionSplitDetails', {})
      const policyStatus = {
        quotePolicyNo: policyInstanceNo.toString(),
        missingRequirements: [],
      }
      // Add missingRequirement for payment method
      if (paymentInstruction && paymentInstruction.collectionMethod) {
        const { fundDetails, collectionMethod } = paymentInstruction
        let paymentCode = ''
        if (productId === POLICY_PRODUCT_CODE_SUPER) {
          // Super policy
          if (collectionMethod === ELIGIBLE_MLC_ACCOUNT) {
            if (fundPaymentMethod === MASTER_KEY_ACCOUNT_ID) {
              if (fundDetails && fundDetails.externalMembershipNo) {
                policyStatus.missingRequirements.push(MASTERKEY_ACCNT_PROVIDED)
              } else {
                policyStatus.missingRequirements.push(MASTERKEY_ACCNT)
              }
            }
            if (fundPaymentMethod === WRAPPER_NAVIGATOR_ID) {
              if (fundDetails && !fundDetails.externalMembershipNo) {
                policyStatus.missingRequirements.push(NAVIGATOR_ACCNT)
              }
            }
            if (FUND_PAYMENT_METHODS_IOOF.includes(fundPaymentMethod)) {
              if (fundDetails && !fundDetails.externalMembershipNo) {
                policyStatus.missingRequirements.push(INSIGNIA_ACCOUNT)
              }
            }
          }
          if (fundPaymentMethod === ROLL_OVER_ID) {
            if (fundDetails && !fundDetails.externalMembershipNo) {
              policyStatus.missingRequirements.push(PAYMENT_ER)
            }
          } else {
            paymentCode = getPaymentCode(collectionMethod)
          }
        } else if (productId === POLICY_PRODUCT_CODE_NON_SUPER) {
          // Non super policy
          if (collectionMethod === ELIGIBLE_MLC_ACCOUNT) {
            if (fundDetails && !fundDetails.externalMembershipNo) {
              policyStatus.missingRequirements.push(NAVIGATOR_ACCNT)
            }
          } else {
            paymentCode = getPaymentCode(collectionMethod)
          }
        }
        // add the payment code
        if (paymentCode) {
          policyStatus.missingRequirements.push(paymentCode)
        }
      }

      // Add missingRequirement if in force date is not ASAP
      if (policyCommencementDate) {
        const currentDate = moment()
        if (currentDate.diff(moment(policyCommencementDate), 'days', true) < 0) {
          policyStatus.missingRequirements.push(INFORCE_DT)
        }
      }
      // add for commission split details
      if (!isEmpty(commissionSplitDetails)) {
        if (commissionSplitDetails.splitRemuneration) {
          policyStatus.missingRequirements.push(ADVISOR_SPLIT)
        }
      }

      // add missing requirement for document
      if (uploadedDocument && uploadedDocument.length) {
        policyStatus.missingRequirements.push(...uploadedDocument)
      }

      // check for beneficiaries
      if (beneficiaries && beneficiaries.length) {
        // check if life insured is added as beneficiaries
        if (relationships && relationships.length) {
          const isValid = isLifeInsuredSelectedAsBeneficiary(relationships, beneficiaries)
          if (isValid) {
            policyStatus.missingRequirements.push(LA_BENF)
          }
        }

        // in each policy binding type is similar for all the beneficiaries
        if (
          productId === POLICY_PRODUCT_CODE_SUPER &&
          beneficiaries[0].type &&
          beneficiaries[0].type === BENEFICIARY_TYPE_BINDING_CODE
        ) {
          // missing requirement is added if Super policy and a Binding Beneficiary
          policyStatus.missingRequirements.push(BENF)
        }
      }

      // if genetic result in ure
      if (geneticTest) {
        policyStatus.missingRequirements.push(GENETIC_TEST_KEY)
      }

      /* if adviser selects 'General' as the response for Type
       * of Advice question or when the adviser selects 'No'
       * as the response for Target Market Determination question.
       */
      if (
        existingConsents.REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD &&
        existingConsents.REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD.value
      ) {
        policyStatus.missingRequirements.push(PRODUCT_APPROVAL_REQUIRED)
      }

      // check if any missing requirement in policy
      if (policyStatus.missingRequirements.length) {
        missingRequirementList.push(policyStatus)
      }
    })
  }
  return missingRequirementList
}

export const getTheExternalPolicyNo = policy => {
  const identifiers = get(policy, 'policy.identifiers', [])
  if (identifiers.length > 0) {
    const externalPolicyId = identifiers.find(ele => ele.type === 'POLICY_ID')
    return externalPolicyId ? externalPolicyId.value : null
  }
  return null
}

export const getApplicableFeatures = benefit =>
  benefit.features
    ? benefit.features.map(feature => ({
        code: feature.featureName,
        selected: feature.featureApplicable === 'Y',
      }))
    : []

export const getExistingBenefitsFromPolicies = policies => {
  const policiesToHandle = getPoliciesForLifeInsured(policies).filter(
    ({ policy }) => policy.status === POLICY_STATE_INFORCE
  )
  const existingInsurances = policiesToHandle.map((policy, index) => {
    const benefits =
      policy.policy && policy.policy.benefits
        ? policy.policy.benefits.filter(
            ({ benefitStatus }) => benefitStatus === POLICY_STATE_INFORCE
          )
        : []
    return {
      insurer: 'MLC Insurance',
      policyInstanceNo: index + 1,
      policyNo: getTheExternalPolicyNo(policy)
        ? getTheExternalPolicyNo(policy)
        : policy.bancsPolicyNo,
      benefits: benefits.map((benefit, benefitIndex) => ({
        benefitName: benefit.name,
        benefitCode: benefit.type,
        benefitInstanceNo: benefitIndex + 1,
        sumInsured: benefit.sumAssured,
        replacingThisCover: false,
        startdate: benefit.benefitCommencementDate,
        allowableFeatures: getApplicableFeatures(benefit),
      })),
      isFixed: true,
    }
  })

  if (existingInsurances.length) {
    return {
      existingCovers: {
        consent: {
          name: 'EXISTING_COVER',
          value: true,
        },
        existingInsurances,
      },
    }
  }

  return {}
}

const getCoverArray = (existingCoverArray, isFixedFlag) =>
  existingCoverArray && existingCoverArray.length
    ? existingCoverArray.filter(
        cover => (isFixedFlag && cover.isFixed) || (!isFixedFlag && !cover.isFixed)
      )
    : []

export const getUpdatedExistingCovers = (quote, newExistingCoversArray) => {
  const prevExistingCovers = quote.existingCovers.existingInsurances
    ? [...getCoverArray(quote.existingCovers.existingInsurances, true), ...newExistingCoversArray]
    : [...newExistingCoversArray]
  return {
    ...quote,
    existingCovers: { ...quote.existingCovers, existingInsurances: prevExistingCovers },
  }
}

export const getNonFixedExistingCovers = existingClientCovers => {
  const updatedExistingCovers = {
    ...existingClientCovers,
    existingInsurances: getCoverArray(existingClientCovers.existingInsurances, false),
  }
  return updatedExistingCovers
}
// @TODO: I'm hoping this isn't needed anymore,
// API should only return policy data relevant to customer logged in
export const getPoliciesForLoggedInCustomerAsOwner = policies =>
  policies && policies.length > 0
    ? policies.filter(policy => {
        const policyOwners =
          policy.policy && policy.policy.relationships && policy.policy.relationships.length > 0
            ? policy.policy.relationships.filter(
                relationship => relationship.roleCode === POLICY_RELATIONSHIPS_OWNER
              )
            : []

        const primaryPolicyOwner = getPrimaryPolicyOwner(policyOwners)

        if (primaryPolicyOwner && primaryPolicyOwner.bancsCustomerNo === getBancsCustomerNumber()) {
          return true
        }
        if (
          primaryPolicyOwner &&
          primaryPolicyOwner.bancsCustomerNo === BANCS_CUSTOMER_NUMBER_NULIS
        ) {
          const lifeAssured = getPrimaryLifeInsured(policy.policy.relationships)
          return (
            lifeAssured.length > 0 &&
            lifeAssured.find(LA => LA.bancsCustomerNo === getBancsCustomerNumber())
          )
        }
        return false
      })
    : []

export const getPolicyOwner = (
  relationships: Array<Object>,
  getMultipleOwners: boolean = false,
  isQuoteEntity: boolean = false,
  isLAForNulis: boolean = false
) => {
  const superFundDetails = relationships.find(
    relationship =>
      relationship &&
      [BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(
        relationship.bancsCustomerNo
      )
  )
  if (superFundDetails) {
    if (isLAForNulis) {
      return relationships.find(
        relationship => relationship.roleCode === POLICY_RELATIONSHIPS_LIFEASSURED
      )
    }
    return superFundDetails
  }
  const policyOwners =
    relationships && relationships.length > 0
      ? relationships.filter(relationship =>
          isQuoteEntity
            ? relationship.role.includes(POLICY_RELATIONSHIPS_OWNER)
            : relationship.roleCode === POLICY_RELATIONSHIPS_OWNER
        )
      : []
  if (policyOwners.length) {
    return getMultipleOwners
      ? [...policyOwners].sort(a => (a.isPrimaryPolicyOwner === 'Y' ? -1 : 1))
      : getPrimaryPolicyOwner(policyOwners)
  }

  const smsfDetails = relationships.find(relationship =>
    isQuoteEntity
      ? relationship.role.includes(POLICY_RELATIONSHIPS_SMSF)
      : relationship.roleCode === POLICY_RELATIONSHIPS_SMSF
  )

  if (smsfDetails) return get(smsfDetails, 'level1[0]', smsfDetails)
  return false
}

/**
 * Returns true if policy owner's bancsCustomerNo is NULIS or IIML
 * @param {array} policies - policies data
 * @return {Boolean}
 */
export const hasPolicyOwnerSuperFund = (policies = []) =>
  !!policies.data &&
  policies.data.some(policyData =>
    [BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(
      getPolicyOwner(get(policyData, 'policy.relationships', [])).bancsCustomerNo
    )
  )

export const getPolicyOwnerName = policyOwner => {
  const partyType = get(policyOwner, 'relatedParty.partyType', POLICY_PARTY_TYPE_INDIVIDUAL)
  if (policyOwner && policyOwner.bancsCustomerNo === BANCS_CUSTOMER_NUMBER_NULIS) {
    return get(policyOwner, 'relatedParty.businessName', BANCS_CUSTOMER_NUMBER_NULIS)
  }
  return partyType === POLICY_PARTY_TYPE_INDIVIDUAL
    ? getFullName(get(policyOwner, 'relatedParty', {}), false)
    : get(policyOwner, 'relatedParty.businessName', '')
}

export const getPolicyOwnerFirstName = policyOwner => {
  const partyType = get(policyOwner, 'relatedParty.partyType', POLICY_PARTY_TYPE_INDIVIDUAL)
  return partyType === POLICY_PARTY_TYPE_INDIVIDUAL
    ? `${get(policyOwner, 'relatedParty.firstName', '')}`
    : get(policyOwner, 'relatedParty.businessName', '')
}

export const getPolicyDetailsByMemberId = (data, getAdviserPolicy, policyNo) => {
  const {
    businessData: { policies },
  } = data
  const { relations, isSMSF } = policies[0]
  const memberRole = !isOwnerSuperFund(relations) ? 'OWR' : 'LA'
  const bancsPartyNo = getBancsPartyNoByMemberRole(relations, memberRole)
  if (bancsPartyNo) {
    getAdviserPolicy(bancsPartyNo, isSMSF, policyNo)
  }
}

export const checkIfValidBenefitStatusCode = (statusCode, benefitStatus) => {
  if (!!statusCode && !!benefitStatus) {
    const obj = benefitStatus && benefitStatus.find(codeObject => codeObject.code === statusCode)
    return !!obj
  }
  return false
}

export const getAllInForcePolicies = (policies = [], policyStatus = []) =>
  policies.filter(policy => {
    const status = get(policy, 'status', get(policy, 'policy.status', ''))
    const currentStatus = get(
      policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
      'value',
      POLICY_STATUS_NA
    )
    return currentStatus.toUpperCase() === POLICY_STATUS_NA
  })

export const getPoliciesCodesForReporting = (policyStatus = [], isInforced) =>
  policyStatus
    .filter(({ value, isPolicyIncludedForReporting }) => {
      const condition = isInforced
        ? value.toUpperCase() === POLICY_STATUS_NA
        : value.toUpperCase() !== POLICY_STATUS_NA
      return condition && isPolicyIncludedForReporting
    })
    .map(({ status }) => status)

export const getBenefitCodesForReporting = (policyStatus = [], isInforced) =>
  policyStatus
    .filter(({ value, isBenefitIncludedForReporting }) => {
      const condition = isInforced
        ? value.toUpperCase() === POLICY_STATUS_NA
        : value.toUpperCase() !== POLICY_STATUS_NA
      return condition && isBenefitIncludedForReporting
    })
    .map(({ status }) => status)

export const isBenefitInForce = (benefit: Object, policyStatus: Array<Object>) => {
  const status = get(benefit, 'benefitStatus', '')
  const currentStatus = get(
    policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
    'value',
    ''
  )
  return currentStatus.toUpperCase() === POLICY_STATUS_NA
}

export const getAllInForceBenefits = (benefits = [], policyStatus = []) =>
  benefits.filter(benefit => isBenefitInForce(benefit, policyStatus))

export const getAllOutOfForcePolicies = (policies = [], policyStatus = []) =>
  policies.filter(policy => {
    const status = get(policy, 'status', get(policy, 'policy.status', ''))
    const currentStatus = get(
      policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
      'value',
      POLICY_STATUS_NA
    )
    return !(currentStatus.toUpperCase() === POLICY_STATUS_NA)
  })

/**
 * @desc Returns the policyId when isSearchable is true for the matching policy status
 * @param {*} policies the policies array
 * @param {*} policyStatus the PolicyStatus masterdata
 * @param {*} searchInput the policy being searched
 */
export const getPolicyIDBySearchInput = (policies, policyStatus, searchInput) => {
  const findPolicy =
    policies &&
    policies.length &&
    policies.find(pol => {
      const policyIdentifier = pol.policy.identifiers.find(
        ({ type }) => type === POLICY_IDENTIFIER_POLICY_ID
      )
      return policyIdentifier.value === (searchInput && searchInput.trim().toUpperCase())
    })
  if (isEmpty(findPolicy)) return false
  const status = get(findPolicy, 'policy.status', '')
  const isSearchable = get(
    policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
    'isSearchable',
    false
  )

  return isSearchable ? findPolicy.policy.identifiers[1].value : false
}

/*
 *
 *   @desc     TPD Otpimiser utils
 *
 *   @method   addOptimiserSuffixToBenefitName
 *     @desc   append suffix (Optimiser) to benefit name if
 *             optimiserParentBenefitReference is present
 *     @return {string}
 *
 *   @method   convertBenefitCodeToFullName
 *     @desc   convert benefit code to their full name -> LC to Life Cover
 *     @return {string}
 *
 *   @method   isTdpOptimised
 *     @desc   checks if benefit has optimiserParentBenefitReference, if yes,
 *             creates a label that describes if the policy has parentBenefitReference or
 *             optimiserParentBenefitReference & is linked with parentExternalPolicyNo or
 *             parentPolicyNo
 *     @return {string}
 *
 */

export const addOptimiserSuffixToBenefitName = (
  policy: Object,
  isTpdDefinition: boolean = true
) => {
  const isOptimiserParentBenefitReference = get(policy, 'optimiserParentBenefitReference', false)

  const tpdDefinition = get(policy, 'tpdDefinition', '')

  const checkTpdDefinition = Array.isArray(tpdDefinition) ? tpdDefinition[0] : tpdDefinition

  const applyTpdDefinition = isTpdDefinition ? `(${checkTpdDefinition})` : ''

  if (isOptimiserParentBenefitReference)
    return `${policy.name} ${applyTpdDefinition} ${TPD_OPTIMISER}`
  return policy.name || ''
}

export const convertBenefitCodeToFullName = (masterList: Object, benefitCode: String) => {
  const isBenefitCode = ({ code }) => code === benefitCode
  const benefitList = get(masterList, 'benefitList', []).find(isBenefitCode)
  return get(benefitList, 'value', '')
}

export const convertFeatureCodeToFullName = (masterListData, benefitCode, benefitDuration) => {
  const isBenefitCode = ({ code, duration }) =>
    duration ? code === benefitCode && duration === benefitDuration : code === benefitCode
  const featuresList = get(masterListData, 'features', []).find(isBenefitCode)
  return get(featuresList, 'value', '')
}

// May need to fix
export const isTdpOptimised = (
  benefit: Object,
  masterList: Object,
  policyIdentifierMappings = []
) => {
  const parentBenefitReference = get(benefit, 'parentBenefitReference', false)
  const benefitReference = get(benefit, 'optimiserParentBenefitReference', parentBenefitReference)
  if (benefitReference) {
    const {
      parentType,
      parentBenefitCode,
      parentBenefitInstanceNo,
      parentExternalPolicyNo,
      parentPolicyReferenceNo,
      parentPolicyNo,
    } = benefitReference
    let policyText = ''

    let parentReferencePolicyNumber = parentExternalPolicyNo || parentPolicyReferenceNo
    if (!parentReferencePolicyNumber && parentPolicyNo) {
      // This just finds the policy ID based on the internal bancs number
      const match = policyIdentifierMappings.find(mapping => {
        const bancsCustomerNo = get(
          mapping.find(item => item.type === 'BANCS_POLICY_NO'),
          'value',
          null
        )
        return bancsCustomerNo === parentPolicyNo
      })
      parentReferencePolicyNumber = get(
        (match || []).find(item => item.type === 'POLICY_ID'),
        'value',
        null
      )
    }
    if (parentReferencePolicyNumber) {
      policyText = ` of Policy ${parentReferencePolicyNumber}`
    }
    return `${convertBenefitCodeToFullName(
      masterList,
      parentBenefitCode || parentType
    )} ${parentBenefitInstanceNo}${policyText}`
  }
  return undefined
}

/**
 * @desc Returns array of user readable information for lives Aasured per policy benefit
 * @param {*} benefitAssured array of lives assured for benefit
 * @param {*} relationships Relationships of policy object
 */

export const getLivesAssuredForBenefits = (benefitAssured, relationships) => {
  const livesAssuredCustomerIds = benefitAssured.map(({ bancsCustomerNo }) => bancsCustomerNo)
  return relationships.filter(
    ({ bancsCustomerNo, roleCode }) =>
      livesAssuredCustomerIds.indexOf(bancsCustomerNo) > -1 &&
      roleCode === POLICY_RELATIONSHIPS_LIFEASSURED
  )
}

export const getConnectedBenefits = (policies: Array, policy: Object): Array => {
  if (
    policy?.policy?.benefits?.every(
      benefit => !benefit.linkedChildBenefitReference && !benefit.parentBenefitReference
    )
  ) {
    return null
  }
  const connectedBenefitsInfo = new Set()
  const connectedPolicyBancsNo = []
  let policyConnected
  get(policy, 'policy.benefits', []).forEach(benefitEach => {
    policies.forEach(policyData => {
      if (
        get(benefitEach, 'linkedChildBenefitReference', [])
          .concat(get(benefitEach, 'parentBenefitReference', []))
          .some(
            linkedReference =>
              linkedReference.linkedChildPolicyNo === policyData.bancsPolicyNo ||
              linkedReference.parentPolicyNo === policyData.bancsPolicyNo
          )
      ) {
        policyConnected = policyData
        connectedPolicyBancsNo.push(policyConnected.bancsPolicyNo)
      }

      const connectedPolicyID = policyConnected?.policy?.identifiers
        ? policyConnected.policy.identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID)
            .value
        : get(policyConnected, 'bancsPolicyNo')
      const connectedBenefitName = policyConnected?.policy?.benefits?.find(benefit =>
        get(benefitEach, 'linkedChildBenefitReference', [])
          .concat(get(benefitEach, 'parentBenefitReference', []))
          .some(
            linkedReference =>
              (linkedReference.linkedChildBenefitInstanceNo === benefit.benefitInstanceNo ||
                linkedReference.parentBenefitInstanceNo === benefit.benefitInstanceNo) &&
              (linkedReference.linkedChildBenefitCode === benefit.type ||
                linkedReference.parentType === benefit.type)
          )
      )?.name
      connectedPolicyID &&
        connectedBenefitName &&
        connectedBenefitsInfo.add(`${connectedPolicyID} ${connectedBenefitName}`)
    })
  })

  return { connectedBenefitsInfo, connectedPolicyBancsNo }
}
export const getLifeInsuredNameList = (benefitAssured, relationships) =>
  getLivesAssuredForBenefits(benefitAssured, relationships)
    .map(({ relatedParty: { firstName, lastName } }) => `${firstName} ${lastName}`)
    .join(', ')

export const getExternalPolicyNumbers = (policies = {}) =>
  policies.data
    ? policies.data.map(policy => {
        const identifiers = get(policy, 'policy.identifiers', [])
        const policyId = identifiers.find(
          identifier => identifier.type === 'POLICY_ID' && identifier.value
        ) || { value: '' }
        return policyId.value
      })
    : []
export const sortLifeAssuredByPolicyOwner = (lifeAssured, bancsCustomerNo) => {
  const lifeAssuredIsPolicyOwner = lifeAssured.bancsCustomerNo === bancsCustomerNo
  return lifeAssuredIsPolicyOwner ? -1 : 0
}

const EXTERNAL_SUPER_PRODUCT_CLASS = 'External Super'

export const filterOutIOOFOwnedPolicies = ({ policyOwners }: Object) =>
  !policyOwners.includes(BANCS_CUSTOMER_NUMBER_IOOF)

// @TODO: currently used for CP, use shouldShowBeneficiaries from ./policyUtilsTs.ts
// for any new code
export const showBeneficiariesBasedOnProductClassAndLifeRelatedBenefitsStatus = ({
  productClass,
  benefits = [],
} = {}) =>
  productClass !== EXTERNAL_SUPER_PRODUCT_CLASS &&
  benefits.some(
    ({ type, benefitStatus }) =>
      LIFE_RELATED_POLICY_BENEFITS.includes(type) && benefitStatus === POLICY_STATE_INFORCE
  )

export const showBeneficiariesBasedOnAllPolicies = (policies = []) =>
  policies.some(showBeneficiariesBasedOnProductClassAndLifeRelatedBenefitsStatus)

export const isPolicyLapseDateOverThanCertainDays = (policy, days) => {
  if (policy) {
    if (policy.benefits && policy.benefits.length > 0) {
      let latestLapsedDate = policy.benefits[0].benefitStatusDate
      policy.benefits.forEach(benefit => {
        if (benefit.benefitStatus === POLICY_LAPSE_STATUS) {
          if (moment(benefit.benefitStatusDate).isAfter(latestLapsedDate)) {
            latestLapsedDate = benefit.benefitStatusDate
          }
        }
      })

      const currentDate = moment()
      return currentDate.diff(moment(latestLapsedDate), 'days') > days
    }
  }
  return false
}

export const hasBindingBeneficiaries = (beneficiaries = []) =>
  beneficiaries.every(benefeciary =>
    [BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE, BENEFICIARY_TYPE_LAPSING_BINDING_CODE].includes(
      benefeciary.bindingType
    )
  )
export const hasNonBindingBeneficiaries = (beneficiaries = []) =>
  beneficiaries.every(benefeciary => benefeciary.bindingType === BENEFICIARY_TYPE_NON_BINDING_CODE)

// FIXME: This must be moved onto masterdata as it may change during production. MM
export const PLACEHOLDER_BENEFICIARY_BLACKLIST = ['CU1C01T6662083', 'CU1C01T6633426']

// only validating non-seo benefeciaries
export const checkIfPlaceholderOrCorruptBeneficiaries = (beneficiaries = []) => {
  const missingData = beneficiaries.some(
    benefeciary =>
      !benefeciary.relationshipType ||
      !benefeciary.bindingType ||
      PLACEHOLDER_BENEFICIARY_BLACKLIST.includes(benefeciary.bancsCustomerNo)
  )
  const mixOfBindingAndNonBinding =
    !hasBindingBeneficiaries(beneficiaries) && !hasNonBindingBeneficiaries(beneficiaries)

  return missingData || mixOfBindingAndNonBinding
}

export const getBindingTypeText = bindingType => {
  const match = BENEFICIARY_TYPES_CODES.find(code => code.value === bindingType)
  return match && match.label
}

export const getBenefeciaryName = relatedParty => {
  const { firstName, middleName, lastName, businessName, partyType } = relatedParty
  return partyType === POLICY_PARTY_TYPE_INDIVIDUAL
    ? [firstName, middleName, lastName].filter(Boolean).join(' ')
    : businessName
}

export const isBenefitAssuredForLifeInsured = ({ benefitAssured = [] }, selectedLifeInsured) =>
  benefitAssured.every(ele => typeof ele === 'string')
    ? benefitAssured.includes(selectedLifeInsured)
    : benefitAssured.some(ben => ben.bancsCustomerNo === selectedLifeInsured)

export const getFullNameOfLifeAssuredWithInforceBenefitState = (
  relationship,
  benefits,
  policyStatus
) => {
  const { bancsCustomerNo: bancsCustomerNoOfLA } = relationship
  const isAnyBenefitsInForce = getAllInForceBenefits(benefits, policyStatus).some(benefit =>
    isBenefitAssuredForLifeInsured(benefit, bancsCustomerNoOfLA)
  )
  return `${getFullName(
    relationship.relatedParty ? relationship.relatedParty : relationship,
    false
  )}${isAnyBenefitsInForce ? '' : ' (Out of force)'}`
}
export const getChildCIBenefitAssured = policies =>
  policies.reduce((bancsCustNo, item) => {
    const childBancsCustNo = get(item, 'policy.benefits', [])
      .filter(benefit => benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE && benefit.benefitAssured)
      .flatMap(benefit => benefit.benefitAssured)
      .map(benefitAssured => benefitAssured.bancsCustomerNo)
    return bancsCustNo.concat(childBancsCustNo)
  }, [])

export const getAgentFromPolicies = policies =>
  get(policies, '[0].policy.relationships', []).find(
    relationship => relationship.roleCode === POLICY_RELATIONSHIPS_AGENT
  )
const filterOutChildBeneficiaries = (benefits, lifeAssured) => {
  if (
    benefits.some(
      benefit =>
        isBenefitAssuredForLifeInsured(benefit, lifeAssured.bancsCustomerNo) &&
        benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE
    )
  ) {
    return false
  }
  return true
}

export const selectAllLifeAssuredForPolicy = (relationships, policyNo, benefits) =>
  relationships.filter(relationship => {
    const relationshipIsInPolicy = relationship.policyRolesMap[policyNo]
    const relationshipIsLifeAssured =
      relationshipIsInPolicy &&
      relationshipIsInPolicy.roles.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
    return (
      filterOutChildBeneficiaries(benefits, relationship) &&
      relationshipIsInPolicy &&
      relationshipIsLifeAssured
    )
  })

// Necessary for Jest mocking
export const getQueueLength = queue => queue.length

/**
 * To identify if given policy is protection first policy
 * @param {string} productId product id for policy
 * @param {Object} productTypes productTypes in master data from sitecore
 * @returns {boolean} if policy is protection first policy
 */
export const getIsProtectionFirstPolicy = (productId: string, productTypes: Array<Object> = []) =>
  productTypes.some(
    product =>
      product.code === productId &&
      (product.isProtectionFirstSuperPolicy || product.isProtectionFirstNonSuperPolicy)
  )

/**
 * To return policy type label as per product class
 * @param {string} productClass product class for policy
 * @returns {string} inside super or outside super based on product class
 */
export const getPolicyTypeLabel = productClass => {
  if (productClass === ORDINARY_PRODUCT_CLASS || productClass === NON_SUPER_PRODUCT_CLASS) {
    return OUTSIDE_SUPER
  }
  return INSIDE_SUPER
}

/**
 * Get's the child benefit's benefitStatus property.
 * This is needed for OCM to hide/display child benefits in COC
 * @param {object} childBenefitReference The child benefit reference
 * @param {array} policyStore Policy store from redux
 * @returns {string} Returns the human readable benefit status as a string (IE. Inforce)
 */
export const getChildBenefitStatus = (
  childBenefitReference: Object,
  policyStore: Array<Object>
) => {
  const { linkedChildBenefitCode, linkedChildBenefitInstanceNo, linkedChildPolicyNo } =
    childBenefitReference
  const policy = policyStore.find(p => p.policyNo === linkedChildPolicyNo)
  // @FIXME: We need to hardcode this in case of no policy return, we have faulty data were a
  // benefit link can exist for an expired policy upon which no policy data is returned to the user.
  if (!policy) {
    return 'Expired'
  }
  const benefit = policy.benefits.find(
    ({ benefitInstanceNo, type }) =>
      benefitInstanceNo === linkedChildBenefitInstanceNo && linkedChildBenefitCode === type
  )
  return benefit.benefitStatus
}

/**
 * Format the results to show the benefits section
 * @param {*} policy
 * @param {*} masterList
 */

export const isCPI = benefit =>
  isCpiStatus(benefit.escalations || []) ? CPI_STATUS_ON : CPI_STATUS_OFF

export const formatBenefits = (
  extractedBenefits = [],
  masterList,
  childRelatedPartyList,
  isPolicyOutOfForce,
  policyIdentifierMappings
) =>
  extractedBenefits.flatMap(benefit => {
    let waitingPeriod = 'N/A'
    if (benefit.waitingPeriod) {
      waitingPeriod = `${benefit.waitingPeriod.value} ${benefit.waitingPeriod.unit}`
    }
    let coverPeriod = 'N/A'
    if (benefit.coverPeriod) {
      coverPeriod =
        PERIOD_UNIT_AGE === benefit.coverPeriod.unit
          ? `${benefit.coverPeriod.unit} ${benefit.coverPeriod.value}`
          : `${benefit.coverPeriod.value} ${benefit.coverPeriod.unit}`
    }
    const isBoosterAvailable =
      benefit.subBenefits &&
      benefit.subBenefits.find(
        subBenefit => get(subBenefit, 'subBenefitCode', '') === POLICY_FEATURE_BO
      )
    const benefitObj = {
      benefit: addOptimiserSuffixToBenefitName(benefit) || '',
      type: benefit.type,
      subBenefits: (benefit.subBenefits || []).map(({ subBenefitCode, subBenefitName }) => ({
        subBenefitCode,
        subBenefitName,
      })),
      benefitAssured: benefit.benefitAssured || '',
      campaignDetail: benefit.campaignDetail || [],
      paymentStructure: benefit.premiumStyle || 'N/A',
      features: (benefit.features || []).filter(feature => feature.featureApplicable === 'Y'),
      coverPeriod,
      waitingPeriod,
      discounts: benefit.discounts || [],
      waitingBenefitPeriod:
        waitingPeriod !== 'N/A' || coverPeriod !== 'N/A'
          ? `${waitingPeriod} / ${coverPeriod}`
          : waitingPeriod,
      // waitingBenefitPeriod can be removed after policy uplift as they are displayed seprately
      parentBenefitCode: get(benefit.parentBenefitReference, 'parentBenefitCode', ''),
      parentBenefitReferenceNo: get(benefit.parentBenefitReference, 'parentBenefitInstanceNo', ''),
      cpiStatus: isCPI(benefit),
      sumInsured: `${dollarAmountWithCommasAndTwoDecimal(
        benefit.sumAssured || benefit.coverAmount
      )}${INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(benefit.type) ? ' p/m' : ''}`,
      benefitInstanceNo: benefit.benefitInstanceNo,
      premium: dollarAmountWithCommasAndTwoDecimal(benefit.premiumAmount),
      parentCover: isTdpOptimised(benefit, masterList, policyIdentifierMappings),
      childRelatedPartyList:
        benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE
          ? childRelatedPartyList
              .filter(({ bancsCustomerNo }) =>
                benefit.benefitAssured
                  .flatMap(assured => assured.bancsCustomerNo)
                  .includes(bancsCustomerNo)
              )
              .map(({ relatedParty }) => relatedParty)
          : [],
      coverStyle: get(benefit, 'coverStyle', ''),
      tpdDefinition: get(benefit, 'tpdDefinition[0]', ''),
      premiumFrequency: benefit.premiumFrequency,
      benefitStatusDate: benefit.benefitStatusDate,
      benefitCommencementDate: benefit.benefitCommencementDate,
      benefitExpiryDate: benefit.benefitExpiryDate,
      parentBenefitReference: benefit.parentBenefitReference,
      optimiserParentBenefitReference: benefit.optimiserParentBenefitReference,
    }
    let boosterObj = {}
    if (isBoosterAvailable) {
      boosterObj = {
        benefit: isBoosterAvailable.subBenefitName,
        benefitAssured: benefit.benefitAssured || '',
        benefitInstanceNo: benefit.benefitInstanceNo,
        childRelatedPartyList: [],
        parentType: benefit.type,
        cpiStatus: 'N/A',
        parentCover: isTdpOptimised(
          {
            parentBenefitReference: {
              parentBenefitCode: benefit.type,
              parentBenefitInstanceNo: benefit.benefitInstanceNo,
            },
          },
          masterList,
          policyIdentifierMappings
        ),
        paymentStructure: benefit.premiumStyle,
        premium: dollarAmountWithCommasAndTwoDecimal(isBoosterAvailable.premium),
        subBenefits: [],
        sumInsured: `${dollarAmountWithCommasAndTwoDecimal(isBoosterAvailable.sumAssured)} p/m`,
        type: isBoosterAvailable.subBenefitCode,
        waitingBenefitPeriod: 'N/A',
        premiumFrequency: benefit.premiumFrequency,
        benefitStatusDate: isBoosterAvailable.subBenefitStartDate,
        benefitExpiryDate: isBoosterAvailable.subBenefitEndDate,
      }
    }
    return isBoosterAvailable ? [benefitObj, boosterObj] : [benefitObj]
  })

export const generateCOCPayload = (
  policy: Object,
  policies: Array<Object>,
  benefitStatus: Array<Object>
) => {
  const benefits = policy.benefits
    .filter(
      benefit =>
        checkIfValidBenefitStatusCode(benefit.benefitStatusCode, benefitStatus) &&
        !BLACKLIST_BENEFITS_COC.includes(benefit.benefitStatusCode)
    )
    .map(benefit => ({
      ...benefit,
      stampDuty: benefit.stampDuty !== undefined ? benefit.stampDuty.toString() : undefined,
    }))

  const requestPayload = {
    docType: DOC_TYPE_CERT_CURR,
    correlationID: generateCorrelationID(),
    policy: {
      productId: policy.productId,
      legacyProductCode: policy.legacySystemProductCode,
      productClass: policy.productClass,
      identifiers: policy.identifiers,
      startDate: policy.startDate,
      firstCollectionDate: policy.paymentDetails && policy.paymentDetails.firstCollectionDate,
      nextCollectionDate: policy.paymentDetails && policy.paymentDetails.nextCollectionDate,
      status: policy.status,
      premiumPaymentMethod:
        policy.premiumPaymentMethod ||
        (policy.paymentDetails && policy.paymentDetails.collectionMethod),
      jurisdiction: policy.jurisdiction,
      paymentHistory: policy.paymentHistory,
      benefits: benefits.map(benefit => ({
        ...benefit,
        ...(benefit.linkedChildBenefitReference && {
          linkedChildBenefitReference: benefit.linkedChildBenefitReference.map(reference => ({
            ...reference,
            benefitStatus: getChildBenefitStatus(
              reference,
              policies.map(({ policy: p, bancsPolicyNo }) => ({
                ...p,
                policyNo: bancsPolicyNo,
              }))
            ),
          })),
        }),
      })),
      ageAdjustmentFlag: policy.ageAdjustmentFlag || 'N',
      relationships: policy.relationships.filter(
        ({ roleCode }) => ![POLICY_RELATIONSHIPS_LOA].includes(roleCode)
      ),
      policyPremium: policy.policyPremium,
      policyExpiry: policy.policyExpiry,
      policyFee: policy.policyFee,
      stampDuty: policy.stampDuty,
      taxRebate: policy.taxRebate,
      policyName: policy.policyName,
    },
  }
  return requestPayload
}

export const getPolicyStatus = (status = '', policyStatusList) =>
  get(
    policyStatusList?.find(p => p.status.toUpperCase() === status.toUpperCase()),
    'value',
    POLICY_STATUS_NA
  )
