/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTwitter16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.366 5.546c0 4.194-3.3 9.025-9.33 9.025A9.499 9.499 0 0 1 0 13.148c.264.03.517.038.792.038 1.533 0 2.944-.5 4.071-1.354-1.442-.03-2.65-.943-3.066-2.2.204.029.406.049.619.049.294 0 .59-.04.863-.108C1.777 9.278.649 8.001.649 6.459v-.04c.437.236.946.385 1.482.403A3.144 3.144 0 0 1 .67 4.181c0-.589.163-1.13.447-1.601C2.73 4.505 5.157 5.762 7.878 5.9a3.435 3.435 0 0 1-.082-.727C7.796 3.425 9.26 2 11.076 2c.944 0 1.797.383 2.397 1.002a6.592 6.592 0 0 0 2.08-.765 3.185 3.185 0 0 1-1.441 1.748A6.779 6.779 0 0 0 16 3.493a6.936 6.936 0 0 1-1.645 1.64c.01.138.01.276.01.413z"
      fill="#000"
    />
  </Icon>
)

export default IconTwitter16
