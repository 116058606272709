/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTarget32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6.114c-4.498.685-8.2 4.388-8.886 8.886H3v3h3.114c.685 4.498 4.388 8.2 8.886 8.886V30h3v-3.114c4.498-.685 8.2-4.388 8.886-8.886H30v-3h-3.114c-.685-4.498-4.388-8.2-8.886-8.886V3h-3v3.114zM16.5 9C12.496 9 9 12.496 9 16.5s3.496 7.5 7.5 7.5 7.5-3.496 7.5-7.5S20.504 9 16.5 9z"
      fill="#000"
    />
  </Icon>
)

export default IconTarget32
