// @flow
import moment from 'moment'
import get from 'lodash/get'

// constants
import {
  PAYMENT_HISTORY_DATE_FORMAT,
  POLICY_STATE_INFORCE,
  POLICY_FREQUENCY_MONTHLY,
  POLICY_FREQUENCY_BIANNUAL,
  POLICY_FREQUENCY_ANNUAL,
} from '../constants/policies'
import { PREMIUM_COLLECTION_FREQUENCY } from '../constants/units'
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../constants/forms'

import directDebitSchema, {
  FORM_ID as directDebitSchemaId,
} from '../components/organisms/AddPayers/components/PaymentDetails/DirectDebit/directDebit.schema'

import creditCardSchema, {
  FORM_ID as creditCardSchemaId,
} from '../components/organisms/AddPayers/components/PaymentDetails/CreditCard/creditCard.schema'

import linkedMasterkeySchema, {
  FORM_ID as linkedMasterkeyId,
} from '../components/organisms/AddPayers/components/PaymentDetails/LinkedMasterKey/linkedMasterKey.schema'

import wrapSuperSmsfSchema, {
  FORM_ID as wrapSuperSmsfSchemaId,
} from '../components/organisms/AddPayers/components/PaymentDetails/WrapSuperSmf/wrapSuperSmf.schema'

import rolloverPaymentMethodSchema, {
  FORM_ID as rolloverPaymentMethodSchemaId,
} from '../components/organisms/AddPayers/components/PaymentDetails/RolloverPaymentMethod/rolloverPaymentMethod.schema'

import {
  CC,
  DD,
  WRAP,
  MASTER_KEY_ACCOUNT,
  ROLLOVER_FROM_EXTERNAL_FUND,
  BPAY,
  CHEQUE,
} from '../constants/customerPaymentDetails'

export const getActivePaymentDetailsForm = (paymentMethod, { fields }, displayCardListing) => {
  let activeFormId
  let activeSchema
  switch (paymentMethod) {
    case DD: {
      activeFormId = directDebitSchemaId
      activeSchema = directDebitSchema({ fields })
      break
    }
    case CC: {
      activeFormId = creditCardSchemaId
      activeSchema = creditCardSchema({ fields }, displayCardListing)
      break
    }
    case WRAP: {
      activeFormId = wrapSuperSmsfSchemaId
      activeSchema = wrapSuperSmsfSchema
      break
    }
    case MASTER_KEY_ACCOUNT: {
      activeFormId = linkedMasterkeyId
      activeSchema = linkedMasterkeySchema()({})
      break
    }
    case ROLLOVER_FROM_EXTERNAL_FUND: {
      activeFormId = rolloverPaymentMethodSchemaId
      activeSchema = rolloverPaymentMethodSchema(fields)
      break
    }
    default: {
      activeFormId = null
      activeSchema = null
    }
  }
  return {
    activeFormId,
    activeSchema,
  }
}
const COLLECTION_FREQUENCY = {
  MON: 'Monthly',
  QTR: 'Quarterly',
  BIAN: 'Half - Yearly',
  ANN: 'Annual',
  SNGL: 'Single',
}
export const getCollectionFrequency = frequency => COLLECTION_FREQUENCY[frequency] || ''

export const getCollectionFrequencyUnit = frequency => PREMIUM_COLLECTION_FREQUENCY[frequency] || ''

export const isWithinOneYear = paymentEntry => {
  const currentTime = moment()
  const oneYearBack = moment().subtract(1, 'years')
  const paymentDate = moment(paymentEntry.paymentDate, PAYMENT_HISTORY_DATE_FORMAT)
  return paymentDate.isBetween(oneYearBack, currentTime)
}

export const filterInforecePolicies = policies =>
  policies
    ? policies.filter(
        policy => policy && policy.policy && policy.policy.status === POLICY_STATE_INFORCE
      )
    : []

export const getPolicyID = policy => {
  const identifiers = get(policy, 'identifiers', [])
  const policyIdIndex =
    identifiers && identifiers.length
      ? identifiers.findIndex(item => item.type === 'POLICY_ID')
      : -1
  return policyIdIndex !== -1 ? identifiers[policyIdIndex].value : ''
}
export const extractPaymentHistory = policies => {
  const paymentHistory = policies
    ? policies.map(entity =>
        entity && entity.policy && entity.policy.paymentHistory
          ? { ...entity.policy.paymentHistory, policyId: getPolicyID(entity.policy) }
          : {}
      )
    : []

  const accumulatedHistory = []
  paymentHistory.forEach(policyPaymentHistory => {
    if (policyPaymentHistory && policyPaymentHistory.history) {
      policyPaymentHistory.history.forEach(historyItem => {
        if (isWithinOneYear(historyItem))
          accumulatedHistory.push({ ...historyItem, policyId: policyPaymentHistory.policyId })
      })
    }
  })
  return accumulatedHistory
}

export const getPaymentFrequency = (activePolicy, fields) =>
  fields[`paymentDetailsPaymentFrequency${activePolicy.paymentFrequency}`]
    ? fields[`paymentDetailsPaymentFrequency${activePolicy.paymentFrequency}`].value
    : null

export const getEligiblePaymentFrequency = (premiumPaymentMethod: string) => {
  switch (premiumPaymentMethod) {
    case ROLLOVER_FROM_EXTERNAL_FUND: {
      return [POLICY_FREQUENCY_ANNUAL]
    }
    case BPAY:
    case CHEQUE: {
      return [POLICY_FREQUENCY_BIANNUAL, POLICY_FREQUENCY_ANNUAL]
    }
    default: {
      return [POLICY_FREQUENCY_MONTHLY, POLICY_FREQUENCY_BIANNUAL, POLICY_FREQUENCY_ANNUAL]
    }
  }
}

export const getPaymentFrequencyOptions = (
  premiumPaymentMethod: string,
  paymentFrequency: string
) => {
  const eligiblePaymentFrequency = getEligiblePaymentFrequency(premiumPaymentMethod)
  return REPORTING_PAYMENT_FREQUENCY_OPTIONS.reduce((paymentFrequencyOptions, option) => {
    if (eligiblePaymentFrequency.includes(option.value) || paymentFrequency === option.value) {
      paymentFrequencyOptions.push(option)
    }
    return paymentFrequencyOptions
  }, [])
}
