import { GENDER, STATES } from '../../../../../constants/forms'
import {
  date,
  required,
  optional,
  datePast,
  isCurrentDate,
  ageRangeCondition,
} from '../../../../../utils/formUtils'

export const FORM_ID = 'captureClient'

const schema = {
  gender: {
    condition: required,
    errorMsg: 'Please enter Gender',
    tooltip: '',
    options: GENDER,
  },
  isSmoker: {
    condition: required,
    errorMsg: 'Please enter Smoking status before proceeding',
    tooltip: '',
    options: [
      {
        label: 'Smoker',
        value: 'Yes',
      },
      {
        label: 'Non Smoker',
        value: 'No',
      },
    ],
  },
  dateOfBirth: {
    condition: [
      required,
      date,
      { condition: datePast, errorMsg: 'Enter a valid date of birth' },
      { condition: isCurrentDate, errorMsg: 'Enter a valid date of birth' },
      {
        condition: ageRangeCondition(11, 70),
        errorMsg: 'Age next birthday should be between 11 years and 70 years',
      },
    ],
    errorMsg: 'Enter a valid value for date of birth',
    tooltip: '',
  },
  occupation: {
    condition: required,
    errorMsg: 'Please select a valid value for occupation type',
    tooltip: '',
    options: [
      {
        label: 'Occupation 1',
        value: 'occupation-1',
      },
      {
        label: 'Occupation 2',
        value: 'occupation-2',
      },
      {
        label: 'Occupation 3',
        value: 'occupation-3',
      },
      {
        label: 'Occupation 4',
        value: 'occupation-4',
      },
      {
        label: 'Occupation 5',
        value: 'occupation-5',
      },
      {
        label: 'Occupation 6',
        value: 'occupation-6',
      },
    ],
  },
  occupationCode: { condition: optional },
  occupationRating: { condition: required },
  earning: {
    valueType: 'number',
    condition: required,
    errorMsg: 'Please enter earnings PA',
    tooltip: '',
  },
  earningExcludingSuper: {
    valueType: 'number',
    condition: required,
    errorMsg: 'Please enter earnings PA',
    tooltip: '',
  },
  stateOfResidence: {
    condition: required,
    errorMsg: 'Please select a State of residence',
    tooltip: '',
    options: STATES,
  },
  employmentStatus: {
    condition: required,
    errorMsg: 'Please select a value for employment status',
    tooltip: '',
  },
}

export default schema
