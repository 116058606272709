// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from '@mlcl-digital/mlcl-design'
import { actionCreators } from '../../../../../../actions'

// constants
import { LOADING_TYPES, LOADING_REASONS } from '../../../../../../constants/benefit'

// utils
import { ignoreColumns } from '../../../../../../utils/stylesUtils'
import { errorCheck } from '../../../../../../utils/formUtils'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'
import { manualLoadingBenefitsType } from '../../../../../../utils/preAssessmentBenefitUtils'

// schema.
import SCHEMA, { FORM_ID } from './loadings.schema'

// components
import Button from '../../../../../atoms/Button'
import { IconAddCircle16 } from '../../../../../atoms/Icons'
import TabHeaderComponent from '../TabHeader'
import LoadingList from '../LoadingList'
import { benefitList } from '../PreAssessmentTab'

// style
import styles from './loadings.styles'

const FullWidthInput = ignoreColumns(Input)

// styled component
export const AddLoadingBtn = styled(Button)(styles.addLoadingBtn)
const ContentWrapper = styled('section')(styles.contentWrapper)
const AddLoadingIcon = styled(IconAddCircle16)(({ disabled }) => styles.addLoadingIcon(disabled))

type loadingsProps = {
  actions: Object<Function>,
  fields: Object<Function>,
  form: Object<Function>,
  loadings: Object<Function>,
  underwriterName: string,
}

export class Loadings extends Component<loadingsProps> {
  schemaWithAuthorableFields = SCHEMA()

  componentWillMount() {
    const { actions, form } = this.props
    const { formInit } = actions
    const schema = this.schemaWithAuthorableFields({})
    if (!form) formInit(FORM_ID, schema, {})
  }

  componentWillReceiveProps(nextProps) {
    const { actions, underwriterName } = this.props
    const { formUpdateField } = actions
    if (nextProps.underwriterName !== underwriterName) {
      formUpdateField(FORM_ID, 'underwriterName', {
        error: false,
        value: nextProps.underwriterName || '',
      })
    }
  }

  handleChange = ({ value, name }) => {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions

    const schema = this.schemaWithAuthorableFields({})
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  removeLoading = index => {
    const {
      loadings,
      actions: { removeLoading },
    } = this.props

    const { benefit, reason: loadingReason, loadingValueType } = loadings[index]

    if (benefitList && benefitList[0]) {
      benefitList
        .find(item => item.umeCode === benefit)
        .possibleCombination.map(combination => {
          const { reason, type } = combination
          if (
            reason.toLowerCase() === loadingReason[0].reasonCode.toLowerCase() &&
            type === loadingValueType
          ) {
            // eslint-disable-next-line no-param-reassign
            combination.selected = false
            // eslint-disable-next-line no-param-reassign
            combination.status = 'new'
          }
          return combination
        })
    }
    removeLoading(loadings.filter((loading, id) => index !== id))
  }

  editLoading = index => {
    const {
      actions: { gotoPanel, editLoading },
    } = this.props

    editLoading(index)
    gotoPanel(3)
  }

  renderLoading() {
    const {
      fields: { manualLoadingAddLoadingBtnText },
      actions: { gotoPanel },
      loadings,
    } = this.props

    const loadingProps = loadings.reduce((accumlator, element) => {
      const { benefit, loadingValueType, loadingValue, reason } = element
      accumlator.push({
        benefit: (() => {
          const item = manualLoadingBenefitsType(true).find(option => option.value === benefit)
          return item && item.label ? item.label : ''
        })(),
        reason: (() =>
          reason
            ? LOADING_REASONS.find(
                option => option.value.toLowerCase() === reason[0].reasonCode.toLowerCase()
              ).label
            : '')(),
        type: (() =>
          LOADING_TYPES.find(
            option => option.value.toLowerCase() === loadingValueType.toLowerCase()
          ).label)(),
        amount: loadingValueType === 'PER' ? `${loadingValue}%` : `$${loadingValue}`,
      })
      return accumlator
    }, [])

    return (
      <Fragment>
        {
          <LoadingList
            loadings={loadingProps}
            editHandler={this.editLoading}
            removeHandler={this.removeLoading}
          />
        }
        <AddLoadingBtn
          type="tertiary"
          onClick={() => {
            gotoPanel(2)
          }}
          disabled={!manualLoadingBenefitsType().length}
        >
          <AddLoadingIcon disabled={!manualLoadingBenefitsType().length} />
          <span>{renderTextField(manualLoadingAddLoadingBtnText)}</span>
        </AddLoadingBtn>
      </Fragment>
    )
  }

  render() {
    const { fields, form } = this.props
    if (!form) return null

    const { underwriterName } = form.fields

    const { manualLoadingDefaultText, manualLoadingUnderwriterNameError } = fields

    return (
      <Fragment>
        <TabHeaderComponent activeTab={2} fields={fields} />
        <ContentWrapper>
          <p>{renderTextField(manualLoadingDefaultText)}</p>
          <FullWidthInput
            label={get(fields, 'manualLoadingUnderwriterNameLabel.value', '')}
            htmlFor="underwriterName"
            name="underwriterName"
            changeHandler={this.handleChange}
            error={underwriterName.error.error}
            caption={
              underwriterName.error.error && renderTextField(manualLoadingUnderwriterNameError)
            }
            value={underwriterName.value}
          />
          {this.renderLoading()}
        </ContentWrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms, preAssessment: { loadings, underwriterName } }) => ({
  form: forms[FORM_ID],
  loadings,
  underwriterName,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Loadings)
