/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconMinus16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1z"
      fill="#000"
    />
  </Icon>
)

export default IconMinus16
