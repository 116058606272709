// @flow
import get from 'lodash/get'

import {
  STATUS_QUOTE_APPLICATION,
  STATUS_DRAFT_APPLICATION,
  STATUS_SUBMITTED_APPLICATION,
  STATUS_COMPLETED_APPLICATION,
  FILTER_CATEGORY_ALL,
  applicationsfilterMap,
  alterationApplicationsFilterMap,
  APPLICATION_TYPES,
} from '../constants/application'

export const getStatusChipVariant = status => {
  switch (status) {
    case STATUS_QUOTE_APPLICATION:
    case STATUS_DRAFT_APPLICATION:
      return 'default'

    case STATUS_SUBMITTED_APPLICATION:
      return 'success'

    case STATUS_COMPLETED_APPLICATION:
      return 'closed'

    default:
      return 'default'
  }
}

export const getMethodChipVariant = (applicationType: string) =>
  applicationType === APPLICATION_TYPES.DigiAlt ? 'default' : 'closed'

export const getApplicationFilter = (
  isExistingBusiness: boolean,
  filterType: string = FILTER_CATEGORY_ALL
) => {
  if (isExistingBusiness) {
    return alterationApplicationsFilterMap[filterType]
  }
  return applicationsfilterMap[filterType]
}

export const getAlterationTypeLabel = (
  alterationType: string = '',
  alterationTypes: Array<Object> = []
) => {
  if (!alterationType) return ''
  return alterationTypes.find(altType => altType.code === alterationType).value
}

export const getApplicationTypeLabel = (
  applicationType: string,
  applicationTypes: Array<Object> = []
) =>
  get(
    applicationTypes.find(appType => appType.code === applicationType),
    'value',
    ''
  )
