import { space, iconWidth, fontSize, fontWeights, colours } from '../../../styles'

const styles = {
  base: (theme, arrow, first, darkPanel) => ({
    color: theme.text ? theme.text : colours.darkestGrey,
    background: theme.bg ? theme.bg : 'transparent',
    position: 'relative',
    padding: `${space(3, true)} ${space(5, true)}`,
    paddingRight: space(7) + iconWidth.sm,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'center',
    border: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.border ? theme.border : colours.lightGrey,
    textDecoration: 'none',
    transition: 'background .35s ease',
    ...(first && {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.border ? theme.border : colours.lightGrey,
    }),
    ...(darkPanel && {
      color: colours.white,
      borderBottomColor: colours.darkestGrey,
    }),
    '&:hover': {
      background: theme.hover ? theme.hover.bg : 'transparent',
      [arrow]: {
        transform: 'translateX(4px)',
      },
    },
    '&:focus': {
      background: theme.hover ? theme.hover.bg : 'transparent',
      outline: 'none',
    },
    '&:active': {
      background: theme.active ? theme.active.bg : 'transparent',
    },
  }),
  title: {
    display: 'block',
    margin: 0,
    color: 'inherit',
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
  },
  arrow: {
    color: 'inherit',
    position: 'absolute',
    top: `calc(50% - ${iconWidth.sm * 2})`,
    right: space(4),
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
  },
  check: {
    color: 'inherit',
    position: 'absolute',
    top: `calc(50% - ${iconWidth.sm * 2})`,
    right: space(8),
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
  },
  cross: {
    color: 'inherit',
    position: 'absolute',
    top: `calc(50% - ${iconWidth.sm * 2})`,
    right: space(4),
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
  },
  description: {
    margin: 0,
    color: 'inherit',
    fontSize: fontSize.xs,
    textAlign: 'left',
  },
  activeDescription: {
    color: colours.mediumGreen,
  },
  disabled: {
    color: colours.lightestGrey,
    borderColor: colours.lightestGrey,
  },
  status: {
    marginBottom: '4px',
  },
}

export default styles
