export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'
export const SIDEBAR_HIDE_FOOTER = 'SIDEBAR_HIDE_FOOTER'
export const SIDEBAR_SHOW_FOOTER = 'SIDEBAR_SHOW_FOOTER'
export const SIDEBAR_NEXT_PANEL = 'SIDEBAR_NEXT_PANEL'
export const SIDEBAR_PREVIOUS_PANEL = 'SIDEBAR_PREVIOUS_PANEL'
export const SIDEBAR_GOTO_PANEL = 'SIDEBAR_GOTO_PANEL'
export const SIDEBAR_SET_SUB_PANEL = 'SIDEBAR_SET_SUB_PANEL'
export const SIDEBAR_SET_TAB = 'SIDEBAR_SET_TAB'
export const SIDEBAR_DIRECTION = 'SIDEBAR_DIRECTION'
export const SIDEBAR_SET_PANEL_PROPS = 'SIDEBAR_SET_PANEL_PROPS'
export const SIDEBAR_SET_IS_NEXT_BTN_LOADER = 'SIDEBAR_SET_IS_NEXT_BTN_LOADER'
export const DISABLE_SIDEBAR_CLOSING = 'DISABLE_SIDEBAR_CLOSING'
export const UPDATE_QUICK_QUOTE_CHECK = 'UPDATE_QUICK_QUOTE_CHECK'
export const SIDE_BAR_SET_SOURCE = 'SIDE_BAR_SET_SOURCE'
