import { space, colours, fontSize } from '../../../../../../styles'

const styles = {
  wrapper: {
    paddingLeft: space(4, true),
    paddingRight: space(3, true),
    fontSize: fontSize.xxs,
    color: colours.whiteGrey,
  },

  discount: {
    paddingBottom: space(2, true),
  },
}

export default styles
