// @flow

import get from 'lodash/get'
import { COMPANY_ROLE, STATES, TITLES } from '../../../../../constants/forms'
import {
  date,
  datePast,
  email,
  optional,
  required,
  isNotFormattedDate,
  firstNameLastName,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../../utils/formUtils'

export const FORM_ID: string = 'director'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema =
  (fields?: {
    policyOwnerDetailsDateOfBirthFieldError: Object,
    policyOwnerDetailsDateOfBirthFieldFutureDateError: Object,
    policyOwnerDetailsDateOfBirthFieldFormatError: Object,
    policyOwnerDetailsDateOfBirthFieldAgeRangeError: Object,
    policyOwnerDetailsInvalidFirstNameError: Object,
    policyOwnerDetailsFirstNameFieldError: Object,
    policyOwnerDetailsInvalidLastNameError: Object,
    policyOwnerDetailsLastNameFieldError: Object,
  }): Function =>
  (state?: Object): Object => ({
    title: {
      condition: required,
      options: TITLES,
    },
    firstName: {
      condition: [
        required,
        {
          condition: firstNameLastName,
          errorMsg: fields && fields.policyOwnerDetailsInvalidFirstNameError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsFirstNameFieldError.value,
      tooltip: '',
    },
    middleName: {
      condition: optional,
    },
    lastName: {
      condition: [
        required,
        {
          condition: firstNameLastName,
          errorMsg: fields && fields.policyOwnerDetailsInvalidLastNameError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsLastNameFieldError.value,
      tooltip: '',
    },
    dateOfBirth: {
      condition: [
        required,
        {
          condition: isNotFormattedDate,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldFormatError.value,
        },
        {
          condition: date,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldError.value,
        },
        {
          condition: datePast,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldFutureDateError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldError.value,
    },
    companyRole: {
      condition: required,
      options: hasSecratory => (hasSecratory ? [COMPANY_ROLE[0]] : COMPANY_ROLE),
    },
    email: {
      condition: email,
    },
    sameAsCompanyAddress: {
      condition: state.isManualPostal || state.address ? optional : required,
    },
    address: {
      condition: state.sameAsCompanyAddress || state.isManualPostal ? optional : required,
    },
    addressUnit: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            fields,
            'policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value'
          ),
        },
      ],
    },
    addressStreet: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressStreetFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressValidStreetFieldError.value'),
        },
      ],
    },
    addressLocality: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressLocalityFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            fields,
            'policyOwnerDetailsResidentialAddressValidLocalityFieldError.value'
          ),
        },
      ],
    },
    addressState: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressStateFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressValidStateFieldError.value'),
        },
      ],
      options: STATES,
    },
    addressCountry: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressCountryFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            fields,
            'policyOwnerDetailsResidentialAddressCountryFieldInvalidError.value'
          ),
        },
      ],
    },
    addressPostCode: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressPostCodeFieldError.value'),
            }
          : optional,
        {
          condition: manualAddressPostalCode,
          errorMsg: get(fields, 'policyOwnerDetailsResidentialAddressValidPostalFieldError.value'),
        },
      ],
    },
    identifiers: {
      condition: optional,
    },
  })

export default schema
