// @flow
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from '../../../../../actions'

// components.
import TabBar from '../../../../molecules/TabBar'

// schema.
import SCHEMA from './payerTab.schema'

type PayerTabProps = {
  actions: {
    showSidebarFooter: Function,
    hideSidebarFooter: Function,
    getAdvisorDetails: Function,
    getPopularProtection: Function,
    getTemplates: Function,
    setNextTabPanel: Function,
  },
  nextTabSideBarPanel: number,
  fields: Object<Object>,
}

// TODO: set up redux and always clear it on load of this view

export class PayerTab extends Component<PayerTabProps> {
  constructor(props) {
    super(props)
    this.updateSidebarFooter = this.updateSidebarFooter.bind(this)
    this.setActiveTab = this.setActiveTab.bind(this)
  }

  setActiveTab(index) {
    const {
      actions: { setNextTabPanel },
      fields: { paymentDetailsLabel },
    } = this.props
    if (index === 1) setNextTabPanel(index, paymentDetailsLabel.value)
    else setNextTabPanel(index, null)
  }

  updateSidebarFooter(tabId) {
    const {
      actions: { showSidebarFooter, hideSidebarFooter },
    } = this.props

    if (tabId === 0 || tabId === 1) {
      hideSidebarFooter()
    } else {
      showSidebarFooter()
    }
  }

  render() {
    const { fields, nextTabSideBarPanel } = this.props
    return (
      <TabBar
        spaceEvenly
        currentTab={nextTabSideBarPanel || 0}
        onTabsLoad={this.updateSidebarFooter}
        onTabChange={this.setActiveTab}
        tabSize="sm"
        schema={SCHEMA(this.props)}
        fields={fields}
        disableHash
      />
    )
  }
}

export const mapStateToProps = state => ({
  nextTabSideBarPanel: state.sidebar.nextTabSideBarPanel,
  nextTabSideBarLabelPanel: state.sidebar.nextTabSideBarLabelPanel,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PayerTab)
