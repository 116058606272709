// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// styles.
import styles from './paymentDetails.styles'

// schema
import schema, { FORM_ID } from './paymentDetails.schema'

// components.
import DirectDebitComponent from './DirectDebit'
import Select from '../../../../atoms/Select'
import WrapSuperSmfComponent from './WrapSuperSmf'
import RolloverPaymentMethodComponent from './RolloverPaymentMethod'
import LinkedMasterKeyComponent from './LinkedMasterKey'
import CreditCardComponent from './CreditCard'

// helpers.
import { errorCheck } from '../../../../../utils/formUtils'
import { getPaymentMode, isNonSuperProduct } from '../../../../../utils/quoteUtils'
import { getPaymentFrequency } from '../../../../../utils/paymentUtils'

// constants
import {
  SMSF_SUPER_FUND_NAME,
  SMSF_FUND_PAYMENT_METHOD,
  ROLLOVER_FUND_PAYMENT_METHOD,
  MLC_SUPERANNUATION_FUND_NAME,
  MASTERKEY_PAYMENT_METHOD,
} from '../../constants'
import { CC, DD, CHEQUE, BPAY, WRAP } from '../../../../../constants/customerPaymentDetails'

import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_FREQUENCY_MONTHLY,
  FUND_PAYMENT_METHODS_IOOF,
} from '../../../../../constants/policies'
import { paymentDetailsInitData } from './paymentIntUtils'

type PaymentDetailsProps = {
  // Redux actions available to the component.
  actions: Object<Function>,
  // data of form in the redux.
  form: {},
  // To render all child labels from sitecore content editor.
  fields: {},
  // create Quote from store
  createQuote: {},
  // create Quote from store
  sidebar: {},
  // Payment mode from store
  paymentModeDetails: {
    superFundName: string,
    fundPaymentMethod: string,
  },
  // MasterList to get category data.
  masterData: Object,
}

const Disclaimer = styled('p')(styles.disclaimer)
const Root = styled('div')(styles.root)
const PaymentFrequency = styled('p')(styles.paymentFrequency)

export class PaymentDetails extends Component<PaymentDetailsProps> {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.getCollectionMethodsOptions = this.getCollectionMethodsOptions.bind(this)
    const {
      actions: { formInit, getMasterList },
      masterData,
    } = this.props
    if (!masterData) getMasterList()
    const paymentDetailsData = paymentDetailsInitData(this.props)

    formInit(FORM_ID, schema, paymentDetailsData)
  }

  getCollectionMethodsOptions(paymentFrequency) {
    const { paymentModeDetails, sidebar, masterData } = this.props
    const { superFundName, fundPaymentMethod } = paymentModeDetails
    const activePolicyType = get(sidebar, 'panelProps.productId', null)

    if (!isNonSuperProduct(activePolicyType)) {
      if (
        superFundName === MLC_SUPERANNUATION_FUND_NAME &&
        fundPaymentMethod !== SMSF_FUND_PAYMENT_METHOD
      ) {
        return masterData.allowablePaymentMethods
          .filter(payment => payment.applicableForNULIS.toLowerCase() === 'true')
          .map(p => ({
            label: p.paymentDisplayName,
            value: p.code,
          }))
      }
      if (
        (superFundName === MLC_SUPERANNUATION_FUND_NAME ||
          superFundName === SMSF_SUPER_FUND_NAME) &&
        fundPaymentMethod === SMSF_FUND_PAYMENT_METHOD
      ) {
        return masterData.allowablePaymentMethods
          .filter(payment => payment.applicableForSMSFWrap.toLowerCase() === 'true')
          .map(p => ({
            label: p.paymentDisplayName,
            value: p.code,
          }))
      }
      if (
        superFundName === SMSF_SUPER_FUND_NAME &&
        fundPaymentMethod !== SMSF_FUND_PAYMENT_METHOD
      ) {
        return masterData.allowablePaymentMethods
          .filter(payment => payment.applicableForSMSFWrap.toLowerCase() === 'true')
          .map(p => ({
            label: p.paymentDisplayName,
            value: p.code,
          }))
      }
    }

    return masterData.allowablePaymentMethods
      .filter(payment =>
        paymentFrequency === POLICY_FREQUENCY_MONTHLY
          ? payment.applicableForNonSuper.toLowerCase() === 'true' &&
            payment.code !== CHEQUE &&
            payment.code !== BPAY
          : payment.applicableForNonSuper.toLowerCase() === 'true'
      )
      .map(p => ({
        label: p.paymentDisplayName,
        value: p.code,
      }))
  }

  handleChange({ value, name }) {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  render() {
    const { fields, form, paymentModeDetails, sidebar, createQuote, policyInstanceNo } = this.props
    if (!form) return null
    const { activeIndex, quotes } = createQuote
    const { policyStructure } = quotes ? quotes[activeIndex] : {}

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''
    let frequency = ''
    if (activePolicy) frequency = getPaymentFrequency(activePolicy, fields)
    const { paymentDetails } = form.fields
    const { paymentDetailsLabel, chequeDisclaimer, bpayDisclaimer, paymentOptionError } = fields
    const { superFundName, fundPaymentMethod } = paymentModeDetails
    const activePolicyType = get(sidebar, 'panelProps.productId', null)
    const paymentFrequency = activePolicy && activePolicy.paymentFrequency
    const paymentOptions = this.getCollectionMethodsOptions(paymentFrequency)

    switch (true) {
      case activePolicyType === POLICY_PRODUCT_CODE_SUPER &&
        [...FUND_PAYMENT_METHODS_IOOF, SMSF_FUND_PAYMENT_METHOD].includes(fundPaymentMethod):
        return (
          <Root>
            <WrapSuperSmfComponent {...this.props} />
          </Root>
        )
      case activePolicyType === POLICY_PRODUCT_CODE_SUPER &&
        superFundName === MLC_SUPERANNUATION_FUND_NAME &&
        fundPaymentMethod === ROLLOVER_FUND_PAYMENT_METHOD:
        return (
          <Root>
            <RolloverPaymentMethodComponent {...this.props} />
          </Root>
        )
      case activePolicyType === POLICY_PRODUCT_CODE_SUPER &&
        superFundName === MLC_SUPERANNUATION_FUND_NAME &&
        fundPaymentMethod === MASTERKEY_PAYMENT_METHOD:
        return <LinkedMasterKeyComponent {...this.props} />
      default:
        return (
          <Root>
            <Select
              data-locator="paymentDetails_dd"
              label={paymentDetailsLabel && paymentDetailsLabel.value}
              value={paymentDetails.value}
              name="paymentDetails"
              id="paymentDetails"
              changeHandler={this.handleChange}
              options={paymentOptions}
              error={paymentDetails.error.error}
              caption={paymentDetails.error.error && paymentOptionError.value}
            />
            {(paymentDetails.value === BPAY || paymentDetails.value.value === BPAY) && (
              <div>
                <PaymentFrequency>{frequency}</PaymentFrequency>
                <Disclaimer data-locator="disclaimer_label">
                  {bpayDisclaimer && bpayDisclaimer.value}
                </Disclaimer>
              </div>
            )}
            {(paymentDetails.value === CHEQUE || paymentDetails.value.value === CHEQUE) && (
              <div>
                <PaymentFrequency>{frequency}</PaymentFrequency>
                <Disclaimer data-locator="disclaimer_label">
                  {chequeDisclaimer && chequeDisclaimer.value}
                </Disclaimer>
              </div>
            )}
            {(paymentDetails.value === DD || paymentDetails.value.value === DD) && (
              <DirectDebitComponent {...this.props} />
            )}
            {(paymentDetails.value === CC || paymentDetails.value.value === CC) && (
              <CreditCardComponent {...this.props} />
            )}
            {(paymentDetails.value === WRAP || paymentDetails.value.value === WRAP) && (
              <WrapSuperSmfComponent {...this.props} />
            )}
          </Root>
        )
    }
  }
}

export const mapStateToProps = ({ forms, createQuote, sidebar, masterList }) => ({
  form: forms[FORM_ID],
  createQuote,
  sidebar,
  paymentModeDetails: getPaymentMode(createQuote),
  masterData: masterList.data,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)
