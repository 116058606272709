import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontWeights, fontSize, colours } = Variables

const gridStructure = () => ({
  gridTemplateColumns: '3fr 1fr 1fr 0.8fr 1.2fr',
  display: 'grid',
  gridColumnGap: space(1.5, true),
})

const getBackgroundColor = ({
  isRemoved,
  hasChanged,
  isNewPremium,
  isCoverPeriodEdited,
  isWaitingPeriodEdited,
}) => {
  if ((isRemoved || hasChanged || isCoverPeriodEdited || isWaitingPeriodEdited) && isNewPremium)
    return colours.lightestGrey
  return 'inherit'
}

const styles = {
  sumInsuredInput: ({ error }) => ({
    '& input': {
      ...(!error && {
        backgroundColor: colours.tertiarySix,
        border: `1px solid ${colours.tertiaryThree}`,
        borderRadius: '3px',
      }),
    },
    '& input, & span': {
      lineHeight: space(3, true),
    },
  }),
  cardCell: ({ isHeader, isCTA, isEditedIcon }) => ({
    display: 'flex',
    fontSize: fontSize.xs,
    alignItems: 'center',
    fontWeight: isHeader ? fontWeights.semiBold : fontWeights.light,
    color: colours.tertiaryOne,
    padding: `${space(3, true)} 0`,
    justifyContent: isCTA ? 'flex-end' : 'initial',
    '&:first-of-type': {
      paddingLeft: space(3, true),
    },
    '&:last-of-type': {
      paddingRight: space(1, true),
    },
    '&>div': {
      marginBottom: 0,
    },
    ...(isEditedIcon && {
      '&:before': {
        content: '""',
        width: space(1, true),
        height: space(1, true),
        borderRadius: '50%',
        background: colours.tertiaryTwo,
        marginRight: space(1),
      },
    }),
  }),
  groupRowFirstCell: {
    display: 'inline',
    fontSize: fontSize.xs,
    alignItems: 'center',
    fontWeight: fontWeights.semiBold,
    padding: `${space(3, true)} 0`,
    '& span': {
      fontWeight: 400,
    },
  },
  benefitNameContainer: {
    width: '100%',
    '& svg': {
      color: colours.overlayWhite,
      paddingRight: space(0.5, true),
    },
  },
  headerRow: () => ({
    ...gridStructure(),
  }),
  groupRow: () => ({
    ...gridStructure(),
  }),
  featureRow: ({ isRemoved, isNewPremium }) => ({
    backgroundColor: getBackgroundColor({ isRemoved, isNewPremium }),
    display: 'grid',
    gridTemplateColumns: '5fr 2fr',
    borderTop: `1px solid ${colours.tertiaryFive} `,
    paddingRight: '0.5rem',
  }),
  optionsRow: ({ isCoverPeriodEdited, isWaitingPeriodEdited, isNewPremium }) => ({
    backgroundColor: getBackgroundColor({
      isCoverPeriodEdited,
      isWaitingPeriodEdited,
      isNewPremium,
    }),
    display: 'grid',
    gridTemplateColumns: isNewPremium ? '1fr 1fr 1fr 4fr' : '1fr 1fr 4fr',
    borderTop: `1px solid ${colours.tertiaryFive} `,
    paddingRight: '0.5rem',
    '& >div:nth-of-type(2)': {
      '& > div:last-of-type': {
        minWidth: space(20, true),
        marginBottom: 0,
      },
    },
  }),
  rowContainer: {
    '&:nth-of-type(2n)': {
      background: colours.tertiaryFive,
    },
  },
  dataRow: ({ isNewPremium, isRemoved, hasChanged, shouldHighlight }) => ({
    background: getBackgroundColor({ isRemoved, hasChanged, isNewPremium }),
    ...gridStructure(),
    paddingRight: '0.5rem',
    ...(shouldHighlight && {
      border: `1px solid ${colours.mlcAquaAccessible}`,
      borderRadius: '5px',
    }),
  }),
  marginRight: {
    display: 'flex',
    width: '85%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    '& div.highlight': {
      color: colours.mlcAquaDark,
      fontWeight: fontWeights.semiBold,
    },
  },
  footerItemValue: {
    fontSize: fontSize.xs,
    display: 'flex',
    width: '15%',
    justifyContent: 'flex-end',
  },
  headerContainer: {
    display: 'flex',
    '& > div': {
      width: '50%',
      '&:last-of-type': {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
      },
    },
  },
  headerCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: space(5, true),
    marginTop: space(1, true),
    'span:first-of-type': {
      fontWeight: fontWeights.semiBold,
    },
  },
  headerPolicyValue: {
    fontSize: fontSize.md,
    fontWeight: fontWeights.semiBold,
  },
  footerColumn: ({ isWarning }) => ({
    display: 'flex',
    ...(isWarning && { color: colours.functionalDangerDark }),
  }),
  beneficiary: {
    color: colours.tertiaryOne,
    fontSize: fontSize.md,
    fontWeight: fontWeights.semiBold,
    marginBottom: 0,
    marginLeft: space(3, true),
    marginTop: space(2, true),
  },
  optionsButton: ({ hasChangedOptions }) => ({
    '&:before': {
      content: hasChangedOptions ? '""' : null,
      width: space(1, true),
      height: space(1, true),
      borderRadius: '50%',
      background: colours.tertiaryTwo,
    },
    color: colours.mlcAquaDark,
    '& svg': {
      color: colours.mlcAquaDark,
      marginLeft: space(0.5, true),
      padding: `${space(0.25, true)} 0`,
    },
    paddingRight: '0',
    paddingLeft: '0',
  }),
  infoIcon: ({ isWarning }) => ({
    color: isWarning ? colours.functionalDangerDark : colours.tertiaryTwo,
  }),
  tooltipContainer: {
    display: 'inline-block',
    marginRight: space(1),
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    background: colours.functionalDangerLight,
    color: colours.functionalDangerDark,
    padding: space(2),
    svg: {
      marginRight: space(1),
    },
  },
  notification: {
    border: 0,
    marginBottom: 0,
    padding: `${space(2, true)} ${space(3, true)}`,
    display: 'flex',
    svg: {
      fontSize: fontSize.md,
    },
    span: {
      margin: `${space(0.5, true)} 0 0 ${space(0.5, true)}`,
      fontWeight: fontWeights.light,
    },
  },
  infoMessageSpan: {
    paddingRight: space(1, true),
    fontSize: fontSize.xxs,
    color: colours.tertiaryTwo,
  },
  benefitName: {
    display: 'flex',
    flexWrap: 'wrap',
    '&:first-of-type': {
      color: colours.tertiaryTwo,
      fontSize: fontSize.xxs,
      fontWeight: fontWeights.light,
      lineHeight: space(2, true),
    },
    fontWeight: fontWeights.semiBold,
    alignItems: 'center',
  },
  linkedBenefitBox: ({ isChild = true }) => ({
    display: 'inline-block',
    fontWeight: fontWeights.light,
    fontSize: space(1.5, true),
    marginTop: space(0.5, true),
    color: colours.tertiaryOne,
    '& svg': {
      color: colours.tertiaryOne,
      padding: '0 0.25rem 0.25rem 0',
    },
    '& span': {
      fontWeight: fontWeights.bold,
      marginRight: space(1, true),
    },
    '& button': {
      color: isChild ? colours.tertiaryOne : colours.mlcAquaDark,
      padding: '0',
      marginRight: space(1, true),
      marginLeft: isChild ? space(0.25, true) : 0,
    },
  }),
  coverGroupContainer: {
    background: colours.tertiaryFive,
    color: colours.tertiaryOne,
    padding: '0 1rem 0.25rem',
    margin: '0 1rem 1rem',
  },
  coverContainer: {
    background: 'white',
    marginBottom: '1rem',
    marginTop: '1rem',
    borderRadius: '5px',
  },
  resetButton: ({ isOptions }) => ({
    wordBreak: 'keep-all',
    padding: '8px 8px',
    ...(isOptions && { marginRight: space(2.75) }),
  }),
  editCoverGroupBtn: ({ open }) => ({
    background: 'none',
    border: 'none',
    color: colours.mlcAquaDark,
    display: 'flex',
    fontSize: fontSize.xs,
    alignItems: 'center',
    '& svg': {
      marginLeft: '0.5rem',
      transform: open ? 'rotate(180deg)' : 'none',
    },
  }),
  totalLabel: {
    fontSize: fontSize.xxs,
  },
  coverDivider: {
    background: 'white',
    height: '2px',
    border: 'none',
  },
  removedFeatureChip: {
    margin: `0 ${space(1, true)}`,
    textAlign: 'center',
  },
  featureButton: {
    minWidth: space(10.37),
  },
}

export const styleOverrides = {
  footer: {
    background: colours.tertiaryFive,
  },
  body: {
    padding: 0,
  },
}

export default styles
