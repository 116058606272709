export const PAYER_TYPE_INDIVIDUAL = 'ADD_PAYER/PAYER_TYPE_INDIVIDUAL'
export const PAYER_TYPE_BUSINESS = 'ADD_PAYER/PAYER_TYPE_BUSINESS'
export const SMSF_SUPER_FUND_NAME = 'SMSF (External Trustee)'
export const SMSF_FUND_PAYMENT_METHOD = 'WRAP'
export const ROLLOVER_FUND_PAYMENT_METHOD = 'ER'
export const MLC_SUPERANNUATION_FUND_NAME = 'MLC Superannuation Trustee Structures'
export const MASTERKEY_PAYMENT_METHOD = 'EMA'

export const INCOME_PROTECTION_COVERS = ['PHI_PLAT_2020', 'PHI_STD_2020']
export const BUSINESS_EXPENSE_COVERS = ['BE', 'BE_PLATINUM']
