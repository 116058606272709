import { dataApi } from '@sitecore-jss/sitecore-jss-react'

import dataFetcher from '../dataFetcher'
// configs.
import config from '../temp/config'
/**
 * gets route data from Sitecore. this data is used to
 * construct the component layout for a jss route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 * @param {dataApi.LayoutServiceRequestOptions} options Layout service fetch options
 */
export function getRouteData(route, language, options = {}) {
  const fetchOptions = {
    layoutServiceConfig: { host: (options && options.sitecoreApiHost) || config.sitecoreApiHost },
    querystringParams: {
      sc_lang: language || config.defaultLanguage,
      ...(process.env.NODE_ENV !== 'production' && {
        sc_apikey: config.sitecoreApiKey,
      }),
    },
    requestConfig: options,
    fetcher: dataFetcher,
  }
  return dataApi.fetchRouteData(route, fetchOptions).catch(error => {
    console.error('Route data fetch error', error)
    return null
  })
}
