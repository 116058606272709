// @flow
import {
  QUOTE_TEMPLATE_GET_ADVISOR_MANAGED_SUCCESS,
  QUOTE_TEMPLATE_GET_POPULAR_PROTECTION_SUCCESS,
  QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE_SUCCESS,
  QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE_SUCCESS,
  QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_SUCCESS,
  QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_FAILURE,
  QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_ERROR,
} from '../actions/types/quoteTemplate'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  mlclManaged: [],
  advisorManaged: [],
  statusCode: '',
}

const quoteTemplates = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case QUOTE_TEMPLATE_GET_POPULAR_PROTECTION_SUCCESS: {
      return {
        ...state,
        mlclManaged: action.payload.data,
      }
    }

    case QUOTE_TEMPLATE_GET_ADVISOR_MANAGED_SUCCESS: {
      return {
        ...state,
        advisorManaged: action.payload,
      }
    }

    case QUOTE_TEMPLATE_RENAME_ADVISER_MANAGED_TEMPLATE_SUCCESS: {
      return {
        ...state,
        advisorManaged: action.payload,
      }
    }

    case QUOTE_TEMPLATE_DELETE_ADVISER_MANAGED_TEMPLATE_SUCCESS: {
      return {
        ...state,
        advisorManaged: action.payload,
      }
    }

    case QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_SUCCESS: {
      const { dataReceived } = action.payload
      const statusCode = dataReceived.response.code
      return {
        ...state,
        advisorManaged: statusCode >= 200 && statusCode < 300 ? dataReceived : state.advisorManaged,
        statusCode,
      }
    }
    case QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_FAILURE:
    case QUOTE_TEMPLATE_SAVE_ADVISER_MANAGED_TEMPLATE_ERROR: {
      const { dataReceived } = action.payload
      return {
        ...state,
        statusCode: dataReceived.response.code,
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default:
      return state
  }
}

export default quoteTemplates
