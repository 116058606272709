import { containerUtils, fontSize, iconWidth, mediaQueries, space } from '../../../../../styles'

const styles = {
  base: {
    position: 'relative',
  },
  list: isOpen => ({
    display: isOpen ? 'block' : 'none',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
    [mediaQueries.md]: {
      display: 'block',
      position: 'static',
    },
  }),
  button: theme => ({
    ...containerUtils.innerPaddingHorizontal,
    backgroundColor: theme.bg,
    border: 0,
    display: 'inline-flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.text,
    justifyContent: 'space-between',
    paddingRight: space(2),
    position: 'relative',
    appearance: 'none',
    textAlign: 'left',
    width: '100%',
    paddingTop: space(2),
    paddingBottom: space(2),
    zIndex: 2,

    '&:focus': {
      outlineWidth: 0,
    },

    [mediaQueries.md]: {
      display: 'none',
    },
  }),
  text: {
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [mediaQueries.md]: {
      fontSize: fontSize.xs,
    },
  },
  indicator: {
    color: 'currentColor',
    width: iconWidth.sm,
    height: iconWidth.sm,
    [mediaQueries.md]: {
      marginLeft: space(1),
    },
  },
}

export default styles
