export const OKTA_API = 'OKTA_API'
export const OKTA_AUTHENTICATION = 'OKTA_AUTHENTICATION'
export const OKTA_AUTHENTICATION_INIT = 'OKTA_AUTHENTICATION_INIT'
export const OKTA_AUTHENTICATION_SUCCESS = 'OKTA_AUTHENTICATION_SUCCESS'
export const OKTA_AUTHENTICATION_FAILURE = 'OKTA_AUTHENTICATION_FAILURE'
export const OKTA_AUTHENTICATION_ERROR = 'OKTA_AUTHENTICATION_ERROR'
export const OKTA_AUTHORIZATION = 'OKTA_AUTHORIZATION'
export const OKTA_AUTHORIZATION_INIT = 'OKTA_AUTHORIZATION_INIT'
export const OKTA_AUTHORIZATION_SUCCESS = 'OKTA_AUTHORIZATION_SUCCESS'
export const OKTA_AUTHORIZATION_FAILURE = 'OKTA_AUTHORIZATION_FAILURE'
export const OKTA_AUTHORIZATION_ERROR = 'OKTA_AUTHORIZATION_ERROR'
export const OKTA_CHANGE_PASSWORD_MODAL = 'OKTA_CHANGE_PASSWORD_MODAL'
export const OKTA_CHANGE_PASSWORD = 'OKTA_CHANGE_PASSWORD'
export const OKTA_CHANGE_PASSWORD_INIT = 'OKTA_CHANGE_PASSWORD_INIT'
export const OKTA_CHANGE_PASSWORD_SUCCESS = 'OKTA_CHANGE_PASSWORD_SUCCESS'
export const OKTA_CHANGE_PASSWORD_FAILURE = 'OKTA_CHANGE_PASSWORD_FAILURE'
export const OKTA_CHANGE_PASSWORD_ERROR = 'OKTA_CHANGE_PASSWORD_ERROR'
export const OKTA_REDIRECT = 'OKTA_REDIRECT'
export const MYLINK_PRIMARY_AUTH = 'MYLINK_PRIMARY_AUTH'
export const MYLINK_PRIMARY_AUTH_ERROR = 'MYLINK_PRIMARY_AUTH_ERROR'
export const MYLINK_PRIMARY_AUTH_FAILURE = 'MYLINK_PRIMARY_AUTH_FAILURE'
export const MYLINK_OTP_VERIFY_REQUEST = 'MYLINK_OTP_VERIFY_REQUEST'
export const MYLINK_OTP_VERIFY_REQUEST_SUCCESS = 'MYLINK_OTP_VERIFY_REQUEST_SUCCESS'
export const MYLINK_OTP_VERIFY_REQUEST_ERROR = 'MYLINK_OTP_VERIFY_REQUEST_ERROR'
export const MYLINK_OTP_SUBMIT_REQUEST_FAILURE = 'MYLINK_OTP_SUBMIT_REQUEST_FAILURE'
export const MYLINK_OTP_VERIFY_REQUEST_FAILURE = 'MYLINK_OTP_VERIFY_REQUEST_FAILURE'
export const MYLINK_OTP_SUBMIT_REQUEST = 'MYLINK_OTP_SUBMIT_REQUEST'
export const MYLINK_OTP_SUBMIT_REQUEST_SUCCESS = 'MYLINK_OTP_SUBMIT_REQUEST_SUCCESS'
export const MYLINK_OTP_SUBMIT_REQUEST_ERROR = 'MYLINK_OTP_SUBMIT_REQUEST_ERROR'
export const MYLINK_OTP_VERIFY_SET_ERROR = 'MYLINK_OTP_VERIFY_SET_ERROR'
export const MYLINK_OTP_RESEND_REQUEST = 'MYLINK_OTP_RESEND_REQUEST'
export const MYLINK_OTP_RESEND_REQUEST_SUCCESS = 'MYLINK_OTP_RESEND_REQUEST_SUCCESS'
export const MYLINK_OTP_RESEND_REQUEST_ERROR = 'MYLINK_OTP_RESEND_REQUEST_ERROR'
export const MYLINK_OTP_RESEND_REQUEST_FAILURE = 'MYLINK_OTP_RESEND_REQUEST_FAILURE'
export const MYLINK_PRIMARY_AUTH_INIT = 'MYLINK_PRIMARY_AUTH_INIT'
export const MYLINK_OTP_VERIFY_REQUEST_INIT = 'MYLINK_OTP_VERIFY_REQUEST_INIT'
export const MYLINK_OTP_SUBMIT_REQUEST_INIT = 'MYLINK_OTP_SUBMIT_REQUEST_INIT'
export const MYLINK_OTP_RESEND_REQUEST_INIT = 'MYLINK_OTP_RESEND_REQUEST_INIT'
export const MYLINK_OTP_GRANT_ACCESS = 'MYLINK_OTP_GRANT_ACCESS'
export const MYLINK_PRIMARY_AUTH_SUCCESS = 'MYLINK_PRIMARY_AUTH_SUCCESS'

export const OKTA_REVOKE_ACCESS_TOKEN = 'OKTA_REVOKE_ACCESS_TOKEN'
export const OKTA_REVOKE_ACCESS_TOKEN_INIT = 'OKTA_REVOKE_ACCESS_TOKEN_INIT'
export const OKTA_REVOKE_ACCESS_TOKEN_SUCCESS = 'OKTA_REVOKE_ACCESS_TOKEN_SUCCESS'
export const OKTA_REVOKE_ACCESS_TOKEN_FAILURE = 'OKTA_REVOKE_ACCESS_TOKEN_FAILURE'
export const OKTA_REVOKE_ACCESS_TOKEN_ERROR = 'OKTA_REVOKE_ACCESS_TOKEN_ERROR'

export const OKTA_CLOSE_CURRENT_SESSION = 'OKTA_CLOSE_CURRENT_SESSION'
export const OKTA_CLOSE_CURRENT_SESSION_INIT = 'OKTA_CLOSE_CURRENT_SESSION_INIT'
export const OKTA_CLOSE_CURRENT_SESSION_SUCCESS = 'OKTA_CLOSE_CURRENT_SESSION_SUCCESS'
export const OKTA_CLOSE_CURRENT_SESSION_FAILURE = 'OKTA_CLOSE_CURRENT_SESSION_FAILURE'
export const OKTA_CLOSE_CURRENT_SESSION_ERROR = 'OKTA_CLOSE_CURRENT_SESSION_ERROR'

export const OKTA_SIGNOUT = 'OKTA_SIGNOUT'
export const OKTA_SIGNOUT_SUCCESS = 'OKTA_SIGNOUT_SUCCESS'
export const OKTA_SIGNOUT_FAILURE = 'OKTA_SIGNOUT_FAILURE'

export const OKTA_REAUTHORIZATION = 'OKTA_REAUTHORIZATION'
export const OKTA_REAUTHORIZATION_INIT = 'OKTA_REAUTHORIZATION_INIT'
export const OKTA_REAUTHORIZATION_SUCCESS = 'OKTA_REAUTHORIZATION_SUCCESS'

export const OKTA_REVOKE_ERROR_CODE = 'OKTA_REVOKE_ERROR_CODE'
export const OKTA_SET_BANCS_CUSTOMER_NO = 'OKTA_SET_BANCS_CUSTOMER_NO'

export const OKTA_SET_USER_PROFILE_FROM_REDIRECT = 'OKTA_SET_USER_PROFILE_FROM_REDIRECT'
