// @flow
import {
  CLIENT_DETAILS_FETCH,
  CLIENT_DETAILS_FETCH_INIT,
  CLIENT_DETAILS_FETCH_SUCCESS,
  CLIENT_DETAILS_FETCH_ERROR,
  CLIENT_DETAILS_FETCH_FAILURE,
  EXISTING_CLIENT_LISTING_FETCH_INIT,
  EXISTING_CLIENT_LISTING_FETCH_SUCCESS,
  EXISTING_CLIENT_LISTING_FETCH_ERROR,
  EXISTING_CLIENT_LISTING_FETCH_FAILURE,
  CLIENT_LISTING_FETCH_INIT,
  CLIENT_DETAILS_UPDATE,
  CLIENT_DETAILS_UPDATE_SUCCESS,
  CLIENT_DETAILS_UPDATE_ERROR,
  CLIENT_DETAILS_UPDATE_FAILURE,
  CLIENT_LISTING_FETCH_SUCCESS,
  CLIENT_LISTING_FETCH_ERROR,
  CLIENT_LISTING_FETCH_FAILURE,
  CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE,
  CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_SUCCESS,
  CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_FAILURE,
  CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_ERROR,
  DELETE_SEARCHED_EXISITNG_CLIENTS,
  SET_SEARCHED_CLIENT,
  SET_CLIENT_LA_POLICYID,
  CREATE_CHANGE_COVER_WORK_ITEM_ERROR,
  CLIENT_DETAILS_UPDATE_RESET,
} from '../actions/types/client'

import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  details: {},
  isLoading: true,
  isSubmitting: false,
  isFetchingExistingClient: false,
  hasFetchError: false,
  hasUpdateError: false,
  search: {
    results: [],
    isLoading: false,
    hasFetchError: false,
  },
  existingClients: {
    results: [],
    isLoading: false,
    hasFetchError: false,
  },
  existingClient: '',
  changeCoverDetails: {},
}

const client = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case CLIENT_DETAILS_FETCH:
    case CLIENT_DETAILS_FETCH_INIT: {
      return {
        ...initialState,
        existingClient: state.existingClient,
        existingClients: { ...state.existingClients },
        changeCoverDetails: { ...state.changeCoverDetails },
        isSubmitting: false,
        isLoading: true,
        hasFetchError: false,
      }
    }

    case CLIENT_DETAILS_FETCH_SUCCESS: {
      return {
        ...state,
        details: action.payload,
        isSubmitting: false,
        isLoading: false,
      }
    }

    case CLIENT_DETAILS_FETCH_ERROR:
    case CLIENT_DETAILS_FETCH_FAILURE: {
      return {
        ...state,
        details: { ...state.details },
        isSubmitting: false,
        isLoading: false,
        hasFetchError: true,
      }
    }
    // FIXME: loading up state with junk data to trigger the initial search call isn't optimal
    case CLIENT_LISTING_FETCH_INIT: {
      return {
        ...state,
        search: {
          results: [
            {
              policyOwner: {
                bancsCustomerNo: '',
                firstName: '',
                lastName: '',
                fullName: '',
                contactNumber: '',
                email: '',
              },
              lifeAssured: [],
              isSMSF: '',
            },
          ],
          isLoading: true,
        },
      }
    }

    case CLIENT_LISTING_FETCH_SUCCESS: {
      const {
        businessData: { clientListing: { totalRecords, clients: results } = {} },
      } = action.payload

      return {
        ...state,
        search: {
          results,
          totalRecords,
          isLoading: false,
        },
        isFetchingExistingClient: false,
      }
    }

    case EXISTING_CLIENT_LISTING_FETCH_INIT: {
      return {
        ...state,
        isSubmitting: false,
        isLoading: false,
        isFetchingExistingClient: true,
        hasFetchError: false,
      }
    }

    case EXISTING_CLIENT_LISTING_FETCH_SUCCESS: {
      const {
        businessData: { clientListing: { totalRecords, clients: results } = {} },
      } = action.payload

      return {
        ...state,
        existingClients: {
          results,
          totalRecords,
          isLoading: false,
        },
        isFetchingExistingClient: false,
      }
    }

    case EXISTING_CLIENT_LISTING_FETCH_ERROR:
    case EXISTING_CLIENT_LISTING_FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        existingClients: {
          results: [],
          isLoading: false,
          hasFetchError: true,
        },
        isFetchingExistingClient: false,
      }
    }

    case CLIENT_LISTING_FETCH_ERROR:
    case CLIENT_LISTING_FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        search: {
          results: [],
          isLoading: false,
          hasFetchError: true,
        },
        isFetchingExistingClient: false,
      }
    }

    case DELETE_SEARCHED_EXISITNG_CLIENTS: {
      return {
        ...state,
        isLoading: false,
        existingClients: {
          results: [],
          isLoading: false,
          hasFetchError: false,
        },
        isFetchingExistingClient: false,
      }
    }

    case SET_SEARCHED_CLIENT: {
      const { bancsCustomerNo } = action.payload

      return {
        ...state,
        isLoading: false,
        existingClient: bancsCustomerNo,
      }
    }

    case SET_CLIENT_LA_POLICYID: {
      const { bancsCustomerNo, bancsPolicyNo } = action.payload

      return {
        ...state,
        isLoading: false,
        changeCoverDetails: { bancsCustomerNo, bancsPolicyNo },
      }
    }

    case CREATE_CHANGE_COVER_WORK_ITEM_ERROR: {
      const { error } = action.payload

      return {
        ...state,
        isLoading: false,
        changeCoverDetails: { ...state.changeCoverDetails, error: error || '' },
      }
    }

    case CLIENT_DETAILS_UPDATE: {
      return {
        ...state,
        isSubmitting: true,
        hasUpdateError: false,
      }
    }

    case CLIENT_DETAILS_UPDATE_SUCCESS: {
      return {
        ...state,
        details: action.payload,
        isSubmitting: false,
        isLoading: false,
      }
    }

    case CLIENT_DETAILS_UPDATE_ERROR:
    case CLIENT_DETAILS_UPDATE_FAILURE:
    case CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_FAILURE:
    case CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_ERROR: {
      return {
        ...state,
        isSubmitting: false,
        hasUpdateError: true,
      }
    }

    case CLIENT_DETAILS_UPDATE_RESET: {
      return {
        ...state,
        hasUpdateError: false,
      }
    }

    case CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE: {
      return {
        ...state,
        isSubmitting: true,
        hasUpdateError: false,
      }
    }

    case CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_SUCCESS: {
      const {
        payload: {
          businessData: { member },
        },
      } = action
      return {
        ...state,
        details: member,
        isSubmitting: false,
        isLoading: false,
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default client
