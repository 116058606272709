// @flow
import get from 'lodash/get'

import { COMPANY_OR_INDIVIDUAL, STATES } from '../../../../../constants/forms'
import {
  optional,
  required,
  minLength,
  phone,
  phoneInputs,
  email,
  manualAddressRegex,
  manualAddressPostalCode,
  postcode,
} from '../../../../../utils/formUtils'
import { POLICY_MEMBER_COMPANY, POLICY_MEMBER_DIRECTOR } from '../../../../../constants/policies'

export const FORM_ID: string = 'smsfTrusteeDetails'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema = (state?: Object = {}): Object => {
  const contactNumberCondition =
    state.trustee.value === POLICY_MEMBER_COMPANY
      ? [
          { condition: required, errorMsg: get(state, 'fields.trusteePhoneNumberError.value', '') },
          { condition: phone, errorMsg: get(state, 'fields.trusteePhoneNumberError.value', '') },
        ]
      : optional
  return {
    companyEmail: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY
          ? {
              condition: required,
              errorMsg: get(state, 'fields.policyOwnerDetailsEmailFieldError.value', ''),
            }
          : optional,
        {
          condition: email,
          errorMsg: get(state, 'fields.policyOwnerDetailsEmailFieldError.value', ''),
        },
      ],
    },
    companyAddress: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY && !state.isManualCompanyAddress
          ? {
              condition: required,
              errorMsg: get(state, 'fields.trusteeCompanyAddressRequiredErrorMessage.value', ''),
            }
          : optional,
      ],
    },
    companyHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value',
            ''
          ),
        },
      ],
    },
    companyStreet: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY && state.isManualCompanyAddress
          ? {
              condition: required,
              errorMsg: get(
                state,
                'fields.policyOwnerDetailsResidentialAddressStreetFieldError.value',
                ''
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidStreetFieldError.value',
            ''
          ),
        },
      ],
    },
    companyLocality: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY && state.isManualCompanyAddress
          ? {
              condition: required,
              errorMsg: get(
                state,
                'fields.policyOwnerDetailsResidentialAddressLocalityFieldError.value',
                ''
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidLocalityFieldError.value',
            ''
          ),
        },
      ],
    },
    companyState: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY && state.isManualCompanyAddress
          ? {
              condition: required,
              errorMsg: get(
                state,
                'fields.policyOwnerDetailsResidentialAddressStateFieldError.value',
                ''
              ),
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidStateFieldError.value',
            ''
          ),
        },
      ],
      options: STATES,
    },
    companyCountry: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY && state.isManualCompanyAddress
          ? required
          : optional,
      ],
    },
    companyPostCode: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY && state.isManualCompanyAddress
          ? {
              condition: required,
              errorMsg: get(
                state,
                'fields.policyOwnerDetailsResidentialAddressPostCodeFieldError.value',
                ''
              ),
            }
          : optional,
        {
          condition: manualAddressPostalCode,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidPostalFieldError.value',
            ''
          ),
        },
      ],
    },
    companyName: {
      condition: state.trustee.value === POLICY_MEMBER_COMPANY ? required : optional,
    },
    contactNumber: {
      condition: contactNumberCondition,
      onChangeCondition: phoneInputs,
      errorMsg: get(state, 'fields.trusteePhoneNumberError.value', ''),
    },
    contactNumberCode: {
      condition: optional,
    },
    isSoleDirector: {
      condition: optional,
    },
    directors: {
      condition: [
        state.trustee.value === POLICY_MEMBER_COMPANY ? required : optional,
        state.trustee.value === POLICY_MEMBER_COMPANY ? minLength(1) : optional,
        state.trustee.value === POLICY_MEMBER_COMPANY
          ? directors =>
              !directors.some(
                director => director.relatedParty.companyRole === POLICY_MEMBER_DIRECTOR
              )
          : optional,
      ],
    },
    individuals: {
      condition: [state.trustee.value !== POLICY_MEMBER_COMPANY ? minLength(2) : optional],
    },
    trustee: {
      condition: required,
      options: COMPANY_OR_INDIVIDUAL,
    },
    residentialAddress: { condition: optional },
    residentialStreet: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidStreetFieldError.value',
            ''
          ),
        },
      ],
    },
    residentialHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value',
            ''
          ),
        },
      ],
    },
    residentialLocality: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidLocalityFieldError.value',
            ''
          ),
        },
      ],
    },
    residentialState: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidStateFieldError.value',
            ''
          ),
        },
      ],
      options: STATES,
    },
    residentialCountry: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressCountryFieldInvalidError.value',
            ''
          ),
        },
      ],
    },
    residentialPostCode: {
      condition: [
        optional,
        {
          condition: manualAddressPostalCode,
          errorMsg: get(
            state,
            'fields.policyOwnerDetailsResidentialAddressValidPostalFieldError.value',
            ''
          ),
        },
        postcode,
      ],
    },
  }
}

export default schema
