/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconMinus32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 16.5a1.5 1.5 0 0 1-1.5 1.5h-20a1.5 1.5 0 0 1 0-3h20a1.5 1.5 0 0 1 1.5 1.5z"
      fill="#000"
    />
  </Icon>
)

export default IconMinus32
