export const ADVISOR_DETAILS_FETCH = 'ADVISOR_DETAILS_FETCH'
export const ADVISOR_DETAILS_FETCH_SUCCESS = 'ADVISOR_DETAILS_FETCH_SUCCESS'
export const ADVISOR_DETAILS_FETCH_ERROR = 'ADVISOR_DETAILS_FETCH_ERROR'
export const ADVISOR_DETAILS_FETCH_FAILURE = 'ADVISOR_DETAILS_FETCH_FAILURE'
export const ADVISOR_DETAILS_UPDATE = 'ADVISOR_DETAILS_UPDATE'
export const ADVISOR_DETAILS_UPDATE_SUCCESS = 'ADVISOR_DETAILS_UPDATE_SUCCESS'
export const ADVISOR_DETAILS_UPDATE_ERROR = 'ADVISOR_DETAILS_UPDATE_ERROR'
export const ADVISOR_DETAILS_UPDATE_FAILURE = 'ADVISOR_DETAILS_UPDATE_FAILURE'
export const ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST = 'ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST'
export const ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_SUCCESS =
  'ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_SUCCESS'
export const ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_INIT = 'ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_INIT'
export const ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_FAILURE =
  'ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_FAILURE'

export const ADVISOR_REMOVE_SUPPORT_STAFF = 'ADVISOR_REMOVE_SUPPORT_STAFF'
export const ADVISOR_REMOVE_SUPPORT_STAFF_SUCCESS = 'ADVISOR_REMOVE_SUPPORT_STAFF_SUCCESS'
export const ADVISOR_REMOVE_SUPPORT_STAFF_FAILURE = 'ADVISOR_REMOVE_SUPPORT_STAFF_FAILURE'

export const CUSTOMER_REMOVE_ADVISER = 'CUSTOMER_REMOVE_ADVISER'
export const CUSTOMER_REMOVE_ADVISER_SUCCESS = 'CUSTOMER_REMOVE_ADVISER_SUCCESS'
export const CUSTOMER_REMOVE_ADVISER_FAILURE = 'CUSTOMER_REMOVE_ADVISER_FAILURE'
export const CUSTOMER_REMOVE_ADVISER_ERROR = 'CUSTOMER_REMOVE_ADVISER_ERROR'
export const CUSTOMER_FETCH_ADVISER = 'CUSTOMER_FETCH_ADVISER'

export const RESET_ADVISOR_DATA = 'RESET_ADVISOR_DATA'

export const ADVISOR_BETA_TESTING_FEATURES_FETCH = 'ADVISOR_BETA_TESTING_FEATURES_FETCH'
export const ADVISOR_BETA_TESTING_FEATURES_FETCH_INIT = 'ADVISOR_BETA_TESTING_FEATURES_FETCH_INIT'
export const ADVISOR_BETA_TESTING_FEATURES_FETCH_SUCCESS =
  'ADVISOR_BETA_TESTING_FEATURES_FETCH_SUCCESS'
export const ADVISOR_BETA_TESTING_FEATURES_FETCH_ERROR = 'ADVISOR_BETA_TESTING_FEATURES_FETCH_ERROR'
export const ADVISOR_BETA_TESTING_FEATURES_FETCH_FAILURE =
  'ADVISOR_BETA_TESTING_FEATURES_FETCH_FAILURE'
