// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import CustomerInfo from '../QuoteSummary/components/LeftPanel/CustomerInfo'

// types.
import { type quoteCollectionType } from '../../../reducers/createQuote'

// utils
import { getActiveQuote } from '../../../utils/quoteUtils'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'

type LifeInsuredQuickViewProps = {
  // sitecore fields
  fields: Object,
  actions: {
    closeSidebar: Function,
  },
  quoteData: Object,
  // create quote data which user has selected.
  createQuote: {
    activeIndex: number,
    quotes: Array<quoteCollectionType>,
  },
}

export const LifeInsuredQuickView = (props: LifeInsuredQuickViewProps) => {
  const { fields: propFields } = props
  const fields = {
    ...reduceAuthorableFields(propFields),
  }

  const { quoteData } = props

  return <CustomerInfo fields={fields} quoteData={quoteData} withHeading={false} />
}

export const mapStateToProps = ({ createQuote }) => ({
  quoteData: getActiveQuote(createQuote),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LifeInsuredQuickView))
