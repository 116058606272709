import { space, colours, fontSize } from '../../../../../../styles'

const styles = {
  root: {
    position: 'relative',
  },

  paymentMethodWrapper: {
    marginBottom: space(3, true),
  },

  paymentMethod: {
    display: 'block',
    padding: `${space(1, true)} ${space(1, true)} ${space(1, true)} 0`,
    color: colours.darkGrey,
  },

  rolloverAuthorityWrapper: {
    display: 'block',
    label: {
      color: colours.darkGrey,
      fontSize: fontSize.xxs,
      lineHeight: space(2, true),
    },
    a: {
      color: colours.mediumGreen,
      textDecoration: 'none',
    },
  },
  fundDetails: {
    display: 'inline-block',
    width: '60%',
    paddingLeft: space(2, true),
    marginTop: space(2.5, true),
  },
  searchFundContainer: {
    display: 'flex',
  },
  fundType: {
    display: 'inline-block',
    width: '40%',
  },
  noResults: {
    padding: space(2),
  },
  selectFund: {
    marginBottom: space(2),
  },
  fundValue: {
    input: {
      border: 'none',
    },
  },
}

export default styles
