export const POLICY_DETAILS_PATH = 'policy-details'
export const PREMIUM_PAYMENT_METHOD = {
  CC: 'Credit Card',
  DD: 'Direct Debit',
  CQ: 'Cheque',
  BP: 'BPAY',
  EC: 'E-Contribution',
  ER: 'E-Rollover',
  EMA: 'Eligible MLC Account',
  ME: 'Masterkey Account',
  WRAP: 'Navigator',
}
export const PREMIUM_PAYMENT_METHOD_READABLE_LABEL = {
  CC: 'Credit Card',
  DD: 'Direct Debit',
  CQ: 'Cheque',
  BP: 'BPAY',
  EC: 'E-Contribution',
  ER: 'Rollover',
  EMA: 'MasterKey Account',
  ME: 'Navigator',
}
export const CUSTOMER_CONCIERGE_OPTIONS = {
  customerPersonalDetails: '/customerPersonalDetails',
  documents: '/documents',
  beneficiaries: '/beneficiaries',
  paymentDetails: '/payment-details',
  makeAPayment: '/payment',
  assessNeeds: 'https://moneysmart.gov.au/how-life-insurance-works/life-insurance-calculator',
}
