import moment from 'moment'
import { required, checkCondition } from '../../../../../../utils/formUtils'
import { isIPCover } from '../../../../../../utils/quoteUtils'
import { checkPolicyDuplicacy, MLC_INSURANCE } from '../existingCover.schema'

export const FORM_ID = 'existingPolicy'

const checkIfValueNotExists = value => !value || !value.toString().trim

export const defaults = policyInstanceNo => ({
  benefits: [],
  insurer: {},
  policyNo: '',
  policyInstanceNo,
})

export const yearPast = value => {
  const currentDate = moment()
  const givenDate = moment(value, 'MM/YY', true)
  const isValidFormat = givenDate.isValid()
  if (givenDate.year() > currentDate.year()) {
    givenDate.set('year', givenDate.year() - 100)
  }
  return (
    !isValidFormat || currentDate.diff(givenDate) < 0 || currentDate.diff(givenDate, 'months') > 852
  )
}

export const schema = (fields, policyList, insurer, policyInstanceNo) => ({
  insurer: {
    condition: value => !value,
    errorMsg: fields.insurerMandatoryError,
  },
  policyNo: {
    condition: [
      {
        condition: value =>
          checkPolicyDuplicacy(policyList || [], value, insurer, policyInstanceNo),
        errorMsg: fields.policyNoDuplicacyError,
      },
      ...(insurer && insurer.value === MLC_INSURANCE ? [{ condition: required }] : []),
    ],
    errorMsg: fields.policyNoMandatoryError,
  },
  policyInstanceNo: {},
  benefits: {
    condition: [
      {
        condition: value => !value.length,
        errorMsg: fields.benefitsMandatoryError,
      },
      {
        condition: value => {
          let hasError = false
          value.map((benefit, benefitIndex) => {
            if (checkIfValueNotExists(benefit.sumInsured.value)) {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].sumInsured.error = {
                error: true,
                errorMsg: fields.sumInsuredMandatoryError,
              }
              hasError = true
            } else {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].sumInsured.error = false
            }
            const isIncomeAssureBenefit = isIPCover({ type: benefit.benefitCode.value })
            if (isIncomeAssureBenefit) {
              if (checkIfValueNotExists(benefit.superGuaranteeSumInsured.value)) {
                // eslint-disable-next-line no-param-reassign
                value[benefitIndex].superGuaranteeSumInsured.error = {
                  error: true,
                  errorMsg: fields.sumInsuredMandatoryError,
                }
                hasError = true
              } else {
                // eslint-disable-next-line no-param-reassign
                value[benefitIndex].superGuaranteeSumInsured.error = false
              }
            }
            if (checkIfValueNotExists(benefit.startdate && benefit.startdate.value)) {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].startdate = {
                ...value[benefitIndex].startdate,
                error: {
                  error: true,
                  errorMsg: fields.startDateMandatoryError,
                },
              }
              hasError = true
            } else if (
              checkCondition(
                benefit.startdate && benefit.startdate.value,
                yearPast,
                fields.existingCoverStartDateIncorrectError
              ).error
            ) {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].startdate = {
                ...value[benefitIndex].startdate,
                error: {
                  error: true,
                  errorMsg: fields.existingCoverStartDateIncorrectError,
                },
              }
              hasError = true
            } else {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].startdate = {
                ...value[benefitIndex].startdate,
                error: false,
              }
            }
            if (checkIfValueNotExists(benefit.replacingThisCover.value)) {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].replacingThisCover.error = {
                error: true,
                errorMsg: fields.replacingThisCoverMandatoryError,
              }
              hasError = true
            } else {
              // eslint-disable-next-line no-param-reassign
              value[benefitIndex].replacingThisCover.error = false
            }
            return benefit
          })
          return hasError
        },
      },
    ],
  },
})
