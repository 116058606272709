// @flow
import {
  OKTA_SIGNOUT_SUCCESS,
  OKTA_AUTHORIZATION_SUCCESS,
  MYLINK_OTP_GRANT_ACCESS,
  OKTA_AUTHORIZATION_INIT,
  OKTA_AUTHORIZATION_FAILURE,
  OKTA_AUTHORIZATION_ERROR,
  OKTA_REVOKE_ACCESS_TOKEN_SUCCESS,
  OKTA_SIGNOUT_FAILURE,
  OKTA_AUTHORIZATION,
  OKTA_REVOKE_ERROR_CODE,
  OKTA_SET_BANCS_CUSTOMER_NO,
} from '../actions/types/okta'

// this sub-store is being persisted to a cookie, so its maximum size is 4093 bytes.
export const initialState = {
  isLoading: false,
  token: '',
  tokenExpiresIn: '',
  errorCode: null,
  bancsCustomerNo: '',
}

const okta = (
  state: Object = initialState,
  action: { type: string, payload: { status: boolean }, error: boolean }
): Object => {
  switch (action.type) {
    case OKTA_AUTHORIZATION:
    case OKTA_AUTHORIZATION_INIT: {
      return {
        ...initialState,
        isLoading: true,
      }
    }

    case OKTA_AUTHORIZATION_SUCCESS: {
      const {
        tokenData: { accessToken, expiresAt, customerID },
      } = action.payload
      window.username = customerID
      window.bcn = customerID
      return {
        ...state,
        isLoading: false,
        token: accessToken,
        tokenExpiresIn: expiresAt,
        errorCode: null,
        bancsCustomerNo: customerID,
      }
    }

    case MYLINK_OTP_GRANT_ACCESS: {
      const {
        tokenData: { accessToken, expiresAt },
        quoteCollectionId,
      } = action.payload
      window.username = quoteCollectionId
      window.bcn = quoteCollectionId
      return {
        ...state,
        isLoading: false,
        token: accessToken,
        tokenExpiresIn: expiresAt,
        errorCode: null,
        // for teleunderwriter bancsCustomerNo will already be set and
        // quoteCollectionId will not be present in payload
        ...(quoteCollectionId && { bancsCustomerNo: quoteCollectionId }),
      }
    }

    case OKTA_AUTHORIZATION_ERROR:
    case OKTA_AUTHORIZATION_FAILURE: {
      const {
        data: { errorCode },
      } = action.payload
      return {
        ...initialState,
        isLoading: false,
        errorCode,
      }
    }

    case OKTA_REVOKE_ACCESS_TOKEN_SUCCESS:
    case OKTA_SIGNOUT_SUCCESS:
    case OKTA_SIGNOUT_FAILURE: {
      return initialState
    }
    case OKTA_REVOKE_ERROR_CODE: {
      return {
        ...state,
        errorCode: null,
      }
    }

    case OKTA_SET_BANCS_CUSTOMER_NO: {
      return {
        ...state,
        bancsCustomerNo: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default okta
