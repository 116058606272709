import get from 'lodash/get'
import { createSelector } from 'reselect'
import moment from 'moment'
import queryString from 'query-string'
import { ADVISOR_PORTAL } from '../constants/site'
import {
  getFullAddressWithState,
  getEmail,
  getMobile,
  getFirstName,
  getFullName,
  getPreferredValueForMobile,
  getCountryCode,
  getPhoneNumberWithCode,
  getPhoneNumber,
  getPreferredItemBreakdown,
  getNonPreferredLatestItemBreakdown,
  getFullAddressWithStateNonPrefered,
  determineDistrict,
  getPreferredAddress,
  constructAddress,
  getLatestAddressBasedOnType,
  getContactDetailsData,
} from '../utils/contactUtils'
import history from '../utils/browserHistory'

import { getValue } from '../utils/formUtils'
import {
  fetchClientDirectorSecretaryDetails,
  getClientRelationshipDetails,
  fetchDirectorSecretaryDetails,
} from '../utils/clientDetailsUtils'

import { DEFAULT_PHONE_CODE, DEFAULT_PHONE_ENTITY } from '../constants/contactDetails'
import {
  POLICY_MEMBER_IDENTIFIER_PARTY,
  COUNTRY_CODE,
  PREFERRED_YES,
  ADDRESS_TYPE_HOME,
  POLICY_RELATIONSHIPS_SMSF,
  ADDRESS_TYPE_STATEMENT,
  POLICY_PARTY_TYPE_BUSINESS,
} from '../constants/policies'
import { FORM_ID } from '../components/molecules/ContactDetails/ContactDetailsForm/contactDetailsForm.schema'
import { FORM_ID as BUSINESS_FORM_ID } from '../components/organisms/CustomerPersonalDetails/BusinessDetails/businessDetails.schema'
import { CONTACT_TYPES } from '../constants/forms'
import { getMemberUpdateRequestPayload } from '../components/organisms/Alteration/AltsContactDetails/components/ContactDetailsForm/utils'
import { getCurrentDate } from './alterations'

const getClientId = () => queryString.parse(history.location.search).clientId
const checkIfAdvisor = state => state.config.SITE === ADVISOR_PORTAL

const getPersonalDetails = state =>
  get(checkIfAdvisor(state) ? state.client : state.customerPersonalDetails, 'details', {})

export const getContactDetailsHasUpdateError = state =>
  checkIfAdvisor(state) ? state.client.hasUpdateError : state.customerPersonalDetails.hasUpdateError

const getBusinessDetails = state => {
  if (checkIfAdvisor(state)) {
    const clientId = getClientId()
    const customerPolicies = get(state.policies, 'data', [])
    return getClientRelationshipDetails(customerPolicies, clientId)
  }
  return state.customerPersonalDetails
}
const getCustomerRelationships = state => state.customerRelationships

export const getContactDetailsForm = state => state.forms[FORM_ID]
export const getBusinessDetailsForm = state => state.forms[BUSINESS_FORM_ID]
const getResidentialAddressLookup = state => get(state, 'addressLookup.residentialAddress', '')
const getPostalAddressLookup = state => get(state, 'addressLookup.postalAddress', '')

const getMasterList = state => state.masterList

const getPartyNumber = details => {
  const partyNumberIdentifier =
    details.identifiers &&
    details.identifiers.find(identifier => identifier.type === POLICY_MEMBER_IDENTIFIER_PARTY)
  return partyNumberIdentifier ? partyNumberIdentifier.value : ''
}

const communicationChannelMap = {
  PPR: 'Paper',
  DIGI: 'Digital',
}

export const getCurrentContactDetails = createSelector([getPersonalDetails], personalDetails => {
  const { contactMethods, preferredCommChannel } = personalDetails

  if (contactMethods) {
    const emailAddress = getEmail(get(contactMethods, 'emails', []))
    const mobileDetails = getMobile(get(contactMethods, 'phones', []))
    const contactNumber = `${get(mobileDetails, 'idc', DEFAULT_PHONE_CODE)} ${get(
      mobileDetails,
      'number',
      ''
    )}`
    const telePhoneNumber = getPhoneNumberWithCode(
      get(contactMethods, 'phones', []),
      CONTACT_TYPES.TEL
    )
    const address = getFullAddressWithState(contactMethods.addresses)
    const secondaryAddress = getFullAddressWithStateNonPrefered(contactMethods.addresses)
    const preferredMethodOfComms = communicationChannelMap[preferredCommChannel] || 'None'
    return {
      emailAddress,
      contactNumber,
      telePhoneNumber,
      address,
      preferredMethodOfComms,
      secondaryAddress,
    }
  }
  return null
})

export const getCurrentPersonalDetails = createSelector([getPersonalDetails], personalDetails => {
  const dateOfBirth = get(personalDetails, 'dateOfBirth', '')
  const partyNumber = getPartyNumber(personalDetails) || ''
  const firstName = getFirstName(personalDetails) || ''
  const fullName = getFullName(personalDetails) || ''
  const gender = get(personalDetails, 'gender', '')
  const preferredName = get(personalDetails, 'preferredName', '')
  return {
    firstName,
    fullName,
    partyNumber,
    dateOfBirth:
      (dateOfBirth && dateOfBirth !== '' && moment(dateOfBirth).format('DD/MM/YYYY')) || '',
    gender,
    preferredName,
  }
})

/**
 *  Grab current contact details to be
 * passed into form state as default values
 *
 */
export const getContactDetailsFormattedForForm = createSelector(
  [getPersonalDetails],
  personalDetails => {
    const phonesArray = get(personalDetails, 'contactMethods.phones', [])
    const mobileObj = getMobile(phonesArray)
    const phoneObj = getPhoneNumber(
      get(personalDetails, 'contactMethods.phones', []),
      CONTACT_TYPES.TEL
    )
    const phonesMobile = mobileObj.number || ''
    const phonesTele = phoneObj.number || ''
    const phoneCode = mobileObj.idc || DEFAULT_PHONE_CODE
    const addresses = get(personalDetails, 'contactMethods.addresses', [])
    const address = getFullAddressWithState(addresses)
    const secondaryAddress = getFullAddressWithStateNonPrefered(addresses)
    const emails = getEmail(get(personalDetails, 'contactMethods.emails', []))

    const {
      street: addressStreet,
      houseNo: addressHouseNo,
      locality: addressLocality,
      state: addressState,
      country: addressCountry,
      postCode: addressPostCode,
      district: poBox,
    } = getPreferredItemBreakdown(addresses)

    const {
      street: secondaryStreet,
      houseNo: secondaryHouseNo,
      locality: secondaryLocality,
      state: secondaryState,
      country: secondaryCountry,
      postCode: secondaryPostCode,
      district: secondarypoBox,
    } = getNonPreferredLatestItemBreakdown(addresses)

    const { preferredCommChannel } = personalDetails
    const methodOfComms =
      preferredCommChannel === 'PPR' || preferredCommChannel === 'DIGI'
        ? preferredCommChannel
        : undefined
    return {
      useAsPostalAddress: true,
      phones: phonesMobile,
      phonesMobile,
      phonesTele,
      phoneCode,
      emails,
      address,
      addressHouseNo,
      addressStreet,
      addressLocality,
      addressState,
      addressCountry: addressCountry || COUNTRY_CODE,
      addressPostCode,
      poBox,
      preferredMethodOfComms: methodOfComms,
      secondaryAddress,
      secondaryStreet,
      secondaryHouseNo,
      secondaryLocality,
      secondaryState,
      secondaryCountry,
      secondaryPostCode,
      secondarypoBox,
    }
  }
)

const returnFormRequestPayload = (personalDetails, formData, masterList) => {
  if (!formData) return null
  const {
    phones,
    emails,
    addressHouseNo,
    addressStreet,
    addressLocality,
    addressState,
    addressCountry,
    addressPostCode,
    phoneCode,
    preferredMethodOfComms,
    hasAddressChanged,
    postalNumber,
    postalType,
  } = formData.fields

  const contactMethods = get(personalDetails, 'contactMethods.phones', [])
  const phoneEntityMobile = getMobile(contactMethods)
  const mobileIdc = getValue(phoneCode.value)
  const phonesPayload = [
    {
      ...DEFAULT_PHONE_ENTITY,
      preferred: getPreferredValueForMobile(contactMethods),
      ...phoneEntityMobile,
      number: phones.value,
      idc: mobileIdc,
      countryCode: getCountryCode(mobileIdc, masterList),
    },
  ]

  let emailEntity = get(personalDetails, 'contactMethods.emails', [])

  if (emailEntity.length === 0) {
    emailEntity.push({
      type: ADDRESS_TYPE_HOME,
      email: '',
      preferred: PREFERRED_YES,
    })
  }

  emailEntity = emailEntity.map(email => {
    if (email.preferred === PREFERRED_YES) {
      return {
        ...email,
        email: emails.value,
      }
    }
    return email
  })

  return {
    ...personalDetails,
    preferredCommChannel: get(preferredMethodOfComms, 'value.value', preferredMethodOfComms.value),

    contactMethods: {
      ...personalDetails.contactMethods,
      phones: phonesPayload,
      emails: emailEntity,
      addresses: get(personalDetails, 'contactMethods.addresses', []).map(address => {
        if (address.preferred === PREFERRED_YES) {
          return {
            ...address,
            houseNo: addressHouseNo.value,
            street: postalType && postalType.value === 'PO BOX' ? '' : addressStreet.value,
            locality: addressLocality.value,
            state: addressState.value,
            country: get(addressCountry, 'value.value', addressCountry.value) || COUNTRY_CODE,
            postCode: addressPostCode.value,
            ...(hasAddressChanged &&
              hasAddressChanged.value === 'YES' && {
                houseName: '',
              }),
            district: determineDistrict(postalType, postalNumber, address.district),
          }
        }
        return address
      }),
    },
  }
}

/**
 *  Grab current contact details to be
 * passed into form state as default values
 *
 */
export const formatMemberDetailsRequestPayload = createSelector(
  [getPersonalDetails, getContactDetailsForm, getMasterList],
  (personalDetails, formData, masterList) =>
    returnFormRequestPayload(personalDetails, formData, masterList)
)

export const formatBusinessDetailsRequestPayload = createSelector(
  [getPersonalDetails, getBusinessDetailsForm, getMasterList],
  (personalDetails, formData, masterList) => {
    const { preferredCommChannel, contactMethods } =
      returnFormRequestPayload(personalDetails, formData, masterList) || {}
    return {
      preferredCommChannel,
      contactMethods,
    }
  }
)

export const getClientBusinessDetails = createSelector(
  [getBusinessDetails, getCustomerRelationships, checkIfAdvisor],
  (personalDetails, customerRelationships, isAdvisor) => {
    // for Advisor actual details is nested in related party object
    const details = isAdvisor ? get(personalDetails, 'relatedParty', {}) : personalDetails.details
    const abn = get(details, 'abnNumber', '')
    const role = get(details, 'roleCode', '')
    const isSMSF = role === POLICY_RELATIONSHIPS_SMSF
    const businessName = get(details, 'businessName', '')
    const bancsCustomerNo = get(details, 'identifiers[0].value', '')
    const directors = isAdvisor
      ? fetchDirectorSecretaryDetails(personalDetails)
      : fetchClientDirectorSecretaryDetails(customerRelationships[bancsCustomerNo])

    return {
      abn,
      isSMSF,
      businessName,
      directors:
        directors &&
        directors.length > 0 &&
        directors.map(director => {
          const name = `${get(director, 'relatedParty.firstName', '')} ${get(
            director,
            'relatedParty.lastName',
            ''
          )}`
          return name
        }),
    }
  }
)

export const getClientCurrentContactDetails = createSelector(
  [getPersonalDetails],
  personalDetails => getContactDetailsData(personalDetails)
)

/**
 *  Grab current contact details to be
 * passed into form state as default values
 *
 */
export const getClientContactDetailsFormattedForForm = createSelector(
  [getPersonalDetails],
  personalDetails => {
    const phonesArray = get(personalDetails, 'contactMethods.phones', [])
    const mobileObj = getMobile(phonesArray)
    const phoneObj = getPhoneNumber(
      get(personalDetails, 'contactMethods.phones', []),
      CONTACT_TYPES.TEL
    )
    const phonesMobile = mobileObj.number || ''
    const phonesTele = phoneObj.number || ''
    const phoneCode = mobileObj.idc || DEFAULT_PHONE_CODE
    const emails = getEmail(get(personalDetails, 'contactMethods.emails', []))

    const { preferredCommChannel } = personalDetails
    const methodOfComms =
      preferredCommChannel === 'PPR' || preferredCommChannel === 'DIGI'
        ? preferredCommChannel
        : undefined
    const addressesArray = get(personalDetails, 'contactMethods.addresses', [])

    const partyType = get(personalDetails, 'partyType', '')
    const isPartyTypeOrg = partyType === POLICY_PARTY_TYPE_BUSINESS

    // If partyType=ORG, then home address is not needed and hence will not be shown in form.
    const homeAddress = isPartyTypeOrg
      ? {}
      : getLatestAddressBasedOnType(addressesArray, ADDRESS_TYPE_HOME)
    const residentialAddress = constructAddress(homeAddress)
    const {
      houseNo: residentialAddressHouseNo = '',
      street: residentialAddressStreet = '',
      locality: residentialAddressLocality = '',
      state: residentialAddressState = '',
      country: residentialAddressCountry = COUNTRY_CODE,
      postCode: residentialAddressPostCode = '',
    } = homeAddress

    // If partyType=ORG, use preferred address as statement address
    const statementAddress = isPartyTypeOrg
      ? getPreferredAddress(addressesArray)
      : getLatestAddressBasedOnType(addressesArray, ADDRESS_TYPE_STATEMENT)
    const {
      houseNo: postalAddressHouseNo = '',
      street: postalAddressStreet = '',
      locality: postalAddressLocality = '',
      state: postalAddressState = '',
      country: postalAddressCountry = COUNTRY_CODE,
      postCode: postalAddressPostCode = '',
    } = statementAddress
    // If partyType=ORG, then get the preferred address and use that instead for secondary address.
    const postalAddress = constructAddress(statementAddress)
    // If address is present, check box is Unchecked to show postal address in form
    const showPostalAddressCheckbox = !Object.keys(statementAddress).length

    return {
      showPostalAddressCheckbox,
      phones: phonesMobile,
      phonesMobile,
      phonesTele,
      phoneCode,
      emails,
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry: residentialAddressCountry || COUNTRY_CODE,
      residentialAddressPostCode,
      preferredMethodOfComms: methodOfComms,
      postalAddress,
      postalAddressStreet,
      postalAddressHouseNo,
      postalAddressLocality,
      postalAddressState,
      postalAddressCountry,
      postalAddressPostCode,
    }
  }
)

const returnContactDetailsFormRequestPayload = (
  personalDetails,
  formData,
  masterList,
  currentDate,
  isResidentialManual,
  isPostalManual
) => {
  if (!formData) return null
  const { phones, emails, phoneCode, preferredMethodOfComms } = formData.fields

  const contactMethods = get(personalDetails, 'contactMethods.phones', [])
  const phoneEntityMobile = getMobile(contactMethods)
  const mobileIdc = getValue(phoneCode.value)
  const phonesPayload = [
    {
      ...DEFAULT_PHONE_ENTITY,
      preferred: getPreferredValueForMobile(contactMethods),
      ...phoneEntityMobile,
      number: phones.value,
      idc: mobileIdc,
      countryCode: getCountryCode(mobileIdc, masterList),
    },
  ]

  let emailEntity = get(personalDetails, 'contactMethods.emails', [])

  if (emailEntity.length === 0) {
    emailEntity.push({
      type: ADDRESS_TYPE_HOME,
      email: '',
      preferred: PREFERRED_YES,
    })
  }

  emailEntity = emailEntity.map(email => {
    if (email.preferred === PREFERRED_YES) {
      return {
        ...email,
        email: emails.value,
      }
    }
    return email
  })

  return {
    ...personalDetails,
    preferredCommChannel: get(preferredMethodOfComms, 'value.value', preferredMethodOfComms.value),

    contactMethods: {
      ...personalDetails.contactMethods,
      phones: phonesPayload,
      emails: emailEntity,
      addresses: getMemberUpdateRequestPayload(
        formData,
        get(personalDetails, 'contactMethods.addresses', []),
        currentDate,
        isResidentialManual,
        isPostalManual
      ),
    },
  }
}

/**
 *  Grab current contact details to be
 * passed into form state as default values
 *
 */
export const formatContactDetailsAsMemberDetailsRequestPayload = createSelector(
  [
    getPersonalDetails,
    getContactDetailsForm,
    getMasterList,
    getCurrentDate,
    getResidentialAddressLookup,
    getPostalAddressLookup,
  ],
  (
    personalDetails,
    formData,
    masterList,
    currentDate,
    residentialAddressLookup,
    postalAddressLookup
  ) =>
    returnContactDetailsFormRequestPayload(
      personalDetails,
      formData,
      masterList,
      currentDate,
      get(residentialAddressLookup, 'isManual', false),
      get(postalAddressLookup, 'isManual', false)
    )
)

export const formatMemberDetailsForAdviserRequestPayload = createSelector(
  [
    getPersonalDetails,
    getContactDetailsForm,
    getMasterList,
    getCurrentDate,
    getResidentialAddressLookup,
    getPostalAddressLookup,
  ],
  (
    personalDetails,
    formData,
    masterList,
    currentDate,
    residentialAddressLookup,
    postalAddressLookup
  ) => {
    const { isTFNProvided, contactMethods, preferredCommChannel } =
      returnContactDetailsFormRequestPayload(
        personalDetails,
        formData,
        masterList,
        currentDate,
        get(residentialAddressLookup, 'isManual', false),
        get(postalAddressLookup, 'isManual', false)
      ) || {}
    return {
      preferredCommChannel,
      isTFNProvided,
      contactMethods,
    }
  }
)
