/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  PAYER_TYPE_INDIVIDUAL,
  PAYER_TYPE_BUSINESS,
} from '../components/organisms/AddPayers/constants'

import { INSIDE_SUPER, OUTSIDE_SUPER } from './policies'

export const STATES = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' },
]

export const CONTRIBUTION_TYPES = [
  { label: 'Employer SG', value: 'ESG' },
  { label: 'Award', value: 'AWD' },
  { label: 'Salary sacrifice', value: 'SS' },
  { label: 'Employer Voluntary', value: 'VOL' },
  { label: 'Personal', value: 'PER' },
  { label: 'Spouse contribution', value: 'SPS' },

  { label: 'Child contribution', value: 'CHD' },
  { label: 'Other third party ', value: 'OTP' },
  {
    label: 'Other concessional contributions amount',
    value: 'CON',
  },
  {
    label: 'Other non-concessional contributions amount',
    value: 'NON',
  },
  { label: 'Transfers from reserves – assessable', value: 'ATR' },
  { label: 'Transfers reserves – non-assessable', value: 'NTR' },
]

export const GENDER = [
  { label: 'Male', value: 'Male', shortLabel: 'M' },
  { label: 'Female', value: 'Female', shortLabel: 'F' },
]
export const YES_NO = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
]

export const CONTACT_TYPES = {
  MOBILE: 'M',
  HOME: 'H',
  WORK: 'W',
  UNKNOWN: 'U',
  FAX: 'FAX',
  TEL: 'TEL',
}
export const blankPhoneNo = '0000000000'
// TODO: hard-wiring here in lieu of implementing these as Sitecore
// multi-select fields.
// commented-out options are listed in Headless Quote Entity and
// PaymentInstruction Entity, but unsure as to their usefulness in the UI at
// this stage.
export const PAYMENT_FREQUENCY_OPTIONS = [
  { label: 'Monthly', value: 'MON' },
  // { label: 'Quarterly', value: 'QTR' },
  { label: 'Half yearly', value: 'BIAN' },
  { label: 'Yearly', value: 'ANN' },
  // { label: 'Single', value: 'SNGL' },
  // { label: 'Weekly', value: 'WKLY' },
  // { label: '2-Weekly', value: 'BIWKLY' },
  // { label: '4-Weekly', value: 'FOURWKLY' },
]

export const REPORTING_PAYMENT_FREQUENCY_OPTIONS = [
  { label: 'Monthly', value: 'MON' },
  { label: 'Quarterly', value: 'QTR' },
  { label: 'Half yearly', value: 'BIAN' },
  { label: 'Yearly', value: 'ANN' },
]

export const PAYMENT_FREQUENCY_MULTIPLIER = [
  { label: 12, value: 'MON' },
  { label: 4, value: 'QTR' },
  { label: 2, value: 'BIAN' },
  { label: 1, value: 'ANN' },
]

export const POLICY_OWNER_OPTIONS = {
  policyOwnerOptionValue: 'policyOwner',
  lifeInsuredOptionValue: 'lifeInsured',
}

export const TITLES = [
  { value: 'MR', label: 'Mr' },
  { value: 'MRS', label: 'Mrs' },
  { value: 'MS', label: 'Ms' },
  { value: 'MISS', label: 'Miss' },
  { value: 'DM', label: 'Dame' },
  { value: 'DR', label: 'Dr' },
  { value: 'CPT', label: 'Captain' },
  { value: 'FTH', label: 'Father' },
  { value: 'LDY', label: 'Lady' },
  { value: 'MDM', label: 'Madame' },
  { value: 'MAS', label: 'Master' },
  { value: 'PAS', label: 'Pastor' },
  { value: 'PRO', label: 'Professor' },
  { value: 'REV', label: 'Rev' },
  { value: 'SIR', label: 'Sir' },
]

export const RELATIONSHIPS = [
  { label: 'Spouse', value: 'SPUS' },
  { label: 'Child', value: 'CHLD' },
  { label: 'Interdependency', value: 'INTDPN' },
  { label: 'Other dependent', value: 'FINDPND' },
]

export const PRODUCT_APPROVAL_REQUIRED = 'PRODUCT_APPROVAL_REQUIRED'

export const BENEFICIARY_TYPE_NON_BINDING = 'Non-Binding'
export const BENEFICIARY_TYPE_BINDING = 'Binding'
export const BENEFICIARY_TYPE_LAPSING_BINDING_CODE = 'LBND'
export const BENEFICIARY_TYPE_NON_BINDING_CODE = 'NBND'
export const BENEFICIARY_TYPE_BINDING_CODE = 'NLBND' as const

export const BEN_CATEGORY = 'BEN_CATEGORY'
export const ATTRIBUTE_VALUE_NA = 'NA'

export const BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE = BENEFICIARY_TYPE_BINDING_CODE

export const LAPSING_BINDING_LABEL = 'Binding'
export const SEO_BINDING_LABEL = 'SEO binding'
const NON_LAPSING_BINDING_LABEL = 'Non lapsing binding'
export const NON_BINDING_LABEL = 'Non binding'
export const BENEFICIARY_TYPES_CODES = [
  { value: BENEFICIARY_TYPE_LAPSING_BINDING_CODE, label: LAPSING_BINDING_LABEL },
  { value: BENEFICIARY_TYPE_NON_LAPSED_BINDING_CODE, label: NON_LAPSING_BINDING_LABEL },
  { value: BENEFICIARY_TYPE_NON_BINDING_CODE, label: NON_BINDING_LABEL },
]

export const SMOKING_STATUS = [
  { label: 'Smoker', value: 'Yes' },
  { label: 'Non Smoker', value: 'No' },
]

export const DELIVERY_METHOD = [
  { label: 'Email', value: 'ELC' },
  { label: 'Post', value: 'PAPER' },
]

export const DATE_FORMAT_HOUR_AFTER_YEAR = 'YYYY/MM/DD [at] h:mma'

export const DATE_FORMAT_DAY_AND_MONTH_AFTER_HOUR = 'h:mma dddd D MMM'

export const ADDITIONAL_CODE = [
  { label: 'No', value: 'N' },
  { label: 'Yes', value: 'Y' },
]

export const ADVISER_TYPE = [
  { label: 'Individual', value: 'Individual' },
  { label: 'Business', value: 'Business' },
]

export const RELATIONSHIPS_TO_LIFE_INSURED = [
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Child', value: 'Child' },
  { label: 'Parent/Guardian', value: 'Parent or Guardian' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Other dependent', value: 'Other dependent' },
]

export const PRODUCT_IDS_TO_CONFIG_MAPPING = [
  [
    ['LC_MLCI_S_MLC'],
    {
      beneficiary: ['addBeneficiaryNominationFormMLCInsurance'],
      payment: 'editPaymentDetailsFormName',
      customerPayment: 'customerEditPaymentDetailsFormName',
      customerBeneficiary: 'cpAddBeneficiaryNominationFormNameMLCInsurance',
      policyType: INSIDE_SUPER,
      supportsNavigatorPayment: false,
    },
  ],
  [
    ['LC_MLCI_NS_MLC'],
    {
      beneficiary: ['addBeneficiaryNominationFormMLCInsurance'],
      payment: 'editPaymentDetailsFormName',
      customerPayment: 'customerEditPaymentDetailsFormName',
      customerBeneficiary: 'cpAddBeneficiaryNominationFormNameMLCInsurance',
      policyType: OUTSIDE_SUPER,
      supportsNavigatorPayment: false,
    },
  ],
  [
    ['LC_PPP_NS_MLC'],
    {
      beneficiary: ['addBeneficiaryNominationFormPPP_LCS_NS'],
      payment: 'editPaymentDetailsFormNamePPPLCS',
      customerPayment: 'customerEditPaymentDetailsFormNamePPPLCS',
      customerBeneficiary: 'cpAddBeneficiaryNominationFormNamePPP_LCS',
      policyType: INSIDE_SUPER,
    },
  ],
  [
    ['LC_LCS_S_MLC'],
    {
      beneficiary: ['addBeneficiaryNominationFormPPP_LCS'],
      payment: 'editPaymentDetailsFormNamePPPLCS',
      customerPayment: 'customerEditPaymentDetailsFormNamePPPLCS',
      customerBeneficiary: 'cpAddBeneficiaryNominationFormNamePPP_LCS',
      policyType: INSIDE_SUPER,
    },
  ],
  [
    [
      'LC_IP_PF_ADV_MLC',
      'LC_IP_PF_BE_NS_MLC',
      'LC_IP_PF_BLUE_MLC',
      'LC_IP_PF_EXCELL_NS_MLC',
      'LC_IP_PF_GOLD_NS_V1_MLC',
      'LC_IP_PF_IDL_NS_MLC',
      'LC_IP_PF_GOLD_NS_V2_MLC',
      'LC_PF_SOLITAIRE_NS_MLC',
      'LC_PF_SRM_NS_V3_MLC',
      'LC_PF_FRM_NS_V1_MLC',
      'LC_PF_LIFE_NS_V2_MLC',
      'LC_PF_LIFE_NS_V3_MLC',
      'LC_PF_FRM_NS_V3_MLC',
      'LC_PF_RECMON_NS_V1_MLC',
      'LC_PF_RECMON_NS_V3_MLC',
    ],
    {
      beneficiary: ['addBeneficiaryNominationFormPF_NS'],
      payment: 'editPaymentDetailsFormNamePF',
      customerPayment: 'customerEditPaymentDetailsFormNamePF',
      customerBeneficiary: 'cpAddBeneficiaryNominationFormNamePF_NS',
      policyType: OUTSIDE_SUPER,
    },
  ],
  [
    [
      'LC_IP_PF_EXCELL_S_MLC',
      'LC_PF_LIFE_S_V2_MLC',
      'LC_PF_LIFE_S_V3_MLC',
      'LC_PF_SOLITAIRE_S_MLC',
      'LC_PF_SRM_S_V3_MLC',
    ],
    {
      beneficiary: ['addBeneficiaryNominationFormPF_S'],
      payment: 'editPaymentDetailsFormNamePF',
      customerPayment: 'customerEditPaymentDetailsFormNamePF',
      customerBeneficiary: 'cpAddBeneficiaryNominationFormNamePF_S',
      policyType: INSIDE_SUPER,
    },
  ],
] as const

export const getProductIdConfig = (productId: string) => {
  // @ts-ignore not sure why read only array of string is being cast as never to includes method CF
  const match = PRODUCT_IDS_TO_CONFIG_MAPPING.find(([productIds]) => productIds.includes(productId))
  return match ? match[1] : {}
}

export const SUPER_ESTATE_OPTIMISER_RELATIONSHIP_BENEFICIARY_MAPPING: Record<
  string,
  { label: string; relationshipType: string; benefitPercentage: string }
> = {
  LS_SPS: {
    label: 'Lump Sum - Spouse',
    relationshipType: 'Spouse',
    benefitPercentage: '100%',
  },
  LS_MC: {
    label: 'Lump Sum - Minor Children',
    relationshipType: 'Minor Children',
    benefitPercentage: '100%',
  },
  LS_SPS_MC: {
    label: 'Lump Sum - Spouse/Minor Children (50/50)',
    relationshipType: 'Spouse/ Minor Children',
    benefitPercentage: '50/50',
  },
  LS_DC_MC: {
    label: 'Lump Sum - Dependent Children and Minor Children',
    benefitPercentage: '50/50',
    relationshipType: 'Dependent Children and Minor Children',
  },
  LS_EST: {
    label: 'Lump Sum - Estate',
    benefitPercentage: '100%',
    relationshipType: 'Estate',
  },
  LS_PRTNR: {
    label: 'Lump Sum - Partner',
    relationshipType: 'Partner',
    benefitPercentage: '100%',
  },
  ABP_SPS: {
    label: 'Account Based Pension - Spouse',
    relationshipType: 'Spouse',
    benefitPercentage: '100%',
  },
  ABP_MC: {
    label: 'Account Based Pension - Minor Children',
    relationshipType: 'Minor Children',
    benefitPercentage: '100%',
  },
  ABP_SPS_MC: {
    label: 'Account Based Pension - Spouse/Minor Children (50/50)',
    relationshipType: 'Spouse/ Minor Children',
    benefitPercentage: '50/50',
  },
} as const

export const RELATIONSHIP_MAPPING = {
  OTHDPND: 'Other Dependent',
  SPUS: 'Spouse',
  LGLRPSNT: 'Legal Personal Representative',
  INTDPN: 'Interdependency',
  CHLD: 'Child',
  FINDPND: 'Financial Dependent',
}

export const RELATIONSHIP_BENEFICIARY_FORM = [
  { value: 'OTHDPND', label: 'Other Dependent' },
  { value: 'SPUS', label: 'Spouse' },
  { value: 'LGLRPSNT', label: 'Legal Personal Representative' },
  { value: 'INTDPN', label: 'Interdependency' },
  { value: 'CHLD', label: 'Child' },
  { value: 'FINDPND', label: 'Financial Dependent' },
]
export const DEFAULT_QUOTE_COLLECTION_NAME = 'Untitled Quote'

export const COMPANY_ROLE = [
  {
    label: 'Director',
    value: 'Director',
  },
  {
    label: 'Secretary',
    value: 'Secretary',
  },
]

export const PAYMENT_ACCOUNT_TYPE = [
  {
    label: 'Personal',
    value: PAYER_TYPE_INDIVIDUAL,
  },
  {
    label: 'Business',
    value: PAYER_TYPE_BUSINESS,
  },
]

export const PAYMENT_DRAW_DATE = (() => {
  const options = []
  let opt = 1
  while (opt < 29) {
    options.push({ label: opt, value: opt.toString() })
    opt += 1
  }
  return options
})()

export const PAYMENT_DETAILS = [
  {
    label: 'Credit Card',
    value: 'CC',
  },
  {
    label: 'Direct Debit',
    value: 'DD',
  },
  {
    label: 'Cheque',
    value: 'CQ',
  },
  {
    label: 'BPAY',
    value: 'BPAY',
  },
  {
    label: 'E-Contribution',
    value: 'EC',
  },
  {
    label: 'E-Rollover',
    value: 'ER',
  },
  {
    label: 'Masterkey Account',
    value: 'EMA',
  },
  {
    label: 'Navigator',
    value: 'WRAP',
  },
]

export const PREMIUM_PAYMENT_METHOD_READABLE_LABEL = {
  CC: 'Credit Card',
  DD: 'Direct Debit',
  CQ: 'Cheque',
  BP: 'BPAY',
  EC: 'E-Contribution',
  ER: 'E-Rollover',
  EMA: 'Masterkey Account',
  ME: 'MLC Internal Account',
}

export const DEFAULT_INSURERS = [
  { label: 'MLC Insurance', value: 'MLC Insurance' },
  { label: 'AIA', value: 'AIA' },
  { label: 'AMP', value: 'AMP' },
  { label: 'ASGARD', value: 'ASGARD' },
  { label: 'Allianz', value: 'Allianz' },
  { label: 'Asteron', value: 'Asteron' },
  { label: 'Aussie Life', value: 'Aussie Life' },
  { label: 'BT Life', value: 'BT Life' },
  { label: 'ClearView', value: 'ClearView' },
  { label: 'Comminsure', value: 'Comminsure' },
  { label: 'Macquarie Life', value: 'Macquarie Life' },
  { label: 'Metlife', value: 'Metlife' },
  { label: 'One Path', value: 'One Path' },
  { label: 'St. George', value: 'St. George' },
  { label: 'Suncorp', value: 'Suncorp' },
  { label: 'TAL Ltd.', value: 'TAL Ltd.' },
  { label: 'Westpac Life', value: 'Westpac Life' },
  { label: 'Zurich', value: 'Zurich' },
  { label: 'Other', value: 'Other' },
]

export const DEFAULT_PAGE_LIMIT = 20
export const DEFAULT_PAGE_NUMBER = '1'
export const DEFAULT_PAGE_INDEX = 0
export const MANUAL = 'Manual'
export const DIGITAL = 'Digital'
export const CATEGORY_ALL = 'ALL'
export const QUOTE_OPTIONS = [
  { label: 'All', value: 'ALL' },
  { label: 'Digital', value: 'DIGITAL' },
  { label: 'Manual', value: 'MANUAL' },
]
export const APP_FILTER_OPTIONS = [
  { label: 'All', value: 'ALL' },
  { label: 'Draft', value: 'PENDING' },
  { label: 'Submitted', value: 'SUBMITTED' },
]

export const REGULATORY = [
  { label: 'ATO regulated', value: 'ATO', shortLabel: 'ATO regulated' },
  { label: 'APRA regulated', value: 'APRA', shortLabel: 'APRA regulated' },
]
export const COMPANY_OR_INDIVIDUAL = [
  { label: 'Company', value: 'Company', shortLabel: 'Company' },
  { label: 'Individual', value: 'Individual', shortLabel: 'Individual' },
]

export const FUND_TYPES = [
  {
    label: 'Fund name',
    value: 'fundName',
  },
  {
    label: 'Product name',
    value: 'productName',
  },
  {
    label: 'USI',
    value: 'usi',
  },
  {
    label: 'ABN',
    value: 'abn',
  },
]

export const COVER_CHANGE_FIELD_COMMENT = 'comment'

export const CUST_PAYMENT_OPTIONS = [
  {
    label: 'Credit Card',
    value: 'CC',
  },
  {
    label: 'Direct Debit',
    value: 'DD',
  },
  {
    label: 'Navigator',
    value: 'WRAP',
  },
]

export const BENEFICIARY_PARTY_TYPE = [
  { label: 'Individual', value: 'PER' },
  { label: 'Estate of Life Insured', value: 'ORG' },
]

export const POLICY_STATUS = [
  { label: 'Inforce', value: 'INF' },
  { label: 'Expired', value: 'EXP' },
  { label: 'Lapsed', value: 'LAP' },
  { label: 'Terminated', value: 'TERM' },
]

export const INSURANCE_OPTIONS = [
  { label: 'Accidental Death', value: 'Accidental Death Benefit' },
  { label: 'Income Protection/Business Expense', value: 'Income Protection/Business Expense' },
  { label: 'Child Critical Illness', value: 'Child Critical Illness Cover' },
  { label: 'Critical illness Cover', value: 'Critical Illness Cover' },
  { label: 'Life Cover', value: 'Life Cover' },
  { label: 'OAI', value: 'OAI' },
  { label: 'Total Permanent Disability', value: 'Total and Permanent Disability' },
]

export const YES_NO_RADIO = [
  {
    key: 'Y',
    text: 'Yes',
    value: 'Y',
  },
  {
    key: 'N',
    text: 'No',
    value: 'N',
  },
]

export const PREFERRED_METHOD_OF_COMMS = 'preferredMethodOfComms'
export const PREFERRED_METHOD_OF_COMMS_FIELDS = {
  digital: { value: 'DIGI', label: 'Digital' },
  paper: { value: 'PPR', label: 'Paper' },
}

export const PREFERRED_METHOD_OF_COMMS_EVENT_MAP = {
  [PREFERRED_METHOD_OF_COMMS_FIELDS.digital.value]: 'Digital communication',
  [PREFERRED_METHOD_OF_COMMS_FIELDS.paper.value]: 'Paper communication',
}

export const FORM_LABELS = {
  title: 'Title',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  email: 'Email address',
  dateOfBirth: 'Date of birth',
  mobilePhone: 'Mobile phone',
  postalAddress: 'Postal address',
  idc: 'Country Code',
  cantFindAddress: "Can't find address or international?",
  residentialAddress: 'Residential Address',
  postalAddressToggle: 'Use this as postal address',
  street: 'Street',
  houseNo: 'House No',
  poBox: 'Post Office Box',
  locality: 'Locality / City / Town',
  state: 'State',
  country: 'Country',
  postCode: 'Postcode',
  companyName: 'Company name',
  abn: 'ABN',
  companyAddress: 'Company Address',
}

export const FORM_ADDRESS_VALIDATION_ERRORS = {
  countryRequired: 'Please select the country',
  localityRequired: 'Please enter the locality',
  postcodeRequired: 'Please enter the postcode',
  stateRequired: 'Please enter the state',
  streetRequired: 'Please enter the street',
}

export const FORM_PERSON_VALIDATION_ERRORS = {
  titleRequired: 'Please select their title',
  firstNameRequired: 'Please enter their first name',
  lastNameRequired: 'Please enter their last name',
  dateOfBirthRequired: 'Please enter their date of birth',
  invalidDateOfBirth: 'Please enter a valid date',
}

export const FORM_COMPANY_VALIDATION_ERRORS = {
  companyNameRequired: "Please enter the companies' name",
  companyABNRequired: "Please enter the companies' ABN",
}

export const FORM_CONTACT_DETAILS_VALIDATION_ERRORS = {
  emailRequired: 'Please enter their email',
  invalidEmail: 'Please enter a valid email',
  mobilePhoneRequired: 'Please enter their mobile number',
  invalidMobilePhone: 'Please enter a valid phone number',
}

export const FORM_BUTTON_LABELS = {
  remove: 'Remove',
  removeAuthority: 'Remove authority',
}

export const INDEFINITE = '9999-01-01'

export const GENERIC_ERROR_MESSAGE = 'Something has gone wrong, please try again later.'

export const PREFERRED_CONTACT_TIME_AM = 'AM'
export const PREFERRED_CONTACT_TIME_PM = 'PM'
export const PREFERRED_CONTACT_TIME_ANY = 'Any'
