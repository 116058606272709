// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../../utils/telemetry'
import { actionCreators } from '../../../../../../actions'

// compoments
import TabHeaderComponent from '../TabHeader'
import EnquiryIdExpiredModal from '../../../../EnquiryIdExpiredModal'
import Checkbox from '../../../../../atoms/Checkbox'
import Button from '../../../../../atoms/Button'
import Disclaimer from '../Disclaimer'
import Tag from '../../../../../atoms/Tag'

// styles.
import styles from './preAssessment.styles'
import { ignoreColumns } from '../../../../../../utils/stylesUtils'

// Schema
import { preAssessmentSchema } from './preAssessment.schema'

// Utils
import { optional, errorCheck } from '../../../../../../utils/formUtils'
import { getPreAssessmentEnquiryPayload } from '../../../../../../utils/ureUtils'
import history from '../../../../../../utils/browserHistory'
import { getBenefitList, resetBenefitList } from '../../../../../../utils/preAssessmentBenefitUtils'

// constants
import { PREASSESSMENT_HIGH_LEVEL_TYPES } from '../../../../../../constants/benefit'
import Heading from '../../../../../atoms/Heading'

type preAssessmentProps = {
  form: Object,
  // redux action object
  actions: {
    // close sidebar action
    closeSidebar: Function,
    // update policy structure in redux
    createQuoteUpdatePolicyStructure: Function,
  },
  // sitecore field data
  fields: {
    [string]: string,
  },
  captureClientForm: Object,
  preAssessmentDetails: Object,
  preAssessment: Object,
  createQuote: Object,
  ure: Object,
  advisor: Object,
}

const FullWidthCheckbox = ignoreColumns(Checkbox)
const PreAssessmentCheckbox = styled(FullWidthCheckbox)(styles.preAssessmentCheckbox)
const PreAssessmentSection = styled('section')(styles.section)
const PreAssessmentFieldSet = styled('div')(styles.fieldSet)
const PreAssessmentButton = styled(Button)(styles.button)
const ErrorMsg = styled('p')(styles.error)
const BenefitTag = styled(Tag)(styles.benefitTag)
const EnquiryLines = styled('section')(styles.enquiryLines)
const MsgLine = styled('p')(styles.msgLine)
const PreAssessmentTitle = styled(Heading)(styles.preAssessmentTitle)
const EnquiryHeading = styled(Heading)(styles.enquiryHeading)
const EnquiryRow = styled('div')(({ noMargin }) => styles.enquiryRow(noMargin))

// eslint-disable-next-line import/no-mutable-exports
export let benefitList = []
export class PreAssessment extends Component<preAssessmentProps> {
  constructor(props) {
    super(props)
    this.state = { formSchema: {}, selectAll: true }
  }

  componentDidMount() {
    const {
      actions: { ureInitalData },
      ure: { initialData },
    } = this.props
    if (isEmpty(initialData)) {
      ureInitalData()
    } else {
      this.setFormSchemaState()
    }
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!isEqual(nextProps.form, this.props.form)) {
      this.setState({
        selectAll: Object.values(nextProps.form.fields).some(
          opt => opt.value === '' || opt.value === false
        ),
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      preAssessment: { loadings },
      ure: { initialData, results, answers, isLoading },
      createQuote,
      actions: { getDetailsForEnquiryId, setActiveEnquiryId },
    } = this.props
    const {
      ure: { initialData: initialDataPrev, results: resultsDataPrev },
    } = prevProps
    const { formSchema } = this.state
    const enquiryId = get(createQuote, 'quotes.[0].preAssessmentDetails.enquiryId')

    if (
      Object.keys(initialData).length !== Object.keys(initialDataPrev).length ||
      (Object.keys(initialData).length && !Object.keys(formSchema).length) ||
      !isEqual(resultsDataPrev, results)
    ) {
      this.setFormSchemaState()
      if (!isEmpty(results)) {
        const tagEvent = createEvent({
          GA: { category: 'Preassessment results', action: 'Preassessment results' },
          Splunk: {
            attributes: {
              'workflow.name': 'Preassessment results',
            },
          },
        })
        tagEvent.end()
      }
    }

    if (initialData && initialData.umeBenefits && !benefitList.length)
      benefitList = getBenefitList(initialData.umeBenefits, loadings)

    // In case preassessment is already done(when navigating from quotes or app listing page),
    // fetch answers and then results
    if (enquiryId && get(initialData, 'umeBenefits') && isEmpty(answers) && !isLoading) {
      setActiveEnquiryId(enquiryId)
      getDetailsForEnquiryId(enquiryId)
    }
  }

  // Dynamically Generate schema coming the product rules api
  getFormSchema() {
    const {
      ure: { initialData },
    } = this.props
    const schema = {}
    initialData.umeBenefits.forEach(o => {
      if (o.types.includes('pre-sale') && !o.excludeInPA) {
        schema[o.umeText] = {
          ...o,
          condition: optional,
        }
      }
    })
    return schema
  }

  // Set the dynamic form schema on internal state for component
  setFormSchemaState(): void {
    const {
      actions: { formInit },
      ure: { results },
    } = this.props

    const generatedSchema = this.getFormSchema()
    if (!generatedSchema) {
      return
    }

    this.setState(
      state => ({
        ...state,
        formSchema: generatedSchema,
      }),
      () => {
        const { formSchema: schema } = this.state
        const defaults = Object.keys(schema)
          .map(sc => {
            let value = false
            if (results && results.umeBenefits && results.umeBenefits[0]) {
              const matchedData = results.umeBenefits.find(
                benefit => benefit.umeCode === schema[sc].umeCode
              )
              if (matchedData) {
                const {
                  highLevelResults: { decisionValue },
                } = matchedData
                value = !!decisionValue
              }
            }
            return {
              [sc]: value,
            }
          })
          .reduce((acc, currentVal) => Object.assign({}, acc, currentVal), {})
        formInit(preAssessmentSchema, schema, defaults)
      }
    )
  }

  handleChange = ({ value, name }): void => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { formSchema: schema } = this.state

    const dataOfField = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(preAssessmentSchema, name, dataOfField)
    formValidate(preAssessmentSchema, schema)
  }

  selectAllHandler = () => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { formSchema: schema, selectAll } = this.state
    Object.keys(schema).forEach(key => {
      const dataOfField = {
        error: errorCheck(true, schema[key].umeText.condition, schema[key].umeText.errorMsg),
        value: selectAll,
      }
      formUpdateField(preAssessmentSchema, schema[key].umeText, dataOfField)
      this.setState({ selectAll: !selectAll })
    })

    formValidate(preAssessmentSchema, schema)
  }

  goToUre = () => {
    const {
      actions: { closeSidebar },
    } = this.props
    history.push('/ure-tool')
    closeSidebar()
  }

  callback = payload => () => {
    const {
      preAssessment: { loadings },
      actions: {
        updateLoading,
        removeEnquiryId,
        updateEnquiryId,
        showEnquiryIdExpiredModal,
        saveLoadings,
      },
    } = this.props
    if (loadings && loadings.length) {
      saveLoadings(null)
      updateLoading([])
      resetBenefitList()
    } else {
      removeEnquiryId(true)
    }
    showEnquiryIdExpiredModal(false)
    updateEnquiryId({
      callback: this.goToUre,
      isPreassessment: true,
      payload,
    })
  }

  getBenefitLabel = (key: string) => {
    const { formSchema: schema } = this.state
    let status = ''
    let statusType = 'primary'
    const {
      ure: { results },
    } = this.props

    if (results && results.umeBenefits && results.umeBenefits[0]) {
      const matchedData = results.umeBenefits.find(
        benefit => benefit.umeCode === schema[key].umeCode
      )
      if (matchedData) {
        const {
          highLevelResults: { decisionValue, decisionBucket },
        } = matchedData
        status = decisionValue
        statusType = PREASSESSMENT_HIGH_LEVEL_TYPES.find(
          highLevelType => highLevelType.type === decisionBucket
        ).tagType
      }
    }

    return (
      <Fragment>
        {schema[key].paDisplayText} {status && <BenefitTag type={statusType}>{status}</BenefitTag>}
      </Fragment>
    )
  }

  renderEnquiryLines() {
    const {
      ure: { results = Array },
    } = this.props

    if (results && results.preAssessmentTitle && results.enquiryLines && results.enquiryLines[0]) {
      const { preAssessmentTitle, enquiryLines } = results
      return (
        <EnquiryLines>
          <PreAssessmentTitle size={3}>{preAssessmentTitle}</PreAssessmentTitle>
          {enquiryLines.map((enquiry, id) => (
            <EnquiryRow noMargin={id === 0}>
              <EnquiryHeading size={3}>{enquiry.heading}</EnquiryHeading>
              {enquiry.messages &&
                enquiry.messages[0] &&
                enquiry.messages.map(messages => <MsgLine>{messages}</MsgLine>)}
            </EnquiryRow>
          ))}
        </EnquiryLines>
      )
    }
    return null
  }

  render() {
    const {
      fields,
      form,
      captureClientForm,
      preAssessmentDetails,
      preAssessment,
      ure: { initialData, answers, activeEnquiryId, isLoading },
      createQuote: { quotes, activeIndex },
      advisor: { details },
    } = this.props
    if (!form || isLoading) {
      return <Loader type="panel" />
    }
    const {
      enquiryIdModalTitle,
      enquiryIdModalContent,
      enquiryIdModalButton,
      preAssessmentModalLoadingWarning,
      preAssessmentModalLoadingWarningDesc,
    } = fields
    const { formSchema: schema, selectAll } = this.state
    const { enquiryId, createdOn } = preAssessmentDetails
    const payload = getPreAssessmentEnquiryPayload(
      form,
      captureClientForm,
      activeEnquiryId ? answers[activeEnquiryId].allAnswers : '',
      quotes[activeIndex].memberMandatories,
      details
    )
    const { loadings } = preAssessment

    return (
      <Fragment>
        <TabHeaderComponent fields={fields} />
        <PreAssessmentSection>
          <p>{fields.preAssessmentPleaseSelect.value}</p>
          <PreAssessmentFieldSet>
            {Object.keys(schema).map(key => (
              <PreAssessmentCheckbox
                key={key}
                text={this.getBenefitLabel(key)}
                name={schema[key].umeText}
                htmlFor={schema[key].umeText}
                onChangeHandler={this.handleChange}
                checked={form.fields[schema[key].umeText].value}
              />
            ))}
            {Object.keys(form.fields).every(field => form.fields[field].error.error) && (
              <ErrorMsg>{fields.preAssessmentPleaseSelectError.value}</ErrorMsg>
            )}
            <PreAssessmentButton type="tertiary" onClick={this.selectAllHandler}>
              {selectAll
                ? fields.preAssessmentSelectAll.value
                : fields.preAssessmentUnSelectAll.value}
            </PreAssessmentButton>
          </PreAssessmentFieldSet>
        </PreAssessmentSection>

        {this.renderEnquiryLines()}

        {enquiryId && createdOn && (
          <Disclaimer
            fields={fields}
            expiryDuration={initialData.umeExpiryPeriod}
            createdOn={createdOn}
          />
        )}
        <EnquiryIdExpiredModal
          enquiryIdModalTitle={
            (loadings && loadings.length) || preAssessment.underwriterName
              ? preAssessmentModalLoadingWarning
              : enquiryIdModalTitle.value
          }
          enquiryIdModalContent={
            (loadings && loadings.length) || preAssessment.underwriterName
              ? preAssessmentModalLoadingWarningDesc.value
              : enquiryIdModalContent.value
          }
          enquiryIdModalButton={enquiryIdModalButton.value}
          callback={this.callback(payload)}
          payload={payload}
        />
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  productRules,
  createQuote,
  preAssessment,
  ure,
  advisor,
}) => ({
  form: forms[preAssessmentSchema],
  productRules,
  captureClientForm: forms.captureClient,
  preAssessmentDetails: get(
    createQuote,
    `quotes[${createQuote.activeIndex}].preAssessmentDetails`,
    {}
  ),
  preAssessment,
  createQuote,
  ure,
  advisor,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PreAssessment)
