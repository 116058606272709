/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronUp32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 14.621l-8.94 8.94-2.12-2.122L16.5 10.38l11.06 11.06-2.12 2.122-8.94-8.94z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronUp32
