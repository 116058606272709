// @flow

import { optional } from '../../../../../../utils/formUtils'

export const FORM_ID: string = 'preAssessmentManualLoadings'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema = (): Function => (): Object => ({
  underwriterName: {
    condition: optional,
  },
})

export default schema
