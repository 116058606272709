import { space, colours, fontSize } from '../../../../../../../styles'

const styles = {
  container: {
    position: 'relative',
  },
  head: {
    textAlign: 'center',
    borderBottom: `1px solid ${colours.lightGrey}`,
  },
  delTemplateItem: {
    margin: `${space(3, true)} 0`,
  },
  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
  },
  editButton: {
    fontSize: fontSize.xs,
  },
  edit: {
    marginLeft: space(2),
  },
  wrap: {
    padding: `${space(3, true)} ${space(3, true)}`,
    transform: 'translateY(100%)',
    textAlign: 'center',
  },
}

export default styles
