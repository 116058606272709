// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

import { components } from 'react-select'

// styles.
import styles from './selectMenu.styles'

export const SelectActionLink = styled('div')(styles.menuLink)
const SelectMaxHeight = styled('div')(styles.maxHeight)

type MenuListProps = {
  // Contents of label
  children: Node,
  // onClick of link general occupation list should open
  onClickOfCustomMenuOption: () => void,
}

const onClickHandler = props => () => {
  const {
    selectProps: { onClickOfCustomMenuOption },
  } = props

  onClickOfCustomMenuOption && onClickOfCustomMenuOption()
}

const MenuList = (props: MenuListProps) => {
  const { children } = props
  return (
    <components.MenuList {...props}>
      <SelectMaxHeight>{children}</SelectMaxHeight>
      <SelectActionLink onClick={onClickHandler(props)}>
        Manually select general occupation
      </SelectActionLink>
    </components.MenuList>
  )
}

export default MenuList
