import {
  space,
  colours,
  fontSize,
  fontWeights,
  QUOTE_SUMMARY_POLICY_CARD_DEFAULT_WIDTH,
} from '../../../../../styles'

const variants = {
  primary: {
    color: colours.mediumGreen,
    fontSize: fontSize.sm,
    fontWeight: fontWeights.semiBold,
  },
  secondary: {
    color: colours.white,
    fontSize: fontSize.sm,
    fontWeight: fontWeights.normal,
  },
  tertiary: {
    color: colours.white,
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
  },
}

const styles = {
  quoteIcon: {
    color: colours.white,
    margin: space(1, true),
    width: space(2),
    height: space(2),
    float: 'right',
  },

  setColor: type => ({
    color: variants[type].color,
  }),

  setFontSize: type => ({
    fontSize: variants[type].fontSize,
  }),

  quoteActionRoot: (align, type) => ({
    padding: `${space(3, true)} ${space(3, true)}`,
    display: 'flex',
    flexDirection: align,
    borderBottom: type ? 'none' : `1px solid ${colours.darkestGrey}`,
    width: '100%',
    maxWidth: QUOTE_SUMMARY_POLICY_CARD_DEFAULT_WIDTH,
  }),

  quoteButton: () => ({
    '&:hover': {
      background: colours.lightGreen,
    },
  }),

  quoteLabel: type => ({
    color: colours.white,
    fontSize: fontSize.xs,
    fontWeight: variants[type].fontWeight,
    textAlign: 'center',
    cursor: 'inherit',
  }),
  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
  },
  closeButton: {
    margin: `${space(3, true)} 0`,
  },
}

export default styles
