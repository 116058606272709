// @flow
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { formatCurrency } from '../../../../utils/quoteUtils'
import { sumInsuredBenefitToField, PROJECTIONS_BENEFIT_FIELDS_MAPPING } from './projections.fields'
import {
  POLICY_FREQUENCY_MONTHLY,
  POLICY_FREQUENCY_ANNUAL,
  POLICY_FREQUENCY_BIANNUAL,
} from '../../../../constants/policies'
import { COMBINED_COVER } from '../../../../constants/benefit'

const benefitCodeMapping = [
  {
    type: 'LC',
    code: 'lc',
  },
  {
    type: 'PTD',
    code: 'tpd',
  },
  {
    type: 'BE',
    code: 'be',
  },
  {
    type: 'BE_PLATINUM',
    code: 'be',
  },
  {
    type: 'CI_PLUS',
    code: 'ci',
  },
  {
    type: 'CI_STD',
    code: 'ci',
  },
  {
    type: 'CI',
    code: 'ci',
  },
  {
    type: 'Child_CI',
    code: 'cic',
  },
  {
    type: 'HIV',
    code: 'hivhep',
  },
  {
    type: 'PHI_PLAT_2020',
    code: 'ip',
  },
  {
    type: 'PHI_STD_2020',
    code: 'ip',
  },
]

/**
 * returns the appropriate Sitecore field for a given frequency type.
 *
 * @param {string} frequency - frequency type for a policy.
 * @param {Object} fields - Sitecore fields.
 * @param {Object} fields.projectionsTableFrequencyMonthly - monthly Sitecore field.
 * @param {Object} fields.projectionsTableFrequencyAnnual - annual Sitecore field.
 * @param {Object} fields.projectionsTableFrequencyBiannual - biannual Sitecore field.
 * @returns {Object} Sitecore field of the matching frequency type.
 */
export const fieldFromFrequency = (
  frequency: string,
  {
    projectionsTableFrequencyMonthly,
    projectionsTableFrequencyAnnual,
    projectionsTableFrequencyBiannual,
  }: Object
): Object => {
  switch (frequency) {
    case POLICY_FREQUENCY_MONTHLY: {
      return projectionsTableFrequencyMonthly
    }
    case POLICY_FREQUENCY_ANNUAL: {
      return projectionsTableFrequencyAnnual
    }
    case POLICY_FREQUENCY_BIANNUAL: {
      return projectionsTableFrequencyBiannual
    }
    default: {
      return projectionsTableFrequencyMonthly
    }
  }
}

const checkPaymentFrequencyForCombinedPolicies = policies => {
  const paymentFrequencyNotEqualFlag = !policies.every(
    policy => policy.paymentFrequency === policies[0].paymentFrequency
  )

  if (paymentFrequencyNotEqualFlag) {
    return POLICY_FREQUENCY_ANNUAL
  }
  return policies[0].paymentFrequency
}

/**
 * returns the appropriate projections data-set given an identifier string.
 *
 * @param {Array} projections - projections data from a policy.
 * @param {string} type - string identifier for a projections data-set, e.g. 'sumInsuredProjection'
 * @returns {Array} projections data matching the given type.
 */
export const projectionByType = (projections: Array<Object>, type: string): ?Array<Object> => {
  const targetProjection =
    (projections || []).find(projection => typeof projection[type] !== 'undefined') || {}
  return targetProjection[type] || null
}

/**
 * returns the number of years of projections data available.
 *
 * @param {Array} projections - projections data from a policy.
 * @returns {number} the number of years worth of projections data available.
 */
export const getProjectionYears = (projections: Array<Object>): number => {
  const premiums = projectionByType(projections, 'premiumProjectionTwo')
  return premiums && premiums.length ? premiums.length : 0
}

/**
 * returns a max duration value (15) from an array of duration values [5, 10, 15, 20]
 * given a years value (13).
 *
 * @param {number} years - a number corresponding to how many years of data is available.
 * @param {Array} durationOptions - an array of duration numbers [5, 10, 15, 20, 30, 40, 50, 60]
 * @returns {number} the highest applicable duration value from durationOptions.
 */
export const getMaxDuration = (years: number, durationOptions: Array<number>): number =>
  durationOptions.reduce((maxDuration, duration, index, options) => {
    const prevDuration = index === 0 ? 0 : options[index - 1]
    return years <= duration && years > prevDuration ? duration : maxDuration
  }, 0)

// filter out 'ageNextBirthday' key.
const removeANBKey = (key: string): boolean => key !== 'ageNextBirthday'

// filter function to only include benefits that have a Sitecore field mapping.
const filterBenefits = (key: string): boolean =>
  Object.keys(PROJECTIONS_BENEFIT_FIELDS_MAPPING).includes(key)

// strip 'AUD' from currency strings lol.
export const stripCurrencyType = (value: string): string => value.toString().replace('AUD', '')

/**
 * generate an array of benefit keys (e.g. 'lcSumInsured'), filtering out
 * 'ageNextBirthday' and any unknown benefits.
 *
 * @param {Array} projections - projections data from a policy.
 * @returns {Array} array of benefit strings from sum insured data.
 */
export const getSumInsuredBenefitCodes = (
  projections: Array<Object>,
  selectedCover: string = ''
): Array<string> => {
  if (!projections || !projections.length) {
    return []
  }
  const sumInsureds = projectionByType(projections, 'sumInsuredProjection')
  if (!sumInsureds || !sumInsureds.length) {
    return []
  }

  let benefitCode = ''

  if (selectedCover && selectedCover !== COMBINED_COVER) {
    const cover = benefitCodeMapping.find(benefit => benefit.type === selectedCover).code
    benefitCode = `${cover}SumInsured`
  }
  return sumInsureds[0]
    ? Object.keys(sumInsureds[0])
        .filter(removeANBKey)
        .filter(!benefitCode ? filterBenefits : key => key === benefitCode)
    : []
}

/** Multiplier for cumulative frequency */
export const premiumMultiplier = {
  [POLICY_FREQUENCY_MONTHLY]: 12,
  [POLICY_FREQUENCY_BIANNUAL]: 2,
  [POLICY_FREQUENCY_ANNUAL]: 1,
}

/**
 * returns the benefit code projection key.
 *
 * @param {string} selectedCover - selected cover for projections.
 * @param {Object} type - projection type - selected / level / stepped.
 * @returns {Object} corresponding projection key for selected benefit and type.
 */

const getProjectionCoversKeys = (selectedCover, type) => {
  if (selectedCover === 'combined') return type

  const benefitType = benefitCodeMapping.find(benefit => benefit.type === selectedCover)
  return benefitType ? `${benefitType.code}${capitalize(type)}Premium` : ''
}

// get cumulative value of a key.
export const cumulativeValue = (
  data: Array<Object>,
  key: string,
  index: number,
  paymentFrequency: string
): number =>
  data.reduce((value, projection, projectionIndex) => {
    if (projectionIndex > index) {
      return value
    }
    return projection[key]
      ? value +
          (Number(stripCurrencyType(projection[key])) * premiumMultiplier[paymentFrequency] || 0)
      : 0
  }, 0)

// Logic for combining projection values for Projections Table

export const makeCombinedProjectionsForTable = (policies, structure = 'stepped') => {
  const paymentFrequencyNotEqualFlag = !policies.every(
    policy => policy.paymentFrequency === policies[0].paymentFrequency
  )

  const mergeSumInsured = data => {
    const result = {}

    data.forEach(projection => {
      if (projection) {
        Object.entries(projection).forEach(entry => {
          const key = entry[0]
          const value = entry[1]
          if (key !== 'ageNextBirthday' && result[key]) {
            result[key] += parseFloat(value)
          } else {
            result[key] = parseFloat(value)
          }
        })
      }
    })
    return result
  }

  const finalProjectionsArray = policies.reduce((policyFirst, policySecond) => {
    const policiesData =
      get(policyFirst.projections[0], 'premiumProjectionTwo', []).length >
      get(policySecond.projections[0], 'premiumProjectionTwo', []).length
        ? { previousPolicy: policyFirst, currentPolicy: policySecond }
        : { previousPolicy: policySecond, currentPolicy: policyFirst }
    const previousProjectionsPremium = get(
      policiesData.previousPolicy,
      'projections[0].premiumProjectionTwo'
    )
    const previousPaymentFrequency = get(policiesData.previousPolicy, 'paymentFrequency', '')

    const currentProjectionsPremium = get(
      policiesData.currentPolicy,
      'projections[0].premiumProjectionTwo'
    )
    const currentPaymentFrequency = get(policiesData.currentPolicy, 'paymentFrequency', '')

    const previousProjectionsSumInsured = get(
      policiesData.previousPolicy,
      'projections[0].sumInsuredProjection',
      []
    )
    const currentProjectionsSumInsured = get(
      policiesData.currentPolicy,
      'projections[0].sumInsuredProjection',
      []
    )

    const finalProjection = previousProjectionsPremium.map((previousProjection, index) => {
      const { projectionYear } = previousProjection
      let monthlyPremium = 0
      if (paymentFrequencyNotEqualFlag) {
        monthlyPremium =
          premiumMultiplier[previousPaymentFrequency] *
            parseFloat(get(previousProjection, structure, 0)) +
          premiumMultiplier[currentPaymentFrequency] *
            parseFloat(get(currentProjectionsPremium[index], structure, 0))
      } else {
        monthlyPremium =
          parseFloat(get(previousProjection, structure, 0)) +
          parseFloat(get(currentProjectionsPremium[index], structure, 0))
      }
      return {
        projectionYear,
        [structure]: monthlyPremium.toFixed(2).toString(),
      }
    })

    const combineSumInsured = previousProjectionsSumInsured.map(
      (previousProjectionSumInsured, index) =>
        mergeSumInsured([previousProjectionSumInsured, currentProjectionsSumInsured[index]])
    )

    return {
      paymentFrequency: POLICY_FREQUENCY_ANNUAL,
      projections: [
        {
          premiumProjectionTwo: finalProjection,
          sumInsuredProjection: combineSumInsured,
        },
      ],
    }
  })
  if (!paymentFrequencyNotEqualFlag) {
    finalProjectionsArray.paymentFrequency = policies[0].paymentFrequency
  }
  return {
    paymentFrequency: finalProjectionsArray.paymentFrequency,
    premiumProjectionTwo: finalProjectionsArray.projections[0].premiumProjectionTwo,
    sums: finalProjectionsArray.projections[0].sumInsuredProjection,
  }
}

/**
 * generates table header (column) data for ProjectionsTable.
 *
 * @param {Object} options - object containing arguments.
 * @param {Array} options.projections - projections data from a policy.
 * @param {string} options.paymentFrequency - payment frequency for a policy.
 * @param {Object} options.fields - Sitecore fields.
 * @returns {Array} array of column header data for ProjectionsTable.
 */
export const makeTableColumns = ({
  projections,
  paymentFrequency,
  fields,
  structure = 'stepped',
  isCombined = false,
  isCumulative = false,
  policies,
  selectedCover,
}: Object): Array<Object> => {
  const {
    projectionsTableHeaderPremium,
    projectionsTableHeaderANB,
    ProjectionsTableHeaderAnnualizedPremium,
    ProjectionsTableHeaderAnnualized,
  } = fields

  let resultantFrequency = paymentFrequency

  const header = (heading, accessor) => ({
    header: heading,
    accessor,
  })
  let benefitColumns = getSumInsuredBenefitCodes(projections, selectedCover).map(benefit =>
    header(sumInsuredBenefitToField(benefit, fields), benefit)
  )

  if (isCombined) {
    resultantFrequency = checkPaymentFrequencyForCombinedPolicies(policies)

    benefitColumns = getSumInsuredBenefitCodes(
      [{ sumInsuredProjection: makeCombinedProjectionsForTable(policies, structure).sums }],
      selectedCover
    ).map(benefit => header(sumInsuredBenefitToField(benefit, fields), benefit))
  }

  const paymentFrequencyNotEqualFlag = Boolean(
    policies && !policies.every(policy => policy.paymentFrequency === policies[0].paymentFrequency)
  )

  return [
    header(projectionsTableHeaderANB, 'age'),
    header(
      [
        paymentFrequencyNotEqualFlag && isCombined
          ? ProjectionsTableHeaderAnnualized
          : fieldFromFrequency(resultantFrequency, fields),
        projectionsTableHeaderPremium,
      ],
      'premium'
    ),
    ...(isCumulative
      ? [header(ProjectionsTableHeaderAnnualizedPremium, 'annualCumulativePremium')]
      : []),
  ].concat(benefitColumns)
}

/**
 * generates table projections row data for ProjectionsTable.
 *
 * @param {Array} projections - projections data from a policy.
 * @param {number} [count] - number of rows to generate.
 * @returns {Array} an array of row objects to populate ProjectionsTable.
 */
export const makeTableRows = (
  {
    projections,
    paymentFrequency = POLICY_FREQUENCY_ANNUAL,
    isCumulative = false,
    structure = 'stepped',
    isCombined = false,
    policies,
    selectedCover = '',
  }: Object,
  count?: number
): Array<Object> => {
  let sums = projectionByType(projections, 'sumInsuredProjection')
  let premiumProjectionTwo = projectionByType(projections, 'premiumProjectionTwo')

  const projectionKey = getProjectionCoversKeys(selectedCover, structure)

  if (isCombined) {
    // In combining projections disabling eslint to resolve variable name inconsistency
    // eslint-disable-next-line no-param-reassign
    ;({ sums, paymentFrequency, premiumProjectionTwo } = makeCombinedProjectionsForTable(
      policies,
      projectionKey
    ))
  }

  const sumInsuredData = (sumInsured: Object): Object =>
    Object.keys(sumInsured)
      .filter(removeANBKey)
      .filter(filterBenefits)
      .reduce(
        (data, key) => ({
          ...data,
          [key]: formatCurrency(stripCurrencyType(sumInsured[key] || '0')),
        }),
        {}
      )

  // apply `count` to control how many rows to generate.
  const endIndex = typeof count === 'undefined' ? premiumProjectionTwo.length : count
  const premiums = (premiumProjectionTwo && premiumProjectionTwo.slice(0, endIndex)) || []

  return premiums
    .filter(premium => premium[projectionKey])
    .map((premium, index) => ({
      age: premium.projectionYear,
      premium: formatCurrency(stripCurrencyType(premium[projectionKey] || '0')),
      ...(isCumulative && {
        annualCumulativePremium: formatCurrency(
          cumulativeValue(premiums, projectionKey, index, paymentFrequency)
        ),
      }),
      ...sumInsuredData(sums[index]),
    }))
}

/**
 * returns generated table data from a policy's projection data and payment
 * frequency.
 *
 * @param {Object} options - object containing arguments
 * @param {Array} options.projections - a policy's projection data
 * @param {string} options.paymentFrequency - a policy's payment frequency
 * @param {Object} options.fields - Sitecore fields
 * @param {Boolean} options.isCumulative - request for cumulative data
 * @param {number} [count] - number of rows to generate
 * @returns {Object} projections table data for ProjectionsTable component
 */
export const makeTableData = (options: Object, count?: number): Object => ({
  columns: makeTableColumns(options),
  rows: makeTableRows(options, count),
})

// Projections logic for combined policies

export const combinedProjections = policies => {
  const paymentFrequencyNotEqualFlag = !policies.every(
    policy => policy.paymentFrequency === policies[0].paymentFrequency
  )

  const finalProjectionsArray = policies.reduce((policyFirst, policySecond) => {
    const policiesData =
      get(policyFirst.projections[0], 'premiumProjectionTwo', []).length >
      get(policySecond.projections[0], 'premiumProjectionTwo', []).length
        ? { previousPolicy: policyFirst, currentPolicy: policySecond }
        : { previousPolicy: policySecond, currentPolicy: policyFirst }
    const previousProjections = get(
      policiesData.previousPolicy,
      'projections[0].premiumProjectionTwo',
      []
    )
    const previousPaymentFrequency = get(policiesData.previousPolicy, 'paymentFrequency', '')

    const currentProjections = get(
      policiesData.currentPolicy,
      'projections[0].premiumProjectionTwo',
      []
    )
    const currentPaymentFrequency = get(policiesData.currentPolicy, 'paymentFrequency', '')

    const finalProjection = previousProjections.map((previousProjection, index) => {
      const { projectionYear } = previousProjection

      const currentPremiumMultiplier = paymentFrequencyNotEqualFlag
        ? premiumMultiplier[currentPaymentFrequency]
        : premiumMultiplier[POLICY_FREQUENCY_ANNUAL]

      const previousPremiumMultiplier = paymentFrequencyNotEqualFlag
        ? premiumMultiplier[previousPaymentFrequency]
        : premiumMultiplier[POLICY_FREQUENCY_ANNUAL]

      const uniquePremiumStructureKeys = Object.keys(
        Object.assign({}, ...[previousProjection, currentProjections[index]])
      )

      const premiumData = uniquePremiumStructureKeys.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: (
            previousPremiumMultiplier * parseFloat(get(previousProjection, cur, 0)) +
            currentPremiumMultiplier * parseFloat(get(currentProjections[index], cur, 0))
          )
            .toFixed(2)
            .toString(),
        }),
        {}
      )

      return {
        ...premiumData,
        projectionYear,
      }
    })

    return {
      paymentFrequency: POLICY_FREQUENCY_ANNUAL,
      projections: [
        {
          premiumProjectionTwo: finalProjection,
        },
      ],
    }
  })

  return finalProjectionsArray.projections[0].premiumProjectionTwo
}

/**
 * returns generated projections chart data.
 *
 * @param {Object} options - object containing arguments
 * @param {Array} options.projections - a policy's projection data
 * @param {string} [options.isCumulative] - true when premium values should be cumulative
 * @param {Object} [options.count] - number of data points to generate
 * @returns {Array} chart data for LineChart
 */
export const makeChartData = ({
  projections,
  isCumulative = false,
  isStepped = false,
  isLevel = false,
  isSelected = false,
  isCombined = false,
  count,
  paymentFrequency = POLICY_FREQUENCY_ANNUAL,
  selectedCover,
  policies,
}: Object): ?Array<Object> => {
  const chartProjections = isCombined
    ? combinedProjections(policies)
    : projectionByType(projections, 'premiumProjectionTwo')

  // return null if projection data for chart isn available.
  if (!chartProjections) {
    return null
  }

  let resultantFrequency = paymentFrequency

  if (isCombined) {
    resultantFrequency = checkPaymentFrequencyForCombinedPolicies(policies)
  }

  const value = (key: string, index: number): number => {
    const projectionKey = getProjectionCoversKeys(selectedCover, key)
    if (isCumulative) {
      return cumulativeValue(chartProjections, projectionKey, index, resultantFrequency)
    }
    return Number(stripCurrencyType(chartProjections[index][projectionKey] || '0'))
  }

  // apply `count` to control how many points to generate.
  const endIndex = typeof count === 'undefined' ? chartProjections.length : count
  const premiums = chartProjections.slice(0, endIndex)

  const selectedCoverKey = getProjectionCoversKeys(selectedCover, 'selected')
  // make chart data.
  return premiums
    .filter(index => index[selectedCoverKey])
    .map(({ projectionYear }, index) => ({
      age: projectionYear,
      ...(isSelected && { Selected: value('selected', index) }),
      ...(isStepped && { Stepped: value('stepped', index) }),
      ...(isLevel && { Level: value('level', index) }),
    }))
}

/**
 * returns generated projections PDF data.
 *
 * @param {Array} policies - policies data from a quote.
 * @param {number} [count] - number of rows to generate.
 * @returns {Object} projections data for PDF generation.
 * annualizedPremium and truncated projections based on selection
 */
export const makeProjectionsPDFData = ({ policies, count }: Object): Object => {
  const paymentFrequencyNotEqualAcrossPolicies = !policies.every(
    policy => policy.paymentFrequency === policies[0].paymentFrequency
  )
  return {
    policyStructure: policies.map(policyStructure => {
      const { paymentFrequency, projections } = policyStructure

      const sumInsuredProjection = projectionByType(projections, 'sumInsuredProjection')
      const premiumProjectionOne = projectionByType(projections, 'premiumProjectionOne')
      const premiumProjectionTwo = projectionByType(projections, 'premiumProjectionTwo')

      // if payment frequency varies, converts stepped/level/selected premium to annual
      const premiumValueWithMultiplier = (key: string, index: number): string =>
        (Number(premiumProjectionTwo[index][key] || '0') * premiumMultiplier[paymentFrequency])
          .toFixed(2)
          .toString()

      // apply `count` to control how many rows to generate.
      const endIndex = typeof count === 'undefined' ? premiumProjectionTwo.length : count
      return {
        ...policyStructure,
        projections: [
          {
            sumInsuredProjection:
              (sumInsuredProjection && sumInsuredProjection.slice(0, endIndex)) || [],
            premiumProjectionOne:
              (premiumProjectionOne && premiumProjectionOne.slice(0, endIndex)) || [],
            premiumProjectionTwo: paymentFrequencyNotEqualAcrossPolicies
              ? ((premiumProjectionTwo && premiumProjectionTwo.slice(0, endIndex)) || []).map(
                  ({ projectionYear }, index) => ({
                    projectionYear,
                    stepped: premiumValueWithMultiplier('stepped', index),
                    level: premiumValueWithMultiplier('level', index),
                    selected: premiumValueWithMultiplier('selected', index),
                  })
                )
              : (premiumProjectionTwo && premiumProjectionTwo.slice(0, endIndex)) || [],
          },
        ],
      }
    }),
    // flag specifies all premiums are updated to annualized premiums.
    annualisedPremium: paymentFrequencyNotEqualAcrossPolicies,
  }
}
