import get from 'lodash/get'
import {
  getFullAddressWithState,
  getPhoneNumber,
  getPhoneNumberCode,
} from '../../../../../utils/contactUtils'
import { PREFERRED_YES, COUNTRY_CODE } from '../../../../../constants/policies'
import { DEFAULT_PHONE_CODE } from '../../../../../constants/contactDetails'

import { getDropdownValue } from '../../../../../utils/commonUtils'

export const getDataFromForm = (field, defaultValue, form) =>
  get(form, `fields[${field}].value`, defaultValue || '')

export const initForms = props => {
  const {
    createQuote,
    sidebar: { panelProps: sidebarPanelProps },
    countriesPhoneCode,
  } = props

  if (!sidebarPanelProps)
    return {
      directors: [],
      contactNumberCode: getDropdownValue(DEFAULT_PHONE_CODE, countriesPhoneCode),
    }
  const { policyInstanceNo, partyType, payerType } = sidebarPanelProps
  if (!partyType && !payerType)
    return {
      directors: [],
      contactNumberCode: getDropdownValue(DEFAULT_PHONE_CODE, countriesPhoneCode),
    }

  const { quotes, activeIndex } = createQuote
  const activeQuote = quotes[activeIndex]
  const policy = activeQuote.policyStructure.find(p => p.policyInstanceNo === policyInstanceNo)
  const { identifier } = partyType || payerType
  const relationship = policy.relationships.find(
    rel =>
      rel.relatedParty && rel.relatedParty.identifiers.some(id => id.value === identifier.value)
  )

  if (!relationship || !relationship.relatedParty.businessName)
    return {
      directors: [],
      contactNumberCode: getDropdownValue(DEFAULT_PHONE_CODE, countriesPhoneCode),
    }
  const { relatedParty } = relationship

  const residentialAddressIndex = relatedParty.contactMethods.addresses.findIndex(
    address => address.preferred === PREFERRED_YES
  )

  const primaryEmailIndex =
    relatedParty.contactMethods.emails &&
    relatedParty.contactMethods.emails.findIndex(email => email.preferred === PREFERRED_YES)

  const email =
    primaryEmailIndex !== -1 && primaryEmailIndex !== undefined
      ? relatedParty.contactMethods.emails[primaryEmailIndex].email
      : ''

  const address =
    residentialAddressIndex !== -1
      ? relatedParty.contactMethods.addresses[residentialAddressIndex]
      : {}

  const data = {
    companyName: relatedParty.businessName,
    abn: relatedParty.abnNumber,
    abnConfirmation: relatedParty.abnNumber,
    email,
    residentialAddress: getFullAddressWithState([address]),
    residentialAddressHouseNo: address.houseNo,
    residentialAddressStreet: address.street,
    residentialAddressLocality: address.locality,
    residentialAddressState: address.state,
    residentialAddressCountry: address.country || COUNTRY_CODE,
    residentialAddressPostCode: address.postCode,
    isSoleDirector: relationship.companies && relationship.companies.length === 1,
    directors: relationship.companies || [],
    contactNumber: getDataFromForm('contactNumber'),
    contactNumberCode: getDropdownValue(DEFAULT_PHONE_CODE, countriesPhoneCode),
  }

  if (relatedParty.contactMethods && relatedParty.contactMethods.phones) {
    data.contactNumber = getPhoneNumber(get(relatedParty, 'contactMethods.phones', []))
    data.contactNumberCode = getDropdownValue(
      getPhoneNumberCode(get(relatedParty, 'contactMethods.phones', [])) || DEFAULT_PHONE_CODE,
      countriesPhoneCode
    )
  }

  return data
}
