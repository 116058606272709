// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../../utils/telemetry'
import { actionCreators } from '../../../../../../actions'

// components.
import SidebarNavItem from '../../../../../molecules/SidebarNavItem'

// utils
import { renderTextField, reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { isFeatureEnabledForAP } from '../../../../../../utils/commonUtils'
import history from '../../../../../../utils/browserHistory'

// constants
import { REJECT_CPI_LANDING_PAGE } from '../../../../../../constants/routes'

// styles.
import styles from '../requestManualQuote.styles'
import { ALTERATION_TYPES } from '../../../../../../constants/alterations'

type SelectChangeTypeProps = {
  // sitecore manage fields
  fields: Object<Object>,
  // function to open sidebar with quote type options
  selectQuoteType: Function,
  // alterations data
  alterations: Object,
  // master data
  masterData: Object,
  // actions
  actions: Object,
  // alterations error modal state
  altsErrorModal: Object,
}

const SelectionHeading = styled('div')(styles.resultsHeading)
export class SelectChangeType extends PureComponent<SelectChangeTypeProps> {
  componentDidMount() {
    const {
      actions: { initModal },
    } = this.props
    initModal('altsErrorModal')
  }

  editLifeInsuredCover = () => {
    const { selectQuoteType } = this.props
    selectQuoteType()
    const tagEvent = createEvent({
      GA: { category: 'Change Clients Cover', action: "Edit Life Insured's Cover" },
      Splunk: {
        attributes: {
          'workflow.name': 'Change Clients Cover - Edit',
        },
      },
    })
    tagEvent.end()
  }

  isRejectCPIUnavailable = () => {
    const {
      alterations: { rules },
    } = this.props
    return get(rules, 'businessData.assesment.rejectCPI_EligibleForAdviser', 'N') === 'N'
  }

  handleRejectCPI = () => {
    const {
      actions: { closeSidebar, showModal, setModalDescription, setAlterationTypeSelectedByUser },
      altsErrorModal,
    } = this.props
    const tagEvent = createEvent({
      GA: {
        category: 'Change Clients Cover',
        action: 'Reject CPI',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Change Clients Cover - Reject CPI',
        },
      },
    })
    tagEvent.end()
    if (this.isRejectCPIUnavailable()) {
      if (altsErrorModal.description !== 'rejectCPI') {
        setModalDescription({
          modalType: 'altsErrorModal',
          description: 'rejectCPI',
        })
      }
      showModal('altsErrorModal')
    } else {
      closeSidebar()
      setAlterationTypeSelectedByUser(ALTERATION_TYPES.REJECT_CPI)
      history.push(REJECT_CPI_LANDING_PAGE)
    }
  }

  render() {
    const {
      fields,
      masterData: { featureControlSwitch },
    } = this.props
    const { ChangeCoverType } = fields
    const { EditCoverTitle, RejectCPITitle, EditCoverDesc, RejectCPIDesc, ChangeCoverErrMessage } =
      reduceAuthorableFields(fields)
    const isRejectCPIEnabled = isFeatureEnabledForAP(featureControlSwitch, 'altsEnableRejectCPI')
    const isEditLifeInsuredCover = isFeatureEnabledForAP(
      featureControlSwitch,
      'apEditLifeInsuredCover'
    )
    return (
      <Fragment>
        <SelectionHeading>{renderTextField(ChangeCoverType)}</SelectionHeading>
        {isEditLifeInsuredCover && (
          <SidebarNavItem
            title={EditCoverTitle}
            description={EditCoverDesc}
            clickHandler={this.editLifeInsuredCover}
          />
        )}
        {isRejectCPIEnabled && (
          <SidebarNavItem
            title={RejectCPITitle}
            description={RejectCPIDesc}
            clickHandler={this.handleRejectCPI}
            status={this.isRejectCPIUnavailable() && ChangeCoverErrMessage}
          />
        )}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ alterations, masterList, modal }) => ({
  alterations,
  masterData: masterList.data,
  altsErrorModal: get(modal, 'altsErrorModal', {}),
})

export const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectChangeType)
