// @flow
import get from 'lodash/get'

import { getActivePolicy } from '../../../../../../utils/quoteUtils'

export const creditCardData = (props: Object): Object => {
  const {
    form,
    createQuote: { quotes, activeIndex },
    sidebar,
  } = props
  const { policyStructure } = quotes[activeIndex]
  const activePolicyInstanceNo = getActivePolicy(sidebar)
  const activePolicy = policyStructure.find(
    policy => policy.policyInstanceNo === activePolicyInstanceNo
  )
  // values for fields in form to be passed during form initialization
  return {
    cardNumber: '',
    cardHolderName: get(
      activePolicy,
      'paymentInstruction.creditCardDetails.cardOwnerName',
      get(form, 'fields.cardHolderName.value', '')
    ),
    expiryDate: get(
      activePolicy,
      'paymentInstruction.creditCardDetails.cardExpiryDate',
      get(form, 'fields.expiryDate.value', '')
    ),
    paymentDrawDay: get(
      activePolicy,
      'paymentInstruction.paymentDrawDay',
      get(form, 'fields.paymentDrawDay.value', '')
    ),
    termsAndConditions: get(form, 'fields.termsAndConditions.value', ''),
  }
}
