// @flow
import get from 'lodash/get'
import difference from 'lodash/difference'
import {
  DECREASE_SUM_INSURED,
  UNCHANGED,
  REMOVE_BENEFIT,
  BENEFIT_ALTS_TYPE_REMOVE,
  INCREASED,
  DECREASED,
} from '../constants/alterations'
import {
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
  SHORT_WAITING_PERIOD_FEATURE,
} from '../constants/benefit'
import {
  NON_SUPER_PRODUCT_CLASS,
  POLICY_BENEFIT_TISO,
  POLICY_FEATURE_LSBO,
  POLICY_FEATURE_LSO,
  POLICY_FEATURE_BO,
  PPP_EXC_BENEFIT_PERIOD_IP_STD_PLUS,
  PPP_EXC_WAITING_PERIOD_IP_STD_PLUS,
  INCOME_PROTECTION_STANDARD_ID_OLD,
  INCOME_PROTECTION_PLUS_ID,
  INCOME_PROTECTION_SSR_ID,
  INCOME_PROTECTION_PSR_ID,
  POLICY_PRODUCT_CODE_PPP_NON_SUPER,
  POLICY_PRODUCT_CODE_LCS,
  POLICY_FREQUENCY_MONTHLY,
  POLICY_BENEFIT_CI,
  POLICY_BENEFIT_CI_PLUS,
  POLICY_BENEFIT_CI_STD,
  INCOME_PROTECTION_ID,
  INCOME_PROTECTION_PHI_PLATINUM_ID,
  INCOME_PROTECTION_SR_ID,
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_LC_PLUS,
  POLICY_BENEFIT_LC_STANDARD,
  INCOME_PROTECTION_PHI_STD_2020_ID,
  INCOME_PROTECTION_PHI_PLAT_2020_ID,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  BUSINESS_EXPENSE,
  BUSINESS_EXPENSE_PLATINUM,
  POLICY_BENEFIT_PTD,
  SWP_ALLOWABLE_WP,
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PRODUCT_CODE_NON_SUPER,
} from '../constants/policies'
import { getConnectedBenefits } from './policyUtils'
import { isChildOptimiser } from './extendedQuoteUtils'
import { sortPeriodValues } from './productRules'

export const getRejectCPISavings = (policy: Object) => {
  const policyData =
    get(policy, 'escalation.policyChangeDetails', []).find(item => item.changeType === 'Re-Rate') ||
    {}
  const policyDataEscalation =
    get(policy, 'escalation.policyChangeDetails', []).find(
      item => item.changeType === 'Escalation'
    ) || {}
  return policyDataEscalation.newNetPremium - policyData.newNetPremium
}

// return a list of all policies bancsNo with linked relationship,
// recursively calling self for each linked policy node
export const getLinkedPolicyList = (policies, policyNode) => {
  const policyToSelect = new Set()

  const selectAllLinkedPolicy = policy => {
    policyToSelect.add(policy.bancsPolicyNo)
    if (
      difference(
        [
          policy.bancsPolicyNo,
          ...get(getConnectedBenefits(policies, policy), 'connectedPolicyBancsNo', []),
        ],
        [...policyToSelect]
      ).length === 0
    )
      return

    difference(
      [policy.bancsPolicyNo, ...getConnectedBenefits(policies, policy)?.connectedPolicyBancsNo],
      [...policyToSelect]
    ).forEach(nextWorkPolicyNodeNo => {
      const nextWorkPolicyNode = policies.find(
        policyToWork => policyToWork.bancsPolicyNo === nextWorkPolicyNodeNo
      )
      policyToSelect.add(nextWorkPolicyNodeNo)
      selectAllLinkedPolicy(nextWorkPolicyNode)
    })
  }

  selectAllLinkedPolicy(policyNode)
  return [...policyToSelect]
}

export const updateSumInsuredInAlteredBenefits = (
  alteredBenefits: Array<Object> = [],
  cover: Object,
  sumInsured: number
) => {
  let isBenefitExists = false
  const updatedAlteredBenefits: Array<Object> = alteredBenefits.map(benefit => {
    if (
      benefit.benefitCode === cover.type &&
      +benefit.benefitInstanceNo === +cover.benefitInstanceNo
    ) {
      isBenefitExists = true
      return {
        ...benefit,
        ...(benefit.isSystemGenerated && { isSystemGenerated: false }),
        /*
          resetting new premium when alteration is done for system generated benefit with
          unchanged premium returned in API response so that wrong new premium is not
          displayed because of setting isSystemGenerated as false above
        */
        ...(benefit.isSystemGenerated &&
          benefit.systemGeneratedReason === 'none' && { newGrossBenefitPremium: undefined }),
        benefitAlterationType: DECREASE_SUM_INSURED,
        newSumInsured: sumInsured ? parseInt(sumInsured, 10) : 0,
      }
    }
    return benefit
  })
  if (isBenefitExists) {
    return updatedAlteredBenefits
  }
  return [
    ...alteredBenefits,
    {
      benefitCode: cover.type,
      benefitInstanceNo: cover.benefitInstanceNo,
      benefitName: cover.name,
      benefitAlterationType: DECREASE_SUM_INSURED,
      newSumInsured: sumInsured ? parseInt(sumInsured, 10) : 0,
    },
  ]
}

export const resetSumInsuredInAlteredBenefits = (
  alteredBenefits: Array<Object> = [],
  cover: Object
) =>
  alteredBenefits.reduce((updatedAlteredBenefits, benefit) => {
    if (
      benefit.benefitCode === cover.type &&
      +benefit.benefitInstanceNo === +cover.benefitInstanceNo
    ) {
      if (benefit.modifiedOptions) {
        delete benefit.newSumInsured
        updatedAlteredBenefits.push({
          ...benefit,
          benefitAlterationType: UNCHANGED,
        })
      }
    } else {
      updatedAlteredBenefits.push(benefit)
    }
    return updatedAlteredBenefits
  }, [])

export const resetSumInsuredInPolicy = (policy: Object, cover: Object) => {
  const updatedAlteredBenefits = resetSumInsuredInAlteredBenefits(
    get(policy, 'alteration.alteredBenefits', []),
    cover
  )
  if (!updatedAlteredBenefits.length) {
    delete policy.alteration?.alteredBenefits
    if (!policy.alteration?.newPremiumPayingFrequency) {
      delete policy.alteration
    }
  }
  return {
    ...policy,
    ...((updatedAlteredBenefits.length || policy.alteration?.newPremiumPayingFrequency) && {
      alteration: {
        ...policy.alteration,
        calculatePolicyPrice: true,
        ...(updatedAlteredBenefits.length && { alteredBenefits: updatedAlteredBenefits }),
      },
    }),
  }
}

export const cancelBenefitOptionForPolicy = (
  policy: Object,
  cover: Object,
  featureName: number
) => {
  const alteredBenefits = get(policy, 'alteration.alteredBenefits', []).reduce(
    (updatedAlteredBenefits, benefit) => {
      if (
        benefit.benefitCode === cover.type &&
        +benefit.benefitInstanceNo === +cover.benefitInstanceNo &&
        benefit.modifiedOptions
      ) {
        const filteredModifiedOption = benefit.modifiedOptions.filter(
          options => get(options, 'feature.featureName', '') !== featureName
        )
        if (filteredModifiedOption.length) {
          updatedAlteredBenefits.push({
            ...benefit,
            modifiedOptions: filteredModifiedOption,
          })
        } else if (benefit.benefitAlterationType !== UNCHANGED) {
          delete benefit.modifiedOptions
          updatedAlteredBenefits.push(benefit)
        }
      } else {
        updatedAlteredBenefits.push(benefit)
      }
      return updatedAlteredBenefits
    },
    []
  )
  if (!alteredBenefits.length) {
    delete policy.alteration?.alteredBenefits
    if (!policy.alteration?.newPremiumPayingFrequency) {
      delete policy.alteration
    }
  }
  return {
    ...policy,
    ...((alteredBenefits.length || policy.alteration?.newPremiumPayingFrequency) && {
      alteration: {
        ...policy.alteration,
        calculatePolicyPrice: true,
        ...(alteredBenefits.length && { alteredBenefits }),
      },
    }),
  }
}

export const removeBenefitOptionForPolicy = (
  alteredBenefits: Array<Object> = [],
  cover: Object,
  featureName: number
) => {
  let isBenefitExists = false
  const updatedAlteredBenefits: Array<Object> = alteredBenefits.map(benefit => {
    if (
      benefit.benefitCode === cover.type &&
      +benefit.benefitInstanceNo === +cover.benefitInstanceNo
    ) {
      isBenefitExists = true

      const filteredModifiedOption = (benefit.modifiedOptions || []).filter(
        options => get(options, 'feature.featureName', '') !== featureName
      )

      if (filteredModifiedOption.length) {
        return {
          ...benefit,
          modifiedOptions: [
            ...filteredModifiedOption,
            { optionAlterationType: REMOVE_BENEFIT, feature: { featureName } },
          ],
        }
      }
      return {
        ...benefit,
        ...(benefit.isSystemGenerated && { isSystemGenerated: false }),
        /*
          resetting new premium when alteration is done for system generated benefit with
          unchanged premium returned in API response so that wrong new premium is not
          displayed because of setting isSystemGenerated as false above
        */
        ...(benefit.isSystemGenerated &&
          benefit.systemGeneratedReason === 'none' && { newGrossBenefitPremium: undefined }),
        modifiedOptions: [
          {
            optionAlterationType: REMOVE_BENEFIT,
            feature: {
              featureName,
            },
          },
        ],
      }
    }
    return benefit
  })
  if (isBenefitExists) {
    return updatedAlteredBenefits
  }

  return [
    ...alteredBenefits,
    {
      benefitCode: cover.type,
      benefitInstanceNo: cover.benefitInstanceNo,
      benefitName: cover.name,
      benefitAlterationType: UNCHANGED,
      modifiedOptions: [
        {
          optionAlterationType: REMOVE_BENEFIT,
          feature: {
            featureName,
          },
        },
      ],
    },
  ]
}

export const changeWaitingPeriodForPolicy = (
  alteredBenefits: Array<Object> = [],
  cover: Object,
  value: Object,
  isBenefitPeriod: Boolean
) => {
  let isBenefitExists = false
  const selectedObj = isBenefitPeriod
    ? [
        {
          optionAlterationType: DECREASED,
          benefitPeriod: {
            unit: value.unit,
            value: value.value,
          },
        },
      ]
    : [
        {
          optionAlterationType: INCREASED,
          waitingPeriod: {
            unit: value.unit,
            value: value.value,
          },
        },
      ]
  const updatedAlteredBenefits: Array<Object> = alteredBenefits.map(benefit => {
    if (
      benefit.benefitCode === cover.type &&
      +benefit.benefitInstanceNo === +cover.benefitInstanceNo
    ) {
      isBenefitExists = true

      if (benefit.modifiedOptions) {
        let alteredModifiedOptions
        if (isBenefitPeriod) {
          alteredModifiedOptions = [...benefit.modifiedOptions].filter(
            option => !(option.optionAlterationType === DECREASED && option.benefitPeriod)
          )
        } else {
          alteredModifiedOptions = [...benefit.modifiedOptions].filter(
            option => !(option.optionAlterationType === INCREASED && option.waitingPeriod)
          )
        }
        return {
          ...benefit,
          modifiedOptions: [...alteredModifiedOptions, ...selectedObj],
        }
      }
      return {
        ...benefit,
        ...(benefit.isSystemGenerated && { isSystemGenerated: false }),
        /*
          resetting new premium when alteration is done for system generated benefit with
          unchanged premium returned in API response so that wrong new premium is not
          displayed because of setting isSystemGenerated as false above
        */
        ...(benefit.isSystemGenerated &&
          benefit.systemGeneratedReason === 'none' && { newGrossBenefitPremium: undefined }),
        modifiedOptions: [...selectedObj],
      }
    }
    return benefit
  })
  if (isBenefitExists) {
    return updatedAlteredBenefits
  }

  return [
    ...alteredBenefits,
    {
      benefitCode: cover.type,
      benefitInstanceNo: cover.benefitInstanceNo,
      benefitName: cover.name,
      benefitAlterationType: UNCHANGED,
      modifiedOptions: [...selectedObj],
    },
  ]
}

export const resetWaitingBenefitPeriod = (
  policy: Object,
  cover: Object,
  isBenefitPeriod: Boolean
) => {
  let updatedAlteredBenefits = get(policy, 'alteration.alteredBenefits', []).reduce(
    (updatedBenefits, benefit) => {
      if (
        benefit.benefitCode === cover.type &&
        +benefit.benefitInstanceNo === +cover.benefitInstanceNo &&
        benefit.modifiedOptions
      ) {
        let filtedOptions
        if (isBenefitPeriod) {
          filtedOptions = benefit.modifiedOptions.filter(
            option => !(option.optionAlterationType === DECREASED && option.benefitPeriod)
          )
        } else {
          filtedOptions = benefit.modifiedOptions.filter(
            option => !(option.optionAlterationType === INCREASED && option.waitingPeriod)
          )
        }
        delete benefit.modifiedOptions
        updatedBenefits.push({
          ...benefit,
          ...(filtedOptions.length && { modifiedOptions: filtedOptions }),
        })
      } else {
        updatedBenefits.push(benefit)
      }
      return updatedBenefits
    },
    []
  )

  updatedAlteredBenefits = updatedAlteredBenefits.filter(
    benefit =>
      !(
        benefit.benefitAlterationType === UNCHANGED &&
        !(benefit.modifiedOptions && benefit.modifiedOptions.length)
      )
  )

  if (!updatedAlteredBenefits.length) {
    delete policy.alteration?.alteredBenefits
    if (!policy.alteration?.newPremiumPayingFrequency) {
      delete policy.alteration
    }
  }
  return {
    ...policy,
    ...((updatedAlteredBenefits.length || policy.alteration?.newPremiumPayingFrequency) && {
      alteration: {
        ...policy.alteration,
        calculatePolicyPrice: true,
        ...(updatedAlteredBenefits.length && { alteredBenefits: updatedAlteredBenefits }),
      },
    }),
  }
}

export const removeBenefitInAlteredBenefits = (
  alteredBenefits: Array<Object> = [],
  cover: Object
) => {
  let isBenefitExists = false
  const updatedAlteredBenefits: Array<Object> = alteredBenefits.map(benefit => {
    if (
      benefit.benefitCode === cover.type &&
      +benefit.benefitInstanceNo === +cover.benefitInstanceNo
    ) {
      isBenefitExists = true
      const benefitClone = {
        ...benefit,
        ...(benefit.isSystemGenerated && { isSystemGenerated: false }),
        /*
          resetting new premium when alteration is done for system generated benefit with
          unchanged premium returned in API response so that wrong new premium is not
          displayed because of setting isSystemGenerated as false above
        */
        ...(benefit.isSystemGenerated &&
          benefit.systemGeneratedReason === 'none' && { newGrossBenefitPremium: undefined }),
        benefitAlterationType: BENEFIT_ALTS_TYPE_REMOVE,
      }
      delete benefitClone.newSumInsured
      return benefitClone
    }
    return benefit
  })
  if (isBenefitExists) {
    return updatedAlteredBenefits
  }

  return [
    ...alteredBenefits,
    {
      benefitCode: cover.type,
      benefitInstanceNo: cover.benefitInstanceNo,
      benefitName: cover.name,
      benefitAlterationType: BENEFIT_ALTS_TYPE_REMOVE,
    },
  ]
}

// Finds the total sum insured and total premiums
// isNewPremium true for new premium tab if false then
// calculate total sum insured and premium using existing values
export const getTotalSumInsuredAndTotalPremiums = (
  coverGroup: Array<Object>,
  alteration: Array<Object>,
  isNewPremium: Boolean = true
) => {
  let sumInsured = 0
  let premiums = 0
  coverGroup.forEach(cover => {
    let isPresent = false
    if (isNewPremium) {
      get(alteration, 'alteredBenefits', []).some(benefit => {
        if (
          +cover.benefitInstanceNo === +benefit.benefitInstanceNo &&
          cover.type === benefit.benefitCode
        ) {
          isPresent = true
          sumInsured += parseInt(
            benefit.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
              ? 0
              : benefit.newSumInsured || cover.coverAmount,
            10
          )
          premiums +=
            benefit.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
              ? 0
              : benefit.newGrossBenefitPremium || +cover.premiumAmount
          return true
        }
        return false
      })
      if (!isPresent) {
        sumInsured += parseInt(cover.coverAmount, 10) || 0
        premiums += +cover.premiumAmount || 0
      }
    } else {
      sumInsured += parseInt(cover.coverAmount, 10) || 0
      premiums += +cover.premiumAmount || 0
    }
  })
  return {
    sumInsured,
    premiums,
  }
}

export const findOriginalCover = (policy, policyInstanceNo, benefitInstanceNo, type) =>
  +policy.policyInstanceNo === +policyInstanceNo &&
  get(policy, 'covers', []).find(
    cover => +cover.benefitInstanceNo === +benefitInstanceNo && cover.type === type
  )

/**
 * Gets the altered cover amount all defaults to the current cover amount
 * @param {*} policy
 * @param {*} cover
 * @returns
 */
const getAlteredCoverAmount = (policy, cover) => {
  const alteredBenefit = policy.alteration?.alteredBenefits?.find(
    benefit =>
      `${benefit.benefitCode}${benefit.benefitInstanceNo}` ===
      `${cover.type}${cover.benefitInstanceNo}`
  )
  if (alteredBenefit) {
    return alteredBenefit.benefitAlterationType === 'removeBenefit'
      ? 0
      : alteredBenefit.newSumInsured
  }
  return cover.coverAmount
}

/**
 * Gets the cover and policy object references in childBenefitReference or parentBenefitReference
 * @param {array[object]} policies
 * @param {object} reference
 * @param {'parent' | 'child'} type
 * @returns
 */
export const getReferenceBenefit = (policies, reference, type) => {
  const policy = policies.find(
    p =>
      `${p.policyInstanceNo}` ===
      reference[type === 'parent' ? 'parentPolicyReferenceNo' : 'childPolicyReferenceNo']
  )

  const cover = policy?.covers.find(
    c =>
      `${c.type}${c.benefitInstanceNo}` ===
      `${reference[type === 'parent' ? 'parentType' : 'childType']}${
        reference[type === 'parent' ? 'parentBenefitInstanceNo' : 'childBenefitInstanceNo']
      }`
  )

  return { policy, cover }
}

/**
 *  As per RET-11705 when a blur the sum insured input we need to check if the sum of
 *  all child benefits of the cover is not more than the altered sum insured
 *  additionally we also need to make sure that the sum of the cover the user has modified
 * as well as it's siblings are not more than ther cover's parent benefit
 * @param {array[object]} policies
 * @param {object} selectedCover
 * @returns
 */
export const validateParentChildCoverAmounts = (policies, selectedCover) => {
  const key = `${selectedCover.type}${selectedCover.benefitInstanceNo}`

  const sumChildBenefitAmount = cover =>
    (cover?.childBenefitReferences ?? []).reduce((summedCoverAmount, reference) => {
      const { policy: referencePolicy, cover: referenceCover } = getReferenceBenefit(
        policies,
        reference,
        'child'
      )
      return (
        summedCoverAmount + parseInt(getAlteredCoverAmount(referencePolicy, referenceCover), 10)
      )
    }, 0)

  const coverAmounts = policies.flatMap(policy =>
    policy.covers.map(cover => ({
      name: `${cover.type}${cover.benefitInstanceNo}`,
      coverAmount: getAlteredCoverAmount(policy, cover),
      siblingBenefitCoverAmount: cover.parentBenefitReference
        ? sumChildBenefitAmount(
            getReferenceBenefit(policies, cover.parentBenefitReference, 'parent').cover
          )
        : null,
      childBenefitsCoverAmount: sumChildBenefitAmount(cover),
      parentBenefitCoverAmount: cover.parentBenefitReference
        ? getAlteredCoverAmount(
            getReferenceBenefit(policies, cover.parentBenefitReference, 'parent').policy,
            getReferenceBenefit(policies, cover.parentBenefitReference, 'parent').cover
          )
        : null,
    }))
  )

  const {
    coverAmount,
    childBenefitsCoverAmount,
    parentBenefitCoverAmount,
    siblingBenefitCoverAmount,
  } = coverAmounts.find(item => item.name === key)

  return (
    childBenefitsCoverAmount > coverAmount ||
    (typeof parentBenefitCoverAmount === 'number' &&
      parentBenefitCoverAmount < siblingBenefitCoverAmount)
  )
}

export const isBenefitOptionRemoved = ({
  featureName,
  type,
  benefitInstanceNo,
  alteredBenefits,
}) => {
  let isBenefitOptionRemovedFlag = false
  alteredBenefits.some(benefit => {
    if (
      benefit.benefitCode === type &&
      +benefit.benefitInstanceNo === +benefitInstanceNo &&
      benefit.modifiedOptions
    ) {
      benefit.modifiedOptions.some(option => {
        if (get(option, 'feature.featureName', '') === featureName) {
          isBenefitOptionRemovedFlag = true
          return true
        }
        return false
      })
    }
    return false
  })

  return isBenefitOptionRemovedFlag
}

/**
 * This is a check for when we want to disable the remove button for a cover/benefit
 * If the policy contains Child CI benefit but only 1 of the required co-benefits for Child CI
 * then we want to disable the remove button for that required co-benefit
 * https://jira.mlctech.io/browse/RET-21589
 * @param {*} cover
 * @param {*} alteredBenefits
 * @param {*} alteration
 * @returns boolean
 */
export const noHangingChildCiCover = (cover, alteredBenefits = [], alteration) => {
  const listOfApplicableBenefitCodes = [
    POLICY_BENEFIT_CI,
    POLICY_BENEFIT_CI_PLUS,
    POLICY_BENEFIT_CI_STD,
    INCOME_PROTECTION_ID,
    INCOME_PROTECTION_PHI_PLATINUM_ID,
    INCOME_PROTECTION_PLUS_ID,
    INCOME_PROTECTION_PSR_ID,
    INCOME_PROTECTION_SR_ID,
    INCOME_PROTECTION_STANDARD_ID_OLD,
    INCOME_PROTECTION_SSR_ID,
    POLICY_BENEFIT_LC,
    POLICY_BENEFIT_LC_PLUS,
    POLICY_BENEFIT_LC_STANDARD,
    INCOME_PROTECTION_PHI_STD_2020_ID,
    INCOME_PROTECTION_PHI_PLAT_2020_ID,
    BUSINESS_EXPENSE,
    BUSINESS_EXPENSE_PLATINUM,
    POLICY_BENEFIT_PTD,
  ]

  const { type } = cover
  const removedBenefits = (alteration?.alteredBenefits ?? [])
    .filter(benefit => benefit.benefitAlterationType === 'removeBenefit')
    .map(benefit => `${benefit.benefitCode}${benefit.benefitInstanceNo}`)

  const benefits = alteredBenefits
    .map(benefit => ({
      type: benefit.type,
      benefitIdentifier: `${benefit.type}${benefit.benefitInstanceNo}`,
    }))
    .filter(({ benefitIdentifier }) => !removedBenefits.includes(benefitIdentifier))

  const benefitCodes = benefits.map(benefit => benefit.type)
  if (
    listOfApplicableBenefitCodes.includes(type) &&
    benefitCodes.includes(POLICY_CHILD_COVER_BENEFIT_CODE)
  ) {
    let applicablePolicyCount = 0
    benefitCodes.forEach(code => {
      if (listOfApplicableBenefitCodes.includes(code)) {
        applicablePolicyCount += 1
      }
    })
    if (applicablePolicyCount > 1) {
      return true
    }
    return false
  }
  return true
}

export const isRemovedCover = (cover, alteredBenefits = []) =>
  alteredBenefits.some(
    alteredBenefit =>
      alteredBenefit.benefitCode === cover.type &&
      String(alteredBenefit.benefitInstanceNo) === String(cover.benefitInstanceNo) &&
      alteredBenefit.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
  )

export const getIsMoreThanOneActiveBenefit = (
  covers: Array<Object>,
  alteredBenefits: Array<Object> = []
) => {
  let noOfActiveBenefits = 0
  return covers.some(cover => {
    const isBenefitRemoved = isRemovedCover(cover, alteredBenefits)
    if (!isBenefitRemoved) {
      noOfActiveBenefits += 1
    }
    if (noOfActiveBenefits === 2) {
      return true
    }
    return false
  })
}

// Adding LSBO subBenefit in features
export const addSubBenefitInFeatures = (features, subBenefits = []) => {
  const existingFeatures = features.filter(
    ({ featureApplicable, featureName }) =>
      featureApplicable === 'Y' && featureName !== POLICY_BENEFIT_TISO
  )
  subBenefits.forEach(({ applicable, subBenefitCode, premium }) => {
    if (
      applicable === 'Y' &&
      [POLICY_FEATURE_LSBO, POLICY_FEATURE_LSO, POLICY_FEATURE_BO].includes(subBenefitCode)
    ) {
      existingFeatures.push({
        featureApplicable: applicable,
        featureName: subBenefitCode,
        premium,
        isSubBenefit: true,
      })
    }
  })

  return existingFeatures
}

// Checks if only child benefit present with tpd optimiser and other benefits are removed
export const getIsChildOptimiserOnlyBenefitInPolicy = ({
  policyStructureArray = [],
  coverToCompareWith,
  policyInstanceNo,
}) => {
  const { optimiserParentBenefitReference = {} } = coverToCompareWith
  if (
    optimiserParentBenefitReference &&
    optimiserParentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
  ) {
    return policyStructureArray.some(({ productClass, covers, alteration = {} }) => {
      if (productClass === NON_SUPER_PRODUCT_CLASS) {
        const filteredCovers = covers.filter(policyCover => {
          if (
            isChildOptimiser(policyCover, coverToCompareWith, policyInstanceNo) ||
            isRemovedCover(policyCover, alteration.alteredBenefits)
          ) {
            return false
          }
          return true
        })
        if (!filteredCovers.length) {
          return true
        }
      }
      return false
    })
  }
  return false
}

// Filter out removed benefits for conformation page
export const filterBenifitsForConformationPage = (
  applicableFeatures: Array<Object>,
  modifiedOptions: Array<Object>
) =>
  applicableFeatures.filter(
    benefit =>
      !modifiedOptions.some(
        option =>
          benefit.featureName === get(option, 'feature.featureName') &&
          option.optionAlterationType === REMOVE_BENEFIT
      )
  )

// Filter out removed covers for conformation page
export const filterCoversForConformationPage = (
  covers: Array<Object>,
  alteredBenefits: Array<Object>
) =>
  covers.filter(
    cover =>
      !alteredBenefits.some(
        benefit =>
          benefit.benefitCode === cover.type &&
          +benefit.benefitInstanceNo === +cover.benefitInstanceNo &&
          benefit.benefitAlterationType === BENEFIT_ALTS_TYPE_REMOVE
      )
  )

// check weather feature is eligible for removal
export const isRemoveEligibleForRemoval = ({
  benefits,
  type,
  benefitInstanceNo,
  featureName,
  isSubBenefit = false,
}) => {
  if (isSubBenefit) {
    return false
  }
  let isRemoveEligible = true
  const filteredbenefit = benefits.find(
    benefit => benefit.type === type && +benefit.benefitInstanceNo === +benefitInstanceNo
  )
  if (filteredbenefit) {
    const { features } = filteredbenefit
    features &&
      features.forEach(feature => {
        if (
          get(feature, 'assesment.featureCanBeRemoved', 'Y') === 'N' &&
          get(feature, 'assesment.featureName') === featureName
        ) {
          isRemoveEligible = false
        }
      })
  }
  return isRemoveEligible
}

export const getWaitingPeriodFromModifiedOptions = modifiedOptions => {
  const filteredModifiedOption = modifiedOptions.find(
    e => e.waitingPeriod && e.optionAlterationType === INCREASED
  )
  return get(filteredModifiedOption, 'waitingPeriod', null)
}

export const getCoverPeriodFromModifiedOptions = modifiedOptions => {
  const filteredModifiedOption = modifiedOptions.find(
    e => e.benefitPeriod && e.optionAlterationType === DECREASED
  )
  return get(filteredModifiedOption, 'benefitPeriod', null)
}

export const getIsAlterationInPolicies = policyStructure =>
  policyStructure.some(
    policy =>
      policy.alteration?.alteredBenefits?.some(benefit => !benefit.isSystemGenerated) ||
      policy.alteration?.newPremiumPayingFrequency
  )

export const removeAlterationsInPolicyStructure = policyStructure =>
  policyStructure.map(item => {
    const { alteration, ...rest } = item
    return rest
  })

export const filteredBenefitPeriodForIp = (
  type: string,
  allowableBenefitPeriod: Array<Object>,
  productId: string
) => {
  let benefitPeriod = allowableBenefitPeriod
  if (
    (productId === POLICY_PRODUCT_CODE_PPP_NON_SUPER || productId === POLICY_PRODUCT_CODE_LCS) &&
    (type === INCOME_PROTECTION_STANDARD_ID_OLD || type === INCOME_PROTECTION_PLUS_ID)
  ) {
    benefitPeriod = allowableBenefitPeriod.filter(
      period =>
        !PPP_EXC_BENEFIT_PERIOD_IP_STD_PLUS.find(
          ({ value, unit }) => period.value === value && period.unit === unit
        )
    )
  }
  return benefitPeriod
}

export const filteredWaitingPeriodForIp = (
  type: string,
  allowableWaitingPeriod: Array<Object>,
  productId: string
) => {
  let waitingPeriod = allowableWaitingPeriod
  if (productId === POLICY_PRODUCT_CODE_PPP_NON_SUPER || productId === POLICY_PRODUCT_CODE_LCS) {
    if (
      type === INCOME_PROTECTION_STANDARD_ID_OLD ||
      type === INCOME_PROTECTION_PLUS_ID ||
      type === INCOME_PROTECTION_SSR_ID ||
      type === INCOME_PROTECTION_PSR_ID
    ) {
      waitingPeriod = allowableWaitingPeriod.filter(
        period =>
          !PPP_EXC_WAITING_PERIOD_IP_STD_PLUS.find(
            ({ value, unit }) => period.value === value && period.unit === unit
          )
      )
    }
  }
  return waitingPeriod
}

// RET-16569 - Set alteration effective date at policy level
export const getPolicyAlterationEffectiveDate = (activePolicy, policyStructure) => {
  // Default value of effective date is set as policyAlterationEligibilityDate
  let policyAlterationEligibilityDate = activePolicy?.policyAlterationEligibilityDate

  // check linked policy has monthly frequency
  const checkPaymentFrequency = linkedPolicy =>
    linkedPolicy?.paymentFrequency === POLICY_FREQUENCY_MONTHLY

  // policyAlterationEligibilityDate is picked from linked policy(if payment frequency is monthly)
  const updatePolicyAlterationEligibilityDate = linkedPolicy => {
    if (checkPaymentFrequency(linkedPolicy)) {
      policyAlterationEligibilityDate = linkedPolicy?.policyAlterationEligibilityDate
    }
  }

  // Update eligibilty date based on parentBenefitReference
  const handleParentBenefitReference = parentBenefitReference => {
    const parentPolicyReferenceNo = Number(parentBenefitReference.parentPolicyReferenceNo)
    if (parentPolicyReferenceNo !== Number(activePolicy.policyInstanceNo)) {
      const linkedPolicy = policyStructure.find(
        policy => Number(policy.policyInstanceNo) === parentPolicyReferenceNo
      )
      updatePolicyAlterationEligibilityDate(linkedPolicy)
    }
  }

  // Update eligibilty date based on childBenefitReferences
  const handleChildBenefitReferences = childBenefitReferences => {
    childBenefitReferences.forEach(childPolicy => {
      const childPolicyReferenceNo = Number(childPolicy.childPolicyReferenceNo)
      if (childPolicyReferenceNo !== Number(activePolicy.policyInstanceNo)) {
        const linkedPolicy = policyStructure.find(
          policy => Number(policy.policyInstanceNo) === childPolicyReferenceNo
        )
        updatePolicyAlterationEligibilityDate(linkedPolicy)
      }
    })
  }

  // Check for linked policy
  // Any cover in the policy has either parentBenefitReference or childBenefitReferences
  activePolicy?.covers?.forEach(cover => {
    if (cover?.parentBenefitReference) {
      handleParentBenefitReference(cover.parentBenefitReference)
    }
    if (cover?.childBenefitReferences) {
      handleChildBenefitReferences(cover.childBenefitReferences)
    }
  })

  return policyAlterationEligibilityDate
}

export const getFeatureFromMasterData = (feature, masterData) =>
  (masterData?.features ?? []).find(
    ({ code, duration, durationUnit: { value } = {} }) =>
      code === feature.featureName &&
      String(duration) === String(feature.duration) &&
      value === feature.durationUnit
  )

export const makeAltsProductsWaitingPeriods = (
  altsProductRules,
  { type, periods, productId, featuresList }
) => {
  const { coverPeriod, coverPeriodUnit, waitingPeriod, waitingPeriodUnit } = periods
  const currentProductRules = (altsProductRules || []).find(item => item?.productId === productId)
  const productRulesForBenefit =
    get(currentProductRules, 'benefits', []).find(benefit => benefit.benefitCode === type) || {}
  let allowableBenefitPeriod = get(productRulesForBenefit, 'allowableBenefitPeriod', [])
  allowableBenefitPeriod = filteredBenefitPeriodForIp(
    type,
    allowableBenefitPeriod,
    currentProductRules?.productId
  )

  const sortedAllowableBenefitPeriod = sortPeriodValues(allowableBenefitPeriod, false)

  let benefitFlag = false
  const filteredbenefitPeriodData = []
  sortedAllowableBenefitPeriod.forEach(period => {
    const { value, unit } = period
    if (+value === +coverPeriod && unit === coverPeriodUnit) {
      benefitFlag = true
    }
    if (benefitFlag) {
      filteredbenefitPeriodData.push(period)
    }
  })
  // Filter waiting period & Benefit Period data to display only the increased data
  // Data in sitecore should be formatted.
  let allowableWaitingPeriod = get(productRulesForBenefit, 'allowableDefermentPeriod', [])

  allowableWaitingPeriod = filteredWaitingPeriodForIp(
    type,
    allowableWaitingPeriod,
    currentProductRules?.productId
  )

  const isSWPFeature = featuresList?.find(
    feature => feature.featureName === SHORT_WAITING_PERIOD_FEATURE
  )
  if (
    isSWPFeature &&
    (productId === POLICY_PRODUCT_CODE_SUPER || productId === POLICY_PRODUCT_CODE_NON_SUPER)
  ) {
    allowableWaitingPeriod = allowableWaitingPeriod.filter(period =>
      SWP_ALLOWABLE_WP.find(({ value, unit }) => period.value === value && period.unit === unit)
    )
  }

  const sortedAllowableWaitingPeriod = sortPeriodValues(allowableWaitingPeriod)
  const filteredWaitingPeriodData = []
  let flag = false
  sortedAllowableWaitingPeriod.forEach(period => {
    const { value, unit } = period
    if (+value === +waitingPeriod && unit === waitingPeriodUnit) {
      flag = true
    }
    if (flag) {
      filteredWaitingPeriodData.push(period)
    }
  })

  return {
    benefitPeriodData: filteredbenefitPeriodData,
    waitingPeriodData: filteredWaitingPeriodData,
  }
}
