// @flow
import {
  MASTERLIST_FETCH_INIT,
  MASTERLIST_FETCH_SUCCESS,
  MASTERLIST_FETCH_ERROR,
  MASTERLIST_FETCH_FAILURE,
} from '../actions/types/masterList'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  data: {},
  errors: [],
  isFetching: false,
}

const masterList = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case MASTERLIST_FETCH_INIT: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case MASTERLIST_FETCH_SUCCESS: {
      return {
        ...action.payload,
        isFetching: false,
      }
    }
    case MASTERLIST_FETCH_ERROR:
    case MASTERLIST_FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default masterList
