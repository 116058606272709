/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconPolicy32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M8.608 2.5V1l-1.5 1.5h1.5zM3 8.5V7L1.515 8.715 3 8.5zm21.392-6h1.5l-1.5-1.5v1.5zM30 8.5l1.485.215L30 7v1.5zM16.5 1H8.608v3H16.5V1zM1.515 8.715c1.133 7.834 3.94 13.343 6.89 16.924 1.472 1.785 2.985 3.097 4.348 3.973C14.061 30.452 15.4 31 16.5 31v-3c-.244 0-.991-.183-2.125-.911-1.077-.693-2.36-1.789-3.654-3.358-2.58-3.13-5.177-8.12-6.236-15.446l-2.97.43zM7.108 2.5c0 1.474-.42 2.587-1.069 3.312C5.409 6.515 4.436 7 3 7v3c2.172 0 4.002-.765 5.274-2.187 1.253-1.4 1.834-3.287 1.834-5.313h-3zM16.5 4h7.892V1H16.5v3zm12.015 4.285c-1.059 7.326-3.657 12.316-6.236 15.446-1.293 1.57-2.577 2.665-3.654 3.358-1.134.728-1.881.911-2.125.911v3c1.1 0 2.44-.547 3.747-1.388 1.363-.876 2.876-2.188 4.347-3.973 2.95-3.58 5.758-9.09 6.89-16.924l-2.969-.43zM22.892 2.5c0 2.026.58 3.913 1.834 5.313C25.998 9.235 27.828 10 30 10V7c-1.436 0-2.41-.485-3.039-1.188-.65-.725-1.069-1.838-1.069-3.312h-3z"
      fill="#000"
    />
  </Icon>
)

export default IconPolicy32
