/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconHeart32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M14.5 10a1.5 1.5 0 0 0 3 0h-3zM16 26.5l-.988 1.129a1.5 1.5 0 0 0 1.976 0L16 26.5zM17.5 10c0-1.683-1.182-3.195-2.454-4.184C13.71 4.778 11.896 4 10 4v3c1.104 0 2.289.472 3.204 1.184.978.76 1.296 1.499 1.296 1.816h3zM10 4C6.792 4 4.968 5.835 4.033 7.704 3.143 9.485 3 11.365 3 12h3c0-.365.107-1.735.717-2.954C7.282 7.916 8.208 7 10 7V4zm-7 8c0 3.38 2.262 6.506 4.662 9.033 2.439 2.566 5.439 4.923 7.35 6.596l1.976-2.258c-2.089-1.827-4.838-3.97-7.15-6.404C7.488 16.494 6 14.12 6 12H3zm14.5-2c0-.317.318-1.055 1.296-1.816C19.71 7.472 20.896 7 22 7V4c-1.895 0-3.711.778-5.046 1.816C15.682 6.806 14.5 8.317 14.5 10h3zM22 7c1.792 0 2.718.915 3.283 2.046.61 1.219.717 2.589.717 2.954h3c0-.635-.143-2.515-1.033-4.296C27.032 5.834 25.208 4 22 4v3zm4 5c0 2.12-1.488 4.494-3.837 6.967-2.313 2.434-5.062 4.577-7.15 6.404l1.975 2.258c1.911-1.673 4.912-4.03 7.35-6.596C26.737 18.506 29 15.38 29 12h-3z"
      fill="#000"
    />
  </Icon>
)

export default IconHeart32
