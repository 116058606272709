import { useState, useEffect, useRef } from 'react'

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleKeyboardEvents = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    }
  }

  const handleDOMClicks = (event: MouseEvent) => {
    if (ref.current && !(ref.current as Element).contains(event.target as Node)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvents, true)
    document.addEventListener('click', handleDOMClicks, true)
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents, true)
      document.removeEventListener('click', handleDOMClicks, true)
    }
  }, [])

  return { ref, isComponentVisible, setIsComponentVisible }
}
