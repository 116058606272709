// @flow
import {
  FORM_UPDATE,
  FORM_UPDATE_FIELD,
  FORM_RESET,
  FORM_SUBMIT,
  FORM_INIT,
  FORM_VALIDATE,
  FORM_SUBMIT_COMPLETE,
  FORM_UPDATE_VALIDATIONS,
  FORM_VALIDATE_ONEOF,
  FORM_RESET_FIELD,
  FORM_ADD_FIELD,
  FORM_REMOVE_FIELDS,
  FORM_SET_VALID,
  FORM_SET_DIRTY,
  FORM_SET_SENDING,
  FORM_DELETE_FROM_STORE,
  FORM_SUBMIT_PARTIALLY,
} from '../actions/types/form'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

// utils.
import { validateAll, runAllValidations, validateOneOf } from '../utils/formUtils'
import { INIT_STATE } from '../actions/types/navigation'

const initialFormState = (schema, data) => {
  const fields = {}
  Object.keys(schema).forEach(key => {
    fields[key] = {
      error: false,
      value: data && data[key] !== undefined ? data[key] : '',
    }
  })
  return {
    isValid: false,
    isSending: false,
    isDirty: false,
    fields,
  }
}

const initialState = {}

const forms = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case FORM_INIT: {
      const { id, schema, data, dynamicFields } = action.payload
      const { fields, ...init } = initialFormState(schema, data)
      return {
        ...state,
        [id]: {
          ...init,
          fields: {
            ...fields,
            ...dynamicFields,
          },
        },
      }
    }

    case FORM_UPDATE: {
      const { id, data } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          fields: {
            ...state[id].fields,
            ...data,
          },
        },
      }
    }

    case FORM_UPDATE_FIELD: {
      const { id, field, data } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isDirty: true,
          fields: {
            ...state[id].fields,
            [field]: {
              ...state[id].fields[field],
              ...data,
            },
          },
        },
      }
    }

    case FORM_ADD_FIELD: {
      const { id, field, data } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          fields: {
            ...state[id].fields,
            [field]: {
              error: false,
              ...data,
            },
          },
        },
      }
    }

    case FORM_REMOVE_FIELDS: {
      const { id, field } = action.payload
      const fields = typeof field === 'string' ? [field] : field
      const formFields = state[id].fields
      const updatedFields = Object.keys(formFields)
        .filter(key => !!fields.filter(fieldKey => key !== fieldKey).length)
        .reduce((acc, key) => {
          acc[key] = formFields[key]
          return acc
        }, {})
      return {
        ...state,
        [id]: {
          ...state[id],
          fields: updatedFields,
        },
      }
    }

    case FORM_RESET_FIELD: {
      const { id, field } = action.payload
      const resetFields = {}
      const fields = typeof field === 'string' ? [field] : field
      fields.forEach(item => {
        resetFields[item] = {
          error: false,
          value: '',
        }
      })
      return {
        ...state,
        [id]: {
          ...(state[id] && {
            ...state[id],
            fields: {
              ...state[id].fields,
              ...resetFields,
            },
          }),
        },
      }
    }

    case FORM_RESET: {
      const { id, schema, data } = action.payload
      return {
        ...state,
        [id]: {
          ...initialFormState(schema, data),
        },
      }
    }

    case FORM_VALIDATE: {
      const { id, schema } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isValid: validateAll(schema, state[id].fields),
        },
      }
    }

    case FORM_VALIDATE_ONEOF: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isValid: validateOneOf(state[id].fields),
        },
      }
    }

    case FORM_UPDATE_VALIDATIONS: {
      const { id, schema } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          ...runAllValidations(schema, state[id]),
        },
      }
    }

    case FORM_SET_VALID: {
      const { id, isValid } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isValid,
        },
      }
    }

    case FORM_SET_DIRTY: {
      const { id, isDirty } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isDirty,
        },
      }
    }

    case FORM_SET_SENDING: {
      const { id, isSending } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isSending,
        },
      }
    }

    case FORM_SUBMIT: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isSending: true,
          isDirty: false,
        },
      }
    }
    case FORM_SUBMIT_PARTIALLY: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isDirty: false,
        },
      }
    }

    case FORM_DELETE_FROM_STORE: {
      const ids = action.payload
      if (ids && ids.length) {
        ids.forEach(id => {
          delete state[id]
        })
      }
      return {
        ...state,
      }
    }

    case FORM_SUBMIT_COMPLETE: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isSending: false,
          isDirty: false,
        },
      }
    }

    case INIT_STATE: {
      return {
        captureClient: state.captureClient,
        chooseCover: state.chooseCover,
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default forms
