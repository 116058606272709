/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowDown32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M15.5 25.5l-1.06 1.06 1.06 1.061 1.06-1.06L15.5 25.5zm7.94-10.06l-9 9 2.12 2.12 9-9-2.12-2.12zm-6.88 9l-9-9-2.12 2.12 9 9 2.12-2.12zM14 4v21.5h3V4h-3z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowDown32
