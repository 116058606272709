/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronRight32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.879 16.5l-8.94-8.94 2.122-2.12L22.12 16.5l-11.06 11.06-2.122-2.12 8.94-8.94z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronRight32
