// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Textarea } from '@mlcl-digital/mlcl-design'

// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from '../../../../../../actions'

// utilities
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { errorCheck } from '../../../../../../utils/formUtils'

// constants
import { COVER_CHANGE_FIELD_COMMENT } from '../../../../../../constants/forms'
import { WORK_TYPE_BENALTQT } from '../../../../../../constants/workItems'

// components.
import Button from '../../../../../atoms/Button'

// selectors
import { getAltsQuoteBancsPolicyNo } from '../../../../../../selectors/createQuote'

// styles.
import styles from '../requestManualQuote.styles'

import SCHEMA, { FORM_ID, CHANGE_CLIENT_COVER } from './requestAQuote.schema'

type RequestAQuoteProps = {
  fields: Object<Object>,
  error: String,
  actions: Object,
  form: Object,
  client: Object,
  goToRequestManualQuote: Function,
  isAltsQuote: boolean,
  altsQuoteBancsPolicyNo: string,
}

const FormWrapper = styled('div')(styles.formWrapper)
const TextareaWrap = styled(Textarea)(styles.textarea)
const Form = styled('form')()
const SubmitButton = styled(Button)(styles.submitButton)

export class RequestAQuote extends PureComponent<RequestAQuoteProps> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)
    const {
      actions: { formInit },
      fields,
    } = this.props
    const schema = this.schemaWithAuthorableFields(fields)
    const comment = ''
    formInit(FORM_ID, schema, {
      comment,
    })
  }

  componentWillUnmount() {
    const {
      actions: { formResetField },
      form,
    } = this.props

    if (form) {
      formResetField(FORM_ID, COVER_CHANGE_FIELD_COMMENT)
    }
  }

  handleSubmit = () => {
    const {
      fields,
      goToRequestManualQuote,
      isAltsQuote,
      actions: {
        createChangeCoverWorkItem,
        createChangeCoverWorkItemError,
        formUpdateField,
        formValidate,
        disableSidebarClosing,
      },
      client,
      form,
      altsQuoteBancsPolicyNo,
    } = this.props
    const bancsPolicyNo = isAltsQuote
      ? altsQuoteBancsPolicyNo
      : get(client, 'changeCoverDetails.bancsPolicyNo', '')
    const commentValue = get(form, 'fields.comment.value', '')
    const isValid = get(form, 'isValid', false)
    if (!isValid) {
      const schema = this.schemaWithAuthorableFields(fields)
      const data = {
        error: errorCheck('', schema[COVER_CHANGE_FIELD_COMMENT].condition, null),
        value: '',
      }
      formUpdateField(FORM_ID, COVER_CHANGE_FIELD_COMMENT, data)
      formValidate(FORM_ID, schema)
      return
    }

    if (bancsPolicyNo) {
      const comment = isAltsQuote
        ? `${get(fields, 'AltsManualQuotePrefix.value')} ${commentValue}`
        : commentValue
      createChangeCoverWorkItem(
        {
          workTypeCode: WORK_TYPE_BENALTQT,
          bancsPolicyNo,
          comment,
        },
        err => {
          if (err) return createChangeCoverWorkItemError('error')
          disableSidebarClosing()
          return goToRequestManualQuote()
        }
      )
    }
  }

  handleChange = value => {
    const {
      actions: { formUpdateField, formValidate, createChangeCoverWorkItemError },
      fields,
    } = this.props

    // Clearing previous work item error
    createChangeCoverWorkItemError()
    const schema = this.schemaWithAuthorableFields(fields)
    const data = {
      error: errorCheck(value, schema[COVER_CHANGE_FIELD_COMMENT].condition, null),
      value,
    }

    formUpdateField(FORM_ID, COVER_CHANGE_FIELD_COMMENT, data)
    formValidate(FORM_ID, schema)
  }

  render() {
    const { fields, error, form } = this.props
    const { ReqQuotePlcHolderText, QuoteSubmitErrorMessage, SubmitBtn } =
      reduceAuthorableFields(fields)

    if (!form || !form.fields) return null

    const {
      fields: { comment },
    } = form

    return (
      <Fragment>
        <Form id={FORM_ID} aria-labelledby="change-client-cover">
          <FormWrapper>
            <TextareaWrap
              placeholder={ReqQuotePlcHolderText}
              id={CHANGE_CLIENT_COVER}
              name={CHANGE_CLIENT_COVER}
              value={comment.value}
              changeHandler={this.handleChange}
              blurHandler={() => {}}
              caption={
                (comment.error && comment.error.errorMsg) || (error && QuoteSubmitErrorMessage)
              }
              error={(comment.error && comment.error.error) || error}
            />
          </FormWrapper>
          <SubmitButton type="primary" onClick={this.handleSubmit}>
            {SubmitBtn}
          </SubmitButton>
        </Form>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ client, forms, createQuote }) => ({
  error: client.changeCoverDetails && client.changeCoverDetails.error,
  form: forms[FORM_ID],
  client,
  altsQuoteBancsPolicyNo: getAltsQuoteBancsPolicyNo(createQuote),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestAQuote)
