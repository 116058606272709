// @flow

import { STATES } from '../../../../../../constants/forms'
import {
  email,
  optional,
  required,
  phone,
  phoneInputs,
  postcode,
  manualAddressRegex,
  manualAddressPostalCode,
  isEqualAsPrevious,
  mobilePhone,
  regexTest,
  getFieldValueFromForm,
} from '../../../../../../utils/formUtils'
import {
  marketingPreference,
  phoneCodeAustralia,
} from '../../../../../../constants/customerPersonalDetails'

export const FORM_ID: string = 'contactDetailsForm'

type ContactDetailsFormSchemaState = {
  isManualResidential?: boolean,
  isManualPostal?: boolean,
  hidePostal?: boolean,
  advisorEmailAddress: String,
}

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema =
  (): Function =>
  (
    {
      isManualResidential,
      isManualPostal,
      hidePostal,
      advisorEmailAddress,
    }?: ContactDetailsFormSchemaState,
    fields
  ): Object => ({
    phones: {
      condition: [
        {
          condition: required,
          errorMsg: fields && fields.ContactDetailsFormPhonesRequiredError,
        },
        {
          condition: (value, formFields) => {
            const phoneCodeValue = getFieldValueFromForm('phoneCode', { fields: formFields })
            const phoneRegex = phoneCodeValue === phoneCodeAustralia ? mobilePhone : phone
            return !regexTest(phoneRegex, value)
          },
          errorMsg: fields && fields.ContactDetailsFormPhonesError,
        },
      ],
      onChangeCondition: phoneInputs,
      errorMsg: fields && fields.ContactDetailsFormPhonesRequiredError,
    },
    phoneCode: {
      condition: required,
    },
    emails: {
      condition: [
        {
          condition: required,
          errorMsg: fields && fields.ContactDetailsFormEmailsRequiredError,
        },
        { condition: email, errorMsg: fields && fields.ContactDetailsFormEmailsError },
        {
          condition: isEqualAsPrevious(advisorEmailAddress),
          errorMsg: fields.customerEmailNotSameAsAdvisorEmail,
        },
      ],
    },
    marketingPreference: {
      options: marketingPreference,
      condition: optional,
    },
    residentialAddress: {
      condition: isManualResidential || !hidePostal ? optional : required,
      errorMsg: fields && fields.ContactDetailsFormResidentialAddressFieldError,
    },
    residentialAddressHouseNo: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressHouseNoFieldError,
            }
          : optional,
        isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidHouseNoFieldError,
            }
          : {},
      ],
    },
    residentialAddressStreet: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressStreetFieldError,
            }
          : optional,
        isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidStreetFieldError,
            }
          : {},
      ],
    },
    residentialAddressLocality: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressLocalityFieldError,
            }
          : optional,
        isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidLocalityFieldError,
            }
          : {},
      ],
    },
    residentialAddressState: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressStateFieldError,
            }
          : optional,
        isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressInvalidStateFieldError,
            }
          : {},
      ],
      options: STATES,
    },
    residentialAddressCountry: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressCountryFieldError,
            }
          : optional,
      ],
    },
    residentialAddressPostCode: {
      condition: [
        isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressPostCodeFieldError,
            }
          : optional,
        isManualResidential
          ? {
              condition: manualAddressPostalCode,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidPostCodeFieldError,
            }
          : {},
        postcode,
      ],
    },
    showPostalAddressCheckbox: {
      condition: optional,
    },
    postalAddress: {
      condition: isManualPostal || hidePostal ? optional : required,
      errorMsg: fields && fields.PostalAddressFieldMandatoryError,
    },
    postalAddressHouseNo: {
      condition: [
        isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressHouseNoFieldError,
            }
          : optional,
        isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidHouseNoFieldError,
            }
          : {},
      ],
    },
    postalAddressStreet: {
      condition: [
        isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressStreetFieldError,
            }
          : optional,
        isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidStreetFieldError,
            }
          : {},
      ],
    },
    postalAddressLocality: {
      condition: [
        isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressLocalityFieldError,
            }
          : optional,
        isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidLocalityFieldError,
            }
          : {},
      ],
    },
    postalAddressState: {
      condition: [
        isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressStateFieldError,
            }
          : optional,
        isManualPostal
          ? {
              condition: manualAddressRegex,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressInvalidStateFieldError,
            }
          : {},
      ],
      options: STATES,
    },
    postalAddressCountry: {
      condition: [
        isManualPostal
          ? {
              condition: required,
              errorMsg: fields.ContactDetailsFormResidentialAddressCountryFieldError,
            }
          : optional,
      ],
    },
    postalAddressPostCode: {
      condition: [
        isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.ContactDetailsFormResidentialAddressPostCodeFieldError,
            }
          : optional,
        isManualPostal
          ? {
              condition: manualAddressPostalCode,
              errorMsg:
                fields && fields.ContactDetailsFormResidentialAddressInvalidPostCodeFieldError,
            }
          : {},
        postcode,
      ],
    },
    hasAddressChanged: {
      condition: optional,
    },
    hasSecondaryAddressChanged: {
      condition: optional,
    },
  })
export default schema
