/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconFile32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5h5v4h-5V5zm-3-3h11v4h5v23H5V6h5V2zm0 7H8v17h15V9h-2v3H10V9zm0 8h11v-3H10v3zm0 5h8v-3h-8v3z"
      fill="#000"
    />
  </Icon>
)

export default IconFile32
