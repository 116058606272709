// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { A11yLabel, Label } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../actions'

// component.
import Input from '../../../../../atoms/Input'
import Select from '../../../../../atoms/Select'
import Heading from '../../../../../atoms/Heading'

// styles.
import styles from './wrapSuperSmf.styles'
import { ignoreColumns } from '../../../../../../utils/stylesUtils'

// schema.
import SCHEMA, { FORM_ID } from './wrapSuperSmf.schema'
import { FORM_ID as paymentDetails } from '../paymentDetails.schema'

// helpers.
import { errorCheck } from '../../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { isNonSuperProduct } from '../../../../../../utils/quoteUtils'
import { wrapSupeSmfInitData } from './wrapSuperSmfUtil'
import { getPaymentFrequency } from '../../../../../../utils/paymentUtils'

import { WRAP } from '../../../../../../constants/customerPaymentDetails'
import { SUPER_OWNER_IOOF } from '../../../../../../constants/policies'

type wrapSuperSmfProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  // Sitecore authorable fields
  fields: Object,
  policyInstanceNo: string,
  createQuote: Object,
}

const FullWidthInput = ignoreColumns(Input)

const Form = styled('form')(styles.base)
const Wrapper = styled('div')()
const Dropdown = styled(Select)(styles.accountType)
const PaymentMethod = styled(Heading)(styles.paymentMethod)
const SmfDisplayText = styled('p')(styles.smfDisplayText)
const PaymentFrequency = styled('p')(styles.paymentFrequency)

export class WrapSuperSmf extends Component<wrapSuperSmfProps> {
  componentWillMount() {
    const { actions, form } = this.props
    const { formInit, formUpdateField } = actions
    const schema = SCHEMA()
    const wrapSuperSmfData = wrapSupeSmfInitData(this.props)
    if (!form) {
      formInit(FORM_ID, schema, wrapSuperSmfData)
    }
    const data = {
      error: '',
      value: WRAP,
    }

    formUpdateField(paymentDetails, paymentDetails, data)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { actions, fields } = this.props
    const { formUpdateField, formValidate } = actions
    const schema = SCHEMA(fields, value)
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  renderDynamicAccountField() {
    const { fields, form } = this.props
    const { accountNumber } = form.fields

    const { wrapSuperSmsfAccountNumberLabel, wrapSuperSmsfAccountNumberCaption } =
      reduceAuthorableFields(fields)
    return (
      <Fragment>
        <FullWidthInput
          htmlFor="accountNumber"
          name="accountNumber"
          label={wrapSuperSmsfAccountNumberLabel}
          changeHandler={this.handleChange}
          value={accountNumber.value}
          error={accountNumber.value && accountNumber.error.error}
          caption={
            (accountNumber.error.errorMsg && accountNumber.error.errorMsg.value) ||
            wrapSuperSmsfAccountNumberCaption
          }
          captionWithIcon={false}
        />
      </Fragment>
    )
  }

  render() {
    const { fields, form, createQuote, policyInstanceNo } = this.props
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''
    if (!form) return null

    const { paymentDrawDate } = form.fields
    let frequency = ''
    if (activePolicy) frequency = getPaymentFrequency(activePolicy, fields)

    const {
      addIndividualHeaderLabel,
      wrapSuperSmsfPaymentMethod,
      wrapSuperSmsfPaymentMethodDisplayText,
      wrapSuperSmsfDisclaimer,
      wrapSuperSmsfPaymentDrawDateLabel,
      wrapSuperSmsfPaymentDrawDatePlaceholder,
      wrapSuperSmsfPaymentDrawDateError,
      wrapSuperSmsfPaymentTabPaymentMethodLabel,
      InsigniaWrapPaymentMethodDisplayText,
    } = reduceAuthorableFields(fields)

    const schema = SCHEMA(fields)
    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{addIndividualHeaderLabel}</A11yLabel>
          <Form id={FORM_ID}>
            {activePolicy && !isNonSuperProduct(activePolicy.productId) && (
              <Fragment>
                <Label htmlFor="paymentMethod">{wrapSuperSmsfPaymentTabPaymentMethodLabel}</Label>
                <PaymentMethod size="6" id="paymentMethod">
                  {activePolicy.superFundName === SUPER_OWNER_IOOF
                    ? InsigniaWrapPaymentMethodDisplayText
                    : wrapSuperSmsfPaymentMethod}
                </PaymentMethod>
              </Fragment>
            )}
            <SmfDisplayText>{wrapSuperSmsfPaymentMethodDisplayText}</SmfDisplayText>
            {this.renderDynamicAccountField()}
            <SmfDisplayText>{wrapSuperSmsfDisclaimer}</SmfDisplayText>
            <Dropdown
              label={wrapSuperSmsfPaymentDrawDateLabel}
              placeholder={wrapSuperSmsfPaymentDrawDatePlaceholder}
              value={paymentDrawDate.value}
              name="paymentDrawDate"
              id="paymentDrawDate"
              error={paymentDrawDate.error.error}
              caption={paymentDrawDate.error.error && wrapSuperSmsfPaymentDrawDateError}
              changeHandler={this.handleChange}
              options={schema.paymentDrawDate.options}
            />
            <PaymentFrequency>{frequency}</PaymentFrequency>
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms }) => ({
  form: forms[FORM_ID],
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(WrapSuperSmf)
