import { colours, fontSize, inputPadding, space, fontWeights } from '../../../../styles'

const styles = {
  wrapper: {
    position: 'relative',
  },
  menuBtnWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  menuBtn: ({ theme, error }) => ({
    flex: 1,
    paddingLeft: inputPadding.sm.horizontal,
    paddingRight: space(2) + inputPadding.sm.horizontal * 3,
    paddingTop: space(1),
    paddingBottom: space(1),
    color: error ? colours.red : theme.text,
    fontSize: fontSize.xs,
    position: 'relative',
    overflow: 'hidden',
    height: '48px',
    background: colours.white,
    borderColor: error ? colours.red : colours.darkestGrey,
    '-webkit-appearance': 'none',
    width: '100%',
    textAlign: 'left',
    '&:focus': {
      borderColor: theme.active.border,
      outline: 0,
    },
    '&:hover': {
      borderColor: theme.hover.border,
    },
  }),
  dropdownIcon: props => ({
    position: 'absolute',
    top: '9px',
    right: '15px',
    svg: {
      color: props.error ? colours.red : colours.black,
    },
  }),
  list: {
    li: {
      listStyle: 'none',
      padding: `${space(1, true)} ${space(1.5, true)}`,
      '&:nth-child(odd)': {
        background: colours.offWhite,
      },
      '&:hover': {
        background: colours.lightestGrey,
        cursor: 'pointer',
      },
    },
  },
  menu: (config = {}) => ({
    width: '512px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: `1px solid ${colours.lightestGrey}`,
    section: {
      '&:last-child': {
        borderBottom: 0,
      },
    },
    position: 'absolute',
    top: space(6, true),
    left: 0,
    zIndex: 1,
    background: colours.white,
    ...(config.isMenuOpen ? { display: 'flex' } : { display: 'none' }),
  }),
  listSection: (config = {}) => ({
    width: config.width || '100%',
    ...(config.rightBorder ? { borderRight: `1px solid ${colours.lightestGrey}` } : {}),
    borderBottom: `1px solid ${colours.lightestGrey}`,
  }),
  listHeading: {
    padding: `${space(1, true)} ${space(1.5, true)}`,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
    lineHeight: space(3, true),
    color: colours.darkGrey,
    marginBottom: 0,
  },
}

export default styles
