// @flow
import React, { useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, Modal } from '@mlcl-digital/mlcl-design'

// TODO: Use Design system components instead
import Heading from '../../atoms/Heading'

// styles
import styles from './SeriesCodeChangedAlert.styles'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import history from '../../../utils/browserHistory'

// actions
import { actionCreators } from '../../../actions'

type SeriesCodeChangedAlertProps = {
  isOpen: Boolean,
  actions: Array,
  fields: Object,
  /** Flag to indicate whether Redirection to Quote Tool should happen */
  shouldRedirect?: Boolean,
  /** Calculate Quote API state from Redux */
  isRecalculating?: Boolean,
}

const ModalBody = styled(Heading)(styles.body)
const SeriesChangeModal = styled(Modal)(styles.seriesChangeModal)

export const SeriesCodeChangedAlert = ({
  isOpen,
  actions,
  fields,
  shouldRedirect,
  isRecalculating,
}: SeriesCodeChangedAlertProps) => {
  const [isModalOpen, toggleModal] = useState(isOpen)
  const [isModalCTAInvoked, setIsModalCTAInvoked] = useState(false)

  const { seriesCodeAlertTitle, seriesCodeAlertContent, seriesCodeAlertConfirmButton } = fields

  useEffect(() => {
    toggleModal(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (isModalCTAInvoked && !isRecalculating && shouldRedirect) {
      // Make sure the Calculate Quote request is complete before Redirection and Modal closure
      history.push('/quote-tool')
      toggleModal(false)
    }
  }, [isRecalculating])

  const handleModalClose = useCallback(() => {
    const { createQuoteCalculate } = actions
    setIsModalCTAInvoked(true)

    createQuoteCalculate()
    !shouldRedirect && toggleModal(false)
  }, [isModalOpen])

  return (
    <SeriesChangeModal
      isOpen={isModalOpen}
      onClose={handleModalClose}
      title={renderTextField(seriesCodeAlertTitle)}
      footer={
        <Button onClick={handleModalClose} isLoading={isRecalculating}>
          {renderTextField(seriesCodeAlertConfirmButton)}
        </Button>
      }
    >
      <ModalBody size="6">{seriesCodeAlertContent?.value}</ModalBody>
    </SeriesChangeModal>
  )
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ createQuote: { isRecalculating } }) => ({
  isRecalculating,
})

SeriesCodeChangedAlert.defaultProps = {
  shouldRedirect: false,
  isRecalculating: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesCodeChangedAlert)
