import get from 'lodash/get'
import moment from 'moment'

import { ALLOWED_DATE_FORMAT, CLIENT_SIDE_DATE_FORMAT } from '../../../../../utils/quoteUtils'
import { getFullAddressWithState } from '../../../../../utils/contactUtils'
import { PREFERRED_YES, COUNTRY_CODE } from '../../../../../constants/policies'

export const getDataFromForm = (field, defaultValue, form) =>
  get(form, `fields[${field}].value`, defaultValue)

export const initForms = props => {
  if (!props.businessForm) return {}
  const {
    businessForm: {
      fields: {
        directors,
        residentialAddress,
        residentialCountry,
        residentialHouseNo,
        residentialLocality,
        residentialPostCode,
        residentialState,
        residentialStreet,
      },
    },
    sidebarPanelProps: { directorIdentifier },
  } = props
  const selectedDirector =
    directors.value &&
    directorIdentifier &&
    directors.value.find(director =>
      director.relatedParty.identifiers.some(
        identifier => identifier.value === directorIdentifier.value
      )
    )
  if (!selectedDirector) return {}
  const { relatedParty } = selectedDirector

  const residentialAddressIndex = relatedParty.contactMethods.addresses.findIndex(
    address => address.preferred === PREFERRED_YES
  )
  const primaryEmailIndex = relatedParty.contactMethods.emails.findIndex(
    email => email.preferred === PREFERRED_YES
  )

  const email =
    primaryEmailIndex !== -1 ? relatedParty.contactMethods.emails[primaryEmailIndex].email : {}
  const address =
    residentialAddressIndex !== -1
      ? relatedParty.contactMethods.addresses[residentialAddressIndex]
      : {}
  const fullAddress = getFullAddressWithState(relatedParty.contactMethods.addresses).trim()
  const data = {
    title: relatedParty.title,
    firstName: relatedParty.firstName,
    middleName: relatedParty.middleName,
    lastName: relatedParty.lastName,
    dateOfBirth: moment(relatedParty.dateOfBirth, ALLOWED_DATE_FORMAT, true).format(
      CLIENT_SIDE_DATE_FORMAT
    ),
    email,
    companyRole: relatedParty.companyRole,
    addressStreet: address.street,
    addressUnit: address.houseNo,
    addressLocality: address.locality,
    addressState: address.state,
    addressCountry: address.country || COUNTRY_CODE,
    addressPostCode: address.postCode,
    address: fullAddress,
    identifiers: relatedParty.identifiers[0],
    sameAsCompanyAddress:
      (residentialAddress.value === fullAddress &&
        residentialCountry.value === address.country &&
        residentialHouseNo.value === address.houseNo &&
        residentialLocality.value === address.locality &&
        residentialPostCode.value === address.postCode &&
        residentialState.value === address.state &&
        residentialStreet.value === address.street) ||
      relatedParty.sameAsCompanyAddress,
  }

  return data
}
