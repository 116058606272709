/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronDown32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 19.379l8.94-8.94 2.12 2.122L16.5 23.62 5.44 12.561l2.12-2.122 8.94 8.94z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronDown32
