/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowLeft16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M2 8l-.707-.707L.586 8l.707.707L2 8zm5.707 4.293l-5-5-1.414 1.414 5 5 1.414-1.414zm-5-3.586l5-5-1.414-1.414-5 5 1.414 1.414zM15 7H2v2h13V7z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowLeft16
