// @flow
import get from 'lodash/get'

// constants
import {
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_PARTY_TYPE_BUSINESS,
} from '../constants/policies'

import { BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'

import { getPrimaryLifeInsured } from './policyUtils'

export const fetchDirectorSecretaryDetails = clientDetails => {
  const role = get(clientDetails, 'roleCode', '')
  const isSMSF = role === POLICY_RELATIONSHIPS_SMSF
  let dirOrTrustees = get(clientDetails, 'level1', [])
  if (isSMSF && dirOrTrustees.length && dirOrTrustees[0].partyType === POLICY_PARTY_TYPE_BUSINESS) {
    dirOrTrustees = get(clientDetails, 'level1[0].level2', [])
  }

  return dirOrTrustees
}

export const fetchClientDirectorSecretaryDetails = clientDetails => {
  const isSMSF = Boolean(get(clientDetails, 'level1[0].level2', false))
  let dirOrTrustees = get(clientDetails, 'level1', [])
  if (
    isSMSF &&
    dirOrTrustees.length &&
    dirOrTrustees[0].relatedParty.partyType === POLICY_PARTY_TYPE_BUSINESS
  ) {
    dirOrTrustees = get(clientDetails, 'level1[0].level2', [])
  }
  return dirOrTrustees
}

export const getClientRelationshipDetails = (customerPolicies, clientId, isOverview = false) => {
  const currentPolicy =
    customerPolicies.find(
      policy =>
        policy.policy.relationships.find(
          relationship => relationship.bancsCustomerNo === clientId
        ) || []
    ) || []
  const relationships = get(currentPolicy, 'policy.relationships', [])
  let clientDetails = relationships.find(relationship =>
    [BANCS_CUSTOMER_NUMBER_IOOF, BANCS_CUSTOMER_NUMBER_NULIS].includes(relationship.bancsCustomerNo)
  )
  // FIXME: Refactored for MLA change, will need to edit this function to allow for MLA.
  if (clientDetails) return get(getPrimaryLifeInsured(relationships), '[0]', {})

  let roleCodeOrder = [POLICY_RELATIONSHIPS_OWNER, POLICY_RELATIONSHIPS_SMSF]
  if (isOverview) {
    // On overview section in policy details, IET role gets preference over OWR
    roleCodeOrder = [POLICY_RELATIONSHIPS_SMSF, POLICY_RELATIONSHIPS_OWNER]
  }
  roleCodeOrder.some(roleCode => {
    clientDetails = relationships.find(relationship => relationship.roleCode === roleCode)
    return clientDetails
  })
  if (clientDetails) return clientDetails
  return false
}

export const checkExistingBusiness = (client: Object) => !!(client && client.details)
