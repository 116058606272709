// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../actions'

// styles.
import styles from './tfn.styles'
import { ignoreColumns } from '../../../../utils/stylesUtils'

// components.
import Input from '../../../atoms/Input'

// helpers
import { reduceAuthorableFields } from '../../../../utils/sitecoreUtils'
import { errorCheck } from '../../../../utils/formUtils'

// schema.
import SCHEMA, { FORM_ID } from '../AddNulis.schema'

const FullWidthInput = ignoreColumns(Input)

const Form = styled('form')(styles.base)

const Wrapper = styled('div')(styles.wrapper)

type AddNulisProps = {
  // To render all labels from sitecore content editor
  fields: Object,
  // list of actions
  actions: Object<Function>,
  // form sub-store
  form: Object,
  isManualResidential: Object,
  nulis: Object,
}

export class AddNulis extends Component<AddNulisProps> {
  schemaWithAuthorableFields = SCHEMA()

  componentWillMount() {
    const { actions } = this.props
    const { hideSidebarFooter } = actions

    hideSidebarFooter()
  }

  handleChange = ({ value, name }) => {
    const {
      actions,
      fields,

      isManualResidential,
      nulis: { showNulisExtraFields },
    } = this.props
    const { formUpdateField, formValidate } = actions

    const schema = this.schemaWithAuthorableFields(
      {
        isManualResidential,
        showNulisExtraFields,
      },
      fields
    )
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  render() {
    const { fields, form } = this.props

    if (!form) return null

    const { tfn } = form.fields

    const { nulisTaxFileNumber } = reduceAuthorableFields(fields)

    return (
      <Fragment>
        <Wrapper>
          <Form id={FORM_ID} aria-labelledby="nulis-label">
            <FullWidthInput
              htmlFor="tfn"
              name="tfn"
              tab="-1"
              label={nulisTaxFileNumber}
              changeHandler={this.handleChange}
              placeholder={nulisTaxFileNumber}
              error={tfn.error.error}
              caption={tfn.error.error && tfn.error.errorMsg}
              value={tfn.value}
            />
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ nulis, forms, addressLookup, abnLookup, sidebar }) => ({
  nulis,
  form: forms[FORM_ID],
  isManualResidential:
    addressLookup.policyOwnerResidentialAddress &&
    addressLookup.policyOwnerResidentialAddress.isManual,
  abnLookup,
  sidebar,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNulis)
