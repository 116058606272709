// @flow
import { space } from '../../../../../styles'

const styles = {
  wrapper: {
    padding: space(3),
  },
  fieldset: {
    padding: 0,
    display: 'flex',
    width: '100%',
    border: 'none',
    position: 'relative',
    margin: 0,
    justifyContent: 'space-between',
  },
  fullWidth: {
    flex: '0 0 100%',
  },
  sectionXsm: {
    width: '50%',
  },
}

export default styles
