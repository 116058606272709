/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconEmail16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10.152L2 6.723V11a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6.723l-6 3.429zm5.864-5.655A1 1 0 0 0 13 4H3a1 1 0 0 0-.865.497L8 7.848l5.864-3.35zM3 2h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3z"
      fill="#000"
    />
  </Icon>
)

export default IconEmail16
