/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowUp16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M8 2l.707-.707L8 .586l-.707.707L8 2zM3.707 7.707l5-5-1.414-1.414-5 5 1.414 1.414zm3.586-5l5 5 1.414-1.414-5-5-1.414 1.414zM9 15V2H7v13h2z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowUp16
