// @flow
import React from 'react'

// components.
import Modal from '../../molecules/Modal'
import LookUp from './components/LookUp'

type OccupationProps = {
  // To render all labels from sitecore content editor
  fields: {},
  // boolean to show modal
  openOccupation: boolean,
  // function to open or close occupation
  onClose: Function,
  // function to store occupation in createQuote reducer
  updateMandatoryDetails: Function,
  // function to validate form and update value
  handleChange: Function,
  occupationFieldName?: String,
  isSecondaryOccupation: boolean,
}

const OccupationRoot = (props: OccupationProps) => {
  const {
    fields,
    openOccupation,
    onClose,
    updateMandatoryDetails,
    handleChange,
    occupationFieldName,
    isSecondaryOccupation,
  } = props
  const { captureClientGeneralOccupation, captureClientGeneralOccupationSelect } = fields
  return (
    <Modal
      title={captureClientGeneralOccupation}
      isOpen={openOccupation}
      onClose={onClose}
      showCloseButton
      shouldFocusCloseButton
    >
      <p>{captureClientGeneralOccupationSelect}</p>
      <LookUp
        fields={fields}
        onClose={onClose}
        updateMandatoryDetails={updateMandatoryDetails}
        handleChange={handleChange}
        occupationFieldName={occupationFieldName}
        isSecondaryOccupation={isSecondaryOccupation || false}
      />
    </Modal>
  )
}
OccupationRoot.defaultProps = {
  occupationFieldName: 'occupation',
}

export default OccupationRoot
