/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
// @flow
import React, { Component, Fragment } from 'react'
import get from 'lodash/get'
import { SidebarActionItem, Disclaimer as DisclaimerAtom } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import partition from 'lodash/partition'
import sortBy from 'lodash/sortBy'
import 'core-js/es7/array'
// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components.
import Button from '../../atoms/Button'
import Caption from '../../atoms/Caption'
import Beneficiary from './components/Beneficiary'

// schema.
import SCHEMA, { FORM_ID } from './applicationBeneficiaries.schema'
import beneficiaryDetailsSchema, {
  FORM_ID as beneficiaryDetailsId,
} from '../BeneficiaryDetails/beneficiaryDetails.schema'

// utils
import { errorCheck, percentage } from '../../../utils/formUtils'
import {
  getPolicyOwner,
  getLifeAsssured,
  getPolicyByInstanceNo,
  CLIENT_SIDE_DATE_FORMAT,
} from '../../../utils/quoteUtils'
import { getPreferredAddress, makeName, getIsMemberHasRole } from '../../../utils/contactUtils'
import { deepEqual } from '../../../utils/commonUtils'
import { renderTextField } from '../../../utils/sitecoreUtils'

// constants.
import {
  BENEFICIARY_TYPE_NON_BINDING_CODE,
  BENEFICIARY_TYPE_BINDING_CODE,
  BENEFICIARY_TYPE_NON_BINDING,
} from '../../../constants/forms'

import {
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_PAYER,
  POLICY_BENEFICIARY_ACTION_REMOVE,
  POLICY_BENEFICIARY_ACTION_ADD,
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../../../constants/policies'

import { BENEFICIARY_PERCENTAGE_KEY } from '../../../constants/beneficiary'

// styles
import styles from './applicationBeneficiaries.styles'

type ApplicationBeneficiariesProps = {
  // To render all labels from sitecore content editor
  fields: {},
  // Quote data
  createQuote: Object<Object>,
  // Policy InstanceNo.
  policyInstanceNo: number,
  // Redux actions available to the component.
  actions: {
    formInit: Function,
    formUpdateField: Function,
    formValidate: Function,
    formSetValid: Function,
    formRemoveFields: Function,
    formSubmit: Function,
    formReset: Function,
    formAddField: Function,
    gotoPanel: Function,
    hideSidebarFooter: Function,
    showSidebarFooter: Function,
  },
  // Redux form data of the component.
  form: Object,
}

const Disclaimer = styled(DisclaimerAtom)(styles.disclaimer)
const Wrapper = styled('div')(styles.base)
const Header = styled('div')(styles.header)
const SplitButton = styled(Button)(styles.button)
const Total = styled('strong')(styles.total)
const ErrorMsg = styled('div')(styles.error)
const BeneficiaryType = styled('div')(styles.beneficiaryType)
const BeneficiaryTypeLabel = styled('div')(styles.beneficiaryTypeLabel)
const BeneficiaryTypeText = styled('div')(styles.beneficiaryTypeText)
export const NonBindingLBDisclaimer = styled('div')(styles.nonBindingDisclaimer)

const createBeneficiary = (
  { title, firstName, lastName, dateOfBirth, contactMethods, identifiers },
  addressSameAsLifeInsured = false,
  relationship = '',
  action = null
) => ({
  ...(action ? { action } : null),
  title,
  firstName,
  lastName,
  dateOfBirth,
  addressSameAsLifeInsured,
  relationship,
  identifiers,
  ...getPreferredAddress(contactMethods.addresses),
})

const getTotalBeneficiaryPercentage = beneficiaryFields =>
  Object.keys(beneficiaryFields).reduce((acc, key) => {
    const beneficiaryField = beneficiaryFields[key]
    const { action } = beneficiaryField.data
    return action !== POLICY_BENEFICIARY_ACTION_REMOVE ? acc + Number(beneficiaryField.value) : acc
  }, 0)

// Check if beneficiary is life insured
const isLifeInsured = (beneficiaryField, lifeInsuredObj) => {
  // We remove action and addressSameAsLifeInsured keys before we deep equal check.
  // strip form usage fields before comparison
  const {
    action,
    addressSameAsLifeInsured,
    relationship,
    address,
    policyInstanceNo,
    type,
    preferred,
    middleName,
    beneficiaryIndex,
    ...field
  } = beneficiaryField.data
  const {
    action: beneficiaryObjectAction,
    addressSameAsLifeInsured: beneficiaryObjectAddressSameAsLifeInsured,
    relationship: beneficiaryObjectRelationship,
    address: beneficiaryObjectAddress,
    policyInstanceNo: beneficiaryObjectPolicyInstanceNo,
    type: beneficiaryObjectType,
    beneficiaryIndex: beneficiaryObjectBeneficiaryIndex,
    preferred: beneficiaryObjPreferred,
    middleName: beneficiaryObjMiddleName,
    dateOfBirth,
    ...lifeInsuredRest
  } = lifeInsuredObj

  // TODO: Remove this date formatting once someone unifies date formats.
  const lifeInsured = {
    ...lifeInsuredRest,
    dateOfBirth,
  }

  return action !== POLICY_BENEFICIARY_ACTION_REMOVE ? isEqual(lifeInsured, field) : false
}

const createBeneficiaryFields = (beneficiaries, lifeInsured) => {
  let lifeInsuredAddress

  if (lifeInsured) {
    const { contactMethods: lifeInsuredContactMethods } = lifeInsured && lifeInsured.relatedParty
    lifeInsuredAddress = { ...getPreferredAddress(lifeInsuredContactMethods.addresses) }
  }

  return beneficiaries && beneficiaries.length
    ? beneficiaries
        .map(beneficiary => {
          const { relatedParty } = beneficiary
          if (!relatedParty || !relatedParty.contactMethods) return []
          const {
            contactMethods: { addresses },
          } = relatedParty
          // Strip out non-related address attributes to compare with life insured address
          const {
            address,
            beneficiaryIndex,
            policyInstanceNo,
            title,
            type,
            ...beneficiaryAddress
          } = getPreferredAddress(addresses)

          const data = createBeneficiary(
            relatedParty,
            lifeInsuredAddress ? isEqual(beneficiaryAddress, lifeInsuredAddress) : false,
            beneficiary.relationship,
            beneficiary.action
          )

          return {
            value: beneficiary.percentageAllocation,
            error: false,
            data,
          }
        })
        .reduce((acc, beneficiary) => {
          const obj = acc
          const key = `${String(Date.now() * (Math.random() / 0.1))}BeneficiaryPercentage`
          obj[key] = beneficiary
          return obj
        }, {})
    : []
}

export class ApplicationBeneficiaries extends Component<ApplicationBeneficiariesProps> {
  componentWillMount() {
    const {
      policyInstanceNo,
      actions: { formInit, showSidebarFooter, hideSidebarFooter },
      createQuote,
    } = this.props
    const { activeIndex, quotes } = createQuote

    const { policyStructure } = quotes[activeIndex]
    const policy =
      policyInstanceNo && policyStructure
        ? getPolicyByInstanceNo(policyStructure, policyInstanceNo)
        : null
    const lifeInsured = getLifeAsssured(createQuote)
    const beneficiaryFields = policy
      ? createBeneficiaryFields(policy.beneficiaries, lifeInsured)
      : null
    const beneficiaryType = policy
      ? this.getBenefitType(policy, policy.productId === POLICY_PRODUCT_CODE_SUPER)
      : null

    formInit(FORM_ID, SCHEMA, beneficiaryType && { beneficiaryType }, beneficiaryFields)

    const hasBeneficiaries =
      beneficiaryFields &&
      Object.keys(beneficiaryFields).filter(
        key => beneficiaryFields[key].data.action !== POLICY_BENEFICIARY_ACTION_REMOVE
      ).length

    if (hasBeneficiaries) {
      hideSidebarFooter()
    } else showSidebarFooter()
    const tagEvent = createEvent({
      GA: {
        category: 'Beneficiaries',
        action: 'View',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Beneficiaries - View',
        },
      },
    })
    tagEvent.end()
  }

  componentDidUpdate(prevProps) {
    const {
      policyInstanceNo,
      actions: { formInit, hideSidebarFooter, showSidebarFooter },
      createQuote,
    } = this.props
    const { activeIndex, quotes } = createQuote

    const { policyStructure } = quotes[activeIndex]
    const policy = policyStructure ? getPolicyByInstanceNo(policyStructure, policyInstanceNo) : null
    const lifeInsured = getLifeAsssured(createQuote)
    const beneficiaryFields = policy
      ? createBeneficiaryFields(policy.beneficiaries, lifeInsured)
      : null

    const beneficiaryType = policy
      ? this.getBenefitType(policy, policy.productId === POLICY_PRODUCT_CODE_SUPER)
      : null

    if (prevProps.policyInstanceNo !== policyInstanceNo) {
      formInit(FORM_ID, SCHEMA, beneficiaryType && { beneficiaryType }, beneficiaryFields)
      const hasBeneficiaries =
        beneficiaryFields &&
        Object.keys(beneficiaryFields).filter(
          key => beneficiaryFields[key].data.action !== POLICY_BENEFICIARY_ACTION_REMOVE
        ).length
      if (hasBeneficiaries) hideSidebarFooter()
      else showSidebarFooter()
    }
  }

  getBenefitType = (policy: {
    beneficiaries: Array<{
      type: string,
    }>,
    superFundName: string,
    fundPaymentMethod: string,
  }): any => {
    if (typeof policy === 'undefined') {
      return false
    }

    const bindingType =
      policy && policy.beneficiaries && policy.beneficiaries.length && policy.beneficiaries[0].type

    let beneficiaryType

    if (bindingType) {
      // Selected and saved option
      beneficiaryType = bindingType
    } else {
      // default to non-binding
      beneficiaryType = BENEFICIARY_TYPE_NON_BINDING_CODE
    }

    return beneficiaryType
  }

  // Updates beneficiary fields with a split percentage
  splitPercentage = () => {
    const {
      actions: { formUpdateField, formValidate, formSetValid },
      form: {
        fields: { beneficiaryType, ...beneficiaryFields },
      },
    } = this.props
    const amount = Object.keys(beneficiaryFields).length
    const value = 100 / amount
    const data = {
      error: errorCheck(value, percentage),
      value,
    }

    Object.keys(beneficiaryFields).forEach(name => {
      formUpdateField(FORM_ID, name, data)
      formValidate(FORM_ID, SCHEMA)
    })

    if (getTotalBeneficiaryPercentage(beneficiaryFields) !== 100) formSetValid(FORM_ID, false)
  }

  // handle changes on form elements.
  handleChangeOfBeneficiaryPercentage = ({ name, value }): void => {
    const {
      form: {
        fields: { ...beneficiaryFields },
      },
    } = this.props
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const data = {
      error: errorCheck(value, beneficiaryFields[name].condition),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  addBeneficiaryDetails = () => {
    const {
      policyInstanceNo,
      actions: { gotoPanel, formReset },
      fields,
    } = this.props
    formReset(beneficiaryDetailsId, beneficiaryDetailsSchema(fields, false, false))
    gotoPanel(1, { policyInstanceNo })
    const tagEvent = createEvent({
      GA: {
        category: 'Beneficiaries',
        action: 'Add',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Beneficiaries - Add',
        },
      },
    })
    tagEvent.end()
  }

  editAddBeneficiaryDetails = (data, formKey) => {
    const {
      policyInstanceNo,
      actions: { gotoPanel },
    } = this.props
    gotoPanel(1, { data, formKey, policyInstanceNo })
    const tagEvent = createEvent({
      GA: {
        category: 'Beneficiaries',
        action: 'Edit',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Beneficiaries - Edit',
        },
      },
    })
    tagEvent.end()
  }

  addBeneficiary = (data): void => {
    const {
      actions: { formAddField, hideSidebarFooter },
    } = this.props
    const key = `${String(Date.now())}${BENEFICIARY_PERCENTAGE_KEY}`
    const formData = {
      ...createBeneficiary(data),
      dateOfBirth: data.dateOfBirth,
      action: POLICY_BENEFICIARY_ACTION_ADD,
    }

    formAddField(FORM_ID, key, {
      value: '',
      data: formData,
    })
    hideSidebarFooter()
  }

  /**
   * set Additional beneficiary field in the store
   * @param {object} data - fields data
   */
  setAdditionalBeneficiaryField = (data: Object) => {
    const {
      actions: { hideSidebarFooter, gotoPanel, formInit },
      policyInstanceNo,
      fields,
    } = this.props

    const formData = {
      ...createBeneficiary(data),
      dateOfBirth: moment(data.dateOfBirth).format(CLIENT_SIDE_DATE_FORMAT),
      action: POLICY_BENEFICIARY_ACTION_ADD,
    }

    gotoPanel(1, { policyInstanceNo })
    formInit(beneficiaryDetailsId, beneficiaryDetailsSchema(fields, false, false), formData)
    hideSidebarFooter()
  }

  removeBeneficiary = fieldName => {
    const {
      form: { fields },
      actions: {
        formSetValid,
        formRemoveFields,
        formUpdateField,
        hideSidebarFooter,
        showSidebarFooter,
      },
      policyInstanceNo,
      createQuote: { activeIndex, quotes },
    } = this.props

    const { policyStructure } = quotes[activeIndex]
    const policy = policyStructure ? getPolicyByInstanceNo(policyStructure, policyInstanceNo) : null
    const beneficiaryFields =
      policy && policy.beneficiaries ? createBeneficiaryFields(policy.beneficiaries) : null

    if (fields[fieldName].data.action === POLICY_BENEFICIARY_ACTION_ADD) {
      formRemoveFields(FORM_ID, fieldName)
    } else {
      formUpdateField(FORM_ID, fieldName, {
        data: {
          ...fields[fieldName].data,
          action: POLICY_BENEFICIARY_ACTION_REMOVE,
        },
      })
    }
    const { beneficiaryType, ...beneficiaries } = fields

    if (
      Object.keys(beneficiaries).length - 1 > 0 ||
      Object.keys(beneficiaries).filter(
        key =>
          beneficiaries[key].data &&
          beneficiaries[key].data.action !== POLICY_BENEFICIARY_ACTION_REMOVE
      ).length > 0
    ) {
      const filteredUnRemoved =
        beneficiaryFields &&
        Object.values(beneficiaryFields).filter(
          obj => obj && obj.data !== POLICY_BENEFICIARY_ACTION_REMOVE
        )
      // persist sidebar footer visibility unless it has been removed from quote
      if (filteredUnRemoved && filteredUnRemoved.length === 0) {
        showSidebarFooter()
      } else {
        hideSidebarFooter()
      }
      // If no beneficiaries left we stop validation blocking as we
      // can save as having no beneficiaries added.
      formSetValid(FORM_ID, true)
    } else showSidebarFooter()
    const tagEvent = createEvent({
      GA: {
        category: 'Beneficiaries',
        action: 'Remove',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Beneficiaries - Remove',
        },
      },
    })
    tagEvent.end()
  }

  renderBenefeciary = (
    { data, value, error: { error, errorMsg } }: Object,
    key: string,
    suffix: string = '',
    showEdit: boolean = true
  ) => {
    const { firstName, lastName } = data
    return (
      <Beneficiary
        key={key}
        name={key}
        value={value}
        error={error}
        errorMessage={error && errorMsg}
        label="Benefit Percentage"
        title={`${firstName} ${lastName} ${suffix}`}
        editHandler={showEdit ? () => this.editAddBeneficiaryDetails(data, key) : null}
        deleteHandler={() => {
          this.removeBeneficiary(key)
        }}
        changeHandler={this.handleChangeOfBeneficiaryPercentage}
      />
    )
  }

  getSideBarActionTitle = relationship => {
    const { fields } = this.props
    let title = ''
    if (getIsMemberHasRole(relationship.role, POLICY_RELATIONSHIPS_LIFEASSURED)) {
      title = fields.addBeneficiaryLifeInsuredButtonText.value
    } else if (getIsMemberHasRole(relationship.role, POLICY_RELATIONSHIPS_OWNER)) {
      title = get(fields, 'addBeneficiaryOwnerButtonText.value', '(Owner)')
    } else if (getIsMemberHasRole(relationship.role, POLICY_RELATIONSHIPS_PAYER)) {
      title = get(fields, 'addBeneficiaryPayerButtonText.value', '(Payer)')
    }
    return `Add ${makeName(relationship.relatedParty)} ${title}`
  }

  render() {
    const {
      form,
      fields,
      policyInstanceNo,
      createQuote,
      createQuote: { quotes, activeIndex },
    } = this.props
    if (!form) return null
    const {
      fields: { beneficiaryType, ...formFields },
    } = form

    // Beneficiaries that they are not marked for removal.
    const beneficiaryFields = Object.keys(formFields)
      .filter(key => formFields[key].data.action !== POLICY_BENEFICIARY_ACTION_REMOVE)
      .reduce((acc, key) => {
        acc[key] = formFields[key]
        return acc
      }, {})

    const hasBeneficiaries = Object.keys(beneficiaryFields).length
    const { policyStructure } = quotes[activeIndex]
    // Get policy
    const policy = policyStructure ? getPolicyByInstanceNo(policyStructure, policyInstanceNo) : null
    // Get Life insured
    const policyLifeInsured = getLifeAsssured(createQuote)
    // Create beneficiary data from life insured
    const lifeInsured =
      policyLifeInsured && policyLifeInsured.relatedParty
        ? createBeneficiary(policyLifeInsured.relatedParty, true)
        : null
    // Get Payer
    const payerDetails = policy ? getPolicyOwner(policy, POLICY_RELATIONSHIPS_PAYER) : null
    // Create beneficiary data from payer
    const payer =
      payerDetails && payerDetails.relatedParty
        ? createBeneficiary(
            payerDetails.relatedParty,
            payerIsLifeInsured,
            payerDetails.relatedParty.relationship
          )
        : null
    // Check if payer is same as life insured
    // eslint-disable-next-line no-use-before-define
    const payerIsLifeInsured = payer
      ? deepEqual(payer.relatedParty, policyLifeInsured.relatedParty)
      : false

    // Amount of beneficiaries
    const amount = Object.keys(beneficiaryFields).length

    // Total of beneficiarye percentage values
    const totalBeneficiaryPercentage = getTotalBeneficiaryPercentage(beneficiaryFields)

    const getOrderedBeneficiariesBy = ({ shardFunction, sortKey }) =>
      partition(
        Object.keys(beneficiaryFields).map(key => ({
          ...beneficiaryFields[key],
          key,
          [sortKey]: beneficiaryFields[key].data && beneficiaryFields[key].data[sortKey],
          isLifeInsured: lifeInsured && isLifeInsured(beneficiaryFields[key], lifeInsured),
        })),
        shardFunction
      )
        .flatMap(beneficiaries => sortBy(beneficiaries, sortKey))
        .map(({ [sortKey]: ignore, ...rest }) => rest)
    const shouldShowNonBindingDisclaimer = policy && policy.productId === POLICY_PRODUCT_CODE_SUPER
    return (
      <Wrapper>
        {hasBeneficiaries ? (
          <Fragment>
            <Header>
              <Total>
                100% <span>total</span>
              </Total>
              <SplitButton type="tertiary" disabled={!(amount % 3)} onClick={this.splitPercentage}>
                {fields.addBeneficiaryBenefitMakeEqualPercentageAmountLabel.value}
              </SplitButton>
            </Header>

            <form id="addBenificiary">
              <BeneficiaryType>
                <BeneficiaryTypeLabel data-test-id="beneficiaryTypeLabel">
                  {fields.beneficiaryTypeLabel.value}
                </BeneficiaryTypeLabel>
                <BeneficiaryTypeText data-test-id="beneficiaryTypeText">
                  {BENEFICIARY_TYPE_NON_BINDING}
                </BeneficiaryTypeText>
                {shouldShowNonBindingDisclaimer && (
                  <NonBindingLBDisclaimer>
                    {renderTextField(fields.NonBindingBenfDesc, true)}
                  </NonBindingLBDisclaimer>
                )}
              </BeneficiaryType>
              {getOrderedBeneficiariesBy({
                shardFunction: beneficiary => beneficiary.isLifeInsured === true,
                sortKey: 'firstName',
              }).map(({ data, value, error, key, isLifeInsured: isLifeAssuredCheck }) => {
                const suffix = isLifeAssuredCheck ? '(Life Insured)' : ''
                return this.renderBenefeciary(
                  { data, value, error },
                  key,
                  suffix,
                  !isLifeAssuredCheck
                )
              })}
            </form>
          </Fragment>
        ) : null}
        {amount < 6 ? (
          <Fragment>
            {policy.relationships &&
              !!policy.relationships.length &&
              policy.relationships
                .filter(
                  relationship =>
                    relationship.relatedParty &&
                    relationship.relatedParty.partyType === POLICY_PARTY_TYPE_INDIVIDUAL
                )
                .map(
                  relationship =>
                    (!relationship.companies || !relationship.companies.length) &&
                    !(
                      policy.beneficiaries &&
                      policy.beneficiaries.length &&
                      policy.beneficiaries.some(beneficiary => {
                        const relationshipIdentifier = get(
                          relationship,
                          'relatedParty.identifiers[0].value'
                        )
                        return (
                          relationshipIdentifier &&
                          get(beneficiary, 'relatedParty.identifiers[0].value') ===
                            relationshipIdentifier
                        )
                      })
                    ) &&
                    (!hasBeneficiaries ||
                      !Object.keys(beneficiaryFields).some(beneficiaryKey => {
                        const relationshipIdentifier = get(
                          relationship,
                          'relatedParty.identifiers[0].value'
                        )
                        return (
                          relationshipIdentifier &&
                          get(beneficiaryFields[beneficiaryKey], 'data.identifiers[0].value') ===
                            relationshipIdentifier
                        )
                      })) && (
                      <SidebarActionItem
                        key={`${relationship.role.join('_')}-${relationship.lastName}-${
                          relationship.firstName
                        }`}
                        title={this.getSideBarActionTitle(relationship)}
                        clickHandler={() => {
                          getIsMemberHasRole(relationship.role, POLICY_RELATIONSHIPS_LIFEASSURED)
                            ? this.addBeneficiary(relationship.relatedParty)
                            : this.setAdditionalBeneficiaryField(relationship.relatedParty)
                        }}
                      />
                    )
                )}
            <SidebarActionItem
              title={fields.addBeneficiaryButtonText.value}
              clickHandler={this.addBeneficiaryDetails}
            />
          </Fragment>
        ) : null}

        {!!totalBeneficiaryPercentage && totalBeneficiaryPercentage !== 100 && !form.isValid && (
          <ErrorMsg>
            <Caption error>
              <Text field={fields.beneficiaryTotalPercentageError} />
            </Caption>
          </ErrorMsg>
        )}

        {hasBeneficiaries && beneficiaryType.value ? (
          <Disclaimer>
            {beneficiaryType.value === BENEFICIARY_TYPE_BINDING_CODE ? (
              <RichText field={fields.beneficiaryBindingDisclaimer} />
            ) : (
              <RichText field={fields.beneficiaryNonBindingDisclaimer} />
            )}
          </Disclaimer>
        ) : null}
      </Wrapper>
    )
  }
}

export const mapStateToProps = ({ forms, createQuote }) => ({
  form: forms[FORM_ID],
  createQuote,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationBeneficiaries)
