// components.
import CreateQuote from '../../CreateQuote'
import SearchExistingClient from '../../CreateQuote/components/SearchExistingClient'

const SIDEBAR_SCHEMA = () => [
  {
    sitecoreFieldHeadingKey: 'quoteHeaderLabel',
    component: CreateQuote,
    visible: false,
    callbackKey: 'handleNewClient',
    props: {
      handleNewClient: next => next(),
      secondaryHeader: true,
    },
    isDark: true,
  },
  {
    sitecoreFieldHeadingKey: 'advisorPortalEditQuoteHeaderLabel',
    component: SearchExistingClient,
    visible: false,
    props: {
      secondaryHeader: true,
    },
  },
]

export default SIDEBAR_SCHEMA
