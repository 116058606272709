// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { pathOr } from 'lodash/fp'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// Constants
import { DEFAULT_INSURERS } from '../../../../../../constants/forms'

// Icons
import { IconAddCircle16 } from '../../../../../atoms/Icons'

// components
// eslint-disable-next-line import/no-named-as-default
import Benefits from '../ExistingBenefits'
import ButtonComponent from '../../../../../atoms/Button'
import Select from '../../../../../atoms/Select'
// eslint-disable-next-line import/no-named-as-default
import Input from '../../../../../atoms/Input'

// utils
import { getSelectedValue, generateFieldsFromData } from '../../../../../../utils/formUtils'
import { isIPCover } from '../../../../../../utils/quoteUtils'
import { defaults, FORM_ID, schema } from './existingPolicy.schema'
import { MLC_INSURANCE } from '../existingCover.schema'

// Styles
import styles from '../existingCover.styles'

type ExistingCoverProps = {
  currentPolicy: Object,
  policyIndex: Number,
  fields: Object,
  benefits: Object,
  updatePolicy: Function,
  existingInsurancesError: Object,
  renderErrorMessage: Function,
  form: Object,
  currentSelectedPolicy: Object,
  actions: Object,
  existingInsurances: Object,
  resetAddNewPolicyForm: Function,
  resetEditMode: Function,
  handleExistingCoverChange: Function,
}

export const TwoColumnLayout = styled('div')(styles.twoColumnLayout)
const Button = styled(ButtonComponent)(styles.button)
const PrimaryButton = styled(Button)(styles.primaryButton)
const Icon = styled(IconAddCircle16)(styles.blueIcon)
const BenefitAccordionContainer = styled('div')(styles.benefitContainer)
const Dropdown = styled('div')(styles.dropdown)
const DropdownContainer = styled('div')(styles.dropDownContainer)
const PolicyWrapper = styled('div')(styles.policyWrapper)
const PolicyContainer = styled(TwoColumnLayout)(styles.policyContainer)
const PolicyInput = styled(Input)(styles.policyInput)
const BenefitButton = styled(Button)(styles.benefitButton)
const PolicyActionContainer = styled('div')(styles.policyActionContainer)

export class Policy extends Component<ExistingCoverProps> {
  constructor(props) {
    super(props)
    this.addBenefitHandler = this.addBenefitHandler.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.state = {
      showBenefitOptions: false,
      showInsurerError: false,
      showPolicyNoError: false,
    }
  }

  componentDidMount() {
    const {
      currentSelectedPolicy,
      policyIndex,
      actions: { formInit, formReset },
      fields,
      currentPolicy,
      existingInsurances,
    } = this.props
    const policyInstanceNo = existingInsurances.length + 1

    // The insurer field in form requires value.value
    if (currentPolicy) {
      currentPolicy.insurer = {
        value: currentPolicy.insurer,
      }
    }
    if (currentSelectedPolicy === policyIndex) {
      formInit(
        FORM_ID,
        schema(fields),
        currentPolicy ? cloneDeep(currentPolicy) : defaults(policyInstanceNo)
      )
    } else {
      formReset(
        FORM_ID,
        schema(fields),
        currentPolicy ? cloneDeep(currentPolicy) : defaults(policyInstanceNo)
      )
    }
  }

  handleInputChange = value => {
    const {
      actions: { formUpdateField },
    } = this.props
    const { name, value: inputValue } = value
    formUpdateField(FORM_ID, name, { value: inputValue, error: false })
  }

  handleSavePolicy = () => {
    const {
      actions: { formSubmit },
      fields,
      updatePolicy,
      policyIndex,
      existingInsurances,
      resetAddNewPolicyForm,
      resetEditMode,
      form: {
        fields: {
          insurer: { value: insurer },
          policyInstanceNo: { value: policyInstanceNo },
        },
      },
      form: { fields: formFields },
    } = this.props
    formSubmit(FORM_ID, schema(fields, existingInsurances, insurer, policyInstanceNo), () => {
      updatePolicy(formFields, policyIndex)
      resetAddNewPolicyForm()
      resetEditMode()
    })
  }

  updateBenefits = (benefit, benefitIndex, removeBenefit) => {
    let {
      form: {
        fields: {
          benefits: { value: currentBenefitsList },
        },
      },
    } = this.props
    const {
      actions: { formUpdateField },
    } = this.props

    if (removeBenefit) {
      currentBenefitsList = { value: benefit }
    } else {
      currentBenefitsList[benefitIndex] = benefit
    }

    formUpdateField(FORM_ID, 'benefits', currentBenefitsList)
  }

  handleCancelPolicy = () => {
    const {
      actions: { formReset },
      fields,
      resetAddNewPolicyForm,
      resetEditMode,
      existingInsurances,
      handleExistingCoverChange,
    } = this.props
    formReset(FORM_ID, schema(fields), defaults())
    resetAddNewPolicyForm()
    resetEditMode()
    if (!existingInsurances.length) {
      handleExistingCoverChange()
    }
  }

  addBenefitHandler() {
    const { showBenefitOptions } = this.state
    if (this.validatePolicy()) {
      this.setState({ showBenefitOptions: !showBenefitOptions })
    }
  }

  validatePolicy() {
    const {
      form: {
        fields: { insurer, policyNo },
      },
    } = this.props
    let hasError = false
    if (!insurer.value.value) {
      this.setState({ showInsurerError: true })
      hasError = true
    } else {
      this.setState({ showInsurerError: false })
    }
    if (insurer.value.value === MLC_INSURANCE) {
      if (!policyNo.value) {
        this.setState({ showPolicyNoError: true })
        hasError = true
      } else {
        this.setState({ showPolicyNoError: false })
      }
    } else {
      this.setState({ showPolicyNoError: false })
    }

    return !hasError
  }

  addBenefitToPolicy(benefit) {
    const {
      benefitName,
      benefitCode,
      allowableDefermentPeriod,
      allowableBenefitPeriod,
      allowableFeatures,
      benefitType,
    } = benefit
    const {
      form: {
        fields: {
          benefits: { value: benefits },
        },
      },
      actions: { formUpdateField },
    } = this.props
    const newBenefit = {
      benefitType,
      benefitName,
      benefitCode,
      benefitInstanceNo: benefits.length + 1,
      sumInsured: '',
      startdate: '',
      replacingThisCover: '',
    }
    const isIncomeAssureBenefit = isIPCover({ type: benefitCode })
    if (isIncomeAssureBenefit) {
      newBenefit.superGuaranteeSumInsured = ''
    }
    if (allowableDefermentPeriod && getSelectedValue(allowableDefermentPeriod)) {
      newBenefit.waitingPeriod = getSelectedValue(allowableDefermentPeriod)
    }
    if (allowableBenefitPeriod && getSelectedValue(allowableBenefitPeriod)) {
      newBenefit.benefitPeriod = getSelectedValue(allowableBenefitPeriod)
    }

    if (allowableFeatures) {
      newBenefit.allowableFeatures = [...allowableFeatures]
    }
    benefits.push(generateFieldsFromData(newBenefit))
    formUpdateField(FORM_ID, 'benefits', {
      value: benefits,
      error: false,
    })
    this.setState({ showBenefitOptions: false })
  }

  render() {
    const { fields, policyIndex, benefits, existingInsurancesError, renderErrorMessage, form } =
      this.props
    if (!form) return null
    const {
      fields: {
        benefits: { value: currentBenefits },
        insurer,
        policyNo,
      },
      fields: formFields,
    } = form
    const { showBenefitOptions, showPolicyNoError, showInsurerError } = this.state
    const {
      existingCoverInsurerLabel,
      existingCoverInsurerPlaceHolder,
      existingCoverPolicyNumberLabel,
      existingCoverAddBenefitButtonLabel,
      existingCoverSavePolicyButtonLabel,
      insurerMandatoryError,
      policyNoMandatoryError,
      existingCoverCancelPolicyButtonLabel,
    } = fields
    const isInsurerValid = pathOr(false, 'insurer.error.error', formFields)
    const isPolicyNoValid = pathOr(false, 'policyNo.error.error', formFields)
    const policyNoErrorMsg = pathOr(null, 'policyNo.error.errorMsg', formFields)
    const isBenefitsValid =
      !currentBenefits.length && pathOr(false, 'benefits.error.error', formFields)
    const benefitErrorMsg = pathOr(false, 'benefits.error.errorMsg', formFields)

    return (
      <PolicyWrapper>
        <PolicyContainer>
          <Select
            label={renderTextField(existingCoverInsurerLabel)}
            placeholder={renderTextField(existingCoverInsurerPlaceHolder)}
            value={insurer.value.value}
            name="insurer"
            id={`insurer${policyIndex}`}
            changeHandler={this.handleInputChange}
            error={showInsurerError || isInsurerValid}
            caption={(showInsurerError || isInsurerValid) && renderTextField(insurerMandatoryError)}
            options={DEFAULT_INSURERS}
          />
          <TwoColumnLayout>
            <PolicyInput
              htmlFor="policyNo"
              label={renderTextField(existingCoverPolicyNumberLabel)}
              type="text"
              name="policyNo"
              id={`policyNo${policyIndex}`}
              changeHandler={this.handleInputChange}
              onBlur={this.validatePolicy}
              error={showPolicyNoError || isPolicyNoValid}
              caption={
                (showPolicyNoError && renderTextField(policyNoMandatoryError)) ||
                (isPolicyNoValid && renderTextField(policyNoErrorMsg))
              }
              value={policyNo.value}
            />
          </TwoColumnLayout>
        </PolicyContainer>
        {currentBenefits &&
          currentBenefits.map((benefit, benefitIndex) => (
            <BenefitAccordionContainer>
              <Benefits
                benefit={benefit}
                benefitsData={benefits}
                fields={fields}
                benefitIndex={benefitIndex}
                currentBenefits={currentBenefits}
                policyIndex={policyIndex}
                insurer={insurer.value.value}
                benefitError={
                  existingInsurancesError && existingInsurancesError.benefits.value[benefitIndex]
                }
                renderErrorMessage={renderErrorMessage}
                updateBenefits={this.updateBenefits}
              />
            </BenefitAccordionContainer>
          ))}
        <DropdownContainer>
          <BenefitButton type="tertiary" onClick={this.addBenefitHandler}>
            <Icon />
            <Text field={existingCoverAddBenefitButtonLabel} />
          </BenefitButton>
          {showBenefitOptions && (
            <Dropdown id={`dropdown${policyIndex}`}>
              {benefits &&
                benefits.map(benefit => (
                  <button
                    key={`benefit${benefit.benefitName}`}
                    onClick={() => {
                      this.addBenefitToPolicy(benefit)
                    }}
                    onKeyPress={() => {
                      this.addBenefitToPolicy(benefit)
                    }}
                    type="button"
                  >
                    {/* Displaying Income protection in case of IP benefit */}
                    {benefit.benefitType && get(benefit, 'benefitType.code', '') === 'IP'
                      ? get(benefit, 'benefitType.value', '')
                      : get(benefit, 'benefitName', '')}
                  </button>
                ))}
            </Dropdown>
          )}
        </DropdownContainer>
        {isBenefitsValid && renderErrorMessage(renderTextField(benefitErrorMsg))}
        <PolicyActionContainer>
          {currentBenefits.length ? (
            <PrimaryButton onClick={this.handleSavePolicy} data-locator="LOCATOR_CONTINUE_BUTTON">
              <Text field={existingCoverSavePolicyButtonLabel} />
            </PrimaryButton>
          ) : (
            ''
          )}
          <Button
            type="secondary"
            onClick={this.handleCancelPolicy}
            data-locator="LOCATOR_CONTINUE_BUTTON"
          >
            <Text field={existingCoverCancelPolicyButtonLabel} />
          </Button>
        </PolicyActionContainer>
      </PolicyWrapper>
    )
  }
}

export const mapStateToProps = ({ forms }) => ({
  form: forms[FORM_ID],
})

export const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Policy)
