// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import { actionCreators } from '../../../../../actions'

// components.
import TemplateList from './TemplateList'

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = state => ({
  advisorManagedTemplates:
    state.quoteTemplates.advisorManaged && state.quoteTemplates.advisorManaged.data,
  productRules: state.productRules.data,
  advisorDetails: state.advisor.details,
  memberMandatories: get(state, 'createQuote.quotes[0].memberMandatories', {}),
  masterData: state.masterList.data || {},
  calculateSaveQuote: state.calculateSaveQuote || {},
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)
