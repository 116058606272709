// @flow
import React from 'react'
// Organism
import AltsLandingPageOrganism from '../../../organisms/AltsLandingPage'

type AltsLandingPageProps = {
  // Sitecore fields.
  fields: Object<Object>,
  // Params
  params: Object,
}

const AltsLandingPage = ({ fields, params }: AltsLandingPageProps) => (
  <AltsLandingPageOrganism fields={fields} params={params} />
)

export default AltsLandingPage
