/* eslint-disable jsx-a11y/aria-role */
// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// components.
import AddressLookUp from '../../../../molecules/AddressLookUp'
import AbnLookup from '../../../../molecules/AbnLookup'

// styles
import styles from './smsfDetails.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'
import ToggleGroup from '../../../../molecules/ToggleGroup'

// schema.
import SCHEMA, { FORM_ID } from './smsfDetails.schema'
import { initForms } from './smsfDetailsInitUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { errorCheck, getValue, generateFieldsFromData } from '../../../../../utils/formUtils'

type SmsfDetailsProps = {
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  // Sitecore authorable fields
  fields: Object,
  isManualResidential: boolean,
  abnLookup: Object,
  sidebar: Object,
}

const FullWidthAbnLookup = ignoreColumns(AbnLookup)
const Wrapper = styled('section')(styles.wrapper)
const Fieldset = styled('div')(styles.fieldset)
const InputFullWidth = styled(Input)(styles.fullWidth)
const AddressFullWidth = ignoreColumns(AddressLookUp)
const FullWidthToggleGroup = ignoreColumns(ToggleGroup)

export class SmsfDetails extends Component<SmsfDetailsProps> {
  componentWillMount() {
    const {
      actions: { formInit, formValidate },
      isManualResidential,
      form,
      sidebar,
      fields,
    } = this.props

    if (!sidebar.panelProps) return
    const schema = SCHEMA({ isManualResidential }, fields)

    const data = initForms(this.props)
    if (!form) {
      formInit(FORM_ID, schema, data)
      if (Object.keys(data).length) {
        formValidate(FORM_ID, schema)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { abnLookup: prevAbnLookUp } = prevProps
    const {
      abnLookup,
      actions: { formUpdateField },
    } = this.props

    if (prevAbnLookUp.companyName !== abnLookup.companyName) {
      formUpdateField(FORM_ID, 'superannuationFundName', { value: abnLookup.companyName })
    }
  }

  toggleAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      isManualResidential,
      fields,
    } = this.props

    const schema = SCHEMA(
      {
        isManualResidential,
      },
      fields
    )
    const data = {
      error: errorCheck(value, schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handleChange = ({ value, name }) => {
    const { actions, isManualResidential, fields } = this.props
    const { formUpdateField, formValidate } = actions

    const schema = SCHEMA(
      {
        isManualResidential,
      },
      fields
    )
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressChange = address => {
    const { name, data, value } = address
    const { actions, isManualResidential, fields } = this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = SCHEMA(
      {
        isManualResidential,
      },
      fields
    )
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  render() {
    const {
      fields,
      form,
      abnLookup: { error, isLoading, isAbnCancelled },
      isManualResidential,
    } = this.props
    const schema = SCHEMA({ isManualResidential }, fields)
    if (!form) return ''
    const {
      businessOwnerAbnPlaceHolder,
      policyOwnerValidatingBusinessAbnNumberWarning,
      policyOwnerValidatedBusinessAbnNumberError,
      businessOwnerABNError,
      policyOwnerDetailsEmailFieldLabel,
      policyOwnerDetailsEmailFieldPlaceholder,
      policyOwnerDetailsEmailFieldError,
      policyOwnerRegulatoryFieldLabel,
      wrapSuperSmsfATIORegulatedFundDetailsABN,
      wrapSuperSmsfAPRARegulatedFundDetailsABN,
      wrapSuperSmsfFundDetailsCancelledABN,
      policyOwnerRegulatoryMethodFieldError,
      policyOwnerSuperannuationFundNameFieldLabel,
      policyOwnerSuperannuationFundNameFieldPlaceholder,
      policyOwnerSuperannuationFundNameFieldError,

      // Residential Address
      policyOwnerDetailsResidentialAddressToggleToManualText,
      policyOwnerDetailsResidentialAddressFieldLabel,
      policyOwnerDetailsResidentialAddressFieldPlaceholder,
      policyOwnerDetailsResidentialAddressFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
      policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
      policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
      policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
      policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
      policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
      policyOwnerDetailsResidentialAddressToggleToAutoText,
    } = reduceAuthorableFields(fields)
    const {
      residentialAddress,
      residentialStreet,
      residentialHouseNo,
      residentialLocality,
      residentialState,
      residentialCountry,
      residentialPostCode,
      abn,
      superannuationFundName,
      regulatory,
      email,
    } = form.fields

    const handleAbnCaption = () => {
      if (isAbnCancelled) {
        return wrapSuperSmsfFundDetailsCancelledABN
      }
      if (abn.error.error) {
        return businessOwnerABNError
      }
      if (isLoading) {
        return policyOwnerValidatingBusinessAbnNumberWarning
      }
      if (error) {
        return policyOwnerValidatedBusinessAbnNumberError
      }
      return null
    }

    return (
      <Wrapper>
        <Fieldset role="fieldset">
          <InputFullWidth
            htmlFor="superannuationFundName"
            name="superannuationFundName"
            label={policyOwnerSuperannuationFundNameFieldLabel}
            placeholder={policyOwnerSuperannuationFundNameFieldPlaceholder}
            changeHandler={this.handleChange}
            value={superannuationFundName.value}
            error={superannuationFundName.error.error}
            caption={
              superannuationFundName.error.error && policyOwnerSuperannuationFundNameFieldError
            }
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <FullWidthToggleGroup
            labelSize="sm"
            htmlFor="regulatory"
            variant="tab"
            label={policyOwnerRegulatoryFieldLabel}
            name="regulatory"
            labelledby="client-label"
            handleChange={this.handleChange}
            options={schema.regulatory.options}
            value={regulatory.value}
            error={regulatory.error.error}
            caption={regulatory.error.error && policyOwnerRegulatoryMethodFieldError}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <FullWidthAbnLookup
            htmlFor="abn"
            name="abn"
            label={
              regulatory.value === 'ATO'
                ? wrapSuperSmsfATIORegulatedFundDetailsABN
                : wrapSuperSmsfAPRARegulatedFundDetailsABN
            }
            changeHandler={this.handleChange}
            placeholder={businessOwnerAbnPlaceHolder}
            error={isAbnCancelled || abn.error.error || error}
            caption={handleAbnCaption()}
            value={abn.value}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <AddressFullWidth
            name="smsfAddress"
            auto={{
              label: policyOwnerDetailsResidentialAddressFieldLabel,
              placeholder: policyOwnerDetailsResidentialAddressFieldPlaceholder,
              toggleLabel: policyOwnerDetailsResidentialAddressToggleToManualText,
              value: residentialAddress.value,
              selectChangeHandler: this.addressChange,
              addressError: residentialAddress.error.error,
              addressName: 'residentialAddress',
              addressErrorMessage:
                residentialAddress.error.error && policyOwnerDetailsResidentialAddressFieldError,
            }}
            manual={{
              streetPlaceholder: policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
              streetValue: residentialStreet.value,
              streetRequiredErrorMessage: residentialStreet.error.errorMsg,
              streetError: residentialStreet.error.error,
              streetName: 'residentialStreet',
              houseNoPlaceholder: policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
              houseNoValue: residentialHouseNo.value,
              houseNoError: residentialHouseNo.error.error,
              houseNoRequiredErrorMessage: residentialHouseNo.error.erroMsg,
              houseNoName: 'residentialHouseNo',
              localityPlaceholder: policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
              localityLabelValue: residentialLocality.value,
              localityError: residentialLocality.error.error,
              localityRequiredErrorMessage: residentialLocality.error.errorMsg,
              localityName: 'residentialLocality',
              statePlaceholder: policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
              stateOptions: schema.residentialState.options,
              stateValue: residentialState.value,
              stateError: residentialState.error.error,
              stateRequiredErrorMessage: residentialState.error.errorMsg,
              stateName: 'residentialState',
              countryPlaceholder: policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
              countryValue: residentialCountry.value,
              countryError: residentialCountry.error.error,
              countryRequiredErrorMessage: residentialCountry.error.errorMsg,
              countryName: 'residentialCountry',
              postCodePlaceholder: policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
              postCodeValue: residentialPostCode.value,
              postCodeError: residentialPostCode.error.error,
              postCodeRequiredErrorMessage: residentialPostCode.error.errorMsg,
              postCodeName: 'residentialPostCode',
              manualToggleLabel: policyOwnerDetailsResidentialAddressToggleToAutoText,
              inputEntryHandler: this.handleChange,
            }}
          />
        </Fieldset>
        <Fieldset role="fieldset">
          <InputFullWidth
            htmlFor="email"
            name="email"
            label={policyOwnerDetailsEmailFieldLabel}
            changeHandler={this.handleChange}
            placeholder={policyOwnerDetailsEmailFieldPlaceholder}
            value={email.value}
            error={email.error.error}
            caption={email.error.error && policyOwnerDetailsEmailFieldError}
          />
        </Fieldset>
      </Wrapper>
    )
  }
}

export const mapStateToProps = ({
  forms,
  addressLookup,
  abnLookup,
  captureCreditCard,
  createQuote,
  sidebar,
}) => ({
  forms,
  form: forms[FORM_ID],
  isManualPostal:
    addressLookup.policyOwnerPostalAddress && addressLookup.policyOwnerPostalAddress.isManual,
  isManualResidential: addressLookup.smsfAddress && addressLookup.smsfAddress.isManual,
  abnLookup,
  captureCreditCard,
  createQuote,
  sidebar,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SmsfDetails)
