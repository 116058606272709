/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconCalendar32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10V8h-8v2H9V8H6v4h20V8h-3v2h-3zM9 5H5.5A2.5 2.5 0 0 0 3 7.5v19A3.5 3.5 0 0 0 6.5 30h19a3.5 3.5 0 0 0 3.5-3.5v-19A2.5 2.5 0 0 0 26.5 5H23V3h-3v2h-8V3H9v2zM6 15h20v11.5a.5.5 0 0 1-.5.5h-19a.5.5 0 0 1-.5-.5V15zm2 5h4v-3H8v3zm6 0h4v-3h-4v3zm6 0h4v-3h-4v3zM8 25h4v-3H8v3zm6 0h4v-3h-4v3zm6 0h4v-3h-4v3z"
      fill="#000"
    />
  </Icon>
)

export default IconCalendar32
