// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// components.
import { IconAddCircle16 } from '../../../../../atoms/Icons'
import Button from '../../../../../atoms/Button'

// styles.
import styles from './addPolicy.styles'

type AddPolicyProps = {
  // Add policy label
  quoteEntityAddPolicy: 'string',
  // Function to fire when clicked
  clickHandler: Function,
}

const Wrap = styled('div')(styles.wrap)
const Btn = styled(Button)(styles.button)
const Icon = styled(IconAddCircle16)(styles.icon)

const AddPolicy = ({ quoteEntityAddPolicy, clickHandler }: AddPolicyProps) => (
  <Wrap>
    <Btn type="tertiary" onClick={clickHandler}>
      <Icon />
      <Text field={quoteEntityAddPolicy} />
    </Btn>
  </Wrap>
)

export default AddPolicy
