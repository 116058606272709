// @ts-expect-error file not in typescript
import { required } from '../../../../../utils/formUtils'

import { fieldsType } from '../../../../../types/components/Sidebar'

export const FORM_ID = 'quickQuoteSumInsured'

const schema = (coversNameArr: string[], fields: fieldsType) =>
  coversNameArr.reduce<{
    [key: string]: { valueType: string; condition: string; errorMsg: string }
  }>((acc, cur) => {
    acc[cur] = {
      valueType: 'number',
      condition:
        cur === 'total_and_permanent_disability'
          ? [
              required,
              {
                condition: (
                  value: string,
                  values: { [key: string]: { error: boolean; value: string } }
                ) => +value > (+values?.life_cover?.value || +values?.non_life_cover?.value),
                errorMsg: fields.quickQuoteSumInsuredTPDGTLCErrorMsg.value,
              },
            ]
          : required,
      errorMsg: fields.quickQuoteSumInsuredBlankErrorMsg.value,
    }
    return { ...acc }
  }, {})

export default schema
