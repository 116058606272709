import { space, fontSize, colours } from '../../../styles'

const styles = {
  menuLink: {
    padding: space(1),
    color: colours.mediumGreen,
    fontSize: fontSize.xxs,
  },
  maxHeight: {
    maxHeight: space(32),
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}

export default styles
