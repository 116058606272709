// @flow
import get from 'lodash/get'

import { getPolicyOwner, getPrimaryLifeInsured } from '../../../../utils/policyUtils'

import {
  BANCS_CUSTOMER_NUMBER_NULIS,
  BANCS_CUSTOMER_NUMBER_IOOF,
} from '../../../../constants/bancs'
import {
  POLICY_PARTY_TYPE_INDIVIDUAL,
  POLICY_RELATIONSHIPS_OWNER,
} from '../../../../constants/policies'

const WHITE_SEPARATOR = ' '

/**
 * Returns an array of relationships using the business rules:
 * 1. If bancsCustomerNo = NULIS for roleCode = OWR and roleCode = LA then return the LA record
 * 2. If bancsCustomerNo != NULIS for roleCode = OWR and roleCode = LA
 * then return primary OWR and LA records
 *    assuming OWR record != LA record
 * 3. If OWR and LA records are redundant, then return back the unique set of records
 * @returns Array<Object>: [{relationship}]
 * */
export const getUniqueRelationships: Array<Object> = policies => {
  const allPolicyRelationships = []

  policies &&
    policies.forEach(eachPolicy => {
      const relationshipsInPolicy = get(eachPolicy, 'policy.relationships', [])

      let policyOwnersInPolicy = getPolicyOwner(relationshipsInPolicy)
      // Convert to array as getPolicyOwner can return an object or an array !!
      if (policyOwnersInPolicy && !Array.isArray(policyOwnersInPolicy)) {
        policyOwnersInPolicy = [policyOwnersInPolicy]
      }
      if (policyOwnersInPolicy) {
        // if there is1 record, then if there is a NULIS as OWR, then dont add that relationship
        if (policyOwnersInPolicy.length === 1) {
          if (
            ![BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(
              policyOwnersInPolicy[0].bancsCustomerNo
            )
          ) {
            allPolicyRelationships.push(policyOwnersInPolicy[0])
          }
        } else {
          // If more than one record, then there is no NULIS as OWR.
          allPolicyRelationships.push(...policyOwnersInPolicy)
        }
      }

      const lifeInsuredInPolicyArray = getPrimaryLifeInsured(relationshipsInPolicy)
      allPolicyRelationships.push(...lifeInsuredInPolicyArray)
    })

  // Derive an object with key as bancscustomerNo
  // and add roles key: an array of unique roleCodes
  const uniqueRelationshipsObj = {}
  allPolicyRelationships.forEach(relationship => {
    const { bancsCustomerNo, roleCode } = relationship
    if (Object.keys(uniqueRelationshipsObj).includes(bancsCustomerNo)) {
      if (!uniqueRelationshipsObj[bancsCustomerNo].roles.includes(roleCode)) {
        uniqueRelationshipsObj[bancsCustomerNo].roles =
          uniqueRelationshipsObj[bancsCustomerNo].roles.concat(roleCode)
      }
    } else {
      uniqueRelationshipsObj[bancsCustomerNo] = {
        ...relationship,
        roles: [relationship.roleCode],
      }
    }
  })

  // Return array of unique relationships
  return Object.values(uniqueRelationshipsObj)
}

/**
 * if roles: ['LA'] return LifeInsuredField
 * if roles: ['OWR'] return PolicyOwnerField
 * if roles: ['LA'] return PolicyOwnerAndLifeInsuredField
 * @returns Heading based on roles array
 */
const constructPartyRoleHeading = (
  roles,
  PolicyOwnerField,
  LifeInsuredField,
  PolicyOwnerAndLifeInsuredField
) => {
  if (roles.length === 1) {
    return roles.includes(POLICY_RELATIONSHIPS_OWNER) ? PolicyOwnerField : LifeInsuredField
  }
  return PolicyOwnerAndLifeInsuredField
}

/**
 * Returns heading based on roles and partyType
 * Uses firstName lastName for partyType=PER
 * Uses businessName for partyType=ORG
 */
export const constructHeading = (
  roleCode,
  roles,
  PolicyOwnerField,
  LifeInsuredField,
  PolicyOwnerAndLifeInsuredField,
  partyType,
  firstName,
  lastName,
  businessName
) => {
  const partyRoleHeading = constructPartyRoleHeading(
    roles,
    PolicyOwnerField,
    LifeInsuredField,
    PolicyOwnerAndLifeInsuredField
  )
  if (roleCode === POLICY_RELATIONSHIPS_OWNER) {
    return `${partyRoleHeading}${
      partyType === POLICY_PARTY_TYPE_INDIVIDUAL
        ? `${firstName}${WHITE_SEPARATOR}${lastName}`
        : `${businessName}`
    }`
  }
  return `${partyRoleHeading}${firstName}${WHITE_SEPARATOR}${lastName}`
}

/** Helper function to fetch the bancsCustomerNo within a client detail
 * @return string : a bancsCustomerNo
 */
export const getBancsCustomerNo = (clientDetails: Object) => {
  const customerIdentifiers = get(clientDetails, 'relatedParty.identifiers', [])
  const bancsIdentifier = customerIdentifiers.find(
    identifier => identifier.type === 'BANCS_CUSTOMER_NO'
  )
  return bancsIdentifier && bancsIdentifier.value
}

export const getPartyType = relationship => get(relationship, 'relatedParty.partyType', '')
