export const EXPERIENCE_API_BASE = '/digital/retailproxy/experience'
export const EXPERIENCE_API_REFERENCE = '/digital/retailproxy/reference'
export const EXPERIENCE_API_URE_BASE = '/digital/retailproxy/ure'
export const EXPERIENCE_API_ADDRESS_BASE = '/digital/retailproxy/reference/address/addresses'
export const EXPERIENCE_API_BSB_BASE = '/digital/retailproxy/reference/bsb/branches'
export const EXPERIENCE_API_DOCUMENT_GENERATE = '/documents/generate'
export const EXPERIENCE_API_SITECORE_BASE = '/digital/retailproxy/sitecore'
export const EXPERIENCE_API_TRANSACT_BASE =
  '/digital/retailproxy/experience/payments/updateBankTransaction'
export const EXPERIENCE_API_SAVE_CARD_TRANSACT_BASE =
  '/digital/retailproxy/experience/policies/paymentInstruction/'

export const SITECORE_API_BASE = '/digital/retail/data'
export const SITECORE_URE_BASE = '/api/ure/v1'
export const SITECORE_QUOTEAPPLY_BASE = '/api/quoteapply/v1'
export const SITECORE_API_COMPUTE = '/api/compute/v1'

// TODO: hardcoded for now until okta exposes these values to be consumed.
export const EXPERIENCE_API_HEADER_X_INITIAL_USER = 'User'

export const EXPERIENCE_API_VERSION_1 = '/v1'

export const SC_BRANCH_TAG = 'UREBranchTag'
export const DEFAULT_TAG = 'MLC'
export const URE_VERSION_OCCUPATIONS_RATING = 'OccupationRatingAPIVersion'

export const EXP_CUSTOMER_STATUS_UNLOADED = 'UNLOADED'
export const EXP_CUSTOMER_STATUS_LOADING = 'LOADING'
export const EXP_CUSTOMER_STATUS_LOADED = 'LOADED'
export const EXP_CUSTOMER_STATUS_FAILED = 'FAILED'
