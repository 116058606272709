import { colours, fontSize, fontWeights, shadows, space } from '../../../../../styles'

const styles = {
  label: (theme, variant, isFontBold) => {
    if (variant === 'circle') {
      return {
        display: 'inline',
        marginLeft: space(1),
      }
    }

    return {
      cursor: 'pointer',
      fontSize: variant === 'tab' ? fontSize.xs : fontSize.sm,
      fontWeight: isFontBold ? fontWeights.semiBold : fontWeights.normal,
      lineHeight: fontSize.md,
      padding: variant === 'tab' ? space(1) : space(3),
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      ...(variant !== 'noFixedheight' &&
        variant !== 'mobileFullWidth' && {
          height: variant === 'tab' ? 48 : 72,
        }),
      flex: 1,
      justifyContent: 'center',
      width: '100%',
      background: colours.white,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colours.lightestGrey,
      boxShadow: variant !== 'tab' && shadows.low,
      '&:hover': {
        borderColor: theme.hover.border,
      },
    }
  },
  error: {
    borderColor: colours.red,
    color: colours.red,
  },
  disabled: {
    borderColor: colours.lightestGrey,
    color: colours.lightestGrey,
  },
  input: (theme, variant) => {
    if (variant === 'circle') {
      return {
        position: 'static',
      }
    }
    return {
      opacity: 0,
      position: 'absolute',
      zIndex: '1',
      '&:checked + label': {
        color: theme.selected.text,
        border: 'none',
        background: theme.selected.bg,
        '& *': {
          color: theme.selected.text,
        },
      },
    }
  },
}

export default styles
