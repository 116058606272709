// @flow
import { STATES, TITLES } from '../../../../../constants/forms'

import {
  date,
  datePast,
  email,
  optional,
  required,
  isNotFormattedDate,
  postcode,
  phone,
  phoneInputs,
  firstNameLastName,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../../../utils/formUtils'

// TODO: Reinstate to address schema optional to required
// once addresslookup has been integrated
const schema =
  (fields?: {
    policyOwnerDetailsResidentialAddressCountryFieldError: Object,
    policyOwnerDetailsResidentialAddressCountryFieldInvalidError: Object,
    policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage: Object,
    policyOwnerDetailsDateOfBirthFieldError: Object,
    policyOwnerDetailsDateOfBirthFieldFutureDateError: Object,
    policyOwnerDetailsDateOfBirthFieldFormatError: Object,
    policyOwnerDetailsDateOfBirthFieldAgeRangeError: Object,
    policyOwnerDetailsResidentialAddressStreetFieldError: Object,
    policyOwnerDetailsResidentialAddressLocalityFieldError: Object,
    policyOwnerDetailsResidentialAddressStateFieldError: Object,
    policyOwnerDetailsResidentialAddressPostCodeFieldError: Object,
    policyOwnerDetailsPostalAddressStreetFieldError: Object,
    policyOwnerDetailsPostalAddressLocalityFieldError: Object,
    policyOwnerDetailsPostalAddressStateFieldError: Object,
    policyOwnerDetailsPostalAddressPostCodeFieldError: Object,
    policyOwnerDetailsResidentialAddressValidStreetFieldError: Object,
    policyOwnerDetailsResidentialAddressValidLocalityFieldError: Object,
    policyOwnerDetailsResidentialAddressValidStateFieldError: Object,
    policyOwnerDetailsResidentialAddressValidPostalFieldError: Object,
    policyOwnerDetailsInvalidFirstNameError: Object,
    policyOwnerDetailsFirstNameFieldError: Object,
    policyOwnerDetailsInvalidLastNameError: Object,
    policyOwnerDetailsLastNameFieldError: Object,
  }): Function =>
  (state?: Object): Object => ({
    title: {
      condition: required,
      options: TITLES,
    },
    firstName: {
      condition: [
        required,
        {
          condition: firstNameLastName,
          errorMsg: fields && fields.policyOwnerDetailsInvalidFirstNameError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsFirstNameFieldError.value,
      tooltip: '',
    },
    middleName: {
      condition: optional,
    },
    lastName: {
      condition: [
        required,
        {
          condition: firstNameLastName,
          errorMsg: fields && fields.policyOwnerDetailsInvalidLastNameError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsLastNameFieldError.value,
      tooltip: '',
    },
    dateOfBirth: {
      condition: [
        required,
        {
          condition: isNotFormattedDate,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldFormatError.value,
        },
        {
          condition: date,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldError.value,
        },
        {
          condition: datePast,
          errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldFutureDateError.value,
        },
      ],
      errorMsg: fields && fields.policyOwnerDetailsDateOfBirthFieldError.value,
    },
    email: {
      condition: [required, email],
    },
    residentialAddress: {
      condition: state.isManualResidential ? optional : required,
    },
    residentialHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg:
            fields && fields.policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value,
        },
      ],
    },
    residentialStreet: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsResidentialAddressStreetFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidStreetFieldError.value,
            }
          : {},
      ],
    },
    residentialLocality: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressLocalityFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidLocalityFieldError.value,
            }
          : {},
      ],
    },
    residentialState: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsResidentialAddressStateFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidStateFieldError.value,
            }
          : {},
      ],
      options: STATES,
    },
    residentialCountry: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressCountryFieldError.value,
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: fields && fields.policyOwnerDetailsResidentialAddressCountryFieldError.value,
        },
      ],
    },
    residentialPostCode: {
      condition: [
        state.isManualResidential
          ? {
              condition: required,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressPostCodeFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressPostalCode,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidPostalFieldError.value,
            }
          : {},
        postcode,
      ],
    },
    isPostalResidentialAddressSame: {
      condition: optional,
    },
    postalAddress: {
      condition: state.isManualPostal || state.postalHidden ? optional : required,
    },
    postalHouseNo: {
      condition: [
        optional,
        {
          condition: manualAddressRegex,
          errorMsg:
            fields && fields.policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage.value,
        },
      ],
    },
    postalStreet: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsPostalAddressStreetFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidStreetFieldError.value,
            }
          : {},
      ],
    },
    postalLocality: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsPostalAddressLocalityFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidLocalityFieldError.value,
            }
          : {},
      ],
    },
    postalState: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsPostalAddressStateFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressRegex,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidStateFieldError.value,
            }
          : {},
      ],
      options: STATES,
    },
    postalCountry: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressCountryFieldError.value,
            }
          : optional,
        {
          condition: manualAddressRegex,
          errorMsg: fields && fields.policyOwnerDetailsResidentialAddressCountryFieldError.value,
        },
      ],
    },
    postalPostCode: {
      condition: [
        state.isManualPostal
          ? {
              condition: required,
              errorMsg: fields && fields.policyOwnerDetailsPostalAddressPostCodeFieldError.value,
            }
          : optional,
        state.isManualResidential
          ? {
              condition: manualAddressPostalCode,
              errorMsg:
                fields && fields.policyOwnerDetailsResidentialAddressValidPostalFieldError.value,
            }
          : {},
        postcode,
      ],
    },
    contactNumber: {
      condition: [{ condition: required }, { condition: phone }],
      onChangeCondition: phoneInputs,
    },
    contactNumberPhoneCode: {
      condition: required,
    },
    secondaryNumber: {
      condition:
        state && state.hasSecondaryContact && state.hasSecondaryContact.value
          ? [{ condition: required }, { condition: phone }]
          : optional,
      onChangeCondition: phoneInputs,
    },
    secondaryNumberPhoneCode: {
      condition: required,
    },
    secondaryContactNoCheck: {
      condition: optional,
    },
    identifier: {
      condition: optional,
    },
  })

export default schema
