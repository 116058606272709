// @flow
import get from 'lodash/get'
import {
  ADD_LOADING,
  DELETE_LOADING,
  EDIT_LOADING,
  UPDATE_LOADING,
  SET_PRE_ASSESSMENT_OPEN_MODE,
  RESET_PRE_ASSESSMENT_OPEN_MODE,
  SAVE_LOADINGS,
} from '../types/preAssessment'

import { extractLoadingsFromQuote } from '../../utils/preAssessmentBenefitUtils'

export const addLoading = payload => ({
  type: ADD_LOADING,
  payload,
})

export const removeLoading = payload => ({
  type: DELETE_LOADING,
  payload,
})

export const editLoading = payload => ({
  type: EDIT_LOADING,
  payload,
})

export const updateLoading = payload => ({
  type: UPDATE_LOADING,
  payload,
})

export const setPreAssessmentOpenMode = payload => ({
  type: SET_PRE_ASSESSMENT_OPEN_MODE,
  payload,
})

export const resetPreAssessmentOpenMode = () => ({
  type: RESET_PRE_ASSESSMENT_OPEN_MODE,
})

export const saveLoadings = payload => ({
  type: SAVE_LOADINGS,
  payload,
})

export const updateLoadingsFromQuote =
  (quote: Object) =>
  ({ error, payload }: Object) =>
  (dispatch: Function) => {
    let loadings = []
    if (!error) {
      const umeBenefits = get(payload, 'data.umeBenefits', [])
      const { policyStructure } = quote
      loadings = extractLoadingsFromQuote(umeBenefits, policyStructure)
    }
    dispatch(updateLoading(loadings))
  }
