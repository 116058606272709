import {
  IP_COVER_CALCULATED,
  SET_IP_SUM_INSURED,
  IP_COVER_SUM_INSURED_ERROR_MODAL,
} from '../types/calculateSaveQuote'

export const isIPCoverCalculated = isIPCalculated => dispatch => {
  dispatch({
    type: IP_COVER_CALCULATED,
    payload: { isIPCalculated },
  })
}
export const setIPSumInsured = sumInsured => dispatch => {
  dispatch({
    type: SET_IP_SUM_INSURED,
    payload: { sumInsured },
  })
}

export const toggleIPCoverSumInsuredErrorModal = showModal => dispatch => {
  dispatch({
    type: IP_COVER_SUM_INSURED_ERROR_MODAL,
    payload: { showModal },
  })
}
