/* eslint-disable no-unused-vars */
// @flow
import React from 'react'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormBlock } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './projectionsForm.styles'

// components.
import Checkbox from '../../../atoms/Checkbox'
import Select from '../../../atoms/Select'

// utils.
import { getValue } from '../../../../utils/formUtils'

// styled components.
const Form = styled('div')(styles.form)
const FieldGroup = styled(FormBlock)(styles.fieldGroup)

type ProjectionsFormProps = {
  // quote index of selected quote.
  selectedQuoteIndex: number,
  // policy index of selected policy.
  selectedPolicyIndex: number,
  // cover type of selected cover.
  selectedCover: string,
  // quote select options.
  quoteOptions: Array<Object>,
  // policy select options.
  policyOptions: Array<Object>,
  // cover select options.
  coverOptions: Array<Object>,
  // duration select options.
  durationOptions: Array<Object>,
  // selected project duration.
  duration: number,
  // apply CPI checkbox value.
  applyCPI: boolean,
  // cumulative checkbox value.
  cumulative: boolean,
  // disable CPI checkbox based on cover
  disableCPI: boolean,
  // function to call when quote is changed.
  changeQuote: Function,
  // function to call when policy is changed.
  changePolicy: Function,
  // function to call when cover is changed.
  changeCover: Function,
  // function to call when project duration is changed.
  changeDuration: Function,
  // function to call when apply CPI is changed.
  changeCPI: Function,
  // function to call when cumulative is changed.
  changeCumulative: Function,
  // sitecore fields from Sidebar route.
  fields: {
    projectionsQuoteLabel: Object,
    projectionsPolicyLabel: Object,
    ProjectionsCoverLabel: Object,
    projectionsApplyCPILabel: Object,
    projectionsCumulativeLabel: Object,
    projectionsProjectDurationLabel: Object,
    ProjectionsSteppedLabel: Object,
    ProjectionsLevelLabel: Object,
    projectionsChartTitle: Object,
  },
}

const ProjectionsForm = (props: ProjectionsFormProps) => {
  const {
    selectedQuoteIndex,
    selectedPolicyIndex,
    selectedCover,
    quoteOptions,
    policyOptions,
    coverOptions,
    durationOptions,
    duration,
    applyCPI,
    cumulative,
    changeQuote,
    changePolicy,
    changeCover,
    changeDuration,
    changeCPI,
    changeCumulative,
    disableCPI,
    fields: {
      projectionsQuoteLabel,
      projectionsPolicyLabel,
      ProjectionsCoverLabel,
      projectionsApplyCPILabel,
      projectionsCumulativeLabel,
      projectionsProjectDurationLabel,
    },
  } = props

  const handleChange =
    (handler: Function): Function =>
    ({ value }) => {
      if (typeof handler === 'function') {
        handler(getValue(value))
      }
    }

  return (
    <Form aria-labelledby="projections" data-testid="projections-form">
      {/* Quote */}
      <Select
        label={projectionsQuoteLabel.value}
        value={selectedQuoteIndex}
        name="projections-quote"
        id="projections-quote"
        changeHandler={handleChange(changeQuote)}
        options={quoteOptions}
      />

      {/* Policy */}
      <Select
        label={projectionsPolicyLabel.value}
        value={selectedPolicyIndex}
        name="projections-policy"
        id="projections-policy"
        changeHandler={handleChange(changePolicy)}
        options={policyOptions}
      />

      {/* Benefits */}
      <Select
        label={ProjectionsCoverLabel.value}
        value={selectedCover}
        name="projections-benefit"
        id="projections-benefit"
        changeHandler={handleChange(changeCover)}
        options={coverOptions}
      />

      {/* Project duration */}
      <Select
        value={duration}
        label={projectionsProjectDurationLabel.value}
        name="projections-duration"
        id="projections-duration"
        formBlockStyle={styles.durationSelect}
        changeHandler={handleChange(changeDuration)}
        options={durationOptions}
      />

      <FieldGroup>
        {/* Apply CPI */}
        <Checkbox
          text={projectionsApplyCPILabel.value}
          name="projections-cpi"
          htmlFor="projections-cpi"
          disabled={disableCPI}
          onChangeHandler={handleChange(changeCPI)}
          checked={applyCPI}
          css={styles.checkbox}
        />

        {/* Cumulative */}
        <Checkbox
          text={projectionsCumulativeLabel.value}
          name="projections-cumulative"
          htmlFor="projections-cumulative"
          onChangeHandler={handleChange(changeCumulative)}
          checked={cumulative}
          css={styles.checkbox}
        />
      </FieldGroup>
    </Form>
  )
}

export default ProjectionsForm
