// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'

import Input from '../../atoms/Input'
import Button from '../../atoms/Button'
// styles.
import styles from './actionableInput.styles'

type ActionableInputProps = {
  // all input props
  inputProps: {},
  // icon which will render beside the input
  icon: Node,
  // Set button for actioning inputs disabled state
  disableAction?: boolean,
  // Function to fire on click of button for actionable field
  handleClick: Function,
}

const Wrap = styled('div')(styles.wrap)
const InputField = styled(Input)(styles.input)

const ActionableInput = (props: ActionableInputProps) => {
  const { handleClick, icon, disableAction, ...rest } = props
  return (
    <Wrap>
      <InputField {...rest} />
      <Button disabled={disableAction} type="primary" onClick={handleClick}>
        {icon}
      </Button>
    </Wrap>
  )
}

ActionableInput.defaultProps = {
  disableAction: false,
}

export default ActionableInput
