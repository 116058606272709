import moment from 'moment'
import get from 'lodash/get'
import { ADVISOR_PORTAL } from '../constants/site'
import { POLICY_ACTIVITY_ALL, POLICY_ACTIVITY_CLOSED } from '../constants/policies'

/**
 * Returns a list of Service history items
 * Records to display are controlled by masterdata
 * Description of each item is also defined in masterdata
 * Based on serviceStatus value passed, data is filtered for all, open or closed
 * Set is sorted based on creationDate
 * @param {*} serviceHistory Service history on a policy for a client
 * @param {*} masterData Sitecore masterdata object
 * @param {*} site Advisor or customer portal key
 * @param {*} serviceStatus ALL, OPEN or CLOS
 * @returns Array of sorted and filtered set of Service history records
 */
export const filterResults = (
  serviceHistory,
  policyActivities = [],
  site,
  serviceStatus = POLICY_ACTIVITY_ALL
) => {
  // fetch list of items to be displayed from masterdata based on portal type
  const masterDataKey = site === ADVISOR_PORTAL ? 'applyAP' : 'applyCP'
  const workItemsDisplayList = policyActivities.filter(i => Boolean(i[masterDataKey]))
  const workItemsInclusionList = workItemsDisplayList.map(({ code }) => code)

  return serviceHistory
    .filter(transaction => {
      if (serviceStatus === POLICY_ACTIVITY_ALL) {
        return true
      }
      if (serviceStatus === POLICY_ACTIVITY_CLOSED) {
        return transaction.status.toUpperCase() === POLICY_ACTIVITY_CLOSED
      }
      return transaction.status.toUpperCase() !== POLICY_ACTIVITY_CLOSED
    })
    .filter(({ workTypeCode }) => workItemsInclusionList.includes(workTypeCode))
    .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
    .map(({ policyId, bancsPolicyNo, status, workTypeCode, creationDate }) => ({
      policyNumber: policyId || bancsPolicyNo,
      status,
      requestType: get(
        workItemsDisplayList.find(({ code }) => code.toUpperCase() === workTypeCode.toUpperCase()),
        'value',
        ''
      ),
      createdDate: moment(creationDate).format('DD/MM/YYYY'),
      creationDate,
      closedDate: '',
    }))
}

// returns service history transactions based on the status shared
export const formatResults = (data, policyActivities, site, serviceStatus) => {
  const serviceHistory = []
  let policyId = ''
  data.forEach(element => {
    const transactions = get(element, 'policy.serviceHistory.transactions', [])
    const { identifiers } = get(element, 'policy', {})
    const policyIdIndex = identifiers
      ? identifiers.findIndex(item => item.type === 'POLICY_ID')
      : -1
    policyId = policyIdIndex !== -1 ? identifiers[policyIdIndex].value : element.bancsPolicyNo
    if (transactions.length) {
      transactions.forEach(transaction => {
        serviceHistory.push({ ...transaction, policyId })
      })
    }
  })
  return filterResults(serviceHistory, policyActivities, site, serviceStatus)
}
