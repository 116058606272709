import { inputPadding, colours, fontSize, fontWeights, space, pxToRem } from '../../../styles'

const styles = {
  label: theme => ({
    color: theme.text,
    width: '100%',
    display: 'block',
    fontSize: fontSize.xxs,
    lineHeight: '16px',
    marginBottom: '4px',
  }),
  input: (theme, value, prefix, suffix, noBorder) => ({
    fontSize: fontSize.xs,
    fontWeight: fontWeights.normal,
    color: colours.darkestGrey,
    // we do this as all fonts sizes are set to rems and we need 2 pixels less in this one case.
    lineHeight: pxToRem(22),
    paddingLeft: inputPadding.sm.horizontal + space(prefix.length ? 1 : 0),
    paddingRight: inputPadding.sm.horizontal + space(suffix.length ? 1 : 0),
    paddingTop: inputPadding.sm.vertical,
    paddingBottom: inputPadding.sm.vertical,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    width: '100%',
    backgroundColor: theme.bg,
    borderWidth: noBorder ? '0px' : '1px',
    borderStyle: 'solid',
    borderColor: value ? theme.active.border : theme.border,
    textDecoration: 'none',
    boxSizing: 'border-box',

    '&::placeholder': {
      color: theme.default,
    },
    '&:hover:not(:disabled)': {
      borderColor: theme.hover.border,
    },
    '&:focus:not(:disabled)': {
      outline: '-webkit-focus-ring-color auto 5px',
      borderColor: theme.active.border,
    },
  }),
  unEditable: {
    border: 0,
    paddingLeft: 0,
  },
  caption: {
    marginLeft: space(1),
    marginRight: space(1),
    '& a': {
      color: colours.mediumGreen,
    },
  },
  error: {
    backgroundColor: colours.white,
    borderColor: colours.red,
    color: colours.red,
    '&::placeholder': {
      color: colours.red,
    },
    '&:hover:not(:disabled)': {
      color: colours.red,
      borderColor: colours.red,
    },
  },
  disabled: {
    borderColor: colours.lightestGrey,
    color: colours.lightestGrey,
    cursorPointer: 'default',
    backgroundColor: colours.white,
    '&::placeholder': {
      color: colours.lightestGrey,
    },
  },
  wrap: {
    position: 'relative',
    width: '100%',
  },
  prefix: {
    paddingTop: inputPadding.sm.vertical,
    paddingBottom: inputPadding.sm.vertical,
    position: 'absolute',
    left: space(1),
    top: 0,
    lineHeight: pxToRem(22),
  },
  suffix: {
    paddingTop: inputPadding.sm.vertical,
    paddingBottom: inputPadding.sm.vertical,
    position: 'absolute',
    right: space(1),
    top: 0,
    lineHeight: pxToRem(22),
  },
  transparent: {
    background: 'transparent',
    borderWidth: 0,
    padding: 0,
    color: colours.white,
    fontSize: fontSize.md,
  },
  ieNinelabel: {
    color: colours.lightestGrey,
    position: 'absolute',
    cursor: 'text',
    width: '100%',
    height: '100%',
    padding: space(1),
  },
}

export default styles
