import {
  required,
  optional,
  accountNo,
  accountName,
  accountNumberWithNotAllZero,
} from '../../../../../../utils/formUtils'
import { PAYMENT_DRAW_DATE } from '../../../../../../constants/forms'

export const FORM_ID = 'directDebit'

const schema = ({ fields }) => ({
  accountName: {
    condition: [
      {
        condition: required,
        errorMsg: fields.addPayerDirectDebitAccountNameError.value,
      },
      {
        condition: accountName,
        errorMsg: fields.addPayerDirectDebitInvalidAccountNameError.value,
      },
    ],
    tooltip: '',
  },
  accountNumber: {
    condition: [
      {
        condition: required,
        errorMsg: fields.addPayerDirectDebitAccountNumberError.value,
      },
      {
        condition: accountNo,
        errorMsg: fields.addPayerDirectDebitInvalidAccountNumberError.value,
      },
      {
        condition: accountNumberWithNotAllZero,
        errorMsg: fields.AddPayerDirectDebitAllZeroAccountNumberError.value,
      },
    ],

    tooltip: '',
  },
  directDebitBsb: {
    condition: required,
    errorMsg: fields.addPayerDirectDebitBSBError.value,
    tooltip: '',
  },
  paymentDrawDate: {
    condition: optional,
    options: PAYMENT_DRAW_DATE,
  },
  termsAndConditions: {
    condition: [required, false],
    errorMsg: fields.addPayerDirectDebitTermsError.value,
    tooltip: '',
  },
})

export default schema
