import { space, colours } from '../../../../../../styles'

const styles = {
  row: {
    display: 'flex',
  },
  halfRow: {
    display: 'flex',
    width: '50%',
  },
  leftCol: {
    flexGrow: 1,
    marginRight: space(2),
  },
  rightCol: {
    flexGrow: 1,
  },
  abn: {
    flexGrow: 1,
    marginRight: space(1),
  },
  formButton: {
    marginTop: space(3),
  },
  formTitle: {
    color: colours.black,
  },
}

export default styles
