import moment from 'moment'

export const FORM_ID = 'existingCover'

export const defaults = () => ({
  consent: {
    name: 'EXISTING_COVER',
    value: '',
  },
  existingInsurances: [],
})

export const yearPast = value => {
  const currentDate = moment()
  const givenDate = moment(value, 'MM/YY', true)
  const isValidFormat = givenDate.isValid()
  if (givenDate.year() > currentDate.year()) {
    givenDate.set('year', givenDate.year() - 100)
  }
  return (
    !isValidFormat || currentDate.diff(givenDate) < 0 || currentDate.diff(givenDate, 'months') > 852
  )
}

export function checkPolicyDuplicacy(policyList, policyNo, insurer, policyInstanceNo) {
  return policyList.some(
    policyItem =>
      policyItem.policyInstanceNo.value !== policyInstanceNo &&
      policyItem.policyNo.value === policyNo &&
      policyItem.insurer.value.value === insurer.value
  )
}

export const MLC_INSURANCE = 'MLC Insurance'

const schema = () => ({
  consent: {},
  existingInsurances: {
    defaults: {
      insurer: { value: '' },
      policyNo: '',
      policyInstanceNo: '',
      benefits: [],
    },
  },
})

export default schema
