/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconGooglePlus32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 17.718h-2.359v2.423h-2.37v-2.423h-2.358v-2.436h2.359v-2.423h2.369v2.423H29v2.436zM11.272 25C6.7 25 3 21.197 3 16.5S6.7 8 11.272 8c2.234 0 4.096.834 5.54 2.226l-2.246 2.215c-.609-.603-1.681-1.31-3.294-1.31-2.822 0-5.124 2.4-5.124 5.37 0 2.968 2.302 5.368 5.124 5.368 3.272 0 4.502-2.423 4.694-3.664h-4.694v-2.923h7.809c.078.43.135.859.135 1.415 0 4.86-3.172 8.303-7.944 8.303z"
      fill="#000"
    />
  </Icon>
)

export default IconGooglePlus32
