// @flow
import get from 'lodash/get'

// components.
import RequestManualQuote from '../../CreateQuote/components/RequestManualQuote'
import SelectChangeType from '../../CreateQuote/components/RequestManualQuote/SelectChangeType'
import RequestAQuote from '../../CreateQuote/components/RequestManualQuote/RequestAQuote'
import SubmittedRequest from '../../CreateQuote/components/RequestManualQuote/SubmittedRequest'
import SelectQuoteType from '../../CreateQuote/components/RequestManualQuote/SelectQuoteType'

// actions
import { closeSidebar } from '../../../../actions/creators/sidebar'

const SIDEBAR_SCHEMA = (fields = {}) => [
  {
    sitecoreFieldHeadingKey: 'Header',
    component: RequestManualQuote,
    visible: false,
    callbackKey: 'selectLifeAssued',
    props: {
      selectLifeAssued: next => next(),
      secondaryHeader: true,
    },
  },
  {
    sitecoreFieldHeadingKey: 'Header',
    component: SelectChangeType,
    visible: false,
    callbackKey: 'selectQuoteType',
    props: {
      selectQuoteType: next => next(),
      secondaryHeader: true,
    },
  },
  {
    sitecoreFieldHeadingKey: 'Header',
    component: SelectQuoteType,
    visible: false,
    callbackKey: 'goToRequestAQuote',
    props: {
      goToRequestAQuote: next => next(),
      secondaryHeader: true,
    },
  },
  {
    sitecoreFieldHeadingKey: 'Header',
    component: RequestAQuote,
    visible: false,
    callbackKey: 'goToRequestManualQuote',
    props: {
      goToRequestManualQuote: next => next(),
      secondaryHeader: true,
    },
  },
  {
    sitecoreFieldHeadingKey: 'Header',
    component: SubmittedRequest,
    visible: false,
    props: {
      secondaryHeader: true,
      forcedClose: true,
    },
    nextText: get(fields, 'CloseBtn.value', 'Close'),
    handleNext: (next, dispatch) => {
      dispatch(closeSidebar())
    },
  },
]

export default SIDEBAR_SCHEMA
