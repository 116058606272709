import { RetentionDashboardObject } from './retentionDashboard'

// eslint-disable-next-line no-shadow
export enum RETENTION_CATEGORIES {
  POLICY_ANNIVERSARY = 'policy-anniversary',
  MISSED_PAYMENTS = 'missed-payment',
  LAPSED = 'lapsed',
  CANCELLED = 'cancelled',
}

export type DashboardDetail = {
  anniversary: RetentionDashboardObject
  riskOfLapse: RetentionDashboardObject
  lapsed: RetentionDashboardObject
  cancelled: RetentionDashboardObject
}

export type RetentionDashboardStoreState = {
  dashboardDetail: DashboardDetail
}
