/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChat16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.59 11H13a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v7h2v1.967L8.59 11zM2 16v-3a2 2 0 0 1-2-2V4a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H9l-7 3z"
      fill="#000"
    />
  </Icon>
)

export default IconChat16
