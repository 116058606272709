// @flow
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
// components.
import { Input, Button } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './searchBar.styles'

const Bar = styled('div')(({ inSidebar }) => styles.bar(inSidebar))
const Wrap = styled('div')(({ inSidebar }) => styles.wrap(inSidebar))
const SearchButton = styled(Button)(styles.button)
const SearchInput = styled(Input)(({ inSidebar }) => styles.input(inSidebar))

const SearchBar = props => {
  const { placeholder, title, value, clickHandler, changeHandler, inSidebar } = props
  const onChange = e => {
    changeHandler(e.value)
  }
  const onSubmit = event => {
    event.preventDefault()
    clickHandler()
  }

  return (
    <form id="searchBar" onSubmit={onSubmit}>
      <Bar inSidebar={inSidebar}>
        <Wrap inSidebar={inSidebar}>
          <SearchInput
            id="searchBar"
            testId="searchBar"
            name="searchBar"
            clearable
            changeHandler={onChange}
            value={value}
            inSidebar={inSidebar}
            placeholder={title || placeholder || undefined}
            noMargin
          />
        </Wrap>
        <SearchButton variant="primary" size="medium" onClick={clickHandler} inSidebar={inSidebar}>
          Search
        </SearchButton>
      </Bar>
    </form>
  )
}

SearchBar.propTypes = {
  // title of the serach bar.
  title: PropTypes.string,
  // searchbar input placeholder text.
  placeholder: PropTypes.string,
  // The text value of the serach input
  value: PropTypes.string,
  // function to fire when clicking search
  clickHandler: PropTypes.func,
  // function to fire when search value changes
  changeHandler: PropTypes.func,
  // used in sideBar or not
  inSidebar: PropTypes.bool,
}

SearchBar.defaultProps = {
  title: '',
  value: '',
  clickHandler: () => {},
  changeHandler: () => {},
  placeholder: '',
  inSidebar: false,
}

export default SearchBar
