// @flow
import get from 'lodash/get'

export const makeTimelineData = (field: Array<Object> = []): Array<Object> =>
  field.map(item => ({
    label: get(item, 'fields.value'),
    componentName: get(item, 'fields.code.value'),
  }))

/** Returns chip label from the sitecore masterdata lookup list: applicationStates */
const getChipLabel = (index, activeIndex, applicationStates) => {
  if (index === activeIndex)
    return get(
      applicationStates.find(appState => appState.code === 'InProgress'),
      'value',
      ''
    )
  if (index < activeIndex)
    return get(
      applicationStates.find(appState => appState.code === 'Completed'),
      'value',
      ''
    )
  return get(
    applicationStates.find(appState => appState.code === 'Pending'),
    'value',
    ''
  )
}

/** constructs data for progress timeline */
export const constructProgressTimelineData = (
  field: Array<Object> = [],
  activeIndex: number = 0,
  applicationStates: Array<Object> = []
): Array<Object> =>
  field.map((item, index) => ({
    progressLabel: get(item, 'fields.AppLevel'),
    chipLabel: getChipLabel(index, activeIndex, applicationStates),
  }))
