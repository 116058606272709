// @flow
import { routerReducer as routing } from 'react-router-redux'

// redux.
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// shared reducers.
import forms from './forms'
import navigation from './navigation'
import authentication from './authentication'
import okta from './okta'
import modal from './modal'
import masterList from './masterList'
import existingClient from './existingClient'
import timelineWithComponents from './timelineWithComponents'
import alterations from './alterations'

// this is a dummy import and the module will be replaced to spread a site
//  specific set of reducers. this is done in the webpack.common config
//  using NormalModuleReplacementPlugin
import siteReducers from './siteReducers'

const makeRootReducer = envConfig => (asyncReducers?: Object) =>
  combineReducers({
    forms: persistReducer(
      {
        key: 'forms',
        storage,
        whitelist: ['captureClient'],
      },
      forms
    ),
    modal,
    navigation,
    okta,
    routing,
    masterList,
    existingClient,
    viewBag: (state = {}) => state,
    authentication,
    timelineWithComponents,
    alterations,
    ...asyncReducers,
    ...siteReducers,
    config: (state = (envConfig && envConfig.config) || envConfig) => state,
  })
export default makeRootReducer
