/* eslint-disable max-len */
import React from 'react'

const IconTag32 = () => (
  <svg width="32" height="32" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4534 16.88L16.8934 26.44C16.6458 26.688 16.3517 26.8846 16.0279 27.0188C15.7042 27.153 15.3572 27.2221 15.0067 27.2221C14.6563 27.2221 14.3093 27.153 13.9856 27.0188C13.6618 26.8846 13.3677 26.688 13.1201 26.44L1.66675 15V1.66669H15.0001L26.4534 13.12C26.9501 13.6197 27.2289 14.2955 27.2289 15C27.2289 15.7045 26.9501 16.3804 26.4534 16.88V16.88Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33325 8.33331H8.34659"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconTag32
