// @flow

import React, { type Node } from 'react'
import styled from '@emotion/styled'

// styles.
import styles from './tab.styles'
import { themeConsumer, getThemeProp } from '../../../../../styles/ThemeContext'

type TabProps = {
  // Contents of the Tab
  children: Node,
  // Function to fire when tab is clicked
  clickHandler: Function,
  // The index of the tab
  index: Number,
  // The hash to append to the location path when clicked.
  hash?: String | null,
  // Set if the Tab is selected
  selected?: Boolean,
  // Set the size of the tab
  size?: 'lg' | 'sm',
  // set data locator
  dataLocator: string,
  // tab id
  id: string,
}

const El = styled('a')(styles.base, ({ selected, theme, size, disabled }) =>
  styles[size](theme, selected, disabled)
)

const Tab = (props: TabProps) => {
  const { children, clickHandler, index, hash, selected, size, dataLocator, disabled, id } = props

  const hashProp = hash.length
    ? {
        href: `#${hash}`,
      }
    : null

  return (
    <El
      {...getThemeProp(props)}
      {...hashProp}
      data-testid={`tab-${index}`}
      data-locator={dataLocator}
      size={size}
      selected={selected}
      disabled={disabled}
      onClick={() => clickHandler(index, id)}
    >
      {children}
    </El>
  )
}

Tab.defaultProps = {
  selected: false,
  size: 'lg',
  hash: null,
}

export default themeConsumer(Tab, 'tabBar')
