import { RequestManualQuoteProps } from '..'
import { getEscalationDueDate } from '../../../../../../utils/escalations'

// @flow
export const processAfterFetchedPolicies = (
  prevProps: RequestManualQuoteProps,
  thisProps: RequestManualQuoteProps
) => {
  const {
    alterations,
    actions: { getAlterationRules, getProductRules, getEscalation },
  } = thisProps

  if (
    prevProps.alterations.isFetchingPolicies &&
    !alterations.isFetchingPolicies &&
    alterations.policies.length
  ) {
    let isEscalationAPICall = false
    // make escalations api call
    alterations.policies.forEach(policyData => {
      const escalationDueDate = getEscalationDueDate(policyData.policy)

      if (escalationDueDate) {
        getEscalation({
          bancsPolicyNo: policyData.bancsPolicyNo,
          escalationDueDate,
        })
        isEscalationAPICall = true
      }
    })
    // If escalations API call is not made, directly call rules API
    if (!isEscalationAPICall) {
      // make rules API call
      getAlterationRules()
    }

    // product rules api call
    const { policies = [] } = alterations
    const uniqueProductIds = Array.from(new Set(policies.map(policy => policy.policy.productId)))
    uniqueProductIds.map(productId => getProductRules({ productId }))
  }
}
