// @flow
import React from 'react'
import styled from '@emotion/styled'

// utils
import { scrollToTop } from '../../../../../utils/formUtils'

// styles.
import styles from './loadMore.styles'

type LoadMoreProps = {
  // function
  onClick: Function,
  // load more text
  label: String,
  // check if more article are available
  hasMoreArticle: boolean,
  // for stoping scroll top when user click on button
  stopScrollToTop: boolean,
}

const LoadMoreWrap = styled('div')(({ isDisabled }) => styles.loadMoreWrap(isDisabled))
const LoadMoreText = styled('span')(({ isDisabled }) => styles.loadMoreText(isDisabled))

const LoadMore = (props: LoadMoreProps) => {
  const { label, onClick, hasMoreArticle, stopScrollToTop } = props

  const handleOnClick = () => {
    onClick()
    if (!stopScrollToTop) {
      scrollToTop(0)
    }
  }

  return (
    <LoadMoreWrap onClick={hasMoreArticle ? null : handleOnClick} isDisabled={hasMoreArticle}>
      <LoadMoreText isDisabled={hasMoreArticle}>{label}</LoadMoreText>
    </LoadMoreWrap>
  )
}

export default LoadMore
