// @flow
import React from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { Button, Chip, Variables, Label, SectionHeader } from '@mlcl-digital/mlcl-design'
import { useSelector } from 'react-redux'
import { object, arrayOf, shape, func, bool, string } from 'prop-types'
import { createEvent } from '../../../utils/telemetry'
// components
import { CardContainer, Card as NewCard } from '../../molecules/CardChildren'
import WithLoader from '../../molecules/WithLoader'
import ClientDetails from './components/ClientDetails'
import ContactDetails from './components/ContactDetails'
import DirectorDetails from './components/DirectorDetails'
import ActiveServiceRequestTable from '../../molecules/ActiveServiceRequestTable'
import { PolicyCard } from '../../molecules/PolicyCard'

// helpers.
import history from '../../../utils/browserHistory'
import { getFullName } from '../../../utils/contactUtils'

// styles
import styles from './policyDetails.styles'

// util
import { formatResults } from '../../../utils/serviceRequest'
import { getPaymentMethod, getPaymentCollectionMethod } from '../../../utils/paymentMethods'
import { getPolicyOwnerName } from '../../../utils/quoteUtils'
import { dollarAmountWithCommasAndTwoDecimal } from '../../../utils/formUtils'
import { getCollectionFrequency } from '../../../utils/paymentUtils'
import {
  checkIfValidBenefitStatusCode,
  getAllInForcePolicies,
  getAllOutOfForcePolicies,
  addOptimiserSuffixToBenefitName,
  sortLifeAssuredByPolicyOwner,
  getFullNameOfLifeAssuredWithInforceBenefitState,
  isBenefitAssuredForLifeInsured,
  getPolicyTypeLabel,
} from '../../../utils/policyUtils'
import { orderIPBenefits } from '../../../utils/extendedQuoteUtils'

// constants.
import { POLICY_DETAILS_PATH } from '../../../constants/adviser'
import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_IDENTIFIER_POLICY_ID,
  MLC_ACC,
  WRAPPER_NAVIGATOR_ID,
  POLICY_STATUS_NA,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  POLICY_ACTIVITY_OPEN,
} from '../../../constants/policies'
import { renderTextField } from '../../../utils/sitecoreUtils'
import { getSite } from '../../../selectors/common.selectors'

const ClientDetailsWrap = styled('div')(styles.clientDetailsWrap)
const Page = styled.div({
  paddingBottom: Variables.space(8, true),
})
const ActivityMessage1Label = styled(Label)(styles.label)
const ActiveRequestHeading = styled(SectionHeader)(styles.sectionHeader)

export const primaryAction = (clientId, bancsPolicyNo, isInForcePolicyItem) => {
  if (isInForcePolicyItem) {
    const tagEvent = createEvent({
      GA: {
        category: 'Client details',
        action: 'View policy',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Client details - View policy',
        },
      },
    })
    tagEvent.end()
  }
  history.push(`${POLICY_DETAILS_PATH}?clientId=${clientId}&policyId=${bancsPolicyNo}`)
}

const PolicyDetailsComponent = React.memo(props => {
  const policies = useSelector(state => state.policies)
  const quoteData = useSelector(state =>
    props.fromApplicationSummary ? state.applicationSummary : state.createQuote
  )
  const benefitStatus = useSelector(state => get(state.masterList, 'data.benefitStatus', []))
  const benefitList = useSelector(state => get(state.masterList, 'data.benefitList', []))
  const policyActivities = useSelector(state => get(state.masterList, 'data.policyActivities', []))
  const site = useSelector(getSite)
  const {
    fields,
    fromApplicationReview,
    fromApplicationSummary,
    policyStatus = [],
    clientId,
  } = props

  const renderOutOfPolicyItem = policy => {
    const { policy: { policyName, identifiers, status = '' } = {}, bancsPolicyNo } = policy
    const {
      // @FIXME: need to determine why prop types complaining here
      // eslint-disable-next-line react/prop-types
      fields: { policyDetailsSubHeading, policySectionViewPolicyButton },
    } = props

    const policyId = identifiers
      ? identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID).value
      : bancsPolicyNo

    const currentStatus = get(
      policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
      'value',
      POLICY_STATUS_NA
    )
    return (
      <NewCard
        key={policy.bancsPolicyNo}
        header={
          <>
            <Chip variant="important">{currentStatus}</Chip>
            <div>
              <span>{`${policyDetailsSubHeading}${policyId || ''}`}</span>
            </div>
            <div>{policyName || '-'}</div>
          </>
        }
        footer={
          <Button
            variant="secondary"
            size="small"
            onClick={() => primaryAction(clientId, bancsPolicyNo)}
          >
            {policySectionViewPolicyButton}
          </Button>
        }
      />
    )
  }

  const renderPaymentMethod = (policy, fromApplicationReviewOrSummary) => {
    if (fromApplicationReviewOrSummary) {
      // FIXME: These rules need to be updated. MM
      const { paymentInstruction = {}, productId } = policy
      const { collectionMethod = '' } = paymentInstruction
      if (collectionMethod === MLC_ACC) {
        return productId === POLICY_PRODUCT_CODE_SUPER
          ? getPaymentMethod(policy.fundPaymentMethod)
          : getPaymentMethod(WRAPPER_NAVIGATOR_ID)
      }
      return getPaymentMethod(collectionMethod)
    }
    const { premiumPaymentMethod, paymentDetails } = policy.policy
    return getPaymentCollectionMethod(premiumPaymentMethod, paymentDetails)
  }

  const renderPolicyItem = (policy, fromApplicationReviewOrSummary) => {
    const {
      policy: {
        policyName,
        productClass,
        relationships = [],
        identifiers,
        policyPremium,
        benefits = [],
        policyPremiumFrequency,
        paymentDetails = {},
        status = '',
      } = {},
      bancsPolicyNo,
      policyInstanceNo,
      covers = [],
      beneficiaries = [],
      policyCommencementDate,
    } = policy

    const { bancsCustomerNo } = policies
    const policyId = identifiers
      ? identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID).value
      : bancsPolicyNo

    const finalBenefits = orderIPBenefits(false, benefits, benefitList)

    const lifeAssureds = relationships.filter(
      relationship => relationship.roleCode === POLICY_RELATIONSHIPS_LIFEASSURED
    )
    const policyBenefits = fromApplicationReviewOrSummary ? covers : finalBenefits
    const {
      policySectionPaymentMethod,
      policySectionBenefitsHeader,
      policySectionSumInsuredHeader,
      policySectionLivesInsuredHeader,
      policyDetailsSubHeading,
      policySectionViewPolicyButton,
      policyOwner,
      policySectionBeneficiariesLabel,
      policySectionNoBeneficiaryMessage,
      policySectionInforceDateLabel,
    } = fields

    let mainHeadingText = policyName || '-'
    let subHeadingText = `${policyDetailsSubHeading}${policyId || ''}`
    let productClassName = productClass

    let flag = true
    if (fromApplicationReviewOrSummary) {
      mainHeadingText = policy.productName
      productClassName = policy.productClass
      subHeadingText = ''
    } else {
      const currentStatus = get(
        policyStatus.find(p => p.status.toUpperCase() === status.toUpperCase()),
        'value',
        POLICY_STATUS_NA
      )
      if (currentStatus.toUpperCase() !== POLICY_STATUS_NA) flag = false
    }

    const policyFields = {
      policySectionInforceDateLabel,
      policySectionViewPolicyButton,
      policySectionBenefitsHeader,
      policySectionSumInsuredHeader,
      policySectionBeneficiariesLabel,
      policySectionNoBeneficiaryMessage,
      policySectionLivesInsuredHeader,
      policySectionPaymentMethod,
    }
    const childProps = {
      policyType: getPolicyTypeLabel(productClassName),
      subHeadingText,
      mainHeadingText,
      inforceDate: policyCommencementDate
        ? moment(policyCommencementDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
        : undefined,
      onFooterAction: () => primaryAction(clientId, bancsPolicyNo, !fromApplicationReviewOrSummary),
      policySectionPaymentMethod,
      paymentMethod: renderPaymentMethod(policy, fromApplicationReviewOrSummary),
      collectionFrequency: `${getCollectionFrequency(
        get(paymentDetails, 'collectionFrequency', policyPremiumFrequency)
      )} Premium`,
      policyPremiumValue: dollarAmountWithCommasAndTwoDecimal(policyPremium),
      policyBenefitAmounts: policyBenefits
        .filter(
          benefit =>
            checkIfValidBenefitStatusCode(benefit.benefitStatusCode, benefitStatus) ||
            fromApplicationReviewOrSummary
        )
        .map((benefit, i) => ({
          benefitName: addOptimiserSuffixToBenefitName(benefit, false),
          benefitAmount: `${dollarAmountWithCommasAndTwoDecimal(
            benefit.sumAssured || benefit.coverAmount
          )} ${
            INCOME_PROTECTION_RELATED_POLICY_BENEFITS.includes(benefit.type) ? 'p/m' : ''
          }`.trim(),
          index: i,
        })),
      beneficiaries: beneficiaries.map(beneficiary => getFullName(beneficiary.relatedParty)),
      fromApplicationReviewOrSummary,
      policyPersonnel: [...lifeAssureds]
        .sort(lifeAssured => sortLifeAssuredByPolicyOwner(lifeAssured, bancsCustomerNo))
        .filter(
          lifeAssured =>
            !finalBenefits.some(
              benefit =>
                isBenefitAssuredForLifeInsured(benefit, lifeAssured.bancsCustomerNo) &&
                benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE
            )
        )
        .map(lifeAssured =>
          getFullNameOfLifeAssuredWithInforceBenefitState(lifeAssured, finalBenefits, policyStatus)
        ),
      policyOwner: (fromApplicationReviewOrSummary && policyOwner) || '',
      policyOwnerName:
        (fromApplicationReviewOrSummary &&
          getPolicyOwnerName(policy, POLICY_RELATIONSHIPS_OWNER)) ||
        '',
    }
    return (
      flag && (
        <PolicyCard {...childProps} key={bancsPolicyNo || policyInstanceNo} fields={policyFields} />
      )
    )
  }

  const renderClientDetails = () => {
    const {
      fields: { adviserPortalClientDetailsContactDetailsClientDetailsHeading },
      setActiveTab,
      clientDetails,
      clientDetailsFromPolicies,
      isOrg,
      isSMSF,
    } = props

    if (!clientDetails) return null

    return (
      <>
        <ClientDetailsWrap>
          {isOrg ? (
            <>
              <ContactDetails
                setActiveTab={setActiveTab}
                fields={fields}
                isSMSF={isSMSF}
                clientDetails={isSMSF ? clientDetailsFromPolicies : clientDetails}
              />
              <DirectorDetails fields={fields} clientDetails={clientDetailsFromPolicies} />
            </>
          ) : (
            <>
              <SectionHeader
                heading={adviserPortalClientDetailsContactDetailsClientDetailsHeading}
                subHeading=""
              />
              <ClientDetails fields={fields} clientDetails={clientDetails} />
            </>
          )}
        </ClientDetailsWrap>
      </>
    )
  }

  const renderPolicyHeaderSubText = () => {
    const {
      fields: { policySectionSubHeaderText, noActivePoliciesSubHeaderText = '' },
    } = props
    if (fromApplicationReview || fromApplicationSummary) return policySectionSubHeaderText
    if (getAllInForcePolicies(policies.data, policyStatus).length > 0)
      return policySectionSubHeaderText
    return noActivePoliciesSubHeaderText
  }

  const fromApplicationReviewOrSummary = Boolean(fromApplicationReview || fromApplicationSummary)
  const openRequest = policies
    ? formatResults(policies.data, policyActivities, site, POLICY_ACTIVITY_OPEN)
    : {}
  const isPoliciesLoading = fromApplicationReviewOrSummary
    ? false
    : get(policies, 'isLoading', false)
  if (!policies) return null
  const {
    policySectionHeaderText,
    activeRequestHeaderText,
    activeRequestSubHeaderText,
    outOfForcePolicyHeaderText,
    outOfForcePolicySubHeaderText,
    policyServiceRequestDateCreatedText,
    policyServiceRequestNameText,
    policyServiceRequestPolicyText,
    AppActivityMessage1,
  } = fields

  const inForcePolicySectionSubHeaderText = renderPolicyHeaderSubText()
  const inForcePolicies = getAllInForcePolicies(policies.data, policyStatus)
  const outOfForcePolicies = getAllOutOfForcePolicies(policies.data, policyStatus)
  return (
    <WithLoader isLoading={isPoliciesLoading} loaderProps={{ type: 'tab' }}>
      <Page>
        {!fromApplicationReviewOrSummary && (
          <SectionHeader
            heading={policySectionHeaderText}
            subHeading={inForcePolicySectionSubHeaderText}
          />
        )}
        <CardContainer>
          {(fromApplicationReviewOrSummary
            ? quoteData.quotes[quoteData.activeIndex].policyStructure
            : inForcePolicies
          ).map(dataItem => renderPolicyItem(dataItem, fromApplicationReviewOrSummary))}
        </CardContainer>
        {renderClientDetails()}
        {Boolean(openRequest && openRequest.length) && (
          <>
            <ActiveRequestHeading
              heading={activeRequestHeaderText}
              subHeading={activeRequestSubHeaderText}
              SectionMarginBottom={openRequest.length === 0}
            />
            <ActivityMessage1Label size="lg">
              {renderTextField(AppActivityMessage1, true)}
            </ActivityMessage1Label>
            <ActiveServiceRequestTable
              openRequests={openRequest}
              fields={{
                policyServiceRequestDateCreatedText,
                policyServiceRequestNameText,
                policyServiceRequestPolicyText,
              }}
            />
          </>
        )}
        {Boolean(!fromApplicationReviewOrSummary && outOfForcePolicies.length > 0) && (
          <>
            <SectionHeader
              heading={outOfForcePolicyHeaderText}
              subHeading={outOfForcePolicySubHeaderText}
            />
            <CardContainer>
              {outOfForcePolicies.map(policy => renderOutOfPolicyItem(policy))}
            </CardContainer>
          </>
        )}
      </Page>
    </WithLoader>
  )
})

PolicyDetailsComponent.defaultProps = {
  fromApplicationReview: false,
  fromApplicationSummary: false,
  policyStatus: [],
  setActiveTab: () => {},
  isOrg: false,
  isSMSF: false,
}

PolicyDetailsComponent.propTypes = {
  // fields from sitecore
  fields: shape({
    policySectionPaymentMethod: string,
    policySectionBenefitsHeader: string,
    policySectionSumInsuredHeader: string,
    policySectionLivesInsuredHeader: string,
    policyDetailsSubHeading: string,
    policySectionViewPolicyButton: string,
    policyOwner: string,
    policySectionBeneficiariesLabel: string,
    policySectionNoBeneficiaryMessage: string,
    policySectionInforceDateLabel: string,
  }).isRequired,
  // client id of current selected client
  clientId: string,
  fromApplicationReview: bool,
  fromApplicationSummary: bool,
  setActiveTab: func,
  policyStatus: arrayOf(object),
  isOrg: bool,
  isSMSF: bool,
}

export default PolicyDetailsComponent
