/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconCalendar16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4v1h2V4h2v1h2V4h2v2H3V4h2zm0-2H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-2V1H9v1H7V1H5v1zm8 6H3v5h10V8zm-9 3V9h2v2H4zm3-2v2h2V9H7zm3 2V9h2v2h-2z"
      fill="#000"
    />
  </Icon>
)

export default IconCalendar16
