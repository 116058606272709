/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowRight32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M25.5 16.5l1.06 1.06 1.061-1.06-1.06-1.06L25.5 16.5zM15.44 8.56l9 9 2.12-2.12-9-9-2.12 2.12zm9 6.88l-9 9 2.12 2.12 9-9-2.12-2.12zM4 18h21.5v-3H4v3z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowRight32
