// @flow
import get from 'lodash/get'

export const PROJECTIONS_BENEFIT_FIELDS_MAPPING = {
  lcSumInsured: 'projectionsTableHeaderLifeCover',
  ciSumInsured: 'projectionsTableHeaderCriticalIllness',
  cicSumInsured: 'projectionsTableHeaderChildCriticalIllness',
  ipSumInsured: 'projectionsTableHeaderIncomeProtection',
  tpdSumInsured: 'projectionsTableHeaderTotalPermanentDisability',
  beSumInsured: 'projectionsTableHeaderBusinessExpense',
  hivhepSumInsured: 'projectionsTableHeaderHIV',
  adSumInsured: 'projectionsTableHeaderAccidentalBenefit',
}

export const sumInsuredBenefitToField = (benefitKey: string, fields: Object) =>
  get(fields, PROJECTIONS_BENEFIT_FIELDS_MAPPING[benefitKey], '')
