import { required } from '../../../../../utils/formUtils'

export const FORM_ID = 'selectSuper'

const schema = {
  paymentInstructions: {
    condition: required,
    tooltip: '',
  },
  fundDetails: {
    condition: required,
    tooltip: '',
  },
}
export default schema
