// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Label, FormBlock } from '@mlcl-digital/mlcl-design'

// components.
import Caption from '../../atoms/Caption'
import Toggle from './components/Toggle'

// helpers.
import { labelMaker } from '../../../utils/formUtils'
import { getClassnameProp } from '../../../utils/stylesUtils'

// styles.
import styles from './toggleGroup.styles'

// types.
import type { LabelSizes } from '../../../../flow-typed/types'

type ToggleGroupProps = {
  /** value for toggle group */
  htmlFor: string,
  /** Toggle list options */
  options: Array,
  /** Value of the name attribute */
  name: string,
  /** Sets whether the radio button is disabled or not */
  disabled?: boolean,
  /** Selected value of toggle list */
  value?: any,
  /** handles onchange event */
  handleChange: Function,
  /** an extra label for adding containing forms id for aria AA  */
  labelledby?: string,
  /** Label for toggle group */
  label: string,
  /** Variation of toggle item to use in list */
  variant?: 'default' | 'detailed' | 'tab' | 'icon-lg' | 'icon-sm' | 'circle' | 'mobileFullWidth',
  /** Set toggle group to error state */
  error?: boolean,
  /** Text to display in caption tag */
  caption?: string,
  /** Label size */
  labelSize?: LabelSizes,
}

const ToggleWrapper = styled('div')(({ count, gutter, variant }) => ({
  ...styles.wrapper(count, variant, gutter),
}))

const ToggleGroup = (props: ToggleGroupProps) => {
  const { htmlFor, error, isFontBold } = props
  const { label, options, value, labelledby, caption, variant, gutter, labelSize, ...rest } = props
  const ariaLabelledby = `${labelledby} ${labelMaker(htmlFor)}`
  return (
    <FormBlock hasMargin={!error} {...getClassnameProp(props)}>
      {label && (
        <Label size={labelSize} error={error} htmlFor={htmlFor} id={`${htmlFor}-label`}>
          {label}
        </Label>
      )}
      <ToggleWrapper
        count={options.length}
        id={htmlFor}
        aria-labelledby={ariaLabelledby}
        variant={variant}
      >
        {options.map(option => (
          <div key={`${option.label}-${option.value}`}>
            <Toggle
              key={`${option.label}-${option.value}`}
              {...option}
              {...rest}
              labelledby={ariaLabelledby}
              defaultChecked={option.selected || value === option.value}
              checked={option.selected || value === option.value}
              gutter={gutter}
              id={htmlFor}
              aria-labelledby={ariaLabelledby}
              variant={variant}
              disabled={option.disabled}
              isFontBold={isFontBold || false}
            />
          </div>
        ))}
      </ToggleWrapper>
      {caption && <Caption error={error}>{caption}</Caption>}
    </FormBlock>
  )
}

ToggleGroup.defaultProps = {
  value: '',
  labelledby: '',
  disabled: false,
  variant: 'default',
  error: false,
  caption: '',
  labelSize: 'lg',
}

export default ToggleGroup
