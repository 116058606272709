import { createSelector } from 'reselect'
import { Store } from '../types/store'
import { SHARE_AUTH1_WITH_GP, SHARE_AUTH2_WITH_GP } from '../constants/application'

const getConsents = (state: Store) =>
  state?.createQuote?.quotes[state?.createQuote?.activeIndex].consents

export const getHasMedicalAuthorityBeenCompleted = createSelector([getConsents], consents => {
  const MAOne = consents.find(consent => consent.name === SHARE_AUTH1_WITH_GP)
  const MATwo = consents.find(consent => consent.name === SHARE_AUTH2_WITH_GP)
  return MAOne?.value && typeof MATwo?.value !== 'undefined'
})

export const getMedicalAuthorityValues = createSelector([getConsents], consents => {
  const MAOne = consents.find(consent => consent.name === SHARE_AUTH1_WITH_GP)
  const MATwo = consents.find(consent => consent.name === SHARE_AUTH2_WITH_GP)
  return {
    MAOne,
    MATwo,
  }
})
