/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconPlus16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2a1 1 0 1 0-2 0v5H2a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0V9h5a1 1 0 1 0 0-2H9V2z"
      fill="#000"
    />
  </Icon>
)

export default IconPlus16
