// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../actions'

// utils
import { errorCheck } from '../../../../../../utils/formUtils'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

import { benefitList } from '../PreAssessmentTab'

// comnstants
import { LOADING_TYPES, LOADING_REASONS } from '../../../../../../constants/benefit'

// schema.
import SCHEMA, { FORM_ID } from '../AddLoading/addLoading.schema'

// components
import Select from '../../../../../atoms/Select'

import TabHeaderComponent from '../TabHeader'

// style
import styles from '../AddLoading/addLoading.styles'

// styled component
const ContentWrapper = styled('section')(styles.contentWrapper)
const InputWrapper = styled('div')(styles.inputWrapper)
const Dropdown = styled(Select)(styles.dropdown)
const LoadingType = styled('div')(styles.loadingType)
const LoadingAmount = styled('div')(styles.loadingAmount)

type loadingsProps = {
  actions: Object<Function>,
  loadings: Array,
  fields: Object,
  form: Object,
  activeEditIndex: string,
  umeBenefits: Object,
}

export class EditLoading extends Component<loadingsProps> {
  schemaWithAuthorableFields = SCHEMA()

  lastSelection = {}

  componentWillMount() {
    const { actions, loadings, activeEditIndex, umeBenefits } = this.props
    const { formInit } = actions

    if (activeEditIndex !== null) {
      const { benefit, reason, loadingValueType, loadingValue } = loadings[activeEditIndex]
      const schema = this.schemaWithAuthorableFields({
        benefit,
        reason: reason[0].reasonCode,
        type: loadingValueType,
        amount: loadingValue,
      })

      formInit(FORM_ID, schema, {
        benefit: {
          value: benefit,
          label: umeBenefits && umeBenefits.find(opt => opt.umeCode === benefit).loadingDisplayText,
        },
        loadingReason: {
          value: reason[0].reasonCode,
          label: LOADING_REASONS.find(
            opt => opt.value.toLowerCase() === reason[0].reasonCode.toLowerCase()
          ).label,
        },
        loadingType: {
          value: loadingValueType,
          label: LOADING_TYPES.find(
            opt => opt.value.toLowerCase() === loadingValueType.toLowerCase()
          ).label,
        },
        loadingAmount: {
          value: loadingValue,
          label: loadingValueType === 'PER' ? `${loadingValue}%` : `$${loadingValue}`,
        },
      })

      this.lastSelection = {
        benefit,
        reason: reason[0].reasonCode,
        type: loadingValueType,
        amount: loadingValue,
      }
    }
  }

  componentDidUpdate() {
    this.lastSelection = this.getCurrentFormData()
  }

  componentWillUnmount() {
    const {
      actions: { formInit, editLoading },
    } = this.props
    benefitList.map(benefit => {
      const { possibleCombination } = benefit
      possibleCombination.map(com => {
        if (com.status === 'draft') {
          // eslint-disable-next-line no-param-reassign
          com.status = 'new'
          // eslint-disable-next-line no-param-reassign
          com.selected = false
        }
        return com
      })
      return benefit
    })
    formInit(FORM_ID, this.schemaWithAuthorableFields({}), {})
    editLoading(null)
  }

  getCurrentFormData() {
    const { form } = this.props
    const { benefit, loadingReason, loadingType, loadingAmount } = form.fields
    return {
      benefit: benefit.value ? benefit.value.value : '',
      reason: loadingReason.value ? loadingReason.value.value : '',
      type: loadingType.value ? loadingType.value.value : '',
      amount: loadingAmount.value ? loadingAmount.value.value : '',
    }
  }

  handleChange = ({ value, name }) => {
    const { actions, form } = this.props
    const { formUpdateField, formValidate } = actions

    if (benefitList[0] && form.fields[name].value !== value.value) {
      benefitList
        .find(item => item.umeCode === this.lastSelection.benefit)
        .possibleCombination.map(combination => {
          const { reason, type } = combination
          if (reason === this.lastSelection.reason && type === this.lastSelection.type) {
            // eslint-disable-next-line no-param-reassign
            combination.status = 'draft'
          }
          return combination
        })

      const schema = this.schemaWithAuthorableFields(this.getCurrentFormData())

      const data = {
        error: errorCheck(value, schema[name].condition, null),
        value,
      }
      formUpdateField(FORM_ID, name, data)

      if (name === 'benefit') {
        formUpdateField(FORM_ID, 'loadingReason', { error: false, value: '' })
        formUpdateField(FORM_ID, 'loadingType', { error: false, value: '' })
        formUpdateField(FORM_ID, 'loadingAmount', { error: false, value: '' })
      }

      if (name === 'loadingReason') {
        formUpdateField(FORM_ID, 'loadingType', { error: false, value: '' })
        formUpdateField(FORM_ID, 'loadingAmount', { error: false, value: '' })
      }

      if (name === 'loadingType')
        formUpdateField(FORM_ID, 'loadingAmount', { error: false, value: '' })

      formValidate(FORM_ID, schema)
    }
  }

  render() {
    const { fields, form, activeEditIndex } = this.props
    if (!form || activeEditIndex === null) return null
    const { benefit, loadingReason, loadingType, loadingAmount } = form.fields

    const schema = this.schemaWithAuthorableFields(this.getCurrentFormData(), this.lastSelection)

    const {
      addLoadingBenefitLabel,
      addLoadingBenefitPlaceholder,
      addLoadingBenefitError,
      addLoadingReasonLabel,
      addLoadingReasonPlaceholder,
      addLoadingReasonError,
      addLoadingTypeLabel,
      addLoadingTypePlaceholder,
      addLoadingTypeError,
      addLoadingAmountLabel,
      addLoadingAmountPlaceholder,
      addLoadingAmountError,
    } = fields

    return (
      <Fragment>
        <TabHeaderComponent activeTab={2} fields={fields} />
        <ContentWrapper>
          <InputWrapper>
            <Dropdown
              label={renderTextField(addLoadingBenefitLabel)}
              placeholder={renderTextField(addLoadingBenefitPlaceholder)}
              value={benefit.value}
              name="benefit"
              id="benefit"
              error={benefit.error.error}
              caption={benefit.error.error && renderTextField(addLoadingBenefitError)}
              changeHandler={this.handleChange}
              options={schema.benefit.options}
              size="lg"
            />
          </InputWrapper>
          <InputWrapper>
            <Dropdown
              label={renderTextField(addLoadingReasonLabel)}
              placeholder={renderTextField(addLoadingReasonPlaceholder)}
              value={loadingReason.value}
              name="loadingReason"
              id="loadingReason"
              error={loadingReason.error.error}
              caption={loadingReason.error.error && renderTextField(addLoadingReasonError)}
              changeHandler={this.handleChange}
              options={schema.loadingReason.options}
              size="lg"
              disabled={!benefit.value}
            />
          </InputWrapper>
          <InputWrapper>
            <LoadingType>
              <Dropdown
                label={renderTextField(addLoadingTypeLabel)}
                placeholder={renderTextField(addLoadingTypePlaceholder)}
                value={loadingType.value}
                name="loadingType"
                id="loadingType"
                error={loadingType.error.error}
                caption={loadingType.error.error && renderTextField(addLoadingTypeError)}
                changeHandler={this.handleChange}
                options={schema.loadingType.options}
                size="lg"
                disabled={!loadingReason.value}
              />
            </LoadingType>
            <LoadingAmount>
              <Dropdown
                label={renderTextField(addLoadingAmountLabel)}
                placeholder={renderTextField(addLoadingAmountPlaceholder)}
                value={loadingAmount.value}
                name="loadingAmount"
                id="loadingAmount"
                error={loadingAmount.error.error}
                caption={loadingAmount.error.error && renderTextField(addLoadingAmountError)}
                changeHandler={this.handleChange}
                options={schema.loadingAmount.options}
                size="lg"
                disabled={!loadingType.value}
              />
            </LoadingAmount>
          </InputWrapper>
        </ContentWrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  preAssessment: { loadings, activeEditIndex },
  ure: { initialData },
}) => ({
  form: forms[FORM_ID],
  loadings,
  activeEditIndex,
  umeBenefits: initialData.umeBenefits,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditLoading)
