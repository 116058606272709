/* eslint-disable no-unused-vars */
// @flow
import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

type ScrollToTopProps = {
  // Component.
  children: Object,
  // The pathname for the component rendered.
  pathname: Node,
}

const ScrollToTop = (props: ScrollToTopProps) => {
  const {
    children,
    location: { pathname },
  } = props
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [pathname])
  return children
}

export default withRouter(ScrollToTop)
