// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { A11yLabel, Checkbox, Input, Select } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

import AddressLookUp from '../../../../molecules/AddressLookUp'
import InputPhone from '../../../../molecules/InputPhone'

// styles.
import styles from './policyOwnerDetails.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

// schema.
import SCHEMA from './policyOwnerDetails.schema'

// helpers.
import { errorCheck, generateFieldsFromData, getValue } from '../../../../../utils/formUtils'

import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'

// form default Value
import paymentDetailsSchema, {
  FORM_ID as paymentDetailsSchemaFormID,
} from '../PaymentDetails/paymentDetails.schema'
import { initForms } from './memberInitUtils'
import { getActivePolicy } from '../../../../../utils/quoteUtils'
import { getActivePaymentDetailsForm } from '../../../../../utils/paymentUtils'
import { directDebitData } from '../PaymentDetails/DirectDebit/directDebitInItUtils'
import { creditCardData } from '../PaymentDetails/CreditCard/creditCardInItUtils'
import { linkedMasterKeyInitData } from '../PaymentDetails/LinkedMasterKey/linkedMasterKeyUtil'
import { wrapSupeSmfInitData } from '../PaymentDetails/WrapSuperSmf/wrapSuperSmfUtil'
import { rolloverPaymentData } from '../PaymentDetails/RolloverPaymentMethod/rolloverPaymentMethodUtil'

import {
  WRAP,
  ELIGIBLE_MLC_ACCOUNT,
  ROLLOVER_FROM_EXTERNAL_FUND,
  MASTER_KEY_ACCOUNT,
} from '../../../../../constants/customerPaymentDetails'

import { POLICY_PRODUCT_CODE_SUPER } from '../../../../../constants/policies'

type PolicyOwnerDetailsProps = {
  // create Quote from store
  createQuote: Object,
  // sidebar data
  sidebar: Object,
  // formId if form
  formId: string,
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  // form sub-store
  paymentDetailsForm: Object,
  // Sitecore authorable fields
  fields: Object,
  // Address lookup manual entry redux state mapped to props.
  isManualPostal: boolean,
  isManualResidential: boolean,
  forms: object,
  captureCreditCard: Object,
}

type PolicyOwnerDetailsStates = {
  // mode of Policy Owner
  listMode: boolean,
}

export const RESIDENTIAL_ADDRESS = [
  'residentialAddress',
  'residentialAddressStreet',
  'residentialAddressHouseNo',
  'residentialAddressLocality',
  'residentialAddressState',
  'residentialAddressPostCode',
]
export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalAddressStreet',
  'postalAddressHouseNo',
  'postalAddressLocality',
  'postalAddressState',
  'postalAddressPostCode',
]

const FullWidthInput = ignoreColumns(Input)
const HalfWidthInput = styled(Input)(styles.halfWidthInput)
const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const FullWidthCheckbox = ignoreColumns(Checkbox)
export const ContactToggle = ignoreColumns(styled('label')(styles.contactToggle))

const Form = styled('form')(styles.base)
const Wrapper = styled('div')()
const TitleSelect = styled(Select)(styles.title)
const PostalAddressCheckbox = styled(FullWidthCheckbox)(styles.postalAddressCheckbox)
const SecondaryContactNoCheck = styled('input')(styles.secondaryContactCheckbox)
export class PolicyOwnerDetails extends Component<
  PolicyOwnerDetailsProps,
  PolicyOwnerDetailsStates
> {
  componentWillMount() {
    const {
      actions,
      isManualPostal,
      isManualResidential,
      createQuote,
      createQuote: { quotes, activeIndex },
      fields,
      form,
      forms,
      sidebar,
      paymentDetailsForm,
      formId,
      captureCreditCard,
    } = this.props
    const { formInit, setPanelProps, setNextTabPanel } = actions

    const { policyStructure } = quotes[activeIndex]
    const activePolicyInstanceNo = getActivePolicy(sidebar)
    const isAddPayer = !!(sidebar.schemaName === 'addPayer')
    const activePolicy = policyStructure.find(
      policy => policy.policyInstanceNo === activePolicyInstanceNo
    )

    const schema = SCHEMA(fields)({ isManualPostal, isManualResidential, isAddPayer })
    const data = initForms(this.props)
    const paymentDetailsData = {
      paymentDetails: get(
        activePolicy,
        'paymentInstruction.collectionMethod',
        get(paymentDetailsForm, 'fields.paymentDetails.value', '')
      ),
    }
    if (get(sidebar, 'panelProps.moveDefaultToPayment')) {
      setNextTabPanel(1)
      setPanelProps({ moveDefaultToPayment: false })
    }

    if (!paymentDetailsForm) {
      formInit(paymentDetailsSchemaFormID, paymentDetailsSchema, paymentDetailsData)
      const eligiblePaymentType =
        activePolicy.productId === POLICY_PRODUCT_CODE_SUPER ? activePolicy.fundPaymentMethod : WRAP
      const paymentType =
        get(paymentDetailsData, 'paymentDetails') === ELIGIBLE_MLC_ACCOUNT
          ? eligiblePaymentType
          : get(paymentDetailsData, 'paymentDetails')

      const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
        paymentType,
        { fields },
        captureCreditCard.displayCreditCardListing
      )
      // @FIXME: I think this is all dead code, from my testing
      // paymentType always evaluates to an empty string and only triggers the default case
      switch (paymentType) {
        case 'DD':
          formInit(
            activeFormId,
            activeSchema,
            directDebitData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        case 'CC':
          formInit(
            activeFormId,
            activeSchema,
            creditCardData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        case WRAP:
          formInit(
            activeFormId,
            activeSchema,
            wrapSupeSmfInitData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        case MASTER_KEY_ACCOUNT:
          formInit(
            activeFormId,
            activeSchema,
            linkedMasterKeyInitData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        case ROLLOVER_FROM_EXTERNAL_FUND:
          formInit(
            activeFormId,
            activeSchema,
            rolloverPaymentData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        default:
          break
      }
    }
    if (!form) formInit(formId, schema, data)
  }

  addressToggleHandler =
    key =>
    (isManual: boolean): void => {
      const {
        actions: { formResetField },
        formId,
      } = this.props
      // We clear address fields when entering manual mode
      if (isManual) {
        formResetField(formId, key === 'isManualPostal' ? POSTAL_ADDRESS : RESIDENTIAL_ADDRESS)
      }
    }

  togglePostalAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      fields,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
      formId,
      isManualPostal,
      isManualResidential,
      sidebar,
    } = this.props
    const isAddPayer = !!(sidebar.schemaName === 'addPayer')
    const schema = SCHEMA(fields)({
      isManualPostal,
      isManualResidential,
      postalHidden,
      isAddPayer,
    })
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition),
      value,
    }
    formUpdateField(formId, name, data)
    formValidate(formId, schema)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { form, fields, actions, isManualPostal, isManualResidential, formId, sidebar } =
      this.props
    const isAddPayer = !!(sidebar.schemaName === 'addPayer')
    const { formUpdateField, formValidate } = actions
    const hasSecondaryContact = form.fields.secondaryContactNoCheck
    const schema = SCHEMA(fields)({
      hasSecondaryContact,
      isManualPostal,
      isManualResidential,
      isAddPayer,
    })
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(value, schema[name] && schema[name].condition, null),
      value,
    }
    formUpdateField(formId, name, data)
    if (name === 'deliveryMethod' && value.value === 'P') {
      formUpdateField(formId, 'email', {
        error: errorCheck(form.fields.email.value, schema.email && schema.email.condition, null),
        value: form.fields.email.value,
      })
    }
    formValidate(formId, schema)
  }

  addressChange = address => {
    const { name, data, value } = address
    const {
      actions,
      isManualPostal,
      isManualResidential,
      fields,
      formId,
      sidebar,
      form: {
        fields: {
          isPostalResidentialAddressSame: { value: postalHidden },
        },
      },
    } = this.props
    const isAddPayer = !!(sidebar.schemaName === 'addPayer')
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = SCHEMA(fields)({ isManualPostal, isManualResidential, postalHidden, isAddPayer })
    const field = {
      error: errorCheck(
        value,
        schema[name] && schema[name].condition,
        schema[name] && schema[name].errorMsg
      ),
      value: getValue(value),
    }
    formUpdateField(formId, name, field)
    formUpdate(formId, generateFieldsFromData(data))
    formValidate(formId, schema)
  }

  secondaryNumberCheckbox = event => {
    const { target } = event
    const { name, checked } = target
    this.handleChange({
      name,
      value: checked,
    })
    this.handleChange({
      name: 'secondaryNumber',
      value: '',
    })
  }

  render() {
    const { fields, form, isManualPostal, isManualResidential, formId, sidebar } = this.props
    const isAddPayer = !!(sidebar.schemaName === 'addPayer')
    if (!form) return null

    const {
      title,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      email,
      // Address fields
      residentialAddress,
      residentialHouseNo,
      residentialStreet,
      residentialLocality,
      residentialState,
      residentialCountry,
      residentialPostCode,
      isPostalResidentialAddressSame,
      postalAddress,
      postalHouseNo,
      postalStreet,
      postalLocality,
      postalState,
      postalCountry,
      postalPostCode,
      contactNumber,
      secondaryNumber,
      secondaryContactNoCheck,
      contactNumberPhoneCode,
      secondaryNumberPhoneCode,
    } = form.fields

    const {
      addIndividualHeaderLabel,
      policyOwnerDetailsTitleFieldLabel,
      policyOwnerDetailsTitleFieldPlaceholder,
      policyOwnerDetailsTitleFieldError,
      policyOwnerDetailsFirstNameFieldLabel,
      policyOwnerDetailsFirstNameFieldPlaceholder,
      policyOwnerDetailsMiddleNameFieldLabel,
      policyOwnerDetailsMiddleNameFieldPlaceholder,
      policyOwnerDetailsLastNameFieldLabel,
      policyOwnerDetailsLastNameFieldPlaceholder,
      policyOwnerDetailsDateOfBirthFieldLabel,
      policyOwnerDetailsDateOfBirthFieldPlaceholder,
      policyOwnerDetailsEmailFieldLabel,
      policyOwnerDetailsEmailFieldPlaceholder,
      policyOwnerDetailsEmailFieldError,
      policyOwnerDetailsPrimaryContactFieldLabel,
      policyOwnerDetailsPrimaryContactFieldPlaceholder,
      policyOwnerDetailsPrimaryContactFieldError,
      policyOwnerDetailsSecodaryContactFieldLabel,
      policyOwnerDetailsSecondaryContactFieldPlaceholder,
      policyOwnerDetailsSecondaryContactFieldError,
      policyOwnerDetailsAdditionalContactToggleText,
      policyOwnerDetailsRemoveContactToggleText,
      // Residential Address
      policyOwnerDetailsResidentialAddressToggleToManualText,
      PolicyOwnerDetailsIndividualResidentialAddressFieldLabel,
      PolicyOwnerDetailsIndividualResidentialAddressFieldPlaceholder,
      PolicyOwnerDetailsIndividualResidentialAddressFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldLabel,
      policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
      policyOwnerDetailsResidentialAddressHouseNoFieldLabel,
      policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
      policyOwnerDetailsResidentialAddressLocalityFieldLabel,
      policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
      policyOwnerDetailsResidentialAddressStateFieldLabel,
      policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
      policyOwnerDetailsResidentialAddressCountryFieldLabel,
      policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
      policyOwnerDetailsResidentialAddressPostCodeFieldLabel,
      policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
      policyOwnerDetailsResidentialAddressToggleToAutoText,
      // Postal Address
      policyOwnerDetailsPostalAddressUseAsPostal,
      policyOwnerDetailsPostalAddressToggleToManualText,
      policyOwnerDetailsPostalAddressFieldLabel,
      policyOwnerDetailsPostalAddressFieldPlaceholder,
      policyOwnerDetailsPostalAddressFieldError,
      policyOwnerDetailsPostalAddressStreetFieldLabel,
      policyOwnerDetailsPostalAddressStreetFieldPlaceholder,
      policyOwnerDetailsPostalAddressHouseNoFieldLabel,
      policyOwnerDetailsPostalAddressHouseNoFieldPlaceholder,
      policyOwnerDetailsPostalAddressLocalityFieldLabel,
      policyOwnerDetailsPostalAddressLocalityFieldPlaceholder,
      policyOwnerDetailsPostalAddressStateFieldLabel,
      policyOwnerDetailsPostalAddressStateFieldPlaceholder,
      policyOwnerDetailsPostalAddressCountryFieldLabel,
      policyOwnerDetailsPostalAddressCountryFieldPlaceholder,
      policyOwnerDetailsPostalAddressCountryFieldError,
      policyOwnerDetailsPostalAddressPostCodeFieldLabel,
      policyOwnerDetailsPostalAddressPostCodeFieldPlaceholder,
      policyOwnerDetailsPostalAddressToggleToAutoText,
      phoneCodeLabel,
    } = reduceAuthorableFields(fields)

    const hasSecondaryContact = secondaryContactNoCheck && secondaryContactNoCheck.value
    const schema = SCHEMA(fields)({ isManualPostal, isManualResidential, isAddPayer })

    const selectContactNumberCode = {
      label: phoneCodeLabel,
      value: contactNumberPhoneCode.value,
      name: 'contactNumberPhoneCode',
      id: 'contactNumberPhoneCode',
      changeHandler: this.handleChange,
    }
    const inputContactNumber = {
      htmlFor: 'contactNumber',
      name: 'contactNumber',
      label: policyOwnerDetailsPrimaryContactFieldLabel,
      changeHandler: this.handleChange,
      placeholder: policyOwnerDetailsPrimaryContactFieldPlaceholder,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && policyOwnerDetailsPrimaryContactFieldError,
      value: contactNumber.value,
    }
    const inputSecondaryNumber = {
      htmlFor: 'secondaryNumber',
      name: 'secondaryNumber',
      label: policyOwnerDetailsSecodaryContactFieldLabel,
      changeHandler: this.handleChange,
      placeholder: policyOwnerDetailsSecondaryContactFieldPlaceholder,
      error: secondaryNumber.error.error,
      caption: secondaryNumber.error.error && policyOwnerDetailsSecondaryContactFieldError,
      value: secondaryNumber.value,
    }
    const selectSecondaryNumberCode = {
      label: phoneCodeLabel,
      value: secondaryNumberPhoneCode.value,
      name: 'secondaryNumberPhoneCode',
      id: 'secondaryNumberPhoneCode',
      changeHandler: this.handleChange,
    }

    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{addIndividualHeaderLabel}</A11yLabel>
          <Form id={formId} aria-labelledby="application-overview">
            <TitleSelect
              label={policyOwnerDetailsTitleFieldLabel}
              placeholder={policyOwnerDetailsTitleFieldPlaceholder}
              value={title.value}
              name="title"
              id="title"
              error={title.error.error}
              caption={title.error.error && policyOwnerDetailsTitleFieldError}
              changeHandler={this.handleChange}
              options={schema.title.options}
            />

            <HalfWidthInput
              htmlFor="firstName"
              name="firstName"
              label={policyOwnerDetailsFirstNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsFirstNameFieldPlaceholder}
              error={firstName.error.error}
              caption={firstName.error.error ? firstName.error.errorMsg : schema.firstName.toolTip}
              value={firstName.value}
            />

            <HalfWidthInput
              htmlFor="middleName"
              name="middleName"
              label={policyOwnerDetailsMiddleNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsMiddleNameFieldPlaceholder}
              value={middleName.value}
            />

            <FullWidthInput
              htmlFor="lastName"
              name="lastName"
              label={policyOwnerDetailsLastNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsLastNameFieldPlaceholder}
              error={lastName.error.error}
              caption={lastName.error.error ? lastName.error.errorMsg : schema.lastName.toolTip}
              value={lastName.value}
            />

            <HalfWidthInput
              htmlFor="dateOfBirth"
              name="dateOfBirth"
              options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
              label={policyOwnerDetailsDateOfBirthFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsDateOfBirthFieldPlaceholder}
              error={dateOfBirth.error.error}
              caption={dateOfBirth.error.error && dateOfBirth.error.errorMsg}
              value={dateOfBirth.value}
            />
            <FullWidthInput
              htmlFor="email"
              name="email"
              label={policyOwnerDetailsEmailFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsEmailFieldPlaceholder}
              error={email.error.error}
              caption={email.error.error && policyOwnerDetailsEmailFieldError}
              value={email.value}
            />

            <FullWidthAddressLookup
              isManual={
                !residentialAddress.value &&
                (residentialStreet.value ||
                  residentialPostCode.value ||
                  residentialHouseNo.value ||
                  residentialLocality.value ||
                  residentialState.value)
              }
              toggleHandler={this.addressToggleHandler('isManualResidential')}
              name="policyOwnerResidentialAddress"
              auto={{
                label: PolicyOwnerDetailsIndividualResidentialAddressFieldLabel,
                placeholder: PolicyOwnerDetailsIndividualResidentialAddressFieldPlaceholder,
                toggleLabel: policyOwnerDetailsResidentialAddressToggleToManualText,
                value: residentialAddress.value,
                selectChangeHandler: this.addressChange,
                addressError: residentialAddress.error.error,
                addressName: 'residentialAddress',
                addressErrorMessage:
                  residentialAddress.error.error &&
                  PolicyOwnerDetailsIndividualResidentialAddressFieldError,
              }}
              manual={{
                streetLabel: policyOwnerDetailsResidentialAddressStreetFieldLabel,
                streetPlaceholder: policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
                streetValue: residentialStreet.value,
                streetRequiredErrorMessage: residentialStreet.error.errorMsg,
                streetError: residentialStreet.error.error,
                streetName: 'residentialStreet',
                houseNoLabel: policyOwnerDetailsResidentialAddressHouseNoFieldLabel,
                houseNoPlaceholder: policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
                houseNoValue: residentialHouseNo.value,
                houseNoName: 'residentialHouseNo',
                houseNoError: residentialHouseNo.error.error,
                houseNoRequiredErrorMessage: residentialHouseNo.error.errorMsg,
                localityLabel: policyOwnerDetailsResidentialAddressLocalityFieldLabel,
                localityPlaceholder: policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
                localityLabelValue: residentialLocality.value,
                localityError: residentialLocality.error.error,
                localityRequiredErrorMessage: residentialLocality.error.errorMsg,
                localityName: 'residentialLocality',
                stateLabel: policyOwnerDetailsResidentialAddressStateFieldLabel,
                statePlaceholder: policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
                stateOptions: schema.residentialState.options,
                stateValue: residentialState.value,
                stateError: residentialState.error.error,
                stateRequiredErrorMessage: residentialState.error.errorMsg,
                stateName: 'residentialState',
                countryLabel: policyOwnerDetailsResidentialAddressCountryFieldLabel,
                countryPlaceholder: policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
                countryValue: residentialCountry.value,
                countryError: residentialCountry.error.error,
                countryRequiredErrorMessage: residentialCountry.error.errorMsg,
                countryName: 'residentialCountry',
                postCodeLabel: policyOwnerDetailsResidentialAddressPostCodeFieldLabel,
                postCodePlaceholder: policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
                postCodeValue: residentialPostCode.value,
                postCodeError: residentialPostCode.error.error,
                postCodeRequiredErrorMessage: residentialPostCode.error.errorMsg,
                postCodeName: 'residentialPostCode',
                manualToggleLabel: policyOwnerDetailsResidentialAddressToggleToAutoText,
                inputEntryHandler: this.handleChange,
              }}
            />

            <PostalAddressCheckbox
              text={policyOwnerDetailsPostalAddressUseAsPostal}
              name="isPostalResidentialAddressSame"
              htmlFor="isPostalResidentialAddressSame"
              onChangeHandler={this.togglePostalAddress}
              checked={isPostalResidentialAddressSame.value}
            />

            {!isPostalResidentialAddressSame.value && (
              <FullWidthAddressLookup
                isManual={
                  !postalAddress.value &&
                  (postalStreet.value ||
                    postalPostCode.value ||
                    postalHouseNo.value ||
                    postalLocality.value ||
                    postalState.value ||
                    postalCountry.value)
                }
                toggleHandler={this.addressToggleHandler('isManualPostal')}
                name="policyOwnerPostalAddress"
                auto={{
                  label: policyOwnerDetailsPostalAddressFieldLabel,
                  placeholder: policyOwnerDetailsPostalAddressFieldPlaceholder,
                  toggleLabel: policyOwnerDetailsPostalAddressToggleToManualText,
                  value: postalAddress.value,
                  selectChangeHandler: this.addressChange,
                  addressError: postalAddress.error.error,
                  addressName: 'postalAddress',
                  addressErrorMessage:
                    postalAddress.error.error && policyOwnerDetailsPostalAddressFieldError,
                }}
                manual={{
                  streetLabel: policyOwnerDetailsPostalAddressStreetFieldLabel,
                  streetPlaceholder: policyOwnerDetailsPostalAddressStreetFieldPlaceholder,
                  streetValue: postalStreet.value,
                  streetRequiredErrorMessage: postalStreet.error.errorMsg,
                  streetError: postalStreet.error.error,
                  streetName: 'postalStreet',
                  houseNoLabel: policyOwnerDetailsPostalAddressHouseNoFieldLabel,
                  houseNoPlaceholder: policyOwnerDetailsPostalAddressHouseNoFieldPlaceholder,
                  houseNoValue: postalHouseNo.value,
                  houseNoName: 'postalHouseNo',
                  localityLabel: policyOwnerDetailsPostalAddressLocalityFieldLabel,
                  localityPlaceholder: policyOwnerDetailsPostalAddressLocalityFieldPlaceholder,
                  localityLabelValue: postalLocality.value,
                  localityError: postalLocality.error.error,
                  localityRequiredErrorMessage: postalLocality.error.errorMsg,
                  localityName: 'postalLocality',
                  stateLabel: policyOwnerDetailsPostalAddressStateFieldLabel,
                  statePlaceholder: policyOwnerDetailsPostalAddressStateFieldPlaceholder,
                  stateOptions: schema.postalState.options,
                  stateValue: postalState.value,
                  stateError: postalState.error.error,
                  stateRequiredErrorMessage: postalState.error.errorMsg,
                  stateName: 'postalState',
                  countryLabel: policyOwnerDetailsPostalAddressCountryFieldLabel,
                  countryPlaceholder: policyOwnerDetailsPostalAddressCountryFieldPlaceholder,
                  countryValue: postalCountry.value,
                  countryError: postalCountry.error.error,
                  countryRequiredErrorMessage: policyOwnerDetailsPostalAddressCountryFieldError,
                  countryName: 'postalCountry',
                  postCodeLabel: policyOwnerDetailsPostalAddressPostCodeFieldLabel,
                  postCodePlaceholder: policyOwnerDetailsPostalAddressPostCodeFieldPlaceholder,
                  postCodeValue: postalPostCode.value,
                  postCodeError: postalPostCode.error.error,
                  postCodeRequiredErrorMessage: postalPostCode.error.errorMsg,
                  postCodeName: 'postalPostCode',
                  manualToggleLabel: policyOwnerDetailsPostalAddressToggleToAutoText,
                  inputEntryHandler: this.handleChange,
                }}
              />
            )}
            <InputPhone
              inputPhoneNumber={inputContactNumber}
              selectPhoneCode={selectContactNumberCode}
            />

            {hasSecondaryContact && (
              <InputPhone
                inputPhoneNumber={inputSecondaryNumber}
                selectPhoneCode={selectSecondaryNumberCode}
              />
            )}

            <ContactToggle
              hasSecondaryContact={hasSecondaryContact}
              htmlFor="secondaryContactNoCheck"
            >
              <SecondaryContactNoCheck
                type="checkbox"
                name="secondaryContactNoCheck"
                id="secondaryContactNoCheck"
                onChange={this.secondaryNumberCheckbox}
                checked={hasSecondaryContact}
              />
              {hasSecondaryContact
                ? policyOwnerDetailsRemoveContactToggleText
                : policyOwnerDetailsAdditionalContactToggleText}
            </ContactToggle>
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = (
  { forms, createQuote, addressLookup, sidebar, captureCreditCard },
  { formId }
) => ({
  forms,
  form: forms[formId],
  paymentDetailsForm: forms[paymentDetailsSchemaFormID],
  createQuote,
  isManualPostal:
    addressLookup.policyOwnerPostalAddress && addressLookup.policyOwnerPostalAddress.isManual,
  isManualResidential:
    addressLookup.policyOwnerResidentialAddress &&
    addressLookup.policyOwnerResidentialAddress.isManual,
  sidebar,
  captureCreditCard,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PolicyOwnerDetails)
