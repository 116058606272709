import { colours, space, fontSize, mediaQueries, fontWeights } from '../../../styles'

const styles = {
  offset: {
    marginTop: -space(8),
    [mediaQueries.md]: {
      marginTop: -space(7),
    },
  },
  wrap: {
    marginBottom: space(24),
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
  },
  adviserRegistrationContainer: {
    padding: space(3, true),
    background: colours.white,
    [mediaQueries.md]: {
      width: '100%',
      marginBottom: space(15),
      marginTop: space(-8),
      padding: `${space(8, true)} ${space(13, true)}`,
    },
  },
  adviserRegistrationWrapper: {
    maxWidth: space(50),
  },
  adviserRegistrationRadioGroupWrapper: {
    marginTop: space(3),
    width: '100%',
  },
  footer: {
    padding: `${space(7, true)} 0`,
  },
  fieldset: withoutFlex => ({
    padding: `0  0 ${space(1, true)}`,
    display: `${withoutFlex ? 'block' : 'flex'}`,
    width: '100%',
    border: 'none',
    position: 'relative',
    margin: 0,
    justifyContent: 'space-between',
  }),
  allowableFeatures: {
    paddingBottom: space(3),
    borderBottom: `1px solid ${colours.lightestGrey}`,
    marginBottom: space(3),
  },
  sectionXsm: (withoutMargin, transparent) => ({
    width: `calc(30% - ${withoutMargin ? '0px' : space(3, true)})`,
    [transparent && 'input']: {
      color: colours.black,
      fontSize: 'inherit',
    },
  }),
  sectionXl: withoutMargin => ({
    width: `calc(70% - ${withoutMargin ? '0px' : space(3, true)})`,
  }),
  dateOfBirth: {
    width: '100%',
  },
  declarationDescriptions: {
    fontSize: fontSize.xs,
    marginBottom: space(1),
  },
  fullWidth: {
    flex: '0 0 100%',
    overflow: 'auto',
  },
  sectionLg: {
    width: '60%',
  },
  sectionSm: {
    width: `calc(40% - ${space(3, true)})`,
  },
  buttonContainer: (secondary = false) => ({
    fontSize: fontSize.xxs,
    color: !secondary ? colours.mediumGreen : colours.mediumGrey,
    position: 'absolute',
    right: 0,
    bottom: 0,
  }),
  transparent: {},
  button: {
    color: 'inherit',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
  },
  noResults: {
    padding: space(2),
  },
  radioWrapper: {
    margin: `${space(1, true)} 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  exploreButton: {
    fontSize: fontSize.xs,
    [mediaQueries.md]: {
      marginTop: space(4),
      alignSelf: 'center',
    },
  },
  exploreWrapper: {
    padding: space(3, true),
    background: colours.white,
    [mediaQueries.md]: {
      width: '100%',
      background: colours.white,
      padding: `${space(8, true)} ${space(13, true)}`,
    },
  },
  exploreHeading: {
    textAlign: 'center',
    fontSize: fontSize.md,
    color: colours.merlin,
    fontWeight: fontWeights.semiBold,
  },
  exploreDescription: {
    textAlign: 'center',
    fontSize: fontSize.xs,
    marginBottom: space(0),
  },
  exploreMessageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  exploreDescriptionBold: {
    textAlign: 'center',
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    color: colours.black,
  },
  exploreReferenceNumber: {
    textAlign: 'center',
    fontSize: fontSize.md,
    fontWeight: fontWeights.semiBold,
    color: colours.black,
  },
  uploadCertificateWrapper: {
    width: '100%',
    [mediaQueries.md]: {
      width: '45%',
      marginBottom: space(4),
      boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.05)',
    },
  },
  uploadCertificateBody: {
    background: colours.white,
    padding: `${space(3, true)} ${space(5, true)}`,
  },
  certificateHeaderWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  privacyNotificationWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  privacyNotificationLink: {
    fontSize: fontSize.xs,
    color: colours.mediumGreen,
    marginBottom: space(1),
    textDecoration: 'none',
    marginLeft: space(1),
    cursor: 'pointer',
  },
  fileNotUploadError: {
    fontSize: fontSize.xs,
    fontWeight: fontWeights.light,
    color: colours.red,
    marginBottom: 0,
  },
  errorIcon: {
    color: colours.red,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: space(2),
  },
  successfulIcon: {
    color: colours.mediumGreen,
    marginBottom: space(8),
  },
  submit: {
    minWidth: space(16),
  },
  spinner: {
    minHeight: 0,
  },
  businessNameWrap: {
    width: '100%',
  },
  businessNameLabel: {
    fontSize: fontSize.xxs,
    lineHeight: space(2, true),
    marginBottom: space(1),
  },
  businessNamePlaceHolder: {
    fontSize: fontSize.xs,
  },
  authorizedRepresentativeWrapper: {
    width: '100%',
    [mediaQueries.md]: {
      width: '45%',
      marginBottom: space(4),
    },
  },
  representativeDeleteItem: {
    width: space(2),
    height: space(2),
  },
  representativeDeleteItemCTA: {
    cursor: 'pointer',
    float: 'right',
  },
}

export default styles
