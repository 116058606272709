// @flow
import React, { Node, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components.
import Bar from './components/Bar'
import Wrap from './components/Wrap'

// schemas.
import SCHEMAS from './schemas/sidebar.schema'

type SidebarProps = {
  // Contents of page for sidebar to interact with
  children: Node,
  // Redux Dispatch function to be passed with callbacks
  dispatch: Function,
  // application state for sidebar schema to refer to
  state: {},
  // Sidebar state
  sidebar: {
    schemaName: string,
    currentPanel: number,
    isNextBtnLoader: boolean,
    componentName: string,
  },
  // An object containing action creator functions.
  actions: Object,
  // route data
  rendering: Object,
  // placeholder name
  name: string,
}

const Sidebar = ({
  rendering,
  name,
  children,
  dispatch,
  state,
  sidebar,
  actions,
}: SidebarProps) => {
  const { schemaName, currentPanel, isNextBtnLoader, componentName, isSidebarClosingAllowed } =
    sidebar
  // Fields for corresponding sidebar from layout service
  const { fields } =
    get(rendering, `placeholders.${name}`, []).find(
      component => component.componentName === componentName
    ) || {}

  const closeHandler = () => {
    const { closeSidebar } = actions
    closeSidebar()
  }
  const progressPanel = (num = null, props = undefined, activeSubPanel) => {
    const { nextPanel, gotoPanel } = actions
    if (num !== null) gotoPanel(num, props, activeSubPanel)
    else nextPanel(activeSubPanel)
  }

  const goBack = () => {
    const { previousPanel, gotoPanel } = actions
    const { backReset, backToPanel } = SCHEMAS[schemaName](fields, sidebar, state)[currentPanel]
    // animate straight to panel 0 without stopping at any previous panels
    if (backReset) {
      gotoPanel(0, sidebar.panelProps)
    } else if (backToPanel) {
      gotoPanel(backToPanel, sidebar.panelProps)
    } else {
      previousPanel()
      const tagEvent = createEvent({
        GA: {
          category: 'Change Clients Cover',
          action: `Sidebar Back - Type of ${currentPanel === 1 ? 'Change' : 'Quote'} `,
        },
        Splunk: {
          attributes: {
            'workflow.name': `Change Clients Cover - ${currentPanel === 1 ? 'Change' : 'Quote'}`,
          },
        },
      })
      tagEvent.end()
    }
  }

  // if current panel has a defined width in schema, assign it to a prop.
  const { width } = SCHEMAS[schemaName](fields)[currentPanel]
  const widthProp = width ? { sidebarWidth: width } : {}
  return (
    <Fragment>
      <Wrap
        move={sidebar.open}
        closeHandler={closeHandler}
        leftAligned={sidebar.leftAligned}
        isSidebarClosingAllowed={isSidebarClosingAllowed}
        {...widthProp}
      >
        {children}
      </Wrap>
      <Bar
        goBack={goBack}
        progressPanel={progressPanel}
        currentPanel={sidebar.currentPanel}
        animateTo={sidebar.currentPanel}
        panelProps={sidebar.panelProps}
        visible={sidebar.open}
        activeSubPanel={sidebar.activeSubPanel}
        closeHandler={closeHandler}
        schema={SCHEMAS[sidebar.schemaName](fields, sidebar, state)}
        dispatch={dispatch}
        state={state}
        fields={fields}
        leftAligned={sidebar.leftAligned}
        changingAlignment={sidebar.changingAlignment}
        isNextBtnLoader={isNextBtnLoader}
        {...widthProp}
      />
    </Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = state => ({
  state,
  sidebar: state.sidebar,
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
