// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Button, Heading, Card, Variables, Icons } from '@mlcl-digital/mlcl-design'

// utils.
import { isSitecoreField } from '../../../../../utils/sitecoreUtils'

// styles.
import styles from './modalCard.styles'

const { fontWeights } = Variables
const { IconClose16, IconArrowLeft16 } = Icons

// styled components.
export const Overlay = styled('div')(styles.overlay)
export const Base = styled(Card)(styles.base)
const CloseButton = styled(Button)(styles.closeButton)
const BackButton = styled(Button)(styles.backButton)
const Icon = styled(IconClose16)(({ isCloseDisabled }) => styles.icon(isCloseDisabled))
const IconBack = styled(IconArrowLeft16)(({ isCloseDisabled }) => styles.icon(isCloseDisabled))
export const Title = styled(Heading)(styles.title)
const Content = styled('div')(styles.content)

type ModalCardProps = {
  /** Contents of modal */
  children: Node,

  /** When true, do not apply padding to content area */
  disablePadding: boolean,

  /** A function to be called when modal is closed */
  onClose: Function,

  /** Indicates whether clicking the overlay should close the modal */
  shouldOverlayClose: boolean,

  /** Enable close button */
  showCloseButton: boolean,

  /** Enable alternative small modal mode */
  smallMode: boolean,

  /** Title to display */
  title: string | { value: string },

  /** Enable scroll */
  enableScroll: boolean,
}

class ModalCard extends React.PureComponent<ModalCardProps> {
  constructor(props) {
    super(props)
    this.closeButtonRef = null
  }

  componentDidMount() {
    if (this.closeButtonRef) this.closeButtonRef.focus()
  }

  handleModalClose = () => {
    const { isCloseDisabled, onClose } = this.props
    if (isCloseDisabled) return
    onClose()
  }

  handleModalBack = () => {
    const { handleBackButton } = this.props
    handleBackButton()
  }

  render() {
    const {
      children,
      disablePadding,
      onClose,
      showCloseButton,
      shouldOverlayClose,
      smallMode,
      title,
      shouldFocusCloseButton,
      enableScroll,
      isCloseDisabled,
      showBackButton,
    } = this.props

    const overlayProps = {}
    if (shouldOverlayClose) {
      overlayProps.onClick = onClose
    }

    return (
      <div>
        <Overlay data-testid="overlay" {...overlayProps} />
        <Base
          aria-modal="true"
          role="dialog"
          smallMode={smallMode}
          enableScroll={enableScroll}
          id="modalContent"
          styleOverrides={{
            body: {
              padding: 0,
            },
          }}
        >
          {showBackButton && (
            <BackButton
              data-testid="backButton"
              onClick={this.handleModalBack}
              smallMode={smallMode}
              variant="icon"
            >
              <IconBack size="sm" /> Back
            </BackButton>
          )}

          {showCloseButton && (
            <CloseButton
              data-testid="closeButton"
              onClick={this.handleModalClose}
              smallMode={smallMode}
              variant="icon"
              buttonRef={
                shouldFocusCloseButton
                  ? ref => {
                      this.closeButtonRef = ref
                    }
                  : null
              }
            >
              <Icon size="sm" isCloseDisabled={isCloseDisabled} />
            </CloseButton>
          )}
          <Title
            variant="h2"
            smallMode={smallMode}
            styleOverrides={{
              fontWeight: fontWeights.normal,
            }}
          >
            {isSitecoreField(title) ? <Text field={title} /> : title}
          </Title>
          <Content disablePadding={disablePadding} smallMode={smallMode}>
            {children}
          </Content>
        </Base>
      </div>
    )
  }
}

export default ModalCard
