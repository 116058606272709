// @flow

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'
import { EXPERIENCE_API } from '../types/experienceApi'
import { TRANSFORM_THIRD_PARTY_QUOTE } from '../types/thirdParty'

import { getAllProducts } from './productRules'
import { getMasterList } from './masterList'
import { updateQuoteEntity, createQuoteCalculate, redirectToQuoteTool } from './createQuote'
import { createEvent } from '../../utils/telemetry'

export const transformThirdPartyQuote = (thirdPartyQuoteKey, source) => dispatch => {
  const quotes = []
  const THIRD_PARTY_QUOTE_API = `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/quotes/researchPartner/transform/${thirdPartyQuoteKey}`
  // Create event for tracking
  const tagEvent = createEvent({
    GA: {
      category: 'post-transform-third-party-quote',
      action: 'AP - Create new quote via third party',
    },
    Splunk: {
      attributes: {
        'workflow.name': 'post-transform-third-party-quote - AP - Create new quote via third party',
      },
    },
  })
  dispatch({
    type: EXPERIENCE_API,
    name: TRANSFORM_THIRD_PARTY_QUOTE,
    verb: 'GET',
    route: THIRD_PARTY_QUOTE_API,
    additionalHeaders: { source },
    callback: (err, dataReceived) => {
      if (err) {
        // Return an error object and set isDataAvailable to false if there's an error
        return { error: err, isDataAvailable: false }
      }
      const {
        businessData: { quote },
      } = dataReceived

      quotes.push(quote)
      dispatch(
        getAllProducts(() => updateQuoteEntity(quotes, redirectToQuoteTool(createQuoteCalculate())))
      )
      dispatch(getMasterList(true))
      // End event
      tagEvent.end()
      return { isDataAvailable: quotes ? quotes.length : 0 }
    },
  })
}
