// @flow
import { formatCurrency } from '../../../../utils/quoteUtils'

// an array of keys in chart data and their corresponding colors.
export const PROJECTIONS_CHART_LINES = [
  {
    key: 'Selected',
    color: '#000000',
  },
  {
    key: 'Stepped',
    color: '#D86018',
  },
  {
    key: 'Level',
    color: '#00B0B9',
  },
]

// the key in chart data to use as the x axis.
// can also optionally pass any other recharts XAxis props here.
// set the interval to display all ticks on x axis if data is greater than size of the graph
export const PROJECTIONS_CHART_XAXIS = (duration: number) => ({
  dataKey: 'age',
  interval: duration > 20 ? 4 : 0,
})

// the formatter function in chart data to use on the y axis.
export const PROJECTIONS_CHART_YAXIS = {
  tickFormatter: (value: string) => formatCurrency(value, '$', 0, true),
}
