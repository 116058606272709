import { RequestManualQuoteProps } from '..'

// @flow
export const processAfterApisSucceed = (
  prevProps: RequestManualQuoteProps,
  thisProps: RequestManualQuoteProps
) => {
  const { alterations, selectLifeAssued } = thisProps

  // alteration rules and product rules API are in parallel, we need to
  // make sure that both APIs are finished successfully before moving to next screen
  if (
    ((prevProps.alterations.isFetchingRules && !alterations.isFetchingRules) ||
      (prevProps.alterations.productRulesAPICount === 1 &&
        alterations.productRulesAPICount === 0)) &&
    // rules API successful
    !alterations.isFetchingRules &&
    !alterations.rulesError &&
    // got all product rules data
    alterations.productRulesAPICount === 0 &&
    (alterations.escalationAPICount === 0 || alterations.escalationAPICount === null) &&
    alterations.productRulesFailedAPIs.length === 0
  ) {
    // next screen in sidebar
    selectLifeAssued()
  }
}
