export const FETCH_CREDIT_CARD_INFO = 'FETCH_CREDIT_CARD_INFO'
export const FETCH_CREDIT_CARD_INFO_INIT = 'FETCH_CREDIT_CARD_INFO_INIT'
export const FETCH_CREDIT_CARD_INFO_SUCCESS = 'FETCH_CREDIT_CARD_INFO_SUCCESS'
export const FETCH_CREDIT_CARD_INFO_ERROR = 'FETCH_CREDIT_CARD_INFO_ERROR'
export const FETCH_CREDIT_CARD_INFO_FAILURE = 'FETCH_CREDIT_CARD_INFO_FAILURE'

export const FETCH_NAB_DATA = 'FETCH_NAB_DATA'
export const FETCH_NAB_DATA_SUCCESS = 'FETCH_NAB_DATA_SUCCESS'
export const FETCH_NAB_DATA_ERROR = 'FETCH_NAB_DATA_ERROR'
export const FETCH_NAB_DATA_FAILURE = 'FETCH_NAB_DATA_FAILURE'

export const DISPLAY_CREDIT_CARD_LISTING = 'DISPLAY_CREDIT_CARD_LISTING'
