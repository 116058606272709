/* eslint-disable no-nested-ternary */
import {
  fontSize,
  buttonPadding,
  mediaQueries,
  space,
  fontWeights,
  colours,
} from '../../../../../styles'

const styles = {
  base: {
    display: 'block',
    height: 56,
    paddingTop: buttonPadding.sm.vertical,
    paddingBottom: buttonPadding.sm.vertical,
    textAlign: 'left',
    textDecoration: 'none',
    width: '100%',
    [mediaQueries.md]: {
      textAlign: 'center',
    },
  },
  lg: (theme, selected, disabled) => ({
    fontSize: fontSize.sm,
    background: selected ? theme.selected.bg : theme.bg,
    color: disabled ? colours.lightGrey : selected ? theme.selected.text : theme.text,
    paddingLeft: buttonPadding.sm.horizontal,
    paddingRight: buttonPadding.sm.horizontal,
    width: '100%',
    '&:hover': {
      background: selected ? theme.selected.bg : theme.hover.bg,
    },
    '&:active': {
      background: selected ? theme.selected.bg : colours.darkGreen,
    },
  }),
  sm: (theme, selected, disabled) => ({
    color: disabled ? colours.lightGrey : selected ? colours.mediumGreen : colours.darkestGrey,
    fontSize: `${fontSize.xxs}`,
    paddingTop: buttonPadding.lg.vertical,
    paddingBottom: buttonPadding.lg.vertical,
    fontWeight: fontWeights.bold,
    paddingLeft: space(1),
    paddingRight: space(1),
    borderBottom: `4px solid ${selected ? theme.bg : 'transparent'}`,
    '&:hover': {
      color: disabled ? colours.lightGrey : selected ? colours.mediumGreen : theme.hover.bg,
      cursor: disabled ? 'not-allowed' : selected ? 'default' : 'pointer',
    },
    '&:active': {
      borderBottom: `4px solid ${theme.bg}`,
    },
  }),
}

export default styles
