export const NEW_BUSINESS = 'New Business'
export const EXISTING_BUSINESS = 'Existing Business'

export const QUOTE_SEARCH_API_PARAM_NB = 'DIGITAL_QUOTE'
export const QUOTE_SEARCH_API_PARAM_EXISTING = 'DIGITAL_ALTERATION'

export const UPDATED_DATE = 'updatedDate'
export const QUOTE_COLLECTION_NAME = 'quoteCollectionName'

export const DIGITAL_QUICK_QUOTE = 'Digital quick quote'
export const DIGITAL_MANUAL_OVERRIDE = 'Digital manual override'
export const DIGITAL_NOT_QUICK_QUOTE = 'Digital not quick quote'
