// @flow
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FontAwesomeIcon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components
import Button from '../../atoms/Button'
import { IconPhone32 } from '../../atoms/Icons'
import ModalCard from './components/ModalCard'
import Modal from '../../molecules/Modal'
import EnquiryIdExpiredModal from '../EnquiryIdExpiredModal'

// constant
import {
  APPLICATION_STAGE_STEP,
  SAVE_DRAFT_APPLICATION,
  ADV_F2F,
  ADV_PHN,
  MYLINK,
  STEP_TWO,
  CUSTOMER_COMPLETES,
  PERSONAL_STATEMENT,
} from '../../../constants/application'

// helpers.
import history from '../../../utils/browserHistory'
import { getFullUrePayload } from '../../../utils/ureUtils'

// styles
import styles from './personalStatementModal.styles'
import { isFeatureEnabledForAP } from '../../../utils/commonUtils'

const IconPhone = styled(IconPhone32)(styles.userIcon)
const ButtonContainer = styled('div')(styles.buttonContainer)
export const ModalDescription = styled('p')(styles.modalDescription)
export const ErrorDescription = styled('p')(styles.errorDescription)

// SiteCore fields: Content
const SITECORE_FIELD_SHOWLIFEINSUREDCOMPLETES = 'enableLifeInsuredCompletesPersonalStatement'
const SITECORE_FIELD_SHOWADVISORCOMPLETES = 'enableAdvisorCompletesPersonalStatement'
// SiteCore field: Masterdata
const SITECORE_MASTERDATA_PSTOGGLEERRORMESSAGE = 'PSToggleMissingErrorMessage'

type PersonalStatementModalProps = {
  fields: Object,
  actions: Object,
  quoteData: Object,
  handlePersonalStatementCloseModal: Function,
  handleParentPageLoader: Function,
  parentPage: String,
  controlList: Array,
  errorMessages: Array,
}

export class PersonalStatementModal extends Component<PersonalStatementModalProps> {
  constructor(props) {
    super(props)
    this.state = {
      openPersonalStatementModal: true,
      openAdviserCompleteModal: false,
      openAdviserConsentsModal: false,
      actionAgreementConsents: '',
      underWritingConsents: '',
    }
  }

  openAdviserCompleteCallback = () => {
    this.setState({ openPersonalStatementModal: false, openAdviserCompleteModal: true })
  }

  openAdviserComplete = () => {
    this.isAdviserCompletes = true
    this.openAdviserCompleteCallback()
    const tagEvent = createEvent({
      GA: {
        category: 'Personal statement method',
        action: 'Adviser completes',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Personal statement method - Adviser completes',
        },
      },
    })
    tagEvent.end()
  }

  closeAdviserConsentsModal = () => {
    this.setState({ openAdviserConsentsModal: false, actionAgreementConsents: '' })
    this.updatePersonalStatementModal()
  }

  closePersonalStatementModal = () => {
    this.setState({ openPersonalStatementModal: false })
    this.updatePersonalStatementModal()
  }

  closeAdviserCompleteModal = () => {
    this.setState({ openAdviserCompleteModal: false })
    this.updatePersonalStatementModal()
  }

  updatePersonalStatementModal = () => {
    const { handlePersonalStatementCloseModal } = this.props
    handlePersonalStatementCloseModal()
  }

  saveAdviserConsents = () => {
    const {
      actions: {
        updateAdviserVerificationConsents,
        updateApplicationStage,
        updateUnderWritingMethod,
        updateQuoteStatus,
        saveQuote,
      },
      quoteData: { quotes, activeIndex },
      handlePersonalStatementCloseModal,
      handleParentPageLoader,
      parentPage,
    } = this.props

    const { underwritingDetails } = quotes[activeIndex]
    const { actionAgreementConsents, underWritingConsents } = this.state
    if (underwritingDetails && underwritingDetails.enquiryId) {
      // update under writing Method if enquery id available
      const quoteStatus = {
        actionName: null,
        quoteType: null,
        underwritingMethod: underWritingConsents,
      }
      // update quote status
      updateQuoteStatus(quoteStatus)
    }
    // update under writing Method in URE
    updateUnderWritingMethod(underWritingConsents)
    const updateStage = { stage2: APPLICATION_STAGE_STEP.partiallyComplete }
    updateAdviserVerificationConsents(true, activeIndex)
    updateApplicationStage(STEP_TWO, updateStage)
    this.setState({ openAdviserConsentsModal: false })
    handlePersonalStatementCloseModal(actionAgreementConsents)
    if (parentPage === 'ApplicationOverview') {
      handleParentPageLoader(true)
    }
    saveQuote(err => {
      if (!err) {
        history.push(PERSONAL_STATEMENT)
      } else {
        handleParentPageLoader(false)
      }
    })
  }

  adviserLifeInsuredCompleteCallback = () => {
    const {
      actions: { updateUnderWritingMethod, updateQuoteStatus, saveQuote },
      quoteData: { quotes, activeIndex },
      handleParentPageLoader,
    } = this.props
    const { underwritingDetails } = quotes[activeIndex]
    if (underwritingDetails && underwritingDetails.enquiryId) {
      // updateIsTeleFromAdviser(false)
      const quoteStatus = {
        actionName: SAVE_DRAFT_APPLICATION,
        quoteType: null,
        underwritingMethod: MYLINK,
      }
      // update quote status
      updateQuoteStatus(quoteStatus)
    }
    // update under writing Method in URE
    updateUnderWritingMethod(MYLINK)
    this.setState({ openPersonalStatementModal: false })
    handleParentPageLoader(true)
    saveQuote(err => {
      if (!err) {
        history.push(CUSTOMER_COMPLETES)
      } else {
        handleParentPageLoader(false)
      }
    })
  }

  adviserLifeInsuredComplete = () => {
    this.isAdviserCompletes = false
    this.adviserLifeInsuredCompleteCallback()
    const tagEvent = createEvent({
      GA: {
        category: 'Personal statement method',
        action: 'Life insured completes',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Personal statement method - Life insured completes',
        },
      },
    })
    tagEvent.end()
  }

  enquiryIdModalCallback = () => {
    if (this.isAdviserCompletes) {
      this.openAdviserCompleteCallback()
    } else {
      this.adviserLifeInsuredCompleteCallback()
    }
  }

  adviserCompleteInPerson = () => {
    this.setState(
      {
        actionAgreementConsents: SAVE_DRAFT_APPLICATION,
        underWritingConsents: ADV_F2F,
        openAdviserCompleteModal: false,
        openAdviserConsentsModal: false,
      },
      () => {
        this.saveAdviserConsents()
      }
    )
    const tagEvent = createEvent({
      GA: {
        category: 'Personal statement method',
        action: 'Adviser completes in person',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Personal statement method - Adviser completes in person',
        },
      },
    })
    tagEvent.end()
  }

  adviserCompleteOnThePhone = () => {
    this.setState({
      actionAgreementConsents: SAVE_DRAFT_APPLICATION,
      underWritingConsents: ADV_PHN,
      openAdviserCompleteModal: false,
      openAdviserConsentsModal: true,
    })
    const tagEvent = createEvent({
      GA: {
        category: 'Personal statement method',
        action: 'Adviser completes on the phone',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Personal statement method - Adviser completes on the phone',
        },
      },
    })
    tagEvent.end()
  }

  render() {
    const { openAdviserCompleteModal, openAdviserConsentsModal, openPersonalStatementModal } =
      this.state
    const { fields, controlList, errorMessages } = this.props
    const {
      personalStatementModalTitle,
      personalStatementModalDescription,
      adviserCompletesButtonLabel,
      lifeInsuredButtonLabel,
      adviserCompleteModalTitle,
      adviserCompleteModalDescription,
      inPersonButtonLabel,
      inPersonButtonDescription,
      onThePhoneButtonLabel,
      onThePhoneDescription,
      adviserConsentsModalTitle,
      adviserConsentsModalDescription,
      adviserConsentsButtonLabel,
      enquiryIdModalTitle,
      enquiryIdModalContent,
      enquiryIdModalButton,
      AlternateLabel,
      InPersonWithAdviser,
      ByPhoneWithAdviser,
      LifeInsuredByMyLink,
      LifeInsuredByPhone,
      MlcLifeTeleUnderwriting,
    } = fields
    const showAdvisorCompletesInModal = isFeatureEnabledForAP(
      controlList,
      SITECORE_FIELD_SHOWADVISORCOMPLETES
    )
    const showLifeInsuredCompletesInModal = isFeatureEnabledForAP(
      controlList,
      SITECORE_FIELD_SHOWLIFEINSUREDCOMPLETES
    )
    const psToggleMissingErrorMessageValue = get(
      errorMessages.find(({ code }) => code === SITECORE_MASTERDATA_PSTOGGLEERRORMESSAGE),
      'value',
      ''
    )

    return (
      <Fragment>
        {/* Personal Statement Modal  */}
        <Modal
          isOpen={openPersonalStatementModal}
          onClose={this.closePersonalStatementModal}
          title={personalStatementModalTitle}
        >
          {!showAdvisorCompletesInModal && !showLifeInsuredCompletesInModal ? (
            ''
          ) : (
            <ModalDescription>{personalStatementModalDescription}</ModalDescription>
          )}
          <ButtonContainer>
            {showAdvisorCompletesInModal && (
              <ModalCard
                buttonIcon={<FontAwesomeIcon iconName={['far', 'user-group']} size={32} />}
                handleClickEvent={this.openAdviserComplete}
                buttonTitleText={adviserCompletesButtonLabel}
                buttonDescriptionText={InPersonWithAdviser}
                description={AlternateLabel}
                descriptionText={ByPhoneWithAdviser}
                userIcon={<FontAwesomeIcon iconName={['far', 'user-group']} />}
                phoneIcon={<FontAwesomeIcon iconName={['far', 'phone']} />}
              />
            )}
            {showLifeInsuredCompletesInModal && (
              <ModalCard
                buttonIcon={<FontAwesomeIcon iconName={['far', 'user']} />}
                handleClickEvent={this.adviserLifeInsuredComplete}
                buttonTitleText={lifeInsuredButtonLabel}
                buttonDescriptionText={LifeInsuredByMyLink}
                description={AlternateLabel}
                descriptionText={LifeInsuredByPhone}
                userIcon={<FontAwesomeIcon iconName={['far', 'arrow-pointer']} />}
                phoneIcon={<FontAwesomeIcon iconName={['far', 'phone']} />}
                buttonText={MlcLifeTeleUnderwriting}
              />
            )}
            {!showAdvisorCompletesInModal && !showLifeInsuredCompletesInModal && (
              <ErrorDescription>{psToggleMissingErrorMessageValue}</ErrorDescription>
            )}
          </ButtonContainer>
        </Modal>

        {/* Adviser completes Modal */}
        <Modal
          isOpen={openAdviserCompleteModal}
          onClose={this.closeAdviserCompleteModal}
          title={adviserCompleteModalTitle}
        >
          <ModalDescription>{adviserCompleteModalDescription}</ModalDescription>
          <ButtonContainer>
            <ModalCard
              buttonIcon={<FontAwesomeIcon iconName={['far', 'user-group']} />}
              handleClickEvent={this.adviserCompleteInPerson}
              buttonTitleText={inPersonButtonLabel}
              buttonDescriptionText={inPersonButtonDescription}
            />
            <ModalCard
              buttonIcon={<IconPhone />}
              handleClickEvent={this.adviserCompleteOnThePhone}
              buttonTitleText={onThePhoneButtonLabel}
              buttonDescriptionText={onThePhoneDescription}
            />
          </ButtonContainer>
        </Modal>

        {/* Adviser completes Modal  customer-completes */}
        <Modal
          isOpen={openAdviserConsentsModal}
          onClose={this.closeAdviserConsentsModal}
          title={adviserConsentsModalTitle}
        >
          <ModalDescription>{adviserConsentsModalDescription}</ModalDescription>
          <Button type="secondary" onClick={this.saveAdviserConsents}>
            {adviserConsentsButtonLabel}
          </Button>
        </Modal>
        <EnquiryIdExpiredModal
          enquiryIdModalTitle={enquiryIdModalTitle}
          enquiryIdModalContent={enquiryIdModalContent}
          enquiryIdModalButton={enquiryIdModalButton}
          callback={this.enquiryIdModalCallback}
          payload={getFullUrePayload()}
          isPreassessment={false}
        />
      </Fragment>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export const mapStateToProps = ({ createQuote, masterList: { data } = {} }: Object) => ({
  quoteData: createQuote,
  controlList: get(data, 'featureControlSwitch', []),
  errorMessages: get(data, 'errorMessages', []),
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalStatementModal)
