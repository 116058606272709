// @flow
import queryString from 'query-string'
import { OKTA_API } from '../actions/types/okta'
import {
  makeRequestObj,
  makeActions,
  TYPE,
  isValidResponse,
  networkLog,
  getScope,
} from '../utils/middlewareUtils'

const isValidRequest = (action): boolean => {
  if (action && typeof action === 'object') {
    const { type } = action
    return type === OKTA_API
  }

  return false
}

export const apiService =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const { config } = getState()
    // validate if the action needs to be captured by this middleware, otherwise pass to next().
    if (!isValidRequest(action)) return next(action)
    const { name, verb, queryParams, route, queuedAction, callback, additionalHeaders, onError } =
      action

    const { success, failure, error, init } = name ? makeActions(name) : TYPE
    const queryParamString = queryParams ? `?${queryString.stringify(queryParams)}` : ''
    try {
      new Promise(resolve => resolve())
        // assemble complete headers.
        .then(headers =>
          makeRequestObj(action, { ...headers, Source: getScope(), ...additionalHeaders }, true)
        )
        .then(request => {
          next({
            type: init,
            payload: request,
          })

          return fetch(`${route}${queryParamString}`, request)
        })
        .then(async response => {
          let headers = {}

          // iterate headers and convert to object
          response.headers.forEach((value, key) => {
            headers = { ...headers, [key]: value }
          })

          const updatedResponse = {
            headers,
            status: response.status,
            config,
          }

          try {
            updatedResponse.data = await response.json()
          } catch (responseError) {
            updatedResponse.data = null
          }

          return updatedResponse
        })
        .then(dataResp => {
          const { status } = dataResp
          const valid = isValidResponse(status)
          const type = valid ? success : failure
          networkLog(`${verb || 'GET'} - ${type}`, status)
          if (callback && typeof callback === 'function') callback(!valid, dataResp, dispatch)
          return next({
            type,
            payload: dataResp,
            error: !valid,
          })
        })
        // queue next action if available
        .then(prevActionResponse => {
          if (prevActionResponse && !prevActionResponse.error && queuedAction) {
            dispatch(queuedAction(prevActionResponse))
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('oktaAuth API fetch error', err.toString())

          return next({
            type: error,
            payload: {
              data: {
                errorSummary: err.message ? err.message : err,
              },
            },
            error: true,
          })
        })
    } catch (err) {
      // a failure occurred inside this middleware.
      // eslint-disable-next-line no-console
      console.error('oktaAuth API middleware failure', err.toString())
      if (onError && typeof onError === 'function') onError(err)
      return next({
        type: failure,

        payload: {
          data: {
            errorSummary: err.message ? err.message : err,
          },
          // message: err,
        },
        error: true,
      })
    }

    return {}
  }

export default apiService
