/* eslint-disable no-param-reassign */
import { required, phone } from '../../../../../utils/formUtils'

export const FORM_ID = 'underWritingForm'
export const PRIMARY = 'primary'
export const OTHER = 'other'
export const DAYS = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
]

function getAlternateContact(preferred, alternateContact) {
  if (!alternateContact) {
    if (preferred.length) {
      return 'primary'
    }
    return ''
  }
  return 'other'
}

export const DEFAULTS = (isAnyDaySelected, selectedTimeSlot, preferred, alternateContact) => ({
  isAnyDaySelected,
  selectedTimeSlot,
  preferred,
  alternateContactOptions: getAlternateContact(preferred, alternateContact),
  alternateContact,
})

const Schema = (fields, isPrimarySelected) => ({
  isAnyDaySelected: {
    condition: value => !value,
    errorMsg: fields.personalStatementFastTrackDayError,
  },
  selectedTimeSlot: {
    condition: '',
    errorMsg: fields.personalStatementFastTrackTimeError,
  },
  preferred: {
    condition: [
      {
        condition: value => !value.length,
        errorMsg: fields.personalStatementFastTrackTimeError,
      },
    ],
    options: [
      {
        label: 'Between 8:30am and 5:30pm',
        value: 'Between 8:30am and 5:30pm',
        callAfterTime: '08:30:00',
        callBeforeTime: '17:30:00',
      },
      {
        label: 'Between 8:30am and 12 noon',
        value: 'Between 8:30am and 12 noon',
        callAfterTime: '08:30:00',
        callBeforeTime: '12:00:00',
      },
      {
        label: 'Between 12 noon and 2pm',
        value: 'Between 12 noon and 2pm',
        callAfterTime: '12:00:00',
        callBeforeTime: '14:00:00',
      },
      {
        label: 'Between 2pm and 5:30pm',
        value: 'Between 2pm and 5:30pm',
        callAfterTime: '14:00:00',
        callBeforeTime: '17:30:00',
      },
    ],
    field: 'Any day Monday to Friday',
  },
  alternateContactOptions: {
    condition: '',
    errorMsg: fields.personalStatementFastTrackContactNumberMandatoryError,
  },
  alternateContact: {
    condition: [
      ...(isPrimarySelected === OTHER
        ? [
            {
              condition: required,
              errorMsg: fields.personalStatementFastTrackContactNumberMandatoryError,
            },
            {
              condition: phone,
              errorMsg: fields.personalStatementFastTrackContactNumberValidationError,
            },
          ]
        : [{}]),
    ],
    name: 'alternateContact',
  },
})

export default Schema
