/* eslint-disable max-len */
// @flow
import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import QuoteCost from '../QuoteTool/components/QuoteToolPanel/QuoteCost'
import QuoteDownload from '../QuoteTool/components/QuoteDownload'
import Modal from '../../molecules/Modal'
import Button from '../../atoms/Button'

// styles.
import styles from './quoteQuickView.styles'

// types.
import { type quoteCollectionType } from '../../../reducers/createQuote'
import { ADVISER_PORTAL_SOURCE } from '../../../constants/site'
import { getSource } from '../../../utils/middlewareUtils'
import { SAVE_DRAFT_APPLICATION, ADV_F2F } from '../../../constants/application'
import { QUOTE_STATUS_APPLICATION_STAGE } from '../../../constants/policies'

// styled components.
const EditButton = styled(Button)(styles.editButton)
const QuoteFooter = styled('div')(styles.footer)
const QuoteToolContainer = styled('div')(styles.quoteToolContainer)
const ModalButton = styled(Button)(styles.modalButton)

type QuoteQuickViewProps = {
  // sitecore fields
  fields: Object,
  history: Object,
  actions: {
    closeSidebar: Function,
  },
  // create quote data which user has selected.
  createQuote: {
    activeIndex: number,
    quotes: Array<quoteCollectionType>,
  },
}

type QuoteQuickViewState = {
  editQuoteModalOpen: boolean,
}

class QuoteQuickView extends Component<QuoteQuickViewProps, QuoteQuickViewState> {
  constructor() {
    super()
    this.state = {
      editQuoteModalOpen: false,
    }
  }

  onEditQuoteOpen = () => {
    this.setState({ editQuoteModalOpen: true })
  }

  handleCloseModal = () => {
    this.setState({ editQuoteModalOpen: false })
  }

  onConfirmEdit = () => {
    const {
      history,
      actions: { closeSidebar, createQuoteSetIsSatisfied, updateQuoteStatus },
      createQuote: { quotes, activeIndex },
    } = this.props

    createQuoteSetIsSatisfied(false)
    const quoteStatusPayload = {
      actionName: SAVE_DRAFT_APPLICATION,
      quoteType: QUOTE_STATUS_APPLICATION_STAGE,
      ...(quotes[activeIndex].underwritingDetails && { underwritingMethod: ADV_F2F }),
    }
    updateQuoteStatus(quoteStatusPayload)

    this.handleCloseModal()
    closeSidebar()
    history.replace('/quote-tool')
  }

  render() {
    const {
      fields,
      createQuote: { quotes, activeIndex },
    } = this.props

    const {
      editQuoteModalHeader,
      editQuoteModalMessage,
      editQuoteModalButtonLabel,
      editQuoteButtonLabel,
      quoteUpdateSidebarAdviserDetailsTitle: quoteUpdateAdviserDetailsTitle,
      quoteUpdateSidebarAdviserDetailsCompanyFormHeader: quoteUpdateAdviserDetailsCompanyFormHeader,
      quoteUpdateSidebarAdviserDetailsNameLabel: quoteUpdateAdviserDetailsNameLabel,
      quoteUpdateSidebarAdviserDetailsBusinessNameLabel: quoteUpdateAdviserDetailsBusinessNameLabel,
      quoteUpdateSidebarAdviserDetailsAbnLabel: quoteUpdateAdviserDetailsAbnLabel,
      quoteUpdateSidebarAdviserDetailsContactFormHeader: quoteUpdateAdviserDetailsContactFormHeader,
      quoteUpdateSidebarAdviserDetailsAddressLabel: quoteUpdateAdviserDetailsAddressLabel,
      quoteUpdateSidebarAdviserDetailsCantFindAddressLabel:
        quoteUpdateAdviserDetailsCantFindAddressLabel,
      quoteUpdateSidebarAdviserDetailsStreetPlaceholder: quoteUpdateAdviserDetailsStreetPlaceholder,
      quoteUpdateSidebarAdviserDetailsHouseNoPlaceholder:
        quoteUpdateAdviserDetailsHouseNoPlaceholder,
      quoteUpdateSidebarAdviserDetailsLocalityPlaceholder:
        quoteUpdateAdviserDetailsLocalityPlaceholder,
      quoteUpdateSidebarAdviserDetailsStatePlaceholder: quoteUpdateAdviserDetailsStatePlaceholder,
      quoteUpdateSidebarAdviserDetailsCountryPlaceholder:
        quoteUpdateAdviserDetailsCountryPlaceholder,
      quoteUpdateSidebarAdviserDetailsPostCodePlaceholder:
        quoteUpdateAdviserDetailsPostCodePlaceholder,
      quoteUpdateSidebarAdviserDetailsManualToggleLabel: quoteUpdateAdviserDetailsManualToggleLabel,
      quoteUpdateSidebarAdviserDetailsContactLabel: quoteUpdateAdviserDetailsContactLabel,
      quoteUpdateSidebarAdviserDetailsEmailLabel: quoteUpdateAdviserDetailsEmailLabel,
      quoteUpdateSidebarAdviserDetailsSubmitButton: quoteUpdateAdviserDetailsSubmitButton,
      quoteUpdateSidebarAdviserDetailsPhoneInputInvalidErrorMsg:
        quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg,
      quoteUpdateSidebarAdviserDetailsEmailInputInvalidErrorMsg:
        quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg,
      quoteDownloadSidebarModalTitle: quoteDownloadModalTitle,
      quoteDownloadSidebarSelectAllButton: quoteDownloadSelectAllButton,
      quoteDownloadSidebarModalSubmitButton: quoteDownloadModalSubmitButton,
      downloadQuoteSidebarSelectOptionsHeader: downloadQuoteSelectOptionsHeader,
      downloadQuoteSidebarBackButton: downloadQuoteBackButton,
      downloadQuoteSidebarSelectOptionError: downloadQuoteSelectOptionError,
      quoteCollectionSidebarNameTitle: quoteCollectionNameTitle,
      quoteCollectionSidebarNameDescription: quoteCollectionNameDescription,
      quoteCollectionSidebarFirstNameLabel: quoteCollectionFirstNameLabel,
      quoteCollectionSidebarFirstNameError: quoteCollectionFirstNameError,
      quoteCollectionSidebarLastNameLabel: quoteCollectionLastNameLabel,
      quoteCollectionSidebarLastNameError: quoteCollectionLastNameError,
      quoteCollectionSidebarNameSubmitButtonLabel: quoteCollectionNameSubmitButtonLabel,
      quoteEntitySecondaryActionBtn,
      downloadQuoteRequiredBusinessNameErrorMsg,
      downloadQuoteRequiredABNErrorMsg,
      downloadQuoteRequiredFullAddressErrorMsg,
      downloadQuoteRequiredEmailAddressErrorMsg,
      downloadQuoteRequiredStreetErrorMsg,
      downloadQuoteInvalidStateErrorMsg,
      downloadQuoteInvalidPostCodeErrorMsg,
      downloadQuoteRequiredCountryErrorMsg,
      downloadQuoteInvalidLocalityErrorMsg,
      downloadQuoteInvalidStreetErrorMsg,
      downloadQuoteRequiredAdviserNameErrorMsg,
      quoteToolInsidePolicyNamePrefix,
      quoteToolOutsidePolicyNamePrefix,
      quoteToolWrapPolicySuffix,
      quoteToolSuperPolicySuffix,
      quoteToolOutsidePolicySuffix,
    } = fields

    const { editQuoteModalOpen } = this.state

    return (
      <QuoteToolContainer>
        <QuoteCost fields={fields} quotes={quotes} quoteIndex={activeIndex} breakdownOpen />
        {getSource() === ADVISER_PORTAL_SOURCE && (
          <Fragment>
            <QuoteFooter>
              <EditButton type="tertiary" onClick={this.onEditQuoteOpen} data-testid="editQuoteBtn">
                <Text field={editQuoteButtonLabel} />
              </EditButton>
              <QuoteDownload
                fields={{
                  quoteUpdateAdviserDetailsTitle,
                  quoteUpdateAdviserDetailsCompanyFormHeader,
                  quoteUpdateAdviserDetailsNameLabel,
                  quoteUpdateAdviserDetailsBusinessNameLabel,
                  quoteUpdateAdviserDetailsAbnLabel,
                  quoteUpdateAdviserDetailsContactFormHeader,
                  quoteUpdateAdviserDetailsAddressLabel,
                  quoteUpdateAdviserDetailsCantFindAddressLabel,
                  quoteUpdateAdviserDetailsStreetPlaceholder,
                  quoteUpdateAdviserDetailsHouseNoPlaceholder,
                  quoteUpdateAdviserDetailsLocalityPlaceholder,
                  quoteUpdateAdviserDetailsStatePlaceholder,
                  quoteUpdateAdviserDetailsCountryPlaceholder,
                  quoteUpdateAdviserDetailsPostCodePlaceholder,
                  quoteUpdateAdviserDetailsManualToggleLabel,
                  quoteUpdateAdviserDetailsContactLabel,
                  quoteUpdateAdviserDetailsEmailLabel,
                  quoteUpdateAdviserDetailsSubmitButton,
                  quoteUpdateAdviserDetailsPhoneInputInvalidErrorMsg,
                  quoteUpdateAdviserDetailsEmailInputInvalidErrorMsg,
                  quoteDownloadModalTitle,
                  quoteDownloadSelectAllButton,
                  quoteDownloadModalSubmitButton,
                  downloadQuoteSelectOptionsHeader,
                  downloadQuoteBackButton,
                  downloadQuoteSelectOptionError,
                  quoteEntitySecondaryActionBtn,
                  quoteCollectionNameTitle,
                  quoteCollectionNameDescription,
                  quoteCollectionFirstNameLabel,
                  quoteCollectionFirstNameError,
                  quoteCollectionLastNameLabel,
                  quoteCollectionLastNameError,
                  quoteCollectionNameSubmitButtonLabel,
                  downloadQuoteRequiredBusinessNameErrorMsg,
                  downloadQuoteRequiredABNErrorMsg,
                  downloadQuoteRequiredFullAddressErrorMsg,
                  downloadQuoteRequiredEmailAddressErrorMsg,
                  downloadQuoteInvalidStateErrorMsg,
                  downloadQuoteInvalidStreetErrorMsg,
                  downloadQuoteInvalidPostCodeErrorMsg,
                  downloadQuoteInvalidLocalityErrorMsg,
                  downloadQuoteRequiredStreetErrorMsg,
                  downloadQuoteRequiredCountryErrorMsg,
                  downloadQuoteRequiredAdviserNameErrorMsg,
                  quoteToolInsidePolicyNamePrefix,
                  quoteToolOutsidePolicyNamePrefix,
                  quoteToolWrapPolicySuffix,
                  quoteToolSuperPolicySuffix,
                  quoteToolOutsidePolicySuffix,
                }}
                align="column"
                secondaryBtnTheme="secondary"
                hasPrimaryBtn={false}
              />
            </QuoteFooter>
            <Modal
              isOpen={editQuoteModalOpen}
              title={editQuoteModalHeader}
              onClose={this.handleCloseModal}
            >
              <RichText field={editQuoteModalMessage} />
              <ModalButton type="secondary" onClick={this.onConfirmEdit}>
                <Text field={editQuoteModalButtonLabel} />
              </ModalButton>
            </Modal>
          </Fragment>
        )}
      </QuoteToolContainer>
    )
  }
}

const mapStateToProps = ({ createQuote }) => ({
  createQuote,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuoteQuickView))
