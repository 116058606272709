/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconPhone16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M2.828 2.417c-2.262 2.262.943 6.6 2.829 8.485 1.885 1.886 6.222 5.091 8.485 2.828 1.06-1.06 1.414-1.414 0-2.828-.59-.59-1.423-1.587-2.475-1.06-1.414.706-1.414 2.828-4.596-.354-3.182-3.182-1.06-3.182-.353-4.596.525-1.052-.472-1.886-1.061-2.475-1.414-1.415-1.768-1.061-2.829 0z"
      fill="#000"
    />
  </Icon>
)

export default IconPhone16
