// @flow
import * as React from 'react'
import styled from '@emotion/styled'
import { Label } from '@mlcl-digital/mlcl-design'

// components.
import { IconTick16, IconPlus16 } from '../Icons'
import Caption from '../Caption'

// styles.
import { themeConsumer, getThemeProp } from '../../../styles/ThemeContext'
import styles from './checkbox.styles'

// utils
import { getClassnameProp } from '../../../utils/stylesUtils'

type CheckboxProps = {
  // Value for input id and label for.
  htmlFor: string,
  // Label Text.
  text: string,
  // Value of the name attribute.
  name: string,
  // Sets whether the radio button is disabled or not.
  disabled: boolean,
  // Value of the value attribute.
  value?: any,
  // Sets the checked state.
  checked?: boolean,
  // Sets variation value.
  variant?: 'normal' | 'toggle' | null,
  // Set onChange handler.
  onChangeHandler: Function,
  // Tab index value.
  tabindex?: string | number,
  // Direction for switched label.
  direction?: 'left' | 'right',
  // data locator for automation testing
  dataLocator: string,
  // Show/hide caption icon.
  captionWithIcon?: boolean,
  // Flag to dangerously set caption using dangerouslySetInnerHTML.
  captionDangerousHTML?: boolean,
  // Sets Input into error state.
  error?: boolean,
  // Text to display in caption tag.
  caption?: 'string',
  // classes for styling
  className: string,
}

const InputWrapper = styled('div')(styles.wrapper)
const Input = styled('input')(
  ({ theme, variant }) =>
    variant === 'multiselect' ? styles.normal.input(theme) : styles[variant].input(theme),
  ({ variant, direction }) => (variant === 'toggle' ? styles[variant][direction].input : null)
)
const CheckboxLabel = styled(Label)(
  ({ variant }) => (variant === 'multiselect' ? styles.normal.label : styles[variant].label),
  ({ variant, direction }) => (variant === 'toggle' ? styles[variant][direction].label : null)
)
const Tick = styled(IconTick16)(styles.tick)
const Plus = styled(IconPlus16)(styles.plus)
const InputCaption = styled(Caption)(styles.caption)

const Checkbox = (props: CheckboxProps) => {
  const {
    htmlFor,
    text,
    name,
    disabled,
    value,
    checked,
    variant,
    onChangeHandler,
    tabindex,
    direction,
    dataLocator,
    caption,
    captionWithIcon,
    captionDangerousHTML,
    error,
    ...rest
  } = props

  const dataLocatorValue = {
    'data-locator': dataLocator || `input-checkbox-${name}`,
  }

  const handleChange = e => {
    onChangeHandler({ value: e.target.checked, name })
  }
  return (
    <InputWrapper
      role="switch"
      aria-checked={checked}
      {...getClassnameProp({ className: rest.className })}
    >
      <Input
        {...getThemeProp(props)}
        type="checkbox"
        id={htmlFor}
        name={name}
        disabled={disabled}
        value={value}
        checked={rest.defaultChecked ? undefined : checked}
        onChange={variant === 'multiselect' ? onChangeHandler : handleChange}
        variant={variant}
        direction={direction}
        {...dataLocatorValue}
        {...rest}
      />
      <CheckboxLabel
        direction={direction}
        htmlFor={htmlFor}
        checked={checked}
        tabIndex={tabindex}
        variant={variant}
      >
        {checked && variant === 'addbox' && <Tick />}
        {!checked && variant === 'addbox' && <Plus />}
        {text}
        {checked && !(variant === 'toggle' || variant === 'addbox' || variant === 'box') && (
          <Tick />
        )}
      </CheckboxLabel>
      {caption && (
        <InputCaption withIcon={captionWithIcon} error={error}>
          {captionDangerousHTML ? (
            // allow for authorable content in captions.
            // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: caption }} />
          ) : (
            caption
          )}
        </InputCaption>
      )}
    </InputWrapper>
  )
}

Checkbox.defaultProps = {
  value: '',
  checked: false,
  variant: 'normal',
  tabindex: 0,
  direction: 'left',
  captionWithIcon: true,
  captionDangerousHTML: false,
  error: false,
  caption: '',
}

export default themeConsumer(Checkbox, 'form')
