// @flow
import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  SIDEBAR_HIDE_FOOTER,
  SIDEBAR_SHOW_FOOTER,
  SIDEBAR_NEXT_PANEL,
  SIDEBAR_PREVIOUS_PANEL,
  SIDEBAR_GOTO_PANEL,
  SIDEBAR_DIRECTION,
  SIDEBAR_SET_SUB_PANEL,
  SIDEBAR_SET_TAB,
  SIDEBAR_SET_PANEL_PROPS,
  SIDEBAR_SET_IS_NEXT_BTN_LOADER,
  DISABLE_SIDEBAR_CLOSING,
  UPDATE_QUICK_QUOTE_CHECK,
  SIDE_BAR_SET_SOURCE,
} from '../types/sidebar'

import SCHEMAS from '../../components/organisms/Sidebar/schemas/sidebar.schema'

export const openSidebar =
  (
    schemaName: string,
    currentPanel: number = 0,
    panelProps: {} = undefined,
    activeSubPanel?: number,
    leftAligned: boolean = false,
    componentName: string = 'Sidebar'
  ) =>
  dispatch => {
    dispatch({
      type: SIDEBAR_DIRECTION,
      payload: {
        leftAligned,
      },
    })
    dispatch({
      type: SIDEBAR_OPEN,
      payload: {
        schemaName,
        currentPanel,
        panelProps,
        maxLength: SCHEMAS[schemaName]().length - 1,
        activeSubPanel,
        componentName,
      },
    })
  }

export const closeSidebar = () => dispatch => {
  dispatch({
    type: SIDEBAR_CLOSE,
  })
}

export const hideSidebarFooter = () => ({
  type: SIDEBAR_HIDE_FOOTER,
})

export const showSidebarFooter = () => ({
  type: SIDEBAR_SHOW_FOOTER,
})

export const nextPanel = (activeSubPanel?: number) => ({
  type: SIDEBAR_NEXT_PANEL,
  payload: { activeSubPanel },
})

export const previousPanel = (activeSubPanel?: number) => ({
  type: SIDEBAR_PREVIOUS_PANEL,
  payload: { activeSubPanel },
})

export const setSubPanel = (activeSubPanel?: number) => ({
  type: SIDEBAR_SET_SUB_PANEL,
  payload: { activeSubPanel },
})

export const setPanelProps = (panelProps?: Object) => ({
  type: SIDEBAR_SET_PANEL_PROPS,
  payload: { panelProps },
})

export const setNextTabPanel = (
  nextTabSideBarPanel?: number,
  nextTabSideBarLabelPanel: string
) => ({
  type: SIDEBAR_SET_TAB,
  payload: { nextTabSideBarPanel, nextTabSideBarLabelPanel },
})

export const gotoPanel = (
  currentPanel: number,
  panelProps: {} = undefined,
  activeSubPanel?: number
) => ({
  type: SIDEBAR_GOTO_PANEL,
  payload: {
    currentPanel,
    panelProps,
    activeSubPanel,
  },
})

export const setIsNextBtnLoader = (isNextBtnLoader: boolean = true) => ({
  type: SIDEBAR_SET_IS_NEXT_BTN_LOADER,
  payload: {
    isNextBtnLoader,
  },
})

// used to set if sidebar can be closed on clicking of overlay
export const disableSidebarClosing = () => ({
  type: DISABLE_SIDEBAR_CLOSING,
})

/**
 * Action creator to update quick quote check value for create quote
 * @param {*} eligible the eligibily value return from mule api
 * @returns {function} - action creator
 */
export const updateQuickQuoteCheck = (eligible: boolean) => ({
  type: UPDATE_QUICK_QUOTE_CHECK,
  payload: eligible,
})

/**
 * Action creator to set new field, source, for auditing in CARM in create quote entity
 * @param {*} source Digital quick quote | Digital manual override | Digital not quick quote
 * @returns {function} - action creator
 */
export const setQuoteSource = (source: String) => ({
  type: SIDE_BAR_SET_SOURCE,
  payload: { source },
})
