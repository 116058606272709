// @flow

const SEPARATOR = '/'
const FORMAT = 'DD/MM/YYYY'
export const formatDate = (val, format? = FORMAT, separator? = SEPARATOR) => {
  let date = ''
  const dateParts = val.split(separator)

  if (format === 'MM/YY') {
    // for month
    if (dateParts[0] && dateParts[0].length > 2) {
      if (!dateParts[1]) {
        dateParts[1] = dateParts[0].substring(2)
      }
      dateParts[0] = dateParts[0].substring(0, 2)
    }
    if ((dateParts[0] || dateParts[1] || dateParts[2]) && /^\d{0,2}$/.test(dateParts[0])) {
      date += `${dateParts[0]}${
        dateParts[2] || dateParts[1] || dateParts[0].length === 2 ? separator : ''
      }`
    }
    // for year
    if (dateParts[1] && dateParts[1].length > 2) {
      if (!dateParts[2]) {
        dateParts[2] = dateParts[1].substring(2)
      }
      dateParts[1] = dateParts[1].substring(0, 2)
    }
    if ((dateParts[1] || dateParts[2]) && /^\d{0,2}$/.test(dateParts[1])) {
      date += `${dateParts[1]}`
    }
  } else {
    // for Day
    if (dateParts[0] && dateParts[0].length > 2) {
      if (!dateParts[1]) {
        dateParts[1] = dateParts[0].substring(2)
      }
      dateParts[0] = dateParts[0].substring(0, 2)
    }
    if ((dateParts[0] || dateParts[1] || dateParts[2]) && /^\d{0,2}$/.test(dateParts[0])) {
      date += `${dateParts[0]}${
        dateParts[2] || dateParts[1] || dateParts[0].length === 2 ? separator : ''
      }`
    }
    // for Month
    if (dateParts[1] && dateParts[1].length > 2) {
      if (!dateParts[2]) {
        dateParts[2] = dateParts[1].substring(2)
      }
      dateParts[1] = dateParts[1].substring(0, 2)
    }
    if ((dateParts[1] || dateParts[2]) && /^\d{0,2}$/.test(dateParts[1])) {
      date += `${dateParts[1]}${dateParts[2] || dateParts[1].length === 2 ? separator : ''}`
    }
    // for Year
    if (dateParts[2] && dateParts[2].length > 4) {
      dateParts[2] = dateParts[2].substring(0, 4)
    }
    if (dateParts[2] && /^\d{0,4}$/.test(dateParts[2])) {
      date += `${dateParts[2]}`
    }
  }

  return date
}

export const formattedNumber = val => Number(val).toLocaleString()
