import {
  space,
  colours,
  coloursWithOpacity,
  fontSize,
  fontWeights,
  fontFamily,
} from '../../../../../../styles'

const styles = {
  collectionLabel: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.semiBold,
    color: colours.white,
  },

  customerInfoLabel: {
    lineHeight: 1,
    display: 'block',
  },

  customerInfoValue: {
    fontSize: fontSize.md,
    lineHeight: fontSize.lg,
    color: colours.white,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamily.charlie,
    textAlign: 'center',
  },

  customerInfoRoot: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colours.darkestGrey} `,
  },

  customerInfoItem: {
    width: '50%',
    textAlign: 'center',
    flexGrow: 1,
    paddingTop: space(1, true),
    paddingBottom: space(2, true),
    borderRight: `1px solid ${colours.darkestGrey} `,
    background: coloursWithOpacity(colours.white, 0.1),
  },

  occupationInfoRoot: {
    color: colours.white,
    paddingTop: space(3, true),
    paddingBottom: space(3, true),
    borderBottom: `1px solid ${colours.darkestGrey} `,
  },

  occupationInfoHeading: {
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    fontWeight: fontWeights.semiBold,
    marginBottom: space(1, true),
  },

  occupationInfoSummary: {
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
  },

  customerHeading: {
    fontSize: fontSize.md,
    lineHeight: fontSize.lg,
    color: colours.white,
    fontFamily: fontFamily.charlie,
    fontWeight: fontWeights.semiBold,
    paddingTop: space(4, true),
    paddingBottom: space(4, true),
    paddingLeft: space(4, true),
    borderBottom: `1px solid ${colours.darkestGrey} `,
    margin: 0,
  },

  textCenter: {
    textAlign: 'center',
  },
}

export default styles
