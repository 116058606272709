// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// icons
import { IconEdit16, IconClose16 } from '../../atoms/Icons'

// styles.
import styles from './memberList.styles'

// components
import Button from '../../atoms/Button'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'

const MemberListContainer = styled('div')(styles.memberListContainer)
const MemberListCaption = styled('p')(styles.memberListCaption)
const MemberList = styled('ul')(styles.memberList)
const MemberListItem = styled('li')(styles.memberListItem)
const MemberName = styled('span')(styles.memberName)
const EditMember = styled(Button)(styles.editMember)
const RemoveMember = styled(Button)(styles.removeMember)
const PrimaryOwnerLabel = styled('span')(styles.primaryOwnerLabel)
const PrimaryOwnerButton = styled(Button)(styles.primaryOwnerButton)

type memberListProps = {
  caption: string,
  members: [
    {
      name: string,
      identifires: object,
      role: string,
    }
  ],
  className: string,
  editHandler?: Function,
  removeHandler?: Function,
  referMember?: Boolean,
  updatePolicyOwnerHandler: Function,
  fields: object,
}

const MemberListComponent = ({
  caption,
  members,
  editHandler,
  removeHandler,
  className,
  referMember,
  updatePolicyOwnerHandler,
  fields,
}: memberListProps) => (
  <MemberListContainer className={className}>
    <MemberList>
      {members &&
        members.map(member => (
          <Fragment key={`member-${member.name}`}>
            <MemberListCaption>
              {referMember ? member.role : caption}
              <PrimaryOwnerLabel>
                {member.isPrimary ? renderTextField(fields.primaryOwnerLabel) : null}
              </PrimaryOwnerLabel>
            </MemberListCaption>
            <MemberListItem key={`member.name-${member.name}`}>
              <MemberName>{member.name}</MemberName>
              {editHandler && editHandler !== null && !member.isLifeAssured ? (
                <EditMember
                  onClick={referMember ? editHandler(member, !!member.businessName) : editHandler}
                  data-testid="edit"
                  ariaLabel="Edit member"
                >
                  <IconEdit16 />
                </EditMember>
              ) : null}
              {removeHandler && removeHandler !== null ? (
                <RemoveMember
                  onClick={referMember ? removeHandler(member) : removeHandler}
                  data-testid="remove"
                  ariaLabel="Remove member"
                >
                  <IconClose16 />
                </RemoveMember>
              ) : null}
              {!member.company && member.isPrimary !== undefined && !member.isPrimary && (
                <PrimaryOwnerButton onClick={updatePolicyOwnerHandler(member)}>
                  {renderTextField(fields.addPolicyOwnerActionLabel)}
                </PrimaryOwnerButton>
              )}
            </MemberListItem>
          </Fragment>
        ))}
    </MemberList>
  </MemberListContainer>
)

MemberListComponent.defaultProps = {
  editHandler() {},
  removeHandler() {},
  referMember: false,
}

export default MemberListComponent
