// @flow
import { required, firstNameLastName } from '../../../../../../utils/formUtils'
import { type QuoteCollectionNameFields } from './quoteCollectionName.fields'

export const FORM_ID = 'quoteCollectionName'

// this form schema requires fields be combined with it before it can be retrieved.
const getQuoteCollectionNameSchema = (fields: QuoteCollectionNameFields) => ({
  firstName: {
    condition: [
      required,
      {
        condition: firstNameLastName,
        errorMsg: fields && fields.invalidFirstNameError.value,
      },
    ],
    errorMsg: fields.firstNameError.value,
    tooltip: '',
  },
  lastName: {
    condition: [
      required,
      {
        condition: firstNameLastName,
        errorMsg: fields && fields.invalidLastNameError.value,
      },
    ],
    errorMsg: fields.lastNameError.value,
    tooltip: '',
  },
})

export default getQuoteCollectionNameSchema
