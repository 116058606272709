// contants
import {
  DOC_TYPE_BRIEF_QUOTE,
  DOC_TYPE_DETAIL_QUOTE,
  DOC_TYPE_REMUNERATION,
} from '../../../../../../constants/documentTypes'

const schema = [
  {
    key: DOC_TYPE_BRIEF_QUOTE,
    name: 'briefQuote',
    value: 'Brief Quote',
  },
  {
    key: DOC_TYPE_DETAIL_QUOTE,
    name: 'detailedQuote',
    value: 'Detailed Quote',
  },
  {
    key: DOC_TYPE_REMUNERATION,
    name: 'remuneration',
    value: 'Remuneration',
  },
]
export default schema
