import { DOC_TYPE_ALTERATION_QUOTE } from './documentTypes'

export const POLICY_PRODUCT_CODE_SUPER = 'LC_MLCI_S_MLC'
export const POLICY_PRODUCT_CODE_NON_SUPER = 'LC_MLCI_NS_MLC'
export const POLICY_PRODUCT_CODE_PPP_NON_SUPER = 'LC_PPP_NS_MLC'
export const POLICY_PRODUCT_CODE_LCS = 'LC_LCS_S_MLC'
export const POLICY_NON_SUPER_BENEFIT_PREFIX = 'non-'
export const POLICY_NON_SUPER_BENEFIT_PREFIX_NEW = 'non_'
export const POLICY_CHILD_COVER_BENEFIT_CODE = 'Child_CI'
export const POLICY_OAI_BENEFIT_CODE = 'HIV'
export const POLICY_PREMIUM_WAIVER_BENEFIT_CODE = 'WOP-Waiver'
export const POLICY_REMUNERATION_DEFAULT = 'Default'
export const POLICY_REMUNERATION_LEVEL = 'LEVEL'
export const POLICY_REMUNERATION_HYBRID_70 = 'HYBRID'
export const POLICY_REMUNERATION_MIXED = 'MIXED'
export const POLICY_REMUNERATION_TYPE = {
  [POLICY_REMUNERATION_HYBRID_70]: 'Hybrid70',
  [POLICY_REMUNERATION_LEVEL]: 'Level',
  [POLICY_REMUNERATION_DEFAULT]: 'Default',
  [POLICY_REMUNERATION_MIXED]: 'Mixed',
}
export const POLICY_FIXED_PAYMENT_METHOD = 'ER'
export const POLICY_FIXED_PAYMENT_FREQUENCY_VALUE = 'ANN'
export const POLICY_FIXED_PAYMENT_FREQUENCY_LABEL = 'Yearly'
export const POLICY_FREQUENCY_MONTHLY = 'MON'
export const POLICY_FREQUENCY_ANNUAL = 'ANN'
export const POLICY_FREQUENCY_BIANNUAL = 'BIAN'
export const POLICY_FREQUENCY_QUARTER = 'QTR'
export const QUOTE_TOOL_MAX_CHILD_CI_COUNT = 5
export const INCOME_PROTECTION_TEXT = 'IP'
export const INCOME_PROTECTION_ID = 'PHI'
export const MASTER_KEY_ACCOUNT_ID = 'EMA'
export const PLUM_SUPER_ID = 'PLUM'
export const MASTER_KEY_LABEL = 'Eligible MLC Account'
export const WRAPPER_NAVIGATOR_ID = 'WRAP'
export const CREDIT_CARD_ID = 'CC'
export const DIRECT_DEBIT_ID = 'DD'
export const ROLL_OVER_ID = 'ER'
export const ROLL_OVER_LABEL = 'E-Rollover'
export const BPAY = 'BP'
export const CHEQUE = 'CQ'
export const MLC_ACC = 'ME'
export const NAVIGATOR = 'NAVIGATOR'
export const CAPSIL = 'CAPSIL'
export const PLUM = 'PLUM'
export const WRAP = 'WRAP'
export const VISA = 'VI'
export const MASTERCARD = 'MC'
export const MASK_PREFIX = 'XXXX XXXX XXXX'
export const POLICY_RELATIONSHIPS_LIFEASSURED = 'LA'
export const POLICY_RELATIONSHIPS_OWNER = 'OWR'
export const POLICY_RELATIONSHIPS_SMSF = 'IET'
export const POLICY_RELATIONSHIPS_MEMBER = 'MBR'
export const POLICY_RELATIONSHIPS_EMPLOYER = 'EM'
export const COUNTRY_CODE = 'AUS'
export const ADDRESS_TYPE_HOME = 'H'
export const ADDRESS_TYPE_WORK = 'W'
export const ADDRESS_TYPE_STATEMENT = 'S'
export const PREFERRED_YES = 'Y'
export const TYPE_HOME = 'HOM'
export const PREFERRED_NO = 'N'
export const ADDRESS_STATUS_DFL = 'DFL'
export const CONTACT_TYPES_TEL = 'TEL'
export const CONTACT_SUB_TYPES = 'M'
export const CONTACT_SUB_TYPE_MOBILE = 'M'
export const CONTACT_SUB_TYPE_HOME = 'H'
export const CONTACT_SUB_TYPE_WORK = 'W'
export const CONTACT_TEL_SUB_TYPE_LIST = [
  CONTACT_SUB_TYPE_MOBILE,
  CONTACT_SUB_TYPE_HOME,
  CONTACT_SUB_TYPE_WORK,
]
export const POLICY_MEMBER_IDENTIFIER_CUSTOMER = 'BANCS_CUSTOMER_NO'
export const POLICY_MEMBER_IDENTIFIER_PARTY = 'PARTY_NO'
export const POLICY_PAYER_BUSINESS_PARTY = 'BUSINESS_PARTY_NO'
export const POLICY_IDENTIFIER_POLICY_ID = 'POLICY_ID'
export const POLICY_RELATIONSHIPS_LIFE_BENEFICIARY = 'LB'
export const POLICY_RELATIONSHIPS_LEGAL_BENEFICIARY = 'LBEN'
export const POLICY_BENEFICIARY_ACTION_UPDATE = 'UPDATE'
export const POLICY_BENEFICIARY_ACTION_ADD = 'ADD'
export const POLICY_BENEFICIARY_ACTION_REMOVE = 'REMOVE'
export const POLICY_RELATIONSHIPS_PAYER = 'PYR'
export const SUPER_PRODUCT_CLASS = 'Super'
export const NON_SUPER_PRODUCT_CLASS = 'Non Super'
export const ORDINARY_PRODUCT_CLASS = 'Ordinary'
export const EXTERNAL_SUPER_PRODUCT_CLASS = 'External Super'
export const SUPER_OWNER_INTERNAL = 'MLC Superannuation Trustee Structures'
export const SUPER_OWNER_INTERNAL_TRUSTEE_NAME = 'Nulis Nominees Australia Ltd'
export const SUPER_OWNER_IOOF = 'Insignia Wrap Account Trustee'
export const SUPER_OWNER_IOOF_TRUSTEE_NAME = 'IOOF Investment Management Limited'
export const SUPER_OWNER_EXTERNAL = 'SMSF (External Trustee)'
export const QUOTE_STATUS_QUOTE_STAGE = 'DRAFT_QT'
export const QUOTE_STATUS_APPLICATION_STAGE = 'DRAFT_APPLN'
export const QUOTE_STATUS_SUBMITTED_TO_BANCS_STAGE = 'SUBM_APPLN'
export const SAVE_QUOTE_COLLECTION = 'SAVE_QUOTE_COLLECTION'
export const POLICY_PARTY_TYPE_INDIVIDUAL = 'PER'
export const POLICY_PARTY_TYPE_BUSINESS = 'ORG'
export const CPI_STATUS_ON = 'On'
export const CPI_STATUS_OFF = 'Off'
export const BINDING = 'Binding'
export const NON_BINDING = 'Non-Binding'
export const NO_BENEFICIARIES = 'NO-BENEFICIARIES'
export const UPDATE_FROM_LIFE_INSURED = 'updateFromLifeInsured'
export const TPD_DEFINITION_OWN = 'Own'
export const POLICY_FEE_TYPE = 'Full'
export const RISKED_OCCUPATION = 'SR'
export const INCOME_PROTECTION_PHI_PLATINUM_ID = 'PHI_PLATINUM'
export const INCOME_PROTECTION_SR_ID = 'PHI_SR'
export const INCOME_PROTECTION_PLATINUM_ID = 'PHI_PLAT_2020'
export const INCOME_PROTECTION_PLATINUM_ID_OLD = 'PHI_PLAT'
export const INCOME_PROTECTION_STANDARD_ID = 'PHI_STD_2020'
export const INCOME_PROTECTION_STANDARD_ID_OLD = 'PHI_STD'
export const INCOME_PROTECTION_PSR_ID = 'PHI_PSR'
export const INCOME_PROTECTION_PLUS_ID = 'PHI_PLUS'
export const INCOME_PROTECTION_SSR_ID = 'PHI_SSR'
export const INCOME_PROTECTION_PHI_STD_2020_ID = 'PHI_STD_2020'
export const INCOME_PROTECTION_SG_STD_2020_ID = 'SG_STD_2020'
export const INCOME_PROTECTION_PHI_PLAT_2020_ID = 'PHI_PLAT_2020'
export const INCOME_PROTECTION_SG_PLAT_2020_ID = 'SG_PLAT_2020'
export const BUSINESS_EXPENSE = 'BE'
export const BUSINESS_EXPENSE_PLATINUM = 'BE_PLATINUM'
export const POLICY_RELATIONSHIPS_AGENT = 'SA'
export const INSIDE_SUPER = 'Inside Super'
export const OUTSIDE_SUPER = 'Outside Super'
export const PAYMENT_HISTORY_DATE_FORMAT = 'YYYY-MM-DD'
export const PRIMARY = 'primary'
export const DANGER = 'danger'
export const POLICY_STATE_INFORCE = 'Inforce'
export const POLICY_MEMBER_DIRECTOR = 'Director'
export const POLICY_MEMBER_COMPANY = 'Company'
export const POLICY_MEMBER_INDIVIDUAL = 'Individual'
export const POLICY_MEMBER_BUSINESS = 'Business'
export const POLICY_BENEFIT_LC = 'LC'
export const POLICY_BENEFIT_LC_PLUS = 'LC_PLUS'
export const POLICY_BENEFIT_LC_STANDARD = 'LC_STD'
export const POLICY_BENEFIT_CI_PLUS = 'CI_PLUS'
export const POLICY_BENEFIT_CI_STD = 'CI_STD'
export const POLICY_BENEFIT_CI = 'CI'
export const POLICY_BENEFIT_TISO = 'TISO'
export const POLICY_BENEFIT_HEP = 'HEP'
export const POLICY_BENEFIT_SI = 'SI'
export const POLICY_BENEFIT_SMB = 'SMB'
export const POLICY_BENEFIT_SDTRO = 'SDTRO'
export const POLICY_BENEFIT_PLAT_SGBO = 'SG_PLAT_2020'
export const POLICY_BENEFIT_STD_SGBO = 'SG_STD_2020'
export const POLICY_FEATURE_SGBO = 'SGBO'
export const POLICY_FEATURE_BSO = 'BSO'
export const POLICY_FEATURE_CCO = 'CCO'
export const POLICY_BENEFIT_PREMIUM_WAIVER = 'WOP'
export const BENEFIT_FEATURE_NOCD = 'NOCD'
export const POLICY_FEATURE_LSBO = 'LSBO'
export const POLICY_FEATURE_ABO = 'ABO'
export const POLICY_FEATURE_LSO = 'LSO'
export const POLICY_FEATURE_BO = 'BSTR'
export const POLICY_FEATURE_EBO = 'EBO'
export const POLICY_FEATURE_DBB = 'DBB'
export const POLICY_FEATURE_LCBB = 'LCBB'
export const POLICY_FEATURE_NCB = 'NCB'
export const POLICY_FEATURE_NCPB = 'NCPB'
export const POLICY_FEATURE_CCPB = 'CCPB'
export const POLICY_FEATURE_DPB = 'DPB'
export const POLICY_FEATURE_DRB = 'DRB'
export const POLICY_BENEFIT_ADB = 'ADB'
export const POLICY_BENEFIT_PTD = 'PTD'
export const POLICY_OPTIMISER_LABEL = 'Optimiser'
export const BENEFIT_PERIOD_TWO_YEARS = '2 Years'
export const POLICY_RELATIONSHIPS_LOA = 'LOA'

export const FEATURES_DISPLAY_SEPARATE_OPTIONS_DEFAULT = [
  POLICY_BENEFIT_TISO,
  POLICY_FEATURE_BO,
  POLICY_FEATURE_SGBO,
  POLICY_FEATURE_EBO,
]

const FEATURES_DISPLAY_SEPARATE_OPTIONS_ALTERATION = [
  POLICY_BENEFIT_TISO,
  POLICY_FEATURE_BO,
  POLICY_FEATURE_SGBO,
]

export const FEATURE_DISPLAY_MAP = {
  [DOC_TYPE_ALTERATION_QUOTE]: FEATURES_DISPLAY_SEPARATE_OPTIONS_ALTERATION,
}

export const FEATURES_AS_SUB_BENEFITS = [POLICY_FEATURE_EBO, POLICY_FEATURE_BO, POLICY_FEATURE_LSBO]

export const LIFE_RELATED_POLICY_BENEFITS = [
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_ADB,
  POLICY_BENEFIT_LC_PLUS,
  POLICY_BENEFIT_LC_STANDARD,
]

export const LIFE_COVER_ID_LIST = [
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_LC_PLUS,
  POLICY_BENEFIT_LC_STANDARD,
]

export const CRITICAL_ILLNESS_COVER_ID_LIST = [
  POLICY_BENEFIT_CI_PLUS,
  POLICY_BENEFIT_CI_STD,
  POLICY_BENEFIT_CI,
]

// this list includes IP benefits for both MLC and non MLC products
export const INCOME_PROTECTION_RELATED_POLICY_BENEFITS = [
  INCOME_PROTECTION_PHI_PLATINUM_ID,
  INCOME_PROTECTION_SR_ID,
  INCOME_PROTECTION_PLATINUM_ID,
  INCOME_PROTECTION_STANDARD_ID,
  INCOME_PROTECTION_PSR_ID,
  INCOME_PROTECTION_PLUS_ID,
  INCOME_PROTECTION_SSR_ID,
  INCOME_PROTECTION_ID,
  POLICY_BENEFIT_PLAT_SGBO,
  POLICY_BENEFIT_STD_SGBO,
  INCOME_PROTECTION_PLATINUM_ID_OLD,
  INCOME_PROTECTION_STANDARD_ID_OLD,
]

export const BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS = [
  BUSINESS_EXPENSE,
  BUSINESS_EXPENSE_PLATINUM,
]

const PPP_ES_PRE_2014_CODE = 'RI OR1/ES-PRE2014'
const PPP_ES_POST_2014_CODE = 'RI OR1/ES-POST2014'
const LCS_ES_PRE_2014_CODE = 'RI MR1/ES-PRE2014'
export const SMSF_OR_WRAP = '17297'
export const LEGACY_CODE_BLACKLIST_BENEFICIARIES = [
  SMSF_OR_WRAP,
  PPP_ES_PRE_2014_CODE,
  PPP_ES_POST_2014_CODE,
  LCS_ES_PRE_2014_CODE,
]

export const ASAP = {
  name: 'asap',
  label: 'ASAP',
}
export const NOT_BEFORE = {
  name: 'notBefore',
  label: 'Not before...',
}
export const REC_OF_APPS_DOC_CODE = '041217'
export const AUTHORITY_TO_CANCEL_DOC_CODE = '021111'
export const MEDICAL_AUTHORITY_DOC_CODE = '031118'
export const BENEFICIARY_NOMINATION_DOC_CODE = '010000'
export const CERTIFIED_ID_DOC_CODE = '031117'
export const FINANCIAL_EVIDENCE_DOC_CODE = '021253'
export const MEDICAL_EVIDENCE_DOC_CODE = '011316'
export const QUOTE_DOC_CODE = '011360'
export const OTHER_DOC_CODE = '011324'
export const STATUS_YES = 'Yes'
export const STATUS_NO = 'No'
export const MANDATORY = 'M'
export const OPTIONAL = 'O'
export const JURISDICTIONS = {
  TAS: 'Tasmania',
  SA: 'South Australia',
  QLD: 'Queensland',
  ACT: 'Australian Capital Territory',
  OVRS: 'Overseas',
  VIC: 'Victoria',
  NT: 'Northern Territory',
  NSW: 'New South Wales',
  WA: 'Western Australia',
  AUS: 'Australia',
}

export const TPD_DEFINATION_ANY = 'Any'
export const TPD_DEFINATION_OWN = 'Own'

export const TISO_MAX_SUM_INSURED = 250000

export const SOURCE_FUNDING_SYSTEM_COMPASS = 'COMPASS'

export const COMPANY_IDENTIFIER_NGRP = 'NGRP'
export const SMSF_ORGANIZATION_TYPE = 'SF'
export const SMSF_BUSINESS_ORGANIZATION_TYPE = 'CO'

export const POLICY_FEE_YES = 'Y'
export const POLICY_FEE_NO = 'N'

export const POLICY_STATUS_NA = 'NA'

export const TPD_OPTIMISER = '(Optimiser)'
export const D_RATED_OCCUPATION = 'D'
export const HEALTHY_LIVING_DISCOUNT = 'HL'
export const HEALTHY_LIVING_DISCOUNT_TEXT = 'Healthy Living Discount'
export const HEALTHY_LIVING_DISCOUNT_MISSING_REQUIREMENT = 'HEALTHY_LIVING_DISCOUNT'
export const MULTI_COVER_DISCOUNT = 'Multi Cover Discount'

export const SUPER_PRODUCT_ID_LIST = [
  'LC_IP_PF_EXCELL_S_MLC',
  'LC_LCS_S_MLC',
  'LC_MLCI_S_MLC',
  'LC_PF_FRM_NS_V3_MLC',
  'LC_PF_LIFE_S_V2_MLC',
  'LC_PF_LIFE_S_V3_MLC',
  'LC_PF_SRM_S_V3_MLC',
]

export const PROTECTION_FIRST_PRODUCT_ID_LIST = [
  'LC_IP_PF_BE_NS_MLC',
  'LC_PF_LIFE_NS_V2_MLC',
  'LC_PF_LIFE_S_V2_MLC',
  'LC_PF_RECMON_NS_V1_MLC',
  'LC_PF_FRM_NS_V1_MLC',
  'LC_IP_PF_EXCELL_NS_MLC',
  'LC_IP_PF_GOLD_NS_V2_MLC',
  'LC_PF_SRM_NS_V3_MLC',
  'LC_PF_LIFE_NS_V3_MLC',
  'LC_PF_LIFE_S_V3_MLC',
  'LC_PF_RECMON_NS_V3_MLC',
  'LC_IP_PF_EXCELL_S_MLC',
  'LC_PF_FRM_NS_V3_MLC',
  'LC_PF_SRM_S_V3_MLC',
  'LC_IP_PF_IDL_NS_MLC',
  'LC_PF_LIFE_NS_V2_MLC',
]

export const PPP_EXC_BENEFIT_PERIOD_IP_STD_PLUS = [
  { unit: 'Age', value: '55' },
  { unit: 'Age', value: '60' },
]
export const PPP_EXC_WAITING_PERIOD_IP_STD_PLUS = [
  { unit: 'Month', value: '2' },
  { unit: 'Month', value: '6' },
]

// Short Waiting Period for Accidental Injury and Critical Illness allowable waiting periods
export const SWP_ALLOWABLE_WP = [
  { unit: 'Day', value: '14' },
  { unit: 'Day', value: '30' },
]

export const FUND_PAYMENT_METHODS_IOOF = ['IFPE', 'IFSS', 'IFEX', 'IFES', 'IFXI', 'IFEI']
export const BLACKLIST_BENEFITS_COC = ['PHII', 'WVRI', 'WVRA', 'PHIA']

export const INCOME_ASSURE_PRODUCT_IDS = [
  INCOME_PROTECTION_PHI_PLAT_2020_ID,
  INCOME_PROTECTION_PHI_STD_2020_ID,
]

export const IA_LINKED_SGBO_PRODUCT_IDS = [
  INCOME_PROTECTION_SG_PLAT_2020_ID,
  INCOME_PROTECTION_SG_STD_2020_ID,
]

export const POLICY_ACTIVITY_OPEN = 'OPEN'
export const POLICY_ACTIVITY_CLOSED = 'CLOS'
export const POLICY_ACTIVITY_ALL = 'ALL'

export const PREMIUM_CALCULATOR_SUB_BENEFITS = [
  POLICY_FEATURE_LSBO,
  POLICY_FEATURE_LSO,
  POLICY_FEATURE_BO,
  // For PPP, EBO comes as feature from BANCS
  POLICY_FEATURE_EBO,
  POLICY_FEATURE_ABO,
  // For PF Product
  POLICY_FEATURE_NCB,
  POLICY_FEATURE_NCPB,
  POLICY_FEATURE_CCPB,
  POLICY_FEATURE_DPB,
  POLICY_FEATURE_DRB,
]

export const PREMIUM_CALCULATOR_HIDE_OPTIONS = [POLICY_FEATURE_CCO]

export const PREMIUM_CALCULATOR_BENEFITS_SORT_LIST = [
  ...LIFE_COVER_ID_LIST,
  POLICY_BENEFIT_PTD,
  POLICY_BENEFIT_CI_PLUS,
  POLICY_BENEFIT_CI_STD,
  POLICY_BENEFIT_CI,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  POLICY_BENEFIT_SI,
  POLICY_OAI_BENEFIT_CODE,
  POLICY_BENEFIT_HEP,
  POLICY_BENEFIT_ADB,
  ...INCOME_PROTECTION_RELATED_POLICY_BENEFITS,
  ...BUSINESS_EXPENSE_RELATED_POLICY_BENEFITS,
]
