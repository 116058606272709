// @flow
import React from 'react'
import styled from '@emotion/styled'
import { bool, func, string } from 'prop-types'
// atoms
import { Chip, Button } from '@mlcl-digital/mlcl-design'

// styles.
import styles from './taskRow.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

const FullWidth = ignoreColumns('div')
const Wrap = styled('div')(({ isInvertColorTheme, isPaddingBottom }) =>
  styles.wrap(isInvertColorTheme, isPaddingBottom)
)
const Line = styled(FullWidth)(({ isInvertColorTheme }) => styles.line(isInvertColorTheme))
const ItemBlockHidden = styled('div')(styles.lineBoxHidden)
const Title = styled('div')(styles.title)
const DateText = styled('div')(styles.dateText, styles.dateBtnContainer)
const DescriptionWrap = styled('div')(({ hideActionButton, isInvertColorTheme }) =>
  styles.descriptionWrap(hideActionButton, isInvertColorTheme)
)

const TaskRow = props => {
  const {
    title,
    date,
    actionButtonText,
    status,
    handleActionButton,
    description,
    comment,
    hideStatus,
    hideActionButton,
    isInvertColorTheme,
    isPaddingBottom,
    lifeInsuredName,
    shouldShowLifeInsured,
  } = props

  return (
    <Wrap isInvertColorTheme={isInvertColorTheme} isPaddingBottom={isPaddingBottom}>
      <Line isInvertColorTheme={isInvertColorTheme}>
        <DateText>{date}</DateText>
        {!hideStatus && <Chip>{status}</Chip>}
        <Title>
          {title}
          {lifeInsuredName && shouldShowLifeInsured && ` - ${lifeInsuredName}`}
        </Title>

        {!hideActionButton && (
          <Button type="primary" size="xsmall" onClick={() => handleActionButton(title)}>
            {actionButtonText}
          </Button>
        )}
      </Line>
      <ItemBlockHidden />
      {description !== '' || comment !== '' ? (
        <DescriptionWrap
          hideActionButton
          isInvertColorTheme={isInvertColorTheme}
        >{`${`${description} ${comment}`}`}</DescriptionWrap>
      ) : null}
    </Wrap>
  )
}

TaskRow.propTypes = {
  // title of the task
  title: string,
  // date
  date: string,
  // tasks status
  status: string,
  // action button text
  actionButtonText: string,
  // description status
  description: string,
  // comment status
  comment: string,
  // handle callback
  handleActionButton: func,
  // hide the status
  hideStatus: bool,
  // hide action
  hideActionButton: bool,
  // invert the color theme
  isInvertColorTheme: bool,
  // is bottom padding required
  isPaddingBottom: bool,
  lifeInsuredName: string,
  shouldShowLifeInsured: bool,
}

TaskRow.defaultProps = {
  title: '',
  status: '',
  date: '',
  hideStatus: false,
  isInvertColorTheme: false,
  hideActionButton: false,
  isPaddingBottom: false,
  actionButtonText: '',
  comment: '',
  description: '',
  lifeInsuredName: '',
  shouldShowLifeInsured: false,
  handleActionButton: () => {},
}

export default TaskRow
