/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconUmbrella16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.034c-2.357.167-3.927.946-4.873 2.256C1.108 5.7 1 7.5 1 9v1h6v3c0 .421-.161.633-.337.767A1.199 1.199 0 0 1 6 14c-.351 0-.596-.09-.735-.206C5.158 13.705 5 13.51 5 13H3c0 .99.342 1.795.985 2.33.61.51 1.366.67 2.015.67a3.19 3.19 0 0 0 1.876-.642C8.52 14.867 9 14.078 9 13v-3h6V9c0-1.5-.108-3.3-1.127-4.71C12.927 2.98 11.357 2.2 9 2.034V1H7v1.034zM3.027 8h9.946c-.064-1.04-.256-1.896-.721-2.54C11.7 4.697 10.579 4 8 4c-2.58 0-3.7.697-4.252 1.46-.465.644-.657 1.5-.72 2.54z"
      fill="#000"
    />
  </Icon>
)

export default IconUmbrella16
