// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// styles.
import styles from '../captureClient.styles'

// components
import Modal from '../../../../../molecules/Modal'
import Button from '../../../../../atoms/Button'

import { UPDATE_FROM_LIFE_INSURED } from '../../../../../../constants/policies'

import { renderTextField } from '../../../../../../utils/sitecoreUtils'

const ConfirmBtn = styled(Button)(styles.button)

type CaptureClientErrorModalProps = {
  setModalOpen: Function,
  actions: Object<Function>,
  isModalOpen: boolean,
  quoteWillUpdate: boolean,
  fields: Object,
  formId: string,
  schema: string,
  oldMemberMandatories: memberMandatoriesType,
}

export class CaptureClientErrorModal extends Component<CaptureClientErrorModalProps> {
  constructor(props) {
    super(props)

    this.state = {
      isManualClose: false,
    }

    this.handleUpdateQuoteModalClose = this.handleUpdateQuoteModalClose.bind(this)
  }

  componentDidMount() {
    const { quoteWillUpdate, setModalOpen } = this.props
    const { isManualClose } = this.state
    if (!isManualClose && quoteWillUpdate) setModalOpen(quoteWillUpdate)
  }

  componentDidUpdate() {
    const { quoteWillUpdate, setModalOpen } = this.props
    if (quoteWillUpdate) setModalOpen(quoteWillUpdate)
  }

  closeModal = () => {
    const {
      setModalOpen,
      oldMemberMandatories,
      actions: { closeSidebar, setPanelProps, formInit, updateLifeInsuredDetails },
      formId,
      schema,
    } = this.props
    this.setState({ isManualClose: true })
    updateLifeInsuredDetails(oldMemberMandatories)
    formInit(formId, schema, oldMemberMandatories)
    setModalOpen(false)
    setPanelProps({ quoteWillBeModified: false })
    closeSidebar()
  }

  // handles update quote modal close
  handleUpdateQuoteModalClose() {
    const {
      actions: {
        filterPolicyCovers,
        closeSidebar,
        formSubmitComplete,
        setPanelProps,
        isIPCoverCalculated,
      },
      setModalOpen,
      formId,
    } = this.props
    filterPolicyCovers(UPDATE_FROM_LIFE_INSURED)
    this.setState({ isManualClose: false })
    isIPCoverCalculated(false)
    setModalOpen(false)
    formSubmitComplete(formId)
    setPanelProps({ quoteWillBeModified: false })
    closeSidebar()
  }

  render() {
    const { isModalOpen, fields } = this.props

    return (
      <Fragment>
        <Modal onClose={this.closeModal} isOpen={isModalOpen} title="Error">
          {renderTextField(fields.saveLifeInsuredErrorModalContent)}
          <ConfirmBtn
            data-testid="capture-modal-confirm-button"
            type="secondary"
            onClick={this.handleUpdateQuoteModalClose}
          >
            <Text field={fields.saveLifeInsuredErrorModalConfirm} />
          </ConfirmBtn>
        </Modal>
      </Fragment>
    )
  }
}

export default connect()(CaptureClientErrorModal)
