import { space, colours, fontSize, fontWeights } from '../../../../../styles'

const styles = {
  wrap: {
    paddingTop: space(5, true),
    paddingLeft: space(2, true),
    paddingRight: space(2, true),
  },
  resultsHeading: {
    width: '100%',
    textAlign: 'center',
    padding: space(2, true),
    color: colours.mediumGrey,
    borderBottom: `1px solid ${colours.lightGrey}`,
  },
  lifeInsured: {
    width: '100%',
    padding: space(3, true),
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colours.lightGrey}`,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.sm,
    color: colours.darkestGrey,
    lineHeight: space(3, true),
    cursor: 'pointer',
  },
  lifeInsuredName: {
    flexBasis: '50%',
  },
  dateOfBirth: {
    flexBasis: '45%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.xs,
    color: colours.mediumGrey,
  },
  arrow: {
    color: colours.darkestGrey,
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
  },
  noResultFound: {
    padding: space(2, true),
    color: colours.red,
  },
  noResultFoundText: {
    display: 'block',
    marginBottom: space(2, true),
  },
}

export default styles
