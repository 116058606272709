/* eslint-disable array-callback-return */
// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import orderBy from 'lodash/orderBy'
import { SidebarActionItem } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import get from 'lodash/get'
import { actionCreators } from '../../../actions'

// utils.
import { isNonSuperProduct, getLifeAsssured, getIdentifier } from '../../../utils/quoteUtils'
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'

// component.
import MemberList from '../MemberList'

// constants
import {
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_RELATIONSHIPS_MEMBER,
  POLICY_MEMBER_INDIVIDUAL,
  POLICY_MEMBER_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
} from '../../../constants/policies'

// styles.
import styles from './policyOwnerAdd.styles'
import { INCOME_PROTECTION_COVERS, BUSINESS_EXPENSE_COVERS } from '../AddPayers/constants'
import { REF_NO } from '../../../constants/adviser'

type PolicyOwnerAddProps = {
  fields: Object,
  policyInstanceNo: number,
  lifeAssured: ?Object,
  createQuote: Object,
  abnLookup: Object,
  // properties of sidebar
  sidebar: Object,
  actions: {
    deleteMemberDetails: (relatedMember: Object) => void,
    closeSidebar: () => void,
    saveQuote: Function,
  },
  handleNewClient: Function,
  // form ID to delete
  deleteFormIds: Array<string>,
}

const Wrapper = styled('div')(styles.base)
const MemberListContainer = styled(MemberList)(styles.memberListContainer)

export class PolicyOwnerAdd extends Component<PolicyOwnerAddProps> {
  componentDidMount() {
    const {
      actions: { deleteForms, hideSidebarFooter, resetAbnLookup },
      deleteFormIds,
      sidebar: { currentPanel },
      abnLookup: { error, isLoading },
    } = this.props
    if (currentPanel === 0 && deleteFormIds) {
      deleteForms(deleteFormIds)
      hideSidebarFooter()
    }
    if (error || isLoading) {
      resetAbnLookup()
    }
  }

  getPolicyOwnerDetails = policy =>
    policy &&
    policy.relationships &&
    policy.relationships.find(member => member.role.includes(POLICY_RELATIONSHIPS_OWNER))

  getCaption = policyOwnerDetail => {
    let caption = ''
    if (policyOwnerDetail && policyOwnerDetail.role) {
      if (policyOwnerDetail.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
        caption = 'Life Insured'
      else caption = 'Individual'
    }
    return caption
  }

  editPolicyOwner =
    ({ editIdentifier: identifier, role }) =>
    () => {
      const {
        handleNewClient,
        actions: { setPanelProps },
      } = this.props
      const company = role === POLICY_MEMBER_BUSINESS
      setPanelProps({ partyType: { identifier } })
      handleNewClient(company ? 1 : 0)()
    }

  addNewPolicyOwner = (goToSubPanel, identifier) => {
    const {
      handleNewClient,
      actions: { setPanelProps },
    } = this.props
    setPanelProps({ partyType: { identifier }, activeAbnNumber: '' })
    handleNewClient(goToSubPanel)()
  }

  addExistingBeneficiaryAsPolicyOwner = (policyInstanceNo, beneficiary) => {
    const { createQuote } = this.props

    const { quotes, activeIndex } = createQuote
    const { policyStructure } = quotes[activeIndex]
    const currentPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === policyInstanceNo
    )

    const identifier = {
      type: REF_NO,
      value: moment().unix(),
    }

    const newRelationship = {
      relatedParty: beneficiary.relatedParty,
      role: [POLICY_RELATIONSHIPS_MEMBER],
    }
    newRelationship.relatedParty.identifiers = [identifier]
    const relationships = currentPolicy ? currentPolicy.relationships : []
    relationships.push(newRelationship)

    this.editPolicyOwner({ identifier: getIdentifier(newRelationship) })
  }

  addLifeAssuredAsPolicyOwner = (policyInstanceNo, identifier, lifeAssured) => {
    const {
      actions: { closeSidebar },
      createQuote,
    } = this.props

    const { quotes, activeIndex } = createQuote
    const { policyStructure } = quotes[activeIndex]
    const currentPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === policyInstanceNo
    )
    const relationships = currentPolicy ? currentPolicy.relationships : []

    const newRelationship = {
      relatedParty: {
        ...lifeAssured.relatedParty,
      },
      isPrimary: !relationships.some(rel => rel.role.includes(POLICY_RELATIONSHIPS_OWNER)),
      role: [POLICY_RELATIONSHIPS_OWNER],
    }
    const lifeAssuredRelationShip = relationships.find(relationship =>
      this.isLifeAssuredOwner(relationship)
    )

    if (lifeAssuredRelationShip) {
      lifeAssuredRelationShip.isPrimary = !relationships.some(rel =>
        rel.role.includes(POLICY_RELATIONSHIPS_OWNER)
      )
      lifeAssuredRelationShip.role.push(POLICY_RELATIONSHIPS_OWNER)
    } else {
      relationships.push(newRelationship)
    }
    closeSidebar()
    this.addNewPolicyOwner(0, identifier)
  }

  removePolicyOwner =
    ({ deleteIdentifier: identifier }) =>
    () => {
      const {
        actions: { deleteMemberDetails, closeSidebar },
        sidebar: {
          panelProps: { policyInstanceNo },
        },
      } = this.props

      deleteMemberDetails(identifier, policyInstanceNo, POLICY_RELATIONSHIPS_OWNER, true)
      // TODO: To be uncommented when save quote API is completely functional
      // saveQuote()
      closeSidebar()
    }

  setAsPolicyOwner =
    ({ updateIdentifier: identifier }) =>
    () => {
      const {
        actions: { closeSidebar, updatePolicyOwner },
        createQuote,
        policyInstanceNo,
      } = this.props

      const { quotes, activeIndex } = createQuote
      const { policyStructure } = quotes[activeIndex]
      const currentPolicy = policyStructure.find(
        policy => policy.policyInstanceNo === policyInstanceNo
      )

      const relationships = currentPolicy ? currentPolicy.relationships : []

      const primaryIndex = relationships.findIndex(relationship => relationship.isPrimary)
      if (primaryIndex !== -1) {
        relationships[primaryIndex].isPrimary = false
      }

      updatePolicyOwner(policyInstanceNo, identifier, relationships, true)
      // TODO: To be uncommented when save quote API is completely functional
      // saveQuote()
      closeSidebar()
    }

  addNewPolicyOwnerCompany = (goToSubPanel, identifier) => {
    const {
      handleNewClient,
      actions: { setPanelProps },
    } = this.props
    setPanelProps({ partyType: identifier, activeAbnNumber: '' })
    handleNewClient(goToSubPanel)()
  }

  isLifeAssuredOwner = policyOwnerDetail =>
    policyOwnerDetail &&
    policyOwnerDetail.role &&
    policyOwnerDetail.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)

  isPolicyMember = policyOwnerDetail =>
    policyOwnerDetail &&
    policyOwnerDetail.role &&
    !policyOwnerDetail.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) &&
    policyOwnerDetail.role.includes(POLICY_RELATIONSHIPS_MEMBER) &&
    policyOwnerDetail.relatedParty &&
    policyOwnerDetail.relatedParty.partyType === POLICY_PARTY_TYPE_INDIVIDUAL

  isPolicyOwner = policyOwnerDetail =>
    policyOwnerDetail &&
    policyOwnerDetail.role &&
    policyOwnerDetail.role.includes(POLICY_RELATIONSHIPS_OWNER)

  isBeneficiary = (policyOwnerDetail, allBeneficiaries) =>
    allBeneficiaries &&
    policyOwnerDetail &&
    allBeneficiaries.some(
      beneficiary =>
        beneficiary.relatedParty &&
        policyOwnerDetail.relatedParty.firstName === beneficiary.relatedParty.firstName &&
        policyOwnerDetail.relatedParty.lastName === beneficiary.relatedParty.lastName
    )

  isBeneficiaryPolicyOwner = (beneficiary, relationships) => {
    let isBeneficiaryPolicyOwner = false
    if (relationships && beneficiary) {
      relationships.map(relationship => {
        if (
          relationship.relatedParty &&
          relationship.relatedParty.firstName === beneficiary.relatedParty.firstName &&
          relationship.relatedParty.lastName === beneficiary.relatedParty.lastName &&
          relationship.role.includes(POLICY_RELATIONSHIPS_OWNER)
        )
          isBeneficiaryPolicyOwner = true
      })
    }
    return isBeneficiaryPolicyOwner
  }

  hasBusinessOwner = relationships => {
    let hasBusinessOwner = false
    if (relationships) {
      relationships.map(relationship => {
        if (
          relationship.relatedParty &&
          relationship.relatedParty.partyType === POLICY_PARTY_TYPE_BUSINESS
        )
          hasBusinessOwner = true
      })
    }
    return hasBusinessOwner
  }

  isLifeAssureNotOwner = relationships => {
    let isLifeAssureNotOwner = false
    if (relationships) {
      relationships.map(relationship => {
        if (
          relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) &&
          !relationship.role.includes(POLICY_RELATIONSHIPS_OWNER)
        )
          isLifeAssureNotOwner = true
      })
    }
    return isLifeAssureNotOwner
  }

  getTotalPolicyOwners = (relationships: Array<Object>): number => {
    let count = 0
    if (relationships) {
      relationships.map(relationship => {
        if (this.isPolicyOwner(relationship)) count += 1
        return count
      })
    }

    return count
  }

  render() {
    const { fields, policyInstanceNo, lifeAssured, createQuote } = this.props
    const { quotes, activeIndex } = createQuote
    const { policyStructure } = quotes[activeIndex]
    const currentPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === policyInstanceNo
    )
    const covers = (currentPolicy && currentPolicy.covers) || []
    const relationships = currentPolicy ? currentPolicy.relationships : null
    const allBeneficiaries = []
    const allIndividuals = []
    const isIPorBECover = covers.some(
      cover =>
        INCOME_PROTECTION_COVERS.includes(cover.type) ||
        BUSINESS_EXPENSE_COVERS.includes(cover.type)
    )

    policyStructure.map(policy => {
      if (policy.beneficiaries) Array.prototype.push.apply(allBeneficiaries, policy.beneficiaries)
      if (policy.relationships) {
        policy.relationships.map(relationship => {
          if (!this.isLifeAssuredOwner(relationship)) allIndividuals.push(relationship)
        })
      }
    })

    const { addIndividualActionLabel, addBusinessActionLabel, addLifeInsuredLabelSuffix } =
      reduceAuthorableFields(fields)

    const sortedAscendingRelationships =
      relationships && relationships.length && orderBy(relationships, ['relatedParty.firstName'])

    const isNonSuper = currentPolicy && isNonSuperProduct(currentPolicy.productId)
    let isPolicyOwnerACompany
    const totalPolicyOwners = this.getTotalPolicyOwners(relationships)
    return (
      <Wrapper>
        {sortedAscendingRelationships &&
          sortedAscendingRelationships.map(relationship => {
            const companyBusinessName = get(relationship, 'relatedParty.businessName')
            const editHandler = !this.isLifeAssuredOwner(relationship) ? this.editPolicyOwner : null
            const isRelationOwner = this.isPolicyOwner(relationship)
            if (isRelationOwner && companyBusinessName) {
              isPolicyOwnerACompany = true
            }
            if (isRelationOwner && !companyBusinessName) {
              isPolicyOwnerACompany = false
            }
            return (
              isRelationOwner && (
                <MemberListContainer
                  key={`${relationship.relatedParty.firstName}-${
                    relationship.relatedParty.lastName
                  }-${relationship.role.join('_')}`}
                  caption={this.getCaption(relationship)}
                  editHandler={editHandler}
                  removeHandler={this.removePolicyOwner}
                  updatePolicyOwnerHandler={this.setAsPolicyOwner}
                  fields={fields}
                  members={[
                    {
                      name:
                        companyBusinessName ||
                        `${relationship.relatedParty.firstName} ${relationship.relatedParty.lastName}`,
                      editIdentifier: getIdentifier(relationship),
                      deleteIdentifier: getIdentifier(relationship),
                      updateIdentifier: getIdentifier(relationship),
                      role: companyBusinessName ? POLICY_MEMBER_BUSINESS : POLICY_MEMBER_INDIVIDUAL,
                      companyBusinessName,
                      isPrimary: relationship.isPrimary,
                    },
                  ]}
                  referMember
                />
              )
            )
          })}
        {(typeof isPolicyOwnerACompany === 'undefined' || !isPolicyOwnerACompany) &&
          relationships &&
          !!relationships.length &&
          this.getTotalPolicyOwners(relationships) < 6 && (
            <Fragment>
              {lifeAssured && this.isLifeAssureNotOwner(relationships) && (
                <SidebarActionItem
                  title={`Add ${lifeAssured.relatedParty.firstName} ${lifeAssured.relatedParty.lastName} ${addLifeInsuredLabelSuffix}`}
                  clickHandler={() => {
                    this.addLifeAssuredAsPolicyOwner(
                      policyInstanceNo,
                      getIdentifier(lifeAssured),
                      lifeAssured
                    )
                  }}
                />
              )}
              {relationships.map(relationship => {
                const isPolicyMember = this.isPolicyMember(relationship)
                const isRelationOwner = this.isPolicyOwner(relationship)
                return (
                  isPolicyMember &&
                  !isRelationOwner &&
                  !this.isLifeAssuredOwner(relationship) &&
                  totalPolicyOwners > 0 &&
                  isNonSuper &&
                  !isIPorBECover && (
                    <SidebarActionItem
                      title={`Add ${relationship.relatedParty.firstName} ${relationship.relatedParty.lastName}`}
                      clickHandler={this.editPolicyOwner({
                        editIdentifier: getIdentifier(relationship),
                        role: POLICY_MEMBER_INDIVIDUAL,
                      })}
                    />
                  )
                )
              })}
              {relationships.map(relationship => {
                const companyBusinessName = get(relationship, 'relatedParty.businessName')
                return (
                  !this.isLifeAssuredOwner(relationship) &&
                  !this.isPolicyOwner(relationship) &&
                  isNonSuper &&
                  typeof isPolicyOwnerACompany === 'undefined' &&
                  (!isIPorBECover || companyBusinessName) && (
                    <SidebarActionItem
                      title={
                        companyBusinessName
                          ? `Add ${companyBusinessName}`
                          : `Add ${relationship.relatedParty.firstName} ${relationship.relatedParty.lastName}`
                      }
                      clickHandler={this.editPolicyOwner({
                        editIdentifier: getIdentifier(relationship),
                        role: companyBusinessName
                          ? POLICY_MEMBER_BUSINESS
                          : POLICY_MEMBER_INDIVIDUAL,
                      })}
                    />
                  )
                )
              })}
              {isNonSuper && !isIPorBECover && (
                <SidebarActionItem
                  title={addIndividualActionLabel}
                  clickHandler={() =>
                    this.addNewPolicyOwner(0, {
                      identifier: {
                        role: REF_NO,
                        value: '',
                      },
                    })
                  }
                />
              )}
            </Fragment>
          )}

        {isNonSuper &&
          relationships &&
          !!relationships.length &&
          (this.getTotalPolicyOwners(relationships) < 1 || !isNonSuper) &&
          !this.hasBusinessOwner(relationships) && (
            <SidebarActionItem
              title={addBusinessActionLabel}
              clickHandler={() =>
                this.addNewPolicyOwnerCompany(1, {
                  identifier: {
                    role: REF_NO,
                    value: '',
                  },
                })
              }
            />
          )}
      </Wrapper>
    )
  }
}

export const mapStateToProps = ({ createQuote, sidebar, abnLookup }) => ({
  createQuote,
  sidebar,
  abnLookup,
  lifeAssured: getLifeAsssured(createQuote),
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PolicyOwnerAdd)
