/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconDocument16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2h2v2H7V2zM5 0h6v2h3v13H2V2h3V0zm0 4H4v9h8V4h-1v2H5V4zm0 5h6V7H5v2zm0 1v2h4v-2H5z"
      fill="#000"
    />
  </Icon>
)

export default IconDocument16
