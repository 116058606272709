// @flow
import React, { Fragment } from 'react'
import { Tooltip } from '@mlcl-digital/mlcl-design'
import { createEvent } from '../../../../utils/telemetry'

// helpers.
import { required, mobilePhone, email, regexTest, isSBCode } from '../../../../utils/formUtils'
// components.
import UserNameForm from '../UserNameForm'
import PasswordForm from '../PasswordForm'
import OtpForm from '../OtpForm'
import ResetPasswordForm from '../ResetPasswordForm'
import PasswordCriteria from '../PasswordCriteria'
import MFAForm from '../MFAForm'
import ForgottonUserNameForm from '../ForgottonUsernameForm'
import ForgottonUserNameConfirmationComponent from '../ForgottonUsernameForm/ConfirmationComponent'

// utils
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { getMaskedFactorType } from '../../../../utils/bitMaskingUtil'

import passwordCriteriaMapping from '../passwordCriteriaMapping'
// field constants.
export const FORM_ID = 'loginForm'
export const USERNAME_FIELD_KEY = 'username'
export const PASSWORD_FIELD_KEY = 'password'
export const NEW_PASSWORD_FIELD_KEY = 'newPassword'
export const CONFIRM_NEW_PASSWORD_FIELD_KEY = 'confirmNewPassword'
export const OTP_FIELD_KEY = 'otp'
export const FORGOTTEN_USERNAME_FORM_KEY = 'forgottenUserName'

type passwordDescriptionProps = {
  form: Object,
  fields: Object,
}

type UserNameDescriptionProps = {
  fields: Object,
  // @FIXME: this is throwing an error even though I can see it being used
  // eslint-disable-next-line react/no-unused-prop-types
  authentication: Object,
}

// Function to get condition for schema for reset password input field
const getPasswordFieldCondition = (fields, form, passwordCriteria) => {
  const condition = [{ condition: required, errorMsg: fields.resetPasswordEmptyError }]
  // As passwordCriteria is received from verify code API, it is not present initially
  if (!passwordCriteria) {
    return condition
  }
  // Dynamic condition for password as per API response
  Object.keys(passwordCriteria).forEach(criteria => {
    condition.push({
      condition: (value: string) =>
        !passwordCriteriaMapping(fields)[criteria].condition(value, passwordCriteria[criteria]),
    })
  })
  return condition
}

const PasswordDescription = (props: passwordDescriptionProps) => {
  const { form, fields } = props
  return (
    <Fragment>{`${fields.passwordIntro.value} ${
      form.fields[USERNAME_FIELD_KEY] ? form.fields[USERNAME_FIELD_KEY].value : ''
    }`}</Fragment>
  )
}

const UserNameDescription = (props: UserNameDescriptionProps) => {
  const { fields } = props

  return (
    <>
      <>{renderTextField(fields.usernameIntro, true)}</>
      {fields.UsernameIdTooltip && (
        <Tooltip id="adviserId" place="top" className="adviserInfo">
          {renderTextField(fields.UsernameIdTooltip, true)}
        </Tooltip>
      )}
    </>
  )
}

const OTPDescription = (props: UserNameDescriptionProps) => {
  const {
    fields: { otpDescription, otpDescriptionEmail },
    authentication: { isMobileNumberExists, isSupportStaff },
  } = props

  // If mobile number not present for customer, OTP is sent to email
  return (
    <Fragment>
      {renderTextField(
        getMaskedFactorType(isMobileNumberExists, isSupportStaff)
          ? otpDescription
          : otpDescriptionEmail
      )}
    </Fragment>
  )
}

const ForgotUserNameDescription = props => (
  <>{renderTextField(props.fields.forgotUsernameSubtitle)}</>
)

export const userNameFormSchema = (fields, isAdviserPortal, isLoggedInUser = false) => ({
  [USERNAME_FIELD_KEY]: {
    condition: [
      required,
      { condition: isAdviserPortal && isSBCode, errorMsg: fields.InValidUserName },
      { condition: () => isLoggedInUser, errorMsg: fields.AlreadyLoggedInUserError },
    ],
    errorMsg: fields.usernameError,
  },
})

export const userNameComponentSchema = (
  fields,
  handleNextClick,
  handleLoadForgottenUsernameForm
) => ({
  heading: fields.heading,
  descriptionComponent: UserNameDescription,
  inputComponent: UserNameForm,
  formFieldKey: USERNAME_FIELD_KEY,
  submitButtonLabel: fields.submitButtonText,
  loginLinkTooltip: fields.loginLinkTooltip,
  handleNextButtonClick: () => handleNextClick(),
  handleLoadForgottenUsernameForm,
})

export const passwordComponentSchema = (
  fields,
  handleNextClick,
  handleBackButtonClick,
  handleForgotPasswordClick
) => ({
  heading: fields.heading,
  descriptionComponent: PasswordDescription,
  inputComponent: PasswordForm,
  hasBackButton: true,
  backButtonLabel: fields.backButtonLabel,
  formFieldKey: PASSWORD_FIELD_KEY,
  submitButtonLabel: fields.submitButtonText,
  handleNextButtonClick: () => handleNextClick(),
  handleBackButton: () => handleBackButtonClick(),
  handleForgotPassword: () => handleForgotPasswordClick(),
})

export const passwordFormSchema = fields => ({
  [PASSWORD_FIELD_KEY]: {
    condition: required,
    errorMsg: fields.passwordError,
  },
})

export const otpComponentSchema = (fields, handleNextClick, handleBackButtonClick) => ({
  heading: fields.heading,
  descriptionComponent: OTPDescription,
  inputComponent: OtpForm,
  hasBackButton: true,
  backButtonLabel: fields.backButtonLabel,
  formFieldKey: OTP_FIELD_KEY,
  submitButtonLabel: fields.next,
  handleNextButtonClick: () => handleNextClick(),
  handleBackButton: () => handleBackButtonClick(),
})

export const otpFormSchema = fields => ({
  [OTP_FIELD_KEY]: {
    condition: required,
    errorMsg: fields.otpError,
  },
})

export const resetPasswordComponentSchema = (fields, handleNextClick, handleBackButtonClick) => ({
  heading: fields.resetPasswordHeading,
  descriptionComponent: PasswordCriteria,
  inputComponent: ResetPasswordForm,
  formFieldKey: NEW_PASSWORD_FIELD_KEY,
  formSecondaryFieldKey: CONFIRM_NEW_PASSWORD_FIELD_KEY,
  submitButtonLabel: fields.confirm,
  handleNextButtonClick: () => handleNextClick(),
  hasBackButton: true,
  backButtonLabel: fields.backButtonLabel,
  handleBackButton: () => handleBackButtonClick(),
})

export const comparePasswordFields = fields => {
  let hasError
  if (
    fields &&
    fields[NEW_PASSWORD_FIELD_KEY] &&
    fields[CONFIRM_NEW_PASSWORD_FIELD_KEY] &&
    fields[NEW_PASSWORD_FIELD_KEY].value &&
    fields[CONFIRM_NEW_PASSWORD_FIELD_KEY].value
  ) {
    hasError = fields[NEW_PASSWORD_FIELD_KEY].value !== fields[CONFIRM_NEW_PASSWORD_FIELD_KEY].value
  } else {
    hasError = false
  }
  return hasError
}
export const resetPasswordFormSchema = (fields, form, passwordCriteria) => ({
  [NEW_PASSWORD_FIELD_KEY]: {
    condition: [
      required,
      {
        condition: getPasswordFieldCondition(fields, form, passwordCriteria),
        errorMsg: fields.passwordCriteriaError,
      },
      {
        condition: (value, formFields) => comparePasswordFields(formFields),
        errorMsg: fields.passwordMatchError,
      },
    ],
    errorMsg: fields.passwordCriteriaError,
  },
  [CONFIRM_NEW_PASSWORD_FIELD_KEY]: {
    condition: [
      required,
      {
        condition: getPasswordFieldCondition(fields, form, passwordCriteria),
        errorMsg: fields.passwordCriteriaError,
      },
      {
        condition: (value, formFields) => comparePasswordFields(formFields),
        errorMsg: fields.passwordMatchError,
      },
    ],
    errorMsg: fields.passwordCriteriaError,
  },
})

export const mfaComponentSchema = (fields, handleNextClick, handleBackButtonClick) => ({
  heading: fields.heading,
  descriptionComponent: OTPDescription,
  inputComponent: MFAForm,
  hasBackButton: true,
  backButtonLabel: fields.backButtonLabel,
  formFieldKey: OTP_FIELD_KEY,
  submitButtonLabel: fields.next,
  handleNextButtonClick: () => handleNextClick(),
  handleBackButton: () => handleBackButtonClick(),
})

export const forgottenUserNameComponentSchema = (
  fields,
  handleBackButtonClick,
  handleNextClick
) => ({
  heading: fields.forgotUsernameTitle,
  descriptionComponent: ForgotUserNameDescription,
  inputComponent: ForgottonUserNameForm,
  hasBackButton: true,
  backButtonLabel: fields.backButtonLabel,
  formFieldKey: FORGOTTEN_USERNAME_FORM_KEY,
  submitButtonLabel: fields.next,
  handleBackButton: () => handleBackButtonClick(),
  handleNextButtonClick: () => handleNextClick(),
  hasRecaptcha: true,
})

export const forgottenUserNameConfirmationComponentSchema = (
  fields,
  handleNextButtonClick,
  value
) => ({
  heading: fields.forgotUsernameTitle,
  inputComponent: ForgottonUserNameConfirmationComponent,
  descriptionComponent: () => <></>,
  hasBackButton: false,
  backButtonLabel: fields.backButtonLabel,
  submitButtonLabel: 'Back to login',
  handleNextButtonClick: () => {
    const tagEvent = createEvent({
      GA: {
        category: 'Forgot customer number',
        action: 'Forgotten username - back to login',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Forgot customer number - Back to login',
        },
      },
    })
    tagEvent.end()
    handleNextButtonClick()
  },
  value: value.includes('@')
    ? value
    : `XXXX XXX ${value.substring(value.length - 3, value.length)}`,
})

export const forgottenUserNameFormSchema = fields => ({
  [FORGOTTEN_USERNAME_FORM_KEY]: {
    condition: [
      {
        condition: value => {
          if (regexTest(email, value)) {
            return false
          }
          return !regexTest(mobilePhone, value)
        },
        errorMsg: fields.forgotUsernameError,
      },
      {
        condition: required,
        errorMsg: fields.forgotUsernameError,
      },
    ],
  },
})
