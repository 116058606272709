import { space, iconWidth, colours } from '../../../../../styles'

const styles = {
  base: {
    paddingTop: space(1),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colours.lightestGrey,
    listStyle: 'none',
    '&:first-of-type': {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: colours.lightestGrey,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    marginTop: space(1),
    flex: 1,
    justifySelf: 'left',
  },
  icon: {
    flex: 1,
    width: iconWidth.sm,
    height: iconWidth.sm,
    color: colours.black,
  },
  actionButton: {
    padding: space(1),
  },
}

export default styles
