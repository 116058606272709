// @flow
import { createSelector } from 'reselect'
import { Store } from '../types/store'

export const getAdvisor = (state: Store) => state.advisor

export const getFeaturesEnabledForAdvisor = createSelector(
  [getAdvisor],
  advisor => advisor?.betaTestingFeatures?.featuresEnabled || []
)
