// @flow
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import { createEvent } from '../../../../utils/telemetry'
import { renderTextField } from '../../../../utils/sitecoreUtils'
import { getClassnameProp } from '../../../../utils/stylesUtils'
import { IconChevronLeft16 } from '../../../atoms/Icons'
import { actionCreators } from '../../../../actions'
import styles from './backCTA.styles'

const BackButton = styled(Button)(styles.backButton)
const LeftChevronIcon = styled(IconChevronLeft16)(styles.leftChevronIcon)

type BackCTAProps = {
  fields: Object,
  history: Object,
  alterationType: string,
  className?: string,
}
export const BackCTA = ({ fields, history, alterationType, className }: BackCTAProps) => {
  const dispatch = useDispatch()
  const timelineActiveIndex = useSelector(state => state.timelineWithComponents.activeIndex)
  const handleClick = useCallback(() => {
    if (timelineActiveIndex === 0) {
      history.goBack()
    } else {
      dispatch(actionCreators.timelinePrevState())
    }
    const tagEvent = createEvent({
      GA: {
        category: alterationType,
        action: 'Back to previous screen',
      },
      Splunk: {
        attributes: {
          'workflow.name': `${alterationType} - Back to previous screen`,
        },
      },
    })
    tagEvent.end()
  }, [timelineActiveIndex])
  return (
    <BackButton
      variant="tertiary"
      size="small"
      onClick={handleClick}
      {...getClassnameProp({ className })}
      data-testid="backButton"
    >
      <LeftChevronIcon /> {renderTextField(fields.BackCTA)}
    </BackButton>
  )
}

BackCTA.defaultProps = {
  className: null,
}

export default withRouter(BackCTA)
