// @TODO: add focus for a11y requirements
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-vars */
// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { isBrowser } from '../../../../utils/browserUtils'

import styles from './listBox.styles'

import { IconChevronDown16, IconChevronUp16 } from '../../Icons'

const List = styled('ul')(styles.list)
const ListHeading = styled('h4')(styles.listHeading)
const Wrapper = styled('div')(styles.wrapper)

type listBoxPropTypes = {
  onChange: Function,
  data: Array<Object>,
  value: Object,
  placeholder: string,
  dataLocator: string,
}

type listBoxStateTypes = {
  isMenuOpen: boolean,
}

class ListBox extends PureComponent<listBoxPropTypes, listBoxStateTypes> {
  constructor(props: Object) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
    this.menuBtnClickHandler = this.menuBtnClickHandler.bind(this)
    this.optionClickHandler = this.optionClickHandler.bind(this)
    this.documentClickHandler = this.documentClickHandler.bind(this)
    this.menuClickHandler = this.menuClickHandler.bind(this)
  }

  componentDidMount() {
    // Adding event on document so that dropdown can be closed when clicked outside
    if (isBrowser()) {
      document.addEventListener('click', this.documentClickHandler, false)
    }
  }

  documentClickHandler = () => {
    this.closeMenu()
  }

  menuBtnClickHandler = (event: Object) => {
    event.stopPropagation()
    // Stopping event propagation on native event so that
    // click doesn't reach document when menu btn clicked
    event.nativeEvent.stopImmediatePropagation()
    this.toggleMenu()
  }

  menuClickHandler = (event: Object) => {
    event.stopPropagation()
    // Stopping event propagation so that click event does not
    // reach document when clicked anywhere in dropdown
    event.nativeEvent.stopImmediatePropagation()
  }

  optionClickHandler = (event: Object, option: Object) => {
    const { onChange } = this.props
    onChange(option)
    this.closeMenu()
  }

  toggleMenu = () => {
    const { isMenuOpen } = this.state
    this.setState({
      isMenuOpen: !isMenuOpen,
    })
  }

  closeMenu = () => {
    const { isMenuOpen } = this.state
    if (isMenuOpen) {
      this.setState({
        isMenuOpen: false,
      })
    }
  }

  renderMenuItem = (listSectionData: Object, index: number) => {
    const { dataLocator } = this.props
    const elementKey = get(listSectionData, 'heading.props.field.value', `key-${index}`)

    return (
      <section css={styles.listSection(listSectionData)} key={elementKey}>
        {listSectionData.heading && <ListHeading>{listSectionData.heading}</ListHeading>}
        <List>
          {listSectionData.options.map(option => (
            // @TODO: add keyboard event listener
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <li
              key={`${option.label}-${option.value}`}
              onClick={event => this.optionClickHandler(event, option)}
              // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
              role="option"
              data-locator={`${dataLocator}Option-${option.value}`}
            >
              {option.label}
            </li>
          ))}
        </List>
      </section>
    )
  }

  renderMenu = () => {
    const { data, dataLocator } = this.props
    const { isMenuOpen } = this.state
    return (
      // @TODO: add keyboard event listener
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        css={styles.menu({ isMenuOpen })}
        onClick={this.menuClickHandler}
        role="menu"
        data-locator={`${dataLocator}Menu`}
      >
        {data.map((listSectionData, index) => this.renderMenuItem(listSectionData, index))}
      </div>
    )
  }

  render() {
    const { value, placeholder, dataLocator } = this.props
    const { isMenuOpen } = this.state
    return (
      <Wrapper data-locator={dataLocator}>
        <div>
          <button
            css={styles.menuBtn(this.props)}
            onClick={this.menuBtnClickHandler}
            type="button"
            data-locator={`${dataLocator}MenuBtn`}
          >
            {value ? value.label : placeholder}
            <span aria-hidden="true" css={styles.dropdownIcon(this.props)}>
              {isMenuOpen ? <IconChevronUp16 /> : <IconChevronDown16 />}
            </span>
          </button>
        </div>
        {this.renderMenu()}
      </Wrapper>
    )
  }
}

export default ListBox
