// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'
import { createEvent } from '../../../utils/telemetry'

// components.
import { IconChevronDown32, IconChevronUp32 } from '../../atoms/Icons'
import NavItem from '../../atoms/NavItem'

// hooks.
import useComponentVisible from '../../../hooks/useComponentVisible'

// styles.
import styles from './navDropdown.styles'
import { themeConsumer } from '../../../styles/ThemeContext'
import { getClassnameProp } from '../../../utils/stylesUtils'

// constants
import {
  RETENTION_NAV_ID,
  BENEFICIARIES_NAV_ID,
  CP_MAKE_A_PAYMENT_NAV_ID,
  REPORTING_NAV_ID,
  CLIENT_LIST_NAV_ID,
  PAYMENT_DETAILS_NAV_ID,
} from '../../../constants/navigation'

type NavDropDownProps = {|
  /** Sets the button text */
  buttonText: Node,
  /** List of navigation items */
  items: Array<{
    id: string,
    href: string,
    text: string,
  }>,
  /** handle onToggle for addon operations  */
  onToggle?: Function,
  /** Set the button variant value */
  variant?: 'primary' | 'secondary',
  menuItemClickHandler: Function,
|}

const setVariantStyle =
  (variant: string) =>
  (el: string): string =>
    variant && styles[variant][el]

const Container = styled('div')(styles.base.container, ({ variant }): string =>
  setVariantStyle(variant)('container')
)
const Button = styled('button')(styles.base.button, ({ variant }): string =>
  setVariantStyle(variant)('button')
)

const ButtonText = styled('span')(styles.base.buttonText)
const IndicatorCollapsed = styled(IconChevronDown32)(styles.base.indicator, ({ variant }): string =>
  setVariantStyle(variant)('indicator')
)
const IndicatorExpanded = styled(IndicatorCollapsed.withComponent(IconChevronUp32))()
const List = styled('ul')(styles.base.list, ({ variant }) => setVariantStyle(variant)('list'))

const NavDropDown = (props: NavDropDownProps): Node => {
  const { buttonText, items, variant, onToggle, menuItemClickHandler } = props
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const showDropDownList = () => {
    // show dropdown list
    setIsComponentVisible(!isComponentVisible)
    onToggle()
  }

  const gaEvent = {
    [RETENTION_NAV_ID]: {
      category: 'Clients (retention)',
      action: 'View',
    },
    [BENEFICIARIES_NAV_ID]: {
      category: 'Customer Name - Global Navigation',
      action: 'Update my beneficiaries',
    },
    [CP_MAKE_A_PAYMENT_NAV_ID]: {
      category: 'Make a payment - My insurance',
      action: 'CP - make a payment',
    },
    [REPORTING_NAV_ID]: {
      category: 'Resources (Reporting)',
      action: 'AP - access reporting',
    },
    [CLIENT_LIST_NAV_ID]: {
      category: 'Clients (client list)',
      action: 'AP - client list',
    },
    [PAYMENT_DETAILS_NAV_ID]: {
      category: 'Payment Details',
      action: 'View',
    },
  }

  const handleListItemClick = (e, item) => {
    // hide dropdown list
    setIsComponentVisible(false)
    if (menuItemClickHandler) {
      menuItemClickHandler(e, item)
    }
    // Analytics
    const event = createEvent({
      GA: gaEvent[item.id],
      Splunk: {
        attributes: {
          'workflow.name': gaEvent[item.id]?.category,
        },
      },
    })
    event.end()
  }

  return (
    <Container
      ref={ref}
      data-test-id="navdropdown"
      {...getClassnameProp(props)}
      isOpen={isComponentVisible}
      variant={variant}
    >
      <Button isOpen={isComponentVisible} onClick={showDropDownList} variant={variant}>
        <ButtonText>{buttonText}</ButtonText>
        {isComponentVisible ? (
          <IndicatorExpanded isOpen={isComponentVisible} variant={variant} />
        ) : (
          <IndicatorCollapsed isOpen={isComponentVisible} variant={variant} />
        )}
      </Button>
      <div>
        <List isOpen={isComponentVisible} variant={variant}>
          {items.map(item => (
            <NavItem key={item.text} href={item.href} onClick={e => handleListItemClick(e, item)}>
              {item.text}
            </NavItem>
          ))}
        </List>
      </div>
    </Container>
  )
}

NavDropDown.defaultProps = {
  variant: 'primary',
  onToggle: () => {},
}

export default themeConsumer(NavDropDown, 'navigation')
