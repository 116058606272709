/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconCheckboxOff16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <g clipPath="url(#clip0)">
      <path
        className="path1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2H2v12h12V2zM0 0v16h16V0H0z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath>
        <path className="path2" fill="#000" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default IconCheckboxOff16
