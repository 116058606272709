// @flow
import { PERIOD_UNIT_AGE, PERIOD_UNITS } from '../constants/benefit'

export const sortPeriodValues = (periodValues: Array<Object> = [], isAsc: boolean = true) => {
  const agePeriods = periodValues
    .filter(period => period.unit === PERIOD_UNIT_AGE)
    .map(period => ({
      ...period,
      label: `${period.unit} ${period.value}`,
    }))
  agePeriods.sort((a, b) => (isAsc ? a.value - b.value : b.value - a.value))
  const otherPeriods = periodValues
    .filter(period => period.unit !== PERIOD_UNIT_AGE)
    .map(period => ({
      ...period,
      label: `${period.value} ${period.unit}`,
    }))
  // sorting by converting all values in days
  otherPeriods.sort((periodA, periodB) =>
    isAsc
      ? periodA.value * (PERIOD_UNITS[periodA.unit] || 1) -
        periodB.value * (PERIOD_UNITS[periodB.unit] || 1)
      : periodB.value * (PERIOD_UNITS[periodB.unit] || 1) -
        periodA.value * (PERIOD_UNITS[periodA.unit] || 1)
  )
  if (isAsc) {
    return [...otherPeriods, ...agePeriods]
  }
  return [...agePeriods, ...otherPeriods]
}
