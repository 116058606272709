// @flow
import { SET_LIFE_ASSURED_NAME } from '../types/lifeAssured'

export const setLifeAssuredName = (firstName: String, lastName: String) => ({
  type: SET_LIFE_ASSURED_NAME,
  payload: {
    firstName,
    lastName,
  },
})
