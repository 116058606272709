/* eslint-disable no-unused-vars */
// redux.
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
import React from 'react'
import get from 'lodash/get'

// creators
import { closeSidebar, previousPanel, setNextTabPanel } from '../../../../actions/creators/sidebar'
import { updateSmsfDetails } from '../../../../actions/creators/createQuote'

// schemas
import SMSF_DETAILS_SCHEMA, {
  FORM_ID as SMSF_DETAILS_FORM_ID,
} from '../../Smsf/components/SmsfDetails/smsfDetails.schema'
import SMSF_TRUSTEE_SCHEMA, {
  FORM_ID as SMSF_TRUSTEE_FORM_ID,
} from '../../Smsf/components/SmsfTrustee/smsfTrustee.schema'
import ADD_SMSF_TRUSTEE_SCHEMA, {
  FORM_ID as ADD_SMSF_TRUSTEE_FORM_ID,
} from '../../Smsf/components/AddTrustee/addTrustee.schema'
import { initForms as smsfTrusteeInitForm } from '../../Smsf/components/SmsfTrustee/smsfTrusteeInitUtils'
import ADD_DIRECTOR_SCHEMA, {
  FORM_ID as ADD_DIRECTOR_FORM_ID,
} from '../../AddPayers/components/AddDirector/addDirector.schema'

// components.
import Smsf from '../../Smsf'
import AddDirector from '../../AddPayers/components/AddDirector'
import { formToMemberEntityDirector } from '../../../../utils/addBusinessUtils'
import {
  formSubmit,
  formInit,
  formUpdateField,
  formValidate,
  deleteForms,
} from '../../../../actions/creators/forms'
import AddTrustee from '../../Smsf/components/AddTrustee'
import {
  POLICY_MEMBER_COMPANY,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_MEMBER_INDIVIDUAL,
} from '../../../../constants/policies'

const SIDEBAR_SCHEMA = (fields = null, sidebar = {}) => [
  {
    sitecoreFieldHeadingKey: 'policyOwnerSelfManagedSuperFundDetailsHeader',
    component: Smsf,
    visible: false,
    callbackKey: 'handleNewClient',
    props: {
      handleNewClient: next => next(),
      secondaryHeader: true,
      alwaysReset: true,
    },
    nextText: get(fields, 'saveBeneficiariesButtonText.value', 'Save'),
    handleNext: (next, dispatch, state) => {
      const {
        addressLookup,
        forms,
        createQuote,
        abnLookup,
        abnLookup: { isAbnCancelled },
        sidebar: { panelProps: sidebarPanelProps },
      } = state

      const countriesPhoneCode = get(state, 'masterList.data.countryCodeList', [])
      const isManualResidential = addressLookup.smsfAddress && addressLookup.smsfAddress.isManual

      const detailsSchema = SMSF_DETAILS_SCHEMA(
        {
          isManualResidential,
        },
        fields
      )

      if (isAbnCancelled) return

      const updateSmsfDetailsFn = (smsfTrusteeData, smsfTrusteeForm, smsfDetailsData) => {
        dispatch(
          updateSmsfDetails({
            smsfTrusteeData: {
              ...smsfTrusteeData,
              contactNumberCountryCode: get(
                forms,
                `${SMSF_TRUSTEE_FORM_ID}.fields.contactNumberCode.value.countryCode`
              ),
            },
            smsfDetailsData,
          })
        )
        dispatch(closeSidebar())
        dispatch(
          deleteForms([
            SMSF_DETAILS_FORM_ID,
            SMSF_TRUSTEE_FORM_ID,
            ADD_SMSF_TRUSTEE_FORM_ID,
            ADD_DIRECTOR_FORM_ID,
          ])
        )
      }

      const updateTrusteeDetails = smsfDetailsData => {
        const { policyInstanceNo } = sidebarPanelProps
        const activeQuote = createQuote.quotes[createQuote.activeIndex]
        const policy = activeQuote.policyStructure.find(
          p => p.policyInstanceNo === policyInstanceNo
        )
        const relationship = policy.relationships.find(rel =>
          rel.role.includes(POLICY_RELATIONSHIPS_SMSF)
        )
        const isCompany = !!get(relationship, 'companies[0].directors[0]')
        let trusteeSchemaFields
        if (isCompany) {
          trusteeSchemaFields = {
            isSoleDirector: { value: false },
            trustee: { value: POLICY_MEMBER_COMPANY },
          }
        } else {
          trusteeSchemaFields = {
            trustee: { value: POLICY_MEMBER_INDIVIDUAL },
          }
        }
        const trusteeSchema = SMSF_TRUSTEE_SCHEMA(
          forms[SMSF_TRUSTEE_FORM_ID] ? forms[SMSF_TRUSTEE_FORM_ID].fields : trusteeSchemaFields
        )
        if (!forms[SMSF_TRUSTEE_FORM_ID])
          dispatch(
            formInit(
              SMSF_TRUSTEE_FORM_ID,
              trusteeSchema,
              smsfTrusteeInitForm({ createQuote, sidebar, abnLookup, countriesPhoneCode })
            )
          )

        dispatch(setNextTabPanel(1))
        dispatch(
          formSubmit(SMSF_TRUSTEE_FORM_ID, trusteeSchema, updateSmsfDetailsFn, smsfDetailsData)
        )
      }
      dispatch(setNextTabPanel(0))
      dispatch(formSubmit(SMSF_DETAILS_FORM_ID, detailsSchema, updateTrusteeDetails))
    },
  },
  [
    {
      sitecoreFieldHeadingKey: 'policyOwnerDirectorDetailsHeader',
      component: AddDirector,
      visible: false,
      callbackKey: 'handleNewClient',
      props: {
        secondaryHeader: true,
        alwaysReset: true,
      },
      nextText: get(fields, 'nulisAddTFNButton.value', 'Add'),
      handleNext: (next, dispatch, state) => {
        const { addressLookup, forms } = state

        const schemaValue = {
          isManualPostal: addressLookup.address && addressLookup.address.isManual,
          sameAsCompanyAddress: forms.director.fields.sameAsCompanyAddress.value,
          address: forms.director.fields.address.value,
        }

        const schema = ADD_DIRECTOR_SCHEMA(fields)(schemaValue)

        const addBusinessDirectorFn = formData => {
          const trusteeSchema = SMSF_TRUSTEE_SCHEMA({
            isSoleDirector: false,
            ...forms[SMSF_TRUSTEE_FORM_ID].fields,
          })

          const alreadyAddedDirectors =
            get(forms[SMSF_TRUSTEE_FORM_ID], 'fields.directors.value') || []
          const memberEntity = formToMemberEntityDirector(formData, true)
          let trusteeFound = false

          if (formData.identifiers) {
            alreadyAddedDirectors.forEach((director, directorIndex) => {
              if (
                director.relatedParty &&
                director.relatedParty.identifiers.some(
                  identifier => identifier.value === formData.identifiers
                )
              ) {
                trusteeFound = true
                alreadyAddedDirectors[directorIndex] = memberEntity
              }
            })
          }

          if (!trusteeFound) {
            alreadyAddedDirectors.push(memberEntity)
          }

          dispatch(
            formUpdateField(SMSF_TRUSTEE_FORM_ID, 'directors', {
              error: { error: false },
              value: [...alreadyAddedDirectors],
            })
          )
          dispatch(formValidate(SMSF_TRUSTEE_FORM_ID, trusteeSchema))
          dispatch(previousPanel())
        }
        dispatch(formSubmit(ADD_DIRECTOR_FORM_ID, schema, addBusinessDirectorFn))
      },
    },
    {
      sitecoreFieldHeadingKey: 'policyOwnerTrusteeDetailsHeader',
      component: AddTrustee,
      visible: false,
      callbackKey: 'handleNewClient',
      props: {
        secondaryHeader: true,
        alwaysReset: true,
      },
      nextText: get(fields, 'nulisAddTFNButton.value', 'Add'),
      handleNext: (next, dispatch, state) => {
        const { addressLookup, forms } = state

        const schemaValue = {
          isManualPostal: addressLookup.address && addressLookup.address.isManual,
          sameAsCompanyAddress: forms[ADD_SMSF_TRUSTEE_FORM_ID].fields.sameAsCompanyAddress.value,
          address: forms[ADD_SMSF_TRUSTEE_FORM_ID].fields.address.value,
        }

        const schema = ADD_SMSF_TRUSTEE_SCHEMA(fields)(schemaValue)

        const addSmsfTrusteeFn = formData => {
          const trusteeSchema = SMSF_TRUSTEE_SCHEMA({
            isSoleDirector: false,
            ...forms[SMSF_TRUSTEE_FORM_ID].fields,
          })

          const alreadyAddedTrustees =
            get(forms[SMSF_TRUSTEE_FORM_ID], 'fields.individuals.value') || []
          const addTrusteeForm = forms[ADD_SMSF_TRUSTEE_FORM_ID]
          const memberEntity = formToMemberEntityDirector(
            {
              ...formData,
              contactNumberCountryCode: get(
                addTrusteeForm,
                'fields.contactNumberPhoneCode.value.countryCode'
              ),
              secondaryNumberCountryCode: get(
                addTrusteeForm,
                'fields.secondaryNumberPhoneCode.value.countryCode'
              ),
            },
            true,
            true
          )
          let trusteeFound = false

          if (formData.identifiers) {
            alreadyAddedTrustees.forEach((trustee, trusteeIndex) => {
              if (
                trustee.relatedParty.identifiers.some(
                  identifier => identifier.value === formData.identifiers
                )
              ) {
                trusteeFound = true
                alreadyAddedTrustees[trusteeIndex] = memberEntity
              }
            })
          }

          if (!trusteeFound) {
            if (!alreadyAddedTrustees.length) {
              memberEntity.isPrimary = true
            } else {
              memberEntity.isPrimary = false
            }
            alreadyAddedTrustees.push(memberEntity)
          }

          dispatch(
            formUpdateField(SMSF_TRUSTEE_FORM_ID, 'individuals', {
              error: { error: false },
              value: [...alreadyAddedTrustees],
            })
          )
          dispatch(formValidate(SMSF_TRUSTEE_FORM_ID, trusteeSchema))
          dispatch(previousPanel())
        }
        dispatch(formSubmit(ADD_SMSF_TRUSTEE_FORM_ID, schema, addSmsfTrusteeFn))
      },
    },
  ],
]

export default SIDEBAR_SCHEMA
