import get from 'lodash/get'

import { getActivePolicy } from '../../../../../../utils/quoteUtils'

export const rolloverPaymentData = props => {
  const {
    createQuote: { quotes, activeIndex },
    sidebar,
    form,
  } = props

  const { policyStructure } = quotes.length ? quotes[activeIndex] : {}
  const activePolicyInstanceNo = getActivePolicy(sidebar)
  const activePolicy = policyStructure
    ? policyStructure.find(policy => policy.policyInstanceNo === activePolicyInstanceNo)
    : {}

  return {
    membershipNumber: get(
      activePolicy,
      'paymentInstruction.fundDetails.externalMembershipNo',
      get(form, 'fields.accountNumber.value', '')
    ),
    fundType: 'fundName',
    fundName: get(
      activePolicy,
      'paymentInstruction.fundDetails.externalFundName',
      get(form, 'fields.fundName.value', '')
    ),
    productName: get(
      activePolicy,
      'paymentInstruction.fundDetails.externalFundProductName',
      get(form, 'fields.productName.value', '')
    ),
    usi: get(
      activePolicy,
      'paymentInstruction.fundDetails.uniqueSuperannuationId',
      get(form, 'fields.usi.value', '')
    ),
    abn: get(
      activePolicy,
      'paymentInstruction.fundDetails.externalFundABN',
      get(form, 'fields.abn.value', '')
    ),
  }
}
