import { createSelector } from 'reselect'
import { getMasterData, getAlterationPolicies } from './common.selectors'
import { getRulesForAlterationPolicies } from './altsDeclaration.selectors'
import { PortfolioAssessment } from '../types/alterations'
import { ClientPolicy, Relationship } from '../types/ClientPolicies'
// @ts-expect-error file not in typescript
import { fetchAgents } from '../utils/relationshipUtils'

/*
  checks if any agent(adviser) in any policy is one among list of
  advisers (premiumCalculatorEligibleCodes from masterdata) to allow
  premium calculator feature
*/
export const getIsPremiumCalculatorEligible = createSelector(
  [getAlterationPolicies, getMasterData],
  (alterationPolicies, { premiumCalculatorEligibleCodes }) =>
    alterationPolicies.length > 0 &&
    alterationPolicies.every((alterationPolicy: ClientPolicy) =>
      (fetchAgents(alterationPolicy?.policy?.relationships) || []).some((agent: Relationship) =>
        premiumCalculatorEligibleCodes?.some(item => item.value === agent.bancsCustomerNo)
      )
    )
)

// fetch validation message if premium calculator can't be used based on policy status
export const getPremiumCalculatorValidationMessage = createSelector(
  [getRulesForAlterationPolicies, getMasterData],
  (rules, { premiumCalculatorValidationMessages }) => {
    const criteriaList: Array<{ name: keyof PortfolioAssessment; invalidValue?: string }> = [
      { name: 'customerInfo_HasInForcePolicy', invalidValue: 'N' },
      { name: 'customerInfo_HasPendingClaim' },
      { name: 'customerInfo_hasSMBBenefit' },
      { name: 'customerInfo_hasHybridBenefitPeriod' },
      { name: 'decrease_EligibleForCustomer', invalidValue: 'N' },
    ]
    let validationMessage = ''
    const assesment: PortfolioAssessment = rules?.businessData?.assesment ?? {}

    criteriaList.some(({ name, invalidValue }) => {
      const assesmentVal = assesment[name]
      if (assesmentVal && assesmentVal === (invalidValue || 'Y')) {
        validationMessage =
          premiumCalculatorValidationMessages.find(message => message.code === name)?.message ?? ''
        return true
      }
      return false
    })
    return validationMessage
  }
)

// fetch validation message if cover manager can't be used based on policy status for adviser
export const getCoverManagerValidationMessageForAdviser = createSelector(
  [getRulesForAlterationPolicies, getMasterData],
  (rules, { coverManagerAdviserValidationMessages }) => {
    const criteriaList: Array<{ name: keyof PortfolioAssessment; invalidValue?: string }> = [
      { name: 'customerInfo_HasInForcePolicy', invalidValue: 'N' },
      { name: 'customerInfo_HasPendingClaim' },
      { name: 'decrease_EligibleForCustomer', invalidValue: 'N' },
    ]
    let validationMessage = ''
    const assesment: PortfolioAssessment = rules?.businessData?.assesment ?? {}

    criteriaList.some(({ name, invalidValue }) => {
      const assesmentVal = assesment[name]
      if (assesmentVal && assesmentVal === (invalidValue || 'Y')) {
        validationMessage =
          coverManagerAdviserValidationMessages.find(message => message.code === name)?.message ??
          ''
        return true
      }
      return false
    })
    return validationMessage
  }
)

// checks if premium calculator tool get started button be enabled or not
export const getPremiumCalculatorStartedStatus = createSelector(
  [getRulesForAlterationPolicies],
  rules => rules?.businessData?.assesment?.decrease_EligibleForCustomer === 'Y'
)
