// @flow
import { pathOr } from 'lodash/fp'

import { PAYMENT_DRAW_DATE } from '../../../../../../constants/forms'
import { accountNumber, optional } from '../../../../../../utils/formUtils'

import { PAYER_TYPE_INDIVIDUAL } from '../../../constants'

export const FORM_ID: string = 'smsfPayment'
export const getAccountType = forms =>
  pathOr(PAYER_TYPE_INDIVIDUAL, `${FORM_ID}.fields.accountType.value.value`, forms)

const schema = (fields, accountNo) => ({
  accountNumber: {
    condition: [
      {
        condition: optional,
      },
      {
        condition: accountNumber,
        errorMsg: accountNo && fields && fields.AccountNumberErrorMsg,
      },
    ],
  },
  paymentDrawDate: {
    condition: optional,
    options: PAYMENT_DRAW_DATE,
  },
})

export default schema
