/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconQuestion16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.941c0-.21.101-.425.405-.623.318-.207.793-.338 1.3-.315.501.022.948.192 1.257.477.289.267.538.709.538 1.461 0 .206-.062.365-.243.586-.1.122-.228.252-.397.414l-.15.142c-.13.122-.278.262-.417.401-.416.416-.878.947-1.228 1.685-.352.742-.565 1.641-.565 2.772h2c0-.869.162-1.47.373-1.916.212-.449.5-.793.834-1.127a15.776 15.776 0 0 1 .466-.446l.069-.065c.182-.174.382-.373.563-.594.382-.467.695-1.058.695-1.852 0-1.248-.438-2.244-1.18-2.93-.723-.667-1.651-.967-2.525-1.006-.868-.04-1.768.173-2.482.637C4.586 3.116 4 3.901 4 4.942h2zm1.5 8c-1 0-1.5.5-1.5 1s.5 1 1.5 1 1.5-.5 1.5-1-.5-1-1.5-1z"
      fill="#000"
    />
  </Icon>
)

export default IconQuestion16
