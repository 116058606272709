import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, fontWeights, space, fontSize, mediaQueries } = Variables

const styles = {
  bar: inSidebar => ({
    width: '100%',
    marginTop: inSidebar ? '0' : space(1),
    marginBottom: inSidebar ? '0' : space(4),
    display: 'flex',
    flexDirection: 'row',
  }),
  wrap: inSidebar => ({
    width: inSidebar ? space(37, true) : 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),

  button: () => ({
    marginLeft: space(3),
  }),

  label: {
    marginBottom: space(1),
    color: colours.mediumGrey,
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    marginLeft: space(6),
    marginRight: space(6),
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
    },
  },
  input: () => ({
    input: {
      background: colours.tertiarySix,
      '&::placeholder': {
        color: colours.tertiaryTwo,
        fontWeight: fontWeights.semiBold,
      },
    },
  }),
  icon: {
    color: colours.tertiarySix,
    background: 'inherit',
  },
}
export default styles
