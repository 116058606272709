// @flow
import React from 'react'
import { Button } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import get from 'lodash/get'
import styles from './CalculateQuoteErrorModal.styles'
import Accordion from '../Accordion'
import Modal from '../Modal'
import Heading from '../../atoms/Heading'
import { renderTextField } from '../../../utils/sitecoreUtils'

const SubHeading = styled(Heading)(styles.subHeading)
const AccordionContainer = styled('section')(styles.accordion)
const AccordionHeading = styled(Heading)(styles.accordionHeading)
const AccordionFieldset = styled('fieldset')(styles.accordionFieldset)

type RenderErrorPropTypes = {
  fields: Object,
  formattedError: Array<Object>,
  isOpen: boolean,
  handleModalClose: Function,
  modalCTA: string,
}

export const CalculateQuoteErrorModal = ({
  fields,
  formattedError,
  isOpen,
  handleModalClose,
  modalCTA,
}: RenderErrorPropTypes) => {
  const calQuoteErrorModalLabelValue = get(fields, 'CalculateQuoteErrorModalLabel.value')

  const {
    CalculateQuoteErrorModalLabel,
    HideCalculateQuoteErrorModalLabel,
    CalculateQuoteMultipleErrorModalHeading,
    CalculateQuoteErrorModalHeading,
  } = fields

  return (
    <Modal
      isOpen={isOpen}
      title={
        formattedError.length > 1
          ? CalculateQuoteMultipleErrorModalHeading
          : CalculateQuoteErrorModalHeading
      }
      onClose={handleModalClose}
    >
      <div>
        {formattedError.length &&
          formattedError.map(error => (
            <div key={error.errorTitle}>
              {error.errorTitle ? (
                <div>
                  {error.errorTitle}
                  <AccordionContainer>
                    <Accordion
                      label={
                        <AccordionHeading size="3">
                          {renderTextField(CalculateQuoteErrorModalLabel)}
                        </AccordionHeading>
                      }
                      labelKey={calQuoteErrorModalLabelValue}
                      chosenAccordion={calQuoteErrorModalLabelValue}
                      removeIcon
                      toggleLabel={
                        <AccordionHeading size="3">
                          {renderTextField(HideCalculateQuoteErrorModalLabel)}
                        </AccordionHeading>
                      }
                    >
                      <AccordionFieldset>{error.errorContent}</AccordionFieldset>
                    </Accordion>
                  </AccordionContainer>
                </div>
              ) : (
                <SubHeading size="6">{error.errorContent}</SubHeading>
              )}
            </div>
          ))}
      </div>
      <Button type="secondary" onClick={handleModalClose}>
        {renderTextField(modalCTA)}
      </Button>
    </Modal>
  )
}
export default CalculateQuoteErrorModal
