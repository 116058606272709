import { fontSize, colours, fontWeights, space } from '../../../../../../styles'

const link = {
  padding: 0,
  height: 'auto',
  background: 'none',

  '&:hover': {
    background: 'none',
  },

  '&:focus': {
    background: 'none',
  },

  '& svg': {
    color: colours.black,
  },
}

const styles = {
  loadingListContainer: {
    minHeight: space(5, true),
  },
  loadingListCaption: {
    fontSize: fontSize.xxs,
    margin: 0,
    marginBottom: space(-2, true),
  },
  loadingList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  loadingListItem(showBorder = true) {
    return {
      display: 'flex',
      alignItems: 'center',
      color: colours.black,
      paddingBottom: space(2, true),
      paddingTop: space(2, true),
      borderBottom: showBorder ? `1px solid ${colours.lightestGrey}` : 'none',
      marginBottom: showBorder ? space(2, true) : 0,
    }
  },
  miscDetails: {
    fontSize: fontSize.xs,
    fontWeight: fontWeights.bold,
    marginRight: 'auto',
  },
  editLoading: {
    ...link,
    marginRight: space(2, true),
  },
  removeLoading: {
    ...link,
  },
}

export default styles
