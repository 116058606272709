// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Component } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Input } from '@mlcl-digital/mlcl-design'

// redux imports
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../actions'

// atoms.
import Button from '../../../../../atoms/Button'

// molecules
import AddressLookup from '../../../../../molecules/AddressLookUp'

// schema.
import SCHEMA, { FORM_ID } from './quoteUpdateAdviserDetails.schema'

// helpers.
import { errorCheck, getValue, generateFieldsFromData } from '../../../../../../utils/formUtils'
import {
  getFullAddressWithState,
  getEmail,
  getPhoneNumber,
  getPreferredItemBreakdown,
  isPartyTypeOrg,
} from '../../../../../../utils/contactUtils'

// constants
import { COUNTRY_CODE } from '../../../../../../constants/policies'

// styles.
import styles from './quoteUpdateAdviserDetails.styles'

type QuoteUpdateAdviserDetailsProps = {
  // To render all labels from sitecore content editor
  fields: Object<Object>,
  // forms
  form: Object<Object>,
  actions: {
    formInit: Function,
    formUpdateField: Function,
    formUpdate: Function,
    formValidate: Function,
    formResetField: Function,
    formSubmit: Function,
    formSubmitComplete: Function,
    updatePrintableAdviserDetails: Function,
  },
  // Address lookup manual entry redux state mapped to props.
  isManual: boolean,
  details: Object,
  printableAdviserDetails: Object,
  // action to change form details
  submitAdvisorDetails: Function,
  masterData: Object,
}

const Row = styled('div')(styles.row)
const HalfRow = styled('div')(styles.halfRow)
const Form = styled('form')(styles.base)
const FormButton = styled(Button)(styles.formButton)
const LeftCol = styled(Input)(styles.leftCol)
const RightCol = styled(Input)(styles.rightCol)
const AbnField = styled(Input)(styles.abn)
const FormTitle = styled('p')(styles.formTitle)

export const getValidCountry = (countryCode, countryCodeList) => {
  const country = countryCodeList.find(entry => entry.countryCode === countryCode)
  return country ? country.countryCode : ''
}

export const formatValues = (adviserDetails, printableAdviserDetails, masterData) => {
  if (printableAdviserDetails.address1) {
    return {
      adviserName: `${printableAdviserDetails.firstName} ${printableAdviserDetails.lastName}`,
      businessName: printableAdviserDetails.businessName,
      abn: printableAdviserDetails.abn,
      contactNumber: printableAdviserDetails.phone,
      email: printableAdviserDetails.email,
      fullAddress: printableAdviserDetails.address2
        ? `${printableAdviserDetails.address1} ${printableAdviserDetails.state}, ${printableAdviserDetails.address2} ${printableAdviserDetails.postCode}`
        : `${printableAdviserDetails.address1} ${printableAdviserDetails.state} ${printableAdviserDetails.postCode}`,
      houseNo: '',
      street: '',
      locality: '',
      state: '',
      country: COUNTRY_CODE,
      postCode: '',
    }
  }
  const {
    contactMethods: { phones, emails, addresses } = {
      phones: [],
      emails: [],
      addresses: [],
    },
    firstName = '',
    middleName = '',
    lastName = '',
    businessName = '',
    abnNumber = '',
    partyType = '',
  } = adviserDetails
  const phone = getPhoneNumber(phones)
  const email = getEmail(emails)
  const preferredAddress = getPreferredItemBreakdown(addresses) || {}
  const address = getFullAddressWithState([preferredAddress])
  let fullName = ''
  if (firstName.length) fullName += firstName
  if (middleName.length) fullName += ` ${middleName}`
  if (lastName.length) fullName += ` ${lastName}`

  return {
    adviserName: fullName,
    businessName,
    abn: isPartyTypeOrg(partyType) ? abnNumber : '',
    contactNumber: phone,
    email,
    fullAddress: address,
    houseNo: (preferredAddress && preferredAddress.houseNo) || '',
    street: (preferredAddress && preferredAddress.street) || '',
    locality: (preferredAddress && preferredAddress.locality) || '',
    state: (preferredAddress && preferredAddress.state) || '',
    country:
      (preferredAddress && getValidCountry(preferredAddress.country, masterData.countryCodeList)) ||
      COUNTRY_CODE,
    postCode: (preferredAddress && preferredAddress.postCode) || '',
  }
}

export class QuoteUpdateAdviserDetails extends Component<QuoteUpdateAdviserDetailsProps> {
  componentDidMount() {
    const {
      actions,
      details,
      printableAdviserDetails = {},
      fields,
      isManual,
      masterData,
    } = this.props
    const { formInit } = actions
    const { partyType } = details
    const schema = SCHEMA(fields, isManual, partyType)
    formInit(FORM_ID, schema, formatValues(details, printableAdviserDetails, masterData))
  }

  // handle changes on form elements.
  handleChange = ({ value, name }): void => {
    const { actions, isManual, fields, details } = this.props
    const { formUpdateField, formValidate } = actions
    const { partyType } = details
    const schema = SCHEMA(fields, isManual, partyType)
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(value, schema[name].onChangeCondition)
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(value, schema[name].condition),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handleBlur = (event, name): void => {
    const { actions, fields, isManual, details } = this.props
    const { partyType } = details
    const { formUpdateField, formValidate } = actions
    const schema = SCHEMA(fields, isManual, partyType)
    const {
      target: { value },
    } = event
    const data = {
      error: errorCheck(value, schema[name].condition),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressChange = address => {
    const { data, value } = address
    const { actions, fields, isManual, details } = this.props
    const { partyType } = details
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = SCHEMA(fields, isManual, partyType)
    const field = {
      error: errorCheck(value, schema.fullAddress.condition),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, 'fullAddress', field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  addressToggleHandler = (isManual: boolean): void => {
    const {
      actions: { formResetField },
    } = this.props
    if (isManual) {
      formResetField(FORM_ID, ['fullAddress', 'houseNo', 'street', 'locality', 'state', 'postCode'])
    }
  }

  getUpdatePrintableAdviserDetailsPayload = (): Object => {
    const {
      form: {
        fields: {
          adviserName,
          businessName,
          abn,
          fullAddress,
          contactNumber,
          email,
          street,
          houseNo,
          locality,
          state,
          country,
          postCode,
        },
      },
    } = this.props

    let localityValue = ''
    let locationArray = []
    let countryVal = ''
    let stateVal = ''
    let postcodeValue = ''
    let fullAddressArray = []
    if (fullAddress.value.length) {
      fullAddressArray = fullAddress.value.split(', ')
      if (fullAddressArray.length > 2) {
        // have country in the full address
        locationArray = fullAddressArray[fullAddressArray.length - 2]
          .split(' ')
          .concat(fullAddressArray[fullAddressArray.length - 1].split(' '))

        countryVal =
          fullAddressArray[fullAddressArray.length - 1].split(' ')[
            fullAddressArray[fullAddressArray.length - 1].split(' ').length - 2
          ]
        postcodeValue =
          fullAddressArray[fullAddressArray.length - 1].split(' ')[
            fullAddressArray[fullAddressArray.length - 1].split(' ').length - 1
          ]
        stateVal =
          fullAddressArray[fullAddressArray.length - 2].split(' ')[
            fullAddressArray[fullAddressArray.length - 2].split(' ').length - 1
          ]

        locationArray.splice(-1)
        locationArray.splice(-1)
        locationArray.splice(-1)
        localityValue = locationArray.join(' ')
      } else {
        locationArray = fullAddressArray[fullAddressArray.length - 1].split(' ')
        stateVal = locationArray[locationArray.length - 2]
        postcodeValue = locationArray[locationArray.length - 1]
        locationArray.splice(-1)
        locationArray.splice(-1)
        localityValue = locationArray.join(' ')
      }
    }

    const splitName = adviserName.value.trim().split(' ')
    const lastName = splitName[splitName.length - 1]
    return {
      firstName: splitName.filter(e => e !== lastName).join(' '),
      lastName,
      businessName: businessName.value.trim(),
      abn: abn.value.trim(),
      address1: fullAddress.value.length
        ? `${fullAddress.value.split(', ')[0]}, ${localityValue}`
        : `${houseNo.value} ${street.value.trim()}, ${locality.value.trim()}`,
      address2: fullAddress.value.length ? countryVal : country.value.trim(),
      state: fullAddress.value.length ? stateVal : state.value,
      postCode: fullAddress.value.length ? postcodeValue : postCode.value,
      phone: contactNumber.value,
      email: email.value.trim(),
    }
  }

  handleSubmit = () => {
    const { form, actions, fields, isManual, details } = this.props
    const { partyType } = details
    const { formSubmit, formSubmitComplete, updatePrintableAdviserDetails } = actions
    const updatePrintableAdviserDetailsPayload = this.getUpdatePrintableAdviserDetailsPayload()

    if (form.isSending) return
    const schema = SCHEMA(fields, isManual, partyType)
    formSubmit(FORM_ID, schema, () => {
      const { submitAdvisorDetails } = this.props
      submitAdvisorDetails()
      updatePrintableAdviserDetails(updatePrintableAdviserDetailsPayload)
      formSubmitComplete(FORM_ID)
    })
  }

  render() {
    const { fields, form, isManual, details } = this.props
    const { partyType } = details
    const schema = SCHEMA(fields, isManual, partyType)
    if (!form) return null
    const {
      adviserName,
      businessName,
      abn,
      fullAddress,
      contactNumber,
      email,
      street,
      houseNo,
      locality,
      state,
      country,
      postCode,
    } = form.fields
    return (
      <Form id={FORM_ID} aria-labelledby="quote-update-adviser-details">
        <FormTitle>
          <Text field={fields.quoteUpdateAdviserDetailsCompanyFormHeader} />
        </FormTitle>
        <Row>
          <LeftCol
            htmlFor="adviserName"
            name="adviserName"
            label={fields.quoteUpdateAdviserDetailsNameLabel.value}
            changeHandler={this.handleChange}
            error={adviserName.error.error}
            value={adviserName.value}
            data-locator="quoteUpdateAdviserDetails_modal_adviserName"
            caption={adviserName.error.error && adviserName.error.errorMsg}
          />
          <RightCol
            htmlFor="businessName"
            name="businessName"
            label={fields.quoteUpdateAdviserDetailsBusinessNameLabel.value}
            changeHandler={this.handleChange}
            error={businessName.error.error}
            value={businessName.value}
            data-locator="quoteUpdateAdviserDetails_modal_businessName"
            caption={businessName.error.error && businessName.error.errorMsg}
          />
        </Row>
        <HalfRow>
          <AbnField
            htmlFor="abn"
            name="abn"
            label={fields.quoteUpdateAdviserDetailsAbnLabel.value}
            changeHandler={this.handleChange}
            error={abn.error.error}
            value={abn.value}
            data-locator="quoteUpdateAdviserDetails_modal_abn"
            caption={abn.error.error && abn.error.errorMsg}
          />
        </HalfRow>
        <FormTitle>
          <Text field={fields.quoteUpdateAdviserDetailsContactFormHeader} />
        </FormTitle>
        <AddressLookup
          toggleHandler={this.addressToggleHandler}
          name="quoteUpdateAdviserDetailsSettingsAddress"
          auto={{
            label: fields.quoteUpdateAdviserDetailsAddressLabel.value,
            toggleLabel: fields.quoteUpdateAdviserDetailsCantFindAddressLabel.value,
            toggleLabelRight: true,
            value: fullAddress.value,
            selectChangeHandler: this.addressChange,
            addressError: fullAddress.error.error,
            addressErrorMessage: fullAddress.error.errorMsg,
          }}
          manual={{
            streetPlaceholder: fields.quoteUpdateAdviserDetailsStreetPlaceholder.value,
            streetValue: street.value,
            streetError: street.error.error,
            streetRequiredErrorMessage: street.error.errorMsg,
            houseNoPlaceholder: fields.quoteUpdateAdviserDetailsHouseNoPlaceholder.value,
            houseNoValue: houseNo.value,
            houseNoMin: schema.houseNo.minimum,
            localityPlaceholder: fields.quoteUpdateAdviserDetailsLocalityPlaceholder.value,
            localityLabelValue: locality.value,
            localityError: locality.error.error,
            localityRequiredErrorMessage: locality.error.errorMsg,
            statePlaceholder: fields.quoteUpdateAdviserDetailsStatePlaceholder.value,
            stateValue: state.value,
            stateError: state.error.error,
            stateRequiredErrorMessage: state.error.errorMsg,
            countryPlaceholder: fields.quoteUpdateAdviserDetailsCountryPlaceholder.value,
            countryValue: country.value,
            countryIsSelect: true,
            countryError: country.error.error,
            countryRequiredErrorMessage: country.error.errorMsg,
            postCodeLabel: fields.postCodeLabel,
            postCodePlaceholder: fields.quoteUpdateAdviserDetailsPostCodePlaceholder.value,
            postCodeValue: postCode.value,
            postCodeMin: schema.postCode.minimum,
            postCodeError: postCode.error.error,
            postCodeRequiredErrorMessage: postCode.error.errorMsg,
            manualToggleLabel: fields.quoteUpdateAdviserDetailsManualToggleLabel.value,
            inputEntryHandler: this.handleChange,
          }}
        />
        <Row>
          <LeftCol
            htmlFor="contactNumber"
            name="contactNumber"
            label={fields.quoteUpdateAdviserDetailsContactLabel.value}
            changeHandler={this.handleChange}
            error={contactNumber.error.error}
            value={contactNumber.value}
            data-locator="quoteUpdateAdviserDetails_modal_contactNumber"
            caption={contactNumber.error.error && contactNumber.error.errorMsg}
          />
          <RightCol
            htmlFor="email"
            name="email"
            label={fields.quoteUpdateAdviserDetailsEmailLabel.value}
            changeHandler={this.handleChange}
            error={email.error.error}
            value={email.value}
            data-locator="quoteUpdateAdviserDetails_modal_email"
            caption={email.error.error && email.error.errorMsg}
          />
        </Row>
        <FormButton type="secondary" onClick={this.handleSubmit}>
          <Text field={fields.quoteUpdateAdviserDetailsSubmitButton} />
        </FormButton>
      </Form>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

// selector function to retrieve the active quote.
const selectActiveQuote = ({ quotes, activeIndex }: Object): Object => quotes[activeIndex]

export const mapStateToProps = ({
  forms,
  advisor: { details },
  addressLookup,
  createQuote,
  masterList,
}) => ({
  form: forms[FORM_ID],
  details,
  isManual:
    addressLookup.quoteUpdateAdviserDetailsSettingsAddress &&
    addressLookup.quoteUpdateAdviserDetailsSettingsAddress.isManual,
  printableAdviserDetails: selectActiveQuote(createQuote).printableAdviserDetails,
  masterData: masterList.data,
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteUpdateAdviserDetails)
