/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconResizer16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path className="path1" fillRule="evenodd" clipRule="evenodd" d="M15 7v8H7l8-8z" fill="#000" />
  </Icon>
)

export default IconResizer16
