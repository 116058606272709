import {
  checkMinLength,
  checkMinLowerCase,
  checkMinUpperCase,
  checkMinNumber,
  checkMinSymbol,
  checkPasswordNotContainsUsername,
} from '../../../utils/formUtils'

// Mapping for password criteria, keys are as per verify code API response
const passwordCriteriaMapping = fields => ({
  minLength: {
    condition: checkMinLength,
    message: fields.passwordMinLengthMsg.value,
  },
  minLowerCase: {
    condition: checkMinLowerCase,
    message: fields.passwordMinLowerCaseMsg.value,
  },
  minUpperCase: {
    condition: checkMinUpperCase,
    message: fields.passwordMinUpperCaseMsg.value,
  },
  minNumber: {
    condition: checkMinNumber,
    message: fields.passwordMinNumberMsg.value,
  },
  minSymbol: {
    condition: checkMinSymbol,
    message: fields.passwordMinSymbolMsg.value,
  },
  excludeUsername: {
    condition: checkPasswordNotContainsUsername,
    message: fields.passwordContainsUsernameMsg.value,
  },
})

export default passwordCriteriaMapping
