export const OKTA_COOKIE_NAME = 'token'
export const OKTA_SCOPE_NAME = 'scope'

export const SCOPE_TYPE_TELE = 'tele_underwriter'

export const OKTA_ACCOUNT_STATUS = 'status'
export const OKTA_REQUIRES_PASSWORD_RESET = 'requiresPasswordReset'
export const OKTA_HAS_COMPLETED_WELCOME_JOURNEY = 'hasCompletedWelcomeJourney'
export const OKTA_HAS_SEEN_DIGITAL_COMMS = 'hasSeenDigitalComms'
export const OKTA_IS_VALID_IDENTITY = 'isValidIdentity'
export const OKTA_IS_SUPPORT_STAFF = 'isSupportStaff'
export const OKTA_IS_MOBILE_NUMBER_EXISTS = 'isMobileNumberExists'

export const OKTA_SESSION_INACTIVE = 'INACTIVE'
export const CHANGE_PASSWORD = 'changePassword'

export const OKTA_IDENTITY_TYPE_SUPPORT_STUFF = 'advisor_support_staff'
