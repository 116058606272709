// @flow
import React, { Node } from 'react'
import styled from '@emotion/styled'

// components.
import { IconChevronDown32, IconChevronUp32 } from '../../../../atoms/Icons'

// styles.
import styles from './dropdown.styles'
import { themeConsumer, getThemeProp } from '../../../../../styles/ThemeContext'

type DropdownProps = {
  // Item to display in dropdown.
  children: Node,
  // title of current selected item
  text: String,
  // Set if dropdown is open
  isOpen: Boolean,
  // Function to call on click of dropdown
  changeHandler: Function,
}

const Container = styled('div')(styles.base)
const List = styled('div')(({ isOpen }) => styles.list(isOpen))
const Button = styled('button')(({ theme }) => styles.button(theme))
const Text = styled('span')(styles.text)
const Indicator = styled(IconChevronDown32)(styles.indicator)
const IndicatorExpanded = styled(IconChevronUp32)(styles.indicator)

const Dropdown = (props: DropdownProps) => {
  const { children, text, isOpen, changeHandler } = props
  return (
    <Container>
      <Button
        {...getThemeProp(props)}
        isOpen={isOpen}
        data-testid="dropdownBtn"
        onClick={changeHandler}
      >
        <Text>{text}</Text>
        {isOpen ? (
          <IndicatorExpanded data-testid="indicatorExpanded" isOpen={isOpen} />
        ) : (
          <Indicator data-testid="indicator" isOpen={isOpen} />
        )}
      </Button>
      <List isOpen={isOpen}>{children}</List>
    </Container>
  )
}

export default themeConsumer(Dropdown, 'tabBar')
