import { isHealthyLivingRequired } from '../../../../../utils/chooseCoverUtils'
import { optional, required } from '../../../../../utils/formUtils'

export const FORM_ID = 'chooseCover'

const chooseCoverSchema = (fields, healthyLivingCovers) => ({
  healthyLivingProgram: {
    condition: isHealthyLivingRequired(fields, healthyLivingCovers) ? required : optional,
    errorMsg: 'Please choose healthy Living Program ',
    tooltip: '',
    options: [
      {
        label: 'Participate',
        value: 'true',
      },
      {
        label: 'Not now',
        value: 'false',
      },
    ],
  },
})
export default chooseCoverSchema
