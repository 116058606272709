// @flow
import {
  PRODUCT_RULES_FETCH_INIT,
  PRODUCT_RULES_FETCH_SUCCESS,
  PRODUCT_RULES_FETCH_ERROR,
  PRODUCT_RULES_FETCH_FAILURE,
  PRODUCT_RULES_RESET,
} from '../actions/types/productRules'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  data: [],
  errors: [],
  isFetching: false,
}

const productRules = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case PRODUCT_RULES_FETCH_INIT: {
      return {
        ...(state.data && state.data.length
          ? {
              state,
            }
          : {
              initialState,
            }),
        isFetching: true,
      }
    }
    case PRODUCT_RULES_FETCH_SUCCESS: {
      return {
        ...action.payload,
        isFetching: false,
      }
    }
    case PRODUCT_RULES_FETCH_ERROR:
    case PRODUCT_RULES_FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
      }
    }
    case OKTA_RESET_AUTHENTICATION_STATE:
    case PRODUCT_RULES_RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default productRules
