/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconMenu32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 11H6V8h20v3zm-2 7H6v-3h18v3zm2 7H6v-3h20v3z"
      fill="#000"
    />
  </Icon>
)

export default IconMenu32
