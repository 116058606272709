import { space } from '../../../../../../styles'

export default {
  contentWrapper: {
    padding: space(2),
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdown: {
    width: '100%',
  },
  loadingType: {
    flexBasis: '60%',
  },
  loadingAmount: {
    flexBasis: '38%',
  },
}
