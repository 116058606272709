// @flow
import React from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'

// utils
import {
  getPhoneNumber,
  getPhoneNumberCode,
  getAddressByAddressType,
  constructAddress,
  getEmail,
  makeName,
} from '../../../../../utils/contactUtils'
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { numberWithCommas } from '../../../../../utils/formUtils'
import { isEmployee } from '../../../../../utils/extendedQuoteUtils'
import { ADDRESS_TYPE_HOME } from '../../../../../constants/policies'

// styles
import styles from './clientDetails.styles'

const ClientDetailsContainer = styled('table')(styles.container)
const ItemContainer = styled('tr')(styles.itemContainer)
const ItemContainerExcSuper = styled('tr')(styles.itemContainer, styles.itemContainerExcSuper)
const Title = styled('h2')(styles.title)
const ClientDetailsWrapper = styled('div')(styles.wrapper)
const Label = styled('td')(styles.label)
const ValueContainer = styled('td')(styles.valueContainer)
const ValueWapper = styled('span')(styles.valueWapper)
const Column = styled('td')(styles.column)

type ClientDetailsPropTypes = {
  // Life insured details
  lifeInsuredDetails: {
    employement: Object,
    contactMethods: Object,
  },
  // Sitecore fields
  fields: Object,
  // Set flag
  fromApplicationReview: Boolean,
  // Master data from Sitecore
  masterData: Object,
  memberMandatories: object,
}

const clientDetailsItem = (label, value, prefix, fromApplicationReview) => (
  <ValueContainer>
    <table>
      <tbody>
        <tr>
          <Label applicationView={fromApplicationReview}>{renderTextField(label)}</Label>
          <Column>
            <ValueWapper>
              {prefix}
              {value}
            </ValueWapper>
          </Column>
        </tr>
      </tbody>
    </table>
  </ValueContainer>
)

const ClientDetailsComponent = ({
  lifeInsuredDetails,
  fields,
  fromApplicationReview,
  masterData,
  memberMandatories,
}: ClientDetailsPropTypes) => {
  const { earningExcludingSuper = '' } = memberMandatories
  const employmentStatuses = memberMandatories.employmentStatus
  if (!lifeInsuredDetails) return null
  const {
    relatedParty: {
      employment,
      contactMethods: { addresses, phones, emails },
    },
  } = lifeInsuredDetails
  const { occupation = '', totalGrossYrlyIncome = '', employmentStatus = '' } = employment || {}
  const employmemtStatusObject = get(masterData, 'employmentStatus', []).find(
    empStatus => empStatus.code === employmentStatus
  )
  const clientFullName = fromApplicationReview
    ? `- ${makeName(lifeInsuredDetails.relatedParty)}`
    : ''
  const renderTitle = label => (
    <Title applicationView={fromApplicationReview}>
      {renderTextField(label)} {clientFullName}
    </Title>
  )
  const address = constructAddress(getAddressByAddressType(addresses, ADDRESS_TYPE_HOME))
  const phone = `${getPhoneNumberCode(phones)}${getPhoneNumber(phones)}`
  const email = getEmail(emails)
  const {
    clientDetailsOccupationLabel,
    clientDetailsEmailLabel,
    clientDetailsAddressLabel,
    clientDetailsPhoneLabel,
    clientDetailsEmployementStatusLabel,
    clientDetailsAnnualSalaryLabel,
    clientDetailsAnnualSalaryLabelExSup,
    lifeInsuredDetailsLabel,
    clientDetailsAnnualSalaryLabelInclSup,
  } = fields
  const isClientEmployee = isEmployee(employmentStatuses)

  return (
    <ClientDetailsWrapper applicationView={fromApplicationReview}>
      {renderTitle(lifeInsuredDetailsLabel)}
      <ClientDetailsContainer>
        <tbody>
          <ItemContainer>
            {clientDetailsItem(clientDetailsPhoneLabel, phone, '', fromApplicationReview)}
            {clientDetailsItem(
              clientDetailsEmployementStatusLabel,
              employmemtStatusObject && employmemtStatusObject.value,
              '',
              fromApplicationReview
            )}
          </ItemContainer>
          <ItemContainer>
            {clientDetailsItem(
              `${get(clientDetailsAnnualSalaryLabel, 'value')}${
                isClientEmployee ? get(clientDetailsAnnualSalaryLabelInclSup, 'value') : ''
              }`,
              numberWithCommas(totalGrossYrlyIncome),
              '$',
              fromApplicationReview
            )}
          </ItemContainer>
          {isClientEmployee && (
            <ItemContainerExcSuper>
              {clientDetailsItem(
                `${get(clientDetailsAnnualSalaryLabel, 'value')}${get(
                  clientDetailsAnnualSalaryLabelExSup,
                  'value'
                )}`,
                numberWithCommas(earningExcludingSuper),
                '$',
                fromApplicationReview
              )}
            </ItemContainerExcSuper>
          )}
          <ItemContainer>
            {clientDetailsItem(clientDetailsOccupationLabel, occupation, '', fromApplicationReview)}
          </ItemContainer>
          <ItemContainer>
            {clientDetailsItem(clientDetailsEmailLabel, email, '', fromApplicationReview)}
          </ItemContainer>
          <ItemContainer>
            {clientDetailsItem(clientDetailsAddressLabel, address, '', fromApplicationReview)}
          </ItemContainer>
        </tbody>
      </ClientDetailsContainer>
    </ClientDetailsWrapper>
  )
}

export default ClientDetailsComponent
