// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { A11yLabel, FormBlock, Select, Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../actions'

// components.
import Checkbox from '../../atoms/Checkbox'
import AddressLookUp from '../../molecules/AddressLookUp'

// schema.
import SCHEMA, { FORM_ID } from './beneficiaryDetails.schema'

// utils.
import { ignoreColumns } from '../../../utils/stylesUtils'
import { errorCheck, generateFieldsFromData, getValue } from '../../../utils/formUtils'

// constnats.
import { RELATIONSHIPS } from '../../../constants/forms'

// styles
import styles from './beneficiaryDetails.styles'
import { COUNTRY_CODE } from '../../../constants/policies'

type BeneficiaryDetailsProps = {
  // To render all labels from sitecore content editor
  fields: {},
  // Redux actions available to the component.
  actions: {
    formInit: Function,
    formUpdateField: Function,
    formValidate: Function,
    formSubmit: Function,
  },
  // Redux form data of the component.
  form: Object,
  // Manual address state mapped to props from address lookup in redux.
  isManual: boolean,
  // Optional prop to pass data to form
  data?: Object,
}

const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const FullWidthBlock = ignoreColumns(FormBlock)
const FullWidthInput = ignoreColumns(Input)
const Form = styled('form')(styles.base)
const SelectXsm = styled(Select)(styles.sectionXsm)
const InputXl = styled(Input)(styles.sectionXl)
export class BeneficiaryDetails extends Component<BeneficiaryDetailsProps> {
  componentWillMount() {
    const {
      actions: { formInit },
      isManual,
      fields,
    } = this.props
    const schema = SCHEMA(fields, isManual, false)
    formInit(FORM_ID, schema)
  }

  componentDidUpdate(prevProps) {
    const {
      actions: { formInit },
      isManual,
      data,
      fields,
      form,
    } = this.props
    const { fields: formFields } = form
    const { addressSameAsLifeInsured } = formFields
    const schema = SCHEMA(fields, isManual, addressSameAsLifeInsured.value)

    const formatDateIfValid = dateStr => {
      const momentDate = moment(dateStr)
      if (dateStr && momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY')
      }
      return dateStr
    }

    if (data && prevProps.data !== data) {
      // TODO: Remove this date formatting once someone unifies date formats.
      const { dateOfBirth } = data
      const updatedData = {
        ...data,
        dateOfBirth: formatDateIfValid(dateOfBirth),
      }
      formInit(FORM_ID, schema, updatedData)
    }
  }

  addressToggleHandler = (): void => {
    const {
      actions: { formResetField },
    } = this.props

    formResetField(FORM_ID, ['address', 'street', 'houseNo', 'locality', 'state', 'postCode'])
  }

  // handle changes on form elements.
  handleChange = ({ name, value }): void => {
    const {
      actions: { formUpdateField, formValidate, setAdddressToManual },
      isManual,
      fields,
      form,
    } = this.props
    const { fields: formFields } = form
    const { addressSameAsLifeInsured } = formFields
    let manual = isManual
    if (name === 'addressSameAsLifeInsured' && value) {
      setAdddressToManual('beneficiaryAddress', false)
      manual = false
    }
    const schema = SCHEMA(fields, manual, addressSameAsLifeInsured.value)
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressChange = address => {
    const { name, data, value } = address
    const {
      isManual,
      actions: { formUpdateField, formUpdate, formValidate },
      fields,
      form,
    } = this.props
    const { fields: formFields } = form
    const { addressSameAsLifeInsured } = formFields
    const schema = SCHEMA(fields, isManual, addressSameAsLifeInsured.value)
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  render() {
    const { form } = this.props
    if (!form) return ''
    const {
      title,
      firstName,
      lastName,
      dateOfBirth,
      relationship,
      addressSameAsLifeInsured,
      address,
      street,
      houseNo,
      locality,
      state,
      country,
      postCode,
    } = form.fields
    const { fields, isManual } = this.props
    const schema = SCHEMA(fields, isManual, addressSameAsLifeInsured.value)

    address.value = `${street.value} ${locality.value} ${state.value} ${postCode.value}`

    return (
      <Fragment>
        <A11yLabel>{fields.addBeneficiaryHeaderLabel.value}</A11yLabel>
        <Form id="addBenificiary">
          <SelectXsm
            label={fields.beneficiaryDetailTitleLabel.value}
            name="title"
            id="Title"
            placeholder={fields.beneficiaryDetailTitlePlaceholder.value}
            changeHandler={this.handleChange}
            options={schema.title.options}
            value={title.value}
            error={title.error.error}
            caption={title.error.error && fields.beneficiaryDetailTitleError.value}
          />
          <InputXl
            htmlFor="firstName"
            name="firstName"
            label={fields.beneficiaryDetailFirstNameLabel.value}
            placeholder={fields.beneficiaryDetailFirstNamePlaceholder.value}
            changeHandler={this.handleChange}
            value={firstName.value}
            error={firstName.error.error}
            caption={firstName.error.error ? firstName.error.errorMsg : schema.firstName.tooltip}
          />
          <FullWidthInput
            htmlFor="lastName"
            name="lastName"
            label={fields.beneficiaryDetailLastNameLabel.value}
            placeholder={fields.beneficiaryDetailLastNamePlaceholder.value}
            changeHandler={this.handleChange}
            value={lastName.value}
            error={lastName.error.error}
            caption={lastName.error.error ? lastName.error.errorMsg : schema.lastName.tooltip}
          />
          <Input
            withoutMargin
            htmlFor="dateOfBirth"
            name="dateOfBirth"
            label={fields.beneficiaryDetailDateOfBirthLabel.value}
            changeHandler={this.handleChange}
            placeholder={fields.beneficiaryDetailDateOfBirthPlaceholder.value}
            value={dateOfBirth.value}
            error={dateOfBirth.error.error}
            caption={
              dateOfBirth.error.error ? dateOfBirth.error.errorMsg : schema.dateOfBirth.tooltip
            }
            options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
          />
          <Select
            label={fields.beneficiaryDetailRealationshipLabel.value}
            placeholder={fields.beneficiaryDetailRealationshipPlaceholder.value}
            value={relationship.value}
            name="relationship"
            id="relationship"
            changeHandler={this.handleChange}
            options={RELATIONSHIPS}
            error={relationship.error.error}
            caption={relationship.error.error && fields.beneficiaryDetailRealationshipError.value}
          />
          <FullWidthBlock>
            <Checkbox
              htmlFor="beneficiary-addressSameAsLifeInsured"
              name="addressSameAsLifeInsured"
              checked={addressSameAsLifeInsured.value}
              onChangeHandler={this.handleChange}
              text={fields.beneficiaryDetailAddressCheckboxLabel.value}
            />
          </FullWidthBlock>

          {!addressSameAsLifeInsured.value && (
            <FullWidthAddressLookup
              name="beneficiaryAddress"
              toggleBackHandler={this.toggleViews}
              toggleHandler={this.addressToggleHandler}
              auto={{
                label: fields.beneficiaryDetailAddressLabel.value,
                placeholder: fields.beneficiaryDetailAddressPlaceholder.value,
                toggleLabel: fields.beneficiaryDetailCantFindAddressLabel.value,
                value: address.value,
                selectChangeHandler: this.addressChange,
                addressError: address.error.error,
                addressErrorMessage:
                  address.error.error && fields.beneficiaryDetailAddressError.value,
              }}
              manual={{
                streetLabel: fields.beneficiaryDetailStreetLabel.value,
                streetPlaceholder: fields.beneficiaryDetailStreetPlaceholder.value,
                streetValue: street.value,
                streetRequiredErrorMessage: street.error.errorMsg,
                streetError: street.error.error,
                houseNoLabel: fields.beneficiaryDetailHouseNoLabel.value,
                houseNoPlaceholder: fields.beneficiaryDetailHouseNoPlaceholder.value,
                houseNoValue: houseNo.value,
                houseNoError: houseNo.error.error,
                houseNoRequiredErrorMessage: houseNo.error.errorMsg,
                localityLabel: fields.beneficiaryDetailLocalityLabel.value,
                localityPlaceholder: fields.beneficiaryDetailLocalityPlaceholder.value,
                localityLabelValue: locality.value,
                localityError: locality.error.error,
                localityRequiredErrorMessage: locality.error.errorMsg,
                stateLabel: fields.beneficiaryDetailStateLabel.value,
                statePlaceholder: fields.beneficiaryDetailStatePlaceholder.value,
                stateOptions: schema.state.options,
                stateValue: state.value,
                stateError: state.error.error,
                stateRequiredErrorMessage: state.error.errorMsg,
                countryLabel: fields.beneficiaryDetailCountryLabel.value,
                countryPlaceholder: fields.beneficiaryDetailCountryPlaceholder.value,
                countryValue: country.value || COUNTRY_CODE,
                countryError: country.error.error,
                countryRequiredErrorMessage: country.error.errorMsg,
                postCodeLabel: fields.beneficiaryDetailPostCodeLabel.value,
                postCodePlaceholder: fields.beneficiaryDetailPostCodePlaceholder.value,
                postCodeValue: postCode.value,
                postCodeError: postCode.error.error,
                postCodeRequiredErrorMessage: postCode.error.errorMsg,
                manualToggleLabel: fields.beneficiaryDetailAddressManualToggleLabel.value,
                inputEntryHandler: this.handleChange,
              }}
            />
          )}
        </Form>
      </Fragment>
    )
  }
}

BeneficiaryDetails.defaultProps = {
  data: {},
}

export const mapStateToProps = ({ forms, createQuote, occupation, addressLookup }) => ({
  form: forms[FORM_ID],
  createQuote,
  occupation,
  isManual: addressLookup.beneficiaryAddress && addressLookup.beneficiaryAddress.isManual,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryDetails)
