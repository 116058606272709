// @flow
import {
  PRE_POPULATE_MANDATORIES_INIT,
  PRE_POPULATE_MANDATORIES_SUCCESS,
  PRE_POPULATE_MANDATORIES_FAILURE,
  CLEAR_PRE_EXISTING_MANDATORIES,
} from '../actions/types/createQuote'

import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  isLoading: false,
}

const existingClient = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case PRE_POPULATE_MANDATORIES_SUCCESS:
    case PRE_POPULATE_MANDATORIES_FAILURE: {
      return {
        ...state,
        details: action.payload,
        isLoading: false,
      }
    }
    case PRE_POPULATE_MANDATORIES_INIT: {
      return {
        isLoading: true,
      }
    }

    case CLEAR_PRE_EXISTING_MANDATORIES:
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default existingClient
