// @flow
import get from 'lodash/get'

// types.
import { EXPERIENCE_API } from '../types/experienceApi'
import {
  RETENTION_DASHBOARD_FETCH,
  RETENTION_DASHBOARD_NEW_PREMIUMS_FETCH_COMPLETE,
} from '../types/retentionDashboard'

import { callExperienceAPIv2 } from '../../utils/customerPortalExperienceAPIUtils'

// constants.
import { EXPERIENCE_API_BASE, EXPERIENCE_API_VERSION_1 } from '../../constants/apis'
import {
  RETENTION_DASHBOARD_ANNIVERSARY,
  RETENTION_DASHBOARD_RISK_OF_LAPSE,
  RETENTION_DASHBOARD_RECENTLY_LAPSE,
  RETENTION_DASHBOARD_RECENTLY_CANCELLED,
} from '../../constants/dashboard'

export const SEARCH_TYPE_INPUT_PARAMS = [
  RETENTION_DASHBOARD_ANNIVERSARY,
  RETENTION_DASHBOARD_RISK_OF_LAPSE,
  RETENTION_DASHBOARD_RECENTLY_LAPSE,
  RETENTION_DASHBOARD_RECENTLY_CANCELLED,
]

// TODO: Fix issue: If searchparam is sent as partial other than the above, the reducer breaks
export const getRetentionDashboard =
  (data: Object, limit: Number, searchType: Array<String> = SEARCH_TYPE_INPUT_PARAMS) =>
  (dispatch: Function, getState: Function) => {
    const {
      advisor: { advisorDetails },
    } = getState()

    dispatch({
      type: EXPERIENCE_API,
      name: RETENTION_DASHBOARD_FETCH,
      verb: 'POST',
      data: {
        searchType,
        bancsAgencyCodes:
          get(data, 'bancsAgencyCodes.length', 0) >= 1
            ? data.bancsAgencyCodes
            : advisorDetails.map(({ bancsAgencyCode }) => bancsAgencyCode),
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/search?limit=${
        limit || 5000
      }`,
    })
  }

export const fetchPoliciesNewPremiumFromEscApi = async (escalationDueDate, bancsPolicyNo) => {
  const endPoint = `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/escalations/${bancsPolicyNo}?escalationDueDate=${escalationDueDate}`
  if (escalationDueDate) {
    const response = await callExperienceAPIv2({
      path: endPoint,
      version: 'v1',
      splunkCustomEventName: 'Experience Escalations API Fetch Retention',
    })
    let newPremium
    if (
      // RET-21950 show --- for policies on Waiver / IP claim
      response?.businessData?.policyChangeDetails
        ?.find(detail => detail?.changeType === 'Re-Rate')
        ?.revisedBenefitDetails?.some(
          benefitDetail =>
            benefitDetail?.benefitStatus?.code === 'WVRI' ||
            benefitDetail?.benefitStatus?.code === 'PHII'
        )
    ) {
      newPremium = false
    } else if (
      // change required as RET-21401
      response?.businessData?.benefitResponseDetails?.length &&
      response.businessData.benefitResponseDetails.every(
        benefitRes => benefitRes?.responseStatus === 'Decline'
      )
    ) {
      newPremium =
        response?.businessData?.policyChangeDetails?.find(
          detail => detail?.changeType === 'Re-Rate'
        )?.newNetPremium ?? false
    } else {
      newPremium =
        response?.businessData?.policyChangeDetails?.find(
          detail => detail?.changeType === 'Escalation' || detail?.changeType === 'Re-Rate'
        )?.newNetPremium ?? false
    }
    return {
      newPremium,
      bancsPolicyNo,
    }
  }
  return false
}

export const getPoliciesNewpremium =
  (requestedPolicies, startIndex = 0, endIndex = requestedPolicies.length) =>
  async (dispatch: Function) => {
    if (requestedPolicies.length) {
      const results = await Promise.all(
        requestedPolicies.map(async policy => {
          const policiesNewPremium = await fetchPoliciesNewPremiumFromEscApi(
            policy?.anniversaryDate.split('/').reverse().join('-'),
            policy?.bancsPolicyNo
          )
          return policiesNewPremium
        })
      )
      dispatch({
        type: RETENTION_DASHBOARD_NEW_PREMIUMS_FETCH_COMPLETE,
        payload: { results, startIndex, endIndex },
      })
      return results
    }
    return undefined
  }
