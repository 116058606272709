import { space, iconWidth, fontSize, fontWeights, colours } from '../../../../../../../styles'

const styles = {
  base: (theme, first, isEditable) => ({
    color: theme.text ? theme.text : colours.darkestGrey,
    background: theme.bg ? theme.bg : 'transparent',
    position: 'relative',
    padding: `${space(isEditable ? 2 : 3, true)} ${space(4, true)}`,
    paddingRight: isEditable ? space(4) : space(7) + iconWidth.sm,
    height: 78,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'center',
    border: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.border ? theme.border : colours.lightGrey,
    textDecoration: 'none',
    transition: 'background .35s ease',
    ...(first && {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: theme.border ? theme.border : colours.lightGrey,
    }),
    '&:hover': {
      background: theme.hover ? theme.hover.bg : 'transparent',
    },
    '&:focus': {
      background: theme.hover ? theme.hover.bg : 'transparent',
      outline: 'none',
    },
    '&:active': {
      background: theme.active ? theme.active.bg : 'transparent',
    },
  }),
  title: {
    display: 'block',
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    marginBottom: 0,
  },
  edit: {
    color: 'inherit',
    position: 'absolute',
    top: `calc(50% - ${iconWidth.sm * 2})`,
    right: space(8),
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
    cursor: 'pointer',
  },
  close: {
    color: 'inherit',
    position: 'absolute',
    top: `calc(50% - ${iconWidth.sm * 2})`,
    right: space(4),
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
    cursor: 'pointer',
  },
  disabled: {
    color: colours.lightestGrey,
    borderColor: colours.lightestGrey,
  },
  tick: {
    color: colours.white,
  },

  descriptionLabel: {
    color: colours.lightGreen,
    lineHeight: space(3, true),
    fontWeight: fontWeights.normal,
  },
  noBorder: {
    borderBottom: 'none',
  },
}

export default styles
