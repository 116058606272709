export const CLIENT_DETAILS_FETCH = 'CLIENT_DETAILS_FETCH'
export const CLIENT_DETAILS_FETCH_INIT = 'CLIENT_DETAILS_FETCH_INIT'
export const CLIENT_DETAILS_FETCH_SUCCESS = 'CLIENT_DETAILS_FETCH_SUCCESS'
export const CLIENT_DETAILS_FETCH_ERROR = 'CLIENT_DETAILS_FETCH_ERROR'
export const CLIENT_DETAILS_FETCH_FAILURE = 'CLIENT_DETAILS_FETCH_FAILURE'
export const CLIENT_DETAILS_UPDATE = 'CLIENT_DETAILS_UPDATE'
export const CLIENT_DETAILS_UPDATE_SUCCESS = 'CLIENT_DETAILS_UPDATE_SUCCESS'
export const CLIENT_DETAILS_UPDATE_ERROR = 'CLIENT_DETAILS_UPDATE_ERROR'
export const CLIENT_DETAILS_UPDATE_FAILURE = 'CLIENT_DETAILS_UPDATE_FAILURE'
export const CLIENT_LISTING_FETCH_INIT = 'CLIENT_LISTING_FETCH_INIT'
export const CLIENT_LISTING_FETCH = 'CLIENT_LISTING_FETCH'
export const CLIENT_LISTING_FETCH_SUCCESS = 'CLIENT_LISTING_FETCH_SUCCESS'
export const CLIENT_LISTING_FETCH_ERROR = 'CLIENT_LISTING_FETCH_ERROR'
export const CLIENT_LISTING_FETCH_FAILURE = 'CLIENT_LISTING_FETCH_FAILURE'
// Existing client list
export const EXISTING_CLIENT_LISTING_FETCH = 'EXISTING_CLIENT_LISTING_FETCH'
export const EXISTING_CLIENT_LISTING_FETCH_INIT = 'EXISTING_CLIENT_LISTING_FETCH_INIT'
export const EXISTING_CLIENT_LISTING_FETCH_SUCCESS = 'EXISTING_CLIENT_LISTING_FETCH_SUCCESS'
export const EXISTING_CLIENT_LISTING_FETCH_ERROR = 'EXISTING_CLIENT_LISTING_FETCH_ERROR'
export const EXISTING_CLIENT_LISTING_FETCH_FAILURE = 'EXISTING_CLIENT_LISTING_FETCH_FAILURE'
export const CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE = 'CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE'
export const CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_SUCCESS =
  'CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_SUCCESS'
export const CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_FAILURE =
  'CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_FAILURE'
export const CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_ERROR =
  'CLIENT_BUSINESS_CONTACT_DETAILS_UPDATE_ERROR'
export const DELETE_SEARCHED_EXISITNG_CLIENTS = 'DELETE_SEARCHED_EXISITNG_CLIENTS'
export const SET_SEARCHED_CLIENT = 'SET_SEARCHED_CLIENT'
export const SET_CLIENT_LA_POLICYID = 'SET_CLIENT_LA_POLICYID'
export const CREATE_CHANGE_COVER_WORK_ITEM = 'CREATE_CHANGE_COVER_WORK_ITEM'
export const CREATE_CHANGE_COVER_WORK_ITEM_ERROR = 'CREATE_CHANGE_COVER_WORK_ITEM_ERROR'
export const CLIENT_DETAILS_UPDATE_RESET = 'CLIENT_DETAILS_UPDATE_RESET'
