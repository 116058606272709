import createQuote from './createQuote.sidebar.schema'
import editQuote from './editQuote.sidebar.schema'
import addPolicyOwner from './addPolicyOwner.sidebar.schema'
import addPayer from './addPayer.sidebar.schema'
import addBeneficiary from './addBeneficiary.sidebar.schema'
import quoteTool from './quoteTool.sidebar.schema'
import preAssessment from './preAssessment.sidebar.schema'
import lifeInsured from './lifeInsured.sidebar.schema'
import projections from './projections.sidebar.schema'
import addNulis from './addNulis.sidebar.schema'
import smsf from './smsf.sidebar.schema'
import visualiser from './visualiser.sidebar.schema'
import requestManualQuote from './requestManualQuote.sidebar.schema'
import requestAltsManualQuote from './requestAltsManualQuote.sidebar.schema'

export default {
  default: createQuote,
  createQuote,
  editQuote,
  addPayer,
  addBeneficiary,
  addPolicyOwner,
  quoteTool,
  preAssessment,
  lifeInsured,
  projections,
  addNulis,
  smsf,
  visualiser,
  requestManualQuote,
  requestAltsManualQuote,
}
