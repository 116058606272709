/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
// @flow
import moment from 'moment'
import get from 'lodash/get'
import uniqWith from 'lodash/uniqWith'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'

import { type ProductIdType, type SourceBenefitNatureType } from '../../flow-typed/types'

// utils
import { getReferenceBenefit } from './alteration'
import { deepEqual, isKeyAvailable } from './commonUtils'
import { getBancsCustomerNumber, getScope } from './cookieUtils'
import { checkIfValidBenefitStatusCode } from './policyUtils'
import {
  getBancsProfileNumber,
  isEmployee,
  getBenefitFromProducts,
  updateRiskedOccCoverPeriod,
  getCampaignDetailObject,
  isSGBOBenefit,
} from './extendedQuoteUtils'

// constants.
import {
  POLICY_PRODUCT_CODE_SUPER,
  SUPER_OWNER_INTERNAL,
  SUPER_OWNER_INTERNAL_TRUSTEE_NAME,
  SUPER_OWNER_IOOF,
  SUPER_OWNER_IOOF_TRUSTEE_NAME,
  SUPER_OWNER_EXTERNAL,
  POLICY_PRODUCT_CODE_NON_SUPER,
  POLICY_NON_SUPER_BENEFIT_PREFIX,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  POLICY_FIXED_PAYMENT_METHOD,
  QUOTE_TOOL_MAX_CHILD_CI_COUNT,
  INCOME_PROTECTION_TEXT,
  WRAPPER_NAVIGATOR_ID,
  MASTER_KEY_ACCOUNT_ID,
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_PARTY_TYPE_INDIVIDUAL,
  NON_SUPER_PRODUCT_CLASS,
  UPDATE_FROM_LIFE_INSURED,
  RISKED_OCCUPATION,
  INCOME_PROTECTION_SR_ID,
  INCOME_PROTECTION_PLATINUM_ID,
  INCOME_PROTECTION_STANDARD_ID,
  PREFERRED_YES,
  COUNTRY_CODE,
  QUOTE_STATUS_APPLICATION_STAGE,
  POLICY_BENEFIT_LC,
  POLICY_BENEFIT_TISO,
  POLICY_BENEFIT_HEP,
  POLICY_FEATURE_LSBO,
  POLICY_BENEFIT_ADB,
  POLICY_BENEFIT_PTD,
  POLICY_BENEFIT_CI_PLUS,
  POLICY_OPTIMISER_LABEL,
  POLICY_RELATIONSHIPS_MEMBER,
  POLICY_FEATURE_DBB,
  POLICY_FEATURE_LCBB,
  POLICY_FEATURE_EBO,
  POLICY_BENEFIT_CI_STD,
  D_RATED_OCCUPATION,
  LIFE_RELATED_POLICY_BENEFITS,
  POLICY_FEATURE_BO,
  POLICY_FEATURE_SGBO,
  BUSINESS_EXPENSE,
  BUSINESS_EXPENSE_PLATINUM,
  HEALTHY_LIVING_DISCOUNT,
  BPAY,
  POLICY_PARTY_TYPE_BUSINESS,
  ADDRESS_TYPE_STATEMENT,
  ADDRESS_TYPE_HOME,
  MULTI_COVER_DISCOUNT,
} from '../constants/policies'
import {
  BENEFIT_NATURE_TYPE_STANDALONE,
  BENEFIT_NATURE_TYPE_RIDER,
  BENEFIT_NATURE_TYPE_RIDER_CONNECTED,
  COVERS_NOT_FOR_UI,
  WAIVER_BENEFIT_CODE,
  BENEFIT_NATURE_TYPE_NA,
  BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
  CORE_DISCOUNTS,
  LOADING_TYPE_MAPPING,
} from '../constants/benefit'

import { newBeneficiary } from '../constants/customerBeneficiary'

import {
  DISCOUNT_VALUETYPE_PERCENTAGE,
  DISCOUNT_MLC_ON_TRACK_DISCOUNT,
} from '../constants/discounts'

import { type ChildData } from '../components/molecules/ChildCover/childCover.schema'
import { PARTY_TYPE_PERSON, REF_NO } from '../constants/adviser'
import { SCOPE_TYPE_TELE } from '../constants/okta'
import {
  TELE,
  ADV_F2F,
  SAVE_DRAFT_APPLICATION,
  COMPLETE_MYLINK,
  COMPLETE_TELE,
  COMPLETE_APPLICATION,
  ACCEPTED,
  REFERRED,
  ADV_PHN,
  MYLINK,
  WIP_MYLINK,
  URE_BY_ADVISER,
  URE_BY_CUSTOMER,
  URE_BY_TELE,
  PREMIUM_STYLES,
} from '../constants/application'
import { ADVISOR_PORTAL } from '../constants/site'
import { ALTERATION_TYPES, ALTERATION_STATUS, DURATION_MAPPING } from '../constants/alterations'
import history from './browserHistory'
import {
  CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE,
  CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE,
} from '../constants/personalStatement'
import { BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'
import {
  getLatestAddressBasedOnType,
  getPreferredAddress,
  getPreferredItemBreakdown,
} from './contactUtils'

type ProductRulesBenefit = Array<{
  allowableBenefitPeriod: [{ selected: boolean, unit: string, value: number }],
  allowableCoverStyle: Array<{ code: string }>,
  allowableDefermentPeriod: [{ selected: boolean, unit: string, value: number }],
  allowableFeatures: Array<{
    code: string,
    value: string,
    selected: boolean,
    duration?: number,
    durationUnit?: string,
  }>,
  benefitCode: string,
  premiumStructure: Array<{
    code: string,
  }>,
}>

export const INVALID_DATE = 'Invalid Date'
export const NOT_APPLICABLE = 'Not Applicable'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DOB_DATE_FORMAT = 'DD-MM-YYYY'
export const CLIENT_SIDE_DATE_FORMAT = 'DD/MM/YYYY'
export const CREATION_DATE_FORMAT = 'DD/MM/YYYYHH:mm:ssss'
export const DATE_FORMAT_WITH_MONTH_NAME = 'DD MMMM YYYY'
export const MMM_D_YYYY = 'MMM D, YYYY'
export const ALLOWED_DATE_FORMAT = [
  DATE_FORMAT,
  DOB_DATE_FORMAT,
  CLIENT_SIDE_DATE_FORMAT,
  DATE_FORMAT_WITH_MONTH_NAME,
  'MM/YY',
]

export const OCCUPATION_RATINGS_MAP = {
  PTD: 'TPD',
  LC: 'Life',
  ADB: 'Life',
  HIV: 'Life',
  WOP: 'Life',
  TISO: 'Life',
  BE_PLATINUM: 'BE',
  BE: 'BE',
  PHI_PLAT_2020: 'IP',
  PHI_STD_2020: 'IP',
  PHI_SR: 'IP',
  CI_PLUS: 'CI',
  CI_STD: 'CI',
  Child_CI: 'CI',
}

const genderTransform = {
  MALE: 'M',
  FEMALE: 'F',
  UNKNOWN: 'U',
}

export const smokerStatusTransform = {
  YES: 'Y',
  NO: 'N',
  UNKNOWN: 'UNK',
}

export const empStatusTransform = {
  EME: 'EMP',
  ESEM: 'SLF_EMP',
  RET: 'RD',
  HMK: 'HD',
  STU: 'ST',
  UEM: 'UNEMP',
  UNK: 'EMP',
}

/** Mapping values (only when its different)
 * Policy Entity: Policy[n]>benefits[i]>benefitAssured[j]>occupation>occupationRating
 * to
 * Quote Entity: QuoteEntity>PolicyStructure[n]>covers[i]>benefitAssured[j]>occupationalRating */
export const occupationRatingTransform = {
  NA: 'A',
  C: 'OR',
  B2: 'B',
  MP: 'M',
  A2: 'A',
  LP: 'L',
  ML: 'M',
  1: '10',
  'A+': 'A',
}

// types
export type ParentBenefitReferenceType = {
  parentPolicyReferenceNo: string | number,
  parentType: string | number,
  parentBenefitInstanceNo: string | number,
  benefitNature?:
    | BENEFIT_NATURE_TYPE_STANDALONE
    | BENEFIT_NATURE_TYPE_RIDER
    | BENEFIT_NATURE_TYPE_RIDER_CONNECTED,
}
export type BenefitLinkOptionType = {
  label: string,
  value: ParentBenefitReferenceType,
}

// returns the shortest authorable display name for a benefit.
export const getShortDisplayName = (benefit: {
  benefitShortDisplayName?: string,
  benefitDisplayName?: string,
  benefitName: string,
}): string => {
  const checkNotNullOrEmptyString = (value?: string): boolean => !(value === null || value === '')
  // eslint-disable-next-line no-nested-ternary
  return checkNotNullOrEmptyString(benefit.benefitShortDisplayName)
    ? benefit.benefitShortDisplayName
    : checkNotNullOrEmptyString(benefit.benefitDisplayName)
    ? benefit.benefitDisplayName
    : benefit.benefitName
}

// Get display name in all benefit
export const getDisplayName = (benefit: { benefitDisplayName?: string, benefitName: string }) =>
  benefit.benefitDisplayName || benefit.benefitName

// get year difference from todays date if nowDate is not been given
// otherwise it will give year difference between these two dates
export const getRelativeTimeDifference = (date: string, nowDate: string) => {
  const toDate = moment(date, ALLOWED_DATE_FORMAT)

  if (toDate.isValid()) {
    const today = moment(nowDate || new Date())
    // get difference in years between today and birthday
    const years = today.diff(toDate, 'years')

    // add difference in years to birthday so we're left with remainder days
    toDate.add(years, 'years')

    return years + 1
  }
  return INVALID_DATE
}

// find lowest age available in age rules
const getMinAgeAtEntry = ageRules =>
  Array.isArray(ageRules)
    ? ageRules.reduce((lowest, { age }, index) => {
        // eslint-disable-next-line no-param-reassign
        if (!index) lowest = age
        return lowest > age ? age : lowest
      }, 0)
    : ageRules

// find greatest age available in age rules
const getMaxAgeAtEntry = ageRules =>
  Array.isArray(ageRules)
    ? ageRules.reduce((largest, { age }) => (largest > age ? largest : age), 0)
    : ageRules

// check is the dob follow allowed min and max age.
export const checkMinMaxAge = (dob, benefit) => {
  const age = getRelativeTimeDifference(dob)
  const minAgeAtEntry = getMinAgeAtEntry(benefit.minAgeAtEntry)
  const maxAgeAtEntry = getMaxAgeAtEntry(benefit.maxAgeAtEntry)
  const isChildCover = benefit.benefitCode === POLICY_CHILD_COVER_BENEFIT_CODE
  if (!(minAgeAtEntry && maxAgeAtEntry) || isChildCover) {
    return NOT_APPLICABLE
  }
  if (age !== INVALID_DATE) {
    return minAgeAtEntry <= age && maxAgeAtEntry >= age
  }
  return false
}

export const checkPremiumStyle = (dob, benefit) => {
  const age = getRelativeTimeDifference(dob)
  const { minAgeAtEntry, maxAgeAtEntry, premiumStructure = [] } = benefit
  const mostMinAge = getMinAgeAtEntry(minAgeAtEntry)
  const mostMaxAge = getMaxAgeAtEntry(maxAgeAtEntry)
  return premiumStructure.map(premiumStyle => {
    const premiumValue = premiumStyle.value
    let isDisabled = false

    if (mostMaxAge < age || mostMinAge > age) {
      isDisabled = true
    } else {
      // eslint-disable-next-line no-unused-expressions
      Array.isArray(minAgeAtEntry)
        ? minAgeAtEntry.map(minAge => {
            if (minAge.age > age && premiumValue === minAge.premiumStyle) {
              isDisabled = true
            }
          })
        : ''

      // eslint-disable-next-line no-unused-expressions
      Array.isArray(maxAgeAtEntry)
        ? maxAgeAtEntry.map(maxAge => {
            if (maxAge.age < age && premiumValue === maxAge.premiumStyle) {
              isDisabled = true
            }
          })
        : ''
    }
    return {
      ...premiumStyle,
      disabled: isDisabled,
    }
  })
}
export const isValidPremiumStyle = (currentPremiumStyle, dateOfBirth, cover, defaults = false) => {
  const premiumStyle = checkPremiumStyle(dateOfBirth, cover)
  const { disabled = defaults } =
    premiumStyle.find(premium => premium.value === currentPremiumStyle) || {}

  return !disabled
}
// returns premiumStyle after validating cover age rules
const getPremiumStyle = (currentPremiumStyle, memberMandatories, cover) => {
  let selectedPremiumStyle = ''
  const isChildCover = cover.benefitCode === POLICY_CHILD_COVER_BENEFIT_CODE
  const { premiumStructure } = cover
  let dob = get(memberMandatories, 'dateOfBirth', '')
  if (isChildCover) {
    dob = get(cover, 'benefitChildCI.dateOfBirth', '')
  }
  if (isValidPremiumStyle(currentPremiumStyle, dob, cover, true))
    return premiumStructure.find(premiumStyle => premiumStyle.code === currentPremiumStyle)
  return checkPremiumStyle(dob, cover).reduce((selectedValue, premium) => {
    if (premium && premium.selected && !premium.disabled) {
      selectedPremiumStyle = premium
      return premium
    }
    if (!selectedPremiumStyle && !premium.disabled) {
      return premium
    }
    return selectedValue
  }, {})
}

// create period of from the data
export const getPeriod = periods =>
  periods.map(period => period.displayValue || `${period.value} ${period.unit}`)

// check if the salary given by user is following minimum salary criteria
export const isValidMinSalary = (salary, benefit) => {
  if (!benefit.minAnnualSalary) {
    return NOT_APPLICABLE
  }
  return Number(salary) >= Number(benefit.minAnnualSalary)
}

export const isParentInstanceSame = (coverToCompare, coverToCompareWith, policyInstanceNo) =>
  coverToCompare.parentBenefitReference &&
  String(coverToCompare.parentBenefitReference.parentPolicyReferenceNo) ===
    String(policyInstanceNo) &&
  String(coverToCompare.parentBenefitReference.parentType) ===
    String(coverToCompareWith.type || coverToCompareWith.benefitCode) &&
  String(coverToCompare.parentBenefitReference.parentBenefitInstanceNo) ===
    String(coverToCompareWith.benefitInstanceNo)

export const getChildInPolicy = (cover, policy, type) =>
  policy.covers.find(
    policyCover =>
      (type ? policyCover.type === type : true) &&
      policyCover.parentBenefitReference &&
      policyCover.parentBenefitReference.parentType === cover.type &&
      policyCover.parentBenefitReference.parentBenefitInstanceNo === cover.benefitInstanceNo
  )

// get benefit from benefits
export const getBenefit = (benefits = [], benefitCode) =>
  benefits.find(benefit => benefit.benefitCode === benefitCode)

// get product from products
export const getProduct = (productRules, productId): any =>
  productRules.length && productRules.find(product => product.productId === productId)

export const getCoverDataFromProductRules = (productRules, productId, coverType) => {
  const productData = getProduct(productRules, productId)
  const coverData = productData.benefits.find(cover => cover.benefitCode === coverType)
  return coverData && Object.keys(coverData).length ? coverData : false
}

// check if the occupation given by user is following occupation criteria
export const isValidOccupation = (occupation, benefit) => {
  if (!(benefit.allowableOccupations && benefit.allowableOccupations.length)) {
    return NOT_APPLICABLE
  }
  return benefit.allowableOccupations.some(occ => occ.value === occupation)
}

// validate occupation rating against the benefit chosen
export const isValidOccupationalRating = (occupationalRating, benefit) => {
  let isValidRating = false
  const benefitCode = benefit ? benefit.benefitCode : null
  const benefitTypeCode = get(benefit, 'benefitType.code', '')
  const occupationRating = occupationalRating ? occupationalRating[benefitTypeCode] : null
  isValidRating =
    occupationRating === RISKED_OCCUPATION ? benefitCode === INCOME_PROTECTION_STANDARD_ID : true

  return isValidRating
}

// check if the employment given by user is following employment criteria
export const isValidEmployment = (employment, benefit) => {
  if (!(benefit.allowableEmployments && benefit.allowableEmployments.length)) {
    return NOT_APPLICABLE
  }
  return benefit.allowableEmployments.some(emp => emp.code === employment)
}
// validate occupation rating against the benefit chosen
export const isValidOccRatingBasedEmployment = (occupationalRating, benefit, employment = '') => {
  const benefitTypeCode = get(benefit, 'benefitType.code', '')
  const occupationRating = occupationalRating ? occupationalRating[benefitTypeCode] : null
  return benefit.benefitCode === BUSINESS_EXPENSE ||
    benefit.benefitCode === BUSINESS_EXPENSE_PLATINUM
    ? empStatusTransform.ESEM === employment && occupationRating === RISKED_OCCUPATION
    : false
}
// check if any covers selected by user is falling in super segment
export const isSuperSelected = (fields: Object, notToCheckFields = []) =>
  Object.keys(fields).some(field => {
    if (
      fields[field] &&
      !notToCheckFields.includes(field) &&
      !field.includes(POLICY_NON_SUPER_BENEFIT_PREFIX)
    ) {
      return true
    }
    return false
  })

/**
 * Connection Rule cover rules for covers
 *
 * @param {Object} selectedCovers
 * @param {Array<Object>} productRules
 * @param {object} memberMandatories
 */
export const applyGroupConnection = (
  selectedCovers,
  productRules,
  memberMandatories,
  applyDraftQuoteRule = false
) =>
  selectedCovers.map(cover => {
    if (!cover.benefitType) {
      return cover
    }
    const selectionRules = applyDraftQuoteRule
      ? get(cover, 'draftQuoteRule.selectionRules')
      : get(cover, 'coverRule.selectionRules')
    const occupationRatingCode = get(memberMandatories, 'occupationalRating.IP') // hardcode till occupation and product data doesnot match
    if (!occupationRatingCode) return cover
    let updatedCover
    if (selectionRules) {
      // applying connection rules from connectionsRule within coverRules
      selectionRules.some(rule => {
        let isAllConditionValid = true
        const { conditions = [] } = rule
        // checking connection rules set from array of rules
        conditions.some(condition => {
          if (
            (condition.selected && condition.occupationRating !== occupationRatingCode) ||
            (!condition.selected && condition.occupationRating === occupationRatingCode)
          ) {
            isAllConditionValid = false
            return true
          }
          return false
        })
        if (isAllConditionValid) {
          const policy = productRules.find(product => rule.action.productId === product.productId)
          const covers = get(policy, 'covers', get(policy, 'benefits', []))
          updatedCover = covers.find(c => rule.action.benefitCode === c.benefitCode)
          updatedCover.benefitInstanceNo = cover.benefitInstanceNo
        }
        if (updatedCover) return true
        return false
      })
    }
    return updatedCover
  })

export const getAlreadyLinkedPolicy = (policyStructure = []) => {
  const linkage = {}
  policyStructure.forEach(policy => {
    policy.covers.forEach(cover => {
      if (
        cover.parentBenefitReference &&
        get(cover, 'parentBenefitReference.benefitNature', '') !== BENEFIT_NATURE_TYPE_STANDALONE &&
        String(cover.parentBenefitReference.parentPolicyReferenceNo) !==
          String(policy.policyInstanceNo)
      ) {
        linkage[policy.policyInstanceNo] = {
          parent: Number(cover.parentBenefitReference.parentPolicyReferenceNo),
          count:
            linkage[policy.policyInstanceNo] && linkage[policy.policyInstanceNo].count
              ? Number(linkage[policy.policyInstanceNo].count) + 1
              : 1,
          benefitInstanceNos:
            linkage[policy.policyInstanceNo] && linkage[policy.policyInstanceNo].benefitInstanceNos
              ? [...linkage[policy.policyInstanceNo].benefitInstanceNos, cover.benefitInstanceNo]
              : [cover.benefitInstanceNo],
          policyInstanceNos:
            linkage[policy.policyInstanceNo] &&
            linkage[policy.policyInstanceNo].policyInstanceNos &&
            linkage[policy.policyInstanceNo].policyInstanceNos.includes(policy.policyInstanceNo)
              ? [...linkage[policy.policyInstanceNo].policyInstanceNos, policy.policyInstanceNo]
              : [policy.policyInstanceNo],
        }
        linkage[cover.parentBenefitReference.parentPolicyReferenceNo] = {
          child: Number(policy.policyInstanceNo),
          count:
            linkage[cover.parentBenefitReference.parentPolicyReferenceNo] &&
            linkage[cover.parentBenefitReference.parentPolicyReferenceNo].count
              ? Number(linkage[cover.parentBenefitReference.parentPolicyReferenceNo].count) + 1
              : 1,
        }
      }
    })
  })
  return linkage
}

/**
 * getting all connections with rules
 *
 * @param {Object} cover
 * @param {Array<Object>} selectedPolicyStructure
 * @returns
 */
export const getAppliedConnectionRules = (
  cover: Object,
  selectedPolicyStructure: Array<Object> = [],
  currentPolicy
) => {
  if (!cover || !cover.coverRule || !cover.coverRule.connectionRules) {
    return false
  }
  const connection = {
    isConnected: false,
    connectedWith: {},
    connectionReference: {},
  }
  const alreadyLinkedPolicy = getAlreadyLinkedPolicy(selectedPolicyStructure)

  cover.coverRule.connectionRules.some(rule => {
    let isConditionValid = false
    let conditionsCount = 0

    // Actions With condition
    if (rule.conditions) {
      let referredCover = {}
      const policy = {}
      let connectedPolicyInstanceNo
      const ciBenifitNature = get(
        rule.conditions.find(conn => conn.benefitCode === POLICY_BENEFIT_CI_PLUS),
        'benefitNature',
        ''
      )

      rule.conditions.some(condition => {
        const pol = selectedPolicyStructure.find(
          p =>
            p.productId === condition.productId &&
            (!(currentPolicy && currentPolicy.policyInstanceNo) ||
              (currentPolicy.productId === condition.productId &&
                String(currentPolicy.policyInstanceNo) === String(p.policyInstanceNo)) ||
              (currentPolicy.productId !== condition.productId &&
                String(currentPolicy.policyInstanceNo) !== String(p.policyInstanceNo))) &&
            ((currentPolicy &&
              alreadyLinkedPolicy[currentPolicy.policyInstanceNo] &&
              (alreadyLinkedPolicy[currentPolicy.policyInstanceNo].parent ||
                alreadyLinkedPolicy[currentPolicy.policyInstanceNo].child) &&
              ((alreadyLinkedPolicy[p.policyInstanceNo].parent &&
                alreadyLinkedPolicy[alreadyLinkedPolicy[p.policyInstanceNo].parent].child &&
                String(
                  alreadyLinkedPolicy[alreadyLinkedPolicy[p.policyInstanceNo].parent].child
                ) === String(p.policyInstanceNo)) ||
                (alreadyLinkedPolicy[p.policyInstanceNo].child &&
                  alreadyLinkedPolicy[alreadyLinkedPolicy[p.policyInstanceNo].child].parent &&
                  String(
                    alreadyLinkedPolicy[alreadyLinkedPolicy[p.policyInstanceNo].child].parent
                  ) === String(p.policyInstanceNo)))) ||
              !(
                alreadyLinkedPolicy[p.policyInstanceNo] &&
                (alreadyLinkedPolicy[p.policyInstanceNo].parent ||
                  alreadyLinkedPolicy[p.policyInstanceNo].child)
              ))
        )
        const covers = get(pol, 'covers', get(pol, 'benefits', []))
        const isCoverSelectedByUser = covers.some(c => {
          if (
            (c.benefitCode || c.type) === condition.benefitCode &&
            (!currentPolicy ||
              !condition.benefitNature ||
              condition.benefitNature === get(c, 'parentBenefitReference.benefitNature', ''))
          ) {
            referredCover = c
            return true
          }
          return false
        })
        if (
          pol &&
          ((condition.selected && isCoverSelectedByUser) ||
            (!condition.selected && !isCoverSelectedByUser))
        ) {
          policy[pol.policyInstanceNo] = pol
          conditionsCount += 1
          if (
            !ciBenifitNature ||
            !currentPolicy ||
            (ciBenifitNature &&
              ((ciBenifitNature === BENEFIT_NATURE_TYPE_RIDER_CONNECTED &&
                pol.policyInstanceNo !== currentPolicy.policyInstanceNo) ||
                (ciBenifitNature === BENEFIT_NATURE_TYPE_RIDER &&
                  pol.policyInstanceNo === currentPolicy.policyInstanceNo)))
          ) {
            connectedPolicyInstanceNo = pol.policyInstanceNo
          }
        } else if (
          (!pol || !pol.covers.length) &&
          !condition.selected &&
          (!currentPolicy || (currentPolicy && currentPolicy.policyInstanceNo))
        ) {
          connectedPolicyInstanceNo = (currentPolicy || selectedPolicyStructure).policyInstanceNo
          policy[(currentPolicy || selectedPolicyStructure).policyInstanceNo] =
            currentPolicy || selectedPolicyStructure
          conditionsCount += 1
        }
      })
      if (conditionsCount === rule.conditions.length) {
        connection.isConnected = true
        connection.connectionReference = {
          benefitInstanceNo:
            rule.action.benefitNature !== BENEFIT_NATURE_TYPE_RIDER_CONNECTED &&
            rule.action.benefitCode
              ? get(
                  get(
                    currentPolicy || policy[connectedPolicyInstanceNo],
                    'covers',
                    get(currentPolicy || policy[connectedPolicyInstanceNo], 'benefits', [])
                  ).find(c => (c.benefitCode || c.type) === rule.action.benefitCode),
                  'benefitInstanceNo'
                )
              : referredCover.benefitInstanceNo,
          productNumber:
            rule.action.benefitNature !== BENEFIT_NATURE_TYPE_RIDER_CONNECTED &&
            rule.action.benefitCode
              ? (currentPolicy || policy[connectedPolicyInstanceNo]).policyInstanceNo
              : (policy[connectedPolicyInstanceNo] || currentPolicy).policyInstanceNo ||
                policy[connectedPolicyInstanceNo].policyNo,
        }
        connection.connectedWith = { ...rule.action }
        isConditionValid = true
        return true
      }
    }
    // if no condition and standalone Action
    if (!rule.conditions && rule.action) {
      connection.isConnected = true
      connection.connectedWith = { ...rule.action }
      isConditionValid = true
      if (rule.action.benefitNature === BENEFIT_NATURE_TYPE_RIDER) {
        connection.connectionReference = {
          benefitInstanceNo:
            currentPolicy &&
            get(
              currentPolicy.covers.find(c => (c.type || c.benefitCode) === rule.action.benefitCode),
              'benefitInstanceNo',
              ''
            ),
          productNumber: currentPolicy && currentPolicy.policyInstanceNo,
        }
      }
    }
    return isConditionValid
  })
  return connection
}

// Check if product code is inside or outside super`
// @param {string} code - product code
export const isNonSuperProduct = (code: string): Boolean =>
  code !== '' && code.trim() === POLICY_PRODUCT_CODE_NON_SUPER

// Check if super fund code is MASTER KEY ACCOUNT or `WRAP NAVIGATOR
// @param {string} code - fundDetail
export const isPolicyFeeDisabled = (code: string): Boolean =>
  code !== '' && (code.trim() === MASTER_KEY_ACCOUNT_ID || code.trim() === WRAPPER_NAVIGATOR_ID)

// calculate the total sum insured across all policies in the quote as a number.
export const getTotalSumInsuredForQuote = (
  policyStructure: Array<{
    covers: Array<{
      coverAmount: number,
    }>,
  }>
) =>
  policyStructure
    .map(policy =>
      policy.covers.reduce((premiumTotal, cover) => premiumTotal + Number(cover.coverAmount), 0)
    )
    .reduce((quoteSumInsured, policySumInsured) => quoteSumInsured + policySumInsured, 0)

// find the remuneration value from a benefit based on the identifying code.
const getCoverRemunerationPercentage = (cover, remunerationCode) => {
  const remunerationDiscount = get(cover, 'remunerationDiscount', []).find(
    discount => discount.code === remunerationCode
  )
  return (remunerationDiscount && remunerationDiscount.value) || 0
}

// convert CCI form data to benefitChildCI data.
export const childDataToBenefitAssured = (childData: ChildData) => {
  const { firstName, lastName, dateOfBirth, childGender, childSmokerStatus } = childData
  return {
    benefitChildCI: {
      firstName,
      lastName,
      dateOfBirth: moment(dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      gender: childGender,
      isSmoker: childSmokerStatus,
    },
  }
}

// get child data as `benefitChildCI` if cover is CCI.
export const getBenefitAssured = (cover: { benefitCode: string }, childData?: ChildData) => {
  if (!childData) {
    return {}
  }
  const { benefitCode } = cover
  return benefitCode === POLICY_CHILD_COVER_BENEFIT_CODE ? childDataToBenefitAssured(childData) : {}
}

/**
 * get Occupation Rating of a specific cover,
 * @param {array} policyStructure - policy structure
 * @param {object}  occupationalRating - policyInstanceNo that needs to be changed
 * @return {array} updated policyStructure
 */
export const getOccupationRatingForCover = (
  benefitCode: String,
  memberMandatories: object
): Array<Object> => {
  const { occupationalRating = {} } = memberMandatories
  return OCCUPATION_RATINGS_MAP[benefitCode]
    ? occupationalRating[OCCUPATION_RATINGS_MAP[benefitCode]] || ''
    : ''
}

// provide an object to update the cover if optional deferment period is available in product rules.
export const getWaitingPeriod = (cover): Object => {
  if (cover.allowableDefermentPeriod && cover.allowableDefermentPeriod.length) {
    const filteredWaitingPeriod = cover.allowableDefermentPeriod.find(period => period.selected)
    if (filteredWaitingPeriod) {
      const { unit, value } = filteredWaitingPeriod
      return {
        waitingPeriod: {
          unit,
          value,
        },
      }
    }
  }
  return {}
}
// provide an object to update the cover if optional benefit period is available in product rules.
export const getCoverPeriod = (cover): Object => {
  if (cover.allowableBenefitPeriod && cover.allowableBenefitPeriod.length) {
    const filteredCoverPeriod = cover.allowableBenefitPeriod.find(period => period.selected)
    if (filteredCoverPeriod) {
      const { unit, value } = filteredCoverPeriod
      return {
        coverPeriod: {
          unit,
          value,
        },
      }
    }
  }
  return {}
}

export const getCoverStyle = (cover): Object => {
  if (cover.allowableCoverStyle.length) {
    const coverStyle = cover.allowableCoverStyle.filter(style => style.selected)
    return {
      coverStyle: get(coverStyle[0], 'code', cover.allowableCoverStyle[0].code),
    }
  }

  return {}
}

const getCommissionProfile = (cover): Object => {
  if (cover.remuneration.length) {
    const remuneration = cover.remuneration.filter(rem => rem.selected)
    return get(remuneration[0], 'code', '')
  }
  return ''
}

export const getTpdDefinition = (cover): Object => {
  if (cover.allowableTpdDefinition.length) {
    const tpdDefinition = cover.allowableTpdDefinition.filter(tpd => tpd.selected)
    return {
      tpdDefinition: get(tpdDefinition[0], 'code', ''),
    }
  }
  return {}
}

export const getDiscounts = cover => ({
  discounts: get(cover, 'allowableDiscount', [])
    .filter(
      discount =>
        get(discount, 'discountType.code', '') === CORE_DISCOUNTS &&
        discount.discountName !== DISCOUNT_MLC_ON_TRACK_DISCOUNT
    )
    .map(discount => ({
      discountType: CORE_DISCOUNTS,
      discountName: discount.discountName,
    })),
})

export const isFeatureSelected = (features: Array<Object>, code: string) => {
  if (features && features.length) {
    return !!features.filter(
      feature => feature.featureName === code && feature.featureApplicable === 'Y'
    ).length
  }
  return false
}

export const isParentBenefitReferenceAvailable = (parentBenefitReference: Object) => {
  if (!isEmpty(parentBenefitReference)) {
    if (parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_STANDALONE) {
      return false
    }
    return true
  }
  return false
}

export const isLCBBAndDBBFeatureEnable = (cover: Object) => {
  if (!isEmpty(cover)) {
    const type = get(cover, 'type', '')
    const features = get(cover, 'features', [])
    if ((type === POLICY_BENEFIT_CI_PLUS || type === POLICY_BENEFIT_PTD) && features.length) {
      return !!features.filter(
        feature =>
          (feature.featureName === POLICY_FEATURE_DBB ||
            feature.featureName === POLICY_FEATURE_LCBB) &&
          feature.featureApplicable === 'Y'
      ).length
    }
  }
  return false
}

// check if it's an IP cover. Note: This list only includes IP benefits specific to MLC products.
export const isIPCover = cover =>
  !!(
    cover.type === INCOME_PROTECTION_PLATINUM_ID ||
    cover.type === INCOME_PROTECTION_STANDARD_ID ||
    cover.type === INCOME_PROTECTION_SR_ID
  )

// this is only for use when generating the initial policy structure from create quote entry points.
export const postProcessInitialPolicyStructure = (
  productData,
  memberMandatories,
  policyStructure,
  masterData,
  isIPCalculated,
  isLifeInsuredUpdated = false
) => {
  const MONTHS_PER_YEAR = 12
  const { validationsIPBenefit } = masterData
  let calculatedsumInsured = 0
  // track if ip covers have been hit.
  let ipCoverEncountered = false

  // loop through, update ip sum insured.
  const updatedPolicyStructure = policyStructure.map(policy => ({
    ...policy,
    covers: policy.covers.map(cover => {
      // for the first ip benefit, apply a default string value for sum insured.
      if (isIPCover(cover)) {
        let updatedCover = cover
        // TODO: revisit sum insured calculation once
        // new employmentStatus and earning fields are available

        const { earning, employmentStatus, earningExcludingSuper } = memberMandatories
        const earnings = isEmployee(employmentStatus) ? earningExcludingSuper : earning
        const maxIPTier1 = parseInt(
          isKeyAvailable(validationsIPBenefit, 'maxIPTier1', '240000'),
          10
        )
        const maxIPTier2 = parseInt(
          isKeyAvailable(validationsIPBenefit, 'maxIPTier2', '480000'),
          10
        )

        const percentageRatioTier1 = parseInt(
          isKeyAvailable(validationsIPBenefit, 'percentageRatioTier1', '70'),
          10
        )
        const percentageRatioTier2 = parseInt(
          isKeyAvailable(validationsIPBenefit, 'percentageRatioTier2', '50'),
          10
        )
        const percentageRatioTier3 = parseInt(
          isKeyAvailable(validationsIPBenefit, 'percentageRatioTier3', '20'),
          10
        )
        const defaultIPCapValue = parseInt(
          isKeyAvailable(validationsIPBenefit, 'defaultIPCapValue', '30000'),
          10
        )
        const ipOccupationalRating = get(memberMandatories, 'occupationalRating.IP', '')
        if (
          ipOccupationalRating &&
          cover.type === INCOME_PROTECTION_STANDARD_ID &&
          isLifeInsuredUpdated
        ) {
          if (ipOccupationalRating === RISKED_OCCUPATION) {
            updatedCover = updateRiskedOccCoverPeriod(
              updatedCover,
              validationsIPBenefit,
              policy.productId
            )
          } else {
            const ipCover = getBenefitFromProducts(
              productData,
              policy.productId,
              INCOME_PROTECTION_STANDARD_ID
            )
            updatedCover = {
              ...updatedCover,
              ...getWaitingPeriod(ipCover),
              ...getCoverPeriod(ipCover),
            }
          }
        }
        if (!ipCoverEncountered && !isIPCalculated) {
          ipCoverEncountered = true
          const splitEarnings = []
          if (earnings <= maxIPTier1) {
            splitEarnings.push({ ear: earnings, per: percentageRatioTier1 })
          } else if (earnings > maxIPTier1 && earnings <= maxIPTier2) {
            splitEarnings.push(
              { ear: maxIPTier1, per: percentageRatioTier1 },
              { ear: earnings - maxIPTier1, per: percentageRatioTier2 }
            )
          } else {
            splitEarnings.push(
              { ear: maxIPTier1, per: percentageRatioTier1 },
              { ear: maxIPTier2 - maxIPTier1, per: percentageRatioTier2 },
              { ear: earnings - maxIPTier2, per: percentageRatioTier3 }
            )
          }

          splitEarnings.map(({ ear, per }) => (calculatedsumInsured += (ear * per) / 100))
          calculatedsumInsured =
            calculatedsumInsured / MONTHS_PER_YEAR > defaultIPCapValue
              ? defaultIPCapValue
              : Math.floor(calculatedsumInsured / MONTHS_PER_YEAR)

          updatedCover = {
            ...updatedCover,
            coverAmount: Math.floor(calculatedsumInsured),
          }
        }
        return updatedCover
      }
      return {
        ...cover,
      }
    }),
  }))

  return { updatedPolicyStructure, calculatedsumInsured }
}

export const getFeatureName = (
  feature: { featureName: string },
  allowableFeatures: Array<{ code: string, value: string }>
) => {
  const findFeature = allowableFeatures.find(f => f.code === feature.featureName)
  return findFeature && findFeature.value // return featureName only when feature is found
}

export const getCoverFeaturesFromPreferences = (cover): Object => {
  if (cover.allowableFeatures && cover.allowableFeatures.length) {
    return {
      features: cover.allowableFeatures.map(feature => {
        const durationAttributes = feature.durationUnit
          ? {
              duration: feature.duration,
              durationUnit: feature.durationUnit,
            }
          : {}
        const featureElement =
          (cover.features && cover.features.find(item => item.code === feature.code)) || {}
        return {
          featureName: feature.code,
          featureApplicable: featureElement.featureApplicable || (feature.selected ? 'Y' : 'N'),
          ...durationAttributes,
        }
      }),
    }
  }
  return {}
}

export const getSubBenefitApplicableForFeature = (subBenefit: Object, productCover: Object) => {
  const isFeatureApplied = get(
    get(productCover, 'allowableFeatures', []).find(
      feature =>
        feature.code === subBenefit.subBenefitCode ||
        feature.code === get(subBenefit, 'subBenefitType.value', '')
    ),
    'selected',
    false
  )
  if (isFeatureApplied) {
    return 'Y'
  }
  return 'N'
}

export const getSubBenefitApplicable = (subBenefit: Object, productCover: Object) => {
  if (productCover.benefitCode === INCOME_PROTECTION_PLATINUM_ID) {
    if (subBenefit.subBenefitCode === POLICY_FEATURE_LSBO) {
      return getSubBenefitApplicableForFeature(subBenefit, productCover)
    }
  }
  return get(subBenefit, 'subBenefitType.value', '') === 'Mandatory' ? 'Y' : 'N'
}

export const getCoverSubBenefitsFromPreferences = (cover): Object => {
  const subBenefits = get(cover, 'subBenefits', [])
    .filter(subBenefit => get(subBenefit, 'subBenefitType.value', '') === 'Mandatory')
    .map(subBenefit => ({
      subBenefitCode: subBenefit.subBenefitCode,
      subBenefitName: subBenefit.subBenefitName,
      applicable: getSubBenefitApplicable(subBenefit, cover),
      mandatoryIndicator: 'M',
    }))

  return {
    subBenefits,
  }
}

export const getLSBOfromSubBenefits = subBenefits =>
  subBenefits &&
  subBenefits.length &&
  subBenefits.find(code => code.subBenefitCode === POLICY_FEATURE_LSBO)

export const isValidBenefit = (benefit: Object, memberMandatories: Object): boolean => {
  const { dateOfBirth, occupation, earning, employmentStatus, occupationalRating } =
    memberMandatories
  if (
    !checkMinMaxAge(dateOfBirth, benefit) ||
    !isValidMinSalary(earning, benefit) ||
    !isValidOccupation(occupation, benefit) ||
    !isValidEmployment(employmentStatus, benefit) ||
    isValidOccRatingBasedEmployment(occupationalRating, benefit, employmentStatus) ||
    getOccupationRatingForCover(benefit.benefitCode, memberMandatories) === D_RATED_OCCUPATION
  ) {
    return true
  }
  return false
}

// gives simple keys to access product data from product rules.
export const sortProducts = products => ({
  inSuperProduct:
    products[products.findIndex(product => product.productId === POLICY_PRODUCT_CODE_SUPER)],
  outsideSuperProduct:
    products[products.findIndex(product => product.productId === POLICY_PRODUCT_CODE_NON_SUPER)],
})

/**
 * return true/false if dependent cover available for benefit
 * @param { object } policyStructure
 * @param {Array<Object>} benefitDependentOn
 */
export const isDependentCoverAvailableInAllPolicy = (
  allPolicy: Object,
  benefitDependentOn: Array
): Boolean => {
  const isDependent =
    benefitDependentOn.length &&
    benefitDependentOn.some(
      dependent =>
        allPolicy &&
        allPolicy.some(
          policy =>
            policy.covers &&
            policy.covers.length &&
            policy.covers.some(
              cover =>
                policy.productId === dependent.productId && cover.type === dependent.benefitCode
            )
        )
    )
  return isDependent
}

// returns true/false if DependentPolicyAvailable
// @param { Array } benefits - benefits array of a cover
// @param { Array } benefitDependentOn - dependentOn of a benefit
export const isDependentPolicyAvailable = (benefits: Array, benefitDependentOn: Array): Boolean =>
  benefitDependentOn.length &&
  benefitDependentOn.some(dependent =>
    benefits.some(benefit => dependent.code === benefit.benefitCode)
  )

// returns benefits array filtered by client mandatories without grouping
// @param { Array } benefits - benefits array of a cover
// @param { object } memberMandatories - client mandatories

export const allBenefitsFilteredByMemberMandatories = (
  benefits,
  { dateOfBirth, occupation, earning, employmentStatus }
) =>
  benefits.filter(
    benefit =>
      checkMinMaxAge(dateOfBirth, benefit) &&
      isValidMinSalary(earning, benefit) &&
      isValidOccupation(occupation, benefit) &&
      isValidEmployment(employmentStatus, benefit)
  )

// returns benefits array filtered by client mandatories
// @param { Array } benefits - benefits array of a cover
// @param { object } memberMandatories - client mandatories
export const filterBenefitsByMemeberMandatories = (
  benefits: Array,
  memberMandatories: Object,
  updateLifeInsured: string = '',
  allPolicy: Object = {}
): Array => {
  const {
    dateOfBirth,
    occupation,
    earning,
    employmentStatus,
    occupationClassCode,
    occupationalRating,
  } = memberMandatories

  let ipIndex
  // eslint-disable-next-line no-unused-vars
  let ipCode

  // @FIXME: This looks like dead code?
  if (occupationClassCode && occupationClassCode.length) {
    const [{ benefit, code }] = occupationClassCode
    ipIndex = benefit.indexOf(INCOME_PROTECTION_TEXT)
    // eslint-disable-next-line no-unused-vars
    ipCode = code[ipIndex]
  }

  const filteredBenefits = benefits.filter(currentValue => {
    if (
      (!currentValue.displayOnChooseCover && !updateLifeInsured) ||
      (updateLifeInsured &&
        updateLifeInsured === UPDATE_FROM_LIFE_INSURED &&
        (!checkMinMaxAge(dateOfBirth, currentValue) ||
          !isValidMinSalary(earning, currentValue) ||
          !isValidOccupation(occupation, currentValue) ||
          !isValidOccupationalRating(occupationalRating, currentValue) ||
          !isValidEmployment(employmentStatus, currentValue) ||
          getOccupationRatingForCover(currentValue.benefitCode, memberMandatories) ===
            D_RATED_OCCUPATION))
    ) {
      return false
    }
    if (
      updateLifeInsured &&
      updateLifeInsured === UPDATE_FROM_LIFE_INSURED &&
      currentValue.dependentOn.length
    ) {
      return (
        isDependentPolicyAvailable(benefits, currentValue.dependentOn) &&
        !isValidBenefit(currentValue, memberMandatories)
      )
    }

    return true
  })

  return filteredBenefits.map(benefit => ({
    ...benefit,
    label: benefit.benefitShortDisplayName || getDisplayName(benefit),
    active: false,
    disabled: benefit.dependentOn.length
      ? !(
          allPolicy &&
          !!allPolicy.length &&
          !isValidBenefit(benefit, memberMandatories) &&
          !!isDependentCoverAvailableInAllPolicy(
            allPolicy,
            get(benefit, 'coverRule.dependentOnCovers', [])
          )
        )
      : isValidBenefit(benefit, memberMandatories),
  }))
}

// get products Benefits With Client Mandatories
export const productBenefitsWithClientMandatories = (data, memberMandatories) => {
  const sortedProducts = sortProducts(data)

  const products = Object.values(sortedProducts).map(product => ({
    ...product,
    displayText: product.productName || product.productDisplayName,
    benefits: filterBenefitsByMemeberMandatories(product.benefits, memberMandatories, '', {}),
  }))

  return products
}

// returns product object and benefits filtered by client mandatories
// @param { object } product - product object
// @param { object } memberMandatories - client mandatories
export const filterProductBenefitsByClientMandatories = (
  product: Object,
  memberMandatories: Object,
  updateLifeInsured: string = '',
  appliedPolicy: Object = {}
): Object => ({
  ...product,
  displayText: product.productName || product.productDisplayName,
  benefits: filterBenefitsByMemeberMandatories(
    product.benefits,
    memberMandatories,
    updateLifeInsured,
    appliedPolicy
  ),
})

const getBenefitInstanceNumber = (selectedPolicyStructure = [], connectedWith) => {
  let coverInstanceNumber = 0
  const policy = selectedPolicyStructure.find(pol => pol.productId === connectedWith.productId)
  get(policy, 'covers', []).some((cover, index) => {
    if ((cover.benefitCode || cover.type) === connectedWith.benefitCode) {
      coverInstanceNumber = index + 1
      return true
    }
    return false
  })
  return coverInstanceNumber
}

/**
 * return parentBenefitReference object by applying connection rules
 * @param {Object} appliedConnectionRules - connection rules
 * @param {Array} policyStructure - policy structure
 */

export const getParentBenefitReference = (
  appliedConnectionRules: Object<Object>,
  policyStructure: Array<Object>
) => {
  const benefitNature = get(appliedConnectionRules, 'connectedWith.benefitNature')
  const parentPolicyReferenceNo = get(
    appliedConnectionRules,
    'connectionReference.productNumber',
    get(appliedConnectionRules, 'connectedWith.productId') === POLICY_PRODUCT_CODE_SUPER ? 1 : 2
  ).toString()
  return benefitNature === BENEFIT_NATURE_TYPE_STANDALONE
    ? {}
    : {
        parentBenefitReference: {
          parentPolicyReferenceNo, // to get default policy number
          parentType: get(appliedConnectionRules, 'connectedWith.benefitCode'),
          parentBenefitInstanceNo: get(
            appliedConnectionRules,
            'connectionReference.benefitInstanceNo',
            getBenefitInstanceNumber(policyStructure, appliedConnectionRules.connectedWith)
          ).toString(),
          benefitNature: get(appliedConnectionRules, 'connectedWith.benefitNature'),
          parentQuotePolicyNo: parentPolicyReferenceNo,
        },
      }
}

export const isCoverForUI = (cover: Object): boolean => COVERS_NOT_FOR_UI.indexOf(cover.type) < 0

export const isCoverAvailable = (policy: Object): boolean => policy.covers && policy.covers[0]

export const isWOPInPolicy = (policy: Object): boolean =>
  policy.covers.some(cover => cover.type === WAIVER_BENEFIT_CODE)

export const getEscalations = cover => ({
  escalations: get(cover, 'allowableEscalations', []).map(escalation => ({
    escalationType: escalation.escalationType,
  })),
})

// returns cover object by quoteHeadlessEntity structure
// @param { object } cover - cover object
// @param { number } coverIndex - active policy
// @param { number } adviserDetails - adviserDetails
// @param { number } childData - cover childData
// @param { number } policyStructure - quote policy structure
export const convertCoverToEntityStructure = (
  cover: Object,
  coverIndex: number,
  adviserDetails: Object,
  childData: Object,
  selectedPolicyStructure: Array<Object>,
  memberMandatories: Object,
  healthyLivingOption: object = {},
  currentPolicy
): Object => {
  const appliedConnectionRules = getAppliedConnectionRules(
    cover,
    selectedPolicyStructure,
    currentPolicy
  )
  const { healthyLivingDiscount } = healthyLivingOption

  const parentBenefitReference = appliedConnectionRules.isConnected
    ? getParentBenefitReference(appliedConnectionRules, selectedPolicyStructure)
    : null

  const premiumStyle = getPremiumStyle(cover.premiumStyle, memberMandatories, cover)
  const commissionDetails = {
    commissionProfile: '',
    initialRemunerationPercentage: '',
    renewalRemunerationPercentage: '',
  }

  const updatedCommissionProfile = get(
    currentPolicy,
    'covers[0].commissionDetails.commissionProfile',
    ''
  )
  if (updatedCommissionProfile !== '') {
    commissionDetails.commissionProfile = updatedCommissionProfile
  } else {
    commissionDetails.commissionProfile = getCommissionProfile(cover)
  }
  commissionDetails.initialRemunerationPercentage = getCoverRemunerationPercentage(
    cover,
    'initialRemuneration'
  )
  commissionDetails.renewalRemunerationPercentage = getCoverRemunerationPercentage(
    cover,
    'renewalRemuneration'
  )

  const benefitInstanceNo =
    (currentPolicy &&
      currentPolicy.covers.filter(c => (c.type || c.benefitCode) === cover.benefitCode).length +
        1) ||
    1

  // checking healthy living aplicable for cover
  const isHealthyLivingApplicable = !!cover.allowableCampaigns.find(
    ele => ele.campaignCode === HEALTHY_LIVING_DISCOUNT
  )

  const productId = currentPolicy ? currentPolicy.productId : selectedPolicyStructure[0].productId
  const coverData = {
    type: cover.benefitCode,
    name: cover.benefitDisplayName,
    benefitInstanceNo,
    coverAmount: '0',
    sumAssuredApplicable: isCoverForUI({ type: cover.benefitCode }) ? 'OFF' : 'ON',
    sumInsuredFrequency: get(cover, 'sumInsuredFrequency[0].value', ''),
    sumInsuredFrequencyBasis: get(cover, 'sumInsuredFrequencyBasis.value', ''),
    premiumAmount: '0',
    stampDuty: 0,
    premiumStyle: (premiumStyle && premiumStyle.code) || 'Stepped',
    // TODO: read premiumStructureChangeAge from product json once available from bancs
    premiumStructureChangeAge: get(cover, 'premiumStructureChangeAge', ''),
    // TODO: remove occupationalRating, once BANCS is ready with their fix after 20/12
    occupationalRating: getOccupationRatingForCover(cover.benefitCode, memberMandatories),
    occupation: {
      occupationalRating: getOccupationRatingForCover(cover.benefitCode, memberMandatories),
      occupationCode: get(memberMandatories, 'occupationalRating.OCCP_CODE', ''),
    }, // will come from occupation list.
    commissionDetails: {
      // commissions: cover.remuneration.filter(rem => rem.selected).map(rem => ({
      // commissionProfile: rem.code,
      //   icSacrifice: '', // TODO: needs to populate
      //   rcSacrifice: '', // TODO: needs to populate
      //   commissionAmount: '', // TODO: needs to populate
      // })),
      agencyCode: get(adviserDetails, 'agencyCode', ''),
      commissionProfile: commissionDetails.commissionProfile,
      initialRemunerationPercentage: commissionDetails.initialRemunerationPercentage,
      renewalRemunerationPercentage: commissionDetails.renewalRemunerationPercentage,
      bancsProfileNumber: getBancsProfileNumber(
        productId,
        commissionDetails.commissionProfile,
        adviserDetails
      ),
    },
    // based on healthy label toggle button and cover is eligible for healthy living
    // we are assigning the object
    ...getCampaignDetailObject(isHealthyLivingApplicable, healthyLivingDiscount),
    ...parentBenefitReference,
    ...getCoverFeaturesFromPreferences(cover),
    ...getCoverSubBenefitsFromPreferences(cover),
    ...getWaitingPeriod(cover),
    ...getCoverPeriod(cover),
    ...getCoverStyle(cover),
    ...getBenefitAssured(cover, childData),
    ...getTpdDefinition(cover),
    ...getDiscounts(cover),
    ...getEscalations(cover),
  }

  return coverData
}

// take a benefit from product rules and assemble it into what the quote entity requires.
const convertCoversToEntityStructure = (
  selectedPolicyStructure,
  covers: ProductRulesBenefit,
  adviserDetails: {
    agencyCode: string,
    commissionProfiles?: Array<{
      profileCode?: string,
    }>,
  },
  childData: ChildData,
  memberMandatories: object,
  healthyLivingOption: object
): Array<Object> =>
  covers.map((cover, index) =>
    convertCoverToEntityStructure(
      cover,
      index,
      adviserDetails,
      childData,
      selectedPolicyStructure,
      memberMandatories,
      healthyLivingOption
    )
  )

/**
 * return product name with given policyInstanceNo
 * @param {string} policyInstanceNo
 * @param {string} productId
 */

export const renamePolicy = (
  policy: Object,
  insidePolicyNamePrefix: string,
  outsidePolicyNamePrefix: string,
  wrapPolicySuffix: string,
  superPolicySuffix: string,
  outsidePolicySuffix: string,
  showProductNameSuffix: boolean = true
) => {
  const { productId, superFundName, fundPaymentMethod, productName } = policy
  const isOwnedByMLCSuper = superFundName === SUPER_OWNER_INTERNAL
  const isOwnedBySMSF = superFundName === SUPER_OWNER_EXTERNAL
  const isOwnedByIOOF = superFundName === SUPER_OWNER_IOOF
  const isWrapPaymentMethod = fundPaymentMethod === WRAPPER_NAVIGATOR_ID
  const splitedProductName = showProductNameSuffix ? ` - ${productName.split('-')[1]}` : ''
  if (productId === POLICY_PRODUCT_CODE_SUPER) {
    return `${insidePolicyNamePrefix}${
      isOwnedBySMSF || isOwnedByIOOF || (isOwnedByMLCSuper && isWrapPaymentMethod)
        ? ` ${wrapPolicySuffix}`
        : ` ${superPolicySuffix}`
    }${splitedProductName}`
  }
  return `${outsidePolicyNamePrefix}${outsidePolicySuffix}${splitedProductName}`
}

export const generateProductName = (policyInstanceNo: string) =>
  `MLC Insurance - Policy ${Number(policyInstanceNo)}`

/**
 * return the appropriate legacySystemProductCode value.
 * this can be invoked with productRules or with a specific product object.
 * @param {object} [options] - data to invoke this function with.
 * @param {string} [options.paymentInstructions] - the super fund name (e.g.
 *   'SMSF (External Trustee)') to lookup the legacy code with.
 * @param {string} [options.productRules] - all the product rules.
 * @param {string} [options.product] - a specific product from product rules.
 */
export const getLegacyProductCode = ({
  paymentInstructions,
  productRules,
  product,
  fundPaymentMethod,
}: {
  // TODO: change `paymentInstructions` to `superFundName` project-wide.
  paymentInstructions?: string,
  productRules?: Object,
  product?: Object,
  fundPaymentMethod?: string,
}): string => {
  const insideLegacyCode = (insideSuper, superFundName) => {
    let superFundNameForLegacyCode = superFundName
    if (fundPaymentMethod === WRAPPER_NAVIGATOR_ID) {
      // For wrap/navigator of super, latestSeriesCode of external super should be used
      superFundNameForLegacyCode = SUPER_OWNER_EXTERNAL
    }
    const matchingPaymentMethod = insideSuper.paymentMethods.find(
      method => method.superFundName === superFundNameForLegacyCode
    )
    return matchingPaymentMethod ? matchingPaymentMethod.latestSeriesCode : ''
  }
  const firstLegacyCode = firstLegacyCodeProduct =>
    firstLegacyCodeProduct.paymentMethods[0].latestSeriesCode

  // if specific product data is supplied...
  if (product) {
    // if no paymentInstructions supplied, we assume it's a non-super policy
    // and return the first legacy code in the product data.
    if (!paymentInstructions) {
      return firstLegacyCode(product)
    }
    // otherwise, lookup the legacy code in the product's data.
    return insideLegacyCode(product, paymentInstructions)
  }

  // if all product data is supplied as productRules...
  if (productRules) {
    // if paymentInstructions are supplied, it's an Inside Super policy and we
    // use them to look up the appropriate legacy code based on
    // superFundName/paymentInstructions.
    if (paymentInstructions) {
      return insideLegacyCode(
        getProduct(productRules, POLICY_PRODUCT_CODE_SUPER),
        paymentInstructions
      )
    }
    // otherwise, if no paymentInstructions are supplied, it's a non-super
    // (outside) policy, so let's look up the legacy code in the rules.
    return firstLegacyCode(getProduct(productRules, POLICY_PRODUCT_CODE_NON_SUPER))
  }

  // if we make it here (we shouldn't), we don't have enough data to derive the
  // legacy code, so let's return an empty string.
  return ''
}

// create a new policy structure including it's included covers (in product rules format).
export const createPolicy = (
  selectedPolicyStructure = [],
  product: {
    productId: string,
    legacySystemProductCode: string,
  },
  policyIndex = 1,
  covers: Array<Object> = [],
  adviserDetails: Object = {},
  childData: ChildData,
  memberMandatories: object,
  healthyLivingOption: object
) => {
  const productId = product && product.productId // translate key between pds rules and mule.
  const policy = {
    productId,
    productName: generateProductName(policyIndex),
    policyInstanceNo: policyIndex,
    agencyDetails: [
      {
        bancsCustomerNo: getBancsCustomerNumber(),
        agencyCode: adviserDetails.agencyCode,
        accountCode: get(adviserDetails, 'commissionProfiles[0].profileCode', 'NW001'),
      },
    ],
    mlcOnTrack: false,
    paymentFrequency: 'MON',
    policyFee: 'N',
    policyFeeValue: 0,
    legacySystemProductCode: getLegacyProductCode({ product }),
    seriesCodeVersion: get(product, 'seriesVersionMapping[0].latestSeriesVersion', ''),
    currency: 'AUD',
    totalPremiumAmount: '0',
    covers: convertCoversToEntityStructure(
      selectedPolicyStructure,
      covers,
      adviserDetails,
      childData,
      memberMandatories,
      healthyLivingOption
    ),
    relationships: [],
    beneficiaries: [],
    productClass: NON_SUPER_PRODUCT_CLASS,
    premiumPaymentMethod: 'CC', // TODO: To be made optional from API
  }

  return policy
}

// Returns the label value with Cover friendly/display name
export const getBenefitLinkOptionLabel = (
  {
    name,
    benefitInstanceNo,
    optimiserParentBenefitReference,
  }: {
    name: string,
    benefitInstanceNo?: string | number,
    optimiserParentBenefitReference?: Object,
  },
  parentPolicyReferenceNo?: string | number
): string =>
  `${name} ${benefitInstanceNo || ''} ${
    (optimiserParentBenefitReference && '(Optimiser)') || ''
  } (Policy ${parentPolicyReferenceNo})`

// Generates and returns an object with label and value propperties
// which will be used in dropdown link options

export const getBenefitOption = ({
  name,
  policyInstanceNo,
  type,
  benefitInstanceNo,
  sourceBenefitNature,
}: {
  name: string,
  policyInstanceNo: number | string,
  type: string,
  benefitInstanceNo: string | number,
  sourceBenefitNature: SourceBenefitNatureType,
}): BenefitLinkOptionType => {
  const policyInstanceNoAsString = policyInstanceNo.toString()
  const option = {
    label: getBenefitLinkOptionLabel({ name, benefitInstanceNo }, policyInstanceNoAsString),
    value: {
      parentPolicyReferenceNo: policyInstanceNoAsString.toString(),
      parentType: type,
      parentBenefitInstanceNo: benefitInstanceNo.toString(),
    },
  }

  switch (sourceBenefitNature) {
    case BENEFIT_NATURE_TYPE_STANDALONE: {
      return {
        ...option,
        label: BENEFIT_NATURE_TYPE_STANDALONE,
        value: {
          ...option.value,
          benefitNature: BENEFIT_NATURE_TYPE_STANDALONE,
        },
      }
    }

    case BENEFIT_NATURE_TYPE_RIDER: {
      return {
        ...option,
        value: {
          ...option.value,
          benefitNature: BENEFIT_NATURE_TYPE_RIDER,
        },
      }
    }

    case BENEFIT_NATURE_TYPE_RIDER_CONNECTED: {
      return {
        ...option,
        value: {
          ...option.value,
          benefitNature: BENEFIT_NATURE_TYPE_RIDER_CONNECTED,
        },
      }
    }

    default: {
      return option
    }
  }
}

export const getBenefitTypeVariationForCover = (
  cover: Object,
  productRules: Object,
  productId: string,
  occupationalRating: string
) => {
  const { benefits } = getProduct(productRules, productId)
  const { benefitType: coverBenefitType } = getBenefit(
    getProduct(productRules, productId).benefits,
    cover.type
  )

  return {
    ...cover,
    benefitTypeVariation: benefits
      .filter(benefit => {
        const { benefitType } = benefit
        return (
          benefitType &&
          benefitType.code &&
          benefitType.code === (coverBenefitType && coverBenefitType.code)
        )
      })
      .reduce((variation, benefit) => {
        const { benefitShortDisplayName, benefitCode } = benefit

        return isValidOccupationalRating(occupationalRating, benefit)
          ? variation.concat({
              label: benefitShortDisplayName,
              value: benefitCode,
            })
          : variation
      }, []),
  }
}
export const getBenefitTypeVariation = (
  covers: Array,
  productRules: Object,
  productId: string,
  occupationalRating: String
) =>
  covers.map(cover =>
    getBenefitTypeVariationForCover(cover, productRules, productId, occupationalRating)
  )

// returns true if the cover object is CCI and has child data
export const coverHasChildData = (cover: { type: string, benefitChildCI?: {} }): boolean => {
  const { benefitChildCI, type } = cover
  return type === POLICY_CHILD_COVER_BENEFIT_CODE && typeof benefitChildCI !== 'undefined'
}

// sorts a `covers` array by `benefitInstanceNo`
type CoversWithIds = Array<{ benefitInstanceNo: number }>
export const sortCoversById = (covers: CoversWithIds): CoversWithIds =>
  [...covers].sort((firstCover, secondCover) =>
    firstCover.benefitInstanceNo < secondCover.benefitInstanceNo ? -1 : 1
  )

// Check if all the policies covers remuneration types 'commissionProfile' are the same
export const coversStructureMatch = covers => {
  if (!covers) return false
  return covers.every(
    (cover, i, arr) =>
      cover.commissionDetails.commissionProfile === arr[0].commissionDetails.commissionProfile
  )
}

// updates a given covers remuneration details
export const setCoverRemuneration = (
  cover,
  { initialRemunerationPercentage, renewalRemunerationPercentage, commissionProfile },
  productId,
  advisorDetails
) => ({
  ...cover,
  commissionDetails: {
    ...cover.commissionDetails,
    commissionProfile,
    initialRemunerationPercentage:
      initialRemunerationPercentage ||
      get(cover, 'commissionDetails.initialRemunerationPercentage', ''),
    renewalRemunerationPercentage:
      renewalRemunerationPercentage ||
      get(cover, 'commissionDetails.renewalRemunerationPercentage', ''),
    bancsProfileNumber: getBancsProfileNumber(productId, commissionProfile, advisorDetails),
  },
})

// updates a given array of covers remuneration details
export const setRemuneration = (covers, remunerations, productId, advisorDetails) =>
  covers.map(cover => setCoverRemuneration(cover, remunerations, productId, advisorDetails))

// returns true if the payment method triggers the fixed payment frequency rule
export const isFixedPaymentFrequency = (paymentMethod: ?string): boolean =>
  paymentMethod === POLICY_FIXED_PAYMENT_METHOD

// returns true if a given policy should have a fixed payment frequency
export const isPolicyFixedFrequency = (policy: {
  productId: string,
  premiumPaymentMethod: string,
}): boolean => {
  const { productId, premiumPaymentMethod } = policy
  // TODO: when the policy entity allows `fundPaymentMethod` on the policy level,
  // this paymentMethod constant can then be simplified to check for `fundPaymentMethod` only.
  const paymentMethod = premiumPaymentMethod || policy.fundPaymentMethod
  return productId === POLICY_PRODUCT_CODE_SUPER && isFixedPaymentFrequency(paymentMethod)
}

// returns array of benefits filtered by max childCI count, benefit dependentOn
// @param { array } benefits - filtered benefits from productRules
// @param { object } appliedPolicy - active policy
export const filterBenefitsByDependentAndCICount = (
  benefits: Array,
  appliedPolicy: Object
): Array => {
  // total child CI count
  const totalChildCICount =
    appliedPolicy.covers &&
    appliedPolicy.covers.length &&
    appliedPolicy.covers.reduce(
      (accumulator, currentValue) =>
        currentValue.type === POLICY_CHILD_COVER_BENEFIT_CODE ? accumulator + 1 : accumulator,
      0
    )

  return benefits.reduce((accumulator, benefit) => {
    if (
      benefit.benefitCode === POLICY_CHILD_COVER_BENEFIT_CODE &&
      totalChildCICount >= QUOTE_TOOL_MAX_CHILD_CI_COUNT
    ) {
      return [...accumulator, { ...benefit, disabled: true }]
    }
    return [
      ...accumulator,
      {
        ...benefit,
      },
    ]
  }, [])
}

// Returns dependentOnCovers value of a benefit
// @param {object} benefitData - Object containing info of benefit
// @return {array} dependentOn array for the benefit
export const getDependentOn = (benefitData: {
  benefitType: string,
  productId: string,
  productRulesData: Object,
}): Array => {
  const { benefitType, productId, productRulesData } = benefitData
  return get(
    productRulesData
      .find(product => product.productId === productId)
      .benefits.find(benefit => benefit.benefitCode === benefitType),
    'coverRule.dependentOnCovers',
    []
  )
}

// formats a string/number to be displayed in currency format
// 210000000.111111 returns '$21,000,000.11'
export const formatCurrency = (
  input: ?string | ?number,
  prefix: string = '$',
  fraction: number = 2,
  reduceZeros: boolean = false
): string => {
  const isValidInput = typeof input === 'number' || typeof input === 'string'
  const value = isValidInput ? Number(input) : 0
  const negativePrefix = value < 0 ? '-' : ''
  const formattedValue = `${negativePrefix}${prefix}${Math.abs(value).toLocaleString(undefined, {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  })}`
  if (reduceZeros) {
    return Math.abs(Number(value)) >= 1.0e6
      ? `${prefix}${Math.abs(Number(value)) / 1.0e6}M`
      : Math.abs(Number(value)) >= 1.0e3
      ? `${prefix}${Math.abs(Number(value)) / 1.0e3}K`
      : formattedValue
  }
  return formattedValue
}

// returns true if a given policy has a policy fee.
export const policyHasFee = (policy: Object): boolean =>
  policy.policyFee !== 'undefined' && policy.policyFee === 'Y' // lol

// sums cover/benefit `stampDuty` values to return policy stamp duty value
export const getPolicyStampDuty = (policy: Object): number =>
  policy.covers.reduce(
    (totalStampDuty, { stampDuty }) => totalStampDuty + Number(stampDuty || 0),
    0
  )

// sorts policies by Inside Super policy first
export const sortPolicies = (policyStructure: Array<{}>): Array<{}> =>
  [].concat(policyStructure).sort((firstPolicy, secondPolicy) => {
    if (firstPolicy.productClass !== NON_SUPER_PRODUCT_CLASS) {
      return -1
    }
    if (secondPolicy.productClass !== NON_SUPER_PRODUCT_CLASS) {
      return 1
    }
    return 0
  })

// returns array of all covers across products with additional helper attributes
// @param { object } policyStructure - policy to map
const combinePolicyCovers = (policyStructure, appliedPolicyInstanceNo, alreadyLinkedPolicyList) => {
  if (
    alreadyLinkedPolicyList[appliedPolicyInstanceNo] &&
    alreadyLinkedPolicyList[appliedPolicyInstanceNo].child
  ) {
    const connectedPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === alreadyLinkedPolicyList[appliedPolicyInstanceNo].child
    )
    return uniqWith(
      connectedPolicy.covers.filter(
        cover =>
          (cover.type === POLICY_BENEFIT_CI_PLUS || cover.type === POLICY_BENEFIT_CI_STD) &&
          get(cover, 'parentBenefitReference.benefitNature', '') ===
            BENEFIT_NATURE_TYPE_RIDER_CONNECTED &&
          get(cover, 'parentBenefitReference.parentPolicyReferenceNo', '').toString() ===
            appliedPolicyInstanceNo.toString(),
        (c1, c2) =>
          c1.policyInstanceNo === c2.policyInstanceNo &&
          c1.type === c2.type &&
          c1.benefitInstanceNo === c2.benefitInstanceNo
      )
    ).map(c => ({
      ...c,
      productId: connectedPolicy.productId,
      policyInstanceNo: connectedPolicy.policyInstanceNo,
    }))
  }
  if (
    alreadyLinkedPolicyList[appliedPolicyInstanceNo] &&
    alreadyLinkedPolicyList[appliedPolicyInstanceNo].parent
  ) {
    const connectedPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === alreadyLinkedPolicyList[appliedPolicyInstanceNo].parent
    )
    let covers = []
    if (
      alreadyLinkedPolicyList[appliedPolicyInstanceNo].count === 1 &&
      alreadyLinkedPolicyList[appliedPolicyInstanceNo].policyInstanceNos.includes(
        appliedPolicyInstanceNo
      )
    ) {
      covers = flatten(
        policyStructure.map(policy => {
          if (
            appliedPolicyInstanceNo === policy.policyInstanceNo ||
            (alreadyLinkedPolicyList[policy.policyInstanceNo] &&
              (alreadyLinkedPolicyList[policy.policyInstanceNo].parent ||
                alreadyLinkedPolicyList[policy.policyInstanceNo].child))
          ) {
            return {}
          }
          return uniqWith(
            policy.covers.map(cover => ({
              ...cover,
              productId: policy.productId,
              policyInstanceNo: policy.policyInstanceNo,
            })),
            (c1, c2) =>
              c1.policyInstanceNo === c2.policyInstanceNo &&
              c1.type === c2.type &&
              c1.benefitInstanceNo === c2.benefitInstanceNo
          )
        })
      )
    }

    return connectedPolicy
      ? uniqWith(
          [
            ...covers,
            ...connectedPolicy.covers.map(cover => ({
              ...cover,
              productId: connectedPolicy.productId,
              policyInstanceNo: connectedPolicy.policyInstanceNo,
            })),
          ],
          (c1, c2) =>
            c1.policyInstanceNo === c2.policyInstanceNo &&
            c1.type === c2.type &&
            c1.benefitInstanceNo === c2.benefitInstanceNo
        )
      : {}
  }

  return flatten(
    policyStructure.map(policy => {
      if (
        appliedPolicyInstanceNo === policy.policyInstanceNo ||
        (alreadyLinkedPolicyList[policy.policyInstanceNo] &&
          (alreadyLinkedPolicyList[policy.policyInstanceNo].parent ||
            alreadyLinkedPolicyList[policy.policyInstanceNo].child))
      ) {
        return {}
      }
      return uniqWith(
        policy.covers.map(cover => ({
          ...cover,
          productId: policy.productId,
          policyInstanceNo: policy.policyInstanceNo,
        })),
        (c1, c2) =>
          c1.policyInstanceNo === c2.policyInstanceNo &&
          c1.type === c2.type &&
          c1.benefitInstanceNo === c2.benefitInstanceNo
      )
    })
  )
}

const tpdOptimiserOption = (
  options,
  linkage,
  appliedPolicy,
  cover,
  policyStructure,
  isOwnTPDDisabled
) => {
  if (cover.type !== POLICY_BENEFIT_PTD || isOwnTPDDisabled) {
    return options
  }
  const { productId, policyInstanceNo } = appliedPolicy
  const { type, benefitInstanceNo, parentBenefitReference } = cover
  if (parentBenefitReference) {
    const { parentBenefitInstanceNo, parentPolicyReferenceNo, parentType } = parentBenefitReference

    if (
      // level 1 : when tpd is within super policy and connected to LC of Super
      (!isNonSuperProduct(productId) && parentType === POLICY_BENEFIT_LC) ||
      (isNonSuperProduct(productId) &&
        // level 2 : when tpd is within non super policy and connected to LC of Super
        parentType === POLICY_BENEFIT_LC &&
        policyStructure.some(
          policy =>
            !isNonSuperProduct(policy.productId) &&
            String(policy.policyInstanceNo) === String(parentPolicyReferenceNo)
        )) ||
      // level 3 : when tpd is within super/nonsuper policy and
      // connected to CI of NonSuper and CI is connected with LC of Super
      ((parentType === POLICY_BENEFIT_CI_PLUS || parentType === POLICY_BENEFIT_CI_STD) &&
        policyStructure.some(
          policy =>
            isNonSuperProduct(policy.productId) &&
            String(policy.policyInstanceNo) === String(parentPolicyReferenceNo) &&
            policy.covers.some(
              c =>
                String(c.benefitInstanceNo) === String(parentBenefitInstanceNo) &&
                c.type === parentType &&
                get(c, 'parentBenefitReference.parentType', '') === POLICY_BENEFIT_LC &&
                policyStructure.some(
                  p =>
                    !isNonSuperProduct(p.productId) &&
                    String(p.policyInstanceNo) ===
                      get(c, 'parentBenefitReference.parentPolicyReferenceNo', '')
                )
            )
        ))
    ) {
      return [
        ...options,
        {
          label: POLICY_OPTIMISER_LABEL,
          value: {
            benefitNature: BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
            parentBenefitInstanceNo: String(benefitInstanceNo),
            parentPolicyReferenceNo: String(policyInstanceNo),
            parentQuotePolicyNo: String(policyInstanceNo),
            parentType: type,
          },
        },
      ]
    }
  }

  return options
}

// Returns an array of benefit link options for a given cover,
export const getLinks = (
  cover: Object,
  productRules: Object,
  policyStructure: Array,
  appliedPolicy: {
    productId: ProductIdType,
    policyInstanceNo: Number,
  },
  enableTPDOptimser: boolean,
  isOwnTPDDisabled = false
) => {
  const { productId, policyInstanceNo } = appliedPolicy
  const { type, benefitInstanceNo } = cover
  const benefits = getBenefit(getProduct(productRules, productId).benefits, type)
  const { benefitCode, benefitName, benefitRelationships } = benefits
  const alreadyLinkedPolicyList = getAlreadyLinkedPolicy(policyStructure)
  const options = benefitRelationships.reduce((acc, curr) => {
    const { sourceBenefitNature, targetBenefitNature, targetProductCode, targetBenefitCode } = curr
    let links = []
    if (sourceBenefitNature === BENEFIT_NATURE_TYPE_RIDER_CONNECTED) {
      const combinePolicy = combinePolicyCovers(
        policyStructure,
        policyInstanceNo,
        alreadyLinkedPolicyList
      )

      links =
        combinePolicy &&
        combinePolicy.length &&
        combinePolicy
          .filter(
            item =>
              benefitCode !== item.type &&
              item.type === targetBenefitCode &&
              item.productId === targetProductCode &&
              (targetBenefitNature === BENEFIT_NATURE_TYPE_STANDALONE ||
                (item.parentBenefitReference && item.parentBenefitReference.benefitNature) ===
                  targetBenefitNature) &&
              !(
                item.productId !== productId &&
                item.type === POLICY_BENEFIT_CI_PLUS &&
                !(
                  alreadyLinkedPolicyList[policyInstanceNo] &&
                  (alreadyLinkedPolicyList[policyInstanceNo].parent ||
                    alreadyLinkedPolicyList[policyInstanceNo].child)
                )
              )
          )
          .map(item => ({
            name: item.name,
            policyInstanceNo: item.policyInstanceNo,
            type: item.type,
            benefitInstanceNo: item.benefitInstanceNo,
            sourceBenefitNature,
          }))
      return links ? [...acc, ...links] : [...acc]
    }
    if (sourceBenefitNature === BENEFIT_NATURE_TYPE_RIDER) {
      links = uniqWith(
        appliedPolicy.covers,
        (c1, c2) => c1.type === c2.type && c1.benefitInstanceNo === c2.benefitInstanceNo
      )
        .filter(
          item =>
            item.type === targetBenefitCode &&
            productId === targetProductCode &&
            (targetBenefitNature === BENEFIT_NATURE_TYPE_STANDALONE ||
              (item.parentBenefitReference && item.parentBenefitReference.benefitNature) ===
                targetBenefitNature)
        )
        .map(item => ({
          name: item.name,
          policyInstanceNo,
          type: item.type,
          benefitInstanceNo: item.benefitInstanceNo,
          sourceBenefitNature,
        }))
      return [...acc, ...links]
    }
    if (
      sourceBenefitNature === BENEFIT_NATURE_TYPE_STANDALONE &&
      targetBenefitNature === BENEFIT_NATURE_TYPE_NA
    ) {
      links = [
        {
          name: benefitName,
          policyInstanceNo,
          type,
          benefitInstanceNo: cover.benefitInstanceNo,
          sourceBenefitNature,
        },
      ]
      return [...acc, ...links]
    }
    return acc
  }, [])
  // Remove duplicate items and make options
  let reducedOptions = options
    .filter(
      (opt, index, self) =>
        index === self.findIndex(obj => JSON.stringify(obj) === JSON.stringify(opt))
    )
    .map(getBenefitOption)
  if (enableTPDOptimser) {
    reducedOptions = tpdOptimiserOption(
      reducedOptions,
      alreadyLinkedPolicyList,
      appliedPolicy,
      cover,
      policyStructure,
      isOwnTPDDisabled
    )
  }

  return alreadyLinkedPolicyList[policyInstanceNo] &&
    alreadyLinkedPolicyList[policyInstanceNo].parent &&
    alreadyLinkedPolicyList[policyInstanceNo].count === 1 &&
    !alreadyLinkedPolicyList[policyInstanceNo].benefitInstanceNos.includes(benefitInstanceNo)
    ? reducedOptions.filter(
        opt =>
          opt.value.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER ||
          opt.value.benefitNature === BENEFIT_NATURE_TYPE_STANDALONE ||
          opt.value.benefitNature === BENEFIT_NATURE_TYPE_RIDER ||
          (opt.value.benefitNature === BENEFIT_NATURE_TYPE_RIDER_CONNECTED &&
            alreadyLinkedPolicyList[opt.value.parentPolicyReferenceNo] &&
            alreadyLinkedPolicyList[opt.value.parentPolicyReferenceNo].child)
      )
    : reducedOptions
}

// returns a flat array of benefit/cover objects from a policyStructure.
export const getAllBenefits = (policyStructure: Array<Object>): Array<Object> =>
  flatten(policyStructure.map(({ covers }) => covers.map(cover => cover)))

// returns a unique array of all discountTypes in a quote's benefits.
export const getAllDiscountCodes = (policyStructure: Array<Object>): Array<?string> =>
  uniq(
    flatten(
      policyStructure.reduce((codes, policy) => {
        const policyCodes = (policy.covers || [])
          .filter(cover => typeof cover.discounts !== 'undefined')
          .map(({ discounts }) => discounts.map(({ discountType }) => discountType))
        return [].concat(codes, policyCodes)
      }, [])
    )
  )

// returns true when a given `discountType` exists in all provided benefits
export const isDiscountAppliedToAllBenefits = (
  discountType: string,
  benefits: Array<Object>
): boolean =>
  benefits.reduce((isApplied, { discounts }) => {
    // return false if we already know not all benefits have the discount.
    // or return false when this benefit has no discounts.
    if (!isApplied || typeof discounts === 'undefined' || !discounts.length) {
      return false
    }
    // determine if `discountType` is in this benefit's discounts array
    return discounts.reduce((isAppliedToBenefit, { discountType: typeToCheck }) => {
      if (!isAppliedToBenefit && discountType === typeToCheck) {
        return true
      }
      return isAppliedToBenefit
    }, false)
  }, true)

// returns true when a discount type applies to all benefits in a quote.
export const isDiscountQuoteWide = (
  discountType: string,
  policyStructure: Array<Object>
): boolean => isDiscountAppliedToAllBenefits(discountType, getAllBenefits(policyStructure))

// returns an array of benefit names the given discount type is applied to.
// ['Life Cover', 'Permanent Total Disability']
export const getBenefitsFromDiscount = (
  discountType: string,
  policyStructure: Array<Object>
): Array<string> =>
  uniq(
    getAllBenefits(policyStructure).reduce((applicableBenefits, benefit) => {
      // if no discounts, move to the next benefit.
      if (typeof benefit.discounts === 'undefined' || !benefit.discounts.length) {
        return applicableBenefits
      }

      // if discount type exists in a benefit's discounts, push benefit.name to
      // applicableBenefits array.
      benefit.discounts
        .filter(({ discountType: typeToCheck }) => discountType === typeToCheck)
        .forEach(() => {
          applicableBenefits.push(benefit.name)
        })

      return applicableBenefits
    }, [])
  )

// returns a formatted discount amount, using values from the first discount
// object with `discountType` it finds. According to business rules, discount
// values of the same discount type across discount objects should be the same.
// "$15.00" or "25%"
export const getDiscountAmount = (discountType: string, policyStructure: Array<Object>): string =>
  getAllBenefits(policyStructure).reduce((amount, benefit) => {
    // if amount has been assigned, return early.
    // if discounts don't exist, return early.
    if (amount || typeof benefit.discounts === 'undefined' || !benefit.discounts.length) {
      return amount
    }

    // get first matching discount.
    const discount = benefit.discounts.find(
      ({ discountType: typeToCheck }) => discountType === typeToCheck
    )

    // if no matching discount, move on.
    if (!discount) {
      return amount
    }

    // assign formatted amount string to amount and return
    return discount.discountValueType === DISCOUNT_VALUETYPE_PERCENTAGE
      ? `${discount.discountValue}%`
      : formatCurrency(discount.discountValue)
  }, '')

// returns the display name for a given discount code.
// assumes that discount display names in benefits don't differ from benefit to benefit.
// "Multi Cover Discount"
export const getDiscountNameFromProductData = (
  discountType: string,
  productData: Array<Object>
): string => {
  // create a flat array of all discount objects across all benefits from product data.
  const allDiscounts = flatten(
    productData.reduce((discounts, product) => {
      discounts.push(
        flatten(
          product.benefits
            .filter(({ allowableDiscount }) => allowableDiscount && allowableDiscount.length)
            .map(({ allowableDiscount }) => allowableDiscount.map(discount => discount))
        )
      )
      return discounts
    }, [])
  )

  // find the first matching discount object.
  const discountInfo = allDiscounts.find(({ code }) => code === discountType)

  // return the discount's display name, stored as `value` in product data.
  // return the discount code if display name not found.
  return discountInfo ? discountInfo.value : discountType
}

/**
 * return filtered policyStructure array filled with
 * all benefit and product details with parentBenefitReference
 * @param {Array} policyStructure : Policy Structure
 * @param {Array} productRules : Product Rules
 */
export const getMLCLPolicyStructureDetails: Array = (
  policyStructure: Array<Object>,
  productRules: Array<Object>
) => {
  // create policyStructure array format
  const createPolicyStructure = policyStructure.map(({ covers, policyInstanceNo, productId }) => {
    const getProductDetails = getProduct(productRules, productId)
    const formattedCovers = covers.map(cover => {
      const getCoverDetails = getBenefit(getProductDetails.benefits, cover.type)
      return {
        ...cover,
        name: get(getCoverDetails, 'benefitDisplayName', ''),
      }
    })

    return {
      policyInstanceNo,
      productId,
      productName: getProductDetails.productName,
      covers: formattedCovers,
    }
  })

  return createPolicyStructure
}

/**
 * Returns true if valid cover amount
 *
 * @param {string} coverAmount - cover amount for cover
 * @return {boolean} true if valid cover amound else false
 */
export const isValidCoverAmount = (coverAmount: string): boolean => {
  const coverAmountNumber = Number(coverAmount)
  const newSumInsured = Number(coverAmount)

  if ((!coverAmountNumber && !newSumInsured) || (newSumInsured && coverAmountNumber <= 0)) {
    return false
  }
  return true
}

/**
 * Validates the quote collection
 *
 * @param {array} quotes - quotes collection
 * @return {boolean} true if valid quote collection
 */
export const validateQuote = (quotes: Array<Object>): boolean =>
  quotes.every(quote =>
    quote.policyStructure.every(policy =>
      policy.covers.every(cover =>
        isCoverForUI(cover) ? isValidCoverAmount(cover.coverAmount) : true
      )
    )
  )

// get life assured details to populate sidebar
export const getLifeAsssured = (state: Object): ?Object => {
  const activeIndex = get(state, 'activeIndex')
  const { policyStructure } = state ? state.quotes[activeIndex] : []
  const lifeAssured = policyStructure
    ? policyStructure.find(
        policy =>
          policy.relationships &&
          policy.relationships.find(relationship =>
            relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
          )
      )
    : null
  return (
    lifeAssured &&
    lifeAssured.relationships.find(relationship =>
      relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
    )
  )
}

// check if the life assured is added
export const isLifeAssured = quoteData => {
  const lifeAssured = getLifeAsssured(quoteData)
  return lifeAssured ? lifeAssured.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) : false
}

// get all policy owners without LA to populate sidebar
export const getPolicyRelationships = (state: Object): Array<Object> => {
  const activeIndex = get(state, 'activeIndex')
  const { policyStructure } = state.quotes[activeIndex]
  const relationships = uniq(
    flatten(
      policyStructure
        .filter(({ productId }) => isNonSuperProduct(productId))
        .reduce((arr, policy) => {
          if (policy.relationships) {
            arr.push(
              policy.relationships.filter(
                relationship => !relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
              )
            )
          }
          return arr
        }, [])
    )
  )
  return relationships
}

/**
 * Return a beneficiary entity formatted object
 * @param {object} beneficiary - details of a beneficiary
 * @param {string} bindingType - type of beneficiary
 */
export const createBeneficiaryEntity = (
  beneficiary: Object,
  bindingType: string,
  index: number,
  isLISelectedAsBeneficiary: boolean
): Object => {
  const { action, percentageAllocation, relationship, ...memberDetails } = beneficiary
  const {
    identifiers,
    title,
    firstName,
    lastName,
    dateOfBirth,
    addressSameAsLifeInsured,
    gender,
    policyInstanceNo,
    beneficiaryIndex,
    country,
    postCode,
    contactMethods,
    ...address
  } = memberDetails

  return {
    action,
    type: bindingType,
    percentageAllocation,
    relationship,
    roleCode: newBeneficiary.roleCode,
    relatedParty: {
      identifiers: identifiers || [
        {
          type: REF_NO,
          value: (moment().unix().toString() - index).toString(),
        },
      ],
      title,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      partyType: POLICY_PARTY_TYPE_INDIVIDUAL,
      ...(isLISelectedAsBeneficiary
        ? { contactMethods }
        : {
            contactMethods: {
              addresses: [
                {
                  ...address,
                  preferred: PREFERRED_YES,
                  country: country || COUNTRY_CODE,
                  postCode: postCode ? postCode.toString() : '',
                },
              ],
            },
          }),
    },
  }
}

// get active policy on Policy Setup sidebar actions can be mapped to respective policies
export const getActivePolicy = (state: Array<Object>): Object => {
  const policyInstanceNo = get(state, 'panelProps.policyInstanceNo')
  return policyInstanceNo
}

// get a policy in a given policy structure by its policy instance number
export const getPolicyByInstanceNo = (policyStructure, policyInstanceNo) =>
  policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)

// get all benficiaries for policies that arent in a policy.
export const getBeneficiariesOutsideOfPolicyInstance = (policyStructure, policyInstanceNo) =>
  policyStructure
    .filter(policy => policy.policyInstanceNo !== policyInstanceNo)
    .reduce((acc, curr) => {
      if (curr.beneficiaries && curr.beneficiaries.length) {
        return [...acc, ...curr.beneficiaries]
      }
      return []
    }, [])

// check if a given beneficiary is in a given list of form inputs data of beneficiares.
export const checkForBeneficiary = (beneficiaries, beneficiary) =>
  !!Object.keys(beneficiaries)
    .map(key => {
      // strip form usage fields before comparrison
      const {
        relationship,
        action,
        address,
        policyInstanceNo,
        type,
        beneficiaryIndex,
        middleName,
        ...rest
      } = beneficiaries[key].data
      return { ...rest }
    })
    .find(item => {
      // strip form usage fields before comparrison
      const { dateOfBirth, relationship, middleName, preferred, ...rest } = beneficiary
      // TODO: Remove this date formatting once someone unifies date formats.
      const b = {
        ...rest,
        dateOfBirth: moment(dateOfBirth).format('DD/MM/YYYY'),
      }
      return isEqual(b, item)
    })

// for adding and updating policy owners on Policy Setup. Roles can be LA, OWR or both
export const replacePolicyOwner = (policy: Object, newRelationship: Object): Array<Object> => {
  const { relationships } = policy
  // update existing Life Assured's (LA) role with Owner (OWR) identifier
  if (newRelationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)) {
    const updated = relationships.map(relationship => {
      const updatedRelationship = { ...relationship }
      updatedRelationship.role = [
        ...relationship.role.filter(role => role !== POLICY_RELATIONSHIPS_OWNER),
        POLICY_RELATIONSHIPS_OWNER,
      ]
      updatedRelationship.companies = [...updatedRelationship.companies]
      return updatedRelationship
    })
    return updated
  }

  // remove old OWR and return array with new OWR
  const updated = relationships
    .filter(relationship => relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED))
    .map(filtered => {
      const updatedRelationship = Object.assign({}, filtered)
      updatedRelationship.role = filtered.role.filter(role => role !== POLICY_RELATIONSHIPS_OWNER)
      return updatedRelationship
    })
  updated.push(newRelationship)
  return updated
}

export const getPolicyOwner = (policy: Object, role: string): ?Object =>
  policy.relationships.find(relationship => relationship.role.includes(role))

export const getPrimaryPolicyOwner = (policy: Object, role: string): ?Object =>
  policy.relationships.find(
    relationship =>
      relationship.role.includes(role) &&
      typeof relationship.isPrimary !== 'undefined' &&
      relationship.isPrimary
  )

export const getPolicyOwnerName = (policy: Object, role: string): ?string => {
  const { productId } = policy
  let owner = {}
  if (productId === POLICY_PRODUCT_CODE_SUPER) {
    const { superFundName } = policy
    if (superFundName === SUPER_OWNER_INTERNAL) {
      return SUPER_OWNER_INTERNAL_TRUSTEE_NAME
    }
    if (superFundName === SUPER_OWNER_IOOF) {
      return SUPER_OWNER_IOOF_TRUSTEE_NAME
    }
    owner = getPolicyOwner(policy, POLICY_RELATIONSHIPS_SMSF)
  } else {
    owner = getPrimaryPolicyOwner(policy, role) || getPolicyOwner(policy, role)
  }
  if (!isEmpty(owner)) {
    return get(owner, 'relatedParty.partyType') === POLICY_PARTY_TYPE_INDIVIDUAL
      ? `${owner.relatedParty.firstName} ${owner.relatedParty.lastName}`
      : get(owner, 'relatedParty.businessName')
  }
  return ''
}

export const getAllPolicyOwnerName = (policy: Object, role: string): Array<string> => {
  const { productId } = policy
  let owners = []
  if (productId === POLICY_PRODUCT_CODE_SUPER) {
    const { superFundName } = policy
    if (superFundName === SUPER_OWNER_INTERNAL) {
      owners = [getPolicyOwner(policy, POLICY_RELATIONSHIPS_LIFEASSURED)]
    } else {
      owners = [getPolicyOwner(policy, POLICY_RELATIONSHIPS_SMSF)]
    }
  } else {
    owners = policy.relationships.filter(
      relationship =>
        relationship.role.includes(role) &&
        !relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED)
    )
  }
  return owners.map(owner => {
    if (!isEmpty(owner)) {
      return get(owner, 'relatedParty.partyType') === POLICY_PARTY_TYPE_INDIVIDUAL
        ? `${owner.relatedParty.firstName} ${owner.relatedParty.lastName}`
        : get(owner, 'relatedParty.businessName')
    }
    return ''
  })
}

// to feed into policy setup cards, can be modified
// in the future to check for payer/date/beneficiaries
export const policyHasOwner = (
  policy: Object,
  role?: string = POLICY_RELATIONSHIPS_OWNER
): boolean => typeof getPolicyOwner(policy, role) !== 'undefined'

export const removeOptimiserReferenceInPolicyStructure = (
  cover: Object,
  policyInstanceNo: string,
  policyStructure: Array<Object>
): Array<Object> =>
  policyStructure.map(policy => {
    if (policy.policyInstanceNo.toString() === policyInstanceNo.toString()) {
      return {
        ...policy,
        covers: policy.covers.map(policyCover => {
          if (
            policyCover.type === cover.type &&
            policyCover.benefitInstanceNo.toString() === cover.benefitInstanceNo.toString()
          ) {
            delete policyCover.optimiserParentBenefitReference
            return policyCover
          }
          return policyCover
        }),
      }
    }
    return policy
  })

/**
 * Remove selected benefit and its childs
 * @param {array} policyStructure - list of policies in quote
 * @param {object} benefit - details of benefit to be deleted
 * @return { object } new policy structure after removing benefits
 */
export const removeBenefit = (policyStructure: Array<Object>, benefit: Object): Array<Object> => {
  const childBenefitsRemoved = []
  let optimiserCoverParentReference = null
  let newPolicyStructure = policyStructure.map(policy => {
    const updatedCovers = policy.covers.filter(cover => {
      // Removing the benefit selected by user
      if (
        cover.type === benefit.type &&
        String(cover.benefitInstanceNo) === String(benefit.benefitInstanceNo) &&
        policy.policyInstanceNo.toString() === benefit.policyInstanceNo.toString()
      ) {
        if (
          cover.parentBenefitReference &&
          cover.parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        ) {
          optimiserCoverParentReference = cover.parentBenefitReference
        }
        return false
      }
      const { parentBenefitReference } = cover
      // Removing the benefit if it is child of benefit selected
      if (
        isParentInstanceSame(cover, benefit, benefit.policyInstanceNo) &&
        parentBenefitReference.parentType === benefit.type
      ) {
        childBenefitsRemoved.push({
          type: cover.type,
          benefitInstanceNo: cover.benefitInstanceNo,
          policyInstanceNo: policy.policyInstanceNo,
        })
        return false
      }
      return true
    })
    return {
      ...policy,
      covers: updatedCovers,
    }
  })
  if (optimiserCoverParentReference) {
    // Remove optimiserParentBenefitReference for parent Cover
    newPolicyStructure = removeOptimiserReferenceInPolicyStructure(
      {
        type: optimiserCoverParentReference.parentType,
        benefitInstanceNo: optimiserCoverParentReference.parentBenefitInstanceNo,
      },
      optimiserCoverParentReference.parentPolicyReferenceNo,
      newPolicyStructure
    )
  }
  if (childBenefitsRemoved.length) {
    // Recursively calling removeBenefit on child benefits removed
    // so that their childs can be removed
    childBenefitsRemoved.forEach(childBenefit => {
      newPolicyStructure = removeBenefit(newPolicyStructure, childBenefit)
    })
  }
  newPolicyStructure &&
    newPolicyStructure.length &&
    newPolicyStructure.forEach(policy => {
      const policyGroupedByType = groupBy(policy.covers, 'type')
      Object.keys(policyGroupedByType).forEach(type => {
        policyGroupedByType[type].forEach((cover, index) => {
          // updating benefitInstanceNo in reference in linked benefits
          newPolicyStructure.forEach(newPolicy =>
            newPolicy.covers.forEach(policyCover => {
              if (
                policyCover.parentBenefitReference &&
                policyCover.parentBenefitReference.parentType === cover.type &&
                policyCover.parentBenefitReference.parentBenefitInstanceNo.toString() ===
                  cover.benefitInstanceNo.toString() &&
                policyCover.parentBenefitReference.parentPolicyReferenceNo.toString() ===
                  policy.policyInstanceNo.toString()
              ) {
                policyCover.parentBenefitReference.parentBenefitInstanceNo = (index + 1).toString()
              }
              if (
                policyCover.optimiserParentBenefitReference &&
                policyCover.optimiserParentBenefitReference.parentType === cover.type &&
                policyCover.optimiserParentBenefitReference.parentBenefitInstanceNo.toString() ===
                  cover.benefitInstanceNo.toString() &&
                policyCover.optimiserParentBenefitReference.parentPolicyReferenceNo.toString() ===
                  policy.policyInstanceNo.toString()
              ) {
                policyCover.optimiserParentBenefitReference.parentBenefitInstanceNo = (
                  index + 1
                ).toString()
              }
            })
          )

          cover.benefitInstanceNo = index + 1
        })
      })
    })
  return newPolicyStructure
}

/**
 * To update quote by deleting benefits for which no dependentOn benefits are present
 * @param {array} policyStructure - list of policies in quote
 * @param {object} productRulesData - product rules data
 * @return { object } new policy structure after deleting benefits
 */
export const updateQuoteBasedOnDependentOn = (
  policyStructure: Array<Object>,
  productRulesData: Object,
  shouldReturnDeletedCovers = false
): Array<Object> => {
  const deletedCovers = []
  let newPolicyStructure = policyStructure.map(policy => ({
    ...policy,
    covers: policy.covers.filter(cover => {
      const dependentOn = getDependentOn({
        benefitType: cover.type,
        productId: policy.productId,
        productRulesData,
      })
      if (dependentOn.length) {
        const deleteCover = !dependentOn.some(dependentOnBenefit =>
          policyStructure.some(policyBenefits =>
            policyBenefits.covers.some(
              policyCover =>
                policyCover.type === dependentOnBenefit.benefitCode &&
                policyBenefits.productId === dependentOnBenefit.productId
            )
          )
        )
        if (deleteCover) {
          deletedCovers.push({
            type: cover.type,
            benefitInstanceNo: cover.benefitInstanceNo,
            policyInstanceNo: cover.policyInstanceNo,
          })
        }
        return !deleteCover
      }
      return true
    }),
  }))
  if (shouldReturnDeletedCovers) {
    return deletedCovers
  }

  if (deletedCovers.length) {
    // Calling removeBenefit to remove child benefits of deleted benefits if any
    deletedCovers.forEach(benefit => {
      newPolicyStructure = removeBenefit(newPolicyStructure, benefit)
    })
    // recursively calling updateQuoteBasedOnDependentOn to delete those benefits
    // for which dependentOn benefits does not exists after deleting benefits above
    return updateQuoteBasedOnDependentOn(newPolicyStructure, productRulesData)
  }
  return newPolicyStructure
}

/**
 * To get unique new policy number. Used when adding new policy.
 * @param {array} policyStructure - List of policies inside a quote
 * @return {number} unique new policy number
 */
export const getUniquePolicyNo = (policyStructure: Array<Object>): number =>
  policyStructure.length ? [...policyStructure].reverse()[0].policyInstanceNo + 1 : 1

/**
 * Updates policyStructure by changing oldPolicyInstanceNo to newPolicyInstanceNo,
 * also in parentBenefitReference
 * @param {array} policyStructure - policy structure
 * @param {number} oldPolicyInstanceNo  - policyInstanceNo that needs to be changed
 * @param {number} newPolicyInstanceNo - new policyInstanceNo
 * @return {array} updated policyStructure
 */
export const updatePolicyInstanceNo = (
  policyStructure: Array<Object>,
  oldPolicyInstanceNo: number,
  newPolicyInstanceNo: number
): Array<Object> =>
  policyStructure.map(policy => {
    const updatedPolicy = { ...policy }

    // Updating the policyInstanceNo of the policy to new policyInstanceNo
    if (policy.policyInstanceNo === oldPolicyInstanceNo) {
      updatedPolicy.policyInstanceNo = newPolicyInstanceNo
      updatedPolicy.productName = policy.productName
        .split(' ')
        .slice(0, -1)
        .concat([`${newPolicyInstanceNo}`])
        .join(' ')
    }
    // Updating the new policyInstanceNo in benefit relationships
    updatedPolicy.covers = policy.covers.map(cover => {
      const updateCover = { ...cover }
      if (
        cover.parentBenefitReference &&
        String(cover.parentBenefitReference.parentPolicyReferenceNo) === String(oldPolicyInstanceNo)
      ) {
        updateCover.parentBenefitReference.parentPolicyReferenceNo = newPolicyInstanceNo.toString()
      }
      return updateCover
    })
    return updatedPolicy
  })

/**
 * To get payment mode
 * @param {object} createQuote - Quote entity
 * @return {object} Payment mode name
 */
export const getPaymentMode = ({ activeIndex, quotes }) => {
  const insideSuperPolicy = get(quotes, `[${activeIndex}].policyStructure`, []).filter(
    policy => policy.productId === POLICY_PRODUCT_CODE_SUPER
  )[0]

  if (insideSuperPolicy) {
    const { superFundName, fundPaymentMethod } = insideSuperPolicy
    return {
      superFundName,
      fundPaymentMethod,
    }
  }
  return {}
}

/**
 * To get policy structure modified according to save template
 * @param {object}
 * @return {object} policy object
 */
export const getTemplateSavePolicyStructure = policies => {
  const newPolicies = cloneDeep(policies)
  const updatedPolicies = newPolicies.reduce((policiesData, policy) => {
    const { covers } = policy
    const updatedCovers = covers.reduce((coversData, cover) => {
      const {
        benefitInstanceNo,
        name,
        type,
        parentBenefitReference,
        benefitLinks,
        features,
        premiumStyle,
        optimiserParentBenefitReference,
        tpdDefinition,
        campaignDetail,
      } = cover
      if (!(type === POLICY_CHILD_COVER_BENEFIT_CODE)) {
        coversData.push({
          benefitInstanceNo,
          name,
          type,
          parentBenefitReference,
          benefitLinks,
          features,
          premiumStyle,
          optimiserParentBenefitReference,
          ...(type === POLICY_BENEFIT_PTD
            ? {
                tpdDefinition,
              }
            : null),
          campaignDetail,
        })
      }
      return coversData
    }, [])
    policiesData.push({
      ...policy,
      covers: updatedCovers,
    })
    return policiesData
  }, [])
  return updatedPolicies
}
export const getIdentifier = relationship => {
  const identifiers = get(relationship, 'relatedParty.identifiers', [])
  return identifiers.find(indentifier => (indentifier.type === REF_NO ? indentifier : false))
}

export const getIdentifierOfBusiness = relationship => relationship.companies[0].abn

/**
 * rerturn templates array sorted on dateUpdated
 * @param {Array<Object>} data template data array
 */

export const destructAdvisorTemplatePolicyStructure = (data: Array<Object>): Array<Object> =>
  data
    .map(template => ({
      ...template,
      policyStructure: JSON.parse(template.policyStructure),
    }))
    .sort((a, b) => (a.dateUpdated > b.dateUpdated ? -1 : 1))

/**
 * return active quote object
 * @param {Object} state createQuote store
 */

export const getActiveQuote = state => {
  const quotes = get(state, 'quotes')
  const activeQuoteIndex = get(state, 'activeIndex')
  return quotes[activeQuoteIndex]
}

/**
 * return active quote object
 * @param {Object} state createQuote store
 */

export const getPolicyCommencementDate = policy => {
  const date = get(policy, 'policyCommencementDate', '')
  return date !== '' ? moment(date).format('D MMMM YYYY') : ' '
}

/**
 * return memberMandatories in createQuote store (not forms)
 * @param {Object} state
 */
export const getMemberMandatories = state => {
  const quote = getActiveQuote(state)
  return get(quote, 'memberMandatories')
}

/**
 * remove any unavailable policies after updating client mandatories
 * @param {Object} policy
 * @param {Array<Object>} availableBenefits
 */
export const getFilteredPolicyCovers = (policy, availableBenefits) =>
  policy.covers.filter(cover => getBenefit(availableBenefits, cover.type))

export const isOwnTPDDisabled = (
  type,
  occupationClassCode,
  allowedTPDOccupationTypeGroup,
  excludedTPDOccupationTypeCode,
  occupationCode
) => {
  if (!occupationClassCode) return false
  const indexOfTPD = occupationClassCode[0].benefit.indexOf(OCCUPATION_RATINGS_MAP[type])
  if (indexOfTPD !== -1) {
    const ratingGroupOfPTD = occupationClassCode[0].code[indexOfTPD]
    if (
      allowedTPDOccupationTypeGroup &&
      excludedTPDOccupationTypeCode &&
      (!allowedTPDOccupationTypeGroup.some(g => g.value === ratingGroupOfPTD) ||
        excludedTPDOccupationTypeCode.some(c => c.value === occupationCode))
    ) {
      return true
    }
  }
  return false
}

export const filterCoversForOptimiser = (
  covers: Array<Object>,
  memberMandatories: Object,
  masterData: Object
): Array<Object> =>
  covers.filter(cover => {
    const { type, optimiserParentBenefitReference } = cover
    if (type === POLICY_BENEFIT_PTD && optimiserParentBenefitReference) {
      const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode } = masterData
      const { occupationClassCode, occupationCode } = memberMandatories
      const isNotOwnOccupation = isOwnTPDDisabled(
        type,
        occupationClassCode,
        allowedTPDOccupationTypeGroup,
        excludedTPDOccupationTypeCode,
        occupationCode
      )
      if (isNotOwnOccupation) {
        return false
      }
    }
    return true
  })

export const filterPolicyStructure = (
  policyStructure: Object,
  memberMandatories: Object,
  productRules: Object,
  updateLifeInsured: string = '',
  masterData: Object
) => {
  const filteredPolicyStructure = policyStructure.map(policy => {
    const productData = getProduct(productRules, policy.productId)
    const { benefits } = filterProductBenefitsByClientMandatories(
      productData,
      memberMandatories,
      updateLifeInsured,
      {}
    )
    const updatedList = filterBenefitsByDependentAndCICount(benefits, policy)
    let updatedCovers = getFilteredPolicyCovers(policy, updatedList)
    updatedCovers = filterCoversForOptimiser(updatedCovers, memberMandatories, masterData)
    return {
      ...policy,
      covers: updatedCovers,
    }
  })

  return filteredPolicyStructure
}

/**
 *
 * @param {Object} state createQuote store
 * @param {Array<Object>} productRules product rules from store
 */
export const updateFilteredBenefitsData = (
  state,
  productRules,
  updateLifeInsured: string = '',
  manualMemberMandatories: string = '',
  masterData: Object
) => {
  const quote = getActiveQuote(state)
  const { policyStructure, memberMandatories } = quote
  return filterPolicyStructure(
    policyStructure,
    manualMemberMandatories || memberMandatories,
    productRules,
    updateLifeInsured,
    masterData
  )
}

export const removeAllOptimizedCovers = policyStructure =>
  policyStructure.map(policy => ({
    ...policy,
    covers: policy.covers.filter(
      cover =>
        !cover.parentBenefitReference ||
        !(
          cover.parentBenefitReference &&
          cover.parentBenefitReference.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER
        )
    ),
  }))

/**
 * return true if the list of policies changes after updating client mandatories
 * @param {Object} state createQuote store
 * @param {Array<Object>} productRules product rules from store
 */
export const policiesWillUpdate = (state, productRules, masterData) => {
  // make use of updateFilteredBenefitsData and compare it with the current state's policyStructure.
  // do a comparison and if it's different, return true
  const quote = getActiveQuote(state)
  const {
    policyStructure,
    memberMandatories: { occupationClassCode, occupationCode },
  } = quote
  const { allowedTPDOccupationTypeGroup, excludedTPDOccupationTypeCode } = masterData

  const updateList = updateFilteredBenefitsData(
    state,
    productRules,
    UPDATE_FROM_LIFE_INSURED,
    '',
    masterData
  )
  const isTPDDisabled = isOwnTPDDisabled(
    POLICY_BENEFIT_PTD,
    occupationClassCode,
    allowedTPDOccupationTypeGroup,
    excludedTPDOccupationTypeCode,
    occupationCode
  )
  const tpdFilteredList = isTPDDisabled ? removeAllOptimizedCovers(updateList) : updateList
  const willPolicyUpdate = !deepEqual(policyStructure, tpdFilteredList)
  return willPolicyUpdate
}

const addCoversToManualQuote = policy => {
  const covers = []
  if (policy.covers !== undefined) {
    policy.covers.map(cover => {
      covers.unshift({
        type: get(cover, 'type'),
        name: get(cover, 'name'),
        benefitInstanceNo: get(cover, 'benefitInstanceNo'),
        coverAmount: get(cover, 'coverAmount'),
        premiumAmount: get(cover, 'premiumAmount'),
        stampDuty: get(cover, 'stampDuty', ''),
        premiumStyle: get(cover, 'premiumStyle'),
        occupationalRating: get(cover, 'occupationalRating', ''),
        commissionDetails:
          cover.commissionDetails && cover.commissionDetails !== undefined
            ? {
                commissionProfile: get(cover, 'commissionDetails.commissionProfile'),
                initialRemunerationPercentage: get(
                  cover,
                  'commissionDetails.initialRemunerationPercentage'
                ),
                renewalRemunerationPercentage: get(
                  cover,
                  'commissionDetails.renewalRemunerationPercentage'
                ),
              }
            : {},
        features: get(cover, 'features'),
        superFundName: get(cover, 'superFundName', ''),
      })
      return 0
    })
  }
  return covers
}

export const getBancsPartyNoByMemberRole = (relations, memberRole) => {
  if (relations) {
    let item = relations.filter(
      ({ roles, isPrimaryPolicyOwner = 'N' }) =>
        roles.includes(memberRole) && isPrimaryPolicyOwner === PREFERRED_YES
    )
    if (item && item.length) return item[0].bancsPartyNo
    item = relations.filter(({ roles }) => roles.includes(memberRole))
    return item && item.length ? item[0].bancsPartyNo : ''
  }
  return false
}

export const isOwnerSuperFund = relations => {
  if (relations) {
    return !!relations.some(
      ({ bancsPartyNo, roles }) =>
        roles.includes('OWR') &&
        [BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(bancsPartyNo)
    )
  }
  return false
}

export const formatManualQuote = quote => {
  let manualQuote = {}
  if (quote) {
    manualQuote = {
      memberMandatories: quote.memberMandatories
        ? {
            isSmoker: get(quote, 'memberMandatories.isSmoker'),
            dateOfBirth: get(quote, 'memberMandatories.dateOfBirth'),
            gender: get(quote, 'memberMandatories.gender'),
            stateOfResidence: get(quote, 'memberMandatories.stateOfResidence'),
            occupation: get(quote, 'memberMandatories.occupation'),
            occupationalRating: get(quote, 'memberMandatories.occupationalRating', ''),
            occupationClassCode: get(quote, 'memberMandatories.occupationClassCode', []),
            earning: get(quote, 'memberMandatories.earning'),
            employmentStatus: get(quote, 'memberMandatories.employmentStatus'),
          }
        : {},
      alternateEmailDetails:
        quote.alternateEmailDetails && quote.alternateEmailDetails !== undefined
          ? {
              firstName: get(quote, 'alternateEmailDetails.firstName'),
              email: get(quote, 'alternateEmailDetails.email'),
            }
          : {},
      policyStructure: [],
      myLinkDetails:
        quote.myLinkDetails && quote.myLinkDetails !== undefined
          ? {
              email: get(quote, 'myLinkDetails.email'),
              phone: get(quote, 'myLinkDetails.phone'),
            }
          : {},
      quoteName: get(quote, 'quoteName'),
      quoteId: get(quote, 'quoteId'),
      consents: get(quote, 'consents', []),
      type: get(quote, 'type'),
      printableAdviserDetails: get(quote, 'printableAdviserDetails'),
    }

    if (quote.policyStructure && quote.policyStructure !== undefined) {
      quote.policyStructure.map(policy => {
        manualQuote.policyStructure.unshift({
          productId: get(policy, 'productId'),
          productName: get(policy, 'productName'),
          policyInstanceNo: get(policy, 'policyInstanceNo'),
          agencyDetails: get(policy, 'agencyDetails'),
          mlcOnTrack: get(policy, 'mlcOnTrack'),
          paymentFrequency: get(policy, 'paymentFrequency'),
          policyFee: get(policy, 'policyFee', ''),
          policyFeeValue: get(policy, 'policyFeeValue', ''),
          legacySystemProductCode: get(policy, 'legacySystemProductCode'),
          currency: get(policy, 'currency', ''),
          totalPremiumAmount: get(policy, 'totalPremiumAmount'),
          covers:
            policy.covers && policy.covers !== undefined ? addCoversToManualQuote(policy) : [],
          relationships: get(policy, 'relationships', []),
          beneficiaries: get(policy, 'beneficiaries', []),
          productClass: get(policy, 'productClass'),
          premiumPaymentMethod: get(policy, 'premiumPaymentMethod', ''),
          superFundName: get(policy, 'superFundName', ''),
          fundPaymentMethod: get(policy, 'fundPaymentMethod', ''),
        })
        return 0
      })
    }
  }
  return manualQuote
}

/**
 * Updates policyStructure by changing cover occupationalRating,
 * @param {array} policyStructure - policy structure
 * @param {object}  occupationalRating - policyInstanceNo that needs to be changed
 * @return {array} updated policyStructure
 */
export const updateOccupationalRatingInCovers = (
  policyStructure: Array<Object>,
  occupationalRating: object
): Array<Object> =>
  policyStructure.map(policy => {
    const updatedPolicy = { ...policy }
    const { OCCP_CODE } = occupationalRating
    // Updating the new policyInstanceNo in benefit relationships
    updatedPolicy.covers = policy.covers.map(cover => {
      const updateCover = { ...cover }
      const updatedOccupationalRating = OCCUPATION_RATINGS_MAP[cover.type]
        ? occupationalRating[OCCUPATION_RATINGS_MAP[cover.type]] || ''
        : ''
      updateCover.occupationalRating = updatedOccupationalRating
      if (updateCover && updateCover.occupation) {
        updateCover.occupation.occupationalRating = updatedOccupationalRating
        updateCover.occupation.occupationCode = OCCP_CODE
      }
      return updateCover
    })
    return updatedPolicy
  })

// checks whether LC, LC_PLUS, LC_STD or ADB benefits are added
// FIXME: Check if non MLC related life benefits should impact PolicySetup feature
export const checkCoversBenefits = covers => {
  let benefitsAdded = false
  if (covers && covers.length) {
    covers.forEach(benefit => {
      if (LIFE_RELATED_POLICY_BENEFITS.includes(benefit.type)) {
        benefitsAdded = true
      }
    })
  }
  return benefitsAdded
}

export const getUWMethodFromQuote = collection => {
  const { activeIndex, quotes } = collection
  const method = get(quotes, `${activeIndex}].underwritingDetails.underwritingMethod`, '')
  return method
}

export const isTeleUnderwriter = uwMethod => {
  const isValidTeleUser = getScope() === SCOPE_TYPE_TELE && uwMethod === TELE
  return isValidTeleUser
}

export const getUREAssignedParty = (collection: Object) => {
  const { quoteIDToAction, quotes, action } = collection
  const index = quotes.findIndex(quote => quote.quoteId === quoteIDToAction)
  const method = get(quotes, `${index}].underwritingDetails.underwritingMethod`, '')
  const type = get(quotes, `${index}].type`, '')

  if (
    method === ADV_F2F ||
    (action === SAVE_DRAFT_APPLICATION && type === QUOTE_STATUS_APPLICATION_STAGE)
  ) {
    return URE_BY_ADVISER
  }
  if (method === MYLINK || (action === WIP_MYLINK && type === QUOTE_STATUS_APPLICATION_STAGE)) {
    return URE_BY_CUSTOMER
  }
  if (method === TELE || (action === TELE && type === QUOTE_STATUS_APPLICATION_STAGE)) {
    return URE_BY_TELE
  }
  return null
}

export const hasAppSubmited = collection => {
  const { action } = collection
  return action === COMPLETE_MYLINK || action === COMPLETE_TELE || action === COMPLETE_APPLICATION
}

export const handleUREByOtherParty = (createQuote: Object) => {
  const ureParty = getUREAssignedParty(createQuote)
  if (getScope() === SCOPE_TYPE_TELE) {
    if (ureParty === URE_BY_ADVISER || ureParty === URE_BY_CUSTOMER) {
      history.push(CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE)
    }
  } else if (ureParty === URE_BY_ADVISER || ureParty === URE_BY_TELE) {
    history.push(CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE)
  }
}

// Removes feature that are only required for UI and not needed as features by bancs
export const filterFeaturesForAPI = (cover: Object) => {
  if (!cover.features) return []

  if (cover.type === POLICY_BENEFIT_LC) {
    return cover.features.filter(feature => feature.featureName !== POLICY_BENEFIT_TISO)
  }
  if (cover.type === POLICY_BENEFIT_CI_PLUS) {
    return cover.features.filter(feature => feature.featureName !== POLICY_FEATURE_EBO)
  }
  if (isIPCover(cover)) {
    return cover.features.filter(
      feature =>
        feature.featureName !== POLICY_FEATURE_LSBO &&
        feature.featureName !== POLICY_FEATURE_BO &&
        feature.featureName !== POLICY_FEATURE_SGBO
    )
  }
  return cover.features
}

export const filterQuoteEntityToSave = (createQuote, sidebar) => {
  const { quoteIDToDelete = [], workTypeHistory = [], quotes } = createQuote

  return {
    quoteCollectionId: createQuote.quoteCollectionId,
    adviserDetails: createQuote.adviserDetails,
    quotes: quotes.map(quote => {
      const { underwritingDetails } = quote
      const policyStructure = quote.policyStructure.map(policy => {
        const { fastTrackingFollowupInfo, covers, relationships, ...rest } = policy
        return {
          ...rest,
          covers: covers.map(cover => {
            if (cover.type && cover.type !== POLICY_BENEFIT_PTD) {
              delete cover.tpdDefinition
            }
            return {
              ...cover,
              features: filterFeaturesForAPI(cover),
              ...(cover.parentBenefitReference
                ? {
                    parentBenefitReference: {
                      ...cover.parentBenefitReference,
                      ...(cover.type === POLICY_BENEFIT_TISO && cover.parentBenefitReference
                        ? {
                            parentQuotePolicyNo:
                              cover.parentBenefitReference.parentPolicyReferenceNo,
                          }
                        : {}),
                    },
                  }
                : {}),
            }
          }),
          ...(relationships &&
            (underwritingDetails &&
            underwritingDetails.isSatisfied &&
            (underwritingDetails.calculatedUREStatus === ACCEPTED ||
              underwritingDetails.calculatedUREStatus === REFERRED) &&
            ((createQuote.action === COMPLETE_APPLICATION &&
              (underwritingDetails.underwritingMethod === ADV_PHN ||
                underwritingDetails.underwritingMethod === ADV_F2F)) ||
              ((createQuote.action === COMPLETE_MYLINK ||
                createQuote.action === COMPLETE_APPLICATION ||
                createQuote.action === COMPLETE_TELE) &&
                (underwritingDetails.underwritingMethod === MYLINK ||
                  underwritingDetails.underwritingMethod === TELE)))
              ? {
                  relationships: relationships
                    .filter(
                      rel =>
                        !(rel.role.length === 1 && rel.role.includes(POLICY_RELATIONSHIPS_MEMBER))
                    )
                    .map(rel => {
                      const indexOfMbr = rel.role.indexOf(POLICY_RELATIONSHIPS_MEMBER)
                      if (indexOfMbr !== -1) {
                        rel.role.splice(indexOfMbr, 1)
                      }
                      return rel
                    }),
                }
              : { relationships })),
          ...(isEmpty(fastTrackingFollowupInfo) ? {} : { fastTrackingFollowupInfo }),
        }
      })
      return {
        ...quote,
        policyStructure,
        ...(sidebar?.source && { source: sidebar?.source }),
      }
    }),
    action: createQuote.action,
    adviserNumber: createQuote.adviserNumber,
    applicationType: createQuote.applicationType,
    bancsAdviserCustomerNo: createQuote.bancsAdviserCustomerNo,
    discountValue: createQuote.discountValue,
    ...(workTypeHistory.length ? { workTypeHistory } : {}),
    ...(quoteIDToDelete.length ? { quoteIDToDelete } : {}),
    quoteIDToAction: createQuote.quoteIDToAction,
    isUpdated: createQuote.isUpdated,
    internalQuoteCollectionId: createQuote.internalQuoteCollectionId,
    isTeleFromAdviser: createQuote.isTeleFromAdviser,
    ...(createQuote.isNonStandard !== undefined && { isNonStandard: createQuote.isNonStandard }),
    requestedBy: createQuote.requestedBy,
    alterationType: createQuote.alterationType,
    ...(createQuote.quoteCustomerNo && { quoteCustomerNo: createQuote.quoteCustomerNo }),
    ...(createQuote.preferredContactNumber && {
      preferredContactNumber: createQuote.preferredContactNumber,
      preferredContactTime: createQuote.preferredContactTime,
    }),
  }
}

export const isRiskedOccupation = (occCode, occupationList) => occupationList.includes(occCode)

export const checkCreateQuoteWithValidPolicies = quote => {
  const { policyStructure } = quote
  if (policyStructure && policyStructure.length > 0) {
    return policyStructure.some(policy => policy.covers && policy.covers.length > 0)
  }
  return false
}

export const shouldShowLifeInsuredDetails = quoteCollectionName =>
  !quoteCollectionName.includes(',')

const isObjectEmpty = obj => !Object.keys(obj || {}).length

const getNewEscalation = (escalationResponse, changeType) =>
  get(escalationResponse, 'policyChangeDetails', []).find(item => item.changeType === changeType) ||
  {}

// TODO: Update modifiedOption type with actual values in Decrease Risk story
/**
 * Alteration Benefit Object definition
 * @reference https://confluence.mlctech.io/display/DIG/Digi+Alts%3A+Quote+Entity
 */
type AlteredBenefit = {
  benefitCode: String,
  benefitInstanceNo: Number,
  benefitName: String,
  alterationStatus: String,
  newSumInsured: Number,
  newLoadingAmount?: Number,
  newGrossBenefitPremium: Number,
  currency?: String,
  modifiedOption?: Array<Object>,
}

const getBenefitKey = (name, benefitInstanceNo) => `${name}${benefitInstanceNo}`.toUpperCase()

/**
 * Helper to return a Alteration Status for Alteration type
 */
// TODO: Add mapping to return correct alterationStatus for each alterationType
// This is unknown today for other alterationTypes
export const getAlterationStatus = alterationType => {
  switch (alterationType) {
    case ALTERATION_TYPES.DECREASE_RISK:
      return ALTERATION_STATUS.DECREASED
    default:
      return ALTERATION_STATUS.REJECT_CPI
  }
}

/**
 * Return AlteredBenefit object by looking for a matching benefit from policy in
 * Escalation API response from Bancs.
 * Use name-benefitInstanceNo to match an existing record.
 * Create AlteredBenefit from revisedBenefitDetails object.
 * Create benefitName by looking for matching name in Policy using type as key.
 * @return Array of altered benefits
 */
const getAlteredBenefits: Array<AlteredBenefit> = (
  benefits: Array<Object>,
  revisedBenefitDetails: Array<Object>,
  alterationType: String
) => {
  if (isObjectEmpty(benefits)) return []

  return revisedBenefitDetails
    .filter(
      ({ benefitCode, benefitInstance }) =>
        !!benefits.find(
          ({ type, benefitInstanceNo }) =>
            getBenefitKey(benefitCode, benefitInstance) === getBenefitKey(type, benefitInstanceNo)
        )
    )
    .map(
      ({
        benefitCode,
        benefitInstance: benefitInstanceNo,
        newSumassured: newSumInsured,
        newLoadingAmount,
        currency,
      }) => ({
        benefitCode,
        benefitInstanceNo,
        benefitName: get(
          benefits.find(({ type }) => type === benefitCode),
          'name',
          ''
        ),
        alterationStatus: getAlterationStatus(alterationType),
        newSumInsured,
        newLoadingAmount,
        currency,
      })
    )
}

/**
 * Alteration Object
 * Reference: https://confluence.mlctech.io/display/DIG/Digi+Alts%3A+Quote+Entity
 */
type Alteration = {
  isPolicyAltered: Boolean,
  newPolicyFee?: String,
  newGrossPremium?: Number,
  newNetPremium?: Number,
  newStampDuty?: Number,
  taxRebate?: Number,
  saving?: Number,
  smokerStatus?: String,
  alteredBenefits: Array<AlteredBenefit>,
}

/**
 * Create alteration object
 */
const getAlteration: Alteration = (
  policy,
  alterationType,
  apiResponse,
  policyAssessment,
  site,
  benefitStatus
) => {
  if (alterationType === ALTERATION_TYPES.REJECT_CPI) {
    if (!apiResponse) return {}

    const reRate = getNewEscalation(apiResponse, 'Re-Rate')
    const { newPolicyFee, newGrossPremium, newNetPremium } = reRate
    const {
      rejectCPI_PolicyEligibleForAdviser: policyEligibleForAdviser,
      rejectCPI_PolicyEligibleForCustomer: policyEligibleForCustomer,
    } = policyAssessment || {}
    // isPolicyAltered will be marked true only below condition, false otherwise
    const isPolicyAltered =
      site === ADVISOR_PORTAL ? policyEligibleForAdviser === 'Y' : policyEligibleForCustomer === 'Y'

    const benefits = get(policy, 'benefits', [])
    // Filter benefits based on the inforced status
    const inforcedBenefits = benefits.filter(benefit =>
      checkIfValidBenefitStatusCode(benefit.benefitStatusCode, benefitStatus)
    )
    const revisedBenefitDetails = get(reRate, 'revisedBenefitDetails', [])
    const alteredBenefits = getAlteredBenefits(
      inforcedBenefits,
      revisedBenefitDetails,
      alterationType
    )

    return {
      isPolicyAltered,
      newPolicyFee,
      newGrossPremium,
      newNetPremium,
      alteredBenefits,
    }
  }
  // Return {} for all other types of Alterations.
  // TODO: To change the below when ne alterations are added
  return {}
}

const getLifeAssured = (relationships, bancsCustomerNo) =>
  relationships.find(
    ({ bancsCustomerNo: partyBancsCustNo }) => partyBancsCustNo === bancsCustomerNo
  ) || {}

const setValue = (key, roleCode) =>
  ['LA', 'OWR', 'SA', 'PYR'].includes(roleCode) && !key && key === 'N' ? 'Y' : 'N'

/**
 * Helper function to construct the relationships entity
 * from policy entity to quote entity
 * If relationship entity is from a Quote, mark isQuoteEntity as true
 */
export const constructRelationshipsFromPolicy = (relationships, isQuoteEntity = false) =>
  relationships.map(eachRelation => {
    const transformedRelationship = {
      ...eachRelation,
      ...(!isQuoteEntity && { role: [eachRelation.roleCode] }),
      relatedParty: {
        ...eachRelation.relatedParty,
        ...(eachRelation.relatedParty &&
          eachRelation.relatedParty.fatcacrsentity && {
            fatcacrsentity: {
              ...eachRelation.relatedParty.fatcacrsentity,
              fatcaCheck: setValue(
                eachRelation.relatedParty.fatcacrsentity.fatcaCheck,
                eachRelation.roleCode
              ),
              crsCheck: setValue(
                eachRelation.relatedParty.fatcacrsentity.crsCheck,
                eachRelation.roleCode
              ),
            },
          }),
      },
    }

    const valueToCompare = isQuoteEntity ? eachRelation.role[0] : eachRelation.roleCode
    if (
      [BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF].includes(
        get(eachRelation, 'bancsCustomerNo')
      ) &&
      valueToCompare === POLICY_RELATIONSHIPS_OWNER
    ) {
      // Store only the preffered contact methods for organisation
      const filteredContactMethods = {}
      // @FIXME: Need to refactor to remove 'of' syntax
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [contactMethod, value] of Object.entries(
        get(transformedRelationship, 'relatedParty.contactMethods', {})
      )) {
        const preferredValue = getPreferredItemBreakdown(value)
        if (preferredValue && !!Object.entries(preferredValue).length) {
          filteredContactMethods[contactMethod] = [preferredValue]
        }
      }
      transformedRelationship.relatedParty.contactMethods = filteredContactMethods
    } else if (
      ![POLICY_RELATIONSHIPS_OWNER, POLICY_RELATIONSHIPS_LIFEASSURED].includes(valueToCompare)
    ) {
      // Delete the contactMethods under relatedParty
      // when the party is not a Owner or Life Assured
      // Logic to prevent Quote submission failure in Mule due to bad state data of certain parties.
      delete transformedRelationship.relatedParty.contactMethods
    } else {
      // For Owner (not an organisation) or a Life Assured,
      // store only preferered phone, email and current address (RET-14380)
      const filteredContactMethods = {}
      const contactMethods = get(transformedRelationship, 'relatedParty.contactMethods', {})
      const preferredPhone = getPreferredItemBreakdown(contactMethods.phones)
      if (preferredPhone && !!Object.entries(preferredPhone).length) {
        filteredContactMethods.phones = [getPreferredItemBreakdown(contactMethods.phones)]
      }
      const preferredEmail = getPreferredItemBreakdown(contactMethods.emails)
      if (preferredEmail && !!Object.entries(preferredEmail).length) {
        filteredContactMethods.emails = [getPreferredItemBreakdown(contactMethods.emails)]
      }
      // Store specific addresses based on partyType: PER/ORG and roleCode being LA/OWR
      const partyType = get(transformedRelationship, 'relatedParty.partyType', '')
      const filteredAddresses = []
      const statementAddress = getLatestAddressBasedOnType(
        contactMethods.addresses,
        ADDRESS_TYPE_STATEMENT
      )
      const homeAddress = getLatestAddressBasedOnType(contactMethods.addresses, ADDRESS_TYPE_HOME)
      const preferredAddress = getPreferredAddress(contactMethods.addresses)
      if (partyType === PARTY_TYPE_PERSON) {
        // For Individual, amongst the addresses, the assumption is that one of them is preferred
        // If latest statement address is there, add this address
        !!Object.entries(statementAddress).length && filteredAddresses.push(statementAddress)
        // If latest home address is there, add this address
        !!Object.entries(homeAddress).length && filteredAddresses.push(homeAddress)
      }
      // For Organisation, remove all addresses not matching rule below
      if (
        partyType === POLICY_PARTY_TYPE_BUSINESS &&
        valueToCompare === POLICY_RELATIONSHIPS_OWNER
      ) {
        // Add the Preferred address. Assuming this is the Statement address.
        !!Object.entries(preferredAddress).length && filteredAddresses.push(preferredAddress)
      }
      filteredContactMethods.addresses = filteredAddresses
      transformedRelationship.relatedParty.contactMethods = filteredContactMethods
    }

    return transformedRelationship
  })

type AlterationOptions = {
  alterationType: String,
  apiResponse: Object,
  policyAssessment: Object,
  site: String,
}

// TODO:: Complete list of objects which are optional need to be added
// Reference: https://confluence.mlctech.io/display/DIG/Digi+Alts%3A+Quote+Entity
type PolicyStructure = {
  productName: String,
  productId: String,
  fitnessTracker?: String,
  policyInstanceNo: Number,
  productClass: String,
  mlcOnTrack: Boolean,
  policyFee?: String,
  policyFeeValue?: Number,
  premiumPaymentMethod: String,
  currency: String,
  paymentFrequency: String,
  superFundName?: String,
  fundPaymentMethod?: String,
  quoteEffectiveDate?: Date,
  totalPremiumAmount: Number,
  grossPremiumAmount: Number,
  rebateAmount?: Number,
  taxCreditAmount?: Number,
  legacySystemProductCode: String,
  agencyDetails?: Array<Object>,
  covers: Array,
  consents?: Array<Object>,
  stampDuty: String,
  alteration?: Object<Alteration>,
}

/* eslint-disable max-len */
// subenefit for decrease cover
export const getSubBenefitsForDecreaseCover = subBenefits =>
  Array.isArray(subBenefits) &&
  subBenefits.map(subBenefit => ({
    subBenefitCode: subBenefit.subBenefitCode,
    subBenefitName: subBenefit.subBenefitName,
    // Field is only applicable for quote collection, therefore applying valid value for save
    applicable: 'Y',
    // mandatoryIndicator is only applicable for quote collection,therefore applying valid value for save
    mandatoryIndicator: 'M',
    sumAssured: subBenefit.sumAssured,
    premium: subBenefit.premium,
  }))

// Loadings for Decrease Cover
export const getLoadingForDecreaseCover = loadings =>
  loadings.map(loading => {
    const { reason, loadingTypeCode, loadingValue, expiryDate, startDate } = loading
    return {
      ...(loadingTypeCode && {
        loadingTypeCode,
        loadingType: LOADING_TYPE_MAPPING[loadingTypeCode].type,
        loadingValueType: LOADING_TYPE_MAPPING[loadingTypeCode].value,
      }),
      ...(expiryDate && { endDate: expiryDate }),
      ...(reason && { reason }),
      ...(loadingValue && { loadingValue }),
      ...(startDate && { startDate }),
    }
  })

// Exclusions for Decrease Cover
export const getExclusionForDecreaseCover = exclusions =>
  exclusions.map(exclusion => {
    const { exclusionPara, expiryDate, referenceNo, exclusionCode, startDate } = exclusion
    return {
      ...(exclusionPara && { description: exclusionPara }),
      ...(expiryDate && { endDate: expiryDate }),
      ...(referenceNo && { referenceNo }),
      ...(exclusionCode && { exclusionCode }),
      ...(startDate && { startDate }),
    }
  })

// features for decrease cover
export const getFeaturesForDecreaseCover = features => {
  const modifiedFeatures =
    Array.isArray(features) &&
    features.map(feature => {
      const featureMandatories = {
        featureName: feature.featureName,
        featureApplicable: feature.featureApplicable,
        // Field is only applicable for quote collection, therefore applying valid value for save
        premium: 0,
      }
      const durationAttributes = feature.duration
        ? {
            duration: feature.duration,
            durationUnit:
              DURATION_MAPPING[feature.durationUnit.toUpperCase()] || feature.durationUnit,
          }
        : {}

      return {
        ...featureMandatories,
        ...durationAttributes,
      }
    })

  return modifiedFeatures
}

export const getPolicyStructure: PolicyStructure = (
  policy: Object,
  alterationOptions?: AlterationOptions,
  benefitStatus: Array<Object> = []
) => {
  const {
    policyName: productName,
    productId,
    productClass,
    legacySystemProductCode,
    premiumPaymentMethod,
    paymentDetails: { collectionFrequency: paymentFrequency },
    policyPremium: totalPremiumAmount,
    policyPremium: grossPremiumAmount,
    benefits,
    stampDuty,
    relationships,
    identifiers,
    policyFee,
    taxRebate,
    startDate,
  } = policy
  const mlcOnTrack = false
  const currency = 'AUD'

  // policyInstanceNo is hardcoded as 1 as this is a mandatory param.
  // Needs to be overidden when list of polcies are sent by caller

  const policyNo = get(
    identifiers.find(item => item.type === 'POLICY_ID'),
    'value',
    ''
  )
  const bancsPolicyNo = get(
    identifiers.find(item => item.type === 'BANCS_POLICY_NO'),
    'value',
    ''
  )

  const policyStructure = {
    productName,
    productId,
    policyInstanceNo: 1,
    productClass,
    mlcOnTrack,
    premiumPaymentMethod: premiumPaymentMethod || BPAY, // Default premiumPaymentMethod as BP is added to ensure it is passed successfully
    currency,
    policyFee: policyFee > 0 ? 'Y' : 'N',
    policyFeeValue: policyFee,
    paymentFrequency,
    totalPremiumAmount,
    grossPremiumAmount,
    legacySystemProductCode,
    stampDuty,
    relationships,
    policyNo,
    bancsPolicyNo,
    policyCommencementDate: startDate,
    rebateAmount: taxRebate,
  }

  const { alterationType, apiResponse, policyAssessment, site } = alterationOptions

  // Transform benefits into covers
  policyStructure.covers = benefits
    .filter(benefit => checkIfValidBenefitStatusCode(benefit.benefitStatusCode, benefitStatus))
    .map(eachPolicyBenefit => {
      const clonedBenefit = { ...eachPolicyBenefit }
      // TODO: Should this go to bancs or not based on sumAssured?
      clonedBenefit.sumAssuredApplicable = 'ON'

      const benefitAssured = get(clonedBenefit, 'benefitAssured[0]', {})
      const { bancsCustomerNo, stampDutyState, occupation } = benefitAssured
      const { type } = clonedBenefit
      // to cater for Child_CI benefit codes as Child cannot have occupation - RET-16621
      const { occupationRating, occupationDescription } =
        type !== POLICY_CHILD_COVER_BENEFIT_CODE ? occupation : { occupationRating: 'NA' }
      // Get the LifeInsured details from relationship
      const lifeAssured = getLifeAssured(relationships, bancsCustomerNo)
      const employmentStatus = get(
        lifeAssured,
        'relatedParty.employmentStatus',
        'UNK'
      ).toUpperCase()

      clonedBenefit.benefitAssured = [
        {
          memberRefNo: bancsCustomerNo,
          bancsCustomerNo,
          firstName: get(lifeAssured, 'relatedParty.firstName', 'NA'),
          lastName: get(lifeAssured, 'relatedParty.lastName', 'NA'),
          status: get(lifeAssured, 'status', 'NA'),
          dateOfBirth: get(lifeAssured, 'relatedParty.dateOfBirth', 'NA'),
          gender: genderTransform[get(lifeAssured, 'relatedParty.gender', 'U').toUpperCase()],
          smokerStatus:
            smokerStatusTransform[get(lifeAssured, 'relatedParty.isSmoker', 'UNK').toUpperCase()],
          employmentStatus: empStatusTransform[employmentStatus] || employmentStatus,
          stateOfResidence: stampDutyState,
          occupation: occupationDescription || 'ACCOUNTANT',
          occupationalRating:
            occupationRatingTransform[occupationRating.toUpperCase()] || occupationRating,
          incapacityDefinition: 'Own',
        },
      ]

      const CI_MAP = {
        M: 'Male',
        F: 'Female',
        U: 'Unknown',
        Y: 'Yes',
        N: 'No',
      }

      // If the benefit is a Child CI benefit, we need to map the benefitChildCI entity
      if (clonedBenefit.type === POLICY_CHILD_COVER_BENEFIT_CODE) {
        clonedBenefit.benefitChildCI = {
          firstName: clonedBenefit.benefitAssured[0].firstName,
          lastName: clonedBenefit.benefitAssured[0].lastName,
          gender: CI_MAP[clonedBenefit.benefitAssured[0].gender] || CI_MAP.U,
          isSmoker: CI_MAP[clonedBenefit.benefitAssured[0].smokerStatus] || CI_MAP.U,
          dateOfBirth: clonedBenefit.benefitAssured[0].dateOfBirth,
        }
      }

      // Transform key parentBenefitCode to parentType in connected benefits
      const { parentBenefitReference } = clonedBenefit
      if (parentBenefitReference) {
        const { parentBenefitCode } = parentBenefitReference
        if (parentBenefitCode) {
          parentBenefitReference.parentType = parentBenefitCode
          delete parentBenefitReference.parentBenefitCode
        }
      }

      // Transform key parentBenefitCode to parentType in connected optimizer benefits
      const { optimiserParentBenefitReference } = clonedBenefit
      if (optimiserParentBenefitReference) {
        const { parentBenefitCode, parentExternalPolicyNo } = optimiserParentBenefitReference
        if (parentBenefitCode) {
          optimiserParentBenefitReference.parentType = parentBenefitCode
          delete optimiserParentBenefitReference.parentBenefitCode
        }
        if (parentExternalPolicyNo) {
          optimiserParentBenefitReference.parentPolicyReferenceNo = parentExternalPolicyNo
          delete optimiserParentBenefitReference.parentExternalPolicyNo
        }
      }

      // Transform from array to string for tpdDefinition in connected benefits
      const { tpdDefinition: tpdDefinitionArray } = clonedBenefit
      if (tpdDefinitionArray && tpdDefinitionArray.length) {
        clonedBenefit.tpdDefinition = tpdDefinitionArray[0]
      }

      // If premiumStyle in benefit is not present, then default it to Stepped
      if (!eachPolicyBenefit.premiumStyle) {
        clonedBenefit.premiumStyle = PREMIUM_STYLES.STEPPED
      }

      // Remove all optional benefit attributes
      delete clonedBenefit.escalations
      delete clonedBenefit.commissionDetails

      // Modified Quote Enitity for Decrease Cover
      if (alterationType === ALTERATION_TYPES.DECREASE_RISK) {
        // Populate childBenefitReference from policy response to quote collection for decrease cover
        const { linkedChildBenefitReference } = clonedBenefit

        if (Array.isArray(linkedChildBenefitReference)) {
          clonedBenefit.childBenefitReferences = linkedChildBenefitReference.map(benefit => {
            const {
              linkedChildPolicyNo,
              linkedChildBenefitCode,
              linkedChildBenefitInstanceNo,
              benefitNature,
            } = benefit

            return {
              ...(linkedChildPolicyNo && { childPolicyReferenceNo: linkedChildPolicyNo }),
              ...(linkedChildBenefitCode && { childType: linkedChildBenefitCode }),
              ...(linkedChildBenefitInstanceNo && {
                childBenefitInstanceNo: linkedChildBenefitInstanceNo,
              }),
              ...(benefitNature && { benefitNature }),
            }
          })
          // Remove linkedChildBenefitReference
          delete clonedBenefit.linkedChildBenefitReference
        }

        // Transform keys of loading in benefitAssured
        const { loadings, exclusions } = benefitAssured
        if (loadings) {
          clonedBenefit.loading = getLoadingForDecreaseCover(loadings)
        }
        // Transform keys of exclusion in benefitAssured
        if (exclusions) {
          clonedBenefit.exclusion = getExclusionForDecreaseCover(exclusions)
        }
        if (clonedBenefit.subBenefits)
          clonedBenefit.subBenefits = getSubBenefitsForDecreaseCover(clonedBenefit.subBenefits)
        if (clonedBenefit.features)
          clonedBenefit.features = getFeaturesForDecreaseCover(clonedBenefit.features)

        const policyAlterationEligibilityDate = get(
          policyAssessment,
          'policyAlterationEligibilityDate',
          ''
        )
        if (policyAlterationEligibilityDate) {
          policyStructure.policyAlterationEligibilityDate = moment(
            policyAlterationEligibilityDate,
            CLIENT_SIDE_DATE_FORMAT
          ).format(DATE_FORMAT)
        }
      } else {
        delete clonedBenefit.subBenefits
        delete clonedBenefit.features
      }
      if (clonedBenefit.discounts) {
        clonedBenefit.discounts = clonedBenefit.discounts
          .filter(({ discountName }) => discountName === MULTI_COVER_DISCOUNT)
          .map(({ discountName, discountValue }) => ({ discountName, discountValue }))
      }

      return clonedBenefit
    })

  // If PolicyEntity>paymnentDetails>collectionFrequency is not present, then its Push method
  // Use the value from benefits>premiumFrequency
  if (!paymentFrequency) {
    policyStructure.paymentFrequency = get(
      benefits.find(item => !!item.premiumFrequency),
      'premiumFrequency',
      'ANN'
    )
  }

  // Filter out Child life assured, their incompleye contact method data breaks alterations saves and is not a required item for a quote entity
  policyStructure.relationships = constructRelationshipsFromPolicy(relationships).filter(
    relationship => {
      const { bancsCustomerNo } = relationship
      const childCiCovers = policyStructure.covers.filter(
        cover => cover.type === POLICY_CHILD_COVER_BENEFIT_CODE
      )
      if (
        childCiCovers.some(cover => cover.benefitAssured[0].bancsCustomerNo === bancsCustomerNo)
      ) {
        return false
      }
      return true
    }
  )

  if (!isObjectEmpty(alterationOptions) && alterationType !== ALTERATION_TYPES.DECREASE_RISK) {
    if (apiResponse && policyAssessment) {
      policyStructure.alteration = getAlteration(
        policy,
        alterationType,
        apiResponse,
        policyAssessment,
        site,
        benefitStatus
      )
    }
  }

  return policyStructure
}

/** Helper function to find a matching policy within policyStructure
 * based on parentPolicyNo and retreive the policyInstanceNo of that policy
 */
export const getPolicyInstanceNo = (parentPolicyNo, policyStructure) => {
  const matchingPolicy = policyStructure.find(
    eachPolicyStructure => eachPolicyStructure.bancsPolicyNo === parentPolicyNo
  )
  return get(matchingPolicy, 'policyInstanceNo', '').toString()
}

/**
 * Helper function to replace the parentPolicyReferenceNo and childPolicyReferenceNo
 * within each cover with the relevant policyInstanceNo of each policy
 * @param {*} policyStructure List of policyStructure
 * @returns policyStructure with transformed value for parentPolicyReferenceNo
 */
const transformPolicyStructure = policyStructure =>
  policyStructure.map((eachPolicyStructure, _, policyStructureArray) => ({
    ...eachPolicyStructure,
    covers: eachPolicyStructure.covers.map(eachCover => ({
      ...eachCover,
      ...(eachCover.parentBenefitReference && {
        parentBenefitReference: {
          ...eachCover.parentBenefitReference,
          parentPolicyReferenceNo:
            eachCover.type === POLICY_BENEFIT_TISO ||
            eachCover.type === POLICY_BENEFIT_HEP ||
            eachCover.type === POLICY_BENEFIT_ADB ||
            isSGBOBenefit(eachCover.type)
              ? eachPolicyStructure.policyInstanceNo.toString()
              : getPolicyInstanceNo(
                  eachCover.parentBenefitReference.parentPolicyNo,
                  policyStructureArray
                ),
        },
      }),
      ...(eachCover.optimiserParentBenefitReference && {
        optimiserParentBenefitReference: {
          ...eachCover.optimiserParentBenefitReference,
          parentPolicyReferenceNo: getPolicyInstanceNo(
            eachCover.optimiserParentBenefitReference.parentPolicyNo,
            policyStructureArray
          ),
        },
      }),
      ...(eachCover.childBenefitReferences && {
        childBenefitReferences: eachCover.childBenefitReferences.map(item => ({
          ...item,
          childPolicyReferenceNo: getPolicyInstanceNo(
            item.childPolicyReferenceNo,
            policyStructureArray
          ),
        })),
      }),
    })),
  }))

export const removeInvalidReferences = policies =>
  policies.map(policy => ({
    ...policy,
    covers: policy.covers.map(cover => {
      const filteredCover = { ...cover }
      const {
        parentBenefitReference: coverParentBenefitReference,
        childBenefitReferences: coverChildBenefitReferences,
      } = filteredCover
      if (coverParentBenefitReference) {
        const { cover: referenceCoverParent } = getReferenceBenefit(
          policies,
          coverParentBenefitReference,
          'parent'
        )
        if (!referenceCoverParent) {
          filteredCover.parentBenefitReference = {}
        }
      }
      if (coverChildBenefitReferences) {
        filteredCover.childBenefitReferences = coverChildBenefitReferences.filter(childRef => {
          const { cover: referenceCoverChild } = getReferenceBenefit(policies, childRef, 'child')
          return Boolean(referenceCoverChild)
        })
      }
      return filteredCover
    }),
  }))

/**
 * Create policyStructure for each policy for the alteration type.
 * For RejectCPI, the apiResponse should be the Escalation API response.
 * @return [] If polices = [], else Array of PolicyStructure
 */
export const constructPolicyStructure: Array<PolicyStructure> = (
  policies,
  benefitStatus = [],
  policyRules,
  alterationType,
  site
) => {
  const transformedPolicyStructure = transformPolicyStructure(
    policies
      .map((item, index) => {
        const { bancsPolicyNo, policy, escalation } = item
        const matchingRuleForPolicy =
          get(policyRules, 'businessData.policies', []).find(
            eachPolicyRule => eachPolicyRule.bancsPolicyNo === bancsPolicyNo
          ) || {}
        const { assesment: policyAssessment } = matchingRuleForPolicy
        const alterationOptions = {
          alterationType,
          apiResponse: escalation,
          policyAssessment,
          site,
        }
        const policyStructure = getPolicyStructure(policy, alterationOptions, benefitStatus)
        if (policyStructure) policyStructure.policyInstanceNo = index + 1

        return policyStructure
      })
      .filter(policy => policy.covers && policy.covers.length > 0)
  )
  const filteredPolicyStructure = removeInvalidReferences(transformedPolicyStructure)
  return filteredPolicyStructure
}

/** Return the value associated to the type in a Member identifier */
export const getRelatedPartyNoBasedOnType = (identifiers, identifierType) =>
  get(
    identifiers.find(identifier => identifier.type === identifierType),
    'value',
    ''
  )

/**
 *
 * @param {*} policyProductName
 * @param {*} benefitType
 * @param {*} benefitInstanceNo
 * @returns string
 */
export const createBenefitIdentifier = (
  policyProductName: String,
  benefitType: String,
  benefitInstanceNo: String
) => `${policyProductName}-${benefitType}-${benefitInstanceNo}`
