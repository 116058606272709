/* eslint-disable no-bitwise */
/* eslint-disable func-names */
import {
  OKTA_ACCOUNT_STATUS,
  OKTA_REQUIRES_PASSWORD_RESET,
  OKTA_HAS_COMPLETED_WELCOME_JOURNEY,
  OKTA_IS_VALID_IDENTITY,
  OKTA_IS_SUPPORT_STAFF,
  OKTA_IS_MOBILE_NUMBER_EXISTS,
  OKTA_HAS_SEEN_DIGITAL_COMMS,
} from '../constants/okta'

const accountProfileKeys = [
  OKTA_ACCOUNT_STATUS,
  OKTA_REQUIRES_PASSWORD_RESET,
  OKTA_HAS_COMPLETED_WELCOME_JOURNEY,
  OKTA_IS_VALID_IDENTITY,
  OKTA_IS_SUPPORT_STAFF,
  OKTA_IS_MOBILE_NUMBER_EXISTS,
  OKTA_HAS_SEEN_DIGITAL_COMMS,
]

export const destructMaskedBit = (maskedBit, bitMaskList, maskedKeys) => {
  let result = {}
  if (maskedBit && bitMaskList && maskedKeys)
    Object.keys(bitMaskList).forEach(bitMask => {
      if ((bitMaskList[bitMask] & maskedBit) === bitMaskList[bitMask]) {
        const filterKey = bitMask.replace(/[_]/g, '')
        const unMaskedKey = accountProfileKeys.find(
          key => filterKey.indexOf(key.toUpperCase()) === 0
        )
        result = { ...result, [unMaskedKey]: maskedKeys[bitMask] }
      }
    })

  return result
}

// Factor type is 0 for email and 1 for sms
export const getMaskedFactorType = (isMobileNumberExists, isSupportStaff) =>
  isMobileNumberExists || isSupportStaff ? 1 : 0
