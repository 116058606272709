import { fontSize, space, setColumns, colours } from '../../../../../styles'

const styles = {
  base: {
    padding: space(3),
    width: 400,
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
  },
  memberWapper: {
    padding: space(3),
  },
  title: {
    marginRight: `calc(50% + ${space(1, true)})`,
    width: `calc(50% - ${space(1, true)})`,
  },
  halfWidthInput: {
    width: `calc(50% - ${space(1, true)})`,
  },
  postalAddressCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  contactToggle: {
    appearance: 'none',
    background: 'none',
    border: 0,
    color: colours.mediumGreen,
    fontSize: fontSize.xxs,
    marginBottom: space(3),
    marginTop: space(-2),
    padding: 0,
    textAlign: 'right',

    '&:focus': {
      outline: 0,
    },
  },
  secondaryContactCheckbox: {
    visibility: 'hidden',
  },
}
export default styles
