import get from 'lodash/get'
import { required, optional } from '../../../../../../utils/formUtils'

export const FORM_ID = 'rolloverMethod'

const schema = fields => ({
  usi: {
    condition: required,
    errorMsg: get(fields, 'paymentDetailsRolloverUSIErrorMsg.value', ''),
    name: 'usi',
  },
  abn: {
    condition: required,
    errorMsg: get(fields, 'paymentDetailsRolloverABNErrorMsg.value', ''),
  },
  fundName: {
    condition: optional,
  },
  productName: {
    condition: required,
    errorMsg: get(fields, 'paymentDetailsRolloverProductNameErrorMsg.value', ''),
  },
  fundType: {
    condition: required,
    value: 'fundName',
    name: 'fundType',
  },
  fundDetails: {
    condition: optional,
    name: 'fundDetails',
  },
  membershipNumber: {
    condition: optional,
    name: 'membershipNumber',
  },
  rolloverAuthority: {
    condition: required,
    errorMsg: get(fields, 'paymentDetailsRolloverAuthorityDisclaimerErrorMsg.value', ''),
    name: 'rolloverAuthority',
  },
})

export default schema
