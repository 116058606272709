/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconArrowLeft32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M4.5 16.5l-1.06-1.06-1.061 1.06 1.06 1.06L4.5 16.5zm10.06 7.94l-9-9-2.12 2.12 9 9 2.12-2.12zm-9-6.88l9-9-2.12-2.12-9 9 2.12 2.12zM26 15H4.5v3H26v-3z"
      fill="#000"
    />
  </Icon>
)

export default IconArrowLeft32
