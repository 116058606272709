import { space, colours } from '../../../../../../styles'

const styles = {
  names: {
    display: 'flex',
    minHeight: space(12),
  },

  firstName: {
    flexGrow: 1,
    marginRight: space(2),
  },

  lastName: {
    flexGrow: 1,
  },

  exitButton: {
    color: colours.mediumGrey,

    '&:hover': {
      color: colours.mediumGrey,
    },
  },
}

export default styles
