// @flow
import React, { Component } from 'react'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input } from '@mlcl-digital/mlcl-design'
import { actionCreators } from '../../../actions'

// utilities.
import { getClassnameProp } from '../../../utils/stylesUtils'

type AbnLookupProps = {
  // Name of address lookup fields and initialised store state.
  value: string,
  error: string,
  label: string,
  caption: string,
  placeholder: string,
  name: string,
  htmlFor: string,
  changeHandler: () => void,
  blurHandler: () => void,
  actions: { lookupOfABN: (search: string) => void },
}

export class AbnLookup extends Component<AbnLookupProps> {
  blurEventHandler = e => {
    const {
      actions: { lookupOfABN },
      blurHandler,
    } = this.props
    const { value } = e.target
    // eslint-disable-next-line no-unused-expressions
    value.length && lookupOfABN(e.target.value)

    // eslint-disable-next-line no-unused-expressions
    blurHandler && blurHandler(e.target.value)
  }

  render() {
    const { value, error, caption, changeHandler, name, htmlFor, placeholder, label } = this.props

    return (
      <div {...getClassnameProp(this.props)}>
        <Input
          name={name}
          htmlFor={htmlFor}
          label={label}
          placeholder={placeholder}
          blurHandler={this.blurEventHandler}
          changeHandler={changeHandler}
          value={value}
          error={error}
          caption={caption}
        />
      </div>
    )
  }
}

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(undefined, mapDispatchToProps)(AbnLookup)
