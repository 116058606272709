import { Variables } from '@mlcl-digital/mlcl-design'

const { space, mediaQueries, fontWeights, fontSize, colours, shadows, fontFamily, buttonPadding } =
  Variables

const resetCell = {
  borderBottom: 0,
  flex: 'initial',
  minWidth: 'auto',
}
const styles = {
  filterOption: {
    width: '100%',
    border: 'none',
    display: 'block',
    textAlign: 'left',
    borderTop: `1px solid ${colours.lightGrey}`,
    padding: `${space(2, true)} ${space(3, true)}`,
    color: colours.mediumGreen,
  },
  filterOptionActive: {
    width: '100%',
    border: 'none',
    display: 'block',
    textAlign: 'left',
    fontWeight: 'bold',
    borderTop: `1px solid ${colours.lightGrey}`,
    padding: `${space(2, true)} ${space(3, true)}`,
    color: colours.mediumGreen,
  },
  tablePanelMobile: {
    display: 'block',
    boxShadow: shadows.mid,
    marginBottom: space(2),
    '> div:first-of-type': {
      padding: `${space(2, true)} ${space(3, true)}`,
    },
    [mediaQueries.md]: {
      display: 'none',
    },
  },
  wrap: {
    marginBottom: space(24),
    position: 'relative',
  },
  tableRow: {
    '.table-row': {
      cursor: 'pointer',
    },
  },
  applicationSummaryLinkedTag: {
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.xs,
  },
  appSelect: {
    minWidth: space(15, true),
    color: colours.mediumGreen,
    textAlign: 'center',
    fontSize: fontSize.xs,
    zIndex: 2,
    position: 'relative',
    marginBottom: space(0),
  },
  card: {
    paddingTop: space(2),
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    width: '100%',
  },
  tablePanelDesktop: {
    display: 'none',
    [mediaQueries.md]: {
      display: 'block',
      paddingTop: space(4, true),
    },
  },
  tablePanel: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1',
    alignItems: 'center',
  },
  leftPanel: {
    flexBasis: '77.5%',
    'div > input': {
      background: colours.tertiaryFive,
    },
  },
  rightPanel: {
    display: 'flex',
    flexBasis: '22.5%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: space(3),
  },
  disabled: {
    color: colours.mediumGreen,
    fontSize: fontSize.md,
    fontWeight: fontWeights.bold,
    opacity: '0.4',
    cursor: 'not-allowed',
  },
  iconDisabled: {
    opacity: '0.4',
    marginRight: space(1),
    cursor: 'not-allowed',
  },
  icon: {
    color: colours.mediumGreen,
    marginRight: space(1),
  },
  //  styling of secondary small button
  base: {
    textAlign: 'center',
    border: 'none',
    textDecoration: 'none',
    justifyContent: 'center',
    display: 'inline-flex',
    letterSpacing: 1,
    alignItems: 'center',
    lineHeight: 1.33,
    fontWeight: fontWeights.semiBold,
    fontFamily: fontFamily.sourceSans,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    borderRadius: 5,

    fontSize: fontSize.xs,
    padding: `${buttonPadding.sm.vertical}px ${buttonPadding.sm.horizontal}px`,

    color: colours.tertiaryOne,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colours.tertiaryOne,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    background: 'none',
    '&:hover, &:focus': {
      color: colours.tertiaryTwo,
      borderColor: colours.tertiaryTwo,
    },
    '&:active': {
      color: colours.tertiaryOne,
      borderColor: colours.tertiaryOne,
    },
    disabled: {
      backgroundColor: colours.tertiarySix,
      borderColor: colours.mediumGrey,
    },
  },
  chipContainer: {
    flexShrink: 0,
  },
  tableBody: {
    tr: {
      cursor: 'pointer',
      '&:hover': {
        background: colours.tertiaryThree,
      },
    },
    td: {
      '&:nth-child(1)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '10%',
        },
      },
      '&:nth-child(2)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '15%',
        },
      },
      '&:nth-child(3)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '15%',
        },
      },
      '&:nth-child(4)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '34%',
        },
      },
      '&:nth-child(5)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '18%',
        },
      },
      '&:nth-child(6)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '8%',
        },
      },
    },
  },
  tableHead: {
    borderBottom: `1px solid ${colours.tertiaryThree}`,
    th: {
      '&:nth-child(1)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '10%',
        },
      },
      '&:nth-child(2)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '15%',
        },
      },
      '&:nth-child(3)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '15%',
        },
      },
      '&:nth-child(4)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '34%',
        },
      },
      '&:nth-child(5)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '18%',
        },
      },
      '&:nth-child(6)': {
        [mediaQueries.xl]: {
          ...resetCell,
          width: '8%',
        },
      },
    },
  },
}

export default styles
