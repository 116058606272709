// @flow
import React, { Component } from 'react'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { actionCreators } from '../../../actions'

// components.
import ChildCoverForm from './components/ChildCoverForm'
import Modal from '../Modal'

// schema.
import getChildCoverSchema, { FORM_ID } from './childCover.schema'

// helpers.
import { errorCheck, generateFieldsFromData } from '../../../utils/formUtils'
import { getChildCoverFields } from './childCover.fields'

type ChildCoverProps = {
  /** Data to pre-populate modal with before opening */
  data?: {
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    childGender: string,
    childSmokerStatus: string,
  },
  /** Sitecore fields */
  fields: { [string]: string },
  /** Called when Child Details are submitted */
  form: Object,
  /** Redux actions available to the component. */
  actions: {
    /** Action creator */
    formInit: Function,
    /** Action creator */
    formSubmit: Function,
    /** Action creator */
    formUpdate: Function,
    /** Action creator */
    formUpdateField: Function,
    /** Action creator */
    formValidate: Function,
  },
  /** Indicates whether Child Cover modal is open */
  isOpen: boolean,
  /** Called when the modal is closed */
  onClose: Function,
  /** Called when Child Details are submitted */
  onSubmit: Function,
}

type ChildCoverState = {
  // the form schema
  schema: { [string]: {} },
}

export class ChildCover extends Component<ChildCoverProps, ChildCoverState> {
  static defaultProps = {
    data: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      childGender: '',
      childSmokerStatus: '',
    },
  }

  constructor(props: ChildCoverProps) {
    super(props)
    const {
      data,
      fields,
      actions: { formInit, formUpdate },
    } = props

    // Generate form schema from fields and store in state
    this.state = {
      schema: getChildCoverSchema(getChildCoverFields(fields)),
    }

    // Always reset the form on mounting (open)
    const { schema } = this.state
    formInit(FORM_ID, schema)

    // Pre-populate fields with `data` (usually empty, but provided via props)
    if (data) {
      data.dateOfBirth = data.dateOfBirth
        ? moment(data.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : ''
      formUpdate(FORM_ID, generateFieldsFromData(data))
    }
  }

  handleClose = () => {
    const {
      onClose,
      actions: { formInit },
    } = this.props
    const { schema } = this.state
    // Use formInit to reset form data
    formInit(FORM_ID, schema)
    onClose()
  }

  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const { schema } = this.state
    const data = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  handleSubmit = event => {
    event.preventDefault()
    const {
      actions: { formSubmit },
      onSubmit,
    } = this.props
    const { schema } = this.state
    formSubmit(FORM_ID, schema, (data, form) => {
      if (form.isValid) {
        onSubmit(data)
      }
    })
  }

  render() {
    const { form, isOpen, fields } = this.props
    const { schema } = this.state
    const childCoverFields = getChildCoverFields(fields)
    const { title } = childCoverFields

    if (!form) {
      return null
    }

    return (
      <>
        <Modal isOpen={isOpen} onClose={this.handleClose} title={title}>
          <ChildCoverForm
            form={form}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            schema={schema}
            fields={childCoverFields}
          />
        </Modal>
      </>
    )
  }
}

export const mapStateToProps = ({ forms }) => ({
  form: forms.childCover,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChildCover)
