// components.
import PolicyOwnerDetails from '../PolicyOwnerDetails'
import BusinessOwnerDetails from '../BusinessOwnerDetails'
import PaymentDetails from '../PaymentDetails'
// utils.
import { idMaker } from '../../../../../utils/formUtils'

import { PAYER_TYPE_BUSINESS } from '../../constants'

const schema = props => {
  let dynamicTab = [
    {
      title: 'Personal details',
      id: idMaker('Personal details'),
      component: PolicyOwnerDetails,
      isVisible: true,
      dataLocator: 'personalDetails_tile',
      props,
    },
  ]

  if (props.payerCategory === PAYER_TYPE_BUSINESS) {
    dynamicTab = [
      {
        title: 'Business details',
        id: idMaker('Business details'),
        component: BusinessOwnerDetails,
        isVisible: true,
        dataLocator: 'businessDetails_tile',
        props,
      },
    ]
  }

  return [
    ...dynamicTab,
    {
      title: 'Payment details',
      id: idMaker('Payment details'),
      component: PaymentDetails,
      isVisible: true,
      dataLocator: 'paymentDetails_tile',
      props,
    },
  ]
}

export default schema
