export const OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS = 'OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS'
export const OCCUPATIONS_SEARCH_OCCUPATION = 'OCCUPATIONS_SEARCH_OCCUPATION'
export const OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING =
  'OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING'

export const OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_INIT =
  'OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_INIT'

export const OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_FAILURE =
  'OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_FAILURE'
export const OCCUPATIONS_SEARCH_OCCUPATION_FAILURE = 'OCCUPATIONS_SEARCH_OCCUPATION_FAILURE'
export const OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_FAILURE =
  'OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_FAILURE'

export const OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_ERROR =
  'OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_ERROR'
export const OCCUPATIONS_SEARCH_OCCUPATION_ERROR = 'OCCUPATIONS_SEARCH_OCCUPATION_ERROR'
export const OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_ERROR =
  'OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_ERROR'

export const OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_SUCCESS =
  'OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_SUCCESS'
export const OCCUPATIONS_SEARCH_OCCUPATION_SUCCESS = 'OCCUPATIONS_SEARCH_OCCUPATION_SUCCESS'
export const OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_SUCCESS =
  'OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_SUCCESS'

export const OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING =
  'OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING'

export const OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_INIT =
  'OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_INIT'

export const OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_FAILURE =
  'OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_FAILURE'

export const OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_ERROR =
  'OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_ERROR'

export const OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_SUCCESS =
  'OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_SUCCESS'

export const OCCUPATIONS_UPDATE_GENERAL_OCCUPATIONS = 'OCCUPATIONS_UPDATE_GENERAL_OCCUPATIONS'
export const OCCUPATIONS_NAMES_RESET = 'OCCUPATIONS_NAMES_RESET'

export const RESET_OCCUPATIONS_ON_STORE = 'RESET_OCCUPATIONS_ON_STORE'

export const RESET_SEC_OCCUPATION = 'RESET_SEC_OCCUPATION'
