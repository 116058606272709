/* eslint-disable max-len */
import React from 'react'

const IconVisa = () => (
  <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <g filter="url(#filter1_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 6C4 4.89543 4.89543 4 6 4H54C55.1046 4 56 4.89543 56 6V34C56 35.1046 55.1046 36 54 36H6C4.89543 36 4 35.1046 4 34V6Z"
          fill="white"
        />
        <path
          d="M4.125 6C4.125 4.96447 4.96447 4.125 6 4.125H54C55.0355 4.125 55.875 4.96447 55.875 6V34C55.875 35.0355 55.0355 35.875 54 35.875H6C4.96447 35.875 4.125 35.0355 4.125 34V6Z"
          stroke="#E1E0E0"
          strokeWidth="0.25"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1775 13.2414L22.0179 26.5956H25.4704L27.6305 13.2414H24.1775Z"
        fill="#00579F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8399 13.2413H44.1686C43.3444 13.2413 42.7193 13.4833 42.3639 14.3504L37.235 26.595H40.8578C40.8578 26.595 41.4548 24.9475 41.5826 24.5926H46.0157C46.115 25.0618 46.4275 26.595 46.4275 26.595H49.6245L46.8399 13.2413ZM42.5775 21.8648C42.8616 21.0975 43.9549 18.1291 43.9549 18.1291C43.9414 18.1575 44.2395 17.3478 44.4102 16.8501L44.6518 18.0004C44.6518 18.0004 45.3053 21.1969 45.4475 21.8648H42.5775Z"
        fill="#00579F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1338 13.2413L15.7521 22.348L15.3827 20.5009C14.7577 18.3702 12.7968 16.0548 10.6092 14.9046L13.7063 26.581H17.3576L22.7851 13.2413H19.1338Z"
        fill="#00579F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6123 13.2413H7.05704L7.00014 13.5113C11.3334 14.6196 14.2038 17.2904 15.383 20.5009L14.1749 14.3635C13.9762 13.5113 13.3652 13.2702 12.6123 13.2413Z"
        fill="#F9A51A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9877 13.5681C37.3058 13.298 36.226 12.9999 34.891 12.9999C31.4809 12.9999 29.0797 14.8177 29.0652 17.4181C29.0368 19.3356 30.7845 20.4019 32.0915 21.0409C33.4273 21.6948 33.8817 22.1203 33.8817 22.7028C33.8677 23.5988 32.8019 24.0107 31.8074 24.0107C30.4295 24.0107 29.6902 23.7971 28.568 23.2999L28.1128 23.0863L27.6304 26.0841C28.4402 26.4539 29.9319 26.7805 31.4809 26.7945C35.1037 26.7945 37.462 25.0043 37.4909 22.2336C37.5049 20.7135 36.5809 19.5492 34.592 18.5977C33.3849 17.9866 32.6456 17.5739 32.6456 16.9488C32.6605 16.3812 33.2715 15.7986 34.6345 15.7986C35.7572 15.7706 36.5809 16.0406 37.2064 16.3103L37.5194 16.4525L37.9877 13.5681Z"
        fill="#00579F"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="1"
        y="2"
        width="58"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="filter1_d"
        x="0"
        y="0"
        width="60"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.45098 0 0 0 0 0.458824 0 0 0 0 0.501961 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default IconVisa
