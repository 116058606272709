export const DOC_TYPE_CERT_CURR = 'CERT_CURR'
export const DOC_TYPE_QUOTE = 'QUOTE'
export const DOC_TYPE_REMUNERATION = 'REMUNERATION'
export const DOC_TYPE_REPL_QUOTE = 'REPL_QUOTE'
export const DOC_TYPE_ALT_QUOTE = 'DOC_TYPE_ALT_QUOTE'
export const DOC_TYPE_RECORD_ANSWERS = 'DOC_TYPE_RECORD_ANSWERS'
export const DOC_TYPE_DOC = 'DOC'
export const DOC_TYPE_CONTRI_FILE = 'CONTRI_FILE'
export const DOC_TYPE_BRIEF_QUOTE = 'BRIEF_QUOTE'
export const DOC_TYPE_DETAIL_QUOTE = 'DETAIL_QUOTE'
export const DOC_TYPE_ALTERATION_QUOTE = 'ALTERATION_QUOTE'
export const DOC_TYPE_ALTERATION_PC = 'ALTERATION_QUOTE_PC'
export const DOC_TYPE_PROJECTION_TOOL = 'PROJECTION_TOOL'
export const DOC_TYPE_PDF = 'PDF'
export const DOC_TYPE_PAYMENT_RECEIPT = 'PAYMENT_RECEIPT'
export const NULL_BYTE = '%00'
export const INVALID_FILE_NAME = 'Invalid file name'
export const CSV_FILE_EXTENSION = '.csv'
