const styles = {
  base: theme => ({
    verticalAlign: 'middle',
    color: theme.color,
    path: {
      fill: 'currentColor',
    },
  }),
}

export default styles
