// @flow
import { colours, fontSize, space, shadows, mediaQueries } from '../../../styles'

import browser from '../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const infoContainerFlex = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '& > div': {
    marginBottom: space(3, true),
  },
  [mediaQueries.md]: {
    '& > *': {
      flexBasis: '42%',
      maxWidth: '42%',
      marginRight: space(3, true),
    },
  },
  paddingTop: space(7, true),
  paddingBottom: space(20, true),
}

const infoContainerGrid = {
  display: 'grid',
  gridRowGap: space(3, true),
  [mediaQueries.md]: {
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: space(3, true),
  },
  paddingTop: space(7, true),
  paddingBottom: space(20, true),
}

const styles = {
  topBackground: {
    backgroundColor: colours.darkGreen,
    color: colours.white,
  },
  infoContainer: isIE11 ? infoContainerFlex : infoContainerGrid,
  accordion: {
    flex: 1,
    padding: `${space(4, true)} ${space(6, true)}`,
    backgroundColor: colours.white,
    boxShadow: shadows.low,
    alignSelf: 'flex-start',
  },
  accordionHeading: {
    fontSize: fontSize.xs,
    lineHeight: 1.5,
    marginBottom: 0,
  },
  accordionIcon: {
    color: colours.black,
  },
  accordionMailIcon: {
    marginRight: space(4),
  },
  accordionFieldset: {
    margin: 0,
    padding: `${space(4, true)} 0 ${space(2, true)}`,
    borderWidth: 0,
  },
  formSection: {
    width: '80%',
  },
  offset: {
    marginTop: space(-7),
    marginBottom: space(4),
  },
  stickyCTAs: isSticky => ({
    background: colours.darkGreen,
    position: 'absolute',
    bottom: 'auto',
    marginTop: space(-2),
    left: 0,
    right: 0,
    ...(isSticky && {
      position: 'fixed',
      bottom: 0,
    }),
  }),
  cTAList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    textAlign: 'right',
  },
  individualApplicationCTA: {
    display: 'flex',
    width: '100%',
    paddingTop: space(1, true),
    paddingBottom: space(1, true),
  },
  iconBack: {
    color: colours.white,
  },
  linkButton: {
    flex: 1,
    justifyContent: 'left',
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
  },
  modalContent: {
    color: colours.black,
    minHeight: space(10, true),
    marginBottom: space(2),
  },
}

export default styles
