// @flow
import React from 'react'
import styled from '@emotion/styled'

type GenericFallbackProps = {
  /* used to customise the display of the fallback ui. */
  type?: 'default' | 'tab' | 'page',
}

const Fallback = styled('div')()

const GenericFallback = ({ type }: GenericFallbackProps) => <Fallback type={type} />

GenericFallback.defaultProps = {
  type: 'default',
}

export default GenericFallback
