import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Select, Input } from '@mlcl-digital/mlcl-design'

// Redux
import { useSelector, useDispatch } from 'react-redux'
// @ts-expect-error non-TS-code
import { actionCreators } from '../../../actions'

// Utilities
// @ts-expect-error non-TS-code
import { getClassnameProp } from '../../../utils/stylesUtils'

// Styles
import styles from './inputPhone.styles'
import { Store } from '../../../types/store'
import { SelectReturnValue } from '../../../types/inputs'

// Styled components
const Wrapper = styled('div')(styles.wrapper)
const SelectPhoneCode = styled(Select)(styles.selectPhoneCode)
const InputPhoneNumber = styled(Input)(styles.inputPhoneNumber)

type InputPhonePropTypes = {
  selectPhoneCode: {
    label: string
    value: string
    name: string
    placeholder: string
    changeHandler: ({ name, value }: SelectReturnValue) => void
    error: boolean
    caption: string | false
  }
  inputPhoneNumber: {
    label: string
    value: string | object
    name: string
    changeHandler: ({ name, value }: { name: string; value: string }) => void
    error: boolean
    caption: string | false
    placeholder: string
  }
  className?: string | null
}

export const InputPhoneComponent = (props: InputPhonePropTypes) => {
  const { selectPhoneCode, inputPhoneNumber, className } = props
  const countriesPhoneCode = useSelector((state: Store) => state.masterList?.data?.countryCodeList)
  const dispatch = useDispatch()
  const { getMasterList } = actionCreators
  useEffect(() => {
    dispatch(getMasterList(true))
  }, [])
  if (!countriesPhoneCode) return null
  return (
    <Wrapper {...(className && getClassnameProp({ className }))}>
      <SelectPhoneCode {...selectPhoneCode} options={countriesPhoneCode} />
      <InputPhoneNumber type="text" {...inputPhoneNumber} />
    </Wrapper>
  )
}

export default InputPhoneComponent
