// helpers.
import { TITLES } from '../../../../../../constants/forms'
import { fieldsType } from '../../../../../../types/components/SupportStaffRegisterForm'
import {
  ageRangeCondition,
  date,
  email,
  firstNameLastName,
  optional,
  phone,
  required,
  maxLength,
  // @ts-expect-error file not in typescript
} from '../../../../../../utils/formUtils'

export const FORM_ID = 'supportStaffRegisterForm'
export type SchemaNameType = keyof ReturnType<typeof schema>

// will continue to work on validation hence suppress
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const schema = (fields: fieldsType) => ({
  title: {
    condition: required,
    options: TITLES,
  },
  firstName: {
    condition: [
      {
        condition: required,
        // errorMsg: fields.supportStaffRegisterFirstNameRequiredError,
      },
      {
        condition: firstNameLastName,
        // errorMsg: fields.supportStaffRegisterFirstNameInvalidError,
      },
      {
        condition: maxLength(50),
      },
    ],
  },
  lastName: {
    condition: [
      {
        condition: required,
        // errorMsg: fields.supportStaffRegisterLastNameRequiredError,
      },
      {
        condition: firstNameLastName,
        // errorMsg: fields.supportStaffRegisterLastNameInvalidError,
      },
      {
        condition: maxLength(50),
      },
    ],
  },
  dateOfBirth: {
    condition: [
      { condition: required },
      { condition: date },
      { condition: ageRangeCondition(11, 70) },
    ],
  },
  email: {
    condition: [
      {
        condition: required,
        //  errorMsg: fields.supportStaffRegisterEmailError
      },
      {
        condition: email,
        // errorMsg: fields.supportStaffRegisterEmailError
      },
    ],
  },
  contactNumber: {
    condition: [{ condition: required }, { condition: phone }],
    // errorMsg: fields.supportStaffRegisterContactNumberError,
  },
  contactNumberPhoneCode: {
    condition: optional,
  },
  isSupportStaffCheckboxSelected: {
    condition: required,
  },
  loginId: {
    condition: optional,
  },
})

export default schema
