import { colours, fontSize, space, fontWeights } from '../../../styles'

const styles = {
  base: (theme, error, captionTabIndex) => ({
    color: error ? colours.red : theme.text,
    display: 'inline-flex',
    fontSize: fontSize.xxs,
    lineHeight: fontSize.xs,
    justifyContent: 'center',
    marginTop: space(1) / 2,
    marginBottom: space(1),
    marginLeft: space(captionTabIndex),
    '& a': {
      color: theme.link,
      textDecoration: 'none',
      fontSize: fontSize.xxs,
      lineHeight: fontSize.xs,
      display: 'inline',
      padding: 0,
      height: 'auto',
      fontWeight: fontWeights.normal,
    },
  }),
  icon: {
    marginRight: space(1) / 2,
    color: 'inherit',
  },
}

export default styles
