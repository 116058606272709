// @flow
import React from 'react'
import styled from '@emotion/styled'

// icons
import { IconFile32 } from '../../../../atoms/Icons'

// styles.
import styles from './articleCard.styles'

// utils
import history from '../../../../../utils/browserHistory'
import { handleNumberOfLines } from '../../../../../utils/formUtils'

type ArticleCardProps = {
  // title for the article
  title: string,
  // short description of the article
  shortDescription: string,
  // learn more text
  footer: string,
  // save article id to redux store
  handleSetActiveArticle?: Function,
  // artilce id
  articleId: Number,
  // article url
  link: string,
}

const FileIcon = styled(IconFile32)(styles.fileIcon)
const InnerContainer = styled('div')(styles.innerContainer)
const CardWrapper = styled('div')(styles.articleCardWrapper)
const Title = styled('div')(styles.articleTitle)
const TitleWrap = styled('div')(styles.articleTitleWrap)
const BriefDescription = styled('div')(styles.articleBriefDescription)
const Footer = styled('div')(styles.articleFooter)
const FooterText = styled('div')(styles.articleFooterText)

export const ArticleCard = (props: ArticleCardProps) => {
  const { title, footer, articleId, handleSetActiveArticle, shortDescription, link } = props

  return (
    <InnerContainer
      onClick={() => {
        history.push(link)
        handleSetActiveArticle(articleId)
      }}
    >
      <CardWrapper>
        <TitleWrap>
          <FileIcon />
          <Title>{title}</Title>
        </TitleWrap>
        <BriefDescription>{handleNumberOfLines(shortDescription, 150)}</BriefDescription>
      </CardWrapper>
      <Footer>
        <FooterText>{footer}</FooterText>
      </Footer>
    </InnerContainer>
  )
}

ArticleCard.defaultProps = {
  handleSetActiveArticle: () => {},
}

export default ArticleCard
