/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconFullScreen32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7h9v3H7v4H4V7zm21 3h-6V7h9v7h-3v-4zM7 22v-4H4v7h9v-3H7zm18 0v-4h3v7h-9v-3h6z"
      fill="#000"
    />
  </Icon>
)

export default IconFullScreen32
