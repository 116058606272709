import {
  colours,
  fontSize,
  space,
  fontFamily,
  fontWeights,
  deviceBreakpoints,
} from '../../../styles'

const styles = {
  '.ure-container': {
    '.spinner': {
      borderTopColor: colours.lighterGreen,
      borderRightColor: colours.mediumGreen,
      borderBottomColor: colours.mediumGreen,
      borderLeftColor: colours.mediumGreen,
    },

    '*': {
      fontFamily: fontFamily.sourceSans,
    },

    'label, .ure-label': {
      fontSize: fontSize.xs,
      color: colours.black,
      lineHeight: 1.8,
    },

    ol: {
      paddingLeft: space(2),
    },

    '.ure-help': {
      color: colours.darkGrey,
      fontSize: '14px',
    },

    '.enquiry-line > legend.ure-label': {
      borderBottom: 'none',

      span: {
        fontWeight: fontWeights.bold,
        fontSize: fontSize.md,
      },
    },

    '.ure-form-summary': {
      '&.is-error': {
        borderRadius: '0',
      },

      'h2, li, p, a': {
        lineHeight: '1',
        color: colours.black,
      },

      p: {
        lineHeight: '2.4',
      },

      'ul li': {
        a: {
          paddingLeft: space(4),
        },

        '.ure-icon.arrow': {
          top: '6px',
        },
      },
    },

    '.ure-alert': {
      borderRadius: 0,
      backgroundColor: '#fff1e1',
      color: colours.black,
    },

    '.ure-sub-nav-container .ure-sub-nav ul.ure-sub-nav-list li.ure-sub-nav-item': {
      '&.is-valid': {
        '.ure-sub-nav-button': {
          '.ure-sub-nav-icon': {
            backgroundColor: colours.lightGreen,
          },
        },
      },

      '&.is-past &.is-valid': {
        '.ure-sub-nav-button': {
          '.ure-sub-nav-icon': {
            backgroundColor: colours.lightGreen,
          },
        },
      },

      '&.is-invalid': {
        '.ure-sub-nav-button': {
          '.ure-sub-nav-icon': {
            backgroundColor: colours.lighterOrange,
          },
        },
      },

      '&.is-past &.is-invalid': {
        '.ure-sub-nav-button': {
          '.ure-sub-nav-icon': {
            backgroundColor: colours.lighterOrange,
          },
        },
      },

      '&.is-future .ure-sub-nav-button:after': {
        borderColor: colours.lightestGrey,
      },

      '&.is-current': {
        '.ure-sub-nav-button': {
          color: colours.darkGrey,
          '.ure-sub-nav-icon': {
            backgroundColor: colours.lightGreen,
          },
          '.ure-sub-nav-icon .ure-icon': {
            marginTop: '34.5%',
            marginLeft: '35%',
            position: 'absolute',
            width: '30%',
            height: '30%',
            borderRadius: '50%',
            backgroundColor: colours.white,
          },
        },
      },

      '.ure-sub-nav-button': {
        borderRadius: '0',
        fontSize: fontSize.xs,
        '.ure-sub-nav-icon': {
          backgroundColor: colours.lightestGrey,
          '.ure-sub-nav-step-number': {
            color: colours.darkGrey,
            display: 'none',
          },
        },

        '&:hover, &:focus': {
          boxShadow: `0 0 5px ${colours.mediumGreen}`,
        },
        '&:after': {
          borderColor: colours.lightestGrey,
        },
      },
    },

    '.ure-ctrl-holder': {
      '.ure-select:focus:not([readonly]), select:focus:not([readonly]), .ure-text:focus:not([readonly]), textarea:focus:not([readonly])':
        {
          borderColor: colours.mediumGreen,
          borderRadius: '0',
          color: colours.black,
          fontSize: fontSize.xs,
        },

      '.ure-option-group .ure-option': {
        'input ~ label': {
          borderColor: colours.lightGrey,
          borderRadius: '0',
          boxShadow: 'none',
        },

        'input[type="checkbox"]': {
          '~ label': {
            paddingLeft: '48px',
            backgroundColor: 'transparent',
            color: colours.mediumGreen,

            '&:before': {
              borderColor: colours.black,
              boxSizing: 'border-box',
            },

            '.ure-icon ': {
              color: colours.black,
              left: '23px',
            },
          },

          '&:hover, &:checked': {
            '~ label': {
              backgroundColor: colours.mediumGreen,
              borderColor: colours.mediumGreen,
              outline: 'none',
              color: colours.white,
              boxShadow: 'none',

              '&:before': {
                borderColor: colours.mediumGreen,
                boxShadow: 'none',
              },

              '.ure-icon ': {
                color: colours.mediumGreen,
              },
            },
          },
        },

        'input[type="radio"]': {
          '&:hover, &:checked ': {
            '~ label': {
              backgroundColor: colours.mediumGreen,
              borderColor: colours.mediumGreen,
              outline: 'none',
              color: colours.white,
              boxShadow: 'none',
            },
          },
        },
      },

      '.ure-switch-container .ure-switch input:checked + .slider:before': {
        backgroundColor: colours.mediumGreen,
        borderColor: colours.mediumGreen,
      },

      '.ure-option-group .ure-options.is-switch': {
        borderRadius: '0',
        borderColor: colours.mediumGreen,

        '.ure-option .ure-option-item input': {
          '~ label': {
            borderColor: colours.mediumGreen,
          },

          '&:checked ~ label': {
            borderRadius: '0',
            borderColor: colours.mediumGreen,
            color: colours.white,
            backgroundColor: colours.mediumGreen,
          },

          '&:hover ~ label': {
            borderRadius: '0',
            backgroundColor: colours.mediumGreen,
            color: colours.white,
          },

          '&:focus ~ label:before': {
            boxShadow: 'none',
          },
        },
      },
    },

    '.ure-form-section, .ure-accordion.ure-sub-nav-accordion-header': {
      borderRadius: '0',
    },

    '.ure-accordion': {
      '&.ure-sub-nav-accordion-header > .ure-accordion-item .ure-accordion-button-content': {
        color: colours.black,
        fontSize: fontSize.xs,

        '.ure-sub-nav-icon': {
          backgroundColor: colours.lightGreen,
          '.ure-sub-nav-step-number': {
            position: 'absolute',
            width: '30%',
            height: '30%',
            borderRadius: '50%',
            backgroundColor: colours.white,
            textIndent: '-9999px',
          },
        },
      },

      '.ure-accordion-item': {
        '&:hover': {
          '.ec': {
            '&:before, &:after': {
              backgroundColor: colours.darkGreen,
            },
          },
        },
        '&:focus': {
          '.ec': {
            '&:before, &:after': {
              backgroundColor: colours.darkGreen,
            },
          },
        },
      },
    },

    '.ure-button-container': {
      display: 'flex',
      minWidth: '150px',
      marginRight: '16px',
      'button.ure-has-icon': {
        marginRight: space(2),
        span: {
          display: 'none',
        },
      },
      'button:first-of-type': {
        marginLeft: 0,
        order: 1,
      },
    },

    '.cta': {
      borderRadius: 0,
      fontFamily: fontFamily.charlie,
    },

    '.is-primary:not(.is-secondary)': {
      backgroundColor: colours.mediumGreen,
      color: colours.white,
      borderColor: colours.mediumGreen,
      fontSize: fontSize.sm,
      lineHeight: space(3, true),
    },

    '.is-primary.is-secondary': {
      border: `2px solid ${colours.mediumGreen}`,
      fontSize: fontSize.sm,
      lineHeight: space(3, true),
      color: colours.mediumGreen,
    },

    '.cta.is-secondary:hover, .cta.is-secondary:focus': {
      backgroundColor: 'transparent',
    },

    '.ure-input-results, .ure-lookup-field .ure-async-input.ure-text:focus': {
      borderColor: colours.mediumGreen,
      outline: 'none',
    },

    '.cta.is-executing': {
      backgroundColor: colours.lightGrey,
      borderColor: colours.lightGrey,
    },

    '.ure-input-results__result': {
      borderBottomColor: colours.mediumGreen,
      color: colours.mediumGreen,
      lineHeight: 1,

      '&.is-active, &:hover, &.is-selected': {
        backgroundColor: colours.mediumGreen,
        color: colours.white,
      },
    },

    '.ure-chip': {
      backgroundColor: colours.mediumGreen,
      color: colours.white,
      borderRadius: '0',
      fontFamily: fontFamily.charlie,
      lineHeight: '1',
      fontSize: fontSize.sm,
    },

    '.ure-ctrl .ure-option-group .ure-option': {
      '.ure-option-item label': {
        color: colours.mediumGreen,
      },
    },

    /**
     * Responsive Styles
     */
    [`@media only screen and (max-width: ${deviceBreakpoints.sm}px)`]: {
      '.ure-sub-nav-container': {
        width: '100%',
      },
    },

    '.ure-form': {
      '&:after': {
        content: 'none',
      },
    },
  },
}
export default styles
