/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLink16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M12.657 4.02l.645-.763-.645.764zm-.764-.645l-.646.764.646-.764zm-2.813.241l-.764-.645-.002.002.766.643zM6.178 7.074l.764.646.002-.003-.766-.643zm.247 2.818l-.646.764.003.002.643-.766zm.123 1.409a1 1 0 0 0 1.286-1.532L6.548 11.3zm5.587-5.103a1 1 0 1 0 1.527 1.292l-1.527-1.292zM4.3 13.98l-.645.764.645-.764zm.764.646l.646-.764-.646.764zm2.818-.247l.764.646.002-.003-.766-.643zm2.571-3.064l-.763-.646-.003.003.766.643zm-.246-2.817l.646-.763-.646.763zm-.118-1.409A1 1 0 1 0 8.8 8.615l1.29-1.527zM4.816 11.81a1 1 0 1 0-1.528-1.291l1.528 1.29zm8.486-8.553l-.763-.646-1.292 1.528.764.645 1.291-1.527zm-4.988-.284L5.412 6.431l1.532 1.286 2.902-3.458-1.532-1.286zm-2.532 7.685l.766.643 1.286-1.532-.766-.643-1.286 1.532zm-.367-4.23a3 3 0 0 0 .364 4.228L7.07 9.127a1 1 0 0 1-.128-1.408L5.415 6.429zm7.124-3.817a2.994 2.994 0 0 0-4.223.36l1.528 1.29a.994.994 0 0 1 1.403-.122L12.54 2.61zm-.528 2.173c.426.36.477.996.124 1.414l1.527 1.292a3.006 3.006 0 0 0-.36-4.233l-1.29 1.527zm-8.356 9.959l.764.646 1.29-1.528-.763-.645-1.291 1.527zm4.993.278l2.571-3.064-1.532-1.286-2.57 3.064 1.531 1.286zm2.205-7.287l-.764-.646L8.8 8.615l.763.646 1.291-1.527zm.364 4.225a2.999 2.999 0 0 0-.364-4.225L9.562 9.26a.999.999 0 0 1 .128 1.407l1.527 1.291zm-6.798 3.43a3 3 0 0 0 4.227-.365l-1.528-1.291a1 1 0 0 1-1.408.128l-1.291 1.527zm.527-2.173a.997.997 0 0 1-.13-1.406l-1.528-1.291c-1.077 1.274-.89 3.162.367 4.224l1.291-1.527z"
      fill="#000"
    />
  </Icon>
)

export default IconLink16
