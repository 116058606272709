// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import get from 'lodash/get'

// component.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Table, Card, ErrorState, Modal, A11yLabel, Label } from '@mlcl-digital/mlcl-design'
import Checkbox from '../../../../../atoms/Checkbox'
import Input from '../../../../../atoms/Input'
import Select from '../../../../../atoms/Select'

// redux.
import { actionCreators } from '../../../../../../actions'

// schema.
import SCHEMA, { FORM_ID } from './rolloverPaymentMethod.schema'
import { FORM_ID as paymentDetails } from '../paymentDetails.schema'

// constants
import { FUND_TYPES } from '../../../../../../constants/forms'

// helpers.
import { reduceAuthorableFields, renderTextField } from '../../../../../../utils/sitecoreUtils'
import { getPaymentMode } from '../../../../../../utils/quoteUtils'
import { rolloverPaymentData } from './rolloverPaymentMethodUtil'
import { getPaymentFrequency } from '../../../../../../utils/paymentUtils'

// styles.
import styles from './rolloverPaymentMethod.styles'

const Form = styled('form')(styles.root)
const PaymentMethodWrapper = styled('div')(styles.paymentMethodWrapper)
const PaymentMethod = styled('span')(styles.paymentMethod)
const RolloverAuthWrapper = styled(Checkbox)(styles.rolloverAuthorityWrapper)
const PaymentFrequency = styled('p')(styles.paymentFrequency)
const NoResults = styled('div')(styles.noResults)
const FundType = styled(Select)(styles.fundType)
const FundDetails = styled(Input)(styles.fundDetails)
const FundValue = styled(Input)(styles.fundValue)
const SelectFund = styled(Button)(styles.selectFund)
const SearchFundContainer = styled('div')(styles.searchFundContainer)

const columns = ({
  paymentDetailsRolloverFundName,
  paymentDetailsRolloverProductName,
  paymentDetailsRolloverUSI,
  paymentDetailsRolloverABN,
}) => [
  {
    Header: paymentDetailsRolloverUSI,
    id: 'usi',
    accessor: 'usi',
    disableSortBy: true,
    styleOverrides: {
      cell: {
        minWidth: '20%',
      },
      headerCell: {
        minWidth: '20%',
      },
    },
  },
  {
    Header: paymentDetailsRolloverProductName,
    id: 'productName',
    accessor: 'productName',
    disableSortBy: true,
    styleOverrides: {
      cell: {
        minWidth: '30%',
      },
      headerCell: {
        minWidth: '30%',
      },
    },
  },
  {
    Header: paymentDetailsRolloverABN,
    id: 'abn',
    accessor: 'abn',
    disableSortBy: true,
    styleOverrides: {
      cell: {
        minWidth: '20%',
      },
      headerCell: {
        minWidth: '20%',
      },
    },
  },
  {
    Header: paymentDetailsRolloverFundName,
    id: 'fundName',
    accessor: 'fundName',
    disableSortBy: true,
    styleOverrides: {
      cell: {
        minWidth: '30%',
      },
      headerCell: {
        minWidth: '30%',
      },
    },
  },
]

type RolloverProps = {
  // redux actions
  actions: {
    formInit: Function,
  },
  // Sitecore fields
  fields: Object<Object>,
  // Redux form
  form: Object<Object>,
  policyInstanceNo: string,
  // quote entity
  createQuote: Object,
  // funds search results
  fundsLookUp: Object,
  // master data
  masterList: Object,
}

export class RolloverPaymentMethod extends Component<RolloverProps> {
  constructor(props) {
    super(props)
    const {
      actions: { formInit, formUpdateField },
      fields,
      createQuote,
      masterList,
    } = props
    this.state = {
      showSelectFundModal: false,
    }
    const initData = rolloverPaymentData(props)
    formInit(FORM_ID, SCHEMA(reduceAuthorableFields(fields)), initData)
    const paymentMethod = getPaymentMode(createQuote)
    const { fundPaymentMethod } = paymentMethod
    const data = {
      error: '',
      value: fundPaymentMethod,
    }

    formUpdateField(paymentDetails, paymentDetails, data)
    const requestTimeOutInterval = get(masterList.data, 'fundSearchLookup', {
      fundSearchLookup: [{ code: 'fvsTimeOut', value: 0 }],
    }).filter(dataObject => dataObject.code === 'fvsTimeOut')
    const waitTime = requestTimeOutInterval.length ? requestTimeOutInterval[0].value : 0
    this.fundSearch = debounce(this.fundSearch, waitTime)
  }

  onSelectInputChange = ({ value }): void => {
    const {
      actions: { fundSearchReset, formUpdateField },
      form,
      masterList,
    } = this.props

    const fieldData = {
      value,
    }

    formUpdateField(FORM_ID, 'fundDetails', fieldData)
    const { fundType } = form.fields
    const validRolloverCerts = masterList.data.fundSearchLookup
      .filter(data => data.code === 'validRolloverCerts')[0]
      .value.split(',')

    const excludeInternalUSIs = masterList.data.fundSearchLookup
      .filter(data => data.code === 'excludeInternalUSIs')[0]
      .value.split(',')

    const lookupField = fundType.value.value || fundType.value
    const data = {
      validRolloverCerts,
      excludeInternalUSIs,
      [lookupField]: value,
    }

    if (value && value.length > 2) {
      this.fundSearch(data)
    } else if (value && value.length <= 2) {
      fundSearchReset()
    }
  }

  fundSearch = data => {
    const {
      actions: { fundSearch },
    } = this.props
    fundSearch(data)
  }

  fundOptions = values => {
    const { form } = this.props
    const { fundType } = form.fields
    const lookupField = fundType.value.value || fundType.value
    if (
      values &&
      values.length &&
      ['usi', 'abn', 'fundName', 'productName'].includes(lookupField)
    ) {
      return values.map(value => ({
        usi: value.unique_superannuation_identifier,
        productName: value.superannuation_product_name,
        fundName: value.organisation_name,
        abn: value.organisation_abn,
      }))
    }
    return []
  }

  // handle changes on form elements.
  handleFundTypeChange = ({ value, name }) => {
    const { actions } = this.props
    const { formUpdateField } = actions

    const data = {
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formUpdateField(FORM_ID, 'fundDetails', { value: '' })
  }

  handleFocus = () => {
    const {
      actions: { fundSearchReset },
    } = this.props
    fundSearchReset()
  }

  handleNoOptionsMessage = (fundsLookUp, fields) => {
    const { paymentDetailsRolloverFundDetailsLoading, paymentDetailsRolloverFundDetailsNoResults } =
      fields
    if (fundsLookUp.isLoading) {
      return <NoResults>{paymentDetailsRolloverFundDetailsLoading}</NoResults>
    }
    return <NoResults>{paymentDetailsRolloverFundDetailsNoResults}</NoResults>
  }

  handleTableRowClick = rowData => {
    if (!rowData) return
    const {
      actions: { formUpdateField, formValidate },
      fields,
    } = this.props
    const schema = SCHEMA(reduceAuthorableFields(fields))
    Object.keys(rowData.values).map(key =>
      formUpdateField(FORM_ID, key, { value: rowData.values[key] })
    )
    formValidate(FORM_ID, schema)
    this.setState({ showSelectFundModal: false })
  }

  openSelectFundModal = () => {
    this.setState({ showSelectFundModal: true })
  }

  closeSelectFundModal = () => {
    const {
      actions: { fundSearchReset },
    } = this.props
    fundSearchReset()
    this.setState({ showSelectFundModal: false })
  }

  render() {
    const { fields, form, createQuote, policyInstanceNo, fundsLookUp } = this.props
    const schema = SCHEMA(reduceAuthorableFields(fields))
    const {
      paymentDetailsRolloverMembershipNum,
      paymentDetailsRolloverMembershipNumPlaceholder,
      paymentDetailsRolloverFundDetailsLoading,
      paymentDetailsRolloverFundDetailsNoResults,
      paymentDetailsRolloverFundName,
      paymentDetailsRolloverProductName,
      paymentDetailsRolloverUSI,
      paymentDetailsRolloverABN,
      paymentDetailsRolloverFundDetailsPlaceholder,
    } = reduceAuthorableFields(fields)
    if (!form) return null

    const {
      usi,
      membershipNumber,
      rolloverAuthority,
      fundName,
      productName,
      fundType,
      abn,
      fundDetails,
    } = form.fields
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}
    const { showSelectFundModal } = this.state

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''

    let frequency = ''
    if (activePolicy) frequency = getPaymentFrequency(activePolicy, fields)
    const COLUMNS = columns(reduceAuthorableFields(fields))
    return (
      <Fragment>
        <A11yLabel>Add individual</A11yLabel>
        <Form id={FORM_ID} aria-labelledby="rolloverMethod">
          <PaymentMethodWrapper>
            <Label>Payment Method</Label>
            <PaymentMethod>Rollover from external super fund</PaymentMethod>
          </PaymentMethodWrapper>
          <PaymentFrequency>{frequency}</PaymentFrequency>
          <SelectFund onClick={this.openSelectFundModal}>
            {fundName.value && fundName.value.length ? 'Change fund' : 'Select fund'}
          </SelectFund>
          <Input
            htmlFor={schema.membershipNumber.name}
            name={schema.membershipNumber.name}
            label={paymentDetailsRolloverMembershipNum}
            changeHandler={this.handleFundTypeChange}
            value={membershipNumber.value}
            caption={paymentDetailsRolloverMembershipNumPlaceholder}
            captionWithIcon={false}
          />
          {fundName.value && fundName.value.length && (
            <FundValue
              htmlFor="fundName"
              name="fundName"
              label={paymentDetailsRolloverFundName}
              value={fundName.value}
              readOnly
            />
          )}
          {productName.value && productName.value.length && (
            <FundValue
              htmlFor="productName"
              name="productName"
              label={paymentDetailsRolloverProductName}
              value={productName.value}
            />
          )}
          {usi.value && usi.value.length && (
            <FundValue
              htmlFor="usi"
              name="usi"
              label={paymentDetailsRolloverUSI}
              value={usi.value}
            />
          )}
          {abn.value && abn.value.length && (
            <FundValue
              htmlFor="abn"
              name="abn"
              label={paymentDetailsRolloverABN}
              value={abn.value}
            />
          )}
          <RolloverAuthWrapper
            htmlFor={schema.rolloverAuthority.name}
            name={schema.rolloverAuthority.name}
            text={renderTextField(fields.paymentDetailsRolloverAuthorityDisclaimer, true)}
            onChangeHandler={this.handleFundTypeChange}
            checked={rolloverAuthority.value}
            error={rolloverAuthority.error.error}
            caption={rolloverAuthority.error.error && rolloverAuthority.error.errorMsg}
          />
        </Form>
        <Modal
          styleOverrides={{
            width: 800,
          }}
          title="Search Fund"
          isOpen={showSelectFundModal}
          onClose={this.closeSelectFundModal}
        >
          <SearchFundContainer>
            <FundType
              htmlFor={schema.fundType.name}
              options={FUND_TYPES}
              name={schema.fundType.name}
              value={fundType.value}
              changeHandler={this.handleFundTypeChange}
              label="Search options"
            />
            <FundDetails
              htmlFor={schema.fundDetails.name}
              name={schema.fundDetails.name}
              changeHandler={this.onSelectInputChange}
              error={fundDetails.error.error}
              value={fundDetails.value}
              placeholder={paymentDetailsRolloverFundDetailsPlaceholder}
              onFocus={this.handleFocus}
              isSearchable
            />
          </SearchFundContainer>
          {fundsLookUp.response && fundsLookUp.response.length > 0 ? (
            <Table
              styleOverrides={{ headerCell: { minWidth: 'none' }, cell: { minWidth: 'none' } }}
              clickHandler={this.handleTableRowClick}
              classes="table-row"
              data={this.fundOptions(fundsLookUp.response)}
              columns={COLUMNS}
              onSortedChange={() => {}}
            />
          ) : (
            <Card>
              <ErrorState
                message={this.handleNoOptionsMessage(fundsLookUp, {
                  paymentDetailsRolloverFundDetailsLoading,
                  paymentDetailsRolloverFundDetailsNoResults,
                })}
                emptyState
                showBackground={false}
              />
            </Card>
          )}
        </Modal>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms, createQuote, fundsLookUp, masterList }) => ({
  form: forms[FORM_ID],
  createQuote,
  fundsLookUp,
  masterList,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RolloverPaymentMethod)
