// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import SidebarNavItem from '../../../../molecules/SidebarNavItem'
import { TreeItems, Tree, PolicyName } from '../../../../molecules/PolicyStructure'

// utils.
import { getDisplayPolicyStructure } from '../../../../../utils/policyStructureUtils'

// locators.
import { LOCATOR_VIEW_TEMPLATE_TITLE, LOCATOR_VIEW_TEMPLATE_DESC } from './templateItem.locators'

// styles.
import styles from './templateItem.styles'

const DescriptionLabel = styled('span')(styles.descriptionLabel)
const PolicyStructureLabel = styled(PolicyName)(styles.policyStructureLabel)
const PolicyStructureTree = styled(Tree)(styles.policyStructureTree)
export const PolicyStructureContainer = styled('div')(styles.policyStructureContainer)

type TemplateItemProps = {
  // template item id
  id: string,
  // template name
  templateName: string,
  // policy structure
  policyStructure: Array,
  // viewDetailsLabel sitecore field
  viewDetailsLabel?: string,
  // closeDetailsLabel sitecore field
  closeDetailsLabel?: string,
  // handleTemplateClick function
  handleTemplateClick: Function,
  templateDisabled: Boolean,
}

type TemplateItemState = {
  // show details
  showItemDetails: Object<Object>,
}

class TemplateItem extends Component<TemplateItemProps, TemplateItemState> {
  constructor(props) {
    super(props)
    this.state = {
      showItemDetails: {},
    }
  }

  handleDescriptionClick = id => event => {
    event.preventDefault()
    event.stopPropagation()
    const { showItemDetails } = this.state
    this.setState({ showItemDetails: { [id]: !showItemDetails[id] } })
  }

  handleTemplateItemClick = () => {
    const { handleTemplateClick, policyStructure } = this.props
    handleTemplateClick({ policyStructure })
  }

  renderItemDetails = () => {
    const { policyStructure } = this.props
    const displayPolicyStructure = getDisplayPolicyStructure(policyStructure)

    return (
      <PolicyStructureContainer>
        {!!policyStructure.length &&
          policyStructure.map(policy => {
            const { productName, policyInstanceNo } = policy
            return (
              <section key={`${productName}_${policyInstanceNo}`}>
                <PolicyStructureLabel>{productName}</PolicyStructureLabel>
                <PolicyStructureTree>
                  <TreeItems policy={policy} displayPolicyStructure={displayPolicyStructure} />
                </PolicyStructureTree>
              </section>
            )
          })}
      </PolicyStructureContainer>
    )
  }

  render() {
    const {
      id,
      templateName,
      templateDisabled,
      policyStructure,
      viewDetailsLabel,
      closeDetailsLabel,
    } = this.props
    const { showItemDetails } = this.state
    return (
      <Fragment>
        <SidebarNavItem
          labelDataLocator={LOCATOR_VIEW_TEMPLATE_TITLE}
          descDataLocator={LOCATOR_VIEW_TEMPLATE_DESC}
          title={templateName}
          disabled={templateDisabled}
          description={
            <DescriptionLabel>
              {showItemDetails[id] ? closeDetailsLabel : viewDetailsLabel}
            </DescriptionLabel>
          }
          clickHandler={this.handleTemplateItemClick}
          descriptionActive={false}
          descriptionClickHandler={this.handleDescriptionClick(id)}
        />
        {showItemDetails[id] && !!policyStructure.length && this.renderItemDetails()}
      </Fragment>
    )
  }
}

TemplateItem.defaultProps = {
  viewDetailsLabel: 'View Details',
  closeDetailsLabel: 'Close Details',
}

export default TemplateItem
