import { space } from '../../../styles'

const styles = {
  sidebarItem: {
    paddingRight: space(3),
  },
  wrapper: {
    padding: `0 ${space(3, true)}`,
    button: {
      textAlign: 'left',
    },
    span: {
      width: space(37),
    },
  },
  memberListContainer: {
    marginTop: space(2),
  },
}

export default styles
