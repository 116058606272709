/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconPeople80 = ({ ...rest }) => (
  <Icon {...rest} size={80}>
    <path
      d="M40 20.0001C43.6819 20.0001 46.6667 17.0153 46.6667 13.3334C46.6667 9.65152 43.6819 6.66675 40 6.66675C36.3181 6.66675 33.3333 9.65152 33.3333 13.3334C33.3333 17.0153 36.3181 20.0001 40 20.0001Z"
      fill="#00B0B9"
    />
    <path
      d="M52.9667 27.0334C51.6667 25.7334 49.4333 23.3334 45.1 23.3334C44.4 23.3334 40.3667 23.3334 36.6333 23.3334C27.4667 23.3001 20 15.8334 20 6.66675H13.3333C13.3333 17.2001 20.3667 26.1334 30 29.0334V73.3334H36.6667V53.3334H43.3333V73.3334H50V33.5001L63.1667 46.6667L67.8667 41.9668L52.9667 27.0334Z"
      fill="#00B0B9"
    />
  </Icon>
)

export default IconPeople80
