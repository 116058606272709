// Data Feed Registration
export const DATA_FEED_REGISTRATION = 'DATA_FEED_REGISTRATION'
export const DATA_FEED_REGISTRATION_INIT = 'DATA_FEED_REGISTRATION_INIT'
export const DATA_FEED_REGISTRATION_SUCCESS = 'DATA_FEED_REGISTRATION_SUCCESS'
export const DATA_FEED_REGISTRATION_ERROR = 'DATA_FEED_REGISTRATION_ERROR'
export const DATA_FEED_REGISTRATION_FAILURE = 'DATA_FEED_REGISTRATION_FAILURE'
export const DATA_FEED_REGISTRATION_TOAST_SUCCESS = 'Data Feed Registration successful.'
export const DATA_FEED_REGISTRATION_TOAST_ERROR = 'Error in Data Feed Registration'
// Data Feed Cancellation
export const DATA_FEED_CANCELLATION = 'DATA_FEED_CANCELLATION'
export const DATA_FEED_CANCELLATION_INIT = 'DATA_FEED_CANCELLATION_INIT'
export const DATA_FEED_CANCELLATION_SUCCESS = 'DATA_FEED_CANCELLATION_SUCCESS'
export const DATA_FEED_CANCELLATION_ERROR = 'DATA_FEED_CANCELLATION_ERROR'
export const DATA_FEED_CANCELLATION_FAILURE = 'DATA_FEED_CANCELLATION_FAILURE'
export const DATA_FEED_CANCELLATION_TOAST_SUCCESS = 'Data Feed has been cancelled successfully.'
export const DATA_FEED_CANCELLATION_TOAST_ERROR = 'Error in Data Feed Cancellation'
// Unique IDs for toaster
export const DATA_FEED_REGISTRATION_TOAST_ID = 'dataFeedRegistration'
export const DATA_FEED_CANCELLATION_TOAST_ID = 'dataFeedCancellation'
// Terms and conditions
export const DATA_FEED_TERMS_AND_CONDITIONS = 'DATA_FEED_TERMS_AND_CONDITIONS'
export const DATA_FEED_TERMS_AND_CONDITIONS_INIT = 'DATA_FEED_TERMS_AND_CONDITIONS_INIT'
export const DATA_FEED_TERMS_AND_CONDITIONS_SUCCESS = 'DATA_FEED_TERMS_AND_CONDITIONS_SUCCESS'
export const DATA_FEED_TERMS_AND_CONDITIONS_ERROR = 'DATA_FEED_TERMS_AND_CONDITIONS_ERROR'
export const DATA_FEED_TERMS_AND_CONDITIONS_FAILURE = 'DATA_FEED_TERMS_AND_CONDITIONS_FAILURE'
