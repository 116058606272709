export const QUOTE_LISTING_FETCH = 'QUOTE_LISTING_FETCH'
export const QUOTE_LISTING_FETCH_INIT = 'QUOTE_LISTING_FETCH_INIT'
export const QUOTE_LISTING_FETCH_SUCCESS = 'QUOTE_LISTING_FETCH_SUCCESS'
export const QUOTE_LISTING_FETCH_ERROR = 'QUOTE_LISTING_FETCH_ERROR'
export const QUOTE_LISTING_FETCH_FAILURE = 'QUOTE_LISTING_FETCH_FAILURE'

export const QUOTE_DELETION = 'QUOTE_DELETION'
export const QUOTE_DELETION_INIT = 'QUOTE_DELETION_INIT'
export const QUOTE_DELETION_SUCCESS = 'QUOTE_DELETION_SUCCESS'
export const QUOTE_DELETION_ERROR = 'QUOTE_DELETION_ERROR'
export const QUOTE_DELETION_FAILURE = 'QUOTE_DELETION_FAILURE'

export const INIT_EMPTY_ROWS = 'INIT_EMPTY_ROWS'
