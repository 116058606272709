// redux.
import get from 'lodash/get'

// creators
import isEqual from 'lodash/isEqual'
import {
  precheckMandatories,
  updateLifeInsuredDetails,
  createQuoteCalculate,
  submitMemberDetails,
  createQuoteSetPolicyStructure,
} from '../../../../actions/creators/createQuote'
import { resetOccupationInStore } from '../../../../actions/creators/occupation'
import { formInit, formValidate, formSubmit } from '../../../../actions/creators/forms'
import { closeSidebar, setPanelProps } from '../../../../actions/creators/sidebar'
import { setIPSumInsured } from '../../../../actions/creators/calculateSaveQuote'

// components.
import LifeInsuredQuickView from '../../LifeInsuredQuickView'
import CaptureClient from '../../CreateQuote/components/CaptureClient'

// schema.
import captureClientSchema, {
  FORM_ID as captureClientId,
} from '../../CreateQuote/components/CaptureClient/captureClient.schema'

// utils.
import {
  getMemberMandatories,
  postProcessInitialPolicyStructure,
} from '../../../../utils/quoteUtils'
import { formDateFormat } from '../../../../utils/formUtils'
import { checkExistingBusiness } from '../../../../utils/clientDetailsUtils'
import {
  getLifeInsuredData,
  excludeKeysMemberMandatories,
} from '../../../../utils/extendedQuoteUtils'

// constants
import { POLICY_RELATIONSHIPS_LIFEASSURED } from '../../../../constants/policies'
import { OCCUPATION_LIST } from '../../../../constants/occupation'

const FORM_FIELDS_TO_EXCLUDE = [
  'occupationRating',
  'occupationClassCode',
  'occupationalRating',
  'occupation',
  'occupationCode',
  'ageNextBirthday',
]

const updateLAWithMemberMandatories = (memberMandatoriesFormData, lifeInsuredData) => {
  const updatedData = { ...lifeInsuredData }
  updatedData.gender = memberMandatoriesFormData.gender
  updatedData.isSmoker = memberMandatoriesFormData.isSmoker
  updatedData.dateOfBirth = memberMandatoriesFormData.dateOfBirth
  updatedData.primaryOccupation = memberMandatoriesFormData.occupation
  updatedData.occupationRating = memberMandatoriesFormData.occupationRating
  updatedData.earningsPA = memberMandatoriesFormData.earning
  updatedData.earningsPAExcludingSuper = memberMandatoriesFormData.earningExcludingSuper
  updatedData.residentialState = memberMandatoriesFormData.stateOfResidence
  updatedData.employmentStatus = memberMandatoriesFormData.employmentStatus
  return updatedData
}

const SIDEBAR_SCHEMA = () => [
  {
    sitecoreFieldHeadingKey: 'viewLifeInsuredHeader',
    component: LifeInsuredQuickView,
    isDark: true,
    nextText: 'Edit life insured details',
    props: {
      alwaysReset: true,
    },
    handleNext: (next, dispatch, state) => {
      const { createQuote } = state
      const data = { ...getMemberMandatories(createQuote) }
      // the stored dob in memberMandatories follows YYYY/MM/DD and needs to be reformatted
      data.dateOfBirth = formDateFormat(data.dateOfBirth)
      data.occupationRating = data.occupationCode
      dispatch(formInit(captureClientId, captureClientSchema, data))
      dispatch(resetOccupationInStore())
      dispatch(formValidate(captureClientId, captureClientSchema))
      dispatch(
        setPanelProps({
          existingClient: true,
          quoteWillBeModified: false,
          setPreAssessmentVisibility: false,
        })
      )
      next(undefined, undefined, 0)
    },
  },
  {
    sitecoreFieldHeadingKey: 'viewLifeInsuredHeader',
    component: CaptureClient,
    visible: false,
    nextText: 'Confirm',
    props: {
      quoteWillUpdate: next => next(),
    },
    handleNext: (next, dispatch, state) => {
      const {
        createQuote,
        occupation: { occupationRating },
        client,
        productRules,
        masterList: { data },
      } = state
      const { activeIndex, quotes } = createQuote
      const productData = get(productRules, 'data', [])
      const { policyStructure } = quotes[activeIndex] ? quotes[activeIndex] : []
      let occupationData = {}
      if (occupationRating.attributes) {
        const occupations = OCCUPATION_LIST.filter(
          occupation => occupationRating.attributes[occupation]
        )
        const { OCCP_CODE } = occupationRating && occupationRating.attributes
        occupationData = {
          occupation: occupationRating.text
            ? occupationRating.text
            : occupationRating.attributes.text,
          occupationalRating: occupationRating.attributes,
          occupationClassCode: [
            {
              benefit: occupations,
              code: occupations.map(occupation => occupationRating.attributes[occupation]),
            },
          ],
          occupationCode: OCCP_CODE,
        }
      }
      // callback to update createQuote reducer if the form validation was successful.
      const updateMemberMandatories = formFields => {
        const oldMemberMandatories = getMemberMandatories(createQuote)
        // the stored dob in memberMandatories follows YYYY/MM/DD and needs to be reformatted
        oldMemberMandatories.dateOfBirth = formDateFormat(oldMemberMandatories.dateOfBirth)
        if (get(createQuote, `quotes[${activeIndex}].policyStructure[0].relationships.length`, 0)) {
          // If LA exists in relationships, then update that along
          // with member mandatories

          // LA data from relationships
          let lifeInsuredData = getLifeInsuredData({
            createQuote,
            client,
            isExistingBusiness: checkExistingBusiness(client),
          })
          // Overiding data with member mandatories form data
          lifeInsuredData = updateLAWithMemberMandatories(formFields, lifeInsuredData)
          lifeInsuredData.identifier = get(lifeInsuredData, 'identifier.value', '')
          dispatch(submitMemberDetails(lifeInsuredData, null, [POLICY_RELATIONSHIPS_LIFEASSURED]))
        } else {
          dispatch(updateLifeInsuredDetails({ ...formFields, ...occupationData }))
        }
        if (
          !isEqual(
            excludeKeysMemberMandatories(formFields, FORM_FIELDS_TO_EXCLUDE),
            excludeKeysMemberMandatories(oldMemberMandatories, FORM_FIELDS_TO_EXCLUDE)
          )
        ) {
          const { updatedPolicyStructure, calculatedsumInsured } =
            postProcessInitialPolicyStructure(
              productData,
              { ...formFields, ...occupationData },
              policyStructure,
              data,
              false,
              true // // SR occupation check, update default benefit and waiting period
            )
          dispatch(setIPSumInsured(calculatedsumInsured))
          dispatch(createQuoteSetPolicyStructure(updatedPolicyStructure))
        }
        dispatch(resetOccupationInStore())
        const precheck = dispatch(precheckMandatories())
        if (!precheck) {
          dispatch(formInit(captureClientId, captureClientSchema, oldMemberMandatories))
          dispatch(closeSidebar())
          // if existing benefits do not removed due to change in mandatories(precheck false),
          // even then premium should be recalculated(e.g. change in smoker status)
          // if benefits gets removed(precheck true), then premium recalculation done
          // in policyDetailHeader component
          dispatch(createQuoteCalculate({ initiateSGBOCalculation: true }))
        }
        dispatch(
          setPanelProps({
            quoteWillBeModified: precheck,
            oldMemberMandatories,
          })
        )
      }
      dispatch(formSubmit(captureClientId, captureClientSchema, updateMemberMandatories))
    },
  },
]

export default SIDEBAR_SCHEMA
