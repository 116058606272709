import { space, colours, fontSize, fontWeights } from '../../../../../../styles'

const styles = {
  quoteCostRoot: {
    fontSize: fontSize.sm,
    display: 'block',
    borderBottom: `1px solid ${colours.darkestGrey} `,
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'block',
    },
  },

  quotePolicyTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  quoteCostPolicy: isBreakdownExpanded => ({
    paddingTop: space(2, true),
    paddingBottom: space(1, true),
    paddingLeft: space(4, true),
    paddingRight: space(3.5, true),
    '&:nth-of-type(2)': {
      background: isBreakdownExpanded ? colours.darkestGreen : '',
    },
  }),

  quotePolicyNumber: {
    fontSize: fontSize.sm,
    color: colours.white,
  },

  policyHeaderTag: {
    fontSize: fontSize.xxs,
    fontWeight: fontWeights.semiBold,
    span: {
      verticalAlign: 'unset',
    },
  },

  breakdownToggle: {
    width: '100%',
    fontSize: fontSize.xs,
    color: colours.lightGreen,
    '&:hover': {
      color: colours.lightestGreen,
    },
  },

  breakdownList: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: fontSize.xs,
    color: colours.white,
    paddingTop: space(1),
    paddingBottom: space(1),
    marginBottom: space(1),
    borderBottom: `1px solid ${colours.darkestGrey} `,
  },

  breakdownName: {
    paddingBottom: space(1),
    paddingRight: space(1),
  },

  breakdownValue: {
    marginLeft: 'auto',
    textAlign: 'center',
  },

  totalList: {
    border: 0,
    paddingBottom: 0,
    marginBottom: 0,
    dt: {
      fontWeight: fontWeights.bold,
    },
  },

  totalName: {
    lineHeight: space(2, true),
    marginTop: space(0.5),
  },

  totalValue: {
    fontSize: fontSize.sm,
    fontWeight: fontWeights.bold,
    lineHeight: space(2, true),
    marginTop: space(0.5),
  },

  noPolicyMessage: {
    fontSize: fontSize.xxs,
    padding: space(3, true),
    color: colours.white,
  },
  discountLabel: {
    fontSize: fontSize.xxs,
    color: colours.white,
    fontWeight: fontWeights.light,
  },
  flex: {
    display: 'flex',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexGrow1: {
    flexGrow: 1,
  },
  breakdownContainer: {
    width: '100%',
    position: 'relative',
  },
  infoIcon: {
    position: 'absolute',
    color: colours.white,
    margin: space(1, true),
    width: space(2),
    height: space(2),
  },
  tooltipContent: {
    width: space(35, true),
    textAlign: 'left',
  },
}

export default styles
