import { RequestManualQuoteProps } from '..'

// @flow
export const processAfterEscalationsApiResponse = (
  prevProps: RequestManualQuoteProps,
  thisProps: RequestManualQuoteProps
) => {
  const {
    alterations,
    actions: { getAlterationRules },
  } = thisProps

  if (prevProps.alterations.escalationAPICount > 0 && alterations.escalationAPICount === 0) {
    // make rules API call
    getAlterationRules()
  }
}
