// @flow
import {
  FETCH_QUOTE_COLLECTION_INIT,
  FETCH_QUOTE_COLLECTION_SUCCESS,
  CREATE_QUOTE_SAVE,
  CREATE_QUOTE_SAVE_INIT,
  CREATE_QUOTE_SAVE_SUCCESS,
  CREATE_QUOTE_SAVE_FAILURE,
  CREATE_QUOTE_SAVE_ERROR,
  IS_UPDATED_QUOTE,
  CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME,
  CREATE_QUOTE_ADD_PAYMENT_DETAILS,
  UPDATE_APPLICATION_STATUS,
  UPDATE_QUOTE_STATUS,
} from '../actions/types/createQuote'
import { buildQuoteAfterSave } from '../utils/extendedQuoteUtils'
import { QUOTE_STATUS_QUOTE_STAGE, SAVE_QUOTE_COLLECTION } from '../constants/policies'
import { DEFAULT_APP_OUTCOME } from '../constants/ure'
import { DEFAULT_QUOTE_COLLECTION_NAME } from '../constants/forms'
import { getBancsCustomerNumber, getAdviserNo } from '../utils/cookieUtils'
import { ACCEPTED } from '../constants/application'

export const initialQuoteState: quoteCollectionType = {
  memberMandatories: {
    isSmoker: 'Unknown',
    dateOfBirth: '',
    gender: 'Unknown',
    stateOfResidence: '',
    occupationalRating: '',
    occupationClassCode: [
      {
        benefit: [],
        code: [],
      },
    ],
    occupation: '',
    occupationCode: '',
    earning: '',
    earningExcludingSuper: '',
    employmentStatus: '',
  },
  alternateEmailDetails: {
    firstName: '',
    email: '',
  },
  policyStructure: [],
  myLinkDetails: {
    email: '',
    phone: '',
  },
  quoteName: '',
  quoteId: '',
  consents: [],
  type: QUOTE_STATUS_QUOTE_STAGE,
  printableAdviserDetails: {},
  applicationDetails: {
    overallApplicationOutcome: DEFAULT_APP_OUTCOME,
    status: [
      {
        quotePolicyNo: '',
        missingRequirements: [],
      },
    ],
  },
  option: {
    healthyLivingDiscount: false,
  },
}

export const initialState: createQuoteType = {
  activeIndex: 0,
  discountValue: 0,
  isRecalculating: false,
  hasCalculationError: false,
  quoteCollectionId: '',
  quoteCollectionName: DEFAULT_QUOTE_COLLECTION_NAME,
  quotes: [
    {
      ...initialQuoteState,
    },
  ],
  action: SAVE_QUOTE_COLLECTION,
  isSaveQuote: false,
  showSaveQuoteModal: false,
  saveQuoteError: null,
  fetchQuoteError: null,
  calculateQuoteError: null,
  isCalQuoteErrorModal: false,
  isSaveQuoteErrorModal: false,
  isSaveAndExitModal: false,
  isDownloadQuoteInProgress: false,
  isTeleFromAdviser: false,
  isUpdateQuoteSuccess: false,
  isFetchingData: false,
  applicationType: 'NEWBUS',
  bancsAdviserCustomerNo: getAdviserNo() || getBancsCustomerNumber(),
  workTypeHistory: [],
  isRedirectingToThirdParty: false,
}

export const applicationSummary = (
  state = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case FETCH_QUOTE_COLLECTION_INIT: {
      return {
        ...initialState,
        isFetchingData: true,
      }
    }
    case FETCH_QUOTE_COLLECTION_SUCCESS: {
      const {
        payload: { businessData, status },
      } = action
      if (status.type === 'success') {
        const quoteIdToAction = businessData.quoteIDToAction
        const updateState = {
          ...state,
          activeIndex:
            quoteIdToAction && businessData.quotes
              ? businessData.quotes.findIndex(({ quoteId }) => quoteId === quoteIdToAction)
              : state.activeIndex || 0,
          ...businessData,
          isFetchingData: false,
        }
        return updateState
      }
      return state
    }

    case CREATE_QUOTE_SAVE: {
      return {
        ...state,
        saveQuoteError: null,
      }
    }
    case CREATE_QUOTE_SAVE_INIT: {
      return {
        ...state,
        isFetchingData: true,
      }
    }
    case CREATE_QUOTE_SAVE_SUCCESS: {
      const { dataReceived, identifier, productData } = action.payload
      return buildQuoteAfterSave(dataReceived, state, productData, identifier)
    }
    case CREATE_QUOTE_SAVE_FAILURE:
    case CREATE_QUOTE_SAVE_ERROR: {
      return {
        ...state,
        saveQuoteError: action.payload,
        isSaveQuoteErrorModal: true,
        isFetchingData: false,
      }
    }

    case IS_UPDATED_QUOTE: {
      return {
        ...state,
        quotes: state.quotes.map((quote, index) =>
          index === state.activeIndex
            ? {
                ...quote,
                isUpdated: true,
              }
            : {
                ...quote,
                isUpdated: false,
              }
        ),
      }
    }

    case CREATE_QUOTE_SET_QUOTE_COLLECTION_NAME: {
      const { quoteCollectionName } = action.payload
      return {
        ...state,
        quoteCollectionName,
      }
    }

    case CREATE_QUOTE_ADD_PAYMENT_DETAILS: {
      const { policyInstanceNo, paymentMethod } = action.payload
      const { activeIndex } = state
      return {
        ...state,
        quotes: state.quotes.map((quote, index) => {
          if (index === activeIndex) {
            return {
              ...quote,
              policyStructure: quote.policyStructure.map(policy =>
                policy.policyInstanceNo === policyInstanceNo
                  ? {
                      ...policy,
                      paymentInstruction: paymentMethod,
                    }
                  : policy
              ),
            }
          }
          return quote
        }),
      }
    }

    case UPDATE_APPLICATION_STATUS: {
      const { status } = action.payload
      const { activeIndex, quotes } = state
      return {
        ...state,
        quotes: quotes.map((quote, index) =>
          index === activeIndex
            ? {
                ...quote,
                ...(status && status.length
                  ? {
                      applicationDetails: {
                        ...quote.applicationDetails,
                        overallApplicationOutcome: DEFAULT_APP_OUTCOME,
                        status,
                      },
                    }
                  : {
                      applicationDetails: {
                        ...quote.applicationDetails,
                        overallApplicationOutcome: ACCEPTED,
                      },
                    }),
              }
            : quote
        ),
      }
    }

    case UPDATE_QUOTE_STATUS: {
      const {
        quoteStatus: { actionName, quoteType, underwritingMethod },
      } = action.payload
      return {
        ...state,
        action: actionName || state.action,
        quotes: state.quotes.map(
          // insert the policy structure at the active index.
          (quote, index) =>
            index === state.activeIndex
              ? {
                  ...quote,
                  type: quoteType || quote.type,
                  ...(underwritingMethod
                    ? {
                        underwritingDetails: {
                          ...quote.underwritingDetails,
                          underwritingMethod,
                        },
                      }
                    : {}),
                }
              : quote
        ),
      }
    }

    default: {
      return state
    }
  }
}

export default applicationSummary
