/* eslint-disable no-unused-vars */
// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { type Node } from 'react'
import ReactSelect, { components } from 'react-select'
import { Label, FormBlock } from '@mlcl-digital/mlcl-design'

// components.
import Caption from '../Caption'
import { IconChevronDown16, IconChevronUp16 } from '../Icons'

// styles.
import styles from './select.styles'
import { themeConsumer } from '../../../styles/ThemeContext'

export type SelectOptionType = {
  value: mixed,
  label: string,
  isDisabled?: boolean,
}

type SelectProps = {
  // Set focus on the componenet when monted.
  autoFocus?: boolean,
  // Set isSearchable true to make it like a typeahead
  isSearchable?: boolean,
  // handleInputChange to make it like a typeahead
  handleInputChange?: Function,
  // Disable use of select.
  disabled?: boolean,
  // Set select to error state.
  error?: boolean,
  // Se the size of the select component.
  size?: 'sm' | 'lg',
  // A function to be fire upon change of select options.
  changeHandler: Function,
  // The html name for the input.
  name: string,
  // A function to be fire when the select is focused.
  onFocus?: Function,
  // A function to be fire when the select loses focused.
  onBlur?: Function,
  // An array of items for the component to list.
  options: Array<SelectOptionType>,
  // Test to display when select has no option selected.
  placeholder?: string,
  // The select value to display in the select box.
  value?: SelectOptionType | string | number,
  // The position  of the menu when opened.
  menuPosition?: 'absolute' | 'fixed',
  // An extra label for adding containing forms for aria AA.
  labelledby?: string,
  // The id for the select element.
  id: string,
  // The label text for the select.
  label?: string,
  // Tab index for select component.
  tabIndex?: string | number,
  // Text to display in caption tag
  caption?: 'string',
  // Move caption message from left
  captionTabIndex?: number,
  // Choose select variant
  variant?: 'primary | secondary',
  // Style select label
  labelStyle?: {},
  // Style form block
  formBlockStyle?: {},
  // custom component option
  customComponents?: Node,
  // onClick of custom lable
  onClickOfCustomMenuOption?: Function,
  // Used to set loading state
  isLoading?: boolean,
  // set to true if using async
  isAsync: boolean,
  // handle the height of the dropdown list
  noOfDropdownOptionToBeDisplayed?: boolean | number,
  // classes
  className: string,
}

export const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen },
  } = props
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? <IconChevronUp16 /> : <IconChevronDown16 />}
      </components.DropdownIndicator>
    )
  )
}

// Select Component. */
const Select = (props: SelectProps) => {
  const {
    autoFocus,
    changeHandler,
    handleInputChange,
    disabled,
    name,
    onBlur,
    onFocus,
    options,
    placeholder,
    menuPosition,
    id,
    label,
    tabIndex,
    labelledby,
    isSearchable,
    menuIsOpen,
    value,
    error,
    caption,
    captionTabIndex,
    variant,
    labelStyle,
    formBlockStyle,
    customComponents,
    onClickOfCustomMenuOption,
    isLoading,
    noOfDropdownOptionToBeDisplayed,
    isAsync,
    className,
    dataLocator: propDataLocator,
  } = props

  const handleChange = option => {
    changeHandler({ value: option, name })
  }

  const onInputChange = val => {
    handleInputChange(val)
  }

  const handleBlur = () => {
    onBlur(name)
  }

  const handleFocus = () => {
    onFocus(name)
  }

  const hasLabel = !!label.length

  const dataLocator = {
    'data-locator': propDataLocator || `select-${name}`,
  }

  // this allows <Select> accept `value` as either an options object or a
  // string/number that correlates to an option.value
  const getValueAsOption = (): SelectOptionType | void => {
    if (value === '') return value // So placeholder will display if there is no value
    if (!options.length && typeof value === 'string' && value) {
      return { label: value, value }
    }
    if (typeof value === 'string' || typeof value === 'number') {
      // if `value` is passed as a string or number, use it to identify which
      // object in the `options` array should be set as selected/active.
      return options.find(({ value: optionValue }) => optionValue === value)
    }
    return value
  }

  const asyncValueOption = (): SelectOptionType | void => {
    if (value === '') return value // So placeholder will display if there is no value
    if (typeof value === 'string') {
      // if `value` is passed as a string create an object with the string as value and label
      return { value, label: value }
    }
    return value
  }
  return (
    <FormBlock
      css={formBlockStyle}
      hasMargin={!error}
      className={`${className || ''}${(error && ' errorField') || ''}`}
      {...dataLocator}
    >
      {label && (
        <Label css={labelStyle} error={!!error} htmlFor={id} id={`${id}-label`}>
          {label}
        </Label>
      )}
      <ReactSelect
        isLoading={isLoading}
        options={options}
        filterOption={option => option.label}
        value={isAsync ? asyncValueOption() : getValueAsOption()}
        classNamePrefix="mlc"
        onInputChange={onInputChange}
        components={{ DropdownIndicator, ...customComponents }}
        blurInputOnSelect
        tabIndex={tabIndex}
        inputId={id}
        aria-labelledby={`${hasLabel ? `${id}-label` : ''} ${labelledby}`}
        styles={styles[variant](props)}
        autoFocus={autoFocus}
        isDisabled={disabled}
        name={name}
        menuIsOpen={menuIsOpen}
        isSearchable={isSearchable}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        menuPosition={menuPosition}
        onClickOfCustomMenuOption={onClickOfCustomMenuOption}
        noOfDropdownOptionToBeDisplayed={noOfDropdownOptionToBeDisplayed}
      />
      {caption && (
        <Caption error={error} captionTabIndex={captionTabIndex}>
          {caption}
        </Caption>
      )}
    </FormBlock>
  )
}

Select.defaultProps = {
  autoFocus: false,
  isSearchable: false,
  handleInputChange: () => {},
  value: '',
  onFocus: () => {},
  onBlur: () => {},
  error: '',
  disabled: false,
  placeholder: '',
  size: 'sm',
  menuPosition: 'absolute',
  labelledby: '',
  label: '',
  tabIndex: 0,
  caption: '',
  captionTabIndex: 0,
  variant: 'primary',
  labelStyle: {},
  formBlockStyle: {},
  customComponents: {},
  onClickOfCustomMenuOption: () => {},
  isLoading: false,
  noOfDropdownOptionToBeDisplayed: false,
}

export default themeConsumer(Select, 'form')
