import { colours } from '../../../styles'

const styles = {
  overlay: ({ isOverlayComponent }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    background: colours.overlayWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 3,
    width: '100%',
    height: '100%',
    ...(isOverlayComponent && {
      position: 'absolute',
      zIndex: 2,
      opacity: 1,
      background: 'initial',
    }),
  }),
  wrapper: ({ isOverlayComponent }) => ({
    ...(isOverlayComponent && { position: 'relative' }),
  }),
  childrenWrapper: ({ isLoading, isOverlayComponent, childrenBackgroundColor }) => ({
    ...(isLoading && isOverlayComponent && { opacity: 0.25 }),
    backgroundColor: childrenBackgroundColor,
  }),
}

export default styles
