import { Variables } from '@mlcl-digital/mlcl-design'

const { space, fontWeights, colours, fontSize } = Variables
// @flow
const styles = {
  wrapper: {
    visibility: 'hidden',
    height: 0,
    margin: 0,
    padding: 0,
    width: 0,
  },
  labelParent: {
    display: 'flex',
  },
  infoIconParent: {
    margin: space(1, true),
  },
  infoIconEarnings: {
    color: colours.mediumGreen,
  },
  tooltipStyled: {
    width: space(39),
    opacity: 1,
  },
  labelStyled: {
    color: colours.tertiaryOne,
    fontWeight: fontWeights.semiBold,
  },
  inputWrap: {
    marginBottom: 0,
  },
  caption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.xxs,
    lineHeight: fontSize.xs,
    marginTop: space(1),
    marginBottom: space(3),
  },
  captionInfoOne: {
    color: colours.mlcAquaAccessible,
    fontWeight: fontWeights.light,
    padding: 0,
    letterSpacing: 'normal',
    '&:hover:not(:disabled)': {
      color: colours.mlcAquaAccessible,
    },
  },
  captionInfoTwo: {
    textDecoration: 'none',
    color: colours.mlcAquaAccessible,
  },
}
export default styles
