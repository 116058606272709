/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconHelpEncircled32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 16c0 6.075-4.925 11-11 11S5 22.075 5 16 9.925 5 16 5s11 4.925 11 11zm3 0c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14zm-15.001-3.337c-.25.216-.499.603-.499 1.337h-3c0-1.516.563-2.761 1.532-3.603.94-.815 2.138-1.146 3.262-1.113C18.495 9.348 21 10.914 21 13.94c0 1.015-.472 1.743-.954 2.255-.281.3-.689.637-1.003.897-.12.1-.226.187-.306.257-.378.33-.668.64-.874 1.017-.199.363-.363.866-.363 1.633h-3c0-1.233.274-2.238.731-3.073.45-.822 1.036-1.402 1.532-1.836a19.43 19.43 0 0 1 .393-.335l.185-.151c.214-.175.362-.296.519-.463a.774.774 0 0 0 .136-.176v-.002a.025.025 0 0 0 .004-.01v-.013c0-.973-.745-1.628-1.794-1.659-.5-.014-.927.138-1.207.38zM16 21c-1.333 0-2 .75-2 1.5s.667 1.5 2 1.5 2-.75 2-1.5-.667-1.5-2-1.5z"
      fill="#000"
    />
  </Icon>
)

export default IconHelpEncircled32
