/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronLeft16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.414 8l4.293 4.293-1.414 1.414L3.586 8l5.707-5.707 1.414 1.414L6.414 8z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronLeft16
