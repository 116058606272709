import { space, colours, fontSize, fontWeights } from '../../../../../../styles'

const styles = {
  headerWrapper: {
    marginBottom: space(2, true),
  },
  checkboxWrapper: {
    marginBottom: space(1, true),
  },
  backButton: {
    position: 'absolute',
    top: space(4, true),
    left: space(9, true),

    margin: space(0),
    padding: space(0),
    background: 'transparent',
    border: space(0),
    color: colours.black,
    flex: '0 0 100%',
    textAlign: 'left',
    marginBottom: space(4),
    outline: 'none',

    '& svg': {
      color: colours.black,
    },
  },
  selectButton: {
    padding: 0,
    fontSize: fontSize.xs,
    lineHeight: fontSize.md,
  },
  errorMessage: {
    color: colours.red,
    marginBottom: 0,

    '&:before': {
      content: '"!"',
      display: 'inline-block',
      marginRight: space(1) / 4,
      fontWeight: fontWeights.bold,
    },
  },
  errorWrapper: {
    height: space(3, true),
  },
}
export default styles
