// @flow
import get from 'lodash/get'
import {
  OKTA_AUTHENTICATION_INIT,
  OKTA_AUTHENTICATION_SUCCESS,
  OKTA_AUTHENTICATION_ERROR,
  OKTA_AUTHENTICATION_FAILURE,
  OKTA_REDIRECT,
  OKTA_CHANGE_PASSWORD_FAILURE,
  OKTA_CHANGE_PASSWORD_ERROR,
  OKTA_CHANGE_PASSWORD_INIT,
  OKTA_CHANGE_PASSWORD_SUCCESS,
  OKTA_CLOSE_CURRENT_SESSION_FAILURE,
  OKTA_REVOKE_ACCESS_TOKEN_FAILURE,
  OKTA_SIGNOUT_FAILURE,
  OKTA_REAUTHORIZATION_SUCCESS,
  OKTA_REAUTHORIZATION,
  OKTA_CLOSE_CURRENT_SESSION,
  OKTA_SIGNOUT_SUCCESS,
  OKTA_SET_USER_PROFILE_FROM_REDIRECT,
} from '../actions/types/okta'

import {
  OKTA_FORGOT_PASSWORD_INIT,
  OKTA_FORGOT_PASSWORD_SUCCESS,
  OKTA_FORGOT_PASSWORD_ERROR,
  OKTA_FORGOT_PASSWORD_FAILURE,
  OKTA_RESEND_CODE_SUCCESS,
  OKTA_RESEND_CODE_FAILURE,
  OKTA_RESEND_CODE_ERROR,
  OKTA_RESEND_CODE_INIT,
  OKTA_VERIFY_CODE_SUCCESS,
  OKTA_VERIFY_CODE_FAILURE,
  OKTA_VERIFY_CODE_ERROR,
  OKTA_VERIFY_CODE_INIT,
  OKTA_VERIFY_EMAIL_CODE_SUCCESS,
  OKTA_VERIFY_EMAIL_CODE_FAILURE,
  OKTA_VERIFY_EMAIL_CODE_ERROR,
  OKTA_VERIFY_EMAIL_CODE_INIT,
  OKTA_RESET_PASSWORD_INIT,
  OKTA_RESET_PASSWORD_SUCCESS,
  OKTA_RESET_PASSWORD_ERROR,
  OKTA_RESET_PASSWORD_FAILURE,
  OKTA_GET_USER_PROFILE_INIT,
  OKTA_GET_USER_PROFILE_SUCCESS,
  OKTA_GET_USER_PROFILE_FAILURE,
  OKTA_GET_USER_PROFILE_ERROR,
  OKTA_RESET_ERROR_CODE,
  OKTA_UPDATE_USER_PROFILE_INIT,
  OKTA_UPDATE_USER_PROFILE_SUCCESS,
  OKTA_UPDATE_USER_PROFILE_FAILURE,
  OKTA_UPDATE_USER_PROFILE_ERROR,
  OKTA_RESET_USER_PROFILE_ACCOUNT_STATUS,
  OKTA_SET_USER_ID,
  OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_INIT,
  OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_INIT,
  OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_SUCCESS,
  OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_SUCCESS,
  OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_FAILURE,
  OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_ERROR,
  OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_FAILURE,
  OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_ERROR,
  OKTA_AUTHENTICATION_REQUIRE_PASSWORD_RESET,
  OKTA_RESET_AUTHENTICATION_STATE,
  SHOW_HIDE_IDEAL_TIMEOUT,
  OKTA_FORGOT_USERNAME_INIT,
  OKTA_FORGOT_USERNAME_SUCCESS,
  OKTA_FORGOT_USERNAME_FAILURE,
  OKTA_FORGOT_USERNAME_ERROR,
  OKTA_FORGOT_USERNAME_SET_RECAPTCHA,
  OKTA_RESET_IS_ERROR,
} from '../actions/types/authentication'

import { FORM_VALIDATE } from '../actions/types/form'

import {
  OKTA_ACCOUNT_STATUS,
  OKTA_IS_SUPPORT_STAFF,
  CHANGE_PASSWORD,
  OKTA_IS_MOBILE_NUMBER_EXISTS,
} from '../constants/okta'
import { destructMaskedBit } from '../utils/bitMaskingUtil'
import { MFA_REQUIRED } from '../constants/login'
import { isSessionTimeoutDisabled } from '../utils/commonUtils'

export const initialState = {
  isLoading: false,
  errorCode: null, // central location to receive errors.
  hasNetworkFailure: false,
  errorSummary: '',
  hasCompletedWelcomeJourney: true,
  hasSeenDigitalComms: true,
  requiresPasswordReset: false,
  passwordCriteria: {},
  stateToken: '', // for password reset flow.
  sessionToken: null, // for implicit grant.
  isCodeResent: false,
  authenticated: false,
  userId: null,
  isError: null,
  isLocked: false,
  [OKTA_ACCOUNT_STATUS]: '',
  isMFARequired: false,
  smsFactorId: false,
  isValidIdentity: {},
  showIdealSession: false,
  [OKTA_IS_SUPPORT_STAFF]: false,
  isLoggingOut: false,
  [OKTA_IS_MOBILE_NUMBER_EXISTS]: true,
  hasSentForgottonUsername: null,
  recaptchaToken: '',
}

const authentication = (
  state: Object = initialState,
  action: { type: string, payload: { status: boolean, data?: Object }, error: boolean }
): Object => {
  switch (action.type) {
    case OKTA_AUTHENTICATION_INIT: {
      return {
        ...state,
        isLoading: true,
        sessionToken: null,
        authenticated: false,
        errorCode: null,
        hasNetworkFailure: false,
      }
    }

    case OKTA_AUTHENTICATION_SUCCESS: {
      const { data } = action.payload
      const sessionToken = get(data, 'sessionToken', '')
      const isMFARequired = get(data, 'isMFARequired', false)
      const smsFactorId = get(data, 'smsFactorId', false)
      return {
        ...state,
        sessionToken,
        isMFARequired,
        smsFactorId,
        stateToken: '',
        isLoading: false,
        authenticated: !action.error,
        errorCode: null,
      }
    }

    case OKTA_AUTHENTICATION_ERROR:
    case OKTA_AUTHENTICATION_FAILURE:
    case OKTA_SIGNOUT_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload

      return {
        ...state,
        errorCode,
        isMFARequired: false,
        smsFactorId: false,
        authenticated: false,
        sessionToken: null,
        isLoading: false,
        hasNetworkFailure: !errorCode && errorSummary,
        stateToken: '',
        isLoggingOut: false,
      }
    }
    case OKTA_REAUTHORIZATION: {
      return {
        ...state,
        isLoading: true,
        authenticated: false,
      }
    }

    case OKTA_REAUTHORIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        authenticated: true,
      }
    }

    case OKTA_CLOSE_CURRENT_SESSION: {
      return {
        ...state,
        isLoggingOut: true,
      }
    }

    case OKTA_CLOSE_CURRENT_SESSION_FAILURE:
    case OKTA_REVOKE_ACCESS_TOKEN_FAILURE:
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    case OKTA_CHANGE_PASSWORD_INIT: {
      return {
        ...state,
        isLoading: true,
        errorCode: null,
        hasNetworkFailure: false,
      }
    }
    case OKTA_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case FORM_VALIDATE: {
      const { id } = action.payload
      if (id === CHANGE_PASSWORD) {
        return {
          ...state,
          errorCode: null,
          hasNetworkFailure: false,
        }
      }
      return { ...state }
    }

    case OKTA_CHANGE_PASSWORD_ERROR:
    case OKTA_CHANGE_PASSWORD_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload

      return {
        ...state,
        errorCode: action.error && action.payload.data,
        isLoading: false,
        hasNetworkFailure: !errorCode && errorSummary,
      }
    }
    case OKTA_FORGOT_USERNAME_ERROR:
    case OKTA_FORGOT_USERNAME_FAILURE: {
      return {
        ...state,
        errorCode: 'E0000098',
        isLoading: false,
        hasSentForgottonUsername: false,
      }
    }

    case OKTA_FORGOT_USERNAME_SET_RECAPTCHA: {
      const { recaptchaToken } = action.payload
      return {
        ...state,
        recaptchaToken,
      }
    }

    case OKTA_FORGOT_USERNAME_INIT:
    case OKTA_FORGOT_PASSWORD_INIT: {
      return {
        ...state,
        isLoading: true,
        errorCode: null,
        hasNetworkFailure: false,
        hasSentForgottonUsername: null,
      }
    }

    case OKTA_FORGOT_USERNAME_SUCCESS: {
      const { data } = action.payload
      const sentForgottonUserName = get(data, 'result', false)
      return {
        ...state,
        isLoading: false,
        errorCode: null,
        hasSentForgottonUsername: sentForgottonUserName === 'true',
      }
    }

    case OKTA_FORGOT_PASSWORD_SUCCESS: {
      const { data } = action.payload
      const stateToken = get(data, 'stateToken', '')
      return {
        ...state,
        stateToken,
        isLoading: false,
        errorCode: null,
      }
    }

    case OKTA_FORGOT_PASSWORD_ERROR:
    case OKTA_FORGOT_PASSWORD_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
        hasNetworkFailure: !errorCode && errorSummary,
      }
    }

    case OKTA_RESEND_CODE_INIT: {
      return {
        ...state,
        isLoading: true,
        errorCode: null,
        isCodeResent: false,
        hasNetworkFailure: false,
      }
    }

    case OKTA_RESEND_CODE_SUCCESS: {
      const { data } = action.payload
      const stateToken = get(data, 'stateToken', '')
      return {
        ...state,
        stateToken,
        isLoading: false,
        errorCode: null,
        isCodeResent: true,
      }
    }

    case OKTA_RESEND_CODE_ERROR:
    case OKTA_RESEND_CODE_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
        isCodeResent: false,
        hasNetworkFailure: !errorCode && errorSummary,
      }
    }

    case OKTA_VERIFY_CODE_INIT: {
      return {
        ...state,
        errorCode: null,
        isLoading: true,
        isError: null,
        hasNetworkFailure: false,
        isCodeResent: false,
      }
    }

    case OKTA_VERIFY_CODE_SUCCESS: {
      const { data } = action.payload
      const stateToken = get(data, 'stateToken', '')
      return {
        ...state,
        stateToken,
        isError: false,
        isLoading: false,
        passwordCriteria: get(data, '_embedded.policy.complexity', {}),
        errorCode: null,
      }
    }

    case OKTA_VERIFY_CODE_ERROR:
    case OKTA_VERIFY_CODE_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
        isError: true,
        hasNetworkFailure: !errorCode && errorSummary,
        isCodeResent: false,
      }
    }

    case OKTA_VERIFY_EMAIL_CODE_INIT: {
      return {
        ...state,
        errorCode: null,
        isLoading: true,
        isError: null,
        hasNetworkFailure: false,
        isCodeResent: false,
      }
    }

    case OKTA_VERIFY_EMAIL_CODE_SUCCESS: {
      const { data } = action.payload
      const stateToken = get(data, 'stateToken', '')
      return {
        ...state,
        stateToken,
        isError: false,
        isLoading: false,
        passwordCriteria: get(data, '_embedded.policy.complexity', {}),
        errorCode: null,
      }
    }

    case OKTA_VERIFY_EMAIL_CODE_ERROR:
    case OKTA_VERIFY_EMAIL_CODE_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
        isError: true,
        hasNetworkFailure: !errorCode && errorSummary,
        isCodeResent: false,
      }
    }

    case OKTA_RESET_PASSWORD_INIT: {
      return {
        ...state,
        isLoading: true,
        isError: null,
        errorCode: null,
        hasNetworkFailure: false,
        isCodeResent: false,
      }
    }

    case OKTA_RESET_PASSWORD_SUCCESS: {
      const { data, config } = action.payload
      const sessionToken = get(data, 'sessionToken', '')
      const stateToken = get(data, 'stateToken', '')
      const factors = get(data, '_embedded.factors', [])

      const { mappedProfileStatus, bitMaskedList } = config
      const maskedBit = get(data, 'status', null)

      const unMaskedStatus = destructMaskedBit(maskedBit, bitMaskedList, mappedProfileStatus)

      const smsFactor =
        factors.length &&
        factors.find(factor => factor.factorType === 'sms' && factor.provider === 'OKTA')

      return {
        ...state,
        sessionToken:
          (unMaskedStatus &&
            (unMaskedStatus.status === MFA_REQUIRED ? stateToken : sessionToken)) ||
          '',
        smsFactorId: get(smsFactor, 'id', false),
        isMFARequired: (unMaskedStatus && unMaskedStatus.status === MFA_REQUIRED) || false,
        isLoading: false,
        isError: false,
        errorCode: null,
        authenticated: true,
      }
    }

    case OKTA_RESET_PASSWORD_ERROR:
    case OKTA_RESET_PASSWORD_FAILURE: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
        isError: true,
        hasNetworkFailure: !errorCode && errorSummary,
      }
    }

    case OKTA_GET_USER_PROFILE_INIT: {
      return {
        ...state,
        isLoading: true,
        errorCode: null,
        hasNetworkFailure: false,
        isCodeResent: false,
        isLocked: false,
        isValidIdentity: {},
        isError: false,
      }
    }

    case OKTA_GET_USER_PROFILE_SUCCESS: {
      const { data, config } = action.payload
      const { mappedProfileStatus, bitMaskedList } = config
      const maskedBit = get(data, 'user', null)

      const unMaskedResult = destructMaskedBit(maskedBit, bitMaskedList, mappedProfileStatus)

      return {
        ...state,
        isLoading: false,
        errorCode: null,
        errorSummary: '',
        isLocked: unMaskedResult.status ? unMaskedResult.status === 'LOCKED_OUT' : false,
        isError: false,
        ...unMaskedResult,
      }
    }

    case OKTA_GET_USER_PROFILE_FAILURE:
    case OKTA_GET_USER_PROFILE_ERROR: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      // errorSummary only in case of failed network request
      return {
        ...state,
        errorCode,
        errorSummary,
        isLoading: false,
        requiresPasswordReset: false,
        hasCompletedWelcomeJourney: true,
        userId: null,
        hasNetworkFailure: !errorCode && errorSummary,
        isLocked: false,
        [OKTA_ACCOUNT_STATUS]: '',
        isValidIdentity: {},
        [OKTA_IS_SUPPORT_STAFF]: false,
        isError: true,
      }
    }

    case OKTA_RESET_ERROR_CODE: {
      return {
        ...state,
        isLoading: false,
        errorCode: null,
        hasNetworkFailure: false,
      }
    }
    case OKTA_RESET_IS_ERROR: {
      return {
        ...state,
        isError: false,
      }
    }

    case OKTA_UPDATE_USER_PROFILE_INIT: {
      return {
        ...state,
        isLoading: true,
        errorCode: null,
        hasNetworkFailure: false,
        isCodeResent: false,
      }
    }

    case OKTA_SET_USER_PROFILE_FROM_REDIRECT: {
      const { userId, hasCompletedWelcomeJourney, hasSeenDigitalComms, isSupportStaff } =
        action.payload
      return {
        ...state,
        userId,
        hasCompletedWelcomeJourney,
        hasSeenDigitalComms,
        isSupportStaff,
      }
    }

    case OKTA_UPDATE_USER_PROFILE_SUCCESS: {
      const { data, config } = action.payload
      const { mappedProfileStatus, bitMaskedList } = config
      const maskedBit = get(data, 'user', null)
      const unMaskedResult = destructMaskedBit(maskedBit, bitMaskedList, mappedProfileStatus)
      return {
        ...state,
        requiresPasswordReset: unMaskedResult.requiresPasswordReset,
        hasCompletedWelcomeJourney: unMaskedResult.hasCompletedWelcomeJourney,
        hasSeenDigitalComms: unMaskedResult.hasSeenDigitalComms,
        isLoading: false,
        errorCode: null,
      }
    }

    case OKTA_UPDATE_USER_PROFILE_FAILURE:
    case OKTA_UPDATE_USER_PROFILE_ERROR: {
      const {
        data: { errorCode, errorSummary },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
        hasCompletedWelcomeJourney: true,
        hasNetworkFailure: !errorCode && errorSummary,
      }
    }

    case OKTA_RESET_USER_PROFILE_ACCOUNT_STATUS: {
      return {
        ...state,
        isLocked: false,
      }
    }

    case OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_INIT: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_SUCCESS: {
      const { data } = action.payload
      const stateToken = get(data, 'stateToken', '')
      return {
        ...state,
        isLoading: false,
        stateToken,
      }
    }

    case OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_FAILURE:
    case OKTA_AUTHENTICATION_SEND_MFA_SMS_FACTOR_ERROR: {
      const {
        data: { errorCode },
      } = action.payload
      return {
        ...state,
        errorCode,
        stateToken: '',
        isLoading: false,
      }
    }

    case OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_INIT: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_SUCCESS: {
      const { data } = action.payload
      const sessionToken = get(data, 'sessionToken', '')
      return {
        ...state,
        sessionToken,
        isLoading: false,
        errorCode: null,
        isMFARequired: false,
      }
    }

    case OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_FAILURE:
    case OKTA_AUTHENTICATION_VERIFY_MFA_SMS_FACTOR_ERROR: {
      const {
        data: { errorCode },
      } = action.payload
      return {
        ...state,
        errorCode,
        isLoading: false,
      }
    }

    case OKTA_AUTHENTICATION_REQUIRE_PASSWORD_RESET: {
      return {
        ...state,
        requiresPasswordReset: true,
      }
    }

    case OKTA_SET_USER_ID: {
      const { userId } = action.payload
      return {
        ...state,
        userId,
      }
    }

    case SHOW_HIDE_IDEAL_TIMEOUT: {
      const { route } = action.payload
      const isRouteWhiteListed = isSessionTimeoutDisabled(route)

      return {
        ...state,
        showIdealSession: !isRouteWhiteListed,
      }
    }

    case OKTA_SIGNOUT_SUCCESS: {
      return {
        ...state,
        isLoggingOut: false,
      }
    }

    case OKTA_REDIRECT:
    default: {
      return state
    }
  }
}

export default authentication
