import { space, colours } from '../../../../../../styles'

const styles = {
  container: {
    position: 'relative',
  },

  delTemplateItem: {
    margin: `${space(3, true)} 0`,
  },

  subHeading: {
    color: colours.darkGrey,
    fontWeight: 'normal',
  },

  wrap: {
    padding: `${space(3, true)} ${space(3, true)}`,
    transform: 'translateY(100%)',
    textAlign: 'center',
  },
}

export default styles
