import { space, setColumns, colours, fontWeights, fontSize } from '../../../../../../styles'

const styles = {
  base: {
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
  },
  accountType: {
    width: '100%',
  },
  paymentMethod: {
    color: colours.darkGrey,
    fontWeight: fontWeights.light,
    fontSize: fontSize.xs,
    margin: `${space(2, true)} 0 ${space(3, true)}`,
  },
  smfDisplayText: {
    fontSize: fontSize.xxs,
    marginBottom: space(3),
  },
  paymentFrequency: {
    color: colours.black,
  },
}
export default styles
