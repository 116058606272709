export const isBrowser = () =>
  typeof document !== 'undefined' && document.body && typeof window !== 'undefined'

export const browserOrigin = () => {
  let urlOrigin = ''

  if (isBrowser()) {
    if (window.location.origin) {
      urlOrigin = window.location.origin
    } else {
      urlOrigin = `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`
    }
  }
  return urlOrigin
}

export const isLocalStorage = () => !!(isBrowser() && window.localStorage)

export const browserCheck = () => {
  let browser = {}
  if (!isBrowser()) {
    return browser
  }
  if (typeof window.InstallTrigger !== 'undefined') {
    browser = { firefox: 'version XXX' }
  } else if (window.safari !== undefined) {
    browser = { safari: 'version XXX' }
  } else if (typeof document !== 'undefined' && !!document.documentMode) {
    if (!window.atob) {
      browser.ie = 9
    } else if (window.MSInputMethodContext) {
      browser.ie = 11
    } else {
      browser.ie = 10
    }
  } else if (navigator.userAgent.indexOf('Edge') >= 0) {
    browser = { edge: 'version XXX' }
  } else if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
    browser = { chrome: 'version XXX' }
  }
  return browser
}

export const getQueryParameterValueFromURL = (state, param) => {
  let ParamValue

  if (isBrowser()) {
    const params = new URLSearchParams(state || window.location.search)
    ParamValue = params.has(param) && params.get(param)
    ParamValue = ParamValue ? `${ParamValue}${window.location.hash}` : ParamValue
  }
  return ParamValue
}

export default browserCheck()
