export const MLC_ON_TRACK_DECLINED = 'MLC_ON_TRACK_DECLINED'

export const CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS =
  'CLIENT_MEET_TARGET_MARKET_DETERMINATION_REQTS'
export const REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD =
  'REASON_RECOMMENDED_TO_CLIENT_WHO_DOES_NOT_MEET_TMD'
export const TYPE_OF_ADVICE_PERSONAL_OR_GENERAL = 'TYPE_OF_ADVICE_PERSONAL_OR_GENERAL'

export const CUSTOMER_PERSONAL_STATEMENT = '/personalstatement'
export const CUSTOMER_PERSONAL_STATEMENT_HOME_PAGE = '/personalstatement?quoteCollectionId=$id'
export const CUSTOMER_PERSONAL_STATEMENT_DETAIL_PAGE = '/personalstatement/detail'
export const CUSTOMER_PERSONAL_STATEMENT_METHOD_PAGE = '/personalstatement/method'
export const CUSTOMER_PERSONAL_STATEMENT_STATUS_PAGE = '/personalstatement/status'
export const CUSTOMER_PERSONAL_STATEMENT_UNDERWRITING_PAGE = '/personalstatement/underwriting'
export const CUSTOMER_PERSONAL_STATEMENT_URE_PAGE = '/personalstatement/underwriting/engine'
export const CUSTOMER_PERSONAL_STATEMENT_DECLARATION = '/personalstatement/application-declaration'

export const CUSTOMER_TELE_UNDERWRITER_HOME_PAGE = '/teleunderwriter'
export const CUSTOMER_TELE_UNDERWRITER_METHOD_PAGE = '/teleunderwriter/method'
export const CUSTOMER_TELE_UNDERWRITER_STATUS_PAGE = '/teleunderwriter/status'
export const CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE = '/teleunderwriter/underwriting'
export const CUSTOMER_TELE_UNDERWRITER_URE_PAGE = '/teleunderwriter/underwriting/engine'
export const CUSTOMER_TELE_UNDERWRITER_DECLARATION = '/teleunderwriter/application-declaration'

export const BENEFIT_REPLACE = 'replace'
export const NEW_BUS = 'NEWBUS'
