// @flow
import { Variables } from '@mlcl-digital/mlcl-design'

const { colours, space, fontSize, fontWeights, iconWidth, mediaQueries } = Variables

const styles = {
  card: {
    width: '100%',
    marginRight: space(3, true),
    [mediaQueries.md]: {
      width: `calc(50% - ${space(3, true)})`,
    },
    [mediaQueries.lg]: {
      width: `calc(33.33% - ${space(3, true)})`,
    },
  },

  name: {
    position: 'relative',
    display: 'block',
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    marginTop: space(1, true),
    marginBottom: space(4, true),
  },

  tag: colour => ({
    verticalAlign: 'middle',
    lineHeight: fontSize.sm,
    marginTop: space(-0.25, true),
    marginLeft: space(1, true),
    background: !colour ? colours.mlcRubineAccessible : 'default',
  }),

  status: ({ policyStep }) => ({
    position: 'absolute',
    right: 0,
    top: `calc(50% - ${space(1.5, true)})`,
    borderRadius: '100%',
    width: space(3, true),
    height: space(3, true),
    padding: space(0.5, true),
    ...(policyStep && {
      position: 'static',
      marginRight: '20px',
      width: space(2, true),
      height: space(2, true),
    }),
  }),

  labelContainer: ({ leftMargin }) => ({
    marginLeft: leftMargin ? space(4.5) : 0,
  }),
  checked: {
    color: colours.tertiarySix,
    background: colours.mlcAquaCustomer,
  },

  unchecked: {
    color: colours.lightestGrey,
    background: colours.lightestGrey,
  },

  option: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    minHeight: space(10, true),
    paddingTop: space(2, true),
    paddingBottom: space(2, true),
    paddingLeft: space(1, true),
    paddingRight: space(6, true),
    marginLeft: space(-1, true),
    marginRight: space(-1, true),
    borderTop: `1px solid ${colours.lightestGrey}`,
    cursor: 'pointer',
    '&:hover': {
      background: colours.tertiaryFive,
    },
    '&:last-child': {
      borderBottom: `1px solid ${colours.lightestGrey}`,
    },
    '&:nth-child(5)': {
      borderBottom: 0,
    },
  },

  label: {
    fontSize: fontSize.xs,
    color: colours.darkGrey,
  },

  value: {
    fontSize: fontSize.xxs,
    color: colours.mediumGrey,
  },

  icon: {
    position: 'absolute',
    right: space(2, true),
    top: `calc(50% - ${iconWidth.sm / 2}px)`,
    width: iconWidth.sm,
    height: iconWidth.sm,
    color: colours.tertiaryOne,
  },

  iconActive: {
    color: colours.mlcAquaAccessible,
  },

  optionDisabled: {
    cursor: 'default',
    '&:hover': {
      background: 'none',
    },
    color: colours.lightestGrey,
  },
}

export default styles
