// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { A11yLabel, Checkbox, Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// component.
import Button from '../../../../atoms/Button'
import { IconAddCircle16 } from '../../../../atoms/Icons'
import Caption from '../../../../atoms/Caption'

import AddressLookUp from '../../../../molecules/AddressLookUp'
import AbnLookup from '../../../../molecules/AbnLookup'
import InputPhone from '../../../../molecules/InputPhone'

import MemberList from '../../../MemberList'

// styles.
import styles from './businessOwnerDetails.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

// schema.
import SCHEMA, { FORM_ID } from './businessOwnerDetails.schema'
import paymentDetailsSchema, {
  FORM_ID as paymentDetailsSchemaFormID,
} from '../PaymentDetails/paymentDetails.schema'

// helpers.
import { errorCheck, generateFieldsFromData, getValue } from '../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { initForms } from './businessOwnerInitUtils'
import { getActivePaymentDetailsForm } from '../../../../../utils/paymentUtils'
import { directDebitData } from '../PaymentDetails/DirectDebit/directDebitInItUtils'
import { creditCardData } from '../PaymentDetails/CreditCard/creditCardInItUtils'

type businessOwnerDetailsProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  forms: Object,
  // form sub-store
  form: Object,
  // Sitecore authorable fields
  fields: Object,
  // Address lookup manual entry redux state mapped to props.
  isManualPostal: boolean,
  isManualResidential: boolean,
  handleNewClient: Function,
  abnLookup: Object,
  createQuote: Object,
  captureCreditCard: Object,
  sidebar: Object,
  paymentDetailsForm: Object,
}

export const RESIDENTIAL_ADDRESS = [
  'residentialAddress',
  'residentialAddressStreet',
  'residentialAddressHouseNo',
  'residentialAddressLocality',
  'residentialAddressState',
  'residentialAddressPostCode',
]
export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalAddressStreet',
  'postalAddressHouseNo',
  'postalAddressLocality',
  'postalAddressState',
  'postalAddressPostCode',
]

const FullWidthInput = ignoreColumns(Input)
const FullWidthAbnLookup = ignoreColumns(AbnLookup)
const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const FullWidthCheckbox = ignoreColumns(Checkbox)

const Form = styled('form')(styles.base)
const Wrapper = styled('div')()
const SoleDirectorCheckbox = styled(FullWidthCheckbox)(styles.soleDirectorCheckbox)
const AddDirectorBtn = styled(Button)(styles.addDirectorBtn)
const AddDirectorIcon = styled(IconAddCircle16)(styles.addDirectorIcon)
const ErrorAddDirector = styled('div')(styles.errorAddDirector)

export class BusinessOwnerDetails extends Component<businessOwnerDetailsProps> {
  constructor(props) {
    super(props)

    const {
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldFutureDateError,
      policyOwnerDetailsDateOfBirthFieldFormatError,
      policyOwnerDetailsDateOfBirthFieldAgeRangeError,
      policyOwnerDetailsInvalidFirstNameError,
      policyOwnerDetailsFirstNameFieldError,
      policyOwnerDetailsInvalidLastNameError,
      policyOwnerDetailsLastNameFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldError,
      policyOwnerDetailsResidentialAddressValidStreetFieldError,
      policyOwnerDetailsResidentialAddressLocalityFieldError,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError,
      policyOwnerDetailsResidentialAddressStateFieldError,
      policyOwnerDetailsResidentialAddressValidStateFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError,
      policyOwnerDetailsResidentialAddressPostCodeFieldError,
      policyOwnerDetailsResidentialAddressValidPostalFieldError,
    } = props.fields

    this.schemaWithAuthorableFields = SCHEMA({
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldFutureDateError,
      policyOwnerDetailsDateOfBirthFieldFormatError,
      policyOwnerDetailsDateOfBirthFieldAgeRangeError,
      policyOwnerDetailsInvalidFirstNameError,
      policyOwnerDetailsFirstNameFieldError,
      policyOwnerDetailsInvalidLastNameError,
      policyOwnerDetailsLastNameFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldError,
      policyOwnerDetailsResidentialAddressValidStreetFieldError,
      policyOwnerDetailsResidentialAddressLocalityFieldError,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError,
      policyOwnerDetailsResidentialAddressStateFieldError,
      policyOwnerDetailsResidentialAddressValidStateFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError,
      policyOwnerDetailsResidentialAddressPostCodeFieldError,
      policyOwnerDetailsResidentialAddressValidPostalFieldError,
    })
  }

  componentWillMount() {
    const {
      actions,
      isManualPostal,
      isManualResidential,
      form,
      forms,
      fields,
      createQuote,
      createQuote: { quotes, activeIndex },
      paymentDetailsForm,
      captureCreditCard,
      sidebar,
    } = this.props

    if (!sidebar.panelProps) return
    const { formInit } = actions
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isManualResidential,
      fields,
    })

    const { policyStructure } = quotes[activeIndex]
    const activePolicy = policyStructure.find(
      policy => policy.policyInstanceNo === sidebar.panelProps.policyInstanceNo
    )

    const paymentDetailsData = {
      paymentDetails: get(
        activePolicy,
        'paymentInstruction.collectionMethod',
        get(paymentDetailsForm, 'fields.paymentDetails.value', '')
      ),
    }

    if (!paymentDetailsForm) {
      formInit(paymentDetailsSchemaFormID, paymentDetailsSchema, paymentDetailsData)
      const paymentType = get(paymentDetailsData, 'paymentDetails')
      const { activeFormId, activeSchema } = getActivePaymentDetailsForm(
        paymentType,
        { fields },
        captureCreditCard.displayCreditCardListing
      )
      switch (paymentType) {
        case 'DD':
          formInit(
            activeFormId,
            activeSchema,
            directDebitData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        case 'CC':
          formInit(
            activeFormId,
            activeSchema,
            creditCardData({
              form: forms[activeFormId],
              createQuote,
              sidebar,
            })
          )
          break
        default:
          break
      }
    }
    if (!form && sidebar.currentPanel === 1) {
      const data = initForms(this.props)
      formInit(FORM_ID, schema, data)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isManualPostal,
      isManualResidential,
      form,
      fields,
      actions: { formUpdateField, formValidate, formInit },
      abnLookup: { identifierValue: abnNumber, companyName, isAbnCancelled },
      sidebar,
    } = this.props
    const {
      abnLookup: { identifierValue: prevAbnNumber },
    } = prevProps
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isManualResidential,
      fields,
    })
    if (!form && sidebar.currentPanel === 1) {
      const data = initForms(this.props)
      formInit(FORM_ID, schema, data)
      return
    }

    if (!form) return

    const updatedSchema = this.schemaWithAuthorableFields({
      isManualPostal,
      isManualResidential,
      ...form.fields,
    })

    if (abnNumber !== prevAbnNumber) {
      companyName &&
        formUpdateField(FORM_ID, 'companyName', {
          error: { error: false },
          value: isAbnCancelled ? '' : companyName,
        })
      formUpdateField(FORM_ID, 'abnConfirmation', {
        error: { error: !abnNumber },
        value: abnNumber,
      })
      formValidate(FORM_ID, updatedSchema)
    }
  }

  addressToggleHandler =
    key =>
    (isManual: boolean): void => {
      const {
        actions: { formResetField },
      } = this.props
      // We clear address fields when entering manual mode
      if (isManual) {
        formResetField(FORM_ID, key === 'isManualPostal' ? POSTAL_ADDRESS : RESIDENTIAL_ADDRESS)
      }
    }

  toggleSoleDirector = ({ value, name }) => {
    const {
      actions: { formUpdateField },
      form: {
        fields: {
          directors: { value: directorsValue },
        },
      },
    } = this.props
    formUpdateField(FORM_ID, name, { value })

    if (value && directorsValue && directorsValue.length > 1) {
      const [firstDirector] = directorsValue
      formUpdateField(FORM_ID, 'directors', {
        error: { error: false },
        value: [firstDirector],
      })
    } else if (directorsValue && directorsValue.length) {
      formUpdateField(FORM_ID, 'directors', {
        error: { error: false },
        value: directorsValue,
      })
    }
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const {
      actions,
      isManualPostal,
      isManualResidential,
      form,
      fields,
      abnLookup: { isLoading, error },
    } = this.props
    const { formUpdateField, formValidate, resetAbnLookup } = actions

    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isManualResidential,
      ...form.fields,
      fields,
    })
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    if (name === 'abn') {
      formUpdateField(FORM_ID, 'abnConfirmation', { error: { error: true }, value: '' })
      if (isLoading || error) resetAbnLookup()
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressChange = address => {
    const { name, data, value } = address
    const { actions, isManualPostal, isManualResidential, form, fields } = this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isManualResidential,
      ...form.fields,
      fields,
    })
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  addDirectorClickHandler = () => {
    const {
      actions: { setPanelProps },
    } = this.props
    const { handleNewClient } = this.props
    handleNewClient()
    setPanelProps({
      directorIdentifier: {
        type: 'REF_NO',
        value: '',
      },
    })
  }

  editDirectorHandler = member => () => {
    const {
      actions: { setPanelProps },
      handleNewClient,
    } = this.props
    setPanelProps({ directorIdentifier: member.identifiers[0] })
    handleNewClient()
  }

  deleteDirectorHandler = member => () => {
    const {
      form: {
        fields: { directors },
      },
      actions: { formUpdateField },
    } = this.props

    const copyOfDirectors = [...directors.value]
    const filteredDirector = copyOfDirectors.filter(
      director =>
        !director.relatedParty.identifiers.some(i => i.value === member.identifiers[0].value)
    )
    formUpdateField(FORM_ID, 'directors', {
      error: { error: false },
      value: filteredDirector,
    })
  }

  renderDirector() {
    const {
      fields,
      form: {
        fields: { directors, isSoleDirector },
      },
    } = this.props
    const { addDirectorLabel, addDirectorListCaption } = reduceAuthorableFields(fields)
    // TODO: memberName should be mapped from redux store
    const members = []
    if (directors.value) {
      directors.value.forEach(director => {
        members.push({
          name: `${director.relatedParty.firstName} ${director.relatedParty.lastName}`,
          identifiers: director.relatedParty.identifiers,
          role: director.relatedParty.companyRole,
        })
      })
    }

    return (
      <Fragment>
        {!!members.length && (
          <MemberList
            caption={addDirectorListCaption}
            members={members}
            editHandler={this.editDirectorHandler}
            removeHandler={this.deleteDirectorHandler}
            referMember
          />
        )}

        <AddDirectorBtn
          type="tertiary"
          onClick={this.addDirectorClickHandler}
          disabled={
            !(
              (isSoleDirector.value && !members.length) ||
              (!isSoleDirector.value && members.length < 5)
            )
          }
        >
          <AddDirectorIcon />
          <span>{addDirectorLabel}</span>
        </AddDirectorBtn>
      </Fragment>
    )
  }

  render() {
    const {
      fields,
      form,
      isManualPostal,
      isManualResidential,
      abnLookup: { error, isLoading, isAbnCancelled },
    } = this.props

    if (!form) return null
    const {
      companyName,
      abn,
      email,
      // Address fields
      residentialAddress,
      residentialAddressHouseNo,
      residentialAddressStreet,
      residentialAddressLocality,
      residentialAddressState,
      residentialAddressCountry,
      residentialAddressPostCode,
      isSoleDirector,
      directors,
      contactNumberCode,
      contactNumber,
    } = form.fields

    const {
      addIndividualHeaderLabel,
      businessOwnerCompanyNameLabel,
      businessOwnerCompanyNamePlaceholder,
      businessOwnerCompanyNameError,
      businessOwnerABNLabel,
      businessOwnerABNError,
      businessOwnerCancelledABNError,
      policyOwnerDetailsEmailFieldLabel,
      policyOwnerDetailsEmailFieldPlaceholder,
      policyOwnerDetailsEmailFieldError,
      policyOwnerAddDirectorOnBusinessError,
      businessOwnerAbnPlaceHolder,
      // Residential Address
      policyOwnerDetailsResidentialAddressToggleToManualText,
      policyOwnerDetailsResidentialAddressFieldLabel,
      policyOwnerDetailsResidentialAddressFieldPlaceholder,
      policyOwnerDetailsResidentialAddressFieldError,
      policyOwnerDetailsResidentialAddressStreetFieldLabel,
      policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
      policyOwnerDetailsResidentialAddressHouseNoFieldLabel,
      policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
      policyOwnerDetailsResidentialAddressLocalityFieldLabel,
      policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
      policyOwnerDetailsResidentialAddressStateFieldLabel,
      policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
      policyOwnerDetailsResidentialAddressCountryFieldLabel,
      policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
      policyOwnerDetailsResidentialAddressPostCodeFieldLabel,
      policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
      policyOwnerDetailsResidentialAddressToggleToAutoText,
      policyOwnerValidatingBusinessAbnNumberWarning,
      policyOwnerValidatedBusinessAbnNumberError,
      policyOwnerDetailsPrimaryContactFieldLabel,
      policyOwnerDetailsPrimaryContactFieldPlaceholder,
      policyOwnerDetailsPrimaryContactFieldError,

      // director
      businessOwnerSoleDirectorCheckboxLabel,
      phoneCodeLabel,
    } = reduceAuthorableFields(fields)

    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isManualResidential,
      ...form.fields,
      fields,
    })

    const selectContactNumberCode = {
      label: phoneCodeLabel,
      value: contactNumberCode.value.value,
      name: 'contactNumberCode',
      id: 'contactNumberCode',
      changeHandler: this.handleChange,
    }
    const inputContactNumber = {
      htmlFor: 'contactNumber',
      name: 'contactNumber',
      label: policyOwnerDetailsPrimaryContactFieldLabel,
      changeHandler: this.handleChange,
      placeholder: policyOwnerDetailsPrimaryContactFieldPlaceholder,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && policyOwnerDetailsPrimaryContactFieldError,
      value: contactNumber.value,
    }

    const handleAbnCaption = () => {
      if (isAbnCancelled) {
        return businessOwnerCancelledABNError
      }
      if (abn.error.error) {
        return businessOwnerABNError
      }
      if (isLoading) {
        return policyOwnerValidatingBusinessAbnNumberWarning
      }
      if (error) {
        return policyOwnerValidatedBusinessAbnNumberError
      }
      return null
    }

    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{addIndividualHeaderLabel}</A11yLabel>
          <Form id={FORM_ID} aria-labelledby="application-overview">
            <FullWidthInput
              htmlFor="companyName"
              name="companyName"
              label={businessOwnerCompanyNameLabel}
              changeHandler={this.handleChange}
              placeholder={businessOwnerCompanyNamePlaceholder}
              error={companyName.error.error}
              caption={companyName.error.error && businessOwnerCompanyNameError}
              value={companyName.value}
            />
            <FullWidthAbnLookup
              htmlFor="abn"
              name="abn"
              label={businessOwnerABNLabel}
              changeHandler={this.handleChange}
              placeholder={businessOwnerAbnPlaceHolder}
              error={isAbnCancelled || abn.error.error || error}
              caption={handleAbnCaption()}
              value={abn.value}
            />
            <FullWidthAddressLookup
              toggleHandler={this.addressToggleHandler('isManualResidential')}
              name="policyOwnerResidentialAddress"
              auto={{
                label: policyOwnerDetailsResidentialAddressFieldLabel,
                placeholder: policyOwnerDetailsResidentialAddressFieldPlaceholder,
                toggleLabel: policyOwnerDetailsResidentialAddressToggleToManualText,
                value: residentialAddress.value,
                selectChangeHandler: this.addressChange,
                addressError: residentialAddress.error.error,
                addressName: 'residentialAddress',
                addressErrorMessage:
                  residentialAddress.error.error && policyOwnerDetailsResidentialAddressFieldError,
              }}
              manual={{
                streetLabel: policyOwnerDetailsResidentialAddressStreetFieldLabel,
                streetPlaceholder: policyOwnerDetailsResidentialAddressStreetFieldPlaceholder,
                streetValue: residentialAddressStreet.value,
                streetRequiredErrorMessage: residentialAddressStreet.error.errorMsg,
                streetError: residentialAddressStreet.error.error,
                streetName: 'residentialAddressStreet',
                houseNoLabel: policyOwnerDetailsResidentialAddressHouseNoFieldLabel,
                houseNoPlaceholder: policyOwnerDetailsResidentialAddressHouseNoFieldPlaceholder,
                houseNoValue: residentialAddressHouseNo.value,
                houseNoName: 'residentialAddressHouseNo',
                houseNoError: residentialAddressHouseNo.error.error,
                houseNoRequiredErrorMessage: residentialAddressHouseNo.error.errorMsg,
                localityLabel: policyOwnerDetailsResidentialAddressLocalityFieldLabel,
                localityPlaceholder: policyOwnerDetailsResidentialAddressLocalityFieldPlaceholder,
                localityLabelValue: residentialAddressLocality.value,
                localityError: residentialAddressLocality.error.error,
                localityRequiredErrorMessage: residentialAddressLocality.error.errorMsg,
                localityName: 'residentialAddressLocality',
                stateLabel: policyOwnerDetailsResidentialAddressStateFieldLabel,
                statePlaceholder: policyOwnerDetailsResidentialAddressStateFieldPlaceholder,
                stateOptions: schema.residentialAddressState.options,
                stateValue: residentialAddressState.value,
                stateError: residentialAddressState.error.error,
                stateRequiredErrorMessage: residentialAddressState.error.errorMsg,
                stateName: 'residentialAddressState',
                countryLabel: policyOwnerDetailsResidentialAddressCountryFieldLabel,
                countryPlaceholder: policyOwnerDetailsResidentialAddressCountryFieldPlaceholder,
                countryValue: residentialAddressCountry.value,
                countryError: residentialAddressCountry.error.error,
                countryRequiredErrorMessage: residentialAddressCountry.error.errorMsg,
                countryName: 'residentialAddressCountry',
                postCodeLabel: policyOwnerDetailsResidentialAddressPostCodeFieldLabel,
                postCodePlaceholder: policyOwnerDetailsResidentialAddressPostCodeFieldPlaceholder,
                postCodeValue: residentialAddressPostCode.value,
                postCodeError: residentialAddressPostCode.error.error,
                postCodeRequiredErrorMessage: residentialAddressPostCode.error.errorMsg,
                postCodeName: 'residentialAddressPostCode',
                manualToggleLabel: policyOwnerDetailsResidentialAddressToggleToAutoText,
                inputEntryHandler: this.handleChange,
              }}
            />

            <FullWidthInput
              htmlFor="email"
              name="email"
              label={policyOwnerDetailsEmailFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsEmailFieldPlaceholder}
              error={email.error.error}
              caption={email.error.error && policyOwnerDetailsEmailFieldError}
              value={email.value}
            />

            <InputPhone
              inputPhoneNumber={inputContactNumber}
              selectPhoneCode={selectContactNumberCode}
            />

            <SoleDirectorCheckbox
              text={businessOwnerSoleDirectorCheckboxLabel}
              name="isSoleDirector"
              htmlFor="isSoleDirector"
              onChangeHandler={this.toggleSoleDirector}
              checked={isSoleDirector.value}
            />
            {this.renderDirector()}

            {directors.error.error && (
              <ErrorAddDirector>
                <Caption withIcon error>
                  {policyOwnerAddDirectorOnBusinessError}
                </Caption>
              </ErrorAddDirector>
            )}
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  addressLookup,
  abnLookup,
  captureCreditCard,
  createQuote,
  sidebar,
  masterList,
}) => ({
  forms,
  form: forms[FORM_ID],
  paymentDetailsForm: forms[paymentDetailsSchemaFormID],
  isManualPostal:
    addressLookup.policyOwnerPostalAddress && addressLookup.policyOwnerPostalAddress.isManual,
  isManualResidential:
    addressLookup.policyOwnerResidentialAddress &&
    addressLookup.policyOwnerResidentialAddress.isManual,
  abnLookup,
  captureCreditCard,
  createQuote,
  sidebar,
  countriesPhoneCode: masterList.data.countryCodeList,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessOwnerDetails)
