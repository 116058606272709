// @flow
export const transformAgenciesDataToDropdownOption = (agencies: array<Object>): array<Object> => {
  if (agencies && Array.isArray(agencies) && agencies[0]) {
    return agencies.reduce((acc, agency) => {
      const { customerNumber, firstName, surname, organisationName, bancsCustomerNo } = agency
      const name = organisationName || `${surname}, ${firstName}`
      return [
        ...acc,
        {
          label: `${name} - ${customerNumber}`,
          value: bancsCustomerNo,
        },
      ]
    }, [])
  }
  return agencies
}

export const getActiveAdviser = (
  agencies: array<Object>,
  currentBancsCustomerNo: String
): object => {
  if (agencies && Array.isArray(agencies) && agencies[0]) {
    return agencies.find(agency => agency.bancsCustomerNo === currentBancsCustomerNo)
  }
  return agencies
}

export const sortAgencies = (agencies: Array<Object>): Array<Object> =>
  agencies.sort((a, b) => {
    const item1 = (a.surname || a.organisationName || '').toLowerCase()
    const item2 = (b.surname || b.organisationName || '').toLowerCase()
    return item1 > item2 ? 1 : -1
  })
