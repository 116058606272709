/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconPlus32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5.5a1.5 1.5 0 0 1 3 0v20a1.5 1.5 0 0 1-3 0v-20z"
      fill="#000"
    />
    <path
      className="path2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 15.5A1.5 1.5 0 0 1 6.5 14h20a1.5 1.5 0 0 1 0 3h-20A1.5 1.5 0 0 1 5 15.5z"
      fill="#000"
    />
  </Icon>
)

export default IconPlus32
