import { PREFERRED_YES } from './policies'

export const MLC_SUPER = 'MLC Superannuation Trustee Structures'
export const SMSF = 'SMSF (External Trustee)'
export const WRAP_PAYMENT = 'EMA'
export const OWR = 'OWR'
export const LB = 'LB'
export const ROLE_LIFE_ASSURED = 'LA'

export const PRODUCT_TYPE = [
  { value: 'LC_MLCI_S_MLC', prodCode: 'MLCI' },
  { value: 'LC_MLCI_NS_MLC', prodCode: 'MLCIS' },
]

export const ACTION_ADD = 'ADD'
export const ACTION_UPDATE = 'UPDATE'
export const ACTION_REMOVE = 'REMOVE'
export const TOTAL_PERCENTAGE = 100
export const ONE_BENEFICIARY = 1
export const THREE_BENEFICIARIES = 3
export const SIX_BENEFICIARIES = 6
export const PERCENTAGE_REGEX = '^[1-9][0-9]?$|^100$'
export const RELATIONSHIP_LEGAL_REP = 'LGLRPSNT'
export const MINIMUM_AMOUNT_LB_TO_SHOW_SPLIT_MODAL = 1

export const newBeneficiary = {
  action: 'ADD',
  roleCode: 'LB',
  percentageAllocation: '0',
  status: 'ACT',
  type: 'NBND',
  relatedParty: {
    partyType: 'PER',
    fatcacrsentity: {
      fatcaCheck: 'N',
      crsCheck: 'Y',
    },
    companyIdentifier: 'NGRP',
    isSmoker: 'Unknown',
    gender: 'Unknown',
    lifeStatus: 'ALV',
    contactMethods: {
      addresses: [
        {
          houseNo: '',
          street: '',
          locality: '',
          state: '',
          country: '',
          postCode: '',
          addressType: 'S',
          preferred: PREFERRED_YES,
        },
      ],
    },
  },
}
