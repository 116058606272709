/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconSearch16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M10.6 6.8a3.8 3.8 0 0 1-3.8 3.8v2a5.8 5.8 0 0 0 5.8-5.8h-2zm-3.8 3.8A3.8 3.8 0 0 1 3 6.8H1a5.8 5.8 0 0 0 5.8 5.8v-2zM3 6.8A3.8 3.8 0 0 1 6.8 3V1A5.8 5.8 0 0 0 1 6.8h2zM6.8 3a3.8 3.8 0 0 1 3.8 3.8h2A5.8 5.8 0 0 0 6.8 1v2zm2.593 7.807l3.9 3.9 1.414-1.414-3.9-3.9-1.414 1.414z"
      fill="#000"
    />
  </Icon>
)

export default IconSearch16
