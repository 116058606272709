// @flow
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { SITECORE_API } from '../types/sitecoreApi'
import { SITECORE_API_BASE } from '../../constants/apis'
import { MASTERLIST_FETCH } from '../types/masterList'

export const getMasterList =
  (isTriggerRequired?: boolean = false, queuedActionCallback) =>
  (dispatch: Function, getState: Function) => {
    const masterList = get(getState(), 'masterList.data', {})

    const isMasterDataAvailable = !isEmpty(masterList)
    // only trigger if the data doesn't exist in redux store or the user needs to
    if (isTriggerRequired || !isMasterDataAvailable) {
      dispatch({
        type: SITECORE_API,
        name: MASTERLIST_FETCH,
        verb: 'GET',
        route: `${SITECORE_API_BASE}/masterdata`,
        callback: (err, dataReceived) => ({
          ...dataReceived,
          data: dataReceived.data.reduce(
            (obj, item) => ({
              ...obj,
              ...item,
            }),
            {}
          ),
        }),
        queuedAction: queuedActionCallback || null,
      })
    }
  }
