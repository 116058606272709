// @flow
import React, { Component } from 'react'

import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// redux imports
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import Select, { type SelectOptionType } from '../../../../atoms/Select'
import Heading from '../../../../atoms/Heading'

// constants.
import { POLICY_PRODUCT_CODE_SUPER } from '../../../../../constants/policies'

// helpers.
import { errorCheck } from '../../../../../utils/formUtils'

// schema.
import SCHEMA, { FORM_ID } from './selectSuper.schema'

// styles.
import styles from './selectSuper.styles'

const Wrapper = styled('div')(styles.wrapper)
const Section = styled('section')(styles.section)
const HeaderWrapper = styled('div')(styles.headerWrapper)

type SelectSuperProps = {
  // Action creators.
  actions: {
    // Action creator to close the global sidebar.
    closeSidebar: Function,
    // Action creator to update the quote entity in createQuote.
    createQuoteSetPolicyStructure: Function,
  },
  // To render all labels from sitecore content editor
  fields: Object,
  // React router methods.
  productRules: Object,
  // forms
  form: Object,
  renderFoot: () => React.ReactElement,
}
class SelectSuper extends Component<SelectSuperProps> {
  componentWillMount() {
    const {
      actions: { formInit },
    } = this.props
    formInit(FORM_ID, SCHEMA)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate },
      fields: { choseCoverSupPolicyTrusteError, choseCoverSupPolicyFundError },
    } = this.props
    const data = {
      error: errorCheck(
        value,
        SCHEMA[name].condition,
        name === 'paymentInstructions'
          ? choseCoverSupPolicyFundError.value
          : choseCoverSupPolicyTrusteError.value
      ),
      value,
    }
    // if "owned by" is updated, reset the payment methods
    if (name === 'paymentInstructions') {
      formUpdateField(FORM_ID, 'fundDetails', {
        error: '',
        value: '',
      })
      const tagEvent = createEvent({
        GA: {
          category: 'inside super policy details',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'inside super policy details',
          },
        },
      })
      tagEvent.end()
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, SCHEMA)
  }

  createOptionOwner = (paymentMethods): Array<SelectOptionType> => {
    if (!paymentMethods) {
      return []
    }
    // append `allowablePaymentMethods` array to option objects so we can look
    // them up to populate the payment method Select.
    return paymentMethods.map(
      ({ superFundName, superFundDisplayName, allowablePaymentMethods }) => ({
        label: superFundDisplayName || superFundName,
        value: superFundName,
        allowablePaymentMethods,
      })
    )
  }

  createOptionDerived = (allowablePaymentMethods): Array<SelectOptionType> => {
    if (!allowablePaymentMethods) {
      return []
    }
    return allowablePaymentMethods.map(({ value, code }) => ({
      label: value,
      value: code,
    }))
  }

  render() {
    const { productRules, fields, form, renderFoot } = this.props
    if (!productRules || !form || !form.fields) return ''
    const { paymentInstructions, fundDetails } = form.fields
    const superProduct = productRules.find(
      product => product.productId === POLICY_PRODUCT_CODE_SUPER
    )
    const {
      choseCoverSupPolicyHeading,
      choseCoverSupPolicyLabel,
      choseCoverSupPolicyTrusteLabel,
      choseCoverSupPolicyPlaceholder,
      choseCoverSupPolicyFundFrmLabel,
    } = fields
    return (
      <Wrapper>
        <Section>
          <p>
            <Text field={choseCoverSupPolicyHeading} />
          </p>
        </Section>
        <HeaderWrapper>
          <Heading size="5">
            <Text field={choseCoverSupPolicyLabel} />
          </Heading>
        </HeaderWrapper>
        <Select
          label={choseCoverSupPolicyTrusteLabel.value}
          placeholder={choseCoverSupPolicyPlaceholder.value}
          name="paymentInstructions"
          id="paymentInstructions"
          error={paymentInstructions.error.error}
          value={paymentInstructions.value}
          changeHandler={this.handleChange}
          options={
            typeof superProduct.paymentMethods !== 'undefined' &&
            this.createOptionOwner(superProduct.paymentMethods)
          }
        />
        <Select
          label={choseCoverSupPolicyFundFrmLabel.value}
          name="fundDetails"
          placeholder={choseCoverSupPolicyPlaceholder.value}
          id="fundDetails"
          value={fundDetails.value}
          error={fundDetails.error.error}
          changeHandler={this.handleChange}
          options={this.createOptionDerived(
            !paymentInstructions.value ? [] : paymentInstructions.value.allowablePaymentMethods
          )}
        />
        {renderFoot && renderFoot()}
      </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({ productRules, forms }) => ({
  productRules: productRules.data,
  form: forms[FORM_ID],
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectSuper)
