/* eslint-disable no-useless-escape */
// @flow
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import { pathOr } from 'lodash/fp'
import { toast } from 'react-toastify'

// utils
import { browserOrigin } from '../../utils/browserUtils'
import { getRole } from '../../utils/middlewareUtils'
import { generateCorrelationID } from '../../utils/commonUtils'

// constants.
import { SITECORE_API } from '../types/sitecoreApi'
import {
  FETCH_CREDIT_CARD_INFO,
  FETCH_NAB_DATA,
  FETCH_NAB_DATA_SUCCESS,
  FETCH_NAB_DATA_ERROR,
  DISPLAY_CREDIT_CARD_LISTING,
} from '../types/captureCreditCard'
import { SITECORE_QUOTEAPPLY_BASE } from '../../constants/apis'
import {
  TOAST_ID_CAPTURE_CREDITCARD,
  TOAST_TITLE_CAPTURE_CREDITCARD_SUCCESS,
  TOAST_TITLE_CAPTURE_CREDITCARD_ERROR,
} from '../../constants/toast'

export const checkIsNabResponseValid = (nabDataField: string, nabQueryData: Object): boolean => {
  let isNabResponseValid = true

  // handle incorrect timestamp form data
  if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_TIMESTAMP') !== -1) {
    isNabResponseValid = false
  }
  // handle incorrect fingerprint form data
  else if (
    typeof nabDataField === 'string' &&
    (nabDataField.indexOf('EPS_FINGERPRINT') !== -1 || nabDataField.indexOf('fingerprint') !== -1)
  ) {
    isNabResponseValid = false
  }
  // handle incorrect card number form data
  else if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_CARDNUMBER') !== -1) {
    isNabResponseValid = false
  }
  // handle incorrect expiry month form data
  else if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_EXPIRYMONTH') !== -1) {
    isNabResponseValid = false
  }
  // handle incorrect expiry year form data
  else if (typeof nabDataField === 'string' && nabDataField.indexOf('EPS_EXPIRYYEAR') !== -1) {
    isNabResponseValid = false
  }
  // handle actual nab response whether the token is generated or not
  else if (nabQueryData && nabQueryData.strescode !== '00') {
    isNabResponseValid = false
  }

  return isNabResponseValid
}

const createFormData = (nabData: Object, ccData: Object) => {
  const getExpiryMonth = (
    moment(pathOr(-1, 'fields.expiryDate.value', ccData), 'MM/YY').month() + 1
  ).toString()
  const getExpiryYear = moment(pathOr('NA', 'fields.expiryDate.value', ccData), 'MM/YY')
    .year()
    .toString()
    .substr(-2)
  const getCardNumber = pathOr('', 'fields.cardNumber.value', ccData).replace(/\s/g, '')
  const relativeUrl = pathOr('', 'resultUrl', nabData).replace(/^(?:\/\/|[^\/]+)*\//, '')
  const urlOrigin = browserOrigin()

  const newFormData = new FormData()
  newFormData.append('EPS_MERCHANT', pathOr('', 'merchantId', nabData))
  newFormData.append('EPS_TXNTYPE', pathOr('', 'transactType', nabData))
  newFormData.append('EPS_REFERENCEID', pathOr('', 'referenceId', nabData))
  newFormData.append('EPS_TIMESTAMP', pathOr('', 'timestamp', nabData))
  newFormData.append('EPS_FINGERPRINT', pathOr('', 'fingerprint', nabData))
  newFormData.append('EPS_RESULTURL', `${urlOrigin}/${relativeUrl}`)
  newFormData.append('EPS_REDIRECT', pathOr('', 'redirect', nabData))
  newFormData.append('EPS_RESULTPARAMS', pathOr('', 'resultParams', nabData))
  newFormData.append('EPS_STORE', pathOr('', 'store', nabData))
  newFormData.append('EPS_STORETYPE', pathOr('', 'storeType', nabData))
  newFormData.append('EPS_CARDNUMBER', getCardNumber)
  newFormData.append('EPS_EXPIRYMONTH', getExpiryMonth)
  newFormData.append('EPS_EXPIRYYEAR', getExpiryYear)

  return newFormData
}

export const fetchNabStarted = () => (dispatch: Function) => {
  dispatch({
    type: FETCH_NAB_DATA,
  })
}

export const fetchNabSuccess = (data: Object) => (dispatch: Function) => {
  dispatch({
    type: FETCH_NAB_DATA_SUCCESS,
    payload: data,
  })
}

export const fetchNabError = () => (dispatch: Function) => {
  dispatch({
    type: FETCH_NAB_DATA_ERROR,
  })
}

export const fetchNabData = (nabData: Object) => (dispatch: Function, getState: Function) => {
  dispatch(fetchNabStarted())

  const {
    forms: { creditCard, custCreditCardPayment },
  } = getState()

  const role = getRole()
  let formId = {}
  if (role === 'Customer') {
    formId = custCreditCardPayment
  } else if (role === 'Adviser') {
    formId = creditCard
  }

  // form post url
  const transactUrl = pathOr('', 'transacUrl', nabData)

  // form post data
  const bodyFormData = createFormData(nabData, formId)

  // form post config
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    },
  }

  const axiosPromise = axios
    .post(transactUrl, bodyFormData, config)
    .then(result => {
      const getResponseHeaders = result.headers

      const nabResponseUrl =
        getResponseHeaders['x-redirect-uri'] || pathOr('', 'request.responseURL', result)
      const responseData = pathOr(null, 'data', result)

      // actual nab response will return as query parameter in the url
      const parsedNabResponseUrl = queryString.parse(nabResponseUrl.split('?')[1])

      // handle NAB response here and check for valid nab response
      if (
        !checkIsNabResponseValid(responseData, parsedNabResponseUrl) ||
        nabResponseUrl === transactUrl
      ) {
        dispatch(fetchNabError())
        // return false, as NAB transact didn't success
        return {
          isNABSuccess: false,
        }
      }

      dispatch(fetchNabSuccess(parsedNabResponseUrl))
      return {
        isNABSuccess: true,
      }
    })
    .catch(() => {
      dispatch(fetchNabError())
      return {
        isNABSuccess: false,
      }
    })

  return axiosPromise
}

export const fetchCreditCardInfo =
  (callback: Function = () => {}, showSuccessToast = true) =>
  (dispatch: Function, getState: Function) => {
    const {
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()
    let captureCardInfoIsSuccess = false

    // dispatch the action to call the api.
    dispatch({
      type: SITECORE_API,
      name: FETCH_CREDIT_CARD_INFO,
      verb: 'GET',
      route: `${SITECORE_QUOTEAPPLY_BASE}/Fingerprint/Store?referenceId=${generateCorrelationID()}&portal=${getRole()}`,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      callback: (err, dataReceived) => {
        if (dataReceived && dataReceived.data) {
          dispatch(fetchNabData(dataReceived.data)).then(result => {
            if (result.isNABSuccess) {
              captureCardInfoIsSuccess = true
              if (showSuccessToast) {
                toast(TOAST_TITLE_CAPTURE_CREDITCARD_SUCCESS, {
                  toastId: TOAST_ID_CAPTURE_CREDITCARD,
                })
              }
              callback({ captureCardInfoIsSuccess })
            } else {
              toast.error(TOAST_TITLE_CAPTURE_CREDITCARD_ERROR, {
                toastId: TOAST_ID_CAPTURE_CREDITCARD,
              })
            }
            return { captureCardInfoIsSuccess, dataReceived }
          })
        }
        return { captureCardInfoIsSuccess, dataReceived }
      },
    })
  }

export const displayCreditCardListing =
  (hasShownCreditCardListing: boolean) => (dispatch: Function) => {
    dispatch({
      type: DISPLAY_CREDIT_CARD_LISTING,
      payload: hasShownCreditCardListing,
    })
  }
