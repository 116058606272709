/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconAddCircle16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 4C8.3156 4 8.57143 4.25584 8.57143 4.57143V7.42857H11.4286C11.7442 7.42857 12 7.68441 12 8C12 8.31559 11.7442 8.57143 11.4286 8.57143H8.57143V11.4286C8.57143 11.7442 8.31559 12 8 12C7.6844 12 7.42857 11.7442 7.42857 11.4286V8.57143H4.57143C4.25583 8.57143 4 8.31559 4 8C4 7.68441 4.25583 7.42857 4.57143 7.42857H7.42857V4.57143C7.42857 4.25584 7.6844 4 8 4Z"
      fill="#000"
    />
  </Icon>
)

export default IconAddCircle16
