/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { A11yLabel, SidebarActionItem, Checkbox, Input } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// component.
import InputPhone from '../../../../molecules/InputPhone'
import AddressLookUp from '../../../../molecules/AddressLookUp'
import MemberList from '../../../MemberList'

// styles.
import styles from './smsfTrustee.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

// schema.
import SCHEMA, { FORM_ID } from './smsfTrustee.schema'
import { FORM_ID as SMSF_DETAILS_FORM_ID } from '../SmsfDetails/smsfDetails.schema'

// helpers.
import { errorCheck, getValue, generateFieldsFromData } from '../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { initForms } from './smsfTrusteeInitUtils'
import ToggleGroup from '../../../../molecules/ToggleGroup'
import { getActivePolicy } from '../../../../../utils/quoteUtils'
import {
  POLICY_RELATIONSHIPS_LIFEASSURED,
  POLICY_MEMBER_INDIVIDUAL,
  POLICY_MEMBER_DIRECTOR,
  POLICY_MEMBER_COMPANY,
} from '../../../../../constants/policies'

type SmsfTrusteeProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
    formUpdate: (id: string, fields: Function) => void,
  },
  // Address lookup for company address
  isManualCompanyAddress: boolean,
  // form sub-store
  form: Object,
  // Sitecore authorable fields
  fields: Object,
  // Address lookup manual entry redux state mapped to props.
  isManualPostal: boolean,
  isManualResidential: boolean,
  handleNewClient: Function,
  createQuote: Object,
  sidebar: Object,
  smsfDetailsForm: Object,
}

export const RESIDENTIAL_ADDRESS = [
  'residentialAddress',
  'residentialAddressStreet',
  'residentialAddressHouseNo',
  'residentialAddressLocality',
  'residentialAddressState',
  'residentialAddressPostCode',
]
export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalAddressStreet',
  'postalAddressHouseNo',
  'postalAddressLocality',
  'postalAddressState',
  'postalAddressPostCode',
]

const DisabledFullWidthInput = styled(Input)(({ disabled }) => styles.disabledInput(disabled))
const FullWidthCheckbox = ignoreColumns(Checkbox)
const AddressFullWidth = ignoreColumns(AddressLookUp)
const Fieldset = styled('div')(styles.fieldset)
const InputFullWidth = styled(Input)(styles.fullWidth)
const Form = styled('form')(styles.base)
const Wrapper = styled('div')(styles.wrapper)
const SoleDirectorCheckbox = styled(FullWidthCheckbox)(styles.soleDirectorCheckbox)
const ErrorAddDirector = styled('div')(styles.errorAddDirector)
const FullWidthToggleGroup = ignoreColumns(ToggleGroup)
const SidebarItem = styled(SidebarActionItem)(styles.sidebarItem)
const AddPresonSection = styled('section')(styles.addPresonSection)

export class SmsfTrustee extends Component<SmsfTrusteeProps> {
  componentWillMount() {
    const {
      actions,
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
      form,
      sidebar,
      fields,
    } = this.props

    if (!sidebar.panelProps) return
    const { formInit } = actions
    const schema = SCHEMA({
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
      trustee: '',
      fields,
    })

    if (!form) {
      const data = initForms(this.props)
      formInit(FORM_ID, schema, data)
    }
  }

  componentDidUpdate() {
    const {
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
      form,
      actions: { formInit },
      sidebar,
      fields,
    } = this.props
    const schema = SCHEMA({
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
      ...form.fields,
      fields,
    })
    if (!form && sidebar.currentPanel === 1) {
      const data = initForms(this.props)
      formInit(FORM_ID, schema, data)
    }
  }

  toggleSoleDirector = ({ value, name }) => {
    const {
      actions: { formUpdateField, formUpdateValidations },
      form: {
        fields: {
          directors: { value: directorsValue },
        },
        fields,
      },
      fields: labels,
    } = this.props
    formUpdateField(FORM_ID, name, { value })

    if (value && directorsValue && directorsValue.length > 1) {
      const firstDirector = directorsValue.find(
        director => director.relatedParty.companyRole === POLICY_MEMBER_DIRECTOR
      )
      formUpdateField(FORM_ID, 'directors', {
        error: { error: false },
        value: firstDirector ? [firstDirector] : [],
      })
    } else if (directorsValue && directorsValue.length) {
      formUpdateField(FORM_ID, 'directors', {
        error: { error: false },
        value: directorsValue,
      })
    }
    formUpdateValidations(
      FORM_ID,
      SCHEMA({
        ...fields,
        isSoleDirector: { value },
        fields: labels,
      })
    )
  }

  // handle address change
  addressChange = address => {
    const { name, data, value } = address
    const { actions, isManualCompanyAddress, isManualPostal, isManualResidential, form, fields } =
      this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = SCHEMA({
      isManualCompanyAddress,
      isManualPostal,
      isManualResidential,
      ...form.fields,
      fields,
    })
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { actions, isManualPostal, isManualResidential, isManualCompanyAddress, form, fields } =
      this.props
    const { formUpdateField, formValidate } = actions

    const schema = SCHEMA({
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
      ...form.fields,
      fields,
    })
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addDirectorClickHandler = () => {
    const {
      actions: { setPanelProps, setSubPanel },
    } = this.props
    const { handleNewClient } = this.props
    setSubPanel(0)
    handleNewClient()
    setPanelProps({
      directorIdentifier: {
        type: 'REF_NO',
        value: '',
      },
    })
  }

  editDirectorHandler = member => () => {
    const {
      actions: { setPanelProps, setSubPanel },
      handleNewClient,
    } = this.props
    setPanelProps({ directorIdentifier: member.identifiers[0] })
    setSubPanel(0)
    handleNewClient()
  }

  deleteDirectorHandler = member => () => {
    const {
      form: {
        fields: { directors },
      },
      actions: { formUpdateField },
    } = this.props

    const copyOfDirectors = [...directors.value]
    const filteredDirector = copyOfDirectors.filter(
      director =>
        !director.relatedParty.identifiers.some(i => i.value === member.identifiers[0].value)
    )
    formUpdateField(FORM_ID, 'directors', {
      error: { error: false },
      value: filteredDirector,
    })
  }

  addRoleToExistingMember =
    ({ identifier, activeAbnNumber }) =>
    () => {
      const {
        actions: { setPanelProps, setSubPanel },
      } = this.props
      const { handleNewClient } = this.props
      setSubPanel(1)
      handleNewClient()
      setPanelProps({
        individualIdentifier: identifier,
        activeAbnNumber,
      })
    }

  addRoleToLifeAssured = relatedParty => e => {
    e.preventDefault()
    const {
      actions: { formUpdateField },
      form: {
        fields: { individuals },
      },
    } = this.props
    const alreadyAddedTrustees = [
      ...(individuals.value ? individuals.value : []),
      {
        relatedParty,
        isLifeAssured: true,
        isPrimary: !individuals.value.length,
      },
    ]
    formUpdateField(FORM_ID, 'individuals', {
      error: { error: false },
      value: [...alreadyAddedTrustees],
    })
  }

  editIndividualHandler = member => () => {
    const {
      actions: { setPanelProps, setSubPanel },
      handleNewClient,
    } = this.props
    setSubPanel(1)
    setPanelProps({ individualIdentifier: member.identifiers[0] })
    handleNewClient()
  }

  deleteIndividualHandler = member => () => {
    const {
      form: {
        fields: { individuals },
      },
      actions: { formUpdateField },
    } = this.props

    const copyOfIndividuals = [...individuals.value]
    const filteredIndividuals = copyOfIndividuals.filter(
      individual =>
        !individual.relatedParty.identifiers.some(i => i.value === member.identifiers[0].value)
    )
    if (filteredIndividuals[0]) {
      filteredIndividuals[0].isPrimary = true
    }
    formUpdateField(FORM_ID, 'individuals', {
      error: { error: false },
      value: filteredIndividuals,
    })
  }

  updatePolicyOwnerHandler = member => () => {
    const {
      form: {
        fields: { individuals },
      },
      actions: { formUpdateField },
    } = this.props
    const copyOfIndividials = individuals.value
      .map(individual => {
        if (member.identifiers[0].value === individual.relatedParty.identifiers[0].value)
          individual.isPrimary = true
        else individual.isPrimary = false
        return individual
      })
      .sort(a => (a.isPrimary ? -1 : 1))
    formUpdateField(FORM_ID, 'individuals', {
      error: { error: false },
      value: copyOfIndividials,
    })
  }

  renderDirector() {
    const {
      fields,
      form: {
        fields: { directors, isSoleDirector },
      },
    } = this.props
    const { addDirectorLabel, addDirectorListCaption } = reduceAuthorableFields(fields)
    // TODO: memberName should be mapped from redux store
    const members = []
    if (directors.value) {
      directors.value.forEach(director => {
        members.push({
          name: `${director.relatedParty.firstName} ${director.relatedParty.lastName}`,
          identifiers: director.relatedParty.identifiers,
          role: director.relatedParty.companyRole,
        })
      })
    }

    return (
      <Fragment>
        {!!members.length && (
          <MemberList
            caption={addDirectorListCaption}
            members={members}
            editHandler={this.editDirectorHandler}
            removeHandler={this.deleteDirectorHandler}
            referMember
          />
        )}
        <SidebarItem
          title={addDirectorLabel}
          clickHandler={this.addDirectorClickHandler}
          disabled={
            !(
              (isSoleDirector.value && !members.length) ||
              (!isSoleDirector.value && members.length < 5)
            )
          }
        />
      </Fragment>
    )
  }

  renderIndividual() {
    const {
      fields,
      sidebar,
      form: {
        fields: { individuals },
      },
      createQuote: { quotes, activeIndex },
      fields: { addPolicySetAsPrimaryOwnerActionLabel, addPolicyTrusteePrimaryTrusteeLabel },
    } = this.props
    const { policyStructure } = quotes[activeIndex]
    const policyInstanceNo = getActivePolicy(sidebar)
    const currentPolicy = policyStructure.find(
      policy => policy.policyInstanceNo === policyInstanceNo
    )
    const { addDirectorListCaption } = reduceAuthorableFields(fields)
    // TODO: memberName should be mapped from redux store
    const members = []
    if (individuals.value) {
      individuals.value.forEach(({ relatedParty = {}, isPrimary, isLifeAssured }) => {
        members.push({
          name: `${relatedParty.firstName} ${relatedParty.lastName}`,
          identifiers: relatedParty.identifiers,
          role: 'Trustee',
          isPrimary,
          isLifeAssured,
        })
      })
    }
    return (
      <Fragment>
        {!!members.length && (
          <MemberList
            caption={addDirectorListCaption}
            members={members}
            editHandler={this.editIndividualHandler}
            removeHandler={this.deleteIndividualHandler}
            referMember
            fields={{
              addPolicyOwnerActionLabel: addPolicySetAsPrimaryOwnerActionLabel,
              primaryOwnerLabel: addPolicyTrusteePrimaryTrusteeLabel,
            }}
            updatePolicyOwnerHandler={this.updatePolicyOwnerHandler}
          />
        )}
        {currentPolicy &&
          currentPolicy.relationships &&
          currentPolicy.relationships.map(relationship => {
            const isCompany = get(relationship, 'companies[0].directors[0]')

            if (
              !isCompany &&
              relationship.role.includes(POLICY_RELATIONSHIPS_LIFEASSURED) &&
              !members.some(member => member.isLifeAssured)
            ) {
              return (
                <SidebarItem
                  key={`${
                    isCompany
                      ? relationship.companies[0].relatedParty.businessName
                      : `${relationship.relatedParty.firstName}-${relationship.relatedParty.lastName}`
                  }`}
                  title={
                    isCompany
                      ? `Add ${relationship.companies[0].relatedParty.businessName}`
                      : `Add ${relationship.relatedParty.firstName} ${relationship.relatedParty.lastName} ${fields.addLifeInsuredLabelSuffix.value}`
                  }
                  clickHandler={this.addRoleToLifeAssured(relationship.relatedParty)}
                />
              )
            }
          })}
        <SidebarItem
          title="Add trustee"
          clickHandler={this.addRoleToExistingMember({
            identifier: '',
          })}
          disabled={individuals.value.length >= 4}
        />
      </Fragment>
    )
  }

  render() {
    const {
      fields,
      actions: { formUpdateField },
      form,
      smsfDetailsForm,
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
    } = this.props

    if (!form) return null

    const {
      companyName,
      companyEmail,
      companyAddress,
      companyHouseNo,
      companyStreet,
      companyLocality,
      companyState,
      companyCountry,
      companyPostCode,
      isSoleDirector,
      directors,
      individuals,
      trustee,
      contactNumberCode,
      contactNumber,
    } = form.fields
    const { regulatory } = smsfDetailsForm.fields

    const {
      addIndividualHeaderLabel,
      businessOwnerCompanyNameLabel,
      businessOwnerCompanyNamePlaceholder,
      businessOwnerCompanyNameError,
      businessOwnerCompanyDetailsEmailFieldLabel,
      businessOwnerCompanyDetailsEmailFieldPlaceholder,
      businessOwnerCompanyDetailsEmailFieldError,
      businessOwnerCompanyAddressToggleToManualText,
      businessOwnerCompanyAddressFieldLabel,
      businessOwnerCompanyAddressFieldPlaceholder,
      businessOwnerCompanyAddressFieldError,
      businessOwnerCompanyAddressStreetFieldPlaceholder,
      businessOwnerCompanyAddressHouseNoFieldPlaceholder,
      businessOwnerCompanyAddressLocalityFieldPlaceholder,
      businessOwnerCompanyAddressStateFieldPlaceholder,
      businessOwnerCompanyAddressCountryFieldPlaceholder,
      businessOwnerCompanyAddressPostCodeFieldPlaceholder,
      businessOwnerCompanyAddressToggleToAutoText,
      policyOwnerAddDirectorOnSMSFError,
      policyOwnerAddIndividualTrusteeError,
      policyOwnerTrusteeBusinessOrIndividualFieldLabel,
      policyOwnerTrusteeBusinessOrIndividualPlaceholder,
      policyOwnerTrusteeBusinessOrIndividualFieldError,
      policyOwnerCompanyTrusteeExecutedForAndOnBehalfOf,
      // director
      businessOwnerSoleDirectorCheckboxLabel,
      trusteePhoneNumberLabel,
      phoneCodeLabel,
    } = reduceAuthorableFields(fields)

    const schema = SCHEMA({
      isManualPostal,
      isManualResidential,
      isManualCompanyAddress,
      ...form.fields,
      fields,
    })

    const isTrusteeBusiness = trustee.value !== POLICY_MEMBER_INDIVIDUAL
    const isApraRegulated = regulatory.value === 'APRA'

    if (isApraRegulated && trustee.value !== POLICY_MEMBER_COMPANY) {
      formUpdateField(FORM_ID, 'trustee', {
        error: { error: false },
        value: POLICY_MEMBER_COMPANY,
      })
    }

    const selectPrimaryPhoneCode = {
      label: phoneCodeLabel,
      value: contactNumberCode.value.value,
      name: 'contactNumberCode',
      id: 'contactNumberCode',
      changeHandler: this.handleChange,
    }
    const inputPrimaryPhoneNumber = {
      htmlFor: 'contactNumber',
      name: 'contactNumber',
      label: trusteePhoneNumberLabel,
      changeHandler: this.handleChange,
      value: contactNumber.value,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && contactNumber.error.errorMsg,
    }

    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{addIndividualHeaderLabel}</A11yLabel>
          <Form id={FORM_ID} aria-labelledby="smsf-trustee">
            {!isApraRegulated && (
              <FullWidthToggleGroup
                labelSize="sm"
                htmlFor="trustee"
                variant="tab"
                label={policyOwnerTrusteeBusinessOrIndividualFieldLabel}
                placeholder={policyOwnerTrusteeBusinessOrIndividualPlaceholder}
                name="trustee"
                labelledby="client-label"
                handleChange={this.handleChange}
                options={
                  directors.value.length || individuals.value.length
                    ? schema.trustee.options.map(opt => ({ ...opt, disabled: true }))
                    : schema.trustee.options
                }
                value={trustee.value}
                error={trustee.error.error}
                caption={trustee.error.error && policyOwnerTrusteeBusinessOrIndividualFieldError}
              />
            )}
            {trustee.value
              ? isTrusteeBusiness && (
                  <Fragment>
                    <p>{policyOwnerCompanyTrusteeExecutedForAndOnBehalfOf} </p>

                    <DisabledFullWidthInput
                      htmlFor="companyName"
                      name="companyName"
                      label={businessOwnerCompanyNameLabel}
                      changeHandler={this.handleChange}
                      placeholder={businessOwnerCompanyNamePlaceholder}
                      error={companyName.error.error}
                      caption={companyName.error.error && businessOwnerCompanyNameError}
                      value={companyName.value}
                    />
                    <Fieldset role="group">
                      <AddressFullWidth
                        name="companyAddressField"
                        auto={{
                          label: businessOwnerCompanyAddressFieldLabel,
                          placeholder: businessOwnerCompanyAddressFieldPlaceholder,
                          toggleLabel: businessOwnerCompanyAddressToggleToManualText,
                          value: companyAddress.value,
                          selectChangeHandler: this.addressChange,
                          addressError: companyAddress.error.error,
                          addressName: 'companyAddress',
                          addressErrorMessage:
                            companyAddress.error.error && businessOwnerCompanyAddressFieldError,
                        }}
                        manual={{
                          streetPlaceholder: businessOwnerCompanyAddressStreetFieldPlaceholder,
                          streetValue: companyStreet.value,
                          streetRequiredErrorMessage: companyStreet.error.errorMsg,
                          streetError: companyStreet.error.error,
                          streetName: 'companyStreet',
                          houseNoPlaceholder: businessOwnerCompanyAddressHouseNoFieldPlaceholder,
                          houseNoValue: companyHouseNo.value,
                          houseNoError: companyHouseNo.error.error,
                          houseNoRequiredErrorMessage: companyHouseNo.error.errorMsg,
                          houseNoName: 'companyHouseNo',
                          localityPlaceholder: businessOwnerCompanyAddressLocalityFieldPlaceholder,
                          localityLabelValue: companyLocality.value,
                          localityError: companyLocality.error.error,
                          localityRequiredErrorMessage: companyLocality.error.errorMsg,
                          localityName: 'companyLocality',
                          statePlaceholder: businessOwnerCompanyAddressStateFieldPlaceholder,
                          stateOptions: schema.companyState.options,
                          stateValue: companyState.value,
                          stateError: companyState.error.error,
                          stateRequiredErrorMessage: companyState.error.errorMsg,
                          stateName: 'companyState',
                          countryPlaceholder: businessOwnerCompanyAddressCountryFieldPlaceholder,
                          countryValue: companyCountry.value,
                          countryError: companyCountry.error.error,
                          countryRequiredErrorMessage: companyCountry.error.errorMsg,
                          countryName: 'companyCountry',
                          postCodePlaceholder: businessOwnerCompanyAddressPostCodeFieldPlaceholder,
                          postCodeValue: companyPostCode.value,
                          postCodeError: companyPostCode.error.error,
                          postCodeRequiredErrorMessage: companyPostCode.error.errorMsg,
                          postCodeName: 'companyPostCode',
                          manualToggleLabel: businessOwnerCompanyAddressToggleToAutoText,
                          inputEntryHandler: this.handleChange,
                        }}
                      />
                    </Fieldset>
                    <Fieldset role="group">
                      <InputFullWidth
                        htmlFor="companyEmail"
                        name="companyEmail"
                        label={businessOwnerCompanyDetailsEmailFieldLabel}
                        changeHandler={this.handleChange}
                        placeholder={businessOwnerCompanyDetailsEmailFieldPlaceholder}
                        value={companyEmail.value}
                        error={companyEmail.error.error}
                        caption={
                          companyEmail.error.error && businessOwnerCompanyDetailsEmailFieldError
                        }
                      />
                    </Fieldset>
                    <InputPhone
                      selectPhoneCode={selectPrimaryPhoneCode}
                      inputPhoneNumber={inputPrimaryPhoneNumber}
                    />
                    <SoleDirectorCheckbox
                      text={businessOwnerSoleDirectorCheckboxLabel}
                      name="isSoleDirector"
                      htmlFor="isSoleDirector"
                      onChangeHandler={this.toggleSoleDirector}
                      checked={isSoleDirector.value}
                    />
                  </Fragment>
                )
              : ''}
            <AddPresonSection>
              {trustee.value &&
                (isTrusteeBusiness ? this.renderDirector() : this.renderIndividual())}
            </AddPresonSection>
            {directors.error &&
              directors.error.error &&
              trustee.value !== POLICY_MEMBER_INDIVIDUAL && (
                <ErrorAddDirector>{policyOwnerAddDirectorOnSMSFError}</ErrorAddDirector>
              )}
            {individuals.error &&
              individuals.error.error &&
              trustee.value === POLICY_MEMBER_INDIVIDUAL && (
                <ErrorAddDirector>{policyOwnerAddIndividualTrusteeError}</ErrorAddDirector>
              )}
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  addressLookup,
  abnLookup,
  createQuote,
  sidebar,
  masterList,
}) => ({
  forms,
  form: forms[FORM_ID],
  smsfDetailsForm: forms[SMSF_DETAILS_FORM_ID],
  isManualPostal:
    addressLookup.policyOwnerPostalAddress && addressLookup.policyOwnerPostalAddress.isManual,
  isManualResidential:
    addressLookup.policyOwnerResidentialAddress &&
    addressLookup.policyOwnerResidentialAddress.isManual,
  isManualCompanyAddress:
    addressLookup.companyAddressField && addressLookup.companyAddressField.isManual,
  abnLookup,
  createQuote,
  sidebar,
  countriesPhoneCode: masterList.data.countryCodeList,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SmsfTrustee)
