/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconSettings16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.286 1.536l1.905 1.16a5.96 5.96 0 0 1 1.045-.433L6.763.095a8.06 8.06 0 0 1 2.474 0l.527 2.168c.364.112.714.258 1.045.434l1.905-1.161c.67.49 1.26 1.08 1.75 1.75l-1.16 1.905c.175.331.32.681.433 1.045l2.168.527a8.06 8.06 0 0 1 0 2.474l-2.168.527a5.96 5.96 0 0 1-.434 1.045l1.161 1.905c-.49.67-1.08 1.26-1.75 1.75l-1.905-1.16a5.96 5.96 0 0 1-1.045.433l-.527 2.168a8.06 8.06 0 0 1-2.474 0l-.527-2.168a5.963 5.963 0 0 1-1.045-.434l-1.905 1.161a8.048 8.048 0 0 1-1.75-1.75l1.16-1.905a5.957 5.957 0 0 1-.433-1.045L.095 9.237a8.06 8.06 0 0 1 0-2.474l2.168-.527a5.96 5.96 0 0 1 .434-1.045L1.536 3.286c.49-.67 1.08-1.26 1.75-1.75zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
      fill="#000"
    />
  </Icon>
)

export default IconSettings16
