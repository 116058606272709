// @flow
import get from 'lodash/get'
import {
  IA_LINKED_SGBO_PRODUCT_IDS,
  INCOME_ASSURE_PRODUCT_IDS,
  CRITICAL_ILLNESS_COVER_ID_LIST,
  LIFE_COVER_ID_LIST,
  POLICY_BENEFIT_HEP,
  POLICY_BENEFIT_ADB,
} from '../constants/policies'
import { HEP_MAX_SUM_INSURED, ADB_MAX_SUM_INSURED } from '../constants/benefit'

// utils
import { getChildInPolicy } from './quoteUtils'

export const mergeAltsInPolicyStructure = (policyStructure, altsPolicyStructure) =>
  policyStructure.map(policy => {
    const currentPolicy = altsPolicyStructure.find(
      altsPolicy => altsPolicy.bancsPolicyNo === policy.bancsPolicyNo
    )
    return {
      ...policy,
      ...(currentPolicy.alteration && { alteration: currentPolicy.alteration }),
    }
  })

// Finding linked policy here assumes that a policy can be connected
// to a single policy and not multiple
export const getLinkedPolicyInstanceNo = policy => {
  let linkedPolicyInstanceNo
  policy.covers.some(cover => {
    if (
      cover.parentBenefitReference &&
      String(cover.parentBenefitReference.parentPolicyReferenceNo) !==
        String(policy.policyInstanceNo)
    ) {
      linkedPolicyInstanceNo = cover.parentBenefitReference.parentPolicyReferenceNo
      return true
    }
    if (cover.childBenefitReferences) {
      return cover.childBenefitReferences.some(childBenefit => {
        if (String(childBenefit.childPolicyReferenceNo) !== String(policy.policyInstanceNo)) {
          linkedPolicyInstanceNo = childBenefit.childPolicyReferenceNo
          return true
        }
        return false
      })
    }
    return false
  })
  return linkedPolicyInstanceNo
}

/**
 * Find the SGBO benefits in the policyStructure,
 * whenever the type of the cover is Income assure / +
 * @returns Benefits array containing only SGBO benefits
 */
export const getLinkedSgboBenefits = (type, childBenefits = [], policies) =>
  INCOME_ASSURE_PRODUCT_IDS.includes(type)
    ? childBenefits
        .filter(({ childBenefitType }) => IA_LINKED_SGBO_PRODUCT_IDS.includes(childBenefitType))
        .map(({ childPolicyReferenceNo, childBenefitType }) =>
          get(
            policies.find(
              ({ policyInstanceNo: pInstanceNo }) =>
                childPolicyReferenceNo.toString() === pInstanceNo.toString()
            ),
            'covers',
            []
          ).find(c => c.type === childBenefitType)
        )
    : []

// get linked benefit data for a parent, to be used to update child sum insured when
// parent sum insured is changed
export const getLinkedBenefitForSumInsured = (cover, policy) => {
  const { type } = cover
  let childCoverType
  let maxSumInsured
  if (CRITICAL_ILLNESS_COVER_ID_LIST.includes(type)) {
    childCoverType = POLICY_BENEFIT_HEP
    maxSumInsured = HEP_MAX_SUM_INSURED
  } else if (LIFE_COVER_ID_LIST.includes(type)) {
    childCoverType = POLICY_BENEFIT_ADB
    maxSumInsured = ADB_MAX_SUM_INSURED
  }
  const linkedBenefit = childCoverType && getChildInPolicy(cover, policy, childCoverType)
  if (linkedBenefit) {
    return {
      linkedBenefit,
      maxSumInsured,
    }
  }
  return null
}
