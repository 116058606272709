/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTwitter32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.753 12.077C24.753 18.083 20.216 25 11.925 25 9.369 25 6.997 24.255 5 22.962c.363.042.712.055 1.088.055a8.995 8.995 0 0 0 5.599-1.94 4.521 4.521 0 0 1-4.216-3.15c.28.042.558.07.85.07.406 0 .812-.056 1.188-.154a4.536 4.536 0 0 1-3.616-4.458v-.057c.6.337 1.3.55 2.038.577a4.547 4.547 0 0 1-2.01-3.783c0-.842.224-1.618.615-2.292a12.783 12.783 0 0 0 9.297 4.753 5.112 5.112 0 0 1-.113-1.04C15.72 9.04 17.731 7 20.23 7c1.298 0 2.471.548 3.295 1.435a8.842 8.842 0 0 0 2.86-1.096 4.524 4.524 0 0 1-1.981 2.503A9.027 9.027 0 0 0 27 9.138a9.742 9.742 0 0 1-2.261 2.349c.014.197.014.393.014.59z"
      fill="#000"
    />
  </Icon>
)

export default IconTwitter32
