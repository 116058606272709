// @flow

type alterationsModalMessagesType = Array<{
  code: string,
  message: string,
  title?: string,
}>

export const getAlterationModalMsgObj = (
  alterationsModalMessages: alterationsModalMessagesType = [],
  code: string
) => alterationsModalMessages.find(item => item.code === code)

export const getAlterationModalMsg = (
  alterationsModalMessages: alterationsModalMessagesType = [],
  code: string
) => (getAlterationModalMsgObj(alterationsModalMessages, code) || {}).message
