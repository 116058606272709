// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { A11yLabel, Checkbox, Input, Select } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

import AddressLookUp from '../../../../molecules/AddressLookUp'
import InputPhone from '../../../../molecules/InputPhone'

// styles.
import styles from './addTrustee.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'

// schema.
import SCHEMA, { FORM_ID } from './addTrustee.schema'
import { FORM_ID as SMSF_TRUSTEE_FORM_ID } from '../SmsfTrustee/smsfTrustee.schema'

// helpers.
import { errorCheck, generateFieldsFromData, getValue } from '../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'
import { initForms } from './addTrusteeInitUtils'

// constants
import { PREFERRED_YES } from '../../../../../constants/policies'

type addDirectorProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  smsfTrusteeDetailsForm: Object,
  // Sitecore authorable fields
  fields: Object,
  // Address lookup manual entry redux state mapped to props.
  isManualPostal: boolean,

  sidebarPanelProps: Object,
}

type addDirectorState = {
  showAdditionalContact: boolean,
}

export const POSTAL_ADDRESS = [
  'postalAddress',
  'postalAddressStreet',
  'postalAddressHouseNo',
  'postalAddressLocality',
  'postalAddressState',
  'postalAddressCountry',
  'postalAddressPostCode',
]

const FullWidthInput = ignoreColumns(Input)
const HalfWidthInput = styled(Input)(styles.halfColumn)
const FullWidthAddressLookup = ignoreColumns(AddressLookUp)
const FullWidthCheckbox = ignoreColumns(Checkbox)

const Form = styled('form')(styles.base)
const Wrapper = styled('div')()
const TitleSelect = styled(Select)(styles.title)

const SameAsCompanyAddressCheckbox = styled(FullWidthCheckbox)(styles.sameAsCompanyAddressCheckbox)
const ButtonContainer = styled('div')(({ secondary }) => styles.buttonContainer(secondary))
const MoreOptionButton = styled('button')(styles.button)
const ContactNumberContainer = styled('div')(styles.contactNumberContainer)

export class AddDirector extends Component<addDirectorProps, addDirectorState> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)

    const {
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldFutureDateError,
      policyOwnerDetailsDateOfBirthFieldFormatError,
      policyOwnerDetailsDateOfBirthFieldAgeRangeError,
      policyOwnerDetailsPrimaryNumberError,
      addDirectorStreetAddressFieldError,
      addDirectorCityFieldError,
      addDirectorStateFieldError,
      policyOwnerDetailsPostalAddressCountryFieldError,
      addDirectorPostCodeError,
      policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage,
      policyOwnerDetailsResidentialAddressValidStreetFieldError,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError,
      policyOwnerDetailsResidentialAddressValidStateFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError,
      policyOwnerDetailsResidentialAddressValidPostalFieldError,
    } = props.fields

    this.schemaWithAuthorableFields = SCHEMA({
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldFutureDateError,
      policyOwnerDetailsDateOfBirthFieldFormatError,
      policyOwnerDetailsDateOfBirthFieldAgeRangeError,
      policyOwnerDetailsPrimaryNumberError,
      addDirectorStreetAddressFieldError,
      addDirectorCityFieldError,
      addDirectorStateFieldError,
      policyOwnerDetailsPostalAddressCountryFieldError,
      addDirectorPostCodeError,
      policyOwnerDetailsResidentialAddressHouseNoFieldErrorMessage,
      policyOwnerDetailsResidentialAddressValidStreetFieldError,
      policyOwnerDetailsResidentialAddressValidLocalityFieldError,
      policyOwnerDetailsResidentialAddressValidStateFieldError,
      policyOwnerDetailsResidentialAddressCountryFieldInvalidError,
      policyOwnerDetailsResidentialAddressValidPostalFieldError,
    })

    // value of showAdditionalContact state
    const {
      smsfTrusteeDetailsForm: {
        fields: { individuals },
      },
      sidebarPanelProps: { individualIdentifier },
    } = props
    const selectedIndividual =
      individuals.value &&
      individualIdentifier &&
      individuals.value.find(
        ({ relatedParty = {} }) =>
          relatedParty.identifiers &&
          relatedParty.identifiers.some(
            identifier => identifier.value === individualIdentifier.value
          )
      )
    let showAdditionalContact = false
    if (selectedIndividual) {
      const { relatedParty } = selectedIndividual
      const primaryPhoneIndex = relatedParty.contactMethods.phones.findIndex(
        phone => phone.preferred === PREFERRED_YES
      )
      const secondaryNumber =
        get(relatedParty, 'contactMethods.phones.length') === 1
          ? ''
          : get(relatedParty, `contactMethods.phones[${primaryPhoneIndex === 0 ? 1 : 0}].number`)
      if (secondaryNumber) {
        showAdditionalContact = true
      }
    }

    this.state = {
      showAdditionalContact,
    }
  }

  componentWillMount() {
    const { actions, isManualPostal } = this.props
    const { formInit } = actions
    const data = initForms(this.props)
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      sameAsCompanyAddress: data.sameAsCompanyAddress,
      address: data.address,
    })
    formInit(FORM_ID, schema, data)
  }

  toggleAddress = ({ value, name }) => {
    const {
      actions: { formUpdateField, formValidate, setAdddressToManual },
      form: {
        fields: {
          sameAsCompanyAddress: { value: isSameAddress },
        },
      },
    } = this.props
    const { isManualPostal } = this.props
    // Set postal manual state to false if hiding postal details
    if (value && name === 'sameAsCompanyAddress') {
      setAdddressToManual('address', false)
    }
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
      isSameAddress,
    })
    const data = {
      error: errorCheck(value, schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { actions, isManualPostal } = this.props
    const { formUpdateField, formValidate } = actions

    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
    })
    if (schema[name].onChangeCondition) {
      const charactersCheck = errorCheck(
        value,
        schema[name].onChangeCondition,
        schema[name].errorMsg
      )
      if (charactersCheck.error) return
    }
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  addressChange = address => {
    const { name, data, value } = address
    const { actions, isManualPostal } = this.props
    const { formUpdateField, formUpdate, formValidate } = actions
    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
    })
    const field = {
      error: errorCheck(value, schema[name].condition, schema[name].errorMsg),
      value: getValue(value),
    }
    formUpdateField(FORM_ID, name, field)
    formUpdate(FORM_ID, generateFieldsFromData(data))
    formValidate(FORM_ID, schema)
  }

  toggleShowAdditionalContact = () => {
    this.setState(prevState => ({
      showAdditionalContact: !prevState.showAdditionalContact,
    }))
  }

  render() {
    const { fields, form, isManualPostal } = this.props
    const { showAdditionalContact } = this.state

    if (!form) return null

    const {
      title,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      email,
      // Address fields
      sameAsCompanyAddress,
      address,
      addressUnit,
      addressStreet,
      addressLocality,
      addressState,
      addressCountry,
      addressPostCode,
      contactNumber,
      contactNumberPhoneCode,
      secondaryNumber,
      secondaryNumberPhoneCode,
    } = form.fields

    const {
      addIndividualHeaderLabel,
      policyOwnerDetailsTitleFieldLabel,
      policyOwnerDetailsTitleFieldPlaceholder,
      policyOwnerDetailsTitleFieldError,
      policyOwnerDetailsFirstNameFieldLabel,
      policyOwnerDetailsFirstNameFieldPlaceholder,
      policyOwnerDetailsFirstNameFieldError,
      policyOwnerDetailsMiddleNameFieldLabel,
      policyOwnerDetailsMiddleNameFieldPlaceholder,
      policyOwnerDetailsLastNameFieldLabel,
      policyOwnerDetailsLastNameFieldPlaceholder,
      policyOwnerDetailsLastNameFieldError,
      policyOwnerDetailsDateOfBirthFieldError,
      policyOwnerDetailsDateOfBirthFieldLabel,
      policyOwnerDetailsDateOfBirthFieldPlaceholder,
      policyOwnerDetailsEmailFieldLabel,
      policyOwnerDetailsEmailFieldPlaceholder,
      policyOwnerDetailsEmailFieldError,
      // Address
      sameAsCompanyAddressCheckboxLabel,
      policyOwnerDetailsPostalAddressToggleToManualText,
      addDirectorAddressFieldLabel,
      addDirectorAddressFieldPlaceholder,
      policyOwnerDetailsPostalAddressFieldError,
      addDirectorStreetAddressFieldPlaceholder,
      addDirectorUnitAddressFieldPlaceholder,
      addDirectorCityFieldPlaceholder,
      addDirectorStateFieldPlaceholder,
      addDirectorPostCodeFieldPlaceholder,
      policyOwnerDetailsPostalAddressCountryFieldLabel,
      policyOwnerDetailsPostalAddressCountryFieldPlaceholder,
      policyOwnerDetailsPostalAddressToggleToAutoText,
      policyOwnerDetailsPrimaryNumberFieldLabel,
      policyOwnerDetailsSecondaryNumberFieldLabel,
      phoneCodeLabel,
      policyOwnerDetailsAddAdditionalContact,
      policyOwnerDetailsRemoveAdditionalContact,
    } = reduceAuthorableFields(fields)

    const schema = this.schemaWithAuthorableFields({
      isManualPostal,
    })

    const selectPrimaryPhoneCode = {
      label: phoneCodeLabel,
      value: contactNumberPhoneCode.value,
      name: 'contactNumberPhoneCode',
      id: 'contactNumberPhoneCode',
      changeHandler: this.handleChange,
    }
    const inputPrimaryPhoneNumber = {
      htmlFor: 'contactNumber',
      name: 'contactNumber',
      label: policyOwnerDetailsPrimaryNumberFieldLabel,
      changeHandler: this.handleChange,
      value: contactNumber.value,
      error: contactNumber.error.error,
      caption: contactNumber.error.error && contactNumber.error.errorMsg,
    }
    const selectSecondaryPhoneCode = {
      label: phoneCodeLabel,
      value: secondaryNumberPhoneCode.value,
      name: 'secondaryNumberPhoneCode',
      id: 'secondaryNumberPhoneCode',
      changeHandler: this.handleChange,
    }
    const inputSecondaryPhoneNumber = {
      name: 'secondaryNumber',
      label: policyOwnerDetailsSecondaryNumberFieldLabel,
      changeHandler: this.handleChange,
      value: secondaryNumber.value,
      error: secondaryNumber.error.error,
      caption: secondaryNumber.error.error && fields.lifeInsuranceSecondaryNumberError,
    }

    return (
      <Fragment>
        <Wrapper>
          <A11yLabel> {addIndividualHeaderLabel} </A11yLabel>
          <Form id={FORM_ID}>
            <TitleSelect
              label={policyOwnerDetailsTitleFieldLabel}
              placeholder={policyOwnerDetailsTitleFieldPlaceholder}
              value={title.value}
              name="title"
              id="title"
              error={title.error.error}
              caption={title.error.error && policyOwnerDetailsTitleFieldError}
              changeHandler={this.handleChange}
              options={schema.title.options}
            />
            <HalfWidthInput
              htmlFor="firstName"
              name="firstName"
              label={policyOwnerDetailsFirstNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsFirstNameFieldPlaceholder}
              error={firstName.error.error}
              caption={firstName.error.error && policyOwnerDetailsFirstNameFieldError}
              value={firstName.value}
            />
            <HalfWidthInput
              htmlFor="middleName"
              name="middleName"
              label={policyOwnerDetailsMiddleNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsMiddleNameFieldPlaceholder}
              value={middleName.value}
            />
            <FullWidthInput
              htmlFor="lastName"
              name="lastName"
              label={policyOwnerDetailsLastNameFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsLastNameFieldPlaceholder}
              error={lastName.error.error}
              caption={lastName.error.error && policyOwnerDetailsLastNameFieldError}
              value={lastName.value}
            />
            <HalfWidthInput
              htmlFor="dateOfBirth"
              name="dateOfBirth"
              options={{
                date: true,
              }}
              label={policyOwnerDetailsDateOfBirthFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsDateOfBirthFieldPlaceholder}
              error={dateOfBirth.error.error}
              caption={dateOfBirth.error.error && policyOwnerDetailsDateOfBirthFieldError}
              value={dateOfBirth.value}
            />
            <FullWidthInput
              htmlFor="email"
              name="email"
              label={policyOwnerDetailsEmailFieldLabel}
              changeHandler={this.handleChange}
              placeholder={policyOwnerDetailsEmailFieldPlaceholder}
              error={email.error.error}
              caption={email.error.error && policyOwnerDetailsEmailFieldError}
              value={email.value}
            />
            <SameAsCompanyAddressCheckbox
              text={sameAsCompanyAddressCheckboxLabel}
              name="sameAsCompanyAddress"
              htmlFor="sameAsCompanyAddress"
              onChangeHandler={this.toggleAddress}
              checked={sameAsCompanyAddress.value}
            />
            {!sameAsCompanyAddress.value && (
              <FullWidthAddressLookup
                name="address"
                auto={{
                  label: addDirectorAddressFieldLabel,
                  placeholder: addDirectorAddressFieldPlaceholder,
                  toggleLabel: policyOwnerDetailsPostalAddressToggleToManualText,
                  value: address.value,
                  selectChangeHandler: this.addressChange,
                  addressError: address.error.error,
                  addressName: 'address',
                  addressErrorMessage:
                    address.error.error && policyOwnerDetailsPostalAddressFieldError,
                }}
                manual={{
                  streetLabel: addDirectorStreetAddressFieldPlaceholder,
                  streetPlaceholder: addDirectorStreetAddressFieldPlaceholder,
                  streetValue: addressStreet.value,
                  streetRequiredErrorMessage: addressStreet.error.errorMsg,
                  streetError: addressStreet.error.error,
                  streetName: 'addressStreet',
                  houseNoLabel: addDirectorUnitAddressFieldPlaceholder,
                  houseNoPlaceholder: addDirectorUnitAddressFieldPlaceholder,
                  houseNoValue: addressUnit.value,
                  houseNoError: addressUnit.error.error,
                  houseNoRequiredErrorMessage: addressUnit.error.errorMsg,
                  houseNoName: 'addressUnit',
                  localityLabel: addDirectorCityFieldPlaceholder,
                  localityPlaceholder: addDirectorCityFieldPlaceholder,
                  localityLabelValue: addressLocality.value,
                  localityError: addressLocality.error.error,
                  localityRequiredErrorMessage: addressLocality.error.errorMsg,
                  localityName: 'addressLocality',
                  stateLabel: addDirectorStateFieldPlaceholder,
                  statePlaceholder: addDirectorStateFieldPlaceholder,
                  stateOptions: schema.addressState.options,
                  stateValue: addressState.value,
                  stateError: addressState.error.error,
                  stateRequiredErrorMessage: addressState.error.errorMsg,
                  stateName: 'addressState',
                  countryLabel: policyOwnerDetailsPostalAddressCountryFieldLabel,
                  countryPlaceholder: policyOwnerDetailsPostalAddressCountryFieldPlaceholder,
                  countryValue: addressCountry.value,
                  countryError: addressCountry.error.error,
                  countryRequiredErrorMessage: addressCountry.error.errorMsg,
                  countryName: 'addressCountry',
                  postCodeLabel: addDirectorPostCodeFieldPlaceholder,
                  postCodePlaceholder: addDirectorPostCodeFieldPlaceholder,
                  postCodeValue: addressPostCode.value,
                  postCodeError: addressPostCode.error.error,
                  postCodeRequiredErrorMessage: addressPostCode.error.errorMsg,
                  postCodeName: 'addressPostCode',
                  manualToggleLabel: policyOwnerDetailsPostalAddressToggleToAutoText,
                  inputEntryHandler: this.handleChange,
                }}
              />
            )}
            <ContactNumberContainer>
              <InputPhone
                selectPhoneCode={selectPrimaryPhoneCode}
                inputPhoneNumber={inputPrimaryPhoneNumber}
              />
              {!showAdditionalContact && (
                <ButtonContainer>
                  <MoreOptionButton onClick={this.toggleShowAdditionalContact}>
                    {policyOwnerDetailsAddAdditionalContact}
                  </MoreOptionButton>
                </ButtonContainer>
              )}
            </ContactNumberContainer>
            {showAdditionalContact && (
              <ContactNumberContainer>
                <InputPhone
                  selectPhoneCode={selectSecondaryPhoneCode}
                  inputPhoneNumber={inputSecondaryPhoneNumber}
                />
                <ButtonContainer secondary>
                  <MoreOptionButton onClick={this.toggleShowAdditionalContact}>
                    {policyOwnerDetailsRemoveAdditionalContact}
                  </MoreOptionButton>
                </ButtonContainer>
              </ContactNumberContainer>
            )}
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  forms,
  addressLookup,
  abnLookup,
  sidebar: { panelProps },
  createQuote,
  masterList,
}) => ({
  form: forms[FORM_ID],
  smsfTrusteeDetailsForm: forms[SMSF_TRUSTEE_FORM_ID],
  isManualPostal: addressLookup.address && addressLookup.address.isManual,
  abnLookupData: abnLookup.abnLookupData,
  sidebarPanelProps: panelProps,
  createQuote,
  countriesPhoneCode: masterList.data.countryCodeList,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDirector)
