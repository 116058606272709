// @flow
import { colours, fontSize, space } from '../../../../styles'

const cellPadding = {
  paddingBottom: space(2, true),
  paddingRight: space(2, true),
}

const styles = {
  table: {
    display: 'block',
    tableLayout: 'fixed',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },

  header: ({ isFirst }) => ({
    minWidth: isFirst ? space(8, true) : space(14, true),
    fontSize: fontSize.xxs,
    fontWeight: 'normal',
    textAlign: 'left',
    color: colours.mediumGrey,
    ...cellPadding,
  }),

  cell: {
    textAlign: 'left',
    color: colours.black,
    ...cellPadding,
  },
}

export default styles
