// @flow
import {
  ADVISOR_DETAILS_FETCH,
  ADVISOR_DETAILS_FETCH_SUCCESS,
  ADVISOR_DETAILS_FETCH_ERROR,
  ADVISOR_DETAILS_FETCH_FAILURE,
  ADVISOR_DETAILS_UPDATE,
  ADVISOR_DETAILS_UPDATE_SUCCESS,
  ADVISOR_DETAILS_UPDATE_ERROR,
  ADVISOR_DETAILS_UPDATE_FAILURE,
  ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_SUCCESS,
  RESET_ADVISOR_DATA,
  ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_INIT,
  ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_FAILURE,
  ADVISOR_BETA_TESTING_FEATURES_FETCH,
  ADVISOR_BETA_TESTING_FEATURES_FETCH_SUCCESS,
  ADVISOR_BETA_TESTING_FEATURES_FETCH_ERROR,
  ADVISOR_BETA_TESTING_FEATURES_FETCH_FAILURE,
} from '../actions/types/advisor'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

import { getBancsAgencyCodes } from '../utils/adviserUtils'

export const initialState = {
  details: null,
  advisorDetails: [],
  bancsAgencyCodes: [],
  isLoading: false,
  isUpdating: false,
  isSubmitting: false,
  hasFetchError: false,
  hasUpdateError: false,
  supportStaff: {
    isFetching: false,
    data: {},
  },
}

const advisor = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case ADVISOR_DETAILS_FETCH: {
      return {
        ...initialState,
        isSubmitting: false,
        isLoading: true,
        hasFetchError: false,
        isUpdating: false,
      }
    }
    case ADVISOR_DETAILS_FETCH_SUCCESS: {
      return {
        ...state,
        details: action.payload,
        advisorDetails: action.payload.adviserDetails,
        bancsAgencyCodes: getBancsAgencyCodes(action.payload.adviserDetails),
        isSubmitting: false,
        isLoading: false,
        isUpdating: false,
      }
    }
    case ADVISOR_DETAILS_FETCH_ERROR:
    case ADVISOR_DETAILS_FETCH_FAILURE: {
      return {
        ...state,
        details: { ...state.details },
        isSubmitting: false,
        isLoading: false,
        hasFetchError: true,
        isUpdating: false,
      }
    }
    case ADVISOR_DETAILS_UPDATE: {
      return {
        ...state,
        isSubmitting: true,
        hasUpdateError: false,
        isUpdating: true,
      }
    }
    case ADVISOR_DETAILS_UPDATE_SUCCESS: {
      return {
        ...state,
        details: action.payload,
        isSubmitting: false,
        isLoading: false,
        isUpdating: false,
      }
    }
    case ADVISOR_DETAILS_UPDATE_ERROR:
    case ADVISOR_DETAILS_UPDATE_FAILURE: {
      return {
        ...state,
        isSubmitting: false,
        hasUpdateError: true,
        isUpdating: false,
      }
    }

    case ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_INIT: {
      return {
        ...state,
        supportStaff: {
          isFetching: true,
          data: {},
        },
      }
    }

    case ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        supportStaff: {
          isFetching: false,
          data: payload,
        },
      }
    }

    case ADVISOR_RETRIEVE_SUPPORT_STAFF_LIST_FAILURE: {
      return {
        ...state,
        supportStaff: {
          isFetching: false,
          data: {},
        },
      }
    }

    // reset when user logs out.
    case RESET_ADVISOR_DATA:
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    case ADVISOR_BETA_TESTING_FEATURES_FETCH: {
      return {
        ...state,
        betaTestingFeatures: {
          featuresEnabled: [],
          isLoading: true,
          hasErrors: false,
        },
      }
    }

    case ADVISOR_BETA_TESTING_FEATURES_FETCH_SUCCESS: {
      const {
        payload: { data },
      } = action
      return {
        ...state,
        betaTestingFeatures: {
          featuresEnabled: data,
          isLoading: false,
          hasErrors: false,
        },
      }
    }

    case ADVISOR_BETA_TESTING_FEATURES_FETCH_ERROR:
    case ADVISOR_BETA_TESTING_FEATURES_FETCH_FAILURE: {
      return {
        ...state,
        betaTestingFeatures: {
          featuresEnabled: [],
          isLoading: false,
          hasErrors: true,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default advisor
