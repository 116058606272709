/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconInstagram32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.871 11.446c-.73 0-1.317-.587-1.317-1.317 0-.732.587-1.32 1.317-1.32.732 0 1.318.588 1.318 1.32 0 .73-.586 1.317-1.318 1.317zM16 12.333A3.674 3.674 0 0 0 12.333 16 3.675 3.675 0 0 0 16 19.667a3.674 3.674 0 0 0 3.666-3.668A3.673 3.673 0 0 0 16 12.333zm0 9.309a5.636 5.636 0 0 1-5.643-5.643A5.637 5.637 0 0 1 16 10.356 5.636 5.636 0 0 1 21.642 16a5.635 5.635 0 0 1-5.643 5.643zM9.513 7.422a3.488 3.488 0 0 0-1.262.83c-.386.386-.63.758-.829 1.26-.574 1.446-.444 4.883-.444 6.487 0 1.605-.13 5.041.444 6.489.199.5.443.874.83 1.259a3.47 3.47 0 0 0 1.26.831c1.446.573 4.884.445 6.487.445 1.605 0 5.041.128 6.489-.445.5-.199.874-.443 1.259-.831.388-.385.63-.759.831-1.26.573-1.447.445-4.883.445-6.488 0-1.604.128-5.04-.445-6.486a3.491 3.491 0 0 0-.831-1.262 3.481 3.481 0 0 0-1.26-.829c-1.447-.574-4.883-.444-6.488-.444-1.603 0-5.04-.13-6.486.444zM26.927 20.54c-.085 1.761-.486 3.323-1.776 4.61-1.288 1.29-2.85 1.692-4.611 1.777-1.518.087-3.021.072-4.54.072-1.519 0-3.022.015-4.54-.072-1.761-.085-3.322-.486-4.612-1.776-1.289-1.288-1.69-2.85-1.776-4.611C4.986 19.022 5 17.519 5 16c0-1.519-.015-3.022.07-4.54.087-1.761.488-3.322 1.777-4.612 1.29-1.289 2.85-1.69 4.612-1.776C12.978 4.986 14.481 5 16 5c1.519 0 3.022-.015 4.54.07 1.761.087 3.323.488 4.61 1.777 1.29 1.29 1.692 2.85 1.777 4.612.087 1.518.072 3.021.072 4.54 0 1.519.015 3.022-.072 4.54z"
      fill="#000"
    />
  </Icon>
)

export default IconInstagram32
