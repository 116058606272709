export const NAME = 'app'
export const DEFAULT_LANGUAGE = 'en'
export const NOT_FOUND_ROUTE = '/notfound'
export const SERVER_ERROR_ROUTE = '/error'
export const LAYOUT_SERVICE_LOGOUT_ITEM = '/logout'
export const SITECORE_ROUTES = [
  '/:lang([a-zA-Z]{2})/:sitecoreRoute/:policyId*',
  '/:lang([a-zA-Z]{2}-[a-zA-Z]{2})/:sitecoreRoute*',
  '/:lang([a-zA-Z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
]
export const NAV_LOGOUT_ITEM_ID = 'logout'
export const NAV_CHANGE_PASSWORD_ITEM_ID = 'change-password'
