// @flow
import { useEffect, useRef } from 'react'

const useEffectSkipMount = (callback, dependencies) => {
  const isMount = useRef(true)
  useEffect(() => {
    if (isMount.current) {
      isMount.current = false
    } else {
      callback()
    }
  }, dependencies)
}

export default useEffectSkipMount
