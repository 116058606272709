import moment from 'moment'
import {
  ADDRESS_STATUS_DFL,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_STATEMENT,
  COUNTRY_CODE,
  PREFERRED_NO,
  PREFERRED_YES,
} from '../../../../../../constants/policies'
import {
  sortBasedonDate,
  determineDistrict,
  getLatestAddressBasedOnType,
} from '../../../../../../utils/contactUtils'
import { getValue } from '../../../../../../utils/formUtils'

const HYPHEN = '-'
export const generateFormId = (formId, formIdIndex) => `${formId}${HYPHEN}${formIdIndex}`

/** Get the latest matching address type sorted based on date. */
export const getAddressBasedOnType = (addresses, addressType) => {
  const allAddresses = sortBasedonDate(
    addresses.filter(address => address.addressType === addressType),
    'creationTime'
  )
  return allAddresses && allAddresses.length ? allAddresses[0] : {}
}

export const getPreviousDay = currentDate =>
  moment(currentDate || moment(), 'DD/MM/YYYY')
    .subtract(1, 'days')
    .format('YYYY-MM-DD')

/** Return new address object with fields read from postal address form fields */
const getAddressFromPostalFields = (form, statementAddress, preferred, isPostalManual) => {
  const {
    postalAddressHouseNo,
    postalAddressStreet,
    postalAddressLocality,
    postalAddressState,
    postalAddressCountry,
    postalAddressPostCode,
    hasSecondaryAddressChanged,
    postalAddressPostalType,
    postalAddressPostalNumber,
    postalAddressPONo,
  } = form.fields

  return {
    ...statementAddress,
    houseNo: getValue(postalAddressHouseNo.value),
    street:
      postalAddressPostalType && postalAddressPostalType.value.toUpperCase() === 'PO BOX'
        ? ''
        : getValue(postalAddressStreet.value),
    locality: getValue(postalAddressLocality.value),
    state: getValue(postalAddressState.value),
    country: getValue(postalAddressCountry.value) || COUNTRY_CODE,
    postCode: getValue(postalAddressPostCode.value),
    addressStatus:
      statementAddress.addressStatus !== undefined
        ? statementAddress.addressStatus
        : ADDRESS_STATUS_DFL,
    ...(hasSecondaryAddressChanged &&
      getValue(hasSecondaryAddressChanged.value) === 'YES' && {
        houseName: '',
      }),
    district: determineDistrict(
      postalAddressPostalType,
      postalAddressPostalNumber,
      isPostalManual ? getValue(postalAddressPONo.value) : statementAddress.district
    ),
    ...(preferred && { preferred }),
  }
}

/** Return new address object with fields read from residential address form fields */
const getAddressFromResidentialFields = (
  form,
  homeAddress,
  preferred,
  isResidentialManual = false
) => {
  const {
    residentialAddressHouseNo,
    residentialAddressStreet,
    residentialAddressLocality,
    residentialAddressState,
    residentialAddressCountry,
    residentialAddressPostCode,
    hasAddressChanged,
    residentialAddressPostalType,
    residentialAddressPostalNumber,
  } = form.fields

  return {
    ...homeAddress,
    houseNo: getValue(residentialAddressHouseNo.value),
    street:
      residentialAddressPostalType && residentialAddressPostalType.value.toUpperCase() === 'PO BOX'
        ? ''
        : getValue(residentialAddressStreet.value),
    locality: getValue(residentialAddressLocality.value),
    state: getValue(residentialAddressState.value),
    country: getValue(residentialAddressCountry.value) || COUNTRY_CODE,
    postCode: getValue(residentialAddressPostCode.value),
    addressType: ADDRESS_TYPE_HOME,
    addressStatus:
      homeAddress.addressStatus !== undefined ? homeAddress.addressStatus : ADDRESS_STATUS_DFL,
    ...(hasAddressChanged &&
      getValue(hasAddressChanged.value) === 'YES' && {
        houseName: '',
      }),
    district: determineDistrict(
      residentialAddressPostalType,
      residentialAddressPostalNumber,
      isResidentialManual ? '' : homeAddress.district
    ),
    ...(preferred && { preferred }),
  }
}

/**
 * Creates a Request payload for member update for the Alterations contact form
 * @param {*} form the form field values
 * @param {*} addresses the addresses from policy
 * @returns array of addresses for payload
 */
export const getMemberUpdateRequestPayload = (
  form,
  addresses,
  currentDate,
  isResidentialManual,
  isPostalManual
) => {
  const { showPostalAddressCheckbox, hasAddressChanged, hasSecondaryAddressChanged } = form.fields
  const homeAddress = getLatestAddressBasedOnType(addresses, ADDRESS_TYPE_HOME)
  let statementAddress = getLatestAddressBasedOnType(addresses, ADDRESS_TYPE_STATEMENT)
  const addressList = []

  // If residential address field has changed, Updates only residential address
  if (hasAddressChanged && hasAddressChanged.value === 'YES') {
    // If policy doesn't have a statement address and its been added and
    // if statement address is marked preffered,
    // then mark home as non preferred, else retain default
    const preferred =
      (Object.keys(statementAddress).length === 0 &&
        hasSecondaryAddressChanged &&
        hasSecondaryAddressChanged.value === 'YES') ||
      statementAddress.preferred === PREFERRED_YES
        ? PREFERRED_NO
        : undefined
    addressList.push(
      getAddressFromResidentialFields(form, homeAddress, preferred, isResidentialManual)
    )
  }

  // statement address is not present in policy
  if (Object.keys(statementAddress).length === 0) {
    // Postal address has been added in the form
    if (hasSecondaryAddressChanged && hasSecondaryAddressChanged.value === 'YES') {
      // Empty the address list as any home addresses added are invalid
      addressList.length = 0
      // 1. Mark Residential address as Non preferred
      // 1.a Mark existing home address as redundant
      //    by setting effectiveStartDate, effectiveEndDate to a day previous to currentDate
      const redundantHomeAddress = {
        ...homeAddress,
        effectiveStartDate: getPreviousDay(currentDate),
        effectiveEndDate: getPreviousDay(currentDate),
      }
      // 1.b Add the residential address as a new non preferred address
      //    drop all fields needed for updating a party address
      const newHomeAddress = getAddressFromResidentialFields(
        form,
        homeAddress,
        PREFERRED_NO,
        isResidentialManual
      )
      delete newHomeAddress.contactRef
      delete newHomeAddress.effectiveStartDate
      delete newHomeAddress.effectiveEndDate
      delete newHomeAddress.creationTime
      // Add both redundant and new home address into addresses
      addressList.push(
        { ...redundantHomeAddress, houseName: '' },
        { ...newHomeAddress, houseName: '' }
      )

      // 2. Add the statement address as preferred
      statementAddress = getAddressFromPostalFields(
        form,
        statementAddress,
        PREFERRED_YES,
        isPostalManual
      )
      addressList.push({ ...statementAddress, addressType: ADDRESS_TYPE_STATEMENT })
    }
  } // Statement address is present in policy
  else if (hasSecondaryAddressChanged && hasSecondaryAddressChanged.value === 'YES') {
    // else, Use the postal address field if postal address field is updated
    statementAddress = getAddressFromPostalFields(form, statementAddress, undefined, isPostalManual)
    addressList.push({ ...statementAddress, addressType: ADDRESS_TYPE_STATEMENT })
  } else if (getValue(showPostalAddressCheckbox)) {
    // If UserResidentailAsPostal is true; Use residential fields as statement address
    // Retain the contact ref no of statement address to run an update again below
    const { contactRef } = statementAddress
    // Swap the preferred address as there can be only one preferred address
    const swapPreferred = { [PREFERRED_YES]: PREFERRED_NO, [PREFERRED_NO]: PREFERRED_YES }

    // If no preferred is present in homeAddress by default it is 'N'
    statementAddress = getAddressFromResidentialFields(
      form,
      homeAddress,
      swapPreferred[homeAddress.preferred || PREFERRED_NO],
      isResidentialManual
    )
    addressList.push({ ...statementAddress, contactRef, addressType: ADDRESS_TYPE_STATEMENT })
  } // else donot add any statement address in array

  return addressList
}
