/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLocationPin16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4a4.002 4.002 0 0 1-3 3.874V13H7V7.874A4.002 4.002 0 0 1 8 0a4 4 0 0 1 4 4zM8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-5.231 5.816c-.657.336-.753.578-.767.613.01.03.052.112.204.245.23.2.62.425 1.19.634C4.525 13.723 6.152 14 8 14s3.475-.277 4.605-.692c.569-.21.96-.435 1.189-.634a.887.887 0 0 0 .182-.202.231.231 0 0 0 .022-.043c-.014-.035-.11-.277-.767-.613-.691-.353-1.736-.657-3.027-.824l.258-1.984c1.429.186 2.717.535 3.68 1.027.876.448 1.858 1.218 1.858 2.392 0 .752-.418 1.342-.894 1.756-.479.416-1.114.745-1.811 1.002C11.89 15.7 10.019 16 8 16c-2.02 0-3.891-.3-5.295-.815-.697-.257-1.332-.586-1.81-1.002C.417 13.77 0 13.18 0 12.427c0-1.174.982-1.944 1.859-2.392.962-.492 2.25-.841 3.679-1.027l.258 1.984c-1.29.167-2.336.471-3.027.824zM14 12.42v-.003.003zm-12 0v-.003.003z"
      fill="#000"
    />
  </Icon>
)

export default IconLocationPin16
