// @flow
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../actions'

// styles.
import styles from './lookup.styles'

const LookUpWrapper = styled('table')(styles.wrapper)
const LookUpHeader = styled('thead')(styles.header)
const LookUpBodyWrapper = styled('tbody')(styles.bodyWrapper)
const LookUpBody = styled('tr')(styles.body)
const OccupationNameCell = styled('td')(styles.nameCell)
const OccupationHeaderCell = styled('th')(styles.nameCell)
const OccupationHeaderRankCell = styled('th')(styles.rankCell)
const OccupationRankCell = styled('td')(styles.rankCell)

type LookUpProps = {
  // To render all labels from sitecore content editor
  fields: {},
  // function to open or close occupation
  onClose: Function,
  // function to validate form and update value
  handleChange: Function,
  // Redux actions available to the component.
  actions: Object<Function>,
  // Redux props of general occupation
  generalOccupations: Array<Object>,
  // occupation form field name
  occupationFieldName: String,
  // check if secondary occupation
  isSecondaryOccupation: boolean,
  isLoading: Boolean,
}

export class LookUp extends Component<LookUpProps, {}> {
  componentWillMount() {
    const { actions } = this.props
    const { getGeneralOccupations } = actions
    getGeneralOccupations()
  }

  onSelectOfOccupation(occupation) {
    const {
      onClose,
      handleChange,
      actions: { updateGeneralOccupations, resetOccupationNames },
      occupationFieldName,
      isSecondaryOccupation,
    } = this.props
    return () => {
      const value = { label: occupation.text, value: occupation.text }
      handleChange({ value, name: occupationFieldName })
      resetOccupationNames()
      updateGeneralOccupations(occupation, isSecondaryOccupation)

      onClose()
    }
  }

  render() {
    const {
      fields: {
        captureClientGeneralOccupationName,
        captureClientGeneralOccupationLife,
        captureClientGeneralOccupationTPD,
        captureClientGeneralOccupationCI,
        captureClientGeneralOccupationIP,
        captureClientGeneralOccupationBE,
      },
      generalOccupations,
      isLoading,
    } = this.props
    const generalOccupationsWithCorrectRatings =
      generalOccupations && generalOccupations.filter(Occ => Occ.attributes.MLCI_Occ)
    const allGeneralOccupation =
      (generalOccupationsWithCorrectRatings &&
        generalOccupationsWithCorrectRatings.map(occupation => ({
          ...occupation.attributes,
          text: occupation.text,
          rating: occupation.aliases[0],
          occupationCode: occupation.attributes.OCCP_CODE,
        }))) ||
      []
    if (isLoading) return <Loader type="tab" />
    return (
      <LookUpWrapper>
        <LookUpHeader>
          <tr>
            <OccupationHeaderCell>{captureClientGeneralOccupationName}</OccupationHeaderCell>
            <OccupationHeaderRankCell>
              {captureClientGeneralOccupationLife}
            </OccupationHeaderRankCell>
            <OccupationHeaderRankCell>{captureClientGeneralOccupationTPD}</OccupationHeaderRankCell>
            <OccupationHeaderRankCell>{captureClientGeneralOccupationCI}</OccupationHeaderRankCell>
            <OccupationHeaderRankCell>{captureClientGeneralOccupationIP}</OccupationHeaderRankCell>
            <OccupationHeaderRankCell>{captureClientGeneralOccupationBE}</OccupationHeaderRankCell>
          </tr>
        </LookUpHeader>
        <LookUpBodyWrapper>
          {allGeneralOccupation.map(occupation => (
            <LookUpBody
              key={`${occupation.text}-${occupation.Life}`}
              onClick={this.onSelectOfOccupation(occupation)}
            >
              <OccupationNameCell>{occupation.text}</OccupationNameCell>
              <OccupationRankCell>{occupation.Life}</OccupationRankCell>
              <OccupationRankCell>{occupation.TPD}</OccupationRankCell>
              <OccupationRankCell>{occupation.CI}</OccupationRankCell>
              <OccupationRankCell>{occupation.IP}</OccupationRankCell>
              <OccupationRankCell>{occupation.BE}</OccupationRankCell>
            </LookUpBody>
          ))}
        </LookUpBodyWrapper>
      </LookUpWrapper>
    )
  }
}

export const mapStateToProps = ({ occupation }) => ({
  generalOccupations: occupation.generalOccupations,
  isLoading: occupation.isLoading,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LookUp)
