import get from 'lodash/get'
import moment from 'moment'

import {
  getActivePolicy,
  ALLOWED_DATE_FORMAT,
  getIdentifier,
  CLIENT_SIDE_DATE_FORMAT,
} from '../../../../../utils/quoteUtils'
import {
  getPreferredItemBreakdown,
  getFullAddressWithState,
} from '../../../../../utils/contactUtils'
import { PREFERRED_YES, COUNTRY_CODE } from '../../../../../constants/policies'
import { DEFAULT_PHONE_CODE } from '../../../../../constants/contactDetails'

export const getDataFromForm = (field, defaultValue, form) =>
  get(form, `fields[${field}].value`, defaultValue || '')

export const getMemberDetails = (policy, type = { identifier: {} }) =>
  policy &&
  policy.relationships &&
  policy.relationships.find(
    member =>
      member.relatedParty &&
      member.relatedParty.identifiers &&
      member.relatedParty.identifiers.some(
        identity => identity.value === get(type, 'identifier.value')
      )
  )
export const initForms = props => {
  const {
    createQuote: { quotes, activeIndex },
    sidebar,
    payerType,
    partyType,
  } = props

  const { policyStructure } = quotes[activeIndex]
  const activePolicyInstanceNo = getActivePolicy(sidebar)
  const activePolicy = policyStructure.find(
    policy => policy.policyInstanceNo === activePolicyInstanceNo
  )

  const payerTypeLabel = payerType ? 'panelProps.payerType' : null
  const partyTypeLabel = partyType ? 'panelProps.partyType' : null

  const relatedMember = getMemberDetails(
    activePolicy,
    get(sidebar, payerTypeLabel || partyTypeLabel)
  )
  const relatedParty = relatedMember && relatedMember.relatedParty

  const data = {
    title: getDataFromForm('title'),
    firstName: getDataFromForm('firstName'),
    middleName: getDataFromForm('middleName'),
    lastName: getDataFromForm('lastName'),
    dateOfBirth: getDataFromForm('dateOfBirth', null),
    email: getDataFromForm('email'),
    isPostalResidentialAddressSame: getDataFromForm('isPostalResidentialAddressSame', true),
    residentialAddress: getDataFromForm('residentialAddress'),
    residentialStreet: getDataFromForm('residentialStreet'),
    residentialHouseNo: getDataFromForm('residentialHouseNo'),
    residentialLocality: getDataFromForm('residentialLocality'),
    residentialState: getDataFromForm('residentialState'),
    residentialCountry: getDataFromForm('residentialCountry') || COUNTRY_CODE,
    residentialPostCode: getDataFromForm('residentialPostCode'),
    postalStreet: getDataFromForm('postalStreet'),
    postalHouseNo: getDataFromForm('postalHouseNo'),
    postalLocality: getDataFromForm('postalLocality'),
    postalState: getDataFromForm('postalState'),
    postalCountry: getDataFromForm('postalCountry'),
    postalPostCode: getDataFromForm('postalPostCode'),
    postalAddress: getDataFromForm('postalAddress'),
    contactNumber: getDataFromForm('contactNumber'),
    contactNumberPhoneCode: DEFAULT_PHONE_CODE,
    secondaryNumber: getDataFromForm('secondaryNumber'),
    secondaryNumberPhoneCode: DEFAULT_PHONE_CODE,
    secondaryContactNoCheck: !!getDataFromForm('secondaryNumber'),
  }

  if (relatedParty) {
    let primaryPhoneIndex = ''
    data.contactNumber = ''
    data.secondaryNumber = ''
    if (relatedParty.contactMethods && relatedParty.contactMethods.phones) {
      primaryPhoneIndex = relatedParty.contactMethods.phones.findIndex(
        phone => phone.preferred === PREFERRED_YES
      )
      data.contactNumber = relatedParty.contactMethods.phones[primaryPhoneIndex].number
      data.contactNumberPhoneCode = get(
        relatedParty,
        `contactMethods.phones[${primaryPhoneIndex}].idc`,
        DEFAULT_PHONE_CODE
      )
      data.secondaryNumber =
        relatedParty.contactMethods.phones.length === 1
          ? ''
          : relatedParty.contactMethods.phones[primaryPhoneIndex === 0 ? 1 : 0].number
      data.secondaryNumberPhoneCode =
        relatedParty.contactMethods.phones.length === 1
          ? DEFAULT_PHONE_CODE
          : relatedParty.contactMethods.phones[primaryPhoneIndex === 0 ? 1 : 0].idc ||
            DEFAULT_PHONE_CODE
    }
    const residentialAddressIndex = relatedParty.contactMethods.addresses.findIndex(
      address => address.preferred === PREFERRED_YES
    )

    const primaryEmailIndex =
      relatedParty.contactMethods && relatedParty.contactMethods.emails
        ? relatedParty.contactMethods.emails.findIndex(email => email.preferred === PREFERRED_YES)
        : ''
    const postalAddress =
      relatedParty.contactMethods.addresses[residentialAddressIndex === 0 ? 1 : 0]
    const residentialAddress = getPreferredItemBreakdown(relatedParty.contactMethods.addresses)
    data.title = relatedParty.title
    data.firstName = relatedParty.firstName
    data.middleName = relatedParty.middleName
    data.lastName = relatedParty.lastName
    data.dateOfBirth = moment(relatedParty.dateOfBirth, ALLOWED_DATE_FORMAT, true).format(
      CLIENT_SIDE_DATE_FORMAT
    )
    data.email =
      relatedParty.contactMethods && relatedParty.contactMethods.emails
        ? relatedParty.contactMethods.emails[primaryEmailIndex].email
        : ''
    data.isPostalResidentialAddressSame = relatedParty.contactMethods.addresses.length === 1

    data.residentialAddress = getFullAddressWithState(relatedParty.contactMethods.addresses)
    data.residentialStreet = residentialAddress.street
    data.residentialHouseNo = residentialAddress.houseNo
    data.residentialLocality = residentialAddress.locality
    data.residentialState = residentialAddress.state
    data.residentialCountry = residentialAddress.country || COUNTRY_CODE
    data.residentialPostCode = residentialAddress.postCode
    data.postalStreet = postalAddress ? postalAddress.street : ''
    data.postalHouseNo = postalAddress ? postalAddress.houseNo : ''
    data.postalLocality = postalAddress ? postalAddress.locality : ''
    data.postalState = postalAddress ? postalAddress.state : ''
    data.postalCountry = postalAddress ? postalAddress.country : ''
    data.postalPostCode = postalAddress ? postalAddress.postCode : ''
    data.postalAddress =
      postalAddress && postalAddress.postCode
        ? `${postalAddress.houseNo} ${postalAddress.street}, ${postalAddress.locality} ${postalAddress.state} ${postalAddress.country} ${postalAddress.postCode}`
        : ''
    data.secondaryContactNoCheck = !!data.secondaryNumber
    data.identifier = getIdentifier(relatedMember)
    data.partyType = relatedParty.partyType
  }
  return data
}
