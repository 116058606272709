/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconFacebook32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 8.65h-1.998c-1.568 0-1.86.778-1.86 1.903v2.498h3.731l-.496 3.915h-3.235V27h-3.897V16.966H10V13.05h3.245V10.17C13.245 6.825 15.219 5 18.097 5c1.375 0 2.56.105 2.903.158V8.65z"
      fill="#000"
    />
  </Icon>
)

export default IconFacebook32
