/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLoading16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M7 1.07L6.858.082l.142.99zM4 2h1V1H4v1zm8 12h-1v1h1v-1zm-4 0a6 6 0 0 1-6-6H0a8 8 0 0 0 8 8v-2zM8 2a6 6 0 0 1 6 6h2a8 8 0 0 0-8-8v2zm1.5 11.811A6.012 6.012 0 0 1 8 14v2c.69 0 1.36-.087 2-.252l-.5-1.937zM14 8a5.992 5.992 0 0 1-2.572 4.925l1.144 1.64A7.992 7.992 0 0 0 16 8h-2zm-2.572 4.925a6.002 6.002 0 0 1-.522.326l.97 1.748c.24-.133.472-.277.696-.434l-1.144-1.64zM2 8a5.992 5.992 0 0 1 2.572-4.925l-1.144-1.64A7.992 7.992 0 0 0 0 8h2zm2.572-4.925c.11-.077.222-.15.337-.218L3.876 1.143c-.153.093-.302.19-.448.292l1.144 1.64zm1.713-.827a5.96 5.96 0 0 1 .857-.187L6.858.08a7.96 7.96 0 0 0-1.143.25l.57 1.917zm.857-.187C7.422 2.02 7.708 2 8 2V0a8.06 8.06 0 0 0-1.142.081l.284 1.98zM0 3h4V1H0v2zm3-1v.255h2V2H3zm0 .255V6h2V2.255H3zM16 13h-4v2h4v-2zm-3 1v-.255h-2V14h2zm0-.255V10h-2v3.745h2z"
      fill="#000"
    />
  </Icon>
)

export default IconLoading16
