// helpers.
import {
  required,
  date,
  optional,
  datePast,
  firstNameLastName,
  manualAddressRegex,
  manualAddressPostalCode,
} from '../../../utils/formUtils'

// constants
import { TITLES, STATES } from '../../../constants/forms'

export const FORM_ID = 'benificiaryDetails'

const schema = (fields, isManual, ignoreAddress) => ({
  title: {
    condition: [{ condition: required }],
    options: TITLES,
  },
  firstName: {
    condition: [
      required,
      {
        condition: firstNameLastName,
        errorMsg: fields.beneficiaryDetailsInvalidFirstNameError.value,
      },
    ],
    errorMsg: fields.beneficiaryDetailFirstNameError.value,
    tooltip: '',
  },
  lastName: {
    condition: [
      required,
      {
        condition: firstNameLastName,
        errorMsg: fields.beneficiaryDetailsInvalidLastNameError.value,
      },
    ],
    errorMsg: fields.beneficiaryDetailLastNameError.value,
    tooltip: '',
  },
  dateOfBirth: {
    condition: [
      required,
      date,
      { condition: datePast, errorMsg: fields.beneficiaryDetailValidDateOfBirthErrorMsg.value },
    ],
    errorMsg: fields.beneficiaryDetailDateOfBirthError.value,
    tooltip: '',
  },
  relationship: {
    condition: required,
  },
  addressSameAsLifeInsured: {
    condition: optional,
  },
  address: {
    condition: isManual || ignoreAddress ? optional : required,
  },
  street: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.beneficiaryDetailStreetError.value,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.beneficiaryDetailInvalidStreetError.value,
          }
        : {},
    ],
  },
  houseNo: {
    condition: [
      optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.beneficiaryDetailInvalidHouseNoError.value,
          }
        : {},
    ],
  },
  locality: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.beneficiaryDetailLocalityError.value,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.beneficiaryDetailInvalidLocalityError.value,
          }
        : {},
    ],
  },
  state: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.beneficiaryDetailStateError.value,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressRegex,
            errorMsg: fields.beneficiaryDetailInvalidStateError.value,
          }
        : {},
    ],
    options: STATES,
  },
  country: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.beneficiaryDetailCountryError.value,
          }
        : optional,
    ],
  },
  postCode: {
    condition: [
      isManual
        ? {
            condition: required,
            errorMsg: fields.beneficiaryDetailPostCodeError.value,
          }
        : optional,
      isManual
        ? {
            condition: manualAddressPostalCode,
            errorMsg: fields.beneficiaryDetailInvalidPostCodeError.value,
          }
        : {},
    ],
  },
  identifiers: {
    condition: optional,
  },
})

export default schema
