/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconChevronDown16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9.586l4.293-4.293 1.414 1.414L8 12.414 2.293 6.707l1.414-1.414L8 9.586z"
      fill="#000"
    />
  </Icon>
)

export default IconChevronDown16
