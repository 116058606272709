import { space, colours, fontSize } from '../../../../../../styles'

export default {
  contentWrapper: {
    padding: space(2),
  },
  addLoadingBtn: {
    borderTop: `1px solid ${colours.lightestGrey}`,
    display: 'block',
    width: '100%',
    textAlign: 'left',
    height: 'auto',
    padding: `${space(2, true)} 0 0}`,
    span: {
      fontSize: fontSize.xs,
      paddingLeft: space(1),
    },
  },
  addLoadingIcon(disabled) {
    if (disabled) {
      return {
        color: colours.mediumGrey,
      }
    }
    return {}
  },
}
