export const KNOWLEDGE_BASE_LIST_FETCH = 'KNOWLEDGE_BASE_LIST_FETCH'
export const KNOWLEDGE_BASE_LIST_FETCH_INIT = 'KNOWLEDGE_BASE_LIST_FETCH_INIT'
export const KNOWLEDGE_BASE_LIST_FETCH_SUCCESS = 'KNOWLEDGE_BASE_LIST_FETCH_SUCCESS'
export const KNOWLEDGE_BASE_LIST_FETCH_ERROR = 'KNOWLEDGE_BASE_LIST_FETCH_ERROR'
export const KNOWLEDGE_BASE_LIST_FETCH_FAILURE = 'KNOWLEDGE_BASE_LIST_FETCH_FAILURE'

export const KNOWLEDGE_BASE_LOAD_MORE = 'KNOWLEDGE_BASE_LOAD_MORE'
export const KNOWLEDGE_BASE_FETCH_ARTICLE = 'KNOWLEDGE_BASE_FETCH_ARTICLE'

export const SET_ACTIVE_ARTICLE_ID = 'SET_ACTIVE_ARTICLE_ID'
export const SET_ACTIVE_ARTICLE_CATEGOTY_TYPE = 'SET_ACTIVE_ARTICLE_CATEGOTY_TYPE'
export const SET_ACTIVE_ARTICLE_TAG = 'SET_ACTIVE_ARTICLE_TAG'

export const KNOWLEDGE_BASE_ID = 'knowledgeBase'
