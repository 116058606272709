/* eslint-disable max-len */
import React from 'react'

const IconCreditCard = () => (
  <svg width="50" height="30" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H38C39.1046 0 40 0.895431 40 2V22C40 23.1046 39.1046 24 38 24H2C0.895431 24 0 23.1046 0 22V2Z"
      fill="#E8E5E5"
    />
    <path
      d="M3.00037 17.5C3.00037 16.6716 3.67194 16 4.50037 16H12.5004C13.3288 16 14.0004 16.6716 14.0004 17.5C14.0004 18.3284 13.3288 19 12.5004 19H4.50037C3.67194 19 3.00037 18.3284 3.00037 17.5Z"
      fill="#BDB7B7"
    />
    <path
      d="M16.0007 17.5C16.0007 16.6716 16.6723 16 17.5007 16H35.5007C36.3292 16 37.0007 16.6716 37.0007 17.5C37.0007 18.3284 36.3292 19 35.5007 19H17.5007C16.6723 19 16.0007 18.3284 16.0007 17.5Z"
      fill="#BDB7B7"
    />
    <path
      d="M3.00037 10C3.00037 8.89543 3.8958 8 5.00037 8C6.10494 8 7.00037 8.89543 7.00037 10C7.00037 11.1046 6.10494 12 5.00037 12C3.8958 12 3.00037 11.1046 3.00037 10Z"
      fill="white"
    />
  </svg>
)

export default IconCreditCard
