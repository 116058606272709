/**
 * @Description:
 *  This HOC add loader if `isLoader` prop is true.
 *
 *  @sig Function -> Function
 *  @param {Function} React Stateless Component.
 *  @returns {Function} Returns Loader Component or Children Component.
 *  @example
 *
 *  <WithLoader isLoading={isLoading}>{component}</WithLoader>
 *  //=> return loader if "isLoader" true else return children component
 *
 *  <WithLoader isLoading={isLoading} loaderProps={{ type: 'tab' }}>{component}</WithLoader>
 *  //=> return loader with different type if "isLoader" true else return children component
 */

// @flow
import React, { Fragment } from 'react'
import type { Node as ReactNode } from 'react'
import styled from '@emotion/styled'
import { Loader } from '@mlcl-digital/mlcl-design'

// styles
import styles from './withLoader.styles'

const Wrapper = styled('div')(styles.wrapper)
const Overlay = styled('div')(styles.overlay)
const ChildrenWrapper = styled('div')(styles.childrenWrapper)

type WithLoaderProps = {
  // Loading state
  isLoading: Boolean,
  // Loader props
  loaderProps: Object,
  // children
  children: ReactNode,
  // Overlay loading state
  overlay?: boolean,
  // if overlay should come over component and not complete screen
  isOverlayComponent?: boolean,
  // passing customisable children background color
  childrenBackgroundColor?: string | undefined,
}

const WithLoader = ({
  isLoading,
  loaderProps,
  children,
  overlay,
  isOverlayComponent,
  childrenBackgroundColor,
}: WithLoaderProps) => (
  <Fragment>
    {isLoading && !overlay ? (
      <Loader data-testid="loader" {...loaderProps} />
    ) : (
      <Wrapper isOverlayComponent={isOverlayComponent}>
        {overlay && isLoading && (
          <Overlay isOverlayComponent={isOverlayComponent}>
            <Loader spinnerSize={50} {...loaderProps} isLoading={isLoading} />
          </Overlay>
        )}
        <ChildrenWrapper
          isLoading={isLoading}
          isOverlayComponent={isOverlayComponent}
          childrenBackgroundColor={childrenBackgroundColor}
        >
          {children}
        </ChildrenWrapper>
      </Wrapper>
    )}
  </Fragment>
)

WithLoader.defaultProps = {
  overlay: false,
  isOverlayComponent: false,
  childrenBackgroundColor: undefined,
}

export default WithLoader
