// @flow
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// components.
import Button from '../../../../../../atoms/Button'
import Modal from '../../../../../../molecules/Modal'
import Heading from '../../../../../../atoms/Heading'
// data locators
import { LOCATOR_CONFIRM_DELETE_TEMPLATE } from '../../yourTemplates.locators'
// styles.
import styles from './deleteItem.styles'

const SubHeading = styled(Heading)(styles.subHeading)
export const DelButtonStyle = styled(Button)(styles.delTemplateItem)

type DeleteItemProps = {
  // handle modal close
  handleModalClose: Function,
  // handle delete item
  handleDeleteItem: Function,
  // is modal open
  showModal: boolean,
  // sitecore fields
  fields: {
    yourTemplatesDeleteTempLabel: string,
    yourTemplatesDeleteTempDesc: string,
    yourTemplatesDeleteTempButton: string,
  },
}

export default class DeleteItem extends PureComponent<DeleteItemProps> {
  closeModal = () => {
    const { handleModalClose } = this.props
    handleModalClose()
  }

  handleDelete = () => {
    const { handleDeleteItem } = this.props
    handleDeleteItem()
  }

  render() {
    const {
      showModal,
      fields: {
        yourTemplatesDeleteTempLabel,
        yourTemplatesDeleteTempDesc,
        yourTemplatesDeleteTempButton,
      },
    } = this.props

    return (
      <Modal
        isOpen={showModal}
        onClose={this.closeModal}
        title={yourTemplatesDeleteTempLabel.value}
      >
        <SubHeading size="6">
          <Text field={yourTemplatesDeleteTempDesc} />
        </SubHeading>
        <DelButtonStyle
          data-locator={LOCATOR_CONFIRM_DELETE_TEMPLATE}
          data-testid="modalDeleteButton"
          type="secondary"
          onClick={this.handleDelete}
        >
          <Text field={yourTemplatesDeleteTempButton} />
        </DelButtonStyle>
      </Modal>
    )
  }
}
