// @flow
// types.
import get from 'lodash/get'
// types
import {
  SAVE_ANSWERS_URE,
  INITIALIZE_DATA_URE,
  UPDATE_DATA_WITH_NEW_DETAILS_URE,
  GET_RESULTS_OF_URE,
  SAVE_URL_FOR_URE_RESULT,
  RESET_URE_DATA,
  UPDATE_URE_WITH_ALL_ACTIVE_ENQUIRY_ID,
  GET_PAYLOAD_OF_FULL_URE,
  UPDATE_ANSWERS_OF_FULL_URE,
  SET_ACTIVE_ENQUIRY_ID_OF_URE,
  UPDATE_STATUS_OF_ENQUIRY,
  UPDATE_UNDER_WRITING_METHOD,
  FETCH_FULL_URE_DATA,
  OPEN_URE_ERROR_MODAL_POPUP,
  CLOSE_URE_ERROR_MODAL_POPUP,
  RESET_FULL_URE_RESULT_SUCCESS_DATA,
  CONFIRM_SMOKER_TERMS,
  RESET_CONFIRM_SMOKER_TERMS,
  GET_DETAILS_FOR_ENQUIRY_ID,
  GET_DETAILS_FOR_ENQUIRY_ID_INIT,
} from '../types/ure'
import { EXPERIENCE_API } from '../types/experienceApi'
import { SITECORE_API } from '../types/sitecoreApi'

// constants
import {
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_URE_BASE,
  SITECORE_URE_BASE,
} from '../../constants/apis'
import { POLICY_RELATIONSHIPS_LIFEASSURED } from '../../constants/policies'
import {
  CUSTOMER_PERSONAL_STATEMENT_DECLARATION,
  CUSTOMER_TELE_UNDERWRITER_DECLARATION,
} from '../../constants/personalStatement'
import { RESULTS_PAGE } from '../../constants/application'
import { SCOPE_TYPE_TELE } from '../../constants/okta'

// components
import { preAssessmentSchema } from '../../components/organisms/CreateQuote/components/PreAssessment/PreAssessmentTab/preAssessment.schema'

// utils
import { getPreAssessmentEnquiryPayload } from '../../utils/ureUtils'
import { isBrowser } from '../../utils/browserUtils'
import history from '../../utils/browserHistory'
import { getScope } from '../../utils/cookieUtils'

// helper
import { getOutcomeEnquiry } from './createQuote'

export const saveAnswersInUre = answers => (dispatch, getState) => {
  const {
    ure: { callBackUrl },
  } = getState()
  const {
    allAnswers: { ENQUIRY_TYPE },
    enquiryId,
  } = answers

  dispatch({
    type: SAVE_ANSWERS_URE,
    payload: {
      activeEnquiryId: enquiryId,
      answers,
    },
  })

  if (callBackUrl && ENQUIRY_TYPE.indexOf('Retail') === -1) history.push(callBackUrl)
}

export const updateStatusOfEnquiry = enquiryId => ({
  type: UPDATE_STATUS_OF_ENQUIRY,
  payload: {
    enquiryId,
  },
})

export const setActiveEnquiryId = enquiryId => ({
  type: SET_ACTIVE_ENQUIRY_ID_OF_URE,
  payload: {
    enquiryId,
  },
})

export const updateAnswersOfEnquiryId = (previousAction, MLCL_SITECORE_CMS_KEY) => {
  const {
    payload: {
      data: { RETAIL: answers },
      metaData: { enquiryId },
    },
  } = previousAction
  return {
    type: EXPERIENCE_API,
    name: UPDATE_ANSWERS_OF_FULL_URE,
    verb: 'PUT',
    route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/enquiries/${enquiryId}/answers`,
    additionalHeaders: {
      CMSapikey: MLCL_SITECORE_CMS_KEY,
    },
    metaData: { answers, enquiryId },
    data: { answers },
  }
}

export const getPayloadForEnquiryId =
  (enquiryId: string) => (dispatch: Function, getState: Function) => {
    const {
      createQuote: { quotes, activeIndex, action },
      advisor: { details: advisorDetails },
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()

    let agencyCode = ''
    let agencyName = ''

    if (advisorDetails) {
      agencyCode = get(advisorDetails, 'agencyCode', 'SB054438').replace('SB', '')
      agencyName = get(advisorDetails, 'agencyName', 'Jhone Doe')
    } else {
      agencyCode = get(quotes, `[${activeIndex}].myLinkDetails.agencyCode`, 'SB054438').replace(
        'SB',
        ''
      )
      agencyName = get(quotes, `[${activeIndex}].myLinkDetails.agencyName`, 'Jhone Doe')
    }

    dispatch({
      type: SITECORE_API,
      name: GET_PAYLOAD_OF_FULL_URE,
      verb: 'POST',
      route: `${SITECORE_URE_BASE}/FullUrePayload/results`,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      metaData: { enquiryId },
      queuedAction: prevActionResp =>
        updateAnswersOfEnquiryId(prevActionResp, MLCL_SITECORE_CMS_KEY),
      data: {
        enquiryId,
        activeQuote: quotes[activeIndex],
        moreInfo: {
          uwMethod: action,
          agencyCode,
          agencyName,
        },
      },
    })
  }

export const fullUreOfCompleteOfEnquiry = (closeAction, isUreFrmCP) => (dispatch, getState) => {
  const {
    event: { payload },
  } = closeAction
  const {
    ure: { activeEnquiryIds },
  } = getState()

  const hasPayloadWithEnquiryId = enquiryId =>
    activeEnquiryIds.find(
      enquiry => enquiry.enquiryId === enquiryId && get(enquiry, 'payload.RETAIL')
    )

  const indexOfCurrentEnquiryId = activeEnquiryIds.findIndex(
    enquiry => enquiry.enquiryId === payload.enquiryId
  )
  dispatch(saveAnswersInUre(payload))
  dispatch(updateStatusOfEnquiry(payload.enquiryId))

  // enabling next action
  if (indexOfCurrentEnquiryId !== -1) {
    // finding next available enquiry ID which is not enquiried yet
    const nextAvailableUnEnquiredId = activeEnquiryIds.findIndex(
      (enquiry, afterIndex) => !enquiry.isEnquired && afterIndex > indexOfCurrentEnquiryId
    )
    if (nextAvailableUnEnquiredId !== -1) {
      if (hasPayloadWithEnquiryId(activeEnquiryIds[nextAvailableUnEnquiredId].enquiryId)) {
        dispatch(setActiveEnquiryId(activeEnquiryIds[nextAvailableUnEnquiredId].enquiryId))
      } else {
        dispatch(getPayloadForEnquiryId(activeEnquiryIds[nextAvailableUnEnquiredId].enquiryId))
      }
    } else {
      // If no next available enquiry Id then it will find in all available enquiry
      const anyAvailableUnEnquiredId = activeEnquiryIds.findIndex(
        (enquiry, beforeIndex) => !enquiry.isEnquired && beforeIndex < indexOfCurrentEnquiryId
      )
      if (anyAvailableUnEnquiredId !== -1) {
        if (hasPayloadWithEnquiryId(activeEnquiryIds[anyAvailableUnEnquiredId].enquiryId)) {
          dispatch(setActiveEnquiryId(activeEnquiryIds[anyAvailableUnEnquiredId].enquiryId))
        } else {
          dispatch(getPayloadForEnquiryId(activeEnquiryIds[anyAvailableUnEnquiredId].enquiryId))
        }
      }
      if (anyAvailableUnEnquiredId === -1) {
        if (isUreFrmCP) {
          if (getScope() === SCOPE_TYPE_TELE) {
            history.push(CUSTOMER_TELE_UNDERWRITER_DECLARATION)
          } else {
            history.push(CUSTOMER_PERSONAL_STATEMENT_DECLARATION)
          }
        } else {
          history.push(RESULTS_PAGE)
        }
      }
    }
  }
}

export const saveUrlForCallBack = () => ({
  type: SAVE_URL_FOR_URE_RESULT,
  payload: {
    path: !!isBrowser() && window.location.pathname,
  },
})

export const ureInitalData =
  ({ queuedAction }: Object = {}, callback: Function) =>
  (dispatch, getState) => {
    const {
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()

    dispatch({
      type: SITECORE_API,
      name: INITIALIZE_DATA_URE,
      verb: 'GET',
      route: `${SITECORE_URE_BASE}/uretemplate`,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      queuedAction,
      callback: (err, payload) => {
        if (typeof callback === 'function') {
          callback(err, payload)
        }
        return {
          ...payload,
        }
      },
    })
  }

export const getResultsOfUre = data => (dispatch, getState) => {
  const {
    config: { MLCL_SITECORE_CMS_KEY },
  } = getState()

  dispatch({
    type: SITECORE_API,
    name: GET_RESULTS_OF_URE,
    verb: 'POST',
    route: `${SITECORE_URE_BASE}/preassessment/results`,
    additionalHeaders: {
      CMSapikey: MLCL_SITECORE_CMS_KEY,
    },
    data,
    queuedAction: getOutcomeEnquiry,
  })
}

export const getDetailsForEnquiryId =
  (enquiryId: string) => (dispatch: Function, getState: Function) => {
    const {
      ure: {
        initialData: { umeBenefits, umeExpiryPeriod },
      },
      createQuote,
    } = getState()
    const activeQuote = createQuote.quotes[createQuote.activeIndex]
    dispatch({
      type: GET_DETAILS_FOR_ENQUIRY_ID_INIT,
    })
    dispatch({
      type: EXPERIENCE_API,
      name: GET_DETAILS_FOR_ENQUIRY_ID,
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_URE_BASE}/enquiries/${enquiryId}`,
      queuedAction: prevAction => {
        if (!prevAction.error) {
          return getResultsOfUre({
            ...prevAction.payload,
            umeBenefits,
            expiry: {
              noOfDays: umeExpiryPeriod,
              createdOn: get(activeQuote, 'preAssessmentDetails.createdOn', ''),
            },
          })
        }
        // No queued action in case of error
        return {
          type: '',
        }
      },
    })
  }

export const updateEnquiryWithUpdatedDetails = () => (dispatch, getState) => {
  const {
    forms,
    ure: { activeEnquiryId, answers },
    createQuote: { quotes, activeIndex },
    advisor: { details },
  } = getState()
  const allAnswers = getPreAssessmentEnquiryPayload(
    forms[preAssessmentSchema],
    forms.captureClient,
    activeEnquiryId ? answers[activeEnquiryId].allAnswers : '',
    quotes[activeIndex].memberMandatories,
    details
  )
  const updatedAnswers = {
    ...answers,
    [activeEnquiryId]: {
      ...answers[activeEnquiryId],
      allAnswers: allAnswers.answers,
    },
  }
  dispatch({
    type: UPDATE_DATA_WITH_NEW_DETAILS_URE,
    payload: {
      updatedAnswers,
      activeEnquiryId,
    },
  })
}

export const resetUREData = () => ({
  type: RESET_URE_DATA,
})

export const resetFullUreResultData = () => ({
  type: RESET_FULL_URE_RESULT_SUCCESS_DATA,
})

export const updateUnderWritingMethod = underwritingMethod => ({
  type: UPDATE_UNDER_WRITING_METHOD,
  payload: {
    underwritingMethod,
  },
})

export const listAllEnquiryIds = () => (dispatch, getState) => {
  const state = getState()
  const {
    createQuote: { activeIndex, quotes },
    ure: { activeEnquiryIds: activeEnquiryIdsOfStore, activeEnquiryId },
  } = state
  const activeQuote = quotes[activeIndex]
  const lifeAssuredDetail = get(activeQuote, 'policyStructure[0].relationships').find(
    member => member.role.indexOf(POLICY_RELATIONSHIPS_LIFEASSURED) !== -1
  )
  const fullUreEnquiryId = get(
    activeQuote,
    'underwritingDetails.enquiryId',
    get(activeQuote, 'preAssessmentDetails.enquiryId', '')
  )
  const indexOfAlreadySavedfullUreEnquiryId = activeEnquiryIdsOfStore.findIndex(
    enquiry => enquiry.enquiryId === fullUreEnquiryId
  )
  const activeEnquiryIds =
    indexOfAlreadySavedfullUreEnquiryId !== -1
      ? [{ ...activeEnquiryIdsOfStore[indexOfAlreadySavedfullUreEnquiryId], payload: {} }]
      : [
          {
            enquiryId: get(
              activeQuote,
              'underwritingDetails.enquiryId',
              get(activeQuote, 'preAssessmentDetails.enquiryId', '')
            ),
            createdOn: get(
              activeQuote,
              'underwritingDetails.createdOn',
              get(activeQuote, 'preAssessmentDetails.createdOn', '')
            ),
            name: `${lifeAssuredDetail.relatedParty.firstName} ${lifeAssuredDetail.relatedParty.lastName}`,
            payload: {},
            updatedOn: '',
            isEnquired: false,
            isChildCI: false,
          },
        ]
  activeQuote.policyStructure.forEach(policy => {
    const { policyInstanceNo } = policy
    policy.covers.forEach(cover => {
      const { type, benefitInstanceNo } = cover
      if (type === 'Child_CI') {
        const {
          childCIUnderwritingInfo: { enquiryId, createdOn },
        } = cover
        if (enquiryId) {
          const indexOfAlreadySavedEnquiry = activeEnquiryIdsOfStore.findIndex(
            enquiry => enquiry.enquiryId === enquiryId
          )
          const benefitAssured = get(cover, 'benefitChildCI')
          const { firstName, lastName } = benefitAssured
          if (indexOfAlreadySavedEnquiry === -1) {
            activeEnquiryIds.push({
              enquiryId,
              createdOn,
              name: `${firstName} ${lastName}`,
              payload: {},
              updatedOn: '',
              isEnquired: false,
              isChildCI: true,
              benefitInstanceNo,
              policyInstanceNo,
            })
          } else {
            activeEnquiryIds.push({
              ...activeEnquiryIdsOfStore[indexOfAlreadySavedEnquiry],
              name: `${firstName} ${lastName}`,
              payload: {},
            })
          }
        }
      }
    })
  })
  const activeEnquiryIndex = activeEnquiryIds.findIndex(
    enquiry => enquiry.enquiryId === activeEnquiryId
  )
  if (activeEnquiryIndex !== -1) {
    activeEnquiryIds[activeEnquiryIndex].isEnquired = false
  }

  dispatch({
    type: UPDATE_URE_WITH_ALL_ACTIVE_ENQUIRY_ID,
    payload: {
      activeEnquiryIds,
    },
  })
}

export const retriggerFullUredata = () => (dispatch, getState) => {
  const {
    config: { MLCL_SITECORE_CMS_KEY },
    ure: {
      answers,
      activeEnquiryIds,
      initialData: { umeBenefits, umeExpiryPeriod },
    },
  } = getState()
  const unifiedEnquiryIds = activeEnquiryIds.map(data => data.enquiryId)
  const enquiries = Object.keys(answers)
    .filter(answerId => unifiedEnquiryIds.includes(answerId))
    .map(answerId => ({
      ...answers[answerId],
      expiry: {
        noOfDays: umeExpiryPeriod,
        createdOn: get(
          activeEnquiryIds.find(enquiry => enquiry.enquiryId === answerId),
          'createdOn'
        ),
      },
    }))
  dispatch({
    type: SITECORE_API,
    name: FETCH_FULL_URE_DATA,
    verb: 'POST',
    route: `${SITECORE_URE_BASE}/UwDecision/fullure`,
    additionalHeaders: {
      CMSapikey: MLCL_SITECORE_CMS_KEY,
    },
    data: { enquiries, umeBenefits, isRefetch: true },
    metaData: { isRefetchURE: true },
  })
}

export const fetchFullUREdata = data => (dispatch, getState) => {
  const {
    config: { MLCL_SITECORE_CMS_KEY },
  } = getState()
  dispatch({
    type: SITECORE_API,
    name: FETCH_FULL_URE_DATA,
    verb: 'POST',
    route: `${SITECORE_URE_BASE}/UwDecision/fullure`,
    additionalHeaders: {
      CMSapikey: MLCL_SITECORE_CMS_KEY,
    },
    data,
  })
}

export const openUreErrorModal = () => ({
  type: OPEN_URE_ERROR_MODAL_POPUP,
})

export const closeUreErrorModal = () => ({
  type: CLOSE_URE_ERROR_MODAL_POPUP,
})

export const confirmSmokerTerms = () => ({
  type: CONFIRM_SMOKER_TERMS,
})

export const resetConfirmSmokerTerms = () => ({
  type: RESET_CONFIRM_SMOKER_TERMS,
})
