// @flow
export const creditTypesRegEx = {
  americanExpress: /^(34|37)[0-9]*$/,
  masterCard: /^(2|5[0-5])[0-9]*$/,
  discover: /^(6011|30|3[5-6]|3[8-9]|62|6[4-5])[0-9]*$/,
  visa: /^(4)[0-9]*/,
}

export const creditTypesValidation = {
  isAmericanExpress: false,
  isMasterCard: false,
  isDiscover: false,
  isVisa: false,
  isPlcc: false,
}

export const getCreditType = (cardNumber: string): Object => {
  const cardType = creditTypesValidation
  if (cardNumber) {
    // optima and americanExpress
    if (creditTypesRegEx.americanExpress.test(cardNumber)) {
      return {
        ...cardType,
        isAmericanExpress: true,
      }
    }
    // Mastercard
    if (creditTypesRegEx.masterCard.test(cardNumber)) {
      return {
        ...cardType,
        isMasterCard: true,
      }
    }
    // Discover
    if (creditTypesRegEx.discover.test(cardNumber)) {
      return {
        ...cardType,
        isDiscover: true,
      }
    }
    // Visa
    if (creditTypesRegEx.visa.test(cardNumber)) {
      return {
        ...cardType,
        isVisa: true,
      }
    }
  }
  return cardType
}
