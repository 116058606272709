// @flow
import React, { Component, type Node } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Checkbox, Label, Tooltip, ToggleGroup } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../../../utils/telemetry'
import { actionCreators } from '../../../../../actions'

// components.
import ChildCover from '../../../../molecules/ChildCover'
import { IconInfo16 } from '../../../../atoms/Icons'

// constants.
import {
  POLICY_NON_SUPER_BENEFIT_PREFIX,
  POLICY_CHILD_COVER_BENEFIT_CODE,
} from '../../../../../constants/policies'

// schema.
import chooseCoverSchema, { FORM_ID } from './chooseCover.schema'

// helpers.
import {
  productBenefitsWithClientMandatories,
  sortProducts,
  isNonSuperProduct,
  isValidBenefit,
  isIPCover,
} from '../../../../../utils/quoteUtils'
import { errorCheck } from '../../../../../utils/formUtils'
import { reduceAuthorableFields, renderTextField } from '../../../../../utils/sitecoreUtils'

// styles.
import styles from './chooseCover.styles'
import { ignoreColumns } from '../../../../../utils/stylesUtils'
import {
  isHealthyLivingRequired,
  isOneOfFieldContainValue,
} from '../../../../../utils/chooseCoverUtils'
import { getHealthyEligibleCovers } from '../../../../../selectors/common.selectors'

// set up components for ignoring column layouts.
const FullWidthToggleGroup = ignoreColumns(ToggleGroup)

const Wrapper = styled('div')(styles.wrapper)
const Section = styled('div')(styles.section)
const LabelHeadingDiv = styled('div')(styles.labelHeading)
const LabelSubheading = styled('span')(styles.labelSubheading)
const InfoIcon = styled(IconInfo16)(styles.infoIconHealthyLiving)
const TooltipContent = styled(Tooltip)(styles.tooltipContent)
const LabelContainer = styled(Label)(styles.LabelContainer)
const ToggleContainer = styled('div')(styles.ToggleWrapper)
type ChooseCoverProps = {
  // product rules payload from experience api.
  productRules: Object,
  // mandatory details filled by user.
  memberMandatories: Object,
  // bound redux actions
  actions: Function,
  // sitecore field data
  fields: {
    [string]: string,
  },
  // form fields object
  form: Object<Object>,
  healthyLivingCovers: Array,
  renderFoot: Function,
}

type ChooseCoverState = {
  // contains both non-super and super benefits.
  productData: Object,
  // dynamic form schema.
  formSchema: Object | boolean,
  // indicates whether Child Cover details modal is open
  isChildCoverOpen: boolean,
  // details of CCI benefit to use to lookup in productData
  childCoverDetails: {
    name: string,
    section: string,
    index: number | null,
  },
}

class ChooseCover extends Component<ChooseCoverProps, ChooseCoverState> {
  constructor(props) {
    super(props)

    this.state = {
      productData: {},
      formSchema: {},
      isChildCoverOpen: false,
      childCoverDetails: {
        name: '',
        section: '',
        index: null,
      },
    }
  }

  componentDidMount() {
    this.updateBasicData()
  }

  componentDidUpdate(previousProps) {
    this.updateBasicData(previousProps)
  }

  // Dynamically Generate schema coming the product rules api
  getFormSchema(): Object | boolean {
    const { productRules, form, healthyLivingCovers } = this.props
    if (!productRules) return false
    const coversSchema = productRules
      .map(prod => {
        const { benefits, productId } = prod
        const isNonSuper = isNonSuperProduct(productId)

        return benefits.map(benefit => {
          const { benefitCode } = benefit
          return isNonSuper ? `${POLICY_NON_SUPER_BENEFIT_PREFIX}${benefitCode}` : benefitCode
        })
      })
      .reduce((acc, curr) => acc.concat(curr), [])
      .reduce(
        (o, key) => ({
          ...o,
          [key]: {
            condition: '',
          },
        }),
        {}
      )
    return {
      ...coversSchema,
      ...chooseCoverSchema(form && form.fields ? form.fields : {}, healthyLivingCovers),
    }
  }

  // Set the dynamic form schema on internal state for component
  setFormSchemaState(): void {
    const { actions } = this.props
    const { formInit } = actions

    const generatedSchema = this.getFormSchema()

    if (!generatedSchema) {
      return
    }

    this.setState(
      state => ({
        ...state,
        formSchema: generatedSchema,
      }),
      () => {
        const { formSchema: schema } = this.state
        formInit(FORM_ID, schema)
      }
    )
  }

  getBenefitBySectionIndex(section: string, index: number) {
    const { productData } = this.state
    return productData[section].benefits[index]
  }

  handleOnChange =
    (section, index) =>
    ({ value, name }) => {
      const { productData } = this.state
      const { benefitCode } = this.getBenefitBySectionIndex(section, index)
      const isChildCover = benefitCode === POLICY_CHILD_COVER_BENEFIT_CODE

      this.setState({ productData: this.updateChooseCover(productData, section, index, value) })
      this.validateChange(value, name)

      if (isChildCover) {
        if (value) {
          // When selecting CCI
          this.openChildCover({ name, section, index })
        } else {
          // When de-selecting CCI
          this.closeChildCover()
        }
      }
      if (value) {
        const tagEvent = createEvent({
          GA: {
            category: name,
            action: 'Select covers',
          },
          Splunk: {
            attributes: {
              'workflow.name': `${name} - Select covers`,
            },
          },
        })
        tagEvent.end()
      }
    }

  filterProductData = data => {
    const { memberMandatories } = this.props

    const filteredProduct = productBenefitsWithClientMandatories(data, memberMandatories)
    const { inSuperProduct, outsideSuperProduct } = sortProducts(filteredProduct)
    return { superCovers: inSuperProduct, nonSuperCovers: outsideSuperProduct }
  }

  updateChooseCover = (state, section, index, value) => {
    const { memberMandatories } = this.props
    const copyOfState = {
      ...state,
    }
    // @FIXME: Mutation is being done, code should be optimised
    const benefitList = copyOfState[section].benefits
    benefitList[index] = {
      ...benefitList[index],
      active: value,
    }

    // Disable IP benefit, if it's already added in the quote. To be removed, IDII-1458
    if (isIPCover({ type: benefitList[index].benefitCode })) {
      const otherProductType = Object.keys(state).filter(productType => productType !== section)
      copyOfState[otherProductType] = {
        ...copyOfState[otherProductType],
        benefits: copyOfState[otherProductType].benefits.map(cover => ({
          ...cover,
          ...(isIPCover({ type: cover.benefitCode }) && {
            disabled: value,
          }),
        })),
      }
    }
    const allBenefitList = Object.values(copyOfState).reduce(
      (acc, currentValue) =>
        acc.concat(
          currentValue.benefits.map(benefit => ({
            ...benefit,
            productId: currentValue.productId,
          }))
        ),
      []
    )
    Object.keys(copyOfState).forEach(key => {
      const benefitListNew = copyOfState[key].benefits
      benefitListNew.forEach((benefit, i) => {
        // create benfitName as per form fields for nonSuperCover adding prefix
        const benefitName =
          section === 'superCovers'
            ? benefit.benefitCode
            : `${POLICY_NON_SUPER_BENEFIT_PREFIX}${benefit.benefitCode}`
        const matchedStateItem = copyOfState[section].benefits.find(
          bItem => bItem.benefitCode === benefit.benefitCode
        )
        if (
          get(benefit, 'coverRule.dependentOnCovers.length', 0) &&
          benefit.coverRule.dependentOnCovers.some(_benefit =>
            allBenefitList.some(
              b =>
                _benefit.productId === b.productId &&
                b.benefitCode === _benefit.benefitCode &&
                b.active
            )
          ) &&
          !isValidBenefit(benefit, memberMandatories)
        ) {
          benefitListNew[i] = {
            ...benefit,
            disabled: false,
            active: benefit.active,
          }
          this.validateChange(benefitListNew[i].active, benefitName, false)
        } else if (
          get(benefit, 'coverRule.dependentOnCovers.length', 0) &&
          !benefit.coverRule.dependentOnCovers.some(_benefit =>
            allBenefitList.some(
              b =>
                _benefit.productId === b.productId &&
                b.benefitCode === _benefit.benefitCode &&
                b.active
            )
          ) &&
          i !== index &&
          matchedStateItem?.benefitCode !== 'PTD' &&
          key !== 'nonSuperCovers'
        ) {
          benefitListNew[i] = {
            ...benefit,
            disabled: true,
            active: false,
          }
          this.validateChange(false, benefitName)
          if (matchedStateItem) matchedStateItem.active = false
        }
      })
      copyOfState[key].benefits = benefitListNew
    })
    return copyOfState
  }

  openChildCover = ({ name, section, index }): void => {
    this.setState({
      isChildCoverOpen: true,
      childCoverDetails: {
        name,
        section,
        index,
      },
    })
  }

  cancelChildCover = (): void => {
    this.closeChildCover()

    // Deselect Child CI benefit
    const {
      productData,
      childCoverDetails: { name, section, index },
    } = this.state
    this.setState({ productData: this.updateChooseCover(productData, section, index, false) })
    this.validateChange(false, name)
  }

  closeChildCover = (): void => {
    this.setState({ isChildCoverOpen: false })
  }

  shouldhealthyProgramBeDisabled = () => {
    const {
      form: { fields },
      healthyLivingCovers,
    } = this.props
    const isDisabled = !isHealthyLivingRequired(fields, healthyLivingCovers)
    // if HeathyLiving is disabled and value is selected priviously then we are setting empty value
    if (isDisabled && get(fields, 'healthyLivingProgram.value', '') !== '') {
      this.validateChange('', 'healthyLivingProgram')
    }
    return isDisabled
  }

  // Validate Form on change of benefit selections
  // @param {value} any - value of the field
  // @param {name} string - value of the field name
  validateChange(value, name, UpdateForm = true) {
    const { actions, form, healthyLivingCovers } = this.props
    const { formUpdateField, formValidate, formSetValid } = actions

    const data = {
      error: errorCheck(value, {}, null),
      value,
    }
    const formField = { ...form.fields }
    if (UpdateForm) {
      formUpdateField(FORM_ID, name, data)
    }
    // we are updating here because in chooseCoverSchema method we are
    // getting old form field value here we are updating and passing that
    // to chooseCoverSchema method
    formField[name] = { ...formField[name], value }
    if (isOneOfFieldContainValue(formField, ['healthyLivingProgram']))
      formValidate(FORM_ID, chooseCoverSchema(formField, healthyLivingCovers))
    else formSetValid(FORM_ID, false)

    // GA tag for healthyLivingProgram button
    if (name === 'healthyLivingProgram') {
      if (value === 'true') {
        const tagEvent = createEvent({
          GA: {
            category: 'Healthy Lives Discount toggle is turned On',
            action: 'Select',
          },
          Splunk: {
            attributes: {
              'workflow.name': "'Healthy Lives Discount toggle is turned On",
            },
          },
        })
        tagEvent.end()
      } else {
        const tagEvent = createEvent({
          GA: {
            category: 'Healthy Lives Discount toggle is turned Off',
            action: 'Select',
          },
          Splunk: {
            attributes: {
              'workflow.name': "'Healthy Lives Discount toggle is turned Off",
            },
          },
        })
        tagEvent.end()
      }
    }
  }

  updateBasicData(previousProps) {
    const { productRules, memberMandatories } = this.props
    if (!productRules) return

    if (
      !previousProps ||
      (previousProps &&
        Object.keys(previousProps.memberMandatories).some(
          key =>
            JSON.stringify(previousProps.memberMandatories[key]) !==
            JSON.stringify(memberMandatories[key])
        ))
    ) {
      this.setState({ productData: this.filterProductData(productRules) })
      this.setFormSchemaState()
    }
  }

  createBenefit(benefit, index: number, isSuper: boolean = true): Node {
    const {
      benefitCode,
      benefitShortDisplayName,
      benefitType,
      disabled,
      active,
      displayOnChooseCover,
    } = benefit
    // convert string to boolean
    const shouldDisplay = Number(displayOnChooseCover)
    const handler = this.handleOnChange(isSuper ? 'superCovers' : 'nonSuperCovers', index)

    return shouldDisplay ? (
      <Checkbox
        key={index}
        htmlFor={isSuper ? benefitCode : `${POLICY_NON_SUPER_BENEFIT_PREFIX}${benefitCode}`}
        text={benefitType && benefitType.value ? benefitType.value : benefitShortDisplayName}
        name={isSuper ? benefitCode : `${POLICY_NON_SUPER_BENEFIT_PREFIX}${benefitCode}`}
        disabled={disabled}
        value={benefitCode}
        direction="left"
        variant="addbox"
        onChangeHandler={handler}
        checked={active}
      />
    ) : null
  }

  render() {
    const { fields, form, healthyLivingCovers, renderFoot } = this.props
    if (!healthyLivingCovers || !form) return null
    const { productData, isChildCoverOpen } = this.state
    const { superCovers, nonSuperCovers } = productData
    const { healthyLivingProgram } = form.fields
    const isHeathyLivingDisabled = this.shouldhealthyProgramBeDisabled()
    const { ChooseCoverHealthyLivingTooltip, ChooseCoverHealthyLivingLabel, ChooseCoverBMILabel } =
      fields
    return (
      <Wrapper>
        <form id={FORM_ID} aria-labelledby="choose-cover">
          <Section>
            {superCovers && superCovers.benefits && !!superCovers.benefits.length && (
              <Label variant="input" htmlFor="super-covers" id="super-covers-label">
                {superCovers && superCovers.displayText}
              </Label>
            )}
            {superCovers && (
              <div id="super-covers">
                {superCovers.benefits.map((benefit, i) => this.createBenefit(benefit, i))}
              </div>
            )}
          </Section>
          <Section>
            {nonSuperCovers && nonSuperCovers.benefits && !!nonSuperCovers.benefits.length && (
              <Label variant="input" htmlFor="non-super-covers" id="non-super-covers-label">
                {nonSuperCovers && nonSuperCovers.displayText}
              </Label>
            )}
            {nonSuperCovers && (
              <div id="non-super-covers">
                {nonSuperCovers.benefits.map((benefit, i) => this.createBenefit(benefit, i, false))}
              </div>
            )}
          </Section>
          <Section>
            {
              <LabelHeadingDiv>
                <LabelContainer
                  variant="input"
                  htmlFor="healthy-living-program"
                  id="healthy-living-program-label"
                >
                  {renderTextField(ChooseCoverHealthyLivingLabel)}{' '}
                  <LabelSubheading>{renderTextField(ChooseCoverBMILabel)}</LabelSubheading>
                </LabelContainer>
                <div data-tip data-for="earningsPAExclSuper">
                  <InfoIcon />
                </div>
                <TooltipContent
                  id="earningsPAExclSuper"
                  place="left"
                  offset={{ top: 118, left: 25 }}
                  multiline
                >
                  {renderTextField(ChooseCoverHealthyLivingTooltip, true)}
                </TooltipContent>
              </LabelHeadingDiv>
            }
            <ToggleContainer>
              <FullWidthToggleGroup
                htmlFor="healthyLivingProgram"
                name="healthyLivingProgram"
                labelledby="client-label"
                handleChange={({ value, name }) => this.validateChange(value, name)}
                options={chooseCoverSchema(
                  form.fields,
                  healthyLivingCovers
                ).healthyLivingProgram.options.map(opt => ({
                  ...opt,
                  disabled: isHeathyLivingDisabled,
                }))}
                value={healthyLivingProgram.value}
                error={healthyLivingProgram.error.error}
                checked={!healthyLivingProgram.value === ''}
              />
            </ToggleContainer>
          </Section>
        </form>
        {isChildCoverOpen && (
          <ChildCover
            isOpen={isChildCoverOpen}
            onClose={this.cancelChildCover}
            onSubmit={this.closeChildCover}
            fields={reduceAuthorableFields(fields)}
          />
        )}
        {renderFoot && renderFoot()}
      </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({ forms, productRules, createQuote }) => ({
  form: forms[FORM_ID],
  productRules: productRules.data && productRules.data.length ? productRules.data : null,
  memberMandatories: createQuote.quotes[0].memberMandatories || null,
  healthyLivingCovers: getHealthyEligibleCovers(productRules),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCover)
