// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Tooltip, Chip } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { actionCreators } from '../../../../../../actions'

// constants.
import {
  POLICY_PRODUCT_CODE_SUPER,
  POLICY_PREMIUM_WAIVER_BENEFIT_CODE,
  MULTI_COVER_DISCOUNT,
} from '../../../../../../constants/policies'

// components.
import Button from '../../../../../atoms/Button'
import Tag from '../../../../../atoms/Tag'
import { IconInfo16 } from '../../../../../atoms/Icons'

// utils.
import {
  policyHasFee,
  formatCurrency,
  getPolicyStampDuty,
  sortPolicies,
} from '../../../../../../utils/quoteUtils'

// styles.
import styles from './quoteCost.styles'
import {
  healthyLivingDiscountVal,
  orderIPBenefits,
  getPolicyHasDiscountAndAmount,
} from '../../../../../../utils/extendedQuoteUtils'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// styled components.
const QuoteCostRoot = styled('div')(styles.quoteCostRoot)
const QuoteCostTitle = styled('div')(styles.quotePolicyTitle)
const QuoteCostPolicy = styled('div')(({ isBreakdownExpanded }) =>
  styles.quoteCostPolicy(isBreakdownExpanded)
)
const QuotePolicyNumber = styled('span')(styles.quotePolicyNumber)
const HeaderTag = styled('span')(styles.policyHeaderTag)
const BreakdownList = styled('dl')(styles.breakdownList)
const BreakdownName = styled('dt')(styles.breakdownName)
const BreakdownValue = styled('dd')(styles.breakdownValue)
const BreakdownToggle = styled(Button)(styles.breakdownToggle)
const TotalList = styled(BreakdownList)(styles.totalList)
const TotalName = styled(BreakdownName)(styles.totalName)
const TotalValue = styled(BreakdownValue)(styles.totalValue)
const NoPolicyMessage = styled('div')(styles.noPolicyMessage)
const HealthyLivingDiscount = styled('div')(styles.discountLabel)
const BreakdownContainer = styled('div')({ ...styles.flex, ...styles.breakdownContainer })
const InfoIcon = styled(IconInfo16)(styles.infoIcon)
const TooltipContent = styled(Tooltip)(styles.tooltipContent)

type QuoteCostProps = {
  // create quote data which user has selected.
  createQuote: Object,
  // Sitecore fields,
  fields: {
    // total annual cost label.
    quoteEntityTACost: 'string',
    // Total bi-annual cost label
    quoteEntityTBCost: 'string',
    // Total monthly cost label
    quoteEntityTMCost: 'string',
    // view cost breakdown label.
    quoteEntityViewCostBreak: 'string',
    // hide cost breakdown label.
    quoteEntityHideCostBreak: 'string',
    // policy fee label.
    quoteEntityPolicyFee: string,
    // policy fee label.
    quoteEntityStampDuty: string,
    // inside super label
    policyInsideSuper: string,
    // outside super label
    policyOutsideSuper: string,
    quoteEntityNoAvailablePolicies: string,
    quoteToolInsidePolicyNamePrefix: Object,
    quoteToolOutsidePolicyNamePrefix: Object,
    quoteToolWrapPolicySuffix: Object,
    quoteToolSuperPolicySuffix: Object,
    quoteToolOutsidePolicySuffix: Object,
    QuoteHealthyLivingProgramDiscountLabel: string,
    QuoteHealthyLivingProgramDiscountTooltip: String,
    QuoteHealthyLivingProgramDiscountAppliedLabel: String,
  },
  // integer of the index within the quote collection that is being rendered.
  quoteIndex: number,
  breakdownOpen: boolean,
}

type QuoteCostState = {
  // true when quote cost breakdown is visible
  isBreakdownOpen: boolean,
}

class QuoteCost extends Component<QuoteCostProps, QuoteCostState> {
  constructor(props) {
    super(props)
    this.state = {
      isBreakdownOpen: props.breakdownOpen || false,
    }
  }

  toggleBreakdown = () => {
    this.setState(prevState => ({ isBreakdownOpen: !prevState.isBreakdownOpen }))
  }

  renderBreakdownItem = (
    name,
    amount,
    keyPrefix,
    showDiscountLabel = false,
    showTooltip = false,
    tooltipText = '',
    currencyFormatingReq = true
  ) => {
    const key = keyPrefix ? `${keyPrefix}-${amount}` : `${name}-${amount}`
    const {
      fields: { QuoteHealthyLivingProgramDiscountAppliedLabel },
    } = this.props
    return (
      <BreakdownContainer key={key}>
        <BreakdownName>
          <div>{name}</div>
          {showDiscountLabel && (
            <HealthyLivingDiscount>
              {renderTextField(QuoteHealthyLivingProgramDiscountAppliedLabel)}
            </HealthyLivingDiscount>
          )}
        </BreakdownName>
        <BreakdownValue>{currencyFormatingReq ? formatCurrency(amount) : amount}</BreakdownValue>
        {showTooltip && (
          <div>
            <div data-tip data-for={key}>
              <InfoIcon />
            </div>
            <TooltipContent id={key} place="right" offset={{ bottom: 10, right: 25 }} multiline>
              {renderTextField(tooltipText, true)}
            </TooltipContent>
          </div>
        )}
      </BreakdownContainer>
    )
  }

  // display healthy label based on healthy toggle
  // and based on cover is eligible for healthyLiving
  renderCoverBreakdown = cover =>
    this.renderBreakdownItem(
      cover.name,
      cover.premiumAmount,
      `${cover.type}${cover.benefitInstanceNo}`
    )

  renderPolicyFee = (name, policy, isSuperAvailable, index) => {
    if (
      policy.productId === POLICY_PRODUCT_CODE_SUPER ||
      (policy.productId !== POLICY_PRODUCT_CODE_SUPER && !isSuperAvailable && index === 0)
    ) {
      return this.renderBreakdownItem(name, policy.policyFeeValue)
    }
    return false
  }

  renderBreakdown = policy => {
    const sortedCovers = orderIPBenefits(false, policy.covers, [])
    return sortedCovers.length ? (
      <BreakdownList>
        {sortedCovers
          .filter(cover => cover.type !== POLICY_PREMIUM_WAIVER_BENEFIT_CODE)
          .map(ele => this.renderCoverBreakdown(ele))}
        {sortedCovers
          .filter(cover => cover.type === POLICY_PREMIUM_WAIVER_BENEFIT_CODE)
          .map(ele => this.renderCoverBreakdown(ele))}
      </BreakdownList>
    ) : null
  }

  render() {
    const {
      createQuote: { quotes },
      quoteIndex,
      fields: {
        quoteEntityViewCostBreak,
        quoteEntityHideCostBreak,
        quoteEntityTACost,
        quoteEntityTBCost,
        quoteEntityTMCost,
        quoteEntityPolicyFee,
        quoteEntityStampDuty,
        policyInsideSuper,
        policyOutsideSuper,
        quoteEntityNoAvailablePolicies,
        QuoteHealthyLivingProgramDiscountLabel,
        QuoteHealthyLivingProgramDiscountTooltip,
        MultiCoverDiscountLabel,
        MultiCoverDiscountTooltip,
      },
    } = this.props
    const quote = quotes[quoteIndex]
    const sortedPolicies = sortPolicies(quote.policyStructure)
    const { isBreakdownOpen } = this.state

    const isSuperAvailable =
      sortedPolicies && sortedPolicies.length
        ? sortedPolicies[0].productId === POLICY_PRODUCT_CODE_SUPER
        : ''

    const SUB_LABELS = {
      ANN: quoteEntityTACost,
      BIAN: quoteEntityTBCost,
      MON: quoteEntityTMCost,
    }

    return (
      <QuoteCostRoot>
        {!sortedPolicies.length && isBreakdownOpen ? (
          <NoPolicyMessage>{quoteEntityNoAvailablePolicies.value}</NoPolicyMessage>
        ) : null}
        {sortedPolicies.map((policy, index) => (
          <QuoteCostPolicy
            // eslint-disable-next-line react/no-array-index-key
            key={`${policy.productId}-${index}`}
            isBreakdownExpanded={isBreakdownOpen}
          >
            <QuoteCostTitle>
              <QuotePolicyNumber>{policy.productName.split('-')[1]}</QuotePolicyNumber>
              {policy.productId === POLICY_PRODUCT_CODE_SUPER ? (
                <HeaderTag>
                  <Tag type="primary">{policyInsideSuper.value}</Tag>
                </HeaderTag>
              ) : (
                <HeaderTag>
                  <Chip variant="important">{policyOutsideSuper.value}</Chip>
                </HeaderTag>
              )}
            </QuoteCostTitle>
            {isBreakdownOpen ? this.renderBreakdown(policy) : null}
            <TotalList>
              {isBreakdownOpen ? (
                <Fragment>
                  {policyHasFee(policy) &&
                    this.renderPolicyFee(
                      quoteEntityPolicyFee.value,
                      policy,
                      isSuperAvailable,
                      index
                    )}
                  {this.renderBreakdownItem(
                    <Text field={quoteEntityStampDuty} />,
                    getPolicyStampDuty(policy),
                    quoteEntityStampDuty.value,
                    false
                  )}
                  {+(policy.legacySystemProductCode?.split(' ')[1]?.match(/\d+/)?.[0] ?? 0) >= 6 &&
                    getPolicyHasDiscountAndAmount(MULTI_COVER_DISCOUNT, policy)
                      .isPolicyHasDiscount &&
                    this.renderBreakdownItem(
                      <Text field={MultiCoverDiscountLabel} />,
                      `${
                        getPolicyHasDiscountAndAmount(MULTI_COVER_DISCOUNT, policy).discountAmount
                      }%`,
                      MultiCoverDiscountLabel.value,
                      false,
                      true,
                      MultiCoverDiscountTooltip,
                      false
                    )}
                  {get(quote, 'option.healthyLivingDiscount', false)
                    ? this.renderBreakdownItem(
                        <Text field={QuoteHealthyLivingProgramDiscountLabel} />,
                        `${healthyLivingDiscountVal(policy.covers)}%`,
                        QuoteHealthyLivingProgramDiscountLabel.value,
                        false,
                        true,
                        QuoteHealthyLivingProgramDiscountTooltip,
                        false
                      )
                    : null}
                </Fragment>
              ) : null}
              <TotalName>
                <Text field={SUB_LABELS[policy.paymentFrequency]} />
              </TotalName>
              <TotalValue>{formatCurrency(policy.totalPremiumAmount)}</TotalValue>
            </TotalList>
          </QuoteCostPolicy>
        ))}
        {/* view quote sidebar, below cta is not shown */}
        <BreakdownToggle type="tertiary" onClick={this.toggleBreakdown}>
          <Text
            field={isBreakdownOpen ? quoteEntityHideCostBreak || {} : quoteEntityViewCostBreak}
          />
        </BreakdownToggle>
      </QuoteCostRoot>
    )
  }
}

const mapStateToProps = ({ createQuote }) => ({
  createQuote,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteCost)
