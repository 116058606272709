/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTick32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.748 9.332L14.733 25.855 6.44 17.56l2.122-2.122 5.706 5.707 8.985-13.478 2.496 1.664z"
      fill="#000"
    />
  </Icon>
)

export default IconTick32
