/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconFileUpload64 = ({ ...rest }) => (
  <Icon {...rest} size={64}>
    <circle cx="25" cy="25" r="24.5" fill="#F7F8F8" stroke="#E1E0E0" />
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4709 13.0596H18.1717C16.5873 13.0596 15.2988 14.348 15.2988 15.9324V34.2526C15.2988 35.8369 16.5873 37.1254 18.1717 37.1254H31.5243C33.1072 37.1254 34.3971 35.8369 34.3971 34.2526L34.3957 19.6813L28.4709 13.0596ZM28.6866 14.4646L33.1396 19.4408H28.6866V14.4646ZM33.6191 34.2523C33.6191 35.4082 32.679 36.3483 31.5231 36.3483H18.1705C17.0147 36.3483 16.0745 35.4082 16.0745 34.2523L16.076 15.9322C16.076 14.7763 17.0161 13.8362 18.172 13.8362H27.91V20.2176H33.6209L33.6191 34.2523Z"
      fill="white"
    />
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.4709 13.0596H18.1717C16.5873 13.0596 15.2988 14.348 15.2988 15.9324V34.2526C15.2988 35.8369 16.5873 37.1254 18.1717 37.1254H31.5243C33.1072 37.1254 34.3971 35.8369 34.3971 34.2526L34.3957 19.6813L28.4709 13.0596ZM28.6866 14.4646L33.1396 19.4408H28.6866V14.4646ZM33.6191 34.2523C33.6191 35.4082 32.679 36.3483 31.5231 36.3483H18.1705C17.0147 36.3483 16.0745 35.4082 16.0745 34.2523L16.076 15.9322C16.076 14.7763 17.0161 13.8362 18.172 13.8362H27.91V20.2176H33.6209L33.6191 34.2523Z"
      fill="#01838C"
    />
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3154 26.548L21.9057 27.0524L24.3938 24.1416V31.5476H25.1707V24.1416L27.6602 27.0524L28.2505 26.548L24.7829 22.4917L21.3154 26.548Z"
      fill="#01838C"
    />
  </Icon>
)

export default IconFileUpload64
