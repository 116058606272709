/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconInfo16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.58916 11.2305C6.46742 11.2305 6.34568 11.1848 6.25438 11.1087C6.1022 10.987 6.02612 10.7892 6.07177 10.5913L6.81742 6.78699L6.40655 7.01525C6.14786 7.1522 5.82829 7.07612 5.67612 6.81742C5.53916 6.55873 5.61525 6.23916 5.87394 6.08699L7.28916 5.28047C7.47177 5.17394 7.70003 5.18916 7.86742 5.3109C8.03481 5.43264 8.1109 5.64568 8.08046 5.84351L7.30438 9.80003L7.94351 9.52612C8.21742 9.40438 8.52177 9.52612 8.64351 9.80003C8.76525 10.0739 8.64351 10.3783 8.3696 10.5L6.8022 11.1848C6.74133 11.2152 6.66525 11.2305 6.58916 11.2305ZM8.09568 3.95655C8.23264 3.8196 8.32394 3.62177 8.32394 3.42394C8.32394 3.22612 8.24786 3.02829 8.09568 2.89133C7.95873 2.75438 7.7609 2.66307 7.56307 2.66307C7.36525 2.66307 7.16742 2.73916 7.03046 2.89133C6.89351 3.02829 6.8022 3.22612 6.8022 3.42394C6.8022 3.62177 6.87829 3.8196 7.03046 3.95655C7.16742 4.09351 7.36525 4.18481 7.56307 4.18481C7.7609 4.18481 7.95873 4.10873 8.09568 3.95655ZM14 7.00003C14 3.13481 10.8652 3.05176e-05 7.00003 3.05176e-05C3.13481 3.05176e-05 3.05176e-05 3.13481 3.05176e-05 7.00003C3.05176e-05 10.8652 3.13481 14 7.00003 14C10.8652 14 14 10.8652 14 7.00003ZM12.7826 7.00003C12.7826 10.1957 10.1957 12.7826 7.00003 12.7826C3.80438 12.7826 1.21742 10.1957 1.21742 7.00003C1.21742 3.80438 3.80438 1.21742 7.00003 1.21742C10.1957 1.21742 12.7826 3.80438 12.7826 7.00003Z"
      fill="#000"
    />
  </Icon>
)

export default IconInfo16
