// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Button, Loader } from '@mlcl-digital/mlcl-design'
// redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actionCreators } from '../../../../../actions'

// components.
import SearchBar from '../../../../molecules/SearchBar'

// icons
import { IconChevronRight16 } from '../../../../atoms/Icons'

// constants
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_NUMBER } from '../../../../../constants/forms'
import { ORDER_BY_DESC, POLICY_OWNER_KEY } from '../../../../../constants/application'

// schema.
import captureClientSchema, {
  FORM_ID as CAPTURE_CLIENT_FORM_ID,
} from '../CaptureClient/captureClient.schema'
import manualLoadingSchema, {
  FORM_ID as MANUAL_LOADINGS_FORM_ID,
} from '../PreAssessment/Loadings/loadings.schema'

// utils
import { resetBenefitList } from '../../../../../utils/preAssessmentBenefitUtils'
import { renderTextField, reduceAuthorableFields } from '../../../../../utils/sitecoreUtils'

// styles.
import styles from './searchExistingClient.styles'

type SearchExistingClientsProps = {
  fields: Object,
  actions: Object,
  // client details
  client: {
    // existing clients member results
    existingClients: Object,
  },
  // advisor details
  advisor: Object,
  // quote collection
  createQuote: Object,
  // fetch existing client loading state
  isFetchingExistingClient: boolean,
}
type SearchExistingClientsState = {
  // search input state
  searchInput: String,
  // existing client results exist or not
  searchedExisting: boolean,
}

//  default search params
const defaultSearchParams = {
  sortBy: POLICY_OWNER_KEY,
  orderBy: ORDER_BY_DESC,
  offset: DEFAULT_PAGE_NUMBER,
  limit: DEFAULT_PAGE_LIMIT,
}

const Wrap = styled('div')(styles.wrap)
const SearchResultsContainer = styled('div')()
const SearchList = styled('div')()
const ResultsHeading = styled('div')(styles.resultsHeading)
export const LifeInsured = styled('div')(styles.lifeInsured)
const LifeInsuredName = styled('span')(styles.lifeInsuredName)
const DateOfBirth = styled('span')(styles.dateOfBirth)
const Arrow = styled(IconChevronRight16)(styles.arrow)
const NoResultFound = styled('div')(styles.noResultFound)
const NoResultFoundText = styled('span')(styles.noResultFoundText)

export class SearchExistingClients extends Component<
  SearchExistingClientsProps,
  SearchExistingClientsState
> {
  constructor(props: AdvisorClientListingProps) {
    super(props)
    this.state = {
      searchInput: '',
      searchedExisting: false,
    }
  }

  changeHandler = (value: string): void => this.setState({ searchInput: value })

  clickHandler = (): void => {
    const {
      actions: { getMemberSearch, deleteExistingSearchedClients },
      advisor: { bancsAgencyCodes },
      client,
    } = this.props
    const { searchInput } = this.state
    // prevent search if searchInput in empty
    if (!searchInput) {
      // if existing clients exist on empty search, lets clear them. MM
      this.setState({ searchedExisting: false })
      if (client.existingClients.results.length) {
        deleteExistingSearchedClients()
      }
      return
    }

    if (searchInput.length) {
      const requestParam = {
        ...defaultSearchParams,
        searchParam: {
          bancsAgencyCodes,
          partyNameLike: `${searchInput}`,
          searchForLifeInsured: true,
        },
      }

      this.setState({ searchedExisting: true })
      getMemberSearch(requestParam)
    }
  }

  initialiseCaptureClientForm = () => {
    const {
      actions: {
        formInit,
        removeEnquiryId,
        resetAllSavedState,
        updateLoading,
        saveLoadings,
        resetConfirmSmokerTerms,
        setPanelProps,
      },
      createQuote,
    } = this.props

    const { adviserNumber, bancsAdviserCustomerNo } = createQuote
    // reset capature client form
    removeEnquiryId(true)
    resetAllSavedState(adviserNumber, bancsAdviserCustomerNo)
    updateLoading([])
    resetBenefitList()
    // reset manual loading underwriter form data
    saveLoadings(null)
    formInit(MANUAL_LOADINGS_FORM_ID, manualLoadingSchema()({}), {})
    resetConfirmSmokerTerms()
    // Set props on sidebar
    setPanelProps({
      setPreAssessmentVisibility: true,
    })
  }

  setExistingClient = bancsCustomerNo => () => {
    const {
      actions: { openSidebar, setExistingClientDetails, prePopulateMandatoriesWithExistingClient },
    } = this.props
    openSidebar('createQuote', 1)
    setExistingClientDetails(bancsCustomerNo)
    this.initialiseCaptureClientForm()
    prePopulateMandatoriesWithExistingClient(bancsCustomerNo)
  }

  renderLifeInsuredList = (lifeAsured, index) => {
    const name = `${get(lifeAsured, 'firstName', '')} ${get(lifeAsured, 'lastName', '')}`
    const dob = get(lifeAsured, 'dateOfBirth', '')
    const bancsCustomerNo = get(lifeAsured, 'bancsCustomerNo', '')

    return (
      <LifeInsured
        onClick={this.setExistingClient(bancsCustomerNo)}
        key={`${get(lifeAsured, 'firstName')}-${index}`}
      >
        <LifeInsuredName>{name}</LifeInsuredName>
        <DateOfBirth>
          <span>{dob}</span>
          <Arrow />
        </DateOfBirth>
      </LifeInsured>
    )
  }

  createNewQuote = () => {
    const {
      actions: {
        formInit,
        removeEnquiryId,
        resetAllSavedState,
        updateLoading,
        saveLoadings,
        resetConfirmSmokerTerms,
        setPanelProps,
        openSidebar,
      },
      createQuote,
    } = this.props

    const { adviserNumber, bancsAdviserCustomerNo } = createQuote
    openSidebar('createQuote', 1)
    // reset capature client form
    formInit(CAPTURE_CLIENT_FORM_ID, captureClientSchema, {})
    removeEnquiryId(true)
    resetAllSavedState(adviserNumber, bancsAdviserCustomerNo)
    updateLoading([])
    resetBenefitList()
    // reset manual loading underwriter form data
    saveLoadings(null)
    formInit(MANUAL_LOADINGS_FORM_ID, manualLoadingSchema()({}), {})
    resetConfirmSmokerTerms()
    // Set props on sidebar
    setPanelProps({
      setPreAssessmentVisibility: true,
      newClient: true,
    })
  }

  render() {
    const { searchInput, searchedExisting } = this.state
    const {
      fields: {
        advisorPortalSearchExistingClientResultsHeading,
        advisorPortalSearchExistingClientNoResultsFoundHeading,
        advisorPortalSearchExistingClientCreateNewQuoteButtonText,
      },
      fields,
      client: { existingClients },
      advisor,
      isFetchingExistingClient,
    } = this.props
    const { advisorPortalSearchExistingClientSearchBarHeading } = reduceAuthorableFields(fields)
    const clientList = get(existingClients, 'results', [])
    const lifeAssuredList = clientList.flatMap(item => [...item.lifeAssured])

    return (
      <Fragment>
        <Wrap>
          <SearchBar
            placeholder={advisorPortalSearchExistingClientSearchBarHeading}
            value={searchInput}
            clickHandler={this.clickHandler}
            changeHandler={this.changeHandler}
            inSidebar
          />
        </Wrap>
        {(isFetchingExistingClient || advisor.isLoading) && <Loader spinnerSize={40} />}
        {lifeAssuredList && lifeAssuredList.length > 0 ? (
          <Fragment>
            <SearchResultsContainer>
              {!isFetchingExistingClient && !advisor.isLoading && (
                <Fragment>
                  <ResultsHeading>
                    {renderTextField(advisorPortalSearchExistingClientResultsHeading)}
                  </ResultsHeading>
                  <SearchList>
                    {lifeAssuredList.map((lifeAssured, index) =>
                      this.renderLifeInsuredList(lifeAssured, index)
                    )}
                  </SearchList>
                </Fragment>
              )}
            </SearchResultsContainer>
          </Fragment>
        ) : (
          searchedExisting &&
          !isFetchingExistingClient &&
          !advisor.isLoading && (
            <Fragment>
              <NoResultFound>
                <NoResultFoundText>
                  {renderTextField(advisorPortalSearchExistingClientNoResultsFoundHeading)}
                </NoResultFoundText>
                <Button variant="primary" onClick={this.createNewQuote}>
                  {renderTextField(advisorPortalSearchExistingClientCreateNewQuoteButtonText)}
                </Button>
              </NoResultFound>
            </Fragment>
          )
        )}
      </Fragment>
    )
  }
}

export const mapStateToProps = ({
  client,
  advisor,
  createQuote,
  client: { isFetchingExistingClient },
}) => ({
  client,
  isFetchingExistingClient,
  advisor,
  createQuote,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchExistingClients)
