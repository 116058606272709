/* eslint-disable no-unused-vars */
// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

// redux imports
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../actions'

// components.
import Button from '../../../../../atoms/Button'
import Input from '../../../../../atoms/Input'

// helpers.
import { errorCheck } from '../../../../../../utils/formUtils'

// schema.
import getQuoteCollectionNameSchema, { FORM_ID } from './quoteCollectionName.schema'
import { getQuotecollectionNameFields } from './quoteCollectionName.fields'

// styles.
import styles from './quoteCollectionName.styles'

// constants
import { DEFAULT_QUOTE_COLLECTION_NAME } from '../../../../../../constants/forms'

type QuoteCollectionNameProps = {
  // To render all labels from sitecore content editor
  fields: Object,
  // forms
  form: Object,
  /** Called when indured first name and last name is submitted */
  onSubmit: Function,
  // An object containing action creator functions.
  actions: Object<Function>,
  // ClientDetails memberEntity data
  client: Object,
  // State object containing quote object
  createQuote: Object,
  // LifeAssured entity
  lifeAssured: Object,
  exitToDashboard: Function,
}

const Form = styled('form')(styles.base)
const Names = styled('div')(styles.names)
const ExitButton = styled(Button)(styles.exitButton)

class QuoteCollectionName extends Component<QuoteCollectionNameProps> {
  static defaultProps = {
    data: {
      firstName: '',
      lastName: '',
    },
  }

  state = {
    redirect: false,
  }

  componentWillMount() {
    const {
      fields,
      actions: { formInit },
      createQuote: { quoteCollectionName, lifeInsuredFirstName, lifeInsuredLastName },
      client,
      lifeAssured,
    } = this.props
    const existingClient = get(client, 'existingClient', '')
    const existingClientDetails = get(client, 'details', {})

    this.setState(
      {
        schema: getQuoteCollectionNameSchema(getQuotecollectionNameFields(fields)),
      },
      () => {
        // Always reset the form on mounting (open)
        const { schema } = this.state

        if (quoteCollectionName === DEFAULT_QUOTE_COLLECTION_NAME) {
          const quoteNameDataToInit = {}
          const { firstName, lastName } = lifeAssured
          if (firstName) {
            quoteNameDataToInit.firstName = firstName
            quoteNameDataToInit.lastName = lastName
          }
          formInit(FORM_ID, schema, quoteNameDataToInit)
        } else {
          formInit(FORM_ID, schema, {
            firstName: lifeInsuredFirstName,
            lastName: lifeInsuredLastName,
          })
        }
      }
    )
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { schema } = this.state
    const {
      actions: { formUpdateField, formValidate },
    } = this.props
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  // handle submit of form, checks for validation
  handleSubmit = event => {
    event.preventDefault()
    const { actions, onSubmit } = this.props
    const { formSubmit, setLifeAssuredName } = actions
    const { schema } = this.state
    formSubmit(FORM_ID, schema, (data, form) => {
      if (form.isValid) {
        const firstName = get(form, 'fields.firstName.value', '')
        const lastName = get(form, 'fields.lastName.value', '')
        setLifeAssuredName(firstName, lastName)
        onSubmit(data)
      }
    })
  }

  handleExitToDashboard = () => {
    const {
      actions: { showSaveQuoteModal, resetCreateQuote },
    } = this.props

    this.setState(
      {
        redirect: true,
      },
      () => {
        showSaveQuoteModal(false)
        resetCreateQuote()
      }
    )
  }

  render() {
    const { schema } = this.state
    const { form, client } = this.props
    const existingClient = get(client, 'existingClient', '')
    if (!form) return null

    const {
      form: {
        fields: { firstName, lastName },
      },
      fields,
      exitToDashboard,
    } = this.props
    const quoteCollectionNameFields = getQuotecollectionNameFields(fields, exitToDashboard)
    const { redirect } = this.state
    if (redirect) {
      return <Redirect to="/dashboard" />
    }

    const disableName = existingClient && existingClient !== ''

    return (
      <Form id={FORM_ID} aria-labelledby="quote-collection-name">
        <p>
          <Text field={quoteCollectionNameFields.description} />
        </p>
        <Names>
          <Input
            htmlFor="firstName"
            name="firstName"
            label={quoteCollectionNameFields.firstName.value}
            changeHandler={this.handleChange}
            error={firstName.error.error}
            caption={firstName.error.error ? firstName.error.errorMsg : schema.firstName.tooltip}
            value={firstName.value}
            css={styles.firstName}
            disabled={!!disableName}
            focusOnMount
            data-locator="saveQuote_modal_firstName"
          />
          <Input
            htmlFor="lastName"
            name="lastName"
            label={quoteCollectionNameFields.lastName.value}
            changeHandler={this.handleChange}
            error={lastName.error.error}
            caption={lastName.error.error ? lastName.error.errorMsg : schema.lastName.tooltip}
            value={lastName.value}
            css={styles.lastName}
            disabled={!!disableName}
            data-locator="saveQuote_modal_lastName"
          />
        </Names>
        <Button type="secondary" onClick={this.handleSubmit} data-locator="saveQuote_modal_confirm">
          <Text field={quoteCollectionNameFields.submitButton} />
        </Button>
        {exitToDashboard && (
          <ExitButton
            type="tertiary"
            onClick={this.handleExitToDashboard}
            data-locator="exit_to_dashboard"
            data-testid="exitToDashboard"
          >
            <Text field={quoteCollectionNameFields.exitBtn} />
          </ExitButton>
        )}
      </Form>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

const mapStateToProps = ({ forms, createQuote, client, lifeAssured }) => ({
  form: forms[FORM_ID],
  createQuote,
  client,
  lifeAssured,
})

export default connect(mapStateToProps, mapDispatchToProps)(QuoteCollectionName)
