/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconLightning16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path className="path1" d="M3 9l7.5-8L9 7h4l-8 8 2-6H3z" fill="#000" />
  </Icon>
)

export default IconLightning16
