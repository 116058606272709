// @flow
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { A11yLabel, Label } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../actions'

// component.
import Input from '../../../../../atoms/Input'
import Select from '../../../../../atoms/Select'
import Heading from '../../../../../atoms/Heading'

// styles.
import styles from './linkedMasterKey.styles'
import { ignoreColumns } from '../../../../../../utils/stylesUtils'

// schema.
import SCHEMA, { FORM_ID } from './linkedMasterKey.schema'
import { FORM_ID as paymentDetails } from '../paymentDetails.schema'

// helpers.
import { errorCheck } from '../../../../../../utils/formUtils'
import { reduceAuthorableFields } from '../../../../../../utils/sitecoreUtils'
import { getPaymentMode } from '../../../../../../utils/quoteUtils'
import { linkedMasterKeyInitData } from './linkedMasterKeyUtil'
import { getPaymentFrequency } from '../../../../../../utils/paymentUtils'

type linkedMasterKeyProps = {
  // Redux actions available to the component.
  actions: {
    formInit: (id: string, schema: Function, data?: Object) => void,
    formUpdateField: (id: string, name: string, data: Object) => void,
    formValidate: (id: string, schema: Function) => void,
    formReset: (id: string, schema: Function, data?: Object) => void,
  },
  // form sub-store
  form: Object,
  // Sitecore authorable fields
  fields: Object,
  policyInstanceNo: string,
  createQuote: Object,
}

const FullWidthInput = ignoreColumns(Input)

const Form = styled('form')(styles.base)
const Wrapper = styled('div')()
const Dropdown = styled(Select)(styles.accountType)
const PaymentMethod = styled(Heading)(styles.paymentMethod)
const SmfDisplayText = styled('p')(styles.smfDisplayText)
const PaymentFrequency = styled('p')(styles.paymentFrequency)

export class LinkedMasterKey extends Component<linkedMasterKeyProps> {
  constructor(props) {
    super(props)

    this.schemaWithAuthorableFields = SCHEMA({})
  }

  componentWillMount() {
    const { actions, createQuote, form } = this.props
    const { formInit, formUpdateField } = actions
    const schema = this.schemaWithAuthorableFields({})
    const linkedMasterKeyData = linkedMasterKeyInitData(this.props)
    if (!form) {
      formInit(FORM_ID, schema, linkedMasterKeyData)
    }
    const paymentMethod = getPaymentMode(createQuote)
    const { fundPaymentMethod } = paymentMethod
    const data = {
      error: '',
      value: fundPaymentMethod,
    }

    formUpdateField(paymentDetails, paymentDetails, data)
  }

  // handle changes on form elements.
  handleChange = ({ value, name }) => {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions

    const schema = this.schemaWithAuthorableFields({})
    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }

    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schema)
  }

  render() {
    const { fields, form, createQuote, policyInstanceNo } = this.props
    const { quotes, activeIndex } = createQuote || {}
    const { policyStructure } = quotes ? quotes[activeIndex] : {}

    const activePolicy = policyStructure
      ? policyStructure.find(policy => policy.policyInstanceNo === policyInstanceNo)
      : ''

    if (!form) return null

    const { accountNumber, paymentDrawDate } = form.fields

    let frequency = ''
    if (activePolicy) frequency = getPaymentFrequency(activePolicy, fields)

    const {
      addIndividualHeaderLabel,
      linkedMasterkeyAccountNumberCaption,
      linkedMasterkeyDisclaimer,
      linkedMasterkeyPaymentDrawDateLabel,
      linkedMasterkeyPaymentDrawDatePlaceholder,
      linkedMasterkeyPaymentDrawDateError,
      LinkedMasterkeyPaymentMethodLabel,
      linkedMasterkeyAccountPaymentMethodValue,
      linkedMasterkeyAccountNumberLabel,
    } = reduceAuthorableFields(fields)

    const schema = this.schemaWithAuthorableFields()
    return (
      <Fragment>
        <Wrapper>
          <A11yLabel>{addIndividualHeaderLabel}</A11yLabel>
          <Form id={FORM_ID}>
            <Label htmlFor="paymentMethod">{LinkedMasterkeyPaymentMethodLabel}</Label>
            <PaymentMethod size="6" id="paymentMethod">
              {linkedMasterkeyAccountPaymentMethodValue}
            </PaymentMethod>
            <FullWidthInput
              htmlFor="masterkeyAccount"
              name="accountNumber"
              label={linkedMasterkeyAccountNumberLabel}
              changeHandler={this.handleChange}
              error={accountNumber.error.error}
              caption={linkedMasterkeyAccountNumberCaption}
              value={accountNumber.value}
              captionWithIcon={false}
            />

            <SmfDisplayText>{linkedMasterkeyDisclaimer}</SmfDisplayText>
            <Dropdown
              label={linkedMasterkeyPaymentDrawDateLabel}
              placeholder={linkedMasterkeyPaymentDrawDatePlaceholder}
              value={paymentDrawDate.value}
              name="paymentDrawDate"
              id="paymentDrawDate"
              error={paymentDrawDate.error.error}
              caption={paymentDrawDate.error.error && linkedMasterkeyPaymentDrawDateError}
              changeHandler={this.handleChange}
              options={schema.paymentDrawDate.options}
            />
            <PaymentFrequency>{frequency}</PaymentFrequency>
          </Form>
        </Wrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms }) => ({
  form: forms[FORM_ID],
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkedMasterKey)
