// @flow

// components.
import Projections from '../../Projections'

const SIDEBAR_SCHEMA = () => [
  {
    sitecoreFieldHeadingKey: 'projectionsTitle',
    component: Projections,
    width: 980,
  },
]

export default SIDEBAR_SCHEMA
