/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconCheckbox16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <g clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd" fill="#000">
      <path className="path1" d="M14 2H2v12h12V2zM0 0v16h16V0H0z" />
      <path
        className="path2"
        d="M7.114 9.803l3.25-5.2 1.272.794-4.25 6.8L3.72 8.53l1.06-1.06 2.334 2.333z"
      />
    </g>
    <defs>
      <clipPath>
        <path className="path3" fill="#000" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default IconCheckbox16
