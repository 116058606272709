// @flow
import moment from 'moment'
import get from 'lodash/get'

import {
  PREFERRED_YES,
  PREFERRED_NO,
  COUNTRY_CODE,
  ADDRESS_TYPE_WORK,
  POLICY_PARTY_TYPE_BUSINESS,
  POLICY_PARTY_TYPE_INDIVIDUAL,
  COMPANY_IDENTIFIER_NGRP,
  CONTACT_SUB_TYPE_WORK,
  CONTACT_SUB_TYPES,
  CONTACT_TYPES_TEL,
  ADDRESS_TYPE_STATEMENT,
  ADDRESS_TYPE_HOME,
} from '../constants/policies'
import { ADVISER_REGISTRATION_GENDER } from '../constants/adviserRegistration'
import { DATE_FORMAT, ALLOWED_DATE_FORMAT } from './quoteUtils'
import { REF_NO } from '../constants/adviser'

export const formToMemberEntityDirector = (formData, isDirector = false, isTrustee = false) => {
  const memberEntityStructure = {
    isPrimary: get(formData, 'isPrimary', false),
    relatedParty: {
      identifiers: [
        {
          type: REF_NO,
          value: formData.identifiers ? formData.identifiers : moment().unix().toString(),
        },
      ],

      ...(!isDirector ? { companyIdentifier: COMPANY_IDENTIFIER_NGRP } : {}),
      title: get(formData, 'title', ''),
      firstName: get(formData, 'firstName', ''),
      middleName: get(formData, 'middleName', ''),
      lastName: get(formData, 'lastName', ''),
      dateOfBirth: moment(get(formData, 'dateOfBirth', ''), ALLOWED_DATE_FORMAT, true).format(
        DATE_FORMAT
      ),
      companyRole: get(formData, 'companyRole', ''),
      contactMethods: {
        addresses: [],
        emails: [
          { email: get(formData, 'email', ''), preferred: PREFERRED_YES, type: ADDRESS_TYPE_WORK },
        ],
        ...(formData && formData.contactNumber
          ? {
              phones: [
                {
                  number: get(formData, 'contactNumber', ''),
                  preferred: PREFERRED_YES,
                  type: CONTACT_TYPES_TEL,
                  subType: CONTACT_SUB_TYPE_WORK,
                  idc: get(formData, 'contactNumberPhoneCode', ''),
                  countryCode: get(formData, 'contactNumberCountryCode'),
                },
              ],
            }
          : []),
      },
      partyType: isDirector ? POLICY_PARTY_TYPE_INDIVIDUAL : POLICY_PARTY_TYPE_BUSINESS,
      sameAsCompanyAddress: get(formData, 'sameAsCompanyAddress', false),
      ...(isDirector ? { gender: ADVISER_REGISTRATION_GENDER } : {}),
    },
  }

  const residentialManualAddress = {
    houseNo: get(formData, 'addressUnit', ''),
    street: get(formData, 'addressStreet', ''),
    locality: get(formData, 'addressLocality', ''),
    state: get(formData, 'addressState', ''),
    country: get(formData, 'addressCountry', '') || COUNTRY_CODE,
    postCode: get(formData, 'addressPostCode', '').toString(),
    preferred: PREFERRED_YES,
    addressType: isTrustee ? ADDRESS_TYPE_HOME : ADDRESS_TYPE_STATEMENT,
  }
  if (formData.secondaryNumber) {
    memberEntityStructure.relatedParty.contactMethods.phones.push({
      number: get(formData, 'secondaryNumber', ''),
      preferred: PREFERRED_NO,
      type: CONTACT_TYPES_TEL,
      subType: CONTACT_SUB_TYPES,
      idc: get(formData, 'secondaryNumberPhoneCode', ''),
      countryCode: get(formData, 'secondaryNumberCountryCode'),
    })
  }

  memberEntityStructure.relatedParty.contactMethods.addresses = [residentialManualAddress]
  return memberEntityStructure
}
