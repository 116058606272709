import { space, fontSize } from '../../../../../styles'

const styles = {
  root: {
    padding: space(3),
    width: space(50),
    position: 'relative',
  },
  disclaimer: {
    fontSize: fontSize.xxs,
  },
}

export default styles
