// @flow
import application from './application'
import createQuote from './createQuote'
import applicationSummary from './applicationSummary'
import preferences from './advisorPreferences'
import policies from './policies'
import searchPolicy from './searchPolicy'
import productRules from './productRules'
import quoteTemplates from './quoteTemplates'
import client from './client'
import quotes from './quotes'
import advisor from './advisor'
import occupation from './occupation'
import sidebar from './sidebar'
import ure from './ure'
import preAssessment from './preAssessment'
import enquiryIdExpiredModal from './enquiryIdExpiredModal'
import abnLookup from './abnLookup'
import captureCreditCard from './captureCreditCard'
import bsbLookup from './bsbLookup'
import fileUploadInfo from './fileUpload'
import addressLookup from './addressLookup'
import retentionDashboard from './retentionDashboard'
import nulis from './nulis'
import clientReport from './clientReport'
import dataFeedRegistration from './dataFeedRegistration'
import taskDashboard from './taskDashboard'
import contactMLC from './contactMLC'
import coverManagerDocuments from './coverManagerDocuments'
import correspondenceHistory from './correspondenceHistory'
import knowledgeBase from './knowledgeBase'
import fundsLookUp from './fundsLookup'
import adviserRegistration from './adviserRegistration'
import visualiser from './visualiser'
import calculateSaveQuote from './calculateSaveQuote'
import lifeAssured from './lifeAssured'
import thirdParty from './thirdParty'
import supportStaff from './supportStaff'

export default {
  application,
  applicationSummary,
  createQuote,
  client,
  advisor,
  policies,
  searchPolicy,
  preferences,
  productRules,
  quoteTemplates,
  occupation,
  sidebar,
  ure,
  preAssessment,
  enquiryIdExpiredModal,
  quotes,
  abnLookup,
  captureCreditCard,
  calculateSaveQuote,
  fileUploadInfo,
  bsbLookup,
  addressLookup,
  retentionDashboard,
  nulis,
  clientReport,
  dataFeedRegistration,
  contactMLC,
  taskDashboard,
  correspondenceHistory,
  coverManagerDocuments,
  knowledgeBase,
  fundsLookUp,
  adviserRegistration,
  visualiser,
  lifeAssured,
  thirdParty,
  supportStaff,
}
