// @flow
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

// constants
import {
  POLICY_BENEFIT_CI_PLUS,
  POLICY_BENEFIT_PTD,
  TPD_DEFINATION_ANY,
  POLICY_PRODUCT_CODE_NON_SUPER,
  TPD_DEFINATION_OWN,
  POLICY_RELATIONSHIPS_MEMBER,
  POLICY_IDENTIFIER_POLICY_ID,
  POLICY_STATUS_NA,
  POLICY_BENEFIT_HEP,
  POLICY_BENEFIT_ADB,
} from '../constants/policies'
import { BENEFIT_NATURE_TYPE_RIDER_OPTIMISER } from '../constants/benefit'

// utils
import {
  getAlreadyLinkedPolicy,
  getProduct,
  isParentInstanceSame,
  getAppliedConnectionRules,
  getParentBenefitReference,
  getBenefit,
  isNonSuperProduct,
  sortProducts,
  getUniquePolicyNo,
  createPolicy,
  getCoverSubBenefitsFromPreferences,
} from './quoteUtils'
import { getValue } from './formUtils'
import { convertBenefitCodeToFullName, getPolicyStatus } from './policyUtils'

export const updatePolicyStructureForLinkage = ({
  cover,
  createQuote,
  productRules,
  actions,
  selectedLinkageOption,
  advisorDetails,
  policyIndex,
  coverIndex,
}: Object) => {
  const {
    createQuoteUpdateBenefitLink,
    createQuoteUpdateTpdDefination,
    addBenefitToPolicy,
    addOptimerParentBenefitReference,
    addPolicyToQuote,
    removeBenefit,
    removeOptimiserParentBenefitReference,
  } = actions
  const { activeIndex, quotes } = createQuote
  const activeQuote = quotes[activeIndex]
  const { policyStructure } = activeQuote
  const activePolicy = policyStructure[policyIndex]
  const { type, policyInstanceNo: coverPolicyInstanceNo, benefitInstanceNo } = cover
  const alreadyLinkedPolicy = getAlreadyLinkedPolicy(policyStructure)
  if (type === POLICY_BENEFIT_CI_PLUS) {
    let pIndex
    const connectedPolicy = policyStructure.find((p, index) => {
      if (
        Number(p.policyInstanceNo) !== Number(cover.policyInstanceNo) &&
        Number(cover.policyInstanceNo) ===
          Number(
            alreadyLinkedPolicy[p.policyInstanceNo] &&
              (alreadyLinkedPolicy[p.policyInstanceNo].parent ||
                alreadyLinkedPolicy[p.policyInstanceNo].child)
          )
      ) {
        pIndex = index
        return true
      }
      return false
    })
    if (connectedPolicy) {
      const connectedProductRules = getProduct(productRules, connectedPolicy.productId)
      const updatedPolicyStructure = policyStructure.map(pol => {
        if (pol.policyInstanceNo === activePolicy.policyInstanceNo) {
          return {
            ...pol,
            covers: pol.covers.map(cov =>
              cov.benefitInstanceNo === benefitInstanceNo
                ? {
                    ...cov,
                    parentBenefitReference: getValue(selectedLinkageOption),
                  }
                : { ...cov }
            ),
          }
        }
        if (pol.policyInstanceNo === connectedPolicy.policyInstanceNo) {
          return {
            ...pol,
            covers: pol.covers.map(cov =>
              isParentInstanceSame(cov, cover, coverPolicyInstanceNo)
                ? {
                    ...cov,
                    parentBenefitReference: null,
                  }
                : { ...cov }
            ),
          }
        }
        return { ...pol }
      })
      connectedPolicy.covers.forEach((cov, covIndex) => {
        if (isParentInstanceSame(cov, cover, coverPolicyInstanceNo)) {
          const appliedConnectionRules = getAppliedConnectionRules(
            getBenefit(connectedProductRules.benefits, cov.type),
            [connectedPolicy],
            connectedPolicy
          )
          const parentBenefitReference = getParentBenefitReference(
            appliedConnectionRules,
            updatedPolicyStructure
          )
          createQuoteUpdateBenefitLink({
            policyIndex: pIndex,
            coverIndex: covIndex,
            value: parentBenefitReference.parentBenefitReference,
          })
        }
      })
    }
  }
  if (type === POLICY_BENEFIT_PTD) {
    let tobeDeletedCover
    policyStructure.forEach(p => {
      p.covers.forEach(cov => {
        if (isParentInstanceSame(cov, cover, coverPolicyInstanceNo)) {
          tobeDeletedCover = {
            ...cov,
            policyInstanceNo: String(p.policyInstanceNo),
            quoteIndex: activeIndex,
          }
        }
      })
    })

    if (
      selectedLinkageOption.value.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER &&
      selectedLinkageOption.value.parentType === cover.type &&
      selectedLinkageOption.value.parentBenefitInstanceNo.toString() ===
        cover.benefitInstanceNo.toString() &&
      selectedLinkageOption.value.parentPolicyReferenceNo.toString() ===
        activePolicy.policyInstanceNo.toString() &&
      !tobeDeletedCover
    ) {
      const clonedCover = cloneDeep(cover)
      clonedCover.parentBenefitReference = selectedLinkageOption.value
      // Adding optimiserParentBenefitReference in new cover created (optimiser cover)
      clonedCover.optimiserParentBenefitReference = {
        parentPolicyReferenceNo: cover.parentBenefitReference.parentPolicyReferenceNo,
        parentType: cover.parentBenefitReference.parentType,
        parentBenefitInstanceNo: cover.parentBenefitReference.parentBenefitInstanceNo,
        benefitNature: BENEFIT_NATURE_TYPE_RIDER_OPTIMISER,
      }
      clonedCover.premiumAmount = '0'
      const linkedPolicy = alreadyLinkedPolicy[coverPolicyInstanceNo]
      const parentPolicyInstance = linkedPolicy
        ? linkedPolicy.parent || linkedPolicy.child
        : get(
            policyStructure.find(
              p =>
                !alreadyLinkedPolicy[p.policyInstanceNo] &&
                String(p.policyInstanceNo) !== String(coverPolicyInstanceNo)
            ),
            'policyInstanceNo'
          )
      if (parentPolicyInstance) {
        const indexOfPolicy = policyStructure.findIndex(
          p => String(p.policyInstanceNo) === String(parentPolicyInstance)
        )
        const policyInstance = policyStructure[indexOfPolicy]

        // Only applicable sub-benefits for cloned cover
        const connectedProductRules = getProduct(productRules, policyInstance.productId)
        const productCover = getBenefit(connectedProductRules.benefits, cover.type)
        const { subBenefits } = getCoverSubBenefitsFromPreferences(productCover)
        clonedCover.subBenefits = subBenefits

        if (
          clonedCover.tpdDefinition === TPD_DEFINATION_ANY &&
          activePolicy.productId === POLICY_PRODUCT_CODE_NON_SUPER
        ) {
          createQuoteUpdateTpdDefination({
            policyInstanceNo: activePolicy.policyInstanceNo,
            benefitInstanceNo,
            type: cover.type,
            value: TPD_DEFINATION_OWN,
          })
        }
        clonedCover.tpdDefinition = isNonSuperProduct(policyInstance.productId)
          ? TPD_DEFINATION_OWN
          : TPD_DEFINATION_ANY

        clonedCover.policyInstanceNo = parentPolicyInstance
        clonedCover.benefitInstanceNo =
          policyInstance.covers.filter(c => c.type === type).length + 1
        addBenefitToPolicy(clonedCover, indexOfPolicy, policyInstance.policyFee)
        // adding optimiserParentBenefitReference to parent cover
        addOptimerParentBenefitReference(
          cover,
          coverPolicyInstanceNo,
          clonedCover.optimiserParentBenefitReference
        )
      } else {
        const { outsideSuperProduct } = sortProducts(productRules)
        const newPolicyNo = getUniquePolicyNo(policyStructure)
        clonedCover.policyInstanceNo = newPolicyNo
        clonedCover.benefitInstanceNo = 1
        clonedCover.tpdDefinition = TPD_DEFINATION_OWN
        const policy = createPolicy(
          productRules,
          outsideSuperProduct,
          newPolicyNo,
          [],
          advisorDetails,
          [],
          activeQuote.memberMandatories,
          activeQuote.option
        )
        policy.covers = [...policy.covers, clonedCover]
        const clonedRelationships = policy.relationships && cloneDeep(policy.relationships)
        const filterdRelationships =
          clonedRelationships &&
          clonedRelationships.map(r => ({
            ...r,
            role: [POLICY_RELATIONSHIPS_MEMBER],
          }))
        if (filterdRelationships) {
          policy.relationships.push(...filterdRelationships)
        }

        // Only applicable sub-benefits for cloned cover
        const productCover = getBenefit(outsideSuperProduct.benefits, cover.type)
        const { subBenefits } = getCoverSubBenefitsFromPreferences(productCover)
        clonedCover.subBenefits = subBenefits

        addPolicyToQuote(policy)
        // adding optimiserParentBenefitReference to parent cover
        addOptimerParentBenefitReference(
          cover,
          coverPolicyInstanceNo,
          clonedCover.optimiserParentBenefitReference
        )
      }
    }
    // if selectedLinkageOption is not optimiser option for parent tpd
    else if (
      !(
        selectedLinkageOption.value.benefitNature === BENEFIT_NATURE_TYPE_RIDER_OPTIMISER &&
        selectedLinkageOption.value.parentType === cover.type &&
        selectedLinkageOption.value.parentBenefitInstanceNo.toString() ===
          cover.benefitInstanceNo.toString() &&
        selectedLinkageOption.value.parentPolicyReferenceNo.toString() ===
          activePolicy.policyInstanceNo.toString()
      )
    ) {
      if (tobeDeletedCover) {
        removeBenefit(tobeDeletedCover)
      }
      if (selectedLinkageOption.value.benefitNature !== BENEFIT_NATURE_TYPE_RIDER_OPTIMISER) {
        removeOptimiserParentBenefitReference(cover, coverPolicyInstanceNo)
      }

      createQuoteUpdateBenefitLink({
        policyIndex,
        coverIndex,
        value: getValue(selectedLinkageOption),
      })
    }
  } else {
    createQuoteUpdateBenefitLink({
      policyIndex,
      coverIndex,
      value: getValue(selectedLinkageOption),
    })
  }
}

export const getExtendedBenefitName = (benefit, bancsPolicyNo, masterData) => {
  const { parentBenefitReference, optimiserParentBenefitReference } = benefit
  const benefitReference = optimiserParentBenefitReference || parentBenefitReference
  if (benefitReference && benefitReference.parentPolicyNo === bancsPolicyNo) {
    return `${convertBenefitCodeToFullName(masterData, benefitReference.parentBenefitCode)} ${
      benefitReference.parentBenefitInstanceNo
    }`
  }
  return ''
}

export const getConnectedBenefitData = (benefit, bancsPolicyNo, masterData, policies) => {
  const { parentBenefitReference, optimiserParentBenefitReference } = benefit
  const benefitReference = optimiserParentBenefitReference || parentBenefitReference
  if (benefitReference && benefitReference.parentPolicyNo !== bancsPolicyNo) {
    // finding connected policy number from bancsPolicyNo
    let parentPolicyIndex = null
    const parentPolicy = policies?.find((policy, index) => {
      if (policy.bancsPolicyNo === benefitReference.parentPolicyNo) {
        parentPolicyIndex = index
        return true
      }
      return false
    })
    const parentPolicyIdentifier = get(parentPolicy, 'policy.identifiers', []).find(
      identifier => identifier.type === POLICY_IDENTIFIER_POLICY_ID
    )

    return {
      benefitName: `${convertBenefitCodeToFullName(
        masterData,
        benefitReference.parentBenefitCode
      )} ${benefitReference.parentBenefitInstanceNo}`,
      policyNumber: parentPolicyIdentifier?.value,
      isPolicyOutOfForce:
        getPolicyStatus(parentPolicy?.policy?.status, masterData.policyStatus) !== POLICY_STATUS_NA,
      /*
        Since we are making API call to get all policies where client is policy owner,
        if connected policy is not present in API response that means client is not policy owner
        in connected policy
      */
      isClientNotPolicyOwnerOfPolicy: !parentPolicy,
      parentPolicyIndex,
    }
  }
  return {
    benefitName: '',
    policyNumber: '',
    isPolicyOutOfForce: false,
    isClientNotPolicyOwnerOfPolicy: false,
    parentPolicyIndex: null,
  }
}

export const getTotalChildSumInsured = (selectedBenefit, policyStructure, policyInstanceNo) => {
  let totalSumInsured = 0
  policyStructure.forEach(policy => {
    const alteredBenefits = policy.alteration?.alteredBenefits
    policy.covers.forEach(cover => {
      // checking if cover(variable) is child of selectedBenefit(variable)
      if (
        isParentInstanceSame(cover, selectedBenefit, policyInstanceNo) &&
        cover.type !== POLICY_BENEFIT_HEP &&
        cover.type !== POLICY_BENEFIT_ADB
      ) {
        if (alteredBenefits) {
          const alteredCover = alteredBenefits.find(
            item =>
              item.benefitCode === cover.type &&
              String(item.benefitInstanceNo) === String(cover.benefitInstanceNo)
          )
          const coverSumInsured = Number(alteredCover?.newSumInsured || cover.coverAmount)
          totalSumInsured += coverSumInsured
        } else {
          totalSumInsured += Number(cover.coverAmount)
        }
      }
    })
  })
  return totalSumInsured
}
