import {
  colours,
  fontSize,
  inputPadding,
  shadows,
  space,
  fontWeights,
  mediaQueries,
} from '../../../styles'

// utils
import { setErrorColor } from '../../../utils/stylesUtils'
import browser from '../../../utils/browserUtils'

const isIE11 = browser.ie && browser.ie === 11

const optionStyles = {
  sm: {
    paddingHorizontal: space(2),
    paddingVertical: space(1),
    height: space(5),
    fontSize: fontSize.xs,
    borderColor: colours.lightestGrey,
  },
  lg: {
    paddingHorizontal: space(3),
    paddingVertical: space(3),
    height: space(9),
    fontSize: fontSize.sm,
  },
}

const setOptionBg = (state, defaultColor) => {
  if (state.isSelected) return colours.lightGrey
  if (state.isFocused) return colours.lightestGrey
  return defaultColor
}

const checkDropdownIndicator = customComponents =>
  customComponents &&
  customComponents.DropdownIndicator &&
  customComponents.DropdownIndicator() === null

export const styles = {
  primary: ({
    theme,
    error,
    isAsync,
    isSearchable,
    customComponents,
    noOfDropdownOptionToBeDisplayed,
  }) => ({
    option: (provided, state) => ({
      ...provided,
      minHeight: space(6),
      fontSize: fontSize.xs,
      color: theme.text,
      paddingLeft: inputPadding.sm.horizontal,
      paddingRight: inputPadding.sm.horizontal,
      paddingTop: inputPadding.sm.vertical,
      paddingBottom: inputPadding.sm.vertical,
      display: 'inline-flex',
      alignItems: 'center',
      background: state.isDisabled ? colours.mediumDarkGrey : setOptionBg(state, colours.white),
      '&:nth-child(odd)': {
        background: state.isDisabled ? colours.mediumGrey : setOptionBg(state, colours.offWhite),
      },
    }),
    valueContainer: () => ({
      flex: 1,
      paddingLeft: inputPadding.sm.horizontal,
      ...(checkDropdownIndicator(customComponents)
        ? { paddingRight: space(2) + inputPadding.sm.horizontal * 3 }
        : { marginRight: space(2) + inputPadding.sm.horizontal * 3 }),
      paddingTop: space(1),
      paddingBottom: space(1),
      color: theme.text,
      fontSize: fontSize.xs,
      position: 'relative',
      overflow: 'hidden',
      height: 48,
    }),
    placeholder: () => ({
      margin: 0,
      color: setErrorColor(error, colours.red, theme.placeholder),
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    loadingIndicator: provided => ({
      ...provided,
      ...(isAsync ? { position: 'absolute', top: space(2), right: 0 } : null),
    }),
    dropdownIndicator: () => ({
      position: 'absolute',
      paddingTop: inputPadding.sm.vertical,
      right: inputPadding.sm.horizontal * 2,
      top: 0,
      height: space(6),
      width: space(2),
      svg: {
        color: setErrorColor(error, colours.red, colours.black),
      },
    }),
    control: (provided, state) => ({
      maxHeight: space(6),
      border: 'none',
      margin: 0,
      borderWidth: state.isFocused ? 0 : 1,
      borderStyle: state.isFocused ? 'none' : 'solid',
      cursor: checkDropdownIndicator(customComponents) ? 'text' : 'pointer',
      borderColor: setErrorColor(
        error,
        colours.red,
        state.isFocused || state.hasValue ? theme.active.border : theme.border
      ),
      outline: state.isFocused ? '-webkit-focus-ring-color auto 5px' : 'none',
      '&:after': {
        content: '""',
        height: 0,
        position: 'absolute',
        bottom: 0,
        left: 1,
        right: 1,
        background: setErrorColor(
          error,
          colours.red,
          state.isFocused ? theme.active.border : theme.border
        ),
      },
      background: theme.bg,
      '&:hover:not(:disabled)': {
        borderColor: setErrorColor(
          error,
          colours.red,
          state.isFocused ? theme.active.border : theme.hover.border
        ),
        '&:after': {
          background: setErrorColor(
            error,
            colours.red,
            state.isFocused ? theme.active.border : theme.hover.border
          ),
        },
      },
    }),
    menu: () => ({
      padding: 0,
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      maxHeight: noOfDropdownOptionToBeDisplayed ? noOfDropdownOptionToBeDisplayed * 50 : space(30),
      borderBottom: !isSearchable ? '1px solid' : 'none',
      overflowY: 'auto',
    }),
    menuList: () => ({
      background: colours.white,
      borderWidth: 1,
      borderColor: theme.active.border,
      borderTopWidth: 0,
      borderStyle: 'solid',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: error ? colours.red : 'inherit',
    }),
  }),
  custom: ({ size, theme, error, isAsync }) => {
    const sizeStyles = optionStyles[size]
    return {
      option: (provided, state) => ({
        ...provided,
        minHeight: sizeStyles.height,
        fontSize: sizeStyles.fontSize,
        color: theme.optionText || sizeStyles.color,
        paddingTop: sizeStyles.paddingVertical,
        paddingBottom: sizeStyles.paddingVertical,
        paddingLeft: sizeStyles.paddingHorizontal,
        paddingRight: sizeStyles.paddingHorizontal,
        background: state.isSelected || state.isFocused ? colours.offWhite : '',
        display: 'inline-flex',
        alignItems: 'center',
        '&:nth-child(odd)': {
          background: colours.offWhite,
        },
        '&:hover': {
          background: colours.offWhite,
        },
      }),
      valueContainer: () => ({
        flex: 1,
        paddingTop: sizeStyles.paddingVertical,
        paddingBottom: sizeStyles.paddingVertical,
        paddingLeft: sizeStyles.paddingHorizontal,
        paddingRight: sizeStyles.paddingHorizontal,
        height: sizeStyles.height,
        fontSize: sizeStyles.fontSize,
        position: 'relative',
        overflow: 'hidden',
        background: error ? colours.lightRed : theme.bg || colours.white,
      }),
      placeholder: () => ({
        margin: 0,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: error ? colours.red : 'inheirt',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
      loadingIndicator: provided => ({
        ...provided,
        ...(isAsync ? { position: 'absolute', top: space(2), right: 0 } : null),
      }),
      control: (provided, state) => ({
        height: sizeStyles.height,
        border: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        margin: 0,
        borderColor: sizeStyles.borderColor,
        boxShadow: state.isFocused ? shadows.mid : 'none',
      }),
      menu: () => ({
        padding: 0,
        boxShadow: shadows.mid,
        ...(isAsync ? { maxHeight: 300, overflowY: 'scroll' } : null),
      }),
      menuList: () => ({
        background: colours.white,
      }),
      singleValue: (provided, state) => ({
        ...provided,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        color: error ? colours.red : 'inherit',
      }),
    }
  },
  secondary: ({ theme, error, isAsync }) => ({
    option: (provided, state) => ({
      ...provided,
      color: theme.text,
      background: setOptionBg(state, 'inherit'),
      '&:nth-child(odd)': {
        background: setOptionBg(state, colours.offWhite),
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'inherit',
      svg: {
        color: error ? colours.red : 'inherit',
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    loadingIndicator: provided => ({
      ...provided,
      ...(isAsync ? { position: 'absolute', top: space(2), right: 0 } : null),
    }),
    valueContainer: provided => ({
      ...provided,
      fontWeight: fontWeights.semiBold,
      padding: 0,
      justifyContent: 'center',
      background: error && colours.lightRed,
    }),
    placeholder: provided => ({
      ...provided,
      margin: 0,
      color: error ? colours.red : 'inherit',
    }),
    control: provided => ({
      ...provided,
      borderWidth: 0,
      borderStyle: 'none',
      borderRadius: 0,
      backgroundColor: 'none',
      minHeight: 0,
    }),
    menu: provided => ({
      ...provided,
      padding: 0,
      boxShadow: shadows.mid,
      ...(isAsync ? { maxHeight: 300, overflowY: 'scroll' } : null),
    }),
    menuList: provided => ({
      ...provided,
      background: colours.white,
      textAlign: 'left',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: error ? colours.red : 'inherit',
      transform: isIE11 ? 'translate(-1.5rem, -50%)' : 'translateY(-50%)',
    }),
    input: provided => ({
      ...provided,
    }),
  }),
  tertiary: ({ theme, error, isAsync }) => ({
    option: (provided, state) => ({
      ...provided,
      color: theme.text,
      background: state.isDisabled ? colours.lightGrey : setOptionBg(state, 'inherit'),
      borderBottom: `1px solid ${colours.lightestGrey}`,
      '&:last-child': {
        borderBottom: 0,
      },
      [mediaQueries.xs]: {
        fontSize: fontSize.xs,
      },
      [mediaQueries.sm]: {
        fontSize: fontSize.sm,
      },
      [mediaQueries.md]: {
        fontSize: fontSize.sm,
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'inherit',
      svg: {
        color: error ? colours.red : 'inherit',
        width: '1em',
        height: '1em',
      },
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    loadingIndicator: provided => ({
      ...provided,
      ...(isAsync ? { position: 'absolute', top: space(2), right: 0 } : null),
    }),
    valueContainer: provided => ({
      ...provided,
      fontWeight: fontWeights.semiBold,
      padding: 0,
      justifyContent: 'center',
      background: error && colours.lightRed,
      minHeight: space(7),
      '&:focus': {
        outline: 'none',
      },
    }),
    placeholder: provided => ({
      ...provided,
      width: 'max-content',
      margin: 0,
      fontSize: fontSize.xl,
      fontWeight: fontWeights.semiBold,
      color: error ? colours.red : colours.white,
      minHeight: space(7),
      marginLeft: 0,
      textAlign: 'left',
      left: 0,
      [mediaQueries.xs]: {
        fontSize: fontSize.lg,
      },
      [mediaQueries.sm]: {
        fontSize: fontSize.xl,
      },
      [mediaQueries.md]: {
        fontSize: fontSize.xl,
      },
    }),
    control: provided => ({
      ...provided,
      borderWidth: 0,
      borderStyle: 'none',
      borderRadius: 0,
      backgroundColor: 'none',
      minHeight: space(7),
      boxShadow: 'none',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
      '&:hover': {
        borderColor: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    menu: provided => ({
      ...provided,
      padding: 0,
      boxShadow: shadows.mid,
      ...(isAsync ? { maxHeight: 300, overflowY: 'scroll' } : null),
    }),
    menuList: provided => ({
      ...provided,
      background: colours.white,
      textAlign: 'left',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: error ? colours.red : 'inherit',
    }),
    input: provided => ({
      ...provided,
    }),
  }),
}

export default styles
