// @flow
import { EXPERIENCE_API } from '../actions/types/experienceApi'
import { createEvent } from '../utils/telemetry'

import {
  makeActions,
  makeRequestObj,
  makeQueryString,
  TYPE,
  isValidResponse,
  networkLog,
  makeRequestHeaders,
} from '../utils/middlewareUtils'

const isValidRequest = (action: { route: string, type: string }): boolean =>
  typeof action === 'object' && action.type === EXPERIENCE_API && action.route

const apiService =
  (store: { getState: Function }) =>
  (next: Function): Object =>
  (action: {|
    additionalHeaders?: Object,
    callback?: Function,
    errorPayload?: Object,
    name: string,
    query?: Object,
    route: string,
    type: string,
    unAuthenticated?: boolean,
    verb?: string,
    metaData?: Object,
    queuedAction?: Function,
    removeHeaders?: Array<string>,
  |}): Object => {
    // validate if the action needs to be captured by this middleware, otherwise pass to next().
    if (!isValidRequest(action)) return next(action)
    const {
      name,
      callback,
      failureCallback,
      route,
      verb,
      query,
      unAuthenticated,
      additionalHeaders = {},
      errorPayload = {},
      metaData = {},
      queuedAction,
      removeHeaders,
    } = action

    let isUnauthenticatedRequest = false
    const queryString = query ? makeQueryString(query) : ''
    const { success, failure, error, init } = name ? makeActions(name) : TYPE

    const {
      okta: { token },
      config,
    } = store.getState()

    const { dispatch } = store
    // get the domain for the api from the environment variable.
    const API_DOMAIN: string = config.MLCL_EXPERIENCE_API

    if (action.route.includes('/general/gerneralRequest')) {
      isUnauthenticatedRequest = true
    }

    try {
      new Promise(resolve => {
        resolve()
      })
        .then(() => {
          if (unAuthenticated) {
            return Promise.resolve({})
          }
          // pass additional headers along to create the request object.
          return makeRequestHeaders(
            'application/json',
            token,
            additionalHeaders,
            isUnauthenticatedRequest,
            removeHeaders
          )
        })
        // assemble complete headers.
        .then(headers => makeRequestObj(action, headers, true))
        // make the api call.
        .then(request => {
          next({
            type: init,
            payload: request,
          })
          const event = createEvent({
            Splunk: {
              attributes: {
                'workflow.name': 'Experience API Fetch',
                endpoint: route,
                transactionId: request?.headers?.transactionId,
              },
            },
          })
          event.end()

          return fetch(`${API_DOMAIN}${route}${queryString}`, request)
        })
        // process the response from the api into our standard format.
        .then(response =>
          response.json().then(data => ({
            dataReceived: data,
            status: response.status,
          }))
        )
        .then(({ status, dataReceived }) => {
          // check if response is valid, or an error response has been received.
          const valid = isValidResponse(status)
          const err = valid ? false : dataReceived
          const type = valid ? success : failure
          const data = callback ? callback(err, dataReceived, action.data) : dataReceived
          networkLog(`${verb || 'GET'} - ${type}`, status)

          if (Object.keys(metaData).length > 0) {
            data.metaData = metaData
          }

          return next({
            type,
            payload: data,
            error: !valid,
          })
        })
        .then(prevAction => {
          if (queuedAction) {
            dispatch(queuedAction(prevAction))
          }
        })
        // error triggered from the api response.
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('Experience API fetch error', err.toString())

          if (failureCallback) {
            failureCallback(err)
          }
          return next({
            type: error,
            payload: {
              message: err,
              ...errorPayload,
            },
            error: true,
          })
        })
    } catch (err) {
      // a failure occurred inside this middleware.
      // eslint-disable-next-line no-console
      console.error('Experience API middleware failure', err.toString())

      return next({
        type: failure,
        payload: {
          message: err,
          ...errorPayload,
        },
        error: true,
      })
    }

    return {}
  }

export default apiService
