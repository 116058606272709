import styled from '@emotion/styled'

import Button from '../../atoms/Button'

import styles from './fieldCaptionCta.styles'

// Component for CTA(button) in caption below form input field
const FieldCaptionCta = styled(Button)(styles.fieldCaptionCta)

export default FieldCaptionCta
