// @flow
// constants.
import {
  SHOW_MODAL,
  HIDE_MODAL,
  INIT_STATE,
  SET_MODAL_DESCRIPTION,
  INIT_MODAL,
} from '../types/modal'

export const initModal = modalType => ({
  type: INIT_MODAL,
  payload: {
    modalType,
  },
})

export const showModal = modalType => ({
  type: SHOW_MODAL,
  payload: {
    modalType,
  },
})

export const hideModal = modalType => ({
  type: HIDE_MODAL,
  payload: {
    modalType,
  },
})

export const resetAllSavedState = (adviserNumber: String, bancsAdviserCustomerNo: String) => ({
  type: INIT_STATE,
  payload: {
    adviserNumber,
    bancsAdviserCustomerNo,
  },
})

type setModalDescriptionPayloadType = {
  modalType: string,
  description: string,
}
export const setModalDescription = (payload: setModalDescriptionPayloadType) => ({
  type: SET_MODAL_DESCRIPTION,
  payload,
})
