// @flow
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { getLifeAsssured } from '../utils/quoteUtils'
import { POLICY_MEMBER_IDENTIFIER_CUSTOMER } from '../constants/policies'
import { ADVISER_PRESENTED_LICOP_FLYER } from '../constants/application'
import { DECISON_TYPE_NON_STANDARD } from '../constants/ure'
import { getPolicyStructure, getProductRules, getHealthyEligibleCovers } from './common.selectors'
import { checkQuoteToolPolicyEligibleForHealthyLiving } from '../utils/extendedQuoteUtils'

const getCreateQuote = state => state.createQuote

// Gets the LA bancsCustomerNo from createQuote.
// We will store the bancsCustomerNo in the relationship entity
// in case the LA is an existing client of the advisor
export const getExistingClientBancsCustomerNo = state => {
  const quoteCollection = getCreateQuote(state)
  const lifeAssured = getLifeAsssured(quoteCollection)
  if (!lifeAssured) return null
  const identifiers = get(lifeAssured, 'relatedParty.identifiers', null)
  if (!identifiers) return null
  return get(
    identifiers.find(({ type }) => type === POLICY_MEMBER_IDENTIFIER_CUSTOMER),
    'value',
    null
  )
}

export const getIsAnyPolicyAltered = createSelector(getPolicyStructure, policyStructure =>
  policyStructure?.some(policy => get(policy, 'alteration.isPolicyAltered', false))
)

export const getIsHealthyLivingDisabled = createSelector(
  [getPolicyStructure, getProductRules],
  (policyStructure, productRules) => {
    const eligibleHealthyCovers = getHealthyEligibleCovers(productRules)
    return !checkQuoteToolPolicyEligibleForHealthyLiving(policyStructure, eligibleHealthyCovers)
  }
)

// used in alteration manual quote
// returns bancsPolicyNo for altered policy or for first policy
export const getAltsQuoteBancsPolicyNo = createSelector(
  createQuote => createQuote,
  createQuote => {
    const { policyStructure } = createQuote.quotes[createQuote.activeIndex]
    const alteredPolicy = policyStructure.find(policy => get(policy, 'alteration.isPolicyAltered'))
    if (alteredPolicy) {
      return alteredPolicy.bancsPolicyNo
    }
    return policyStructure[0] && policyStructure[0].bancsPolicyNo
  }
)

export const getIsRecalculating = createSelector(
  [getCreateQuote],
  createQuote => createQuote?.isRecalculating
)

export const hasAdviserBeenPresentedLicopFlyer = createSelector([getCreateQuote], createQuote => {
  const activeQuote = createQuote?.quotes?.[createQuote?.activeIndex]

  const isUWDecisionNonStandard = !!activeQuote?.underwritingDetails?.status?.find(
    eachStatus => eachStatus?.underwritingDecision === DECISON_TYPE_NON_STANDARD
  )

  return (
    isUWDecisionNonStandard &&
    !activeQuote?.consents?.find(consent => consent?.name === ADVISER_PRESENTED_LICOP_FLYER)
  )
})
