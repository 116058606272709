import { space, setColumns, fontSize, colours } from '../../../../../styles'

const styles = {
  base: {
    padding: space(3),
    width: 400,
    ...setColumns(2),
    '& > *': {},
    position: 'relative',
  },
  title: {
    marginRight: `calc(50% + ${space(1, true)})`,
    width: `calc(50% - ${space(1, true)})`,
  },
  postalAddressCheckbox: {
    zIndex: 0,
    marginBottom: space(3),
  },
  halfColumn: {
    width: `calc(50% - ${space(1, true)})`,
  },
  buttonContainer: (secondary = false) => ({
    fontSize: fontSize.xxs,
    color: !secondary ? colours.mediumGreen : colours.mediumGrey,
    position: 'absolute',
    right: 0,
    bottom: 0,
  }),
  button: {
    color: 'inherit',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
  },
  contactNumberContainer: {
    position: 'relative',
    width: '100%',
  },
}
export default styles
