import { colours, fontSize, space, shadows, fontWeights, mediaQueries } from '../../../styles'

const dimensions = {
  outer: 16,
  inner: 12,
}

const switchWidth = 26
const toggleSize = 14

const commonStyles = {
  label: {
    color: colours.darkestGrey,
    cursor: 'pointer',
    fontSize: fontSize.xss,
    lineHeight: fontSize.xs,
    position: 'relative',
    float: 'left',
    textAlign: 'center',
    width: '104px',
    height: '88px',
    paddingTop: '45px',
    border: `solid 1px ${colours.lightGrey}`,
    boxShadow: shadows.low,
    userSelect: 'none',
    backgroundColor: colours.white,
    marginRight: space(2, true),
    marginBottom: space(1, true),
    marginTop: space(1),
    '&:focus': {
      outline: 'none',
    },
    '&>svg': {
      color: colours.darkestGrey,
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: `${dimensions.inner}px`,
      height: `${dimensions.inner}px`,
    },
  },
  input: () => ({
    opacity: '0',
    position: 'absolute',
    zIndex: '1',
    '&:checked + label': {
      '&::after': {
        borderColor: colours.darkestGrey,
      },
      background: colours.lightGreen,
      color: colours.white,
      borderColor: colours.lightGreen,
    },
    '&:checked + label svg': {
      color: colours.white,
    },
    '&:disabled + label svg, &:disabled + label': {
      color: colours.lightGrey,
    },
    '&:disabled + label': {
      borderColor: colours.lightestGrey,
    },
  }),
}

const styles = {
  wrapper: {
    display: 'inline-flex',
    position: 'relative',
    width: '100%',

    [mediaQueries.md]: {
      width: 'auto',
    },
  },
  normal: {
    label: {
      color: colours.darkestGrey,
      cursor: 'pointer',
      fontSize: fontSize.xs,
      lineHeight: fontSize.sm,
      paddingLeft: space(3),
      position: 'relative',
      display: 'block',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '0',
        top: space(1.5, true),
        transform: 'translateY(-50%)',
        backgroundColor: colours.white,
        boxShadow: `inset 0 0 0 2px ${colours.darkestGrey}`,
        height: dimensions.outer,
        width: dimensions.outer,
      },

      '&:focus': {
        outline: 'none',
      },
      '&>svg': {
        color: colours.darkestGrey,
        position: 'absolute',
        top: space(1.5, true),
        transform: 'translateY(-50%)',
        width: `${dimensions.inner}px`,
        height: `${dimensions.inner}px`,
        left: `${(dimensions.outer - dimensions.inner) / 2}px`,
      },
    },
    // eslint-disable-next-line no-unused-vars
    input: theme => ({
      opacity: '0',
      position: 'absolute',
      zIndex: '1',
      top: dimensions.outer - dimensions.inner,
      width: dimensions.outer,
      height: dimensions.outer,

      '&:disabled + label': {
        color: colours.lightestGrey,
        cursor: 'not-allowed',
        '&:before': {
          boxShadow: `inset 0 0 0 2px ${colours.lightestGrey}`,
        },
      },

      '&:checked + label': {
        '&::after': {
          backgroundColor: colours.darkestGrey,
        },
      },
    }),
  },
  tick: {
    color: colours.darkestGrey,
    position: 'absolute',
    top: '44%',
    transform: 'translateY(-50%)',
    width: dimensions.inner,
    height: dimensions.inner,
    left: (dimensions.outer - dimensions.inner) / 2,
  },

  toggle: {
    left: {
      label: {
        paddingLeft: switchWidth + space(2),
        '&:before': {
          left: 0,
        },
        '&:after': {
          left: 1,
        },
      },
      input: {
        '&:checked + label:after': {
          transform: 'translateX(-100%)',
          left: switchWidth - 1,
        },
      },
    },
    right: {
      label: {
        paddingRight: switchWidth + space(2),
        '&:before': {
          right: 0,
        },
        '&:after': {
          right: switchWidth - toggleSize - 1,
        },
      },
      input: {
        '&:checked + label:after': {
          transform: 'translateX(0)',
          right: 1,
        },
      },
    },

    label: {
      cursor: 'pointer',
      display: 'inline-flex',
      position: 'relative',

      '&:before': {
        backgroundColor: colours.lightGrey,
        borderRadius: 100,
        width: switchWidth,
        height: 16,
        content: '""',
        top: 0,
        position: 'absolute',
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 1,
        width: toggleSize,
        height: toggleSize,
        background: '#fff',
        borderRadius: '50%',
        transition: '0.3s',
      },

      '&:focus': {
        outline: 'none',
      },
    },
    // eslint-disable-next-line no-unused-vars
    input: theme => ({
      height: '0',
      width: '0',
      position: 'absolute',
      visibility: 'hidden',
      zIndex: '1',
      '&:checked + label': {
        '&:before': {
          background: colours.lightGreen,
          borderColor: colours.lightGreen,
        },
      },
    }),
  },
  addbox: commonStyles,
  box: {
    label: {
      ...commonStyles.label,
      fontSize: fontSize.sm,
      lineHeight: fontSize.md,
      fontWeight: fontWeights.semiBold,
      width: '100%',
      height: 'auto',
      padding: `${space(7.5, true)} ${space(3, true)}`,
      marginRight: 0,

      [mediaQueries.md]: {
        width: space(30, true),
        marginRight: space(2, true),
      },
    },
    input: commonStyles.input,
  },
}

export default styles
