// constants
import { PREFERRED_NO, PREFERRED_YES } from './policies'
import { CONTACT_TYPES } from './forms'

export const DEFAULT_EMAIL_ENTITY = {
  type: 'H',
  email: '',
  preferred: 'Y',
}

export const MOBILE_ENTITY = {
  type: 'TEL',
  number: '',
  preferred: 'Y',
  countryCode: 'AUS',
  subType: 'W',
}

export const PHONE_ENTITY = {
  preferred: PREFERRED_NO,
  subType: CONTACT_TYPES.HOME,
}

export const DEFAULT_PHONE_ENTITY = {
  type: CONTACT_TYPES.TEL,
  subType: CONTACT_TYPES.MOBILE,
  preferred: PREFERRED_YES,
}

export const DEFAULT_PHONE_CODE = '61'
export const OVERSEAS_STATE = 'OVRS'
export const DATE_OF_BIRTH_FORMAT = 'DD/MM/YYYY'
export const API_DATE_OF_BIRTH_FORMAT = 'YYYY-MM-DD'
export const COMMUNICATION_CHANNEL_MAP = {
  PPR: 'Paper',
  DIGI: 'Digital',
} as const

export const LOA_DURATION_INDEFINITE = 'Indefinite'
export const LOA_TYPE_INDIVIDUAL = 'Individual'
export const LOA_TYPE_COMPANY = 'Company'
