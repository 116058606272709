// @flow
import {
  SET_TIMELINE_DATA,
  TIMELINE_NEXT_STAGE,
  TIMELINE_PREV_STAGE,
  RESET_TIMELINE_DATA,
} from '../actions/types/timelineWithComponents'

const initialState = {
  activeIndex: null,
  activeComponent: '',
  data: null,
}
const timelineWithComponents = (state: Object = initialState, { type, payload }: Object) => {
  switch (type) {
    case SET_TIMELINE_DATA: {
      return {
        ...state,
        data: payload,
        activeIndex: 0,
        activeComponent: payload[0].componentName,
      }
    }
    case RESET_TIMELINE_DATA: {
      return {
        ...initialState,
      }
    }
    case TIMELINE_NEXT_STAGE: {
      return {
        ...state,
        activeIndex: state.activeIndex + 1,
        activeComponent: state.data[state.activeIndex + 1].componentName,
      }
    }
    case TIMELINE_PREV_STAGE: {
      return {
        ...state,
        activeIndex: state.activeIndex - 1,
        activeComponent: state.data[state.activeIndex - 1].componentName,
      }
    }
    default: {
      return state
    }
  }
}

export default timelineWithComponents
