export const WORK_TYPE_BENALTERAT = 'BENALTERAT'
export const WORK_TYPE_CHNGDETAIL = 'CHNGDETAIL'
export const WORK_TYPE_NEWBUSGENQT = 'NEWBUSGENQT'
export const WORK_TYPE_AGENCYTRANSFER = 'AGENCYTRANSFER'
export const WORK_TYPE_NEWAGENCY = 'NEWAGENCY'
export const WORK_TYPE_EDITPAYMENT = 'CREAMAINTDD'
export const WORK_TYPE_RELNALTER = 'RELNALTER'
export const WORK_TYPE_BENALTQT = 'BENALTQT'

export const BIG_T_BOOK = 'Big T Book'
export const BIG_T_INTERVIEW = 'Big T Interview'
