// @flow
import get from 'lodash/get'
import moment from 'moment'
import {
  POLICY_RELATIONSHIPS_AGENT,
  POLICY_RELATIONSHIPS_OWNER,
  POLICY_RELATIONSHIPS_SMSF,
  POLICY_IDENTIFIER_POLICY_ID,
} from '../constants/policies'
import { BANCS_CUSTOMER_NUMBER_NULIS, BANCS_CUSTOMER_NUMBER_IOOF } from '../constants/bancs'
import { getFullName } from './contactUtils'
import { getPrimaryLifeInsured } from './policyUtils'

type RelationshipType = { roleCode: string }

export const checkAgentRole = (relationship: RelationshipType) =>
  relationship.roleCode === POLICY_RELATIONSHIPS_AGENT

export const fetchAgents = (relationships: Array<RelationshipType>) =>
  relationships.filter(checkAgentRole)

export const getPersonalDetailsData = (data: Object) => {
  const dateOfBirth = get(data, 'dateOfBirth', '')
  const fullName = getFullName(data) || ''
  const gender = get(data, 'gender', '')
  return {
    fullName,
    dateOfBirth:
      (dateOfBirth && dateOfBirth !== '' && moment(dateOfBirth).format('DD MMMM YYYY')) || '',
    gender,
  }
}

/*
  policyNumberList added in returned data, useful
  when finding unique policy owners in all policies and
  policy owner having data of list of policies in which it is owner
*/
export const getPolicyOwners = (policy: Object) => {
  const { relationships, identifiers } = policy
  const policyNo = identifiers?.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID)?.value

  // Case 1: If NULIS or IOOF policy owner, then LA details is displayed on UI
  let clientDetails = relationships.find(relationship =>
    [BANCS_CUSTOMER_NUMBER_IOOF, BANCS_CUSTOMER_NUMBER_NULIS].includes(relationship.bancsCustomerNo)
  )
  if (clientDetails) {
    // getPrimaryLifeInsured returns array with one element
    const lifeInsured = getPrimaryLifeInsured(relationships)?.[0]

    return [
      {
        relationship: { ...clientDetails, lifeInsured },
        policyNumberList: [policyNo],
      },
    ]
  }

  /*
    Case 2: SMSF policy - relationship with roleCode 'IET' is the owner,
    contains Trustee(level1 key) and Director details(level2 inside level1)
  */
  clientDetails = relationships.find(
    relationship => relationship.roleCode === POLICY_RELATIONSHIPS_SMSF
  )
  if (clientDetails) {
    return [{ relationship: clientDetails, policyNumberList: [policyNo] }]
  }

  // Case 3: All remaining type of policies, Owner is entity with roleCode 'OWR', can be multiple
  return relationships
    .filter(relationship => relationship.roleCode === POLICY_RELATIONSHIPS_OWNER)
    .map(relationship => ({
      relationship,
      policyNumberList: [policyNo],
    }))
}

export const getUniquePolicyOwners = (policyOwners: Object[]) => {
  const uniquePolicyOwners = []
  policyOwners.forEach(policyOwner => {
    const existingPolicyOwner = uniquePolicyOwners.find(
      item => item.relationship.bancsCustomerNo === policyOwner.relationship.bancsCustomerNo
    )
    if (existingPolicyOwner) {
      // there will be single element in policyNumberList in policyOwner object
      existingPolicyOwner.policyNumberList.push(policyOwner.policyNumberList[0])
    } else {
      uniquePolicyOwners.push(policyOwner)
    }
  })
  return uniquePolicyOwners
}
