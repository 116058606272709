// @flow
import React, { Fragment } from 'react'
import get from 'lodash/get'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// components.
import SidebarNavItem from '../../molecules/SidebarNavItem'

// schema.
import captureClientSchema, {
  FORM_ID as CAPTURE_CLIENT_FORM_ID,
} from './components/CaptureClient/captureClient.schema'

import manualLoadingSchema, {
  FORM_ID as MANUAL_LOADINGS_FORM_ID,
} from './components/PreAssessment/Loadings/loadings.schema'

import { resetBenefitList } from '../../../utils/preAssessmentBenefitUtils'
import { getBancsCustomerNumber } from '../../../utils/cookieUtils'

type CreateQuoteProps = {
  handleNewClient: Function,
  // To render all labels from sitecore content editor
  fields: {},
  // list of actions
  actions: Object<Function>,
  // sidebar object
  sidebar: Object,
  // advisor entuty
  advisor: Object,
}

const CreateQuote = (props: CreateQuoteProps) => {
  const {
    handleNewClient,
    fields,
    actions,
    sidebar: { schemaName, currentPanel },
    advisor: { details },
  } = props

  const {
    formInit,
    removeEnquiryId,
    resetStateForNewQuote,
    updateLoading,
    saveLoadings,
    resetConfirmSmokerTerms,
    setPanelProps,
    openSidebar,
    deleteExistingSearchedClients,
    setExistingClientDetails,
    isIPCoverCalculated,
    setLifeAssuredName,
    resetExistingClient,
    resetOccupationInStore,
  } = actions

  const {
    initQuoteNewClientLabel,
    initQuoteNewClientDesc,
    initQuoteExistClientLabel,
    initQuoteExistClientDesc,
  } = fields
  if (currentPanel === 0 && schemaName === 'editQuote') {
    openSidebar('createQuote')
  }

  const adviserNumber = get(details, 'customerDetails.customerNo', undefined)
  const bancsAdviserCustomerNo = getBancsCustomerNumber()

  const onNewClientClick = () => {
    setExistingClientDetails()
    openSidebar('createQuote')
    // reset capature client form
    resetExistingClient()
    formInit(CAPTURE_CLIENT_FORM_ID, captureClientSchema, {})
    removeEnquiryId(true)
    resetStateForNewQuote(adviserNumber, bancsAdviserCustomerNo)
    updateLoading([])
    // reset IP cover calculation flag
    isIPCoverCalculated(false)
    resetBenefitList()
    // reset manual loading underwriter form data
    saveLoadings(null)
    formInit(MANUAL_LOADINGS_FORM_ID, manualLoadingSchema()({}), {})
    resetConfirmSmokerTerms()
    setLifeAssuredName('', '')
    handleNewClient()
    resetOccupationInStore()
    // Set props on sidebar
    setPanelProps({
      setPreAssessmentVisibility: true,
    })
    const tagEvent = createEvent({
      GA: {
        category: 'Create digital quote for new customer',
        action: 'AP - Create quote for new customer',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Create digital quote for new customer - Create',
        },
      },
    })
    tagEvent.end()
  }

  const onExistingClientClick = () => {
    openSidebar('editQuote')
    deleteExistingSearchedClients()
    handleNewClient()
    // reset IP cover calculation flag
    isIPCoverCalculated(false)
    const tagEvent = createEvent({
      GA: {
        category: 'Create manual quote for existing customer',
        action: 'AP - Create quote for existing customer',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Create manual quote for existing customer - AP - new quotes started',
        },
      },
    })
    tagEvent.end()
  }

  return (
    <Fragment>
      <SidebarNavItem
        title={initQuoteNewClientLabel.value}
        description={initQuoteNewClientDesc.value}
        clickHandler={onNewClientClick}
        darkPanel
      />
      <SidebarNavItem
        title={initQuoteExistClientLabel.value}
        description={initQuoteExistClientDesc.value}
        clickHandler={onExistingClientClick}
        darkPanel
      />
    </Fragment>
  )
}

export const mapStateToProps = ({ sidebar, createQuote, advisor }) => ({
  sidebar,
  createQuote,
  advisor,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuote)
