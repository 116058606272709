// @ts-expect-error non-ts code
import { makeRequestHeaders } from './middlewareUtils'
// @ts-expect-error non-ts code
import { getInstance } from '../store'
import { Store } from '../types/store'
import { MulesoftResponse } from '../types/mulesoft'
import { createEvent } from './telemetry'

export const PORTAL_API_PATH = 'v1/digital/retailproxy/experience'

export const callExperienceAPIv2 = async ({
  path,
  method = 'GET',
  requestBody = {},
  version = 'v2',
  splunkCustomEventName,
}: {
  path: string
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH'
  requestBody?: object
  version?: string
  splunkCustomEventName?: string
}): Promise<MulesoftResponse<object | Array<object>> | false> => {
  const {
    okta: { token },
    config,
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  } = getInstance().getState() as Store
  const API_DOMAIN = config.MLCL_EXPERIENCE_API
  const headers = makeRequestHeaders('application/json', token)
  const url = path.indexOf('http') > -1 ? path : `${API_DOMAIN || ''}${path}`
  const event = createEvent({
    Splunk: {
      attributes: {
        'workflow.name': 'Experience API Fetch',
        endpoint: path,
        transactionId: headers?.transactionId,
      },
    },
  })
  event.end()

  try {
    const response = await fetch(url, {
      method,
      headers: { ...headers, version },
      ...(method !== 'GET' && { body: JSON.stringify(requestBody) }),
    })

    // Splunk custom event firing
    if (splunkCustomEventName) {
      const customEvent = createEvent({
        Splunk: {
          attributes: {
            'workflow.name': splunkCustomEventName,
            endpoint: path,
            transactionId: headers?.transactionId,
            error: response.status !== 200,
          },
        },
      })
      customEvent.end()
    }

    return response.status === 200 ? response.json() : false
  } catch (e) {
    return false
  }
}

export const responseCameBackAllGood = (
  response: MulesoftResponse<object | Array<object>> | false
) =>
  !!(
    response &&
    response.status &&
    (response.response || response.businessData) &&
    response.status.success
  )
