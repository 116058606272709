// @flow
import { get } from 'lodash'
/**
 *
 * @param {*} fields
 * @param {*} eligibleHealthyCovers
 * @returns boolean value
 * description- checking the healthy lIving is required field basen on form field value
 */
export const isHealthyLivingRequired = (fields, eligibleHealthyCovers) =>
  eligibleHealthyCovers.some(cover => get(fields, `${cover}.value`, 'false'))

// Checks one of the fields has value and returns the form is valid
export const isOneOfFieldContainValue = (fields, notToCheckFields = []) => {
  let isValid = false
  isValid = Object.keys(fields)
    .reduce(
      (acc, curr) => (notToCheckFields.includes(curr) ? acc : acc.concat(fields[curr].value)),
      []
    )
    .some(val => val)
  return isValid
}
