/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconClose16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.464 3.05A1 1 0 0 0 3.05 4.464L6.586 8 3.05 11.536a1 1 0 1 0 1.414 1.414L8 9.414l3.536 3.536a1 1 0 1 0 1.414-1.414L9.414 8l3.536-3.536a1 1 0 1 0-1.414-1.414L8 6.586 4.464 3.05z"
      fill="#000"
    />
  </Icon>
)

export default IconClose16
