import { buttonPadding, colours, fontFamily, fontSize, fontWeights } from '../../../styles'

const styles = {
  base: {
    height: 48,
    paddingTop: buttonPadding.sm.vertical,
    paddingBottom: buttonPadding.sm.vertical,
    paddingLeft: buttonPadding.sm.horizontal,
    paddingRight: buttonPadding.sm.horizontal,
    textAlign: 'center',
    border: 'none',
    textDecoration: 'none',
    justifyContent: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: fontSize.sm,
    lineHeight: fontSize.md,
    fontFamily: fontFamily.charlie,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  primary: theme => ({
    color: theme.text,
    backgroundColor: theme.bg,
    '&:hover, &:focus': {
      backgroundColor: theme.hover.bg,
    },
    '&:active': {
      backgroundColor: theme.active.bg,
    },
  }),
  secondary: theme => ({
    color: theme.bg,
    backgroundColor: colours.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.bg,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover, &:focus': {
      color: theme.hover.bg,
      backgroundColor: colours.white,
      borderColor: theme.hover.bg,
    },
    '&:active': {
      color: theme.active.bg,
      backgroundColor: colours.white,
      borderColor: theme.active.bg,
    },
    disabled: {
      backgroundColor: colours.white,
      borderColor: colours.mediumGrey,
    },
  }),
  tertiary: theme => ({
    color: theme.bg,
    backgroundColor: 'transparent',
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.bold,
    '&:hover, &:focus': {
      color: theme.hover.bg,
    },
    '&:active': {
      color: theme.active.bg,
    },
    disabled: {
      backgroundColor: 'transparent',
    },
  }),
  // eslint-disable-next-line no-unused-vars
  icon: theme => ({
    background: 'transparent',
    height: 44,
    paddingTop: buttonPadding.xs.vertical,
    paddingBottom: buttonPadding.xs.vertical,
    paddingLeft: buttonPadding.xs.horizontal,
    paddingRight: buttonPadding.xs.horizontal,
  }),
  action: theme => ({
    height: 56,
    paddingTop: buttonPadding.lg.vertical,
    paddingBottom: buttonPadding.lg.vertical,
    paddingLeft: buttonPadding.lg.horizontal,
    paddingRight: buttonPadding.lg.horizontal,
    color: theme.text,
    backgroundColor: theme.bg,
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.bold,
    '&:hover, &:focus': {
      color: theme.hover.text,
      backgroundColor: theme.hover.bg,
    },
    '&:active': {
      color: theme.active.text,
      backgroundColor: theme.active.bg,
    },
  }),
  whiteWithBorder: theme => ({
    color: colours.white,
    background: 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colours.white,
    '&:hover, &:focus': {
      color: theme.hover.bg,
      background: colours.white,
      borderColor: theme.hover.bg,
    },
    '&:active': {
      color: theme.active.text,
      background: colours.white,
      borderColor: colours.white,
    },
    '&:disabled': {
      color: colours.mediumGrey,
      background: colours.darkGreen,
      borderColor: colours.mediumGrey,
    },
  }),
  error: {
    backgroundColor: colours.lightRed,
    color: colours.red,
    '&:hover, &:focus': {
      backgroundColor: colours.red,
      color: colours.lightRed,
    },
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
    color: colours.mediumGrey,
    backgroundColor: colours.lightestGrey,
  },
  transparent: {
    background: 'transparent',
  },
  link: theme => ({
    height: 56,
    paddingTop: buttonPadding.lg.vertical,
    paddingBottom: buttonPadding.lg.vertical,
    paddingLeft: buttonPadding.lg.horizontal,
    paddingRight: buttonPadding.lg.horizontal,
    color: theme.text,
    backgroundColor: 'transparent',
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.bold,
    '&:hover, &:focus': {
      color: theme.hover && theme.hover.text,
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: theme.active && theme.active.text,
      backgroundColor: 'transparent',
    },
  }),
  spinner: {
    minHeight: 0,
  },
}

export default styles
