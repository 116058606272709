// @flow
import get from 'lodash/get'
import {
  EXPERIENCE_API_VERSION_1,
  EXPERIENCE_API_BASE,
  SITECORE_API_BASE,
} from '../../constants/apis'
import { ADVISER_NO_KEY } from '../../constants/adviser'
import {
  RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF,
  SAVE_SUPPORT_STAFF_CONSENT,
  SAVE_ACTIVE_SUPPORT_STAFF_DATA,
  SAVE_ACTIVE_ADVISER_DATA,
  FETCH_SUPPORT_STAFF_DETAILS,
  RESET_ADVISOR_SUPPORT_STAFF,
  UPDATE_SUPPORT_STAFF_DETAILS,
} from '../types/supportStaff'
import { EXPERIENCE_API } from '../types/experienceApi'
import { SITECORE_API } from '../types/sitecoreApi'
import { getBancsCustomerNumber } from '../../utils/cookieUtils'
import { sortAgencies } from '../../utils/supportStaffUtils'

type SaveSupportStaffPayloadType = {
  advsupportstaffid: String,
  hasacceptedterms: String,
  name: String,
  version: String,
}

export const retrieveAdviserForSupportStaff =
  (payload: Object, callback: Function = () => {}) =>
  dispatch => {
    const { bancsCustomerNo } = payload

    dispatch({
      type: EXPERIENCE_API,
      name: RETRIEVE_ADVISOR_LIST_FOR_SUPPORT_STAFF,
      verb: 'GET',
      removeHeaders: [ADVISER_NO_KEY],
      // eslint-disable-next-line max-len
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/supportStaff/relationship/search/${bancsCustomerNo}`,
      callback: (err, dataReceived) => {
        if (!err && dataReceived) {
          callback({ ...dataReceived })
          const businessData = get(dataReceived, 'businessData', {})
          const agencies = get(businessData, 'agencies')
          return {
            data: {
              ...businessData,
              ...(agencies ? { agencies: sortAgencies(agencies) } : null),
            },
          }
        }
        return dataReceived
      },
    })
  }

export const saveSupportStaffConsent =
  (data: SaveSupportStaffPayloadType, callback?: Function) => (dispatch, getState) => {
    const {
      config: { MLCL_SITECORE_CMS_KEY },
    } = getState()
    dispatch({
      type: SITECORE_API,
      name: SAVE_SUPPORT_STAFF_CONSENT,
      verb: 'POST',
      route: `${SITECORE_API_BASE}/advisersupportstaffdeclaration`,
      additionalHeaders: {
        CMSapikey: MLCL_SITECORE_CMS_KEY,
      },
      data,
      callback: dataReceived => {
        callback({ ...dataReceived })
        return { data: dataReceived }
      },
    })
  }

export const saveActiveSupportStaffData = (data: Object) => ({
  type: SAVE_ACTIVE_SUPPORT_STAFF_DATA,
  payload: {
    data,
  },
})

export const resetAdvisorSupportStaff = () => ({
  type: RESET_ADVISOR_SUPPORT_STAFF,
})

export const saveActiveAdviserData = (data: Object) => ({
  type: SAVE_ACTIVE_ADVISER_DATA,
  payload: {
    data,
  },
})

export const getSupportStaffDetails = (payload: Object, callback: Function) => ({
  type: EXPERIENCE_API,
  name: FETCH_SUPPORT_STAFF_DETAILS,
  verb: 'GET',
  route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${payload.bancsCustomerNo}`,
  callback: (err, dataReceived) => {
    const details = get(dataReceived, 'businessData.member', {})
    callback(err, details)
    return dataReceived
  },
})

export const updateSupportStaffDetails =
  (data: Object, callback: Function) => (dispatch: Function) => {
    dispatch({
      type: EXPERIENCE_API,
      name: UPDATE_SUPPORT_STAFF_DETAILS,
      verb: 'PUT',
      data: {
        member: data,
      },
      additionalHeaders: {
        'X-InitialRole': 'Support_staff',
      },
      route: `${EXPERIENCE_API_VERSION_1}${EXPERIENCE_API_BASE}/members/${getBancsCustomerNumber()}`,
      callback: (err, dataReceived) => {
        callback(dataReceived, err)
        const details = get(dataReceived, 'businessData.member', {})
        dispatch(saveActiveSupportStaffData(details))
      },
    })
  }
