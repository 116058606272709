/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import { Chip, Icons, Card as NewCard, Tooltip, Notification } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { createEvent } from '../../../utils/telemetry'

// utils
import { renderTextField } from '../../../utils/sitecoreUtils'
import { filterCoversForConformationPage } from '../../../utils/alteration'

import { AltsDecreaseCoverRowGroup } from './AltsDecreaseCoverRowGroup'

// styles
import styles, { styleOverrides } from './altsDecreasePolicyCard.styles'
import {
  NEW_PREMIUM_TAB,
  DEFAULT_AMOUNT,
  DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
} from '../../../constants/alterations'
import {
  POLICY_BENEFIT_PLAT_SGBO,
  POLICY_BENEFIT_STD_SGBO,
  POLICY_BENEFIT_TISO,
  SUPER_PRODUCT_CLASS,
} from '../../../constants/policies'
import { formatCurrency } from '../../../utils/quoteUtils'

// components
import WithLoader from '../WithLoader'

const StyledCardCell = styled('div')(({ isHeader }) => styles.cardCell({ isHeader }))
const MarginRight = styled('span')(styles.marginRight)
const Footer = styled('div')(styles.footer)
const FooterItemValue = styled('span')(styles.footerItemValue)

const HeaderPolicyValue = styled('div')(styles.headerPolicyValue)
const BeneficiaryHeader = styled('h3')(styles.beneficiary)
const HeaderRow = styled('div')(({ isNewPremium }) => styles.headerRow({ isNewPremium }))
const FooterColumn = styled('div')(styles.footerColumn)
const HeaderContainer = styled('div')(styles.headerContainer)
const HeaderCell = styled('div')(styles.headerCell)

const { IconInfo16 } = Icons
const InfoIcon = styled(IconInfo16)(styles.infoIcon)
const TooltipContainer = styled('div')(styles.tooltipContainer)
const ErrorMessage = styled('div')(styles.errorMessage)
const StyledNotification = styled(Notification)(styles.notification)

const AltsDecreasePolicyCard = ({
  fields,
  quotesData,
  isNewPremium,
  activeTabValue,
  isFetchingCalculateQuote,
  isConfirmationPage,
}) => {
  const {
    policySectionBenefitsHeader,
    policySectionSumInsuredHeader,
    policyDetailsSubHeading,
    PolicySectionPremiumHeader,
    StampDuty,
    StampDutyLabel,
    PolicyFee,
    PremiumSummary,
    SavingsAmountTooltip,
    PolicySavingsLabel,
    TaxRebateLabel,
    EstimatedPremiumChange,
    PolicyStartDate,
    AlterationEffectiveDateLabel,
    PolicyErrorMessage,
  } = fields

  const [listOfBenefitRowIds, setBenefitRowIdsList] = useState([])

  const printEstimatedSavingsLabel = collectionFrequency =>
    get(PolicySavingsLabel, 'value', '').replace('##', collectionFrequency.toLowerCase()) ||
    'Estimated savings'
  const printEstimatedPremiumIncreaseLabel = collectionFrequency =>
    get(EstimatedPremiumChange, 'value', '').replace('##', collectionFrequency.toLowerCase())
  const sortItemsBasedOnName = items =>
    items.sort((a, b) => {
      if (a.lifeInsuredLastName === b.lifeInsuredLastName) {
        return a.lifeInsuredFirstName < b.lifeInsuredFirstName ? -1 : 1
      }
      return a.lifeInsuredLastName < b.lifeInsuredLastName ? -1 : 1
    })

  /* This function sorts all the covers by last name then first name descending order.
     Once sorted, the covers are grouped at the top level by the beneficiary name and then again by
     the benefit name */
  const sortGroupedCovers = covers => {
    const sortedCovers = sortItemsBasedOnName(
      covers.filter(el => el.type.toLowerCase() !== 'child_ci')
    )

    // All CHILD_CI covers need to be at the bottom of the list
    const childCICovers = covers.filter(el => el.type.toLowerCase() === 'child_ci')
    childCICovers.length && sortedCovers.push(...sortItemsBasedOnName(childCICovers))

    const coverGroups = {}
    sortedCovers.forEach(cover => {
      const personKey = `${cover.lifeInsuredFirstName} ${cover.lifeInsuredLastName}`
      if (!coverGroups[personKey]) {
        coverGroups[personKey] = {
          [cover.benefitName]: [cover],
        }
      } else if (coverGroups[personKey] && !coverGroups[personKey][cover.benefitName]) {
        coverGroups[personKey][cover.benefitName] = [cover]
      } else {
        coverGroups[personKey][cover.benefitName].push(cover)
      }
    })

    // Re Order the cover groups so that Child benefits like TISO, SGBO
    // are inserted right after their respective parent.
    // Ex: TISO comes after LC, SGBO after IA or IA+
    const childBenefitTypes = [
      POLICY_BENEFIT_PLAT_SGBO,
      POLICY_BENEFIT_STD_SGBO,
      POLICY_BENEFIT_TISO,
    ]
    const orderedCoverGroups = {}
    Object.entries(coverGroups).forEach(([lifeAssured, coverGroupsForLA]) => {
      const typesListInCoverGroups = {}
      Object.entries(coverGroupsForLA).forEach(([benefitName, coversPerBenefit]) => {
        const { type: typeOfCover } =
          coversPerBenefit && !!coversPerBenefit.length && coversPerBenefit[0]
        typesListInCoverGroups[typeOfCover] = benefitName
      })

      const coverTypesObj = {}
      Object.values(coverGroupsForLA).forEach(eachCoverGroup => {
        if (eachCoverGroup && !!eachCoverGroup.length) {
          coverTypesObj[eachCoverGroup[0].type] = eachCoverGroup[0]
        }
      })

      const onlyParentTypes = {}
      Object.keys(coverTypesObj)
        .filter(type => !childBenefitTypes.includes(type))
        .forEach(k => {
          onlyParentTypes[k] = coverTypesObj[k]
        })

      const onlyChildTypes = {}
      Object.keys(coverTypesObj)
        .filter(type => childBenefitTypes.includes(type))
        .forEach(k => {
          onlyChildTypes[k] = coverTypesObj[k]
        })

      const parentTypesList = Object.keys(onlyParentTypes)

      Object.entries(onlyChildTypes).forEach(eachChildType => {
        const [childBenefitName, childBenefit] = eachChildType
        parentTypesList.forEach((v, i) => {
          v === get(childBenefit, 'parentBenefit.parentType', '').toString() &&
            parentTypesList.splice(i + 1, 0, childBenefitName)
        })
      })

      orderedCoverGroups[lifeAssured] = {}
      parentTypesList.forEach(k => {
        orderedCoverGroups[lifeAssured][coverTypesObj[k].benefitName] =
          coverGroups[lifeAssured][coverTypesObj[k].benefitName]
      })

      return orderedCoverGroups
    })

    return orderedCoverGroups
  }

  const showTooltip =
    (activeTabValue === NEW_PREMIUM_TAB || isConfirmationPage) &&
    SavingsAmountTooltip &&
    SavingsAmountTooltip.value

  return (
    <>
      {showTooltip && <Tooltip id="policySavingTooltip">{SavingsAmountTooltip.value}</Tooltip>}
      {quotesData.map(
        ({
          alteration,
          chipVariant,
          productId,
          policyType,
          productName,
          stampDuty,
          collectionFrequency,
          totalPremiumAmount,
          policyNo,
          covers,
          policyFee,
          rebateAmount,
          productClass,
          bancsPolicyNo,
          policyInstanceNo,
          policyCommencementDate,
          bannerMessage,
          isPolicyErrorMessage,
          isPolicyChangeDisabled,
        }) => {
          const filteredCovers =
            isConfirmationPage && alteration
              ? filterCoversForConformationPage(covers, get(alteration, 'alteredBenefits', []))
              : covers
          const isPremiumIncreased = alteration.saving < 0

          return (
            <div>
              <NewCard
                key={bancsPolicyNo}
                header={
                  <HeaderContainer>
                    <div>
                      <Chip variant={chipVariant}>{policyType}</Chip>
                      <HeaderPolicyValue>
                        <span>
                          {renderTextField(policyDetailsSubHeading)} {policyNo}
                        </span>
                      </HeaderPolicyValue>
                      <div>{productName}</div>
                    </div>
                    <div>
                      <HeaderCell>
                        <span>{renderTextField(PolicyStartDate)}</span>
                        <span>
                          {policyCommencementDate &&
                            moment(policyCommencementDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                        </span>
                      </HeaderCell>
                      {alteration?.policyAlterationEffectiveDate && (
                        <HeaderCell>
                          <span>{renderTextField(AlterationEffectiveDateLabel)}</span>
                          <span>
                            {moment(alteration?.policyAlterationEffectiveDate, 'YYYY-MM-DD').format(
                              'DD MMM YYYY'
                            )}
                          </span>
                        </HeaderCell>
                      )}
                    </div>
                  </HeaderContainer>
                }
                footer={
                  <Footer>
                    <WithLoader
                      isLoading={isFetchingCalculateQuote && isNewPremium && !isConfirmationPage}
                      overlay
                      isOverlayComponent
                      loaderProps={{
                        spinnerSize: 25,
                        cssLoader: {
                          marginLeft: '78%',
                        },
                      }}
                    >
                      <FooterColumn>
                        <MarginRight>
                          {renderTextField(StampDuty) || renderTextField(StampDutyLabel)}
                        </MarginRight>
                        <FooterItemValue>
                          {alteration.newStampDuty !== undefined &&
                          alteration.isPolicyAltered &&
                          (activeTabValue === NEW_PREMIUM_TAB || isConfirmationPage)
                            ? formatCurrency(alteration.newStampDuty)
                            : stampDuty}
                        </FooterItemValue>
                      </FooterColumn>
                      <FooterColumn>
                        {productClass === SUPER_PRODUCT_CLASS && (
                          <Fragment>
                            <MarginRight>{renderTextField(TaxRebateLabel)}</MarginRight>
                            <FooterItemValue>
                              {(activeTabValue === NEW_PREMIUM_TAB || isConfirmationPage) &&
                              alteration.isPolicyAltered
                                ? formatCurrency(
                                    alteration.taxRebate || rebateAmount,
                                    alteration.taxRebate || rebateAmount > 0 ? '-$' : '$'
                                  )
                                : formatCurrency(rebateAmount, rebateAmount > 0 ? '-$' : '$')}
                            </FooterItemValue>
                          </Fragment>
                        )}
                      </FooterColumn>
                      <FooterColumn>
                        <MarginRight>{renderTextField(PolicyFee)}</MarginRight>
                        <FooterItemValue>
                          {alteration.newPolicyFee !== undefined &&
                          alteration.isPolicyAltered &&
                          (isNewPremium || isConfirmationPage)
                            ? formatCurrency(alteration.newPolicyFee)
                            : policyFee}
                        </FooterItemValue>
                      </FooterColumn>
                      <FooterColumn className="highlight">
                        <MarginRight>
                          {PremiumSummary.value.replace('##', collectionFrequency.toLowerCase())}
                        </MarginRight>
                        <FooterItemValue>
                          {(isNewPremium || isConfirmationPage) &&
                          alteration.isPolicyAltered &&
                          alteration.newNetPremium !== undefined
                            ? formatCurrency(alteration.newNetPremium)
                            : formatCurrency(totalPremiumAmount)}
                        </FooterItemValue>
                      </FooterColumn>
                      {showTooltip && (
                        <FooterColumn isWarning={isPremiumIncreased}>
                          <MarginRight>
                            {SavingsAmountTooltip && SavingsAmountTooltip.value && (
                              <TooltipContainer
                                data-tip
                                data-for="policySavingTooltip"
                                onClick={() => {
                                  const event = createEvent({
                                    GA: {
                                      category: DECREASE_COVER_GA_TAG_QUOTE_SCREEN,
                                      action: 'Estimated savings tooltip',
                                    },
                                    Splunk: {
                                      attributes: {
                                        'workflow.name': `${DECREASE_COVER_GA_TAG_QUOTE_SCREEN} - Estimated savings tooltip`,
                                      },
                                    },
                                  })
                                  event.end()
                                }}
                              >
                                <InfoIcon isWarning={isPremiumIncreased} />
                              </TooltipContainer>
                            )}
                            {isPremiumIncreased
                              ? printEstimatedPremiumIncreaseLabel(collectionFrequency)
                              : printEstimatedSavingsLabel(collectionFrequency)}
                          </MarginRight>
                          <FooterItemValue>
                            {alteration.saving !== undefined
                              ? formatCurrency(Math.abs(alteration.saving))
                              : DEFAULT_AMOUNT}
                          </FooterItemValue>
                        </FooterColumn>
                      )}
                    </WithLoader>
                  </Footer>
                }
                styleOverrides={styleOverrides}
              >
                {Object.entries(sortGroupedCovers(filteredCovers)).map(beneficiaries => (
                  <div key={beneficiaries[0]}>
                    <BeneficiaryHeader data-testid="beneficiariesNames">
                      {beneficiaries[0]}
                    </BeneficiaryHeader>
                    <HeaderRow isNewPremium={isNewPremium}>
                      <StyledCardCell isHeader>
                        {renderTextField(policySectionBenefitsHeader)}
                      </StyledCardCell>
                      <StyledCardCell isHeader>
                        {renderTextField(policySectionSumInsuredHeader)}
                      </StyledCardCell>
                      <StyledCardCell isHeader>
                        {renderTextField(PolicySectionPremiumHeader)}
                      </StyledCardCell>
                    </HeaderRow>
                    {Object.values(beneficiaries[1]).map(coverGroup => (
                      <AltsDecreaseCoverRowGroup
                        coverGroup={coverGroup}
                        key={coverGroup[0].type}
                        activeTabValue={activeTabValue}
                        fields={fields}
                        policyType={policyType}
                        isNewPremium={isNewPremium}
                        bancsPolicyNo={bancsPolicyNo}
                        alteration={alteration}
                        lifeInsured={beneficiaries[0]}
                        listOfBenefitRowIds={listOfBenefitRowIds}
                        setBenefitRowIdsList={setBenefitRowIdsList}
                        policyInstanceNo={policyInstanceNo}
                        isFetchingCalculateQuote={isFetchingCalculateQuote}
                        isConfirmationPage={isConfirmationPage}
                        productId={productId}
                        isPolicyChangeDisabled={isPolicyChangeDisabled}
                      />
                    ))}
                  </div>
                ))}
              </NewCard>
              {!isConfirmationPage && bannerMessage && (
                <StyledNotification variant="info" data-testid="notification-banner">
                  <Icon iconName={['far', 'fa-circle-info']} />
                  <span>{bannerMessage}</span>
                </StyledNotification>
              )}
              {isNewPremium && isPolicyErrorMessage && !isConfirmationPage && (
                <ErrorMessage>
                  <Icon iconName={['far', 'triangle-exclamation']} />
                  {renderTextField(PolicyErrorMessage, true)}
                </ErrorMessage>
              )}
            </div>
          )
        }
      )}
    </>
  )
}

AltsDecreasePolicyCard.propTypes = {
  isFetchingCalculateQuote: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    Optimiser: PropTypes.shape({
      value: PropTypes.string,
    }),
    policySectionBenefitsHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    policySectionSumInsuredHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    policyDetailsSubHeading: PropTypes.shape({
      value: PropTypes.string,
    }),
    PolicySectionPremiumHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    RemoveCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
    Reduced: PropTypes.shape({
      value: PropTypes.string,
    }),
    ResetRowCTA: PropTypes.shape({
      value: PropTypes.string,
    }),
    BenefitOptions: PropTypes.shape({
      value: PropTypes.string,
    }),
    BenefitViewOptions: PropTypes.shape({
      value: PropTypes.string,
    }),
    StampDuty: PropTypes.shape({
      value: PropTypes.string,
    }),
    StampDutyLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    PolicyFee: PropTypes.shape({
      value: PropTypes.string,
    }),
    PremiumSummary: PropTypes.shape({
      value: PropTypes.string,
    }),
    WaitingPeriod: PropTypes.shape({
      value: PropTypes.string,
    }),
    BenefitPeriod: PropTypes.shape({
      value: PropTypes.string,
    }),
    PolicySectionLifeInsuredHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    ViewLoadingsCTALabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    ViewExclusionsCTALabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    ViewLoadingsExclusionsCTALabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    LoadingsModalHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    ExclusionsModalHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    LoadingsExclusionsModalHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    LoadingModalDescForRPM: PropTypes.shape({
      value: PropTypes.string,
    }),
    PercentageAppliedLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    LoadingsHeadingLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    ExclusionsHeadingLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    SumInsuredErrorMsg: PropTypes.shape({
      value: PropTypes.string,
    }),
    SumInsuredBlankErrorMsg: PropTypes.shape({
      value: PropTypes.string,
    }),
    AlteredLabel: PropTypes.shape({
      value: PropTypes.string,
    }),
    BlockRemovalOption: PropTypes.shape({
      value: PropTypes.string,
    }),
    PolicyErrorMessage: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  quotesData: PropTypes.arrayOf(
    PropTypes.shape({
      alteration: PropTypes.shape({
        isPolicyAltered: PropTypes.bool,
        newGrossPremium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        saving: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        taxRebate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        newPolicyFee: PropTypes.number,
        newNetPremium: PropTypes.number,
        newStampDuty: PropTypes.number,
      }),
      policyType: PropTypes.string,
      stampDuty: PropTypes.string,
      policyFee: PropTypes.string,
      totalPremiumAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      chipVariant: PropTypes.string,
      productName: PropTypes.string,
      productClass: PropTypes.string,
      collectionFrequency: PropTypes.string,
      policyNo: PropTypes.string,
      rebateAmount: PropTypes.string,
      policyInstanceNo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      isPolicyErrorMessage: PropTypes.bool.isRequired,
      isPolicyChangeDisabled: PropTypes.bool.isRequired,
      covers: PropTypes.arrayOf(
        PropTypes.shape({
          benefitName: PropTypes.string,
          coverAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          premiumStyle: PropTypes.string,
          optimiserParentBenefit: PropTypes.shape({
            name: PropTypes.string,
          }),
          parentBenefit: PropTypes.shape({
            name: PropTypes.string,
            commencementDate: PropTypes.string,
            policyNo: PropTypes.string,
            parentPolicyReferenceNo: PropTypes.string,
            parentType: PropTypes.string.isRequired,
            parentBenefitInstanceNo: PropTypes.string.isRequired,
          }),
          childBenefits: PropTypes.arrayOf(
            PropTypes.shape({
              childPolicyReferenceNo: PropTypes.string,
              childBenefitType: PropTypes.string,
              childBenefitInstanceNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
          ),
          tpdDefinition: PropTypes.string,
          benefitCommencementDate: PropTypes.string,
          premiumAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          isOptionsVisible: PropTypes.bool,
          periods: PropTypes.shape({
            coverPeriod: PropTypes.string,
            waitingPeriod: PropTypes.string,
          }),
          hideSumInsured: PropTypes.bool,
          disableSumInsured: PropTypes.bool,
          lifeInsuredName: PropTypes.string,
          lifeInsuredFirstName: PropTypes.string,
          lifeInsuredLastName: PropTypes.string,
          optionsId: PropTypes.string,
          isIPorBEBenefit: PropTypes.bool,
          isRemoved: PropTypes.bool,
          hasChanged: PropTypes.bool,
          applicableFeatures: arrayOf(
            PropTypes.shape({
              featureApplicable: PropTypes.string,
              featureName: PropTypes.string,
              premium: PropTypes.number,
            })
          ),
          loading: PropTypes.arrayOf(
            PropTypes.shape({
              endDate: PropTypes.string,
              loadingType: PropTypes.string,
              loadingValue: PropTypes.number,
              loadingValueType: PropTypes.string,
              startDate: PropTypes.string,
              reason: PropTypes.arrayOf(
                PropTypes.shape({
                  reasonCode: PropTypes.string,
                  reasonDescription: PropTypes.string,
                })
              ),
            })
          ),
          exclusion: PropTypes.arrayOf(
            PropTypes.shape({
              endDate: PropTypes.string,
              referenceNo: PropTypes.string,
              exclusionCode: PropTypes.string,
              startDate: PropTypes.string,
              description: PropTypes.string,
            })
          ),
          apEnabledFeatures: PropTypes.shape({
            apDecreaseBenefitPeriod: PropTypes.bool,
            apDecreaseSumInsured: PropTypes.bool,
            apIncreaseWaitingPeriod: PropTypes.bool,
            apRemoveBenefit: PropTypes.bool,
            apRemoveBenefitOption: PropTypes.bool,
          }),
        })
      ),
    })
  ),
  isNewPremium: PropTypes.bool.isRequired,
  isConfirmationPage: PropTypes.bool.isRequired,
}

export { AltsDecreasePolicyCard }
