/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconThumbsUp32 = ({ ...rest }) => (
  <Icon {...rest} size={91}>
    <path
      d="M23.9809 84.2499H3.9375C2.29802 84.2499 0.96875 82.9178 0.96875 81.2749V31.692C0.96875 30.0491 2.29802 28.717 3.9375 28.717H23.9809C25.6204 28.717 26.9497 30.0491 26.9497 31.692V81.2749C26.9497 82.9178 25.6204 84.2499 23.9809 84.2499ZM6.90625 78.2999H21.0122V34.667H6.90625V78.2999Z"
      fill="#01838C"
    />
    <path
      d="M58.0955 84.2497H41.2731C35.5168 84.2497 29.8054 82.3511 25.1914 78.904L22.2067 76.6742C21.4543 76.112 21.0122 75.2288 21.0122 74.289V31.6919C21.0122 31.182 21.1427 30.6823 21.3905 30.2378L34.963 5.95985C36.0517 3.77073 38.1347 2.03338 40.612 1.31577C43.0952 0.603983 45.7784 0.956974 47.9832 2.2934C50.994 4.11936 52.6958 7.43719 52.4233 10.954L51.052 28.7169H77.3039C81.3787 28.7169 85.1056 30.5995 87.5293 33.881C89.953 37.1625 90.6604 41.2851 89.4703 45.1898C87.9337 49.6014 80.293 71.2515 77.072 75.2143C72.4029 80.9566 65.4855 84.2497 58.0955 84.2497ZM26.9497 72.7957L28.7399 74.1335C32.3364 76.8195 36.788 78.2997 41.2731 78.2997H58.0955C63.6938 78.2997 68.9326 75.8055 72.4681 71.4563C74.3497 69.1437 80.2437 53.6223 83.8285 43.3406C84.4258 41.3693 84.0489 39.1715 82.7558 37.4211C81.4642 35.6706 79.4769 34.6669 77.3039 34.6669H47.8455C47.0178 34.6669 46.2292 34.3211 45.6667 33.7125C45.1043 33.1038 44.8216 32.2889 44.8854 31.4623L46.5032 10.4935C46.6017 9.22974 45.99 8.03858 44.9101 7.38344C44.1055 6.89681 43.1677 6.77043 42.2617 7.0319C41.3586 7.29338 40.6294 7.90203 40.2091 8.74456L26.9497 32.4676V72.7957Z"
      fill="#01838C"
    />
  </Icon>
)

export default IconThumbsUp32
