// @flow
import {
  POLICIES_FETCH,
  POLICIES_FETCH_INIT,
  POLICIES_FETCH_SUCCESS,
  POLICIES_FETCH_ERROR,
  POLICIES_FETCH_FAILURE,
  POLICIES_RESET,
} from '../actions/types/policies'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

export const initialState = {
  data: [],
  isLoading: false,
  isSubmitting: false,
  isFetchingPolicy: false,
  hasFetchError: false,
  clientId: '',
}

const policies = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case POLICIES_FETCH:
    case POLICIES_FETCH_INIT: {
      return {
        ...initialState,
        isFetchingPolicy: true,
        isLoading: true,
        hasFetchError: false,
      }
    }
    case POLICIES_FETCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.policies,
        clientId: action.payload.clientId,
        bancsCustomerNo: action.payload.bancsCustomerNo,
        isFetchingPolicy: false,
        isSubmitting: false,
        isLoading: false,
      }
    }
    case POLICIES_FETCH_ERROR:
    case POLICIES_FETCH_FAILURE: {
      return {
        ...state,
        data: [],
        isSubmitting: false,
        isFetchingPolicy: false,
        isLoading: false,
        hasFetchError: true,
      }
    }
    case POLICIES_RESET:
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default policies
