// @flow
import React, { type Node } from 'react'
import styled from '@emotion/styled'
import { Label } from '@mlcl-digital/mlcl-design'

// helpers.
import { idMaker, labelMaker } from '../../../../../utils/formUtils'
import { themeConsumer, getThemeProp } from '../../../../../styles/ThemeContext'

// styles.
import styles from './toggle.styles'

type ToggleProps = {
  // Value of the label attribute
  label: string,
  // Value of the name attribute
  name: string,
  // Sets whether the radio button is disabled or not
  disabled?: boolean,
  // Selected value of toggle list
  value?: any,
  // handles onchange event
  handleChange: Function,
  // an extra label for adding containing forms id for aria AA
  labelledby?: string,
  // Variant of toggle item to use in list
  variant?:
    | 'default'
    | 'detailed'
    | 'tab'
    | 'icon-lg'
    | 'icon-sm'
    | 'circle'
    | 'noFixedheight'
    | 'mobileFullWidth',
  // Set toggle group to error state
  error?: boolean,
  // Set detailed content/subcontent for label
  detail?: string,
  // Add icon for toggle/label
  icon?: Node,
  // Sets the default selection for the toggle
  checked: boolean,
  // data locator
  dataLocator?: string,
  labelStyles?: Object,
}

const Input = styled('input')(({ theme, variant }) => styles.input(theme, variant))
const RadioLabel = styled(Label)(
  ({ theme, variant, isFontBold }) => styles.label(theme, variant, isFontBold),
  ({ error }) => (error ? styles.error : null),
  ({ disabled }) => (disabled ? styles.disabled : null),
  ({ labelStyles }) => labelStyles || null
)

const Toggle = (props: ToggleProps) => {
  const {
    label,
    detail,
    name,
    icon,
    error,
    disabled,
    value,
    handleChange,
    labelledby,
    variant,
    checked,
    dataLocator,
    isFontBold,
    labelStyles,
  } = props

  const onChangeHandler = event => {
    handleChange(event.target)
  }

  const dataLocatorValue = {
    'data-locator': dataLocator || `input-radio-${name}`,
  }

  return (
    <div {...dataLocatorValue}>
      <Input
        {...getThemeProp(props)}
        type="radio"
        id={idMaker(`${name}-${value || label}`)}
        data-testid="toggle"
        name={name || label}
        disabled={disabled}
        value={value}
        onChange={onChangeHandler}
        checked={checked}
        aria-labelledby={`${labelledby} ${labelMaker(value || label)}`}
        variant={variant}
      />
      <RadioLabel
        {...getThemeProp(props)}
        error={error}
        disabled={disabled}
        htmlFor={idMaker(`${name}-${value || label}`)}
        id={idMaker(`${name}-${value || label}`)}
        data-testid="radioLabel"
        variant={variant}
        isFontBold={isFontBold}
        labelStyles={labelStyles}
      >
        {label || value}
        {detail || null}
        {icon || null}
      </RadioLabel>
    </div>
  )
}

Toggle.defaultProps = {
  value: '',
  labelledby: '',
  disabled: false,
  variant: 'default',
  error: false,
  detail: null,
  icon: null,
  dataLocator: '',
  labelStyles: {},
}

export default themeConsumer(Toggle, 'toggle')
