// @flow
import moment from 'moment'

/**
 * Returns escalation due date
 * @param {object} policy - policy data
 */
export const getEscalationDueDate = (policy: Object) => {
  const escalationDueDate = moment(policy?.escalationDueDate, 'YYYY-MM-DD')
  const lastAnniversaryDate = moment(policy?.lastAnniversaryDate, 'YYYY-MM-DD')
  const nextAnniversaryDate = moment(policy?.nextAnniversaryDate, 'YYYY-MM-DD')
  if (
    escalationDueDate.isAfter(lastAnniversaryDate) &&
    escalationDueDate.isSameOrBefore(nextAnniversaryDate)
  ) {
    return lastAnniversaryDate.format('YYYY-MM-DD')
  }
  if (escalationDueDate.isAfter(nextAnniversaryDate)) {
    return nextAnniversaryDate.format('YYYY-MM-DD')
  }
  return false
}
