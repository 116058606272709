import { Variables } from '@mlcl-digital/mlcl-design'

const { space, colours, fontSize, fontWeights } = Variables

const styles = {
  wrap: {
    paddingTop: space(5, true),
    paddingLeft: space(2, true),
    paddingRight: space(2, true),
  },
  resultsHeading: {
    width: '100%',
    textAlign: 'left',
    padding: space(2, true),
    color: colours.darkestGrey,
    fontWeight: fontWeights.semiBold,
    borderBottom: `1px solid ${colours.lightGrey}`,
  },
  lifeInsured: {
    width: '100%',
    padding: space(3, true),
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colours.lightGrey}`,
    fontWeight: fontWeights.semiBold,
    fontSize: fontSize.sm,
    color: colours.darkestGrey,
    lineHeight: space(3, true),
    cursor: 'pointer',
  },
  dateOfBirth: {
    fontWeight: fontWeights.normal,
    fontSize: fontSize.xs,
    color: colours.darkGrey,
  },
  arrow: {
    color: 'inherit',
    transform: 'translateX(0)',
    transition: 'transform .35s ease',
  },
  noResultFound: {
    padding: space(2, true),
    color: colours.red,
  },
  noResultFoundText: {
    display: 'block',
    marginBottom: space(2, true),
  },
  textArea: {
    color: colours.darkGrey,
    fontSize: fontSize.sm,
  },
  wrapper: {
    padding: space(2, true),
  },
  blockWrap: {
    textAlign: 'center',
    margin: 'auto',
    width: '70%',
    marginTop: space(12),
  },
  contentWrap: {
    marginTop: space(5, true),
    fontSize: fontSize.xs,
    fontWeight: fontWeights.semiBold,
    color: colours.darkGrey,
  },
  formWrapper: {
    height: `calc(100vh - ${space(24, true)})`,
    padding: space(2, true),
    overflowY: 'auto',
  },
  submitButton: {
    margin: space(3, true),
    width: `calc(100% - ${space(6, true)})`,
  },
}

export default styles
