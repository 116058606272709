import { space, fontSize, colours } from '../../../../../styles'

const styles = {
  wrapper: {
    border: `1px solid ${colours.mediumGrey}`,
    width: '100%',
    textAlign: 'center',
    'tr, td, th, thead, tbody': {
      display: 'inline-block',
    },
    tr: {
      width: '100%',
    },
    ':after': {
      clear: 'both',
      content: '',
      display: 'block',
    },
  },
  header: {
    borderBottom: `1px solid ${colours.lightestGrey}`,
    width: '100%',
    overflowY: 'scroll',
    float: 'left',
    display: 'block',
  },
  bodyWrapper: {
    height: space(25),
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    display: 'block',
  },
  body: {
    width: '100%',
    float: 'left',
    display: 'block',
    '&:nth-child(odd)': {
      background: colours.offWhite,
    },
    '&:hover': {
      cursor: 'pointer',
      background: colours.whiteGrey,
    },
  },
  nameCell: {
    width: '60%',
    textAlign: 'left',
    padding: space(2),
    borderRight: `1px solid ${colours.lightestGrey}`,
    fontSize: fontSize.xxs,
  },
  rankCells: {
    width: '40%',
    float: 'right',
  },
  rankCell: {
    width: '8%',
    padding: `${space(2, true)} 0`,
    fontSize: fontSize.xxs,
  },
  loader: {
    fontSize: fontSize.xxs,
  },
}

export default styles
