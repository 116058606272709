import React from 'react'
import styled from '@emotion/styled'

// components.
import { Card as ThirdPartyLoadingCard, Loader } from '@mlcl-digital/mlcl-design'
import Logo from '../../atoms/NewLogo'
// styles.
import styles from './thirdPartyLoading.styles'

const HeaderOuterContent = styled('div')(styles.headerOuterContent)
const ThirdPartyQuoteProgressMessage = styled('p')(styles.thirdPartyQuoteProgressMessage)
const ThirdPartyProgressContainer = styled('div')(styles.thirdPartyProgressContainer)

type ThirdPartyLoadingProps = {
  logoHorizontal: string
  logoVertical: string
  logoAltText: string
  message: string
}

type ThirdPartyLoaderProps = {
  thirdPartyQuoteProgressMsg: string
}

const ThirdPartyLoader = React.memo(({ thirdPartyQuoteProgressMsg }: ThirdPartyLoaderProps) => (
  <ThirdPartyProgressContainer>
    <Loader spinnerSize={60} borderSize={5} />
    <ThirdPartyQuoteProgressMessage>{thirdPartyQuoteProgressMsg}</ThirdPartyQuoteProgressMessage>
  </ThirdPartyProgressContainer>
))

const ThirdPartyLoading = ({
  logoHorizontal,
  logoVertical,
  logoAltText,
  message,
}: ThirdPartyLoadingProps) => {
  const renderHeader = () => (
    <>
      <HeaderOuterContent />
      <Logo href="/" horizontalSrc={logoHorizontal} verticalSrc={logoVertical} alt={logoAltText} />
      <HeaderOuterContent />
    </>
  )

  const renderBody = () => (
    <div>
      <ThirdPartyLoader thirdPartyQuoteProgressMsg={message} />
    </div>
  )

  return (
    <ThirdPartyLoadingCard
      styleOverrides={{
        base: styles.base,
        header: styles.header,
        body: styles.body,
      }}
      header={renderHeader()}
    >
      {renderBody()}
    </ThirdPartyLoadingCard>
  )
}

export default ThirdPartyLoading
