// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// components.
import Radio from '../../atoms/Radio'

// styles.
import styles from './radioGroup.styles'

export type RadioOptionType = {
  /** Value of one radio button */
  value: any,
  /** Label text for one radio button */
  text: string,
  /** the unique key for one single radio button in a group */
  key: string | number,
}

type RadioGroupProps = {
  /** Toggle list options */
  options: Array<RadioOptionType>,
  /** handles onchange event */
  handleChange: Function,
  /** Set toggle group to error state */
  error?: ?boolean,
  /** Sets whether the radio button group is disabled or not. */
  disabled?: boolean,
  /** selected radio item */
  selectedItem: ?string | ?number,
  /** Value of the name attribute. */
  name: string,
}

const RadioGroupWrapper = styled('div')(styles.radioSection)
const RadioElementWrapper = styled('div')(styles.radioElement)
const ErrorMessage = styled('p')(styles.errorMessage)

const RadioGroup = (props: RadioGroupProps) => {
  const { options, error, selectedItem, disabled, handleChange, name } = props

  return (
    <Fragment>
      <RadioGroupWrapper>
        {options.map(option => (
          <RadioElementWrapper key={option.key}>
            <Radio
              text={option.text}
              value={option.value}
              name={`${name}_radio_${option.key}`}
              htmlFor={`${name}-radio-${option.key}`}
              handleOnChange={handleChange}
              checked={selectedItem && selectedItem === option.key}
              disabled={disabled}
            />
          </RadioElementWrapper>
        ))}
      </RadioGroupWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  )
}

RadioGroup.defaultProps = {
  error: undefined,
  disabled: false,
}

export default RadioGroup
