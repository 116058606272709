// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { createEvent } from '../../../utils/telemetry'

// redux.

// helpers
import { reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import { getMaskedFactorType } from '../../../utils/bitMaskingUtil'
import { decodeCookie } from '../../../utils/cookieUtils'
import { validateAll } from '../../../utils/formUtils'
import { checkIfActiveSession } from '../../../utils/oktaUtils'
import { isLocalStorage } from '../../../utils/browserUtils'

// constants
import { THIRD_PARTY_QUOTE_COOKIE_NAME, THIRD_PARTY_SOURCE } from '../../../constants/bancs'

// components.
import LoginWrapper from '../../molecules/LoginWrapper'

import styles from './loginForm.styles'
// schemas.
import {
  FORM_ID,
  userNameFormSchema,
  userNameComponentSchema,
  passwordComponentSchema,
  passwordFormSchema,
  otpComponentSchema,
  otpFormSchema,
  resetPasswordFormSchema,
  resetPasswordComponentSchema,
  USERNAME_FIELD_KEY,
  OTP_FIELD_KEY,
  PASSWORD_FIELD_KEY,
  NEW_PASSWORD_FIELD_KEY,
  CONFIRM_NEW_PASSWORD_FIELD_KEY,
  mfaComponentSchema,
  forgottenUserNameComponentSchema,
  forgottenUserNameFormSchema,
  forgottenUserNameConfirmationComponentSchema,
} from './Schema/login.schema'

// styles.
import { themeConsumer } from '../../../styles/ThemeContext'
import { ADVISOR_PORTAL } from '../../../constants/site'

const CenterBox = styled('div')(styles.base)
const BackgroundDetail = styled('div')(({ theme }) => styles.backgroundDetail(theme))

type LoginFormProps = {
  // sitecore fields
  fields: Object<Object>,
  // redux actions
  actions: {
    formInit: Function,
    formUpdateField: Function,
    formValidate: Function,
    formSubmit: Function,
    resetPassword: Function,
    formRemoveFields: Function,
    formAddField: Function,
    oktaResetErrorCode: Function,
    oktaResetIsError: Function,
    resetOktaAuthorizationError: Function,
    getUserProfile: Function,
    signOut: Function,
    verifyEmailCode: Function,
  },
  // redux authentication
  authentication: {
    // password criteria
    passwordCriteria: Object<Object>,
  },
  // redux form
  form: Object<Object>,
  // okta details
  okta: Object,
  isAdviserPortal: Boolean,
}

type LoginFormState = {
  // form schema to load
  formSchema: Object<Object>,
  // component to render
  componentSchema: Object<Object>,
}

export class LoginFormComponent extends Component<LoginFormProps, LoginFormState> {
  constructor(props) {
    super(props)
    const { fields, isAdviserPortal } = props

    const reduceFields = reduceAuthorableFields(fields)
    this.state = {
      formSchema: userNameFormSchema(reduceFields, isAdviserPortal),
      componentSchema: userNameComponentSchema(
        reduceFields,
        this.handleUsernameForm,
        this.loadForgottenUsernameForm
      ),
    }
  }

  async componentDidMount() {
    const {
      actions: {
        transformThirdPartyQuote,
        formInit,
        oktaResetUserAccountStatus,
        resetAuthenticationState,
      },
      fields,
      config,
      isAdviserPortal,
    } = this.props

    const isActiveSession = await checkIfActiveSession(config)
    const thirdPartyQuoteKey = decodeCookie(THIRD_PARTY_QUOTE_COOKIE_NAME)
    const thirdPartySource = decodeCookie(THIRD_PARTY_SOURCE)
    const reduceFields = reduceAuthorableFields(fields)

    oktaResetUserAccountStatus()
    if (isLocalStorage()) {
      window.localStorage.removeItem('userId')
    }
    if (isActiveSession) {
      if (thirdPartyQuoteKey) {
        transformThirdPartyQuote(thirdPartyQuoteKey, thirdPartySource, true)
      }
    } else {
      resetAuthenticationState()
    }
    formInit(FORM_ID, userNameFormSchema(reduceFields, isAdviserPortal))
  }

  validateForm = () => {
    const {
      actions: { formValidate },
    } = this.props
    const { formSchema } = this.state

    formValidate(FORM_ID, formSchema)
  }

  handlePasswordForm = () => {
    const {
      actions: { oktaSignIn, formSubmit },
    } = this.props
    const { formSchema } = this.state

    formSubmit(FORM_ID, formSchema, formFields => {
      if (formFields[USERNAME_FIELD_KEY] && formFields[PASSWORD_FIELD_KEY])
        // call okta sign in
        oktaSignIn(
          {
            username: formFields[USERNAME_FIELD_KEY],
            password: formFields[PASSWORD_FIELD_KEY],
          },
          this.loadOTPChallengeSchema
        )
    })
  }

  handleOTPForm = () => {
    const {
      actions: { verifyCode, formSubmit, verifyEmailCode },
      authentication: { isMobileNumberExists, isSupportStaff },
    } = this.props
    const { formSchema } = this.state
    // remove password field from redux

    formSubmit(FORM_ID, formSchema, formFields => {
      if (formFields[OTP_FIELD_KEY])
        if (getMaskedFactorType(isMobileNumberExists, isSupportStaff)) {
          // call verify code api
          verifyCode(formFields[OTP_FIELD_KEY], this.loadResetPasswordSchema)
        } else {
          verifyEmailCode(formFields[OTP_FIELD_KEY], this.loadResetPasswordSchema)
        }
    })
  }

  handleOTPChallengeSchema = () => {
    const {
      actions: { verifyOktaPrimaryAuthSMSFactor, formSubmit },
    } = this.props
    const { formSchema } = this.state
    // remove password field from redux

    formSubmit(FORM_ID, formSchema, formFields => {
      if (formFields[OTP_FIELD_KEY])
        // call verify code api
        verifyOktaPrimaryAuthSMSFactor(formFields[OTP_FIELD_KEY])
    })
  }

  handleResetPasswordForm = () => {
    const {
      actions: { resetPassword, formSubmit },
    } = this.props

    const { formSchema } = this.state
    // remove otp field from redux

    formSubmit(FORM_ID, formSchema, formFields => {
      if (formFields[NEW_PASSWORD_FIELD_KEY] && formFields[CONFIRM_NEW_PASSWORD_FIELD_KEY])
        // call reset password api
        resetPassword(
          {
            newPassword: formFields[NEW_PASSWORD_FIELD_KEY],
          },
          this.loadOTPChallengeSchema
        )
    })
  }

  loadUserNameSchema = () => {
    const {
      fields,
      actions: { formAddField, oktaResetErrorCode, oktaResetIsError, resetOktaAuthorizationError },
      isAdviserPortal,
    } = this.props
    oktaResetErrorCode()
    oktaResetIsError()
    resetOktaAuthorizationError()
    const reduceFields = reduceAuthorableFields(fields)
    formAddField(FORM_ID, USERNAME_FIELD_KEY, { value: '' })
    this.setState({
      formSchema: userNameFormSchema(reduceFields, isAdviserPortal),
      componentSchema: userNameComponentSchema(
        reduceFields,
        this.handleUsernameForm,
        this.loadForgottenUsernameForm
      ),
    })
  }

  loadOTPChallengeSchema = () => {
    const {
      fields,
      actions: { formAddField, oktaResetErrorCode, oktaResetIsError, resetOktaAuthorizationError },
    } = this.props
    oktaResetErrorCode()
    oktaResetIsError()
    resetOktaAuthorizationError()
    const reduceFields = reduceAuthorableFields(fields)
    formAddField(FORM_ID, OTP_FIELD_KEY, { value: '' })
    this.setState({
      formSchema: otpFormSchema(reduceFields),
      componentSchema: mfaComponentSchema(
        reduceFields,
        this.handleOTPChallengeSchema,
        this.loadUserNameSchema
      ),
    })
  }

  loadPasswordSchema = () => {
    const {
      fields,
      actions: { formAddField, oktaResetErrorCode, oktaResetIsError, resetOktaAuthorizationError },
    } = this.props
    oktaResetErrorCode()
    oktaResetIsError()
    resetOktaAuthorizationError()
    const reduceFields = reduceAuthorableFields(fields)
    formAddField(FORM_ID, PASSWORD_FIELD_KEY, { value: '' })

    this.setState({
      formSchema: passwordFormSchema(reduceFields),
      componentSchema: passwordComponentSchema(
        reduceFields,
        this.handlePasswordForm,
        this.loadUserNameSchema,
        this.loadOTPSchema
      ),
    })
  }

  loadOTPSchema = () => {
    const {
      fields,
      actions: { formAddField, oktaResetErrorCode, oktaResetIsError, resetOktaAuthorizationError },
    } = this.props
    oktaResetErrorCode()
    oktaResetIsError()
    resetOktaAuthorizationError()
    const reduceFields = reduceAuthorableFields(fields)
    formAddField(FORM_ID, OTP_FIELD_KEY, { value: '' })
    this.setState({
      formSchema: otpFormSchema(reduceFields),
      componentSchema: otpComponentSchema(
        reduceFields,
        this.handleOTPForm,
        this.loadUserNameSchema
      ),
    })
  }

  loadResetPasswordSchema = () => {
    const {
      fields,
      form,
      authentication: { passwordCriteria },
      actions: { formAddField, oktaResetErrorCode, oktaResetIsError, resetOktaAuthorizationError },
    } = this.props
    oktaResetErrorCode()
    oktaResetIsError()
    resetOktaAuthorizationError()
    const reduceFields = reduceAuthorableFields(fields)

    formAddField(FORM_ID, NEW_PASSWORD_FIELD_KEY, { value: '' })
    formAddField(FORM_ID, CONFIRM_NEW_PASSWORD_FIELD_KEY, { value: '' })
    this.setState({
      formSchema: resetPasswordFormSchema(reduceFields, form, passwordCriteria),
      componentSchema: resetPasswordComponentSchema(
        reduceFields,
        this.handleResetPasswordForm,
        this.loadOTPSchema
      ),
    })
  }

  loadSchema = () => {
    const {
      authentication: { requiresPasswordReset, isLocked, isValidIdentity },
    } = this.props

    if (!isLocked) {
      if (isValidIdentity)
        if (requiresPasswordReset) {
          this.loadOTPSchema()
        } else {
          this.loadPasswordSchema()
        }
    }
  }

  handleUsernameForm = () => {
    const {
      actions: { getUserProfile, formSubmit, setOktaUserId, formUpdateValidations },
      form,
      isAdviserPortal,
      fields,
    } = this.props

    const { formSchema } = this.state

    const userId = isLocalStorage() && window.localStorage.getItem('userId')

    const userNameSchemaWithFields = userNameFormSchema(
      reduceAuthorableFields(fields),
      isAdviserPortal,
      Boolean(userId && userId !== form.fields[USERNAME_FIELD_KEY].value)
    )

    formUpdateValidations(FORM_ID, userNameSchemaWithFields)
    if (validateAll(userNameSchemaWithFields, form.fields)) {
      formSubmit(FORM_ID, formSchema, formFields => {
        if (formFields[USERNAME_FIELD_KEY]) {
          // set okta userId and remove extra spaces for userid
          const inputUserId =
            formFields[USERNAME_FIELD_KEY] && formFields[USERNAME_FIELD_KEY].trim()
          setOktaUserId(inputUserId)
          // fetch user profile
          getUserProfile(inputUserId, this.loadSchema)
        }
      })
    }
  }

  loadForgottenUsernameForm = () => {
    const {
      fields,
      actions: { oktaResetErrorCode, oktaResetIsError, resetOktaAuthorizationError },
    } = this.props
    oktaResetErrorCode()
    oktaResetIsError()
    resetOktaAuthorizationError()
    const reduceFields = reduceAuthorableFields(fields)
    const event = createEvent({
      GA: {
        category: 'Forgot customer number',
        action: 'Forgot customer number - Select',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Forgot customer number - Select',
        },
      },
    })
    event.end()
    this.setState({
      formSchema: forgottenUserNameFormSchema(reduceFields),
      componentSchema: forgottenUserNameComponentSchema(
        reduceFields,
        this.loadUserNameSchema,
        this.handleForgotUsernameForm
      ),
    })
  }

  handleForgotUsernameForm = () => {
    const {
      actions: { formSubmit, oktaForgotUsername },
    } = this.props

    const { formSchema } = this.state
    formSubmit(FORM_ID, formSchema, formFields => {
      const { forgottenUserName } = formFields
      oktaForgotUsername(forgottenUserName, isSuccessful => {
        this.handleForgotUsernameConfirmation(isSuccessful)
      })
    })
  }

  handleForgotUsernameConfirmation = isSuccessful => {
    const {
      fields,
      form: {
        fields: {
          forgottenUserName: { value },
        },
      },
    } = this.props
    const reduceFields = reduceAuthorableFields(fields)
    if (isSuccessful) {
      this.setState({
        componentSchema: forgottenUserNameConfirmationComponentSchema(
          reduceFields,
          this.loadUserNameSchema,
          value
        ),
      })
    }
    const tagEvent = createEvent({
      GA: {
        category: 'Forgot customer number',
        action: `Forgotten username - ${isSuccessful ? 'success' : 'unsuccessful'}`,
      },
      Splunk: {
        attributes: {
          'workflow.name': `Forgot customer number - ${isSuccessful ? '(success)' : '(failure)'}`,
        },
      },
    })
    tagEvent.end()
  }

  render() {
    const { formSchema, componentSchema } = this.state
    const { theme } = this.props
    return (
      <>
        <CenterBox>
          <LoginWrapper
            data-testid={`${FORM_ID}-login-form`}
            {...this.props}
            components={componentSchema}
            formSchema={formSchema}
            formId={FORM_ID}
          />
        </CenterBox>
        <BackgroundDetail theme={theme} />
      </>
    )
  }
}

export const mapStateToProps = (state, ownProps) => ({
  form: state.forms[FORM_ID],
  authentication: state.authentication,
  isFetchingAdviserDetails: state.advisor.isLoading && !state.advisor.details,
  ...ownProps,
  okta: state.okta,
  config: state.config,
  isAdviserPortal: state.config && state.config.SITE === ADVISOR_PORTAL,
})

export default withRouter(connect(mapStateToProps)(themeConsumer(LoginFormComponent, 'banner')))
