/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconEdit32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99 22L18 8l5 5L9 27H4l-.01-5zM27 9l-3 3-5-5 3-3c.5-.5 1-1 2 0l3 3c1 1 .5 1.5 0 2z"
      fill="#000"
    />
  </Icon>
)

export default IconEdit32
