// @flow
import React from 'react'
import styled from '@emotion/styled'
import { Image, PageWrap, Icons } from '@mlcl-digital/mlcl-design'

// components
import Title from './components/Title'

// helpers.
import { renderTextField } from '../../../utils/sitecoreUtils'

// styles
import styles from './pageHeader.styles'

// icons
const { IconArrowLeft16 } = Icons

const TopBackground = styled('div')(({ theme }) => styles.topBackground(theme))
const HeaderPageWrap = styled(PageWrap)(({ theme }) => styles.headerPageWrap(theme))
const InfoContainer = styled('section')(({ nowrap = false, slim }) =>
  styles.infoContainer({ nowrap, slim })
)
const ContentContainer = styled('div')(styles.contentContainer)
const ChildrenContainer = styled('div')(styles.childrenContainer)
const BackButton = styled('button')(({ theme }) => styles.backButton(theme))
const RightImage = styled(Image)(({ theme, bigImage }) => styles.rightImage(theme, bigImage))

type HeaderComponentPropTypes = {
  // back click handler
  backClickHandler: Function,
  // page heading
  heading: {
    value: String,
  },
  // page sub heading
  subHeading: {
    value: String,
  },
  // childrens
  children: Object,
  // icon text for back button
  iconText: Object,
  theme: String,
  // this will align 'heading' and 'subComponent' side by side
  // in one line, mostly for Tagging purpose only.
  isTag: Boolean,
  // this prop will change size of the Header image to avoid
  // overlapping with some page content because some page content
  // has negative margin
  bigImage?: Boolean,
  // source of the banner image
  imageSrc?: String,
  imageAlt?: String,
  showBackIcon: Boolean,
  subComponent: Node,
  // Disable back button
  isBackButtonDisabled?: Boolean,
  nowrap?: Boolean,
  slim?: Boolean,
}

const HeaderCompoment = ({
  backClickHandler,
  heading,
  subHeading,
  iconText,
  children,
  showBackIcon,
  theme,
  subComponent,
  isTag,
  bigImage,
  imageSrc,
  imageAlt,
  isBackButtonDisabled,
  nowrap,
  slim,
}: HeaderComponentPropTypes) => (
  <TopBackground theme={theme}>
    <HeaderPageWrap theme={theme}>
      <InfoContainer nowrap={nowrap} slim={slim}>
        <ContentContainer slim={slim}>
          {showBackIcon && (
            <BackButton onClick={backClickHandler} theme={theme} disabled={isBackButtonDisabled}>
              <IconArrowLeft16 /> {renderTextField(iconText)}
            </BackButton>
          )}
          <Title
            heading={heading}
            subHeading={subHeading}
            theme={theme}
            subComponent={subComponent}
            isTag={isTag}
          />
        </ContentContainer>
        {children && <ChildrenContainer>{children}</ChildrenContainer>}
      </InfoContainer>
      {!children && imageSrc && (
        <RightImage theme={theme} bigImage={bigImage} src={imageSrc} alt={imageAlt} />
      )}
    </HeaderPageWrap>
  </TopBackground>
)

HeaderCompoment.defaultProps = {
  bigImage: false,
  imageSrc: '',
  imageAlt: '',
  isBackButtonDisabled: false,
  nowrap: false,
  slim: false,
}

export default HeaderCompoment
