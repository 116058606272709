export const ONE_OFF_PAYMENT = 'oneOffPayment'
export const SAVE_CARD_PAYMENT = 'saveCard'
export const MASTERKEY_ACCNT = 'MASTERKEY_ACCNT'
export const MASTERKEY_ACCNT_PROVIDED = 'MASTERKEY_ACCNT_PROVIDED'
export const PAYMENT_CHEQUE = 'PAYMENT_CHEQUE'
export const NAVIGATOR_ACCNT = 'NAVIGATOR_ACCNT'
export const INSIGNIA_ACCOUNT = 'INSIGNIA_ACCOUNT'
export const NAVIGATOR_ACCNT_PROVIDED = 'NAVIGATOR_ACCNT_PROVIDED'
export const PAYMENT_BPAY = 'PAYMENT_BPAY'
export const PAYMENT_ER = 'PAYMENT_ER'
export const AUTH_CANCEL_DOC = 'AUTH_CANCEL_DOC'
export const BENF_DOC = 'BENF_DOC'
export const MEDICAL_DOC = 'MEDICAL_DOC'
export const FINANCIAL_DOC = 'FINANCIAL_DOC'
export const QUOTE_DOC = 'QUOTE_DOC'
export const OTHER_DOC = 'OTHER_DOC'
export const ADVISOR_SPLIT = 'ADVISOR_SPLIT'
export const INFORCE_DT = 'INFORCE_DT'
export const BENF = 'BENF'
export const LA_BENF = 'LA_BENF'
export const CP_ONE_OFF_PAYMENT = 'cpOneOffPayment'
export const POLICY_LAPSE_STATUS = 'Revivable Lapse'
export const POLICY_OUT_OF_FORCE = 'policyIsOutOfForce'
export const POLICY_LAPSE_TOO_LONG = 'policyLapsedTooLong'
export const POLICY_PAYMENT_INVALID = 'policyPaymentInvalid'
export const CP_PAYMENT_CONCIERGE_MENU_TEXT = 'MAKE A PAYMENT'
