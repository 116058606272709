import 'core-js/es7/array'
import { createSelector } from 'reselect'
import {
  EXP_CUSTOMER_STATUS_UNLOADED,
  EXP_CUSTOMER_STATUS_LOADING,
  EXP_CUSTOMER_STATUS_FAILED,
  EXP_CUSTOMER_STATUS_LOADED,
} from '../constants/apis'

// utils
// @ts-expect-error file not in typescript
import { getAllInForcePolicies, getAllOutOfForcePolicies } from '../utils/policyUtils'

// types
import { Store } from '../types/store'
import { CustomerPolicy } from '../types/ClientPolicies'

// Root selectors
export const getPolicyStatuses = (state: Store) =>
  Object.values(state.customerPolicyStatus).map(status => ({
    policyNo: status.policyNo,
    loaded: status.statuses.self.status,
    paymentInstrumentLoaded: status.statuses.paymentInstrument.status,
    paymentHistory: status.statuses.paymentHistory.status,
    workItems: status.statuses.workItems.status,
  }))
export const getCustomerPolicies = (state: Store) => Object.values(state.customerPolicies)

export const getCustomerRelationshipsObject = (state: Store) => state.customerRelationships

export const getCustomerRelationships = (state: Store) => Object.values(state.customerRelationships)

export const getCustomerPolicySummaryStatus = (state: Store) =>
  state.customerPolicySummaryStatus.isLoading

const authentication = (state: Store) => state.authentication

export const haveAnyPoliciesFailed = createSelector([getPolicyStatuses], statuses =>
  statuses.some(status => status.loaded === EXP_CUSTOMER_STATUS_FAILED)
)

export const getAllPolicyBancsPolicyNos = createSelector([getCustomerPolicies], policies =>
  policies.map(policy => policy.policyNo)
)

export const getAllPolicyBancsPolicyIds = createSelector([getCustomerPolicies], policies =>
  policies.map(policy => policy.policyId)
)

// Selectors
export const getMasterList = (state: Store) => ({
  policyStatus: state.masterList?.data?.policyStatus || [],
  benefitStatus: state.masterList?.data?.benefitStatus || [],
  benefitDescriptions: state.masterList?.data?.benefitDescriptions || [],
  benefitList: state.masterList?.data?.benefitList || [],
})

export const checkIfAllPoliciesLoaded = createSelector(
  [getPolicyStatuses],
  policies =>
    !policies.some(
      ({ loaded }) =>
        loaded === EXP_CUSTOMER_STATUS_UNLOADED || loaded === EXP_CUSTOMER_STATUS_LOADING
    )
)

export const checkIfAllWorkItemsLoaded = createSelector(
  [getPolicyStatuses],
  policies =>
    !policies.some(
      ({ workItems }) =>
        workItems === EXP_CUSTOMER_STATUS_UNLOADED || workItems === EXP_CUSTOMER_STATUS_LOADING
    )
)

export const hasSummaryLoaded = createSelector(
  [getCustomerPolicySummaryStatus],
  summaryStatus => summaryStatus === EXP_CUSTOMER_STATUS_LOADED
)

export const getCustomerAdvisorList = createSelector([getCustomerPolicies], policies => [
  ...new Set(policies.flatMap(policy => policy.agents)),
])

export const hasCustomerAdviserDetailsFinishedLoading = createSelector(
  [getCustomerRelationshipsObject, getCustomerAdvisorList],
  (relationships, advisors) => {
    if (!advisors[0] || !relationships[advisors[0]]) {
      return true
    }
    const { loadingState } = relationships[advisors[0]]
    return !(
      loadingState === EXP_CUSTOMER_STATUS_UNLOADED || loadingState === EXP_CUSTOMER_STATUS_LOADING
    )
  }
)

export const hasCustomerAdviserDetailsFailed = createSelector(
  [getCustomerRelationshipsObject, getCustomerAdvisorList],
  (relationships, advisors) => {
    if (!advisors[0] || !relationships[advisors[0]]) {
      return false
    }
    const { loadingState } = relationships[advisors[0]]
    return loadingState === EXP_CUSTOMER_STATUS_FAILED
  }
)

export const getInforceCustomerPolicies = createSelector(
  [getCustomerPolicies, getMasterList],
  (policies, { policyStatus }) => getAllInForcePolicies(policies, policyStatus) as CustomerPolicy[]
)

export const getOutOfForceCustomerPolicies = createSelector(
  [getCustomerPolicies, getMasterList],
  (policies, { policyStatus }) =>
    getAllOutOfForcePolicies(policies, policyStatus) as CustomerPolicy[]
)

export const checkIfAllRelationshipsHaveRelatedParty = createSelector(
  [getCustomerRelationships],
  customerRelationships => {
    const bancsCustNos = Object.values(customerRelationships)
    return (
      bancsCustNos.length &&
      bancsCustNos.every(relationship => Boolean(relationship.hasRelatedParty))
    )
  }
)

export const shouldShowCommsModal = createSelector(
  [authentication],
  auth => !auth.hasSeenDigitalComms && auth.hasCompletedWelcomeJourney
)

export const getUserId = createSelector([authentication], auth => auth.userId)

// to decide if making related party api call is required
export const getIsRelatedPartyLoadedOrLoading = createSelector(
  [getCustomerRelationships],
  customerRelationships => {
    const bancsCustNos = Object.values(customerRelationships)
    return (
      bancsCustNos.length &&
      bancsCustNos.every(
        relationship =>
          relationship.loadingState === EXP_CUSTOMER_STATUS_LOADING ||
          relationship.loadingState === EXP_CUSTOMER_STATUS_LOADED
      )
    )
  }
)

export const getModal = (state: Store) => state.modal
