/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconFilter16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5152 0.909091C13.5152 1.40909 13.1061 1.81818 12.6061 1.81818H1.39396C0.893955 1.81818 0.484863 1.40909 0.484863 0.909091C0.484863 0.409091 0.893955 0 1.39396 0H12.6061C13.1061 0 13.5152 0.409091 13.5152 0.909091Z"
        fill="black"
      />
      <path
        d="M9.99992 4.09082H3.99991C3.49991 4.09082 3.09082 4.49991 3.09082 4.99991C3.09082 5.49992 3.49991 5.90901 3.99991 5.90901H9.99992C10.4999 5.90901 10.909 5.49992 10.909 4.99991C10.909 4.49991 10.4999 4.09082 9.99992 4.09082Z"
        fill="black"
      />
      <path
        d="M8.15158 8.18188H5.84855C5.34855 8.18188 4.93945 8.59097 4.93945 9.09097C4.93945 9.59097 5.34855 10.0001 5.84855 10.0001H8.15158C8.65158 10.0001 9.06067 9.59097 9.06067 9.09097C9.06067 8.59097 8.65158 8.18188 8.15158 8.18188Z"
        fill="black"
      />
    </svg>
  </Icon>
)

export default IconFilter16
