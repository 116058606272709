// @flow
import React from 'react'
import type { Node } from 'react'
import styled from '@emotion/styled'

// utils
import { convertStringToKey } from '../../../utils/commonUtils'
import { getBenefitLinkOptionLabel } from '../../../utils/quoteUtils'
import { isPolicyCoverChildOrParent } from '../../../utils/policyStructureUtils'

// styles
import styles from './policyStructure.styles'

type displayPolicyStructureCoverType = {
  name: string,
  type: string,
  benefitInstanceNo: string,
  policyInstanceNo: string,
  covers: Array<Object>,
  parentBenefitReference?: Object,
  optimiserParentBenefitReference?: Object,
}
type PolicyStructureProps = {
  policy: {
    // Product name
    productName: string,
    // Product ID
    productId: string,
    // Policy InstanceNo.
    policyInstanceNo: number,
    // Covers
    covers: Array<{
      // Benefit name
      name: string,
      // Cover children
      coverChildren: Array<{
        // Name
        name: string,
        // BenefitInstanceNo
        benefitInstanceNo: number,
      }>,
    }>,
  },
  displayPolicyStructure: Array<displayPolicyStructureCoverType>,
}
type PolicyStructureChildProps = {
  ...PolicyStructureProps,
  displayPolicyStructureFullData: Array<displayPolicyStructureCoverType>,
}

const Root = styled('section')(styles.root)
const TreeChildren = styled('li')(styles.treeChildren)
const TreeChildrenItemContainer = styled('ul')(styles.treeChildrenItemContainer)
const TreeChildrenLeafItem = styled('li')(styles.treeChildrenLeafItem)
const TreeChildrenLeafLabel = styled('span')(({ isLinkedToSamePolicy }) =>
  styles.treeChildrenLeafLabel(isLinkedToSamePolicy)
)

export const Tree = styled('ul')(styles.tree)
export const PolicyName = styled('p')(styles.policyName)

export const TreeChildrenLeaf = ({
  displayPolicyStructure,
  policy,
  displayPolicyStructureFullData,
}: PolicyStructureChildProps): Node =>
  displayPolicyStructure
    .filter(cover =>
      isPolicyCoverChildOrParent(cover, policy.policyInstanceNo, displayPolicyStructureFullData)
    )
    .map((cover, childIndex) => {
      const coverKey = `${convertStringToKey(cover.name)}_${cover.benefitInstanceNo}_${childIndex}`

      const getName =
        cover.policyInstanceNo.toString() === policy.policyInstanceNo.toString()
          ? `${cover.name} ${
              (cover.optimiserParentBenefitReference && '(Optimiser)') || ''
            } - Linked`
          : getBenefitLinkOptionLabel(
              {
                name: cover.name,
                optimiserParentBenefitReference: cover.optimiserParentBenefitReference,
              },
              cover.policyInstanceNo
            )
      const isLinkedToSamePolicy =
        cover.policyInstanceNo.toString() === policy.policyInstanceNo.toString()

      return (
        <TreeChildrenLeafItem key={coverKey}>
          <TreeChildrenLeafLabel isLinkedToSamePolicy={isLinkedToSamePolicy}>
            {getName}
          </TreeChildrenLeafLabel>
          {cover.covers && cover.covers.length ? (
            <TreeChildrenItemContainer>
              <TreeChildrenLeaf
                displayPolicyStructure={cover.covers}
                policy={policy}
                displayPolicyStructureFullData={displayPolicyStructureFullData}
              />
            </TreeChildrenItemContainer>
          ) : null}
        </TreeChildrenLeafItem>
      )
    })

export const TreeItems = ({ displayPolicyStructure, policy }: PolicyStructureProps): Node =>
  displayPolicyStructure
    .filter(cover =>
      isPolicyCoverChildOrParent(cover, policy.policyInstanceNo, displayPolicyStructure)
    )
    .map(({ name, covers, policyInstanceNo, optimiserParentBenefitReference }, index) => {
      const coverKey = `${convertStringToKey(name)}_${index}`

      const getName =
        policyInstanceNo.toString() === policy.policyInstanceNo.toString()
          ? name
          : getBenefitLinkOptionLabel({ name, optimiserParentBenefitReference }, policyInstanceNo)
      const isLinkedToSamePolicy =
        policyInstanceNo.toString() === policy.policyInstanceNo.toString()

      return (
        <TreeChildren key={coverKey}>
          <TreeChildrenLeafLabel isLinkedToSamePolicy={isLinkedToSamePolicy}>
            {getName}
          </TreeChildrenLeafLabel>
          {covers && !!covers.length && (
            <TreeChildrenItemContainer>
              <TreeChildrenLeaf
                displayPolicyStructure={covers}
                policy={policy}
                displayPolicyStructureFullData={displayPolicyStructure}
              />
            </TreeChildrenItemContainer>
          )}
        </TreeChildren>
      )
    })

const PolicyStructure = ({ policy, displayPolicyStructure }: PolicyStructureProps): Node => {
  const { productName } = policy

  return (
    <Root>
      <PolicyName>{productName}</PolicyName>
      {displayPolicyStructure && displayPolicyStructure.length ? (
        <Tree>
          <TreeItems displayPolicyStructure={displayPolicyStructure} policy={policy} />
        </Tree>
      ) : null}
    </Root>
  )
}

export default PolicyStructure
