// @flow
import React, { Fragment } from 'react'
import styled from '@emotion/styled'

// icons
import { IconEdit16, IconClose16 } from '../../../../../atoms/Icons'

// styles.
import styles from './loadingList.styles'

// components
import Button from '../../../../../atoms/Button'

export const LoadingListItem = styled('div')(({ showBorder }) => styles.loadingListItem(showBorder))
export const RemoveLoading = styled(Button)(styles.removeLoading)
export const EditLoading = styled(Button)(styles.editLoading)
const LoadingListContainer = styled('div')(styles.loadingListContainer)
const LoadingListCaption = styled('p')(styles.loadingListCaption)
const LoadingList = styled('div')(styles.loadingList)
const MiscDetails = styled('span')(styles.miscDetails)

type loadingListProps = {
  loadings: [
    {
      benefit: string,
      reason: string,
      type: string,
      amount: string,
    }
  ],
  className: string,
  editHandler?: Function,
  removeHandler?: Function,
}

const LoadingListComponent = ({
  editHandler,
  removeHandler,
  className,
  loadings,
}: loadingListProps) => (
  <LoadingListContainer className={className}>
    <LoadingList>
      {loadings &&
        loadings[0] &&
        loadings.map((loading, index) => {
          const { benefit, reason, type, amount } = loading
          const key = `${benefit}-${index}`

          return (
            <Fragment key={key}>
              <LoadingListCaption>{benefit}</LoadingListCaption>
              <LoadingListItem showBorder={index !== loadings.length - 1}>
                <MiscDetails>{`${reason}${reason && ', '}${type}, ${amount}`}</MiscDetails>
                <EditLoading aria-label="Editing" onClick={() => editHandler(index)}>
                  <IconEdit16 />
                </EditLoading>
                <RemoveLoading aria-label="Removing" onClick={() => removeHandler(index)}>
                  <IconClose16 />
                </RemoveLoading>
              </LoadingListItem>
            </Fragment>
          )
        })}
    </LoadingList>
  </LoadingListContainer>
)

LoadingListComponent.defaultProps = {
  editHandler() {},
  removeHandler() {},
}

export default LoadingListComponent
