// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'

import { actionCreators } from '../../../actions'
import Modal from '../../molecules/Modal'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import styles from './enquiryIdExpiredModal.styles'

type EnquiryIdExpiredModalProps = {
  enquiryIdModalTitle: Object,
  enquiryIdModalContent: Object,
  enquiryIdModalButton: Object,
  enquiryIdExpiredModal: Object,
  actions: Object,
  callback: Function,
  onClose: Function,
}

const SubHeading = styled(Heading)(styles.subHeading)
const EnquiryIdModalBtn = styled(Button)(styles.confirmBtn)

export class EnquiryIdExpiredModal extends Component<EnquiryIdExpiredModalProps> {
  handleModalClose = () => {
    const {
      actions: { showEnquiryIdExpiredModal },
    } = this.props
    showEnquiryIdExpiredModal(false)
  }

  render() {
    const {
      enquiryIdModalTitle,
      enquiryIdModalContent,
      enquiryIdModalButton,
      enquiryIdExpiredModal,
      callback,
      onClose,
    } = this.props
    return (
      <Modal
        isOpen={enquiryIdExpiredModal.showModal}
        title={enquiryIdModalTitle}
        onClose={onClose || this.handleModalClose}
      >
        <SubHeading size="6">{enquiryIdModalContent}</SubHeading>
        <EnquiryIdModalBtn type="secondary" onClick={callback}>
          {enquiryIdModalButton}
        </EnquiryIdModalBtn>
      </Modal>
    )
  }
}

export const mapStateToProps = ({ enquiryIdExpiredModal }) => ({
  enquiryIdExpiredModal,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryIdExpiredModal)
