/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconEmail32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 18.661L6 14.138V22h19v-7.862l-9.5 4.523zm9.5-7.846V9H6v1.815l9.5 4.524 9.5-4.524zM6 6h19a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3z"
      fill="#000"
    />
  </Icon>
)

export default IconEmail32
