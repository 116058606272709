// components.
import QuoteQuickView from '../../QuoteQuickView'

const SIDEBAR_SCHEMA = () => [
  {
    sitecoreFieldHeadingKey: 'viewQuoteHeader',
    component: QuoteQuickView,
    isDark: true,
  },
]

export default SIDEBAR_SCHEMA
