export const ARTICLE_TAG_PAGE_URL = 'info-hub/article/tag'
export const ARTICLE_LISTING_PAGE_URL = 'info-hub'
export const ADVISER_ARTICLE_LISTING_PAGE_URL = 'knowledge-base'
export const ARTICLE_NO_OF_RECORDS = 6
export const NO_MORE_ARTICLES_AVAILABLE = 0
export const ARTICLE_PAGE_INDEX = 1
export const ARTICLE_CATEGORY_TYPE_MORE_TEXT = 'more'
export const ARTICLE_CATEGORY_TYPE_ALL_TEXT = 'All'

export const MAXIMUM_MOBILE_SCREEN_SIZE = 770
export const TYPE_OF_STRING = 'string'
export const DEFAULT_ACTIVE_TAB = 0
export const MAXIMUM_TAB_SIZE = 5
