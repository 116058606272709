import { required } from '../../../../../../utils/formUtils'

export const FORM_ID = 'changeClientCover'
export const CHANGE_CLIENT_COVER = 'changeClientCoverComment'

const schema = () => fields => ({
  comment: {
    condition: [
      {
        condition: required,
        errorMsg: fields && fields.QuoteDetailsMandatoryError.value,
      },
    ],
  },
})

export default schema
