// @flow
import {
  SHOW_MODAL,
  HIDE_MODAL,
  INIT_STATE,
  INIT_MODAL,
  SET_MODAL_DESCRIPTION,
} from '../actions/types/modal'

export const initialState = {}

const modal = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case INIT_MODAL: {
      return {
        ...state,
        [action.payload.modalType]: {
          isModalVisible: false,
        },
      }
    }

    case SHOW_MODAL: {
      return {
        ...state,
        [action.payload.modalType]: {
          ...state[action.payload.modalType],
          isModalVisible: true,
        },
      }
    }

    case HIDE_MODAL: {
      return {
        ...state,
        [action.payload.modalType]: {
          ...state[action.payload.modalType],
          isModalVisible: false,
        },
      }
    }

    case SET_MODAL_DESCRIPTION: {
      return {
        ...state,
        [action.payload.modalType]: {
          ...state[action.payload.modalType],
          description: action.payload.description,
        },
      }
    }

    case INIT_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default modal
