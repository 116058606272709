/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTrash32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.716 8H5v3h1.581l.764 13.75A4.5 4.5 0 0 0 11.838 29h9.324a4.5 4.5 0 0 0 4.493-4.25L26.42 11H28V8h-5.716a7.949 7.949 0 0 0-1.095-2.408C20.273 4.249 18.734 3 16.5 3s-3.773 1.25-4.689 2.592A7.949 7.949 0 0 0 10.716 8zm3.174 0h5.22a4.69 4.69 0 0 0-.399-.717C18.177 6.501 17.466 6 16.5 6c-.966 0-1.677.5-2.211 1.283a4.69 4.69 0 0 0-.4.717zm9.524 3H9.586l.754 13.583A1.5 1.5 0 0 0 11.838 26h9.324a1.5 1.5 0 0 0 1.498-1.417L23.414 11zM13 21v-7h3v7h-3zm4 0v-7h3v7h-3z"
      fill="#000"
    />
  </Icon>
)

export default IconTrash32
