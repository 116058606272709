/* eslint-disable no-unused-vars */
// @flow
import React from 'react'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx } from '@emotion/core'

// styles.
import styles from './childCoverForm.styles'

// components.
import Button from '../../../../atoms/Button'
import Input from '../../../../atoms/Input'
import ToggleGroup from '../../../ToggleGroup'

// types.
import { type ChildCoverFields } from '../../childCover.fields'

// styled components.
const Form = styled('form')()
const Names = styled('div')(styles.names)

type ChildCoverFormProps = {
  /** Form store data from forms sub-store */
  form: Object,
  /** Called when input values change */
  onChange: Function,
  /** Called when Child Details are submitted */
  onSubmit: Function,
  /** Form schema containing validation rules and error messages */
  schema: Object,
  /** Copy for CCI form derived from Sitecore fields */
  fields: ChildCoverFields,
}

const ChildCoverForm = (props: ChildCoverFormProps) => {
  const {
    form: {
      fields: { childGender, childSmokerStatus, dateOfBirth, firstName, lastName },
    },
    fields,
    onChange,
    onSubmit,
    schema,
  } = props

  return (
    <Form id="childCover" aria-labelledby="child-cover">
      <p>{fields.desc}</p>
      <Names>
        <Input
          htmlFor="firstName"
          name="firstName"
          label={fields.firstName}
          changeHandler={onChange}
          error={firstName.error.error}
          caption={firstName.error.error ? firstName.error.errorMsg : schema.firstName.tooltip}
          value={firstName.value}
          css={styles.firstName}
          focusOnMount
        />
        <Input
          htmlFor="lastName"
          name="lastName"
          label={fields.lastName}
          changeHandler={onChange}
          error={lastName.error.error}
          caption={lastName.error.error ? lastName.error.errorMsg : schema.lastName.tooltip}
          value={lastName.value}
          css={styles.lastName}
        />
      </Names>
      <Input
        htmlFor="dateOfBirth"
        name="dateOfBirth"
        label={fields.dateOfBirth}
        changeHandler={onChange}
        placeholder="DD / MM / YYYY"
        error={dateOfBirth.error.error}
        caption={dateOfBirth.error.error ? dateOfBirth.error.errorMsg : schema.dateOfBirth.tooltip}
        value={dateOfBirth.value}
        css={styles.dob}
        options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
      />
      <ToggleGroup
        htmlFor="childGender"
        label={fields.gender}
        name="childGender"
        labelledby="child-cover"
        handleChange={onChange}
        value={childGender.value}
        error={childGender.error.error}
        caption={childGender.error.error ? childGender.error.errorMsg : schema.childGender.tooltip}
        options={schema.childGender.options}
        css={styles.toggle}
        isFontBold
      />
      <ToggleGroup
        htmlFor="childSmokerStatus"
        label={fields.isSmoker}
        name="childSmokerStatus"
        labelledby="child-cover"
        handleChange={onChange}
        value={childSmokerStatus.value}
        error={childSmokerStatus.error.error}
        caption={
          childSmokerStatus.error.error
            ? childSmokerStatus.error.errorMsg
            : schema.childSmokerStatus.tooltip
        }
        options={schema.childSmokerStatus.options}
        css={styles.toggle}
        isFontBold
      />
      <Button type="secondary" onClick={onSubmit}>
        Confirm
      </Button>
    </Form>
  )
}

export default ChildCoverForm
