// @flow
import React, { createRef, PureComponent } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { Label, FormBlock } from '@mlcl-digital/mlcl-design'

// components.
import Caption from '../Caption'

// styles.
import styles from './input.styles'
import { getClassnameProp } from '../../../utils/stylesUtils'
import browser from '../../../utils/browserUtils'
import { themeConsumer, getThemeProp } from '../../../styles/ThemeContext'
import { formatDate, formattedNumber } from '../../../utils/inputUtils'

type numeralThousandsGroupStyle = {
  numeralThousandsGroupStyle: {
    thousand: string,
  },
}
type disableDecimal = {
  disableDecimal: boolean,
}
type date = {
  date: boolean,
}

type pattern = {
  pattern: string,
}

type InputOptions = numeralThousandsGroupStyle | disableDecimal | date | pattern

type InputProps = {
  // Label for attribute and input id.
  htmlFor: string,
  // Label text.
  label: string,
  // name for input.
  name: string,
  // Input variation/type.
  type?: 'text' | 'password' | 'number',
  // Text change method callback.
  changeHandler?: Function,
  // Blur event.
  blurHandler?: Function,
  // Focus event.
  focusHandler?: Function,
  // Placeholder text for input.
  placeholder?: string,
  // Initial value of input.
  value?: any,
  // Sets input as readonly.
  readOnly?: boolean,
  // Sets Input disabled state.
  disabled?: boolean,
  // Sets Input into error state.
  error?: boolean,
  // Sets Input into transparent state.
  transparent?: boolean,
  // Set if label is floated or fixed.
  floatedLabel?: boolean,
  // Text to display in caption tag.
  caption?: 'string',
  // Flag to dangerously set caption using dangerouslySetInnerHTML.
  captionDangerousHTML?: boolean,
  // Removes Input bottom margin
  noMargin?: boolean,
  // Set editable state of input.
  editable?: boolean,
  // Focus input element on mount.
  focusOnMount?: boolean,
  // Set a string prefix to the input which doesn't impact the input value.
  prefix?: string,
  // Set a string suffix to the input which doesn't impact the input value.
  suffix?: string,
  // Minimum value to accept (for number type inputs)
  min?: string | number,
  // Maximum value to accept (for number type inputs)
  max?: string | number,
  // Max decimal place for a decimal typed input
  maxDecimal?: number,
  className: Object,
  // Show/hide caption icon.
  captionWithIcon?: boolean,
  // to make different types of input
  options?: InputOptions,
  // enable/disable autoComplete of input
  autoComplete?: String,
}

const inputDynamicStyles = [
  ({ theme, value, prefix, suffix, noBorder }) =>
    styles.input(theme, value, prefix, suffix, noBorder),
  ({ error }) => (error ? styles.error : null),
  ({ disabled, editable }) => (disabled && editable ? styles.disabled : null),
  ({ editable }) => (!editable ? styles.unEditable : null),
  ({ transparent }) => (transparent ? styles.transparent : null),
]

const InputLabel = styled(Label)(props => styles.label(props.theme))
const InputWrap = styled('div')(styles.wrap)
const InputPrefix = styled('span')(styles.prefix)
const InputSufix = styled('span')(styles.suffix)
const InputField = styled('input')(inputDynamicStyles)
const InputCaption = styled(Caption)(styles.caption)
const IeNinelabel = styled('label')(styles.ieNinelabel)

export const CHAR_CODE_PLUS = 43
export const CHAR_CODE_HYPHEN = 45
export const CHAR_CODE_PERIOD = 46
export const CHAR_CODE_DELETE = 8
export const CHAR_CODE_UPPERCASE_E = 69
export const CHAR_CODE_LOWERCASE_E = 101

export class Input extends PureComponent<InputProps> {
  inputRef = createRef()

  backButtonPressed = false

  isIENine = browser.ie && browser.ie === 9

  constructor(props) {
    super(props)
    const { value } = props
    this.state = { showPlaceholder: true, valueOfState: this.formattedValue(value) }
  }

  componentDidUpdate(lastProps) {
    const { value: lastPropValue } = lastProps
    const { value } = this.props
    if (lastPropValue !== value) {
      this.setState({ valueOfState: this.formattedValue(value) })
    }
  }

  formattedValue = val => {
    const { options } = this.props
    if (get(options, 'numeralThousandsGroupStyle') === 'thousand') {
      if (!/^\d+$/.test(val)) {
        // @FIXME: need to determine safe way to refactor param assignment
        // eslint-disable-next-line no-param-reassign
        val = val.replace(/\D/g, '')
      }
      return val && formattedNumber(val)
    }
    return val
  }

  setValue = (val, callback) => {
    this.setState({ valueOfState: val }, () => {
      if (callback && typeof callback === 'function') {
        callback()
      }
    })
  }

  togglePlaceholder = val => {
    this.setState({ showPlaceholder: val })
  }

  onChange = event => {
    const { maxDecimal, type, options, name, changeHandler } = this.props
    const { selectionEnd } = event.target
    let { value: val } = event.target
    let formattedValue
    const rightCharsCount = val.length - selectionEnd
    // Prevent surpassing max decimal if input is set to decimal type
    if (type === 'decimal') {
      const decimalSplit = val.split('.')
      if (decimalSplit.length > 1) {
        const decimals = decimalSplit[1]
        if (decimals.length >= maxDecimal || decimalSplit.length > 2) {
          val = val.substring(0, val.toString().indexOf('.') + maxDecimal + 1)
        }
      }
    }
    if (!this.backButtonPressed) {
      if (get(options, 'date')) {
        val = formatDate(val, get(options, 'pattern', 'DD/MM/YYYY'))
        this.setValue(val)
      }
      if (get(options, 'numeralThousandsGroupStyle') === 'thousand') {
        val = val.replace(/,/g, '')
        formattedValue = this.formattedValue(val)
      }
    } else {
      if (get(options, 'numeralThousandsGroupStyle') === 'thousand') {
        val = val.replace(/,/g, '')
      }
      formattedValue = this.formattedValue(val)
    }
    if (formattedValue && type !== 'number') {
      const newPosition = formattedValue.length - rightCharsCount
      this.setValue(formattedValue, () => {
        this.inputRef.current.setSelectionRange(newPosition, newPosition)
      })
    }
    this.setValue(get(options, 'date') ? val : this.formattedValue(val), () => {
      changeHandler({ value: val, name })
    })
  }

  onKeyDown = event => {
    if (event.which === CHAR_CODE_DELETE || event.which === CHAR_CODE_PERIOD) {
      this.backButtonPressed = true
    } else {
      this.backButtonPressed = false
    }
  }

  onKeyPress = event => {
    const { type, options } = this.props
    // Stop number fields taking mathematical keys
    // 'e'(69), 'E'(101) '+'(43) '.'(46) and '-'(45)
    if (
      (type === 'number' &&
        (event.charCode === CHAR_CODE_PLUS ||
          event.charCode === CHAR_CODE_HYPHEN ||
          event.charCode === CHAR_CODE_PERIOD ||
          event.charCode === CHAR_CODE_UPPERCASE_E ||
          event.charCode === CHAR_CODE_LOWERCASE_E)) ||
      (type === 'decimal' &&
        (event.charCode === CHAR_CODE_PLUS ||
          event.charCode === CHAR_CODE_HYPHEN ||
          event.charCode === CHAR_CODE_UPPERCASE_E ||
          event.charCode === CHAR_CODE_LOWERCASE_E)) ||
      (get(options, 'disableDecimal') && event.charCode === CHAR_CODE_PERIOD)
    ) {
      event.preventDefault()
    }
  }

  onBlur = event => {
    const { name, blurHandler } = this.props
    const { showPlaceholder } = this.state
    if (this.isIENine && !showPlaceholder && !event.target.value) this.togglePlaceholder(true)
    blurHandler(event, name)
  }

  onFocus = event => {
    const { showPlaceholder } = this.state
    const { focusHandler } = this.props
    if (this.isIENine && showPlaceholder) this.togglePlaceholder(false)
    focusHandler(event)
  }

  togglePlaceholderHandler = () => {
    this.togglePlaceholder(false)
    this.inputRef.current.focus()
  }

  render() {
    const {
      htmlFor,
      label,
      type,
      placeholder,
      readOnly,
      disabled,
      transparent,
      error,
      value,
      floatedLabel,
      name,
      caption,
      captionDangerousHTML,
      noMargin,
      editable,
      focusOnMount,
      prefix,
      suffix,
      className,
      min,
      max,
      options,
      captionWithIcon,
      autoComplete,
      noBorder,
      tab,
      dataLocator: propDataLocator,
    } = this.props

    const { showPlaceholder, valueOfState } = this.state

    const dataLocator = {
      'data-locator': propDataLocator || `input-${type || 'text'}-${name}`,
    }
    if (!editable && (value === '' || value === null)) return null
    const floating = !!value.length

    return (
      <FormBlock hasMargin={!noMargin ? !error : !noMargin} {...getClassnameProp({ className })}>
        {label && (
          <InputLabel
            allowFloating={floatedLabel}
            htmlFor={htmlFor}
            id={`${htmlFor}-label`}
            isFloating={floating}
            error={error}
          >
            {label}
          </InputLabel>
        )}
        <InputWrap
          {...getThemeProp(this.props)}
          disabled={disabled || !editable}
          editable={editable}
          error={error}
          {...dataLocator}
        >
          {this.isIENine && !label && showPlaceholder && (
            <IeNinelabel onClick={this.togglePlaceholderHandler}>{placeholder}</IeNinelabel>
          )}
          {prefix && !!prefix.length && <InputPrefix>{prefix}</InputPrefix>}
          <InputField
            {...getThemeProp(this.props)}
            ref={this.inputRef}
            id={htmlFor}
            name={name}
            type={type}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            transparent={transparent}
            disabled={disabled || !editable}
            error={error}
            value={valueOfState || ''}
            editable={editable}
            autoFocus={focusOnMount}
            prefix={prefix}
            suffix={suffix}
            options={options}
            min={min}
            max={max}
            noBorder={noBorder}
            autoComplete={autoComplete}
            className={error && 'errorField'}
            tabIndex={tab}
          />
          {suffix && <InputSufix>{suffix}</InputSufix>}
        </InputWrap>
        {caption && (
          <InputCaption withIcon={captionWithIcon} error={error}>
            {captionDangerousHTML ? (
              // allow for authorable content in captions.
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: caption }} />
            ) : (
              caption
            )}
          </InputCaption>
        )}
      </FormBlock>
    )
  }
}

Input.defaultProps = {
  blurHandler: () => {},
  changeHandler: () => {},
  focusHandler: () => {},
  floatedLabel: false,
  placeholder: '',
  readOnly: false,
  type: 'text',
  value: '',
  disabled: false,
  error: false,
  caption: '',
  transparent: false,
  captionDangerousHTML: false,
  noMargin: false,
  editable: true,
  focusOnMount: false,
  prefix: '',
  suffix: '',
  min: null,
  max: null,
  captionWithIcon: true,
  maxDecimal: 2,
  options: undefined,
  autoComplete: 'off',
  tab: '0',
}

export default themeConsumer(Input, 'form')
