// @flow
import { space, setColumns } from '../../../../styles'

const styles = {
  form: {
    ...setColumns(1),
    '& > *': {
      ...setColumns(1)['& > *'],
    },
  },

  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: space(3, true),
  },

  checkbox: {
    width: '45%',
  },

  durationSelect: {
    width: space(12, true),
    marginBottom: 0,
  },
}

export default styles
