// @flow

import {
  SEARCH_POLICY,
  SEARCH_POLICY_SUCCESS,
  SEARCH_POLICY_ERROR,
  SEARCH_POLICY_FAILURE,
  SEARCH_POLICY_RESET,
} from '../actions/types/searchPolicy'

export const initialState = {
  data: [],
  isLoading: false,
  hasFetchError: false,
  isFetchingSearchPolicy: false,
  clientId: '',
}

const searchPolicy = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case SEARCH_POLICY_RESET: {
      return {
        ...initialState,
      }
    }
    case SEARCH_POLICY: {
      return {
        ...state,
        data: [],
        isLoading: true,
        isFetchingSearchPolicy: true,
        hasFetchError: false,
      }
    }
    case SEARCH_POLICY_SUCCESS: {
      const {
        businessData: { policies },
      } = action.payload

      return {
        ...state,
        data: policies,
        isFetchingSearchPolicy: false,
        isLoading: false,
      }
    }
    case SEARCH_POLICY_ERROR:
    case SEARCH_POLICY_FAILURE: {
      return {
        ...state,
        data: [],
        isLoading: false,
        isFetchingSearchPolicy: false,
        hasFetchError: true,
      }
    }

    default: {
      return state
    }
  }
}

export default searchPolicy
