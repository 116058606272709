import axios from 'axios'
import config from './temp/config'

export default function dataFetcher(url, data) {
  return axios({
    url,
    method: data ? 'POST' : 'GET',
    data,
    withCredentials: true,
    ...(!data && {
      headers: {
        sc_apikey: config.sitecoreApiKey,
      },
    }),
  })
}
