/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconNotification16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M12 7h-1v.847l.836.14L12 7zM4 7l.164.986L5 7.847V7H4zm4-5c.789 0 1.318.114 1.68.282.342.158.578.383.76.708.416.739.56 1.976.56 4.01h2c0-1.966-.106-3.729-.816-4.99A3.606 3.606 0 0 0 10.522.468C9.807.136 8.962 0 8 0v2zm3.836 5.986c-.014-.002.06.009.203.147.138.133.296.345.447.637.3.585.514 1.386.514 2.23h2c0-1.156-.287-2.272-.736-3.145a4.433 4.433 0 0 0-.835-1.16c-.325-.315-.751-.596-1.265-.681l-.328 1.972zM13 11c0-.165.07-.25.064-.243a.845.845 0 0 1-.178.143c-.248.164-.652.35-1.192.523C10.624 11.767 9.226 12 8 12v2c1.44 0 3.043-.267 4.306-.673.627-.202 1.223-.454 1.683-.759.23-.152.465-.342.65-.578.187-.24.361-.575.361-.99h-2zM8 0c-.961 0-1.807.136-2.522.468A3.605 3.605 0 0 0 3.816 2.01C3.106 3.27 3 5.034 3 7h2c0-2.034.144-3.271.56-4.01.182-.325.418-.55.76-.708C6.681 2.114 7.21 2 8 2V0zM3.836 6.014c-.514.085-.94.366-1.265.681-.331.32-.61.723-.835 1.16C1.286 8.728 1 9.844 1 11h2c0-.844.213-1.645.514-2.23.15-.292.309-.504.447-.637.143-.138.217-.149.203-.147l-.328-1.972zM1 11c0 .415.174.75.361.99.185.236.42.426.65.578.46.305 1.056.557 1.683.759C4.957 13.733 6.56 14 8 14v-2c-1.226 0-2.623-.233-3.694-.577-.54-.173-.944-.359-1.192-.523a.847.847 0 0 1-.178-.143C2.93 10.749 3 10.835 3 11H1zm7.9 2.8c.03.022-.009.007-.033-.08a.423.423 0 0 1 .05-.337c.051-.076.072-.043-.08.01-.148.053-.413.107-.837.107v2c1.075 0 2.08-.255 2.582-1.008.272-.409.332-.885.208-1.321a1.87 1.87 0 0 0-.69-.971l-1.2 1.6zm-3-1.6a1.87 1.87 0 0 0-.69.97c-.124.437-.065.913.208 1.322C5.92 15.245 6.925 15.5 8 15.5v-2c-.424 0-.69-.054-.838-.107-.151-.053-.13-.086-.08-.01.064.096.082.229.051.337-.024.087-.062.102-.033.08l-1.2-1.6z"
      fill="#000"
    />
  </Icon>
)

export default IconNotification16
