// @flow
import React, { Fragment, type Node } from 'react'
import styled from '@emotion/styled'
import { Text } from '@sitecore-jss/sitecore-jss-react'

// styles.
import styles from './projectionsTable.styles'

// styled components.
const Table = styled('table')(styles.table)
const Header = styled('th')(styles.header)
const Cell = styled('td')(styles.cell)

type ProjectionsTableProps = {
  // projections table data to render.
  data: Object,
}

const ProjectionsTable = ({ data }: ProjectionsTableProps): Node => {
  const { columns, rows } = data

  // render that table header row.
  const renderHeader = (): Node => (
    <tr>
      {columns.map(({ header, accessor }, index) => (
        <Header key={`header-${accessor}`} isFirst={index === 0}>
          {Array.isArray(header) ? (
            header.map(field => (
              <Fragment key={`field-${field.value}`}>
                <Text key={field.value} field={field} />{' '}
              </Fragment>
            ))
          ) : (
            <Text field={header} />
          )}
        </Header>
      ))}
    </tr>
  )

  // render the the table data rows.
  const renderRows = (): Node =>
    rows.map(row => {
      const keys = columns.map(({ accessor }) => accessor)
      return (
        <tr key={`row-${row.age}`}>
          {keys.map(key => (
            <Cell key={`cell-${key}-${row[key]}`}>{row[key]}</Cell>
          ))}
        </tr>
      )
    })

  return (
    <Table data-testid="projections-table">
      <thead>{renderHeader()}</thead>
      <tbody>{renderRows()}</tbody>
    </Table>
  )
}

export default ProjectionsTable
