// @flow

import { required } from '../../../../../../utils/formUtils'
import {
  manualLoadingBenefitsType,
  manualLoadingReasons,
  manualLoadingType,
  manualLoadingAmount,
} from '../../../../../../utils/preAssessmentBenefitUtils'

export const FORM_ID: string = 'preAssessmentAddLoading'

const schema =
  () =>
  (previousData? = {}, currentData? = {}) => {
    const { benefit, reason, type } = previousData
    const { benefit: currentBenefit, reason: currentReason, type: currentType } = currentData

    return {
      benefit: {
        condition: required,
        options: manualLoadingBenefitsType(benefit, currentBenefit),
      },
      loadingReason: {
        condition: required,
        options: manualLoadingReasons(benefit, currentReason),
      },
      loadingType: {
        condition: required,
        options: manualLoadingType(benefit, reason, currentType),
      },
      loadingAmount: {
        condition: required,
        options: manualLoadingAmount(type, currentType),
      },
    }
  }

export default schema
