// @flow
import { toast } from 'react-toastify'

// types
import {
  ADVISOR_PREFERENCES_GET,
  ADVISOR_PREFERENCES_GET_SUCCESS,
  ADVISOR_PREFERENCES_GET_ERROR,
  ADVISOR_PREFERENCES_GET_FAILURE,
  ADVISOR_PREFERENCES_POST,
  ADVISOR_PREFERENCES_POST_SUCCESS,
  ADVISOR_PREFERENCES_POST_ERROR,
  ADVISOR_PREFERENCES_POST_FAILURE,
  ADVISOR_PREFERENCES_RESTORE,
  ADVISOR_PREFERENCES_RESTORE_SUCCESS,
  ADVISOR_PREFERENCES_RESTORE_ERROR,
  ADVISOR_PREFERENCES_RESTORE_FAILURE,
  ADVISOR_PREFERENCES_UPDATE,
} from '../actions/types/advisorPreferences'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

import {
  TOAST_ID_RESTORE_PREFERENCES,
  TOAST_TITLE_RESTORE_PREFERENCES_SUCCESS,
  TOAST_TITLE_RESTORE_PREFERENCES_ERROR,
} from '../constants/toast'

export const initialState = {
  preferences: [],
  isLoading: false,
  isPreferencePostLoading: false,
  isPreferenceRestoreLoading: false,
  hasFetchError: false,
  hasUpdateError: false,
  status: {},
}

const advisorPreferencesGet = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
) => {
  switch (action.type) {
    case ADVISOR_PREFERENCES_GET: {
      return {
        ...state,
        isLoading: true,
        hasFetchError: false,
      }
    }

    case ADVISOR_PREFERENCES_GET_SUCCESS: {
      const { data, status } = action.payload
      return {
        ...state,
        isLoading: false,
        preferences: data,
        status,
      }
    }

    case ADVISOR_PREFERENCES_POST: {
      return {
        ...state,
        hasUpdateError: false,
        isPreferencePostLoading: true,
      }
    }

    case ADVISOR_PREFERENCES_UPDATE: {
      const { records } = action.payload
      return {
        ...state,
        preferences: [...records],
      }
    }

    case ADVISOR_PREFERENCES_POST_SUCCESS: {
      const {
        error,
        payload: { requestPayload },
      } = action
      return {
        ...state,
        error,
        isPreferencePostLoading: false,
        preferences: state.preferences.map(preference => {
          if (preference.productId === requestPayload.productId) {
            preference.benefits.map(benefit => {
              if (benefit.benefitCode === requestPayload.benefitCode) {
                const benefitFound = { ...requestPayload }
                return benefitFound
              }
              return false
            })
          }
          return preference
        }),
      }
    }

    case ADVISOR_PREFERENCES_RESTORE: {
      return {
        ...state,
        isPreferenceRestoreLoading: true,
      }
    }

    case ADVISOR_PREFERENCES_RESTORE_SUCCESS: {
      const { dataReceived } = action.payload
      toast(TOAST_TITLE_RESTORE_PREFERENCES_SUCCESS, {
        toastId: TOAST_ID_RESTORE_PREFERENCES,
        type: toast.TYPE.SUCCESS,
      })
      return {
        ...state,
        preferences: dataReceived.data,
        isPreferenceRestoreLoading: false,
      }
    }

    case ADVISOR_PREFERENCES_GET_ERROR:
    case ADVISOR_PREFERENCES_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasFetchError: true,
        error: action.error,
      }
    }

    case ADVISOR_PREFERENCES_POST_ERROR:
    case ADVISOR_PREFERENCES_POST_FAILURE: {
      return {
        ...state,
        isSubmit: false,
        hasUpdateError: true,
        isPreferencePostLoading: false,
        error: action.error,
      }
    }

    case ADVISOR_PREFERENCES_RESTORE_ERROR:
    case ADVISOR_PREFERENCES_RESTORE_FAILURE: {
      toast(TOAST_TITLE_RESTORE_PREFERENCES_ERROR, {
        toastId: TOAST_ID_RESTORE_PREFERENCES,
        type: toast.TYPE.ERROR,
      })
      return {
        ...state,
        error: action.error,
        isPreferenceRestoreLoading: false,
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default advisorPreferencesGet
