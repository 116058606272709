// @flow
import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { CSVLink } from 'react-csv'
import {
  PageWrap,
  Notification,
  Heading,
  Icons,
  Select,
  Input,
  Button,
} from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createEvent } from '../../../utils/telemetry'
import { actionCreators } from '../../../actions'

// schema.
import SCHEMA, { FORM_ID } from './reportTool.schema'

// helpers.
import { errorCheck } from '../../../utils/formUtils'
import { renderTextField, reduceAuthorableFields } from '../../../utils/sitecoreUtils'
import {
  addOrRemoveElement,
  removeIndex,
  transformToSelectOptions,
  isFeatureEnabledForAP,
} from '../../../utils/commonUtils'

import {
  getPoliciesCodesForReporting,
  getBenefitCodesForReporting,
} from '../../../utils/policyUtils'

import {
  maxBenefitItem,
  getClientReportHeader,
  getFormatClientReportData,
} from '../../../utils/reportingUtils'

// constants
import {
  STATES,
  GENDER,
  REPORTING_PAYMENT_FREQUENCY_OPTIONS,
  INSURANCE_OPTIONS,
  POLICY_OWNER_OPTIONS,
} from '../../../constants/forms'
import { FULL_DOWNLOAD, TAILORED_SEARCH } from '../../../constants/reporting'
import { POLICY_STATE_INFORCE } from '../../../constants/policies'
import { CSV_FILE_EXTENSION } from '../../../constants/documentTypes'

// styles.
import styles from './reportTool.styles'

// components
import PageHeader from '../../molecules/PageHeader'
import ToggleGroup from '../../molecules/ToggleGroup'
import WithLoader from '../../molecules/WithLoader'
import MutliSelect from '../../molecules/MultiSelectDropdown'
import Modal from '../../molecules/Modal'

const { IconDownload16 } = Icons

const Wrap = styled(PageWrap)(styles.offset)
const FormWrap = styled('div')(styles.base)
const Form = styled('form')(styles.form)
const Header = styled('div')(styles.header)
const Title = styled(Heading)(styles.title)
const SectionHeader = styled('div')(styles.sectionHeader)
const HalfWidthInput = styled(Input)(styles.halfWidthInput, styles.inputLabel)
const QuarterWidthInput = styled(Input)(styles.quarterWidthInput, styles.inputLabel)
const InputComponent = styled(Input)(styles.inputLabel)
const FieldSet = styled('div')(({ useFlex }) => styles.fieldset(useFlex))
const Holder = styled('div')(styles.holder)
const HalfWidthSelect = styled(Select)(styles.holder)
const HalfWidthToggleGroup = styled(ToggleGroup)(styles.halfWidthInput)
const ClearButton = styled(Button)(styles.button)
const ButtonPanel = styled('div')(styles.buttonPanel)
const ButtonsHolder = styled('div')(styles.buttonsHolder)
const Icon = styled(IconDownload16)(styles.icon)
export const DownloadCSV = styled(CSVLink)(() => styles.download(Icon))
export const DownloadFullCSV = styled(CSVLink)(() => styles.downloadFullReport(Icon))
const NotificationWrapper = styled(Notification)(styles.notification)
const RedirectLink = styled('a')(styles.redirectLink)
const LoaderText = styled('div')(styles.loaderText)
const ModalDescription = styled('p')(styles.modalDescription)

type ReportingFormProps = {
  // sitecore fields
  fields: Object<Object>,
  // redux actions
  actions: Object,
  // redux form
  form: Object<Object>,
  // Advisor Details
  advisorDetails: Array<Object>,
  // master list
  masterData: Object,
  // report data
  clientReport: Object,
}

type ReportingFormState = {
  // form schema to load
  formSchema: Object<Object>,
  // component to render
  componentSchema: Object<Object>,
  // policy report data
  reportData: Array<Object>,
  // csv downloading
  isDownloading: boolean,
  // No record Found
  isNoRecordFound: boolean,
  // product dropdown options
  productOptions: Array<Object>,
  // display button after reaching threshold
  isDownloadThreshold: boolean,
  // open/close report modal
  isReportModal: boolean,
  // open/close retry modal
  isRetryModal: boolean,
}

const CSV_ASYNC_ON_CLICK = true

const getSearchTypeOptions = fields => {
  const { optionSwitchPolicyOwner, optionSwitchLifeInsured } = reduceAuthorableFields(fields)
  return [
    {
      label: `${optionSwitchPolicyOwner}`,
      value: `${POLICY_OWNER_OPTIONS.policyOwnerOptionValue}`,
      shortLabel: 'K',
    },
    {
      label: `${optionSwitchLifeInsured}`,
      value: `${POLICY_OWNER_OPTIONS.lifeInsuredOptionValue}`,
      shortLabel: 'R',
    },
  ]
}

export class ReportingFormComponent extends PureComponent<ReportingFormProps, ReportingFormState> {
  schemaWithAuthorableFields = SCHEMA()

  constructor(props) {
    super(props)
    const {
      masterData: { adviserReportingProductOptions, adviserReportingPolicyOptions },
    } = props
    this.state = {
      fullDownloadData: [],
      tailoredSearchData: [],
      isDownloading: false,
      isDownloadingFullClientReport: false,
      isNoRecordFound: false,
      isDownloadThreshold: false,
      productOptions:
        adviserReportingProductOptions && transformToSelectOptions(adviserReportingProductOptions),
      policyOptions:
        (adviserReportingPolicyOptions &&
          transformToSelectOptions(adviserReportingPolicyOptions)) ||
        [],
      isReportModal: false,
      isRetryModal: false,
    }

    // used for download through modal
    this.downloadType = ''
    // to show continue button in loader
    this.thresholdTimerID = ''

    this.csvLinkRefFullClient = React.createRef()
    this.csvLinkRef = React.createRef()
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillMount() {
    const { actions, form, fields } = this.props
    const { formInit } = actions
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
    const initialFormFieldValues = {
      searchType: POLICY_OWNER_OPTIONS.policyOwnerOptionValue,
      policyStatus: POLICY_STATE_INFORCE,
    }
    formInit(FORM_ID, schema, initialFormFieldValues)
  }

  componentDidUpdate(prevProps: Object) {
    const {
      clientReport: { isLoading, hasFetchError, data },
    } = this.props
    if (!isLoading && isLoading !== prevProps.clientReport.isLoading) {
      clearTimeout(this.thresholdTimerID)
      window.removeEventListener('beforeunload', this.handleBeforeUnload)

      // keys to be used while setting state
      let dataKey
      let isDownloadingKey
      switch (this.downloadType) {
        case TAILORED_SEARCH: {
          dataKey = 'tailoredSearchData'
          isDownloadingKey = 'isDownloading'
          break
        }
        default: {
          dataKey = 'fullDownloadData'
          isDownloadingKey = 'isDownloadingFullClientReport'
        }
      }

      if (hasFetchError) {
        this.setState({
          [dataKey]: [],
          [isDownloadingKey]: false,
          isDownloadThreshold: false,
        })
        this.showRetryModal()
      } else if (data && data.length) {
        this.setState(
          {
            [dataKey]: [...data],
            [isDownloadingKey]: false,
            isDownloadThreshold: false,
          },
          () => {
            if (this.downloadType === FULL_DOWNLOAD) {
              this.csvLinkRefFullClient.current.link.click()
            } else {
              this.csvLinkRef.current.link.click()
            }
            this.setState({ [dataKey]: [] })
          }
        )
      } else {
        this.setState({
          [dataKey]: [],
          [isDownloadingKey]: false,
          isNoRecordFound: true,
          isDownloadThreshold: false,
        })
      }
    }
  }

  getAdviserCodes = () => {
    const { advisorDetails } = this.props
    return advisorDetails.map(item => ({
      label: item.agencyCode,
      value: item.agencyCode,
    }))
  }

  getAdvisorCodesArray = () => {
    const { advisorDetails } = this.props
    return advisorDetails ? advisorDetails.map(item => item.agencyCode) : []
  }

  handleFullClientDownload = async () => {
    const {
      actions: { downloadPolicies },
      masterData,
    } = this.props
    const { productOptions } = this.state
    this.setState({ isDownloadingFullClientReport: true, isNoRecordFound: false })
    const params = {
      adviserCode: this.getAdvisorCodesArray(),
      insuranceType: productOptions.map(product => product.value),
      policyStatus:
        masterData.policyStatus && getPoliciesCodesForReporting(masterData.policyStatus, true),
      benefitStatus:
        masterData.policyStatus && getBenefitCodesForReporting(masterData.policyStatus, true),
    }
    downloadPolicies(params)
  }

  handleTailoredSearchDownload = () => {
    const {
      actions: { downloadPolicies, formSubmit },
      form,
      fields,
      masterData,
    } = this.props
    const { productOptions } = this.state
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
    formSubmit(FORM_ID, schema, (formData, formStatus) => {
      if (formStatus.isValid) {
        const {
          searchType,
          firstName,
          lastName,
          companyName,
          ageFrom,
          ageTo,
          gender,
          state,
          product,
          sumInsuredFrom,
          sumInsuredTo,
          premiumPaymentFrequency,
          premiumFrom,
          premiumTo,
          // insuranceAnniversary,
          policyStatus,
          insuranceOptions,
          adviserCode,
        } = formData

        // create search param with from data
        const param = {
          ...(searchType ? { searchBy: searchType } : {}),
          ...(firstName ? { firstName } : {}),
          ...(lastName ? { lastName } : {}),
          ...(companyName ? { companyName } : {}),
          ...(ageFrom ? { ageRangeFrom: ageFrom } : {}),
          ...(ageTo ? { ageRangeTo: ageTo } : {}),
          ...(gender ? { gender } : {}),
          ...(state ? { state } : {}),
          insuranceType:
            product && product.length
              ? product
              : productOptions.map(productOption => productOption.value),
          ...(premiumPaymentFrequency ? { paymentFrequency: premiumPaymentFrequency } : {}),
          ...(sumInsuredFrom ? { sumInsuredFrom } : {}),
          ...(sumInsuredTo ? { sumInsuredTo } : {}),
          ...(premiumFrom ? { premiumFrom } : {}),
          ...(premiumTo ? { premiumTo } : {}),
          // ...(insuranceAnniversary ? { insuranceAnniversary } : {}),
          policyStatus: getPoliciesCodesForReporting(
            masterData.policyStatus,
            policyStatus === POLICY_STATE_INFORCE
          ),
          benefitStatus: getBenefitCodesForReporting(
            masterData.policyStatus,
            policyStatus === POLICY_STATE_INFORCE
          ),
          ...(insuranceOptions && insuranceOptions.length ? { insuranceOptions } : {}),
          ...(adviserCode
            ? { adviserCode: [adviserCode] }
            : { adviserCode: this.getAdvisorCodesArray() }),
        }
        // Get full record to download - when you are in first page
        this.setState({ isDownloading: true, isNoRecordFound: false })
        downloadPolicies(param)
      }
    })
  }

  handleDownloadButtonClick = (data: Array<Object>, done: Function, type: String) => {
    this.downloadType = type
    if (data.length) {
      done()
    } else {
      done(false)
      if (type === FULL_DOWNLOAD) {
        const tagEvent = createEvent({
          GA: {
            category: 'Reporting - Inforce policies report',
            action: 'AP - download inforce policies report',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Reporting - Inforce policies report - Download',
            },
          },
        })
        tagEvent.end()
        this.showReportModal()
      } else {
        const {
          actions: { formSubmit },
          form,
          fields,
        } = this.props
        const tagEvent = createEvent({
          GA: {
            category: 'Reporting - Download report',
            action: 'AP - download custom report',
          },
          Splunk: {
            attributes: {
              'workflow.name': 'Reporting - Download report',
            },
          },
        })
        tagEvent.end()
        const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
        formSubmit(FORM_ID, schema, (formData, formStatus) => {
          if (formStatus.isValid) {
            this.showReportModal()
          }
        })
      }
    }
  }

  handleDownload = () => {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    const tagEvent = createEvent({
      GA: {
        category: 'Downloading Policy Reports',
        action: 'Download Confirm',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Downloading Policy Reports - Download Confirm',
        },
      },
    })
    tagEvent.end()
    const {
      masterData,
      fields: { reportThresholdTimeForContinue },
    } = this.props
    const controlList = get(masterData, 'featureControlSwitch', [])
    const showRedirectToDashboard = isFeatureEnabledForAP(controlList, 'enableRedirectOnReporting')
    if (showRedirectToDashboard) {
      this.thresholdTimerID = setTimeout(() => {
        this.setState({ isDownloadThreshold: true })
      }, Number(get(reportThresholdTimeForContinue, 'value', 10) * 1000))
    }
    this.showReportModal(false)
    if (this.downloadType === FULL_DOWNLOAD) {
      this.handleFullClientDownload()
    } else {
      this.handleTailoredSearchDownload()
    }
  }

  handleRetryDownload = () => {
    this.showRetryModal(false)
    this.handleDownload()
  }

  showReportModal = (isReportModal: boolean = true) => {
    this.setState({
      isReportModal,
    })
  }

  showRetryModal = (isRetryModal: boolean = true) => {
    this.setState({
      isRetryModal,
    })
  }

  handleReset = () => {
    const { actions, form, fields } = this.props
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
    const { formReset, formUpdateField } = actions
    formReset(FORM_ID, schema)
    formUpdateField(FORM_ID, 'searchType', { value: POLICY_OWNER_OPTIONS.policyOwnerOptionValue })
    formUpdateField(FORM_ID, 'policyStatus', { value: POLICY_STATE_INFORCE })
  }

  handleSelectAllOptionChange = (name, type) => {
    const { productOptions } = this.state
    const { actions, form, fields } = this.props
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
    const productOptionValues = productOptions.map(product => product.value)
    const { formUpdateField, formValidate } = actions
    const schemaWithData = schema
    const data = {
      error: errorCheck(
        productOptionValues,
        schemaWithData[name].condition,
        schemaWithData[name].errorMsg
      ),
      value: type === 'select' ? productOptionValues : [],
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schemaWithData)
  }

  handleOptionChange = event => {
    const { value, name } = event.target
    const fieldName = removeIndex(name)
    const { actions, form, fields } = this.props
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))

    const formField = form.fields[fieldName]

    const newValue = addOrRemoveElement([...formField.value], value)

    const { formUpdateField, formValidate } = actions
    const schemaWithData = schema
    const data = {
      error: errorCheck(
        value,
        schemaWithData[fieldName].condition,
        schemaWithData[fieldName].errorMsg
      ),
      value: newValue,
    }
    formUpdateField(FORM_ID, fieldName, data)
    formValidate(FORM_ID, schemaWithData)
  }

  notificationCloseError = (): void => {
    this.setState({
      isNoRecordFound: false,
    })
  }

  handleBeforeUnload = (e: Object) => {
    // Cancel the event
    // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    e.preventDefault()
    // Chrome requires returnValue to be set
    e.returnValue = ''
  }

  handleChange({ value, name }) {
    const { actions, form, fields } = this.props
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
    const { formUpdateField, formValidate } = actions
    const schemaWithData = schema
    const data = {
      error: errorCheck(value, schema[name].condition),
      value,
    }
    formUpdateField(FORM_ID, name, data)
    formValidate(FORM_ID, schemaWithData)
    this.checkAndResetAgeFields(name, value, fields)
  }

  // For range fields, check both fields to see if validation errors occur from modifying one field.
  handleRangeValidation(name, type) {
    const { actions, form, fields } = this.props
    const schema = this.schemaWithAuthorableFields(form, reduceAuthorableFields(fields))
    const { formUpdateField, formValidate } = actions
    const schemaWithData = schema
    const rangeFields = {
      age: ['ageFrom', 'ageTo'],
      sumInsured: ['sumInsuredTo', 'sumInsuredFrom'],
      premium: ['premiumTo', 'premiumFrom'],
    }

    rangeFields[type].forEach(field => {
      const data = {
        error: errorCheck(form.fields[field].value, schema[field].condition),
        value: form.fields[field].value,
      }
      formUpdateField(FORM_ID, field, data)
      formValidate(FORM_ID, schemaWithData)
    })
  }

  checkAndResetAgeFields(name, value, fields) {
    const {
      actions: { formResetField },
    } = this.props
    if (name === 'searchType' && value === getSearchTypeOptions(fields)[0].value) {
      formResetField(FORM_ID, 'ageFrom')
      formResetField(FORM_ID, 'ageTo')
      formResetField(FORM_ID, 'gender')
    }
  }

  renderLoadingStateContent() {
    const {
      fields: { reportLoaderContinueLabel, generatingReport },
    } = this.props
    const { isDownloadThreshold } = this.state
    return (
      <Fragment>
        <LoaderText>{renderTextField(generatingReport)}</LoaderText>
        {isDownloadThreshold ? (
          <RedirectLink href="/dashboard" target="_blank">
            {renderTextField(reportLoaderContinueLabel)}
          </RedirectLink>
        ) : null}
      </Fragment>
    )
  }

  render() {
    const { form, fields } = this.props
    const {
      isDownloading,
      isNoRecordFound,
      productOptions,
      policyOptions,
      isDownloadingFullClientReport,
      fullDownloadData,
      tailoredSearchData,
      isReportModal,
      isRetryModal,
    } = this.state
    if (!form) return null
    const {
      searchType,
      firstName,
      lastName,
      companyName,
      ageFrom,
      ageTo,
      gender,
      state,
      product,
      sumInsuredFrom,
      sumInsuredTo,
      premiumPaymentFrequency,
      premiumFrom,
      premiumTo,
      policyStatus,
      insuranceOptions,
      adviserCode,
    } = form.fields

    const {
      heading,
      content,
      reportingHeader,
      clientDetailsHeader,
      policyDetailsHeader,
      firstNameLabel,
      lastNameLabel,
      companyNameLabel,
      ageRangeFromLabel,
      ageRangeToLabel,
      clientGender,
      clientState,
      insuranceTypeLabel,
      benefitSumInsuredFrom,
      benefitSumInsuredTo,
      paymentFrequency,
      premeumAmountFrom,
      premeumAmountTo,
      policyStatusLabel,
      insuranceOptionsLabel,
      optionSwithCaption,
      downloadButtonText,
      fullDownloadButtonText,
      clearSearchButtonText,
      clientReportNoRecordFoundText,
      adviserCodeLabel,
      reportingDownloadFilename,
      fullReportingDownloadFilename,
      reportModalTitle,
      reportModalDescription,
      reportModalButton,
      retryReportDownloadTitle,
      retryReportDownloadDescription,
    } = fields
    const searchTypeOptions = getSearchTypeOptions(fields)

    const csvHeadersForTailoredSearch =
      tailoredSearchData && tailoredSearchData.length
        ? getClientReportHeader(maxBenefitItem(tailoredSearchData), reduceAuthorableFields(fields))
        : []
    const csvHeadersForFullDownload =
      fullDownloadData && fullDownloadData.length
        ? getClientReportHeader(maxBenefitItem(fullDownloadData), reduceAuthorableFields(fields))
        : []
    const isLifeInsured = searchType.value === POLICY_OWNER_OPTIONS.lifeInsuredOptionValue
    return (
      <WithLoader
        loaderProps={{ loaderContent: this.renderLoadingStateContent() }}
        isLoading={isDownloading || isDownloadingFullClientReport}
        overlay
      >
        {/* <Hero fields={fields} /> */}
        <PageHeader heading={heading} subHeading={content}>
          <DownloadFullCSV
            ref={this.csvLinkRefFullClient}
            asyncOnClick={CSV_ASYNC_ON_CLICK}
            headers={csvHeadersForFullDownload}
            data={getFormatClientReportData(fullDownloadData)}
            onClick={(event, done) =>
              this.handleDownloadButtonClick(fullDownloadData, done, FULL_DOWNLOAD)
            }
            filename={`${fullReportingDownloadFilename.value}${CSV_FILE_EXTENSION}`}
          >
            {renderTextField(fullDownloadButtonText)}
          </DownloadFullCSV>
        </PageHeader>
        <Wrap>
          <Header>
            <Title variant="h2">{reportingHeader.value}</Title>
          </Header>
          <FormWrap>
            <Form id="client-test" aria-labelledby="client-test-label">
              <HalfWidthToggleGroup
                labelSize="sm"
                htmlFor="searchType"
                variant="tab"
                name="searchType"
                value={searchType.value}
                labelledby="searchType"
                options={searchTypeOptions}
                handleChange={this.handleChange}
                caption={
                  searchType.error.error ? searchType.error.errorMsg : optionSwithCaption.value
                }
                error={searchType.error.error}
              />
              <SectionHeader>{policyDetailsHeader.value}</SectionHeader>
              <FieldSet>
                <Holder>
                  <MutliSelect
                    name="product"
                    options={productOptions}
                    label={insuranceTypeLabel.value}
                    selectedOptions={product.value}
                    handleOptionChange={this.handleOptionChange}
                    abbreviateMultipleSelected
                    handleSelectAll={name => this.handleSelectAllOptionChange(name, 'select')}
                    handleDeselectAll={name => this.handleSelectAllOptionChange(name, 'deselect')}
                  />
                </Holder>
                <Holder>
                  <FieldSet>
                    <HalfWidthInput
                      editable
                      htmlFor="sumInsuredFrom"
                      label={get(benefitSumInsuredFrom, 'value', '')}
                      name="sumInsuredFrom"
                      changeHandler={this.handleChange}
                      error={sumInsuredFrom.error.error}
                      value={sumInsuredFrom.value}
                      placeholder="$"
                      caption={sumInsuredFrom.error.error && sumInsuredFrom.error.errorMsg}
                      blurHandler={(value, name) => this.handleRangeValidation(name, 'sumInsured')}
                      captionWithIcon
                    />
                    <HalfWidthInput
                      editable
                      htmlFor="sumInsuredTo"
                      label={get(benefitSumInsuredTo, 'value', '')}
                      name="sumInsuredTo"
                      changeHandler={this.handleChange}
                      error={sumInsuredTo.error.error}
                      value={sumInsuredTo.value}
                      placeholder="$"
                      caption={sumInsuredTo.error.error && sumInsuredTo.error.errorMsg}
                      blurHandler={(value, name) => this.handleRangeValidation(name, 'sumInsured')}
                      captionWithIcon
                    />
                  </FieldSet>
                </Holder>
              </FieldSet>
              <FieldSet>
                <Holder>
                  <FieldSet>
                    <HalfWidthSelect
                      data-locator="premiumPaymentFrequency"
                      label={get(paymentFrequency, 'value', '')}
                      value={premiumPaymentFrequency.value}
                      name="premiumPaymentFrequency"
                      id="premiumPaymentFrequency"
                      changeHandler={this.handleChange}
                      options={REPORTING_PAYMENT_FREQUENCY_OPTIONS}
                      error={premiumPaymentFrequency.error.error}
                      caption={premiumPaymentFrequency.error.error && 'error-text'}
                      labelStyle={styles.selectLabel}
                    />
                    <HalfWidthInput
                      editable
                      htmlFor="premiumFrom"
                      label={get(premeumAmountFrom, 'value', '')}
                      name="premiumFrom"
                      changeHandler={this.handleChange}
                      error={premiumFrom.error.error}
                      value={premiumFrom.value}
                      placeholder="$"
                      caption={premiumFrom.error.error && premiumFrom.error.errorMsg}
                      blurHandler={(value, name) => this.handleRangeValidation(name, 'premium')}
                      captionWithIcon
                    />
                  </FieldSet>
                </Holder>
                <Holder>
                  <FieldSet>
                    <HalfWidthInput
                      editable
                      htmlFor="premiumTo"
                      label={get(premeumAmountTo, 'value', '')}
                      name="premiumTo"
                      changeHandler={this.handleChange}
                      error={premiumTo.error.error}
                      value={premiumTo.value}
                      placeholder="$"
                      caption={premiumTo.error.error && premiumTo.error.errorMsg}
                      blurHandler={(value, name) => this.handleRangeValidation(name, 'premium')}
                      captionWithIcon
                    />
                    {/*
                    <HalfWidthInput
                      editable
                      htmlFor="insuranceAnniversary"
                      label={renderTextField(insurancyAnniversary)}
                      name="insuranceAnniversary"
                      placeholder="DD / MM / YYYY"
                      changeHandler={this.handleChange}
                      error={insuranceAnniversary.error.error}
                      value={insuranceAnniversary.value}
                      caption={
                        insuranceAnniversary.error.error && insuranceAnniversary.error.errorMsg
                      }
                      options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                    /> */}
                  </FieldSet>
                </Holder>
              </FieldSet>
              <FieldSet>
                <HalfWidthSelect
                  data-locator="policyStatus"
                  label={get(policyStatusLabel, 'value', '')}
                  value={policyStatus.value}
                  name="policyStatus"
                  id="policyStatus"
                  changeHandler={this.handleChange}
                  options={policyOptions}
                  error={policyStatus.error.error}
                  caption={policyStatus.error.error && 'error-text'}
                  labelStyle={styles.selectLabel}
                />
                <Holder>
                  <MutliSelect
                    name="insuranceOptions"
                    options={INSURANCE_OPTIONS}
                    label={insuranceOptionsLabel.value}
                    selectedOptions={insuranceOptions.value}
                    handleOptionChange={this.handleOptionChange}
                  />
                </Holder>
              </FieldSet>
              <FieldSet>
                <Holder>
                  <HalfWidthSelect
                    data-locator="adviserCode"
                    label={get(adviserCodeLabel, 'value', '')}
                    value={adviserCode.value}
                    name="adviserCode"
                    id="adviserCode"
                    changeHandler={this.handleChange}
                    options={this.getAdviserCodes()}
                    error={adviserCode.error.error}
                    caption={adviserCode.error.error && 'error-text'}
                    labelStyle={styles.selectLabel}
                  />
                </Holder>
              </FieldSet>
              <SectionHeader>{clientDetailsHeader.value}</SectionHeader>

              <FieldSet>
                <HalfWidthInput
                  editable
                  htmlFor="firstName"
                  label={get(firstNameLabel, 'value', '')}
                  name="firstName"
                  changeHandler={this.handleChange}
                  error={firstName.error.error}
                  value={firstName.value}
                  caption={firstName.error.error && firstName.error.errorMsg}
                  captionWithIcon
                />
                <HalfWidthInput
                  editable
                  htmlFor="lastName"
                  label={get(lastNameLabel, 'value', '')}
                  name="lastName"
                  changeHandler={this.handleChange}
                  error={lastName.error.error}
                  value={lastName.value}
                  caption={lastName.error.error && lastName.error.errorMsg}
                  captionWithIcon
                />
              </FieldSet>

              <FieldSet>
                <Holder>
                  <InputComponent
                    editable
                    htmlFor="companyName"
                    label={get(companyNameLabel, 'value', '')}
                    name="companyName"
                    changeHandler={this.handleChange}
                    error={companyName.error.error}
                    value={companyName.value}
                    caption=""
                    disabled={isLifeInsured}
                    captionWithIcon
                  />
                </Holder>
                <HalfWidthSelect
                  data-locator="state"
                  label={get(clientState, 'value', '')}
                  value={state.value}
                  name="state"
                  id="state"
                  changeHandler={this.handleChange}
                  options={STATES}
                  error={state.error.error}
                  caption={state.error.error && 'error-text'}
                  labelStyle={styles.selectLabel}
                />
              </FieldSet>
              {searchType.value === POLICY_OWNER_OPTIONS.lifeInsuredOptionValue && (
                <FieldSet>
                  <HalfWidthSelect
                    data-locator="gender"
                    label={get(clientGender, 'value', '')}
                    value={gender.value}
                    name="gender"
                    id="gender"
                    changeHandler={this.handleChange}
                    options={GENDER}
                    error={gender.error.error}
                    caption={gender.error.error && 'error-text'}
                    disabled={searchType.value === searchTypeOptions[0].value}
                    labelStyle={styles.selectLabel}
                  />

                  <Holder>
                    <FieldSet useFlex>
                      <QuarterWidthInput
                        editable
                        htmlFor="ageFrom"
                        label={get(ageRangeFromLabel, 'value', '')}
                        name="ageFrom"
                        changeHandler={this.handleChange}
                        error={ageFrom.error.error}
                        value={ageFrom.value}
                        caption={ageFrom.error.error && ageFrom.error.errorMsg}
                        disabled={searchType.value === searchTypeOptions[0].value}
                        blurHandler={(value, name) => this.handleRangeValidation(name, 'age')}
                        captionWithIcon
                      />
                      <QuarterWidthInput
                        editable
                        htmlFor="ageTo"
                        label={get(ageRangeToLabel, 'value', '')}
                        name="ageTo"
                        changeHandler={this.handleChange}
                        error={ageTo.error.error}
                        value={ageTo.value}
                        caption={ageTo.error.error && ageTo.error.errorMsg}
                        disabled={searchType.value === searchTypeOptions[0].value}
                        blurHandler={(value, name) => this.handleRangeValidation(name, 'age')}
                        captionWithIcon
                      />
                    </FieldSet>
                  </Holder>
                </FieldSet>
              )}

              <ButtonPanel>
                <ButtonsHolder>
                  <DownloadCSV
                    filename={`${reportingDownloadFilename.value}${CSV_FILE_EXTENSION}`}
                    ref={this.csvLinkRef}
                    asyncOnClick={CSV_ASYNC_ON_CLICK}
                    headers={csvHeadersForTailoredSearch}
                    data={getFormatClientReportData(tailoredSearchData)}
                    onClick={(event, done) =>
                      this.handleDownloadButtonClick(tailoredSearchData, done, TAILORED_SEARCH)
                    }
                  >
                    {renderTextField(downloadButtonText)}
                  </DownloadCSV>
                  <ClearButton
                    variant="secondaryWithTheme"
                    size="medium"
                    onClick={this.handleReset}
                  >
                    {clearSearchButtonText.value}
                  </ClearButton>
                </ButtonsHolder>
              </ButtonPanel>
              {isNoRecordFound && (
                <NotificationWrapper variant="error" closeHandler={this.notificationCloseError}>
                  {clientReportNoRecordFoundText.value}
                </NotificationWrapper>
              )}
            </Form>
          </FormWrap>
        </Wrap>
        <Modal
          isOpen={isReportModal}
          title={reportModalTitle}
          onClose={() => this.showReportModal(false)}
        >
          <ModalDescription>{renderTextField(reportModalDescription)}</ModalDescription>
          <Button variant="secondaryWithTheme" onClick={this.handleDownload}>
            {renderTextField(reportModalButton)}
          </Button>
        </Modal>
        <Modal
          isOpen={isRetryModal}
          title={retryReportDownloadTitle}
          onClose={() => this.showRetryModal(false)}
        >
          <ModalDescription>{renderTextField(retryReportDownloadDescription)}</ModalDescription>
          <Button variant="secondaryWithTheme" onClick={this.handleRetryDownload}>
            {renderTextField(reportModalButton)}
          </Button>
        </Modal>
      </WithLoader>
    )
  }
}

export const mapStateToProps = ({
  forms,
  advisor: { advisorDetails },
  masterList,
  clientReport,
}) => ({
  form: forms[FORM_ID],
  advisorDetails: advisorDetails && advisorDetails.length ? advisorDetails : [],
  masterData: masterList.data,
  clientReport,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportingFormComponent)
