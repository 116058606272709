// @flow
import {
  OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_SUCCESS,
  OCCUPATIONS_SEARCH_OCCUPATION,
  OCCUPATIONS_SEARCH_OCCUPATION_SUCCESS,
  OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS,
  OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_INIT,
  OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_SUCCESS,
  OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_FAILURE,
  OCCUPATIONS_SEARCH_OCCUPATION_FAILURE,
  OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_FAILURE,
  OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_ERROR,
  OCCUPATIONS_SEARCH_OCCUPATION_ERROR,
  OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_ERROR,
  OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_INIT,
  OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_ERROR,
  OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_FAILURE,
  OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_SUCCESS,
  OCCUPATIONS_UPDATE_GENERAL_OCCUPATIONS,
  OCCUPATIONS_NAMES_RESET,
  RESET_OCCUPATIONS_ON_STORE,
  RESET_SEC_OCCUPATION,
} from '../actions/types/occupation'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

type occupationAttributes = {
  TPD: string,
  Life: string,
  BE: string,
  IP: string,
  CI: string,
}

type occupationType = {
  name: string,
  attributes: occupationAttributes,
  text: string,
  aliases: Array<string>,
}

export type occupationsStateType = {
  generalOccupations: Array<occupationType>,
  occupationNames: Array<string>,
  occupationRating: occupationType,
  isLoading: boolean,
}

export const initialState: occupationsStateType = {
  generalOccupations: [],
  occupationNames: [],
  occupationRating: {},
  secondaryOccupationRating: {},
  isLoading: false,
  isLoadingOccupationRating: false,
}

const occupation = (
  state: Object = initialState,
  action: { type: string, payload: Object, error: boolean }
): occupationsStateType => {
  switch (action.type) {
    case OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_SUCCESS: {
      return {
        ...state,
        generalOccupations: action.payload,
        isLoading: false,
      }
    }
    case OCCUPATIONS_SEARCH_OCCUPATION: {
      return {
        ...state,
        occupationNames: [''],
        isLoading: true,
      }
    }

    case OCCUPATIONS_SEARCH_OCCUPATION_SUCCESS: {
      return {
        ...state,
        occupationNames: action.payload,
        isLoading: false,
      }
    }

    case OCCUPATIONS_NAMES_RESET: {
      return {
        ...state,
        occupationNames: [],
      }
    }

    case OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_SUCCESS: {
      return {
        ...state,
        occupationRating: action.payload,
        isLoading: false,
        isLoadingOccupationRating: false,
      }
    }

    case OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_INIT:
    case OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_INIT: {
      return {
        ...state,
        isLoadingOccupationRating: true,
      }
    }

    case OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_SUCCESS: {
      return {
        ...state,
        secondaryOccupationRating: action.payload,
        isLoading: false,
        isLoadingOccupationRating: false,
      }
    }

    case OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_FAILURE:
    case OCCUPATIONS_SEARCH_OCCUPATION_FAILURE:
    case OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_FAILURE:
    case OCCUPATIONS_GET_ALL_GENERAL_OCCUPATIONS_ERROR:
    case OCCUPATIONS_SEARCH_OCCUPATION_ERROR:
    case OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_ERROR:
    case OCCUPATIONS_GET_SELECTED_SEC_OCCUPATION_RATING_FAILURE:
    case OCCUPATIONS_GET_SELECTED_OCCUPATION_RATING_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoadingOccupationRating: false,
      }
    }

    case OCCUPATIONS_UPDATE_GENERAL_OCCUPATIONS: {
      const { occupationData, isSecondaryOccupation } = action.payload
      return {
        ...state,
        isLoading: false,
        ...(!isSecondaryOccupation && {
          occupationRating: {
            attributes: occupationData,
          },
        }),
        ...(isSecondaryOccupation && {
          secondaryOccupationRating: { attributes: occupationData },
        }),
      }
    }

    case RESET_OCCUPATIONS_ON_STORE: {
      return initialState
    }

    case RESET_SEC_OCCUPATION: {
      return {
        ...state,
        secondaryOccupationRating: {},
      }
    }

    // reset when user logs out.
    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default occupation
