// @flow
import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import get from 'lodash/get'

// Used to reduce verbosity of sitecore authorable fields object
// for easier consumtion within our components.
export const reduceAuthorableFields = (fields: Object): Object =>
  Object.keys(fields).reduce((prev, next) => {
    const obj = prev
    obj[next] = fields[next] && fields[next].value
    return obj
  }, {})

// returns true if argument is a Sitecore field object
export const isSitecoreField = (field: string | { value: string }): boolean =>
  typeof field === 'object' && field !== null && typeof field.value === 'string'

/**
 * Return inline editable text field
 *
 * @param {string} field - key for label
 * @param {boolean} richText - identifer if required field is of type RichText
 * @returns - react element
 */

export const renderTextField = (field, richText = false) => {
  if (field) {
    if (richText) {
      return <RichText field={typeof field === 'string' ? { value: field } : field} />
    }
    return <Text field={typeof field === 'string' ? { value: field } : field} />
  }
  // console.error('sitecore field has not been defined')
  return null
}

export const reduceContentListFields = (list: Array<Object>): Array<Object> =>
  list.map(item => reduceAuthorableFields(item.fields))

// Replace multiple label from sitecore with the data object values
export const replaceLabelPlaceholders = (field, data) => {
  const regExForBraces = /{\w+}/g
  // Field can be sitecore label or string
  const label = typeof field === 'string' ? field : get(field, 'value', '')
  return (
    label.replace(
      regExForBraces,
      placeholder => data[placeholder.substring(1, placeholder.length - 1)] || placeholder
    ) || ''
  )
}
