/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconPhone32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.586 4.272c.522.523 1.45 1.542 2.062 2.764.543 1.085 1.42 3.653-.648 5.721a8.488 8.488 0 0 0-.446.476c.033.074.082.173.155.297.27.457.787 1.138 1.736 2.087.95.95 1.625 1.461 2.071 1.723.098.057.178.098.242.128.103-.091.25-.233.485-.468 2.068-2.069 4.636-1.191 5.721-.648 1.222.612 2.241 1.54 2.764 2.062.586.586 1.154 1.223 1.567 1.916.438.736.802 1.707.676 2.852-.12 1.09-.64 1.898-1.032 2.403-.362.466-.816.92-1.166 1.27l-.045.044c-2.922 2.922-6.894 2.536-9.902 1.428-3.097-1.14-6.145-3.332-8.483-5.67-2.338-2.338-4.53-5.386-5.67-8.482-1.108-3.01-1.494-6.981 1.427-9.903l.046-.045c.35-.35.803-.804 1.269-1.166.505-.392 1.313-.913 2.403-1.032 1.145-.126 2.117.238 2.852.676.693.413 1.33.981 1.916 1.567zm5.37 13.271zm-4.467-4.49v.003a.12.12 0 0 1 0-.004zm-3.025 7.482C9.222 16.293 5.851 9.765 9.222 6.393c1.58-1.58 2.136-2.106 4.242 0 .878.878 2.653 3.004 1.415 4.243-1.415 1.414-2.762 2.895 1.445 7.102 4.207 4.208 5.626 2.797 7.04 1.383 1.239-1.238 3.365.537 4.243 1.415 2.106 2.106 1.58 2.662 0 4.242-3.371 3.371-9.9 0-14.143-4.242z"
      fill="#000"
    />
  </Icon>
)

export default IconPhone32
