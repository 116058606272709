import React from 'react'
import get from 'lodash/get'
import { Heading } from '@mlcl-digital/mlcl-design'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../utils/sitecoreUtils'

// types
import { CompanyPropTypes } from '../../../../types/components/PersonalAndContactDetails'

// components
import Director from './Director'

const Company = (props: CompanyPropTypes) => {
  const { fields, data, isSMSF } = props
  const abn = get(data, 'relatedParty.abnNumber', '') as string
  const businessName = get(data, 'relatedParty.businessName', '') as string
  const secondaryData = data?.level1
  return (
    <>
      <GridContainer>
        <LabelValuePair
          label={renderTextField(isSMSF ? fields.FundName : fields.CompanyName)}
          value={businessName}
        />
        {abn && <LabelValuePair label={renderTextField(fields.AbnNumber)} value={abn} />}
      </GridContainer>
      {!isSMSF &&
        secondaryData &&
        secondaryData.map((director, index: number) => (
          <>
            <Heading variant="h3" size="small">
              {`${get(fields, 'DirectorDetailsTitle.value', '') as string} ${
                secondaryData.length > 1 ? index + 1 : ''
              }`}
            </Heading>
            <Director {...props} data={director} />
          </>
        ))}
    </>
  )
}

export default Company
