const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  input: {
    flex: 1,
  },
}

export default styles
