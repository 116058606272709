import get from 'lodash/get'
import {
  optional,
  required,
  numeric,
  validateCreditCardNumber,
  validateMasterOrVisaNumber,
  isNotFormattedDate,
  validateFutureDate,
} from '../../../../../../utils/formUtils'
import { PAYMENT_DRAW_DATE } from '../../../../../../constants/forms'

export const FORM_ID = 'creditCard'

const schema = ({ fields }, displayCreditCardListing) => ({
  cardNumber: {
    condition: [
      displayCreditCardListing === true ? optional : required,
      {
        condition: numeric,
        errorMsg: get(fields, 'addPayerCreditCardAccountNumberNumericErrorMsz.value', ''),
      },
      {
        condition: validateCreditCardNumber,
        errorMsg: get(fields, 'addPayerCreditCardAccountNumberValidErrorMsz.value', ''),
      },
      {
        condition: validateMasterOrVisaNumber,
        errorMsg: get(fields, 'addPayerCreditCardAccountNumberMasterVisaErrorMsz.value', ''),
      },
    ],
    errorMsg: get(fields, 'addPayerCreditCardAccountNumberErrorMsz.value', ''),
    tooltip: '',
  },
  cardHolderName: {
    condition: required,
    errorMsg: get(fields, 'addPayerCreditCardAccountNameErrorMsz.value', ''),
    tooltip: '',
  },
  expiryDate: {
    condition: [
      required,
      {
        condition: validateFutureDate(),
        errorMsg: get(fields, 'addPayerCreditCardExpiryDateValidErrorMsz.value', ''),
      },
      {
        condition: isNotFormattedDate,
        errorMsg: get(fields, 'addPayerCreditCardExpiryDateValidErrorMsz.value', ''),
      },
    ],
    errorMsg: get(fields, 'addPayerCreditCardExpiryDateErrorMsz.value', ''),
    tooltip: '',
  },
  paymentDrawDay: {
    condition: optional,
    errorMsg: get(fields, 'addPayerCreditCardPaymentDrawDayErrorMsg.value', ''),
    options: PAYMENT_DRAW_DATE,
    tooltip: '',
  },
  termsAndConditions: {
    condition: required,
    errorMsg: fields.addPayerCreditCardTermsError.value,
    tooltip: '',
  },
})

export default schema
