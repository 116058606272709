/* eslint-disable no-unused-vars */
// @flow
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { components } from 'react-select'
import { Label, FormBlock } from '@mlcl-digital/mlcl-design'

// components.
import ListBox from './ListBox'
import Caption from '../Caption'
import { IconChevronDown16, IconChevronUp16 } from '../Icons'

// styles.
import { themeConsumer } from '../../../styles/ThemeContext'

// styles.
import { getClassnameProp } from '../../../utils/stylesUtils'

export type SelectOptionType = {
  value: mixed,
  label: string,
}

type DataType = {
  heading?: string,
  options: Array<SelectOptionType>,
}

type MultiSectionSelectProps = {
  // Set select to error state.
  error?: boolean,
  // A function to be fire upon change of select options.
  changeHandler: Function,
  // The html name for the input.
  name: string,
  // An array of items for the component to list.
  data: Array<DataType>,
  // Test to display when select has no option selected.
  placeholder?: string,
  // The select value to display in the select box.
  value?: SelectOptionType | string | number,
  // An extra label for adding containing forms for aria AA.
  labelledby?: string,
  // The id for the select element.
  id: string,
  // The label text for the select.
  label?: string,
  // Text to display in caption tag
  caption?: 'string',
  // Style select label
  labelStyle?: {},
  // Theme for site
  theme: Object,
}

export const DropdownIndicator = (props: Object) => {
  const {
    selectProps: { menuIsOpen },
  } = props
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? <IconChevronUp16 /> : <IconChevronDown16 />}
      </components.DropdownIndicator>
    )
  )
}

// MultiSectionSelect Component. */
const MultiSectionSelect = (props: MultiSectionSelectProps) => {
  const {
    changeHandler,
    name,
    data,
    placeholder,
    id,
    label,
    labelledby,
    value,
    error,
    caption,
    labelStyle,
    theme,
    dataLocator: propDataLocator,
  } = props

  const handleChange = option => {
    changeHandler({ value: option, name })
  }

  const hasLabel = !!label.length

  const dataLocator = {
    'data-locator': propDataLocator || `select-${name}`,
  }

  // this allows <Select> accept `value` as either an options object or a
  // string/number that correlates to an option.value
  const getValueAsOption = (): SelectOptionType | void => {
    if (value === '') return value // So placeholder will display if there is no value
    if (!data.length && typeof value === 'string' && value) {
      return { label: value, value }
    }
    if (typeof value === 'string' || typeof value === 'number') {
      // if `value` is passed as a string or number, use it to identify which
      // object in the `data` array should be set as selected/active.
      let selectedObj
      let index
      for (index = 0; index < data.length; index += 1) {
        selectedObj = data[index].options.find(({ value: optionValue }) => optionValue === value)
        if (selectedObj) {
          break
        }
      }
      return selectedObj
    }
    return value
  }
  return (
    <FormBlock hasMargin={!error} {...dataLocator} {...getClassnameProp(props)}>
      {label && (
        <Label css={labelStyle} error={error} htmlFor={id} id={`${id}-label`}>
          {label}
        </Label>
      )}
      <ListBox
        data={data}
        value={getValueAsOption()}
        id={id}
        aria-labelledby={`${hasLabel ? `${id}-label` : ''} ${labelledby}`}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        theme={theme}
        error={error}
      />
      {caption && <Caption error={error}>{caption}</Caption>}
    </FormBlock>
  )
}

MultiSectionSelect.defaultProps = {
  value: '',
  error: '',
  placeholder: '',
  labelledby: '',
  label: '',
  caption: '',
  labelStyle: {},
}

export default themeConsumer(MultiSectionSelect, 'form')
