/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconAddCircle32 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16.0001 8.22217C16.6137 8.22217 17.1112 8.71964 17.1112 9.33328V14.8888H22.6667C23.2804 14.8888 23.7778 15.3863 23.7778 15.9999C23.7778 16.6136 23.2804 17.1111 22.6667 17.1111H17.1112V22.6666C17.1112 23.2803 16.6137 23.7777 16.0001 23.7777C15.3864 23.7777 14.889 23.2803 14.889 22.6666V17.1111H9.3334C8.71974 17.1111 8.22229 16.6136 8.22229 15.9999C8.22229 15.3863 8.71974 14.8888 9.3334 14.8888H14.889V9.33328C14.889 8.71964 15.3864 8.22217 16.0001 8.22217Z"
      fill="#000"
    />
  </Icon>
)

export default IconAddCircle32
