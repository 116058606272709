import { required } from '../../../../../utils/formUtils'
import { PAYMENT_DETAILS } from '../../../../../constants/forms'

export const FORM_ID = 'paymentDetails'

const schema = {
  paymentDetails: {
    condition: required,
    options: PAYMENT_DETAILS,
  },
}

export default schema
