import { Variables } from '@mlcl-digital/mlcl-design'

import { VariablesType } from '../../../../../types/ComponentLibrary'

const { colours, space } = Variables as VariablesType

const styles = {
  wrapper: {
    width: space(50),
    position: 'relative' as const,
    backgroundColor: colours.tertiarySix,
    padding: space(3),
    label: {
      marginTop: space(2),
    },
  },
  section: {
    clear: 'both' as const,
    marginBottom: space(3),
    color: 'black',
  },
  headerWrapper: {
    marginBottom: space(4),
  },
}

export default styles
