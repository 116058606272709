// @flow
import { getPersistor } from '../store'

// utils
import { isBrowser, isLocalStorage } from './browserUtils'
import { isWhiteListedURL } from './commonUtils'

export const logout = ({ oktaSignOutAction, currentRoute }) => {
  const checkRouteValid = isWhiteListedURL(currentRoute)

  if (isLocalStorage()) {
    window.localStorage.removeItem('NotificationBarVisible')
    window.localStorage.removeItem('userId')
  }

  if (isBrowser() && checkRouteValid) {
    // trigger signout action
    // remove persisted data
    const persistor = getPersistor()
    if (persistor) persistor.purge()
    oktaSignOutAction()
  }
}
