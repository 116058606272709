// @flow
import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../../../../actions'

// utils
import { errorCheck } from '../../../../../../utils/formUtils'
import { renderTextField } from '../../../../../../utils/sitecoreUtils'

// schema.
import SCHEMA, { FORM_ID } from './addLoading.schema'

// components
import Select from '../../../../../atoms/Select'

import TabHeaderComponent from '../TabHeader'

// style
import styles from './addLoading.styles'

// styled component
const ContentWrapper = styled('section')(styles.contentWrapper)
const InputWrapper = styled('div')(styles.inputWrapper)
const Dropdown = styled(Select)(styles.dropdown)
const LoadingType = styled('div')(styles.loadingType)
const LoadingAmount = styled('div')(styles.loadingAmount)

type addLoadingProps = {
  actions: Object<Function>,
  fields: Object,
  form: Object,
}

export class AddLoading extends Component<addLoadingProps> {
  schemaWithAuthorableFields = SCHEMA()

  componentWillMount() {
    const { actions } = this.props
    const { formInit } = actions
    const schema = this.schemaWithAuthorableFields({})
    formInit(FORM_ID, schema, {})
  }

  getExclusionList() {
    const { form } = this.props
    const { benefit, loadingReason, loadingType, loadingAmount } = form.fields
    return {
      benefit: benefit.value ? benefit.value.value : '',
      reason: loadingReason.value ? loadingReason.value.value : '',
      type: loadingType.value ? loadingType.value.value : '',
      amount: loadingAmount.value ? loadingAmount.value.value : '',
    }
  }

  handleChange = ({ value, name }) => {
    const { actions } = this.props
    const { formUpdateField, formValidate } = actions
    const schema = this.schemaWithAuthorableFields(this.getExclusionList())

    const data = {
      error: errorCheck(value, schema[name].condition, null),
      value,
    }
    formUpdateField(FORM_ID, name, data)

    if (name === 'benefit') {
      formUpdateField(FORM_ID, 'loadingReason', { error: false, value: '' })
      formUpdateField(FORM_ID, 'loadingType', { error: false, value: '' })
      formUpdateField(FORM_ID, 'loadingAmount', { error: false, value: '' })
    }

    if (name === 'loadingReason') {
      formUpdateField(FORM_ID, 'loadingType', { error: false, value: '' })
      formUpdateField(FORM_ID, 'loadingAmount', { error: false, value: '' })
    }

    if (name === 'loadingType')
      formUpdateField(FORM_ID, 'loadingAmount', { error: false, value: '' })

    formValidate(FORM_ID, schema)
  }

  render() {
    const { fields, form } = this.props
    if (!form) return null
    const { benefit, loadingReason, loadingType, loadingAmount } = form.fields
    const schema = this.schemaWithAuthorableFields(this.getExclusionList())
    const {
      addLoadingBenefitLabel,
      addLoadingBenefitPlaceholder,
      addLoadingBenefitError,
      addLoadingReasonLabel,
      addLoadingReasonPlaceholder,
      addLoadingReasonError,
      addLoadingTypeLabel,
      addLoadingTypePlaceholder,
      addLoadingTypeError,
      addLoadingAmountLabel,
      addLoadingAmountPlaceholder,
      addLoadingAmountError,
    } = fields

    return (
      <Fragment>
        <TabHeaderComponent activeTab={2} fields={fields} />
        <ContentWrapper>
          <InputWrapper>
            <Dropdown
              label={renderTextField(addLoadingBenefitLabel)}
              placeholder={renderTextField(addLoadingBenefitPlaceholder)}
              value={benefit.value}
              name="benefit"
              id="benefit"
              error={benefit.error.error}
              caption={benefit.error.error && renderTextField(addLoadingBenefitError)}
              changeHandler={this.handleChange}
              options={schema.benefit.options}
              size="lg"
            />
          </InputWrapper>
          <InputWrapper>
            <Dropdown
              label={renderTextField(addLoadingReasonLabel)}
              placeholder={renderTextField(addLoadingReasonPlaceholder)}
              value={loadingReason.value}
              name="loadingReason"
              id="loadingReason"
              error={loadingReason.error.error}
              caption={loadingReason.error.error && renderTextField(addLoadingReasonError)}
              changeHandler={this.handleChange}
              options={schema.loadingReason.options}
              size="lg"
              disabled={!benefit.value}
            />
          </InputWrapper>
          <InputWrapper>
            <LoadingType>
              <Dropdown
                label={renderTextField(addLoadingTypeLabel)}
                placeholder={renderTextField(addLoadingTypePlaceholder)}
                value={loadingType.value}
                name="loadingType"
                id="loadingType"
                error={loadingType.error.error}
                caption={loadingType.error.error && renderTextField(addLoadingTypeError)}
                changeHandler={this.handleChange}
                options={schema.loadingType.options}
                size="lg"
                disabled={!loadingReason.value}
              />
            </LoadingType>
            <LoadingAmount>
              <Dropdown
                label={renderTextField(addLoadingAmountLabel)}
                placeholder={renderTextField(addLoadingAmountPlaceholder)}
                value={loadingAmount.value}
                name="loadingAmount"
                id="loadingAmount"
                error={loadingAmount.error.error}
                caption={loadingAmount.error.error && renderTextField(addLoadingAmountError)}
                changeHandler={this.handleChange}
                options={schema.loadingAmount.options}
                size="lg"
                disabled={!loadingType.value}
              />
            </LoadingAmount>
          </InputWrapper>
        </ContentWrapper>
      </Fragment>
    )
  }
}

export const mapStateToProps = ({ forms, preAssessment: { loadings, activeEditIndex } }) => ({
  form: forms[FORM_ID],
  loadings,
  activeEditIndex,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddLoading)
