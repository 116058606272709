import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Chip, Tooltip, Icons } from '@mlcl-digital/mlcl-design'
import ActionLink from '@mlcl-digital/mlcl-design/lib/base/ActionLink'
import setupIcons from '@mlcl-digital/mlcl-design/lib/base/setupIcons'

import styled from '@emotion/styled'
import get from 'lodash/get'

import { CardTable, CardCell, Card as NewCard } from '../CardChildren'

import { renderTextField } from '../../../utils/sitecoreUtils'

import styles from './altsPolicyCard.styles'

const { IconInfo16 } = Icons

const StyledCardTable = styled(CardTable)(styles.cardTable)
const StyledCardCell = styled(CardCell)(({ isHeader, content }) =>
  styles.cardCell({ isHeader, content })
)
const Footer = styled('dl')(styles.footer)
const PolicyUnavailable = styled('div')(styles.policyUnavailable)
const TooltipContainer = styled('div')(styles.tooltipContainer)
const TooltipContent = styled(Tooltip)(styles.tooltipContent)
const InfoIcon = styled(IconInfo16)(styles.infoIcon)
const ListTerm = styled('dt')(styles.listTerm)
const ListTermDescription = styled('dd')(styles.listTermDescription)
const ListTermLight = styled('dt')(styles.listTermLight)
const ListTermDescriptionLight = styled('dd')(styles.listTermDescriptionLight)
const CTARowItem = styled('div')(styles.aCTARowItem)
const ConnectedBenefit = styled('p')(styles.connectedBenefit)

const AltsPolicyCard = ({
  cardKeyPolicyNumber,
  fields,
  policyType,
  subHeadingText,
  mainHeadingText,
  collectionFrequency,
  policyPremiumValue,
  policyBenefitAmounts,
  policyFee,
  policyPremiumText,
  policyUnavailableText,
  stampDuty,
  taxRebate,
  policySavingsLabel,
  policySavings,
  isHighlightTotalPremium,
  showTaxRebate,
  shouldDisplay,
  toggleShouldDisplay,
  linkedBenefits,
  flexGrow,
}) => {
  const {
    policySectionBenefitsHeader,
    policySectionSumInsuredHeader,
    NAErrText,
    PolicyFee,
    StampDutyLabel,
    TaxRebateLabel,
    SavingsAmountTooltip,
    ShowMoreDetailsCTA,
    ShowFewerDetailsCTA,
    LinkedPolicyText,
  } = fields

  setupIcons()

  const showDetailsCTAProps = shouldDisplay
    ? { label: ShowFewerDetailsCTA?.value, iconName: 'fa-chevron-up' }
    : { label: ShowMoreDetailsCTA?.value, iconName: 'fa-chevron-down' }

  return (
    <NewCard
      header={
        <>
          <Chip variant={policyType === 'Inside Super' ? 'default' : 'important'}>
            {policyType}
          </Chip>
          <div>
            <span>{subHeadingText}</span>
          </div>
          <div>{mainHeadingText}</div>
        </>
      }
      footer={
        !policyUnavailableText && (
          <Footer>
            <dt>{renderTextField(StampDutyLabel)}</dt>
            <dd>{stampDuty}</dd>
            {showTaxRebate && (
              <>
                <dt>{renderTextField(TaxRebateLabel)}</dt>
                <dd>{taxRebate}</dd>
              </>
            )}
            <dt>{renderTextField(PolicyFee)}</dt>
            <dd>{policyFee}</dd>
            <ListTerm isHighlightTotalPremium={isHighlightTotalPremium}>
              {policyPremiumText}
            </ListTerm>
            <ListTermDescription isHighlightTotalPremium={isHighlightTotalPremium}>
              {policyPremiumValue}
            </ListTermDescription>
            {policySavings && (
              <>
                <ListTermLight>
                  <TooltipContainer>
                    <span data-tip data-for="policySavingTooltip">
                      <InfoIcon />
                    </span>
                    <TooltipContent id="policySavingTooltip">
                      {renderTextField(SavingsAmountTooltip)}
                    </TooltipContent>
                  </TooltipContainer>
                  {policySavingsLabel}
                </ListTermLight>
                <ListTermDescriptionLight data-testid="policyPremiumSavings">
                  {policySavings}
                </ListTermDescriptionLight>
              </>
            )}
            {linkedBenefits && (
              <>
                {linkedBenefits.map(benefitInfo => (
                  <ConnectedBenefit>
                    {get(LinkedPolicyText, 'value', '').replace('####', benefitInfo.split(' ')[0])}
                  </ConnectedBenefit>
                ))}
              </>
            )}
          </Footer>
        )
      }
      flexGrow={flexGrow}
    >
      <StyledCardTable shouldDisplay={shouldDisplay}>
        <StyledCardCell isHeader>{renderTextField(policySectionBenefitsHeader)}</StyledCardCell>
        <StyledCardCell isHeader>{renderTextField(policySectionSumInsuredHeader)}</StyledCardCell>
        <StyledCardCell isHeader>{collectionFrequency}</StyledCardCell>
        {policyBenefitAmounts.map(({ benefitAmount, benefitName, index, benefitPremium }) => (
          <Fragment key={`${benefitName}${benefitAmount}-${index}`}>
            <StyledCardCell content={policySectionBenefitsHeader?.value}>
              {benefitName}
            </StyledCardCell>
            <StyledCardCell content={policySectionSumInsuredHeader?.value}>
              {policyUnavailableText ? renderTextField(NAErrText) : benefitAmount}
            </StyledCardCell>
            <StyledCardCell content={collectionFrequency}>
              {policyUnavailableText ? renderTextField(NAErrText) : benefitPremium}
            </StyledCardCell>
          </Fragment>
        ))}
      </StyledCardTable>
      <CTARowItem>
        <ActionLink
          onClick={() => toggleShouldDisplay(cardKeyPolicyNumber)}
          label={showDetailsCTAProps.label}
          iconName={showDetailsCTAProps.iconName}
        />
      </CTARowItem>
      {policyUnavailableText && (
        <PolicyUnavailable>{renderTextField(policyUnavailableText)}</PolicyUnavailable>
      )}
    </NewCard>
  )
}

AltsPolicyCard.propTypes = {
  cardKeyPolicyNumber: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    policySectionBenefitsHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    policySectionSumInsuredHeader: PropTypes.shape({
      value: PropTypes.string,
    }),
    NAErrText: PropTypes.shape({
      value: PropTypes.string,
    }),
    PolicyFee: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  policyBenefitAmounts: PropTypes.arrayOf(
    PropTypes.shape({
      benefitAmount: PropTypes.string,
      benefitName: PropTypes.string,
    })
  ).isRequired,
  collectionFrequency: PropTypes.string.isRequired,
  policyType: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  mainHeadingText: PropTypes.string.isRequired,
  policyPremiumValue: PropTypes.string.isRequired,
  policyFee: PropTypes.string.isRequired,
  policyPremiumText: PropTypes.string.isRequired,
  policyUnavailableText: PropTypes.string,
  stampDuty: PropTypes.string.isRequired,
  taxRebate: PropTypes.string,
  showTaxRebate: PropTypes.bool,
  policySavingsLabel: PropTypes.string.isRequired,
  policySavings: PropTypes.string,
  linkedBenefits: PropTypes.arrayOf(PropTypes.string),
  isHighlightTotalPremium: PropTypes.bool,
  shouldDisplay: PropTypes.bool,
  toggleShouldDisplay: PropTypes.func,
  flexGrow: PropTypes.bool,
}

AltsPolicyCard.defaultProps = {
  policyUnavailableText: '',
  taxRebate: null,
  showTaxRebate: true,
  isHighlightTotalPremium: false,
  policySavings: null,
  shouldDisplay: true,
  toggleShouldDisplay: () => {},
  linkedBenefits: [],
  flexGrow: false,
}

export { AltsPolicyCard }
