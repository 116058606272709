/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconSearch32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      d="M21.5 14a6.5 6.5 0 0 1-6.5 6.5v3a9.5 9.5 0 0 0 9.5-9.5h-3zM15 20.5A6.5 6.5 0 0 1 8.5 14h-3a9.5 9.5 0 0 0 9.5 9.5v-3zM8.5 14A6.5 6.5 0 0 1 15 7.5v-3A9.5 9.5 0 0 0 5.5 14h3zM15 7.5a6.5 6.5 0 0 1 6.5 6.5h3A9.5 9.5 0 0 0 15 4.5v3zm4.44 13.06l6.5 6.5 2.12-2.12-6.5-6.5-2.12 2.12z"
      fill="#000"
    />
  </Icon>
)

export default IconSearch32
