import { space, setColumns } from '../../../styles'

const styles = {
  base: {
    padding: space(3),
    width: 400,
    ...setColumns(2),
    '& > *': {
      ...setColumns(2)['& > *'],
    },
    position: 'relative',
  },
  halfWidth: {
    display: 'block',
    '& > *': {
      ...setColumns(2)['& > *'],
    },
  },
  sectionXl: {
    flex: `0 1 calc(70% - ${space(1, true)})`,
  },
  sectionXsm: {
    maxWidth: `calc(30% - ${space(1, true)})`,
  },
}
export default styles
