/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconRemoveCircle32 = ({ ...rest }) => (
  <Icon {...rest} size={32}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27ZM5.35714 12C4.60761 12 4 12.6716 4 13.5C4 14.3284 4.60761 15 5.35714 15H21.6429C22.3924 15 23 14.3284 23 13.5C23 12.6716 22.3924 12 21.6429 12H5.35714Z"
      fill="#000"
    />
  </Icon>
)

export default IconRemoveCircle32
