import { fontSize, colours, fontWeights, space, fontFamily } from '../../../styles'

const link = {
  padding: 0,
  height: 'auto',
  background: 'none',

  '&:hover': {
    background: 'none',
  },

  '&:focus': {
    background: 'none',
  },

  '& svg': {
    color: colours.black,
  },
}

const styles = {
  memberListContainer: {},
  memberListCaption: {
    fontSize: fontSize.xxs,
    margin: 0,
    marginBottom: space(-2, true),
  },
  memberList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  memberListItem: {
    display: 'flex',
    alignItems: 'center',
    color: colours.black,
    paddingBottom: space(2, true),
    borderBottom: `1px solid ${colours.lightestGrey}`,
    paddingTop: space(2, true),
    flexWrap: 'wrap',
  },
  memberName: {
    fontSize: fontSize.xs,
    fontWeight: fontWeights.bold,
    marginRight: 'auto',
  },
  editMember: {
    ...link,
    marginRight: space(2, true),
  },
  removeMember: {
    ...link,
  },
  primaryOwnerLabel: {
    color: colours.mediumGreen,
    fontWeight: fontWeights.bold,
    marginLeft: space(1, true),
  },
  primaryOwnerButton: {
    background: colours.white,
    color: colours.mediumGreen,
    fontSize: fontSize.xxs,
    paddingLeft: '0',
    paddingTop: '0',
    width: '100%',
    height: space(1, true),
    fontFamily: fontFamily.sourceSans,
    fontWeight: fontWeights.bold,
    justifyContent: 'flex-start',
    '&:hover': {
      background: colours.white,
    },
  },
}

export default styles
