// @flow

import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Image } from '@mlcl-digital/mlcl-design'

// styles
import styles from './logo.styles'

type LogoProps = {|
  /** Sets the routing path */
  href: string,
  /** Sets the horizontal logo assets source */
  horizontalSrc?: null | string,
  /** Sets the vertical logo assets source */
  verticalSrc?: null | string,
  /** Sets alt value for the logo image */
  alt?: null | string,
|}

const LinkContainer = styled(Link)(styles.container)
const Container = styled('div')(styles.container)
const LogoImage = styled(Image)(styles.image)
const Horizontal = styled(LogoImage)(styles.horizontal)
const Vertical = styled(LogoImage)(styles.vertical)

const LogoImages = ({ horizontalSrc, alt, verticalSrc }: Object) => (
  <Fragment>
    <Horizontal src={horizontalSrc} alt={alt} />
    <Vertical src={verticalSrc} alt={alt} />
  </Fragment>
)

const Logo = (props: LogoProps) => {
  const { href, horizontalSrc, verticalSrc, alt, ...rest } = props
  if (href) {
    return (
      <LinkContainer {...rest} to={href}>
        <LogoImages horizontalSrc={horizontalSrc} verticalSrc={verticalSrc} alt={alt} />
      </LinkContainer>
    )
  }
  return (
    <Container {...rest}>
      <LogoImages horizontalSrc={horizontalSrc} verticalSrc={verticalSrc} alt={alt} />
    </Container>
  )
}

Logo.defaultProps = {
  horizontalSrc: null,
  verticalSrc: null,
  alt: null,
}

export default Logo
