import { fontSize, colours } from '../../../../../../styles'

const styles = {
  label: {
    display: 'block',
    fontSize: fontSize.xxs,
    label: {
      fontSize: fontSize.xxs,
      color: colours.darkGrey,
      a: {
        color: colours.mediumGreen,
        textDecoration: 'none',
      },
    },
  },
  paymentFrequency: {
    color: colours.black,
  },
}

export default styles
