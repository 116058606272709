/* eslint-disable max-len */
import React from 'react'
import Icon from './Icon'

const IconTimer16 = ({ ...rest }) => (
  <Icon {...rest} size={16}>
    <path
      className="path1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8.414V3h2v4.586l2.207 2.207-1.414 1.414L7 8.414zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm0 2A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      fill="#000"
    />
  </Icon>
)

export default IconTimer16
